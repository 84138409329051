import React, {useState, useRef} from "react"
import MainTable from "../MainTable/MainTable"
import {currencyData} from "helpers/helpData"
import {localization} from "helpers/localizationForCompanies"
import {fromDate} from "helpers/dateFormat"
import {formatCurrency} from "helpers/currencyFormat"
import {ReactComponent as AttachmentIcon} from "assets/icons/attachment.svg"
import s from "./ProjectFinancialDetailsTable.module.scss"
import classNames from "classnames"
import {getFileIconByType} from "helpers/fileIconsByType"
import useOutsideClicker from "hooks/useOutsideClicker"

const ProjectFinancialDetailsTable = (
  {
    onAdd,
    onDelete,
    onEdit,
    havePermissions,
    data = []
  }
) => {
  const [visibleAttachmentsPopupId, setVisibleAttachmentsPopupId] = useState(
    null
  )
  const attachmentsRef = useRef(null)
  const withBaselineSpendColumn = data.some(item => item.base_line_spend)
  useOutsideClicker(attachmentsRef, () => setVisibleAttachmentsPopupId(null))

  return (
    <MainTable
      data={data}
      dataTypes={[
        {
          title: "Start date",
          value: ({start_date}) => fromDate(start_date)
        },
        {
          title: "End date",
          value: ({end_date}) => fromDate(end_date)
        },
        ...(withBaselineSpendColumn
          ? [
            {
              title: "Baseline Spend",
              value: ({base_line_spend_currency, base_line_spend}) =>
                base_line_spend
                  ? formatCurrency(
                    currencyData.filter(
                      e => e.label === base_line_spend_currency
                    )[0]?.value,
                    base_line_spend
                  )
                  : "-"
            }
          ]
          : []),

        {
          title: `${localization.targetSpend}`,
          value: ({budgeted_spend_currency, budgeted_spend}) =>
            formatCurrency(
              currencyData.filter(e => e.label === budgeted_spend_currency)[0]
                ?.value,
              budgeted_spend
            ),
          alignment: "left"
        },
        {
          title: `${localization.targetSavings}`,
          value: ({anticipated_savings_currency, anticipated_savings}) =>
            formatCurrency(
              currencyData.filter(
                e => e.label === anticipated_savings_currency
              )[0]?.value,
              anticipated_savings
            ),
          alignment: "left"
        },
        {
          title: `${localization.savingstype}`,
          value: ({savings_type}) => savings_type.name
        },
        {
          title: "Note",
          value: ({note}) => note || "-"
        },
        {
          title: "",
          value: ({attachments = [], id}) => (
            <div className={s.attachmentsWrap}>
              <AttachmentIcon
                className={classNames(s.icon, {
                  [s.disabled]: !attachments?.length
                })}
                onClick={() =>
                  attachments.length > 0 && setVisibleAttachmentsPopupId(id)
                }
              />
              {visibleAttachmentsPopupId === id && attachments.length > 0 && (
                <ul className={s.attachments} ref={attachmentsRef}>
                  {attachments.map((attachment, index) => (
                    <li className={s.attachment} key={index}>
                      {getFileIconByType(
                        attachment.type || attachment.file_type
                      )}
                      <a
                        className={s.link}
                        href={attachment.path}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {attachment.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )
        }
      ]}
      ariaLabel='financial details'
      options={item => {
        const options = [
          {
            title: "Edit",
            onClick: () => onEdit(item)
          },
          {
            title: "Delete",
            onClick: () => onDelete(item.id)
          }
        ]

        !havePermissions.update && delete options[0]
        !havePermissions.destroy && delete options[1]
        return options
      }}
      button={
        havePermissions.update && {
          name: "New detail",
          onClick: () => onAdd()
        }
      }
    />
  )
}
export default ProjectFinancialDetailsTable
