import React from "react";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import General from "./General/General";
import {useTranslation} from "react-i18next";

const AdminSettingsGeneralContainer = () => {
  const {t} = useTranslation();
  return (
      <div className={s.wrap}>
        <h2 className={s.title} data-translate-key="general">{t("general")}</h2>
        <General/>
      </div>
  );
};
export default AdminSettingsGeneralContainer;
