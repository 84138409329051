import React, { useEffect } from "react";
import LandingLayout from "../../Landinglayout/Landinglayout";
import s from "./CookiePage.module.scss";

const CookiePage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <LandingLayout>
      <div className={s.cookiePage}>
        <div className={s.landingContainer}>
          <h1 className={s.title}>Cookie Policy for Focal Point</h1>

          <div className={s.content}>
            <p className={s.text}>
              This is the Cookie Policy for Focal Point, accessible from
              https://focalpointprocurement.com
            </p>

            <h3 className={s.subtitle}>What Are Cookies</h3>

            <p className={s.text}>
              As is common practice with almost all professional websites this
              site uses cookies, which are tiny files that are downloaded to
              your computer, to improve your experience. This page describes
              what information they gather, how we use it and why we sometimes
              need to store these cookies. We will also share how you can
              prevent these cookies from being stored however this may downgrade
              or 'break' certain elements of the sites functionality.
            </p>

            <p className={s.text}>
              For more general information on cookies, please read{" "}
              <a
                href="https://www.cookieconsent.com/what-are-cookies/"
                target="_blank"
                rel="noopener noreferrer"
                className={s.link}
              >
                "What Are Cookies"
              </a>{" "}
              . Information regarding cookies from this Cookies Policy are from{" "}
              <a
                href="https://www.generateprivacypolicy.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={s.link}
              >
                the Privacy Policy Generator
              </a>
              .
            </p>

            <h3 className={s.subtitle}>How We Use Cookies</h3>

            <p className={s.text}>
              We use cookies for a variety of reasons detailed below.
              Unfortunately in most cases there are no industry standard options
              for disabling cookies without completely disabling the
              functionality and features they add to this site. It is
              recommended that you leave on all cookies if you are not sure
              whether you need them or not in case they are used to provide a
              service that you use.
            </p>

            <h3 className={s.subtitle}>Disabling Cookies</h3>

            <p className={s.text}>
              You can prevent the setting of cookies by adjusting the settings
              on your browser (see your browser Help for how to do this). Be
              aware that disabling cookies will affect the functionality of this
              and many other websites that you visit. Disabling cookies will
              usually result in also disabling certain functionality and
              features of the this site. Therefore it is recommended that you do
              not disable cookies. This Cookies Policy was created with the help
              of the{" "}
              <a
                href="https://www.cookiepolicygenerator.com/cookie-policy-generator/"
                target="_blank"
                rel="noopener noreferrer"
                className={s.link}
              >
                Cookies Policy Generator from CookiePolicyGenerator.com
              </a>
              .
            </p>

            <h3 className={s.subtitle}>The Cookies We Set</h3>

            <p className={s.text}>• Login related cookies</p>

            <p className={s.text}>
              We use cookies when you are logged in so that we can remember this
              fact. This prevents you from having to log in every single time
              you visit a new page. These cookies are typically removed or
              cleared when you log out to ensure that you can only access
              restricted features and areas when logged in.
            </p>

            <p className={s.text}>• Email newsletters related cookies</p>

            <p className={s.text}>
              This site offers newsletter or email subscription services and
              cookies may be used to remember if you are already registered and
              whether to show certain notifications which might only be valid to
              subscribed/unsubscribed users
            </p>

            <p className={s.text}>• Surveys related cookies</p>

            <p className={s.text}>
              From time to time we offer user surveys and questionnaires to
              provide you with interesting insights, helpful tools, or to
              understand our user base more accurately. These surveys may use
              cookies to remember who has already taken part in a survey or to
              provide you with accurate results after you change pages.
            </p>

            <p className={s.text}>• Forms related cookies</p>

            <p className={s.text}>
              When you submit data to through a form such as those found on
              contact pages or comment forms cookies may be set to remember your
              user details for future correspondence.
            </p>

            <p className={s.text}>• Site preferences cookies</p>

            <p className={s.text}>
              In order to provide you with a great experience on this site we
              provide the functionality to set your preferences for how this
              site runs when you use it. In order to remember your preferences
              we need to set cookies so that this information can be called
              whenever you interact with a page is affected by your preferences.
            </p>

            <h3 className={s.subtitle}>Third Party Cookies</h3>

            <p className={s.text}>
              In some special cases we also use cookies provided by trusted
              third parties. The following section details which third party
              cookies you might encounter through this site.
            </p>

            <p className={s.text}>
              This site uses Google Analytics which is one of the most
              widespread and trusted analytics solution on the web for helping
              us to understand how you use the site and ways that we can improve
              your experience. These cookies may track things such as how long
              you spend on the site and the pages that you visit so we can
              continue to produce engaging content.
            </p>

            <p className={s.text}>
              For more information on Google Analytics cookies, see the official
              Google Analytics page.
            </p>

            <p className={s.text}>
              Third party analytics are used to track and measure usage of this
              site so that we can continue to produce engaging content. These
              cookies may track things such as how long you spend on the site or
              pages you visit which helps us to understand how we can improve
              the site for you.
            </p>

            <p className={s.text}>
              From time to time we test new features and make subtle changes to
              the way that the site is delivered. When we are still testing new
              features these cookies may be used to ensure that you receive a
              consistent experience whilst on the site whilst ensuring we
              understand which optimisations our users appreciate the most.
            </p>

            <h3 className={s.subtitle}>More Information</h3>

            <p className={s.text}>
              Hopefully that has clarified things for you and as was previously
              mentioned if there is something that you aren't sure whether you
              need or not it's usually safer to leave cookies enabled in case it
              does interact with one of the features you use on our site.
            </p>

            <p className={s.text}>
              However if you are still looking for more information then you can
              contact us through one of our preferred contact methods:
            </p>

            <p className={s.text}>
              By visiting this link:
              https://qa.focalpointprocurement.com/cookie-policy
            </p>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default CookiePage;
