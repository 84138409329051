import React, {useState} from "react";
import styles from "./Subtasks.module.scss";
import PropTypes from "prop-types";
import DropListTrigger from "../../../../elements/DropListTrigger/DropListTrigger";
import Popup from "../../../../popups/Popup/Popup";
import SubtaskForm from "../../../../forms/SubtaskForm/SubtaskForm";
import ResponsibleUser from "../ResponsibleUser/ResponsibleUser";

const SingleSubtask = ({data, deleteSubtask, editSubtask, selectTask}) => {
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);
  const [popUp, setPopUp] = useState(null);

  const deleteSubtaskAction = () => {
    deleteSubtask(data.id);
    setPopUp(null);
  };

  const editSubtaskAction = values => {
    editSubtask(values, data.id);
    setPopUp(null);
  };

  const subtaskParams = {
    ...data,
    responsible_user: data?.responsible_user ? {
      value: data.responsible_user.id,
      label: data.responsible_user.name
    } : null
  };

  return (
    <React.Fragment>
      <div
        className={styles.subtask}
        key={data.id}
        onClick={() => selectTask(data.id)}
      >
        <div>{data.name}</div>
        <ResponsibleUser user={data.responsible_user}/>
        <DropListTrigger
          data={[
            {
              title: "Edit",
              onClick: () => setPopUp("edit")
            },
            {
              title: "Delete",
              onClick: () => setPopUp("delete")
            }
          ]}
          visibleMenu={visibleDropListMenu}
          setVisibleMenu={setVisibleDropListMenu}
        />
      </div>
      {popUp === "delete" && (
        <Popup
          title="Delete a subtask"
          text="Are you sure you want to delete this subtask?"
          onClose={() => setPopUp(null)}
          onClick={deleteSubtaskAction}
          isDelete
          dialog
        />
      )}
      {
        popUp === "edit" && (
          <Popup title="Edit subtask" onClose={() => setPopUp(null)}>
            <SubtaskForm
              onClose={() => setPopUp(null)}
              defaultValues={subtaskParams}
              onSubmit={values => {
                editSubtaskAction(values);
                setPopUp(null);
              }}
            />
          </Popup>
        )
      }
    </React.Fragment>
  );
};

export default SingleSubtask;

SingleSubtask.propTypes = {
  data: PropTypes.any,
  deleteSubtask: PropTypes.func,
  editSubtask: PropTypes.func,
  selectTask: PropTypes.func
};