import React from 'react';
import styles from "./CurrentVersion.module.scss";

const CurrentVersion = () => {
  return (
    <div className={styles.version}>
      Version {process.env.REACT_APP_VERSION}
    </div>
  );
};

export default CurrentVersion;