import {
  GET_TYPES,
  CHANGE_POPUP,
  ADD_TYPE,
  DELETE_TYPE,
  EDIT_TYPE,
  CLOSE_ALERT
} from "./types"

export const initialState = {
  types: [],
  popup: null,
  alert: {
    type: "",
    message: ""
  },
  loading: true
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TYPES:
      return {
        ...state,
        types: payload || state.types,
        loading: !payload
      }
    case CHANGE_POPUP:
      return {
        ...state,
        popup: payload
      }
    case ADD_TYPE:
      return {
        ...state,
        types: payload.type ? [...state.types, payload.type] : state.types,
        alert: payload.alert,
        popup: payload.type ? null : "add"
      }
    case EDIT_TYPE:
      return {
        ...state,
        types: payload.type
          ? state.types.map(e => (e.id === payload.type.id ? payload.type : e))
          : state.types,
        alert: payload.alert,
        popup: payload.type ? null : "edit"
      }
    case DELETE_TYPE:
      return {
        ...state,
        types: payload.id
          ? state.types.filter(e => e.id !== payload.id)
          : state.types,
        alert: payload.alert,
        popup: payload.id ? null : "delete"
      }
    case CLOSE_ALERT:
      return {
        ...state,
        alert: {
          type: "",
          message: ""
        }
      }
  }
}
