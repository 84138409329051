import React from "react";
import PropTypes from "prop-types";
import CustomLink from "components/elements/CustomLink/CustomLink";
import { ReactComponent as Linkedin } from "../../../../../assets/icons/AboutUs/Linkedin.svg";
import { ReactComponent as Twitter } from "../../../../../assets/icons/AboutUs/Twitter.svg";
import s from "./TeamCard.module.scss";
import {useTranslate} from "../../../../../../../hooks/useTranslate";

const TeamCard = ({ img, name, office, text, linkedin, twitter }) => {
  const [translateLabelKey, translateLabelValue] = useTranslate(text);
  const [translateLabelKeyOffice, translateLabelValueOffice] = useTranslate(office);

  return (
    <div className={s.card}>
      <img src={img} alt="teamImg" className={s.img} />
      <h3 className={s.name}>{name}</h3>
      <h4 className={s.office} data-translate-key={translateLabelKeyOffice}>{translateLabelValueOffice}</h4>
      <p className={s.text} data-translate-key={translateLabelKey}>{translateLabelValue}</p>
      <div className={s.socials}>
        <CustomLink type="link" className={s.link} path={linkedin} >
          <Linkedin />
        </CustomLink>

        <CustomLink type="link" className={s.link} path={twitter} >
          <Twitter />
        </CustomLink>
      </div>
    </div>
  );
};

TeamCard.propTypes = {
  img: PropTypes.any,
  name: PropTypes.string,
  office: PropTypes.string,
  text: PropTypes.string,
  linkedin: PropTypes.string,
  twitter: PropTypes.string
};

TeamCard.defaultProps = {
  img: "",
  name: "",
  office: "",
  text: "",
  linkedin: "/",
  twitter: "/"
};

export default TeamCard;
