export const stagesStyles = {
  planned: { cardStyle: { border: "1px solid #D6D6D6" }, status: "planned" },
  active: {
    cardStyle: { background: "#FFFAEF", border: "1px solid #FAEACC" },
    status: "active"
  },
  completed: {
    cardStyle: { background: "#F1FFEF", border: "1px solid #D3EDCA" },
    status: "completed"
  }
}
