import React, { useState, useEffect } from "react"
import classNames from "classnames"
import s from "./ProjectCompletionPopup.module.scss"
import Popup from "components/popups/Popup/Popup"
import Button from "components/elements/Button/Button"
import DatePicker from "components/elements/DatePicker/DatePicker"
import { formatDate } from "helpers/dateFormat"
import services from "services"
import SelectCheckDropdown from "components/elements/SelectCheckDropdown/SelectCheckDropdown"
const ProjectCompletionPopup = ({ onClose, onSubmit, project, stages }) => {
  const [actualEndDate, setActualEndDate] = useState(
    project?.actual_end_date ? new Date(project.actual_end_date) : ""
  )
  const [allSuppliers, setAllSuppliers] = useState([])
  const [searchedSuppliers, setSearchedSuppliers] = useState([])
  const [checkedSuppliers, setCheckedSuppliers] = useState([])
  const [checkedSupplierNames, setCheckedSupplierNames] = useState({})
  const formatSuppliersToObject = arr => {
    const result = {}
    arr.map(({ label }) => {
      result[label] = true
    })
    return result
  }
  const formatSuppliers = arr =>
    arr?.map(({ id, name, project_supplier_status }) => ({
      id,
      label: name,
      value: name,
      subItems: null,
      checked: project_supplier_status === "selected"
    }))
  useEffect(() => {
    project?.id &&
      services.supplierServices.getProjectSuppliers(project.id).then(res => {
        if (res?.data) {
          setAllSuppliers(formatSuppliers(res.data.suppliers || []))
          setSearchedSuppliers(formatSuppliers(res.data.suppliers || []))
          setCheckedSuppliers(
            formatSuppliers(
              res.data.suppliers?.filter(
                e => e.project_supplier_status === "selected"
              )
            )
          )
          setCheckedSupplierNames(
            formatSuppliersToObject(
              formatSuppliers(
                res.data.suppliers?.filter(
                  e => e.project_supplier_status === "selected"
                ) || []
              )
            )
          )
        }
      })
  }, [])
  const filterResult = obj => {
    const result = { ...obj }
    if (result["selected_supplier_ids"] === null) {
      delete result["selected_supplier_ids"]
    }
    if (result["unselected_supplier_ids"] === null) {
      delete result["unselected_supplier_ids"]
    }
    return result
  }
  return (
    <Popup
      onClose={onClose}
      title='Project completion'
      text='Please, complete the following fields to complete this project'
    >
      <div className='dateBlock'>
        <DatePicker
          label='Target End date'
          selected={new Date(project?.end_date)}
          selectsStart
          startDate={new Date(project?.end_date)}
          placeholderText='Choose end date'
          wrapperClassName='wrapperdatePicker'
          className='customInput'
          todayButton='Go to today'
          showYearDropdown
          dateFormatCalendar='MMMM'
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          disabled={true}
        />
        <DatePicker
          label='Actual End date'
          selected={actualEndDate}
          onChange={date => setActualEndDate(date)}
          selectsEnd
          startDate={new Date(project?.end_date)}
          placeholderText='Choose actual end date'
          wrapperClassName='wrapperdatePicker'
          className='customInput'
          todayButton='Go to today'
          showYearDropdown
          dateFormatCalendar='MMMM'
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={!actualEndDate}
        />
      </div>
      <SelectCheckDropdown
        label='Suppliers'
        value={checkedSupplierNames}
        onChange={(e, item) => {
          setCheckedSupplierNames({ ...checkedSupplierNames, ...e })
          if (Object.values(e)[Object.values(e).length - 1]) {
            setCheckedSuppliers([...checkedSuppliers, item])
          } else {
            setCheckedSuppliers(checkedSuppliers.filter(e => e.id !== item.id))
          }
        }}
        options={searchedSuppliers}
        placeholder='Start typing supplier name'
        onSearch={data => {
          setCheckedSupplierNames(
            formatSuppliersToObject([...checkedSuppliers, ...data])
          )
          setCheckedSuppliers([
            ...checkedSuppliers.filter(
              e => !Boolean(data.filter(el => el.id !== e.id).length)
            ),
            ...data
          ])
        }}
        emptyMessage='suppliers not found'
        withEndComma={true}
        className={s.suppliers}
        valueFormat='all'
      />
      <div className={classNames("popupBtnsWrap", s.btns)}>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          styled='primary'
          type='submit'
          className='popupBtn'
          disabled={!actualEndDate}
          onClick={() =>
            onSubmit(
              project.id,
              filterResult({
                stage_id: stages[stages.length - 1]?.id,
                actual_end_date: formatDate(actualEndDate) || null,
                selected_supplier_ids: checkedSuppliers.filter(e => e.checked)
                  .length
                  ? checkedSuppliers
                      .filter(e => e.checked)
                      .map(e => e.id)
                      .join(",")
                  : null,
                unselected_supplier_ids:
                  allSuppliers
                    .filter(
                      e =>
                        !Boolean(
                          checkedSuppliers.filter(sup => sup.id === e.id)?.[0]
                            ?.checked
                        )
                    )
                    .map(e => e.id)
                    .join(",") || null
              })
            )
          }
        >
          Complete
        </Button>
      </div>
    </Popup>
  )
}
export default ProjectCompletionPopup
