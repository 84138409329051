import React, {useState, useRef, useEffect, useCallback} from "react";
import classNames from "classnames";
import {debounce} from "lodash";
import s from "./FilterBarDropdownUsers.module.scss";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";
import {ReactComponent as Clear} from "assets/icons/clear.svg";
import styles from "components/elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown.module.scss";
import useOutsideClicker from "hooks/useOutsideClicker";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import Checkbox from "components/elements/Checkbox/Checkbox";
import services from "services";
import {useTranslate} from "../../../../../hooks/useTranslate";
import {useTranslation} from "react-i18next";

const FilterBarDropdownUsers = (
  {
    name,
    handleChange,
    clearAllFilters,
    setClearAllFilters,
    setFilterValue,
  }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [message, setMessage] = useState("");
  const {t} = useTranslation();
  const [translateMessageKey, translateMessageValue] = useTranslate(message);
  const [translateNameKey, translateNameValue] = useTranslate(name);

  const dropdownRef = useRef(null);
  useOutsideClicker(dropdownRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setMessage("Start typing user name to select one");
    if (!isOpen) {
      setSearchVal("");
      if (checkedUsers.length) setUsers(checkedUsers);
      else setUsers([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (clearAllFilters) {
      setCheckedUsers([]);
      setFilterValue(name, false);
      setClearAllFilters(false);
    }
  }, [clearAllFilters]);

  const onClear = () => {
    setCheckedUsers([]);
    setFilterValue(name, false);
    handleChange([]);
  };

  const handleSearch = useCallback(
    debounce((term) => {
      services.userServices.usersAutocomplete({query: term}).then((res) => {
        setUsers(res.data);
        res.data.length
          ? setMessage("")
          : setMessage("No work item results found");
      });
    }, 300),
    []
  );

  const isCheckedUser = (id) => {
    return Boolean(checkedUsers.filter((e) => e.id === id).length);
  };

  const handleCheckUser = (e, item) => {
    const checked = e.target.checked;
    if (!checked) {
      const newcheckedUsers = checkedUsers.filter((e) => e.id !== item.id);
      setCheckedUsers(newcheckedUsers);
      handleChange(newcheckedUsers);
      setFilterValue(name, newcheckedUsers.length > 0);
    } else {
      const newcheckedUsers = [...checkedUsers, item];
      setCheckedUsers(newcheckedUsers);
      handleChange(newcheckedUsers);
      setFilterValue(name, newcheckedUsers.length > 0);
    }
  };

  return (
    <div
      className={classNames(styles.filterDropdownWrapper, {
        [styles.filterListOpened]: isOpen,
      })}
      ref={dropdownRef}
    >
      <div
        className={styles.nameBlock}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {checkedUsers[0] ? (
          <p className={styles.selectedOption}>
            {`${checkedUsers[0].name}`}{" "}
            {checkedUsers.length > 1 && `(+${checkedUsers.length - 1})`}
          </p>
        ) : (
          <p data-translate-key={translateNameKey}>{translateNameValue}</p>
        )}
        <span
          className={classNames(styles.arrowIcon, {
            [styles.listOpen]: isOpen,
          })}
        >
          <Arrow/>
        </span>
      </div>
      {isOpen && (
        <div className={styles.openedList}>
          <div className={s.usersWrap}>
            <Input
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
                handleSearch(e.target.value);
              }}
              placeholder="Search user"
              className={s.searchField}
            />
            {users.length > 0 && (
              <ul className={s.users}>
                {users.map((item, index) => (
                  <li className={s.user} key={index}>
                    <Checkbox
                      value={isCheckedUser(item.id)}
                      checked={isCheckedUser(item.id)}
                      onChange={(e) => handleCheckUser(e, item)}
                      label={item.name}
                    />
                  </li>
                ))}
              </ul>
            )}
            {message.length > 0 && !users.length && (
              <span className={s.message} data-translate-key={translateMessageKey}>{translateMessageValue}</span>
            )}
          </div>
          <div className={s.clearBlock}>
            <Button
              styled="textButton"
              className={styles.clearBtn}
              onClick={onClear}
            >
              <Clear/>
              <span data-translate-key="clear">{t("clear")}</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default FilterBarDropdownUsers;
