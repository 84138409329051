import React, { useState, useMemo, memo } from "react";
import s from "./ApprovalStepsWidget.module.scss";
import ActivityItem from "./components/ActivityItem/ActivityItem";
import helpers from "./helpers";
import { drawAllLines } from "./draw";
import WidgetEmptyState from "components/elements/WidgetEmptyState/WidgetEmptyState";

const ApprovalStepsWidget = memo(({ steps }) => {
  const { config, width, height, getRowsPos, formatStepsData } = helpers;
  const [data, setData] = useState({});
  const [dataPos, setDataPos] = useState([]);
  const colsLength = Object.keys(data).length;

  useMemo(() => {
    setData(formatStepsData(steps));
    setDataPos(getRowsPos(formatStepsData(steps)));
  }, [steps.length]);
  return (
    <div
      className={s.wrap}
      style={{ height: colsLength > 0 ? `475px` : "auto" }}
      data-attr="project-approval-wrap"
    >
      {colsLength > 0 && (
        <div
          className={s.innerWrap}
          style={{
            width: `${width(data)}px`,
            height: colsLength > 0 ? `${height(data)}px` : "auto",
          }}
        >
          {Object.keys(data).map((_, colIdx) => (
            <span
              key={colIdx}
              className={s.label}
              style={{
                left: `${config.colWidth * colIdx +
                  config.colMargin * colIdx +
                  config.innerMarginLeft}px`,
              }}
            >{`Step ${colIdx + 1}`}</span>
          ))}
          {dataPos.map(({ top, left, index, ...rest }, idx) => (
            <ActivityItem data={rest} left={left} top={top} key={idx} />
          ))}
          <svg width={width(data)} height={height(data)} fill="none">
            {drawAllLines(data, dataPos).map(({ x1, y1, x2, y2 }, index) => (
              <line
                key={index}
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke="#607D8B"
                strokeDasharray="2 2"
              />
            ))}
          </svg>
        </div>
      )}
      {!colsLength && (
        <div className={s.emptyWrap}>
          <WidgetEmptyState description="After adding the first step, we will have information for this widget" />
        </div>
      )}
    </div>
  );
});
export default ApprovalStepsWidget;
