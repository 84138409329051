import React, {useContext} from "react";
import FormHeader from "./FormHeader/FormHeader";
import styles from "./ApprovalForm.module.scss";
import {useFormik} from "formik";
import GeneralForm from "./GeneralForm/GeneralForm";
import ApproverForm from "./ApproverForm/ApproverForm";
import {getInitialValues} from "./helpers/getInitialValues";
import {validationSchema} from "./helpers/validationSchema";
import {validator} from "./helpers/validator";
import {useDidUpdate} from "../../../hooks/useDidUpdate";
import PriorityForm from "./PriorityForm/PriorityForm";
import {conditionTypes} from "../../../helpers/approvalsDefaultData";
import ConditionForm from "./ConditionForm/ConditionForm";
import {addCondition} from "./helpers/addCondition";
import {addSingleCondition} from "./helpers/addSingleCondition";
import {deleteCondition} from "./helpers/deleteCondition";
import {changeCondition} from "./helpers/changeCondition";
import {changeSingleCondition} from "./helpers/changeSingleCondition";
import {deleteSingleCondition} from "./helpers/deleteSingleCondition";
import {useParams} from "react-router-dom";
import {ModeContext} from "../../../contexts/ModeContext/ModeContext";

const ApprovalForm = ({onSubmit, initialValues}) => {
  const params = useParams();
  const isEdit = Boolean(initialValues?.id);
  const {enableViewMode} = useContext(ModeContext);

  const formik = useFormik({
    initialValues: getInitialValues(initialValues),
    validationSchema: validationSchema(!Boolean(params.milestoneId)),
    validate: values => validator(values),
    onSubmit: values => onSubmit(values),
  });

  const {handleSubmit, values, setFieldValue, errors, touched, resetForm} = formik;

  const formProps = {values, setFieldValue, errors, touched};

  useDidUpdate(() => {
    setFieldValue("approval_group", {});
    setFieldValue("what_approved", {});
    setFieldValue("approval_type", {});
  }, [values.approver]);

  useDidUpdate(() => {
    if (values.condition_type.value === conditionTypes[0].value) addConditionAction();
    else setFieldValue("conditions", []);
  }, [values.condition_type]);

  const addConditionAction = () =>
    setFieldValue("conditions", addCondition(values.conditions));

  const addSingleConditionAction = conditionId =>
    setFieldValue("conditions", addSingleCondition(values.conditions, conditionId));

  const deleteConditionAction = conditionId =>
    setFieldValue("conditions", deleteCondition(values.conditions, conditionId));

  const changeConditionAction = (conditionId, value) =>
    setFieldValue("conditions", changeCondition(values.conditions, conditionId, value));

  const changeSingleConditionAction = (conditionId, singleConditionId, value, type) =>
    setFieldValue("conditions", changeSingleCondition(
      values.conditions, conditionId, singleConditionId, type, value
    ));

  const deleteSingleConditionAction = (conditionId, singleConditionId) =>
    setFieldValue("conditions", deleteSingleCondition(values.conditions, conditionId, singleConditionId));

  return (
    <form className={styles.approvalForm} onSubmit={handleSubmit} autoComplete="off">
      <FormHeader isEdit={isEdit} reset={() => {
        resetForm();
        enableViewMode();
      }}/>
      <GeneralForm {...formProps}/>
      {values.condition_type.value === conditionTypes[0].value && (
        <ConditionForm
          {...formProps}
          conditions={values.conditions}
          addCondition={addConditionAction}
          addSingleCondition={addSingleConditionAction}
          deleteCondition={deleteConditionAction}
          changeCondition={changeConditionAction}
          changeSingleCondition={changeSingleConditionAction}
          deleteSingleCondition={deleteSingleConditionAction}
        />
      )}
      <ApproverForm {...formProps}/>
      <PriorityForm {...formProps}/>
    </form>
  );
};

export default ApprovalForm;