import React, { useState, useEffect } from "react";
import ReportingTagContractsTable from "components/tables/ReportingTagContractsTable/ReportingTagContractsTable";
import services from "services";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import qs from "query-string";
import { useParams, useHistory, useLocation } from "react-router-dom";
import TableLoading from "components/loadingStates/Table/Table";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Contracts = ({ className }) => {
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const query = qs.parse(history.location.search);

  useEffect(() => {
    services.reportsServices
      .tagReportContracts(params.id, query)
      .then((res) => {
        setData(res.data.contracts);
        setDataCount(res.data.contracts_count);
        setLoading(false);
      });
  }, [location.search]);

  const onPageChange = (page) => {
    const query = qs.parse(history.location.search);
    history.push({ search: qs.stringify({ ...query, page }) });
  };
  return (
    <div className={className}>
      <h3
        className="subtitle"
        data-translate-key={getTranslateKey("Contracts")}
      >
        {t(getTranslateKey("Contracts"))}
      </h3>
      {!loading && data.length > 0 && (
        <ReportingTagContractsTable
          data={data}
          dataCount={dataCount}
          perPage={30}
          currentPage={query?.page || 1}
          onPageChange={onPageChange}
        />
      )}
      {loading && <TableLoading length={7} />}
      {!loading && !data.length && (
        <EmptyBlock title="You don’t have any contracts yet." message />
      )}
    </div>
  );
};

export default Contracts;
