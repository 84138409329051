import React, { useState } from "react"
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout"
import MilestoneContainer from "components/containers/MilestoneContainer/MilestoneContainer"
import { useProjectsPath } from "hooks/useProjectsPath"
import {useSelector} from "react-redux";
import {userRoles} from "../../../helpers/userPermissions/userRoles";

const MilestonePage = () => {
  const projectsPath = useProjectsPath();
  const user = useSelector(state => state?.user);
  const isBusinessUser = user.roles.includes(userRoles.business_user);
  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);

  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      name: "Projects",
      path: projectsPath,
      disabled: (isBusinessUser || isCategoryOwner),
    }
  ])
  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <MilestoneContainer setBreadcrumbs={setBreadcrumbs} />
    </DefaultLayout>
  )
}

export default MilestonePage
