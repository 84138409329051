import React, {Fragment, useReducer, useEffect, useState, useRef, useLayoutEffect} from "react";
import {useParams, useHistory, useLocation} from "react-router-dom";
import CategoriesContractsTable from "components/tables/CategoriesContractsTable/CategoriesContractsTable";
import {CircularProgress} from "@material-ui/core";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import querystring from "query-string";
import {useSelector} from "react-redux";
import Popup from "components/popups/Popup/Popup";
import AddProjectContractForm from "components/forms/AddProjectContractForm/AddProjectContractForm";
import services from "services";
import {
  setContracts,
  setLoading,
  addNewContract,
  closePopup,
  openPopup,
  setSelectedContract,
  setAlert,
  deleteContract,
} from "./helpers/actions";
import {initialState, reducer} from "./helpers/reducer";
import {popupTypes} from "./helpers/types";
import TableLoading from "components/loadingStates/Table/Table";
import CategoryContractsFilter from "components/filters/CategoryContractsFilter/CategoryContractsFilter";
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar";
import styles from "./Contracts.module.scss";

const Contracts = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchContracts, setSearchContracts] = useState([]);
  const {
    contracts,
    contracts_count,
    loading,
    popup,
    current_contract,
    alert,
  } = state;
  const {currentCategoryID} = useSelector((state) => state.categories);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const query = querystring.parse(history.location.search);
  const firstRender = useRef(true);

  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  });

  useEffect(() => {
    if (firstRender.current) dispatch(setLoading(true));
    setContracts(dispatch, params.id || currentCategoryID, query);
  }, [location.search, params.id]);

  const searchHandler = (term) => {
    services.contractsServices
      .getContractsAutocomplete({query: term})
      .then((res) => setSearchContracts(res.data));
  };
  const onDeleteTag = async (tagId, contractId) =>
    await services.tagsServices.deleteContractTag(contractId, tagId);

  const onAssignTag = async (tagId, contractId) =>
    await services.tagsServices.assignContractTag(contractId, tagId);
  return (
    <Fragment>
      {loading ? (
        <FilterBarLoading className={styles.filter}/>
      ) : (
        <CategoryContractsFilter className={styles.filter}/>
      )}
      {loading && <TableLoading length={6}/>}
      {contracts.length > 0 && !loading && (
        <CategoriesContractsTable
          data={contracts}
          totalCount={contracts_count}
          perPage={20}
          onAdd={() => dispatch(openPopup(popupTypes.add))}
          onDelete={(item) => {
            dispatch(setSelectedContract(item));
            dispatch(openPopup(popupTypes.delete));
          }}
          onDeleteTag={onDeleteTag}
          onAssignTag={onAssignTag}
        />
      )}
      {!contracts.length && !loading && (
        <EmptyBlock
          subtitle="There are no contracts in this category yet. You can add a new one"
          buttonTitle="Add contract"
          onClick={() => dispatch(openPopup(popupTypes.add))}
        />
      )}
      {loading && (
        <div className="loaderWrap justifyCenter alignCenter">
          <CircularProgress/>
        </div>
      )}
      {popup === popupTypes.add && (
        <Popup
          onClose={() => dispatch(closePopup())}
          title="Add contract"
          text="Choose a contract from existing ones"
        >
          <AddProjectContractForm
            contracts={searchContracts}
            currentContracts={contracts}
            addHandler={(values) => addNewContract(dispatch, values)}
            onClose={() => dispatch(closePopup())}
            onSearch={searchHandler}
          />
        </Popup>
      )}
      {popup === popupTypes.delete && (
        <Popup
          title="Delete contract"
          text={`Are you sure you want to delete this contract?`}
          onClose={() => dispatch(closePopup())}
          onClick={() => deleteContract(dispatch, params.id, current_contract)}
          dialog
          isDelete
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => dispatch(setAlert({type: "", message: ""}))}
        />
      )}
    </Fragment>
  );
};
export default Contracts;
