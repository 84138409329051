import React from "react";
import FinancialBenefitsEditContainer
  from "../../containers/FinancialBenefitsEditContainer/FinancialBenefitsEditContainer";
import {useProjectsPath} from "../../../hooks/useProjectsPath";
import {useParams} from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

const FinancialBenefitsEditPage = () => {
  const projectsPath = useProjectsPath();
  const {projectId, financialBenefitId} = useParams();

  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: projectsPath,
          name: "Projects"
        },
        {
          name: "Project details",
          path: `/projects/${projectId}`
        },
        {
          name: "Project results",
          path: `/projects/${projectId}?tab=Project%20Results`
        },
        {
          name: "Benefit details",
          path: `/projects/${projectId}/financial-benefits/${financialBenefitId}`
        },
        {
          name: "Edit Benefit",
        }
      ]}
    >
      <FinancialBenefitsEditContainer/>
    </DefaultLayout>
  );
};

export default FinancialBenefitsEditPage;