import React from "react";
import s from "./Label.module.scss";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const Label = (
  {
    labelClass,
    label,
    withoutTranslate = false,
    optional
  }
) => {
  const {t} = useTranslation();

  if (optional) {
    return (
      <div className={s.labelOptional}>
           <span data-translate-key={getTranslateKey(label)}>
            {t(getTranslateKey(label))}
           </span>
        {" "}
        <span data-translate-key={getTranslateKey("(optional)")}>
            {t(getTranslateKey("(optional)"))}
          </span>
      </div>
    );
  }

  return (
    <p
      className={classNames(labelClass, s.label)}
      data-translate-key={getTranslateKey(label)}
    >
      {!withoutTranslate && t(getTranslateKey(label))}
    </p>
  );

};

export default Label;


Label.propTypes = {
  labelClass: PropTypes.string,
  label: PropTypes.string,
  withoutTranslate: PropTypes.bool,
  optional: PropTypes.bool
};