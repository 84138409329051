import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import querystring from "query-string"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import TableLoading from "components/loadingStates/Table/Table"
import SystemReportsUsersTable from "components/tables/SystemReportsUsersTable/SystemReportsUsersTable"
import services from "services"
import SystemReportsUsersFilter from "components/filters/SystemReportsUsersFilter/SystemReportsUsersFilter"
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar"
import {
  getAllIds,
  formatFilters
} from "components/elements/FilterBar/helpers/helpers"
import s from "./SystemReportsUsers.module.scss"
import { formatDepartmentsFilter } from "components/filters/helpers/formatDepartmentsFilter"

const SystemReportsUsers = () => {
  const history = useHistory()

  const [users, setUsers] = useState({
    users: [],
    users_count: 0
  })
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState([])

  const query = querystring.parse(history.location.search)

  useEffect(() => {
    services.reportServices.getSystemReportsUsers(query).then(res => {
      setUsers(res.data)
      setLoading(false)
    })
  }, [history.location])
  useEffect(() => {
    services.userServices.getUsersFilters().then(res => {
      setFilters(
        formatFilters({
          divisions: res?.data?.divisions,
          departments: res?.data?.departments
            ? formatDepartmentsFilter(res.data.departments)
            : []
        })
      )
    })
  }, [])
  return (
    <div>
      {!Array.isArray(filters) && Object.keys(filters).length ? (
        <SystemReportsUsersFilter filters={filters} className={s.filterbar} />
      ) : (
        <FilterBarLoading className={s.filterbar} />
      )}
      {loading && <TableLoading length={5} />}
      {!loading && Boolean(users.users_count) && (
        <SystemReportsUsersTable data={users} />
      )}
      {!loading && !Boolean(users.users_count) && (
        <EmptyBlock withBtn={false}>
          <div>You don’t have users.</div>
        </EmptyBlock>
      )}
    </div>
  )
}

export default SystemReportsUsers
