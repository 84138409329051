export const getConditionValues = value => {
  let separator = "===";
  if (value.indexOf("!==") > -1) separator = "!==";
  if (value.indexOf(">") > -1) separator = ">";
  if (value.indexOf("<") > -1) separator = "<";

  let parts = value.split(separator);
  return {
    fieldValue: parts[0].substring(1, parts[0].length - 1),
    generalValue: parts[1]?.substring(1, parts[1].length - 1) || "",
    generalCondition: separator,
  };
};