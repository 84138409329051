import React, {useState, useEffect} from "react";
import querystring from "query-string";
import {useHistory} from "react-router-dom";
import MainTable from "components/tables/MainTable/MainTable";
import sort from "helpers/sortHelper";

const SystemReportsDepartmentsTable = ({data, perPage = 30}) => {
  const history = useHistory();
  const query = querystring.parse(history.location.search);
  const [departments, setDepartments] = useState(data.departments);
  useEffect(() => {
    setDepartments(data.departments);
  }, [data.departments]);
  const onPageChange = page => {
    history.push({search: querystring.stringify({...query, page})});
  };

  const onSort = ({sort_by, direction}) => {
    if (sort_by === "name") {
      setDepartments(
        sort.sortString(departments, {field: sort_by, direction})
      );
    } else if (sort_by === "users_count" || sort_by === "projects_count") {
      setDepartments(
        sort.sortNumber(departments, {field: sort_by, direction})
      );
    }
  };

  return (
    <MainTable
      stickyHeader={true}
      data={departments}
      perPage={perPage}
      totalCount={data.departments_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={query.page || 1}
      fullHeight={true}
      subKey="departments"
      subTypes={[
        {
          title: "Department",
          value: ({name}) => name,
          onClick: id => history.push(`/system-reports/departments/${id}`),
          sticky: true
        },
        {
          title: "Users",
          value: ({users_count}) => users_count || "-",
          alignment: "right"
        },
        {
          title: "Projects",
          value: ({projects_count}) => projects_count || "-",
        },
      ]}
      dataTypes={[
        {
          title: "Department",
          value: ({name}) => name,
          sortable: true,
          onClick: id => history.push(`/system-reports/departments/${id}`),
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          sticky: true
        },

        {
          title: "Users",
          value: ({users_count}) => users_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "users_count", direction});
          }
        },
        {
          title: "Projects",
          value: ({projects_count}) => projects_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "projects_count", direction});
          }
        }
      ]}
    />
  );
};
export default SystemReportsDepartmentsTable;
