import React from "react"
import { ReactComponent as DOCFile } from "assets/icons/doc-file.svg"
import { ReactComponent as DOCXFile } from "assets/icons/docx-file.svg"
import { ReactComponent as PDFFile } from "assets/icons/pdf-file.svg"
import { ReactComponent as PPTXFile } from "assets/icons/pptx-file.svg"
import { ReactComponent as XLSXFile } from "assets/icons/xlsx-file.svg"
export const getFileIconByType = type => {
  switch (type) {
    case "application/msword":
    case ".doc":
      return <DOCFile />
    case "application/pdf":
    case ".pdf":
      return <PDFFile />
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case ".pptx":
      return <PPTXFile />
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case ".xlsx":
      return <XLSXFile />
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case ".docx":
      return <DOCXFile />
    default:
      return null
  }
}
