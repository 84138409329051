import React, {useRef} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import s from "./DatePicker.module.scss";
import {ReactComponent as Calendar} from "assets/icons/calendar.svg";
import {useTranslate} from "../../../hooks/useTranslate";

const DatePicker = (
  {
    label,
    calendarIcon = true,
    error,
    inputWrapClassName,
    withoutTranslate = false,
    ...props
  }
) => {

  const datePickerRef = useRef(null);
  const [translateKey, translateValue] = useTranslate(label);
  const [translateKeyError, translateValueError] = useTranslate(error);
  const [translatePlaceholderKey, translatePlaceholderValue] = useTranslate(props.placeholderText);

  return (
    <div className={s.date}>
      <div className={classNames(s.inputWrap, inputWrapClassName)}>
        <p
          className={s.inputLabel}
          data-translate-key={!withoutTranslate && translateKey}
        >
          {withoutTranslate ? label : translateValue}
        </p>
        <div className={s.container}>
          <ReactDatePicker
            {...props}
            data-translate-key={translatePlaceholderKey}
            placeholderText={translatePlaceholderValue}
            ref={datePickerRef}
          />
          {calendarIcon && <Calendar onClick={() => datePickerRef.current.setOpen(true)}/>}
        </div>
        {error && <span className={s.errorMessage} data-translate-key={translateKeyError}>{translateValueError}</span>}
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string
};

export default DatePicker;
