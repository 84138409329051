import React from 'react'
import Popup from 'components/popups/Popup/Popup'
import EditCompanyGoalForm from 'components/forms/EditCompanyGoalForm/EditCompanyGoalForm'
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const EditPopup = ({onSubmit, onClose, defaultValues, source, name}) => {
  const {t} = useTranslation();
  return <Popup onClose={onClose} title={`Edit ${source} goal`} text={
    <>
     <span data-translate-key={getTranslateKey("Please, complete the following fields to edit goals for")}>
       {t(getTranslateKey("Please, complete the following fields to edit goals for"))}
       </span>
     {name}
    </>
  }
  >
    <EditCompanyGoalForm onSubmit={onSubmit} onClose={onClose} defaultValues={defaultValues}/>
  </Popup>
}

export default EditPopup