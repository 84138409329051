import services from "../../../../services";
import {CHANGE_MODE, LOAD_FIELD_DETAIL, LOAD_REQUEST_DETAIL, EDIT_FIELD} from "./reducer";

export const loadRequestDetail = (dispatch, id) => {
  services.requestTypeServices.getRequestTypesDetails(id).then(res => {
    if (res.data) {
      dispatch({
        type: LOAD_REQUEST_DETAIL,
        payload: res.data,
      });
    }
  });
};

export const loadStepFieldDetail = (dispatch, stepId, fieldId) => {
  services.requestTypeServices.getStepFieldDetails(stepId, fieldId).then(res => {
    if (res.data) {
      dispatch({
        type: LOAD_FIELD_DETAIL,
        payload: res.data,
      });
    }
  });
};

export const editStepField = (dispatch, stepId, fieldId, params) => {
  services.requestTypeServices.editStepField(stepId, fieldId, params).then(res => {
    if (res.data) {
      dispatch({
        type: EDIT_FIELD,
        payload: res.data,
      });
    }
  });
}

export const changeMode = (dispatch, mode) => {
  dispatch({
    type: CHANGE_MODE,
    payload: mode,
  });
}