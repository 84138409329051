import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import s from "./RequestFilterBar.module.scss"
import services from "../../../services";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";
import FilterBar from "../../elements/FilterBar/FilterBar";
import FilterBarDropdownDepartment
  from "../DashboardContainer/components/MainDashboard/Filter/FilterBarDropdownDepartment";

const RequestFilterBar = () => {
  const history = useHistory();
  const query = qs.parse(history.location.search);
  const [savingsTypes, setSavingsTypes] = useState([]);

  useEffect(() => {
    services.savingsTypeServices.getSavingsType().then(res => {
      if (res.data) {
        setSavingsTypes(res.data);
      }
    });
  }, []);

  const actualGroup = query?.savings_group_ids ? savingsTypes.find(s => s.id === query?.savings_group_ids) : null;

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  return (
    <FilterBar
      filterName="Filter savings"
      handleClearAll={() => history.push({})}
      className={s.filter}
    >
      <FilterBarDropdownDepartment
        name="Savings group"
        options={savingsTypes}
        handleChange={values => {
          handleChangeFilter("savings_group_ids", [values.saving_group]);
        }}
        defaultFilterValue={actualGroup}
      />
    </FilterBar>
  );
};

export default RequestFilterBar;
