import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import AddDivision from "./Popups/AddDivision/AddDivision";
import EditDivision from "./Popups/EditDivision/EditDivision";
import DeleteDivision
  from "./Popups/DeleteDivision/DeleteDivision";
import services from "services";
import AdminSettingsUsersDivisionsTable
  from "../../../tables/AdminSettingsUsersDivisionsTable/AdminSettingsUsersDivisionsTable";


const Divisions = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(null);
  const [currentDivision, setCurrentDivision] = useState(null);

  useEffect(() => {
    loadDivisions();
  }, [history.location]);

  const loadDivisions = () => {
    services.divisionServices.getDivisions().then(res => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  }

  const addHandler = params => {
    services.divisionServices.addDivision(params).then(res => {
      if (res.data) {
        setData([...data, res.data]);
        setCurrentDivision(null);
        setPopup(null);
      }
    });
  };

  const editHandler = params => {
    services.divisionServices.changeDivision(currentDivision.divisions_id, params).then(res => {
      if (res.data) {
        setData(data.map(division => {
          if (division.divisions_id === res.data.divisions_id) return res.data;
          return division;
        }));
        setCurrentDivision(null);
        setPopup(null);
      }
    });
  };

  const deleteHandler = ({divisions_id}) => {
    services.divisionServices.deleteDivision(currentDivision.divisions_id).then(res => {
      if (res.data) {
        loadDivisions();
        setCurrentDivision(null);
        setPopup(null);
      }
    });
  };

  const renderContent = () => {
    if (data.length) return (
      <AdminSettingsUsersDivisionsTable
        data={data}
        onAdd={() => setPopup("add")}
        onEdit={division => {
          setCurrentDivision(division);
          setPopup("edit");
        }}
        onDelete={division => {
          setCurrentDivision(division);
          setPopup("delete");
        }}
      />
    );
    return (
      <EmptyBlock
        subtitle="You don’t have any divisions yet."
        buttonTitle="New division"
        onClick={() => setPopup("add")}
      />
    )
  }

  return (
    <>
      {loading && <TableLoading length={2}/>}
      {!loading && renderContent()}
      {popup === "add" && (
        <AddDivision
          onClose={() => setPopup(null)}
          onSubmit={addHandler}
        />
      )}
      {popup === "edit" && (
        <EditDivision
          onClose={() => setPopup(null)}
          onSubmit={editHandler}
          defaultData={currentDivision}
        />
      )}
      {popup === "delete" && (
        <DeleteDivision
          onClose={() => setPopup(null)}
          onSubmit={deleteHandler}
        />
      )}
    </>
  );
};

export default Divisions;
