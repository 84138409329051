export default {
  goal: {
    annual_goal: 0,
    left_annual_goal: 0,
    quarter1: 0,
    quarter2: 0,
    quarter3: 0,
    quarter4: 0,
  },
  savings: [],
}