import React from "react"
import MainTable from "../MainTable/MainTable"
import { useHistory, useParams } from "react-router-dom"
import { formatDate } from "helpers/dateFormat"

const AdminSettingsProjectsStageTasksTable = ({
  data = [],
  onEdit,
  perPage,
  totalCount,
  onPageChange,
  currentPage,
  onAdd,
  onDelete
}) => {
  const history = useHistory()
  const params = useParams()
  return (
    <MainTable
      totalName='Tasks'
      data={data}
      dataTypes={[
        {
          title: "Task",
          value: ({ name }) => name
        },
        {
          title: "Updated",
          value: ({ updated_at }) => (updated_at ? formatDate(updated_at) : "-")
        }
      ]}
      button={{
        name: "New task",
        onClick: () => onAdd()
      }}
      options={item => {
        return [
          onEdit && {
            title: "Edit",
            onClick: () => {
              onEdit(item)
            }
          },
          onDelete && {
            title: "Delete",
            onClick: () => {
              onDelete(item)
            }
          }
        ]
      }}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  )
}
export default AdminSettingsProjectsStageTasksTable
