import React, {Fragment, useEffect, useReducer} from "react";
import {useParams, useHistory} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import querystring from "query-string";
import CategoriesProjectsTable from "components/tables/CategoriesProjectsTable/CategoriesProjectsTable";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import DeleteProjectPopup from "components/popups/DeleteProjectPopup/DeleteProjectPopup";
import {
  setProjects,
  deleteProject,
  setPopup,
  resetAlert,
  setCurrentProject,
} from "./helpers/actions";
import {initialState, reducer} from "./helpers/reducer";
import {popupTypes} from "./helpers/types";
import {useSelector} from "react-redux";
import TableLoading from "components/loadingStates/Table/Table";
import services from "services";
import CategoryProjectsFilter from "components/filters/CategoryProjectsFilter/CategoryProjectsFilter";
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar";
import styles from "./Projects.module.scss";
import {paths} from "../../../../../../../routes/paths";
import {userTypes} from "../../../../../../../helpers/userPermissions/userRoles";

const Projects = ({category}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {user_type} = useSelector(state => state.user);
  const {support} = userTypes;

  const {
    projects,
    projects_count,
    loading,
    popup,
    current_project,
    alert,
  } = state;

  const {currentCategoryID} = useSelector((state) => state.categories);
  const params = useParams();
  const history = useHistory();

  const query = querystring.parse(history.location.search) || {};

  const getProjects = () => {
    setProjects(dispatch, params.id || currentCategoryID, query);
  };

  useEffect(() => {
    getProjects();
  }, [history.location, params.id]);

  const onDeleteTag = async (tagId, projectId) =>
    await services.tagsServices.deleteProjectTag(projectId, tagId);

  const onAssignTag = async (tagId, projectId) =>
    await services.tagsServices.assignProjectTag(projectId, tagId);
  return (
    <Fragment>
      {loading ? (
        <FilterBarLoading className={styles.filter}/>
      ) : (
        <CategoryProjectsFilter className={styles.filter}/>
      )}
      {loading && <TableLoading length={8}/>}
      {projects.length > 0 && !loading && (
        <CategoriesProjectsTable
          data={projects}
          onDelete={(id) => {
            dispatch(setCurrentProject(id));
            dispatch(setPopup(popupTypes.delete));
          }}
          onAdd={() => history.push(paths.createProject, {
            category
          })}
          projectsCount={projects_count}
          perPage={20}
          onDeleteTag={onDeleteTag}
          onAssignTag={onAssignTag}
        />
      )}
      {!projects.length && !loading && (
        user_type !== support
          ? <EmptyBlock
            subtitle={`The category has no projects yet. You can add a new one`}
            buttonTitle="New project"
            onClick={() => history.push(paths.createProject, {
              category
            })}
          />
          : <EmptyBlock
            subtitle={`The category has no projects yet. You can add a new one`}
            withBtn={false}
          />
      )}
      {loading && (
        <div className="loaderWrap justifyCenter alignCenter">
          <CircularProgress/>
        </div>
      )}
      {popup === popupTypes.delete && (
        <DeleteProjectPopup
          project={projects.find((project) => project.id === current_project)}
          onClose={() => dispatch(setPopup(null))}
          onClick={() => deleteProject(dispatch, current_project)}
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          onClose={() => dispatch(resetAlert())}
        />
      )}
    </Fragment>
  );
};
export default Projects;
