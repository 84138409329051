export const hasOverflowingChildren = (element, orientation) => {
  const { width, height, x, y } = element.getBoundingClientRect();
  switch (orientation) {
    case "x":
      return width + x > window.innerWidth;
    case "y":
      return height + y > window.innerHeight;
    default:
      return null;
  }
};
