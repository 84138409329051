import React, {useEffect, useState} from "react";
import services from "../../../../../services";
import TaskStatusesTable from "./components/TaskStatusesTable/TaskStatusesTable";
import Popup from "../../../../popups/Popup/Popup";
import TaskStatusForm from "../../../../forms/TaskStatusForm/TaskStatusForm";
import s from "../../../AdminSettingsProjectsContainer/Projects/components/Stages/Stages.module.scss";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {replaceStages} from "../../../AdminSettingsProjectsContainer/Projects/components/Stages/helpers/actions";

const TaskStatuses = () => {
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [popUp, setPopUp] = useState(null);
  const [currentTaskStatus, setCurrentTaskStatus] = useState(null);

  useEffect(() => {
    services.taskStatusServices.getAdminTaskStatuses().then(res => {
      setTaskStatuses(res.data || []);
    });
  }, []);

  const createHandler = values => {
    services.taskStatusServices.addTaskStatus(values).then(res => {
      if (res.data) {
        setTaskStatuses([...taskStatuses, res.data]);
      }
      setPopUp(null);
    });
  }

  const editHandler = values => {
    services.taskStatusServices.editTaskStatus(currentTaskStatus.id, values).then(res => {
      if (res.data) {
        setTaskStatuses(taskStatuses.map(task => {
          if (task.id === res.data.id) return res.data;
          return task;
        }));
      }
      setCurrentTaskStatus(null);
      setPopUp(null);
    });
  }

  const reorderHandler = ids => {
    services.taskStatusServices.reorderTasks(ids).then(res => {
      if (res.data) {
        setTaskStatuses(res.data);
      }
    });
  }

  const deleteHandler = () => {
    services.taskStatusServices.deleteTaskStatus(currentTaskStatus.id).then(() => {
      setTaskStatuses(taskStatuses.filter(task => task.id !== currentTaskStatus.id));
      setCurrentTaskStatus(null);
      setPopUp(null);
    });
  }

  const handleDragEnd = result => {
    const sourceLvl = result.source.index
    if (result.destination.index > sourceLvl) {
      const lvl = result.destination.index + 1
      const newTasks = [
        ...taskStatuses.filter((_, index) => index !== sourceLvl).slice(0, lvl),
        taskStatuses[sourceLvl],
        ...taskStatuses.slice(lvl + 1)
      ];
      reorderHandler(newTasks.map(task => task.id).join(","));
    } else if (result.destination.index < sourceLvl) {
      const lvl = result.destination.index
      const newTasks = [
        ...taskStatuses.slice(0, lvl),
        taskStatuses[sourceLvl],
        ...taskStatuses.filter((_, index) => index !== sourceLvl).slice(lvl)
      ];
      reorderHandler(newTasks.map(task => task.id).join(","));
    }
  }

  return (
    <>
      <DragDropContext
        onDragEnd={handleDragEnd}
      >
        <Droppable droppableId='task_statuses' type='TASK_STATUSES'>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <TaskStatusesTable
                taskStatuses={taskStatuses}
                onCreate={() => setPopUp("create")}
                onEdit={item => {
                  setCurrentTaskStatus(item);
                  setPopUp("edit");
                }}
                onDelete={item => {
                  setCurrentTaskStatus(item);
                  setPopUp("delete");
                }}
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {
        popUp === "create" && (
          <Popup
            onClose={() => setPopUp(null)}
            title="New status"
            text="Please, complete the following fields to add a new status"
          >
            <TaskStatusForm
              onClose={() => setPopUp(null)}
              onSubmit={values => createHandler(values)}
            />
          </Popup>
        )
      }
      {
        (popUp === "edit" && currentTaskStatus) && (
          <Popup
            onClose={() => setPopUp(null)}
            title="Edit status"
          >
            <TaskStatusForm
              onClose={() => setPopUp(null)}
              onSubmit={values => editHandler(values)}
              initialValues={currentTaskStatus}
            />
          </Popup>
        )
      }
      {popUp === "delete" && (
        <Popup
          title="Delete status"
          text="Are you sure you want to delete this status?"
          onClose={() => setPopUp(null)}
          onClick={() => deleteHandler()}
          isDelete
          dialog
          subText="All tasks with it will be transferred to the previous status."
        />
      )}
    </>
  );
};

export default TaskStatuses;
