import React, {useEffect, useState} from "react";
import s from "./ProjectTypeDetailsContainer.module.scss";
import classNames from "classnames";
import Skeleton from "@material-ui/lab/Skeleton";
import DropListTrigger from "components/elements/DropListTrigger/DropListTrigger";
import Detail from "components/elements/Detail/Detail";
import DetailLoading from "components/loadingStates/Detail/Detail";
import {boardData} from "./helpers/testBoardData";
import {useHistory, useParams} from "react-router-dom";
import services from "../../../services";
import MilestonesTasksBoard from "../../elements/MilestonesTasksBoard/MilestonesTasksBoard";
import Popup from "../../popups/Popup/Popup";
import AddTypePopup from "../AdminSettingsProjectsContainer/Projects/components/TypesAndGroups/popups/AddTypePopup";
import {addTask, deleteTask, editTask} from "../../../helpers/tasks/tasksActions";

const ProjectTypeDetailsContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [milestonesLoading, setMilestonesLoading] = useState(true);
  const [detailData, setDetailData] = useState(boardData);
  const [milestones, setMilestones] = useState([]);
  const [popUp, setPopUp] = useState(null);

  const loadMilestone = () => {
    services.adminProjectsServices.getProjectTypeStages(params.id).then(res => {
      setMilestones(res.data?.stages || []);
      setMilestonesLoading(false);
    });
  };

  useEffect(() => {
    services.adminProjectsServices.getProjectType(params.id).then(res => {
      setDetailData(res.data);
      loadMilestone();
      setLoading(false);
    });
  }, []);

  const editType = (typeId, values) => {
    services.adminProjectsServices
      .editProjectType(typeId, values)
      .then(res => {
        setDetailData(res.data);
        setPopUp(null);
      });
  };

  const deleteType = () => {
    services.adminProjectsServices
      .deleteProjectType(params.id)
      .then(res => {
        if (res.data) {
          history.push("/admin-settings?tab=Projects");
        }
      });
  };

  const deleteApproval = approvalId => {
    services.approvalServices
      .deleteApprovalAdmin(approvalId)
      .then(() => loadMilestone());
    return true;
  };

  return (
    <div className={s.wrap}>
      <header
        className={s.header}
        onMouseLeave={() => setVisibleDropListMenu(false)}
      >
        {loading ? (
          <Skeleton
            variant='text'
            className={classNames("title", s.title)}
            animation='wave'
            width={200}
          />
        ) : (
          <h2 className={s.title}>{detailData.name}</h2>
        )}
        <DropListTrigger
          data={[
            {
              title: "Delete",
              onClick: () => setPopUp("delete")
            }
          ]}
          visibleMenu={visibleDropListMenu}
          setVisibleMenu={setVisibleDropListMenu}
        />
      </header>
      {!loading ? (
        <Detail
          data={[
            {
              name: "Load, %",
              value: detailData.load || "-"
            },
            {
              name: "Group",
              value: detailData.project_group?.name || "-"
            },
          ]}
          buttons={[
            {
              name: "Edit",
              onClick: () => setPopUp("edit")
            }
          ]}
          className={s.detail}
        />
      ) : (
        <DetailLoading length={3} className={s.detail}/>
      )}
      {
        milestonesLoading ?
          <DetailLoading length={3} className={s.detail}/> :
          <MilestonesTasksBoard
            data={milestones}
            className={s.tasksBoard}
            addTask={values => addTask(values, loadMilestone, params.id)}
            editTask={(values, taskId) => editTask(values, taskId, loadMilestone, params.id)}
            deleteTask={id => deleteTask(id, loadMilestone, params.id)}
            deleteApproval={id => deleteApproval(id)}
            projectTypeName={detailData.name}
          />
      }
      {popUp === "edit" && (
        <AddTypePopup
          onClose={() => setPopUp(null)}
          onFormSubmit={values => editType(params?.id, values)}
          type={{
            type: detailData.name,
            load: detailData.load,
            activityGroup: {
              id: detailData.project_group?.id,
              label: detailData.project_group?.name,
              value: detailData.project_group?.name
            }
          }}
        />
      )}
      {popUp === "delete" && (
        <Popup
          title="Delete project type"
          text="Are you sure you want to delete this type?"
          onClose={() => setPopUp(null)}
          successBtnTitle="Delete"
          onClick={deleteType}
          isDelete
          dialog
        />
      )}
    </div>
  );
};

export default ProjectTypeDetailsContainer;
