import React, {useState, useEffect, useRef} from "react";
import classNames from "classnames";
import "react-dropdown/style.css";
import s from "./UniversalSelect.module.scss";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";
import useOutsideClicker from "hooks/useOutsideClicker";
import PropTypes from "prop-types";
import PriorityItem from "./CustomItem/PriorityItem";
import StatusItem from "./StatusItem/StatusItem";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import {useTranslate} from "../../../hooks/useTranslate";

const UniversalSelect = (
  {
    options,
    name,
    value,
    onChange,
    label,
    placeholder,
    error,
    disabled,
    customItemType,
    withTranslate = false,
  }
) => {
  const {t} = useTranslation();
  const [translateLabelKey, translatelabelValue] = useTranslate(label);
  const [opened, setOpened] = useState(false);
  const [focused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const dropRef = useRef(null);

  useEffect(() => setCurrentValue(value), [value]);

  const handleChange = (item, index) => {
    if (!disabled) {
      setOpened(false);
      setCurrentValue(item);
      onChange(item, index, options);
      setFocused(false);
    }
  };

  useOutsideClicker(dropRef, () => {
    setOpened(false);
    setFocused(false);
  });

  const renderCurrentValue = () => {
    if (currentValue.value) {
      switch (customItemType) {
        case "priority":
          return (
            <div
              className={s.priority} style={{background: currentValue.color}}
              data-translate-key={withTranslate && getTranslateKey(currentValue.label)}
            >
              {withTranslate ? t(getTranslateKey(currentValue.label)) : currentValue.label}
            </div>
          );
        case "status":
          return (
            <div className={s.status}>
              <span style={{background: currentValue.color}}/>
              {currentValue.label}
            </div>
          );
      }
    }
    return (
      <span className={s.placeholder} data-translate-key={getTranslateKey(placeholder)}>
        {t(getTranslateKey(placeholder))}
      </span>
    );
  }

  const renderCustomItem = (item, index) => {
    const props = {item, index, onClick: () => handleChange(item, index), withTranslate}
    switch (customItemType) {
      case "priority":
        return <PriorityItem {...props}/>;
      case "status":
        return <StatusItem {...props}/>;
    }
  }

  return (
    <div className={s.wrap} ref={dropRef}>
      {label && (
        <p className={s.label} data-translate-key={translateLabelKey}>
          {translatelabelValue}
        </p>
      )}
      <div
        className={classNames(s.dropdown, {
          [s.opened]: opened,
          [s.disabled]: disabled,
          [s.focused]: focused,
        })}
        name={name}
      >
        <header
          className={s.header}
          onClick={() => {
            if (!disabled) {
              setFocused(!focused);
              setOpened(!opened);
            }
          }}
        >
          {renderCurrentValue()}
          <Arrow className={s.arrow}/>
        </header>
        {opened && (
          <div className={s.dropWrap}>
            {options.map((o, index) => renderCustomItem(o, index))}
          </div>
        )}
      </div>
      {error && <span className={s.errorMessage} data-translate-key={getTranslateKey(error)}>
        {t(getTranslateKey(error))}
      </span>}
    </div>
  );
};

export default UniversalSelect;

UniversalSelect.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  customItem: PropTypes.any,
};