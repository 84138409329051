import React from "react";

export const timeOptions = (time = 0) => [
  {label: time > 1 ? "hours" : "hour", value: "hours"},
  {label: time > 1 ? "days" : "day", value: "days"},
  {label: time > 1 ? "weeks" : "week", value: "weeks"},
  {label: time > 1 ? "months" : "month", value: "months"},
];

export const getStatusLabel = (status, color) => (
  <div style={{display: "flex", alignItems: "center"}}>
    <span
      style={{
        width: "8px",
        height: "8px",
        display: "block",
        borderRadius: "50%",
        backgroundColor: color,
        marginRight: "8px",
      }}
    />
    <span>{status}</span>
  </div>
);
export const statusOptions = [
  {
    id: 1,
    label: getStatusLabel("Planned", "#df0505"),
    value: "planned",
  },
  {
    id: 2,
    label: getStatusLabel("In progress", "#ff0"),
    value: "in_progress",
  },
  {
    id: 3,
    label: getStatusLabel("Completed", "#0d9d4f"),
    value: "completed",
  },
];
export const status = {
  planned: "planned",
  "in progress": "in_progress",
  completed: "completed",
};
