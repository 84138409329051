export const paths = {
  home: "/",
  contact: "/contact-us",
  about: "/about-us",
  product: "/product",
  features: "/features",
  login: "/login",
  passwordChange: "/password-change",
  passwordResetRequest: "/password-reset",
  passwordReset: "/password-reset/:token",
  profile: "/profile",
  users: "/users",
  projects: "/projects",
  projectDetail: "/projects/:id",
  categories: "/categories-overview",
  contracts: "/contracts",
  contractsNew: "/contract/new",
  contractsEdit: "/contract/edit/:id",
  contractsDetails: "/contract/details/:id",
  suppliers: "/suppliers",
  supplierDetail: "/suppliers/:id",
  blog: "/blog",
  article: "/article",
  cookie: "/cookie-policy",
  privacy: "/privacy-policy",
  pricing: "/pricing",
  milestoneDetail: "/projects/:projectId/milestones/:id",
  financialBenefits: "/financial-benefits",
  resourceUtilizationReport: "/users/resource-utilization/report",
  dashboard: "/dashboard",
  dashboardTasks: "/self/tasks",
  surveyResults: "/projects/:id/survey-results",
  survey: "/projects/:id/survey/:surveyId",
  projectsSurveys: "/project-surveys",
  suppliersSurveys: "/supplier-surveys",
  projectsSurveysTemplate: "/project-survey/template/:id",
  suppliersSurveysTemplate: "/supplier-survey/supplier/:id",
  userDetails: "/users/:id",
  financialReports: "/financial-reports",
  notifications: "/notifications",
  procurementActivity: "/procurement-activity",
  previewProject: "/projects/:id/preview",
  activity: "/activity",
  adminScenario: "/admin-settings/scenario/:id",
  adminAdditionalQuestion: "/admin-settings/additional-question/:id/:name",
  tagSystemReports: "/system-reports/tags/:id",
  systemReports: "/system-reports",
  projectSystemReports: "/system-reports/projects/:id",
  userReport: "/system-reports/users/:id",
  departmentReport: "/system-reports/departments/:id",
  teamReport: "/system-reports/teams/:id",
  categoryDetail: "/categories/:id",
  adminSettingsProjectStage: "/admin-settings/projects/stages/:id",
  companyGoals: "/company-goals",
  companyGoal: "/company-goals/:id",
  requests: "/requests",
  requestDetails: "/requests/:id",
  createProject: "/create/projects",
  advancedProjectEdit: "/edit/advanced-projects",
  editProject: "/edit/projects/:id",
  createRequest: "/requests/create",
  editRequest: "/requests/edit/:id",
  adminSettingsGeneral: "/admin-settings/general",
  adminSettingsUsers: "/admin-settings/users",
  adminSettingsApprovals: "/admin-settings/approvals",
  adminSettingsProjects: "/admin-settings/projects",
  adminSettingsContracts: "/admin-settings/contracts",
  adminSettingsAdditionalQuestions: "/admin-settings/additional_questions",
  adminSettingsFlags: "/admin-settings/flags",
  adminSettingsSavings: "/admin-settings/savings",
  flagReport: "/system-reports/flags/:id",
  addFinancialBenefit: "/projects/:id/financial-benefits",
  editFinancialBenefit: "/projects/:projectId/financial-benefits/:financialBenefitId/edit",
  financialBenefitDetails: "/projects/:projectId/financial-benefits/:financialBenefitId",
  adminSettingsRequest: "/admin-settings/requests",
  adminSettingsProjectTypeDetails: "/admin-settings/project-types/:id",
  adminSettingsConfigureTaskDetails: "/admin-settings/project-types/:id/configure_task/:taskId",
  adminSettingsCustomDropDownsEdit: "/admin-settings/custom_dropdowns/:id",
  adminSettingsCustomDropDownsCreate: "/admin-settings/custom_dropdowns/new",
  adminSettingsRequestDetails: "/admin-settings/request/:id",
  adminSettingsStepFieldDetail: "/admin-settings/request-type/:requestId/step/:stepId/field/:fieldId",
  projectActualSavingsMonth: "/projects/:id/actual_savings",
  projectActualSavingsMonthNotes: "/custom_notes/",
  approvalDetails: "/projects/:projectId/milestones/:milestoneId/approvals/:id/detail",
  adminAddApprovalStep: "/admin-settings/project-types/:projectTypeId/create-approval",
  adminApprovalDetails: "/admin-settings/project-types/:projectTypeId/approvals/:id",
  addApprovalStep: "/projects/:projectId/milestones/:milestoneId/create-approval",
  editApprovalStep: "/projects/:projectId/milestones/:milestoneId/approvals/:id",
  clarificationHistory: "/projects/:projectId/milestones/:milestoneId/approvals/:id/approver/:approverId/history",
  taskDetails: "/projects/:projectId/milestones/:milestoneId/tasks/:taskId",
  taskStakeholders: "/projects/:projectId/milestones/:milestoneId/tasks/:taskId/stakeholders",
  adminSettingsTriage: "/admin-settings/triage",
  adminSettingsEmails: "/admin-settings/emails",
  adminSettingsEmailEditor: "/admin-settings/emails/:id/editor",
  adminSettingsEmail: "/admin-settings/emails/:id"
};
