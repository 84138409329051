import React from "react"
import Popup from "components/popups/Popup/Popup"
import AdminStakeholderTypesForm from "components/forms/AdminStakeholderTypesForm/AdminStakeholderTypesForm"
const EditPopup = ({ onClose, onSubmit, defaultValues }) => {
  return (
    <Popup onClose={onClose} title='Edit stakeholder type'>
      <AdminStakeholderTypesForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
    </Popup>
  )
}
export default EditPopup
