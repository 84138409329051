import React, { useEffect, useState } from "react";
import querystring from "query-string";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import DragAndDropGanttCharts from "components/elements/DragAndDropGanttCharts/DragAndDropGanttCharts";
import services from "services";

const ResourceUtilizationReportGanttChart = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const query = querystring.parse(history.location.search);

  useEffect(() => {
    getGanttChart();
  }, [history.location]);

  const getGanttChart = () => {
    services.resourceUtilizationServices
      .getUtilizationGanttChart(query)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  };

  const onDropHandler = (projectId, userId) => {
    services.projectServices
      .editProject(projectId, { owner_id: userId })
      .then(() => getGanttChart());
  };
  return (
    <div>
      {loading && (
        <div className="loaderWrap">
          <CircularProgress />
        </div>
      )}
      {!loading &&
        (Boolean(data.length) ? (
          <DragAndDropGanttCharts data={data} onDrop={onDropHandler} />
        ) : (
          <EmptyBlock
            title="No work item results found. Please, select users."
            message
          ></EmptyBlock>
        ))}
    </div>
  );
};

export default ResourceUtilizationReportGanttChart;
