import React, { useState, Fragment, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { debounce } from "lodash";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import services from "services";
import s from "./AddUserForm.module.scss";
import { useParams } from "react-router-dom";

const AddUserForm = ({ currentUsers = [], addHandler, onClose }) => {
  const [searchedUsers, setSearchedUsers] = useState([]);
  const filteredUsers = searchedUsers.filter((user) =>
    Boolean(!currentUsers.filter((item) => item.id === user.id).length)
  );

  const onSearchUser = useCallback(
    debounce((params) => {
      services.userServices
        .usersAutocomplete(params)
        .then((res) => {
          setSearchedUsers(res.data);
        });
    }, 300),
    []
  );

  const formik = useFormik({
    initialValues: {
      user: {
        id: null,
        name: "",
      },
    },
    validate: ({ user }) => {
      let errors = {};
      if (!Boolean(user.id)) {
        errors.user = "Please, choose the user";
      }
      return errors;
    },
    onSubmit: (values) => {
      addHandler({
        user_id: values.user?.id,
      });
      onClose();
    },
  });

  const { handleSubmit, errors, setFieldValue, touched, values } = formik;

  const userName =
    typeof values.user === "object"
      ? values.user.name
      : values.user;

  useEffect(() => {
    services.userServices.getUsers().then((res) => {
      setSearchedUsers(res.data.users);
    });
    userName &&
      onSearchUser({
        query: userName,
      });
  }, []);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <AutoCompleteInput
        data={filteredUsers}
        value={userName}
        placeholder="Start typing user's name"
        onChange={(e) => {
          setFieldValue("user", e.target.value);
          onSearchUser({
            query: e.target.value,
          });
        }}
        onSelect={(value) => {
          setFieldValue("user", value);
        }}
        label="User"
        name="user"
        className={s.field}
        emptyText={{
          text: "No work item results found.",
        }}
        error={errors.user && touched.user && errors.user}
        withArrow
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Add
        </Button>
      </div>
    </form>
  );
};

export default AddUserForm;
