import React, { Fragment } from "react";
import DeleteUserPopup from "./DeleteUserPopup/DeleteUserPopup";
import AddUserPopup from "./AddUserPopup/AddUserPopup";
import EditUserPopup from "./EditUserPopup/EditUserPopup";
import ResetPassPopup from "./ResetPassPopup/ResetPassPopup";
import {
  setPopup,
  deleteUser,
  addUser,
  editUser,
  resetPassword,
} from "../helpers/actions";

const Popups = ({state, dispatch, divisions, onSuccess}) => {
  return (
    <Fragment>
      {state.popup === "delete" && (
        <DeleteUserPopup
          onClose={() => dispatch(setPopup(null))}
          onSubmit={() => deleteUser(dispatch, state.current_user.id)}
        />
      )}
      {state.popup === "add" && (
        <AddUserPopup
          onSubmit={(user) => addUser(dispatch, user)}
          divisions={divisions}
          onClose={() => dispatch(setPopup(null))}
          filters={state.filters}
        />
      )}
      {state.popup === "edit" && (
        <EditUserPopup
          onSubmit={(user) => {
            editUser(dispatch, state.current_user.id, user,onSuccess);
          }}
          divisions={divisions}
          onClose={() => dispatch(setPopup(null))}
          filters={state.filters}
          defaultData={state.current_user}
        />
      )}
      {state.popup === "reset-pass" && (
        <ResetPassPopup
          onSubmit={(values) =>
            resetPassword(dispatch, state.current_user.id, values)
          }
          onClose={() => dispatch(setPopup(null))}
        />
      )}
    </Fragment>
  );
};
export default Popups;
