import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import FlagReportContainer from "components/containers/FlagReportContainer/FlagReportContainer";
import {useHistory} from "react-router-dom";

const FlagReportPage = () => {
  const hist = useHistory();
  const historyFrom = !hist.location.state ? " " : hist.location.state.from;
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          name: historyFrom === "Admin Settings" ? "Admin Settings" : "System Reports",
          disabled: true,
          path: hist.location.pathname.includes("system-reports") ? "/system-reports" : "/admin-settings",
        },
        {
          name: "Flag report"
        }
      ]}
    >
      <FlagReportContainer/>
    </DefaultLayout>
  );
};

export default FlagReportPage;
