import React, {useState} from "react"
import MainTable from "components/tables/MainTable/MainTable"
import {formatCurrency} from "helpers/currencyFormat"
import {supplierStatuses as statuses} from "helpers/helpData.js"
import sort from "helpers/sortHelper"

const SupplierGeneralTable = (
  {
    data: initialData,
    perPage = 20,
    totalCount,
    currency,
    query,
    changeQuery,
    className
  }
) => {
  const [data, setData] = useState(
    initialData.filter(s => s.total_spend).map(itm => ({...itm, owner: itm.owner?.name || "-"}))
  )

  const onPageChange = page => {
    changeQuery(query => ({
      ...query,
      page
    }))
  }

  const onSort = ({sort_by, direction}) => {
    if (sort_by === "category" || sort_by === "status" || sort_by === "owner")
      setData(sort.sortString(data, {field: sort_by, direction}))
    else {
      setData(sort.sortNumber(data, {field: sort_by, direction}))
    }
  }

  if (!data.length) return '';

  return (
    <MainTable
      data={data}
      className={className}
      dataTypes={[
        {
          title: "Category name",
          value: ({category}) => category,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "category", direction})
          }
        },
        {
          title: "Category level",
          value: ({category_level}) => `Level ${category_level}` || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "category_level", direction})
          }
        },
        {
          title: "Status",
          value: ({status}) =>
            statuses.find(s => s.value === status).label || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "status", direction})
          }
        },
        {
          title: `Spend`,
          value: ({total_spend}) =>
            total_spend
              ? formatCurrency(currency === "$" ? "USD" : currency, total_spend)
              : "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "total_spend", direction})
          }
        },

        {
          title: "Category owner",
          value: ({category_owner}) => category_owner || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "category_owner", direction})
          }
        }
      ]}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={query.page || 1}
    />
  )
}

export default SupplierGeneralTable
