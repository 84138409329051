import React from "react";
import PropTypes from "prop-types";
import HeaderNotificationsItem from "./HeaderNotificationsItem";
import s from "./HeaderNotifications.module.scss";

const HeaderNotifications = ({
  notifications,
  count,
  onNotificationsLoad,
  onDelete,
  onViewport,
  loading,
}) => {
  return (
    <div className={s.wrapNotifications}>
      <HeaderNotificationsItem
        notifications={notifications}
        count={count.business_count}
        loading={loading}
        type="business"
        onNotificationsLoad={onNotificationsLoad}
        onDelete={onDelete}
        onViewport={onViewport}
      />
      <HeaderNotificationsItem
        notifications={notifications}
        count={count.regular_count}
        loading={loading}
        type="regular"
        onNotificationsLoad={onNotificationsLoad}
        onDelete={onDelete}
        onViewport={onViewport}
      />
    </div>
  );
};

HeaderNotifications.propTypes = {
  notifications: PropTypes.array,
  count: PropTypes.object,
};

HeaderNotifications.defaultProps = {
  notifications: [],
  count: { business_count: 0, regular_count: 0 },
};

export default HeaderNotifications;
