import React, {useState} from "react";
import styles from "./SingleSubtask.module.scss";
import PropTypes from "prop-types";
import DropListTrigger from "../../../../elements/DropListTrigger/DropListTrigger";
import Avatar from "../../../../elements/Avatar/Avatar";
import {formatDate} from "../../../../../helpers/dateFormat";
import StatusChanger from "../../../../elements/StatusChanger/StatusChanger";
import {getStatusData, statuses} from "../../helpers/getStatusData";
import {useSelector} from "react-redux";

const SingleSubtask = (
  {
    data,
    setSelectedSubtask,
    openPopUp,
    isHighRule,
    changeStatus,
    isResponsibleUser,
    useForProject
  }
) => {
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);
  const user = useSelector(state => state.user);

  const getAllows = () => {
    if (!useForProject) return true;

    return (isHighRule && data.status !== "completed" && data.optional);
  };

  return (
    <div className={styles.singleTask} onClick={e => {
      e.stopPropagation();
      setSelectedSubtask(data.id);
    }}>
      <div>{data.name}</div>
      <div><Avatar name={data.responsible_user.name}/> {data.responsible_user.name}</div>
      <div>{formatDate(data.due_date, true)}</div>
      <div>
        <StatusChanger
          status={getStatusData(data.status)}
          customStatuses={statuses}
          onChange={(isHighRule || isResponsibleUser || data.responsible_user?.id === user.id) ?
            status => changeStatus(data.id, status.id) : null}
          autoIndex
        />
      </div>
      {
        (getAllows()) && (
          <DropListTrigger
            data={[
              {
                title: "Edit",
                onClick: () => openPopUp("edit", data)
              },
              {
                title: "Delete",
                onClick: () => openPopUp("delete", data)
              }
            ]}
            visibleMenu={visibleDropListMenu}
            setVisibleMenu={setVisibleDropListMenu}
          />
        )
      }
    </div>
  );
};

export default SingleSubtask;

SingleSubtask.propTypes = {
  data: PropTypes.array,
  setSelectedSubtask: PropTypes.func,
  openPopUp: PropTypes.func,
  isHighRule: PropTypes.bool,
  changeStatus: PropTypes.func,
  isResponsibleUser: PropTypes.bool,
  useForProject: PropTypes.bool
};