import axios from "./axios";

export default {
  getFlags: async () => {
    try {
      return await axios.get("/admin/flags");
    } catch (error) {
      throw error;
    }
  },
  addFlag: async values => {
    try {
      return await axios.post("/admin/flags", values);
    } catch (error) {
      throw error;
    }
  },
  editFlag: async (id, values) => {
    try {
      return await axios.put(`/admin/flags/${id}`, values);
    } catch (error) {
      throw error;
    }
  },
  deleteFlag: async id => {
    try {
      return await axios.delete(`/admin/flags/${id}`);
    } catch (error) {
      throw error;
    }
  },
};
