import React from "react";
import {string} from "yup";
import FormConstructor from "../../elements/FormConstructor/FormConstructor";
import PropTypes from "prop-types";

const EditRequestDescriptionForm = ({onSubmit, description, onCLose}) => {

  return (
    <FormConstructor
      submitBtn={{
        styled: "primary",
        title: "Save",
      }}
      cancelBtn={{
        styled: "secondary",
        title: "Cancel",
        onClick: () => onCLose()
      }}
      fields={[
        {
          type: "textarea",
          title: "Description (optional)",
          placeholder: "Enter description",
          required: string()
            .max(180, "The Description is too long. It should be 180 characters or less."),
          key: "description",
          value: description,
          max: 180,
        }
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default EditRequestDescriptionForm;

EditRequestDescriptionForm.propTypes = {
  onSubmit: PropTypes.func,
  description: PropTypes.string,
  onCLose: PropTypes.func,
};