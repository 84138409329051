import React from "react";
import s from "./LogoCompany.module.scss";
import {useSelector} from "react-redux";
import classNames from "classnames";

const LogoCompany = ({classNameLogo, width}) => {
  const user = useSelector(store => store.user);
  const typeParts = user.organization.name.split(" ");
  const logo = user.organization.logo_company?.replace(/ /g, '');
  const avatar = typeParts.length > 1 ? typeParts[0][0] + typeParts[1][0] : typeParts[0][0];

  const logoOrganization = () => {
    let urlNew;
    if (logo.match(/\.(jpeg|jpg|gif|svg|png)$/) != null) {
      return (
        <>
          <img
            className={classNames(s.logo, classNameLogo)}
            src={logo}
            alt="Logo"
          />
          {width > 100 && <span className={s.company}>{user.organization.name}</span>}
        </>
      );
    }
    if (logo.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) != null) {
      if (logo && logo.length > 1 && logo.slice(0, 2) !== "ht") {
        urlNew = new URL("http://" + logo);
      } else {
        urlNew = new URL(logo);
      }
      return (
        <>
          <img
            className={classNames(s.logo, classNameLogo)}
            src={`https://www.google.com/s2/favicons?domain=${urlNew.hostname.slice(0, 3) == "www" ? urlNew.hostname.slice(4) : urlNew.hostname}`}
            alt="Logo"
          />
          {width > 100 && <span className={s.company}>{user.organization.name}</span>}
        </>
      );
    } else {
      return (
        <div className={classNames(s.logoGrey, classNameLogo)}>
          {avatar} <span className={s.company}>{user.organization.name}</span>
        </div>
      )
    }
  };

  return (
    <div className={s.logoCompany}>
      {logo ? logoOrganization()
        : <div className={classNames(s.logoGrey, classNameLogo)}>
          {avatar} <span className={s.company}>{user.organization.name}</span>
        </div>
      }
    </div>
  );
};

export default LogoCompany;