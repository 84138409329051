import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import PasswordResetForm from "components/forms/PasswordResetForm/PasswordResetForm";
import Success from "./components/Success/Success";
import Expired from "./components/Expired/Expired";
import services from "services";
import { useParams } from "react-router-dom";

const PasswordResetContainer = () => {
  const [reset, setReset] = useState(false);
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(true);
  const { token } = useParams();

  const resetHandler = async ({ password }) => {
    try {
      const result = await services.userServices.resetPassword(token, password);
      setReset(true);
      return result;
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    services.userServices
      .getTokenStatus({ reset_password_token: token })
      .then()
      .catch(() => setExpired(true))
      .finally(() => setLoading(false));
  }, [token]);

  return (
    <>
      {loading && (
        <div className="loaderWrap">
          <CircularProgress />
        </div>
      )}

      {!loading &&
        !expired &&
        (!reset ? <PasswordResetForm onSubmit={resetHandler} /> : <Success />)}
      {!loading && expired && <Expired />}
    </>
  );
};

export default PasswordResetContainer;
