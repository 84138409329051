export const findItemInOptions = (item, options) => {
  let findedItem = null
  options.map(option => {
    if (option.label?.toLowerCase().trim() === item?.toLowerCase().trim()) {
      findedItem = option
    } else if (option.subItems) {
      const fItem = findItemInOptions(item, option.subItems)
      if (fItem) {
        findedItem = fItem
      }
    }
  })
  return findedItem
}
const findItemLabelInOptions = (item, options) => {
  let findedItem = null
  options.map(option => {
    if (option.label === item) {
      findedItem = option.label
    } else if (option.subItems) {
      const fItem = findItemLabelInOptions(item, option.subItems)
      if (fItem) {
        findedItem = fItem
      }
    }
  })
  return findedItem
}
export const formatDataToString = (
  value,
  valueFormat,
  options,
  withEndComma
) => {
  const endComma = withEndComma && Object.keys(value).length > 0 ? ", " : ""
  switch (valueFormat) {
    case "all":
      const newValue = Object.keys(value)
        .filter(item => value[item])
        .map(item => {
          return findItemLabelInOptions(item, options)
        })
      return newValue.join(", ") + (newValue.length ? endComma : "")
    case "first":
      const arr = Object.keys(value).filter(item => value[item])
      if (!arr.length) return ""
      return arr.length > 1 ? `${arr[0]} (+${arr.length - 1})` : arr[0]
    default:
      return ""
  }
}
