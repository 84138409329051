import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./Input.module.scss";
import {useTranslate} from "../../../hooks/useTranslate";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import Label from "../Label/Label";

const Input = (
  {
    type,
    className,
    placeholder,
    onChange,
    value,
    disabled,
    scene,
    error,
    icon,
    label,
    onClick,
    id,
    name,
    inputClass,
    labelClass,
    inputProps,
    onBlur,
    onFocus,
    withCounter,
    limitValue,
    isSeparateNumber = false,
    useForFilter = false,
    withoutChange = false,
    withoutTranslate = false,
    onKeyPress,
    labelGrey,
    optional = false,
  }
) => {
  const {t} = useTranslation();
  const [currentValue, setValue] = useState("");
  const [translatePlaceholderKey, translatePlaceholderValue] = useTranslate(placeholder);
  const [translateLabelGreyKey, translateLabelGreyValue] = useTranslate(labelGrey);

  useEffect(() => {
    if (isSeparateNumber) {
      value !== undefined &&
      value !== null &&
      value !== "" &&
      !inputProps.type &&
      setValue(prev => {
        const val = Number(value).toLocaleString("en-US", {
          maximumFractionDigits: 0
        });
        return val === "NaN" ? prev : val;
      });
      (value === undefined || value === null || value === "") &&
      !inputProps.type &&
      setValue("");
    }
    if (!useForFilter) {
      setValue(value);
    }
  }, [value]);

  return (
    <div
      className={classNames(
        s.wrapInput,
        {
          [s.disabled]: disabled,
          [s.authField]: scene === "auth",
          [s.withIcon]: icon,
        },
        className
      )}
    >
    <div className={classNames(s.inputLabelContainer)}>
      {label && (
        <Label
          label={label}
          labelClass={labelClass}
          optional={optional}
          withoutTranslate={withoutTranslate}
        />
      )}
      {labelGrey && (
          <p
              className={classNames(labelClass, s.inputLabelGrey)}
              data-translate-key={translateLabelGreyKey}
          >
              {withoutTranslate ? label : translateLabelGreyValue}
          </p>
        )}
    </div>
      <input
        data-translate-key={translatePlaceholderKey}
        type={type}
        className={classNames(inputClass, s.field)}
        name={name}
        placeholder={translatePlaceholderValue}
        value={currentValue}
        onChange={e => {
          if (isSeparateNumber) {
            onChange(e.target.value.replace(/,/g, ""));
          } else if (!withoutChange) {
            onChange(e);
          }
        }}
        onClick={onClick}
        id={id}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        {...inputProps}
      />
      {(error || withCounter) && (
        <div className={s.bottomWrap}>
          <span className={s.errorMessage} data-translate-key={error}>{t(getTranslateKey(error))}</span>
          {withCounter && (
            <span className={s.counterWrap}>
              <span className={classNames(s.counter, {[s.error]: error})}>
                {value.length}&nbsp;
              </span>
              {`/ ${limitValue}`}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.any,
  className: PropTypes.any,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  scene: PropTypes.string,
  inputProps: PropTypes.object,
  withCounter: PropTypes.bool,
  error: PropTypes.any,
  icon: PropTypes.any,
  label: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  limitValue: PropTypes.number,
  isSeparateNumber: PropTypes.bool,
  useForFilter: PropTypes.bool,
  withoutChange: PropTypes.bool,
  withoutTranslate: PropTypes.bool,
  optional: PropTypes.bool
};

Input.defaultProps = {
  type: "text",
  className: "",
  placeholder: "",
  inputProps: {},
  onChange: () => {
  },
  withCounter: false,
};

export default Input;
