import React, {useState} from "react";
import s from "./Accordeon.module.scss";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";
import classNames from "classnames";
import PropTypes from "prop-types";

const Accordeon = ({children, title = "", positionTitle = "center"}) => {
  const [opened, setOpened] = useState(true);

  return (
    <div className={classNames(s.accordeonWrap, {[s.opened]: opened})}>
      <div className={classNames(s.header, positionTitle)}>
        <span className={s.title}>{title}</span>
        <Arrow
          className={classNames(s.arrow, {[s.opened]: opened})}
          onClick={() => setOpened(!opened)}
        />
      </div>
      {opened && children}
    </div>
  );
};

export default Accordeon;

Accordeon.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  positionTitle: PropTypes.string
};