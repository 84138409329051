export const parseToObj = (formula, level = "first") => {

  const preparedObjects = [];

  let leftBracketsCount = 0;
  let rightBracketsCount = 0;
  let leftBracketPosition = -1;

  for (let i = 0; i <= formula.length; i++) {

    if (isSkip(i, level, preparedObjects)) continue;

    nextIteration: for (let j = i; j <= formula.length; j++) {

      if (formula[i] === "(") leftBracketPosition = i;
      if (formula[j] === "(") leftBracketsCount++;
      if (formula[j] === ")") rightBracketsCount++;

      if (leftBracketPosition > -1 && leftBracketsCount === rightBracketsCount) {
        if (level === "first") {
          preparedObjects.push({
            id: `condition-${j}`,
            condition: getCondition(formula, j),
            ["condition_group"]: parseToObj(getSubFormula(formula, leftBracketPosition, j), "second")
          });
        } else {
          preparedObjects.push({
            id: `condition-${j}`,
            condition: getCondition(formula, j),
            value: getSubFormula(formula, leftBracketPosition, j)
          });
        }
        break nextIteration;
      }

    }

    leftBracketsCount = 0;
    rightBracketsCount = 0;
    leftBracketPosition = -1;
  }

  return preparedObjects;
};

const formatCondition = condition => condition === "||" ? "or" : "and";

const isSkip = (idx, level, objs) => {

  if (level === "first" && objs.length) {
    if (idx <= Object.values(objs[objs.length - 1])[0].split("-")[1]) return true;
  }
  return false;
};

const getCondition = (part, idx) => {
  const condition = part.slice(++idx, idx + 2);
  return Boolean(condition) ? formatCondition(condition) : null;
};

const getSubFormula = (formula, fromIdx, toIdx) => {
  return formula.slice(++fromIdx, toIdx);
};