import { SET_CURRENT_CATEGORY_ID, SET_CATEGORIES } from "helpers/constants";

let initialState = {
  categories: [],
  currentCategoryID: null,
};

const categories = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CATEGORIES: {
      return { ...state, categories: payload };
    }
    case SET_CURRENT_CATEGORY_ID: {
      return { ...state, currentCategoryID: payload };
    }
    default: {
      return state;
    }
  }
};

export default categories;
