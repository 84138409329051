import React from "react";
import { useFormik } from "formik";
import { object, string, ref } from "yup";
import PasswordInput from "components/elements/PasswordInput/PasswordInput";
import Button from "components/elements/Button/Button";
import s from "./ResetUserPasswordForm.module.scss";

const ResetUserPasswordForm = ({ onSubmit, onClose }) => {
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      reenterPassword: "",
    },
    validationSchema: object({
      newPassword: string()
        .required("Please type your new password")
        .min(8, "New password must have 8 characters minimum")
        .max(32, "Max length is 32 characters")
        .matches(/[a-z]{1}/, "Please use at least 1 lower case character")
        .matches(/[A-Z]{1}/, "Please use at least 1 upper case character")
        .matches(/(?=.*[0-9])/, "Please use at least 1 number")
        .matches(
          /(?=.*[!@#\$%\^&\*])/,
          "Please use at least 1 special character"
        ),
      reenterPassword: string()
        .required("Please retype your new password")
        .oneOf(
          [ref("newPassword"), null],
          "Password verification doesn’t match new password"
        ),
    }),
    onSubmit: (values) => {
      onSubmit({
        password: values.newPassword,
      });
    },
  });

  const { handleSubmit, touched, values, errors, setFieldValue } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className={s.inputWrap}>
        <PasswordInput
          placeholder="Enter your new password"
          onChange={(value) => setFieldValue("newPassword", value)}
          value={values.newPassword}
          label="New password"
          error={touched.newPassword && errors.newPassword}
        />
      </div>

      <div className={s.inputWrap}>
        <PasswordInput
          placeholder="Enter your new password"
          onChange={(value) => setFieldValue("reenterPassword", value)}
          value={values.reenterPassword}
          label="Confirm password"
          error={touched.reenterPassword && errors.reenterPassword}
        />
      </div>

      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Save
        </Button>
      </div>
    </form>
  );
};

export default ResetUserPasswordForm;
