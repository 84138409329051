import axios from "./axios";

export default {
  getSavingsGroupAdmin: async () => {
    try {
      return await axios.get("/admin/saving_groups");
    } catch (error) {
      throw error;
    }
  },
  getSavingsGroup: async () => {
    try {
      return await axios.get("/saving_groups");
    } catch (error) {
      throw error;
    }
  },
  createSavingsGroup: async params => {
    try {
      return await axios.post("/admin/saving_groups", params);
    } catch (error) {
      throw error;
    }
  },
  editSavingsGroup: async (id, params) => {
    try {
      return await axios.put(`/admin/saving_groups/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  deleteSavingsGroup: async (id, groupId) => {
    try {
      return await axios.delete(`/admin/saving_groups/${id}?saving_group_id=${groupId}`);
    } catch (error) {
      throw error;
    }
  },
  restoreSavingsGroup: async id => {
    try {
      return await axios.put(`/admin/saving_groups/${id}/restore`);
    } catch (error) {
      throw error;
    }
  },
};
