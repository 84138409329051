import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import Button from "components/elements/Button/Button";
import PropTypes from "prop-types";
import s from "./AdminSettingsFinancialSavingsGroupDeleteForm.module.scss";
import CustomDropdown from "../../elements/CustomDropdown/CustomDropdown";
import Checkbox from "../../elements/Checkbox/Checkbox";
import services from "../../../services";

const AdminSettingsFinancialSavingsGroupDeleteForm = (
  {
    onClose,
    onSubmit,
    groupId
  }
) => {
  const [savingsGroups, setSavingsGroups] = useState([]);

  useEffect(() => {
    services.savingsGroupServices.getSavingsGroupAdmin().then(res => {
      if (res.data) {
        setSavingsGroups(
          res.data
            .filter(group => group.id !== groupId)
            .map(group => ({
              label: group.name,
              value: group.id
            }))
        );
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      savings_group_id: {},
      is_leave: false,
    },

    validate: ({is_leave, savings_group_id}) => {
      const errors = {};
      if (!is_leave && !savings_group_id?.value) {
        errors.savings_group_id = "Savings group is required";
      }
      return errors;
    },

    onSubmit: ({savings_group_id}) => {
      onSubmit({savings_group_id: savings_group_id.value});
      onClose();
    },
  });

  const {handleSubmit, errors, setFieldValue, values, touched} = formik;

  useEffect(() => {
    if (values.is_leave) {
      setFieldValue("savings_group_id", {value: "", label: ""});
    }
  }, [values.is_leave]);


  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className={s.field}>
        <CustomDropdown
          value={values.savings_group_id}
          onChange={e => setFieldValue("savings_group_id", e)}
          options={savingsGroups}
          name="savings"
          label="Savings group"
          placeholder="Choose group"
          disabled={values.is_leave}
          error={errors.savings_group_id && touched.savings_group_id && errors.savings_group_id}
        />
      </div>
      <div className={s.field}>
        <Checkbox
          label="Leave savings in deleted group"
          subLabel="Savings in a deleted group will be displayed only in pre-existing projects."
          withTranslate
          checked={values.is_leave}
          onChange={() => setFieldValue("is_leave", !values.is_leave)}
        />
      </div>
      <div className="popupBtnsWrap">
        <Button styled="secondary" className="popupBtn" type="reset" onClick={onClose}>Cancel</Button>
        <Button styled="primary" type="submit" className="popupBtn">Delete</Button>
      </div>
    </form>
  );
};

export default AdminSettingsFinancialSavingsGroupDeleteForm;

AdminSettingsFinancialSavingsGroupDeleteForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};