import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import TeamReportContainer from "../../containers/TeamReportContainer/TeamReportContainer";

const UserReportPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {name: "System reports", path: paths.systemReports},
        {name: "Team report", path: ""},
      ]}
    >
      <TeamReportContainer/>
    </DefaultLayout>
  );
};

export default UserReportPage;
