import React, {useState, useRef, useEffect} from "react";
import s from "./SelectCheckDropdown.module.scss";
import PropTypes from "prop-types";
import {ReactComponent as ArrowDown} from "assets/icons/arrow-down.svg";
import classNames from "classnames";
import useOutsideClicker from "hooks/useOutsideClicker";
import Input from "../Input/Input";
import CheckList from "./components/CheckList/CheckList";
import {
  formatDataToString,
  findItemInOptions
} from "./helpers/formatDataToString";
import {getSearchedOptions} from "./helpers/getSearchedOptions";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslate} from "../../../hooks/useTranslate";
import Label from "../Label/Label";

const SelectCheckDropdown = (
  {
    label,
    value,
    onChange,
    error,
    className,
    options,
    placeholder,
    valueFormat,
    onSearch,
    emptyMessage,
    withEndComma,
    optional = false,
  }
) => {
  const [visibleDropdownMenu, setVisibleDropdownMenu] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [translatePlaceholderKey, translatePlaceholderValue] = useTranslate(placeholder);
  const dropdownRef = useRef(null);
  const [searchVal, setSearchVal] = useState({
    text: formatDataToString(value, valueFormat, options, withEndComma),
    data: value,
    part: ""
  });
  const [searched, setSearched] = useState(false);
  const {t} = useTranslation();
  const [searchedOptions, setSearchedOptions] = useState(options);
  useOutsideClicker(dropdownRef, () => {
    setVisibleDropdownMenu(false);
    setIsSearching(false);
  });

  useEffect(() => {
    setSearchVal({
      ...searchVal,
      text: formatDataToString(value, valueFormat, options, withEndComma),
      data: value
    });
    setSearchedOptions(options);
  }, [options]);

  useEffect(() => {
    const data = [];
    searchVal.text.split(", ").map(e => {
      const item = findItemInOptions(e, options);
      item && data.push(item);
    });
    setSearchVal({...searchVal, data});
    onSearch && searched && onSearch(data);
  }, [searchVal.text]);

  useEffect(() => {
    const searchedOpt = searchVal.part.length
      ? getSearchedOptions(searchVal.part.toLowerCase(), options)
      : options;
    searched && setSearchedOptions(searchedOpt);
    if (!isSearching && searchVal.part.length) setIsSearching(true);
  }, [searchVal.part]);

  const handleChange = (newValue, item) => {
    setSearchVal({
      text: formatDataToString(newValue, valueFormat, options, withEndComma),
      data: newValue,
      part: ""
    });
    onChange(newValue, item);
  };

  return (
    <div
      className={classNames(s.selectCheckDropdown, className)}
      ref={dropdownRef}
    >
      {label && <Label label={label} optional={optional}/>}
      <div className={s.dropdownWrap}>
        {onSearch ? (
          <Input
            className={s.field}
            placeholder={placeholder}
            value={searchVal.text}
            onChange={e => {
              const val = e.target.value;
              !searched && setSearched(true);
              setSearchVal({
                ...searchVal,
                text: val,
                part: val.split(", ")[val.split(", ").length - 1]
              });
            }}
            onFocus={() => setVisibleDropdownMenu(true)}
          />
        ) : (
          <div
            className={s.dropdownHeader}
            onClick={() => {
              setVisibleDropdownMenu(!visibleDropdownMenu);
            }}
          >
            <span
              data-translate-key={translatePlaceholderKey}
              className={classNames(s.title, {
                [s.placeholder]: !formatDataToString(
                  value,
                  valueFormat,
                  options,
                  withEndComma
                ).length
              })}
            >
              {formatDataToString(value, valueFormat, options, withEndComma)
                .length
                ? formatDataToString(value, valueFormat, options, withEndComma)
                : translatePlaceholderValue}
            </span>
          </div>
        )}
        {visibleDropdownMenu && (
          <CheckList
            data={searchedOptions}
            emptyMessage={emptyMessage}
            value={value}
            onChange={handleChange}
            isSearching={isSearching}
            searchedOptions={searchedOptions}
          />
        )}
        <ArrowDown
          onClick={() => setVisibleDropdownMenu(!visibleDropdownMenu)}
          className={classNames(s.arrow, {
            [s.opened]: visibleDropdownMenu
          })}
        />
      </div>
      {error &&
        <span className={s.errorMessage} data-translate-key={getTranslateKey(error)}>{t(getTranslateKey(error))}</span>}
    </div>
  );
};
SelectCheckDropdown.propTypes = {
  label: PropTypes.any,
  value: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  valueFormat: PropTypes.string
};
SelectCheckDropdown.defaultProps = {
  label: "Savings type",
  value: {
    one_time_ca: false,
    one_time_er: false,
    run_rate_ca: false,
    run_rate_er: false
  },
  onChange: () => {
  },
  options: [
    {index: 1, label: "Run Rate (CA)", value: "run_rate_ca"},
    {index: 2, label: "Run Rate (ER)", value: "run_rate_er"},
    {index: 3, label: "One time (CA)", value: "one_time_ca"},
    {index: 4, label: "One time (ER)", value: "one_time_er"}
  ],
  placeholder: "Choose a type",
  valueFormat: "all"
};
export default SelectCheckDropdown;
