export const autoFillYears = years => {
  const allYears = []
  years &&
    years.map((year, index) => {
      const difference = index ? year - years[index - 1] : 0
      if (difference) {
        for (let i = 0; i <= difference; i++) {
          allYears.push(years[index - 1] + i)
        }
      } else {
        allYears.push(year)
      }
    })

  return Array.from(new Set(allYears))
}
