import React, { useState } from "react"
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout"
import SurveyResultsContainer from "components/containers/SurveyResultsContainer/SurveyResultsContainer"
import { useProjectsPath } from "hooks/useProjectsPath"

const SurveyResultsPage = () => {
  const projectsPath = useProjectsPath()
  const [breadcrumbs, setBreadcrumbs] = useState([
    { name: "Projects", path: projectsPath }
  ])
  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <SurveyResultsContainer setBreadcrumbs={setBreadcrumbs} />
    </DefaultLayout>
  )
}

export default SurveyResultsPage
