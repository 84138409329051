import React, { useEffect, useState, useReducer, Fragment } from "react"
import TableLoading from "components/loadingStates/Table/Table"
import AdminSettingsProjectsTypesTable from "components/tables/AdminSettingsProjectsTypesTable/AdminSettingsProjectsTypesTable"
import AddTypePopup from "./popups/AddTypePopup"
import DeleteTypePopup from "./popups/DeleteTypePopup"
import { initialState, reducer } from "./helpers/reducer"
import {
  getTypes,
  changePopup,
  addType,
  editType,
  deleteType,
  closeAlert
} from "./helpers/actions"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import NotificationBox from "components/elements/NotificationBox/NotificationBox"

const Types = () => {
  const [currentType, setCurrentType] = useState(null)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { types, alert, loading, popup } = state
  useEffect(() => getTypes(dispatch), [])
  return (
    <Fragment>
      {!loading && types.length > 0 && (
        <AdminSettingsProjectsTypesTable
          data={types}
          onAdd={() => dispatch(changePopup("add"))}
          onDelete={item => {
            setCurrentType(item)
            dispatch(changePopup("delete"))
          }}
          onEdit={item => {
            setCurrentType(item)
            dispatch(changePopup("edit"))
          }}
        />
      )}
      {!loading && !types.length && (
        <EmptyBlock
          subtitle='No work item results found'
          buttonTitle='Add type'
          onClick={() => dispatch(changePopup("add"))}
        />
      )}
      {loading && <TableLoading length={3} />}
      {popup === "add" && (
        <AddTypePopup
          onClose={() => dispatch(changePopup(null))}
          onFormSubmit={values => {
            addType(dispatch, values)
          }}
        />
      )}
      {popup === "edit" && (
        <AddTypePopup
          onClose={() => dispatch(changePopup(null))}
          onFormSubmit={values => {
            editType(dispatch, currentType?.id, values)
          }}
          type={{
            type: currentType.name,
            load: currentType.load,
            activityGroup: {
              id: currentType.project_group?.id,
              label: currentType.project_group?.name,
              value: currentType.project_group?.name
            }
          }}
        />
      )}

      {popup === "delete" && (
        <DeleteTypePopup
          onClose={() => dispatch(changePopup(null))}
          onSubmit={() => deleteType(dispatch, currentType.id)}
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => dispatch(closeAlert())}
        />
      )}
    </Fragment>
  )
}

export default Types
