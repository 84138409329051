import React, {useState, useCallback, useEffect} from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {useFormik} from "formik";
import {debounce} from "lodash";
import DatePicker from "components/elements/DatePicker/DatePicker";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import Textarea from "components/elements/Textarea/Textarea";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import DropdownTree from "components/elements/DropdownTree/DropdownTree";
import Input from "components/elements/Input/Input";
import s from "./EditProjectForm.module.scss";
import {formatDate} from "helpers/dateFormat";
import {getProjectStatuses} from "helpers/helpData";
import {localization} from "helpers/localizationForCompanies";
import {schemaManagement, schemaGeneral} from "./helpers/schemas";
import services from "services/index";
import SelectCheckDropdown from "components/elements/SelectCheckDropdown/SelectCheckDropdown";
import {
  formatEntityData,
  formatEntityToObject
} from "helpers/formatEntityData";
import {formatCheckedClients} from "./helpers/formatCheckedClients";
import {currencyData as currencies} from "helpers/helpData";
import CurrencySelectionField from "components/elements/CurrencySelectionField/CurrencySelectionField";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const EditProjectForm = (
  {
    onClose,
    title,
    stage,
    project_type,
    category,
    projectOwner,
    startDateForm,
    endDateForm,
    description,
    clients,
    onSubmit,
    target_spend,
    target_spend_currency
  }
) => {
  const [stages, setStages] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("general");
  const tabs = ["general", "management"];
  const organizationName = useSelector(state => state.user.organization.name);
  const statuses = getProjectStatuses(organizationName);
  const [checkedClients, setCheckedClients] = useState([]);
  const {t} = useTranslation();
  const PROJECT_TITLE_LIMIT = 50;
  const MAX_DESCRIPTION_LENGTH = 180;
  const currentSchema = activeTab === tabs[0] ? schemaGeneral : schemaManagement;

  useEffect(() => {
    services.categoriesServices.getCategories().then(res => {
      setCategories(res.data);
    });
    services.userServices
      .getDepartments()
      .then(res => setDepartments(formatEntityData(res.data || []) || []));
    services.projectServices.getStages().then(res => {
      setStages(
        res.data?.stages?.map(({id, name}) => ({
          id,
          label: name,
          value: name
        })) || []
      );
    });
    services.projectServices.getProjectTypes().then(res => {
      setProjectTypes(
        res.data?.project_types?.map(({id, name}) => ({
          id,
          label: name,
          value: name
        })) || []
      );
    });
  }, []);

  useEffect(() => {
    departments.length &&
    setCheckedClients(formatCheckedClients(clients, departments) || []);
  }, [departments]);

  const formik = useFormik({
    initialValues: {
      title,
      stage: {label: stage.name, value: stage.name, id: stage.id} || {},
      project_type:
        {
          label: project_type.name,
          value: project_type.name,
          id: project_type.id
        } || {},
      category_id: category,
      owner_id: {name: projectOwner.name, id: projectOwner.id},
      start_date: new Date(startDateForm.replace('Z', '')),
      end_date: new Date(endDateForm.replace('Z', '')),
      description: description || "",
      client: clients || {},
      target_spend:
        target_spend === undefined || target_spend === null ? "" : target_spend,
      currency:
        target_spend_currency || currencies.filter(e => e.label === "USD")[0]
    },
    validationSchema: currentSchema,
    validate: ({target_spend}) => {
      const errors = {};
      if (generalTabHaveErrors && !managementTabHaveErrors)
        setActiveTab("general");
      if (!checkedClients.length) {
        errors.client = "Please, choose client";
      }
      if (target_spend < 0) {
        errors.target_spend = "Please, type valid Target spend";
      }
      return errors;
    },
    onSubmit: values => {
      if (activeTab === tabs[1]) {
        onSubmit({
          name: values.title,
          stage_id: values.stage.id,
          project_type_id: values.project_type.id,
          category_id: values.category_id.id,
          owner_id: values.owner_id.id,
          description: values.description,
          start_date: formatDate(values.start_date),
          end_date: formatDate(values.end_date),
          department_ids: checkedClients.map(e => e.id).join(","),
          target_spend: values.target_spend,
          target_spend_currency: values.currency?.label
        });
        onClose();
      } else {
        setActiveTab(tabs[1]);
      }
    }
  });
  const {
    handleSubmit,
    handleChange,
    touched,
    values,
    errors,
    setFieldValue,
    handleBlur
  } = formik;
  const generalTabHaveErrors =
    errors.title || errors.project_type || errors.category_id || errors.client;
  const managementTabHaveErrors =
    errors.description ||
    errors.owner_id ||
    errors.start_date ||
    errors.end_date;
  const searchHandler = useCallback(
    debounce(
      term =>
        services.userServices
          .usersAutocomplete({query: term})
          .then(res => setUsers(res.data)),
      300
    ),
    []
  );

  return (
    <form
      className={s.editProjectForm}
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div className={s.tabs}>
        <span
          className={classNames(s.tab, {[s.active]: activeTab === tabs[0]})}
          onClick={() => setActiveTab(tabs[0])}
          data-translate-key={getTranslateKey("step-1---general-information")}
        >
           {t(getTranslateKey("step-1---general-information"))}
        </span>
        <span
          className={classNames(s.tab, {
            [s.active]: activeTab === tabs[1]
          })}
          onClick={() => setActiveTab(tabs[1])}
          data-translate-key={getTranslateKey("step-2---management")}
        >
         {t(getTranslateKey("step-2---management"))}
        </span>
      </div>
      {activeTab === tabs[0] && (
        <>
          <Input
            value={values.title}
            onChange={handleChange}
            className={classNames(s.titleField, {
              [s.projectTitleWrap]:
              errors.title && touched.title && errors.title
            })}
            name="title"
            type="text"
            placeholder={`Enter ${localization.title}`}
            label={`${localization.title}`}
            onBlur={handleBlur}
            error={errors.title && touched.title && errors.title}
            limitValue={PROJECT_TITLE_LIMIT}
            withCounter
          />
          <div className={s.inputWrap}>
            <CustomDropdown
              options={stages}
              value={values.stage.value}
              onChange={option => setFieldValue("stage", option)}
              label={`${localization.status}`}
            />
          </div>
          <div className={s.inputWrap}>
            <CustomDropdown
              options={projectTypes}
              value={values.project_type}
              onChange={e => setFieldValue("project_type", e)}
              placeholder="Choose a type"
              label="Type"
              error={
                errors.project_type &&
                touched.project_type &&
                errors.project_type.id
              }
            />
          </div>
          <div className={s.inputWrap}>
            <CurrencySelectionField
              className={s.field}
              options={currencies}
              value={values.target_spend}
              onChange={({fieldVal, value, label}) => {
                const currency = {
                  value,
                  label
                };
                currency.value && setFieldValue("currency", currency);
                setFieldValue("target_spend", fieldVal);
              }}
              defaultValue={values.currency.value}
              name="target_spend"
              label="Target spend"
              fieldPlaceholder="000,000"
              error={
                errors.target_spend &&
                touched.target_spend &&
                errors.target_spend
              }
            />
          </div>
          <div className={s.inputWrap}>
            <DropdownTree
              withSearch={true}
              value={values.category_id.name}
              defaultValue={values.category_id}
              onChange={e =>
                setFieldValue("category_id", {
                  ...values.category_id,
                  name: e.target.value
                })
              }
              onSelect={value => {
                setFieldValue("category_id", {
                  id: value.id,
                  name: value.name
                });
              }}
              name="category_id"
              options={categories}
              placeholder={`Enter a ${localization.category}`}
              label="Category"
              error={
                errors.category_id &&
                touched.category_id &&
                errors.category_id.name
              }
            />
          </div>
          <div className={s.inputWrap}>
            <SelectCheckDropdown
              label="Client"
              value={values.client}
              onChange={(e, item) => {
                setFieldValue("client", e);
                item.checked
                  ? setCheckedClients([item, ...checkedClients])
                  : setCheckedClients(
                    checkedClients.filter(e => e.id !== item.id)
                  );
              }}
              options={departments}
              placeholder="Start typing client name"
              onSearch={data => {
                setFieldValue("client", formatEntityToObject(data));
                setCheckedClients(data);
              }}
              emptyMessage="clients not found"
              withEndComma={true}
              className={s.field}
              error={errors.client && touched.client && errors.client}
            />
          </div>
        </>
      )}
      {activeTab === tabs[1] && (
        <>
          <div className={s.inputWrap}>
            <AutoCompleteInput
              data={users}
              value={values.owner_id.name}
              placeholder={`Enter ${localization.procurementOwner}`}
              onChange={e => {
                setFieldValue("owner_id", {
                  id: "",
                  name: e.target.value
                });
                searchHandler(e.target.value);
              }}
              onSelect={value => {
                setFieldValue("owner_id", {
                  id: value.id,
                  name: value.name
                });
              }}
              label={`${localization.procurementOwner}`}
              error={
                errors.owner_id &&
                touched.owner_id &&
                (errors.owner_id.name || errors.owner_id.id)
              }
            />
          </div>
          <div className="dateBlock">
            <DatePicker
              label="Start date"
              selected={values.start_date}
              onChange={date => {
                setFieldValue("start_date", date);
                date > values.end_date && setFieldValue("end_date", date);
              }}
              selectsStart
              startDate={values.start_date}
              endDate={values.end_date}
              placeholderText="Choose start date"
              wrapperClassName="wrapperdatePicker"
              className="customInput"
              todayButton="Go to today"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={5}
              scrollableYearDropdown
              error={touched.start_date && errors.start_date}
            />
            <DatePicker
              label={localization.targetEndDate}
              selected={values.end_date}
              onChange={date => setFieldValue("end_date", date)}
              selectsEnd
              startDate={values.start_date}
              endDate={values.end_date}
              minDate={values.start_date}
              placeholderText={`Choose ${localization.targetEndDate}`}
              wrapperClassName="wrapperdatePicker"
              className="customInput"
              todayButton="Go to today"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={5}
              scrollableYearDropdown
              error={touched.end_date && errors.end_date}
            />
          </div>
          <div className={s.inputWrap}>
            <Textarea
              value={values.description}
              label="Description"
              onChange={e => setFieldValue("description", e.target.value)}
              placeholder="Write a description"
              error={errors.description}
              count={{
                current: values.description.length,
                max: MAX_DESCRIPTION_LENGTH
              }}
            />
          </div>
        </>
      )}
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Save
        </Button>
      </div>
    </form>
  );
};

export default EditProjectForm;

EditProjectForm.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  stage: PropTypes.any,
  project_type: PropTypes,
  category: PropTypes.any,
  projectOwner: PropTypes.any,
  startDateForm: PropTypes.string,
  endDateForm: PropTypes.string,
  description: PropTypes.string,
  clients: PropTypes.any,
  onSubmit: PropTypes.func,
  target_spend: PropTypes.any,
  target_spend_currency: PropTypes.any,
};