import React from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import s from "./AddNoteForm.module.scss";
import Textarea from "components/elements/Textarea/Textarea";
import Button from "components/elements/Button/Button";
import Attachments from "components/forms/AddMilestoneTaskForm/components/Attachments/Attachments";
import services from "services";
import PropTypes from "prop-types";

const AddNoteForm = ({addHandler}) => {
  const formik = useFormik({
    initialValues: {
      description: "",
      attachments: []
    },
    validationSchema: object({
      description: string()
        .required("Please, write a note")
        .max(1000, "Description cannot have length greater than 1000")
    }),
    onSubmit: ({description, attachments}) => {
      addHandler({
        description,
        attachments: attachments.filter(file => file.lastModifiedDate)
      });
      resetForm();
    }
  });

  const {handleSubmit, touched, values, errors, setFieldValue, resetForm} = formik;

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };

  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      );
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        );
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off" className={s.form}>
      <Textarea
        placeholder='Enter a note'
        customHeight={57}
        value={values.description}
        onChange={e => setFieldValue("description", e.target.value)}
        count={{
          current: values.description.length,
          max: 1000
        }}
        label=""
        error={touched.description && errors.description}
      />
      <Attachments
        onChange={handleChangeAttachment}
        onDelete={handleDeleteAttachment}
        attachments={values.attachments}
        parentClassName={s.attachments}
        label=""
      />
      <div className={s.btns}>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          disabled={(!values.description && !values.attachments.length)}
          onClick={() => resetForm()}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          Save
        </Button>
      </div>
    </form>
  );
};

export default AddNoteForm;

AddNoteForm.propTypes = {
  addHandler: PropTypes.func,
};