import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import { paths } from "routes/paths";
import EditRequestContainer from "../../containers/EditRequestContainer/EditRequestContainer";

const EditRequestPage = () => {
	return (
		<DefaultLayout
			breadcrumbs={[
				{
					path: paths.requests,
					name: "Requests"
				},
				{ name: "Edit request" }
			]}
		>
			<EditRequestContainer />
		</DefaultLayout>
	);
};
export default EditRequestPage;
