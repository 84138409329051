import React from "react";
import classNames from "classnames";
import CustomLink from "components/elements/CustomLink/CustomLink";
import s from "./TopNav.module.scss";
import { useSelector } from "react-redux";
import { userRoles } from "helpers/userPermissions/userRoles";

const topNavData = [
  { path: "/features", name: "Product" },
  { path: "/product", name: "How It Works" },
  { path: "/pricing", name: "Pricing" },
  { path: "/about-us", name: "About us" },
  { path: "/blog", name: "Blog" },
  { path: "/contact-us", name: "Contact us" },
];

const TopNav = ({ className, closeAction }) => {
  const user = useSelector((state) => state.user);
  return (
    <nav className={classNames(s.topNav, className)}>
      {topNavData.map((nav, index) => (
        <CustomLink
          key={index}
          path={nav.path}
          className={s.topNavLink}
          activeClassName={s.active}
          onClick={closeAction}
        >
          {nav.name}
        </CustomLink>
      ))}

      <div className={s.smallView}>
        <CustomLink
          path={!user.roles ? "/login" : "/profile"}
          type="nav-link"
          className={classNames(s.topNavLink, s.logInLink)}
          onClick={closeAction}
        >
          {!user.roles ? "Log in" : "My profile"}
        </CustomLink>

        <CustomLink
          className={s.buttonLink}
          type="nav-link"
          path="/contact-us"
          onClick={closeAction}
        >
          Get a demo
        </CustomLink>
      </div>
    </nav>
  );
};

export default TopNav;
