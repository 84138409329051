export const colors = {
  gray: ["#EAEAEA", "#E4E4E4"],
  red: ["#EF5759", "#E85658"],
  orange: ["#F4A261", "#EB9C5E"],
  light_green: ["#C3E26D", "#B6D267"],
  dark_green: ["#63A5A1", "#609D9A"],
  green: ["#0FC9BE", "#0DBCB2"],
  light_blue: ["#6ACFEF", "#63C0DD"],
  dark_blue: ["#5B79B4", "#516DA3"],
  blue: ["#665FE6", "#5C55D0"],
  purple: ["#A862DB", "#9556C2"],
  pink: ["#E24490", "#D93F89"],
  light_pink: ["#EF8EA7", "#DE849B"],
};
// [default, hover]
