import React, {useState, useEffect, Fragment, useContext} from "react";
import {useParams, useHistory} from "react-router-dom";
import {debounce} from "lodash";
import ProjectSuppliersTable from "components/tables/ProjectSuppliersTable/ProjectSuppliersTable";
import AddProjectSupplierForm from "components/forms/AddProjectSupplierForm/AddProjectSupplierForm";
import Popup from "components/popups/Popup/Popup";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import services from "services/index";
import AlertContext from "contexts/AlertContext/AlertContext";
import TableLoading from "components/loadingStates/Table/Table";
import querystring from "query-string";

const SuppliersContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [currentSuppliers, setCurrentSuppliers] = useState([]);
  const [total, setTotal] = useState(0);
  const [permissions, setPermissions] = useState({
    update: false,
    destroy: false
  });
  const [loading, setLoading] = useState(true);
  const {setAlert} = useContext(AlertContext);
  const {tag} = querystring.parse(
    history.location.search
  ) || {};

  useEffect(() => {
    services.supplierServices.getProjectSuppliers(params.id).then(res => {
      setCurrentSuppliers(res.data.suppliers);
      setPermissions({
        update: res.data.permissions?.update || false,
        destroy: res.data.permissions?.destroy || false
      });
      setLoading(false);
    });
  }, [params.id]);

  const addHandler = id => {
    services.supplierServices
      .addProjectSupplier(params.id, {supplier_id: id})
      .then(res => {
        setCurrentSuppliers([...currentSuppliers, res.data]);
        setAlert({
          message: `"${res.data.name}" supplier successfully added.`
        });
      })
      .catch(() =>
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      );
  };

  const removeHandler = id => {
    services.supplierServices
      .removeProjectSupplier(params.id, {supplier_id: id})
      .then(() => {
        setAlert({
          message: `"${
            currentSuppliers.find(item => item.id === id).name
          }"  supplier successfully removed.`,
          onCancel: () => {
            services.supplierServices
              .addProjectSupplier(params.id, {supplier_id: id})
              .then(res => {
                setCurrentSuppliers(state => [...state, res.data]);
              });
          }
        });
        setCurrentSuppliers(state => state.filter(item => item.id !== id));
      })
      .catch(() => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };
  const editHandler = async values => {
    return await services.supplierServices
      .editProjectSupplier(params?.id, values)
      .then(res => {
        setAlert({
          message: "Supplier status successfully changed.",
          type: "success"
        });
        setCurrentSuppliers(
          currentSuppliers.map(e =>
            e.id === values.supplier_id ? res?.data?.supplier : e
          )
        );
      })
      .catch(() => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };
  const searchHandler = debounce(term => {
    services.supplierServices
      .suppliersAutocomplete({query: term})
      .then(res => setSuppliers(res.data));
  }, 300);

  const onDeleteTag = async (tagId, supplierId) => {
    await services.tagsServices
      .deleteSupplierTag(supplierId, tagId)
      .then(() => {
        if (tag && tag.length > 0) {
          setSuppliers(state =>
            state.filter(supplier => supplier.id !== supplierId)
          );
          setTotal(state => state - 1);
        }
      });
  };

  const onAssignTag = async (tagId, supplierId) =>
    await services.tagsServices.assignSupplierTag(supplierId, tagId);

  return (
    <Fragment>
      {loading && <TableLoading length={4}/>}
      {!loading && (
        <Fragment>
          {currentSuppliers.length ? (
            <ProjectSuppliersTable
              currentSuppliers={currentSuppliers}
              removeHandler={removeHandler}
              history={history}
              openPopup={() => setShow(true)}
              havePermissions={permissions}
              handleEdit={editHandler}
              onDeleteTag={onDeleteTag}
              onAssignTag={onAssignTag}
            />
          ) : (
            <EmptyBlock
              subtitle={
                permissions.update
                  ? "You don’t have suppliers yet. You can add a new one"
                  : "You don’t have suppliers yet."
              }
              buttonTitle="Add supplier"
              onClick={() => setShow(true)}
              withBtn={permissions.update}
            />
          )}
          {show && (
            <Popup
              onClose={() => setShow(false)}
              title="Add supplier"
              text="Choose a supplier from existing ones"
            >
              <AddProjectSupplierForm
                suppliers={suppliers}
                currentSuppliers={currentSuppliers}
                addHandler={addHandler}
                onClose={() => setShow(false)}
                onSearch={searchHandler}
              />
            </Popup>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default SuppliersContainer;
