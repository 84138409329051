import React from "react";
import Textarea from "../../../../../elements/Textarea/Textarea";
import AdditionalHeader from "../AdditionalHeader/AdditionalHeader";
import PropTypes from "prop-types";
import styles from "./AdditionalTextField.module.scss";

const AdditionalTextField = ({values, setFieldValue, field, errors}) => {
  const {question, key} = field;
  return (
    <>
      <AdditionalHeader questionName={question.question_name} isRequired={question.is_required}/>
      <Textarea
        className={styles.textarea}
        label=""
        value={values[key]?.value || ""}
        onChange={e => setFieldValue(field.key, {
          value: e.target.value,
          question_id: question.question_id
        })}
        placeholder="Enter your answer"
        error={errors[field.key] && errors[field.key].value}
        count={{current: (values[key]?.value || "").length, max: 1000}}
      />
    </>
  );
};

export default AdditionalTextField;

AdditionalTextField.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any,
};