import React from "react"
import MainTable from "components/tables/MainTable/MainTable"
import {formatCurrency} from 'helpers/currencyFormat'
import {useHistory} from 'react-router-dom'

const Table = (
  {
    data = [],
    onEdit = () => {
    },
    source,
    className
  }
) => {

  const history = useHistory();

  return (
    <MainTable
      className={className}
      data={data}
      dataTypes={[
        {
          title: source?.[0]?.toUpperCase() + source?.slice(1),
          value: ({name, first_name, last_name}) => source === 'users' ? `${first_name} ${last_name}` : name,
          onClick: id => history.push(`/company-goals/${id}?source=${source?.slice(0, -1)}`),
        },
        {
          title: "Annual goal (USD)",
          value: ({goal}) => formatCurrency('$', goal?.annual_goal),
        },
        {
          title: "Q1 (USD)",
          value: ({goal}) => formatCurrency('$', goal?.quarter1 ?? 0),
        },
        {
          title: "Q2 (USD)",
          value: ({goal}) => formatCurrency('$', goal?.quarter2 ?? 0),
        },
        {
          title: "Q3 (USD)",
          value: ({goal}) => formatCurrency('$', goal?.quarter3 ?? 0),
        },
        {
          title: "Q4 (USD)",
          value: ({goal}) => formatCurrency('$', goal?.quarter4 ?? 0),
        },
      ]}
      options={item => {
        return [
          onEdit && {
            title: "Edit",
            onClick: () => {
              onEdit(item)
            }
          }
        ]
      }}
      totalCount={data.length}
    />
  )
}
export default Table
