import { string, object } from "yup";
import { localization } from "helpers/localizationForCompanies";

const PROJECT_TITLE_LIMIT = 30;
const MAX_DESCRIPTION_LENGTH = 180;

export const schemaGeneral = object({
  title: string()
    .required(`Please, type ${localization.title}`)
    .max(
      PROJECT_TITLE_LIMIT,
      `The ${localization.title} is too long. It should be 30 characters or less.`
    ),
  project_type: object({
    id: string().required("Please, choose project type")
  }),
  category_id: object({
    name: string().required(`Please, enter a ${localization.category}`),
    id: string()
  }),
  target_spend: string()
    .required("Please, type Target spend")
    .max(12, "Max digits quantity is 12")
});

export const schemaManagement = object({
  title: string()
    .required(`Please, type ${localization.title}`)
    .max(
      PROJECT_TITLE_LIMIT,
      `The ${localization.title} is too long. It should be 30 characters or less.`
    ),
  target_spend: string()
    .required("Please, type Target spend")
    .max(12, "Max digits quantity is 12"),
  project_type: object({
    id: string().required("Please, choose project type")
  }),
  category_id: object({
    name: string().required(`Please, enter a ${localization.category}`),
    id: string()
  }),
  description: string().max(
    MAX_DESCRIPTION_LENGTH,
    `The Description is too long. It should be ${MAX_DESCRIPTION_LENGTH} characters or less.`
  ),
  owner_id: object({
    name: string().required(`Please, enter ${localization.procurementOwner}`),
    id: string().required(
      "I can't find that person as a Procurement user"
    )
  }),
  start_date: string()
    .required("Start date is required")
    .nullable(),
  end_date: string()
    .required(`${localization.targetEndDate} is required`)
    .nullable()
});
