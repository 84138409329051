import React from "react";
import MainTable from "../MainTable/MainTable";
import Status from "../../elements/Status/Status";
import {formatDate} from "../../../helpers/dateFormat";
import {useHistory} from "react-router-dom";

const EmailsTable = ({data, onDisable}) => {
  const history = useHistory();

  return (
    <MainTable
      totalName="Total"
      totalCount={data.length}
      data={data}
      dataTypes={[
        {
          title: "Name",
          value: ({name}) => name,
          onClick: id => history.push(`/admin-settings/emails/${id}/editor`),
        },
        {
          title: "Type",
          value: ({event}) => event,
        },
        {
          title: "Status",
          value: ({status}) => {
            let preparedStatus = "archived";
            if (status === "enable") preparedStatus = "active";
            return (
              <Status
                status={preparedStatus}
                withTranslate
              />
            )
          },
        },
        {
          title: "Updated",
          value: ({updated}) => formatDate(updated, true),
        }
      ]}
      options={item => {
        return [
          {
            title: "Edit",
            onClick: () => history.push(`/admin-settings/emails/${item.id}/editor`),
          },
          {
            title: item.status === "enable" ? "Disable" : "Enable",
            onClick: () => onDisable(item.id, item.status === "enable" ? "disable" : "enable"),
          }
        ];
      }}
    />
  );
};

export default EmailsTable;