import React, {useContext} from "react";
import s from "./ConditionForm.module.scss";
import sBase from "../ApprovalForm.module.scss";
import PropTypes from "prop-types";
import {ReactComponent as Plus} from "../../../../assets/icons/btn-plus.svg";
import ConditionGroup from "./ConditionGroup/ConditionGroup";
import style from "../../../containers/AdminSettingsStepFieldDetailsContainer/components/StepList/StepList.module.scss";
import {ModeContext} from "../../../../contexts/ModeContext/ModeContext";
import {mods} from "../../../../contexts/ModeContext/mods";

const ConditionForm = (
  {
    conditions,
    addCondition,
    deleteCondition,
    addSingleCondition,
    changeCondition,
    changeSingleCondition,
    deleteSingleCondition,
    errors,
  }
) => {
  const {mode} = useContext(ModeContext);
  const isViewMode = mode === mods.view;

  return (
    <div className={s.conditionForm}>
      <div className={s.header}>
        <h3 className={sBase.formGroupTitle}>Conditions</h3>
        {
          !isViewMode && (
            <button className={s.addConditionBtn} onClick={e => {
              e.preventDefault();
              addCondition();
            }}>
              <Plus/>
            </button>
          )
        }
      </div>
      <div className={s.conditionGroupWrap}>
        {conditions.map((condition, index) => {
          return (
            <ConditionGroup
              key={condition.id}
              idx={++index}
              isLast={index === conditions.length}
              condition={condition}
              deleteCondition={deleteCondition}
              addSingleCondition={addSingleCondition}
              changeCondition={changeCondition}
              changeSingleCondition={changeSingleCondition}
              deleteSingleCondition={deleteSingleCondition}
            />
          );
        })}
      </div>
      {errors?.conditions && <p className={style.error}>{errors.conditions}</p>}
    </div>
  );
};

export default ConditionForm;

ConditionForm.propTypes = {
  addCondition: PropTypes.func,
  addSingleCondition: PropTypes.func,
  deleteCondition: PropTypes.func,
  changeCondition: PropTypes.func,
  conditions: PropTypes.array,
  changeSingleCondition: PropTypes.func,
  deleteSingleCondition: PropTypes.func,
};