import React from 'react'
import s from './Milestones.module.scss'
import {backgroundTypes} from "../../../../../helpers/helpData";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Milestones = ({data}) => {
  const {t} = useTranslation();
  return (
    <div className={s.milestonesWrap}>
      <h3 className={s.title} data-translate-key={getTranslateKey("Milestone")}>
        {t(getTranslateKey("Milestone"))}
      </h3>
      <ul className={s.milestones}>
        {data.map((milestone, index) => (
          <li
            className={s.milestone}
            style={{
              backgroundColor: backgroundTypes[milestone.color],
              width: `${100 / data.length}%`
            }}
            key={milestone.id}
          >
            {milestone.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Milestones
