import React, {useEffect, useState, useRef} from "react";
import s from "./ProjectsGanttChart.module.scss";
import classNames from "classnames";
import {formatData} from "./helpers/formatData";
import {formatCurrency} from "helpers/currencyFormat";
import {currencyData} from "helpers/helpData";
import {ReactComponent as Category} from "assets/icons/gantt-chart/category.svg";
import {getYearsByData} from "./helpers/getYearsByData";
import {getHeaderLabels, getMonthLabels} from "./helpers/getHeaderLabels";
import {getMinMaxDates} from "./helpers/getMinMaxDates";
import {getProjectPos} from "./helpers/getProjectPos";
import {formatDate} from "helpers/dateFormat";
import {ReactComponent as Check} from "assets/icons/gantt-chart/timeline-check.svg";
import ProjectLine from "./components/ProjectLine/ProjectLine";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import services from "../../../services";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const ProjectsGanttChart = ({data, selectedYears, showFullAction, isFullMode, monthsList, isUseFull}) => {
  const [years, setYears] = useState([]);
  const [timelineIdxs, setTimelineIdxs] = useState([]);
  const [parentScrollLeftPos, setParentScrollLeftPos] = useState(0);
  const [stages, setStages] = useState([]);
  const {t} = useTranslation();
  const parentRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    services.projectServices
      .getStages()
      .then(res => setStages(res?.data?.stages || []));
  }, []);

  useEffect(() => {
    if (selectedYears && selectedYears.length) {
      setYears(selectedYears);
    } else {
      setYears(getYearsByData(data));
    }
  }, [selectedYears]);

  const renderLabels = () => {
    if (monthsList?.length) {
      return getMonthLabels(monthsList).map((e, idx) => <span
        key={idx}
        className={s.label}
        data-translate-key={getTranslateKey(e)}
      >
        {t(getTranslateKey(e))}
      </span>);
    } else {
      return getHeaderLabels(years).map((e, idx) => <span
        key={idx}
        className={s.label}
        data-translate-key={getTranslateKey(e)}
      >
        {t(getTranslateKey(e))}
      </span>);
    }
  };

  return (
    <div className={s.chartContainer}>
      <div
        className={s.chartWrap}
        ref={parentRef}
        onScroll={() => {
          setParentScrollLeftPos(parentRef.current?.scrollLeft || 0);
        }}
      >
        <div className={s.chartInlineWrap}>
          <div className={s.header}>
            <span className={s.label} data-translate-key={getTranslateKey("Projects")}>
              {t(getTranslateKey("Projects"))}
            </span>
            <div className={s.headerInline}>
              {renderLabels()}
            </div>
          </div>
          <div className={s.chart}>
            {Object.keys(formatData(data)).map((e, index) => (
              <div key={index}>
                <div className={s.user}>{formatData(data)[e][0].owner.name}</div>
                <div className={s.projects}>
                  {formatData(data)[e].map((project, idx) => (
                    <div key={idx} className={s.projectWrap}>
                      <div className={s.projectDetails}>
                        <div className={s.top}>
                        <span
                          className={s.name}
                          onClick={() =>
                            history.push(`/projects/${project.id}`)
                          }
                        >
                          {project.name}
                        </span>
                          <span className={s.currency}>
                          {formatCurrency(
                            currencyData.filter(
                              e => e.label === project.target_spend_currency
                            )[0]?.value,
                            project.target_spend,
                            false
                          )}
                        </span>
                        </div>
                        <div className={s.category}>
                          <Category/>
                          <span>{project.category?.name}</span>
                        </div>
                      </div>
                      <div className={s.projectLineWrap}>
                        <ProjectLine
                          project={project}
                          useWithMonthReorder={monthsList?.length}
                          index={index}
                          idx={idx}
                          years={years}
                          onClick={() => setTimelineIdxs([idx, index])}
                          onCloseTimeLine={() => {
                            setTimelineIdxs([]);
                          }}
                          usersLength={Object.keys(formatData(data)).length}
                          projectsUserLength={formatData(data)[e]?.length}
                        />
                        {idx === timelineIdxs[0] && index === timelineIdxs[1] && (
                          <div
                            className={s.timeline}
                            style={{
                              width: parentRef.current?.offsetWidth
                                ? `${parentRef.current?.offsetWidth -
                                192 -
                                22 +
                                (parentScrollLeftPos > 214
                                  ? 214
                                  : parentScrollLeftPos)}px`
                                : "100%"
                            }}
                          >
                            <div className={s.lineWrap}>
                              {stages.map(
                                ({id}, index) => {
                                  return (
                                    <div
                                      key={id}
                                      className={classNames(s.statusBarWrap, {
                                        [s.active]: index + 1 <= project.stage.level
                                      })}
                                    >
                                      <span className={s.statusBar}>
                                        <Check/>
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <div className={s.statuses}>
                              {stages.map(
                                ({name}, index) => (
                                  <div key={index} className={s.col}>
                                    <span className={s.status}>{name}</span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={s.lines}>
            {(monthsList?.length ? getMonthLabels(monthsList) : getHeaderLabels(years)).map((_, index) => {
              return <span className={s.line} key={index}/>;
            })}
          </div>
          <div className={s.dateCursorWrap}>
            <div
              className={s.dateCursor}
              style={{
                left:
                  getProjectPos(
                    new Date().getTime(),
                    new Date(),
                    ...getMinMaxDates(years),
                    monthsList?.length,
                  ).left + "%"
              }}
            >
              <div className={s.date}>
                {formatDate(new Date(), true)}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        (showFullAction && isUseFull) && (
          <div className={s.additionalActions}>
            <div className={s.showFullAction}>
              <button
                className={s.btn}
                onClick={showFullAction}
                data-translate-key={getTranslateKey(isFullMode ? "Show less" : "Show more")}
              >
                {t(getTranslateKey(isFullMode ? "Show less" : "Show more"))}
              </button>
              {!isFullMode && (
                <p
                  className={s.notification}
                  data-translate-key={getTranslateKey("This action may affect page speed")}>
                  {t(getTranslateKey("This action may affect page speed"))}
                </p>
              )}
            </div>
          </div>
        )
      }

    </div>
  );
};
export default ProjectsGanttChart;

ProjectsGanttChart.propTypes = {
  data: PropTypes.any,
  selectedYears: PropTypes.string,
  showFullAction: PropTypes.func,
  isFullMode: PropTypes.bool,
  monthsList: PropTypes.array,
  isUseFull: PropTypes.bool,
};