import React from "react";
import Popup from "components/popups/Popup/Popup";

const EditSupplierPopup = ({ onClose, onDelete }) => {
  return (
    <Popup
      onClose={onClose}
      title="Delete supplier"
      text={"Are you sure you want to delete this supplier?"}
      onClick={onDelete}
      dialog
      isDelete
    ></Popup>
  );
};

export default EditSupplierPopup;
