import React, {useState, useEffect} from "react"
import s from "./SystemSettings.module.scss"
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput"
import Button from "components/elements/Button/Button"
import services from "services"
import {systemLanguages} from "../../../../../helpers/systemLanguages";
import CustomDropdown from "../../../../elements/CustomDropdown/CustomDropdown";

const SystemSettings = ({data, onChange}) => {
  const [timeZone, setTimeZone] = useState(data.time_zone ?? "");
  const [searchedTimeZones, setSearchedTimeZones] = useState([]);
  const [language, setLanguage] = useState(systemLanguages[0]);
  useEffect(() => {
    services.userServices
      .getTimeZoneAutocomplete({query: timeZone})
      .then(
        res =>
          res.data &&
          setSearchedTimeZones(res.data.map(name => ({name})) || [])
      )
  }, [timeZone])
  useEffect(() => {
    timeZone !== data.time_zone && setTimeZone(data.time_zone)
    language?.value !== data.language && setLanguage(systemLanguages.find(lang => lang.value === data.language))
  }, [data])
  const isDisabledBtn = data.time_zone === timeZone && data.language === language;
  return (
    <div className={s.systemSettings}>
      <AutoCompleteInput
        value={timeZone}
        onChange={e => setTimeZone(e.target.value)}
        onSelect={({name}) => setTimeZone(name)}
        data={searchedTimeZones}
        name='time_zone'
        label='Time zone'
        placeholder='Choose time zone'
        emptyText='time zone not founded'
        className={s.dropdown}
        withArrow={true}
      />
      <CustomDropdown
        value={language}
        onChange={e => setLanguage(e)}
        options={systemLanguages}
        name="language"
        label="Language"
        className={s.dropdown}
      />
      <div className={s.btnWrap}>
        <Button
          styled='primary'
          disabled={isDisabledBtn}
          onClick={() => onChange({time_zone: timeZone, language: language.value})}
          className={s.btn}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
export default SystemSettings
