import axios from "./axios"

export default {
    getDivisions: async params => {
        try {
            return await axios.get(`/admin/divisions`, { params })
        } catch (error) {
            throw error
        }
    },
    addDivision: async params => {
        try {
            return await axios.post(`/admin/divisions`, params)
        } catch (error) {
            throw error
        }
    },
    changeDivision: async (id, params) => {
        try {
            return await axios.put(`/admin/divisions/${id}`, params)
        } catch (error) {
            throw error
        }
    },
    deleteDivision: async id => {
        try {
            return await axios.delete(`/admin/divisions/${id}`);
        } catch (error) {
            throw error;
        }
    }
}
