import React from "react"
import MainTable from "components/tables/MainTable/MainTable"
import { fromDate } from "helpers/dateFormat"
const AdminStakeholderTypesTable = ({
  state,
  onSort,
  onPageChange,
  onAdd,
  onEdit,
  onDelete
}) => {
  return (
    <MainTable
      data={state.stakeholder_types}
      totalCount={state.stakeholder_types_count}
      perPage={state.per_page}
      page={state.page}
      onPageChange={onPageChange}
      isPagination={true}
      dataTypes={[
        {
          title: "Type",
          value: ({ name }) => name || "",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "name", direction })
          }
        },
        {
          title: "Updated",
          value: ({ updated_at }) =>
            updated_at ? fromDate(updated_at) : "-",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "updated_at", direction })
          }
        }
      ]}
      button={{ name: "New Type", onClick: onAdd }}
      options={item => [
        {
          title: "Edit",
          onClick: () => onEdit(item)
        },
        {
          title: "Delete",
          onClick: () => onDelete(item.id)
        }
      ]}
    />
  )
}
export default AdminStakeholderTypesTable
