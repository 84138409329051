import React from 'react';
import s from "./Conditions.module.scss";
import {FormControlLabel, Radio} from "@material-ui/core";
import {getOperatorName} from "./helpers/getOperatorName";
import SingleCondition from "./SingleCondition";

const ConditionGroup = ({conditionGroup, index}) => {
  return (
    <div className={s.conditionGroup}>
      <div className={s.header}>
        <div className={s.subHeader}>
          <h2 className={s.groupTitle}>Conditions group {++index}</h2>
        </div>
        <div className={s.subHeader}>
          <h2 className={s.groupTitle}>Current value</h2>
        </div>
      </div>
      {
        conditionGroup.conditions.map((singleCondition, index) => (
          <SingleCondition
            singleCondition={singleCondition}
            key={index}
          />
        ))
      }
      {
        conditionGroup.operator && (
          <FormControlLabel
            className={s.operator}
            checked={true}
            value={getOperatorName(conditionGroup.operator)}
            control={<Radio color="primary"/>}
            label={getOperatorName(conditionGroup.operator)}
          />
        )
      }
    </div>
  );
};

export default ConditionGroup;