import {string, object} from "yup";
import {currencyData} from "helpers/helpData";
import {load} from "../../../../helpers/localStorage";
import {localization} from "../../../../helpers/localizationForCompanies";

export const MAX_DESCRIPTION_LENGTH = 180;
const user = load("currentUser");
const currentDate = new Date();
export const initialValues = {
  category_id: {name: "", id: ""},
  project_type: {},
  stage: {},
  name: "",
  budget_value: "",
  description: "",
  budget_currency: currencyData.filter(e => e.label === "USD")[0],
  owner_id: {name: user?.name, id: user?.id},
  start_date: new Date(),
  end_date: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
  target_spend_currency: currencyData.filter(e => e.label === "USD")[0],
  target_spend: "",
  attachments: [],
  clients: {},
  suppliers: [],
  stakeholders: {},
};

export const validationSchema = {
  name: string()
    .required("Please, enter a project name")
    .max(50, "Project name should be 50 characters or less.")
    .matches(/^(?!\s+$).*/, "Please, enter a project name"),
  category_id: object({
    id: string().required("Please, choose category")
  }),
  project_type: object({
    id: string().required("Please, choose project type")
  }),
  description: string().max(
    MAX_DESCRIPTION_LENGTH,
    `Please enter task note that consists of no more than ${MAX_DESCRIPTION_LENGTH} characters`
  ),
  start_date: string()
    .required("Start date is required").nullable(),
  end_date: string()
    .required("Target end date is required").nullable(),
  owner_id: object({
    name: string().required(
      `Please, enter ${localization.procurementOwner}`
    ),
    id: string().required(
      `Please, choose exist ${localization.procurementOwner}`
    )
  }),
};
