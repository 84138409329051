import React from 'react';
import s from "./TeamTreeDropDown.module.scss";
import TeamItem from "./TeamItem";

const TeamTreeDropDown = ({data}) => {
  return (
    <div className={s.scrollContainer}>
      -
      {/*<div className={s.teamTreeWrap}>*/}
      {/*  {data.map(item => <TeamItem data={item} key={item.id}/>)}*/}
      {/*</div>*/}
    </div>
  );
};

export default TeamTreeDropDown;