import React from "react";
import PropTypes from "prop-types";
import Popup from "components/popups/Popup/Popup.jsx";
import styles from "./TemplatesDelPopUp.module.scss";
import { ReactComponent as Warning } from "assets/icons/warning-icon.svg";

export const TemplatesDelPopUp = ({ setDelResPopUp, item, onDeleteRestore }) => {
  const isDelete = item.action === "Delete";

  const handleOnCancel = () =>
    setDelResPopUp((prevPopUp) => ({ checked: !prevPopUp.checked, action: "", item: {} }));

  const handleDeleteRestore = () => {
    onDeleteRestore(item.item);
    handleOnCancel();
  };

  return (
    <Popup
      className={styles.wrapper}
      title={`${item.action} template`}
      text={
        isDelete
          ? "Are you sure you want to delete this template? It will remain in all previous surveys, but not in new ones. You can restore it later."
          : "Are you sure you want to restore this template?"
      }
      onClose={handleOnCancel}
      successBtnTitle={item.action}
      onClick={handleDeleteRestore}
      isDelete
      dialog>
      {isDelete && <Warning />}
    </Popup>
  );
};

TemplatesDelPopUp.propTypes = {
  setDelResPopUp: PropTypes.func,
  item: PropTypes.object,
  onDeleteRestore: PropTypes.func,
};
