import React, {useState, useRef, useEffect} from "react";
import classNames from "classnames";
import s from "./BarDropDown.module.scss";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";
import useOutsideClicker from "hooks/useOutsideClicker";
import {CircularProgress} from "@material-ui/core";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslate} from "../../../hooks/useTranslate";
import {useTranslation} from "react-i18next";

const BarDropDown = (
  {
    name,
    handleChange,
    options,
    defaultValues,
    className,
    typeActions = false,
  }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(defaultValues);
  const dropdownRef = useRef(null);
  const {t} = useTranslation();
  useOutsideClicker(dropdownRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setCurrent(defaultValues);
  }, [defaultValues]);


  const handleSelect = (item) => {
    setCurrent(item);
    handleChange(item);
    setIsOpen(false);
  };

  const renderCurrent = () => {
    if (typeActions) return <p data-translate-key={getTranslateKey(name)}>{t(getTranslateKey(name))}</p>;
    return current ? (
      <p data-translate-key={getTranslateKey(current.name)}>{t(getTranslateKey(current.name))}</p>
    ) : (
      <p data-translate-key={getTranslateKey(name)}>{t(getTranslateKey(name))}</p>
    );
  }

  return (
    <div ref={dropdownRef} className={classNames(s.dropDownWrapper, className)}>
      <div onClick={() => setIsOpen(!isOpen)} className={classNames(s.activePanel, {[s.active]: isOpen})}>
        {renderCurrent()}
        <span className={classNames(s.arrow, {[s.active]: isOpen})}>
          <Arrow/>
        </span>
      </div>
      {isOpen && (
        <div className={s.openedList}>
          <ul className={s.expiresInWrap}>
            {options ? (
              options.map((item, index) => {
                return (
                  <li
                    className={classNames(s.item, {
                      [s.active]: current === item && !typeActions,
                    })}
                    onClick={() => handleSelect(item)}
                    key={index}
                    data-translate-key={getTranslateKey(item.name)}
                  >
                    {t(getTranslateKey(item.name))}
                  </li>
                )
              })
            ) : (
              <div className="loaderWrap justifyCenter" style={{marginTop: "24px"}}>
                <CircularProgress/>
              </div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
export default BarDropDown;
