import React, { useEffect, useState } from "react";
import services from "services";
import s from "./Suppliers.module.scss";
import classNames from "classnames";
import { useParams, useHistory } from "react-router-dom";
import ProjectReportSuppliersTable from "components/tables/ProjectReportSuppliersTable/ProjectReportSuppliersTable";
import querystring from "query-string";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";

const Suppliers = ({ className }) => {
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const history = useHistory();
  const query = querystring.parse(history.location.search) || {};
  useEffect(() => {
    services.reportServices
      .getProjectReportsSuppliers(params.id)
      .then((res) => {
        setData(res.data.suppliers);
        setDataCount(res.data.suppliers_count);
        setLoading(false);
      });
  }, [params.id]);
  return (
    <div className={classNames(s.wrap, className)}>
      <h3 className="subtitle">Suppliers</h3>
      {loading && <TableLoading length={7} />}
      {!loading && data.length > 0 && (
        <ProjectReportSuppliersTable
          data={data}
          dataCount={dataCount}
          currentPage={query.page}
          perPage={30}
        />
      )}
      {!loading && !data.length && (
        <EmptyBlock subtitle="No work item results found" withBtn={false} />
      )}
    </div>
  );
};

export default Suppliers;
