import React, {useEffect, useState, useCallback} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {debounce} from "lodash";
import s from "./AddProjectForm.module.scss";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import DatePicker from "components/elements/DatePicker/DatePicker";
import {useFormik} from "formik";
import {object, string} from "yup";
import {formatDate} from "helpers/dateFormat";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import DropdownTree from "components/elements/DropdownTree/DropdownTree";
import services from "services";
import {localization} from "helpers/localizationForCompanies";
import {load} from "helpers/localStorage";
import CurrencySelectionField from "components/elements/CurrencySelectionField/CurrencySelectionField";
import {currencyData as currencies} from "helpers/helpData";
import SelectCheckDropdown from "components/elements/SelectCheckDropdown/SelectCheckDropdown";
import {
  formatEntityData,
  formatEntityToObject
} from "../../../helpers/formatEntityData";
import { formatCheckedClients } from "components/forms/EditProjectForm/helpers/formatCheckedClients";

const AddProjectForm = ({
  onClose,
  onSubmit,
  category,
  defaultData,
  withTargetSpend = true,
  defaultCurrency,
  isGlobalProject = false,
  defaultType
}) => {
  const [stages, setStages] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [checkedClients, setCheckedClients] = useState([]);
  const PROJECT_TITLE_LIMIT = 50;
  const user = load("currentUser");
  useEffect(() => {
    services.categoriesServices.getCategories().then(res => {
      setCategories(res.data);
    });
    services.userServices
      .getDepartments()
      .then(res => setDepartments(formatEntityData(res?.data || []) || []));
    services.projectServices.getStages().then(res => {
      setStages(
        res.data?.stages?.map(({ id, name }) => ({
          id,
          label: name,
          value: name
        })) || []
      );
    });
    services.projectServices.getProjectTypes().then(res => {
      const result = res.data?.project_types?.map(({ id, name }) => ({
        id,
        label: name,
        value: name
      })) || [];
      setProjectTypes(
        result
      );
      result.length && defaultType && setFieldValue("project_type", result.find((type) => type.value?.toLowerCase() === defaultType));
    });
  }, []);
  useEffect(() => {
    departments.length &&
      isGlobalProject &&
      defaultData?.client &&
      setCheckedClients(
        formatCheckedClients(defaultData?.client, departments) || []
      );
  }, [departments]);
  const formik = useFormik({
    initialValues: {
      category_id: defaultData?.category_id || category || { name: "", id: "" },
      project_type: defaultData?.project_type || {},
      title: defaultData?.title || "",
      owner_id: defaultData?.owner_id || { name: user.name, id: user.id },
      start_date: defaultData?.start_date || new Date(),
      end_date: defaultData?.end_date || new Date(),
      target_spend: defaultData?.target_spend || "",
      currency:
        defaultData?.target_spend_currency ||
        currencies.filter(e => e.label === (defaultCurrency || "USD"))[0],
      client: defaultData?.client || {}
    },
    validationSchema: object({
      category_id: object({
        name: string().required(`Please, enter a ${localization.category}`),
        id: string()
      }),
      project_type: object({
        id: string().required("Please, choose project type")
      }),
      owner_id: object({
        name: string().required(
          `Please, enter ${localization.procurementOwner}`
        ),
        id: string().required(
          `Please, choose exist ${localization.procurementOwner}`
        )
      }),
      title: string()
        .required(`Please, type ${localization.title}`)
        .max(
          PROJECT_TITLE_LIMIT,
          `The ${localization.title} is too long. It should be 30 characters or less.`
        ),
      target_spend:
        withTargetSpend &&
        string()
          .required("Please, type Target spend")
          .min(1, "Min digits quantity is 1")
          .max(12, "Max digits quantity is 12"),
      start_date: string()
        .required("Start date is required")
        .nullable(),
      end_date: string()
        .required(`${localization.targetEndDate} is required`)
        .nullable()
    }),
    validate: ({ client, target_spend }) => {
      const errors = {};
      if (!Object.keys(client).length) {
        errors.client = "Please, choose client";
      }
      if(target_spend < 0) {
        errors.target_spend = "Please, type valid Target spend";
      }
      return errors;
    },
    onSubmit: values => {
      onSubmit(
        {
          category_id: values.category_id.id,
          project_type_id: values.project_type.id,
          name: values.title,
          owner_id: values.owner_id.id,
          start_date: formatDate(values.start_date),
          end_date: formatDate(values.end_date),
          department_ids: checkedClients.map(e => e.id).join(","),
          target_spend: values.target_spend,
          target_spend_currency: values.currency?.label
        },
        {
          category: values.category_id,
          project_type: values.project_type,
          name: values.title,
          owner: values.owner_id,
          start_date: formatDate(values.start_date),
          end_date: formatDate(values.end_date),
          target_spend: values.target_spend,
          target_spend_currency: values.currency,
          departments: checkedClients
        }
      );
    }
  });
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur
  } = formik;
  const searchHandler = useCallback(
    debounce(
      term =>
        services.userServices
          .usersAutocomplete({ query: term })
          .then(res => setUsers(res.data)),
      300
    ),
    []
  );

  return (
    <form
      className={s.addProjectForm}
      onSubmit={handleSubmit}
      autoComplete='off'
    >
      <DropdownTree
        withSearch={true}
        value={values.category_id.name}
        defaultValue={values.category_id || category}
        onChange={e =>
          setFieldValue("category_id", {
            ...values.category_id,
            name: e.target.value
          })
        }
        onSelect={value => {
          setFieldValue("category_id", {
            id: value.id,
            name: value.name
          });
        }}
        name='category_id'
        className={s.field}
        options={categories}
        placeholder={`Enter a ${localization.category}`}
        label='Category'
        error={
          errors.category_id && touched.category_id && errors.category_id.name
        }
      />
      <CustomDropdown
        className={s.field}
        options={projectTypes}
        value={values.project_type}
        onChange={e => setFieldValue("project_type", e)}
        placeholder='Choose a type'
        label='Project type'
        error={
          errors.project_type && touched.project_type && errors.project_type.id
        }
      />
      <Input
        value={values.title}
        onChange={handleChange}
        name='title'
        className={classNames(s.titleField, {
          [s.projectTitleWrap]: errors.title && touched.title && errors.title
        })}
        type='text'
        placeholder={`Enter ${localization.title}`}
        label={`${localization.title}`}
        onBlur={handleBlur}
        error={errors.title && touched.title && errors.title}
        limitValue={PROJECT_TITLE_LIMIT}
        withCounter
      />
      <CurrencySelectionField
        className={s.field}
        options={currencies}
        value={values.target_spend}
        onChange={({ fieldVal, value, label }) => {
          const currency = {
            value,
            label
          };
          currency.value && setFieldValue("currency", currency);
          setFieldValue("target_spend", fieldVal);
        }}
        defaultValue={values.currency.value}
        error={errors.target_spend}
        name='target_spend'
        label='Target spend'
        fieldPlaceholder='000,000'
      />
      <SelectCheckDropdown
        label='Client'
        value={values.client}
        onChange={(e, item) => {
          setFieldValue("client", e);
          item.checked
            ? setCheckedClients([item, ...checkedClients])
            : setCheckedClients(checkedClients.filter(e => e.id !== item.id));
        }}
        options={departments}
        placeholder='Start typing client name'
        onSearch={data => {
          setFieldValue("client", formatEntityToObject(data));
          setCheckedClients(data);
        }}
        emptyMessage='clients not found'
        withEndComma={true}
        className={s.field}
        error={errors.client && touched.client && errors.client}
      />
      <AutoCompleteInput
        value={values.owner_id.name}
        onChange={e => {
          setFieldValue("owner_id", {
            id: "",
            name: e.target.value
          });
          searchHandler(e.target.value);
        }}
        onSelect={value => {
          setFieldValue("owner_id", {
            id: value.id,
            name: value.name
          });
        }}
        name='owner_id'
        className={s.field}
        data={users}
        placeholder={`Enter ${localization.procurementOwner}`}
        label={`${localization.procurementOwner}`}
        error={
          errors.owner_id &&
          touched.owner_id &&
          (errors.owner_id.name || errors.owner_id.id)
        }
      />
      <div className='dateBlock'>
        <DatePicker
          label='Start date'
          selected={values.start_date}
          onChange={date => {
            setFieldValue("start_date", date);
            date > values.end_date && setFieldValue("end_date", date);
          }}
          selectsStart
          startDate={values.start_date}
          endDate={values.end_date}
          placeholderText='Choose start date'
          wrapperClassName='wrapperdatePicker'
          className='customInput'
          todayButton='Go to today'
          showYearDropdown
          dateFormatCalendar='MMMM'
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.start_date && errors.start_date}
        />
        <DatePicker
          label={localization.targetEndDate}
          selected={values.end_date}
          onChange={date => setFieldValue("end_date", date)}
          selectsEnd
          startDate={values.start_date}
          endDate={values.end_date}
          minDate={values.start_date}
          placeholderText={`Choose ${localization.targetEndDate}`}
          wrapperClassName='wrapperdatePicker'
          className='customInput'
          todayButton='Go to today'
          showYearDropdown
          dateFormatCalendar='MMMM'
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.end_date && errors.end_date}
        />
      </div>
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          {defaultData ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};
AddProjectForm.propTypes = {
  handleSubmit: PropTypes.func
};
AddProjectForm.defaultProps = {
  handleSubmit: () => {}
};
export default AddProjectForm;
