import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { move } from "./helpers/helpers";
import GanttChart from "./components/GanttChart/GanttChart";

const DragAndDropGanttCharts = ({ data, onDrop }) => {
  const [state, setState] = useState({});
  const getList = (id) => state[id];

  useEffect(() => {
    setState(
      data.reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {})
    );
  }, [data]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      return;
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      setState((state) => ({
        ...state,
        ...{
          [source.droppableId]: result[source.droppableId],
          [destination.droppableId]: result[destination.droppableId],
        },
      }));
      onDrop(
        getList(source.droppableId).projects[source.index].id,
        destination.droppableId
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {Object.keys(state).map((droppableId) => (
        <Droppable droppableId={droppableId} key={droppableId}>
          {(provided) => (
            <div ref={provided.innerRef}>
              <GanttChart
                projects={state[droppableId]?.projects}
                ownerName={state[droppableId]?.name}
                months={state[droppableId]?.utilizations?.utilization}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  );
};
export default DragAndDropGanttCharts;
