import React from "react";
import s from "./Files.module.scss";
import classNames from "classnames";
import {ReactComponent as Delete} from "assets/icons/clear.svg";
import {getFileIconByType} from "helpers/fileIconsByType";

const Files = ({files, onDelete, withLink = false, disabled, className}) => {
  const formatFileName = name =>
    name
      ?.split(".")
      .slice(0, -1)
      .join(".");
  return (
    <ul className={classNames(s.files, className)}>
      {files?.map((file, index) => (
        <li key={index} className={classNames(s.uploadedFile, className)}>
          {getFileIconByType(file.type || file.file_type)}
          {withLink ? (
            <a
              className={s.fileName}
              href={file.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatFileName(file.name)}
            </a>
          ) : (
            <span className={s.fileName}>{formatFileName(file.name)}</span>
          )}
          {onDelete && (
            <Delete
              className={s.deleteFile}
              onClick={() => !disabled && onDelete(file, index)}
            />
          )}
        </li>
      ))}
    </ul>
  );
};
export default Files;
