import React from "react"
import Popup from "components/popups/Popup/Popup"
import AdminTaskRolesForm from "components/forms/AdminTaskRolesForm/AdminTaskRolesForm"
const EditPopup = ({ onClose, onSubmit, defaultValues }) => {
  return (
    <Popup onClose={onClose} title='Edit role'>
      <AdminTaskRolesForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
    </Popup>
  )
}
export default EditPopup
