import React from "react";
import Popup from "components/popups/Popup/Popup";
import ChangeUserForm from "components/forms/ChangeUserForm/ChangeUserForm";

const AddUserPopup = ({onSubmit, onClose, filters, divisions}) => {
  return (
    <Popup title="New user" onClose={onClose}>
      <ChangeUserForm
        onSubmit={onSubmit}
        divisions={divisions}
        onClose={onClose}
        filters={filters}
      />
    </Popup>
  );
};
export default AddUserPopup;
