import React, {useState} from 'react';
import s from "./QuestionNotification.module.scss";
import {ReactComponent as QuestionIcon} from "assets/icons/question.svg";
import {useTranslate} from "../../../hooks/useTranslate";
import classNames from "classnames";

const CustomNotification = (
  {
    value,
    Icon,
    component,
    onClick,
    withTranslate = false,
    text,
    textClassName,
    className,
  }
) => {

  const [isVisible, setVisible] = useState(false);
  const [translateKey, translateValue] = useTranslate(value);

  const renderContent = () => {
    if (text) return (
      <div
        className={textClassName}
        onMouseMove={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        {text}
      </div>
    );

    if (component) return (
      <div
        onMouseMove={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        {component}
      </div>
    );

    return Icon ?
      <Icon
        onMouseMove={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        onClick={() => onClick && onClick()}
      /> :
      <QuestionIcon
        onMouseMove={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        onClick={() => onClick && onClick()}
      />
  }

  return (
    <div className={classNames(s.questionNotification, className)}>
      {renderContent()}
      {
        isVisible && (
          <div className={s.notification} data-translate-key={withTranslate && translateKey}>
            {withTranslate ? translateValue : value}
          </div>
        )
      }
    </div>
  );
};

export default CustomNotification;