import React from "react";
import s from "../MainDashboard.module.scss";
import Notifications from "../Notifications/Notifications";
import Tasks from "../Tasks/Tasks";
import StaticWidgetsForBusinessUser from "../StaticWidgetsForBusinessUser/StaticWidgetsForBusinessUser";
import GanttChartDashboard from "../GanttChart/GanttChartDashboard";
import Categories from "../Categories/Categories";
import Suppliers from "../Suppliers/Suppliers";

const IndividualDashboardBS = () => {
  return (
    <>
      <div className={s.group}>
        <Notifications/>
        <Tasks/>
      </div>
      <div className={s.staticWidgets}>
        <StaticWidgetsForBusinessUser/>
      </div>
      <div className={s.ganttChart}>
        <GanttChartDashboard/>
      </div>
      <div className={s.categories}>
        <Categories/>
      </div>
      <div className={s.suppliers}>
        <Suppliers/>
      </div>
    </>
  );
};

export default IndividualDashboardBS;
