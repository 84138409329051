import React, {useState} from "react";
import s from "./ChangeAdminApprovalGroupForm.module.scss";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import SelectCheckDropdown from "components/elements/SelectCheckDropdown/SelectCheckDropdown";
import {useFormik} from "formik";
import {object, string} from "yup";

const formatApprovers = (data) => {
  const obj = {};
  data.map((e) => (obj[e.label] = true));
  return obj;
};

const formatDefaultApprovers = (data = []) => {
  const obj = {};
  data.map((e) => (obj[e.name] = true));
  return obj;
};

const ChangeAdminApprovalGroupForm = (
  {
    onClose,
    onSubmit,
    defaultValues,
    approvers,
  }
) => {
  const [checkedAprovers, setCheckedApprovers] = useState(
    defaultValues?.approvers || []
  );
  const MAX_NAME_LENGTH = 50;
  const emptyValues = {
    name: "",
    approvers: {},
  };
  const defaultVals = {
    ...defaultValues,
    approvers: formatDefaultApprovers(defaultValues?.approvers),
  };

  const formik = useFormik({
    initialValues: defaultValues ? defaultVals : emptyValues,
    validationSchema: object({
      name: string()
        .required("Group name is required")
        .trim()
        .max(
          MAX_NAME_LENGTH,
          `The group name is too long. It should be ${MAX_NAME_LENGTH} characters or less.`
        ),
    }),
    validate: () => {
      let errors = {};
      if (!checkedAprovers.length) errors.approvers = "Approvers is required";
      return errors;
    },
    onSubmit: ({name}) => {
      onSubmit({
        name,
        approvers: checkedAprovers.map((e) => e.id).join(","),
      });
    },
  });
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
  } = formik;
  return (
    <form onSubmit={handleSubmit} className={s.form} autoComplete="off">
      <Input
        value={values.name}
        onChange={handleChange}
        name="name"
        className={s.field}
        type="text"
        placeholder="Enter group name"
        label="Group name"
        onBlur={handleBlur}
        error={errors.name && touched.name && errors.name}
      />
      <SelectCheckDropdown
        label="Approvers"
        className={s.dropdown}
        options={approvers.sort((a, b) => a.label.localeCompare(b.label))}
        value={values.approvers}
        onChange={(e, item) => {
          setFieldValue("approvers", e);
          item.checked
            ? setCheckedApprovers([item, ...checkedAprovers])
            : setCheckedApprovers(
              checkedAprovers.filter((e) => e.id !== item.id)
            );
        }}
        placeholder="Start typing approval’s name"
        valueFormat="all"
        onSearch={(data) => {
          setFieldValue("approvers", formatApprovers(data));
          setCheckedApprovers(data);
        }}
        emptyMessage="Approvers not found"
        error={errors.approvers && touched.approvers && errors.approvers}
        withEndComma={true}
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {defaultVals.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </form>
  );
};
export default ChangeAdminApprovalGroupForm;
