export const getPieChartWidgetTitle = (selectedYears, chartData) => {

  const currentYear = String(new Date().getFullYear());
  const ALLOWANCE_MONTHS_COUNT = chartData?.allowance_months_count || 0,
    ALLOWANCE_RULE = chartData?.allowance_rule || "";

  if (
    (ALLOWANCE_RULE === "full" && selectedYears) ||
    (!ALLOWANCE_RULE && selectedYears)
  ) {
    return `Total department impact of ${selectedYears?.join(", ") ||
    "all"} Activity`
  } else if (ALLOWANCE_RULE === "months" && selectedYears) {
    return `${ALLOWANCE_MONTHS_COUNT} months department impact of ${selectedYears?.join(
      ", "
    ) || "all"} Activity`
  } else if (ALLOWANCE_RULE === "months" && !selectedYears) {
    return `${ALLOWANCE_MONTHS_COUNT} months department impact of all activities`
  } else if (ALLOWANCE_RULE === "full" && !selectedYears) {
    return `Total department impact of all activities`
  } else {
    return `${currentYear} department impact of ${currentYear} Activity`
  }
}