import React, {useEffect, useState} from "react";
import FilterBar from "../../../../../elements/FilterBar/FilterBar";
import services from "../../../../../../services";
import FilterBarDropdownDepartment from "./FilterBarDropdownDepartment";
import qs from "query-string";
import {useHistory} from "react-router-dom";

const Filter = ({filter}) => {
  const history = useHistory();
  const [savedFilter, setSavedFilter] = useState(null);

  const {...query} = qs.parse(
    history.location.search
  );

  useEffect(() => {
    services.filterService.getFilter("dashboard").then(res => {
      if (res.data && res.data[0]) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      }
    });
  }, []);

  const handleChangeFilter = (filterName, values) => {
    const searchQuery = {
      [filterName]: values.id,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleClearAll = () => {
    history.push({search: qs.stringify()});
  };

  const saveDefaultFilter = () => {
    services.filterService.addFilter("dashboard", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  };

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  };

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...JSON.parse(filterParams ?? savedFilter?.filter_params),
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  return (
    <FilterBar
      filterName="Filter information"
      savedFilter={savedFilter}
      handleClearAll={handleClearAll}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
    >
      <FilterBarDropdownDepartment
        name="Teams"
        options={filter.teams}
        handleChange={value => handleChangeFilter("team_id", value)}
        defaultFilterValue={query.team_id ? filter?.teams?.find(d => d.id === query.team_id) : null}
      />
    </FilterBar>
  );
};

export default Filter;


