import axios from "./axios";

export default {
  getSurveys: async (id, params) => {
    try {
      const result = await axios.get(`/projects/${id}/survey_results`, {
        params,
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getSurveyResults: async (id) => {
    try {
      const result = await axios.get(
        `/projects/${id}/survey_results/full_index`
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
  updateSurvey: async (id, surveyId, params) => {
    try {
      const result = await axios.put(
        `/projects/${id}/survey_results/${surveyId}`,
        params
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
  getSurvey: async (id, surveyId) => {
    try {
      const result = await axios.get(
        `/projects/${id}/survey_results/${surveyId}`
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
  sendSurvey: async (id) => {
    try {
      const result = await axios.get(
        `/projects/${id}/survey_results/send_survey`
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
};
