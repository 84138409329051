import {getConditionResult} from "./getConditionResult";

const getOptions = field => {
  if (field.field_type !== "Custom Dropdown") return [];
  if (typeof field.custom_dropdown.options[0] === "object") return field.custom_dropdown.options;
  return field.custom_dropdown.options.map(o => ({name: o, id: o}));
};

const getValue = (field, defaultFields) => {
  if (defaultFields) {
    const preparedField = defaultFields.find(f => f.id === field.id);
    if (preparedField) {
      return {
        conditions: preparedField.step_field.conditions,
        label: preparedField.value?.name || preparedField.value,
        type: preparedField.step_field.field_type,
        value: preparedField.value?.id || preparedField.value,
      };
    }
    return "";
  }
  return field.field_type === "Boolean" ? false : "";
};

export const setNewSteps = (steps, newSteps, defaultRequest, values, isAll = false) => (
  [
    steps[0], ...newSteps
    .filter((step, index) => {
      if (isAll) return Boolean(index !== 0 && index + 1 !== newSteps.length && step.step_fields.length);

      if (values && index !== 0 && index + 1 !== newSteps.length && step.step_fields.length) {
        return Boolean(step.step_fields.filter(field => {
          if (Boolean(field.conditions) && field.conditions !== "()") {
            return eval(getConditionResult(field.conditions, values));
          }
          return true;
        }).length);
      }
      return false;
    })
    .map(step => {
      return {
        fields: step.step_fields
          .sort((a, b) => a.position - b.position)
          .map(field => ({
            key: field.id,
            type: field.field_type,
            value: getValue(field, defaultRequest?.fields),
            label: field.name,
            conditions: field.conditions,
            optional: field.optional,
            options: getOptions(field)
          })),
        name: step.name,
        id: step.id,
      };
    }),
    steps[steps.length - 1]
  ]
);