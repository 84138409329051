import React from "react"
import s from "./Score.module.scss"
import GaugeChartWidget from "components/elements/GaugeChartWidget/GaugeChartWidget"
import ColumnWidget from "./ColumnWidget/ColumnWidget"

const Score = ({ nps_score = 0, score_types }) => {
  return (
    <div className={s.scoreWrap}>
      <GaugeChartWidget nps_score={nps_score} />
      <div className={s.columnWidgetWrap}>
        <ColumnWidget score_types={score_types} />
      </div>
    </div>
  )
}

export default Score
