import axios from "./axios"

export default {
  getMilestones: async (id, params) => {
    try {
      const result = await axios.get(`/projects/${id}/milestones`, { params })
      return result
    } catch (error) {
      throw error
    }
  },
  getMilestone: async (projectId, id) => {
    try {
      const result = await axios.get(`/projects/${projectId}/milestones/${id}`)
      return result
    } catch (error) {
      throw error
    }
  },

  addMilestone: async (id, params) => {
    try {
      return await axios.post(`/projects/${id}/milestones`, params)
    } catch (error) {
      throw error
    }
  },
  editMilestone: async (projectId, id, params, config) => {
    try {
      const result = await axios.put(
        `/projects/${projectId}/milestones/${id}`,
        params,
        config
      )
      return result
    } catch (error) {
      throw error
    }
  },
  deleteMilestone: async (projectId, id, config) => {
    try {
      const result = await axios.delete(
        `/projects/${projectId}/milestones/${id}`,
        config
      )
      return result
    } catch (error) {
      throw error
    }
  },
  changeMilestonesDuration: async (projectId, params) => {
    try {
      return await axios.put(`/projects/${projectId}/milestones/duration`, params)
    } catch (error) {
      throw error
    }
  }
}
