import React, { useEffect } from 'react'
import {
	Route,
	Redirect,
	withRouter,
	useLocation,
	useHistory
} from 'react-router-dom'
import { useSelector, connect } from 'react-redux'
import { paths } from 'routes/paths'
import { logout } from 'actions/user'
import { userRoles } from 'helpers/userPermissions/userRoles'
import { setLocation } from 'actions/lastLocation'
import { useDispatch } from 'react-redux'

const includeRoles = (activeUserRoles, allowed) => {
	if (!Boolean(activeUserRoles.length)) return false

	const activeUserRolesArray = activeUserRoles.split(',')
	const isAllowedToUser = activeUserRolesArray.some(role => {
		return allowed.includes(role)
	})

	return isAllowedToUser
}

const PrivateRoute = ({
	allowed,
	exact,
	path,
	Component,
	logout,
	allowedUserTypes
}) => {
	const location = useLocation()
	const history = useHistory()
	const user = useSelector(state => state.user)
	const dispatch = useDispatch()
	const isAllowedRole = React.useMemo(() => {
		return allowed
			? includeRoles(user?.roles || userRoles.guest, allowed)
			: true
	}, [user.roles, allowed])
	const isAllowedType = React.useMemo(() => {
		return allowedUserTypes ? allowedUserTypes.includes(user.user_type) : true
	}, [user.user_type, allowedUserTypes])
	useEffect(() => {
		dispatch(setLocation(location))
		if (user?.roles) {
			const timerId = setTimeout(() => {
				logout().then(() => history.push('/login'))
			}, 1800000)
			return () => clearTimeout(timerId)
		}
	}, [location, history, logout, user.roles])

	if (!isAllowedRole || !isAllowedType) {
		if (user.roles) {
			return <Redirect to={paths.profile} />
		} else {
			return <Redirect to={paths.login} />
		}
	}
	return (
		<Route
			exact={exact}
			path={path}
			render={props => <Component {...props} />}
		/>
	)
}

export default connect(null, { logout })(withRouter(PrivateRoute))
