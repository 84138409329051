import axios from "./axios";
import {remove, save} from "helpers/localStorage";

export default {
  login: async params => {
    try {
      const result = await axios.post("/sessions", params);
      if (result.status === 200) {
        axios.defaults.headers[
          "Authorization"
          ] = `Bearer ${result.data.access_token}`;
      }
      save("accessToken", result.data.access_token);
      return result;
    } catch (error) {
      throw error;
    }
  },
  logout: async () => {
    try {
      await axios.delete("/sessions");
      delete axios.defaults.headers["Authorization"];
      remove("accessToken");
      remove("currentUser");
    } catch (error) {
      throw error;
    }
  },
  changePassword: async (currentPassword, newPassword) => {
    try {
      const result = await axios.put("/change_password", {
        old_password: currentPassword,
        new_password: newPassword
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  requestPasswordReset: async email => {
    try {
      const result = await axios.post("/password_retrieval", {
        email
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (token, newPassword) => {
    try {
      const result = await axios.post("/reset_password", {
        reset_password_token: token,
        password: newPassword
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getUsers: async params => {
    try {
      const result = await axios.get("/users", {
        params
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getAdminUsers: async params => {
    try {
      const result = await axios.get("/admin/users", {
        params
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getAdminSupplierUsers: async params => {
    try {
      return await axios.get("/admin/supplier_users", {params});
    } catch (error) {
      throw error;
    }
  },
  postAdminSupplierUsers: async params => {
    try {
      return await axios.post("/admin/supplier_users", params);
    } catch (error) {
      throw error;
    }
  },
  putAdminSupplierUsers: async (params, id) => {
    try {
      return await axios.put(`/admin/supplier_users/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  deleteAdminSupplierUsers: async (id) => {
    try {
      return await axios.delete(`/admin/supplier_users/${id}`);
    } catch (error) {
      throw error;
    }
  },
  resetPasswordAdminSupplierUsers: async (id) => {
    try {
      return await axios.put(`/admin/supplier_users/${id}/reset_password`);
    } catch (error) {
      throw error;
    }
  },

  changeTeamName: async (userId, params) => {
    try {
      return await axios.put(`/admin/users/${userId}/team_name`, params);
    } catch (error) {
      throw error;
    }
  },
  getUser: async id => {
    try {
      const result = await axios.get(`/users/${id}`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  createUser: async params => {
    try {
      const result = await axios.post("/users", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getDivisions: async () => {
    try {
      return await axios.get("/divisions");
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (id, params) => {
    try {
      const result = await axios.put(`/users/${id}`, params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async id => {
    try {
      const result = await axios.delete(`/users/${id}`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getOwners: async () => {
    try {
      const result = await axios.get("/owners");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getUsersFilters: async () => {
    try {
      return await axios.get("/user_filters");
    } catch (error) {
      throw error;
    }
  },
  usersAutocomplete: async params => {
    try {
      const result = await axios.get("/users_autocomplete", {
        params
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  stakeholdersAutocomplete: async params => {
    try {
      const result = await axios.get("/stakeholders_autocomplete", {
        params
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getTokenStatus: async params => {
    try {
      const result = await axios.get("/token_status", {
        params
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getUserProjects: async (id, params) => {
    try {
      const result = await axios.get(`/users/${id}/projects`, {
        params
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  adminDeleteUser: async id => {
    try {
      return await axios.delete(`/admin/users/${id}`);
    } catch (error) {
      throw error;
    }
  },
  adminAddUser: async params => {
    try {
      return await axios.post("/admin/users", params);
    } catch (error) {
      throw error;
    }
  },
  adminEditUser: async (id, params) => {
    try {
      return await axios.put(`/admin/users/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  managers: async params => {
    try {
      return await axios.get("/admin/managers", {params});
    } catch (error) {
      throw error;
    }
  },
  team_hierarchy: async (id, params) => {
    try {
      return await axios.get(`/users/${id}/team_hierarchy`, {params});
    } catch (error) {
      throw error;
    }
  },
  getDepartments: async params => {
    try {
      return await axios.get("/departments", {params});
    } catch (error) {
      throw error;
    }
  },
  getDepartmentsAdmin: async params => {
    try {
      return await axios.get("/admin/departments", {params});
    } catch (error) {
      throw error;
    }
  },
  postDepartmentsAdmin: async params => {
    try {
      return await axios.post("/admin/departments", params);
    } catch (error) {
      throw error;
    }
  },
  putDepartmentsAdmin: async (id, params) => {
    try {
      return await axios.put(`/admin/departments/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  deleteDepartmentsAdmin: async (id, params) => {
    try {
      return await axios.delete(`/admin/departments/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  getUserCPO: async id => {
    try {
      return await axios.get("/admin/users/cpo");
    } catch (error) {
      throw error;
    }
  },
  getSelfSettings: async () => {
    try {
      return await axios.get("/self/settings");
    } catch (error) {
      throw error;
    }
  },
  changeSelfSettings: async values => {
    try {
      return await axios.put("/self/settings", values);
    } catch (error) {
      throw error;
    }
  },
  getTimeZoneAutocomplete: async params => {
    try {
      return await axios.get("/time_zone_autocomplete", {params});
    } catch (error) {
      throw error;
    }
  },
  loginSSO: async par => {
    try {
      const param = par.includes("@") ? `email=${par}` : `company=${par}`;
      const result = await axios.get(`/saml/login?${param}`);
      let res = "";
      if (result.status === 200) {
        res = result.data;
      }
      return res;
    } catch (error) {
      throw error;
    }
  },
  credsSSO: async id => {
    try {
      const result = await axios.get(`/saml/creds?sso_id=${id}`);
      if (result.status === 200) {
        axios.defaults.headers[
          "Authorization"
          ] = `Bearer ${result.data.access_token}`;
        save("accessToken", result.data.access_token);
        return result;
      }
      return undefined;
    } catch (error) {
      throw error;
    }
  }
};
