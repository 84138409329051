import React from "react";
import styles from "./SingleCondition.module.scss";
import {ReactComponent as TrashIcon} from "../../../../../../assets/../assets/icons/trash.svg";
import CustomDropdown from "../../../../../elements/CustomDropdown/CustomDropdown";
import {getConditionValues} from "../../../../../../helpers/conditionValues";
import RadioButtons from "../../../../../elements/Fields/RadioButtons/RadioButtons";
import Input from "../../../../../elements/Input/Input";
import {useParams} from "react-router-dom";
import ContractsAutocomplete from "../../../../../elements/CustomFields/ContractsAutocomplete/ContractsAutocomplete";
import UsersAutocomplete from "../../../../../elements/CustomFields/UsersAutocomplete/UsersAutocomplete";
import {parseFields} from "../../../helpers/parserForSelect";
import SuppliersAutocomplete from "../../../../../elements/CustomFields/SuppliersAutocomplete/SuppliersAutocomplete";
import CategoriesSelect from "../../../../../elements/CustomFields/CategoriesSelect/CategoriesSelect";
import DatePicker from "../../../../../elements/DatePicker/DatePicker";
import {formatDate} from "../../../../../../helpers/dateFormat";
import CustomDropDownSelect from "../../../../../elements/CustomFields/CustomDropDownSelect/CustomDropDownSelect";
import {booleanOptions, conditions, conditionsForNumber} from "../../../../../../helpers/conditions";
import PropTypes from "prop-types";

const SingleCondition = (
  {
    singleCondition,
    changeSingleCondition,
    deleteSingleCondition,
    isLast,
    isLeftOne,
    isViewMode,
    fields,
  }
) => {
  let {fieldId} = useParams();
  const {fieldValue, generalValue, generalCondition} = getConditionValues(singleCondition.value);
  const preparedFields = parseFields(fields.filter(field => field.id !== fieldId));
  const typeField = preparedFields.find(field => field.value === fieldValue)?.type;

  const getField = () => {
    let value = preparedFields.find(field => field.value === fieldValue);
    if (!value) value = {
      label: "",
      value: "",
    };
    return value;
  };

  const getCondition = () => {
    let value = (typeField === "Number" ? conditionsForNumber : conditions)
      .find(condition => condition.value === generalCondition);
    if (!value) value = {
      label: "",
      value: "",
    };
    return value;
  };

  const renderValueComponent = () => {
    const dropDown = preparedFields.find(field => field.value === fieldValue)?.custom_dropdown;

    if (typeField === "Contract") {
      return (
        <ContractsAutocomplete
          isViewMode={isViewMode}
          defaultValue={generalValue}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
        />
      );
    }
    if (typeField === "User") {
      return (
        <UsersAutocomplete
          isViewMode={isViewMode}
          defaultValue={generalValue}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
        />
      );
    }
    if (typeField === "Supplier") {
      return (
        <SuppliersAutocomplete
          isViewMode={isViewMode}
          defaultValue={generalValue}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
        />
      );
    }
    if (typeField === "Category") {
      return (
        <CategoriesSelect
          isViewMode={isViewMode}
          defaultValue={generalValue}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
        />
      );
    }
    if (typeField === "Type of Spend") {
      return (
        <CategoriesSelect
          isViewMode={isViewMode}
          defaultValue={generalValue}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
          onlyFirstLevel
        />
      );
    }
    if (typeField === "Custom Dropdown") {
      const options = dropDown.options.map(option => {
        return {
          id: option,
          name: option,
        };
      });
      return (
        <CustomDropDownSelect
          isViewMode={isViewMode}
          defaultValue={generalValue}
          options={options}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
        />
      );
    }
    if (typeField === "Date") {
      const date = new Date();
      const fromDate = date.setFullYear(date.getFullYear() - 5);
      const toDate = date.setFullYear(date.getFullYear() + 5);
      const value = generalValue && generalValue !== " " ? new Date(generalValue) : "";
      if (isViewMode) return <Input value={formatDate(value, true)} withoutChange/>;
      return (
        <div className='dateBlock'>
          <DatePicker
            label=''
            selected={value}
            onChange={date => {
              changeSingleCondition(singleCondition.id, {value: formatDate(date, true)}, "value");
            }}
            selectsStart
            inputWrapClassName={styles.dateField}
            startDate={fromDate}
            endDate={toDate}
            wrapperClassName='wrapperdatePicker'
            className='customInput'
            todayButton='Go to today'
            showYearDropdown
            dateFormatCalendar='MMMM'
            yearDropdownItemNumber={5}
            scrollableYearDropdown
          />
        </div>
      );
    }
    if (typeField === "Number") {
      if (isViewMode) return <Input value={generalValue} withoutChange/>;
      return (
        <Input
          type="text"
          isSeparateNumber
          label=""
          value={generalValue}
          onChange={e => {
            const val = Number(e);
            !isNaN(val) && changeSingleCondition(singleCondition.id, {value: val}, "value");
          }}
        />
      );
    }
    if (typeField === "Boolean") {
      let value = booleanOptions.find(option => option.value === generalValue);
      if (!value) value = {
        label: "",
        value: "",
      };
      if (isViewMode) return <Input value={value.label} withoutChange/>;
      return (
        <CustomDropdown
          options={booleanOptions}
          value={value}
          onChange={e => {
            changeSingleCondition(singleCondition.id, e, "value");
          }}
          label=""
        />
      );
    }
    return isViewMode ? <Input value={generalValue} withoutChange/> : (
      <Input
        value={generalValue}
        onChange={e => {
          changeSingleCondition(singleCondition.id, {value: e.target.value}, "value");
        }}
      />
    );
  };

  const renderFields = () => {
    return (
      <>
        {
          isViewMode ? <Input value={getField().label} withoutChange/> : (
            <CustomDropdown
              options={preparedFields}
              value={getField}
              name="field_type"
              onChange={e => {
                changeSingleCondition(singleCondition.id, e, "type");
              }}
              label=""
            />
          )
        }
        {
          isViewMode ? <Input value={getCondition().label} withoutChange/> : (
            <CustomDropdown
              options={typeField === "Number" ? conditionsForNumber : conditions}
              value={getCondition}
              name="condition"
              onChange={e => changeSingleCondition(singleCondition.id, e, "condition")}
              label=""
            />
          )
        }
        {renderValueComponent()}
      </>
    );
  };

  return (
    <>
      <div className={styles.singleCondition}>
        {renderFields()}
        {!isViewMode && (
          <div className={styles.deleteSingleConditionBtnWrap}>
            <button className={styles.deleteSingleConditionBtn} onClick={e => {
              e.preventDefault();
              if (!isLeftOne) deleteSingleCondition(singleCondition.id);
            }}>
              <TrashIcon/>
            </button>
          </div>
        )}
      </div>
      {
        !isLast && <RadioButtons
          value={singleCondition.condition}
          change={value => changeSingleCondition(singleCondition.id, value, "radio")}
          isViewMode={isViewMode}
        />
      }
    </>
  );
};

export default SingleCondition;

SingleCondition.propTypes = {
  singleCondition: PropTypes.any,
  changeSingleCondition: PropTypes.func,
  deleteSingleCondition: PropTypes.func,
  isLast: PropTypes.bool,
  isLeftOne: PropTypes.bool,
  isViewMode: PropTypes.bool,
  fields: PropTypes.any,
};