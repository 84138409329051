import React from "react";
import s from "./MainDashboard.module.scss";
import classNames from "classnames";
import IndividualDashboardBS from "./Dasboards/IndividualDashboardBS";
import TeamDashboard from "./Dasboards/TeamDashboard";
import IndividualDashboard from "./Dasboards/IndividualDashboard";
import PropTypes from "prop-types";
import BusinessUserDashboard from "./Dasboards/BusinessUserDashboard";
import CategoryOwnerDashboard from "./Dasboards/CategoryOwnerDashboard";

const MainDashboard = ({rules, dashboardType}) => {
  const {isSupportUser, isDepartmentAndCEO, isProcurement, isBusinessUser, isCategoryOwner} = rules;
  const isFullMainContent = dashboardType === "team" || isBusinessUser || isCategoryOwner;

  let dashboardComponent = <IndividualDashboard dashboardType={dashboardType}/>;

  if (dashboardType === "team") {
    dashboardComponent = (
      <TeamDashboard dashboardType={dashboardType}/>
    );
  }

  const renderDashboard = () => {
    if (isBusinessUser) return <BusinessUserDashboard/>;
    if (isCategoryOwner) return <CategoryOwnerDashboard/>;
    if (isSupportUser) return <IndividualDashboardBS/>;
    return (isProcurement || isDepartmentAndCEO) && dashboardComponent;
  }
  return (
    <div className={classNames(s.main, {[s.full]: isFullMainContent})}>
      {renderDashboard()}
    </div>
  );
};

export default MainDashboard;

MainDashboard.propTypes = {
  isBusinessOrSupportUser: PropTypes.bool,
  isDepartmentAndCEO: PropTypes.bool,
  isProcurement: PropTypes.bool,
  dashboardType: PropTypes.string,
};
