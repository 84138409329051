import axios from "./axios";

export default {
  getProjects: async (
    {
      category_ids,
      department_ids,
      division_ids,
      owner_ids,
      stakeholder_ids,
      years,
      project_type_ids,
      page,
      sort_by,
      direction,
      tag,
      query
    } = {}
  ) => {
    try {
      const result = await axios.get("/projects", {
        params: {
          category_ids: category_ids,
          department_ids: department_ids,
          division_ids: division_ids,
          owner_ids: owner_ids,
          end_date: years,
          project_type_ids: project_type_ids,
          stakeholder_ids,
          page,
          sort_by,
          direction,
          tag,
          query
        }
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  createProject: async params => {
    try {
      const result = await axios.post("/projects", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  createAdvancedProject: async params => {
    try {
      const result = await axios.post("/advanced_projects", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getProject: async id => {
    try {
      const result = await axios.get(`/projects/${id}`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  editProject: async (id, params, config) => {
    try {
      const result = await axios.put(`/projects/${id}`, params, config);
      return result;
    } catch (error) {
      throw error;
    }
  },
  deleteProject: async (id, config) => {
    try {
      const result = await axios.delete(`/projects/${id}`, config);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getProjectsFilters: async () => {
    try {
      return await axios.get("/project_filters");
    } catch (error) {
    }
  },
  getReports: async () => {
    try {
      return await axios.get("/reports");
    } catch (error) {
    }
  },
  getProjectsBoard: async ({
                             category_ids,
                             department_ids,
                             division_ids,
                             owner_ids,
                             stakeholder_ids,
                             years,
                             project_type_ids,
                             query
                           } = {}) => {
    try {
      return await axios.get("/projects_board", {
        params: {
          category_ids: category_ids,
          department_ids: department_ids,
          division_ids: division_ids,
          stakeholder_ids,
          owner_ids: owner_ids,
          end_date: years,
          project_type_ids: project_type_ids,
          query
        }
      });
    } catch (error) {
      throw error;
    }
  },
  getProjectsHistory: async (id, params) => {
    try {
      const result = await axios.get(`/projects/${id}/project_histories`, {
        params
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getGanttChartProjects: async (
    {
      category_ids,
      department_ids,
      division_ids,
      stakeholder_ids,
      owner_ids,
      years,
      query
    } = {}) => {
    try {
      return await axios.get("/projects_gantt", {
        params: {
          category_ids: category_ids,
          department_ids: department_ids,
          division_ids: division_ids,
          stakeholder_ids,
          owner_ids: owner_ids,
          end_date: years,
          query
        }
      });
    } catch (error) {
      throw error;
    }
  },
  projectsAutocomplete: async params => {
    try {
      return await axios.get("/projects_autocomplete", {
        params
      });
    } catch (error) {
      throw error;
    }
  },
  projectsActivity: async params => {
    try {
      const result = await axios.get("/projects_activity", {
        params
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getStages: async () => {
    try {
      return await axios.get("/stages");
    } catch (error) {
      throw error;
    }
  },
  getProjectTypes: async () => {
    try {
      return await axios.get("/project_types");
    } catch (error) {
      throw error;
    }
  },
  getProjectActualSavingsAll: async (id, currentMonth, currentYear) => {
    try {
      return await axios.get(`/projects/${id}/actual_savings/?month=${currentMonth}&year=${currentYear}`);
    } catch (error) {
      throw error;
    }
  },
  getProjectActualSavingsMonth: async (id, params) => {
    try {
      return await axios.get(`/projects/${id}/actual_savings`, {params});
    } catch (error) {
      throw error;
    }
  },

  putProjectActualSavingsGroup: async (id, data) => {
    try {
      return await axios.put(`/projects/${id}/actual_savings/${data.month_id}`, data);
    } catch (error) {
      throw error;
    }
  },
  putProjectActualSavingsMonth: async (id, data) => {
    try {
      return await await axios.put(`/projects/${id}/actual_savings/${data.month_id}`, data);
    } catch (error) {
      throw error;
    }
  },
  getProjectActualSavingsMonthNotes: async (idSavings) => {
    try {
      return await axios.get(`/custom_notes/?source_id=${idSavings}`);
    } catch (error) {
      throw error;
    }
  },
  addNoteToSavings: async (params) => {
    try {
      return await axios.post("/custom_notes", params);
    } catch (error) {
      throw error;
    }
  },
  getTaskDetails: async (milestoneId, taskId) => {
    try {
      return await axios.get(`/milestones/${milestoneId}/tasks/${taskId}`);
    } catch (error) {
      throw error;
    }
  },
  putProjectActualSavings: async (id, data) => {
    try {
      return await axios.post(`/projects/${id}/actual_savings`, data);
    } catch (error) {
      throw error;
    }
  },
  getProjectActualSavingsGroup: async () => {
    try {
      return await axios.get(`/saving_groups`);
    } catch (error) {
      throw error;
    }
  },
  getTaskStakeholders: async taskId => {
    try {
      return await axios.get(`/tasks/${taskId}/stakeholders`);
    } catch (error) {
      throw error;
    }
  },
  createTaskStakeholders: async (taskId, params) => {
    try {
      return await axios.post(`/tasks/${taskId}/stakeholders`, params);
    } catch (error) {
      throw error;
    }
  },
  editTaskStakeholders: async (taskId, stakeholderId, params) => {
    try {
      return await axios.put(`/tasks/${taskId}/stakeholders/${stakeholderId}`, params);
    } catch (error) {
      throw error;
    }
  },
  deleteTaskStakeholders: async (taskId, stakeholderId) => {
    try {
      return await axios.delete(`/tasks/${taskId}/stakeholders/${stakeholderId}`);
    } catch (error) {
      throw error;
    }
  },
  createSubtask: async (taskId, params) => {
    try {
      return await axios.post(`/tasks/${taskId}/subtasks`, params);
    } catch (error) {
      throw error;
    }
  },
  editSubtask: async (params, taskId, subtaskId) => {
    try {
      return await axios.put(`/tasks/${taskId}/subtasks/${subtaskId}`, params);
    } catch (error) {
      throw error;
    }
  },
  deleteSubtask: async (taskId, subtaskId) => {
    try {
      return await axios.delete(`/tasks/${taskId}/subtasks/${subtaskId}`);
    } catch (error) {
      throw error;
    }
  },
  editSubtaskStatus: async (taskId, subtaskId, params) => {
    try {
      return await axios.put(`/tasks/${taskId}/subtasks/${subtaskId}/change_status`, params);
    } catch (error) {
      throw error;
    }
  },
};