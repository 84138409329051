import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import SupplierDetailProjectsTable from "components/tables/SupplierDetailProjectsTable/SupplierDetailProjectsTable";
import services from "services";

const SupplierProjetcs = () => {
  const params = useParams();
  const [projects, setProjects] = useState({
    projects: [],
    projects_count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({});

  useEffect(() => {
    services.supplierServices
      .supplierReportProjects(params.id, query)
      .then((res) => {
        setProjects(res.data);
        setLoading(false);
      });
  }, [params.id, query]);

  return (
    <>
      {loading && <TableLoading length={7} />}
      {!loading && Boolean(projects.projects_count) && (
        <SupplierDetailProjectsTable
          data={projects}
          query={query}
          changeQuery={setQuery}
        />
      )}
      {!loading && !Boolean(projects.projects_count) && (
        <EmptyBlock title="Supplier doesn't have active projects yet." message />
      )}
    </>
  );
};

export default SupplierProjetcs;
