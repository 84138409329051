export const SET_TASKROLES = "SET_TASKROLES"
export const ADD_TASKROLE = "ADD_TASKROLE"
export const CHANGE_TASKROLE = "CHANGE_TASKROLE"
export const DELETE_TASKROLE = "DELETE_TASKROLE"
export const SET_POPUP = "SET_POPUP"
export const CLOSE_ALERT = "CLOSE_ALERT"
export const SET_CURRENT_TASKROLE = "SET_CURRENT_TASKROLE"
export const initialState = {
  per_page: 30,
  page: 1,
  tasks_count: 0,
  taskroles: [],
  current_taskrole: null,
  loading: true,
  alert: {
    type: "",
    message: ""
  },
  popup: null
}
export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_TASKROLES:
      return {
        ...state,
        per_page: payload?.per_page || state.per_page,
        tasks_count:
          payload?.tasks_count || state.tasks_count,
        taskroles:
          payload?.taskroles || state.taskroles,
        search_query: payload?.search_query || state.search_query,
        loading:
          payload.loading !== undefined ? payload?.loading : state.loading,
        alert: payload?.alert || state.alert
      }
    case ADD_TASKROLE: {
      return {
        ...state,
        tasks_count: state.tasks_count + 1,
        taskroles: payload.taskrole
          ? [payload.taskrole.task_role, ...state.taskroles]
          : state.taskroles,
        alert: payload.alert,
        popup: payload.popup !== undefined ? payload.popup : state.popup
      }
    }
    case CHANGE_TASKROLE:
      return {
        ...state,
        taskroles: payload.taskrole
          ? state.taskroles.map(e =>
            e.id === payload.taskrole.task_role.id ? payload.taskrole.task_role : e
          )
          : state.taskroles,
        alert: payload.alert,
        popup: payload.popup !== undefined ? payload.popup : state.popup
      }
    case DELETE_TASKROLE:
      return {
        ...state,
        tasks_count: state.tasks_count - 1,
        taskroles: payload.taskrole_id
          ? state.taskroles.filter(e => e.id !== payload.taskrole_id)
          : state.taskroles,
        alert: payload.alert,
        popup: payload.popup !== undefined ? payload.popup : state.popup
      }
    case SET_POPUP:
      return {
        ...state,
        popup: payload
      }
    case CLOSE_ALERT:
      return {
        ...state,
        alert: {
          type: "",
          message: ""
        }
      }
    case SET_CURRENT_TASKROLE:
      return {
        ...state,
        current_taskrole: payload
      }
  }
}
