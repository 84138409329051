import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {userRoles, userTypes} from "helpers/userPermissions/userRoles";
import {ReactComponent as Dashboard} from "assets/icons/sidebar/dashboard.svg";
import {ReactComponent as Categories} from "assets/icons/sidebar/categories.svg";
import {ReactComponent as Contracts} from "assets/icons/sidebar/contracts.svg";
import {ReactComponent as Projects} from "assets/icons/sidebar/projects.svg";
import {ReactComponent as Reporting} from "assets/icons/sidebar/reporting.svg";
import {ReactComponent as Suppliers} from "assets/icons/sidebar/suppliers.svg";
import {ReactComponent as Users} from "assets/icons/sidebar/users.svg";
import {ReactComponent as AdminSettings} from "assets/icons/sidebar/adminSettings.svg";
import {ReactComponent as ProjectRequests} from "assets/icons/sidebar/projectRequests.svg";
import {ReactComponent as Activity} from "assets/icons/sidebar/activity.svg";
import {ReactComponent as CompanyGoals} from "assets/icons/sidebar/companyGoals.svg";
import { ReactComponent as Notes } from "assets/icons/sidebar/surveys.svg";
import services from "services";
import {paths} from "routes/paths";
import {useProjectsPath} from "../../../../../../hooks/useProjectsPath";

const {admin, triage_user, procurement_admin, procurement_category_owner, procurement_manager, cpo} = userRoles;
const SidebarViewData = () => {
  const user = useSelector(state => state.user);
  const categoriesStore = useSelector(state => state.categories.categories);
  const [categories, setCategories] = useState([]);
  const currentYear = String(new Date().getFullYear());
  const projectsPath = useProjectsPath();

  useEffect(() => {
    if (categoriesStore.length) {
      setCategories(categoriesStore);
    } else {
      services.categoriesServices.getCategories().then((res) => setCategories(res.data));
    }
  }, [categoriesStore]);

  const adminFirstPart = user.roles.split(",").includes(admin) ? [
    {
      name: "General",
      link: paths.adminSettingsGeneral,
    },
    {
      name: "Users",
      link: `${paths.adminSettingsUsers}?statuses=active`,
    },
    {
      name: "Projects",
      link: paths.adminSettingsProjects,
    },
    {
      name: "Approvals",
      link: paths.adminSettingsApprovals,
    },
    {
      name: "Contracts",
      link: paths.adminSettingsContracts,
    },
  ] : [];

  const adminSecondPart = user.roles.split(",").includes(admin) ? [
    {
      name: "Additional questions",
      link: paths.adminSettingsAdditionalQuestions,
    },
    {
      name: "Savings",
      link: paths.adminSettingsSavings,
    },
    {
      name: "Flags",
      link: paths.adminSettingsFlags,
    },
    {
      name: "Emails",
      link: paths.adminSettingsEmails,
    },
  ] : [];

  return [
    {
      icon: <Dashboard/>,
      name: "Dashboard",
      link: paths.dashboard
    },
    {
      icon: <Categories/>,
      name: "Categories",
      sub: [
        {
          name: "Overview",
          link: `/categories-overview?page=1&spend_year=${currentYear - 1}`,
          activeRoutes: [
            {
              name: "/categories-overview"
            }
          ]
        },
        {
          name: "Details",
          link: `/categories/${categories[0]?.id}`,
          activeRoutes: [
            {
              name: "/categories/"
            }
          ]
        }
      ]
    },
    {
      icon: <Suppliers/>,
      name: "Suppliers",
      link: paths.suppliers
    },
    {
      icon: <Users/>,
      name: "Users",
      link: paths.users,
      linkInStart: true
    },
    {
      icon: <Projects/>,
      name: "Projects",
      link: !user?.projects_count ? "/projects?tab=Board" : projectsPath,
      activeRoutes: [
        {
          name: "/projects"
        }
      ],
      linkInStart: true
    },
    {
      icon: <Contracts/>,
      name: "Contracts",
      link: paths.contracts,
      disable: user?.organization.name === "Indeed",
      linkInStart: true
    },
    {
      icon: <Notes />,
      name: "Notes",
      link: paths.projectsSurveys
    },
    //   sub: [
    //     {
    //       name: "for Projects",
    //       link: paths.projectsSurveys,
    //     },
    //     {
    //       name: "for Suppliers",
    //       link: paths.suppliersSurveys,
    //       disable: false,
    //     },
    //   ],
    // },
    {
      icon: <Reporting/>,
      name: "Reports",
      sub: [
        {
          name: "System reports",
          link: paths.systemReports
        },
        {
          name: "Financial reports",
          link: paths.financialReports,
          disable: false
        }
      ]
    },
    (
      user.roles.split(",").includes(admin) ||
      user.triage
    ) && {
      icon: <AdminSettings/>,
      name: "Admin Settings",
      sub: [
        ...adminFirstPart,
        {
          name: "Requests",
          link: paths.adminSettingsRequest,
        },
        ...adminSecondPart,
      ],
    },
    (
      user.user_type === "procurement" ||
      user.user_type === "business" ||
      user.user_type === userTypes.adminTypes ||
      user.roles.includes(triage_user)
    ) && {
      icon: <ProjectRequests/>,
      name: "Requests",
      link: paths.requests
    },
    (user.user_type === userTypes.procurement || user.user_type === userTypes.adminTypes) && {
      icon: <CompanyGoals/>,
      name: "Company goals",
      link: paths.companyGoals
    },
    (
      !user.roles.includes(procurement_admin) &&
      !user.roles.includes(procurement_category_owner) &&
      !user.roles.includes(cpo) &&
      !user.roles.includes(procurement_manager)
    ) && {
      icon: <Activity/>,
      name: "Activity",
      link: paths.activity
    }
  ];
};

export default SidebarViewData;
