export const getTimeOptions = () => {
  const options = []

  for (let i = 0; i <= 23; i++) {
    const time = i <= 12 ? i : i - 12
    const withPrefix = time < 10 ? `0${time}` : time
    const period = i < 12 ? "am" : "pm"

    options.push({
      label: `${withPrefix}:00 ${period}`,
      value: String(i)
    })
  }

  return options
}
