import React, {useState, useEffect} from "react";
import styles from "./CategoriesSuppliers.module.scss";
import PieChart from "components/elements/PieChart/PieChart";
import SpendStatuses from "./SpendStatuses/SpendStatuses";
import CategoriesSuppliersSpendTable
  from "components/tables/CategoriesSuppliersSpendTable/CategoriesSuppliersSpendTable";
import services from "services";
import {formatSpendByStatusData, formatSuppliersRate} from "../../helpers";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import SpendStatusesLoading from "./SpendStatuses/SpendStatusesLoading/SpendStatusesLoading";
import {useLocation} from "react-router-dom";
import querystring from "query-string";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const COLORS = ["#5b79b4", "#6ACFEF", "#F4A261"];

const CategoriesSuppliers = ({categoryIdsFilter}) => {
  const {t} = useTranslation();
  const [spendByStatus, setSpendByStatus] = useState({
    statuses: [],
    pieChartData: [],
    tableData: [],
    spendCurrency: "USD",
    tableCurrency: "USD",
  });
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const location = useLocation();
  const {spend_year, category_ids} = querystring.parse(location.search);

  useEffect(() => {
    services.categoriesServices.getCategoriesSpendByStatus({
      spend_year,
      category_ids: categoryIdsFilter
    }).then((res) => {
      setSpendByStatus((data) => ({
        ...data,
        ...formatSpendByStatusData(res.data),
        spendCurrency: res.data.currency,
      }));
      setLoading(false);
    });
    services.categoriesServices.getCategoriesSuppliersRate({
      spend_year,
      category_ids: categoryIdsFilter
    }).then((res) => {
      setSpendByStatus((data) => ({
        ...data,
        tableData: formatSuppliersRate(res.data.suppliers_rate),
        tableCurrency: res.data.currency,
      }));
      setTableLoading(false);
    });
  }, [spend_year, category_ids, categoryIdsFilter]);

  const isEmpty = () => {
    return !Boolean(
      spendByStatus.statuses?.length &&
      spendByStatus.pieChartData?.length &&
      spendByStatus.tableData?.length
    );
  };
  let newDate = [];
  if (spendByStatus.tableData) {
    newDate = [
      ...spendByStatus.tableData,
      {
        name: "Total:",
        total: "totalSum",
        ...spendByStatus.tableData.reduce(
          (accumulator, current) => ({
            ...accumulator,
            ...Object.keys(accumulator).reduce((obj, key) => ({...obj, [key]: accumulator[key] + current[key]}), {}),
          }), {
            count: 0,
            spend: 0,
          }),
      },
    ];
  }
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title} data-translate-key={getTranslateKey("Suppliers")}>
        {t(getTranslateKey("Suppliers"))}
      </h3>
      {isEmpty() && !loading && !tableLoading && (
        <EmptyBlock title="No suppliers data for displaying." message/>
      )}
      {!isEmpty() && (
        <div className={styles.container}>
          <div className={styles.column}>
            {!loading && <h4 data-translate-key={getTranslateKey("Spend by Status")}>
              {t(getTranslateKey("Spend by Status"))}
            </h4>}
            {loading && <SpendStatusesLoading/>}
            {!loading && (
              <div className={styles.spendContainer}>
                <div className={styles.statusWrapper}>
                  <SpendStatuses data={spendByStatus.statuses}/>
                </div>
                <div className={styles.pieChartWrapper}>
                  <PieChart
                    data={spendByStatus.pieChartData}
                    colors={COLORS}
                    withLegend={false}
                    className={styles.pieChart}
                    defaultCurrency={
                      spendByStatus.spendCurrency !== "USD"
                        ? spendByStatus.spendCurrency
                        : "$"
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.column}>
            {tableLoading && <TableLoading length={2}/>}
            {!tableLoading && (
              <CategoriesSuppliersSpendTable
                data={newDate}
                currency={spendByStatus.tableCurrency}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoriesSuppliers;
