import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import {useHistory} from "react-router-dom";
import projectsTableData from "../../containers/ProjectsContainer/projectsTableData";
import {useSelector} from "react-redux";
import querystring from "query-string";
import {getProjectStatuses} from "helpers/helpData";
import {userRoles} from "../../../helpers/userPermissions/userRoles";

const ProjectsTable = (
  {
    onDelete,
    onChangeStatus,
    perPage,
    totalCount,
    onPageChange,
    state,
    onAssignTag,
    onDeleteTag,
  }
) => {
  const history = useHistory();
  const organizationName = useSelector((state) => state.user.organization.name);
  const statuses = getProjectStatuses(organizationName);

  const onSort = (type) => {
    const search = querystring.parse(history.location.search);
    history.push({
      search: querystring.stringify({...search, page: 1, ...type}),
    });
  };

  const user = useSelector(state => state.user);

  const deleteTagAction = (id, tag, item) => {
    if (tag.type === "flag") {
      if (tag.owner.id === user.id || user.roles.includes(userRoles.admin)) {
        return onDeleteTag(id, item.id);
      }
    } else return onDeleteTag(id, item.id);
  };

  return (
    <>
      <MainTable
        data={state.projects}
        stickyHeader={true}
        withXScroll={true}
        ariaLabel="Projects"
        dataTypes={projectsTableData({
          history,
          statuses,
          onSort,
          deleteTagAction,
          onAssignTag,
        })}
        options={(item) => {
          return [
            {
              title: "Change status",
              onClick: () => {
                onChangeStatus(item.id);
              },
            },
            {
              title: "Delete",
              onClick: () => onDelete(item.id),
            },
          ];
        }}
        perPage={perPage}
        totalCount={totalCount}
        onPageChange={onPageChange}
        isPagination={true}
        page={state.currentPage}
      />
    </>
  );
};
export default ProjectsTable;
