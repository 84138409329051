import {generateUniqueId} from "../../../../helpers/uniqueId";
import {object, string} from "yup";

const getRequired = question => {
  if (question.question_type === "text_field") {
    if (question.is_required) {
      return object({
        value: string()
          .required("Field is required")
          .max(1000, "Please enter field that consists of no more than 1000 characters")
      });
    }
    return object({
      value: string()
        .max(1000, "Please enter field that consists of no more than 1000 characters")
    });
  }

  if (question.is_required) return object({
    value: string().required("Field is required")
  });

  return "";
};

const getAdditionalQuestions = questions => {
  return questions.map(question => ({
    key: question.question_id,
    type: "additional_question",
    question: question,
    value: "",
    required: getRequired(question),
  }));
};

export const setAdditionalQuestions = (steps, questions) => {

  if (questions.length === 0) {
    return steps.filter(step => step.name !== "Additional questions");
  }

  if (steps[steps.length - 1].name === "Additional questions") {
    steps[steps.length - 1] = {
      ...steps[steps.length - 1],
      fields: [
        ...steps[steps.length - 1].fields,
        ...getAdditionalQuestions(questions)
      ]
        .filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i)
    };
  } else {
    if (questions.length) {
      steps.push({
        fields: getAdditionalQuestions(questions),
        name: "Additional questions",
        id: generateUniqueId(),
      });
    }
  }

  return steps;
};