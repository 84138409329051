import React, {Fragment} from "react"
import s from "./General.module.scss"
import CustomLink from "components/elements/CustomLink/CustomLink"
import {ReactComponent as Title} from "assets/icons/usersPage/title.svg"
import {ReactComponent as EmailAdress} from "assets/icons/usersPage/emailAdress.svg"
import {ReactComponent as Division} from "assets/icons/usersPage/division.svg"
import {ReactComponent as Department} from "assets/icons/usersPage/department.svg"
import UserItem from "./components/UserItem/UserItem"

const General = ({data}) => {
  const {title, email, division, department} = data;
  return (
    <Fragment>
      <ul className={s.userBlock}>
        <UserItem title={title} icon={Title}/>
        <UserItem title={email} icon={EmailAdress}/>
        <UserItem title={division && division.name} icon={Division}/>
        <UserItem title={department && department.name} icon={Department}/>
      </ul>
      <CustomLink
        className={s.changePassBtn}
        type='nav-link'
        path='/password-change'
      >
        Change password
      </CustomLink>
    </Fragment>
  )
}
export default General
