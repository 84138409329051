import React, {useEffect, useState} from "react";
import style from "./SingleStep.module.scss";
import DropListTrigger from "../../../../../elements/DropListTrigger/DropListTrigger";
import services from "../../../../../../services";
import Popup from "../../../../../popups/Popup/Popup";
import RequestTypesStepForm from "../../../../../forms/RequestTypesStepForm/RequestTypesStepForm";
import {useParams} from "react-router-dom";
import RequestTypesStepFieldForm from "../../../../../forms/RequestTypesStepFieldForm/RequestTypesStepFieldForm";
import {Draggable} from "react-beautiful-dnd";
import StepField from "../StepField/StepField";
import PropTypes from "prop-types";
import Information from "../../../../../elements/Information/Information";

const SingleStep = ({step, editAction, deleteAction}) => {
  const {id} = useParams();
  const [fields, setFields] = useState(step.step_fields);
  const [popUp, setPopUp] = useState(null);
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const alwaysFields = fields.filter(field => field.conditions === "");

  useEffect(() => {
    setFields(step.step_fields);
  }, [step]);

  const editStep = values => {
    services.requestTypeServices.editRequestTypesSteps(id, step.id, values).then(res => {
      if (res.data) editAction(res.data);
      setPopUp(null);
    });
  };

  const deleteStep = () => {
    services.requestTypeServices.deleteRequestTypesSteps(id, step.id).then(() => {
      deleteAction(step.id);
      setPopUp(null);
    });
  };

  const addStepField = values => {
    services.requestTypeServices.addStepField(step.id, values).then(res => {
      if (res.data) setFields(fields => [...fields, res.data]);
      setPopUp(null);
    });
  };

  const editStepField = (values, fieldId) => {
    services.requestTypeServices.editStepField(step.id, fieldId, values).then(res => {
      if (res.data) {
        setFields(fields => fields.map(field => {
          if (field.id === res.data.id) return res.data;
          return field;
        }));
      }
    });
  };

  const deleteStepField = fieldId => {
    services.requestTypeServices.deleteStepField(step.id, fieldId).then(() => {
      setFields(fields => fields.filter(field => field.id !== fieldId));
    });
  };

  return (
    <>
      <div className={style.step}>
        <div className={style.stepHeader}>
          <div className={style.title}>Step {step.position} - {step.name} {step.default && "(Default)"}</div>
          {!step.default && (
            <DropListTrigger
              data={[
                {
                  title: "Edit",
                  onClick: () => setPopUp("edit")
                },
                {
                  title: "Delete",
                  onClick: () => setPopUp("delete")
                }
              ]}
              visibleMenu={visibleDropListMenu}
              setVisibleMenu={setVisibleDropListMenu}
            />
          )}
        </div>
        {!step.default && (
          <div className={style.content}>
            <div className={style.fields}>
              {
                fields
                  .sort((a, b) => a.position - b.position)
                  .map((field, index) => {
                    return (
                      <Draggable key={field.id} draggableId={field.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <StepField
                              field={field}
                              alwaysFieldsCount={alwaysFields.length}
                              editAction={() => {
                                setCurrentField(field);
                                setPopUp("edit_field");
                              }}
                              deleteAction={() => {
                                setCurrentField(field);
                                setPopUp("delete_field");
                              }}
                              stepId={step.id}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })
              }
            </div>
            <button
              className={style.addFieldBtn}
              onClick={() => setPopUp("add_field")}
            >
              + Add new field
            </button>
          </div>
        )}
      </div>
      {
        popUp === "edit" && (
          <Popup
            title="Edit step"
            onClose={() => setPopUp(null)}
          >
            <RequestTypesStepForm
              onSubmit={editStep}
              onCLose={() => setPopUp(null)}
              defaultValues={step}
            />
          </Popup>
        )
      }
      {
        popUp === "add_field" && (
          <Popup
            title="New field"
            text="Please, complete the following fields to add a new field."
            onClose={() => setPopUp(null)}
          >
            <RequestTypesStepFieldForm
              onSubmit={addStepField}
              onCLose={() => setPopUp(null)}
            />
          </Popup>
        )
      }
      {
        popUp === "delete" && (
          <Popup
            title="Delete step"
            text="Are you sure you want to delete this step? All fields from it will also be removed."
            onClose={() => setPopUp(null)}
            onClick={deleteStep}
            isDelete
            dialog
          />
        )
      }
      {
        (popUp === "edit_field" && currentField) && (
          <Popup
            title="Edit field"
            onClose={() => setPopUp(null)}
          >
            <Information message="This field has already been used, so some information can’t be changed." informationClass={style.information}/>
            <RequestTypesStepFieldForm
              onSubmit={values => {
                editStepField(values, currentField.id);
                setPopUp(null);
              }}
              onCLose={() => {
                setPopUp(null);
                setCurrentField(null);
              }}
              defaultValues={currentField}
            />
          </Popup>
        )
      }
      {
        (popUp === "delete_field" && currentField) && (
          <Popup
            title="Delete field"
            text="Are you sure you want to delete this field? All conditions with it will also be removed."
            onClose={() => {
              setPopUp(null);
              setCurrentField(null);
            }}
            onClick={() => {
              deleteStepField(currentField.id);
              setPopUp(null);
            }}
            isDelete
            dialog
          />
        )
      }
    </>
  );
};

export default SingleStep;

SingleStep.propTypes = {
  step: PropTypes.any,
  editAction: PropTypes.func,
  deleteAction: PropTypes.func,
};