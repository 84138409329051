import React, {useState, useEffect, useCallback} from "react";
import {useFormik} from "formik";
import {debounce} from "lodash";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import services from "services";
import s from "./AddProjectContractForm.module.scss";
import {useParams} from "react-router-dom";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const AddProjectContractForm = (
  {
    currentContracts = [],
    addHandler,
    onClose,
  }
) => {
  const [searchedSuppliers, setSearchedSuppliers] = useState([]);
  const [searchedContracts, setSearchedContracts] = useState([]);
  const {t} = useTranslation();
  const params = useParams();
  const filteredContracts = searchedContracts.filter((contract) =>
    Boolean(!currentContracts.filter((item) => item.id === contract.id).length)
  );
  const onSearchSupplier = useCallback(
    debounce((query) => {
      services.supplierServices.suppliersAutocomplete({query}).then((res) => {
        setSearchedSuppliers(res.data);
      });
    }, 300),
    []
  );

  const onSearchContract = useCallback(
    debounce((params) => {
      services.contractsServices
        .getContractsAutocomplete(params)
        .then((res) => {
          setSearchedContracts(res.data);
        });
    }, 300),
    []
  );

  const formik = useFormik({
    initialValues: {
      supplier: {
        id: null,
        name: "",
      },
      contract: {
        id: null,
        name: "",
      },
    },
    validate: ({contract}) => {
      let errors = {};
      if (!Boolean(contract.id)) {
        errors.contract = "Please, choose the contract";
      }
      return errors;
    },
    onSubmit: (values) => {
      addHandler({
        contract_id: values.contract?.id,
        category_id: params.id,
      });
      onClose();
    },
  });

  const {handleSubmit, errors, setFieldValue, touched, values} = formik;

  const supplierName =
    typeof values.supplier === "object"
      ? values.supplier.name
      : values.supplier;
  const contractName =
    typeof values.contract === "object"
      ? values.contract.name
      : values.contract;

  useEffect(() => {
    values.supplier.id &&
    services.contractsServices
      .getContracts({supplier_ids: values.supplier.id})
      .then((res) => {
        setSearchedContracts(res.data.contracts);
      });
    !values.supplier.id &&
    contractName &&
    onSearchContract({
      query: contractName,
    });
  }, [values.supplier]);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <AutoCompleteInput
        data={searchedSuppliers}
        value={supplierName}
        placeholder=""
        onChange={(e) => {
          setFieldValue("supplier", e.target.value);
          onSearchSupplier(e.target.value);
        }}
        onSelect={(value) => {
          setFieldValue("supplier", value);
        }}
        label="Suppliers"
        optional
        name="supplier"
        emptyText={{
          text: "No work item results found.",
        }}
      />
      <AutoCompleteInput
        data={filteredContracts}
        value={contractName}
        placeholder="Start typing contract's name"
        onChange={(e) => {
          setFieldValue("contract", e.target.value);
          onSearchContract({
            query: e.target.value,
            supplier_id: values.supplier?.id,
          });
        }}
        onSelect={(value) => {
          setFieldValue("contract", value);
        }}
        label="Contract"
        name="contract"
        className={s.field}
        emptyText={{
          text: "No work item results found.",
        }}
        error={errors.contract && touched.contract && errors.contract}
        withArrow
        emptyStateDisplay={Boolean(values.supplier.id)}
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Add
        </Button>
      </div>
    </form>
  );
};

export default AddProjectContractForm;
