import {string, object} from "yup";

export const schemaGeneral = object({
  first_name: string()
    .required("First name is required")
    .max(
      50,
      "Please enter a valid user name that consists of no more than 50 characters"
    )
    .matches(/^(?!\s+$).*/, "First name can’t be blank"),
  last_name: string()
    .required("Last name is required")
    .max(
      50,
      "Please enter a valid user name that consists of no more than 50 characters"
    )
    .matches(/^(?!\s+$).*/, "Last name can’t be blank"),
  title: string()
    .required("Title is required")
    .max(
      32,
      "Please enter a valid title that consists of no more than 32 characters"
    )
    .matches(/^(?!\s+$).*/, "Title can’t be blank"),
  email: string()
    .max(
      32,
      "Please enter a valid email address that consists of no more than 32 characters"
    )
    .email("Email is invalid")
    .required("Email is required"),
});

export const schemaManagement = object({
  first_name: string()
    .required("First name is required")
    .max(
      50,
      "Please enter a valid user name that consists of no more than 50 characters"
    )
    .matches(/^(?!\s+$).*/, "First name can’t be blank"),
  last_name: string()
    .required("Last name is required")
    .max(
      50,
      "Please enter a valid user name that consists of no more than 50 characters"
    )
    .matches(/^(?!\s+$).*/, "Last name can’t be blank"),
  title: string()
    .required("Title is required")
    .max(
      32,
      "Please enter a valid title that consists of no more than 32 characters"
    )
    .matches(/^(?!\s+$).*/, "Title can’t be blank"),
  email: string()
    .max(
      32,
      "Please enter a valid email address that consists of no more than 32 characters"
    )
    .email("Email is invalid")
    .required("Email is required"),
  team_name: string()
    .max(50, "Please enter a valid team name that consists of no more than 50 characters"),
  type: object({value: string().required("Type is required")}),
  division: object({value: string().required("Division is required")}),
  department: object({
    value: string().required("Department is required"),
  }),
  role: object({value: string().required("Role is required")}),
});

export const schemaPassword = object({
  password: string()
    .required("Please enter a password")
    .min(8, "Password must have 8 characters minimum")
    .max(32, "Max length is 32 characters")
    .matches(/[a-z]{1}/, "Please use at least 1 lower case character")
    .matches(/[A-Z]{1}/, "Please use at least 1 upper case character")
    .matches(/(?=.*[0-9])/, "Please use at least 1 number")
    .matches(/(?=.*[!@#\$%\^&\*])/, "Please use at least 1 special character"),
});
