import React from "react";
import sBase from '../Dashboard.module.scss';
import s from "./Widgets.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";

const WidgetsLoading = () => {
  return (
    <div className={classNames(sBase.container, s.widgetContainer)}>
      <Skeleton
        variant="text"
        animation="wave"
        className={s.item}
        width={200}
      />
      <Skeleton
        variant="text"
        animation="wave"
        className={s.item}
        width={160}
      />
      <Skeleton
        variant="text"
        animation="wave"
        className={s.item}
        width={140}
      />
      <Skeleton
        variant="text"
        animation="wave"
        className={s.item}
        width={130}
      />
    </div>
  );
};

export default WidgetsLoading;


