import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import AdminSettingsProjectStageContainer from "components/containers/AdminSettingsProjectStageContainer/AdminSettingsProjectStageContainer";
import {paths} from "../../../routes/paths";

const AdminSettingsProjectStagePage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          name: "Admin Settings",
          disabled: true
        },
        {
          path: paths.adminSettingsProjects,
          name: "Projects",
        },
        {
          name: "Project stage details",
        },
      ]}
    >
      <AdminSettingsProjectStageContainer />
    </DefaultLayout>
  );
};
export default AdminSettingsProjectStagePage;
