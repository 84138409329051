export const boardData = {
	milestones: [
		{
			id: 1,
			name: 'Planned',
			color: '#2FBCCC',
			required_tasks: [
				{
					id: 1,
					name: 'Investigate existing contracts',
					project_types: [],
					description: '',
					help_text: '',
					attachments: []
				}
			],
			recommended_tasks: [
				{
					id: 1,
					name: 'Investigate existing contracts',
					project_types: [],
					description: '',
					help_text: '',
					attachments: []
				}
			],
			approvals: [
				{
					id: 1,
					name: 'IT Review',
					request_type: 'automatic',
					status: 'success',
					approval_group: {
						id: 1,
						name: 'finance'
					},
					tab: {
						id: 1,
						name: 'financial_details'
					}
				}
			]
		},
		{
			id: 2,
			name: 'Active: Needs Analysis',
			color: '#F87D82',
			required_tasks: [
				{
					id: 1,
					name: 'Investigate existing contracts',
					project_types: [],
					description: '',
					help_text: '',
					attachments: []
				},
				{
					id: 2,
					name: 'Investigate existing contracts',
					project_types: [],
					description: '',
					help_text: '',
					attachments: []
				}
			],
			recommended_tasks: [],
			approvals: [
				{
					id: 1,
					name: 'IT Review',
					request_type: 'automatic',
					status: 'success',
					approval_group: {
						id: 1,
						name: 'finance'
					},
					tab: {
						id: 1,
						name: 'financial_details'
					}
				},
				{
					id: 2,
					name: 'IT Review',
					request_type: 'automatic',
					status: 'error',
					approval_group: {
						id: 1,
						name: 'finance'
					},
					tab: {
						id: 1,
						name: 'financial_details'
					}
				}
			]
		},
		{
			id: 3,
			name: 'Active: Stategy Creation',
			color: '#3DB79F',
			required_tasks: [],
			recommended_tasks: [],
			approvals: []
		},
		{
			id: 4,
			name: 'Active: Market Activity',
			color: '#F5905B',
			required_tasks: [],
			recommended_tasks: [],
			approvals: []
		},
		{
			id: 5,
			name: 'Active: Award Business',
			color: '#707BBF',
			required_tasks: [],
			recommended_tasks: [],
			approvals: []
		}
	]
}
