import {getValuesByGroup} from "./getValuesByGroup";

export const getMainValues = (data, mainValues) => {
  const entities = Object.values(mainValues);
  return [
    getValuesByGroup("Risk migration", entities[0], data),
    getValuesByGroup("Process Enhancements", entities[1], data),
    getValuesByGroup("Relationship Management", entities[2], data),
    getValuesByGroup("Contract Benefits", entities[3], data),
  ].filter(i => i).join();
}