import React from "react";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import Users from "./Users/Users";
import {useTranslation} from "react-i18next";
import Tabs from "../../elements/Tabs/Tabs";
import UserOrgChart from "../UserDetailsContainer/components/UserOrgChart/UserOrgChart";
import Tab from "../../elements/Tabs/Tab/Tab";
import SupplierUsers from "./SupplierUsers/SupplierUsers";
import UserDepartments from "../UserDetailsContainer/components/UserDepartments/UserDepartments";
import {useHistory} from "react-router-dom";
import querystring from "query-string";
import Divisions from "./Divisions/Divisions";

const AdminSettingsApprovalsContainer = () => {
  const history = useHistory();
  const {tab} = querystring.parse(history.location.search);
  const {t} = useTranslation();
  const handleTabChange = (tab) => {
    history.push({search: querystring.stringify({tab})});
  };
  return (
    <div className={s.wrap}>
      <h2 className={s.title} data-translate-key="users">{t("users")}</h2>
      <Tabs handleClick={handleTabChange} defaultActiveTab={tab}>
        <Tab label="Users">
          <Users/>
        </Tab>
        <Tab label="Supplier users">
          <SupplierUsers/>
        </Tab>
        <Tab label="Departments">
          <UserDepartments/>
        </Tab>
        <Tab label="Teams">
          <UserOrgChart withTeamEdit isTeam/>
        </Tab>
        <Tab label="Divisions">
          <Divisions/>
        </Tab>
      </Tabs>
    </div>
  );
};
export default AdminSettingsApprovalsContainer;
