import React, {useEffect, useState} from "react";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import {useHistory, useParams} from "react-router-dom";
import services from "services";
import DetailLoading from "../../loadingStates/Detail/Detail";
import Detail from "../../elements/Detail/Detail";
import Skeleton from "@material-ui/lab/Skeleton";
import {trimRest} from "../../../helpers/stringHelper";
import {ReactComponent as Pencil} from "../../../assets/icons/edit-pencil.svg";
import style from "./AdminSettingsRequestDetailsContainer.module.scss";
import Popup from "../../popups/Popup/Popup";
import EditRequestDescriptionForm from "../../forms/EditRequestDescriptionForm/EditRequestDescriptionForm";
import Steps from "./components/Steps/Steps";
import DropListTrigger from "../../elements/DropListTrigger/DropListTrigger";

const AdminSettingsRequestDetailsContainer = () => {
  let {id} = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popUp, setPopUp] = useState(null);
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);

  useEffect(() => {
    services.requestTypeServices.getRequestTypesDetails(id).then(res => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  }, []);

  const editHandler = values => {
    services.requestTypeServices.editRequestTypes(id, {...values, name: data.name}).then(res => {
      if (res.data) {
        setData(res.data);
        setPopUp(null);
      }
    });
  };

  const deleteHandler = () => {
    services.requestTypeServices.deleteRequestTypes(id).then(() => {
      history.push("/admin-settings/requests");
    });
  };

  const renderDetailBlock = () => {
    if (loading && !data) return <DetailLoading length={2}/>;
    return (
      <Detail
        className={style.detail}
        data={[
          {
            name: "Description",
            value: data.description,
            useParagraph: true,
            maxWidth: 700,
          },
        ]}
        buttons={[
          {
            component: (
              <Pencil
                className={style.editBtn}
                onClick={() => setPopUp("edit")}
              />
            )
          }
        ]}
      />
    );
  };

  return (
    <>
      <DefaultLayout breadcrumbs={[
        { name: "Admin Settings", path: "", disabled: true },
        {
          path: paths.adminSettingsRequest,
          name: "Requests",
        },
        {
          name: trimRest(50, data?.name),
        },
      ]}>
        <div className={s.wrap}>
          <div className={s.header}>
            <h2 className={s.title} title={data?.name}>
              {trimRest(50, data?.name) || <Skeleton variant="text" animation="wave" width={120}/>}
            </h2>
            <DropListTrigger
              data={[
                {
                  title: "Delete",
                  onClick: () => setPopUp("delete")
                }
              ]}
              visibleMenu={visibleDropListMenu}
              setVisibleMenu={setVisibleDropListMenu}
            />
          </div>
          {renderDetailBlock()}
          <Steps/>
        </div>
      </DefaultLayout>
      {
        (popUp === "edit") && (
          <Popup
            title="Edit description"
            onClose={() => setPopUp(null)}
          >
            <EditRequestDescriptionForm
              onSubmit={values => editHandler(values)}
              description={data.description}
              onCLose={() => setPopUp(null)}
            />
          </Popup>
        )
      }
      {
        popUp === "delete" && (
          <Popup
            title="Delete request type"
            text="Are you sure you want to delete this request type? It will remain in all previous requests, but not in new ones."
            onClose={() => setPopUp(null)}
            onClick={deleteHandler}
            isDelete
            dialog
          />
        )
      }
    </>
  );
};

export default AdminSettingsRequestDetailsContainer;
