import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import CategoryDetailContainer from "components/containers/CategoryDetailContainer/CategoryDetailContainer";

const CategoryDetailPage = () => {
  return (
    <DefaultLayout withRightSidebar={true}>
      <CategoryDetailContainer />
    </DefaultLayout>
  );
};

export default CategoryDetailPage;
