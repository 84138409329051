import React, {useEffect, useState} from "react";
import s from "./FinancialBenefitsDetailsContainer.module.scss";
import {useHistory, useParams} from "react-router-dom";
import services from "../../../services";
import SplitBenefits from "./SplitBenefits/SplitBenefits";
import Detail from "../../elements/Detail/Detail";
import {formatDate} from "../../../helpers/dateFormat";
import DetailLoading from "../../loadingStates/Detail/Detail";
import {currencyData} from "../../../helpers/helpData";

const FinancialBenefitsDetailsContainer = () => {
  const {projectId, financialBenefitId} = useParams();
  const history = useHistory();
  const [project, setProject] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const preparedCurrency = currencyData.filter(e => e.label === data?.project_spend_currency)[0]?.value;

  useEffect(() => {
    services.projectServices.getProject(projectId).then(res => {
      setProject(res.data);

      services.financialBenefitsServises.getFinancialBenefit(projectId, financialBenefitId).then(res => {
        if (res.data) {
          setData(res.data);
          setLoading(false);
        }
      });
    });
  }, [financialBenefitId]);

  let details = <DetailLoading length={6} className={s.details}/>;

  if (!loading) details = (
    <Detail
      data={[
        {
          name: "Start date",
          value: formatDate(data.start_date, true) || "-",
        },
        {
          name: "Final Cost",
          value: `${preparedCurrency} ${data.project_spend}` || "-",
        },
        {
          name: "Savings type",
          value: data.savings_type?.name || "-",
        },
        {
          name: "End date",
          value: formatDate(data.end_date, true) || "-",
        },
        {
          name: "Final Savings",
          value: `${preparedCurrency} ${data.project_savings}` || "-",
        },
        {
          name: "Attachments",
          value: data.attachments,
          type: "attachment"
        },
        {
          name: "Note",
          value: data.note || "-",
        }
      ].map(i => ({...i, useParagraph: true}))}
      className={s.details}
      useColumns
      buttons={[
        project.permissions?.update && {
          name: "Edit",
          onClick: () => history.push(`/projects/${projectId}/financial-benefits/${financialBenefitId}/edit`),
        }
      ]}
    />
  );

  return (
    <div className={s.wrap}>
      <h2 className={s.title}>Financial benefit</h2>
      {details}
      <SplitBenefits data={data} loading={loading}/>
    </div>
  )
}
export default FinancialBenefitsDetailsContainer
