import React, { useState, Fragment } from "react"
import querystring from "query-string"

import services from "services/index"
import Popup from "components/popups/Popup/Popup"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import SurveysTable from "components/tables/SurveysTable/SurveysTable"
import Button from "components/elements/Button/Button"

const SurveyOwnerContainer = ({
  params,
  getSurveys,
  setAlert,
  surveys,
  history,
  setServeySent
}) => {
  const [show, setShow] = useState(false)
  const query = querystring.parse(history.location.search) || {}

  const pageChangeHandler = page => {
    history.push({ search: querystring.stringify({ ...query, page }) })
  }

  const sortHandler = sort => {
    history.push({
      search: querystring.stringify({ ...query, ...sort })
    })
  }

  const sendSurveyHandler = () => {
    services.surveyServices
      .sendSurvey(params.id)
      .then(res => {
        getSurveys()
        setAlert({
          message: res.data.message
        })
        setServeySent(true)
      })
      .catch(res =>
        setAlert({
          message: res.response.data.error,
          type: "error"
        })
      )
      .finally(() => setShow(false))
  }

  return (
    <Fragment>
      {surveys.survey_results_count ? (
        <SurveysTable
          history={history}
          projectId={params.id}
          surveys={surveys}
          onPageChange={pageChangeHandler}
          currentPage={query.page || 1}
          onSort={sortHandler}
          onSendToNewStackholders={() => setShow(true)}
        />
      ) : (
        <EmptyBlock
          subtitle='You don’t have survey results yet. You can send a survey to stakeholders'
          withBtn={false}
        >
          <Button
            styled='primary'
            onClick={() => setShow(true)}
            style={{ marginTop: "24px" }}
          >
            Send survey
          </Button>
        </EmptyBlock>
      )}

      {show && (
        <Popup
          onClose={() => setShow(false)}
          title='Send survey'
          text='Are you sure you want to send this survey to all project stakeholders?'
          successBtnTitle='Send'
          dialog={true}
          onClick={sendSurveyHandler}
        />
      )}
    </Fragment>
  )
}

export default SurveyOwnerContainer
