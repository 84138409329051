import React, {useEffect, useState} from "react";
import s from "./TaskStakeholdersContainer.module.scss";
import TaskStakeholdersTable from "../../tables/TaskStakeholdersTable/TaskStakeholdersTable";
import services from "../../../services";
import {useParams} from "react-router-dom";

const TaskStakeholdersContainer = () => {
  const {taskId} = useParams();
  const [stakeholders, setStakeholders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    services.projectServices.getTaskStakeholders(taskId).then(res => {
      if (res.data) {
        setStakeholders(res.data);
        setLoading(false);
      }
    });
  }, []);

  const addStakeholder = params => {
    services.projectServices.createTaskStakeholders(taskId, params).then(res => {
      if (res.data) {
        setStakeholders(stakeholders => [...stakeholders, res.data]);
      }
    });
  };

  const editStakeholder = (params, stakeholderId) => {
    services.projectServices.editTaskStakeholders(taskId, stakeholderId, params).then(res => {
      if (res.data) {
        setStakeholders(stakeholders => stakeholders
          .map(stakeholder => {
            if (stakeholder.stakeholder.id === stakeholderId) {
              return res.data;
            }
            return stakeholder;
          }));
      }
    });
  };

  const deleteStakeholder = stakeholderId => {
    services.projectServices.deleteTaskStakeholders(taskId, stakeholderId).then(res => {
      if (res.data) {
        setStakeholders(stakeholders => stakeholders
          .filter(stakeholder => stakeholder.stakeholder.id !== stakeholderId));
      }
    });
  };

  return (
    <div className={s.wrap}>
      <h2>Stakeholders</h2>
      {
        !loading && (
          <TaskStakeholdersTable
            data={stakeholders}
            onAdd={addStakeholder}
            onEdit={editStakeholder}
            onDelete={deleteStakeholder}
          />
        )
      }
    </div>
  );
};

export default TaskStakeholdersContainer;
