import axios from "./axios";

export default {
  getTypeOfSpend: async () => {
    try {
      return await axios.get(`/admin/additional_questions/types_of_spend`);
    } catch (error) {
      throw error;
    }
  },
  getAdditionalQuestions: async (type, source) => {
    try {
      return await axios.get(`/additional_questions?type=${type}&source=${source}`);
    } catch (error) {
      throw error;
    }
  },
  addTypeOfSpend: async values => {
    try {
      return await axios.post(`/admin/additional_questions/types_of_spend`, values);
    } catch (error) {
      throw error;
    }
  },
  deleteTypeOfSpend: async id => {
    try {
      return await axios.delete(`/admin/additional_questions/types_of_spend/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getSupplierStatuses: async () => {
    try {
      return await axios.get(`/admin/additional_questions/supplier_statuses`);
    } catch (error) {
      throw error;
    }
  },
  addSupplierStatus: async values => {
    try {
      return await axios.post(`/admin/additional_questions/supplier_statuses`, values);
    } catch (error) {
      throw error;
    }
  },
  deleteSupplierStatus: async id => {
    try {
      return await axios.delete(`/admin/additional_questions/supplier_statuses/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getBudgets: async () => {
    try {
      return await axios.get(`/admin/additional_questions/budgets`);
    } catch (error) {
      throw error;
    }
  },
  addBudget: async values => {
    try {
      return await axios.post(`/admin/additional_questions/budgets`, values);
    } catch (error) {
      throw error;
    }
  },
  updateBudget: async (values, id) => {
    try {
      return await axios.put(`/admin/additional_questions/budgets/${id}`, values);
    } catch (error) {
      throw error;
    }
  },
  deleteBudget: async id => {
    try {
      return await axios.delete(`/admin/additional_questions/budgets/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getProjectTypes: async () => {
    try {
      return await axios.get(`/admin/additional_questions/project_types`);
    } catch (error) {
      throw error;
    }
  },
  addProjectType: async values => {
    try {
      return await axios.post(`/admin/additional_questions/project_types`, values);
    } catch (error) {
      throw error;
    }
  },
  deleteProjectType: async id => {
    try {
      return await axios.delete(`/admin/additional_questions/project_types/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getCategories: async () => {
    try {
      return await axios.get(`/admin/additional_questions/categories`);
    } catch (error) {
      throw error;
    }
  },
  addCategories: async values => {
    try {
      return await axios.post(`/admin/additional_questions/categories`, values);
    } catch (error) {
      throw error;
    }
  },
  deleteCategories: async id => {
    try {
      return await axios.delete(`/admin/additional_questions/categories/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getQuestions: async id => {
    try {
      return await axios.get(`/admin/conditions/${id}/questions`);
    } catch (error) {
      throw error;
    }
  },
  createQuestion: async (id, questions) => {
    try {
      return await axios.post(`/admin/conditions/${id}/questions`, questions);
    } catch (error) {
      throw error;
    }
  }
}
