import Avatar from "../../../../elements/Avatar/Avatar";
import React from "react";
import PropTypes from "prop-types";
import styles from "./ResponsibleUser.module.scss";
import {ReactComponent as UnknownUser} from "../../../../../assets/icons/unknown-user-icon.svg";
import {trimRest} from "../../../../../helpers/stringHelper";

const ResponsibleUser = ({user}) => {
  if (user) return (
    <div className={styles.flexContainer} title={user.name}>
      <Avatar name={user.name}/> {trimRest(24, user.name)}
    </div>
  );

  return (
    <div className={styles.flexContainer}>
      <UnknownUser/>
      Unassigned
    </div>
  );
};

export default ResponsibleUser;

ResponsibleUser.propTypes = {
  user: PropTypes.any
};