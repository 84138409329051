import React from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import Textarea from "../../../../../../elements/Textarea/Textarea";
import Button from "../../../../../../elements/Button/Button";

const AnnouncementsForm = ({announcement, onClose, isEdit, onEdit, onCreate}) => {
  const NOTE_LENGTH = 150;

  const formik = useFormik({
    initialValues: {
      note: announcement?.body ?? '',
    },
    validationSchema: object({
      note: string()
        .required("Please, write a note")
        .max(
          NOTE_LENGTH,
          `Please enter Note description that consists of no more than ${NOTE_LENGTH} characters`
        )
    }),
    onSubmit: (values) => {
      if (isEdit) {
        onEdit({id: announcement.id, note: values.note});
      } else {
        onCreate(values);
      }
      onClose();
    },
  })

  const {handleSubmit, values, errors, setFieldValue, touched} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <Textarea
        placeholder='Enter a note'
        value={values.note}
        onChange={e => setFieldValue("note", e.target.value)}
        label='Note'
        error={touched.note && errors.note}
        count={{current: values.note?.length, max: NOTE_LENGTH}}
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>{isEdit ? 'Save' : 'Create'}</Button>
      </div>
    </form>
  );
};

export default AnnouncementsForm;


