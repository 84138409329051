import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import CustomDropDownsForm from "../../forms/CustomDropDownsForm/CustomDropDownsForm";
import s from "./AdminSettingsCustomDropDownsAddContainer.module.scss";
import {paths} from "../../../routes/paths";
import services from "../../../services";
import {useHistory} from "react-router-dom";

const AdminSettingsCustomDropDownsAddContainer = () => {
  const history = useHistory();

  const addNewDropDown = values => {
    services.customDropdownsServices.addCustomDropdowns(values).then(res => {
      if (res.data) {
        history.push(paths.adminSettingsRequest + "?tab=Custom%20dropdowns");
      }
    });
  };

  return (
    <DefaultLayout
      breadcrumbs={[
        {name: "Admin Settings", path: "", disabled: true},
        {
          name: "Requests",
          path: paths.adminSettingsRequest,
        },
        {
          name: "New dropdown",
        },
      ]}
    >
      <div className={s.wrapper}>
        <CustomDropDownsForm changeDropDown={addNewDropDown}/>
      </div>
    </DefaultLayout>
  );
};

export default AdminSettingsCustomDropDownsAddContainer;