import React from "react"
import MainTable from "../MainTable/MainTable"
import { useHistory, useParams } from "react-router-dom"
import { ReactComponent as Reorder } from "assets/icons/reorder.svg"
import s from "./AdminSettingsProjectsStagesTable.module.scss"

const AdminSettingsProjectsStagesTable = ({
  data = [],
  onEdit,
  perPage,
  totalCount,
  onPageChange,
  currentPage,
  onAdd,
  onDelete
}) => {
  const history = useHistory()
  const params = useParams()
  return (
    <MainTable
      totalName='Project stages'
      data={data.sort((a, b) => a.level - b.level)}
      dragWithSort={true}
      dragIdLabel='project_stages'
      dataTypes={[
        {
          title: "Stage",
          value: ({ name }) => (
            <div className={s.name}>
              <Reorder /> <span>{name || "-"}</span>
            </div>
          ),
          onClick: id => history.push(`/admin-settings/projects/stages/${id}`)
        },
        {
          title: "Minimum requirements",
          value: ({ stage_tasks_count }) =>
            !stage_tasks_count && stage_tasks_count !== 0
              ? "-"
              : stage_tasks_count
        }
      ]}
      button={{
        name: "New stage",
        onClick: () => onAdd()
      }}
      options={item => {
        return [
          onEdit && {
            title: "Edit",
            onClick: () => {
              onEdit(item)
            }
          },
          onDelete && {
            title: "Delete",
            onClick: () => {
              onDelete(item)
            }
          }
        ]
      }}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  )
}
export default AdminSettingsProjectsStagesTable
