import React, {useState, useEffect, useContext} from "react";
import {useParams} from "react-router-dom";
import classNames from "classnames";
import styles from "./SupplierDetailsContainer.module.scss";
import SupplierDetail from "./components/SupplierDetail/SupplierDetail";
import Skeleton from "@material-ui/lab/Skeleton";
import SupplierGeneral from "./components/SupplierGeneral/SupplierGeneral";
import SupplierContracts from "./components/SupplierContracts/SupplierContracts";
import SupplierProjetcs from "./components/SupplierProjetcs/SupplierProjetcs";
import SupplierStakeholders from "./components/SupplierStakeholders/SupplierStakeholders";
import SupplierServices from "./components/SupplierServices/SupplierServices";
import {ReactComponent as PencilChange} from "../../../assets/icons/pencilChange.svg";
import services from "../../../services";
import UploadLogoPopup from "../../popups/UploadLogoPopup/UploadLogoPopup";
import LogoSuppliers from "../../elements/LogoSuppliers/LogoSuppliers";
import AlertContext from "../../../contexts/AlertContext/AlertContext";
import {useSelector} from "react-redux";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import {useTranslation} from "react-i18next";

const SupplierDetailsContainer = () => {
  const params = useParams();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [supplier, setSupplier] = useState({});
  const [show, setShow] = useState(false);
  const {setAlert, getCancelId} = useContext(AlertContext);
  const user = useSelector(state => state.user);

  useEffect(() => {
    services.supplierServices.getSupplier(params.id).then((res) => {
      setSupplier(res.data);
      setLoading(false);
    });
  }, [params.id]);
  const ownerId = supplier.owner && supplier.owner.id;

  const accessServices =
    user.roles.includes(userRoles.cpo) ||
    user.roles.includes(userRoles.admin) || ownerId === user.id;

  const handleUploadLogoSubmit = supplier => {
    setSupplier(supplier);
  };

  const deleteTagHandle = async tagId => {
    services.tagsServices
      .deleteSupplierTag(params.id, tagId)
      .then(() => {
        setSupplier({
          ...supplier,
          tags: supplier.tags.filter(e => e.id !== tagId)
        });
      });
  };

  const handleAssignTag = async (supplierId, tagId) =>
    await services.tagsServices.assignSupplierTag(supplierId, tagId).then(res => {
      setSupplier({
        ...supplier,
        tags: res.data.tags
      });
      setAlert({
        type: "success",
        message: "Tag successfully assigned."
      });
    });

  return (
    <div className={styles.container}>
      {loading && !supplier?.name ? (
        <Skeleton
          variant="text"
          className={classNames("title", styles.title)}
          animation="wave"
          width={236}
        />
      ) : (
        <div className={styles.logoNameBlock}>
          <div className={styles.imgLogo}>
            {accessServices ? <PencilChange onClick={() => {
              setShow(true);
            }}
            /> : ""}
            <LogoSuppliers classNameLogo={styles.logo} name={supplier.name} logo={supplier.logo}/>
          </div>
          <h2 className={styles.title} data-translate-key="supplier">{t("supplier")} "{supplier.name}"</h2>
        </div>
      )}
      {!loading && (
        <SupplierDetail
          data={supplier}
          onDeleteTag={deleteTagHandle}
          onAssignTag={(tagId) => handleAssignTag(supplier.id, tagId)}
        />
      )}
      <div className={styles.content}>
        <div className={styles.row}>
          <SupplierGeneral/>
        </div>
        <div className={styles.row}>
          <h3 className={styles.name} data-translate-key="services">
            {t("services")}
          </h3>
          <SupplierServices supplier={supplier}/>
        </div>
        <div className={styles.row}>
          <h3 className={styles.name} data-translate-key="active-contracts">
            {t("active-contracts")}
          </h3>
          <SupplierContracts/>
        </div>
        <div className={styles.row}>
          <h3 className={styles.name} data-translate-key="active-projects">
            {t("active-projects")}
          </h3>
          <SupplierProjetcs/>
        </div>
        <div className={styles.row}>
          <h3 className={styles.name} data-translate-key="stakeholders">
            {t("stakeholders")}
          </h3>
          <SupplierStakeholders/>
        </div>
      </div>
      {show && (
        <UploadLogoPopup
          title={`Upload logo`}
          setShow={setShow}
          url={supplier.logo}
          onSubmit={handleUploadLogoSubmit}
        />
      )}
    </div>
  );
};

export default SupplierDetailsContainer;