import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./Button.module.scss";
import {useTranslate} from "../../../hooks/useTranslate";

const Button = (
  {
    children,
    styled,
    className,
    onClick,
    disabled,
    type,
    style,
    withoutTranslate = false
  }
) => {
  const [translateKey, translateValue] = useTranslate(children);

  const classes = classNames(
    s.btn,
    {
      [s.primary]: styled === "primary",
      [s.secondary]: styled === "secondary",
      [s.addElement]: styled === "addElement",
      [s.textButton]: styled === "textButton",
      [s.disabled]: disabled
    },
    className
  );
  return (
    <button
      type={type}
      disabled={disabled}
      className={classes}
      onClick={onClick}
      style={style}
      data-translate-key={translateKey}
    >
      {withoutTranslate ? children : translateValue}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  styled: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.any,
  withoutTranslate: PropTypes.bool,
};

Button.defaultProps = {
  children: "Next",
  onClick: () => {
  },
  styled: "addElement"
};

export default Button;
