import React, {useLayoutEffect, useRef, useState} from 'react';
import styles from "./TimeSpent.module.scss";
import classNames from "classnames";

const TimeSpentItem = ({milestone, maxHeight}) => {
  const projectWrapRef = useRef();
  const [projectSizes, setProjectSizes] = useState({
    current: 0,
    parent: 0,
  });

  const isHide = (parseInt(projectSizes.parent) - parseInt(projectSizes.current)) < 1;
  const title = `${milestone.name} - ${milestone.spend} day${milestone.spend > 1 && "s"}`;

  useLayoutEffect(() => {
    setProjectSizes({
      current: projectWrapRef.current.clientHeight,
      parent: projectWrapRef.current.parentNode.clientHeight - 10,
    });
  }, []);

  return (
    <div
      className={classNames(styles.project)}
      style={{
        height: `${(milestone.spend / maxHeight) * 100}%`,
      }}>
      <div
        className={styles.projectWrap}
        style={{
          opacity: isHide ? "0" : "1",
          cursor: isHide ? "pointer" : "default",
        }}
        title={title}
        ref={projectWrapRef}
      >
        <span>{milestone.name}</span>
        <span>({milestone.spend} day{milestone.spend > 1 && "s"})</span>
      </div>
    </div>
  );
};

export default TimeSpentItem;