import React, {useState, useEffect} from "react"
import {useParams} from "react-router-dom"
import classNames from "classnames"
import styles from "./DepartmentReportContainer.module.scss"
import Detail from "components/elements/Detail/Detail"
import DetailLoading from "components/loadingStates/Detail/Detail"
import services from "services/index"
import {formatDetailData} from "./helpers"
import DepartmentReportGeneral from "./components/DepartmentReportGeneral/DepartmentReportGeneral"
import DepartmentReportProjects from "./components/DepartmentReportProjects/DepartmentReportProjects"
import SavingsChart from "components/elements/SavingsChart/SavingsChart"
import PieChartWidgetLoading from "components/loadingStates/PieChartWidget/PieChartWidget"
import PieChartWiget from "components/elements/PieChartWidget/PieChartWidget"
import FilterBarDropdown from "components/elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown"
import {years as allYears} from "components/containers/UserReportContainer/helpers/years"
import CompanyGoalContainer from 'components/containers/CompanyGoalContainer/CompanyGoalContainer'
import {getPieChartWidgetTitle} from "../../../helpers/getPieChartWidgetTitle";
import {getSavingsWidgetTitle} from "../../../helpers/getSavingsWidgetTitle";
import {getChartValues} from "../../../helpers/getChartValues";
import ReportTimeSpendWidget from "../../elements/ReportTimeSpendWidget/ReportTimeSpendWidget";

const DepartmentReportContainer = () => {
  const params = useParams()
  const [years, setYears] = useState(allYears)

  const [department, setDepartment] = useState({})
  const [loading, setLoading] = useState(true)
  const [chartData, setChartData] = useState(null)
  const [selectedYears, setSelectedYears] = useState(["2021"])
  useEffect(() => {
    params.id &&
    services.reportServices.getDepartmentReport(params.id).then(res => {
      setDepartment(res.data)
      setLoading(false)
    })
    services.reportsServices
      .getYears()
      .then(
        res =>
          res?.data &&
          setYears(
            res?.data?.years?.map(e => ({id: String(e), name: String(e)}))
          )
      )
  }, [params.id]);

  useEffect(() => {
    params.id &&
    services.reportsServices
      .departmentReportActivity(params.id, {
        years: selectedYears?.join(",") || ""
      })
      .then(res => {
        const data = res?.data || {}
        if (res?.data?.period === "quarters") {
          setChartData(res?.data || null)
        } else if (res?.data?.period === "years") {
          setChartData(data);
        } else {
          setChartData(res?.data)
        }
      })
  }, [selectedYears]);

  return (
    <section className={styles.container}>
      {loading ? (
        <DetailLoading length={6}/>
      ) : (
        <>
          <h3 className={styles.title}>{department.name}</h3>
          <Detail
            data={formatDetailData(department)}
            className={styles.detail}
          />
        </>
      )}
      <div className={styles.content}>
        <div className={styles.row}>
          <h3 className={styles.name}>General</h3>
          <DepartmentReportGeneral/>
        </div>
        <div className={styles.row}>
          <h3 className={styles.name}>Time spent</h3>
          <ReportTimeSpendWidget type="department"/>
        </div>
        <div className={styles.row}>
          <CompanyGoalContainer isRequest={true} source='department' withFilters={false} withEdit={false}/>
        </div>
        <div className={styles.row}>
          <h3 className={styles.name}>Project information</h3>
          {!chartData && (
            <PieChartWidgetLoading
              className={styles.pieChartLoader}
              width={144}
            />
          )}
          {(chartData && chartData.period === "years") && (
            <div className={classNames(styles.savingsChartWrap, styles.row)}>
              <PieChartWiget
                data={getChartValues(chartData)}
                title={getPieChartWidgetTitle(selectedYears, chartData)}
              />
              <div className={styles.years}>
                <FilterBarDropdown
                  name='Years'
                  options={years}
                  defaultValues={selectedYears}
                  handleChange={values => {
                    setSelectedYears(values.map(e => e.name))
                  }}
                  setFilterValue={() => {
                  }}
                />
              </div>
            </div>
          )}
          {chartData && !chartData.period && (
            <div className={classNames(styles.savingsChartWrap, styles.row)}>
              <SavingsChart
                title={getSavingsWidgetTitle(selectedYears, chartData)}
                data={chartData}
              />
              <div className={styles.years}>
                <FilterBarDropdown
                  name='Years'
                  options={years}
                  defaultValues={selectedYears}
                  handleChange={values => {
                    setSelectedYears(values.map(e => e.name))
                  }}
                  setFilterValue={() => {
                  }}
                />
              </div>
            </div>
          )}
          <DepartmentReportProjects/>
        </div>
      </div>
    </section>
  )
}

export default DepartmentReportContainer
