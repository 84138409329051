import React, {useEffect, useState} from "react";
import s from "../Category.module.scss";
import classNames from "classnames";
import {formatCurrency} from "../../../../../../../helpers/currencyFormat";
import services from "../../../../../../../services";
import {ReactComponent as CategoryName} from "../../../../../../../assets/icons/category_name.svg";
import {ReactComponent as ProjectIcon} from "../../../../../../../assets/icons/projects_lite.svg";
import {ReactComponent as SuppliersIcon} from "../../../../../../../assets/icons/suppliers_lite.svg";
import {ReactComponent as ContractsIcon} from "../../../../../../../assets/icons/contracts_lite.svg";
import {ReactComponent as SpendIcon} from "../../../../../../../assets/icons/spend.svg";
import {ReactComponent as AnticipatedSavings} from "../../../../../../../assets/icons/anticipated_savings.svg";

const CategoryOverview = ({id}) => {
  const [data, setData] = useState({});

  useEffect(() => {
    services.requestTypeServices.getCategoryOverview(id).then(res => {
      setData(res.data);
    });
  }, []);

  const emptyData = <span style={{color: "#63A5A1"}}>-</span>;

  return (
    <div className={s.categoryOverview}>
      <div className={s.infoBlock}>
        <div className={s.name}><CategoryName/>Category name:</div>
        <div className={s.value}>{data.name || emptyData}</div>
      </div>
      <div className={s.infoBlock}>
        <div className={s.name}><ProjectIcon/>Projects:</div>
        <div className={s.value}>{data.projects_count || emptyData}</div>
      </div>
      <div className={s.infoBlock}>
        <div className={s.name}><SuppliersIcon/>Suppliers:</div>
        <div className={s.value}>{data.suppliers_count || emptyData}</div>
      </div>
      <div className={s.infoBlock}>
        <div className={s.name}><ContractsIcon/>Contracts:</div>
        <div className={s.value}>{data.contracts_count || emptyData}</div>
      </div>
      <div className={s.infoBlock}>
        <div className={s.name}><SpendIcon/>Spend:</div>
        <div className={classNames(s.value, s.turquoise)}>
          {formatCurrency(data.currency, data.target_spend)}
        </div>
      </div>
      <div className={s.infoBlock}>
        <div className={s.name}><AnticipatedSavings/>Anticipated Savings:</div>
        <div className={classNames(s.value, s.turquoise)}>
          {formatCurrency(data.currency, data.anticipated_savings)}
        </div>
      </div>
    </div>
  );
};

export default CategoryOverview;
