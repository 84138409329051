export let baseURL;
export let translateUrl;

let host = window.location.hostname.split(".").slice(-2).join(".");

if (host === "localhost") host = "focalpointprocurement.com";

if (process.env.REACT_APP_MODE === "prod") {
  baseURL = `https://api.${host}/v1`;
} else if (process.env.REACT_APP_MODE === "demo") {
  baseURL = `https://api-demo.${host}/v1`;
} else {
  baseURL = `https://api-qa.${host}/v1`;
}

if (window.location.hostname.split(".").slice(-2).join(".") === "localhost")
  translateUrl = "http://localhost:5000/locales/{{lng}}";
else
  translateUrl = `${baseURL}/locales/{{lng}}`;