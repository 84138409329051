import React from 'react'
import s from './GoalCard.module.scss'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ReactComponent as Edit } from 'assets/icons/edit-pencil.svg'
import {useTranslation} from "react-i18next";
import CustomNotification from "../../../QuestionNotification/CustomNotification";

const GoalsCard = (
  {
    name,
    quarters,
    annualGoal,
    active,
    width,
    height,
    className,
    onClick,
    onEdit,
    loadingState,
    onRedirect,
    subDataCount,
    subDataType,
    teamName,
    type
  }
) => {
  const {t} = useTranslation();

  const getCardType = () => type?.charAt(0);

  const getCardColor = () => {
    if (type === "company") return "#FFA600";
    if (type === "department") return "#1E8B76";
    if (type === "team") return "#5A67B1";
    if (type === "user") return "#CB5CAA";
  };

  return (
    <li
      className={classNames(
        s.goalsCard,
        {[s.active]: active, [s.loading]: loadingState},
        className
      )}
      style={{width: `${width}px`, maxHeight: `${height}px`}}
      onClick={() => !loadingState && onClick()}
    >
      {!loadingState && (
        <React.Fragment>
          <div className={s.titleWrap}>
            <CustomNotification
              value={type}
              className={s.cardWrapper}
              component={
                <div
                  className={s.cardType}
                  style={{background: getCardColor()}}
                >
                  {getCardType()}
                </div>
              }
              withTranslate
            />
            <div className={s.title}>
              <h4
                className={s.name}
                onClick={e => {
                  e.stopPropagation()
                  !loadingState && onRedirect()
                }}
              >
                {teamName ? teamName : name}
              </h4>
              {subDataType && (
                <span>
								{`(${subDataCount} ${subDataType})`}
							</span>
              )}
              {
                active && (
                  <p className={s.titleName}>
                    {teamName ? name : ""}
                  </p>
                )
              }
            </div>
          </div>
          {active && (
            <React.Fragment>
              <header>
                <p>Period</p>
                <p>Goal <span>/ Remaining</span></p>
              </header>
              <ul className={s.quarters}>
                {quarters.map((quarter, index) => (
                  <li className={s.quarter} key={index}>
                    <span className={s.label}>{`Q${index + 1}`}</span>
                    <span className={s.currency}>
										  <span>$ {Number(quarter[0]).toLocaleString("en-US", {maximumFractionDigits: 0})}</span>
                      <span> / </span>
                      <span>$ {Number(quarter[1]).toLocaleString("en-US", {maximumFractionDigits: 0})}</span>
									  </span>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
          <div className={s.subData}>
            <span data-translate-key="annual-goal">{t("annual-goal")}</span>
            <span className={s.annualGoal}>
              <span>
                {`$ ${Number(annualGoal[0]).toLocaleString("en-US", {maximumFractionDigits: 0})}`}
              </span>
              <span> / </span>
              <span>{`$ ${Number(annualGoal[1]).toLocaleString("en-US", {maximumFractionDigits: 0})}`}</span>
						</span>
          </div>
          {onEdit && (
            <span className={s.editBtn}>
							<Edit
                onClick={e => {
                  e.stopPropagation()
                  onEdit()
                }}
              />
						</span>
          )}
        </React.Fragment>
      )}
    </li>
  )
}

GoalsCard.propTypes = {
  name: PropTypes.string,
  quarters: PropTypes.arrayOf(PropTypes.string),
  annualGoal: PropTypes.string,
  leftAnnualGoal: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  loadingState: PropTypes.bool,
  onRedirect: PropTypes.func,
  subDataCount: PropTypes.number
}

GoalsCard.defaultProps = {
  name: 'Company',
  quarters: ['0.0', '0.0', '0.0', '0.0'],
  annualGoal: '0.0',
  leftAnnualGoal: '0.0',
  active: false,
  width: 250,
  height: 258,
  onClick: () => {
  },
  loadingState: false,
  onRedirect: () => {
  },
  subDataCount: 0
}

export default GoalsCard
