import React, {useState} from 'react';
import SimpleDetails from "../../../elements/SimpleDetails/SimpleDetails";
import s from "./DetailsPanel.module.scss";
import TeamTreeDropDown from "../TeamTreeDropDown/TeamTreeDropDown";
import {teamList} from "../helpers/testData";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import Popup from "../../../popups/Popup/Popup";
import EditTriageEntityForm from "../../../forms/EditTriageEntityForm/EditTriageEntityForm";
import {userRoles as useRoles} from "../../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";

const DetailsPanel = ({data, updateEntityItem, entity}) => {
  const [isOpenEditPopUp, setOpenEditPopUp] = useState(false);
  const user = useSelector(state => state.user);
  const {t} = useTranslation();

  return (
    <>
      <SimpleDetails
        onBtnClick={
          user.roles.includes(useRoles.admin) ?
            null :
            () => setOpenEditPopUp(true)
        }
      >
        <div className={s.columns}>
          <div className={s.col}>
            <div className={s.title} data-translate-key={getTranslateKey("Teams")}>
              {t(getTranslateKey("Teams"))}:
            </div>
            <TeamTreeDropDown data={teamList}/>
          </div>
          <div className={s.col}>
            <div className={s.title} data-translate-key={getTranslateKey("Triage person")}>
              {t(getTranslateKey("Triage person"))}:
            </div>
            <p className={s.value}>
              {data?.triage_user ? data?.triage_user.name : "-"}
            </p>
          </div>
        </div>
      </SimpleDetails>
      {isOpenEditPopUp && (
        <Popup
          title="Edit triage person"
          onClose={() => setOpenEditPopUp(false)}
        >
          <EditTriageEntityForm
            successBtnTitle="Save"
            onClose={() => setOpenEditPopUp(false)}
            defaultValues={{
              triage_user: data.triage_user && {
                value: data.triage_user.id,
                label: data.triage_user.name,
              }
            }}
            entity={entity.toLowerCase()}
            submit={triageUserId => {
              updateEntityItem({triageUserId});
              setOpenEditPopUp(false);
            }}
          />
        </Popup>
      )}
    </>
  );
};

export default DetailsPanel;