import { fromDate } from "helpers/dateFormat";
import { localization } from "helpers/localizationForCompanies";
import { formatCurrency } from "helpers/currencyFormat";
import { currencyData } from "helpers/helpData";

export default (data) => {
  return [
    { name: "Category", value: data.category },
    { name: "Start date", value: fromDate(data.start_date) },
    { name: `${localization.targetEndDate}`, value: fromDate(data.end_date) },
    {
      name: `${localization.finalCost}`,
      value: data.project_spend
        ? formatCurrency(
            currencyData.filter(
              (e) => e.label === (data.default_currency || "USD")
            )[0]?.value,
            data.project_spend
          )
        : "-",
    },
    {
      name: `${localization.finalSavings}`,
      value: data.project_savings
        ? formatCurrency(
            currencyData.filter(
              (e) => e.label === (data.default_currency || "USD")
            )[0]?.value,
            data.project_savings
          )
        : "-",
    },
  ];
};
