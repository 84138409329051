import React from "react";
import s from "./ProjectActualSavingsMonthNotesContainer.module.scss";
import {useHistory} from "react-router-dom";
import ProjectActualSavingsMonthNotesForm
  from "../../forms/ProjectActualSavingsMonthNotesForm/ProjectActualSavingsMonthNotesForm";
import PropTypes from "prop-types";

const ProjectActualSavingsMonthNotesContainer = ({idProject}) => {
  const history = useHistory();
  return (
    <div className={s.wrap}>
      <h3 className={s.title}>{history.location.state.from.month}</h3>
      <div className={s.wrapNote}>
        <ProjectActualSavingsMonthNotesForm idProject={idProject}/>
      </div>
    </div>
  );
};

export default ProjectActualSavingsMonthNotesContainer;

ProjectActualSavingsMonthNotesContainer.propTypes = {
  idProject: PropTypes.string,
};