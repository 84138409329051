import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import SystemReportsContainer from "components/containers/SystemReportsContainer/SystemReportsContainer";
const SystemReportsPage = () => {
  return (
    <DefaultLayout>
      <SystemReportsContainer />
    </DefaultLayout>
  );
};

export default SystemReportsPage;
