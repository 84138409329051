import React, {useEffect, useState} from "react";
import AutoCompleteInput from "../../../../../elements/AutoCompleteInput/AutoCompleteInput";
import {
  parserForSelect
} from "../../../../../containers/AdminSettingsStepFieldDetailsContainer/helpers/parserForSelect";
import PropTypes from "prop-types";
import services from "../../../../../../services";
import {recursiveJoin} from "../../../../../../helpers/recursiveJoin";

const TypeOfSpendSelect = (
  {
    field,
    values,
    setFieldValue,
    errors,
    changeAdditionalQuestions
  }
) => {
  const [categories, setCategories] = useState([]);
  const correctFieldKey = field.key ? field.key : field.value;

  useEffect(() => {
    services.categoriesServices
      .getCategories()
      .then(res => {
        if (res.data) {
          setCategories(recursiveJoin(res.data, "categories"));
        }
      });
  }, []);

  return (
    <AutoCompleteInput
      label={field.label}
      value={values[correctFieldKey]?.label || ""}
      placeholder=""
      withoutTranslate
      onChange={e => {
        setFieldValue(correctFieldKey, {
          value: "",
          label: e.target.value,
          type: "Type of Spend",
          conditions: field.conditions
        });
        if (changeAdditionalQuestions) changeAdditionalQuestions({
          value: "",
          label: e.target.value
        }, "type_of_spend", correctFieldKey);
      }}
      onSelect={value => {
        setFieldValue(correctFieldKey, {...parserForSelect(value), type: "Type of Spend", conditions: field.conditions});
        if (changeAdditionalQuestions) changeAdditionalQuestions(parserForSelect(value), "type_of_spend", correctFieldKey);
      }}
      data={categories}
      error={errors?.[correctFieldKey]?.value}
    />
  );
};

export default TypeOfSpendSelect;

TypeOfSpendSelect.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  onlyFirstLevel: PropTypes.bool,
  errors: PropTypes.any,
  changeAdditionalQuestions: PropTypes.func,
};