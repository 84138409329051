import React, {useState} from "react";
import MainTable from "components/tables/MainTable/MainTable";
import PropTypes from "prop-types";
import Popup from "../../popups/Popup/Popup";
import EmptyBlock from "../../elements/EmptyBlock/EmptyBlock";
import StakeholderForm from "../../forms/StakeholderForm/StakeholderForm";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import styles from "./TaskStakeholdersTable.module.scss";
import Avatar from "../../elements/Avatar/Avatar";

const TaskStakeholdersTable = ({data, onDelete, onAdd, onEdit}) => {
  const [popUp, setPopUp] = useState(null);
  const [currentStakeholder, setCurrentStakeholder] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const {permissions} = queryString.parse(
    location.search
  );

  const deleteHandler = () => {
    onDelete(currentStakeholder.stakeholder.id);
    setPopUp(null);
    setCurrentStakeholder(null);
  };

  const addHandler = params => {
    onAdd(params);
    setPopUp(null);
  };

  const editHandler = params => {
    onEdit(params, currentStakeholder.stakeholder.id);
    setPopUp(null);
    setCurrentStakeholder(null);
  };

  const getUsedStakeholderIds = () => {
    return data.filter(s => s.stakeholder.id !== currentStakeholder?.id).map(s => s.stakeholder.id);
  };

  const addPopUp = (
    <Popup
      title="Add stakeholder"
      text="Please, complete the following fields to add a new stakeholder"
      onClose={() => setPopUp(null)}
    >
      <StakeholderForm
        submit={addHandler}
        close={() => setPopUp(null)}
        usedStakeholderIds={getUsedStakeholderIds()}
      />
    </Popup>
  );

  if (!data.length) return (
    <React.Fragment>
      <EmptyBlock
        subtitle={`You don’t have stakeholders yet. ${permissions && "You can add a new one."}`}
        buttonTitle="Add stakeholder"
        onClick={() => setPopUp("add")}
        withBtn={permissions}
      />
      {popUp === "add" && addPopUp}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <MainTable
        data={data}
        useMaxHeight={true}
        totalName="Stakeholders"
        totalCount={data.length}
        dataTypes={[
          {
            title: "Assignee",
            value: ({stakeholder}) => {
              return (
                <div className={styles.avatarWrap}>
                  <Avatar name={stakeholder.name}/> {stakeholder.name}
                </div>
              );
            },
            onClick: (id, stakeholder) => history.push(`/users/${stakeholder.stakeholder.id}`)
          },
          {
            title: "Role",
            value: ({role}) => role,
          },
        ]}
        options={item => {
          return permissions && [
            {
              title: "Edit",
              onClick: () => {
                setCurrentStakeholder(item);
                setPopUp("edit");
              }
            },
            {
              title: "Delete",
              onClick: () => {
                setCurrentStakeholder(item);
                setPopUp("delete");
              }
            }
          ];
        }}
        button={permissions && {
          name: "Add stakeholder",
          onClick: () => setPopUp("add")
        }}
      />
      {popUp === "add" && addPopUp}
      {(popUp === "edit" && currentStakeholder) && (
        <Popup
          title="Edit stakeholder"
          onClose={() => setPopUp(null)}
        >
          <StakeholderForm
            submit={editHandler}
            defaultValues={currentStakeholder}
            usedStakeholderIds={getUsedStakeholderIds()}
            close={() => {
              setPopUp(null);
              setCurrentStakeholder(null);
            }}/>
        </Popup>
      )}
      {(popUp === "delete" && currentStakeholder) && (
        <Popup
          title="Delete stakeholder"
          text="Are you sure you want to delete this stakeholder? All subtasks will be reassigned to the project owner."
          onClose={() => setPopUp(null)}
          onClick={deleteHandler}
          isDelete
          dialog
        />
      )}
    </React.Fragment>
  );
};

export default TaskStakeholdersTable;

TaskStakeholdersTable.propTypes = {
  data: PropTypes.any,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
};