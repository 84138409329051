import React, { useEffect } from "react";
import s from "./SuccessPopup.module.scss";
import Popup from "components/popups/Popup/Popup.jsx";
import { ReactComponent as SuccessCheck } from "assets/icons/success-check.svg";

const SuccessPopup = ({ onClose, message }) => {
  useEffect(() => {
    const timerId = setTimeout(onClose, 3000);
    return () => clearTimeout(timerId);
  }, [onClose]);
  return (
    <Popup className={s.successPopup} onClose={onClose}>
      <div className={s.wrap}>
        <SuccessCheck />
        <span className={s.message}>{message}</span>
      </div>
    </Popup>
  );
};

export default SuccessPopup;
