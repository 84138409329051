import React from "react"
import classNames from "classnames"
import s from "./Note.module.scss"
import Files from "components/forms/AddMilestoneTaskForm/components/Attachments/components/Files/Files"
import { fromHistoryDate } from "helpers/dateFormat"
const Note = ({ className, data }) => {
  return (
    <li className={classNames(s.note, className)}>
      <header className={s.header}>
        <span className={s.name}>{data?.user?.name}</span>
        {data?.updated_at && (
          <span className={s.date}>
            {fromHistoryDate(data.updated_at)
              .split(", ")
              .reverse()
              .join(", ")}
          </span>
        )}
      </header>
      {data?.note && <p className={s.text}>{data.note}</p>}
      {data?.attachments && <Files withLink={true} files={data.attachments} />}
    </li>
  )
}
export default Note
