import AdditionalQuestionsTab from "./AdditionalQuestionsTab/AdditionalQuestionsTab";
import CategoryTab from "./CategoryTab/CategoryTab";
import UsersTab from "./UsersTab/UsersTab";
import RequestStagesTab from "./RequestStagesTab/RequestStagesTab";
import Tabs from "../../../../elements/Tabs/Tabs";
import React, {useEffect, useState} from "react";
import {userRoles, userTypes} from "../../../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import services from "../../../../../services";
import {generateUniqueId} from "../../../../../helpers/uniqueId";

export const RequestTabs = ({data}) => {
  const {admin} = userRoles;
  const {procurement, business} = userTypes;
  const user = useSelector(state => state.user);
  const [stages, setStages] = useState([]);
  const categoryFieldsType = ["Type of Spend", "Category"];
  const categoryFields = data.fields.filter(field => categoryFieldsType.includes(field.step_field.field_type));

  useEffect(() => {
    services.projectServices
      .getStages()
      .then(res => setStages([
        {
          name: "Created",
          id: generateUniqueId()
        },
        {
          name: "Assigned to procurement",
          id: generateUniqueId()
        },
        ...res.data.stages
      ]));
  }, []);

  const getCurrentStage = () => {
    const foundedStage = stages.find(stage => stage.id === data.stage?.id);
    if (!data.stage) {
      if (data.status === "pending") return stages[0];
      if (data.status === "in_progress" && !foundedStage) return stages[1];
    }
    return stages.find(stage => stage.id === data.stage);
  };

  const additionalQuestions = (
    <div label='Additional questions'>
      <AdditionalQuestionsTab questions={data.answers}/>
    </div>
  );
  let category = (
    <div label='Category'>
      <CategoryTab id={data.id}/>
    </div>
  );
  const users = (
    <div label='Users'>
      <UsersTab id={data.id}/>
    </div>
  );
  const requestStages = (
    <div label='Request stages'>
      <RequestStagesTab
        currentStage={getCurrentStage()}
        stages={stages}
        isCompleted={data.status === "completed"}
        status={data.status}
        stageDates={data.stage_dates}
        assigned={data.assigned}
        created={data.created}
      />
    </div>
  );

  if (user.roles.includes(admin)) {
    if (!categoryFields.length) return (
      <Tabs>
        {requestStages}
        {additionalQuestions}
        {users}
      </Tabs>
    );
    return (
      <Tabs>
        {requestStages}
        {additionalQuestions}
        {category}
        {users}
      </Tabs>
    );
  }
  if (user.user_type === procurement) {
    if (!categoryFields.length) return (
      <Tabs>
        {requestStages}
        {additionalQuestions}
      </Tabs>
    );
    return (
      <Tabs>
        {requestStages}
        {additionalQuestions}
        {category}
      </Tabs>
    );
  }
  if (user.user_type === business) {
    return (
      <Tabs>
        {requestStages}
        {additionalQuestions}
      </Tabs>
    );
  }
};

RequestTabs.propTypes = {
  data: PropTypes.any
};