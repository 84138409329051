import React from "react";
import s from "./ProjectsBoard.module.scss";
import BoardTrello from "react-trello";
import CustomCard from "./CustomCard/CustomCard";
import CustomHeader from "./CustomHeader/CustomHeader";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import TopScrollbar from "components/elements/TopScrollbar/TopScrollbar";

const Board = ({onDrop, data}) => {
  const history = useHistory();

  return (
    <TopScrollbar>
      <BoardTrello
        className={s.boardWrap}
        laneStyle={{
          background: "white",
          borderRadius: 2,
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 9px 0px",
          padding: "12px",
          paddingBottom: "0"
        }}
        onBeforeCardDelete={() => {
        }}
        onCardClick={cardId => {
          history.push({
            pathname: `/projects/${cardId}`,
            state: {
              history: history.location.pathname + history.location.search
            }
          });
        }}
        actions={{
          removeCard: id => {
          }
        }}
        data={{
          lanes: data
        }}
        style={{
          background: "transparent",
          padding: "0",
          margin: "5px",
          height: "auto"
        }}
        components={{Card: CustomCard, LaneHeader: CustomHeader}}
        handleDragEnd={(
          cardId,
          sourceLaneId,
          targetLaneId,
        ) => {
          onDrop(cardId, targetLaneId);
        }}
        hideCardDeleteIcon={true}
        draggable
      />
    </TopScrollbar>
  );
};

Board.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  data: PropTypes.any,
};

export default Board;
