import React, {useState, useEffect} from "react";
import classNames from "classnames";
import {useParams} from "react-router-dom";
import styles from "./UserReportContainer.module.scss";
import Detail from "components/elements/Detail/Detail";
import DetailLoading from "components/loadingStates/Detail/Detail";
import services from "services/index";
import {formatDetailData} from "./helpers";
import UserReportGeneral from "./components/UserReportGeneral/UserReportGeneral";
import UserReportProjects from "./components/UserReportProjects/UserReportProjects";
import SavingsChart from "components/elements/SavingsChart/SavingsChart";
import PieChartWidgetLoading from "components/loadingStates/PieChartWidget/PieChartWidget";
import PieChartWiget from "components/elements/PieChartWidget/PieChartWidget";
import FilterBarDropdown from "components/elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {years as allYears} from "./helpers/years";
import CompanyGoalContainer from "components/containers/CompanyGoalContainer/CompanyGoalContainer";
import ReportTimeSpendWidget from "../../elements/ReportTimeSpendWidget/ReportTimeSpendWidget";

const UserReportContainer = () => {
  const params = useParams();
  const [user, setUser] = useState({});
  const [years, setYears] = useState(allYears);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [selectedYears, setSelectedYears] = useState(["2021"]);
  useEffect(() => {
    params.id &&
    services.userServices.getUser(params.id).then(res => {
      setUser(res.data);
      setLoading(false);
    });
    services.reportsServices
      .getYears()
      .then(
        res =>
          res?.data &&
          setYears(
            res?.data?.years?.map(e => ({id: String(e), name: String(e)}))
          )
      );
  }, [params.id]);

  useEffect(() => {
    params.id &&
    services.reportsServices
      .userReportActivity(params.id, {
        years: selectedYears?.join(",") || ""
      })
      .then(res => {
        const {
          one_time_ca,
          one_time_er,
          run_rate_ca,
          run_rate_er,
          total,
          ...spread
        } = res?.data || {};
        if (res?.data?.period === "quarters") {
          setChartData(res?.data || null);
        } else if (res?.data?.period === "years") {
          setChartData({
            savings: {
              one_time_ca,
              one_time_er,
              run_rate_ca,
              run_rate_er,
              total
            },
            ...spread
          });
        } else {
          setChartData(res?.data);
        }
      });
  }, [selectedYears]);

  const currentYear = String(new Date().getFullYear());
  const ALLOWANCE_MONTHS_COUNT = chartData?.allowance_months_count || 0,
    ALLOWANCE_RULE = chartData?.allowance_rule || "";

  const getPieChartWidgetTitle = () => {
    if (
      (ALLOWANCE_RULE === "full" && selectedYears) ||
      (!ALLOWANCE_RULE && selectedYears)
    ) {
      return `Total user impact of ${selectedYears?.join(", ") ||
      "all"} Activity`;
    } else if (ALLOWANCE_RULE === "months" && selectedYears) {
      return `${ALLOWANCE_MONTHS_COUNT} months user impact of ${selectedYears?.join(
        ", "
      ) || "all"} Activity`;
    } else if (ALLOWANCE_RULE === "months" && !selectedYears) {
      return `${ALLOWANCE_MONTHS_COUNT} months user impact of all activities`;
    } else if (ALLOWANCE_RULE === "full" && !selectedYears) {
      return "Total user impact of all activities";
    } else {
      return `${currentYear} user impact of ${currentYear} Activity`;
    }
  };
  const getSavingsWidgetTitle = () => {
    if (ALLOWANCE_RULE === "full") {
      return `Total user impact of ${selectedYears?.join(", ") ||
      "all"} activities`;
    } else if (ALLOWANCE_RULE === "months") {
      return `${ALLOWANCE_MONTHS_COUNT} months user impact of ${selectedYears?.join(
        ", "
      ) || "all"} activities`;
    } else {
      return "Total user impact of all activities";
    }
  };

  const getChartValues = () => {
    const preparedValues = {};
    for (let key in chartData) {
      if (key !== "allowance_months_count" && key !== "allowance_rule" && key !== "default_currency" && key !== "period") {
        preparedValues[key] = chartData[key];
      }
    }
    return preparedValues;
  };

  return (
    <section className={styles.container}>
      {loading ? (
        <DetailLoading length={6}/>
      ) : (
        <>
          <h3 className={styles.title}>{user.name}</h3>
          <Detail data={formatDetailData(user)} className={styles.detail}/>
        </>
      )}
      <div className={styles.content}>
        <div className={styles.row}>
          <h3 className={styles.name}>General</h3>
          <UserReportGeneral/>
        </div>
        <div className={styles.row}>
          <h3 className={styles.name}>Time spent</h3>
          <ReportTimeSpendWidget type="user"/>
        </div>
        <div className={styles.row}>
          <CompanyGoalContainer isRequest={true} source='user' withFilters={false} withEdit={false}/>
        </div>
        <div className={styles.row}>
          <h3 className={styles.name}>Project information</h3>
          {!chartData && (
            <PieChartWidgetLoading
              className={styles.pieChartLoader}
              width={144}
            />
          )}
          {(chartData && chartData.period === "years") && (
            <div className={classNames(styles.savingsChartWrap, styles.row)}>
              <PieChartWiget
                data={getChartValues()}
                title={getPieChartWidgetTitle()}
              />
              <div className={styles.years}>
                <FilterBarDropdown
                  name='Years'
                  options={years}
                  defaultValues={selectedYears}
                  handleChange={values => {
                    setSelectedYears(values.map(e => e.name));
                  }}
                  setFilterValue={() => {
                  }}
                />
              </div>
            </div>
          )}
          {chartData && !chartData.period && (
            <div className={classNames(styles.savingsChartWrap, styles.row)}>
              <SavingsChart
                title={getSavingsWidgetTitle()}
                data={chartData}
              />
              <div className={styles.years}>
                <FilterBarDropdown
                  name='Years'
                  options={years}
                  defaultValues={selectedYears}
                  handleChange={values => {
                    setSelectedYears(values.map(e => e.name));
                  }}
                  setFilterValue={() => {
                  }}
                />
              </div>
            </div>
          )}
          <UserReportProjects/>
        </div>
      </div>
    </section>
  );
};

export default UserReportContainer;
