import React, {useEffect, useReducer, useState} from "react";
import s from "./ContractsContainer.module.scss";
import querystring from "query-string";
import services from "services";
import ContractsFilter from "components/filters/ContractsFilter/ContractsFilter";
import {
  formatFilters,
} from "components/elements/FilterBar/helpers/helpers";
import {useHistory, useLocation} from "react-router-dom";
import {initialState, reducer} from "./helpers/reducer";
import {
  setFilters,
  setCurrentPage,
  setRequestData,
  setAlert,
} from "./helpers/actions";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar";
import TableLoading from "components/loadingStates/Table/Table";
import {ContractsTableTree} from "../../tables/ContractsTableTree/ContractsTableTree";
import Popup from "../../popups/Popup/Popup";
import {useSelector} from "react-redux";
import {userTypes} from "../../../helpers/userPermissions/userRoles";
import {useTranslation} from "react-i18next";

const ContractsContainer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const {user_type} = useSelector(state => state.user);
  const {business} = userTypes;
 const [deleteContractId, setDeleteContractId] = useState(null);

  const getFilters = async () => {
    const result = await services.contractsServices.getContractsFilters();
    const formattedFilters = formatFilters(result.data);
    const formattedExpiresIn = result.data.expires_in;

    dispatch(
      setFilters({
        suppliers: formattedFilters.suppliers,
        expires_in: formattedExpiresIn,
        categories: formattedFilters.categories,
      })
    );
  };

  const getContracts = () => {
    const {page, sort_by, direction, tag, ...query} = querystring.parse(
      location.search
    );

    const filters = Object.assign({}, {expires_in: "Any"}, {page, sort_by, direction, ...query, tag});

    services.contractsServices.getContracts(filters).then((res) => {
      dispatch(
        setRequestData({
          ...res.data,
          loading: false,
          contractsTotalCount: res.data.contracts_count,
        })
      );
    });
  };

  useEffect(() => {
    const {page} = querystring.parse(location.search);
    dispatch(setCurrentPage(Number(page)));
    if (location.state) {
      const {success, contractName} = location.state;
      if (success)
        dispatch(
          setAlert({
            message: `"${contractName}" project successfully deleted.`,
          })
        );
      else
        dispatch(
          setAlert({
            message: "Something went wrong. Please, try again.",
            type: "error",
          })
        );
    }
  }, [location]);

  useEffect(() => {
    getContracts();
  }, [location.search]);

  useEffect(() => {
    getFilters();
  }, []);

  const handlePageChange = (page) => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  const deleteAction = () => {
    services.contractsServices.deleteContract(deleteContractId).then(() => {
      getContracts();
      setDeleteContractId(null);
    });
  };

  return (
    <section className={s.container}>
      <div>
        <h3 className={s.title} data-translate-key="contracts">{t("contracts")}</h3>
      </div>
      <div className={s.blockOfViews}>
        {!Array.isArray(state.filters) && Object.keys(state.filters).length ? (
          <ContractsFilter
            filters={state.filters}
            className={s.filterBar}
          />
        ) : (
          <FilterBarLoading length={2} className={s.filterBar}/>
        )}
        {state.loading ? (
          <TableLoading length={6}/>
        ) : state.contracts.length ? (
          <ContractsTableTree
            state={state}
            perPage={30}
            totalCount={state.totalContractsCount}
            onPageChange={handlePageChange}
            currentPage={state.currentPage}
            onDelete={id => setDeleteContractId(id)}
          />
        ) : (
          (user_type !== business)
            ? <EmptyBlock
              subtitle="There are no contracts according to your search"
            buttonTitle='New contract'
            onClick={() => history.push("/contract/new")}
            withBtn={true}
          />
            : <EmptyBlock
              title="No work item results found"
              withBtn={false}
            />
        )}
      </div>
      {
        deleteContractId && (
          <Popup
            onClose={() => setDeleteContractId(null)}
            title="Delete contract"
            text="Are you sure you want to delete this contract?"
            onClick={() => deleteAction()}
            dialog
            isDelete
          />
        )
      }

    </section>
  );
};

export default ContractsContainer;
