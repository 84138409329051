export const getDisabledFields = (taskType, isHighRule) => {
  if (taskType === "additional") {
    return isHighRule ? [] : [
      "name",
      "description",
      "help",
      "attachments",
      "responsible_user",
      "dates"
    ];
  }
  return [
    "name",
    "description",
    "help",
    "attachments",
    !isHighRule && "responsible_user",
    !isHighRule && "dates",
  ];
};