import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import NPSScoreWidget from "../NPSScoreWidget/NPSScoreWidget";
import services from "services/index";

const UserReportGeneral = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    params.id &&
      services.reportServices.getUserReportGeneral(params.id).then((res) => {
        setData(res.data);
        setLoading(false);
      });
  }, [params.id]);

  return (
    <div>
      {loading ? (
        <CircularProgress className="loader" />
      ) : (
        <NPSScoreWidget data={data} />
      )}
    </div>
  );
};

export default UserReportGeneral;
