import React from 'react';
import styles from "./TeamReportTimeSpent.module.scss";
import ReportTimeSpendWidget from "../../../../elements/ReportTimeSpendWidget/ReportTimeSpendWidget";

const TeamReportTimeSpent = () => {
  return (
    <div className={styles.detailBlock}>
      <h3 className={styles.name}>Time spent</h3>
      <ReportTimeSpendWidget type="team"/>
    </div>
  );
};

export default TeamReportTimeSpent;