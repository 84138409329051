import React from "react";
import MainTable from "../MainTable/MainTable";

const UserDepartmentsTable = (
  {
    data,
    onAdd,
    onEdit,
    onDelete,
    perPage,
    totalCount,
    currentPage,
    onPageChange
  }
) => {
  const findParent = (departments, activeDepartment) => {
    let parent = null;
    departments.some(dep => {
      if (dep.departments.some(d => d === activeDepartment)) {
        parent = dep;
        return true;
      }
      const r = findParent(dep.departments, activeDepartment);
      if (r) {
        parent = r;
        return true;
      }
    });
    return parent;
  };

  return (
    <MainTable
      withXScroll={true}
      stickyHeader={true}
      data={data}
      subKey="departments"
      subTypes={[
        {
          title: "Department",
          value: ({name}) => name,
          sticky: true
        },
        {
          title: "Leader",
          value: ({leader}) => leader?.name || "-",
          alignment: "right"
        },
        {
          title: "Updated",
          value: ({update_at}) => update_at || "-",
        },
      ]}
      dataTypes={[
        {
          title: "Department",
          value: ({name}) => name,
          sticky: true
        },
        {
          title: "Leader",
          value: ({leader}) => leader?.name || "-",
          alignment: "right",
        },
        {
          title: "Updated",
          value: ({updated_at}) => updated_at || "-",
        },
      ]}
      options={item => {
        return [
          {
            title: "Edit",
            onClick: () => {
              onEdit(item, findParent(data, item));
            }
          },
          {
            title: "Delete",
            onClick: () => {
              onDelete(item);
            }
          },
        ];
      }}
      button={
        {
          name: "New department",
          onClick: () => onAdd()
        }
      }
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};

export default UserDepartmentsTable;