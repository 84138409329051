import React from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll";
import s from '../PrivacyPage.module.scss'

const Point08 = () => {
	return (
		<div id="point08" className={s.pointBlock}>
              <h3 className={s.subtitle}>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>

              <p className={s.text} style={{ fontStyle: "italic" }}>
                <span>In Short: </span> In some regions, such as the European
                Economic Area, you have rights that allow you greater access to
                and control over your personal information. You may review,
                change, or terminate your account at any time.
              </p>

              <p className={s.text}>
                In some regions (like the European Economic Area), you have
                certain rights under applicable data protection laws. These may
                include the right (i) to request access and obtain a copy of
                your personal information, (ii) to request rectification or
                erasure; (iii) to restrict the processing of your personal
                information; and (iv) if applicable, to data portability. In
                certain circumstances, you may also have the right to object to
                the processing of your personal information. To make such a
                request, please use the{" "}
                <AnchorLink href="#point12" className={s.link}>
                  contact details
                </AnchorLink>{" "}
                provided below. We will consider and act upon any request in
                accordance with applicable data protection laws.
              </p>

              <p className={s.text}>
                If we are relying on your consent to process your personal
                information, you have the right to withdraw your consent at any
                time. Please note however that this will not affect the
                lawfulness of the processing before its withdrawal, nor will it
                affect the processing of your personal information conducted in
                reliance on lawful processing grounds other than consent.
              </p>

              <p className={s.text}>
                If you are a resident in the European Economic Area and you
                believe we are unlawfully processing your personal information,
                you also have the right to complain to your local data
                protection supervisory authority. You can find their contact
                details here:{" "}
                <a
                  href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.link}
                >
                  http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                </a>{" "}
                .
              </p>

              <p className={s.text}>
                If you are a resident in Switzerland, the contact details for
                the data protection authorities are available here:{" "}
                <a
                  href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.link}
                >
                  https://www.edoeb.admin.ch/edoeb/en/home.html
                </a>{" "}
                .
              </p>

              <p className={s.text}>
                <span>Cookies and similar technologies: </span>
                Most Web browsers are set to accept cookies by default. If you
                prefer, you can usually choose to set your browser to remove
                cookies and to reject cookies. If you choose to remove cookies
                or reject cookies, this could affect certain features or
                services of our Website. To opt-out of interest-based
                advertising by advertisers on our Website visit{" "}
                <a
                  href="http://www.aboutads.info/choices/."
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.link}
                >
                  http://www.aboutads.info/choices/
                </a>
                .
              </p>
            </div>
	)
}

export default Point08
