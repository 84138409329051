import React, {useEffect, useState} from "react";
import MainTable from "../MainTable/MainTable";
import {useHistory} from "react-router-dom";
import {formatCurrency} from "../../../helpers/currencyFormat";
import {fromDate} from "../../../helpers/dateFormat";
import Tags from "../../elements/Tags/Tags";
import {recursiveJoin} from "../../../helpers/recursiveJoin";
import services from "../../../services";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {userRoles, userTypes} from "../../../helpers/userPermissions/userRoles";
import LogoSuppliers from "../../elements/LogoSuppliers/LogoSuppliers";
import {ReactComponent as GreenProjectIcon} from "../../../assets/icons/green-project.svg";
import {ReactComponent as ExpiredSoonIcon} from "../../../assets/icons/expired-soon.svg";
import {ReactComponent as RequestedIcon} from "../../../assets/icons/requested.svg";
import s from "../SuppliersTable/SuppliersTable.module.scss";
import {paths} from "../../../routes/paths";
import CustomNotification from "../../elements/QuestionNotification/CustomNotification";
import querystring from "query-string";

export const ContractsTableTree = (
  {
    state,
    perPage,
    totalCount,
    onPageChange,
    currentPage,
    onDelete
  }
) => {
  const history = useHistory();
  const query = querystring.parse(history.location.search);
  const user = useSelector(state => state.user);
  const {business, procurement, adminTypes} = userTypes;
  const [contractRequests, setContractRequests] = useState([]);

  useEffect(() => {
    services.requestTypeServices.getRequestTypes().then(res => {
      if (res.data) setContractRequests(res.data.filter(r => r.relation === "contract"));
    });
  }, []);

  const onDeleteTag = async (tagId, contractId) =>
    await services.tagsServices.deleteContractTag(contractId, tagId);

  const onAssignTag = async (tagId, contractId) =>
    await services.tagsServices.assignContractTag(contractId, tagId);

  const deleteTagAction = (id, tag, item) => {
    if (tag.type === "flag") {
      if (tag.owner.id === user.id || user.roles.includes(userRoles.admin)) {
        return onDeleteTag(id, item.id);
      }
    } else return onDeleteTag(id, item.id);
  };

  const renderIconWithTitle = (Icon, title) => {
    return (
      <CustomNotification
        value={title}
        Icon={Icon}
        withTranslate
      />
    );
  };

  const renderContractName = (name, {is_expired_soon, is_have_in_project, is_requested}) => {
    let iconList = [];

    const greenProjectIcon = renderIconWithTitle(GreenProjectIcon, "Currently in the project");
    const expiredSoonIcon = renderIconWithTitle(ExpiredSoonIcon, "Contract will be expired soon");
    const requestedIcon = renderIconWithTitle(RequestedIcon, "Requested");

    if (is_have_in_project) {
      iconList = [greenProjectIcon];
      if (is_expired_soon) iconList = [expiredSoonIcon, greenProjectIcon];
      if (is_requested && is_expired_soon) iconList = [requestedIcon];
    }

    return (
      <span className={s.contractName}>
        {iconList}
        {name}
      </span>
    );
  };

  const createRequest = (request, supplier) => {
    history.push(paths.createRequest, {request, entity: supplier});
  };
  const onSort = (type) => {
    const query = querystring.parse(history.location.search);
    history.push({
      search: querystring.stringify({...query, page: 1, ...type}),
    });
  };

  return (
    <MainTable
      stickyHeader={true}
      inlineWrapClassName={s.inlineWrap}
      data={state.contracts}
      withXScroll={true}
      dragIdLabel={"contract"}
      subKey="contracts"
      ariaLabel="Contracts"
      subTypes={[
        {
          title: "Supplier",
          value: ({supplier}) => (
            <div className={s.logoSubSupplier}>
              <LogoSuppliers name={supplier.name} logo={supplier.logo}/>
              <span>{supplier.name}</span>
            </div>),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "supplier", direction});
          },
        },
        {
          title: "Contract",
          value: ({name, statuses}) => renderContractName(name, statuses),
          onClick: id => history.push(`/contract/details/${id}`),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
        },
        {
          title: "End date",
          value: ({end_date}) => (end_date ? fromDate(end_date) : "-"),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "end_date", direction});
          },
        },
        {
          title: "Currency",
          value: ({annual_value_currency}) => annual_value_currency,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "annual_value_currency", direction});
          },
        },
        {
          title: "ACV",
          value: ({annual_value_currency, annual_value}) => {
            return annual_value && annual_value_currency
              ? formatCurrency(annual_value_currency, annual_value)
              : "-";
          },
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "annual_value", direction});
          },
        },
        {
          title: "TCV",
          value: ({total_value_currency, total_value}) => {
            return total_value && total_value_currency
              ? formatCurrency(total_value_currency, total_value)
              : "-";
          },
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "total_value", direction});
          },
        },
        {
          title: "Tags",
          // eslint-disable-next-line react/display-name
          value: (item) =>
            item?.tags ? (
              <Tags
                allData={recursiveJoin(state.contracts, "contracts")}
                item={item}
                onDelete={(id, tag) => deleteTagAction(id, tag, item)}
                onAssign={id => onAssignTag(id, item.id)}
              />
            ) : (
              ""
            ),
          type: "tags"
        }
      ]}
      dataTypes={[
        {
          title: "Supplier",
          value: ({supplier}) => (
            <div className={s.logoSubSupplier}>
              <LogoSuppliers name={supplier.name} logo={supplier.logo}/>
              <span>{supplier.name}</span>
            </div>),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "supplier", direction});
          },
        },
        {
          title: "Contract",
          value: ({name, statuses}) => renderContractName(name, statuses),
          onClick: id => history.push(`/contract/details/${id}`),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
        },
        {
          title: "End date",
          value: ({end_date}) => (end_date ? fromDate(end_date) : "-"),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "end_date", direction});
          },
        },
        {
          title: "Currency",
          value: ({annual_value_currency}) => annual_value_currency,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "annual_value_currency", direction});
          },
        },
        {
          title: "ACV",
          value: ({annual_value_currency, annual_value}) => {
            return annual_value && annual_value_currency
              ? formatCurrency(annual_value_currency, annual_value)
              : "-";
          },
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "annual_value", direction});
          },
        },
        {
          title: "TCV",
          value: ({total_value_currency, total_value}) => {
            return total_value && total_value_currency
              ? formatCurrency(total_value_currency, total_value)
              : "-";
          },
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "total_value", direction});
          },
        },
        {
          title: "Tags",
          // eslint-disable-next-line react/display-name
          value: (item, allData) =>
            item?.tags ? (
              <Tags
                allData={allData}
                item={item}
                onDelete={user.user_type !== business && (((id, tag) => deleteTagAction(id, tag, item)))}
                onAssign={user.user_type !== business && (id => onAssignTag(id, item.id))}
                onSort={({name}) =>
                  query.tag !== name ? onSort({tag: name}) : onSort({tag: ""})
                }
              />
            ) : (
              ""
            ),
          type: "tags",
        }
      ]}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
      options={item => {
        return [
          (user.user_type !== business) && {
            title: "Edit",
            onClick: () => history.push(`/contract/edit/${item.id}`)
          },
          {
            title: "Delete",
            onClick: () => {
              onDelete(item.id);
            }
          },
          ...((
            user.user_type === procurement ||
            user.user_type === business ||
            user.user_type === adminTypes
          ) ? contractRequests : []).map(request => ({
            title: request.name,
            onClick: () => createRequest(request.id, item)
          }))
        ];
      }}
      button={
        user.user_type !== business &&
        {
          name: "New contract",
          onClick: () => history.push("/contract/new")
        }
      }
    />
  );
};

ContractsTableTree.propTypes = {
  state: PropTypes.any,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  onDelete: PropTypes.func,
};