import React, {useState} from 'react';
import styles from "./CustomStatusList.module.scss";
import {ReactComponent as Reorder} from "../../../../../../../assets/icons/reorder.svg";
import {backgroundTypes} from "../../../../../../../helpers/helpData";
import {dateFormatterWithoutTimezone} from "../../../../../../../helpers/dateFormat";
import MainTable from "../../../../../../tables/MainTable/MainTable";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import Popup from "../../../../../../popups/Popup/Popup";
import TaskStatusForm from "../../../../../../forms/TaskStatusForm/TaskStatusForm";

const CustomStatusList = (
  {
    options,
    addCustomSelectorOption,
    editCustomSelectorOption,
    deleteCustomSelectorOption
  }
) => {
  const [popUp, setPopUp] = useState(null);
  const [currentCustomStatus, setCurrentCustomStatus] = useState(null);

  const handleDragEnd = result => {
    console.log(result);
  }

  const createHandler = values => {
    addCustomSelectorOption(values);
    setPopUp(null);
  }

  const editHandler = values => {
    editCustomSelectorOption(currentCustomStatus.id, values);
    setPopUp(null);
    setCurrentCustomStatus(null);
  }

  const deleteHandler = () => {
    deleteCustomSelectorOption(currentCustomStatus.id);
    setPopUp(null);
    setCurrentCustomStatus(null);
  }

  return (
    <React.Fragment>
      <DragDropContext
        onDragEnd={handleDragEnd}
      >
        <Droppable droppableId='statuses' type='STATUSES'>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <MainTable
                totalName="Statuses"
                data={options}
                dragIdLabel="status"
                getDragId={item => item.id}
                dragWithSort
                dataTypes={[
                  {
                    title: "Status",
                    value: ({name}) => (
                      <div className={styles.name}>
                        <Reorder/> <span>{name || "-"}</span>
                      </div>
                    ),
                  },
                  {
                    title: "Color",
                    value: ({color}) => <div className={styles.colorStatus}
                                             style={{background: backgroundTypes[color]}}/>,
                  },
                  {
                    title: "Updated",
                    value: ({updated_at}) => dateFormatterWithoutTimezone(updated_at),
                  },
                ]}
                options={item => [
                  {
                    title: "Edit",
                    onClick: () => {
                      setPopUp("edit");
                      setCurrentCustomStatus(item);
                    },
                  },
                  {
                    title: "Delete",
                    onClick: () => {
                      setPopUp("delete");
                      setCurrentCustomStatus(item);
                    },
                  },
                ]}
                bottomBtn={{
                  title: "+ Add new status",
                  onClick: () => setPopUp("create")
                }}
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {
        popUp === "create" && (
          <Popup
            onClose={() => setPopUp(null)}
            title="New custom status"
            text="Please, complete the following fields to add a new custom status"
          >
            <TaskStatusForm
              onClose={() => setPopUp(null)}
              onSubmit={values => createHandler(values)}
            />
          </Popup>
        )
      }
      {
        (popUp === "edit" && currentCustomStatus) && (
          <Popup
            onClose={() => setPopUp(null)}
            title="Edit custom status"
          >
            <TaskStatusForm
              onClose={() => setPopUp(null)}
              onSubmit={values => editHandler(values)}
              initialValues={currentCustomStatus}
            />
          </Popup>
        )
      }
      {popUp === "delete" && (
        <Popup
          title="Delete custom status"
          text="Are you sure you want to delete this selector?"
          onClose={() => setPopUp(null)}
          onClick={() => deleteHandler()}
          isDelete
          dialog
        />
      )}
    </React.Fragment>
  );
};

export default CustomStatusList;