import {useFormik} from "formik";
import {object, string} from "yup";
import services from "../../../../../services";
import Popup from "../../../../popups/Popup/Popup";
import s from "./SupplierServices.module.scss";
import Input from "../../../../elements/Input/Input";
import Textarea from "../../../../elements/Textarea/Textarea";
import Attachments from "../../../../forms/AddMilestoneTaskForm/components/Attachments/Attachments";
import React from "react";
import Button from "../../../../elements/Button/Button";

const EditServicePopup = ({setShowEditService, editServiceItem, editSupplierService}) => {
  const DESCRIPTION_MAX_LENGTH = 150;
  const SERVICE_TITLE_LIMIT = 50;
  const formik = useFormik({
    initialValues: {
      serviceId: editServiceItem.id,
      name: editServiceItem.name,
      description: editServiceItem.description,
      attachments: editServiceItem?.attachments || [],
    },
    validationSchema: object({
      name: string()
        .required("Please, suppliers service name is required")
        .max(
          SERVICE_TITLE_LIMIT,
          `The suppliers service name is too long. It should be ${SERVICE_TITLE_LIMIT} characters or less.`
        ),
      description: string()
        .required("Please, suppliers service description is required")
        .max(
          DESCRIPTION_MAX_LENGTH,
          `The suppliers service description is too long. It should be ${DESCRIPTION_MAX_LENGTH} characters or less.`
        ),
    }),
    onSubmit: values => {
      editSupplierService({
        name: values.name,
        description: values.description,
        attachments: values.attachments.filter(file => file.lastModifiedDate),
        serviceId: values.serviceId,
      });
    }
  });
  const {handleSubmit, values, errors, setFieldValue, touched, handleChange} = formik;

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };
  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      );
    } else {
      services.taskServices.deleteAttachment(file.uuid).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        );
      });
    }
  };
  return (
    <>
      <Popup
        title="Edit service"
        onClose={() => setShowEditService(false)}
        text="Please, complete the following fields to create a edit service"
        className={s.popUpAddService}
      >
        <form onSubmit={handleSubmit}>
          <Input
            value={values.name}
            onChange={handleChange}
            className={s.inputService}
            name="name"
            type="name"
            placeholder={`Enter service name`}
            label={`Service`}
            error={errors.name && touched.name && errors.name}
            limitValue={SERVICE_TITLE_LIMIT}
            withCounter
          />
          <Textarea
            label="Description"
            value={values.description}
            error={errors.description && touched.description && errors.description}
            onChange={handleChange}
            name="description"
            placeholder="Enter a description name"
            count={{
              current: values.description.length,
              max: DESCRIPTION_MAX_LENGTH
            }}
          />
          <div className={s.inputWrap}>
            <Attachments
              onChange={handleChangeAttachment}
              onDelete={handleDeleteAttachment}
              attachments={values.attachments}
              filesMaxLength={3}
            />
          </div>
          <div className="popupBtnsWrap">
            <Button
              styled="secondary"
              type="reset"
              className="popupBtn"
              onClick={() => setShowEditService(false)}
            >
              Cancel
            </Button>
            <Button styled="primary" type="submit" className="popupBtn">
              Save
            </Button>
          </div>
        </form>
      </Popup>
    </>
  );
};

export default EditServicePopup;