import React from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import s from "./ActualSavingsFilter.module.scss";
import FilterBarDropdownDepartment
  from "../../containers/DashboardContainer/components/MainDashboard/Filter/FilterBarDropdownDepartment";
import qs from "query-string";
import {useHistory} from "react-router-dom";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";

const ActualSavingsFilter = ({actualSavingsGroup}) => {
  const history = useHistory();
  const query = qs.parse(history.location.search);

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = values[0] ? getAllIds(values) : getAllIds([actualSavingsGroup[0].id]);
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  return (
    <FilterBar
      filterName="Filter savings"
      className={s.filter}
      handleClearAll={() => {
        handleChangeFilter("savings_group_ids", [actualSavingsGroup[0].id]);
      }}
    >
      <FilterBarDropdownDepartment
        name="Savings group"
        options={actualSavingsGroup}
        handleChange={values => {
          handleChangeFilter("savings_group_ids", [values.id]);
        }}
        defaultFilterValue={actualSavingsGroup[0]}
        useAlwaysDefault
      />
    </FilterBar>
  );
};


export default ActualSavingsFilter;