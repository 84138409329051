import React from "react";
import Popup from "components/popups/Popup/Popup";
import AdminSettingsUsersDivisionForm
    from "../../../../../forms/AdminSettingsUsersDivisionForm/AdminSettingsUsersDivisionForm";

const EditDivision = ({ onClose, onSubmit, defaultData }) => {
  return (
    <Popup onClose={onClose} title="Edit division">
      <AdminSettingsUsersDivisionForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultData={defaultData}
      />
    </Popup>
  );
};

export default EditDivision;
