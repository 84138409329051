import React from "react";
import s from "./ProjectActualSavingsMonthNotesForm.module.scss";
import PropTypes from "prop-types";
import {fromHistoryDateTwo} from "../../../helpers/dateFormat";
import Files from "../AddMilestoneTaskForm/components/Attachments/components/Files/Files";

const NotesHistoryItem = ({item}) => {

  return (
    <div>
      <div className={s.notesOwner}>
        <div>{item.owner.name + " " + fromHistoryDateTwo(item.created_at)}</div>
      </div>
      <div className={s.actualSavingDiscription}>
        <p className={s.actualSavingDiscription}>{item.description}</p>
      </div>
      <div className={s.actualSavingAttachment}>
        {item.attachments.length ? <Files className={s.attach} files={item.attachments} withLink={true}/> : ''}
      </div>
      <hr/>
    </div>
  );
};

export default NotesHistoryItem;

NotesHistoryItem.propTypes = {
  item: PropTypes.object,
};