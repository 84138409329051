import React, {useEffect, useState, Fragment} from "react";
import s from "./NonFinancialBenefits.module.scss";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import RenderBenefits from "./RenderBenefits";
import services from "services";
import {useParams} from "react-router-dom";
import Popup from "components/popups/Popup/Popup";
import ProjectNonFinancialBenefitsForm
  from "components/forms/ProjectNonFinancialBenefitsForm/ProjectNonFinancialBenefitsForm";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import TableLoading from "components/loadingStates/Table/Table";
import {prepareNonFinancialForRender} from "./helpers/prepareNonFinancialForRender";
import {getNonFinancialLength} from "./helpers/getNonFinancialLength";

const NonFinancialBenefits = ({noIteractions = false, benefits}) => {
  const [nonSavingsTypes, setNonSavingsTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visiblePopups, setVisiblePopups] = useState({
    add: false,
    edit: false,
  });
  const params = useParams();
  const [alert, setAlert] = useState({message: "", type: ""});
  const [permissions, setPermissions] = useState({
    update: false,
    destroy: false,
  });

  const load = () => {
    if (benefits) {
      setNonSavingsTypes(benefits);
      setPermissions({
        update: false,
        destroy: false,
      });
      setLoading(false);
    } else {
      services.nonFinancialBenefitsServices.get(params.id).then((res) => {
        setNonSavingsTypes(prepareNonFinancialForRender(res.data?.non_saving_groups || []));
        setPermissions({
          update: res.data.permissions?.update || false,
          destroy: res.data.permissions?.destroy || false,
        });
        setLoading(false);
      });
    }
  }

  useEffect(() => load(), [params.id, benefits]);

  const handleChangeNonFinancialBenefits = (values, isDelete) => {
    services.nonFinancialBenefitsServices
      .edit(params.id, values)
      .then(() => {
        load();
        setAlert({
          message: `Non-financial benefit successfully ${
            isDelete ? "deleted" : "edited"
          }.`,
        });
        setVisiblePopups({add: false, edit: false});
      })
      .catch(() =>
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error",
        })
      );
  };

  const deleteNonFinancialBenefit = typeId => {
    return {
      non_savings_types_ids: nonSavingsTypes.map(group => group.non_savings_types)
        .flat(1)
        .map(type => type.id)
        .filter(id => id !== typeId)
        .join()
    };
  };

  return (
    <Fragment>
      {loading && <TableLoading length={3}/>}
      {!loading && (
        <div className={s.wrap}>
          {getNonFinancialLength(nonSavingsTypes) ? (
            <RenderBenefits
              data={nonSavingsTypes}
              noIteractions={noIteractions}
              onEdit={() => {
                permissions.update &&
                setVisiblePopups({add: false, edit: true});
              }}
              onDelete={(title, e) => {
                permissions.update &&
                handleChangeNonFinancialBenefits(
                  deleteNonFinancialBenefit(title, e),
                  true
                );
              }}
              havePermissions={permissions}
            />
          ) : (
            <EmptyBlock
              title="Non-financial benefits"
              subtitle={
                !noIteractions && permissions.update
                  ? "You don’t have non-financial benefits yet. You can add a new one"
                  : "You don’t have non-financial benefits yet."
              }
              buttonTitle="Add benefit"
              onClick={() => setVisiblePopups({add: true, edit: false})}
              withBtn={!noIteractions && permissions.update}
            />
          )}
          {visiblePopups.add && (
            <Popup
              title="Add non-financial benefit"
              text="Choose one or more non-financial benefits"
              onClose={() => setVisiblePopups({add: false, edit: false})}
            >
              <ProjectNonFinancialBenefitsForm
                onClose={() => setVisiblePopups({add: false, edit: false})}
                onSubmit={handleChangeNonFinancialBenefits}
              />
            </Popup>
          )}
          {visiblePopups.edit && (
            <Popup
              title="Edit non-financial benefit"
              text="Choose one or more non-financial benefits"
              onClose={() => setVisiblePopups({add: false, edit: false})}
            >
              <ProjectNonFinancialBenefitsForm
                onClose={() => setVisiblePopups({add: false, edit: false})}
                onSubmit={handleChangeNonFinancialBenefits}
                initialData={nonSavingsTypes}
              />
            </Popup>
          )}
          {alert.message && (
            <NotificationBox
              message={alert.message}
              type={alert.type}
              onClose={() => setAlert({message: "", type: ""})}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};
export default NonFinancialBenefits;
