import React, { useState, useRef } from "react"
import classNames from "classnames"
import s from "./ProjectNotesTable.module.scss"
import MainTable from "../MainTable/MainTable"
import Popup from "components/popups/Popup/Popup"
import EditProjectNoteForm from "components/forms/EditProjectNoteForm/EditProjectNoteForm"
import { fromDate } from "helpers/dateFormat"
import { getFileIconByType } from "helpers/fileIconsByType"
import useOutsideClicker from "hooks/useOutsideClicker"
import { ReactComponent as AttachmentIcon } from "assets/icons/attachment.svg"

const ProjectNotesTable = ({
  notes,
  removeHandler,
  editHandler,
  openPopup
}) => {
  const [show, setShow] = useState({ delete: false, edit: false })
  const [note, setNote] = useState({})
  const [visibleAttachmentsPopupId, setVisibleAttachmentsPopupId] = useState(
    null
  )
  const attachmentsRef = useRef(null)
  const menuRef = useRef(null)
  useOutsideClicker(attachmentsRef, () => setVisibleAttachmentsPopupId(null))
  return (
    <div ref={menuRef}>
      <MainTable
        className={classNames(s.table, { [s.overflow]: notes.length > 3 })}
        rowClassName={s.tableRow}
        data={notes}
        dataTypes={[
          {
            title: "Date",
            value: ({ created_at }) => fromDate(created_at)
          },
          {
            title: "Note",
            value: ({ description }) => description
          },
          {
            title: "",
            value: ({ attachments = [], id }) => (
              <div className={s.attachmentsWrap}>
                <AttachmentIcon
                  className={classNames(s.icon, {
                    [s.disabled]: !attachments?.length
                  })}
                  onClick={() =>
                    attachments.length > 0 && setVisibleAttachmentsPopupId(id)
                  }
                />
                {visibleAttachmentsPopupId === id && attachments.length > 0 && (
                  <ul className={s.attachments} ref={attachmentsRef}>
                    {attachments.map((attachment, index) => (
                      <li className={s.attachment} key={index}>
                        {getFileIconByType(
                          attachment.type || attachment.file_type
                        )}
                        <a
                          className={s.link}
                          href={attachment.path}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {attachment.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )
          }
        ]}
        options={item => {
          return [
            {
              title: "Edit",
              onClick: () => {
                setNote(item)
                setShow({ ...show, edit: true })
              }
            },
            {
              title: "Delete",
              onClick: () => {
                setNote(item)
                setShow({ ...show, delete: true })
              }
            }
          ]
        }}
        button={{
          name: "Add note",
          onClick: () => openPopup()
        }}
      />
      {show.delete && (
        <Popup
          onClose={() => setShow({ ...show, delete: false })}
          successBtnTitle='Delete'
          title='Delete note'
          text={"Are you sure you want to delete this note?"}
          onClick={() => {
            removeHandler(note.id)
            setShow({ ...show, delete: false })
          }}
          isDelete
          dialog
        />
      )}
      {show.edit && (
        <Popup
          onClose={() => setShow({ ...show, edit: false })}
          title='Edit note'
        >
          <EditProjectNoteForm
            editHandler={editHandler}
            onClose={() => setShow({ ...show, edit: false })}
            note={note}
          />
        </Popup>
      )}
    </div>
  )
}

export default ProjectNotesTable
