// Polyfills for IE 11
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "intersection-observer";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./UIKit/index.scss";
import {BrowserRouter as Router} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import store from "./store";
import {Provider} from "react-redux";

ReactDOM.render(
  <Router>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App/>
      </Provider>
    </I18nextProvider>
  </Router>,
  document.getElementById("root")
);
