import React, {useState, useCallback, useEffect} from "react";
import classNames from "classnames";
import {useFormik} from "formik";
import {object, string} from "yup";
import PropTypes from "prop-types";
import {debounce} from "lodash";
import DatePicker from "components/elements/DatePicker/DatePicker";
import s from "./EditMilestoneTaskFormNew.module.scss";
import Input from "components/elements/Input/Input";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import {formatDate, fromDate} from "helpers/dateFormat";
import {getStatusLabel} from "helpers/milestonesHelper";
import services from "services";
import Textarea from "components/elements/Textarea/Textarea";
import Attachments from "components/forms/AddMilestoneTaskForm/components/Attachments/Attachments";
import Divider from "../../elements/Divider/Divider";
import {backgroundTypes} from "../../../helpers/helpData";
import Files from "../AddMilestoneTaskForm/components/Attachments/components/Files/Files";

const EditMilestoneTaskFormNew = ({task, onSubmit, onClose, disabledFields}) => {
  const [users, setUsers] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const DESCRIPTION_MAX_LENGTH = 150;
  const HELP_MAX_LENGTH = 500;

  useEffect(() => {
    services.taskServices.getTaskStatuses().then(res => {
      if (res.data) {
        setTaskStatuses(res.data.task_statuses.map(status => {
          return {
            id: status.id,
            label: getStatusLabel(status.name, backgroundTypes[status.color]),
            value: status.id,
          };
        }));
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: task.name,
      help_text: task.help_text || "",
      responsible_user_id: {
        name: task.responsible_user.name,
        id: task.responsible_user.id
      },
      status: task.task_status ? {
        id: task.task_status.id,
        label: getStatusLabel(task.task_status.name, backgroundTypes[task.task_status.color]),
        value: task.task_status.id,
      } : null,
      start_date: !task.start_date.includes("/")
        ? new Date(task.start_date)
        : new Date(fromDate(task.start_date)),
      end_date: !task.end_date.includes("/")
        ? new Date(task.end_date)
        : new Date(fromDate(task.end_date)),
      description: task.description || "",
      attachments: task.attachments || []
    },
    validationSchema: object({
      name: string()
        .required("Name is required")
        .min(2, "Please enter task name that consists of no less than 2 characters")
        .max(32, "Please enter task name that consists of no more than 32 characters"),
      description: string().max(
        DESCRIPTION_MAX_LENGTH,
        `Please enter task note that consists of no more than ${DESCRIPTION_MAX_LENGTH} characters`
      ),
      responsible_user_id: object({
        name: string().required("Please, enter user name"),
        id: string().required("Please select user from dropdown list")
      }),
      start_date: string().required("Start date is required").nullable(),
      end_date: string().required("End date is required").nullable(),
      status: object({
        value: string().required("Status is required")
      }),
    }),
    validate: ({description}) => {
      const errors = {};
      if (!disabledFields?.includes("description") && !description.trim()) {
        errors.description = "Description is required";
      }
      return errors;
    },
    onSubmit: values => {
      onSubmit({
        name: values.name,
        responsible_user_id: values.responsible_user_id.id,
        task_status_id: values.status.value,
        start_date: formatDate(values.start_date),
        end_date: formatDate(values.end_date),
        description: values.description,
        attachments: values.attachments.filter(
          file => file.lastModifiedDate
        ),
        help_text: values.help_text,
      });
    }
  });

  const {
    handleSubmit,
    touched,
    values,
    errors,
    setFieldValue,
    handleChange
  } = formik;

  const searchHandler = useCallback(
    debounce(term => {
      services.userServices
        .stakeholdersAutocomplete({query: term})
        .then(res => setUsers(res.data));
    }, 300),
    []
  );
  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };
  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      );
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        );
      });
    }
  };
  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      {
        !disabledFields?.includes("name") && (
          <Input
            className={classNames(s.countField, {
              [s.countWrap]: errors.name && touched.name
            })}
            label='Task name'
            value={values.name}
            placeholder='Enter task name'
            onChange={e => {
              setFieldValue("name", e.target.value);
            }}
            error={touched.name && errors.name}
            limitValue={32}
            withCounter
          />
        )
      }
      <div
        className={classNames(s.countField, {
          [s.countWrap]: errors.description && touched.description
        })}
      >
        {
          !disabledFields?.includes("description") && (
            <Textarea
              label='Description'
              value={values.description}
              error={
                errors.description && touched.description && errors.description
              }
              onChange={handleChange}
              name='description'
              placeholder='Enter descriptions'
              count={{
                current: values.description.length,
                max: DESCRIPTION_MAX_LENGTH
              }}
            />
          )
        }
      </div>
      <div
        className={classNames(s.countField, {
          [s.countWrap]: errors.description && touched.description
        })}
      >
        {
          !disabledFields?.includes("help") && (
            <Textarea
              disabled={disabledFields?.includes("help")}
              label="Help"
              value={values.help_text}
              onChange={handleChange}
              name="help_text"
              placeholder="Enter help text"
              count={{
                current: values.help_text.length,
                max: HELP_MAX_LENGTH
              }}
            />
          )
        }
      </div>
      <div className={s.inputWrap}>
        {
          disabledFields?.includes("attachments") ?
            (
              <Files
                files={values.attachments}
                withLink
                disabled
              />
            ) : (
              <Attachments
                onChange={handleChangeAttachment}
                onDelete={handleDeleteAttachment}
                attachments={values.attachments}
                disabled={disabledFields?.includes("attachments")}
              />
            )
        }
      </div>
      {!disabledFields?.includes("attachments") && <Divider margin={20}/>}
      <div className={s.inputWrap}>
        <AutoCompleteInput
          disabled={disabledFields?.includes("responsible_user")}
          data={users}
          placeholder={"Enter responsible user name"}
          value={values.responsible_user_id.name}
          onChange={e => {
            setFieldValue("responsible_user_id", {
              id: "",
              name: e.target.value
            });
            searchHandler(e.target.value);
          }}
          onSelect={value => {
            setFieldValue("responsible_user_id", {
              id: value.id,
              name: value.name
            });
          }}
          label='Responsible User'
          error={
            errors.responsible_user_id &&
            touched.responsible_user_id &&
            (errors.responsible_user_id.name || errors.responsible_user_id.id)
          }
        />
      </div>
      <div className={s.inputWrap}>
        <CustomDropdown
          options={taskStatuses}
          value={values.status}
          onChange={option => setFieldValue("status", option)}
          label='Task status'
          menuClasses={s.options}
        />
      </div>
      <div className='dateBlock'>
        <DatePicker
          disabled={disabledFields?.includes("dates")}
          label='Start date'
          selected={values.start_date}
          onChange={date => {
            setFieldValue("start_date", date);
            date > values.end_date && setFieldValue("end_date", date);
          }}
          selectsStart
          startDate={values.start_date}
          endDate={values.end_date}
          placeholderText='Choose start date'
          wrapperClassName='wrapperdatePicker'
          className='customInput'
          todayButton='Go to today'
          showYearDropdown
          dateFormatCalendar='MMMM'
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.start_date && errors.start_date}
        />
        <DatePicker
          disabled={disabledFields?.includes("dates")}
          label='End date'
          selected={values.end_date}
          onChange={date => setFieldValue("end_date", date)}
          selectsEnd
          startDate={values.start_date}
          endDate={values.end_date}
          minDate={values.start_date}
          placeholderText='Choose end date'
          wrapperClassName='wrapperdatePicker'
          className='customInput'
          todayButton='Go to today'
          showYearDropdown
          dateFormatCalendar='MMMM'
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.end_date && errors.end_date}
        />
      </div>
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          className='popupBtn'
          type='reset'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          Save
        </Button>
      </div>
    </form>
  );
};

EditMilestoneTaskFormNew.propTypes = {
  task: PropTypes.object,
  users: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  disabledFields: PropTypes.array,
};
EditMilestoneTaskFormNew.defaultProps = {
  task: {},
  users: [],
  onClose: () => {
  },
  onSubmit: () => {
  }
};

export default EditMilestoneTaskFormNew;
