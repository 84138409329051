import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import { load, save } from "helpers/localStorage";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = [thunk];
const persistedState = load("globalProject");

if (process.env.REACT_APP_MODE !== "prod") {
  middleware = [...middleware];
}

const store = createStore(
  rootReducer,
  load("globalProject"),
  composeEnhancer(applyMiddleware(...middleware))
);
store.subscribe(() => {
  save("globalProject", store.getState().globalProject);
});

export default store;
