import React from "react";
import PropTypes from 'prop-types'
import Button from "components/elements/Button/Button";
import s from "./TableWrapper.module.scss";

const TableWrapper = ({ count, buttonTitle, children }) => {
  return (
    <div className={s.tableWrapper}>
      <div className={s.tabelWrapperTitle}>
        <h3 className={s.countTitle}>
          Total <span className={s.count}>{count}</span>
        </h3>

        {buttonTitle && <Button styled="addElement">{buttonTitle}</Button>}
      </div>

      {children}
    </div>
  );
};

TableWrapper.propTypes = {
  count: PropTypes.any,
  buttonTitle: PropTypes.string,
  children: PropTypes.node
}

TableWrapper.defaultProps = {
  count: 888,
  
}

export default TableWrapper;
