import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './SurveysInfo.module.scss';
import Tabs from 'components/elements/Tabs/Tabs';
import { Templates } from '../Templates/Templates';
import { SentSurveys } from '../SentSurveys/SentSurveys';

export const SurveysInfo = ({ path }) => {
  const history = useHistory();

  const clearQueryOnTabChange = () => {
    history.push({
      search: '',
    });
  };

  return (
    <div className={styles.categoriesInfo}>
      <div className={styles.wrapTabs}>
        <Tabs handleClick={clearQueryOnTabChange}>
          <div label="Templates">
            <Templates />
          </div>
          <div label="Sent surveys">
            {/* SentSurveys will be made in another branch*/}
            <SentSurveys />
          </div>
        </Tabs>
      </div>
    </div>
  );
};
