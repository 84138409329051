import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { object, string } from "yup"
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput"
import Button from "components/elements/Button/Button"
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const ChangeProjectStageForm = ({ onSubmit, onClose, defaultValues }) => {
  const {t} = useTranslation();
  const formik = useFormik({
    initialValues: defaultValues || {
      name: "",
      id: ""
    },
    validationSchema: object({
      name: string()
        .required("Project Stage is required")
        .nullable()
    }),
    onSubmit: values => {
      onSubmit(values).then(onClose)
    }
  })
  const {
    handleSubmit,
    errors,
    setFieldValue,
    touched,
    handleChange,
    values
  } = formik

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <AutoCompleteInput
        data={[]}
        value={values.name}
        placeholder='Enter stage name'
        name='name'
        onChange={handleChange}
        onSelect={value => {
          setFieldValue("id", value.id)
          setFieldValue("name", value.name)
        }}
        label='Project stage'
        error={touched.name && errors.name}
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
          data-translate-key={"cancel"}
        >
          {t(getTranslateKey("cancel"))}
        </Button>
        <Button
          disabled={defaultValues && defaultValues.name === values?.name}
          styled='primary'
          type='submit'
          className='popupBtn'
        >
          {defaultValues ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  )
}

export default ChangeProjectStageForm
