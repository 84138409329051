import React, {useState, useEffect, useCallback} from "react";
import s from "./ProjectForm.module.scss";
import classNames from "classnames";
import {useFormik} from "formik";
import Header from "./components/Header/Header";
import {initialValues, MAX_DESCRIPTION_LENGTH, validationSchema} from "./helpers/validation";
import Input from "components/elements/Input/Input";
import services from "services";
import {object} from "yup";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import CurrencySelectionField from "components/elements/CurrencySelectionField/CurrencySelectionField";
import {currencyData} from "helpers/helpData";
import DatePicker from "components/elements/DatePicker/DatePicker";
import Textarea from "../../elements/Textarea/Textarea";
import Attachments from "../AddMilestoneTaskForm/components/Attachments/Attachments";
import {formatDate} from "../../../helpers/dateFormat";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import CustomDropdown from "../../elements/CustomDropdown/CustomDropdown";
import {formatEntityData, formatEntityToObject} from "../../../helpers/formatEntityData";
import SelectCheckDropdown from "../../elements/SelectCheckDropdown/SelectCheckDropdown";
import {localization} from "../../../helpers/localizationForCompanies";
import {debounce} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {addGlobalProject, editGlobalProject, setGlobalProjectMessage} from "../../../actions/globalProject";
import DropdownTree from "../../elements/DropdownTree/DropdownTree";
import {userRoles} from "../../../helpers/usersData";
import {userRoles as orgRoles, userTypes} from "../../../helpers/userPermissions/userRoles";
import RSAsyncSelect from "../../elements/ReactSelectCustom/RSAsyncSelect";
import {useTranslation} from "react-i18next";
import {checkRoles} from "../../../helpers/checkRoles";

const ProjectForm = (
  {
    initialProject,
    requestId,
    preparedClients,
    preparedStakeholders,
    stages,
    isEditAdvancedProject = false,
  }
) => {
  const [categories, setCategories] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [checkedClients, setCheckedClients] = useState([]);
  const [checkedStakeholders, setCheckedStakeholders] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [categoryLevels, setCategoryLevels] = useState([]);
  const [request, setRequest] = useState(null);
  const dispatch = useDispatch();
  const {roles} = useSelector(state => state.user);
  const user = useSelector(state => state.user);
  const history = useHistory();
  const {t} = useTranslation();
  const isAdvanced = history.location.state?.from === "advanced-created" || isEditAdvancedProject;
  const category_id = history.location.state?.category ?? {name: "", id: ""};
  const preparedOwner = !checkRoles(roles, orgRoles.admin) && {name: user?.name, id: user?.id};
  const {procurement, adminTypes} = userTypes;

  const procurementUserRoles = userRoles
    .filter(role => (role.userType.value === procurement || role.userType.value === adminTypes))
    .map(role => role.value)
    .join(",");

  const handleCreateProject = values => {
    dispatch(addGlobalProject(values));
    dispatch(
      setGlobalProjectMessage({
        type: "success",
        text: "Project successfully created."
      })
    );
  };

  const handleEditProject = values => {
    dispatch(editGlobalProject(values));
    dispatch(
      setGlobalProjectMessage({
        type: "success",
        text: "Project successfully changed."
      })
    );
  };

  const appendFile = file => {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
  };

  const checkedFormat = entities => {
    return entities.map(e => ({
      checked: true,
      id: e.id,
      label: e.name,
      subItems: null,
      value: e.name,
    }));
  };

  useEffect(() => {
    services.categoriesServices.getCategories().then(res => {
      setCategories(res.data, "categories");
    });

    services.userServices
      .stakeholdersAutocomplete({roles: userRoles.map(role => role.value).join(",")})
      .then(res => {
        res.data && setStakeholders(
          res.data.map(a => ({
            id: a.id,
            label: a.name,
            value: a.name
          })).sort((a, b) => a.label.localeCompare(b.label))
        );
      });

    services.adminProjectsServices.getCategoriesLevels()
      .then(res => {
        setCategoryLevels(res.data);
      })
      .catch((error) => {
        return error.response.status <= 500 && setCategoryLevels({
          level1: true,
          level2: true,
          level3: true,
          level4: true
        });
      });

    services.userServices
      .getDepartments({active: true})
      .then(res => setDepartments(formatEntityData(res?.data || []) || []));

    services.projectServices.getProjectTypes().then(res => {
      const result = res.data?.project_types?.map(({id, name}) => ({
        id,
        label: name,
        value: name
      })) || [];
      setProjectTypes(result);
    });

    if (requestId) {
      services.requestTypeServices.getRequestType(requestId).then(res => {
        setRequest(res.data);
      });
    }

    if (initialProject?.id || isEditAdvancedProject) {
      setCheckedClients(checkedFormat(preparedClients));
      setCheckedStakeholders(checkedFormat(preparedStakeholders));
    }
  }, []);

  const suppliersAutocomplete = term => services.supplierServices
    .suppliersAutocomplete({query: term})
    .then(res => res.data.map(supplier => ({
      value: supplier.id,
      label: supplier.name,
    })));

  const usersAutocomplete = useCallback(
    debounce(
      term =>
        services.userServices
          .stakeholdersAutocomplete({
            roles: procurementUserRoles,
            query: term
          })
          .then(res => setUsers(res.data)),
      300
    ),
    []
  );

  const uploadFile = (id, attachment) => {
    const file = appendFile(attachment);
    services.taskServices.addAttachment(file, id).then(res => res.data);
  };

  const formatForAdvancedProject = entities => entities.map(s => ({id: s.id, name: s.name}));

  const deleteFile = id => {
    services.taskServices.deleteAttachment(id).then(res => res.data);
  };
  const currentDate = new Date();

  const formik = useFormik({
    initialValues: initialProject || {
      ...initialValues, category_id,
      end_date: stages.length > 0 && new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (stages.length - 2)),
      owner_id: preparedOwner ? preparedOwner : ""
    },
    validationSchema: object(validationSchema),
    validate: ({clients, target_spend, start_date, end_date}) => {
      const errors = {};
      if (!Object.values(clients).filter(client => client).length) {
        errors.clients = "Please, choose client";
      }
      if (target_spend < 0) {
        errors.target_spend = "Please, type valid Target spend";
      }
      if ((new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate() + (stages.length - 2))) > end_date) {
        errors.end_date = `Project length should be more than ${stages.length - 1} days`;
      }
      return errors;
    },

    onSubmit: (values, {setErrors}) => {
      let preparedValues = {
        category_id: values.category_id.id,
        department_ids: checkedClients.map(e => e.id).join(","),
        start_date: formatDate(values.start_date),
        end_date: formatDate(values.end_date),
        stage_id: values.stage.id,
        name: values.name,
        description: values.description,
        owner_id: values.owner_id.id,
        project_type_id: values.project_type.id,
        target_spend: values.target_spend || 0,
        target_spend_currency: values.target_spend_currency?.label,
        budget_spend: values.budget_value || 0,
        budget_spend_currency: values.budget_currency?.label,
        supplier_ids: values.suppliers.map(e => e.value).join(","),
        stakeholder_ids: checkedStakeholders.map(e => e.id).join(","),
        request_id: requestId,
      };
      if (isAdvanced) {
        preparedValues = {
          ...values,
          suppliers: values.suppliers,
          stakeholders: formatForAdvancedProject(checkedStakeholders),
          departments: formatForAdvancedProject(checkedClients),
          start_date: formatDate(values.start_date),
          end_date: formatDate(values.end_date),
          category: values.category_id,
          owner: values.owner_id,
        };
      }
      if (initialProject?.id || isEditAdvancedProject) {
        if (isEditAdvancedProject) {
          handleEditProject(preparedValues);
          history.goBack();
        } else {
          services.projectServices.editProject(initialProject.id, preparedValues)
            .then(res => {
              if (res.data) {
                res.data.attachments.forEach(f => {
                  if (!values.attachments.find(a => a.id === f.id)) {
                    deleteFile(f.id);
                  }
                });
                values.attachments.forEach(f => {
                  if (!f.id) uploadFile(res.data.id, f);
                });
                history.push(`/projects/${res.data.id}`);
              }
            })
            .catch(err => {
              setErrors({
                stage: {
                  value: err.response.data.error,
                }
              });
            });
        }
      } else {
        if (history.location.state?.from === "advanced-created") {
          handleCreateProject(preparedValues);
          history.goBack();
        } else {
          services.projectServices
            .createProject(preparedValues)
            .then(res => {
              if (res.data) {
                values.attachments.forEach(f => {
                  uploadFile(res.data.id, f);
                });
                history.push(`/projects/${res.data.id}`);
              }
            });
        }
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = formik;

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };

  const handleDeleteAttachment = (_, fileIndex) => {
    setFieldValue(
      "attachments",
      values.attachments.filter((_, idx) => idx !== fileIndex)
    );
  };

  const getLabelForProjectType = () => {
    if (initialProject?.id) {
      return (
        <span data-translate-key="project-type-"> {t("project-type-")}
          <span className={s.subLabel}
                data-translate-key="(changing-the-project-type-may-affect-created-information)">{t("(changing-the-project-type-may-affect-created-information)")}
        </span>
        </span>
      );
    }
    return "Choose a type";
  };

  const clientsField = (
    <SelectCheckDropdown
      label="Clients"
      value={values.clients}
      onChange={(e, item) => {
        setFieldValue("clients", e);
        item.checked
          ? setCheckedClients([item, ...checkedClients])
          : setCheckedClients(checkedClients.filter(e => e.id !== item.id));
      }}
      options={departments}
      placeholder="Start typing client name"
      onSearch={data => {
        setFieldValue("clients", formatEntityToObject(data));
        setCheckedClients(data);
      }}
      emptyMessage="clients not found"
      withEndComma={true}
      className={s.field}
      error={errors.clients && touched.clients && errors.clients}
    />
  );
  return (
    <>
      <form
        className={s.form}
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
      >
        <Header isUpdate={(initialProject?.id || isEditAdvancedProject)} titleName={request && request.name}/>
        <div className={s.col}>
          <DropdownTree
            categoryLevels={categoryLevels}
            withSearch={true}
            defaultValue={values.category_id}
            onChange={e => setFieldValue("category_id", {id: "", name: e.target.value})}
            onSelect={({id, name}) => setFieldValue("category_id", {id, name})}
            name="category"
            className={s.field}
            options={categories}
            placeholder="Start typing category name"
            label="Category"
            error={errors.category_id && touched.category_id && errors.category_id.id}
          />
          <CustomDropdown
            className={s.field}
            options={projectTypes}
            value={values.project_type}
            onChange={e => setFieldValue("project_type", e)}
            placeholder="Choose a type"
            disabled={Boolean(initialProject?.id)}
            label={getLabelForProjectType()}
            error={errors.project_type && touched.project_type && errors.project_type.id}
          />
          <Input
            value={values.name}
            onChange={handleChange}
            name="name"
            className={s.field}
            type="text"
            placeholder="Enter a project name"
            label="Project name"
            onBlur={handleBlur}
            error={errors.name && touched.name && errors.name}
          />
          {initialProject?.id && (
            <CustomDropdown
              className={s.field}
              options={stages}
              value={values.stage.value}
              onChange={option => setFieldValue("stage", option)}
              error={errors.stage && errors.stage.value && errors.stage.value}
              label={`${localization.status}`}
            />
          )}
        </div>
        <div className={s.col}>
          <CurrencySelectionField
            className={s.field}
            options={currencyData}
            value={values.budget_value}
            defaultValue={values.budget_currency}
            onChange={({fieldVal, ...spread}) => {
              setFieldValue("budget_value", fieldVal);
              setFieldValue("budget_currency", {...spread});
            }}
            name="budget"
            label="Budget"
            optional
            fieldPlaceholder="000,000"
          />
          <CurrencySelectionField
            className={s.field}
            options={currencyData}
            value={values.target_spend}
            defaultValue={values.target_spend_currency}
            onChange={({fieldVal, ...spread}) => {
              setFieldValue("target_spend", fieldVal);
              setFieldValue("target_spend_currency", {...spread});
            }}
            name="target_spend"
            label="Target spend"
            optional
            fieldPlaceholder="000,000"
          />
        </div>
        {(!initialProject?.id && !isAdvanced) && (
          <div className={s.col}>
            <RSAsyncSelect
              className={s.field}
              isMulti
              label="Suppliers"
              optional
              onChange={item => setFieldValue("suppliers", item)}
              placeholder="Start typing supplier name"
              load={suppliersAutocomplete}
            />
            {clientsField}
          </div>
        )}
        <div className={s.col}>
          <AutoCompleteInput
            className={s.field}
            value={values.owner_id?.name || ""}
            onChange={e => {
              setFieldValue("owner_id", {
                id: "",
                name: e.target.value
              });
              usersAutocomplete(e.target.value);
            }}
            onSelect={value => {
              setFieldValue("owner_id", {
                id: value.id,
                name: value.name
              });
            }}
            name="owner_id"
            data={users}
            placeholder={`Enter ${localization.procurementOwner}`}
            label={`${localization.procurementOwner}`}
            error={
              errors.owner_id &&
              touched.owner_id &&
              (errors.owner_id.name || errors.owner_id.id)
            }
          />
          {(initialProject?.id || isAdvanced) && clientsField}
          {(!initialProject?.id && !isAdvanced) && (
            <SelectCheckDropdown
              label="Stakeholders"
              optional
              value={values.stakeholders}
              onChange={(e, item) => {
                setFieldValue("stakeholders", e);
                item.checked
                  ? setCheckedStakeholders([item, ...checkedStakeholders])
                  : setCheckedStakeholders(checkedStakeholders.filter(e => e.id !== item.id));
              }}
              options={stakeholders}
              placeholder="Start typing stakeholder name"
              onSearch={data => {
                setFieldValue("stakeholders", formatEntityToObject(data));
                setCheckedStakeholders(data);
              }}
              emptyMessage="stakeholders not found"
              withEndComma={true}
              className={s.field}
            />
          )}
          <div className={classNames("dateBlock", s.datesWrap)}>
            <DatePicker
              label="Start date"
              selected={values.start_date}
              onChange={date => {
                setFieldValue("start_date", date);
                date > values.end_date && setFieldValue("end_date", date);
              }}
              selectsStart
              startDate={values.start_date}
              endDate={values.end_date}
              placeholderText="Choose start date"
              wrapperClassName="wrapperdatePicker"
              className="customInput"
              classWrap={s.datePickerWrap}
              todayButton="Go to today"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={5}
              scrollableYearDropdown
              error={touched.start_date && errors.start_date}
            />
            <DatePicker
              label="Target end date"
              selected={values.end_date}
              onChange={date => setFieldValue("end_date", date)}
              selectsEnd
              startDate={values.start_date}
              endDate={values.end_date}
              minDate={values.start_date}
              placeholderText="Choose end date"
              wrapperClassName="wrapperdatePicker"
              className="customInput"
              classWrap={s.datePickerWrap}
              todayButton="Go to today"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={5}
              scrollableYearDropdown
              error={touched.end_date && errors.end_date}
            />
          </div>
        </div>
        <div className={s.col}>
          <div className={s.field}>
            <Textarea
              value={values.description}
              label="Description"
              onChange={e => setFieldValue("description", e.target.value)}
              placeholder="Enter description"
              error={
                errors.description && touched.description && errors.description
              }
              count={{
                current: values.description.length,
                max: MAX_DESCRIPTION_LENGTH,
              }}
              customHeight={56}
            />
          </div>
          {
            !isAdvanced && (
              <div className={s.field}>
                <Attachments
                  onChange={handleChangeAttachment}
                  onDelete={handleDeleteAttachment}
                  attachments={values.attachments}
                  filesMaxLength={3}
                  customHeight={56}
                />
              </div>
            )
          }
        </div>
      </form>
    </>
  );
};

export default ProjectForm;

ProjectForm.propTypes = {
  initialProject: PropTypes.any,
  requestId: PropTypes.string,
  preparedClients: PropTypes.array,
  preparedSuppliers: PropTypes.array,
  preparedStakeholders: PropTypes.array,
  isEditAdvancedProject: PropTypes.bool,
};