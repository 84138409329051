import React, { Fragment } from "react"

export const formatCurrency = (currency, number, showCode = true) => {
  const [currencyCode, currencySymbol] = currency?.split(" ") || []
  const currencyFull = showCode
    ? `${currencyCode || "-"} `
    : "" + (currencySymbol || "-")

  return (
    <>
      <span style={{ color: "black", opacity: "0.55" }}>
        {currency === "USD $" ? "$" : currency}
      </span>{" "}
      {number?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || "-"}
    </>
  )
}
