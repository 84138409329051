import React from "react"
import Popup from "components/popups/Popup/Popup"
import AdminStakeholderTypesForm from "components/forms/AdminStakeholderTypesForm/AdminStakeholderTypesForm"
const AddPopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      onClose={onClose}
      title='New stakeholder  type'
      text='Please, complete the following fields to create a new stakeholder  type'
    >
      <AdminStakeholderTypesForm onClose={onClose} onSubmit={onSubmit} />
    </Popup>
  )
}
export default AddPopup
