import React, {useEffect, useState} from "react";
import s from "./ContractDetailsContainer.module.scss";
import GeneralInformation from "./components/GeneralInformation/GeneralInformation";
import CategoryInformation from "./components/CategoryInformation/CategoryInformation";
import SupplierADT from "./components/SupplierADT/SupplierADT";
import classNames from "classnames";
import ContractValue from "./components/ContractValue/ContractValue";
import ChildContracts from "./components/ChildContracts/ChildContracts";
import NotesTable from "./components/NotesTable/NotesTable";
import services from "../../../services";
import PropTypes from "prop-types";
import ProjectsGanttChart from "../../elements/ProjectsGanttChart/ProjectsGanttChart";
import {months} from "../../elements/ProjectsGanttChart/helpers/helpData";

const ContractDetailsContainer = ({id}) => {
  const [contract, setContract] = useState(null);
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFullMode, setFullMode] = useState(true);

  useEffect(() => {
    services.contractsServices.getContractDetails(id).then(res => {
      if (res.data) setContract(res.data);
      setLoading(false);
    });
    if (isFullMode) {
      services.contractsServices.getGanttGraph(id).then(res => {
        if (res.data) setProjects(res.data);
        setLoading(false);
      });
    }
  }, [isFullMode]);

  if (!contract) return "";

  return (
    <section className={s.container}>
      <h3 className={s.title}>{contract.name}</h3>
      <div className={classNames(s.blocksWrapper, s.three)}>
        <GeneralInformation params={{
          index: contract?.contract_number || 1,
          business_owner: contract?.business_owner,
          procurement_owner: contract?.procurement_owner,
          start_date: contract?.start_date,
          end_date: contract?.end_date,
        }}/>
        <CategoryInformation params={{
          categories: [contract.category],
          category_owner: contract.category.owner,
          department: contract.category.department,
          division: contract.category.division,
        }}/>
        <SupplierADT params={{
          supplier_owner: contract.supplier.owner,
          supplier: contract.supplier,
        }}/>
      </div>
      <div className={classNames(s.blocksWrapper, s.two)}>
        <ContractValue params={{
          total_value: contract.total_value,
          total_value_currency: contract.total_value_currency,
          annual_value: contract.annual_value,
          annual_value_currency: contract.annual_value_currency,
        }}/>
        <ChildContracts params={{
          sub_contracts_count: contract.childs.projects_count,
          sub_total_value: contract.childs.total_value,
          sub_currency: contract.childs.currency,
          sub_annual_value: contract.childs.annual_value,
        }}/>
      </div>
      {projects && projects?.projects_count !== 0 && (
        <div className={s.GanttChart}>
          <ProjectsGanttChart
            data={projects.projects}
            monthsList={months}
            isFullMode={isFullMode}
            showFullAction={() => setFullMode(!isFullMode)}
            isUseFull={projects.projects_count >= 11}
          />
        </div>)}
      <NotesTable id={id}/>
    </section>
  );
};

export default ContractDetailsContainer;

ContractDetailsContainer.propTypes = {
  id: PropTypes.string,
};