import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import ResourceUtilizationReportContainer from "components/containers/ResourceUtilizationReportContainer/ResourceUtilizationReportContainer";

const ResourceUtilizationReportPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: "/users",
          name: "Users",
        },
        {
          path: "/resource-utilization-report",
          name: "Resource utilization report",
        },
      ]}
    >
      <ResourceUtilizationReportContainer />
    </DefaultLayout>
  );
};

export default ResourceUtilizationReportPage;
