import React, {useState} from 'react'
import FilterBar from 'components/elements/FilterBar/FilterBar'
import FilterBarDropdown from '../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown'
import {debounce} from 'lodash'
import FilterBarDropdownYears from "../CategoriesFilter/FilterBarDropdownYears/FilterBarDropdownYears";

const initialFiltersValues = {
  years: ['2021'],
  statuses: ['active_and_completed']
}
const CompanyGoalFilter = ({
	onFiltersChange,
	filters,
	className,
	source,
	activeFilters
}) => {

	const [activeFiltersData, setActiveFiltersData] = useState(
		initialFiltersValues
	);

	const handleChangeFilter = (filterName, values) => {
		const newFiltersValues = { ...activeFiltersData, [filterName]: values }
		setActiveFiltersData({ ...newFiltersValues })
		onFiltersChange({ ...newFiltersValues })
	};

	const handleClearAll = () => {
		setActiveFiltersData({ years: [], statuses: [] })
		onFiltersChange({ years: [], statuses: [], query: '' })
	};

	const handleSearch = debounce(term => {
		onFiltersChange({ ...activeFilters, query: term })
	}, 300);

	return (
		<FilterBar
			filterName='Filter goals'
			handleClearAll={handleClearAll}
			className={className}
			onSearch={handleSearch}
			withSearch={source !== 'user'}
			withoutDefaultFilter
			clearAllWithSearch={true}
		>
			<FilterBarDropdownYears
				name="Years"
				options={filters.years}
				defaultValues={activeFilters.years}
				handleChange={values => {
					handleChangeFilter("years", values);
				}}
			/>
			<FilterBarDropdown
				name='Statuses'
				options={filters.statuses}
				defaultValues={activeFilters.statuses}
				withOptionTranslate
				isSingleFilter
				handleChange={values => {
					handleChangeFilter('statuses', values)
				}}
			/>
		</FilterBar>
	)
}

export default CompanyGoalFilter
