import React, {useState, useRef} from "react";
import {useResize} from "../../../hooks/useResize"
import LeftSidebar from "./components/LeftSidebar/LeftSidebar";
import Header from "components/elements/Header/Header";
import s from "./DefaultLayout.module.scss";
import RightSidebar from "./components/RightSidebar/RightSidebar";
import RightSidebarOpener from "./components/RightSidebarOpener/RightSidebarOpener";
import NavigationContainer from "components/containers/NavigationContainer/NavigationContainer";
import CreateProjectContainer from "./components/CreateProjectContainer/CreateProjectContainer";
import {AlertContextProvider} from "contexts/AlertContext/AlertContext";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import services from "services";
import {
  addGlobalProjectContract,
  addGlobalProjectSupplier,
  addGlobalProjectUser,
  setGlobalProjectMessage
} from "actions/globalProject";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import {ReactComponent as BackArrow} from "../../../assets/icons/backArrow.svg";
import {ReactComponent as Sort} from "../../../assets/icons/sort.svg";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SelectedTaskSidebar from "../../elements/SelectedTaskSidebar/SelectedTaskSidebar";

const DefaultLayout = (
  {
    children,
    breadcrumbs,
    withRightSidebar = false
  }
) => {
  const user = useSelector(state => state?.user);
  const isBusinessUser = user.roles.includes(userRoles.business_user);
  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);
  const history = useHistory();
  const withoutLeftSidebar = (isBusinessUser || isCategoryOwner);

  const [visRightSidebarItem, setVisRightSidebarItem] = useState(null);
  const [isOverProject, setIsOverProject] = useState(false);
  const dispatch = useDispatch();
  const globalProject = useSelector(state => state.globalProject?.data);
  const handleDragEnd = ({draggableId}) => {
    if (isOverProject && globalProject && draggableId) {
      if (draggableId.includes("contract")) {
        const contractId = draggableId.split("contract-")?.[1];
        const exactContract = Boolean(
          globalProject.contracts.filter(e => e.id === contractId).length
        );
        if (!exactContract) {
          services.contractsServices
            .getContract(contractId)
            .then(res => {
              dispatch(
                setGlobalProjectMessage({
                  type: "success",
                  text:
                    "Contract was added to the project. You can edit it here"
                })
              );
              dispatch(addGlobalProjectContract(res.data));
            })
            .catch(err => {
              dispatch(
                setGlobalProjectMessage({
                  type: "error",
                  text: err.response.data.error
                })
              );
            });
        } else {
          dispatch(
            setGlobalProjectMessage({
              type: "error",
              text: "This contract already added to project."
            })
          );
        }
      } else if (draggableId.includes("supplier")) {
        const supplierId = draggableId.split("supplier-")?.[1];
        const exactSupplier = Boolean(
          globalProject.suppliers.filter(e => e.id === supplierId).length
        );
        if (!exactSupplier) {
          services.supplierServices
            .getSupplier(supplierId)
            .then(res => {
              dispatch(
                setGlobalProjectMessage({
                  type: "success",
                  text:
                    "Supplier was added to the project. You can edit it here"
                })
              );
              dispatch(addGlobalProjectSupplier(res.data));
            })
            .catch(err => {
              dispatch(
                setGlobalProjectMessage({
                  type: "error",
                  text: err.response.data.error
                })
              );
            });
        } else {
          dispatch(
            setGlobalProjectMessage({
              type: "error",
              text: "This supplier already added to project."
            })
          );
        }
      } else if (draggableId.includes("user")) {
        const userId = draggableId.split("user-")?.[1];
        const exactUser = Boolean(
          globalProject.users.filter(e => e.id === userId).length
        );
        if (!exactUser) {
          services.userServices
            .getUser(userId)
            .then(res => {
              dispatch(
                setGlobalProjectMessage({
                  type: "success",
                  text: "User was added to the project. You can edit it here"
                })
              );
              dispatch(addGlobalProjectUser(res.data));
            })
            .catch(err => {
              dispatch(
                setGlobalProjectMessage({
                  type: "error",
                  text: err.response.data.error
                })
              );
            });
        } else {
          dispatch(
            setGlobalProjectMessage({
              type: "error",
              text: "This user already added to project."
            })
          );
        }
      }
    } else {
      setGlobalProjectMessage({
        type: "error",
        text: "Something went wrong. Please, try again."
      });
    }
  };
  const handleDragStart = ({draggableId}) => {
    if (draggableId) {
      const elemNodeName = `tr[data-rbd-draggable-id=${draggableId}]`;
      const item = document.querySelector(elemNodeName);
      item.style.background = "white";
      item.style.display = "inline-block";
      item.style.height = "auto";
    }
  };
  const {t} = useTranslation();
  let backToDashboard = null;

  if ((isBusinessUser || isCategoryOwner) && !history.location.pathname.includes("dashboard")) {
    backToDashboard = (
      <div className={s.backToDashboardBtn} onClick={() => history.push("/dashboard")}
           data-translate-key={"back-to-dashboard"}>
        <BackArrow/> {t("back-to-dashboard")}
      </div>
    );
  }

  const stretch = useRef();

  const options = {
    step: 2,
    axis: 'horizontal'
  };

  const initialValues = { width: 230 };

  const limits = {minWidth: 80, maxWidth: 230};

  const { initResize, size, setSize, drag, childSetDragStop, dragStop } = useResize(stretch, options, initialValues, limits)

  return (
    <div className={s.defaultLayout}>
      <section className={s.header}>
        <Header breadcrumbs={breadcrumbs} />
      </section>
      <section className={s.bodyLayout}>
        {!withoutLeftSidebar && (
          <aside className={s.leftSidebar} ref={!dragStop ? stretch : undefined} style={{width: size.width, cursor: drag ? 'col-resize' : dragStop ? 'not-allowed' : ''}}>
            <div style={{left: size.width}}
              onMouseDown={initResize} 
              className={classNames(s.stretchDiv, {[s.drag]: drag})}/>
            <div
              className={s['expand-btn']}
              style={{
                left: size.width - 15,
              }}
              onClick={() => !dragStop ? size.width > 100 ? setSize({width: 80}) : setSize({width: 230}) : undefined}
            >
              <Sort className={classNames(s.sortArrow, {[s.throw]: size.width < 100})}/>
              <span className={s.tooltip}>{size.width > 90 ? 'Collapse' : 'Expand'}</span>
            </div >
            <LeftSidebar width={size.width} setSize={setSize} childSetDragStop={childSetDragStop}/>
          </aside>
          )
        }
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <Droppable droppableId="global-project" type="GLOBAL_PROJECT">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={classNames(s.wrap, {[s.withoutMenu]: withoutLeftSidebar || size.width !== initialValues.width})}
              >
                <AlertContextProvider>
                  {(isBusinessUser || isCategoryOwner) ?
                    <div className={s.layoutWrapper}>
                      {backToDashboard}
                      {children}
                    </div>
                    :
                    <>
                      {backToDashboard}
                      {children}
                    </>
                  }

                </AlertContextProvider>
                {withRightSidebar && (
                  <div className={s.rightSidebarWrap}>
                    {visRightSidebarItem && (
                      <RightSidebarOpener
                        onClose={() => setVisRightSidebarItem(null)}
                      >
                        {visRightSidebarItem === "categories" && (
                          <NavigationContainer/>
                        )}
                        {visRightSidebarItem === "project" && (
                          <CreateProjectContainer
                            onMouseEnter={() =>
                              withRightSidebar && setIsOverProject(true)
                            }
                            onMouseLeave={() =>
                              withRightSidebar && setIsOverProject(false)
                            }
                          />
                        )}
                      </RightSidebarOpener>
                    )}
                    <RightSidebar
                      onClick={item => setVisRightSidebarItem(item)}
                      active={visRightSidebarItem}
                    />
                  </div>
                )}
                <span
                  style={{
                    display: "none"
                  }}
                >
                  {provided.placeholder}
                </span>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </div>
  );
};
export default DefaultLayout;

DefaultLayout.propTypes = {
  children: PropTypes.any,
  breadcrumbs: PropTypes.any,
  withRightSidebar: PropTypes.bool,
};