import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import s from "./Header.module.scss";
import StatusChanger from "../../../../elements/StatusChanger/StatusChanger";
import {useSelector} from "react-redux";

const Header = ({data, isHighRule, changeStatus}) => {
  const user = useSelector(store => store.user);
  let statusChangerComponent = <StatusChanger status={data.task_status}/>;

  if (isHighRule || user.id === data?.responsible_user?.id) {
    statusChangerComponent = (
      <StatusChanger
        status={data.task_status}
        onChange={status => changeStatus(status.id)}
      />
    );
  }

  if (!data?.id) return <Skeleton variant="text" animation="wave" width={120}/>;

  return (
    <header className={s.taskHeader}>
      <div className={s.titleWrap}>
        <h3 className={s.title}>{data.name}</h3>
        {statusChangerComponent}
      </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
  data: PropTypes.any,
  isHighRule: PropTypes.bool,
  changeStatus: PropTypes.func
};