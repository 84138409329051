import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import { paths } from "routes/paths";
import ProjectReportContainer from "components/containers/ProjectReportContainer/ProjectReportContainer";

const ProjectReportPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: paths.systemReports,
          name: "System reports",
        },
        {
          name: "Project reports",
        },
      ]}
    >
      <ProjectReportContainer />
    </DefaultLayout>
  );
};

export default ProjectReportPage;
