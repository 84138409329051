import React, {useEffect, useState} from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdownExpiresIn from "./components/FilterBarDropdownExpiresIn/FilterBarDropdownExpiresIn";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import {debounce} from "lodash";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";
import services from "../../../services";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import PropTypes from "prop-types";

const ContractsFilter = ({filters, className}) => {
  const history = useHistory();
  const [savedFilter, setSavedFilter] = useState(null);
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    services.filterService.getFilter("contracts").then(res => {
      if (res.data && res.data[0]) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      }
    });

    services.supplierServices
      .suppliersAutocomplete()
      .then(res => setSuppliers(res.data));
  }, []);

  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  const searchSuppliers = debounce((term, checkedOptions) => {
    services.supplierServices
      .suppliersAutocomplete({query: term})
      .then(res => setSuppliers([...checkedOptions, ...res.data]));
  }, 300);

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = filterName !== "expires_in" ? getAllIds(values) : values;
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleClearAll = () => {
    const searchQuery = {page, tab, sort_by, direction};
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const saveDefaultFilter = () => {
    services.filterService.addFilter("contracts", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  };

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  };

  const showDefaultFilter = filterParams => {
    const preparedFilter = savedFilter?.filter_params || "{}";
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams || preparedFilter),
      page: 1,
      tab,
      sort_by,
      direction,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  return (
    <FilterBar
      filterName="Filter contracts"
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      withSearch
    >
      <FilterBarDropdown
        name="Categories"
        searchPlaceholder="Search category"
        defaultValues={query?.category_ids?.split(",")}
        options={filters.categories}
        handleChange={values => {
          handleChangeFilter("category_ids", values);
        }}
      />
      <FilterBarDropdown
        name="Supplier"
        options={suppliers}
        defaultValues={query?.supplier_ids}
        searchPlaceholder="Search suppliers"
        autocomplete={(e, checkedOptions) => searchSuppliers(e.target.value, checkedOptions)}
        handleChange={values => {
          handleChangeFilter("supplier_ids", values);
        }}
      />
      <FilterBarDropdownExpiresIn
        name="Expires in"
        options={filters.expires_in}
        defaultValue={query?.expires_in}
        handleChange={(values) => {
          handleChangeFilter("expires_in", values);
        }}
      />
    </FilterBar>
  );
};

export default ContractsFilter;
