import React from "react";
import classNames from "classnames";
import s from "./Tab.module.scss";
import {useTranslate} from "../../../../hooks/useTranslate";

const Tab = ({activeTab, label, onClick, styled, className, disabled}) => {
  const [translateKey, translateValue] = useTranslate(label);
  const clickHandler = () => {
    !disabled && onClick(label);
  };
  return (
    <li
      className={classNames(
        s.tab,
        {
          [s.primary]: styled === "primary",
          [s.secondary]: styled === "secondary",
          [s.current]: activeTab === label,
          [s.disabled]: disabled,
        },
        className
      )}
      data-translate-key={translateKey}
      onClick={clickHandler}
    >
      {translateValue}
    </li>
  );
};

export default Tab;
