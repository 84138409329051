import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import {currencyData} from "helpers/helpData";
import {formatCurrency} from "helpers/currencyFormat";
import s from "../FirstLevelCategoriesTable/FirstLevelCategoriesTable.module.scss";

const CategoriesSuppliersSpendTable = ({data, currency}) => {
  const checkForMonth = item => Object.prototype.hasOwnProperty.call(item, "total");
  return (
    <MainTable
      data={data}
      uniqueRowStyle={item => checkForMonth(item) && s.totalSum}
      dataTypes={[
        {
          title: "% of Suppliers",
          value: ({name}) => name || "-",
        },
        {
          title: "Suppliers",
          position: "center",
          value: ({count}) => count || "-",
        },
        {
          title: `Spend`,
          position: "end",
          value: ({spend}) =>
            spend
              ? formatCurrency(
                currencyData.filter((e) => e.label === currency)[0]?.value,
                spend
              )
              : "-",
        },
      ]}
    />
  );
};
export default CategoriesSuppliersSpendTable;
