import React, { useState, Fragment, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import styled from "styled-components";
import {
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TableHead,
} from "@material-ui/core";

import s from "./MainTable.module.scss";
import Button from "components/elements/Button/Button";
import TablePagination from "components/elements/TablePagination/TablePagination";
import { renderHeadCells } from "./helpers/renderHeadCells.js";
import RenderRow from "./RenderRow";
import PropTypes from "prop-types";
import "react-datepicker/src/stylesheets/datepicker.scss";
import { months } from "../ProjectActualSavingsTable/ActualSavingsMonthConst";
import { useTranslate } from "../../../hooks/useTranslate";
import { getTranslateKey } from "../../../helpers/getTranslateKey";
import { useTranslation } from "react-i18next";


const MainTable = (
  {
    style,
    tdStyle,
    dataTypes = [],
    subTypes = [],
    data = [],
    className,
    rowClassName,
    subRowClassName,
    ariaLabel,
    options = null,
    button = {},
    buttons = [],
    totalCount,
    totalName,
    onPageChange,
    perPage,
    isPagination,
    page,
    withXScroll,
    dragIdLabel = null,
    getDragId,
    icon,
    subKey,
    dragWithSort = false,
    headerType = "total",
    titleName,
    useMaxHeight = false,
    useMaxHeightLarge = false,
    uniqueRowStyle,
    startDate,
    onDateClick,
    decreaseMonth,
    increaseMonth,
    stickyHeader = false,
    bottomBtn = null,
    inlineWrapClassName
  }
) => {
  const params = useParams();
  const [translateKey, translateValue] = useTranslate(totalName || "Total");
  const [orderBy, setOrderBy] = useState({ title: "", direction: "" });
  const fakeTable = useRef(null);
  const tableWrap = useRef(null);
  const table = useRef(null);
  const fakeTableContent = useRef(null);
  const [visibleFakeTable, setVisibleFakeTable] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setVisibleFakeTable(table.current?.offsetWidth > tableWrap.current?.offsetWidth);
  }, [table.current, tableWrap.current]);
  const handleScrollFake = () => {
    tableWrap.current.scrollLeft = fakeTable?.current.scrollLeft;
  };
  const handleScroll = () => {
    fakeTable.current.scrollLeft = tableWrap.current.scrollLeft;
  };
  const handleResize = () => {
    setVisibleFakeTable(table.current?.offsetWidth > tableWrap.current?.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (table.current && withXScroll) {
      const width = table.current.offsetWidth;
      fakeTableContent.current.setAttribute("style", `width: ${width}px`);
    }
    if (fakeTable?.current && tableWrap.current && withXScroll) {
      fakeTable.current.addEventListener("scroll", handleScrollFake);
      tableWrap.current.addEventListener("scroll", handleScroll);
      return () => {
        fakeTable.current.removeEventListener("scroll", handleScrollFake);
        tableWrap.current.removeEventListener("scroll", handleScroll);
      };
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [params.id]);

  const renderHeader = () => {
    if (headerType === "title") {
      return <div>{titleName || "Table"}</div>;
    }

    return (
      <>
        <div data-translate-key={translateKey}>{translateValue}</div>
        {startDate && (
          <div className={s.calendarStyle}>
            <button
              aria-label="Previous Month"
              className={"react-datepicker__navigation react-datepicker__navigation--previous"}
              style={{ position: "static", margin: 15 }}
              onClick={decreaseMonth}>
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                }>
                {"<"}
              </span>
            </button>
            <div onClick={onDateClick} className={s.monthSavingMargin}>
              {months.filter((el, index) => index === startDate.getMonth()) +
                " " +
                startDate.getFullYear()}
            </div>
            <button
              aria-label="Next Month"
              className={"react-datepicker__navigation react-datepicker__navigation--next"}
              style={{ position: "static", margin: 15 }}
              onClick={increaseMonth}>
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                }>
                {">"}
              </span>
            </button>
          </div>
        )}{" "}
        <span>{totalCount || data.length}</span>
      </>
    );
  };

  const renderBottomBtn = () => {
    if (bottomBtn)
      return (
        <button
          className={s.bottomBtn}
          onClick={() => bottomBtn.onClick()}
          data-translate-key={getTranslateKey(bottomBtn.title)}>
          {t(getTranslateKey(bottomBtn.title))}
        </button>
      );
  };

  return (
    <Fragment>
      {withXScroll && (
        <div
          className={s.fakeTable}
          ref={fakeTable}
          style={{
            visibility: visibleFakeTable ? "visible" : "hidden",
            height: visibleFakeTable ? "1px" : "0px"
          }}
        >
          <div className={s.content} ref={fakeTableContent}/>
        </div>
      )}
      <div
        className={classNames(s.wrap, {
          [s.scroll]: data.length >= 10,
          [s.withMaxHeight]: useMaxHeight,
          [s.withMaxHeightLarge]: useMaxHeightLarge,
        })}
        style={style}>
        <TableContainer
          className={classNames(
            s.tableContainer,
            {
              [s.xScroll]: withXScroll,
            },
            className,
          )}
          style={style}
          ref={tableWrap}
        >
          <div className={classNames(inlineWrapClassName ? inlineWrapClassName : s.inlineWrap)}>
            <Table stickyHeader={stickyHeader} aria-label={ariaLabel} className={s.table} ref={table}>
              <TableHead className={s.tableHead}>
                <TableRow>
                  <TableCell className={s.totalCounter}>
                    {renderHeader()}
                  </TableCell>
                  {buttons.length
                    ? (<TableCell className={s.tableCell} align="right"
                                  style={{borderBottom: "none", backgroundColor: "white"}}>
                      <ButtonsWrap>
                        {buttons.map((el, index) => (
                          // eslint-disable-next-line react/jsx-key
                          <ButtonHolder>
                            <Button
                              key={index}
                              onClick={() => el.onClick()}
                              styled={el.styled}
                              disabled={el.disabled}
                              className={el.className}>
                              {el.name}
                            </Button>
                          </ButtonHolder>
                        ))}
                      </ButtonsWrap>
                    </TableCell>)
                    : ("")}
                  {button.name
                    ? (<Button
                      onClick={() => button.onClick()}
                      styled={button.styled}
                      disabled={button.disabled}
                      className={classNames(s.button, {
                        [button.className]: button.className
                      })}
                    >
                      {button.name}
                    </Button>)
                    : ("")}

                  {icon && (
                    <TableCell className={s.tableCell} align="right"
                               style={{borderBottom: "none", backgroundColor: "white"}}>
                      <div
                        className={s.icon}
                        onClick={() => icon?.onClick && icon.onClick()}
                      >
                        {icon.icon}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableHead className={s.tableHead}>
                <TableRow>
                  {renderHeadCells(
                    dataTypes.filter((e) => e),
                    orderBy,
                    setOrderBy,
                  )}
                  {options && <TableCell className={s.tableCell}/>}
                </TableRow>
              </TableHead>
              <TableBody className={s.tableBody}>
                {data
                  .filter((e) => e)
                  .map((item, index) => (
                    <RenderRow
                      className={subRowClassName && item.name ? subRowClassName : rowClassName}
                      key={item.id || item.uid || item.Id || item.Uid || index}
                      index={index}
                      dragIdLabel={dragIdLabel}
                      item={item}
                      s={s}
                      dataTypes={dataTypes}
                      data={data}
                      tdStyle={tdStyle}
                      options={options}
                      subKey={subKey}
                      subTypes={subTypes}
                      getDragId={getDragId}
                      dragWithSort={dragWithSort}
                      uniqueRowStyle={uniqueRowStyle}
                    />
                  ))}
              </TableBody>
            </Table>
            {renderBottomBtn()}
          </div>
        </TableContainer>
      </div>
      {totalCount > perPage && isPagination && (
        <div className={s.pagination}>
          <TablePagination
            perPage={perPage}
            count={totalCount}
            onPageChange={onPageChange}
            defaultPage={page || 1}
          />
        </div>
      )}
    </Fragment>
  );
};
export default MainTable;

MainTable.propTypes = {
  style: PropTypes.any,
  tdStyle: PropTypes.any,
  dataTypes: PropTypes.array,
  data: PropTypes.array,
  className: PropTypes.string,
  rowClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  options: PropTypes.any,
  button: PropTypes.any,
  buttons: PropTypes.array,
  totalCount: PropTypes.number,
  totalName: PropTypes.string,
  onPageChange: PropTypes.func,
  perPage: PropTypes.number,
  isPagination: PropTypes.bool,
  page: PropTypes.number,
  withXScroll: PropTypes.bool,
  dragIdLabel: PropTypes.string,
  getDragId: PropTypes.func,
  icon: PropTypes.any,
  subTypes: PropTypes.any,
  subKey: PropTypes.string,
  dragWithSort: PropTypes.bool,
  headerType: PropTypes.string,
  titleName: PropTypes.string,
  useMaxHeight: PropTypes.bool,
  useMaxHeightLarge: PropTypes.bool,
  uniqueRowStyle: PropTypes.func,
};

const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute !important;
  right: 0;
  top: 16px;
  margin-right: 17px;
`;

const ButtonHolder = styled.div`
  margin-left: 8px;
`;
