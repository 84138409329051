import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import s from "./CategoriesBreadCrumbs.module.scss";
import { getCategoryPath } from "./helpers";

const CategoriesBreadCrumbs = ({
  currentCategoryId,
  categories,
  className,
}) => {
  const history = useHistory();
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: -999, y: -999 });
  let tooltipTimeot;
  const [data, setData] = useState([]);
  const length = data.length;

  useEffect(() => {
    setData(getCategoryPath(categories, currentCategoryId));
    setShowTooltip(false);
  }, [categories, currentCategoryId]);

  return (
    <div className={classNames(s.wrapper, className)}>
      {showTooltip && (
        <div
          className={s.tooltip}
          style={{
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
          }}
        >
          {showTooltip}
        </div>
      )}
      {data.map((item, index) => (
        <div key={index} className={s.container}>
          <div
            className={s.item}
            onClick={() => {
              if (index !== length - 1) {
                history.push(`/categories/${item.id}`);
              }
            }}
            onMouseOver={() => {
              if (index !== length - 1) {
                setShowTooltip(item.name);
              }
            }}
            onMouseMove={(e) => {
              if (index !== length - 1) {
                clearTimeout(tooltipTimeot);
                const x = e.nativeEvent.offsetX;
                const y = e.nativeEvent.offsetY;
                setTimeout(() => {
                  setTooltipPosition({
                    x: x + 8,
                    y: y - 45,
                  });
                }, 200);
              }
            }}
            onMouseLeave={() => {
              if (index !== length - 1) {
                setShowTooltip(false);
              }
            }}
          >
            {item.name}
          </div>
          {index !== length - 1 ? <span>/</span> : null}
        </div>
      ))}
    </div>
  );
};

export default CategoriesBreadCrumbs;
