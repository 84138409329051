import React from "react";
import {useHistory} from "react-router-dom";
import s from "./Header.module.scss";
import {paths} from "routes/paths";
import Button from "components/elements/Button/Button";
import {userTypes} from "../../../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import ProjectForm from "../../ProjectForm";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Header = ({isUpdate, titleName}) => {

  const history = useHistory();
  const {t} = useTranslation();

  return (
    <header className={s.header}>
      <h2  className={s.title}  data-translate-key={isUpdate ? "edit-project" : "new-project"}> {titleName!=undefined ? titleName : t(getTranslateKey(isUpdate ? "edit-project" : "new-project"))}</h2>
      <div className={s.btns}>
        <Button
          styled='secondary'
          type='button'
          className={s.btn}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className={s.btn}>
          {isUpdate ? "Update" : "Create"}
        </Button>
      </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
    isUpdate: PropTypes.bool,
    titleName: PropTypes.string,
};