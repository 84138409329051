import React from "react";
import SavingsTypes from "./components/Types/SavingsTypes";
import SavingsGroups from "./components/Groups/SavingsGroups";
import Tabs from "../../elements/Tabs/Tabs";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import General from "./components/General/General";

const AdminSettingsSavingsContainer = () => {
  const {t} = useTranslation();
  return (
    <DefaultLayout breadcrumbs={[
      {name: "Admin Settings", path: "", disabled: true},
      {name: "Savings", path: ""},
    ]}>
      <div className={s.wrap}>
        <h2 className={s.title} data-translate-key={getTranslateKey("savings")}>{t(getTranslateKey("savings"))}</h2>
        <Tabs styled="primary">
          <div label='General'>
            <General/>
          </div>
          <div label="Groups">
            <SavingsGroups/>
          </div>
          <div label="Types">
            <SavingsTypes/>
          </div>
        </Tabs>
      </div>
    </DefaultLayout>
  );
};

export default AdminSettingsSavingsContainer;
