import React, { useEffect } from "react";
// import classNames from "classnames";
import LandingLayout from "../../Landinglayout/Landinglayout";
import Intro from "./components/Intro";
import Content from "./components/Content";
import Point01 from "./components/Point01";
import Point02 from "./components/Point02";
import Point03 from "./components/Point03";
import Point04 from "./components/Point04";
import Point05 from "./components/Point05";
import Point06 from "./components/Point06";
import Point07 from "./components/Point07";
import Point08 from "./components/Point08";
import Point09 from "./components/Point09";
import Point10 from "./components/Point10";
import Point11 from "./components/Point11";
import Point12 from "./components/Point12";
import Point13 from "./components/Point13";
import s from "./PrivacyPage.module.scss";

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <LandingLayout>
      <div className={s.privacyPage}>
        <div className={s.landingContainer}>
          <h1>Privacy notice</h1>
          <p className={s.subtext}>Last updated September 16, 2020</p>

          <div className={s.wrapper}>
            <Intro />

            <Content />

            <Point01 />

            <Point02 />

            <Point03 />

            <Point04 />

            <Point05 />

            <Point06 />

            <Point07 />

            <Point08 />

            <Point09 />

            <Point10 />

            <Point11 />

            <Point12 />

            <Point13 />
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default PrivacyPage;
