import React from "react";
import classNames from "classnames";
import sBase from '../Dashboard.module.scss';
import s from "./DefaultLoading.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";

const DefaultLoading = () => {
  return (
    <div className={classNames(sBase.container, s.defaultContainer)}>
      <Skeleton
        variant="text"
        animation="wave"
        width="100%"
      />
      <Skeleton
        variant="text"
        animation="wave"
        width="75%"
      />
      <Skeleton
        variant="text"
        animation="wave"
        width="50%"
      />
      <Skeleton
        variant="text"
        animation="wave"
        width="25%"
      />
    </div>
  );
};

export default DefaultLoading;


