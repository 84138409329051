import React, { useEffect } from "react";
import s from "./NavButton.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import { setGlobalProjectMessage } from "actions/globalProject";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ErrorIcon } from "assets/icons/error-message.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/success-message.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-message.svg";
import {useTranslate} from "../../../../../../../hooks/useTranslate";

const NavButton = ({ icon, onClick, hint, count, message, active }) => {
  const dispatch = useDispatch();
  const [translateHintKey, translateHintValue] = useTranslate(hint);
  const messageStore = useSelector((state) => state.globalProject.message);
  useEffect(() => {
    if (message?.text) {
      setTimeout(
        () => dispatch(setGlobalProjectMessage({ type: "", text: "" })),
        5000
      );
    }
  }, [message]);
  useEffect(() => {
    if (messageStore?.text?.length) {
      handleCloseMessage();
    }
  }, []);
  const handleCloseMessage = (e) => {
    e?.stopPropagation && e.stopPropagation();
    dispatch(
      setGlobalProjectMessage({
        type: "",
        text: "",
      })
    );
  };
  return (
    <li className={s.navButton} onClick={onClick}>
      <div className={classNames(s.iconWrap, { [s.active]: active })}>
        {icon}
        {+count > 0 && <span className={s.count}>{count}</span>}
      </div>
      {!message?.text && <span className={s.hint} data-translate-key={translateHintKey}>
        {translateHintValue}
      </span>}
      {message?.text && (
        <div className={s.message}>
          {message?.type === "success" ? <SuccessIcon /> : <ErrorIcon />}
          <span className={s.text}>{message?.text}</span>
          <CloseIcon
            className={s.closeBtn}
            onClick={(e) => handleCloseMessage(e)}
          />
        </div>
      )}
    </li>
  );
};
NavButton.propTypes = {
  icon: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  hint: PropTypes.string.isRequired,
  count: PropTypes.number,
  message: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  active: PropTypes.bool,
};

export default NavButton;
