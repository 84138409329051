import React from 'react';
import s from "./Conditions.module.scss";
import {FormControlLabel, Radio} from "@material-ui/core";
import {getOperatorName} from "./helpers/getOperatorName";
import classNames from "classnames";
import Input from "../../../elements/Input/Input";
import {ReactComponent as Success} from "assets/icons/success-green.svg";
import {ReactComponent as NoSuccess} from "assets/icons/no-success-red.svg";
import {conditions, conditionsForNumber} from "../../../../helpers/conditions";

const SingleCondition = ({singleCondition}) => {

  const connector = [...conditions, ...conditionsForNumber]
    .find(connector => connector.value === singleCondition.connector);

  return (
    <div className={classNames(s.singleCondition, {[s.last]: !Boolean(singleCondition.operator)})}>
      <div className={classNames(s.subCondition, s.expectedValue)}>
        <div className={s.valuesWrapper}>
          <Input className={s.input} value={singleCondition.name} withoutChange/>
          <span>{connector?.label}</span>
          <Input className={s.input} value={singleCondition.value} withoutChange/>
        </div>
        {
          singleCondition.operator && (
            <FormControlLabel
              checked={true}
              value={getOperatorName(singleCondition.operator)}
              control={<Radio color="primary"/>}
              label={getOperatorName(singleCondition.operator)}
            />
          )
        }
      </div>
      <div className={classNames(s.subCondition, s.currentValue)}>
        <div className={s.valuesWrapper}>
          <Input className={s.input} value={singleCondition.project_value} withoutChange/>
          {singleCondition.matched ? <Success/> : <NoSuccess/>}
        </div>
      </div>
    </div>
  );
};

export default SingleCondition;