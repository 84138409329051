import React, {useContext} from "react";
import s from "./SingleCondition.module.scss";
import {ReactComponent as TrashIcon} from "../../../../../assets/icons/trash.svg";
import RadioButtons from "../../../../elements/Fields/RadioButtons/RadioButtons";
import PropTypes from "prop-types";
import CustomDropdown from "../../../../elements/CustomDropdown/CustomDropdown";
import {singleConditionTypes} from "../../../../../helpers/approvalsDefaultData";
import {getConditionValues} from "../../../../../helpers/conditionValues";
import CategoriesSelect from "../../../../elements/CustomFields/CategoriesSelect/CategoriesSelect";
import {ModeContext} from "../../../../../contexts/ModeContext/ModeContext";
import {mods} from "../../../../../contexts/ModeContext/mods";
import Input from "../../../../elements/Input/Input";
import SuppliersAutocomplete from "../../../../elements/CustomFields/SuppliersAutocomplete/SuppliersAutocomplete";
import DivisionsSelect from "../../../../elements/CustomFields/DivisionsSelect/DivisionsSelect";
import StakeholdersAutocomplete
  from "../../../../elements/CustomFields/StakeholdersAutocomplete/StakeholdersAutocomplete";
import {conditions, conditionsForNumber} from "../../../../../helpers/conditions";
import {currencyData as currencies} from "../../../../../helpers/helpData";
import CurrencySelectionField from "../../../../elements/CurrencySelectionField/CurrencySelectionField";

const SingleCondition = (
  {
    singleCondition,
    changeSingleCondition,
    deleteSingleCondition,
    isLast,
  }
) => {
  const {mode} = useContext(ModeContext);
  const isViewMode = mode === mods.view;
  const {fieldValue, generalValue, generalCondition} = getConditionValues(singleCondition.value);
  const numberFields = ["Target Savings", "Spend"];
  const renderValueComponent = () => {
    if (fieldValue === "Category") {
      return (
        <CategoriesSelect
          isViewMode={isViewMode}
          defaultValue={generalValue}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
        />
      );
    }
    if (fieldValue === "Division") {
      return (
        <DivisionsSelect
          isViewMode={isViewMode}
          defaultValue={generalValue}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
        />
      );
    }
    if (fieldValue === "Spend" || fieldValue === "Target Savings") {
      const value = parseInt(generalValue) || 0;
      const currency = currencies.find(c => c.label === generalValue.split(value)[1]);

      return (
        <>
          <CurrencySelectionField
            label=""
            options={currencies}
            value={value}
            disableCurrency={isViewMode}
            onChange={({fieldVal, label}) => {
              !isViewMode && changeSingleCondition(singleCondition.id, {value: fieldVal + label}, "value");
            }}
            defaultValue={currency ?? currencies[0]}
          />
        </>
      );
    }
    if (fieldValue === "Supplier") {
      return (
        <SuppliersAutocomplete
          isViewMode={isViewMode}
          defaultValue={generalValue}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
        />
      );
    }
    if (fieldValue === "Stakeholder") {
      return (
        <StakeholdersAutocomplete
          isViewMode={isViewMode}
          defaultValue={generalValue}
          changeSingleCondition={value => changeSingleCondition(singleCondition.id, value, "value")}
        />
      );
    }
  };

  const getCondition = () => {
    let value = (numberFields.includes(fieldValue) ? conditionsForNumber : conditions)
      .find(condition => condition.value === generalCondition);
    if (!value) value = {
      label: "",
      value: "",
    };
    return value;
  };

  return (
    <>
      <div className={s.singleCondition}>
        {
          isViewMode ? <Input value={fieldValue} withoutChange/> : (
            <CustomDropdown
              options={singleConditionTypes}
              value={fieldValue}
              name="field_type"
              onChange={e => changeSingleCondition(singleCondition.id, e, "type")}
              label=""
            />
          )
        }
        {
          isViewMode ? <Input value={getCondition().label} withoutChange/> : (
            <CustomDropdown
              options={numberFields.includes(fieldValue) ? conditionsForNumber : conditions}
              value={getCondition}
              name="condition"
              onChange={e => changeSingleCondition(singleCondition.id, e, "condition")}
              label=""
            />
          )
        }
        {renderValueComponent() ?? <Input/>}
        {
          !isViewMode && (
            <div className={s.deleteSingleConditionBtnWrap}>
              <button className={s.deleteSingleConditionBtn} onClick={e => {
                e.preventDefault();
                deleteSingleCondition(singleCondition.id);
              }}>
                <TrashIcon/>
              </button>
            </div>
          )
        }
      </div>
      {
        !isLast && (
          <RadioButtons
            value={singleCondition.condition}
            change={value => changeSingleCondition(singleCondition.id, value, "radio")}
            isViewMode={isViewMode}
          />
        )
      }
    </>
  );
};

export default SingleCondition;

SingleCondition.propTypes = {
  singleCondition: PropTypes.any,
  changeSingleCondition: PropTypes.func,
  deleteSingleCondition: PropTypes.func,
  isLast: PropTypes.bool,
};