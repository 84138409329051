import React, { useState, useEffect } from "react"
import s from "./CheckListItem.module.scss"
import Checkbox from "components/elements/Checkbox/Checkbox"
import { ReactComponent as Arrow } from "assets/icons/black-arrow-down.svg"
import classNames from "classnames"

const CheckListItem = ({
  item,
  value,
  onChange,
  emptyMessage,
  isSearching = false
}) => {
  const [visibleList, setVisibleList] = useState(false)
  useEffect(() => {
    setVisibleList(isSearching)
  }, [isSearching])
  return (
    <li
      className={classNames(s.dropdownMenuItem, {
        [s.withSubItems]: item.subItems
      })}
    >
      {item.subItems && (
        <Arrow
          className={classNames(s.arrow, { [s.opened]: visibleList })}
          onClick={() => setVisibleList(e => !e)}
        />
      )}
      <Checkbox
        className={s.checkbox}
        label={item.label}
        value={value[item.value]}
        checked={Boolean(value[item.value])}
        onChange={e => {
          onChange(
            { ...value, [item.value]: e.target.checked },
            { ...item, checked: e.target.checked }
          )
        }}
      />
      {item.subItems && visibleList && (
        <ul className={s.subList}>
          {item.subItems.map((subItem, index) => (
            <CheckListItem
              item={subItem}
              key={index}
              value={value}
              onChange={onChange}
              emptyMessage={emptyMessage}
              isSearching={isSearching}
            />
          ))}
        </ul>
      )}
    </li>
  )
}
export default CheckListItem
