import React from 'react'
import Popup from 'components/popups/Popup/Popup'
import EditCompanyGoalForm from 'components/forms/EditCompanyGoalForm/EditCompanyGoalForm'

const EditPopup = ({ onSubmit, onClose, defaultValues, validationData }) => {
	return (
		<Popup
			onClose={onClose}
			title='Edit company goal'
			text={`Please, complete the following fields to edit goals for ${
				defaultValues.source === 'company' ? 'your company' : defaultValues.name
			}`}
		>
			<EditCompanyGoalForm
				onSubmit={onSubmit}
				onClose={onClose}
				defaultValues={defaultValues}
				validationData={validationData}
			/>
		</Popup>
	)
}

export default EditPopup
