import React, { useState } from "react"
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout"
import SurveyContainer from "components/containers/SurveyContainer/SurveyContainer"
import { useProjectsPath } from "hooks/useProjectsPath"

const SurveyPage = () => {
  const projectsPath = useProjectsPath()
  const [breadcrumbs, setBreadcrumbs] = useState([
    { name: "Projects", path: projectsPath }
  ])
  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <SurveyContainer setBreadcrumbs={setBreadcrumbs} />
    </DefaultLayout>
  )
}

export default SurveyPage
