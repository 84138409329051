import {
  approvalTypes,
  approvedParams,
  approverTypes,
  conditionTypes, priorities,
  requestTypes
} from "../approvalsDefaultData";
import {parseToObj} from "../formulaToObj";

const convert = params => ({value: params.id, label: params.name});

const getParam = (value, entityList) => entityList.find(i => i.value === value);

export const prepareFormData = data => ({
  id: data.id,
  name: data.name,
  request_type: getParam(data.request_type, requestTypes),
  project_state: data.project_state ? convert(data.project_state) : null,
  condition_type: getParam(data.approval_condition_type, conditionTypes),
  conditions: parseToObj(data?.original_conditions ?? data.conditions),
  approver: getParam(data.approver_type, approverTypes),
  what_approved: getParam(data.project_tab, approvedParams),
  approval_group: data.approval_group ? convert(data.approval_group) : {},
  approval_type: data.approval_group_type ? getParam(data.approval_group_type, approvalTypes) : {},
  priority: getParam(data.priority, priorities),
})