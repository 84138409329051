import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import {currencyData} from "helpers/helpData";
import {formatCurrency} from "helpers/currencyFormat";
import s from "../FirstLevelCategoriesTable/FirstLevelCategoriesTable.module.scss";

const CategoriesProjectsStatusTable = ({data}) => {
  const checkForMonth = item => Object.prototype.hasOwnProperty.call(item, "total");
  return (
    <MainTable
      data={data}
      uniqueRowStyle={item => checkForMonth(item) && s.totalSum}
      withXScroll={true}
      dataTypes={[
        {
          title: "Status",
          value: ({status}) => status[0].toUpperCase() + status.slice(1)
        },

        {
          title: "Projects",
          position: "center",
          value: ({count}) => count || "-"
        },
        {
          title: "Baseline",
          position: "center",
          value: ({baseline_spend}) =>
            baseline_spend
              ? formatCurrency(
                currencyData.filter(e => e.label === "USD")[0]?.value,
                baseline_spend
              )
              : "-"
        },
        {
          title: "Benefits",
          position: "end",
          value: ({benefits}) =>
            benefits
              ? formatCurrency(
                currencyData.filter(e => e.label === "USD")[0]?.value,
                benefits
              )
              : "-"
        }
      ]}
    />
  );
};
export default CategoriesProjectsStatusTable;
