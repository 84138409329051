export const parserForSelect = data => {
  if (data.length) {
    return data?.map(item => ({value: item.id, label: item.name})) || [];
  }
  return {value: data.id, label: data.name};
};

export const parseFields = data => {
  return data?.map(item => ({
    value: item.id,
    label: item.name,
    type: item.field_type,
    custom_dropdown: item.custom_dropdown
  })) || [];
};