import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./SurveyWidget.module.scss";
import { ReactComponent as IndicatorIcon } from "assets/icons/rating-indicator.svg";

const SurveyWidget = ({ value = 1, onChange, allowed }) => {
  const [width, setWidth] = useState(0);
  const numberRef = useRef(null);

  const getWidth = (el, value) => {
    if (value === 1) {
      return 0;
    }
    if (value === 10) {
      return "100%";
    }
    return el.offsetLeft + el.offsetWidth / 2;
  };

  useEffect(() => {
    numberRef.current && value && setWidth(getWidth(numberRef.current, value));
  }, [value]);

  useEffect(() => {
    const handleResize = () => {
      numberRef.current &&
        value &&
        setWidth(getWidth(numberRef.current, value));
    };

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>How satisfied are you with this project</h4>
      <div className={styles.container}>
        <span className={styles.text}>Bad</span>
        <div className={styles.wrap}>
          <div className={styles.lineWrap}>
            <div className={styles.line} style={{ width }}></div>
          </div>
          <div className={styles.numbers}>
            {Array(10)
              .fill(0)
              .map((_, index) => (
                <span
                  key={index}
                  onClick={() => allowed && onChange(index + 1)}
                  ref={value === index + 1 ? numberRef : null}
                >
                  <div
                    className={classNames(styles.iconWrap, {
                      [styles.showIcon]: value === index + 1,
                    })}
                    data-rate={String(value)}
                  >
                    <IndicatorIcon className={styles.icon} />
                  </div>
                  <p className={allowed ? styles.number : styles.notAllowed}>
                    {index + 1}
                  </p>
                </span>
              ))}
          </div>
        </div>
        <span className={styles.text}>Good</span>
      </div>
    </div>
  );
};

export default SurveyWidget;
