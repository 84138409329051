import React from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll";
import s from '../PrivacyPage.module.scss'

const Content = () => {
	return (
		<div className={s.content}>
              <h3 className={s.contentTitle}>TABLE OF CONTENTS</h3>
              <ol className={s.contentList}>
                <li>
                  <AnchorLink href="#point01">
                    {" "}
                    WHAT INFORMATION DO WE COLLECT?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point02">
                    HOW DO WE USE YOUR INFORMATION?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point03">
                    WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point04">
                    DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point05">
                    HOW LONG DO WE KEEP YOUR INFORMATION?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point06">
                    HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point07">
                    DO WE COLLECT INFORMATION FROM MINORS?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point08">
                    WHAT ARE YOUR PRIVACY RIGHTS?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point09">
                    CONTROLS FOR DO-NOT-TRACK FEATURES
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point10">
                    DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point11">
                    DO WE MAKE UPDATES TO THIS NOTICE?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point12">
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#point13">
                    HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </AnchorLink>
                </li>
              </ol>
            </div>
	)
}

export default Content
