import React from 'react';
import PieChart from "../../../elements/PieChart/PieChart";
import {randomColor} from "../../../../helpers/randomCollor";
import {currencyData} from "../../../../helpers/helpData";
import styles from "./SavingWidget.module.scss";
import {trimRest} from "../../../../helpers/stringHelper";

const SavingWidget = (
  {
    data,
    title,
    type,
    typeWidget,
    currency = "$",
    finalSaving,
    className
  }
) => {

  const preparedData = data.map(item => [item[type]?.name, Math.round(item.split_sum)]);
  const preparedColors = data.map(_ => randomColor());
  const preparedCurrency = currencyData.filter(e => e.label === currency)[0]?.value;

  const renderDataWidget = (data, index) => {
    if (typeWidget === "dots") {
      return (
        <div className={styles.dotsList}>
          <div className={styles.dotsWrapper}>
            <div style={{background: preparedColors[index]}} className={styles.dot}/>
            <div title={data[0]}>{trimRest(120, data[0])}</div>
          </div>
        </div>
      )
    }

    return (
      <div className={styles.singleInfo}>
        <div className={styles.header}>
          <p title={data[0]}>{trimRest(12, data[0])}</p>
          <p>{preparedCurrency ?? "$"} {data[1]}</p>
        </div>
        <div className={styles.progress}>
          <div style={{
            background: preparedColors[index],
            width: `${data[1] * 100 / finalSaving}%`
          }}/>
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      <h4>{title}</h4>
      <div className={styles.widgetWrap}>
        <PieChart
          data={[
            ["Total savings", String(finalSaving)],
            ...preparedData,
          ]}
          chartWidth="170px"
          withLegend={false}
          defaultCurrency={preparedCurrency}
          colors={preparedColors}
        />
        <div className={styles.infoWrap}>
          {preparedData.map((data, index) => renderDataWidget(data, index))}
        </div>
      </div>
    </div>
  );
};

export default SavingWidget;