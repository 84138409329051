import React from "react"
import { useHistory } from "react-router-dom"
import qs from "query-string"
import MainTable from "../MainTable/MainTable"
import { projectTypes } from "helpers/helpData"

const AdminSettingsProjectsGroupsTable = ({
  data,
  onAdd,
  onDelete,
  onEdit
}) => {
  const history = useHistory()
  const query = qs.parse(history.location.search)

  return (
    <MainTable
      totalName='Project groups'
      data={data}
      dataTypes={[
        {
          title: "Group",
          value: ({ name }) => name
        },
        {
          title: "Project types",
          value: ({ project_types }) =>
            project_types.length
              ? project_types.map(type => type.name).join(", ")
              : "-"
        }
      ]}
      options={item => {
        return [
          onEdit && {
            title: "Edit",
            onClick: () => {
              onEdit(item)
            }
          },
          onDelete && {
            title: "Delete",
            onClick: () => {
              onDelete(item)
            }
          }
        ]
      }}
      button={{
        name: "New group",
        onClick: () => onAdd()
      }}
      totalCount={data.length}
    />
  )
}

export default AdminSettingsProjectsGroupsTable
