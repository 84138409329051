import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import RequestsContainer from "components/containers/BusinessProjectRequestsContainer/RequestsContainer";

const RequestsPage = () => {
  return (
    <DefaultLayout>
      <RequestsContainer/>
    </DefaultLayout>
  );
};
export default RequestsPage;
