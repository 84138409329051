import React from 'react';
import s from "./GlobalContextMenu.module.scss";

const GlobalContextTranslateMenu = ({x, y, click, close}) => {
  return (
    <div
      onMouseLeave={close}
      className={s.globalContextTranslateMenu}
      id="context-translate-menu"
      style={{
        top: y - 35,
        left: x,
      }}
    >
      <div className={s.change} onClick={click}>
        Change translation
      </div>
    </div>
  );
};

export default GlobalContextTranslateMenu;