export const statuses = [
  {
    value: "approved",
    label: "Approved",
    color: "#83A755"
  },
  {
    value: "requires_clarification",
    label: "Requires clarification",
    color: "#F29239"
  },
  {
    value: "rejected",
    label: "Rejected",
    color: "#DE4935"
  },
]