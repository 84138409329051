import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import { paths } from "routes/paths";
import CreateRequestContainer from "components/containers/CreateRequestContainer/CreateRequestContainer";

const CreateRequestPage = () => {
	return (
		<DefaultLayout
			breadcrumbs={[
				{
					path: paths.requests,
					name: "Requests"
				},
				{ name: "New request" }
			]}
		>
			<CreateRequestContainer />
		</DefaultLayout>
	);
};
export default CreateRequestPage;
