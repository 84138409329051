import React from "react";
import Popup from "components/popups/Popup/Popup";
import AdminSettingsProjectGroupForm from "components/forms/AdminSettingsProjectGroupForm/AdminSettingsProjectGroupForm";

const AddProjectGroupPopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      onClose={onClose}
      title="New project group"
      text=" Please, complete the following fields to create a new project group"
    >
      <AdminSettingsProjectGroupForm onClose={onClose} onSubmit={onSubmit} />
    </Popup>
  );
};

export default AddProjectGroupPopup;
