import React from "react";
import s from "./RequestDetail.module.scss";
import PropTypes from "prop-types";
import {divideFields} from "../../helpers/divideFields";
import {userRoles} from "../../../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import Button from "../../../../elements/Button/Button";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const RequestDetail = ({data, onReject, onAssign, onComplete}) => {
  const {admin} = userRoles;
  const user = useSelector(state => state.user);
  const history = useHistory();
  const [firstPart, secondPart] = divideFields(data.fields);
  const {t} = useTranslation();

  const renderButtons = () => {
    if (data.status === "rejected") return "";

    const rejectBtn = (
      <Button styled="secondary" onClick={onReject}>
        Reject
      </Button>
    );

    const assignBtn = (
      <Button styled='primary' onClick={onAssign}>
        {data.status === "pending" ? "Assign" : "Reassign"}
      </Button>
    );

    const completeBtn = (
      <Button styled="secondary" onClick={onComplete}>
        Complete
      </Button>
    );

    const createProject = (
      <Button
        styled='primary'
        onClick={() => history.push(`/create/projects/?request_id=${data.id}`)}
      >
        Create project
      </Button>
    );

    const triageCondition = user.roles.includes(admin) || user.triage || user.triage_manager;
    const procurementCondition = data.assignee?.id === user.id && data.status === "in_progress";

    if (triageCondition && procurementCondition) {
      return (
        <>
          {rejectBtn}
          {assignBtn}
          {completeBtn}
          {!data.project?.id && createProject}
        </>
      )
    }

    if (triageCondition) {
      if (data.status === "pending" || data.status === "in_progress") {
        return (
          <>
            {rejectBtn}
            {assignBtn}
          </>
        );
      }
    }

    if (procurementCondition) {
      return (
        <>
          {completeBtn}
          {createProject}
        </>
      );
    }
  };

  const getFieldValue = value => {
    if (!value) return <span className={s.noData}
                             data-translate-key={"no-data"}> {t(getTranslateKey("no-data"))}</span>;
    if (typeof value === "object") return value.name;
    else return value;
  };

  return (
    <div className={s.detailWrap}>
      <div className={s.inline}>
        <ul className={s.col}>
          {firstPart.map(field => (
            <li key={field.id} className={s.item}>
              <span className={s.label}>{field.name}</span>
              <span className={s.value}>{getFieldValue(field.value)}</span>
            </li>
          ))}
          <div className={s.item}>
            <span className={s.label} data-translate-key={getTranslateKey("Attachment")}>
              {t(getTranslateKey("Attachment"))}:
            </span>
            <span className={s.attachments}>
								{data.attachments?.length
                  ? data.attachments.map((attachment, index) => (
                    <a
                      key={index}
                      href={attachment.path}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={s.attachment}
                    >
                      {attachment.name} {index !== data.attachments.length - 1 ? "," : ""}
                    </a>
                  ))
                  : "-"}
							</span>
          </div>
        </ul>
        <ul className={s.col}>
          {secondPart.map(field => (
            <li key={field.id} className={s.item}>
              <span className={s.label}>{field.name}</span>
              <span className={s.value}>{getFieldValue(field.value)}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className={s.item}>
        <span className={s.label} data-translate-key={getTranslateKey("Description")}>
          {t(getTranslateKey("Description"))}:
        </span>
        <span className={s.value}>{data.description}</span>
      </div>
      <div className={s.btns}>
        {renderButtons()}
      </div>
    </div>
  );
};

RequestDetail.propTypes = {
  data: PropTypes.object,
  onReject: PropTypes.func,
  onAssign: PropTypes.func,
  onComplete: PropTypes.func,
};

export default RequestDetail;
