import React, {useEffect, useState} from "react";
import s from "../FinancialBenefitsForm.module.scss";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import {ReactComponent as ArrowDown} from "../../../../assets/icons/arrowDown.svg";
import classNames from "classnames";
import services from "../../../../services";
import {userRoles} from "../../../../helpers/usersData";
import {entitiesFormatter} from "../../../../helpers/entitiesFormatter";
import {userTypes} from "../../../../helpers/userPermissions/userRoles";
import RSSelect from "../../../elements/ReactSelectCustom/RSSelect";
import Input from "../../../elements/Input/Input";
import {ReactComponent as Clear} from "assets/icons/clear.svg";
import {generateUniqueId} from "../../../../helpers/uniqueId";

const SplitSavingBox = (
  {
    type,
    values,
    setFieldValue,
    currency,
    final_savings,
    errors
  }
) => {

  const {t} = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const {procurement} = userTypes;
  const options = entities.filter(entity => !values.map(val => val.entity?.value).includes(entity?.value));

  const procurementUserRoles = userRoles
    .filter(role => (role.userType.value === procurement))
    .map(role => role.value)
    .join(",");

  useEffect(() => {
    if (type === "departments") {
      services.userServices.getDepartments().then(res => {
        if (res.data) {
          setEntities(res.data.map(e => entitiesFormatter(e)));
        }
      });
    }

    if (type === "divisions") {
      services.userServices.getDivisions().then(res => {
        if (res.data) {
          setEntities(res.data.map(e => entitiesFormatter(e)));
        }
      });
    }

    if (type === "users") {
      services.userServices.stakeholdersAutocomplete({roles: procurementUserRoles}).then(res => {
        if (res.data) {
          setEntities(res.data.map(e => entitiesFormatter(e)));
        }
      });
    }
  }, []);

  const getTitle = () => {
    if (type === "departments") return "by Departments";
    if (type === "divisions") return "by Divisions";
    if (type === "users") return "by Procurement users";
  };

  const getTitleBtn = () => {
    if (type === "departments") return "+ Add new department";
    if (type === "divisions") return "+ Add new division";
    if (type === "users") return "+ Add new procurement user";
  };

  const addSplitSaving = () => {
    setFieldValue(type, [...values, {entity: null, value: 0, percent: 0, id: generateUniqueId()}]);
  };

  const changeEntity = (entity, id) => {
    setFieldValue(type, values.map(value => {
      if (value.id === id) return {...value, entity};
      return value;
    }));
  };

  const changeValue = (val, id) => {
    setFieldValue(type, values.map(value => {
      if (value.id === id) return {
        ...value,
        value: val,
        percent: parseFloat((val * 100 / final_savings) || 0).toFixed(2)
      };
      return value;
    }));
  };

  const changePercent = (percent, id) => {
    setFieldValue(type, values.map(value => {
      if (value.id === id) return {
        ...value,
        value: parseFloat(percent / 100 * final_savings).toFixed(2),
        percent,
      };
      return value;
    }));
  };

  const deleteSplitSaving = id => {
    setFieldValue(type, values.filter(value => value.id !== id));
  };

  return (
    <div className={classNames(s.splitSavingBox, {[s.opened]: isOpen})}>
      <header onClick={() => setOpen(!isOpen)}>
        <h4 data-translate-key={getTranslateKey(getTitle())}>
          {t(getTranslateKey(getTitle()))}
        </h4>
        <ArrowDown/>
      </header>
      {isOpen && (
        <div className={s.values}>
          <div className={s.listValues}>
            {values.map(item => {
              return (
                <div className={s.item} key={item.id}>
                  <div className={s.field}>
                    <RSSelect
                      defaultValue={item.entity}
                      onChange={option => changeEntity(option, item.id)}
                      options={options}
                      placeholder={`Choose ${type.slice(0, -1)}`}
                    />
                  </div>
                  <span>has</span>
                  <div className={s.field}>
                    <Input
                      type="number"
                      label=""
                      value={item.value}
                      onChange={e => {
                        const val = e.target.value;
                        if (Number(val) <= final_savings) {
                          changeValue(val, item.id);
                        }
                      }}
                    />
                    <div className={s.label}>
                      {currency.label}
                    </div>
                  </div>
                  <span>or</span>
                  <div className={s.field}>
                    <Input
                      type="number"
                      label=""
                      value={item.percent}
                      onChange={e => {
                        const val = e.target.value;
                        if (Number(val) <= 100) {
                          changePercent(val, item.id);
                        }
                      }}
                    />
                    <div className={s.label}>
                      %
                    </div>
                  </div>
                  <Clear onClick={() => deleteSplitSaving(item.id)}/>
                </div>
              );
            })}
          </div>
          <button
            onClick={addSplitSaving}
            className={s.addNewBtn}
            type="button"
          >
            {getTitleBtn()}
          </button>
        </div>
      )}
      {errors[type] && (
        <p className={s.error} data-translate-key={getTranslateKey(errors[type])}>
          {t(getTranslateKey(errors[type]))}
        </p>
      )}
    </div>
  );
};

export default SplitSavingBox;