import React from "react";
import s from "./ContractValue.module.scss";
import sBase from "../../ContractDetailsContainer.module.scss";
import Wrapper from "../Wrapper/Wrapper";
import {currencyData} from "../../../../../helpers/helpData";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const ContractValue = ({params}) => {
  const {t} = useTranslation();
  const {total_value, total_value_currency, annual_value, annual_value_currency} = params;

  return (
    <Wrapper>
      <div className={s.header}>
        <h4 className={sBase.sectionTitle} data-translate-key={getTranslateKey("Contract value")}>
          {t(getTranslateKey("Contract value"))}
        </h4>
      </div>
      <div className={s.infoWrap}>
        <div className={s.infoBlock}>
          <p className={s.title} data-translate-key={getTranslateKey("Total contract value")}>
            {t(getTranslateKey("Total contract value"))}
          </p>
          <p className={s.value}>
            {`${currencyData.find(c => c.label === total_value_currency)?.value} ${total_value.toLocaleString("en-US", {
              maximumFractionDigits: 0
            })}`}
          </p>
        </div>
        <div className={s.infoBlock}>
          <p className={s.title} data-translate-key={getTranslateKey("Annual contract value")}>
            {t(getTranslateKey("Annual contract value"))}
          </p>
          <p className={s.value}>
            {`${currencyData.find(c => c.label === annual_value_currency)?.value} ${annual_value.toLocaleString("en-US", {
              maximumFractionDigits: 0
            })}`}
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default ContractValue;
