import React, {useEffect, useState} from "react";
import services from "services/index";
import sBase from "../MainDashboard.module.scss";
import {getTitle} from "../../helpers/dataFormat";
import s from "./StaticWidgetsForTeam.module.scss";
import classNames from "classnames";
import WidgetsLoading from "../../../../../loadingStates/Dashboard/Widgets/WidgetsLoading";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import qs from "query-string";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const StaticWidgetsForTeam = () => {
  const [staticData, setStaticData] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const {t} = useTranslation();
  const {projects, resource_utilization, net_promoter_score} = staticData;
  const {team_id} = qs.parse(
    history.location.search
  );

  useEffect(() => {
    setLoading(true);
    services.dashboardServices.getStatistic({view: "team", team_id}).then((res) => {
      setStaticData(res.data);
    });
    setLoading(false);
  }, [team_id]);

  const getProjects = () => {
    const prepareProjects = Object.keys(projects ?? {});
    if (prepareProjects.length) {
      return (
        <ul className={s.widgetList}>
          {
            prepareProjects.map((projectKey, index) => {
              return (
                <li key={index}>
                  <span className={s.title}>
                    <span data-translate-key={getTranslateKey(projectKey)}>
										  {t(getTranslateKey(projectKey))}
									  </span>
                    {` - ${projects[projectKey].count}`}
                  </span>
                  <span
                    className={classNames(s.value, s.green)}>{`$ ${projects[projectKey].value?.toLocaleString("en-US", {maximumFractionDigits: 0})}`}</span>
                </li>
              )
            })
          }
        </ul>
      );
    }
    return <EmptyContent padding={0} text='You don’t have projects yet.'/>;
  }

  const getProjectsResourceUtilization = () => {
    const prepareResourceUtilization = Object.keys(resource_utilization ?? {});
    if (prepareResourceUtilization.length) {
      return (
        <ul className={s.widgetList}>
          {
            prepareResourceUtilization.map((projectKey, index) => {
              const translateKey = `${projectKey > 1 ? 'Next month' : 'This month'}`;
              return (
                <li key={index}>
                  <span className={s.title}>
                    <span data-translate-key={getTranslateKey(translateKey)}>
										  {t(getTranslateKey(translateKey))}
									  </span>
                    {` - ${resource_utilization[projectKey].count}`}
                  </span>
                  <span
                    className={classNames(s.value, s.green)}>{`${resource_utilization[projectKey].value}%`}</span>
                </li>
              )
            })
          }
        </ul>
      );
    }
    return <EmptyContent padding={0} text='You don’t have projects yet.'/>;
  }

  const getNetPromoterScore = () => {
    const prepareNetPromoterScore = Object.keys(net_promoter_score ?? {});
    if (prepareNetPromoterScore.length) {
      return (
        <ul className={s.widgetList}>
          {
            prepareNetPromoterScore.map((projectKey, index) => {
              const translateKey = `${projectKey > 1 ? `Last ${projectKey} month` : 'Last month'}`;
              return (
                <li key={index}>
                  <span className={s.title}>
                    <span data-translate-key={getTranslateKey(translateKey)}>
										  {t(getTranslateKey(translateKey))}
									  </span>
                    {` - ${net_promoter_score[projectKey].all}`}
                  </span>
                  <span
                    className={classNames(s.value, s.yellow)}>{`${net_promoter_score[projectKey].done}`}</span>
                </li>
              )
            })
          }
        </ul>
      );
    }
    return <EmptyContent padding={0} text='You don’t have NPS yet.'/>;
  }

  return (
    <div className={s.widgetsWrapper}>
      <section className={sBase.wrapper}>
        {
          loading ? <WidgetsLoading/> :
            <>
              <h4 className={sBase.title} data-translate-key={getTitle('widgetProjects')}>
                {t(getTranslateKey(getTitle('widgetProjects')))}
              </h4>
              {getProjects()}
            </>
        }
      </section>
      <section className={sBase.wrapper}>
        {
          loading ? <WidgetsLoading/> :
            <>
              <h4 className={sBase.title} data-translate-key={getTitle('widgetResourceUtilization')}>
                {t(getTranslateKey(getTitle('widgetResourceUtilization')))}
              </h4>
              {getProjectsResourceUtilization()}
            </>
        }
      </section>
      <section className={sBase.wrapper}>
        {
          loading ? <WidgetsLoading/> :
            <>
              <h4 className={sBase.title} data-translate-key={getTitle('widgetNetPromoterScore')}>
                {t(getTranslateKey(getTitle('widgetNetPromoterScore')))}
              </h4>
              {getNetPromoterScore()}
            </>
        }
      </section>
    </div>
  );
};

export default StaticWidgetsForTeam;


