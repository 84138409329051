import React from "react";
import PropTypes from "prop-types";
import Textarea from "../../../../../elements/Textarea/Textarea";

const DescriptionField = ({values, setFieldValue, field, errors}) => {
  return (
    <Textarea
      value={values[field.key]?.value || ""}
      label={field.label}
      onChange={e => setFieldValue(field.key, {value: e.target.value, type: "Description", conditions: field.conditions})}
      placeholder='Enter description'
      error={errors[field.key] && errors[field.key].value}
      count={{
        current: (values[field.key]?.value ?? "").length,
        max: 180
      }}
    />
  );
};

export default DescriptionField;

DescriptionField.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any
};