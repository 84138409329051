import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {setCategories} from "actions/categories";
import styles from "./CategoriesContainer.module.scss";
import SpendTMT from "./components/SpendTMT/SpendTMT";
import FirstLevelCategories from "./components/FirstLevelCategories/FirstLevelCategories";
import CategoriesSuppliers from "./components/CategoriesSuppliers/CategoriesSuppliers";
import CategoriesContractsExpirations from "./components/CategoriesContractsExpirations/CategoriesContractsExpirations";
import CategoriesProjects from "./components/CategoriesProjects/CategoriesProjects";
import services from "services";
import CategoriesFilter from "components/filters/CategoriesFilter/CategoriesFilter";
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar";
import qs from "query-string";
import {useHistory} from "react-router-dom";
import {
  getAllIds,
  formatFilters
} from "components/elements/FilterBar/helpers/helpers";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const CategoriesContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const [filters, setFilters] = useState(null);
  const [categoryIdsFilter, setCategoryIdsFilter] = useState("");
  const query = qs.parse(history.location.search);
  const containerRef = useRef();

  useEffect(() => {
    dispatch(setCategories());
    services.categoriesServices.getCategoriesFilters().then(res => {
      setFilters(
        formatFilters({
          categories: res.data.categories,
          spend_years: res.data.spend_years
        })
      );
    });
  }, []);

  const handleChangeFilters = (filterName, values) => {
    if (filterName === "category_ids") {
      const valuesIds = getAllIds(values);
      setCategoryIdsFilter(valuesIds.join());
    } else {
      const {...spread} = query;
      const value = Array.isArray(values) ? values : [values];
      const valuesIds = getAllIds(value);
      const searchQuery = {
        ...spread,
        [filterName]: valuesIds
      };

      history.push({
        search: qs.stringify(searchQuery, {arrayFormat: "comma"})
      });
    }
  };

  return (
    <section className={styles.categoriesContainer}>
      <div className={styles.container} ref={containerRef}>
        <h2 className={styles.title} data-translate-key={getTranslateKey("Categories")}>
          {t(getTranslateKey("Categories"))}
        </h2>
        {filters ? (
          <CategoriesFilter
            className={styles.filterbar}
            filters={filters}
            onFiltersChange={handleChangeFilters}
            categoryIdsFilter={categoryIdsFilter}
            afterFilterChangeAction={() => {
              const currentScrollPosition = containerRef.current.scrollTop;
              containerRef.current.scrollTo(0, currentScrollPosition);
            }}
          />
        ) : (
          <FilterBarLoading className={styles.filterbar} length={2}/>
        )}
        <div className={styles.row}>
          <SpendTMT categoryIdsFilter={categoryIdsFilter}/>
          <FirstLevelCategories
            categories={filters?.categories}
            categoryIdsFilter={categoryIdsFilter}
            updateCategoryIds={ids => setCategoryIdsFilter(ids.join())}
          />
        </div>
        <div className={styles.row}>
          <CategoriesSuppliers categoryIdsFilter={categoryIdsFilter}/>
        </div>
        <div className={styles.row}>
          <CategoriesContractsExpirations categoryIdsFilter={categoryIdsFilter}/>
        </div>
        <div className={styles.row}>
          <CategoriesProjects categoryIdsFilter={categoryIdsFilter}/>
        </div>
      </div>
    </section>
  );
};

export default CategoriesContainer;
