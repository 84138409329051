import axios from "./axios";

export default {
  sendContactUsForm: async ({
    firstName,
    lastName,
    email,
    company,
    industry,
    role,
    message,
  }) => {
    try {
      const fields = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        company: company,
        industry: industry,
        role: role.value,
        question: message,
      };

      return await axios.post("/contact_messages", fields);
    } catch (e) {

    }
  },
};
