import  React from "react";
import s from "./SidebarDashboard.module.scss";
import Messages from "./Messages/Messages";
import Activities from "./Activities/Activities";
import LastActivities from "./LastActivities/LastActivities";

const SidebarDashboard = ({isSupportUser}) => {
  return (
    <div className={s.sidebar}>
      <div className={s.messages}>
        <Messages/>
      </div>
      {!isSupportUser && <Activities/>}
      {isSupportUser && <LastActivities/>}
    </div>
  );
};

export default SidebarDashboard;


