import React, {Fragment, useContext, useEffect, useState} from "react";
import FinancialBenefitsContainer from "./FinancialBenefits/FinancialBenefitsContainer";
import NonFinancialBenefits from "./NonFinancialBenefits/NonFinancialBenefits";
import CommentContainer from "components/elements/Comment/CommentContainer";
import services from "services";
import {useParams} from "react-router-dom";
import ActualSavings from "./ActualSavings/ActualSavings";
import {cancelRequest} from "../../../../../services/axios";
import AlertContext from "../../../../../contexts/AlertContext/AlertContext";
import {months} from "../../../../tables/ProjectActualSavingsTable/ActualSavingsMonthConst";
import CalendarActualSavings from "./CalendarActualSavings";

const ProjectResults = ({data}) => {
  const params = useParams();
  const handleChangeComment = async (data) => {
    await services.projectServices.editProject(params.id, data);
  };
  const {setAlert, getCancelId} = useContext(AlertContext);
  const [benefits, setBenefits] = useState([]);
  const [benefitsByYear, setBenefitsByYear] = useState({});
  const [loading, setLoading] = useState(true);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [currentMonth, setCurrentMonth] = useState({months: months.filter((el, index) => index === new Date().getMonth())});
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [permissions, setPermissions] = useState({
    update: false,
    destroy: false
  });
  const [actualSavings, setActualSavings] = useState([]);
  const currentYear = new Date().getFullYear();
  const editActualSavingsGroup = (values) => {
    services.projectServices.putProjectActualSavingsGroup(params.id, values)
      .then(res => {
        if (res.data)
          services.projectServices.getProjectActualSavingsAll(params.id, currentMonth.months[0], currentYear)
            .then(res => {
              setActualSavings(res.data[currentYear] ? res.data[currentYear].savings : []);
            });
      });
    setShow(false);
  };

  const newActualSavingsGroup = (values) => {
    services.projectServices.putProjectActualSavings(params.id, values)
      .then(res => {
        if (res.data)
          services.projectServices.getProjectActualSavingsAll(params.id, currentMonth.months[0], currentYear)
            .then(res => {
              setActualSavings(res.data[currentYear] ? res.data[currentYear].savings : []);
              setAlert({
                type: "success",
                message: "Settings successfully changed."
              });
            });
      })
      .catch(err =>
        setAlert({
          message:
            err.response?.data.error ||
            "Record with this group, month and year already exist",
          type: "error"
        })
      );

    setShow(false);
  };

  useEffect(() => {
    services.financialBenefitsServises
      .getFinancialBenefits(params.id)
      .then(res => {
        setBenefits(res.data.financial_benefits);
        setBenefitsByYear(res.data.financial_benefits_by_year);
        setDefaultCurrency(res.data.default_currency);
        setPermissions({
          update: res.data.permissions?.update || false,
          destroy: res.data.permissions?.destroy || false
        });
        setLoading(false);
      });

  }, [params.id]);
  useEffect(() => {
    !loading &&
    services.financialBenefitsServises
      .getFinancialBenefits(params.id)
      .then(res => {
        setDefaultCurrency(res.data.default_currency);
        setBenefitsByYear(res.data.financial_benefits_by_year);
      });
    services.projectServices.getProjectActualSavingsAll(params.id, currentMonth.months[0], currentYear)
      .then(res => {
        setActualSavings(res.data[currentYear] ? res.data[currentYear].savings : []);
      });
  }, [benefits]);
  const appendFile = file => {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
  };
  const addHandler = ({attachments, ...spread}) => {
    services.financialBenefitsServises
      .createFinancialBenefit(params.id, {...spread})
      .then(res => {
        const benefit = res?.data;
        setAlert({
          message: "Financial benefit successfully added."
        });
        setBenefits([...benefits, benefit]);
        for (let i = 0; i < attachments.length; i++) {
          const file = appendFile(attachments[i]);
          benefit.id &&
          services.taskServices.addAttachment(file, benefit.id).then(res => {
            const attachment = res?.data;
            attachment &&
            setBenefits(benefits =>
              benefits.map(e =>
                e.id === benefit.id
                  ? {
                    ...e,
                    attachments: [...e.attachments, attachment]
                  }
                  : e
              )
            );
          });
        }
      })
      .catch(() =>
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      );
  };
  const deleteHandler = id => {
    const cancelId = getCancelId();
    const oldBenefits = benefits;

    setAlert({
      message: "Financial benefit successfully deleted.",
      onCancel: () => {
        cancelRequest(cancelId);
        setBenefits(oldBenefits);
      }
    });
    services.financialBenefitsServises
      .deleteFinancialBenefit(params.id, id, {cancelId})
      .then(res => {
        if (res.status < 300) setBenefits(benefits.filter(item => item.id !== id));
      })
      .catch(() => {
        setBenefits(oldBenefits);
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };
  const handleDateClick = () => {
    setShowCalendar(!showCalendar);
  };
  const handleDateSelect = (date) => {
    const calendarData = {months: months.filter((el, index) => index === date.getMonth())};
    setShowCalendar(false);
    if (currentMonth.months[0] !== calendarData.months[0]) {
      setCurrentMonth(calendarData);
      services.projectServices.getProjectActualSavingsAll(params.id, months.filter((el, index) => index === date.getMonth())[0], date.getFullYear())
        .then(res => {
          setActualSavings(res.data[date.getFullYear()] ? res.data[date.getFullYear()].savings : []);
        });
    }
    setStartDate(date);
  };
  const handleIncreaseMonth = () => {
    const newStartDate = new Date(startDate);
    newStartDate.setMonth(newStartDate.getMonth() + 1);
    handleDateSelect(newStartDate);
  };
  const handleDecreaseMonth = () => {
    const newStartDate = new Date(startDate);
    newStartDate.setMonth(newStartDate.getMonth() - 1);
    handleDateSelect(newStartDate);
  };
  return (
    <Fragment>
      <FinancialBenefitsContainer
        loading={loading}
        addHandler={addHandler}
        defaultCurrency={defaultCurrency}
        benefitsByYear={benefitsByYear}
        deleteHandler={deleteHandler}
        permissions={permissions}
        benefits={benefits}
        data={data}
      />
      <ActualSavings
        onDateClick={handleDateClick}
        startDate={startDate}
        actualSavings={actualSavings}
        benefits={benefits}
        editActualSavingsGroup={editActualSavingsGroup}
        newActualSavingsGroup={newActualSavingsGroup}
        decreaseMonth={handleDecreaseMonth}
        increaseMonth={handleIncreaseMonth}
        savingsGr={benefitsByYear}
        owner={data?.owner?.id}
      />
      {showCalendar &&
        <CalendarActualSavings
          increaseMonth={handleIncreaseMonth}
          decreaseMonth={handleDecreaseMonth}
          startDate={startDate}
          onDateSelect={handleDateSelect}
        />}
      <NonFinancialBenefits/>
      <CommentContainer
        comment={data.results_comment}
        owner={data?.owner?.id}
        onAddComment={handleChangeComment}
      />
    </Fragment>
  );
};

export default ProjectResults;