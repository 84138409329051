import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import UserDetailsContainer from "components/containers/UserDetailsContainer/UserDetailsContainer";
import {paths} from "../../../routes/paths";

const UserDetailsPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        { name: "Users", path: paths.users },
        { name: "User details", path: "" },
      ]}
    >
      <UserDetailsContainer />
    </DefaultLayout>
  );
};

export default UserDetailsPage;
