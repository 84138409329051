import React from "react";
import s from "./Divider.module.scss";

const Divider = (
  {
    background,
    margin,
  }
) => {
  return <div
    style={{
      background: background || '#EAEAEA',
      marginTop: margin || 0,
      marginBottom: margin || 0
    }}
    className={s.divider}/>;
};

export default Divider;
