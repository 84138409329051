import React from "react";
import {useDispatch, useSelector} from "react-redux";
import CustomLink from "components/elements/CustomLink/CustomLink";
import {ReactComponent as Logo} from "assets/icons/logo.svg";
import HeaderNotificationsContainer from "./components/HeaderNotificationsContainer/HeaderNotificationsContainer";
import HeaderUserMenu from "./components/HeaderUserMenu/HeaderUserMenu";
import Breadcrumbs from "components/elements/Breadcrumbs/Breadcrumbs";
import s from "./Header.module.scss";
import {Switch} from "@material-ui/core";
import {changeTranslationMod} from "../../../actions/globalSettings";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Header = ({breadcrumbs}) => {
  const {name: userName, roles} = useSelector(state => state.user);
  const isEnableTranslationMod = useSelector(state => state.globalSettings.isEnableTranslationMod);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const changeTranslationModHandler = value => dispatch(changeTranslationMod(value));

  const isProcurementAdmin = roles.includes(userRoles.procurement_admin);

  return (
    <header className={s.header}>
      <div className={s.container}>
        <CustomLink
          className={s.logo}
          type="nav-link" path="/"
          onClick={() => window.sessionStorage.removeItem("currentMenuTab")}
        >
          <Logo/>
        </CustomLink>
        <Breadcrumbs className={s.breadcrumbs} data={breadcrumbs}/>
      </div>
      <div className={s.headerControl}>
        {isProcurementAdmin && (
          <div className={s.translationMod}>
            <Switch
              checked={isEnableTranslationMod}
              onChange={e => changeTranslationModHandler(e.target.checked)}
              color="primary"
              inputProps={{'aria-label': 'controlled'}}
            />
            <span data-translate-key={getTranslateKey("Translation mode")}>
              {t(getTranslateKey("Translation mode"))}
            </span>
          </div>
        )}
        <div className={s.notifications}>
          <HeaderNotificationsContainer/>
        </div>
        <div className={s.userMenu}>
          <HeaderUserMenu userName={userName}/>
        </div>
      </div>
    </header>
  );
};

export default Header;
