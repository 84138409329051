import React from "react";
import classNames from "classnames";
import styles from "./SurveyResultsTable.module.scss";
import MainTable from "components/tables/MainTable/MainTable";

const SurveyResultsTable = ({
  data,
  perPage,
  onPageChange,
  currentPage,
  onSort,
}) => {
  return (
    <MainTable
      data={data.survey_results}
      dataTypes={[
        {
          title: "Stakeholder",
          value: ({ stakeholder }) => stakeholder,
          sortable: true,
          handleSort: (type) =>
            onSort({ sort_by: "name", direction: type.direction }),
        },
        {
          title: "Result",
          value: ({ value }) =>
            value ? (
              <div className={styles.container}>
                <p>{value}</p>
                <span
                  className={classNames({
                    [styles.hight]: value >= 8,
                    [styles.medium]: value > 4 && value < 8,
                    [styles.low]: value <= 4,
                  })}
                ></span>
              </div>
            ) : (
              "-"
            ),
        },
        {
          title: "Comment",
          value: ({ value, comment }) =>
            value ? (
              comment
            ) : (
              <p className={styles.incomplete}>Incomplete survey</p>
            ),
        },
      ]}
      perPage={perPage}
      totalCount={data.survey_results_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};

export default SurveyResultsTable;
