import React from 'react'
import s from '../PrivacyPage.module.scss'

const Point04 = () => {
	return (
		<div id="point04" className={s.pointBlock}>
              <h3 className={s.subtitle}>
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </h3>

              <p className={s.text} style={{ fontStyle: "italic" }}>
                <span>In Short: </span>We may use cookies and other tracking
                technologies to collect and store your information.
              </p>

              <p className={s.text}>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice.
              </p>
            </div>
	)
}

export default Point04
