import React from "react"
import s from "./GaugeChartWidget.module.scss"
import GaugeChart from "react-gauge-chart"
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const GaugeChartWidget = ({ nps_score = 0 }) => {
  const {t} = useTranslation();
  const getPercent = (min, max, val) => (max * (val - min)) / (max - min)
  const percent = getPercent(-100, 100, nps_score)
  return (
    <div className={s.gaugeChartWrap}>
      <span className={s.title} data-translate-key={getTranslateKey("NPS Score")}>
        {t(getTranslateKey("NPS Score"))}
      </span>
      <GaugeChart
        id='gauge-chart1'
        marginInPercent={0}
        cornerRadius={0}
        percent={getPercent(0, 1, percent / 100)}
        arcPadding={0}
        arcWidth={0.16}
        colors={["#DE4935", "#F2CD7B", "#83A755"]}
        needleColor='#455A64'
        needleBaseColor='#455A64'
        hideText={true}
        arcsLength={[0.5, 0.15, 0.35]}
        animateDuration={5000}
        className={s.gaugeChart}
      />
      {nps_score !== undefined && (
        <span className={s.percent}>
          {nps_score !== null ? nps_score : "-"}
        </span>
      )}
    </div>
  )
}

export default GaugeChartWidget
