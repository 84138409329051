import React, {useState} from "react";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import Popup from "components/popups/Popup/Popup";
import ProjectFinancialBenefitsTable
  from "components/tables/ProjectFinancialBenefitsTable/ProjectFinancialBenefitsTable";
import AddProjectFinancialBenefitForm
  from "components/forms/AddProjectFinancialBenefitForm/AddProjectFinancialBenefitForm";
import FinancialByYear from "../../FinancialByYear/FinancialByYear";
import {currencyData as currencies} from "helpers/helpData";
import TableLoading from "components/loadingStates/Table/Table";
import {useHistory, useParams} from "react-router-dom";

const FinancialBenefitsContainer = (
  {
    loading,
    addHandler,
    benefitsByYear,
    permissions,
    benefits,
    defaultCurrency,
    deleteHandler,
    data
  }
) => {

  const [show, setShow] = useState(false);
  const history = useHistory();
  const params = useParams();

  return (
    <>
      {loading && <TableLoading length={5}/>}
      {!loading && (
        <>
          {benefits.length ? (
            <ProjectFinancialBenefitsTable
              benefits={benefits}
              removeHandler={deleteHandler}
              openPopup={() => setShow(true)}
              havePermissions={permissions}
            />
          ) : (
            <EmptyBlock
              title="Financial benefits"
              subtitle={
                permissions.update
                  ? "You don’t have financial benefits yet. You can add a new one"
                  : "You don’t have financial benefits yet."
              }
              buttonTitle="Add benefit"
              onClick={() => history.push(`/projects/${params.id}/financial-benefits`)}
              withBtn={permissions.update}
            />
          )}
          {benefitsByYear && (
            <FinancialByYear
              data={benefitsByYear}
              title="Financial Benefits by year"
              defaultCurrency={defaultCurrency}
            />
          )}
          {show && (
            <Popup
              onClose={() => setShow(false)}
              title="Add financial benefit"
              text="Please, complete the following fields to add a new financial benefit"
            >
              <AddProjectFinancialBenefitForm
                status={data.stage.name}
                actualEndDate={data.actual_end_date}
                targetEndDate={data.end_date}
                currencies={currencies}
                defaultCurrency={defaultCurrency}
                onSubmit={addHandler}
                onClose={() => setShow(false)}
              />
            </Popup>
          )}
        </>
      )}
    </>
  );
};

export default FinancialBenefitsContainer;
