import React from "react"
import Popup from "components/popups/Popup/Popup"
const DeletePopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      onClose={onClose}
      title='Delete task role'
      text='Are you sure you want to delete this task role?'
      onClick={onSubmit}
      dialog
      isDelete
      subText="It will remain with all current users, but not with new ones."
    ></Popup>
  )
}
export default DeletePopup
