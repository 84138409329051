import React from "react";
import styles from "./BlockChart.module.scss";

export const BlockContainer = ({ completed, active, total, el, setToolTip, quater }) => {
  const onMouseEnter = (el, action) =>
    setToolTip((p) => ({ ...p, show: true, id: el, action: action }));

  const onMouseMove = (e, el, action) => {
    const parent = e.currentTarget.offsetParent.offsetParent.getBoundingClientRect();
    const x = e.clientX - parent.left;
    const y = e.clientY - parent.top;
    setToolTip((p) => ({ ...p, x: x, y: y, id: el, action: action }));
  };

  const onMouseLeave = () => setToolTip((p) => ({ ...p, show: false, action: "" }));

  const correctCompleted = Array.isArray(completed) ? completed.length : completed;
  return (
    <div style={{ position: "relative" }}>
      <div
        className={quater ? styles.quater : styles.item}
        onMouseEnter={() => active && onMouseEnter(el, "Active")}
        onMouseMove={(e) => active && onMouseMove(e, el, "Active")}
        onMouseOut={() => active && onMouseLeave()}
        style={{
          cursor: active ? "pointer" : "",
          zIndex: "1",
          border:
            correctCompleted && active
              ? "1px solid #5B79B4"
              : !correctCompleted && active
              ? "1px solid #5B79B4"
              : "",
          backgroundColor:
            correctCompleted && active
              ? "#ADBCD9"
              : !correctCompleted && active
              ? "#ADBCD9"
              : "#C8C8C8",
        }}></div>
      <div
        onMouseEnter={() => onMouseEnter(el, "Completed")}
        onMouseMove={(e) => onMouseMove(e, el, "Completed")}
        onMouseOut={() => onMouseLeave()}
        style={{
          height: `${
            correctCompleted && !active
              ? 100
              : !correctCompleted && !active
              ? 0
              : (completed / total) * 100
          }px`,
          bottom: 0,
          width: `${quater ? "19px" : "39px"}`,
          backgroundColor: "#5B79B4",
          zIndex: "100000",
          cursor: "pointer",
          maxHeight: "100%",
          position: "absolute",
          right: "0.7px",
        }}
      />
    </div>
  );
};
