import React, { useState } from "react"
import MainTable from "components/tables/MainTable/MainTable"
import Popup from "components/popups/Popup/Popup"

const StakeholdersTable = ({
  stakeholders,
  removeHandler,
  history,
  onAddNew,
  onEdit,
  havePermissions
}) => {
  const [show, setShow] = useState(false)
  const [stakeholder, setStakeholder] = useState({})

  return (
    <>
      <MainTable
        data={stakeholders}
        dataTypes={[
          {
            title: "Name",
            value: ({ user }) => user.name,
            sortable: true,
            onClick: (_, e) => history.push(`/users/${e.user?.id}`),
            sticky: true
          },
          {
            title: "Title",
            value: ({ user: { title } }) => title
          },
          {
            title: "Email",
            value: ({ user: { email } }) => email
          },
          {
            title: "Type",
            value: ({ stakeholder_type }) => stakeholder_type?.name || "-"
          },
          {
            title: "Division",
            value: ({ user: { division } }) => division?.name || "-"
          },
          {
            title: "Department",
            value: ({ user: { department } }) => department?.name || "-"
          }
        ]}
        options={item => {
          return [
            havePermissions.destroy && {
              title: "Remove",
              onClick: () => {
                setStakeholder(item)
                setShow(true)
              }
            },
            havePermissions.update && {
              title: "Edit",
              onClick: () => {
                onEdit(item)
              }
            }
          ]
        }}
        button={
          havePermissions.update && {
            name: "Add Stakeholder",
            onClick: onAddNew
          }
        }
      />

      {show && (
        <Popup
          onClose={() => setShow(false)}
          successBtnTitle='Remove'
          title='Remove stakeholder'
          text='Are you sure you want to remove this stakeholder?'
          onClick={() => {
            removeHandler(stakeholder)
            setShow(false)
          }}
          isDelete
          dialog
        />
      )}
    </>
  )
}

export default StakeholdersTable
