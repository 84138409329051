import React, {useState} from "react";
import ActualSavingsPopup from "../../popups/ActualSavingsPopup/ActualSavingsPopup";
import {useHistory, useParams} from "react-router-dom";
import services from "../../../services";
import MonthTable from "./MonthTable";
import PropTypes from "prop-types";
import AddNewActualSavingsForm from "../../forms/AddNewActualSavingsForm/AddNewActualSavingsForm";
import querystring from "query-string";

const ActualSavingsMonthTable = ({actualSavings, load, data, project, savingsGr,className}) => {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [showNewActual, setShowNewActual] = useState(false);
  const [alert, setAlert] = useState({message: "", type: ""});
  const [editSavingsMonth, setEditSavingsMonth] = useState(false);
  const history = useHistory();
  const query = querystring.parse(history.location.search) || {};

  const editActualSavingsMonth = (onSubmit) => {
    services.projectServices.putProjectActualSavingsMonth(params.id, onSubmit)
      .then(res => {
        if (res.data) load({savings_group_ids: query?.savings_group_ids});
      });
    setShow(false);
  };

  const newActualSavings = (values) => {
    services.projectServices.putProjectActualSavings(params.id, values)
      .then(res => {
        if (res.data) load(query);
        setAlert({
          type: "success",
          message: "Settings successfully changed."
        });
        setShow(false);
      })
      .catch(err =>
        setAlert({
          message:
            err.response?.data.error ||
            "Record with this group, month and year already exist",
          type: "error"
        })
      );

  };

  return (
    <>
      {actualSavings &&
        <MonthTable
          className={className}
          data={data}
          setEditSavingsMonth={setEditSavingsMonth}
          setShow={setShow}
          setShowNewActual={setShowNewActual}
          project={project}
        />
      }
      {show && (
        <ActualSavingsPopup
          title={`Edit savings for ${editSavingsMonth.month}`}
          data={editSavingsMonth}
          setShow={setShow}
          onSubmitEdit={editActualSavingsMonth}/>
      )}
      {showNewActual && (
        <AddNewActualSavingsForm
          title="New actual savings"
          text="Please, complete the following fields to create new actual savings"
          setShowNewActual={setShowNewActual}
          onSubmitEdit={newActualSavings}
          savingsGr={savingsGr}
        />
      )}
    </>
  );
};
export default ActualSavingsMonthTable;


ActualSavingsMonthTable.propTypes = {
  onSubmit: PropTypes.func
};