import axios from "./axios";

export default {
  getTriageHierarchies: async () => {
    try {
      return await axios.get(`/admin/triage_hierarchies`);
    } catch (error) {
      throw error;
    }
  },

  updateTriageHierarchies: async params => {
    try {
      return await axios.put(`/admin/triage_hierarchies/replace`, params);
    } catch (error) {
      throw error;
    }
  },

  getTriages: async params => {
    try {
      return await axios.get(`/admin/triages`, {
        params
      });
    } catch (error) {
      throw error;
    }
  },

  updateTriage: async values => {
    try {
      return await axios.put(`/admin/triages`, values);
    } catch (error) {
      throw error;
    }
  },

  deleteTriage: async triageId => {
    try {
      return await axios.delete(`/admin/triages/${triageId}`);
    } catch (error) {
      throw error;
    }
  },


  getTriageUsers: async params => {
    try {
      return await axios.get(`/admin/triage_users`, {
        params
      });
    } catch (error) {
      throw error;
    }
  },
}