import React, {Fragment, useEffect, useState, useRef} from "react";
import s from "./General.module.scss";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import Button from "components/elements/Button/Button";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import services from "services";
import {getAllowanceOptions, getAllowance} from "../helpers/formatData";
import {savingsData, whenRuleData} from "../helpers/helpData";
import {useHistory} from "react-router-dom";
import Popup from "components/popups/Popup/Popup";
import classNames from "classnames";
import Input from "../../../elements/Input/Input";
import {SET_LOGO_COMPANY} from "../../../../helpers/constants";
import {useDispatch, useSelector} from "react-redux";
import {save} from "../../../../helpers/localStorage";
import {systemLanguages} from "../../../../helpers/systemLanguages";
import {useTranslation} from "react-i18next";

const General = () => {
  const [defaultData, setDefaultData] = useState({});
  const [currency, setCurrency] = useState({});
  const [savings, setSavings] = useState({});
  const [logoCompany, setLogoCompany] = useState({});
  const [alert, setAlert] = useState({
    type: "",
    message: ""
  });
  const [currencyData, setCurrencyData] = useState([]);
  const [allowance, setAllowance] = useState({});
  const allowanceOptions = getAllowanceOptions();
  const [whenRule, setWhenRule] = useState({});
  const [timeZone, setTimeZone] = useState({
    value: "-4",
    label: "UTC -4"
  });
  const [timeFormat, setTimeFormat] = useState({
    value: "24",
    label: "24 hours"
  });
  const [dateFormat, setDateFormat] = useState("MM/DD/YY");
  const [language, setLanguage] = useState(systemLanguages[0]);
  const history = useHistory();
  const unblockHandle = useRef();
  const dispatch = useDispatch();
  const [isShowModal, setIsShowModal] = useState(false);
  const [nextTarget, setNextTarget] = useState(null);
  const user = useSelector(state => state.user);
  const {t} = useTranslation();

  const disableBtn =
    language.value === defaultData?.language &&
    currency.value === defaultData?.currency &&
    logoCompany.value === defaultData?.logoCompany &&
    savings.value === defaultData?.savings &&
    (defaultData.allowance?.rule === "full"
      ? defaultData.allowance?.rule === allowance.value
      : defaultData.allowance?.months === allowance.value)
    &&
    whenRule.value === defaultData.whenRule?.value;

  useEffect(() => {
    unblockHandle.current = history.block(target => {
      if (!disableBtn) {
        setIsShowModal(true);
        setNextTarget(target);
        return false;
      } else return true;
    });
    return () => {
      unblockHandle.current && unblockHandle.current();
    };
  }, [disableBtn]);

  const handleConfirm = () => {
    unblockHandle.current && unblockHandle.current();
    history.push(nextTarget);
  };

  useEffect(() => {
    services.adminServices.getOrganization().then(res => {
      const currency = res.data.default_currency;
      const language = res.data.language;
      const logoCompany = res.data.logo_company;
      const savings = res.data.widget_time_period;
      const _allowance = {
        rule: res.data.allowance_rule,
        months: String(res.data.allowance_months_count)
      };
      const _whenRule = {
        value: res.data.when_rule,
        label: whenRuleData.find(item => item.value === res.data.when_rule)
      };
      setCurrency({
        label: currency,
        value: currency
      });
      setLogoCompany({
        label: logoCompany,
        value: logoCompany
      });
      setLanguage(systemLanguages.find(lang => lang.value === language) ?? systemLanguages[0]);
      setSavings({
        value: savings,
        label: savings.charAt(0).toUpperCase() + savings.slice(1)
      });
      setAllowance(getAllowance(res.data));
      setWhenRule(_whenRule);
      setDefaultData({
        currency,
        savings,
        logoCompany,
        language,
        allowance: _allowance,
        whenRule: _whenRule
      });
    });
    services.currenciesServices.getAdminCurrencies().then(res => {
      setCurrencyData(
        res.data.map(e => ({
          value: e,
          label: e
        }))
      );
    });
  }, []);

  const handleSaveSettings = () => {
    let allowance_rule;
    let allowance_months_count;
    if (allowance.value === "full") {
      allowance_rule = "full";
    } else {
      allowance_rule = "months";
      allowance_months_count = allowance.value;
    }

    dispatch({
      type: SET_LOGO_COMPANY,
      payload: logoCompany.value,
    });

    save("currentUser", {
      ...user, organization: {
        ...user.organization,
        logo_company: logoCompany.value
      }
    });

    services.adminServices
      .changeOrganization({
        widget_time_period: savings.value,
        logo_company: logoCompany.value.replace(/ /g, ''),
        allowance_rule,
        default_currency: currency.value,
        allowance_months_count,
        when_rule: whenRule.value,
        language: language.value
      })
      .then(res => {
        const currency = res.data.default_currency;
        const savings = res.data.widget_time_period;
        const language = res.data.language;
        const logoCompany = res.data.logo_company;
        const _allowance = {
          rule: res.data.allowance_rule,
          months: String(res.data.allowance_months_count)
        };
        const _whenRule = {
          value: res.data.when_rule,
          label: whenRuleData.find(item => item.value === res.data.when_rule)
        };
        setAlert({
          type: "success",
          message: "Settings successfully changed."
        });
        setDefaultData({
          currency,
          savings,
          logoCompany,
          language,
          allowance: _allowance,
          whenRule: _whenRule,
        });
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Something went wrong. Please, try again."
        });
      });

  };

  return (
    <Fragment>
      <div className={classNames(s.col, s.mainCol)}>
        <CustomDropdown
          value={currency.label}
          onChange={e => setCurrency(e)}
          options={currencyData}
          name="currency"
          label="Currency*"
          className={s.dropdown}
        />
        <CustomDropdown
          value={timeZone.label}
          onChange={e => setTimeZone(e)}
          options={[]}
          name="time_zone"
          label="Time zone*"
          className={s.dropdown}
        />
        <CustomDropdown
          value={timeFormat.label}
          onChange={e => setTimeFormat(e)}
          options={[]}
          name="time_format"
          label="Time format*"
          className={s.dropdown}
        />
        <CustomDropdown
          value={dateFormat}
          onChange={e => setDateFormat(e)}
          options={[]}
          name="date_format"
          label="Date format*"
          className={s.dropdown}
        />
        <CustomDropdown
          value={language}
          onChange={e => setLanguage(e)}
          options={systemLanguages}
          name="language"
          label="Language"
          className={s.dropdown}
        />
      </div>

      <div className={classNames(s.col, s.mainCol)}>
        <h2 className={s.titleDomain} data-translate-key="logo">{t("logo")}</h2>
        <Input
          value={logoCompany.label}
          onChange={e => {
            setLogoCompany({
              label: e.target.value,
              value: e.target.value
            });
          }}
          name="logoCompany"
          placeholder={`For image link`}

        />
      </div>
      <div className={s.col}>
        <h2 className={s.title} data-translate-key="savings-widget">{t("savings-widget")}</h2>
        <CustomDropdown
          value={savings.value}
          onChange={e => setSavings(e)}
          options={savingsData}
          name="savings"
          label="Time period*"
          className={s.dropdown}
        />
      </div>
      <div className={s.col}>
        <CustomDropdown
          value={allowance.value}
          onChange={e => {
            setAllowance(e);
          }}
          options={allowanceOptions}
          name="allowance"
          label="Allowance rule(months)"
          className={s.dropdown}
        />
      </div>
      <div className={s.col}>
        <CustomDropdown
          value={whenRule.value}
          onChange={e => setWhenRule(e)}
          options={whenRuleData}
          name="whenrule"
          label="When rule"
          className={s.dropdown}
        />
      </div>
      <div className={s.btnWrap}>
        <Button
          styled="primary"
          disabled={disableBtn}
          onClick={handleSaveSettings}
          className={s.btn}
        >
          Save
        </Button>
        <span className={s.message}
              data-translate-key="changes-in-fields-with-*-will-not-affect-existing-members-of-the-organization.">
          {t("changes-in-fields-with-*-will-not-affect-existing-members-of-the-organization.")}
          <br/>
          <span
            data-translate-key="they-will-be-used-only-as-default-settings-for-new-members">{t("they-will-be-used-only-as-default-settings-for-new-members")}</span>
        </span>
      </div>
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
      {isShowModal && (
        <Popup
          title="Leave page"
          text="You haven’t saved your changes yet. Do you want to leave without saving?"
          onClose={() => setIsShowModal(false)}
          onClick={handleConfirm}
          successBtnTitle="Leave"
          isLeave
          dialog
        />
      )}
    </Fragment>
  );
};

export default General;
