import React, {useRef, useState} from "react";
import EmailEditor from 'react-email-editor';
import styles from "./AdminSettingsEmailsContainer.module.scss";
import services from "../../../services";
import {useParams} from "react-router-dom";
import Button from "../../elements/Button/Button";
import {generateUniqueId} from "../../../helpers/uniqueId";
import EmailFields from "./EmailFields/EmailFields";
import NotificationBox from "../../elements/NotificationBox/NotificationBox";

const AdminSettingsEmailEditorContainer = () => {
  const {id} = useParams();
  const emailEditorRef = useRef(null);
  const [emailEditorKey, setEmailEditorKey] = useState(generateUniqueId());
  const [alert, setAlert] = useState({message: "", type: ""});

  const saveTemplate = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const {design, html} = data;
      services.emailTemplatesServices.changeEmailTemplate(id, {
        template: html,
        template_json: JSON.stringify(design)
      }).then(res => {
        if (res.status === 200) {
          setAlert({
            type: "success",
            message: "Template successfully saved."
          });
        }
      });
    });
  };

  const resetTemplate = () => {
    services.emailTemplatesServices.resetEmailTemplate(id).then(res => {
      if (res.data) {
        setEmailEditorKey(generateUniqueId());
      }
    });
  };

  const onLoad = () => {
    services.emailTemplatesServices.getEmailTemplate(id).then(res => {
      if (res.data?.template_json) {
        try {
          emailEditorRef.current.editor.loadDesign(JSON.parse(res.data.template_json));
        } catch (err) {
          throw err;
        }
      }
    });
  }

  return (
    <div className={styles.wrap}>
      <header>
        <EmailFields/>
        <div className={styles.btns}>
          <Button styled='primary' onClick={resetTemplate}>
            Reset Template
          </Button>
          <Button styled='primary' onClick={saveTemplate}>
            Save Template
          </Button>
        </div>
      </header>
      <div className={styles.editorWrapper}>
        <EmailEditor
          key={emailEditorKey}
          ref={emailEditorRef}
          onLoad={onLoad}
        />
      </div>
      {alert.message && (
        <NotificationBox
          className={styles.alert}
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </div>
  );
};

export default AdminSettingsEmailEditorContainer;