import React from "react";
import Popup from "components/popups/Popup/Popup";
import ChangeFlagForm from "components/forms/ChangeFlagForm/ChangeFlagForm"

const EditGroupPopup = ({
  onClose,
  onSubmit,
  defaultValues,
}) => {
  return (
    <Popup
      onClose={onClose}
      title="Edit flag"
      text="Please, complete the following fields to add a flag"
    >
      <ChangeFlagForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
    </Popup>
  );
};
export default EditGroupPopup;
