import React from "react";
import styled from "styled-components";

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  li {
    padding-left: 28px !important;
  }
  span {
    padding-left: 15px !important;
  }
`;

const ColorCircle = styled.div`
  position: absolute;
  border-radius: 8px;
  height: 9px;
  width: 9px;
  left: 15px !important;
  background-color: ${({ color }) => color ?? ""};
`;

// Helper function that adds a circle of any colour to the option in
// dropdown list, to use it need to add the mandatory colour field
export const optionWithColor = (option, children) =>
  option?.color ? (
    <OptionContainer>
      <ColorCircle color={option.color} />
      {children}
    </OptionContainer>
  ) : (
    children
  );
