import React from "react"
import Stages from "./components/Stages/Stages"
import Tabs from "../../../elements/Tabs/Tabs";
import TaskStatuses from "../../AdminSettingsContainer/Tabs/TaskStatuses/TaskStatuses";
import Groups from "./components/TypesAndGroups/components/Groups/Groups";
import Types from "./components/TypesAndGroups/Types";
import Stakeholders from "./components/Stakeholders/Stakeholders";
import General from "./components/General/General";
import CustomStatuses from "./components/CustomStatuses/CustomStatuses";
import TaskRoles from "./components/TaskRoles/TaskRoles";

const Projects = () => {
  return (
    <Tabs>
      <div label='General'>
        <General/>
      </div>
      <div label='Stages'>
        <Stages/>
      </div>
      <div label='Groups'>
        <Groups/>
      </div>
      <div label='Types'>
        <Types/>
      </div>
      <div label='Tasks'>
        <TaskStatuses/>
      </div>
      <div label='Task roles'>
        <TaskRoles/>
      </div>
      <div label='Stakeholders'>
        <Stakeholders/>
      </div>
      <div label='Custom statuses'>
       <CustomStatuses/>
      </div>
    </Tabs>
  )
}
export default Projects
