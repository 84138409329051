import React from "react";
import s from "./ChildContracts.module.scss";
import sBase from "../../ContractDetailsContainer.module.scss";
import Wrapper from "../Wrapper/Wrapper";
import {currencyData} from "../../../../../helpers/helpData";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const ChildContracts = ({params}) => {
  const {t} = useTranslation();
  const {
    sub_contracts_count,
    sub_total_value,
    sub_currency,
    sub_annual_value,
  } = params;

  return (
    <Wrapper>
      <div className={s.header}>
        <h4 className={sBase.sectionTitle} data-translate-key={getTranslateKey("Child contracts")}>
          {t(getTranslateKey("Child contracts"))}
        </h4>
        <span className={s.subCount}>
          {sub_contracts_count}
          <span data-translate-key={getTranslateKey("project")}>
            {t(getTranslateKey("project"))}
          </span>
        </span>
      </div>
      <div className={s.infoWrap}>
        <div className={s.infoBlock}>
          <p className={s.title} data-translate-key={getTranslateKey("Total contract value")}>
            {t(getTranslateKey("Total contract value"))}
          </p>
          <p className={s.value}>
            {`${currencyData.find(c => c.label === sub_currency)?.value} ${sub_total_value.toLocaleString("en-US", {maximumFractionDigits: 0})}`}
          </p>
        </div>
        <div className={s.infoBlock}>
          <p className={s.title} data-translate-key={getTranslateKey("Annual contract value")}>
            {t(getTranslateKey("Annual contract value"))}
          </p>
          <p className={s.value}>
            {`${currencyData.find(c => c.label === sub_currency)?.value} ${sub_annual_value.toLocaleString("en-US", {maximumFractionDigits: 0})}`}
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default ChildContracts;
