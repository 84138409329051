import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import SupplierDetailContractsTable from "components/tables/SupplierDetailContractsTable/SupplierDetailContractsTable";
import services from "services";

const SupplierContracts = () => {
  const params = useParams();
  const [contracts, setContracts] = useState({
    contracts: [],
    contracts_count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({});

  useEffect(() => {
    services.supplierServices
      .supplierReportContracts(params.id, query)
      .then((res) => {
        setContracts(res.data);
        setLoading(false);
      });
  }, [params.id, query]);
  const onDeleteTag = async (tagId, contractId) =>
    await services.tagsServices.deleteContractTag(contractId, tagId);

  const onAssignTag = async (tagId, supplierId) =>
    await services.tagsServices.assignContractTag(supplierId, tagId);

  return (
    <>
      {loading && <TableLoading length={5} />}
      {!loading && Boolean(contracts.contracts_count) && (
        <SupplierDetailContractsTable
          data={contracts}
          query={query}
          changeQuery={setQuery}
          onAssignTag={onAssignTag}
          onDeleteTag={onDeleteTag}
        />
      )}
      {!loading && !Boolean(contracts.contracts_count) && (
        <EmptyBlock title="Supplier doesn't have any contracts yet." message />
      )}
    </>
  );
};

export default SupplierContracts;
