import {string} from "yup";

export const setRequestTypes = (steps, requestTypes, defaultRequest) => {
  steps[0] = {
    ...steps[0],
    fields: [
      {
        key: "request_type_id",
        type: "radio",
        options: requestTypes,
        value: defaultRequest ? {
          type: "radio",
          value: defaultRequest.request_type,
        } : "",
        required: string().required("Type is required"),
        conditions: "",
        optional: true,
      },
      steps[0].fields[steps[0].fields.length - 1]
    ]
  };

  return steps;
};
