import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "components/elements/Button/Button";
import s from "./EmptyBlock.module.scss";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import SearchIcon from "../../../assets/icons/search-big.png";

const EmptyBlock = (
  {
    title,
    subtitle,
    svgTitle = false,
    buttonTitle,
    onClick,
    className,
    withBtn,
    children,
    message,
    buttonDisabled,
  }
) => {
  const {t} = useTranslation();
  return (
    <div className={classNames(s.emptyBlock, className)}
         data-translate-key={getTranslateKey(title)}>
      {t(getTranslateKey(title)) && (
        <span className={classNames(s.title, {[s.message]: message})} data-translate-key={getTranslateKey(title)}>
          {t(getTranslateKey(title))}
        </span>
      )}
      {svgTitle && <img className={s.icon} src={SearchIcon} alt="no data"/>}
      {subtitle && !message && (
        <span className={s.subtitle}
              style={{marginBottom: !withBtn && "0"}}
              data-translate-key={getTranslateKey(subtitle)}>
          {t(getTranslateKey(subtitle))}
        </span>
      )}
      {withBtn && !message ? (
        <Button
          styled="addElement"
          onClick={onClick}
          disabled={buttonDisabled}
          data-translate-key={getTranslateKey(buttonTitle)}>
          {t(getTranslateKey(buttonTitle))}
        </Button>
      ) : (
        children
      )}
    </div>
  );
};

EmptyBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
  withBtn: PropTypes.bool,
  message: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
};

EmptyBlock.defaultProps = {
  subtitle: "",
  buttonTitle: "",
  onClick: () => {
  },
  withBtn: true,
  message: false,
  buttonDisabled: false,
};

export default EmptyBlock;
