import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {paths} from "routes/paths";
import {useParams} from "react-router-dom";
import AdminSettingsTaskDetailsContainer
  from "../../containers/AdminSettingsTaskDetailsContainer/AdminSettingsTaskDetailsContainer";

const AdminSettingsTaskDetailsPage = () => {
  const {id} = useParams();

  return (
    <DefaultLayout
      breadcrumbs={[
        {
          name: "Admin Settings",
          disabled: true,
        },
        {
          name: "Projects",
          path: paths.adminSettingsProjects
        },
        {
          name: "Project type details",
          path: `/admin-settings/project-types/${id}`
        },
        {
          name: "Task details",
        }
      ]}
    >
      <AdminSettingsTaskDetailsContainer/>
    </DefaultLayout>
  );
};

export default AdminSettingsTaskDetailsPage;
