export const formatEntityData = (data = []) => {
  return data.map(({ id, name, departments }) => {
    return {
      id,
      value: name,
      label: name,
      subItems: departments?.length ? formatEntityData(departments) : null
    };
  });
};
export const formatEntityToObject = data => {
  const obj = {};
  data.map(e => (obj[e.label] = true));
  return obj;
};
