import React, { useEffect, useState } from "react";
import classNames from "classnames";
import s from "./PieChartWidget.module.scss";
import { formatDataToArray } from "./formatDataToArray";
import WidgetEmptyState from "components/elements/WidgetEmptyState/WidgetEmptyState";
import services from "../../../services";
import { entitiesFormatter } from "../../../helpers/entitiesFormatter";
import { useTranslate } from "../../../hooks/useTranslate";
import { BlockChart } from "../PieChart/BlockChart.jsx";

const PieChartWidget = ({ data: fullData, title, className, onClick, quater }) => {
  const [savingsTypesList, setSavingsTypesList] = useState([]);
  const [translateKey, translateValue] = useTranslate(title);

  useEffect(() => {
    services.savingsTypeServices.getSavingsTypeList().then((res) => {
      if (res.data) {
        setSavingsTypesList(res.data.map((type) => entitiesFormatter(type)));
      }
    });
  }, []);

  const data = { ...fullData };

  delete data.default_currency;
  delete data.total;

  const quatersYears =
    quater &&
    !Object.values(Object.values(data.savings)).some((el) => Object.keys(el)[0].includes("Q"));

  const sum = (completed, notCompleted) => {
    const keys = Object.keys(data);
    const sum = keys
      .map(
        (e) =>
          ((completed && data[e]?.completed) || 0) +
          ((notCompleted && data[e]?.not_completed) || 0),
      )
      .reduce((a, b) => a + b, 0);
    return (sum / 1000000).toFixed(1);
  };

  const emptyWidget = () => {
    const keys = Object.keys(data);
    const sum = keys
      .map((e) => (data[e]?.completed || 0) + (data[e]?.not_completed || 0))
      .reduce((a, b) => a + b, 0);

    return !sum;
  };

  return (
    <>
      {data && (
        <div className={classNames(s.widget, className)}>
          <div className={s.titleWrap}>
            <h4 className={s.subtitle} data-translate-key={translateKey}>
              {translateValue}
            </h4>
            {onClick && <span onClick={onClick} className={s.viewDetails}></span>}
          </div>
          <div
            style={{ justifyContent: quatersYears ? "center" : "space-between" }}
            className={s.inlineWrap}>
            {!emptyWidget() && (
              <div className={s.lineWrap}>
                <div />
                <div />
                <div />
                <div />
              </div>
            )}
            {!emptyWidget() &&
              formatDataToArray(data, savingsTypesList).map((item) => <BlockChart item={item} />)}
            {quater &&
              !quatersYears &&
              Object.values(data.savings[0])[0].map((item, index) => (
                <BlockChart
                  item={item}
                  quater={quater}
                  index={index}
                  data={Object.values(data.savings)}
                />
              ))}
            {quatersYears && (
              <BlockChart
                quater={quater}
                data={Object.values(data.savings)}
                quatersYears={quatersYears}
              />
            )}
            {!emptyWidget() && (
              <div className={s.totalContainer}>
                <h2>Total - ${sum(true, true)} M</h2>
                <div className={s.margins}>Active&Planned - ${sum(false, true)} M</div>
                <div className={s.totalChild}>
                  <div
                    style={{
                      width: `${(sum(false, true) / sum(true, true)) * 150}px`,
                      height: "4px",
                      borderRadius: "2px",
                      backgroundColor: "#ADBCD9",
                    }}
                  />
                </div>
                <div className={s.margins}>Completed - ${sum(true, false)} M</div>
                <div className={s.totalChild}>
                  <div
                    style={{
                      width: `${(sum(true, false) / sum(true, true)) * 150}px`,
                      height: "4px",
                      borderRadius: "2px",
                      backgroundColor: "#5B79B4",
                    }}
                  />
                </div>
              </div>
            )}
            {emptyWidget() && !quater && (
              <WidgetEmptyState title="There is no data that matches selected filters" />
            )}
          </div>
          {(!emptyWidget() || quater) && (
            <div className={s.squareContainer}>
              <div
                className={s.square}
                style={{ backgroundColor: "#ADBCD9", marginRight: "5px" }}
              />
              <div>Active&Planned</div>
              <div
                className={s.square}
                style={{ backgroundColor: "#5b79b4", margin: "0px 5px 0px 15px" }}
              />
              <div>Completed</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PieChartWidget;
