import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import { SurveyTemplateTable } from "./components/SurveyTemplateTable/SurveyTemplateTable";

const SurveyTemplatePage = () => {
  return (
    <DefaultLayout>
      <SurveyTemplateTable />
    </DefaultLayout>
  );
};

export default SurveyTemplatePage;
