import React, {useState} from 'react';
import FilterBar from 'components/elements/FilterBar/FilterBar';
import FilterBarDropdown from '../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown';
import {useHistory} from 'react-router-dom';
import qs from 'query-string';
import {debounce} from 'lodash';
import FilterBarDropdownYears from "../CategoriesFilter/FilterBarDropdownYears/FilterBarDropdownYears";

const initialFiltersValues = {
	years: [],
	departments: [],
	users: []
}

const CompanyGoalsFilter = (
  {
    onFiltersChange,
    filters,
    className,
    onSearch,
    activeFilter
  }
) => {

  const currentYear = String(new Date().getFullYear());
  const date = new Date();
  const [activeFilters, setActiveFilters] = useState(initialFiltersValues);
  const history = useHistory();

  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  const handleChangeFilter = (filterName, values) => {
    if (filterName !== "years") {
      const newFiltersValues = {...activeFilters, [filterName]: values};
      setActiveFilters({...newFiltersValues});
      onFiltersChange({...newFiltersValues});
    } else {
      history.push({
        search: qs.stringify(
          {...query, year: values},
          {arrayFormat: "comma"}
        )
      });
    }
  };

  const handleClearAll = () => {
    setActiveFilters(initialFiltersValues);
    onFiltersChange(initialFiltersValues);
    history.push({
      search: qs.stringify({...query, years: currentYear}, {arrayFormat: "comma"})
    });
  };

	const handleSearch = debounce(term => {
		onSearch(term)
	}, 300);

  return (
    <FilterBar
      filterName='Filter goals'
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ''}
      withoutDefaultFilter
      withSearch
    >
      <FilterBarDropdownYears
        name="Years"
        options={filters.years}
        defaultValues={String(date.getFullYear())}
        handleChange={values => {
          handleChangeFilter("years", values);
        }}
      />
      <FilterBarDropdown
        name='Departments'
        searchPlaceholder='Search department'
        options={filters.departments}
        customQueryParams={activeFilter?.departments}
        handleChange={values => {
          handleChangeFilter('departments', values)
        }}
      />
      <FilterBarDropdown
        name='Users'
        searchPlaceholder='Search user'
        customQueryParams={activeFilter?.users}
        options={filters.users}
        handleChange={values => {
          handleChangeFilter('users', values)
        }}
      />
    </FilterBar>
  )
}

export default CompanyGoalsFilter
