import {
  SET_FILTERS,
  SET_LOADING,
  SET_ACTIVE_FILTERS,
  SET_CURRENT_PAGE,
  SET_REQUEST_DATA,
} from "./reducer";

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: { loading },
});

export const setRequestData = (data) => ({
  type: SET_REQUEST_DATA,
  payload: { ...data },
});

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: { filters },
});

export const setActiveFilters = (activeFilters) => ({
  type: SET_ACTIVE_FILTERS,
  payload: { activeFilters },
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: { page },
});
