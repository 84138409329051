import React from 'react';
import sBase from "../MainDashboard.module.scss";
import {TableCell, TableRow} from "@material-ui/core";
import Status from "../../../../../elements/Status/Status";
import s from "./RecentRequest.module.scss";
import gradient from "random-gradient";
import {trimRest} from "../../../../../../helpers/stringHelper";
import {ReactComponent as UnknownUser} from "../../../../../../assets/icons/unknown-user-icon.svg";

const SingleRequest = ({request}) => {
  const separatedProcurementName = (request.responsible_procurement_name ?? "").split(" ");

  const avatar = separatedProcurementName.length > 1 ? separatedProcurementName[0][0] + separatedProcurementName[1][0] : separatedProcurementName[0][0];

  const renderResponsibleProcurementName = () => {
    if (!request.responsible_procurement_name) return (
      <div className={s.procurementName}>
        <UnknownUser/>
        Unassigned
      </div>
    );
    return (
      <div className={s.procurementName}>
        <span className={s.icon} style={{background: gradient(request.responsible_procurement_name)}}>
          {avatar}
        </span>
        {trimRest(12, request.responsible_procurement_name)}
      </div>
    )
  }
  return (
    <TableRow className={sBase.tableRow}>
      <TableCell className={sBase.tableCell}>
        <div className={s.requestName}>
          <a href={`/requests/${request.id}`}>{request.name}</a>
          <span>{request.request_type_name}</span>
        </div>
      </TableCell>
      <TableCell className={sBase.tableCell}>{renderResponsibleProcurementName()}</TableCell>
      <TableCell className={sBase.tableCell}><Status status={request.status} type="_recent"/></TableCell>
    </TableRow>
  );
};

export default SingleRequest;