import React from "react";
import AdminSettingsAdditionalQuestionsContainer
  from "../../containers/AdminSettingsAdditionalQuestionsContainer/AdminSettingsAdditionalQuestionsContainer";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

const AdminSettingsAdditionalQuestionsPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {name: "Admin Settings", path: "", disabled: true},
        {name: "Additional questions", path: ""},
      ]}
    >
      <AdminSettingsAdditionalQuestionsContainer/>
    </DefaultLayout>
  )

};
export default AdminSettingsAdditionalQuestionsPage;
