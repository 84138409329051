import React from "react"
import Button from "components/elements/Button/Button"
import Input from "components/elements/Input/Input"
import { useFormik } from "formik"
import { object, string } from "yup"

const AdminSettingsProjectGroupForm = ({ onSubmit, onClose, defaultData }) => {
  const MAX_NAME_LENGTH = 50
  const initialValues = {
    name: defaultData?.name || ""
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: object({
      name: string()
        .required("Project group is required")
        .trim()
        .max(
          MAX_NAME_LENGTH,
          `The group name is too long. It should be ${MAX_NAME_LENGTH} characters or less.`
        )
    }),
    onSubmit: values => {
      onSubmit(values)
      onClose()
    }
  })

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched
  } = formik
  return (
    <form onSubmit={handleSubmit} autoComplete='off' noValidate>
      <Input
        value={values.name}
        onChange={handleChange}
        name='name'
        type='text'
        placeholder='Enter group name'
        label='Project group'
        onBlur={handleBlur}
        error={errors.name && touched.name && errors.name}
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          className='popupBtn'
          type='reset'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={defaultData && defaultData.name === values.name}
          styled='primary'
          type='submit'
          className='popupBtn'
        >
          {defaultData ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  )
}

export default AdminSettingsProjectGroupForm
