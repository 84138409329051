import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import CreateProjectContainer
  from "../../containers/CreateProjectContainer/CreateProjectContainer";
import qs from "query-string";
import {paths} from "../../../routes/paths";
import {useHistory} from "react-router-dom";

const CreateProjectPage = () => {
  const history = useHistory();
  const {request_id} = qs.parse(history.location.search);

  let breadcrumbs = [
    {
      path: paths.projects,
      name: "Projects"
    },
    {
      name: "New project"
    }
  ];

  if (request_id) {
    breadcrumbs = [
      {
        path: paths.requests,
        name: "Requests"
      },
      {
        path: `/requests/${request_id}`,
        name: "Request details"
      },
      {
        name: "New project"
      }
    ];
  }

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <CreateProjectContainer requestId={request_id}/>
    </DefaultLayout>
  );
};
export default CreateProjectPage;
