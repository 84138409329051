import React from "react";
import Popup from "components/popups/Popup/Popup";
import AddProjectForm from "components/forms/AddProjectForm/AddProjectForm";
import {
  formatEntityToObject,
  formatEntityData
} from "helpers/formatEntityData";
import PropTypes from "prop-types";
import NavigationItem from "../../NavigationContainer/components/NavigationItem/NavigationItem";

const AddProjectPopup = (
  {
    closeAction,
    handleCreateProject,
    defaultData = {},
    title,
    text,
    withTargetSpend,
    isGlobalProject,
    isProjectRequest = false,
    defaultType
  }
) => {
  const {departments, ...spread} = defaultData;
  return (
    <Popup
      onClose={() => {
        closeAction();
      }}
      title={title || "New project"}
      text={
        text || "Please, complete the following fields to add a new project"
      }
    >
      <AddProjectForm
        onClose={() => {
          closeAction();
        }}
        onSubmit={handleCreateProject}
        defaultType={defaultType}
        defaultData={
          Object.keys(defaultData).length
            ? {
              ...spread,
              client: departments
                ? isProjectRequest
                  ? formatEntityToObject(formatEntityData(departments))
                  : formatEntityToObject(departments)
                : {}
            }
            : null
        }
        withTargetSpend={withTargetSpend}
        isGlobalProject={isGlobalProject || isProjectRequest}
      />
    </Popup>
  );
};

export default AddProjectPopup;

AddProjectPopup.propTypes = {
  closeAction: PropTypes.func,
  handleCreateProject: PropTypes.func,
  defaultData: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  withTargetSpend: PropTypes.bool,
  isGlobalProject: PropTypes.bool,
  isProjectRequest: PropTypes.bool,
  defaultType: PropTypes.any
};