import React from "react";
import styles from "./SelectedTaskDetails.module.scss";
import {trimRest} from "../../../../../helpers/stringHelper";
import PropTypes from "prop-types";
import TaskDetails from "./TaskDetails/TaskDetails";
import Surveys from "./Surveys/Surveys";
import Notes from "./Notes/Notes";
import StatusChanger from "../../../StatusChanger/StatusChanger";
import {getStatusData, statuses} from "../../../../containers/TaskDetailsContainer/helpers/getStatusData";
import {useSelector} from "react-redux";

const SelectedTaskDetails = (
  {
    data,
    openEditModal,
    useForProject,
    addNote,
    editNote,
    deleteNote,
    isHighRule,
    changeStatus,
    isResponsibleUser
  }
) => {
  const user = useSelector(state => state.user);

  return (
    <div className={styles.selectedTaskDetails}>
      <h3 title={data.name}>
        {trimRest(20, data.name)}
        {useForProject && (
          <StatusChanger
            status={getStatusData(data.status)}
            customStatuses={statuses}
            onChange={(isHighRule || isResponsibleUser || data.responsible_user?.id === user.id) ?
              status => changeStatus(data.id, status.id) : null}
            autoIndex
          />
        )}
      </h3>
      <TaskDetails data={data} openEditModal={openEditModal} useForProject={useForProject} isHighRule={isHighRule}/>
      <Surveys data={data?.surveys} useForProject={useForProject}/>
      {useForProject && (
        <Notes
          data={data?.notes}
          onAdd={values => addNote(data.id, values)}
          onEdit={(noteId, values) => editNote(noteId, values)}
          onDelete={deleteNote}
        />
      )}
    </div>
  );
};

export default SelectedTaskDetails;

SelectedTaskDetails.propTypes = {
  data: PropTypes.any,
  openEditModal: PropTypes.func,
  useForProject: PropTypes.bool,
  addNote: PropTypes.func,
  editNote: PropTypes.func,
  deleteNote: PropTypes.func,
  isHighRule: PropTypes.bool,
  changeStatus: PropTypes.func,
  isResponsibleUser: PropTypes.bool
};
