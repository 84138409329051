import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { object, string } from "yup"
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput"
import Button from "components/elements/Button/Button"
import services from "services"
import { useSelector } from "react-redux"

const AddProjectSupplierForm = ({
  suppliers,
  currentSuppliers,
  addHandler,
  onClose,
  onSearch
}) => {
  const [data, setData] = useState([])
  const [value, setValue] = useState("")
  const organiztionName = useSelector(state => state.user.organization.name)
  const isIndeedCopmany = organiztionName === "Indeed"

  useEffect(() => {
    const filteredSuppliers = suppliers.filter(
      supplier => !currentSuppliers.find(item => item.id === supplier.id)
    )
    setData(filteredSuppliers)
  }, [suppliers, currentSuppliers])

  const formik = useFormik({
    initialValues: {
      id: ""
    },
    validationSchema: object({
      id: string()
        .required("Please, choose the supplier")
        .nullable()
    }),
    onSubmit: values => {
      addHandler(values.id)
      onClose()
    }
  })
  const { handleSubmit, errors, setFieldValue, touched } = formik

  const handleEmptyTextClick = () => {
    services.supplierServices.createSupplier(value).then(res => {
      setData([...data, res.data])
    })
  }

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <AutoCompleteInput
        data={data}
        value={value}
        placeholder='Start typing supplier’s name'
        onChange={e => {
          setValue(e.target.value)
          onSearch(e.target.value)
        }}
        onSelect={value => {
          setFieldValue("id", value.id)
          setValue(value.name)
        }}
        label='Supplier'
        error={touched.id && errors.id}
        emptyText={{
          text: "No work item results found. You can ",
          linkText: "add new supplier"
        }}
        handleEmptyTextClick={handleEmptyTextClick}
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          Add
        </Button>
      </div>
    </form>
  )
}

export default AddProjectSupplierForm
