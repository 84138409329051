import React, {useState} from "react";
import baseStyle from "../TasksList.module.scss";
import {backgroundTypes} from "../../../../../../helpers/helpData";
import {trimRest} from "../../../../../../helpers/stringHelper";
import DropListTrigger from "../../../../DropListTrigger/DropListTrigger";
import TaskPopup from "../../../popups/TaskPopup";
import DeleteTypePopup from "../../../popups/DeleteTypePopup";
import PropTypes from "prop-types";
import {generateUniqueId} from "../../../../../../helpers/uniqueId";
import {useHistory, useParams} from "react-router-dom";

const Task = ({provided, task, tasksType, milestoneColor, deleteTask, editTask}) => {
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(null);
  const [taskPopUp, setTaskPopUp] = useState(null);
  const history = useHistory();
  const params = useParams();
  const preparedTask = {
    ...task,
    hyperlinks: task?.hyperlinks?.map(h => ({...h, id: generateUniqueId()})) ?? [],
    responsible_user: task.responsible_user ? {
      value: task.responsible_user.id,
      label: task.responsible_user.name
    } : null
  };

  return (
    <>
      <li
        onClick={() => history.push(`/admin-settings/project-types/${params.id}/configure_task/${task.id}`)}
        className={baseStyle.task}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <span style={{background: backgroundTypes[milestoneColor]}} className={baseStyle.line}/>
        <div className={baseStyle.taskWrapper}>
          <p>{trimRest(25, task.name)}</p>
          <DropListTrigger
            data={[
              {
                title: "Edit",
                onClick: () => setTaskPopUp("edit"),
              },
              {
                title: "Delete",
                onClick: () => setTaskPopUp("delete"),
              }
            ]}
            visibleMenu={visibleDropListMenu}
            setVisibleMenu={() => {
              if (visibleDropListMenu) {
                setVisibleDropListMenu(null);
              } else {
                setVisibleDropListMenu(true);
              }
            }}
          />
        </div>
      </li>
      {taskPopUp === "edit" && (
        <TaskPopup
          onClose={() => {
            setTaskPopUp(null);
          }}
          onFormSubmit={values => {
            editTask(values);
            setTaskPopUp(null);
          }}
          type={preparedTask}
          taskType={tasksType.replace("_", " ").slice(0, -1)}
        />
      )}
      {taskPopUp === "delete" && (
        <DeleteTypePopup
          onClose={() => setTaskPopUp(null)}
          onSubmit={() => {
            deleteTask();
            setTaskPopUp(null);
          }}
        />
      )}
    </>
  );
};

export default Task;

Task.propTypes = {
  provided: PropTypes.any,
  task: PropTypes.any,
  tasksType: PropTypes.string,
  milestoneColor: PropTypes.string,
  deleteTask: PropTypes.func,
  editTask: PropTypes.func,
};