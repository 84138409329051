import React, {useEffect, useReducer, useState} from "react";
import {useSelector} from "react-redux";
import s from "./UsersContainer.module.scss";
import services from "services";
import querystring from "query-string";
import {useLocation, useHistory} from "react-router-dom";
import UsersTabs from "./components/UsersTabs/UsersTabs";
import UsersFilter from "components/filters/UsersFilter/UsersFilter";
import Button from "components/elements/Button/Button";
import {
  formatFilters
} from "components/elements/FilterBar/helpers/helpers";
import {
  setFilters,
  setRequestData,
  setLoading
} from "./helpers/actions";
import {initialState, reducer} from "./helpers/reducer";
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar";
import {formatDepartmentsFilter} from "components/filters/helpers/formatDepartmentsFilter";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import qs from "query-string";

const UsersContainer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const organizationName = useSelector(state => state.user.organization.name);
  const {t} = useTranslation();
  const [savedFilter, setSavedFilter] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  useEffect(() => {
    getFilters();
    services.filterService.getFilter("users").then(res => {
      if (res.data.length > 0) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      } else {
        history.push({
          search: querystring.stringify({
            ...query,
            user_type: "procurement"
          })
        });
      }
    });

  }, []);

  useEffect(() => {
    const query = querystring.parse(location.search);
    if (Object.keys(query).length == 0) return;
    getUsers(query);
  }, [location.search]);

  const getFilters = async () => {
    const result = await services.userServices.getUsersFilters();
    const formattedResult = {
      ...result.data,
      departments: formatDepartmentsFilter(result.data.departments)
    };
    const formattedFilters = formatFilters(formattedResult);
    dispatch(setFilters(formattedFilters));
  };

  const getUsers = query => {
    const filters = {
      ...query
    };
    if (Object.keys(query).length === 1 && query.tab) dispatch(setLoading(true));
    services.userServices.getUsers(filters).then(res => {
      dispatch(
        setRequestData({
          ...res.data,
          loading: false,
          usersTotalCount: res.data.users_count
        })
      );
    });
  };

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  };
  const showDefaultFilter = filterParams => {

    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams ?? savedFilter.filter_params),
      page: 1,
      tab,
      sort_by,
      direction,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });

  };
  const saveDefaultFilter = () => {
    services.filterService.addFilter("users", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  };

  return (
    <section className={s.listOfUsersContainer}>
      <div className={s.wrap}>
        <h1 className={s.title} data-translate-key={getTranslateKey("Users")}>
          {t(getTranslateKey("Users"))}
        </h1>
        <Button
          styled="secondary"
          onClick={() => history.push("/users/resource-utilization/report")}
          className={s.button}
        >
          View resource utilization
        </Button>
      </div>
      {!Array.isArray(state.filters) && Object.keys(state.filters).length ? (
        <UsersFilter
          showDefaultFilter={showDefaultFilter}
          deleteDefaultFilter={deleteDefaultFilter}
          saveDefaultFilter={saveDefaultFilter}
          savedFilter={savedFilter}
          filters={state.filters}
          className={s.filterBar}
        />
      ) : (
        <FilterBarLoading length={2} className={s.filterBar}/>
      )}

      <div className={s.tableBlock}>
        <UsersTabs state={state}/>
      </div>
    </section>
  );
};

export default UsersContainer;
