import React, {useState} from "react";
import s from "./DashboardContainer.module.scss";
import MainDashboard from "./components/MainDashboard/MainDashboard";
import SidebarDashboard from "./components/SidebarDashboard/SidebarDashboard";
import {useSelector} from "react-redux";
import {userRoles, userTypes} from "../../../helpers/userPermissions/userRoles";
import BarDropDown from "../../elements/BarDropDown/BarDropDown";
import {dashboardTypes} from "./helpers/dashboardTypes";
import {useTranslate} from "../../../hooks/useTranslate";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";
import FilterBarDropdownYears from "../../filters/CategoriesFilter/FilterBarDropdownYears/FilterBarDropdownYears";

const DashboardContainer = () => {
  const user = useSelector(state => state.user);
  const {procurement, support, adminTypes} = userTypes;
  const [dashboardType, setDashboardType] = useState(dashboardTypes[0]);
  const [translateKey, translateValue] = useTranslate("Dashboard");
  const history = useHistory();
  const isSupportUser = user.roles.includes(userRoles.finance_user) ||
    user.roles.includes(userRoles.risk_user) ||
    user.roles.includes(userRoles.audit_user);

  const isDepartmentAndCEO = user.roles.includes(userRoles.cpo) ||
    user.roles.includes(userRoles.director) ||
    user.roles.includes(userRoles.admin);

  const isProcurement = user.roles.includes(userRoles.procurement_category_owner) ||
    user.roles.includes(userRoles.procurement_manager);

  const isBusinessUser = user.roles.includes(userRoles.business_user);

  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);

  const isFilteringByYear = user.user_type.includes(procurement)
    || user.user_type.includes(support)
    || user.user_type.includes(adminTypes);

  const handleChange = option => {
    if (option.value === "individual") {
      history.push({
        search: qs.stringify({}, {arrayFormat: "comma"})
      });
    }
    setDashboardType(option);
  };
  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );
  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  return (
    <section className={s.container}>
      <div className={s.headerWrapper}>
        <div className={s.titleYears}>
          <h3 className={s.title} data-translate-key={translateKey}>{translateValue}</h3>
          {isFilteringByYear && <FilterBarDropdownYears
            className={s.yearsOpenedList}
            name="Years"
            options={[2019, 2020, 2021, 2022, 2023, 2024]}
            defaultValues={query?.spend_year?.split(",")}
            handleChange={(values) => {
              handleChangeFilter("spend_year", values);
            }}
          />}
        </div>
        {(isProcurement || isDepartmentAndCEO) && (
          <BarDropDown
            name=""
            handleChange={value => handleChange(value)}
            options={dashboardTypes}
            defaultValues={dashboardType}
            withTranslate
          />
        )}
      </div>
      <div className={s.dashboardWrapper}>
        <MainDashboard
          rules={{
            isSupportUser,
            isDepartmentAndCEO,
            isProcurement,
            isBusinessUser,
            isCategoryOwner
          }}
          dashboardType={dashboardType.value}
        />
        {(dashboardType.value !== "team" && !isBusinessUser && !isCategoryOwner) && (
          <SidebarDashboard isSupportUser={isSupportUser}/>
        )}
      </div>
    </section>
  );
};

export default DashboardContainer;
