import React from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import {debounce} from "lodash";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";

const UsersFilter = ({filters, className, showDefaultFilter, deleteDefaultFilter, saveDefaultFilter, savedFilter}) => {
  const history = useHistory();
  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleClearAll = () => {
    history.push({
      search: qs.stringify({
        user_type: query.user_type
      })
    });
  };

  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);


  return (
    <FilterBar
      filterName="Filter users"
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      withSearch
    >
      <FilterBarDropdown
        name="Departments"
        searchPlaceholder="Search department"
        options={filters.departments}
        defaultValues={query?.department_ids}
        handleChange={(values) => {
          handleChangeFilter("department_ids", values);
        }}
      />
      <FilterBarDropdown
        name="Divisions"
        options={filters.divisions}
        defaultValues={query?.division_ids}
        handleChange={(values) => {
          handleChangeFilter("division_ids", values);
        }}
      />
    </FilterBar>
  );
};

export default UsersFilter;
