import React from "react";
import PropTypes from "prop-types";
import styles from "./AdditionalHeader.module.scss";
import classNames from "classnames";

const AdditionalHeader = ({questionName, isRequired, className}) => {
  return <p className={classNames(styles.title, className)}>
    {questionName}
    {isRequired && <sup>*</sup>}
  </p>;
};

export default AdditionalHeader;

AdditionalHeader.propTypes = {
  questionName: PropTypes.string,
  isRequired: PropTypes.bool,
};