import {number, object, string} from "yup";

export const validationSchema = object({
  start_date: string().typeError("Start date is required"),
  end_date: string().typeError("End date is required"),
  final_cost: number()
    .required("Final cost is required")
    .min(1, "Final cost is required"),
  final_savings: number()
    .required("Final savings is required")
    .min(1, "Final savings is required"),
  savings_type: object().typeError("Savings type is required"),
});