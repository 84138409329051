import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import services from "../../../../../services";
import styles from "../SupplierGeneral/SupplierGeneral.module.scss";
import SupplierServicesTable from "../../../../tables/SupplierServicesTable/SupplierServicesTable";
import AddServicePopup from "./AddServicePopup";
import EmptyBlock from "../../../../elements/EmptyBlock/EmptyBlock";
import {useSelector} from "react-redux";
import {userRoles} from "../../../../../helpers/userPermissions/userRoles";
import {appendFile} from "../../../../../helpers/appendFile";
import sort from "../../../../../helpers/sortHelper";
import EditServicePopup from "./EditServicePopup";
import {delay} from "../../../../../helpers/delay";
import Popup from "../../../../popups/Popup/Popup";
import NotificationBox from "../../../../elements/NotificationBox/NotificationBox";

const SupplierServices = ({supplier}) => {
  const params = useParams();
  const {roles, id} = useSelector(state => state.user);
  const {admin, cpo, procurement_admin} = userRoles;
  const [query, setQuery] = useState({});
  const [showDel, setShowDel] = useState(false);
  const [itemDelete, setItemDelete] = useState();
  const [showEditService, setShowEditService] = useState(false);
  const [showNewService, setShowNewService] = useState(false);
  const [data, setData] = useState(null);
  const [editServiceItem, setEditService] = useState(null);
  const ownerId = supplier?.owner && supplier.owner.id;
  const [alert, setAlert] = useState({message: "", type: ""});

  const load = () => {
    services.supplierServices
      .getSuppliersServices(params.id)
      .then(res => {
        setData(res.data);
      });
  };

  useEffect(() => {
    load();
  }, []);

  const postSuppliersNewServices = (param) => {
    const {name, description, attachments} = param;
    services.supplierServices.postSuppliersNewServices(params.id, {name, description, attachments})
      .then(res => {
        if (res.data) {
          Promise.all([...attachments.map(attachment => {
            const file = appendFile(attachment);
            services.taskServices.addAttachment(file, res.data.id).then(res => res.data);
          })])
            .then(() => {
              setData([...data, {id, name, description, attachments}]);
              delay(500).then(() => load());
              setShowNewService(false);
            })
            .catch((err) => {
              setAlert({
                message: err.response?.data.error ||
                  "Something went wrong. Please, try again.",
                type: "error"
              });
            });
          setAlert({
            type: "success",
            message: "New service have been successfully recorded."
          });
        }
      })
      .catch((err) => {
        setAlert({
          message: err.response?.data.error ||
            "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };
  const onSort = ({sort_by, direction}) => {
    if (sort_by === "category" || sort_by === "status" || sort_by === "owner")
      setData(sort.sortString(data, {field: sort_by, direction}));
    else {
      setData(sort.sortNumber(data, {field: sort_by, direction}));
    }
  };

  const deleteService = () => {
    services.supplierServices
      .deleteSuppliersNewServices(params.id, itemDelete.id, {"service_id": itemDelete.id})
      .then(() => {
        setData(data.filter((item) => item.id !== itemDelete.id));
      });
    setShowDel(false);
  };
  const editService = ({name, description, attachments, serviceId}) => {
    services.supplierServices.editSuppliersServices(params.id, {name, description, attachments, serviceId})
      .then(res => {
        if (res.data)
          Promise.all([...attachments.map(attachment => {
            const file = appendFile(attachment);
            services.taskServices.addAttachment(file, res.data.id).then(res => res.data);
          })])
            .then(res => res.data)
            .catch((err) => {
              setAlert({
                message: err.response?.data.error ||
                  "Something went wrong. Please, try again.",
                type: "error"
              });
            })
            .finally(() => delay(500).then(() => load()));
        setAlert({
          type: "success",
          message: "The editing service has been successfully changed."
        });
        setData(data.map(element => element.id === res.data.id ? res.data : element));
        setShowEditService(false);
      }).catch((err) => {
      setAlert({
        message: err.response?.data.error ||
          "Something went wrong. Please, try again.",
        type: "error"
      });
    });
  };

  const renderTable = () => {
    const allows = roles === admin || roles === cpo || roles === procurement_admin || id === ownerId;

    if (data?.length > 0) return (
      <SupplierServicesTable
        data={data}
        query={query}
        changeQuery={setQuery}
        className={styles.supplierGeneralTable}
        supplier={supplier}
        setShowNewService={setShowNewService}
        onSort={onSort}
        setShowDel={setShowDel}
        editService={setEditService}
        setShowEditService={setShowEditService}
        setItemDelete={setItemDelete}
      />
    );

    if (allows) return (
      <EmptyBlock
        subtitle="The supplier doesn’t have any services."
        buttonTitle="New service"
        onClick={() => setShowNewService(true)}
      />
    );

    return (
      <EmptyBlock
        subtitle="The supplier doesn’t have any services."
        withBtn={false}
        onClick={() => setShowNewService(true)}
      />
    );
  };

  return (
    <Fragment>
      {renderTable()}
      {showNewService && (
        <AddServicePopup
          addSupplierService={postSuppliersNewServices}
          setShowNewService={setShowNewService}
        />
      )}
      {showEditService && (
        <EditServicePopup
          editService={service => {
            let a = data.findIndex((e) => service.id === e.id);
            setData(data[a] = service);
          }}
          editSupplierService={editService}
          editServiceItem={editServiceItem}
          setShowEditService={setShowEditService}
        />
      )}
      {showDel && (
        <Popup
          title="Delete service"
          onClose={() => setShowDel(false)}
          text="Are you sure you want to delete this service?"
          dialog
          isDelete
          onClick={deleteService}
        >
        < /Popup>
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </Fragment>
  );
};
export default SupplierServices;