import React from "react";
import classNames from "classnames";
import VideoPlayer from "components/elements/VideoPlayer/VideoPlayer";
import s from "./OurCompany.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const OurCompany = () => {
  const {t} = useTranslation();
  return (
    <section className={s.ourCompany}>
      <div className={s.landingContainer}>
        <h2 className={s.title} data-translate-key={"about-us"}>
          {t(getTranslateKey("about-us"))}
        </h2>

        <div className={s.wrapper}>
          <div className={s.description}>
            <h2 className={classNames(s.title, s.subtitle)} data-translate-key={"our-company"}>
              {t(getTranslateKey("our-company"))}
            </h2>

            <p className={s.text}
               data-translate-key={"founded-by-procurement-veterans-focal-point-is-uniquely-positioned-to-solve-the-issues-that-procurement-faces-today."}>
              {t(getTranslateKey("founded-by-procurement-veterans-focal-point-is-uniquely-positioned-to-solve-the-issues-that-procurement-faces-today."))}
            </p>

            <p className={s.text}
               data-translate-key={"our-product-allows-organizations-to-maximize-the-value-of-every-dollar-spent-on-third-parties,-while-ensuring-the-safety-and-security-of-transactions,-data-and-enhancing-the-customer-experience."}>
              {t(getTranslateKey("our-product-allows-organizations-to-maximize-the-value-of-every-dollar-spent-on-third-parties,-while-ensuring-the-safety-and-security-of-transactions,-data-and-enhancing-the-customer-experience."))}
            </p>
          </div>

          <div className={s.videoBlock}>
            <VideoPlayer
              link="https://video.wixstatic.com/video/eb30b9_d6adce7505f440c8be32248fb3eb1db1/1080p/mp4/file.mp4"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurCompany;
