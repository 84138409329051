import React, {useEffect, useRef, useState} from 'react';
import styles from "./EmailFields.module.scss";
import {ReactComponent as ArrowDown} from "../../../../assets/icons/arrowDown.svg";
import useOutsideClicker from "../../../../hooks/useOutsideClicker";
import classNames from "classnames";
import services from "../../../../services";
import {useParams} from "react-router-dom";
import CustomNotification from "../../../elements/QuestionNotification/CustomNotification";

const EmailFields = () => {
  const params = useParams();
  const [isOpen, setOpen] = useState(false);
  const [fields, setFields] = useState([]);
  const groupedRef = useRef();

  useEffect(() => {
    services.emailTemplatesServices.getEmailFields(params.id).then(res => {
      if (res.data) {
        setFields(res.data);
      }
    });
  }, []);

  useOutsideClicker(groupedRef, () => {
    setOpen(false);
  });

  return (
    <div className={styles.emailFieldsWrap} ref={groupedRef}>
      <div className={styles.dropDownWrap}>
        <div
          className={classNames(styles.emailFieldsDropDown, {[styles.opened]: isOpen})}
          onClick={() => setOpen((prevIsOpen) => !prevIsOpen)}
        >
          Email fields
          <ArrowDown/>
        </div>
        <CustomNotification
          withTranslate
          value="Click to any field and Ctrl+V for use in template"
        />
      </div>
      {
        (isOpen && fields.length > 0) && (
          <div className={styles.fields}>
            {fields.map(field => (
              <div
                key={field.id}
                className={styles.item}
                onClick={() => {
                  navigator.clipboard.writeText(`|{${field.id}}|`);
                  setOpen(false);
                }}
              >
                {field.name}
              </div>
            ))}
          </div>
        )
      }
    </div>
  );
};

export default EmailFields;