import React, {useEffect, useState} from "react";
import s from "./EditProjectContainer.module.scss";
import ProjectForm from "components/forms/ProjectRequestActionsForm/ProjectForm";
import PropTypes from "prop-types";
import {CircularProgress} from "@material-ui/core";
import {currencyData} from "../../../helpers/helpData";
import services from "../../../services";
import {formatEntityData, formatEntityToObject} from "../../../helpers/formatEntityData";
import {useTranslation} from "react-i18next";

const EditProjectContainer = ({projectId}) => {
  const [project, setProject] = useState();
  const [stages, setStages] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    services.projectServices.getProject(projectId).then(res => {
      setProject(res.data);
    });
    services.projectServices.getStages().then(res => {
      setStages(
        res.data?.stages?.map(({id, name}) => ({
          id,
          label: name,
          value: name
        })) || []
      );
    });
  }, []);

  const getProjectParams = () => {
    return {
      id: project.id,
      category_id: project.category,
      stage: {
        id: project.stage.id,
        label: project.stage.name,
        value: project.stage.name
      },
      project_type: {
        id: project.project_type.id,
        value: project.project_type.name,
        label: project.project_type.name
      },
      name: project.name,
      budget_value: project.budget_spend,
      description: project.description ?? "",
      budget_currency: currencyData.find(e => e.label === project.budget_spend_currency) ||
        currencyData[currencyData.length - 1],
      owner_id: project.owner,
      start_date: new Date(project.start_date),
      end_date: new Date(project.end_date),
      target_spend_currency: currencyData.find(e => e.label === project.target_spend_currency) ||
        currencyData[currencyData.length - 1],
      target_spend: project.target_spend,
      attachments: project.attachments,
      clients: formatEntityToObject(formatEntityData(project.departments)),
      suppliers: project.suppliers.map(supplier => ({label: supplier.name, value: supplier.id})),
      stakeholders: formatEntityToObject(formatEntityData(project.stakeholders)),
    };
  };

  if (!project) return (
    <div className={s.loader}>
      <CircularProgress/>
    </div>
  );

  return (
    <div className={s.wrap}>
      <h2 className={s.title} data-translate-key="edit-project">
        {t("edit-project")}
      </h2>
      {stages && <ProjectForm
        initialProject={getProjectParams()}
        stages={stages}
        preparedClients={project.departments}
        preparedStakeholders={project.suppliers}
      />}
    </div>
  );
};

export default EditProjectContainer;

EditProjectContainer.propTypes = {
  projectId: PropTypes.string,
};