import React from 'react';
import SavingWidget from "../SavingWidget/SavingWidget";
import styles from "../FinancialBenefitsDetailsContainer.module.scss";
import {CircularProgress} from "@material-ui/core";
import classNames from "classnames";

const SplitBenefits = ({data, loading}) => {

  if (loading) {
    return (
      <div className={styles.widgets}>
        <div className={classNames("loaderWrap justifyCenter", styles.widget)}>
          <CircularProgress/>
        </div>
        <div className={classNames("loaderWrap justifyCenter", styles.widget)}>
          <CircularProgress/>
        </div>
        <div className={classNames("loaderWrap justifyCenter", styles.widget)}>
          <CircularProgress/>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.widgetsWrap}>
      <h2>Split benefits</h2>
      <div className={styles.widgets}>
        <SavingWidget
          className={styles.widget}
          type="department"
          data={data.savings.departments}
          title="By Departments"
          currency={data.project_spend_currency}
          finalSaving={data.project_savings}
        />
        <SavingWidget
          className={styles.widget}
          type="division"
          data={data.savings.divisions}
          title="By Divisions"
          currency={data.project_spend_currency}
          finalSaving={data.project_savings}
        />
        <SavingWidget
          className={styles.widget}
          type="procurement_user"
          typeWidget="dots"
          data={data.savings.procurement_users}
          title="By Procurement Users"
          currency={data.project_spend_currency}
          finalSaving={data.project_savings}
        />
      </div>
    </div>
  );
};

export default SplitBenefits;