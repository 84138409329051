import React from 'react'
import s from '../PrivacyPage.module.scss'

const Intro = () => {
	return (
		<>
		<p className={s.text}>
              Thank you for choosing to be part of our community at Focal Point
              Procurement Solutions Inc., doing business as Focal Point ({" "}
              <span>"Focal Point", "we", "us", "our"</span> ). We are committed
              to protecting your personal information and your right to privacy.
              If you have any questions or concerns about this privacy notice,
              or our practices with regards to your personal information, please
              contact us at{" "}
              <a
                href="mailto:info@focalpointprocurement.com"
                target="_blank"
                rel="noopener noreferrer"
                className={s.link}
              >
                info@focalpointprocurement.com.
              </a>
            </p>

            <p className={s.text}>
              When you visit our website{" "}
              <a
                href="http://www.focalpointprocurement.com"
                target="_blank"
                rel="noopener noreferrer"
                className={s.link}
              >
                http://www.focalpointprocurement.com
              </a>
              (the <span>"Website"</span> ), and more generally, use any of our
              services (the <span>"Services"</span>, which include the Website),
              we appreciate that you are trusting us with your personal
              information. We take your privacy very seriously. In this privacy
              notice, we seek to explain to you in the clearest way possible
              what information we collect, how we use it and what rights you
              have in relation to it. We hope you take some time to read through
              it carefully, as it is important. If there are any terms in this
              privacy notice that you do not agree with, please discontinue use
              of our Services immediately.
            </p>

            <p className={s.text}>
              This privacy notice applies to all information collected through
              our Services (which, as described above, includes our Website), as
              well as, any related services, sales, marketing or events.
            </p>

            <p className={s.text}>
              <span>
                Please read this privacy notice carefully as it will help you
                understand what we do with the information that we collect.
              </span>
            </p>	
		</>
	)
}

export default Intro
