import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import {useParams} from "react-router-dom";
import ModeContextProvider from "../../../contexts/ModeContext/ModeContext";
import {mods} from "../../../contexts/ModeContext/mods";
import AddApprovalContainer from "../../containers/AddApprovalContainer/AddApprovalContainer";

const AddApprovalPage = () => {
  const params = useParams();

  let breadcrumbs = [
    {name: "Projects", path: paths.projects},
    {
      name: "Project details",
      path: `/projects/${params.projectId}`,
    },
    {
      name: "Milestones",
      path: `/projects/${params.projectId}?tab=Milestones`,
    },
    {name: "New approval step", path: "", disabled: true},
  ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <ModeContextProvider defaultMode={mods.edit}>
        <AddApprovalContainer/>
      </ModeContextProvider>
    </DefaultLayout>
  );
};

export default AddApprovalPage;
