import React, {useEffect, useState} from "react";
import ActualSavingsMonthTable from "../../tables/ProjectActualSavingsTable/ActualSavingsMonthTable";
import s from "./ProjectActualSavingsContainer.module.scss";
import ActualSavingsFilter from "../../filters/ActualSavingsFilter/ActualSavingsFilter";
import services from "../../../services";
import ActualSavingsChart from "./ActualSavingsChart";
import {useHistory, useParams} from "react-router-dom";
import querystring from "query-string";
import {useDidUpdate} from "../../../hooks/useDidUpdate";

const ProjectActualSavingsContainer = () => {
    const params = useParams();
    const history = useHistory();
    const query = querystring.parse(history.location.search) || {};
    const [actualSavingsAll, setActualSavingsAll] = useState([]);
    const currentYear = new Date().getFullYear();
    const [preparedData, setPreparedData] = useState([]);
    const [savingsGroups, setSavingsGroups] = useState([]);
    const [project, setProject] = useState({});

    useEffect(() => {
      services.savingsGroupServices.getSavingsGroup().then(res => {
        if (res.data) {
          setSavingsGroups(res.data?.saving_groups ?? []);
        }
      });
      services.projectServices.getProject(params.id).then(res => {
        setProject(res.data);
      });

      services.projectServices.getProjectActualSavingsMonth(params.id, currentYear)
        .then(res => {
          setActualSavingsAll(res.data[currentYear] ? res.data[currentYear].savings : []);
        });
    }, []);

    let result;

    if (actualSavingsAll.length > 0) {
      result = actualSavingsAll.reduce((acc, item) => {
        const date = item.group_name;
        if (acc[date]) {
          acc  [date].push(item);
        } else {
          acc[date] = [item];
        }
        return acc;
      }, {});
    }

    const load = (query) => {
      services.projectServices.getProjectActualSavingsMonth(params.id, {...query})
        .then(res => {
          const prepareData = [];
          Object.entries(res.data).forEach(([year, {savings, total, ...yearData}]) => {
            prepareData.push(...savings, {year, ...total, ...yearData});
          });
          prepareData.pop();
          setPreparedData(prepareData);
        });
    };
    useDidUpdate(() => {
      load(query);
    }, [params.id, query?.savings_group_ids]);
    const savingChart = () => {
      if (result) {
        return Object.keys(result).map((key) => {
          return (
            <ActualSavingsChart
              className={s.actualSavingsFilter}
              savingsData={result[key]}
              savingName={key}
              currentYear={currentYear}
            />
          );
        });
      }
    };

    return (
      <div className={s.wrap}>
        <h3 className={s.title}>Actual savings</h3>
        {actualSavingsAll.length > 0 && (
          savingChart()
        )}
        <ActualSavingsFilter
          actualSavingsGroup={savingsGroups.filter(group => actualSavingsAll.some(item => item.group_name === group.name))}
        />
        {project.owner?.id &&
          <ActualSavingsMonthTable
            project={project}
            className={s.table}
            data={preparedData}
            actualSavings={actualSavingsAll}
            load={load}
            savingsGr={savingsGroups.filter(group => actualSavingsAll.some(item => item.group_name === group.name))}
          />
        }
      </div>
    );
  }
;
export default ProjectActualSavingsContainer;