import React from "react";
import Popup from "components/popups/Popup/Popup";
import PropTypes from "prop-types";

const DeleteNonFinancialSavingsTypePopup = ({onClose, onSubmit}) => {
  return (
    <Popup
      onClose={onClose}
      onClick={onSubmit}
      title="Delete non-financial savings type"
      text="Are you sure you want to delete this savings type?"
      isDelete
      dialog
    />
  );
};

export default DeleteNonFinancialSavingsTypePopup;


DeleteNonFinancialSavingsTypePopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};