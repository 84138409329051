import { load } from "helpers/localStorage";

const companyName = load("company") || "";

const defaultNames = {
  title: "Title",
  category: "Category",
  procurementOwner: "Procurement Owner",
  businessOwner: "Business Owner",
  targetSpend: "Target Spend",
  targetSavings: "Target Savings",
  targetSavingsExtra: "(Counted as the difference between Baseline spend and Target spend)",
  finalCost: "Final Cost",
  finalSavings: "Final Savings",
  savingstype: "Savings type",
  status: "Status",
  targetEndDate: "Target End date",
  notes: "Notes",
};

const indeedNames = {
  title: "Title",
  category: "Category",
  procurementOwner: "Procurement Owner",
  businessOwner: "Business Owner",
  targetSpend: "Target Spend",
  targetSavings: "Target Savings",
  targetSavingsExtra: "(Counted as the difference between Baseline spend and Target spend)",
  finalCost: "Final Cost",
  finalSavings: "Final Savings",
  savingstype: "Savings Class",
  status: "Status",
  targetEndDate: "Target DI5 Date",
  notes: "Comments",
};

const riotGamesNames = {
  title: "Project name",
  category: "Commodity(Parent)",
  procurementOwner: "Sourcer",
  businessOwner: "Deal Owner",
  targetSpend: "Estimated project cost",
  targetSavings: "Target Savings",
  targetSavingsExtra: "(Counted as the difference between Baseline spend and Target spend)",
  finalCost: "Final Cost",
  finalSavings: "Total Savings",
  savingstype: "Savings type",
  status: "Project Status",
  targetEndDate: "Estimated Completion Date",
  notes: "Notes",
};

export const localization = (() => {
  switch (companyName) {
    case "Indeed":
      return indeedNames;
    case "Riot Games":
      return riotGamesNames;
    default:
      return defaultNames;
  }
})();
