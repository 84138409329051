import React, {useEffect, useState} from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import {useParams} from "react-router-dom";
import ApprovalDetailsContainer from "../../containers/ApprovalDetailsContainer/ApprovalDetailsContainer";
import services from "../../../services";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";

const ApprovalDetailsPage = () => {
  const {projectId, id, milestoneId} = useParams();
  const [data, setData] = useState(null);
  const user = useSelector(state => state?.user);
  const isBusinessUser = user.roles.includes(userRoles.business_user);
  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);

  const load = () => {
    services.approvalServices.getApprovalDetails(milestoneId, id).then(res => {
      setData(res.data);
    });
  }

  useEffect(() => {
    load();
  }, []);

  let breadcrumbs = [
    {
      path: paths.projects,
      name: "Projects",
      disabled: (isBusinessUser || isCategoryOwner),
    },
    {
      name: "Project details",
      path: `/projects/${projectId}`,
    },
    {
      name: "Milestones",
      path: `/projects/${projectId}?tab=Milestones`
    },
    {
      name: data?.name ?? "Approval",
    }
  ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <ApprovalDetailsContainer data={data} load={load}/>
    </DefaultLayout>
  );
};

export default ApprovalDetailsPage;
