import React from "react";
import styles from "./Question.module.scss";
import { Arrow } from "../Arrow/Arrow";

export const Question = ({ quest, arrowArr, setArrowArr }) => {
  return (
    <>
      <div className={styles.questCont}>
        <div>{quest?.question?.toString() || ""}</div>
        <div>
          {typeof quest?.question_type === "string"
            ? quest?.question_type
            : quest?.question_type?.label?.toString() || ""}
        </div>
        <div>{quest?.weight?.toString() || ""}</div>
      </div>
      <Arrow arrowArr={arrowArr} setArrowArr={setArrowArr} quest={quest} />
    </>
  );
};
