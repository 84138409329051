import React from "react";
import PropTypes from "prop-types";
import DatePicker from "../../../../../elements/DatePicker/DatePicker";
import {formatDate} from "../../../../../../helpers/dateFormat";
import classNames from "classnames";
import styles from "./DateField.module.scss";

const DateField = ({values, setFieldValue, field, errors}) => {
  const date = new Date();
  const fromDate = date.setFullYear(date.getFullYear());
  const toDate = date.setFullYear(date.getFullYear());
  const correctFieldKey = field.key ? field.key : field.value;

  return (
    <div className={classNames("dateBlock", styles.dateWrap)}>
      <DatePicker
        label={field.label}
        value={values[correctFieldKey]?.value}
        onChange={date => setFieldValue(correctFieldKey, {
          value: formatDate(date, true),
          type: "Date",
          conditions: field.conditions
        })}
        selectsStart
        startDate={fromDate}
        endDate={toDate}
        wrapperClassName="wrapperdatePicker"
        className="customInput"
        todayButton="Go to today"
        showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={5}
        scrollableYearDropdown
        withoutTranslate
        error={errors?.[correctFieldKey]?.value}
      />
    </div>
  );
};

export default DateField;

DateField.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any
};