import React, {useEffect, useState} from 'react';
import s from "./ProjectStatuses.module.scss";
import {useParams} from "react-router-dom";
import services from "../../../../../../../../services";
import ProjectStatusesSelector from "./ProjectStatusesSelector";
import {getTranslateKey} from "../../../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const ProjectStatuses = ({projectOwnerId,status}) => {
  const params = useParams();
  const [customSelectors, setCustomSelectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentOpened, setCurrentOpened] = useState(null);
  const {t} =useTranslation();

  useEffect(() => {
    services.customStatusesServices.getProjectCustomSelectors(params.id).then(res => {
      setCustomSelectors(res.data);
      setLoading(false);
    });
  }, []);

  const changeProjectSelectorOption = (selectorId, optionId) => {
    services.customStatusesServices.changeProjectSelectorOption(params.id, selectorId, optionId).then(res => {
      if (res.data) {
        setCustomSelectors(customSelectors.map(selector => selector.id === selectorId ? res.data : selector));
      }
    });
    setCurrentOpened(null);
  }

  if (!customSelectors.length || loading) return "";

  return (
    <div className={s.projectStatuses}>
      <h3 data-translate-key={getTranslateKey("List of statuses")}>
        {t(getTranslateKey("List of statuses"))}
      </h3>
      <div className={s.selectors}>
        {customSelectors.map(selector => (
          <ProjectStatusesSelector
            data={selector}
            projectOwnerId={projectOwnerId}
            setOpen={value => setCurrentOpened(value)}
            changeSelectorOption={optionId => changeProjectSelectorOption(selector.id, optionId)}
            isOpen={currentOpened === selector.id}
            status={status}
            key={selector.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectStatuses;