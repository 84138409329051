import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { formatCategoryName } from "./formatCategoryName";
import s from "./Navigation.module.scss";
import { ReactComponent as Toggle } from "assets/icons/sort.svg";
import Input from "components/elements/Input/Input";
import TreeItem from "components/elements/TreeItem/TreeItem";
import { searchCategories, openedCategories } from "helpers/categoriesHelper";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const Navigation = ({ categories, currentCategoryId }) => {
  const [searchValue, setSearchValue] = useState("");
  const {t} = useTranslation();
  const [searchedCategories, setSearchedCategories] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (categories.length) {
      setSearchedCategories(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (currentCategoryId && categories) {
      setSearchedCategories(openedCategories(categories, currentCategoryId));
    }
  }, [currentCategoryId]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length) {
      const result = searchCategories(e.target.value, [...categories]);
      setSearchedCategories(result);
    } else {
      setSearchedCategories(categories);
    }
  };

  return (
    <div className={s.navigation}>
      <div className={classNames(s.list, s.openedList)}>
        <span className={s.title} data-translate-key={getTranslateKey("Categories")}>
          {t(getTranslateKey("Categories"))}
        </span>
        <Input
          icon
          value={searchValue}
          placeholder="Search category"
          onChange={handleSearch}
          className={s.inputSearch}
        />
        {searchedCategories.length ? (
          <ul className={s.categoriesNavigation}>
            {searchedCategories.map((item) => (
              <div key={item.id} className={s.item}>
                <TreeItem
                  item={{
                    ...item,
                    name: formatCategoryName(item.name),
                  }}
                  currentCategoryId={currentCategoryId}
                  handleClick={(item) => {
                    if (item.id !== currentCategoryId) {
                      history.push(`/categories/${item.id}`);
                    }
                  }}
                />
              </div>
            ))}
          </ul>
        ) : (
          <p className={s.noResults} data-translate-key={getTranslateKey("No work item results found")}>
            {t(getTranslateKey("No work item results found"))}
          </p>
        )}
      </div>
    </div>
  );
};
Navigation.propTypes = {
  categories: PropTypes.array,
  currentCategoryId: PropTypes.string,
};

export default Navigation;
