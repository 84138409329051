import React from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import qs from "query-string";
import {debounce} from "lodash";
import {useHistory} from "react-router-dom";

const SystemReportsDepartmentsFilter = ({className}) => {
  const history = useHistory()
  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  )

  const handleClearAll = () => {
    const searchQuery = {page, tab, sort_by, direction}
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    })
  };

  const handleSearch = debounce(term => {
    history.push({
      search: qs.stringify(
        {...query, query: term},
        {
          arrayFormat: "comma"
        }
      )
    })
  }, 300);

  return (
    <FilterBar
      filterName='Filter departments'
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      withSearch
    >
    </FilterBar>
  )
}

export default SystemReportsDepartmentsFilter
