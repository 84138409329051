import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./Status.module.scss";
import {useTranslate} from "../../../hooks/useTranslate";

const Status = ({status, type = "", background, withTranslate}) => {
  const [translateKey, translateValue] = useTranslate(status?.split("_").join(" "));

  return (
    <span
      className={classNames(s.status, s[status + type])}
      style={{background: background?.replace(/_/g, "")}}
      data-translate-key={withTranslate && translateKey}
    >
      {withTranslate ? translateValue : status}
    </span>
  );
};

Status.propTypes = {
  status: PropTypes.string,
};

export default Status;
