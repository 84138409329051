import React from 'react'
import s from '../PrivacyPage.module.scss'

const Point05 = () => {
	return (
		<div id="point05" className={s.pointBlock}>
              <h3 className={s.subtitle}>
                5. HOW LONG DO WE KEEP YOUR INFORMATION?
              </h3>

              <p className={s.text} style={{ fontStyle: "italic" }}>
                <span>In Short: </span>We keep your information for as long as
                necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.
              </p>

              <p className={s.text}>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than 2 years.aw.
              </p>

              <p className={s.text}>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
            </div>
	)
}

export default Point05
