import React, {useState, useCallback} from "react";
import {debounce} from "lodash";
import {useFormik} from "formik";
import styles from "./EditSupplierForm.module.scss";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import services from "services/index";
import {supplierStatuses as statuses} from "helpers/helpData.js";
import CustomDropdown from "../../elements/CustomDropdown/CustomDropdown";
import {userRoles} from "../../../helpers/usersData";

const EditSupplierForm = ({data, onSubmit, onClose}) => {
  const [users, setUsers] = useState([]);
  const procurementUserRoles = userRoles
      .filter(role => (role.value !== "business_user"))
      .map(role => role.value)
      .join(",");

  const formik = useFormik({
    initialValues: {
      status: statuses.find((s) => s.value === data.status) || statuses[0],
      owner_id: {name: data.owner?.name || "", id: data.owner?.id},
    },

    onSubmit: (values) => {
      onSubmit({
        owner_id: values.owner_id.id,
      });
      onClose();
    },
  });
  const {handleSubmit, touched, values, errors, setFieldValue} = formik;

  const searchHandler = useCallback(
    debounce(
      (term, roles) =>
        services.userServices
          .stakeholdersAutocomplete({query: term, roles})
          .then((res) => setUsers(res.data)),
      300
    ),
    []
  );
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className={styles.inputWrap}>
        {
          data.category_suppliers.length === 0 && (
            <CustomDropdown
              options={statuses}
              value={values.status.value}
              onChange={(option) => setFieldValue("status", option)}
              label="Status"
            />
          )
        }
      </div>
      <div className={styles.inputWrap}>
        <AutoCompleteInput
          data={users}
          value={values.owner_id.name}
          placeholder="Start typing user’s name"
          onChange={(e) => {
            setFieldValue("owner_id", {
              id: "",
              name: e.target.value,
            });
            searchHandler(e.target.value, procurementUserRoles);
          }}
          onSelect={(value) => {
            setFieldValue("owner_id", {
              id: value.id,
              name: value.name,
            });
          }}
          label="Owner"
          emptyText={{
            text: "No work item results found.",
          }}
        />
      </div>

      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Save
        </Button>
      </div>
    </form>
  );
};

export default EditSupplierForm;
