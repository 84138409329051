import React from "react";
import s from "./QuestionsContent.module.scss";
import {Button} from "@material-ui/core";
import plus from "../../../../../assets/icons/grey-plus.svg";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {statuses} from "../../helpers/types";
import SingleQuestion from "./SingleQuestion/SingleQuestion";
import {addQuestionLocal, updateLocalQuestions} from "../../helpers/actions";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const QuestionsContent = ({status, questionsList, dispatch}) => {
  const {t} = useTranslation();
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(questionsList, result.source.index, result.destination.index);
    updateLocalQuestions(dispatch, items.map((i, index) => {
      return {
        ...i,
        question_position: index
      };
    }));
  };

  return (
    <div className={s.questionsContent}>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId="droppable" isDropDisabled={status === statuses.static}>
          {provided => (
            <div ref={provided.innerRef}{...provided.droppableProps}>
              {questionsList.map((q, index) => (
                <Draggable key={q.question_id} draggableId={`${q.question_id}`} index={index}>
                  {
                    provided =>
                      <SingleQuestion
                        question={q}
                        dispatch={dispatch}
                        provided={provided}
                        status={status}
                        questionsLength={questionsList.length}
                      />
                  }
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {
        status === statuses.edit &&
        <Button className={s.addNewQuestion}
                onClick={() => addQuestionLocal(dispatch, questionsList.length)}
                data-translate-key="add-new-question">
          <img src={plus} className={s.iconPlus}/>
          {t("add-new-question")}
        </Button>
      }
    </div>
  );
};

export default QuestionsContent;

QuestionsContent.propTypes = {
  status: PropTypes.any,
  questionsList: PropTypes.array,
  dispatch: PropTypes.any,
};