import React from "react";
import { useSelector } from "react-redux";
import styles from "./UserTabs.module.scss";
import Tabs from "components/elements/Tabs/Tabs";
import Tab from "components/elements/Tabs/Tab/Tab";
import UserProjects from "../UserProjects/UserProjects";
import UserOrgChart from "../UserOrgChart/UserOrgChart";

const UserTabs = () => {
  const organizationName = useSelector((state) => state.user.organization.name);

  return (
    <div className={styles.tabs}>
      <Tabs defaultActiveTab="Projects">
        <Tab label="Org chart">
          <UserOrgChart />
        </Tab>
        <Tab label="Categories" disabled>
          none
        </Tab>
        <Tab label="Suppliers" disabled>
          none
        </Tab>
        <Tab label="Contracts" disabled={true || organizationName === "Indeed"}>
          none
        </Tab>
        <Tab label="Projects">
          <UserProjects />
        </Tab>
        
      </Tabs>
    </div>
  );
};

export default UserTabs;
