import React, { useEffect } from "react";
import ProcurementTogether from "./components/ProcurementTogether/ProcurementTogether";
import IncreasingProcurement from "./components/IncreasingProcurement/IncreasingProcurement";
import Benefits from "./components/Benefits/Benefits";
import Features from "./components/Features/Features";
import ScheduleADemo from "./components/ScheduleADemo/ScheduleADemo";
import ManagementOverview from "./components/ManagementOverview/ManagementOverview";
import ContactUs from "../../components/ContactUs/ContactUs";
import LandingLayout from "../../Landinglayout/Landinglayout";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <>
      <LandingLayout>
        <ProcurementTogether />
        <IncreasingProcurement />
        <Benefits />
        <Features />
        <ScheduleADemo />
        <ManagementOverview />
        <ContactUs />
      </LandingLayout>
    </>
  );
};

export default LandingPage;
