import React from "react";
import s from "./RightSidebarOpener.module.scss";
import { ReactComponent as CloseIcon } from "assets/icons/sort.svg";
import PropTypes from "prop-types";

const RightSidebarOpener = ({ children, onClose }) => {
  return (
    <div className={s.rightSidebarOpener}>
      {children}
      <button className={s.closeBtn} onClick={onClose}>
        <CloseIcon />
      </button>
    </div>
  );
};
RightSidebarOpener.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
};
RightSidebarOpener.defaultProps = {
  onClose: () => {},
};
export default RightSidebarOpener;
