import React from "react";
import Popup from "components/popups/Popup/Popup";
import AdminSettingsProjectGroupForm from "components/forms/AdminSettingsProjectGroupForm/AdminSettingsProjectGroupForm";

const EditProjectGroupPopup = ({ onClose, onSubmit, defaultData }) => {
  return (
    <Popup onClose={onClose} title="Edit project group">
      <AdminSettingsProjectGroupForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultData={defaultData}
      />
    </Popup>
  );
};

export default EditProjectGroupPopup;
