export default (data) => {
  const result = [[{ type: "string", label: "" }, "Result"]];
  let length = 0;

  data.forEach((item) => {
    if (item.value) {
      result.push([item.stakeholder, item.value]);
      length++;
    }
  });

  length === 1 && result.push([" ", null], [" ", null], [" ", null]); // need for good styling when only one stakeholder

  return result;
};
