import axios from "./axios";

export default {
  getCurrencies: async () => {
    try {
      const result = await axios.get(`/currencies`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getAdminCurrencies: async () => {
    try {
      const result = await axios.get(`/admin/currencies`);
      return result;
    } catch (error) {
      throw error;
    }
  },
};
