import React, { useState, useEffect } from "react";
import classNames from "classnames";
import s from "./AssignToProjectForm.module.scss";
import { useFormik } from "formik";
import { object, string } from "yup";
import Button from "components/elements/Button/Button";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Input from "components/elements/Input/Input";
import services from "services";
import {useTranslation} from "react-i18next";

const AssignToProjectForm = ({ onClose, onSubmit }) => {
  const [mode, setMode] = useState("exist");
  const [projectVal, setProjectVal] = useState("");
  const [projects, setProjects] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    services.projectServices
      .projectsAutocomplete({ query: projectVal })
      .then((res) => setProjects(res.data));
  }, [projectVal]);
  const formik = useFormik({
    initialValues:
      mode === "exist"
        ? {
            project: {
              id: "",
              name: "",
            },
          }
        : {
            name: "",
          },
    validationSchema:
      mode === "exist"
        ? object({
            project: object({
              id: string().required("Project is required"),
            }),
          })
        : object({
            name: string()
              .required("Project title is required")
              .max(
                30,
                "The Project title is too long. It should be 30 characters or less."
              ),
          }),
    onSubmit: (values) => {
      if (mode === "exist") {
        onSubmit({ project: values.project });
      } else {
        onSubmit({ name: values.name });
      }
    },
  });
  const { values, errors, handleSubmit, setFieldValue } = formik;
  return (
    <form
      className={s.form}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <div className={s.tabs}>
        <span
          className={classNames(s.tab, { [s.active]: mode === "exist" })}
          onClick={() => setMode("exist")}
          data-translate-key="existing-projects">{t("existing-projects")}
        </span>
        <span
          className={classNames(s.tab, { [s.active]: mode === "new" })}
          onClick={() => setMode("new")}
          data-translate-key="create-new-project">{t("create-new-project")}
        </span>
      </div>
      {mode === "exist" ? (
        <AutoCompleteInput
          data={projects}
          value={values.project.name}
          placeholder="Start typing project name"
          onChange={(e) => {
            setFieldValue("project", {
              ...values.project,
              name: e.target.value,
            });
            setProjectVal(e.target.value);
          }}
          onSelect={(value) => {
            setFieldValue("project", value);
          }}
          name="project"
          label="Project"
          error={errors.project?.id}
          emptyText={{
            text: "No work item results found.",
          }}
        />
      ) : (
        <Input
          className={s.field}
          label="Project"
          value={values.name}
          placeholder="Enter a project's title"
          onChange={(e) => {
            setFieldValue("name", e.target.value);
          }}
          error={errors.name}
        />
      )}
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Add
        </Button>
      </div>
    </form>
  );
};
export default AssignToProjectForm;
