import React, {useEffect, useState} from 'react';
import services from "../../../../../services";
import {useParams} from "react-router-dom";
import DetailLoading from "../../../../loadingStates/Detail/Detail";
import Detail from "../../../../elements/Detail/Detail";
import styles from "../../TeamReportContainer.module.scss";

const TeamReportDetail = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    services.reportServices.getTeamReport(params.id).then(res => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  }, []);

  if (loading) return <DetailLoading className={styles.detailBlock} length={6}/>

  return (
    <section className={styles.detailBlock}>
      <h3 className={styles.title}>{data.name}</h3>
      <Detail data={[
        {
          name: "Leader",
          value: data?.leader.name,
        },
        {
          name: "Department",
          value: data?.department.name,
        },
        {
          name: "Users",
          value: data?.users.map(user => user.name).join(", ") || "-",
        },
      ]}/>
    </section>
  );
};

export default TeamReportDetail;