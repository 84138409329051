import React, {useState, Fragment, useRef} from 'react'
import classNames from 'classnames'
import styles from './PieChart.module.scss'
import {Chart} from 'react-google-charts'
import P from 'prop-types'
import {pieChartColors} from './colors'
import {formatValue, formatFullValue} from './formatValue'
import {ReactComponent as HelpOutline} from 'assets/icons/help-outline.svg'
import {hasOverflowingChildren} from 'helpers/overflow'
import ChartLoading from './ChartLoading'
import {trimRest} from "../../../helpers/stringHelper";

const testData = [
  ['Run Rate (CA)', '5000000'],
  ['Active&Planned', 5],
  ['Completed', 5],
  ['test', 4]
]
const testColors = ['#DA795E', '#5B79B4']

const PieChart = (
  {
    data = testData,
    colors = testColors,
    chartWidth,
    className,
    defaultCurrency,
    withLegend = true,
    wrapClassName
  }
) => {
  const [loading, setLoading] = useState(true)
  const hintRef = useRef(null)
  const [overflow, setOverflow] = useState(false)
  const [tooltipPos, setTooltipPos] = useState({x: -999, y: -999})
  const [tooltipIndex, setTooltipIndex] = useState(null)
  const [showTotalTooltip, setShowTotalTooltip] = useState(false)
  const [totalTooltipPos, setTotalTooltipPos] = useState({x: -999, y: -999})
  let moveTimeout
  const sum = +data[0][1]
  const generateLegend = () => {
    const legend = []
    data.forEach((option, index) => {
      if (index !== 0) {
        legend.push({
          title: option[0],
          color: colors[index - 1],
          value: option[1]
        })
      }
    })
    return legend
  }
  const formatToPercent = num => {
    const percent = ((num * 100) / sum).toFixed(2)
    return `(${percent}%)`
  }

  const emptyData = data.map((item, index) => {
    switch (index) {
      case 0:
        return [item[0], '10000000000']
      case 1:
        return [item[0], 10000000000]
      default:
        return [item[0], 1]
    }
  })

  return (
    <div
      className={classNames(
        styles.chartContainer,
        {[styles.empty]: !sum},
        wrapClassName
      )}
      style={{marginTop: loading && '16px'}}
    >
      <div
        style={{position: 'relative'}}
        onMouseMove={e => {
          if (tooltipIndex !== null) {
            const parent = e.currentTarget.offsetParent.getBoundingClientRect()
            const x = e.clientX - parent.left
            const y = e.clientY - parent.top
            setTooltipPos({x, y})
            setTooltipIndex(null)
          }
        }}
      >
        <Chart
          width={chartWidth}
          height={chartWidth}
          chartType='PieChart'
          loader={<ChartLoading isLoaded={() => setLoading(false)}/>}
          data={sum ? data : emptyData}
          legend_toggle={false}
          chartEvents={[
            {
              eventName: 'ready',
              callback: ({chartWrapper, google}) => {
                const chart = chartWrapper.getChart()
                google.visualization.events.addListener(
                  chart,
                  'onmouseover',
                  e => {
                    setTooltipIndex(e.row)
                  }
                )
              }
            }
          ]}
          style={{margin: '0 auto', zIndex: 0}}
          options={{
            chartArea: {width: '85%', height: '85%'},
            backgroundColor: 'transparent',
            colors: sum ? colors : [pieChartColors.gray],
            pieHole: 0.85,
            pieSliceText: 'none',
            tooltip: {
              trigger: 'none'
            },
            legend: {
              position: 'none',
              alignment: 'center'
            }
          }}
          className={className}
        />
        {!loading && (
          <div
            className={classNames(styles.totalAmount, {[styles.empty]: !sum})}
          >
						<span
              style={{cursor: 'pointer'}}
              onMouseMove={e => {
                const parent = e.currentTarget.offsetParent.offsetParent.getBoundingClientRect()
                const x = e.clientX - parent.left
                const y = e.clientY - parent.top
                clearTimeout(moveTimeout)
                moveTimeout = setTimeout(
                  () => setTotalTooltipPos({x, y}),
                  200
                )
              }}
              onMouseOver={() => setShowTotalTooltip(true)}
              onMouseLeave={() => setShowTotalTooltip(false)}
            >
							{formatValue(+data[0][1], defaultCurrency)}
						</span>
            <span className={styles.chartTitle}>
							{sum ? (
                trimRest(15, data[0][0])
              ) : (
                <Fragment>
                  <div title={data[0][0]}>{trimRest(10, data[0][0])}</div>
                  <div
                    className={styles.helpIconWrap}
                    onMouseEnter={() =>
                      !overflow &&
                      setOverflow(hasOverflowingChildren(hintRef.current, 'x'))
                    }
                  >
                    <HelpOutline/>
                    <span
                      ref={hintRef}
                      className={classNames(styles.message, {
                        [styles.left]: overflow
                      })}
                    >
											No Data Found
										</span>
                  </div>
                </Fragment>
              )}
						</span>
          </div>
        )}
      </div>
      {!loading && withLegend && (
        <div className={styles.legendWrap}>
          <div className={styles.legend}>
            {generateLegend().map((option, index) => {
              return (
                <div className={styles.legendItem} key={index}>
                  <div
                    className={styles.circle}
                    style={{
                      background: option.color
                    }}
                  />
                  <p className={styles.value}>
                    {formatValue(option.value, defaultCurrency)}
                  </p>
                  <p className={styles.title}>{option.title}</p>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {tooltipIndex !== null && sum > 0 && (
        <div
          className={styles.tooltip}
          style={{
            left: `${tooltipPos.x - 92}px`,
            top: `${tooltipPos.y - 49}px`
          }}
        >
          <span className={styles.type}>{data[tooltipIndex + 1][0]}</span>
          <div className={styles.inlineWrap}>
						<span className={styles.sum}>
							{formatFullValue(data[tooltipIndex + 1][1], defaultCurrency)}
              &nbsp;
						</span>
            <span className={styles.percent}>
							{formatToPercent(+data[tooltipIndex + 1][1])}
						</span>
          </div>
        </div>
      )}
      {showTotalTooltip && sum > 0 && (
        <div
          className={styles.tooltip}
          style={{
            left: `${totalTooltipPos.x - 92}px`,
            top: `${totalTooltipPos.y - 54}px`
          }}
        >
          <span className={styles.type}>Total</span>
          <div className={styles.inlineWrap}>
						<span className={styles.sum}>
							{formatFullValue(data[0][1], defaultCurrency)}
						</span>
          </div>
        </div>
      )}
    </div>
  )
}

PieChart.propTypes = {
  data: P.array,
  colors: P.array,
  chartWidth: P.string,
  defaultCurrency: P.string
}

export default PieChart
