import React from "react";
import s from "../PrivacyPage.module.scss";

const Point10 = () => {
  return (
    <div id="point10" className={s.pointBlock}>
      <h3 className={s.subtitle}>
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </h3>

      <p className={s.text} style={{ fontStyle: "italic" }}>
        <span>In Short: </span>Yes, if you are a resident of California, you are
        granted specific rights regarding access to your personal information.
      </p>

      <p className={s.text}>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>

      <p className={s.text}>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Website, you have the right to request
        removal of unwanted data that you publicly post on the Website. To
        request removal of such data, please contact us using the contact
        information provided below, and include the email address associated
        with your account and a statement that you reside in California. We will
        make sure the data is not publicly displayed on the Website, but please
        be aware that the data may not be completely or comprehensively removed
        from all our systems (e.g. backups, etc.).
      </p>

      <h3 className={s.subtitle}>CCPA Privacy Notice</h3>

      <p className={s.text}>
        The California Code of Regulations defines a "resident" as:
      </p>

      <p className={s.text}>
        (1) every individual who is in the State of California for other than a
        temporary or transitory purpose and <br />
        (2) every individual who is domiciled in the State of California who is
        outside the State of California for a temporary or transitory purpose:
      </p>

      <p className={s.text}>
        All other individuals are defined as "non-residents."
      </p>

      <p className={s.text}>
        If this definition of "resident" applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>

      <p className={s.text}>
        <span>What categories of personal information do we collect?</span>
      </p>

      <p className={s.text}>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>

      <table>
        <thead>
          <tr>
            <th>Category</th>
            <th>Examples</th>
            <th>Collected</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={s.firstCol}>A. Identifiers</td>
            <td>
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address and
              account name
            </td>
            <td className={s.lastCol}>NO</td>
          </tr>

          <tr>
            <td className={s.firstCol}>
              B. Personal information categories listed in the California
              Customer Records statute
            </td>
            <td>
              Name, contact information, education, employment, employment
              history and financial information
            </td>
            <td className={s.lastCol}>YES</td>
          </tr>

          <tr>
            <td className={s.firstCol}>
              C. Protected classification characteristics under California or
              federal law
            </td>
            <td>Gender and date of birth</td>
            <td className={s.lastCol}>NO</td>
          </tr>

          <tr>
            <td className={s.firstCol}>D. Commercial information</td>
            <td>
              Transaction information, purchase history, financial details and
              payment information
            </td>
            <td className={s.lastCol}>NO</td>
          </tr>

          <tr>
            <td className={s.firstCol}>E. Biometric information</td>
            <td>Fingerprints and voiceprints</td>
            <td className={s.lastCol}>NO</td>
          </tr>

          <tr>
            <td className={s.firstCol}>
              F. Internet or other similar network activity
            </td>
            <td>
              Browsing history, search history, online behavior, interest data,
              and interactions with our and other websites, applications,
              systems and advertisements
            </td>
            <td className={s.lastCol}>NO</td>
          </tr>

          <tr>
            <td className={s.firstCol}>G. Geolocation data</td>
            <td>Device location</td>
            <td className={s.lastCol}>YES</td>
          </tr>

          <tr>
            <td className={s.firstCol}>
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </td>
            <td>
              Images and audio, video or call recordings created in connection
              with our business activities
            </td>
            <td className={s.lastCol}>NO</td>
          </tr>

          <tr>
            <td className={s.firstCol}>
              I. Professional or employment-related information
            </td>
            <td>
              Business contact details in order to provide you our services at a
              business level, job title as well as work history and professional
              qualifications if you apply for a job with us
            </td>
            <td className={s.lastCol}>NO</td>
          </tr>

          <tr>
            <td className={s.firstCol}>J. Education Information</td>
            <td>Student records and directory information</td>
            <td className={s.lastCol}>NO</td>
          </tr>

          <tr>
            <td className={s.firstCol}>
              K. Inferences drawn from other personal information
            </td>
            <td>
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </td>
            <td className={s.lastCol}>NO</td>
          </tr>
        </tbody>
      </table>

      <p className={s.text}>
        We may also collect other personal information outside of these
        categories instances where you interact with us in-person, online, or by
        phone or mail in the context of: <br />• Receiving help through our
        customer support channels;
      </p>

      <p className={s.text}>
        • Participation in customer surveys or contests; and
      </p>

      <p className={s.text}>
        • Facilitation in the delivery of our Services and to respond to your
        inquiries.
      </p>

      <p className={s.text}>
        <span>How do we use and share your personal information?</span>
      </p>

      <p className={s.text}>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>

      <p className={s.text}>
        You may contact us by email at {" "}
        <a
          href="mailto:info@focalpointprocurement.com."
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
        >
          info@focalpointprocurement.com.
        </a>
        , by calling toll-free at {" "}
        <a
          href="tel:+14044106601"
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
        >
          +1 (404) 410-6601
        </a>
        , by visiting {" "}
        <a
          href="https://focalpointprocurement.com/contact-us"
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
        >
          https://focalpointprocurement.com/contact-us
        </a>{" "}
        , or by referring to the contact details at the bottom of this document.
      </p>

      <p className={s.text}>
        If you are using an authorized agent to exercise your right to opt-out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>

      <p className={s.text}>
        <span>Will your information be shared with anyone else?</span>
      </p>

      <p className={s.text}>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf.
      </p>

      <p className={s.text}>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be "selling" of your personal
        data.
      </p>

      <p className={s.text}>
        Focal Point Procurement Solutions Inc. has not disclosed or sold any
        personal information to third parties for a business or commercial
        purpose in the preceding 12 months. Focal Point Procurement Solutions
        Inc. will not sell personal information in the future belonging to
        website visitors, users and other consumers.
      </p>

      <p className={s.text}>
        <span>Your rights with respect to your personal data</span>
      </p>

      <p className={s.text} style={{ textDecoration: "underline" }}>
        Right to request deletion of the data - Request to delete
      </p>

      <p className={s.text}>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation or any processing that may be required to
        protect against illegal activities.
      </p>

      <p className={s.text} style={{ textDecoration: "underline" }}>
        Right to be informed - Request to know
      </p>

      <p className={s.text}>
        Depending on the circumstances, you have a right to know: <br />•
        whether we collect and use your personal information;
      </p>

      <p className={s.text}>
        • the categories of personal information that we collect;
      </p>

      <p className={s.text}>
        • the purposes for which the collected personal information is used;
      </p>

      <p className={s.text}>
        • whether we sell your personal information to third parties;
      </p>

      <p className={s.text}>
        • the categories of personal information that we sold or disclosed for a
        business purpose;
      </p>

      <p className={s.text}>
        • the categories of third parties to whom the personal information was
        sold or disclosed for a business purpose; and
      </p>

      <p className={s.text}>
        • the business or commercial purpose for collecting or selling personal
        information. In accordance with applicable law, we are not obligated to
        provide or delete consumer information that is de-identified in response
        to a consumer request or to re-identify individual data to verify a
        consumer request.
      </p>

      <p className={s.text} style={{ textDecoration: "underline" }}>
        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
        Rights
      </p>

      <p className={s.text}>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>

      <p className={s.text} style={{ textDecoration: "underline" }}>
        Verification process
      </p>

      <p className={s.text}>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g. phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>

      <p className={s.text}>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. If, however, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity, and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>

      <p className={s.text} style={{ textDecoration: "underline" }}>
        Other privacy rights
      </p>

      <p className={s.text}>
        • you may object to the processing of your personal data
      </p>

      <p className={s.text}>
        • you may request correction of your personal data if it is incorrect or
        no longer relevant, or ask to restrict the processing of the data
      </p>

      <p className={s.text}>
        • you can designate an authorized agent to make a request under the CCPA
        on your behalf. We may deny a request from an authorized agent that does
        not submit proof that they have been validly authorized to act on your
        behalf in accordance with the CCPA.
      </p>

      <p className={s.text}>
        • you may request to opt-out from future selling of your personal
        information to third parties. Upon receiving a request to opt-out, we
        will act upon the request as soon as feasibly possible, but no later
        than 15 days from the date of the request submission.
      </p>

      <p className={s.text}>
        To exercise these rights, you can contact us by email at {" "}
        <a
          href="mailto:info@focalpointprocurement.com."
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
        >
          info@focalpointprocurement.com.
        </a>
        , by calling toll-free at {" "}
        <a
          href="tel:+14044106601"
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
        >
          +1 (404) 410-6601
        </a>
        , by visiting {" "}
        <a
          href="https://focalpointprocurement.com/contact-us"
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
        >
          https://focalpointprocurement.com/contact-us
        </a>{" "}
        , or by referring to the contact details at the bottom of this document.
        If you have a complaint about how we handle your data, we would like to
        hear from you.
      </p>
    </div>
  );
};

export default Point10;
