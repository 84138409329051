import React, {useEffect, useState} from "react";
import s from "./TeamLeaderboard.module.scss";
import sBase from "../MainDashboard.module.scss";
import {getTitle} from "../../helpers/dataFormat";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import services from "../../../../../../services";
import classNames from "classnames";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import {trimRest} from "../../../../../../helpers/stringHelper";
import {abbreviateNumber} from "../../../../../../helpers/abbreviateNumber";

const TeamLeaderboard = () => {
  const [data, setData] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    services.dashboardServices.getTeamLeaderboard().then((res) => {
      setData(res.data || []);
    });
  }, []);

  return (
    <section className={classNames(sBase.wrapper, s.teamLeaderboardWrapper)}>
      <h4
        className={classNames(sBase.title, s.title)}
        data-translate-key={getTranslateKey(getTitle("teamLeaderboard"))}
      >
        {t(getTranslateKey(getTitle("teamLeaderboard")))}
      </h4>
      <TableContainer className={s.content}>
        <Table stickyHeader className={sBase.table}>
          <TableHead className={sBase.tableHead} data-attr='table-head'>
            <TableRow>
              <TableCell className={sBase.tableCell}>
                <span data-translate-key={getTranslateKey("Team")}>
                  {t(getTranslateKey("Team"))}
                </span>
              </TableCell>
              <TableCell className={sBase.tableCell}>
                <span data-translate-key={getTranslateKey("Achieved")}>
                  {t(getTranslateKey("Achieved"))}
                </span>
              </TableCell>
              <TableCell className={sBase.tableCell}>
                <span data-translate-key={getTranslateKey("Pipeline")}>
                  {t(getTranslateKey("Pipeline"))}
                </span>
              </TableCell>
              <TableCell className={sBase.tableCell}>
                <span data-translate-key={getTranslateKey("Total savings")}>
                  {t(getTranslateKey("Total savings"))}
                </span>
              </TableCell>
              <TableCell className={sBase.tableCell}>
                <span data-translate-key={getTranslateKey("NPS score, %")}>
                  {t(getTranslateKey("NPS score, %"))}
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={sBase.tableBody}>
            {
              (data || []).sort((a, b) => b.nps_score - a.nps_score).map(t => {
                return (
                  <TableRow className={sBase.tableRow} key={t.team.id}>
                    <TableCell className={sBase.tableCell}>
                      <span title={t.team.name}>
                        {trimRest(12, t.team.name)}
                      </span>
                    </TableCell>
                    <TableCell className={sBase.tableCell}>
                      <span className={sBase.currency}>$ </span>
                      {abbreviateNumber(t.achieved)}
                    </TableCell>
                    <TableCell className={sBase.tableCell}>
                      <span className={sBase.currency}>$ </span>
                      {abbreviateNumber(t.pipeline)}
                    </TableCell>
                    <TableCell className={sBase.tableCell}>
                      <span className={sBase.currency}>$ </span>
                      {abbreviateNumber(t.savings)}
                    </TableCell>
                    <TableCell className={sBase.tableCell}>
                        <span className={sBase.nps}>
                          {t.nps_score}
                        </span>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

export default TeamLeaderboard;
