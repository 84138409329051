import { SET_LAST_LOCATION } from "helpers/constants";

let initialState = [];

const lastLocation = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LAST_LOCATION: {
      return [...state, payload];
    }
    default: {
      return state;
    }
  }
};
export default lastLocation;
