import React, {useEffect, useState} from "react";
import classNames from "classnames";
import services from "services/index";
import s from "./Messages.module.scss";
import sBase from "../SidebarDashboard.module.scss";
import moment from "moment";
import Files from "../../../../../forms/AddMilestoneTaskForm/components/Attachments/components/Files/Files";
import SidebarWidgetsLoading from "../../../../../loadingStates/Dashboard/SidebarWidgets/SidebarWidgetsLoading";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import gradient from "random-gradient";
import {useTranslate} from "../../../../../../hooks/useTranslate";

const Messages = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [translateMessagesKey, translateMessagesValue] = useTranslate("Messages");

  useEffect(() => {
    services.dashboardServices.getMessages().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const getContent = () => {
    if (data.length) {
      return (
        <div className={s.content}>
          {
            data.map((m, index) => {
              const avatarGradient = {background: gradient(m.owner.name)}
              return (
                <a href={`/projects/${m.project_id}`} className={s.message} key={index}>
                  <div className={s.owner}>
                    <div className={s.avatarWrapper} style={avatarGradient}>
                      {m.owner.name.substr(0, 1)}
                    </div>
                    <div className={s.info}>
                      <p>{m.owner.name}</p>
                      <span className={s.date}>{moment(m.created_at).format("h:mm:ss a, MM/DD/YYYY")}</span>
                    </div>
                  </div>
                  <p className={s.messageContent}>
                    {m.description}
                  </p>
                  <div className={s.files}>
                    <Files withLink={true} files={m.attachments}/>
                  </div>
                </a>
              );
            })
          }
        </div>
      );
    }
    return <EmptyContent padding={88} text='You don’t have messages yet.'/>;
  }

  return (
    <section className={classNames(sBase.wrapper, s.messagesWrapper)}>
      {
        loading ? <SidebarWidgetsLoading/> :
          <>
            <h4 className={classNames(sBase.title, s.title)} data-translate-key={translateMessagesKey}>
              {translateMessagesValue}
            </h4>
            {getContent()}
          </>
      }
    </section>
  );
};

export default Messages;


