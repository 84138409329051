import React from "react";
import Popup from "components/popups/Popup/Popup";
import AdminSettingsNonFinancialSavingsForm
  from "components/forms/AdminSettingsNonFinancialSavingsForm/AdminSettingsNonFinancialSavingsForm";
import PropTypes from "prop-types";

const EditNonFinancialSavingsTypePopup = (
  {
    onClose,
    onSubmit,
    defaultData,
  }
) => {
  return (
    <Popup onClose={onClose} title="Edit non-financial savings type">
      <AdminSettingsNonFinancialSavingsForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultData={defaultData}
      />
    </Popup>
  );
};

export default EditNonFinancialSavingsTypePopup;

EditNonFinancialSavingsTypePopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultData: PropTypes.any,
};
