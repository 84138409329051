import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";

import {useParams} from "react-router-dom";
import ContractDetailsContainer from "../../containers/ContractDetailsContainer/ContractDetailsContainer";

const ContractDetailsPage = () => {
  let {id} = useParams();
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: "/contracts",
          name: "Contracts",
        },
        {
          name: 'Contract details',
        },
      ]}
    >
      <ContractDetailsContainer id={id}/>
    </DefaultLayout>
  );
};

export default ContractDetailsPage;
