import React, {useState} from "react"
import MainTable from "../../../../tables/MainTable/MainTable";
import {useHistory} from "react-router-dom";
import Popup from "../../../../popups/Popup/Popup";

const Content = ({data, type, editAction = null, addAction, deleteAction}) => {
  const history = useHistory();

  const [openDeletePopUp, setOpenDeletePopUp] = useState({
    item: {},
    isOpen: false,
  });

  const deleteCondition = () => {
    deleteAction(openDeletePopUp.item.id);
    setOpenDeletePopUp({item: null, isOpen: false});
  };

  return (
    <>
      <MainTable
        totalName='Total'
        data={data}
        dataTypes={[
          {
            title: type,
            value: ({name}) => name,
            onClick: (id, {name}) => history.push(`/admin-settings/additional-question/${id}/${name}`),
          },
          {
            title: "Questions",
            value: ({questions_count}) =>questions_count
          },
        ]}
        options={item => {
          return [
            editAction && {
              title: "Edit",
              onClick: () => editAction(item)
            },
            {
              title: "Delete",
              onClick: () => setOpenDeletePopUp({item, isOpen: true})
            }
          ]
        }}
        button={{
          name: "New condition",
          onClick: () => addAction()
        }}
        totalCount={data.length}
      />
      {openDeletePopUp.isOpen && (
        <Popup
          title="Delete condition"
          text="Are you sure you want to delete this condition?"
          onClose={() => setOpenDeletePopUp({item: null, isOpen: false})}
          onClick={deleteCondition}
          isDelete
          dialog
        />
      )}
    </>
  )
}
export default Content;
