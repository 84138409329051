import {getConditionResult} from "./getConditionResult";

export const updateSteps = (steps, values) => {
  return steps.filter(step => Boolean(step.fields.filter(field => {
    if (Boolean(field.conditions) && field.conditions !== "()") {
      return eval(getConditionResult(field.conditions, values));
    }
    return true;
  }).length));
};
