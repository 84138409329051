import React from "react";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import MainTable from "components/tables/MainTable/MainTable";
import {fromHistoryDate} from "helpers/dateFormat";

const ActivityTable = ({data, perPage = 20, totalCount, currentPage,inlineWrapClassName}) => {
  const history = useHistory();

  const onSort = ({sort_by, direction}) => {
    const query = qs.parse(history.location.search);
    history.push({
      search: qs.stringify({...query, sort_by, direction, page: 1}),
    });
  };
  const onPageChange = (page) => {
    const query = qs.parse(history.location.search);
    history.push({search: qs.stringify({...query, page})});
  };
  return (
    <MainTable
      stickyHeader={true}
      inlineWrapClassName={inlineWrapClassName}
      data={data}
      useMaxHeight={true}
      dataTypes={[
        {
          title: "Edited",
          value: ({modification}) => modification,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "modification", direction});
          },
        },
        {
          title: "Project",
          value: ({project}) => project.name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "project_name", direction});
          },
          onClick: (_, {project}) => history.push(`/projects/${project.id}`),
          sticky: true
        },
        {
          title: "Project owner",
          value: ({owner}) => owner.name,
        },
        {
          title: "Editor",
          value: ({user}) => user.name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "user_name", direction});
          },
        },
        {
          title: "Time",
          value: ({created_at}) => fromHistoryDate(created_at),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "created_at", direction});
          },
        },
      ]}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};
export default ActivityTable;
