import React from "react";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import Button from "components/elements/Button/Button";

const SurveyStakeholderContainer = ({ history, params, survey }) => {
  const isAnswered = survey.status === "done";

  return (
    <>
      {isAnswered ? (
        <EmptyBlock
          subtitle="Thank you for your time. You can view your answers"
          withBtn={false}
        >
          <br />
          <Button
            styled="primary"
            onClick={() =>
              history.push(`/projects/${params.id}/survey/${survey.id}`)
            }
          >
            View answers
          </Button>
        </EmptyBlock>
      ) : (
        <EmptyBlock
          subtitle="You don’t have survey results yet. You can take a survey now"
          withBtn={false}
        >
          <br />
          <Button
            styled="primary"
            onClick={() =>
              history.push(`/projects/${params.id}/survey/${survey.id}`)
            }
          >
            Take survey
          </Button>
        </EmptyBlock>
      )}
    </>
  );
};

export default SurveyStakeholderContainer;
