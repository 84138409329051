import React from "react";
import s from "./Conditions.module.scss";
import ConditionGroup from "./ConditionGroup";

const Conditions = ({conditions}) => {
  return (
    <div className={s.conditionsWrap}>
      <h2 className={s.title}>Conditions</h2>
      {conditions.map((conditionGroup, index) => (
        <ConditionGroup
          conditionGroup={conditionGroup}
          index={index}
          key={index}
        />
      ))}
    </div>
  );
};

export default Conditions;