import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import styles from "./NonFinancialSavings.module.scss";
import {ReactComponent as Info} from "assets/icons/info.svg";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import AdminSettingsNonFinancialSavingsTable
  from "components/tables/AdminSettingsNonFinancialSavingsTable/AdminSettingsNonFinancialSavingsTable";
import AddNonFinancialSavingsTypePopup from "./Popups/AddNonFinancialSavingsTypePopup/AddNonFinancialSavingsTypePopup";
import EditNonFinancialSavingsTypePopup
  from "./Popups/EditNonFinancialSavingsTypePopup/EditNonFinancialSavingsTypePopup";
import DeleteNonFinancialSavingsTypePopup
  from "./Popups/DeleteNonFinancialSavingsTypePopup/DeleteNonFinancialSavingsTypePopup";
import Tabs from "components/elements/Tabs/Tabs";
import Tab from "components/elements/Tabs/Tab/Tab";
import services from "services";
import {defaultGroups} from "../helpers/defaultValues";
import RestoreNonFinancialSavingsTypePopup
  from "./Popups/RestoreNonFinancialSavingsTypePopup/RestoreNonFinancialSavingsTypePopup";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const NonFinancialSavings = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const {t} = useTranslation();
  const query = qs.parse(history.location.search);

  const getNonBenefitsByGroup = (groupName, benefits) => {
    return benefits.filter(benefit => benefit.non_saving_group.name === groupName);
  };

  const getGroupId = (groupName, benefits) => {
    return benefits
      .find(benefit => benefit.non_saving_group.name === groupName)?.non_saving_group.id;
  };

  const getCurrentGroupId = () => data.find(g => g.name === query.tableTab)?.id || data[0].id;

  const separateByGroups = data => {
    return defaultGroups.map(group => ({
      id: getGroupId(group, data),
      name: group,
      benefits: getNonBenefitsByGroup(group, data)
    }));
  };

  const load = () => {
    services.financialBenefitsServises.getNonFinancialBenefits().then(res => {
      if (res.data) {
        setData(separateByGroups(res.data));
        setTotalCount(res.data.length);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    load();
  }, [history.location]);

  const addHandler = params => {
    services.financialBenefitsServises.createNonFinancialBenefits({
      name: params.savings_type,
      non_saving_group_id: getCurrentGroupId(),
    }).then(res => {
      if (res.data) {
        load();
        setPopup(null);
      }
    });
  };

  const editHandler = params => {
    services.financialBenefitsServises.editNonFinancialBenefits(currentType.id, {
      name: params.savings_type,
      non_saving_group_id: getCurrentGroupId(),
    }).then(res => {
      if (res.data) {
        load();
        setCurrentType(null);
        setPopup(null);
      }
    });
  };

  const deleteHandler = () => {
    services.financialBenefitsServises.deleteNonFinancialBenefits(currentType.id)
      .then((res) => {
        if (res.statusText === "OK") {
          load();
          setCurrentType(null);
          setPopup(null);
        }
      });
  };

  const restoreHandler = () => {
    services.financialBenefitsServises.restoreNonFinancialBenefits(currentType.id,)
      .then((res) => {
        if (res.statusText === "OK") {
          load();
          setCurrentType(null);
          setPopup(null);
        }
      });
  };

  return (
    <>
      <div className={styles.title}>
        <h2 data-translate-key={getTranslateKey("non-financial-benefits")}>
          {t(getTranslateKey("non-financial-benefits"))}</h2>
        <Info className={styles.icon}/>
      </div>
      {loading && <TableLoading length={2}/>}
      {!loading && (
        <Tabs
          styled="secondary"
          className={styles.tabs}
          handleClick={(tab) => {
            history.push({search: qs.stringify({...query, tableTab: tab})});
          }}
          defaultActiveTab={query.tableTab}
        >
          {data.map(group => (
            <Tab key={group.id} label={group.name}>
              <AdminSettingsNonFinancialSavingsTable
                data={group.benefits}
                onAdd={() => setPopup("add")}
                onEdit={type => {
                  setCurrentType(type);
                  setPopup("edit");
                }}
                onDelete={type => {
                  setCurrentType(type);
                  setPopup("delete");
                }}
                onRestore={type => {
                  setCurrentType(type);
                  setPopup("restore");
                }}
              />
            </Tab>
          ))}
        </Tabs>
      )}
      {!loading && !Boolean(totalCount) && (
        <EmptyBlock
          subtitle="You don’t have any savings types yet."
          buttonTitle="New type"
          onClick={() => setPopup("add")}
        />
      )}

      {popup === "add" && (
        <AddNonFinancialSavingsTypePopup
          onClose={() => setPopup(null)}
          onSubmit={addHandler}
        />
      )}
      {popup === "edit" && (
        <EditNonFinancialSavingsTypePopup
          onClose={() => setPopup(null)}
          onSubmit={editHandler}
          defaultData={currentType}
        />
      )}
      {popup === "delete" && (
        <DeleteNonFinancialSavingsTypePopup
          onClose={() => setPopup(null)}
          onSubmit={() => {
            deleteHandler();
            setPopup(null);
          }}
        />
      )}
      {popup === "restore" && (
        <RestoreNonFinancialSavingsTypePopup
          onClose={() => setPopup(null)}
          onSubmit={() => {
            restoreHandler();
            setPopup(null);
          }}
        />
      )}
    </>
  );
};

export default NonFinancialSavings;
