import { combineReducers } from "redux";
import user from "./user";
import categories from "./categories";
import users from "./users";
import projects from "./projects";
import notifications from "./notifications";
import lastLocation from "./lastLocation";
import globalProject from "./globalProject";
import globalSettings from "./globalSettings";
import subtasks from "./subtasks";

const rootReducer = combineReducers({
  user,
  categories,
  users,
  projects,
  notifications,
  lastLocation,
  globalProject,
  globalSettings,
  subtasks,
});

export default rootReducer;
