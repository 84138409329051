import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import {useParams} from "react-router-dom";
import ClarificationHistoryContainer
  from "../../containers/ClarificationHistoryContainer/ClarificationHistoryContainer";
import {useSelector} from "react-redux";
import {userRoles} from "../../../helpers/userPermissions/userRoles";

const ApprovalClarificationHistoryPage = () => {
  const {projectId, id, milestoneId} = useParams();
  const user = useSelector(state => state?.user);
  const isBusinessUser = user.roles.includes(userRoles.business_user);
  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);

  let breadcrumbs = [
    {
      path: paths.projects,
      name: "Projects",
      disabled: (isBusinessUser || isCategoryOwner),
    },
    {
      name: "Project details",
      path: `/projects/${projectId}`,
    },
    {
      name: "Milestones",
      path: `/projects/${projectId}?tab=Milestones`
    },
    {
      name: "Approval",
      path: `/projects/${projectId}/milestones/${milestoneId}/approvals/${id}/detail`,
    },
    {
      name: "Clarification history",
    }
  ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <ClarificationHistoryContainer/>
    </DefaultLayout>
  );
};

export default ApprovalClarificationHistoryPage;
