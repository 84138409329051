import React from "react";
import sBase from "../Activities.module.scss";
import {trimRest} from "../../../../../../../helpers/stringHelper";
import {formatDate} from "../../../../../../../helpers/dateFormat";
import DropDown from "./DropDown";
import {useTranslation} from "react-i18next";

const CurrentDropDown = ({data}) => {
  const { t } = useTranslation();

  const checkForToday = (createdAt) => {
    const now = new Date();
    const created = new Date(createdAt);
    return (now.getDate() === created.getDate()) ? 'new' : null;
  }

  return (
    <DropDown data={{name: data.name, status: checkForToday(data.created_at)}}>
      <div className={sBase.activityInfo}>
        <div>
          <span data-translate-key="stage">{t("stage")}</span>
          <span>{trimRest(15, data.stage)}</span>
        </div>
        <div>
          <span data-translate-key="expected-date">{t("expected-date")}</span>
          <span>{formatDate(data.expected_date, true)}</span>
        </div>
        <div>
          <span data-translate-key="last-updated">{t("last-updated")}</span>
          <span>{formatDate(data.last_updated_at, true)}</span>
        </div>
        <div>
          <span data-translate-key="expected-financial-benefit">{t("expected-financial-benefit")}</span>
          <span>{`$ ${data.expected_financial_benefit}`}</span>
        </div>
      </div>
    </DropDown>
  );
};

export default CurrentDropDown;



