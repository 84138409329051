import React, {useState} from 'react';
import style from "./StepField.module.scss";
import {ReactComponent as DragIcon} from "../../../../../../assets/icons/drag-icon.svg";
import DropListTrigger from "../../../../../elements/DropListTrigger/DropListTrigger";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";

const StepField = ({field, editAction, deleteAction, stepId, alwaysFieldsCount}) => {
  const {id} = useParams();
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);

  return (
    <>
      <div className={style.field}>
        <div>
          <DragIcon/>
          <a className={style.link} href={`/admin-settings/request-type/${id}/step/${stepId}/field/${field.id}`}>
            {field.name}
          </a>
        </div>
        <div>{field.field_type}</div>
        <div>
          {field.conditions === "" ? "Always" : "Under condition"}
          <DropListTrigger
            data={[
              {
                title: "Edit",
                onClick: () => editAction()
              },
              alwaysFieldsCount > 1 && {
                title: "Delete",
                onClick: () => deleteAction()
              }
            ]}
            visibleMenu={visibleDropListMenu}
            setVisibleMenu={setVisibleDropListMenu}
          />
        </div>
      </div>
    </>
  );
};

export default StepField;

StepField.propTypes = {
  field: PropTypes.any,
  editAction: PropTypes.func,
  deleteAction: PropTypes.func,
  stepId: PropTypes.string,
  alwaysFieldsCount: PropTypes.number,
};