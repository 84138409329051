import React, {useEffect, useState} from 'react';
import CustomStatusTabs from "./CustomStatusTabs/CustomStatusTabs";
import {CircularProgress} from "@material-ui/core";
import CustomStatusList from "./CustomStatusList/CustomStatusList";
import services from "../../../../../../services";

const CustomStatuses = () => {
  const [selectors, setSelectors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    services.customStatusesServices.getCustomSelectorsAdmin().then(res => {
      if (res.data) {
        setSelectors(res.data);
        setLoading(false);
      }
    });
  }, []);

  const addNewSelector = async value => {
    let tabId = "";
    await services.customStatusesServices.addCustomSelector({name: value}).then(res => {
      if (res.data) {
        setSelectors([...selectors, {...res.data, options: []}]);
        tabId = res.data.id;
      }
    });
    return tabId;
  }

  const changeSelector = (id, value) => {
    services.customStatusesServices.changeCustomSelector(id, {name: value}).then(res => {
      if (res.data) {
        setSelectors(selectors.map(selector => selector.id === res.data.id ? {
          ...selector,
          name: res.data.name
        } : selector));
      }
    });
  }

  const deleteSelector = async id => {
    let isDeleted = false;
    await services.customStatusesServices.deleteCustomSelector(id).then(res => {
      if (res.status < 300) {
        setSelectors(selectors.filter(selector => selector.id !== id));
        isDeleted = true;
      }
    });
    return isDeleted;
  }

  const addCustomSelectorOption = (id, params) => {
    services.customStatusesServices.addCustomSelectorOption(id, params).then(res => {
      if (res.data) {
        setSelectors(selectors.map(selector => selector.id === id ? {
          ...selector,
          options: [...selector.options, res.data]
        } : selector));
      }
    });
  }

  const editCustomSelectorOption = (selectorId, id, params) => {
    services.customStatusesServices.editCustomSelectorOption(selectorId, id, params).then(res => {
      if (res.data) {
        setSelectors(selectors.map(selector => selector.id === selectorId ? {
          ...selector,
          options: selector.options.map(option => option.id === id ? res.data : option)
        } : selector));
      }
    });
  }

  const deleteCustomSelectorOption = (selectorId, id) => {
    services.customStatusesServices.deleteCustomSelectorOption(selectorId, id).then(res => {
      if (res.status < 300) {
        setSelectors(selectors.map(selector => selector.id === selectorId ? {
          ...selector,
          options: selector.options.filter(option => option.id !== id)
        } : selector));
      }
    });
  }

  if (loading) return (
    <div
      className="loaderWrap justifyCenter"
      style={{marginTop: "24px"}}
    >
      <CircularProgress/>
    </div>
  );

  return (
    <CustomStatusTabs
      addNewSelector={addNewSelector}
      changeSelector={changeSelector}
      deleteSelector={deleteSelector}
    >
      {selectors.map(selector => (
        <div label={selector.name} id={selector.id} key={selector.id}>
          <CustomStatusList
            options={selector.options}
            addCustomSelectorOption={values => addCustomSelectorOption(selector.id, values)}
            editCustomSelectorOption={(id, values) => editCustomSelectorOption(selector.id, id, values)}
            deleteCustomSelectorOption={id => deleteCustomSelectorOption(selector.id, id)}
          />
        </div>
      ))}
    </CustomStatusTabs>
  );
};

export default CustomStatuses;