import React from "react";
import s from "../PrivacyPage.module.scss";

const Point13 = () => {
  return (
    <div id="point13" className={s.pointBlock}>
      <h3 className={s.subtitle}>
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h3>

	  <p className={s.text}>
	  Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking
	  {" "}
        <a
          href="https://app.termly.io/notify/9e453012-18b7-4f57-abc9-07aaa0e88d52"
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
        >
          here
        </a>{" "}
	   . We will respond to your request within 30 days.
	  </p>
    </div>
  );
};

export default Point13;
