import React from "react";
import classNames from "classnames";
import Slider from "react-slick";
import Slide from "./Slide/Slide";
import { ReactComponent as Prev } from "../../../../assets/icons/Slider/Prev.svg";
import { ReactComponent as Next } from "../../../../assets/icons/Slider/Next.svg";
import sliderData from "./sliderData";
import s from "./Benefits.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <Next className={classNames(s.arrow, s.nextArrow)} onClick={onClick} />
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <Prev className={classNames(s.arrow, s.prevArrow)} onClick={onClick} />
  );
}

const Benefits = () => {
  const {t} = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false
        }
      }
    ]
    // dotsClass: s.dots
  };
  return (
    <section className={s.benefits}>
      <div className={s.landingContainer}>
        <h2 className={s.title} data-translate-key={"benefits-for-everyone"}> {t(getTranslateKey("benefits-for-everyone"))}</h2>
        <Slider {...settings} className={s.slider}>
          {sliderData.map((slide, index) => (
            <Slide
              key={index}
              img={slide.img}
              title={slide.title}
              list={slide.list}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Benefits;
