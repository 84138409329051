import React, { useRef, useState, useLayoutEffect } from "react";
import styles from "./Stakeholders.module.scss";
import StakeholderResponsesWidget from "./components/StakeholderResponsesWidget/StakeholderResponsesWidget";
import AverageResult from "./components/AverageResult/AverageResult";
const Stakeholders = ({ data }) => {
  const [height, setHeight] = useState("auto");
  const divRef = useRef(null);

  useLayoutEffect(() => {
    divRef.current &&
      setHeight(`${divRef.current.getBoundingClientRect().width} px`);
  }, [divRef]);

  return (
    <div className={styles.wrapper} ref={divRef}>
      <StakeholderResponsesWidget data={data.survey_results} />
      <span
        className={styles.divider}
        style={{
          height,
        }}
      ></span>
      <AverageResult value={data.average_value} />
    </div>
  );
};

export default Stakeholders;
