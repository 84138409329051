import React from "react";
import CheckedLine from "../../../../components/CheckedLine/CheckedLine";
import PPMimg from "../../../../assets/img/modules/PPMimg.jpg";
import s from "./PPMSection.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const PPMSection = () => {
  const {t} = useTranslation();
  return (
    <section className={s.ppmSection}>
      <div className={s.landingContainer}>
        <h1 className={s.title} data-translate-key={"product-features"}> {t(getTranslateKey("product-features"))}</h1>

        <div className={s.wrapper}>
          <div className={s.content}>
            <h2 className={s.subTitle}
                data-translate-key={"procurement-performance-management"}> {t(getTranslateKey("procurement-performance-management"))}</h2>

            <p className={s.text}
               data-translate-key={"finally-a-system-where-all-procurement-activities-can-be-housed,-tracked-and-measured.-facilitating-collaboration-with-internal-business-partners.-no-more-manual-processes."}>
              {t(getTranslateKey("finally-a-system-where-all-procurement-activities-can-be-housed,-tracked-and-measured.-facilitating-collaboration-with-internal-business-partners.-no-more-manual-processes."))}
            </p>

            <CheckedLine item="Optimize resource utilization" className={s.checkedLine}/>

            <CheckedLine item="Track procurement activities and associated benefits" className={s.checkedLine}/>

            <CheckedLine item="Measure and manage procurement client satisfaction" className={s.checkedLine}/>
          </div>

          <div className={s.imgBlock}></div>
          {/* <img src={PPMimg} alt="/" className={s.img} /> */}
        </div>
      </div>
    </section>
  );
};

export default PPMSection;
