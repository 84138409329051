import React, { useState } from "react"
import classNames from "classnames"
import s from "./TasksTable.module.scss"
import MainTable from "components/tables/MainTable/MainTable"
import Popup from "components/popups/Popup/Popup"
import EditMilestoneTaskForm from "components/forms/EditMilestoneTaskForm/EditMilestoneTaskForm"
import { fromDate } from "helpers/dateFormat"
import { status as statusHelper, statusOptions } from "helpers/milestonesHelper"
import { ReactComponent as NoteIcon } from "assets/icons/note.svg"
import NotesHistoryPopup from "./popups/NotesHistoryPopup/NotesHistoryPopup"
const TasksTable = ({
  tasks,
  removeHandler,
  editHandler,
  openPopup,
  onSort,
  havePermissions,
  openNotesHandler
}) => {
  const [show, setShow] = useState({
    remove: false,
    edit: false,
    history: false
  })
  const [task, setTask] = useState({})
  const [taskNotes, setTaskNotes] = useState(null)
  return (
    <>
      <MainTable
        className={s.nameColumn}
        data={tasks}
        dataTypes={[
          {
            title: "Task name",
            value: ({ name }) => name
          },
          {
            title: "Start date",
            value: ({ start_date }) =>
              start_date ? fromDate(start_date) : "-",
            sortable: true,
            handleSort: type =>
              onSort({ field: "start_date", direction: type.direction })
          },
          {
            title: "End date",
            value: ({ end_date }) => (end_date ? fromDate(end_date) : "-"),
            sortable: true,
            handleSort: type =>
              onSort({ field: "end_date", direction: type.direction })
          },
          {
            title: "Responsible User",
            value: ({ responsible_user }) => responsible_user?.name || "-"
          },
          {
            title: "Status",
            value: ({ status }) =>
              statusOptions.filter(e => e.value === status)?.[0]?.label || "-"
          },
          {
            title: "",
            value: ({ has_note, id }) => (
              <NoteIcon
                className={classNames(s.note, { [s.disabled]: !has_note })}
                onClick={() => {
                  if (has_note) {
                    openNotesHandler(id).then(res => {
                      setTaskNotes(res?.data)
                      setShow({ ...show, history: true })
                    })
                  }
                }}
              />
            )
          }
        ]}
        options={item => {
          const options = [
            {
              title: "Edit",
              onClick: () => {
                setTask(item)
                setShow({ ...show, edit: true })
              }
            },
            {
              title: "Delete",
              onClick: () => {
                setTask(item)
                setShow({ ...show, remove: true })
              }
            }
          ]
          !item.permissions?.update && delete options[0]
          !item.permissions?.destroy && delete options[1]
          return options
        }}
        button={
          havePermissions?.update && {
            name: "Add task",
            onClick: () => openPopup()
          }
        }
      />

      {show.remove && (
        <Popup
          onClose={() => setShow({ ...show, remove: false })}
          successBtnTitle='Delete'
          title='Delete task'
          text='Are you sure you want to delete this task?'
          onClick={() => {
            removeHandler(task.id)
            setShow({ ...show, remove: false })
          }}
          isDelete
          dialog
        />
      )}
      {show.edit && (
        <Popup
          onClose={() => setShow({ ...show, edit: false })}
          title='Edit task'
        >
          <EditMilestoneTaskForm
            task={task}
            onSubmit={editHandler}
            onClose={() => setShow({ ...show, edit: false })}
          />
        </Popup>
      )}
      {show.history && (
        <NotesHistoryPopup
          onClose={() => setShow({ ...show, history: false })}
          data={taskNotes}
        />
      )}
    </>
  )
}

export default TasksTable
