import React from "react";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import Projects from "./Projects/Projects";
import {useTranslation} from "react-i18next";

const AdminSettingsProjectsContainer = () => {
  const {t} = useTranslation();
  return (
     <div className={s.wrap}>
        <h2 className={s.title} data-translate-key="projects">{t("projects")}</h2>
        <Projects/>
      </div>
  );
};
export default AdminSettingsProjectsContainer;
