import { getAllYears } from "./getAllYears"
export const getYearsByData = data => {
  const dates = []
  data.map(({ start_date, end_date }) => {
    dates.push(new Date(start_date).getTime(), new Date(end_date).getTime())
  })
  return getAllYears([
    new Date(dates.sort()[0]).getFullYear(),
    new Date(dates.sort()[dates.length - 1]).getFullYear()
  ])
}
