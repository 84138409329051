import React, {useEffect, useState} from "react";
import styles from "./AdminSettingsEmailsContainer.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import EmailsFilter from "../../filters/EmailsFilter/EmailsFilter";
import EmailsTable from "../../tables/EmailsTable/EmailsTable";
import TableLoading from "../../loadingStates/Table/Table";
import services from "../../../services";
import {useHistory} from "react-router-dom";
import qs from "query-string";

const AdminSettingsEmailsContainer = () => {
  const history = useHistory();
  const {...query} = qs.parse(history.location.search);
  const [emailsLoading, setEmailsLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    services.emailTemplatesServices.getEmailTemplates(query).then(res => {
      if (res.data) {
        setEmails(res.data);
        setEmailsLoading(false);
      }
    });
  }, [history.location.search]);

  const disableHandler = (id, status) => {
    services.emailTemplatesServices.changeEmailTemplateStatus(id, status).then(res => {
      if (res.data) {
        setEmails(emails.map(email => email.id === res.data.id ? res.data : email));
      }
    });
  }

  return (
    <div className={styles.wrap}>
      <h2 className={styles.title} data-translate-key={"emails"}>
        {t(getTranslateKey("emails"))}
      </h2>
      <EmailsFilter className={styles.filter}/>
      {!emailsLoading ? <EmailsTable data={emails} onDisable={disableHandler}/> : <TableLoading length={4}/>}
    </div>
  );
};
export default AdminSettingsEmailsContainer;
