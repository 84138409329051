import React, {useEffect, useState} from "react";
import s from "../MainDashboard.module.scss";
import IndividualLeaderboard from "../IndividualLeaderboard/IndividualLeaderboard";
import TeamLeaderboard from "../TeamLeaderboard/TeamLeaderboard";
import Filter from "../Filter/Filter";
import StaticWidgetsForTeam from "../StaticWidgetsForTeam/StaticWidgetsForTeam";
import Announcements from "../Announcements/Announcements";
import SavingsGoal from "../SavingsGoal/SavingsGoal";
import services from "../../../../../../services";

const TeamDashboard = ({dashboardType}) => {
  const [filter, setFilter] = useState({
    teams: [],
  });

  useEffect(() => {
    services.dashboardServices.getFilters().then(res => {
      setFilter(res.data);
    });
  }, []);

  return (
    <>
      <div className={s.group}>
        <IndividualLeaderboard/>
        <TeamLeaderboard/>
      </div>
      <div className={s.filter}>
        <Filter filter={filter}/>
      </div>
      <div className={s.savingsGoal}>
        <SavingsGoal type={dashboardType}/>
      </div>
      <div className={s.footerInfo}>
        <StaticWidgetsForTeam/>
        <Announcements/>
      </div>
    </>
  );
};

export default TeamDashboard;
