export const conditions = [
  {
    label: "is",
    value: "===",
  },
  {
    label: "is not",
    value: "!==",
  },
];
export const conditionsForNumber = [
  {
    label: "is equal to",
    value: "===",
  },
  {
    label: "is not equal to",
    value: "!==",
  },
  {
    label: "is greater than",
    value: ">",
  },
  {
    label: "is less than",
    value: "<",
  },
]
export const booleanOptions = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];