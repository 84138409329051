import React from "react";
import s from "../FinancialBenefitsForm.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import SplitSavingBox from "./SplitSavingBox";

const SplitSavingsForm = ({values, setFieldValue, errors}) => {
  const {t} = useTranslation();

  const savingBoxProps = type => ({
    type,
    errors,
    values: values[type],
    setFieldValue,
    currency: values.currency,
    final_savings: values.final_savings,
  });

  return (
    <div className={s.splitSavingsForm}>
      <h3 data-translate-key={getTranslateKey("Split savings")}>
        {t(getTranslateKey("Split savings"))}
      </h3>
      <SplitSavingBox {...savingBoxProps("departments")}/>
      <SplitSavingBox {...savingBoxProps("divisions")}/>
      <SplitSavingBox {...savingBoxProps("users")}/>
    </div>
  );
};

export default SplitSavingsForm;