import React, {useEffect, useState} from "react";
import s from "./BusinessProjectRequestsContainer.module.scss";
import services from "services";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import {useHistory} from "react-router-dom";
import TableLoading from "../../loadingStates/Table/Table";
import RequestsTable from "../../tables/RequestsTable/RequestsTable";
import querystring from "query-string";
import {paths} from "../../../routes/paths";
import Popup from "../../popups/Popup/Popup";
import NotesHistory from "./NotesHistory/NotesHistory";
import {useSelector} from "react-redux";
import {userRoles, userTypes} from "../../../helpers/userPermissions/userRoles";
import RequestsFilter from "./RequestsFilter/RequestsFilter";
import {useTranslation} from "react-i18next";
import {checkRoles} from "../../../helpers/checkRoles";
import {useDidUpdate} from "../../../hooks/useDidUpdate";
import qs from "query-string";

const RequestsContainer = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const query = querystring.parse(history.location.search) || {};
  const {requests, per_page, requests_count} = data;
  const [notesHistory, setNotesHistory] = useState([]);
  const {user_type, roles, triage, triage_manager} = useSelector(state => state.user);
  const {business, procurement} = userTypes;
  const [savedFilter, setSavedFilter] = useState(savedFilter);
  const {t} = useTranslation();

  useEffect(() => {
    services.filterService.getFilter("requests").then(res => {
      if (res.data?.length) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      } else {
        let statuses = [];

        if (loading) {
          const isAdminRoles = (checkRoles(roles, userRoles.admin) || triage || triage_manager);
          if (isAdminRoles) statuses.push("pending");
          if (user_type === procurement || isAdminRoles) statuses.push("in_progress");
          if (user_type === business) statuses = ["pending", "in_progress"];
        }

        const prepareQuery = statuses.length > 0 ? {...query, statuses: statuses.join()} : {...query};
        history.push({search: querystring.stringify({...prepareQuery, page: 1})});
      }
    });
  }, []);

  useDidUpdate(() => {
    services.projectRequestsServices
      .getRequests({...query})
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  }, [history.location]);

  const handlePageChange = page => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  const saveDefaultFilter = () => {
    services.filterService.addFilter("requests", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  }

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  }

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams ?? savedFilter.filter_params),
      page: 1,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  }

  const renderContent = () => {
    if (loading) return <TableLoading length={4}/>;

    if (!loading && !requests.length) {
      if (user_type === business) {
        return (
          <EmptyBlock
            subtitle="You don’t have requests yet."
            buttonTitle="New request"
            onClick={() => history.push(paths.createRequest)}
          />
        );
      }

      return <EmptyBlock withBtn={false} subtitle="You don’t have requests yet."/>;
    }

    return (
      <RequestsTable
        data={requests}
        perPage={per_page}
        totalCount={requests_count}
        onPageChange={handlePageChange}
        currentPage={query.page || 1}
        notesHistoryAction={history => setNotesHistory(history)}
      />
    );
  };

  return (
    <div className={s.wrap}>
      <h2 className={s.title} data-translate-key="requests">{t("requests")}</h2>
      <RequestsFilter
        savedFilter={savedFilter}
        saveDefaultFilter={saveDefaultFilter}
        deleteDefaultFilter={deleteDefaultFilter}
      />
      {renderContent()}
      {
        Boolean(notesHistory.length) && (
          <Popup
            className={s.historyPopUp}
            onClose={() => setNotesHistory([])}
            title='Notes history'
          >
            <NotesHistory history={notesHistory}/>
          </Popup>
        )
      }
    </div>
  );
};

export default RequestsContainer;
