import {
  SET_ALERT,
  SET_FILTERS,
  SET_ACTIVE_FILTERS,
  SET_CURRENT_PAGE,
  SET_REQUEST_DATA,
  DELETE_CONTRACT
} from "./reducer";

export const setRequestData = (data) => ({
  type: SET_REQUEST_DATA,
  payload: {...data}
});

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: {filters}
});

export const setActiveFilters = (activeFilters) => ({
  type: SET_ACTIVE_FILTERS,
  payload: {activeFilters}
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: {page}
});

export const setAlert = (alert) => ({
  type: SET_ALERT,
  payload: {...alert}
});

export const deleteContract = (id) => ({
  type: DELETE_CONTRACT,
  payload: id
});