export const RSCustomStyle = {
  control: (styles, state) => ({
    ...styles,
    borderRadius: 2,
    minHeight: 32,
    border: state.isFocused ? "1px solid #4f78e0 !important" : "1px solid #dadada",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #a6a6a6",
    },
  }),
  indicatorContainer: styles => ({
    ...styles,
    padding: 0
  }),
  indicatorSeparator: style => ({
    ...style,
    display: "none",
  }),
  dropdownIndicator: style => ({
    ...style,
    padding: 3,
  }),
  placeholder: style => ({
    ...style,
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.4)",
    cursor: "text",
    fontFamily: "Roboto",
  }),
  valueContainer: style => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
    flexWrap: "no-wrap",
  }),
  menu: style => ({
    ...style,
    background: "#fff",
    boxShadow: "1px 4px 12px rgb(0 0 0 / 15%)",
    zIndex: 9,
    marginTop: 2,
  }),
  clearIndicator: style => ({
    ...style,
    padding: 3,
  }),
  option: style => ({
    ...style,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingLeft: 13,
    transition: "all 0.3s ease",
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#f3f3f3",
    }
  }),
  loadingIndicator: styles => ({
    ...styles,
    display: "none",
  }),
}