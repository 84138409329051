import React from "react";
import { useFormik } from "formik";
import { object, string, ref } from "yup";
import UserFormWrapper from "components/wrappers/UserFormWrapper/UserFormWrapper";
import PasswordInput from "components/elements/PasswordInput/PasswordInput";
import Button from "components/elements/Button/Button";
import CustomLink from "components/elements/CustomLink/CustomLink";
import s from "./PasswordResetForm.module.scss";

const PasswordResetForm = ({ onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      password: "",
      reenterPassword: "",
    },
    validationSchema: object({
      password: string()
        .required("Please enter your new password")
        .min(8, "New password must have 8 characters minimum")
        .max(32, "Max length is 32 characters")
        .matches(/[a-z]{1}/, "Please use at least 1 lower case character")
        .matches(/[A-Z]{1}/, "Please use at least 1 upper case character")
        .matches(
          /(?=.*[0-9])|(?=.*[!@#\$%\^&\*])/,
          "Please use at least 1 number or special character"
        ),
      reenterPassword: string()
        .required("Please retype your new password")
        .oneOf([ref("password"), null], "Passwords do not match"),
    }),
    onSubmit: (values, { setFieldError, setSubmitting }) => {
      onSubmit(values)
        .then(() => {
          setSubmitting(false);
        })
        .catch((e) => {
          setFieldError("password", e.response.data.error);
          setSubmitting(false);
        });
    },
  });

  const { handleSubmit, touched, values, errors, setFieldValue } = formik;

  return (
    <UserFormWrapper>
      <h1 className={s.title}>Reset your password</h1>

      <p className={s.labelInputResetPassword}>8 characters minimum</p>

      <form className={s.form} onSubmit={handleSubmit}>
        <div className={s.inputWrap}>
          <PasswordInput
            placeholder="Enter new password"
            onChange={(value) => setFieldValue("password", value)}
            value={values.password}
            error={touched.password && errors.password}
          />
        </div>

        <div className={s.inputReenterWrap}>
          <PasswordInput
            placeholder="Confirm password"
            onChange={(value) => setFieldValue("reenterPassword", value)}
            value={values.reenterPassword}
            error={touched.reenterPassword && errors.reenterPassword}
          />
        </div>

        <div className={s.buttonWrap}>
          <Button styled="secondary" type="text" className={s.cancelButton}>
            <CustomLink type="nav-link" path="/login" className={s.cancelLink}>
              Cancel
            </CustomLink>
          </Button>

          <Button styled="primary" type="submit">
            Next
          </Button>
        </div>
      </form>
    </UserFormWrapper>
  );
};

export default PasswordResetForm;
