import React from 'react';
import Popup from "../Popup/Popup";
import TranslateForm from "../../forms/TranslateForm/TranslateForm";

const TranslatePopUp = ({onClose, onSubmit, value, disabledSendBtn}) => {
  return (
    <Popup title="Change translation" onClose={onClose}>
      <TranslateForm
        onClose={onClose}
        onSubmit={onSubmit}
        value={value}
        disabledSendBtn={disabledSendBtn}
      />
    </Popup>
  );
};

export default TranslatePopUp;