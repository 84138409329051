export const statuses = [
  {
    id: "to_do",
    name: "to_do",
    label: "To do",
    color: "gray",
  },
  {
    id: "in_progress",
    name: "in_progress",
    label: "In progress",
    color: "dark_blue"
  },
  {
    id: "completed",
    name: "completed",
    label: "completed",
    color: "light_green"
  }
];

export const getStatusData = status => {
  return statuses.find(s => s.id === status) || statuses[0];
};