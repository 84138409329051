import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import Button from "../../elements/Button/Button";
import s from "./EditTriageEntityForm.module.scss";
import RSSelect from "../../elements/ReactSelectCustom/RSSelect";
import services from "../../../services";
import TriageOption from "../../elements/ReactSelectCustom/components/TriageOption/TriageOption";
import {entityFormat} from "../../../helpers/triages/entityFormat";
import {object, string} from "yup";

const EditTriageEntityForm = ({submit, onClose, entity, defaultValues, successBtnTitle = "Assign"}) => {
  const [triageUsers, setTriageUsers] = useState([]);

  const formik = useFormik({
    initialValues: {
      triage_user: defaultValues.triage_user ?? null,
    },
    validationSchema: object({
      triage_user: object({
        value: string().required("Triage person is required")
      }).typeError("Triage person is required")
    }),
    onSubmit: ({triage_user}) => submit(triage_user?.value),
  });

  useEffect(() => {
    services.triageServices.getTriageUsers({entity: entityFormat(entity)}).then(res => {
      if (res.data) setTriageUsers(res.data.map(user => ({
        value: user.id,
        label: user.name,
        entities_size: user.entities_size,
        entity
      })));
    });
  }, []);

  const {handleSubmit, values, setFieldValue, errors} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <RSSelect
        onChange={item => setFieldValue("triage_user", item)}
        options={triageUsers}
        defaultValue={values.triage_user}
        label="Triage person"
        placeholder="Start typing user name"
        className={s.field}
        customOption={TriageOption}
        error={errors.triage_user && errors.triage_user?.value || errors.triage_user}
        isClearable
      />
      <div className='popupBtnsWrap'>
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          {successBtnTitle}
        </Button>
      </div>
    </form>
  );
};

export default EditTriageEntityForm;