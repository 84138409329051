import React, {useEffect, useState} from "react";
import s from "./ClarificationHistory.module.scss";
import services from "../../../services";
import PropTypes from "prop-types";
import HistoryItem from "./HistoryItem/HistoryItem";
import HistoryLoading from "./HistoryLoading/HistoryLoading";
import AddNoteForm from "../../forms/AddNoteForm/AddNoteForm";
import {appendFile} from "../../../helpers/appendFile";
import {delay} from "../../../helpers/delay";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const ClarificationHistory = ({sourceIdUrlParam}) => {
  const params = useParams();
  const sourceId = params[sourceIdUrlParam];

  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => load(), []);

  const load = () => {
    services.noteServices.getNotes(sourceId, 1).then((res) => {
      setData(res.data.notes);
      setLoading(false);
    });
  };

  const addNote = values => {
    services.noteServices.createNote(sourceId, {
      description: values.description,
    }).then(res => {
      if (res.data) {
        const attachments = values.attachments || [];
        Promise.all([...attachments.map(attachment => {
          const file = appendFile(attachment);
          services.taskServices.addAttachment(file, res.data.id).then(res => res.data);
        })]).finally(() => delay(1000).then(() => load()));
      }
    });
  };

  if (loading) return <HistoryLoading/>;

  return (
    <div className={s.clarificationHistoryWrap}>
      <h2 data-translate-key={getTranslateKey("Clarification history")}>
        {t(getTranslateKey("Clarification history"))}
      </h2>
      <div className={s.historyList}>
        {data.map(note => <HistoryItem key={note.id} data={note}/>)}
      </div>
      <AddNoteForm addHandler={addNote}/>
    </div>
  );
};

export default ClarificationHistory;

ClarificationHistory.propTypes = {
  sourceIdUrlParam: PropTypes.string,
};