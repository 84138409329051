import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import AdminSettingsEmailEditorContainer
  from "../../containers/AdminSettingsEmailEditorContainer/AdminSettingsEmailEditorContainer";
import {paths} from "../../../routes/paths";

const AdminSettingsEmailEditorPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {name: "Admin Settings", disabled: true},
        {
          name: "Emails",
          path: paths.adminSettingsEmails,
        },
        {
          name: "Email Details",
        },
      ]}
    >
      <AdminSettingsEmailEditorContainer/>
    </DefaultLayout>
  );
};

export default AdminSettingsEmailEditorPage;
