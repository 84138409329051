export const SET_STAKEHOLDERS = "SET_STAKEHOLDERS"
export const ADD_STAKEHOLDER = "ADD_STAKEHOLDER"
export const CHANGE_STAKEHOLDER = "CHANGE_STAKEHOLDER"
export const DELETE_STAKEHOLDER = "DELETE_STAKEHOLDER"
export const SET_POPUP = "SET_POPUP"
export const CLOSE_ALERT = "CLOSE_ALERT"
export const SET_CURRENT_STAKEHOLDER = "SET_CURRENT_STAKEHOLDER"
export const initialState = {
  per_page: 30,
  page: 1,
  stakeholder_types_count: 0,
  stakeholder_types: [],
  current_stakeholder: null,
  loading: true,
  alert: {
    type: "",
    message: ""
  },
  popup: null
}
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STAKEHOLDERS:
      return {
        ...state,
        per_page: payload?.per_page || state.per_page,
        stakeholder_types_count:
          payload?.stakeholder_types_count || state.stakeholder_types_count,
        stakeholder_types:
          payload?.stakeholder_types || state.stakeholder_types,
        search_query: payload?.search_query || state.search_query,
        loading:
          payload.loading !== undefined ? payload?.loading : state.loading,
        alert: payload?.alert || state.alert
      }
    case ADD_STAKEHOLDER:
      return {
        ...state,
        stakeholder_types_count: state.stakeholder_types_count + 1,
        stakeholder_types: payload.stakeholder
          ? [payload.stakeholder, ...state.stakeholder_types]
          : state.stakeholder_types,
        alert: payload.alert,
        popup: payload.popup !== undefined ? payload.popup : state.popup
      }
    case CHANGE_STAKEHOLDER:
      return {
        ...state,
        stakeholder_types: payload.stakeholder
          ? state.stakeholder_types.map(e =>
              e.id === payload.stakeholder.id ? payload.stakeholder : e
            )
          : state.stakeholder_types,
        alert: payload.alert,
        popup: payload.popup !== undefined ? payload.popup : state.popup
      }
    case DELETE_STAKEHOLDER:
      return {
        ...state,
        stakeholder_types_count: state.stakeholder_types_count - 1,
        stakeholder_types: payload.stakeholder_id
          ? state.stakeholder_types.filter(e => e.id !== payload.stakeholder_id)
          : state.stakeholder_types,
        alert: payload.alert,
        popup: payload.popup !== undefined ? payload.popup : state.popup
      }
    case SET_POPUP:
      return {
        ...state,
        popup: payload
      }
    case CLOSE_ALERT:
      return {
        ...state,
        alert: {
          type: "",
          message: ""
        }
      }
    case SET_CURRENT_STAKEHOLDER:
      return {
        ...state,
        current_stakeholder: payload
      }
  }
}
