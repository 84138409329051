export const changeCondition = (conditions, conditionId, value) => {
  return conditions.map(condition => {
    if (condition.id === conditionId) {
      return {
        ...condition,
        condition: value,
      };
    }
    return condition;
  });
};