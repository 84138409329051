import React, {useEffect, useState} from "react";
import s from "./Categories.module.scss";
import sBase from "../MainDashboard.module.scss";
import {getTitle} from "../../helpers/dataFormat";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import services from "../../../../../../services";
import classNames from "classnames";
import TablesLoading from "../../../../../loadingStates/Dashboard/Tables/TablesLoading";
import SingleCategory from "./SingleCategory";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Categories = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    services.dashboardServices.getCategories().then((res) => {
      setData(res.data);
    });
    setLoading(false);
  }, []);

  if (loading) return (
    <section className={classNames(sBase.wrapper, s.categoriesWrapper)}>
      <TablesLoading/>
    </section>
  );

  return data.length ?
    (
      <section className={classNames(sBase.wrapper, s.categoriesWrapper)}>
        <h4
          className={classNames(sBase.title, s.title)}
          data-translate-key={getTranslateKey(getTitle("categories"))}
        >
          {t(getTranslateKey(getTitle("categories")))}
        </h4>
        <TableContainer className={s.content}>
          <Table stickyHeader className={sBase.table}>
            <TableHead className={sBase.tableHead} data-attr='table-head'>
              <TableRow>
                <TableCell className={sBase.tableCell}>
                  <span data-translate-key={getTranslateKey("Category")}>
                    {t(getTranslateKey("Category"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                  <span data-translate-key={getTranslateKey("TMT Spend")}>
                    {t(getTranslateKey("TMT Spend"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                  <span data-translate-key={getTranslateKey("Active projects")}>
                    {t(getTranslateKey("Active projects"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                  <span data-translate-key={getTranslateKey("Preferred Spend, %")}>
                    {t(getTranslateKey("Preferred Spend, %"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                  <span data-translate-key={getTranslateKey("Spend with top 5%, %")}>
                    {t(getTranslateKey("Spend with top 5%, %"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}/>
              </TableRow>
            </TableHead>
            <TableBody className={sBase.tableBody}>
              {data.map(c => <SingleCategory data={c}/>)}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    ) : null;
};

export default Categories;


