export const getProjectPos = (start_date, end_date, min, max, useWithMonthReorder = false) => {

  const currentMonth = new Date().getUTCMonth();
  let rest = 0;
  if (useWithMonthReorder) {
    rest = 2.628e+9 * (currentMonth - 3);
    max = new Date(`${new Date().getFullYear() + 1}-01-01`);
    min = new Date(`${new Date().getFullYear()}-01-01`);
  }

  let left = (100 * (new Date(start_date).getTime() - rest - min)) / (max - min);
  const width = (100 * (new Date(end_date).getTime() - min)) / (max - min);

  return {
    left,
    width: width - left,
  };
};