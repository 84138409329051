import React, {useState, useEffect} from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import AdminScenarioContainer from "components/containers/AdminScenarioContainer/AdminScenarioContainer";
import services from "services";
import {useParams} from "react-router-dom";
import {paths} from "../../../routes/paths";

const AdminScenarioPage = () => {
  const [name, setName] = useState("");
  const params = useParams();

  useEffect(() => {
    services.adminServices
      .getScenario(params.id)
      .then((res) => setName(res.data.name));
  }, []);
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          name: "Admin Settings",
          disabled: true,
        },
        {
          path: paths.adminSettingsApprovals,
          name: "Approvals",
        },
        {
          name,
        },
      ]}
    >
      <AdminScenarioContainer name={name}/>
    </DefaultLayout>
  );
};
export default AdminScenarioPage;
