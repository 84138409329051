import React from "react"
import s from "./RightSidebar.module.scss"
import NavButton from "./components/NavButton/NavButton"
import {ReactComponent as CategoriesIcon} from "assets/icons/right-side-categories.svg"
import {ReactComponent as ProjectIcon} from "assets/icons/right-side-project.svg"
import {useSelector} from "react-redux"
import {useLocation} from "react-router-dom"
import Can from "components/elements/Can/Can"
import {userRoles} from "helpers/userPermissions/userRoles"
import {paths} from "../../../../../routes/paths";

const RightSidebar = ({onClick, active}) => {
  const store = useSelector(state => state);
  const {globalProject, user} = store;
  const location = useLocation();
  const {data, message} = globalProject;
  const canCreateAdvancedProject = (
    !user.roles.includes(userRoles.business_user) &&
    !user.roles.includes(userRoles.category_business_owner)
  );
  return (
    <ul className={s.rightSidebar}>
      {!(
        location.pathname.includes(paths.categories) ||
        location.pathname.includes(paths.users) ||
        location.pathname.includes(paths.contracts) ||
        location.pathname.includes((paths.suppliers))
      ) && (
        <NavButton
          icon={<CategoriesIcon/>}
          onClick={() => onClick("categories")}
          hint='Categories'
          active={active === "categories"}
        />
      )}

      <Can
        permission={canCreateAdvancedProject}
        yes={() => (
          <NavButton
            icon={<ProjectIcon/>}
            onClick={() => onClick("project")}
            hint='Creating a project'
            count={data?.contracts.length + data?.suppliers.length}
            active={active === "project"}
            message={message}
          />
        )}
      />
    </ul>
  )
}

export default RightSidebar
