import React from "react";
import s from "../MainDashboard.module.scss";
import Notifications from "../Notifications/Notifications";
import Tasks from "../Tasks/Tasks";
import SavingsGoal from "../SavingsGoal/SavingsGoal";
import StaticWidgets from "../StaticWidgets/StaticWidgets";
import GanttChartDashboard from "../GanttChart/GanttChartDashboard";
import Categories from "../Categories/Categories";
import Suppliers from "../Suppliers/Suppliers";

const IndividualDashboard = ({dashboardType}) => {
  return (
    <>
      <div className={s.group}>
        <Notifications/>
        <Tasks/>
      </div>
      <div className={s.savingsGoal}>
        <SavingsGoal type={dashboardType}/>
      </div>
      <div className={s.staticWidgets}>
        <StaticWidgets/>
      </div>
      <div className={s.ganttChart}>
        <GanttChartDashboard/>
      </div>
      <div className={s.categories}>
        <Categories/>
      </div>
      <div className={s.suppliers}>
        <Suppliers/>
      </div>
    </>
  );
};

export default IndividualDashboard;
