import React from "react";
import {string} from "yup";
import FormConstructor from "../../elements/FormConstructor/FormConstructor";
import PropTypes from "prop-types";
import services from "../../../services";
import {userRoles} from "../../../helpers/usersData";

const RejectRequestForm = ({onSubmit, onCLose}) => {
  const NOTE_LENGTH = 150;

  return (
    <FormConstructor
      submitBtn={{
        styled: "primary",
        title: "Reject",
      }}
      cancelBtn={{
        styled: "secondary",
        title: "Cancel",
        onClick: () => onCLose()
      }}
      fields={[
        {
          type: "textarea",
          title: "Note",
          placeholder: "Write a note",
          required: string()
            .required("Note is required")
            .max(NOTE_LENGTH, `The note is too long. It should be ${NOTE_LENGTH} characters or less.`),
          key: "note",
          value: "",
          max: NOTE_LENGTH,
        },
        {
          type: "attachments",
          key: "attachment",
          value: [],
        }
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default RejectRequestForm;

RejectRequestForm.propTypes = {
  onSubmit: PropTypes.func,
  description: PropTypes.string,
  onCLose: PropTypes.func,
};