import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "react-dropdown/style.css";

import s from "./CustomDropdown.module.scss";
import { ReactComponent as Arrow } from "assets/icons/arrowDown.svg";
import useOutsideClicker from "hooks/useOutsideClicker";
import { useTranslate } from "../../../hooks/useTranslate";
import { useTranslation } from "react-i18next";
import { getTranslateKey } from "../../../helpers/getTranslateKey";
import { optionWithColor } from "components/landing/components/OptionWithColor/optionWithColor.jsx";
import Notification from "../Notification/Notification";

const CustomDropdown = ({
  options,
  name,
  value,
  onChange,
  label,
  className,
  labelClass,
  controlClasses,
  arrowClass,
  dropClass,
  placeholder,
  error,
  disabled,
  onFocus,
  headerClass,
  withTranslate = false,
  titleNotification,
  arrow = true,
}) => {
  const [opened, setOpened] = useState(false);
  const [focused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const { t } = useTranslation();
  const dropRef = useRef(null);
  const [translateKey, translateValue] = useTranslate(label);

  useEffect(() => setCurrentValue(value), [value]);

  const handleChange = (item, index) => {
    if (!disabled) {
      setOpened(false);
      setCurrentValue(item);
      onChange(item, index, options);
      setFocused(false);
    }
  };

  useOutsideClicker(dropRef, () => {
    setOpened(false);
    setFocused(false);
  });

  const getValue = () => {
    if (typeof currentValue === "object" && !currentValue.value) {
      return null;
    } else if (typeof currentValue === "object" && currentValue.value) {
      return currentValue.label;
    } else {
      return currentValue;
    }
  };

  const isSelectedItem = (item) => {
    if (item?.id && currentValue?.id) {
      return item?.id === currentValue?.id;
    } else if (typeof currentValue === "string") {
      return currentValue === item.value || currentValue === item.label;
    } else {
      return currentValue.value === item.value;
    }
  };

  const currentVal =
    currentValue?.label ||
    options.filter((e) => e.label === currentValue || e.value === currentValue)?.[0]?.label ||
    currentValue;

  return (
    <div className={classNames(s.wrap, className)} ref={dropRef}>
      <div className={s.titleWrap}>
        {label && (
          <p className={classNames(s.label, labelClass)} data-translate-key={translateKey}>
            {translateValue}
          </p>
        )}
        {titleNotification && <Notification message={titleNotification} />}
      </div>
      <div
        className={classNames(s.dropdown, {
          [s.opened]: opened,
          [s.disabled]: disabled,
          [s.focused]: focused,
        })}
        name={name}>
        <header
          className={classNames(headerClass, s.header)}
          onClick={() => {
            if (!disabled) {
              onFocus();
              setFocused(!focused);
              setOpened(!opened);
            }
          }}>
          {getValue() ? (
            optionWithColor(
              currentValue,
              <span className={s.value}>
                {withTranslate ? t(getTranslateKey(currentVal)) : currentVal}
              </span>,
            )
          ) : (
            <span className={s.placeholder} data-translate-key={getTranslateKey(placeholder)}>
              {t(getTranslateKey(placeholder))}
            </span>
          )}

          {arrow && <Arrow className={classNames(s.arrow, arrowClass, controlClasses)} />}
        </header>
        {opened && (
          <div className={classNames(s.dropWrap, dropClass)}>
            <ul className={s.list}>
              {options.map((item, index) =>
                optionWithColor(
                  item,
                  <li
                    key={index}
                    onClick={() => handleChange(item, index)}
                    className={classNames(s.item, {
                      [s.selected]: isSelectedItem(item),
                    })}
                    data-translate-key={withTranslate ? getTranslateKey(item.label) : ""}>
                    {withTranslate ? t(getTranslateKey(item.label)) : item.label}
                  </li>,
                ),
              )}
            </ul>
          </div>
        )}
      </div>

      {error && (
        <span className={s.errorMessage} data-translate-key={error}>
          {" "}
          {t(getTranslateKey(error))}
        </span>
      )}
    </div>
  );
};

CustomDropdown.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.any,
  placeholder: PropTypes.any,
  disabled: PropTypes.bool,
  titleNotification: PropTypes.string,
  labelClass: PropTypes.string,
  controlClasses: PropTypes.any,
  arrowClass: PropTypes.string,
  dropClass: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.any,
  headerClass: PropTypes.string,
};

CustomDropdown.defaultProps = {
  options: [],
  value: "",
  onChange: () => {},
  onFocus: () => {},
  label: "",
  className: "",
  placeholder: "",
  disabled: false,
};

export default CustomDropdown;
