import React from "react";
import ModulesCard from "./ModulesCard/ModulesCard";
import s from "./Features.module.scss";
import CustomLink from "components/elements/CustomLink/CustomLink";
import Modules1 from "../../../../assets/icons/Modules/Modules1.svg";
import Modules2 from "../../../../assets/icons/Modules/Modules2.svg";
import Modules3 from "../../../../assets/icons/Modules/Modules3.svg";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const Features = () => {
  const {t} = useTranslation();
  return (
    <section className={s.features}>
      <div className={s.landingContainer}>
        <h2 className={s.title} data-translate-key={"features"}> {t(getTranslateKey("features"))}</h2>

        <div className={s.cardsBlock}>
          <ModulesCard
            img={Modules1}
            title="Procurement Performance Management"
            titleClass={s.bigTitle}
            text="Finally a system where all procurement activities can be housed, tracked and measured. Collaboration with internal business partners. No more manual processes."
          />

          <ModulesCard
            img={Modules2}
            title="Category Management"
            text="Facilitating the process for procurement and users to develop category strategies, category plans and developing category metrics and KPIs."
          />

          <ModulesCard
            img={Modules3}
            title="Category Risk Management"
            text="Manage risk across category suppliers, not just with an individual supplier perspective."
          />
        </div>

        <CustomLink className={s.buttonLink} type="nav-link" path="/features">
          Learn more
        </CustomLink>
      </div>
    </section>
  );
};

export default Features;
