import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "helpers/currencyFormat";
import { currencyData } from "helpers/helpData";
import { useSelector } from "react-redux";
import qs from "query-string";
import { supplierStatuses } from "helpers/helpData";
import Tags from "components/elements/Tags/Tags";
import services from "../../../services";
import LogoSuppliers from "../../elements/LogoSuppliers/LogoSuppliers";
import s from "../SuppliersTable/SuppliersTable.module.scss";

const SystemReportsSuppliersTable = ({ data }) => {
  const history = useHistory();
  const organizationName = useSelector(state => state.user.organization.name);
  const query = qs.parse(history.location.search);

  const onPageChange = page => {
    history.push({ search: qs.stringify({ ...query, page }) });
  };

  const onSort = ({ sort_by, direction, tag }) => {
    history.push({
      search: qs.stringify({ ...query, sort_by, direction, tag, page: 1 })
    });
  };

  const onDeleteTag = async (tagId, supplierId) =>
    await services.tagsServices.deleteSupplierTag(supplierId, tagId);

  const onAssignTag = async (tagId, supplierId) =>
    await services.tagsServices.assignSupplierTag(supplierId, tagId);
  return (
    <MainTable
      stickyHeader={true}
      withXScroll={true}
      data={data.suppliers}
      fullHeight={true}
      dataTypes={[
        {
          title: "Supplier",
          value: ({name, id, logo}) => (
            <div className={s.logoSubSupplier}>
              <LogoSuppliers name={name} logo={logo}/>
              <a className={s.link} href={`/suppliers/${id}`}>{name}</a>
            </div>),
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "name", direction });
          },
          onClick: id => history.push(`/suppliers/${id}`),
          sticky: true
        },
        {
          title: "Spend",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "spend", direction });
          },
          value: ({ spend, spend_currency }) =>
            spend
              ? formatCurrency(
                  currencyData.filter(e => e.label === spend_currency)[0]
                    ?.value,
                  spend
                )
              : "-",
          alignment: "right"
        },
        {
          title: "Payments",
          value: ({ payments_count }) => payments_count || "-",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "payments_count", direction });
          }
        },
        {
          title: "Contracts",
          value: ({ contracts_count }) => contracts_count || "-",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "contracts_count", direction });
          },
          visible: organizationName !== "Indeed"
        },
        {
          title: "Status",
          value: ({ status }) =>
            supplierStatuses.find(itm => itm.value === status)?.label || "-",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "status", direction });
          }
        },
        {
          title: "Owner",
          value: ({ owner }) => owner?.name || "-",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "owner", direction });
          }
        },
        {
          title: "Tags",
          type: "tags",
          value: (item, allData) => (
            <Tags
              allData={allData}
              item={item}
              onDelete={id => onDeleteTag(id, item.id)}
              onAssign={id => onAssignTag(id, item.id)}
              onSort={({ name }) =>
                query.tag !== name ? onSort({ tag: name }) : onSort({ tag: "" })
              }
            />
          ),
        }
      ]}
      perPage={data?.per_page || 30}
      totalCount={data.suppliers_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={query.page || 1}
    />
  );
};
export default SystemReportsSuppliersTable;
