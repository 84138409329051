import React, {useState, useEffect} from "react"
import {useFormik} from "formik"
import {object, string, number} from "yup"
import styled from "styled-components"
import services from "services"
import Input from "components/elements/Input/Input"
import Button from "components/elements/Button/Button"
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown"
import {formatEntityToObject} from "../../../helpers/formatEntityData";
import s from "../AddProjectRequestForm/AddProjectRequestForm.module.scss";
import SelectCheckDropdown from "../../elements/SelectCheckDropdown/SelectCheckDropdown";

const ProjectTypeForm = ({onFormSubmit, onClose, defaultValues}) => {
  const [groups, setGroups] = useState([])
  useEffect(() => {
    services.adminProjectsServices.getProjectGroups().then(res => {
      setGroups(
        res?.data?.map(({name, id}) => ({id, label: name, value: name})) ||
        []
      )
    })
  }, [])
  const formik = useFormik({
    initialValues: defaultValues || {
      type: "",
      load: "",
      activityGroup: {
        value: "",
        label: "",
        id: ""
      }
    },
    validationSchema: object({
      type: string().required("Type name is required"),
      load: number()
        .required("Load is required")
        .min(5, "Load cannot be less than 5")
        .max(100, "Load cannot be greater than 100"),
      activityGroup: object({
        id: string().required("Project group is required")
      })
    }),
    onSubmit: ({activityGroup, type, id, ...spread}, {setSubmitting}) => {
      onFormSubmit({
        ...spread,
        project_group_id: activityGroup.id,
        name: type
      })
    }
  })

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
    touched,
    values,
    ...rest
  } = formik
  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <InputField>
        <Input
          type='text'
          name='type'
          label='Project type'
          placeholder='Enter type name'
          value={values.type}
          error={touched.type && errors.type && errors.type}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputField>
      <InputField>
        <Input
          type='number'
          name='load'
          label='Load, %'
          placeholder='Enter % of load'
          value={values.load}
          error={touched.load && errors.load && errors.load}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}        />
      </InputField>
      <InputField>
        <CustomDropdown
          value={values.activityGroup}
          onChange={e => setFieldValue("activityGroup", e)}
          options={groups}
          name='activityGroup'
          label='Group of activities'
          placeholder='Choose group'
          error={
            errors.activityGroup &&
            touched.activityGroup &&
            errors.activityGroup.id
          }
        />
      </InputField>
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          styled='primary'
          type='submit'
          className='popupBtn'
          disabled={isSubmitting || !isValid || !formik.dirty}
        >
          {defaultValues ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  )
}

export default ProjectTypeForm

const InputField = styled.div`
  margin-bottom: 16px;
`
