import React, { useState } from "react";
import s from "./LinkedProjectsTable.module.scss";
import MainTable from "components/tables/MainTable/MainTable";
import Popup from "components/popups/Popup/Popup";
import { fromDate } from "helpers/dateFormat";
import { formatCurrency } from "helpers/currencyFormat";
import { currencyData } from "helpers/helpData";
import { localization } from "helpers/localizationForCompanies";

const LinkedProjectsTable = ({
  linkedProjects,
  removeHandler,
  history,
  havePermissions,
  openPopup,
}) => {
  const [show, setShow] = useState(false);
  const [project, setProject] = useState({});

  return (
    <>
      <MainTable
        className={s.table}
        data={linkedProjects}
        dataTypes={[
          {
            title: "Related Project",
            value: ({ name }) => name,
            sortable: true,
            onClick: (id) => history.push(`/projects/${id}`),
            sticky: true
          },
          {
            title: "Linked type",
            value: ({ linked_type }) => linked_type,
          },
          {
            title: "Start date",
            value: ({ start_date }) => fromDate(start_date),
          },
          {
            title: `${localization.targetEndDate}`,
            value: ({ end_date }) => fromDate(end_date),
          },
          {
            title: `${localization.targetSpend}`,
            value: ({ budgeted_spend }) =>
              budgeted_spend
                ? formatCurrency(
                  currencyData.filter((e) => e.label === "USD")[0]?.value,
                  budgeted_spend
                )
                : "-",
            alignment: "right",
          },
          {
            title: "Note",
            value: ({ note }) => note,
          },
        ]}
        options={(item) => {
          return [
            havePermissions.destroy && {
              title: "Remove",
              onClick: () => {
                setProject(item);
                setShow(true);
              },
            },
          ];
        }}
        button={
          havePermissions.update && {
            name: "Link Project",
            onClick: () => openPopup(),
          }
        }
      />
      {show && (
        <Popup
          onClose={() => setShow(false)}
          title="Remove linked project"
          successBtnTitle="Remove"
          text={`Are you sure you want to remove the "${project.name}" project?`}
          onClick={() => {
            removeHandler(project.id);
            setShow(false);
          }}
          dialog
          isDelete
          successBtnTitle="Remove"
        />
      )}
    </>
  );
};
export default LinkedProjectsTable;
