import CPO from "../../../../assets/icons/Slider/CPO.svg";
import PS from "../../../../assets/icons/Slider/PS.svg";
import FS from "../../../../assets/icons/Slider/FS.svg";
import PA from "../../../../assets/icons/Slider/PA.svg";
import CM from "../../../../assets/icons/Slider/CM.svg";
import PM from "../../../../assets/icons/Slider/PM.svg";

const sliderData = [
	{
		img: CPO,
		title: "Chief Procurement Officer",
		list: [
			"Approve and track procurement client satisfaction",
			"Control current and forward looking resource utilization",
			"Measure financial and non-financial benefits",
			"Manage procurement engagements in one place",
			"Smart reporting"
		]
	},
	{
		img: PS,
		title: "Procurement Stakeholder",
		list: [
			"Be able to set goals for each procurement engagement",
			"See what tasks need your attention in single portal",
			"Single point to view procurement impact on your organization",
			"Provide feedback to procurement on what is working and what is not"
		]
	},
	{
		img: FS,
		title: "Finance Stakeholder",
		list: [
			"Approve & Track projects financial benefits",
			"Set baseline spend for more robust savings measures",
			"Validate procurement benefits on project by project bases"
		]
	},
	{
		img: PA,
		title: "Procurement Analysts",
		list: [
			"Update your projects in one place",
			"No need to update spreadsheets for project and benefits updates",
			"Collaborate with stakeholders",
			"Single point for finance and stakeholders approve benefits",
			"Easy stakeholder updates from single source"
		]
	},
	{
		img: CM,
		title: "Category Manager",
		list: [
			"Single point for all category related data",
			"Structured processes to evaluate category risk and criticality",
			"Facilitated supply market assessments for each category",
			"Manage stakeholders relations across categories"
		]
	},
	{
		img: PM,
		title: "Procurement Manager",
		list: [
			"Control current and forward looking resource utilization",
			"Single point for team members to provide status updates",
			"No more chasing team members for updates to be manually consolidated",
			"Control resource utilization across team members"
		]
	}
];

export default sliderData;
