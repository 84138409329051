import s from "./ProjectActualSavingsTable.module.scss";
import {ReactComponent as ActualSavingsVector} from "../../../assets/icons/actualSavingsVector.svg";
import {ReactComponent as ActualSavingsPencil} from "../../../assets/icons/pencilChange.svg";
import MainTable from "../MainTable/MainTable";
import React from "react";
import {useHistory, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {formatCurrency} from "../../../helpers/currencyFormat";
import {currencyData} from "../../../helpers/helpData";
import {numberFormatAbbreviated} from "../../containers/CategoriesContainer/helpers";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";

const MonthTable = ({data, setEditSavingsMonth, setShow, setShowNewActual, project, className}) => {
  const history = useHistory();
  const params = useParams();
  const checkForMonth = item => Object.prototype.hasOwnProperty.call(item, "month");
  const {roles, id} = useSelector(state => state.user);
  const owner = project.owner?.id;

  const isUserGroup = owner && roles.includes(userRoles.procurement_admin) ||
    roles.includes(userRoles.admin) ||
    roles.includes(userRoles.cpo) ||
    id.includes(owner) ||
    roles.includes(userRoles.procurement_manager);

  const handleCustomNotes = (item) => {
    return history.push("/custom_notes/", {
      from: {
        id: item.id,
        month: item.month,
        idProject: params.id
      }
    });
  };
  return (
    <div className={className}>
      <MainTable
        className={className}
        data={data}
        uniqueRowStyle={item => !checkForMonth(item) && s.yearStyle}
        dataTypes={[
          {
            title: "Month",
            value: ({month, year}) => month ? month : "YEAR " + year
          },
          {
            title: "Committed",
            value: ({committed, committed_currency}) =>
              formatCurrency(
                currencyData.filter(e => e.label === committed_currency)[0]
                  ?.value,
                numberFormatAbbreviated(committed)
              ),
            alignment: "right"
          },
          {
            title: "Forecasted",
            value: ({forecasted, forecasted_currency}) =>
              formatCurrency(
                currencyData.filter(e => e.label === forecasted_currency)[0]
                  ?.value,
                numberFormatAbbreviated(forecasted)
              ),
            alignment: "right"
          },
          {
            title: "Actual",
            value: ({actual, actual_currency}) =>
              formatCurrency(
                currencyData.filter(e => e.label === actual_currency)[0]
                  ?.value,
                numberFormatAbbreviated(actual)
              ),
            alignment: "right"
          },
          {
            title: "",
            position: "end",
            value: item => (
              <div className={s.ActualSavingsIconsVector}>
                <ActualSavingsVector
                  onClick={() => handleCustomNotes(item)}
                />
              </div>
            ),
            hideCondition: item => !checkForMonth(item)
          },
          isUserGroup && {
            title: "",
            position: "end",
            value: (item) => (
              <div className={s.ActualSavingsIcons}>
                <ActualSavingsPencil
                  onClick={() => {
                    setEditSavingsMonth(item);
                    setShow(true);
                  }}
                />
              </div>
            ),
            hideCondition: item => !checkForMonth(item)
          },
        ]}
        buttons={isUserGroup && [
          {
            name: "New savings",
            onClick: () => {
              setShowNewActual(true);
            }
          }]}
      />
    </div>

  );
};

export default MonthTable;

MonthTable.propTypes = {
  data: PropTypes.any,
  setEditSavingsMonth: PropTypes.func,
  setShow: PropTypes.func,
};