import React from "react";
import Popup from "components/popups/Popup/Popup.jsx";

const DeleteTaskPopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      title="Delete stage task"
      text="Are you sure you want to delete this task?"
      onClose={onClose}
      successBtnTitle="Delete"
      onClick={onSubmit}
      isDelete
      dialog
    />
  );
};

export default DeleteTaskPopup;
