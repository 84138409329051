import React from "react"
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout"
import ProjectContainer from "components/containers/ProjectContainer/ProjectContainer"
import {useHistory} from "react-router-dom"
import {paths} from "routes/paths"
import {useSelector} from "react-redux"
import querystring from "query-string"
import {userRoles} from "../../../helpers/userPermissions/userRoles";

const ProjectPage = () => {
  const history = useHistory()
  const lastLocation = useSelector(state => state.lastLocation)
  const isNotificationsCrumbs = lastLocation[
  lastLocation.length - 2
    ]?.pathname.includes("/sentences")
  const user = useSelector(state => state.user)
  const currentYear = String(new Date().getFullYear());
  const isBusinessUser = user.roles.includes(userRoles.business_user);
  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);

  return (
    <DefaultLayout
      breadcrumbs={[
        {
          name: isNotificationsCrumbs ? "Notifications" : "Projects",
          disabled: !isNotificationsCrumbs && (isBusinessUser || isCategoryOwner),
          path: isNotificationsCrumbs
            ? paths.notifications
            : history.location.state?.history ||
            `/projects?${querystring.stringify({
              years: currentYear,
              owner_ids: user.id,
              tab: "Board"
            })}`
        },
        {name: "Project details", path: ""}
      ]}
    >
      <ProjectContainer/>
    </DefaultLayout>
  )
}

export default ProjectPage
