import React from "react";
import PropTypes from "prop-types";
import Textarea from "../../../../../elements/Textarea/Textarea";
import Attachments from "../../../../AddMilestoneTaskForm/components/Attachments/Attachments";
import services from "../../../../../../services";

const AttachmentsField = ({values, setFieldValue, field}) => {
  const correctFieldKey = field.key ? field.key : field.value;

  const handleChangeAttachment = files => {
    setFieldValue(correctFieldKey, [...(values[correctFieldKey] || []), ...files]);
  };

  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(correctFieldKey, values[correctFieldKey].filter((_, idx) => idx !== index));
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(correctFieldKey, values[correctFieldKey].filter((_, idx) => idx !== index));
      });
    }
  };

  return (
    <Attachments
      onChange={handleChangeAttachment}
      onDelete={handleDeleteAttachment}
      attachments={values[field.key] || []}
      label=""
      filesMaxLength={3}
    />
  );
};

export default AttachmentsField;

AttachmentsField.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any
};