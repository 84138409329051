import {
  SET_DETAILS,
  ADD_DETAIL,
  DELETE_DETAIL,
  EDIT_DETAIL,
  CURRENT_DETAIL,
  SET_POPUP,
  RESET_ALERT,
  SET_DETAILS_BY_YEAR,
  SET_DEFAULT_CURRENCY,
  SET_PERMISSIONS,
  RESET_ERROR,
  SET_DETAIL_ATTACHMENT
} from "./types"

export const initialState = {
  details: [],
  details_by_year: {},
  loading: true,
  popup: null,
  current_detail: null,
  alert: { type: "", message: "" },
  default_currency: "USD",
  permissions: { destroy: false, update: false },
  error: null
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_DETAILS:
      return {
        ...state,
        details: payload,
        loading: false
      }
    case SET_DETAIL_ATTACHMENT:
      return {
        ...state,
        details: state.details.map(detail =>
          detail.id === payload.detailID
            ? {
                ...detail,
                attachments: [...detail.attachments, payload.attachment]
              }
            : detail
        )
      }
    case SET_PERMISSIONS: {
      return { ...state, permissions: payload.permissions }
    }
    case SET_DETAILS_BY_YEAR:
      return {
        ...state,
        details_by_year: payload,
        loading: false
      }
    case ADD_DETAIL:
      return {
        ...state,
        details: payload.detail
          ? [payload.detail, ...state.details]
          : state.details,
        alert: payload.alert,
        popup: !payload.error ? null : "ADD",
        error: payload.error
      }
    case EDIT_DETAIL:
      return {
        ...state,
        details: payload.detail
          ? state.details.map(e =>
              e.id === payload.detail.id ? payload.detail : e
            )
          : state.details,
        alert: payload.alert,
        popup: !payload.error ? null : "EDIT",
        error: payload.error
      }
    case DELETE_DETAIL:
      return {
        ...state,
        details: payload.detail_id
          ? state.details.filter(e => e.id !== state.current_detail)
          : state.details,
        alert: payload.alert,
        popup: null
      }
    case CURRENT_DETAIL:
      return {
        ...state,
        current_detail: payload
      }
    case SET_POPUP:
      return {
        ...state,
        popup: payload
      }
    case RESET_ALERT:
      return {
        ...state,
        alert: {
          type: "",
          message: ""
        }
      }
    case RESET_ERROR:
      return {
        ...state,
        error: null
      }
    case SET_DEFAULT_CURRENCY:
      return {
        ...state,
        default_currency: payload
      }
    default:
      return state
  }
}
