import {CHANGE_TRANSLATION_MOD} from "../helpers/constants";

let initialState = {
  isEnableTranslationMod: false,
};

const globalSettings = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case CHANGE_TRANSLATION_MOD: {
      return {
        ...state,
        isEnableTranslationMod: payload
      }
    }
    default: {
      return state;
    }
  }
}

export default globalSettings;