import React from "react";
import s from "./Information.module.scss";
import PropTypes from "prop-types";
import {useTranslate} from "../../../hooks/useTranslate";
import {ReactComponent as InformationIcon} from "assets/icons/information.svg";
import classNames from "classnames";

const Information = ({message, informationClass}) => {
    const [translateKey, translateValue] = useTranslate(message);

    return (
        <div className={classNames(informationClass, s.informationWrap)}>
            <span className={s.iconWrap}>
                <InformationIcon/>
            </span>
            <span data-translate-key={translateKey}>
                {translateValue}
            </span>
        </div>
    );
};

export default Information;

Information.propTypes = {
    message: PropTypes.string,
};