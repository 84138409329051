import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import Button from "../../../../elements/Button/Button";
import services from "../../../../../services";
import {object, string} from "yup";
import s from "../../../../forms/AddProjectForm/AddProjectForm.module.scss";
import DropdownTree from "../../../../elements/DropdownTree/DropdownTree";

const CategoryForm = ({onSubmit, onClose, categoryList}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    services.categoriesServices.getCategories().then(res => {
      setCategories(res.data);
    });
  }, [categoryList]);

  const formik = useFormik({
    initialValues: {
      category_id: {name: "", id: ""},
    },
    validationSchema: object({
      category_id: object({
        name: string().required("Category is required"),
        id: string()
      }),
    }),
    validate: values => {
      let errors = {category_id: {}};
      if (categoryList.find(cl => cl.category.id === values.category_id.id)) {
        errors.category_id.name = "This category already exists";
      } else {
        errors = {};
      }
      return errors;
    },
    onSubmit: (values) => {
      onSubmit({
        category_id: values.category_id.id,
      });
      onClose();
    },
  });

  const {handleSubmit, values, setFieldValue, errors, touched} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <DropdownTree
        withSearch={true}
        value={values.category_id.name}
        onChange={e =>
          setFieldValue("category_id", {
            ...values.category_id,
            name: e.target.value
          })
        }
        onSelect={value => {
          setFieldValue("category_id", {
            id: value.id,
            name: value.name
          });
        }}
        name='category_id'
        className={s.field}
        options={categories}
        placeholder="Start typing category name"
        label='Category'
        error={
          errors.category_id && touched.category_id && errors.category_id.name
        }
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">Create</Button>
      </div>
    </form>
  );
};
export default CategoryForm;
