import React from 'react';
import s from "./PriorityItem.module.scss";
import {useTranslate} from "../../../../hooks/useTranslate";

const PriorityItem = ({item, index, onClick, withTranslate}) => {
  const [translateKey, translateValue] = useTranslate(item.label);
  return (
    <div
      key={index}
      onClick={onClick}
      title={item.label}
      className={s.itemWrap}
    >
      <div
        className={s.priority}
        style={{
          background: item.color
        }}
        data-translate-key={withTranslate && translateKey}
      >
        {withTranslate ? translateValue : item.label}
      </div>
    </div>
  );
};

export default PriorityItem;