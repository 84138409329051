import Article01 from "../../assets/img/blog/article01.jpg";
import Article02 from "../../assets/img/blog/article02.jpg";
import Author from "../../assets/img/blog/author01.jpg";

const blogData = [
  {
    id: "art01",
    img: Article01,
    path: "/article/art01",
    article: {
      pic: Author,
      name: "Anders Lillevik",
      date: "May 13 • 5 min read",
      title: "Category Management Basics",
      descr:
        "Procurement is still an evolving discipline and if you ask any two practitioners to describe what we do for any one of our processes such as “Supplier Management” or “Strategic Sourcing” you are likely to get very different answers. I believe this is largely because we, as procurement professionals, tend to have different focuses...",
    },
  },
  {
    id: "art02",
    img: Article02,
    path: "/article/art02",
    article: {
      pic: Author,
      name: "Anders Lillevik",
      date: "Apr 6 • 2 min read",
      title: "WHY FOCUS ON CATEGORY MANAGEMENT",
      descr:
        "I remember when I started in procurement way back in the 1990's, before we knew what a spend cube was and before the tools we take for granted today were available. My first job in procurement as a procurement analyst for a large retail bank, A.T Kearney team members were there helping the organization establish it's very ...",
    },
  },
];

const article01 = {
  id: "art01",
  img: Article01,
  path: "/article",
  article: {
    pic: Author,
    name: "Anders Lillevik",
    date: "May 13 • 5 min read",
    title: "Category Management Basics",
    text:
      "Procurement is still an evolving discipline and if you ask any two practitioners to describe what we do for any one of our processes such as “Supplier Management” or “Strategic Sourcing” you are likely to get very different answers. I believe this is largely because we, as procurement professionals, tend to have different focuses based on several factors such as the industry we are in and the maturity of the procurement organizations we work for. The topic of category management is no different. There are multiple approaches on how to deal with category management and what is included in category strategies and plans vary greatly between organizations. A recent survey of top procurement executives showed that ALL of them thought it was an important part of the procurement process but at the same time NONE of them said their organizations were doing category management consistently across their enterprise spend. I am writing this white paper to reflect on what I have found to be the main components of a comprehensive category management program and what is needed to make those components effective for organizations. Creating a Category Strategy The main outcome of creating a category strategy is to be able to determine how the different actors the plan relates to will behave and which tactical levers to use to manage the relationships in the category. Fundamentally this means the category should have guidelines for when to bring transactions to market, if and how to manage incumbent suppliers, the best way to transact with suppliers and existing and desired category Key Performance Indicators.",
  },
};

export { blogData, article01 };
