import React, {useState} from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import Button from "../../elements/Button/Button";
import Popup from "../../popups/Popup/Popup";
import s from "./AddNewActualSavingsForm.module.scss";
import CurrencySelectionField from "../../elements/CurrencySelectionField/CurrencySelectionField";
import {currencyData} from "../../../helpers/helpData";
import PropTypes from "prop-types";
import DatePicker from "../../elements/DatePicker/DatePicker";
import CustomDropdown from "../../elements/CustomDropdown/CustomDropdown";
import classNames from "classnames";
import {CalendarContainer} from "react-datepicker";
import {months} from "../../tables/ProjectActualSavingsTable/ActualSavingsMonthConst";
import {useHistory} from "react-router-dom";
import qs from "query-string";

const AddNewActualSavingsForm = ({title, text, setShowNewActual, onSubmitEdit, savingsGr}) => {
  const [startDate, setStartDate] = useState(new Date());
  const history = useHistory();
  const query = qs.parse(history.location.search);

  const formik = useFormik({
    initialValues: {
      id: "",
      type: "",
      startDate: startDate,
      savingsGroup: savingsGr?.map(e => ({value: e.id, label: e.name}))[0] ?? [],
      commitedSavings: "",
      commitedSavings_currency: "USD",
      actual: "",
      actual_currency: "USD",
      forecasted: "",
      forecasted_currency: "USD",
      month: ""
    },
    validationSchema: object({
      savingsGroup: string().required("Please, choose the savings group"),
      month: string().required("Please, choose the month"),
      actual: string()
        .required("Please, enter your actual savings")
        .matches(/^(?![$-])/, "Value should be equal to or greater than 0")
        .max(12, "Max digits quantity is 12"),
      forecasted: string()
        .required("Please, enter your forecasted savings")
        .matches(/^(?![$-])/, "Value should be equal to or greater than 0")
        .max(12, "Max digits quantity is 12"),
    }),
    onSubmit: (values) => {
      onSubmitEdit({
        saving_group: values.savingsGroup.value,
        year: values.month.getFullYear(),
        month: months.filter((el, index) => index === values.month.getMonth())[0],
        actual: values.actual,
        forecasted: values.forecasted,
      });
      setShowNewActual(false);
    }
  });
  const {
    handleSubmit,
    touched,
    values,
    errors,
    setFieldValue,
  } = formik;
  const calendarContainerStyles = ({className, children}) => {
    return (
      <div className={s.calendarStyleBlock}>
        <CalendarContainer className={className}>
          <div className={s.calendarStyleChildren}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };
  const setValue = savingsGr?.length > 0 && query.savings_group_ids && [savingsGr.find(saving => saving.id === query.savings_group_ids)].map(e => ({
    value: e.id,
    label: e.name
  }));

  return (
    <Popup
      className={s.popPupActualSaving}
      title={title}
      onClose={() => setShowNewActual(false)}
      text={text}
    >
      <form onSubmit={handleSubmit}>
        <div className={s.inputWrap}>
          <CustomDropdown
            className={s.savingsTypeStyles}
            options={savingsGr && savingsGr.map(e => ({value: e.id, label: e.name}))}
            value={setValue ? setValue[0] : values.savingsGroup[0]}
            onChange={option => setFieldValue("savingsGroup", option)}
            label={`Savings group`}
            placeholder="Choose savings group"
            controlClasses={classNames({[s.dropdown]: !values.savingsGroup})}
            error={touched.savingsGroup && errors.savingsGroup}
          />
          <div className="dateBlock">
            <DatePicker
              label="Month"
              selected={values.month}
              onChange={date => {
                setFieldValue("month", date);
              }}
              selectsStart
              startDate={values.month}
              calendarContainer={calendarContainerStyles}
              placeholderText="Choose month"
              wrapperClassName="wrapperdatePicker"
              className="customInput"
              inputLabelClassName={s.monthInputStyle}
              todayButton="Go to today"
              showYearDropdown
              showMonthYearPicker
              dateFormat={"MMMM"}
              yearDropdownItemNumber={5}
              scrollableYearDropdown
              error={touched.month && errors.month}
            />

          </div>
        </div>
        <CurrencySelectionField
          disabled
          className={s.currencyItem}
          options={currencyData}
          value={values.commitedSavings}
          defaultValue={values.currency}
          onChange={({fieldVal, ...currency}) => {
            setFieldValue("currency", currency);
            setFieldValue("commitedSavings", fieldVal);
          }}
          error={touched.commitedSavings && errors.commitedSavings}
          label="Commited savings"
          additionalLabel="(Calculated from financial benefits)"
          fieldPlaceholder="000,000"
        />
        <CurrencySelectionField
          className={s.currencyItem}
          options={currencyData}
          value={values.actual}
          defaultValue={values.currency}
          onChange={({fieldVal, ...currency}) => {
            setFieldValue("currency", currency);
            setFieldValue("actual", fieldVal);
          }}
          error={touched.actual && errors.actual}
          label="Actual savings"
          fieldPlaceholder="000,000"
        />
        <CurrencySelectionField
          value={values.forecasted}
          defaultValue={values.currency}
          onChange={({fieldVal, ...currency}) => {
            setFieldValue("currency", currency);
            setFieldValue("forecasted", fieldVal);
          }}
          error={touched.forecasted && errors.forecasted}
          label="Forecasted savings"
          fieldPlaceholder="000,000"
        />
        <div className={s.popupBtnsWrap}>
          <Button
            styled="secondary"
            type="reset"
            className="popupBtn"
            onClick={() => setShowNewActual(false)}
          >
            Cancel
          </Button>
          <Button styled="primary" type="submit" className="popupBtn">
            Save
          </Button>
        </div>
      </form>
    </Popup>
  );
};

export default AddNewActualSavingsForm;

AddNewActualSavingsForm.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  setShowNewActual: PropTypes.func,
  data: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
