export const emptyValues = {
  first_name: "",
  last_name: "",
  team_name: "",
  title: "",
  email: "",
  password: "",
  type: {
    label: "",
    value: "",
  },
  division: {label: "", value: ""},
  department: {label: "", value: ""},
  role: {label: "", value: ""},
  manager: {
    label: "",
    value: "",
  },
  status: "",
  ready_for_conversation: false
};