import React, {useEffect, useState} from "react"
import FilterBar from "components/elements/FilterBar/FilterBar"
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown"
import qs from "query-string"
import {debounce} from "lodash"
import {useHistory} from "react-router-dom"
import {getAllIds} from "components/elements/FilterBar/helpers/helpers"
import services from "../../../services";

const SystemReportsProjectsFilter = (
  {
    filters,
    className
  }
) => {
  const history = useHistory();
  const [savedFilter, setSavedFilter] = useState(null);

  useEffect(() => {
    services.filterService.getFilter("system_report_projects").then(res => {
      if (res.data && res.data[0]) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      }
    });
  }, []);


  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  )
  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values)
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction
    }
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    })
  }

  const handleClearAll = () => {
    const searchQuery = {page, tab, sort_by, direction}
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    })
  }

  const saveDefaultFilter = () => {
    services.filterService.addFilter("system_report_projects", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  }

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  }

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams ?? savedFilter.filter_params),
      page: 1,
      tab,
      sort_by,
      direction,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  }


  const handleSearch = debounce(term => {
    history.push({
      search: qs.stringify(
        {...query, query: term},
        {
          arrayFormat: "comma"
        }
      )
    })
  }, 300);

  return (
    <FilterBar
      filterName='Filter projects'
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      withSearch
    >
      <FilterBarDropdown
        name='Project type'
        options={filters.project_type_ids}
        defaultValues={query?.project_type_ids?.split(",")}
        handleChange={values => {
          handleChangeFilter("project_type_ids", values)
        }}
        searchPlaceholder='Search project type'
      />
      <FilterBarDropdown
        name='Owner'
        searchPlaceholder='Search owner'
        defaultValues={query?.owner_ids?.split(",")}
        options={filters.owners}
        handleChange={values => {
          handleChangeFilter("owner_ids", values)
        }}
      />
    </FilterBar>
  )
}

export default SystemReportsProjectsFilter
