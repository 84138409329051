import React from "react";
import styles from "./SpendStatuses.module.scss";

const SpendStatuses = ({ data }) => {
  return (
    <>
      {data.map((status) => (
        <div key={status.name}>
          <div className={styles.statusContainer}>
            <div className={styles.textWrapper}>
              <p className={styles.statusName}>{status.name}</p>
              <p className={styles.statusPercent}>{status.value}%</p>
            </div>
            <span className={styles.statusBarContainer}>
              <span
                className={styles.statusBar}
                style={{
                  width: `${status.value}%`,
                  backgroundColor: status.color,
                }}
              />
            </span>
          </div>
        </div>
      ))}
    </>
  );
};

export default SpendStatuses;
