import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {ReactComponent as ArrowDown} from "../../../../../../../../../assets/icons/arrowDown.svg";
import s from "./MilestoneTasksGroup.module.scss";
import MilestoneTasksGroupStage from "../MilestoneTasksGroupStage/MilestoneTasksGroupStage";
import {backgroundTypes} from "../../../../../../../../../helpers/helpData";
import classNames from "classnames";
import MilestoneDragBoard from "../MilestoneTrelloBoard/MilestoneDragBoard";
import {useTranslate} from "../../../../../../../../../hooks/useTranslate";
import services from "../../../../../../../../../services";

const MilestoneTasksGroup = (
  {
    nameGroup,
    milestones,
    typeGroup = "original",
    onCreateTask,
    onEditTask,
    onDeleteTask,
    owner,
    projectName,
    projectTypeId,
    deleteApprovalStep,
    sendAnswer,
    sendRequest,
    statuses,
  }
) => {
  const [translateKey, translateValue] = useTranslate(nameGroup.replace("_", " "))
  const [isOpen, setOpen] = useState(false);

  const renderContent = () => {
    if (typeGroup === "original") return (
      <div className={s.content}>
        {milestones.map(milestone => (
          <MilestoneTasksGroupStage
            nameGroup={nameGroup}
            milestone={milestone}
            key={milestone.id}
            tasksColor={backgroundTypes[milestone.color]}
            milestonesLength={milestones.length}
            projectName={projectName}
            projectTypeId={projectTypeId}
            owner={owner}
            deleteApprovalStep={deleteApprovalStep}
            sendAnswer={sendAnswer}
            sendRequest={sendRequest}
            statuses={statuses}
          />
        ))}
      </div>
    );

    return (
      <MilestoneDragBoard
        milestones={milestones}
        addNewTask={(milestoneId, values) => onCreateTask(milestoneId, values)}
        editTask={(milestoneId, taskId, values) => onEditTask(milestoneId, taskId, values)}
        deleteTask={(milestoneId, taskId) => onDeleteTask(milestoneId, taskId)}
        ownerId={owner}
        statuses={statuses}
      />
    );
  };

  return (
    <div className={classNames(s.groupWrap, {[s.opened]: isOpen})}>
      <div className={classNames(s.headerGroup)} onClick={() => setOpen(!isOpen)}>
        <p data-translate-key={translateKey}>{translateValue}</p>
        <ArrowDown className={classNames(s.arrow, {
          [s.opened]: isOpen
        })}/>
      </div>
      {isOpen && renderContent()}
    </div>
  );

};

export default MilestoneTasksGroup;

MilestoneTasksGroup.propTypes = {
  nameGroup: PropTypes.string,
  milestones: PropTypes.number,
  typeGroup: PropTypes.string,
  onCreateTask: PropTypes.func,
  onEditTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
  owner: PropTypes.any,
};