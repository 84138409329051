import React from "react";
import s from "../PrivacyPage.module.scss";

const Point11 = () => {
  return (
    <div id="point11" className={s.pointBlock}>
      <h3 className={s.subtitle}>11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>

      <p className={s.text} style={{ fontStyle: "italic" }}>
        <span>In Short: </span>Yes, we will update this notice as necessary to
        stay compliant with relevant laws.
      </p>

      <p className={s.text}>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>
    </div>
  );
};

export default Point11;
