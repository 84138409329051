import React, {useContext, useEffect, useState} from "react";
import ApprovalForm from "../../forms/ApprovalForm/ApprovalForm";
import styles from "./EditApprovalContainer.module.scss";
import {prepareRequestData} from "../../../helpers/approvals/prepareRequestData";
import services from "../../../services";
import {useHistory, useParams} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import {prepareFormData} from "../../../helpers/approvals/prepareFormData";
import {ModeContext} from "../../../contexts/ModeContext/ModeContext";
import DropListTrigger from "../../elements/DropListTrigger/DropListTrigger";
import Popup from "../../popups/Popup/Popup";
import AlertContext from "../../../contexts/AlertContext/AlertContext";

const EditApprovalContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const {enableViewMode} = useContext(ModeContext);
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(null);
  const [popUp, setPopUp] = useState(null);
  const {setAlert} = useContext(AlertContext);

  useEffect(() => {
    services.approvalServices.getApprovalDetails(params.milestoneId, params.id).then(res => {
      setData(res.data);
    });
  }, []);

  const send = values => {
    const prepareValues = {
      ...prepareRequestData(values),
    };

    services.approvalServices.editApproval(params.milestoneId, params.id, prepareValues).then(res => {
      if (res.data) {
        setData(res.data);
        enableViewMode();
      }
    }).catch((err) => {
      setAlert({
        message: err.response?.data.error ||
          "Something went wrong. Please, try again.",
        type: "error"
      });
    });
  }

  const deleteApproval = () => {
    services.approvalServices
      .deleteApproval(params.milestoneId, params.id)
      .then(res => {
        if (res.status < 300) history.goBack();
      }).catch((err) => {
      setAlert({
        message: err.response?.data.error ||
          "Something went wrong. Please, try again.",
        type: "error"
      });
    });
    return true;
  };

  const progressLoader = (
    <div className="loaderWrap justifyCenter alignCenter">
      <CircularProgress/>
    </div>
  );

  if (data?.id) return (
    <>
      <div className={styles.approvalContainer}>
        <div className={styles.header}>
          <h2>{history.location.state?.projectName ?? "Project"}</h2>
          <DropListTrigger
            data={[
              {
                title: "Delete",
                onClick: () => setPopUp("delete")
              }
            ]}
            visibleMenu={visibleDropListMenu}
            setVisibleMenu={setVisibleDropListMenu}
          />
        </div>
        <ApprovalForm onSubmit={send} initialValues={prepareFormData(data)}/>
      </div>
      {popUp === "delete" && (
        <Popup
          title="Delete approval step"
          text="Are you sure you want to delete this approval step?"
          onClose={() => setPopUp(null)}
          successBtnTitle="Delete"
          onClick={() => deleteApproval()}
          isDelete
          dialog
        />
      )}
    </>
  );

  return progressLoader;
};

export default EditApprovalContainer;