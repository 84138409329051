import React, {Fragment} from "react";
import {useHistory} from "react-router-dom";
import querystring from "query-string";
import Tabs from "components/elements/Tabs/Tabs";
import UsersTable from "components/tables/UsersTable/UsersTable";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import TableLoading from "components/loadingStates/Table/Table";
import PropTypes from "prop-types";
import qs from "query-string";

const UsersTabs = ({state}) => {
  const history = useHistory();
  const {user_type} = querystring.parse(history.location.search);

  const tabChangeHandler = type => {
    const query = qs.parse(history.location.search);
    history.push({search: querystring.stringify({...query, user_type: type})});
  };
  const isEmptyFilters = !Boolean(
    Object.keys(state.activeFilters).filter(e => state.activeFilters[e].length)
      .length
  );

  return (
    <Tabs defaultActiveTab={user_type || "procurement"} handleClick={tabChangeHandler}>
      <div label='procurement'>
        {!state.loading ? (
          <Fragment>
            {state.users.length > 0 ? (
              <UsersTable state={state} perPage={30} dragIdLabel="user"/>
            ) : (
              <EmptyBlock
                title={
                  isEmptyFilters
                    ? "You don’t have Procurement users yet."
                    : "No work item results found"
                }
                message
              />
            )}
          </Fragment>
        ) : (
          <TableLoading length={6}/>
        )}
      </div>
      <div label="business">
        {!state.loading ? (
          <Fragment>
            {state.users.length > 0 ? (
              <UsersTable dragIdLabel="user" state={state} perPage={30}/>
            ) : (
              <EmptyBlock
                title="There are no Business users added yet."
                message
              />
            )}
          </Fragment>
        ) : (
          <TableLoading length={7}/>
        )}
      </div>
      <div label="support">
        {!state.loading ? (
          <Fragment>
            {state.users.length > 0 ? (
              <UsersTable dragIdLabel="user" state={state} perPage={30}/>
            ) : (
              <EmptyBlock
                title="There are no Support users added yet."
                message
              />
            )}
          </Fragment>
        ) : (
          <TableLoading length={6}/>
        )}
      </div>
    </Tabs>
  );
};

export default UsersTabs;

UsersTabs.propTypes = {
  state: PropTypes.any
};
