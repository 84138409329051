import React from "react";
import PropTypes from 'prop-types';
import s from "./MOSlide.module.scss";

const MOSlide = ({
  img1,
  number1,
  title1,
  text1,
  img2,
  number2,
  title2,
  text2,
}) => {
  return (
    <div className={s.slide}>
      <img src={img1} alt="cardImg1" className={s.img} />

      <h3 className={s.title}>
        {number1}. {title1}
      </h3>
      <p className={s.text}>{text1}</p>

      <img src={img2} alt="cardImg1" className={s.img} />

      <h3 className={s.title}>
        {number2}. {title2}
      </h3>
      <p className={s.text}>{text2}</p>
    </div>
  );
};

MOSlide.propTypes = {
  img1: PropTypes.any,
  number1: PropTypes.string,
  title1: PropTypes.string,
  text1: PropTypes.string,
  img2: PropTypes.any,
  number2: PropTypes.string,
  title2: PropTypes.string,
  text2: PropTypes.string
}

MOSlide.defaultProps = {
  img1: "",
  number1: "",
  title1: "",
  text1: "",
  img2: "",
  number2: "",
  title2: "",
  text2: ""
}

export default MOSlide;
