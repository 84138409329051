import React from "react";
import MainTable from "../MainTable/MainTable";
import { formatCurrency } from "helpers/currencyFormat";
import { fromDate } from "helpers/dateFormat";
import { useHistory } from "react-router-dom";

const BusinessProcurementActivityTable = ({ data }) => {
  const history = useHistory();
  return (
    <MainTable
      totalName="Requested projects"
      ariaLabel="Requested projects"
      data={data}
      dataTypes={[
        {
          title: "Project name",
          value: ({ name }) => name,
          onClick: (id) => history.push(`/projects/${id}/preview`),
          sticky: true
        },
        {
          title: "Project owner",
          value: ({ owner }) => owner?.name || "-",
        },
        {
          title: "Type of Spend",
          value: ({ type_of_spend }) => type_of_spend,
        },
        {
          title: "Budget",
          value: ({ budget, budget_currency }) =>
            formatCurrency(budget_currency, budget),
          alignment: "right",
        },
        {
          title: "Target end date",
          value: ({ end_date }) => fromDate(end_date),
        },
        {
          title: "Status",
          value: ({ status }) => status,
        },
      ]}
    />
  );
};
export default BusinessProcurementActivityTable;
