import React from "react";
import styles from "./RequestFormStepsHeader.module.scss";
import Button from "../../../../elements/Button/Button";
import Step from "./Step";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import {ReactComponent as StepArrow} from "../../../../../assets/icons/step-arrow.svg";

const RequestFormStepsHeader = ({currentStep, prev, steps, isUpdate}) => {
  const dots = <div className={styles.dots}>. . .</div>;
  const history = useHistory();
  const arrow = (
    <div className={styles.arrow}>
      <StepArrow/>
    </div>
  );

  const renderSteps = () => {
    const lastStepIdx = steps.length;

    if (steps.length <= 5) {
      return steps.map((step, index) => {
        const idx = ++index;
        return <Step
          key={idx}
          step={step}
          idx={idx}
          total={steps.length}
          isActive={currentStep === idx}
        />;
      });
    } else {
      const firstStep = <Step step={steps[0]} idx={1} total={steps.length} isActive={currentStep === 1}/>;

      const lastStep = (
        <Step
          step={steps[lastStepIdx - 1]}
          idx={lastStepIdx}
          total={lastStepIdx}
          isActive={currentStep === lastStepIdx}/>
      );
      if (currentStep === 1) {
        return (
          <>
            {firstStep}
            <Step
              step={steps[1]}
              idx={2}
              total={steps.length}
              isActive={currentStep === 2}
            />
            {dots}
            {lastStep}
          </>
        );
      }

      if (currentStep === steps.length) {
        return (
          <>
            {firstStep}
            {dots}
            {<Step
              step={steps[lastStepIdx - 2]}
              idx={lastStepIdx - 1}
              total={lastStepIdx}
              isActive={false}
            />}
            {lastStep}
          </>
        );
      }

      return (
        <>
          {firstStep}
          {currentStep > 3 && dots}
          {currentStep > 3 && arrow}
          {currentStep !== 2 && (
            <Step step={steps[currentStep - 2]} idx={currentStep - 1} total={steps.length} isActive={false}/>
          )}
          <Step step={steps[currentStep - 1]} idx={currentStep} total={steps.length} isActive={true}/>
          {currentStep !== steps.length - 1 && (
            <Step step={steps[currentStep]} idx={currentStep + 1} total={steps.length} isActive={false}/>)}
          {currentStep < steps.length - 2 && dots}
          {currentStep < steps.length - 2 && arrow}
          {lastStep}
        </>
      );
    }
  };

  return (
    <div className={styles.stepsWrapper}>
      <Button
        styled="secondary"
        type="reset"
        className="popupBtn"
        onClick={e => {
          e.preventDefault();
          (currentStep !== 1) ? prev(currentStep - 1) : history.goBack();
        }}
      >
        {currentStep === 1 ? "Cancel" : "Back"}
      </Button>
      <div className={styles.stepsList}>
        {renderSteps()}
      </div>
      <Button
        styled="primary"
        type="submit"
        className="popupBtn"
      >
        {currentStep === steps.length ? isUpdate ? "Save" : "Create" : "Next"}
      </Button>
    </div>
  );
};

export default RequestFormStepsHeader;

RequestFormStepsHeader.propTypes = {
  currentStep: PropTypes.number,
  prev: PropTypes.func,
  steps: PropTypes.array,
  isUpdate: PropTypes.bool,
};