import React, {useEffect, useState} from "react";
import MainTable from "../../../../../../tables/MainTable/MainTable";
import s from "../Category.module.scss";
import services from "../../../../../../../services";
import TableLoading from "../../../../../../loadingStates/Table/Table";
import EmptyTab from "../EmptyTab/EmptyTab";

const CategoryStakeholders = ({id}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    services.requestTypeServices.getCategoryStakeholders(id).then(res => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (loading) return <TableLoading length={2}/>;

  if (!data.length) return <EmptyTab message="Category doesn't have any Category Stakeholders yet"/>;

  return (
    <MainTable
      totalName='Category stakeholders'
      data={data}
      dataTypes={[
        {
          title: "Stakeholder",
          value: ({name, id}) => <a className={s.link} href={`/users/${id}`}>{name}</a>,
        },
        {
          title: "Title",
          value: ({title}) => title,
        },
        {
          title: "Division",
          value: ({division}) => division.name,
        },
        {
          title: "Department",
          value: ({department}) => department.name,
        },
        {
          title: "Role",
          value: ({user_type}) => <span className={s.capitalize}>{user_type.replace("_", " ")}</span>,
        },
      ]}
    />
  );
};

export default CategoryStakeholders;
