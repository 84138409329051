import axios from "./axios";

export default {
  getSelf: async () => {
    try {
      const result = await axios.get("/self");
      return result;
    } catch (error) {
      throw error;
    }
  },
  updateSelf: async (params) => {
    try {
      const result = await axios.put("/self", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
};
