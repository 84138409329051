import { config } from "./defaultConfig";

const getWidgetHeight = (data) => {
  let maxColLength = 0;
  Object.keys(data).map((col) => {
    const colLength = data[col].length;
    if (colLength > maxColLength) {
      maxColLength = colLength;
    }
  });
  return (
    maxColLength * config.colHeight +
    config.rowMargin * (maxColLength - 1) +
    config.headerHeight +
    config.innerMarginBottom
  );
};

const getWidgetWidth = (data) => {
  const colsLength = Object.keys(data).length;
  return (
    colsLength * config.colWidth +
    config.colMargin * (colsLength - 1) +
    config.innerMarginLeft * 2
  );
};

export const width = getWidgetWidth;
export const height = getWidgetHeight;
