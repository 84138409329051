import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import s from "./ChangeApprovalStepForm.module.scss";
import Button from "components/elements/Button/Button";
import SelectCheckDropdown from "components/elements/SelectCheckDropdown/SelectCheckDropdown";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import { useFormik } from "formik";
import services from "services";
import { object, string } from "yup";
import { projectTabs, approvalTypes } from "./helpers";

const ChangeApprovalStepForm = ({ onSubmit, onClose, initialValues }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    services.adminServices.getApprovalGroupsList().then((res) =>
      setGroups(
        res.data.map(({ id, name }) => ({
          id,
          value: name,
          label: name,
        }))
      )
    );
  }, []);
  const emptyValues = {
    group: {
      value: "",
      label: "",
      id: "",
    },
    project_tab: {
      value: "",
      label: "",
    },
    approval_type: {
      label: "",
      value: "",
    },
  };
  const formik = useFormik({
    initialValues: initialValues || emptyValues,
    validationSchema: object({
      group: object({
        id: string().required("Please, choose approval group"),
      }),
      project_tab: object({
        value: string().required("Please, choose project tab"),
      }),
      approval_type: object({
        value: string().required("Please, choose approval type"),
      }),
    }),
    onSubmit: (values) => {
      onSubmit({
        approval_group_id: values.group.id,
        project_tab: values.project_tab.value,
        approval_type: values.approval_type.value,
      });
    },
  });

  const { handleSubmit, touched, values, errors, setFieldValue } = formik;
  return (
    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
      <CustomDropdown
        value={values.group.value}
        onChange={(e) => {
          setFieldValue(
            "group",
            groups.filter((group) => group.value === e.value)[0]
          );
        }}
        options={groups}
        name="group"
        label="Approval group"
        placeholder="Choose group"
        className={s.dropdown}
        error={errors.group && touched.group && errors.group.id}
      />
      <CustomDropdown
        value={values.project_tab.value}
        onChange={(e) => setFieldValue("project_tab", e)}
        options={projectTabs}
        name="project_tab"
        label="Project tab"
        placeholder="Choose project tab"
        className={s.dropdown}
        error={
          errors.project_tab && touched.project_tab && errors.project_tab.value
        }
      />
      <CustomDropdown
        value={values.approval_type.value}
        onChange={(e) => setFieldValue("approval_type", e)}
        options={approvalTypes}
        name="type"
        label="Approval type"
        placeholder="Choose type"
        className={s.dropdown}
        error={
          errors.approval_type &&
          touched.approval_type &&
          errors.approval_type.value
        }
      />

      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {initialValues ? "Save" : "Add"}
        </Button>
      </div>
    </form>
  );
};

ChangeApprovalStepForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
ChangeApprovalStepForm.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
};

export default ChangeApprovalStepForm;
