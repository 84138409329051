import {currencyData} from "../../../../helpers/helpData";
import {generateUniqueId} from "../../../../helpers/uniqueId";

const entityFormat = (entities, type) => entities.map(entity => ({
  entity: {
    label: entity[type].name,
    value: entity[type].id,
  },
  value: entity.split_sum,
  percent: entity.split_sum_percent,
  id: generateUniqueId(),
}));

export const getInitialValues = defaultValues => {
  return {
    start_date: defaultValues?.start_date ? new Date(defaultValues.start_date) : new Date(),
    end_date: defaultValues?.end_date ? new Date(defaultValues.end_date) : new Date(),
    currency: defaultValues?.project_spend_currency ? currencyData
      .find(currency => currency.label === defaultValues.project_spend_currency) : currencyData[15],
    final_cost: defaultValues?.project_spend || 0,
    final_savings: defaultValues?.project_savings || 0,
    savings_type: defaultValues?.savings_type ? {
      label: defaultValues.savings_type.name,
      value: defaultValues.savings_type.id,
    } : null,
    note: defaultValues?.note || "",
    attachments: defaultValues?.attachments || [],
    departments: defaultValues?.savings?.departments ?
      entityFormat(defaultValues.savings.departments, "department") : [],
    divisions: defaultValues?.savings?.divisions ?
      entityFormat(defaultValues.savings.divisions, "division") : [],
    users: defaultValues?.savings?.procurement_users ?
      entityFormat(defaultValues.savings.procurement_users, "procurement_user") : [],
  }
};