import React, {useEffect, useState} from "react";
import {debounce} from "lodash";
import qs from "query-string";
import FilterBar from "../../elements/FilterBar/FilterBar";
import {useHistory} from "react-router-dom";
import style from "./AdminSettingsSupplierUsersFilter.module.scss";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import services from "../../../services";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";
import {userRoles} from "../../../helpers/usersData";

const AdminSettingsSupplierUsersFilter = () => {
  const [suppliers, setSuppliers] = useState([]);
  const history = useHistory();
  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  useEffect(() => {
    services.supplierServices
      .suppliersAutocomplete()
      .then(res => setSuppliers(res.data));
  }, []);


  const handleClearAll = () => {
    history.push({search: qs.stringify()});
  };

  const searchSuppliers = debounce((term, checkedOptions) => {
    services.supplierServices
      .suppliersAutocomplete({query: term})
      .then(res => setSuppliers([...checkedOptions, ...res.data]));
  }, 300);

  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = filterName !== "expires_in" ? getAllIds(values) : values;
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  return (
    <FilterBar
      filterName="Filter supplier users"
      handleClearAll={handleClearAll}
      className={style.filterBar}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      withSearch
    >
      <FilterBarDropdown
        name="Supplier"
        options={suppliers}
        defaultValues={query?.supplier_ids}
        searchPlaceholder="Search suppliers"
        autocomplete={(e, checkedOptions) => searchSuppliers(e.target.value, checkedOptions)}
        handleChange={values => {
          handleChangeFilter("supplier_ids", values);
        }}
      />
      <FilterBarDropdown
        name="Role"
        options={userRoles.map(role => {
          return {name: role.label, id: role.value};
        })}
        defaultValues={query?.supplier_ids}
        searchPlaceholder="Search role"
        autocomplete={(e, checkedOptions) => searchSuppliers(e.target.value, checkedOptions)}
        handleChange={values => {
          handleChangeFilter("role", values);
        }}
      />
    </FilterBar>
  );
};

export default AdminSettingsSupplierUsersFilter;