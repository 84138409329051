import React, { useState, useEffect } from "react";
import s from "./CategoryDetailContainer.module.scss";
import CategoryInfo from "./components/CategoryInfo/CategoryInfo";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentCategoryID, setCategories } from "actions/categories";
import { CircularProgress } from "@material-ui/core";
import { getCategoryName } from "./helpers";

const CategoryDetailContainer = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categories);
  const categoryName = getCategoryName(categories, params.id);

  useEffect(() => {
    dispatch(setCategories());
    setLoading(false);
  }, []);

  useEffect(() => {
    params.id && dispatch(setCurrentCategoryID(params.id));
  }, [params.id]);

  return (
    <section className={s.categoriesContainer}>
      {loading ? (
        <div className="loaderWrap">
          <CircularProgress />
        </div>
      ) : (
        <CategoryInfo
          category={{
            id: params.id,
            name: categoryName,
          }}
          categories={categories}
        />
      )}
    </section>
  );
};

export default CategoryDetailContainer;
