import React, {useState} from "react";
import PropTypes from "prop-types";
import styles from "./TaskDetails.module.scss";
import DetailLoading from "../../../../loadingStates/Detail/Detail";
import Popup from "../../../../popups/Popup/Popup";
import EditMilestoneTaskFormNew from "../../../../forms/EditMilestoneTaskFormNew/EditMilestoneTaskFormNew";
import {getDisabledFields} from "../../helpers/getDisabledFields";
import {ReactComponent as Edit} from "../../../../../assets/icons/edit-pencil.svg";
import Hyperlinks from "./Hyperlinks";
import InfoBlock from "../../../../elements/InfoBlock/InfoBlock";
import {formatDate} from "../../../../../helpers/dateFormat";
import {trimRest} from "../../../../../helpers/stringHelper";
import Avatar from "../../../../elements/Avatar/Avatar";

const TaskDetails = ({data, isHighRule, editHandler}) => {
  const [isOpen, setOpen] = useState(false);

  if (!data?.id) return (
    <div className={styles.detailLoading}>
      <DetailLoading length={3}/>
    </div>
  );

  return (
    <React.Fragment>
      <div className={styles.details}>
        <div className={styles.groupWrap}>
          <div className={styles.main}>
            <InfoBlock title="Responsible user:">
              <div className={styles.infoBlockWrap}>
                {data.responsible_user.name && <Avatar name={data.responsible_user.name}/>}
                {data.responsible_user.name || "-"}
              </div>
            </InfoBlock>
            <InfoBlock title="Start date:">
              {formatDate(data.start_date, true) || "-"}
            </InfoBlock>
            <InfoBlock title="End date:">
              {formatDate(data.end_date, true) || "-"}
            </InfoBlock>
            <InfoBlock title="Attachments:">
              {data.attachments.map((a, index) => (
                <div key={index}>
                  <a
                    title={a.name}
                    className={styles.link}
                    href={a.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {trimRest(20, a.name)}
                  </a>
                  {index < data.attachments.length - 1 && ", "}
                </div>
              ))}
              {data.attachments.length === 0 && "-"}
            </InfoBlock>
            <InfoBlock title="Help:">
              {data.help_text || "-"}
            </InfoBlock>
          </div>
          <Hyperlinks hyperlinks={data.hyperlinks}/>
          <div className={styles.edit}>
            <Edit onClick={() => setOpen(true)}/>
          </div>
        </div>
        <InfoBlock title="Description:">
          {data.description || "no description"}
        </InfoBlock>
      </div>
      {isOpen && (
        <Popup
          onClose={() => setOpen(false)}
          title={`Edit ${data.task_type} task`}
        >
          <EditMilestoneTaskFormNew
            disabledFields={getDisabledFields(data.task_type, isHighRule)}
            onSubmit={values => {
              editHandler(values);
              setOpen(false);
            }}
            task={data}
            onClose={() => setOpen(false)}
          />
        </Popup>
      )}
    </React.Fragment>
  );
};

export default TaskDetails;

TaskDetails.propTypes = {
  data: PropTypes.any,
  isHighRule: PropTypes.bool,
  editHandler: PropTypes.func
};