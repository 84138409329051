import React, {useCallback, useState} from "react";
import {useFormik} from "formik";
import Button from "../../../elements/Button/Button";
import {userRoles, userTypes} from "../../../../helpers/usersData";
import s from "../UserDepartmentsTable.module.scss";
import CustomDropdown from "../../../elements/CustomDropdown/CustomDropdown";
import classNames from "classnames";
import Input from "../../../elements/Input/Input";
import DropdownTree from "../../../elements/DropdownTree/DropdownTree";
import AutoCompleteInput from "../../../elements/AutoCompleteInput/AutoCompleteInput";
import {debounce} from "lodash";
import services from "../../../../services";
import Checkbox from "../../../elements/Checkbox/Checkbox";
import {object, string} from "yup";
import {useDidUpdate} from "../../../../hooks/useDidUpdate";

const UserDepartmentForm = ({onClose, onSubmit, defaultData, departments, parentDepartment}) => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState();

  const procurementUserRoles = userRoles
    .filter(role => (role.userType.value === roles?.value && role.value !== "admin"))
    .map(role => role.value)
    .join(",");

  const formik = useFormik({
    initialValues: {
      department: defaultData?.name || "",
      type: userTypes.find(itm => itm.value === defaultData?.department_type) || "",
      related_department: parentDepartment || "",
      leader: defaultData?.leader || "",
      project_client: defaultData?.project_client || true,
    },
    validationSchema: object({
      department: string()
        .required("Name department is required")
        .min(2, "Please enter a department name with at least 2 characters")
        .max(32, "Please enter a department name with up to 32 characters"),
      type: object({value: string().required("Type is required")}),
    }),

    onSubmit: (values) => {
      onSubmit({
        name: values.department,
        department_type: values.type.value,
        related_department_id: values.related_department.id,
        leader: values.leader.id,
        project_client: values.project_client
      });
      onClose();
    },
  });
  const {handleSubmit, touched, values, errors, setFieldValue} = formik;

  const usersAutocomplete = useCallback(
    debounce(
      (term, roles) =>
        services.userServices
          .stakeholdersAutocomplete({
            roles: roles,
            query: term
          })
          .then(res => setUsers(res.data)),
      300
    ),
    []
  );

  useDidUpdate(() => {
    setUsers([]);
    setFieldValue("leader", {
      value: "",
      name: "",
    });
  }, [values.type.value]);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        className={classNames(s.field, {
          [s.withError]: errors.department && touched.department
        })}
        name="department"
        label="Department"
        value={values.department}
        placeholder="Enter department name"
        onChange={e => {
          setFieldValue("department", e.target.value);
        }}
        error={touched.department && errors.department}
        limitValue={32}
        withCounter
      />
      <div>
        <CustomDropdown
          value={values.type?.label}
          onChange={e => {
            setFieldValue("type", e);
            setRoles(e);
          }}
          options={userTypes.filter(user => user.value !== "admin")}
          name="type"
          label="Type"
          className={s.dropdown}
          placeholder="Choose type"
          error={errors.type && touched.type && errors.type?.value}
        />
        <DropdownTree
          withSearch={true}
          defaultValue={values.related_department}
          onChange={e => setFieldValue("related_department", {id: "", name: e.target.value})}
          onSelect={({id, name}) => setFieldValue("related_department", {id, name})}
          name="department"
          className={s.dropdown}
          options={departments}
          placeholder="Start typing departments name"
          label="Related department"
          error={errors.related_department && touched.related_department && errors.related_department.id}
        />
        <AutoCompleteInput
          disabled={!values.type?.value}
          className={s.dropdown}
          value={values.leader?.name || ""}
          onChange={e => {
            setFieldValue("leader", {
              id: "",
              name: e.target.value
            });
            usersAutocomplete(e.target.value, procurementUserRoles);
          }}
          onSelect={value => {
            setFieldValue("leader", {
              id: value.id,
              name: value.name
            });
          }}
          name="leader"
          data={users}
          placeholder={"Start typing user name"}
          label={"Leader"}
          error={
            errors.leader &&
            touched.leader &&
            (errors.leader.name || errors.leader.id)
          }
        />
        <Checkbox
          label="Use a department as a project client"
          subLabel="This department will be displayed in the list of possible project clients."
          onChange={e => setFieldValue("project_client", e.target.checked)}
          name="project_client"
          className={s.checkbox}
          value={values.project_client}
          checked={values.project_client}
          withTranslate
        />
      </div>
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {defaultData ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default UserDepartmentForm;