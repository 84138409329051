import React, {useEffect, useState} from "react";
import s from "./Suppliers.module.scss";
import sBase from "../MainDashboard.module.scss";
import {getTitle} from "../../helpers/dataFormat";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import services from "../../../../../../services";
import classNames from "classnames";
import SingleSupplier from "./SingleSupplier";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import DefaultLoading from "../../../../../loadingStates/Dashboard/DefaultLoading/DefaultLoading";
import Button from "../../../../../elements/Button/Button";
import {paths} from "../../../../../../routes/paths";
import {ReactComponent as RedirectIcon} from "../../../../../../assets/icons/redirect-icon.svg";
import {useHistory} from "react-router-dom";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Suppliers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    services.dashboardServices.getSuppliers().then((res) => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  }, []);

  const renderContent = () => {
    if (loading) return (
      <section className={sBase.wrapper}>
        <DefaultLoading/>
      </section>
    );

    if (!loading && !data.length) return (
      <section className={classNames(sBase.wrapper, s.suppliersWrapper)}>
        <header>
          <h4 className={sBase.title} data-translate-key={getTranslateKey(getTitle("suppliers"))}>
            {t(getTranslateKey(getTitle("suppliers")))}
          </h4>
        </header>
        <EmptyContent
          className={s.emptyBlock}
          text="You don't have any suppliers yet."
        />
      </section>
    );

    return (
      <section className={classNames(sBase.wrapper, s.suppliersWrapper)}>
        <header>
          <h4 className={sBase.title} data-translate-key={getTranslateKey(getTitle("suppliers"))}>
            {t(getTranslateKey(getTitle("suppliers")))}
          </h4>
          <Button
            styled='secondary'
            className={s.redirectBtn}
            onClick={() => history.push(paths.suppliers)}
          >
            <RedirectIcon/>
            <span data-translate-key={getTranslateKey("Show all")}>
              {t(getTranslateKey("Show all"))}
            </span>
          </Button>
        </header>
        <TableContainer className={s.content}>
          <Table stickyHeader className={sBase.table}>
            <TableHead className={sBase.tableHead} data-attr='table-head'>
              <TableRow>
                <TableCell className={sBase.tableCell}>
                  <span data-translate-key={getTranslateKey("Supplier")}>
                    {t(getTranslateKey("Supplier"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                  <span data-translate-key={getTranslateKey("TMT Spend")}>
                    {t(getTranslateKey("TMT Spend"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                  <span data-translate-key={getTranslateKey("Active projects")}>
                    {t(getTranslateKey("Active projects"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}/>
              </TableRow>
            </TableHead>
            <TableBody className={sBase.tableBody}>
              {data.map(sp => <SingleSupplier data={sp} key={sp.id}/>)}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    );
  }

  return renderContent();
};

export default Suppliers;


