import services from "../../services";

const addAttachmentsToFormData = (formData, files) => {
  for (let i = 0; i < files.length; i++) {
    formData.append("files[]", files[i]);
  }
  return formData;
};

const addHyperlinks = (formData, hyperlinks) => {
  for (let i = 0; i < hyperlinks.length; i++) {
    formData.append("hyperlinks[]", JSON.stringify(hyperlinks[i]));
  }
  return formData;
};

const addParamsToFormData = (formData, params) => {
  Object.keys(params).forEach(param => formData.append(param, params[param]));
  return formData;
};

export const addTask = (values, callback, projectTypeId) => {
  const {
    description,
    help_text,
    name,
    project_type_ids,
    stage_id,
    task_type,
    attachments,
    hyperlinks,
    responsible_user_id
  } = values;

  let formData = new FormData();
  let preparedParams = {description, help_text, name, stage_id, task_type, responsible_user_id};

  if (project_type_ids) {
    preparedParams.project_type_ids = project_type_ids;
  }

  formData = addAttachmentsToFormData(formData, attachments);
  formData = addParamsToFormData(formData, preparedParams);

  formData = addHyperlinks(formData, hyperlinks);

  services.adminProjectsServices
    .addConfigureTask(projectTypeId, formData)
    .then(res => res.data && callback());
};

export const editTask = (values, taskId, callback, projectTypeId) => {
  const {description, help_text, name, stage_id, task_type, attachments, hyperlinks, responsible_user_id} = values;

  let formData = new FormData();

  formData = addAttachmentsToFormData(formData, attachments);
  formData = addParamsToFormData(formData, {
    description, help_text, name, stage_id, task_type, responsible_user_id
  });

  formData = addHyperlinks(formData, hyperlinks);

  services.adminProjectsServices
    .editConfigureTask(projectTypeId, taskId, formData)
    .then(res => res.data && callback(res.data));
};

export const deleteTask = (taskId, callback, projectTypeId) => {
  services.adminProjectsServices
    .deleteConfigureTask(projectTypeId, taskId)
    .then(() => callback());
};