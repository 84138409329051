import { config, linesConfig } from "../helpers/defaultConfig";
import { trimCol } from "../helpers/trimCol";

export const drawAfterYLines = (dataCols) => {
  let afterVerticalLines = [];
  dataCols.map((col, index) => {
    const trimColData = trimCol(col);
    const isLongerNextCol = dataCols[index + 1]
      ? col.length >= dataCols[index + 1].length
      : false;
    if (isLongerNextCol && trimColData.length) {
      if (
        trimColData[0].top <= dataCols[index + 1][0].top &&
        trimColData[trimColData.length - 1].top >=
          dataCols[index + 1][dataCols[index + 1].length - 1].top
      ) {
        afterVerticalLines.push({
          x1:
            trimColData[0].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y1: trimColData[0].top + 53 + 1,
          x2:
            trimColData[trimColData.length - 1].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y2: trimColData[trimColData.length - 1].top + 53 - 1,
        });
      } else if (
        trimColData[0].top <= dataCols[index + 1][0].top &&
        trimColData[trimColData.length - 1].top <
          dataCols[index + 1][dataCols[index + 1].length - 1].top
      ) {
        afterVerticalLines.push({
          x1:
            trimColData[0].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y1: trimColData[0].top + 53 + 1,
          x2:
            trimColData[trimColData.length - 1].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y2: dataCols[index + 1][dataCols[index + 1].length - 1].top + 53 - 1,
        });
      } else if (
        trimColData[0].top > dataCols[index + 1][0].top &&
        trimColData[trimColData.length - 1].top >=
          dataCols[index + 1][dataCols[index + 1].length - 1].top
      ) {
        afterVerticalLines.push({
          x1:
            trimColData[0].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y1: dataCols[index + 1][0].top + 53 + 1,
          x2:
            trimColData[trimColData.length - 1].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y2: trimColData[trimColData.length - 1].top + 53 - 1,
        });
      } else if (
        trimColData[0].top > dataCols[index + 1][0].top &&
        trimColData[trimColData.length - 1].top <
          dataCols[index + 1][dataCols[index + 1].length - 1].top
      ) {
        afterVerticalLines.push({
          x1:
            trimColData[0].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y1: dataCols[index + 1][0].top + 53 + 1,
          x2:
            trimColData[trimColData.length - 1].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y2: dataCols[index + 1][dataCols[index + 1].length - 1].top + 53 - 1,
        });
      } else {
        afterVerticalLines.push({
          x1:
            trimColData[0].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y1: dataCols[index + 1][0].top + 53 + 1,
          x2:
            trimColData[trimColData.length - 1].left +
            linesConfig.horizontalLineWidth +
            config.colWidth,
          y2: trimColData[trimColData.length - 1].top + 53 - 1,
        });
      }
    }
  });

  return afterVerticalLines;
};
