import React, {useEffect} from 'react'
import {useFormik} from 'formik'
import {number, object, string} from 'yup'
import Button from '../../../../elements/Button/Button'
import AutoCompleteInput from '../../../../elements/AutoCompleteInput/AutoCompleteInput'
import {onlyNumbers} from "../../../../../helpers/stringHelper";

const BudgetForm = ({
                      onSubmit,
                      onClose,
                      initialValue = null,
                      onEdit,
                      budgetList
                    }) => {
  const formik = useFormik({
    initialValues: {
      budget: initialValue || {
        id: '',
        value: '',
        name: ''
      }
    },
    validationSchema: object({
      budget: object({
        id: string().required('Budget is required'),
        value: number(),
        name: string()
      })
    }),
    validate: values => {
      let errors = {}
      if (budgetList.find(b => b.name === values.budget.name)) {
        errors.budget = 'Budget with this amount is exists.'
      }
      return errors
    },
    onSubmit: values => {
      initialValue?.id
        ? onEdit({
          budget: values.budget
        })
        : onSubmit({
          budget: values.budget
        })
      onClose()
    }
  })

  const {handleSubmit, values, setFieldValue, touched, errors} = formik

  const getError = err => {
    if (err.budget) {
      if (err.budget.id) {
        return err.budget.id
      }
      return err.budget
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <AutoCompleteInput
        data={[
          {
            id: 1,
            value: String(values.budget.value || 0),
            name:
              'Greater than $' +
              Number(values.budget.value).toLocaleString('en-US') || 0
          },
          {
            id: 2,
            value: String(values.budget.value * 10 || 0),
            name:
              'Greater than $' +
              Number(values.budget.value * 10).toLocaleString('en-US') || 0
          }
        ]}
        placeholder="Start typing stakeholder's name"
        searchByValue={true}
        value={values.budget.id ? values.budget.name : values.budget.value}
        onChange={e =>
          setFieldValue('budget', {
            id: '',
            value: onlyNumbers(e.target.value),
            name: ''
          })
        }
        type='string'
        onSelect={value => setFieldValue('budget', value)}
        label='Budget'
        error={getError(errors)}
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          {initialValue?.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </form>
  )
}
export default BudgetForm
