import React from "react";
import s from "./PieChart.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import { useLocation } from "react-router-dom";

const PieChart = ({ width = 170 }) => {
  const {pathname} = useLocation();
  const square = pathname.includes('projects');

  return (
    <li className={s.chart}>
      <Skeleton
        variant={square ? 'square' : 'circle'}
        className={s.circle}
        width={width}
        height={width}
      />
      <Skeleton
        variant="text"
        className={s.text}
        animation="wave"
        width={176}
      />
      <Skeleton
        variant="text"
        className={s.text}
        animation="wave"
        width={148}
      />
    </li>
  );
};
export default PieChart;
