import React, {useState, useEffect} from "react";
import FirstLevelCategoriesTable from "components/tables/FirstLevelCategoriesTable/FirstLevelCategoriesTable";
import TableLoading from "components/loadingStates/Table/Table";
import services from "services";
import qs from "query-string";
import {useHistory} from "react-router-dom";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import {findCategoriesByName, getCategoriesIds} from "./findCategoriesByName";

const FirstLevelCategories = ({categories = [], categoryIdsFilter, updateCategoryIds}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const query = qs.parse(history.location.search);

  useEffect(() => {
    setLoading(true);
    services.categoriesServices.getCategoriesList({...query, category_ids: categoryIdsFilter}).then(res => {
      setData(res.data);
      setLoading(false);
    });
  }, [history.location.search, categoryIdsFilter]);

  const onNameClick = (_, {name}) => {
    const category_ids = Array.from(
      new Set(getCategoriesIds(findCategoriesByName(name, categories)))
    );
    updateCategoryIds(category_ids);
  };

  let newDate = [];
  if (data) {
    newDate = [
      ...data,
      {
        name: "Total:",
        total: "totalSum",
        ...data.reduce(
          (accumulator, current) => ({
            ...accumulator,
            ...Object.keys(accumulator).reduce((obj, key) => ({...obj, [key]: accumulator[key] + current[key]}), {}),
          }), {
            anticipated_savings: 0,
            spend: 0,
            suppliers_count: 0,
            projects_count: 0,
            payments_count: 0,
            contracts_count: 0
          }),
      },
    ];
  }

  return (
    <>
      {loading && <TableLoading length={7}/>}
      {!loading && Boolean(data.length) && (
        <FirstLevelCategoriesTable data={newDate} onNameClick={onNameClick}/>
      )}
      {!loading && !Boolean(data.length) && (
        <EmptyBlock title="No categories data for displaying." message/>
      )}
    </>
  );
};

export default FirstLevelCategories;
