import React, {useState} from "react";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import FilterBar from "../../elements/FilterBar/FilterBar";
import qs from "query-string";
import {debounce} from "lodash";
import services from "../../../services";
import {useHistory} from "react-router-dom";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";
import FilterBarDropdownExpiresIn
  from "../ContractsFilter/components/FilterBarDropdownExpiresIn/FilterBarDropdownExpiresIn";

const TasksFilter = ({className, filters}) => {
  const history = useHistory();

  const {...query} = qs.parse(
    history.location.search
  );
  const [savedFilter, setSavedFilter] = useState(null);

  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  const handleClearAll = () => {
    history.push({search: qs.stringify()});
  };
  const saveDefaultFilter = () => {
    delete query.user_type;
    services.filterService.addFilter("users", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  };

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  };

  const showDefaultFilter = filterParams => {
    services.filterService.addFilter("admin_settings_users", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  };

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = filterName !== "end_date" ? getAllIds(values) : values;
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  return (
    <FilterBar
      filterName="Filter tasks"
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      withSearch
    >
      <FilterBarDropdown
        name="Project"
        searchPlaceholder="Search department"
        options={filters.projects}
        defaultValues={query?.project_ids}
        handleChange={(values) => {
          handleChangeFilter("project_ids", values);
        }}
      />
      <FilterBarDropdownExpiresIn
        name="End date"
        options={filters.end_date}
        defaultValue={query?.end_date}
        handleChange={(values) => {
          handleChangeFilter("end_date", values);
        }}
      />
      <FilterBarDropdown
        name="Status"
        options={filters.statuses}
        defaultValues={query?.status_ids}
        handleChange={(values) => {
          handleChangeFilter("status_ids", values);
        }}
      />
    </FilterBar>
  );
};

export default TasksFilter;