import React from "react";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import Groups from "./Approvals/Groups/Groups";
import {useTranslation} from "react-i18next";
import {ReactComponent as Info} from "assets/icons/information.svg";
import Information from "../../elements/Information/Information";

const AdminSettingsApprovalsContainer = () => {
  const {t} = useTranslation();
  return (
    <div className={s.wrap}>
      <h2 className={s.title} data-translate-key="approvals">{t("approvals")}</h2>
      <Information message="You can edit approval tasks on the Admin settings / Projects / Project types page." informationClass={s.information}/>
      <Groups/>
    </div>
  );
};
export default AdminSettingsApprovalsContainer;
