import Popup from "../../../../popups/Popup/Popup";
import React, {useState} from "react";
import Input from "../../../../elements/Input/Input";
import Textarea from "../../../../elements/Textarea/Textarea";
import Attachments from "../../../../forms/AddMilestoneTaskForm/components/Attachments/Attachments";
import s from "./SupplierServices.module.scss";
import {useFormik} from "formik";
import {object, string} from "yup";
import services from "../../../../../services";
import Button from "../../../../elements/Button/Button";
import NotificationBox from "../../../../elements/NotificationBox/NotificationBox";

const AddServicePopup = ({setShowNewService, addSupplierService}) => {
  const [alert, setAlert] = useState({message: "", type: ""});
  const DESCRIPTION_MAX_LENGTH = 150;
  const SERVICE_TITLE_LIMIT = 50;

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      attachments: []
    },
    validationSchema: object({
      name: string()
        .required("Supplier services name  is required")
        .max(
          SERVICE_TITLE_LIMIT,
          `The services name is too long. It should be ${SERVICE_TITLE_LIMIT} characters or less.`
        ),
      description: string()
        .required("Supplier services description is required")
        .max(
          DESCRIPTION_MAX_LENGTH,
          `The supplier service is too long. It should be ${DESCRIPTION_MAX_LENGTH} characters or less.`
        ),
    }),
    onSubmit: values => {
      addSupplierService({
        name: values.name,
        description: values.description,
        attachments: values.attachments,
      });

    }
  });
  const {handleSubmit, values, errors, setFieldValue, touched, handleChange} = formik;

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };
  const handleDeleteAttachment = (file, index) => {

    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      );
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        );
      });
    }
  };
  return (

    <Popup
      title="New service"
      onClose={() => setShowNewService(false)}
      text="Please, complete the following fields to create a new project type"
      className={s.popUpAddService}
    >
      <form onSubmit={handleSubmit}>
        <Input
          value={values.name}
          onChange={handleChange}
          className={s.inputService}
          name="name"
          type="name"
          placeholder={`Enter service name`}
          label={`Service`}
          error={errors.name && touched.name && errors.name}
          limitValue={SERVICE_TITLE_LIMIT}
          withCounter
        />
        <Textarea
          label="Description"
          value={values.description}
          error={errors.description && touched.description && errors.description}
          onChange={handleChange}
          name="description"
          placeholder="Enter a description name"
          count={{
            current: values.description.length,
            max: DESCRIPTION_MAX_LENGTH
          }}
        />
        <div className={s.inputWrap}>
          <Attachments
            onChange={handleChangeAttachment}
            onDelete={handleDeleteAttachment}
            attachments={values.attachments}
            filesMaxLength={3}
          />
        </div>
        <div className="popupBtnsWrap">
          <Button
            styled="secondary"
            type="reset"
            className="popupBtn"
            onClick={() => setShowNewService(false)}
          >
            Cancel
          </Button>
          <Button styled="primary" type="submit" className="popupBtn">
            Create
          </Button>
        </div>
      </form>
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </Popup>

  );
};

export default AddServicePopup;