import React, {useEffect, useState} from 'react';
import {parserForSelect} from "../../../containers/AdminSettingsStepFieldDetailsContainer/helpers/parserForSelect";
import AutoCompleteInput from "../../AutoCompleteInput/AutoCompleteInput";
import services from "../../../../services";
import Input from "../../Input/Input";
import {emptySelect} from "../../../../helpers/emptyData";

const StakeholdersAutocomplete = ({changeSingleCondition, defaultValue, isViewMode}) => {
  const [value, setValue] = useState("");
  const [searchedStakeholders, setSearchedStakeholders] = useState([]);

  useEffect(() => {
    if (defaultValue && defaultValue !== " ") {
      services.userServices
        .getUser(defaultValue)
        .then(res => setValue(res.data.name));
    }
  }, [defaultValue]);

  const searchStakeholders = term => {
    services.userServices
      .stakeholdersAutocomplete({query: term})
      .then(res => setSearchedStakeholders(res.data));
  };

  if (isViewMode) return <Input value={value} withoutChange/>;

  return (
    <AutoCompleteInput
      label=""
      value={value}
      placeholder=""
      onChange={e => {
        changeSingleCondition(emptySelect);
        setValue(e.target.value);
        searchStakeholders(e.target.value);
      }}
      onSelect={value => {
        changeSingleCondition(parserForSelect(value));
      }}
      data={searchedStakeholders}
    />
  );
};

export default StakeholdersAutocomplete;