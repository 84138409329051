import React from 'react';
import gradient from "random-gradient";
import styles from "./TriageOption.module.scss";

const TriageOption = ({innerRef, innerProps, data}) => {

  const renderTriageName = () => {
    const {label, entities_size, entity} = data;

    if (!label) return "-";

    const nameParts = label.split(" ").filter(item => item);
    const avatar = nameParts.length > 1 ? nameParts[0][0] + nameParts[1][0] : nameParts[0][0];

    return (
      <div className={styles.option}>
        <span className={styles.avatar} style={{background: gradient(label)}}>
          {avatar ?? "-"}
        </span>
        <div className={styles.values}>
          <p>{label}</p>
          <p>{entities_size} {entity}</p>
        </div>
      </div>
    );
  };

  return (
    <div ref={innerRef} {...innerProps} >
      {renderTriageName()}
    </div>
  );
};

export default TriageOption;