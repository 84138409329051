import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import LandingLayout from "components/landing/Landinglayout/Landinglayout";
import NotFound from "./components/NotFound";

const NotFoundContainer = () => {
  const history = useHistory();
  const userName = useSelector((state) => state.user.name);

  return (
    <>
      {userName ? (
        <DefaultLayout>
          <NotFound history={history} />
        </DefaultLayout>
      ) : (
        <LandingLayout>
          <NotFound history={history} />
        </LandingLayout>
      )}
    </>
  );
};

export default NotFoundContainer;
