import React from "react";
import s from "./CreateProjectRequestContainer.module.scss";
import RequestForm from "../../forms/RequestForm/RequestForm";
import {useTranslation} from "react-i18next";

const CreateRequestContainer = () => {
  const {t} = useTranslation();
  return (
    <div className={s.wrap}>
      <h2 className={s.title} data-translate-key={"new-request"}>{t("new-request")}</h2>
      <RequestForm/>
    </div>
  );
};
export default CreateRequestContainer;
