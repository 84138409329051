import React, {useEffect, useState} from "react";
import {
  parserForSelect
} from "../../../../../containers/AdminSettingsStepFieldDetailsContainer/helpers/parserForSelect";
import PropTypes from "prop-types";
import services from "../../../../../../services";
import DropdownTree from "../../../../../elements/DropdownTree/DropdownTree";

const CategoriesSelect = (
  {
    field,
    values,
    setFieldValue,
    errors,
    changeAdditionalQuestions
  }
) => {
  const [categories, setCategories] = useState([]);
  const correctFieldKey = field.key ? field.key : field.value;

  useEffect(() => {
    services.categoriesServices
      .getCategories()
      .then(res => {
        if (res.data) setCategories(res.data);
      });
  }, []);

  return (
    <DropdownTree
      withSearch={true}
      label={field.label}
      value={values[correctFieldKey]?.label || ""}
      onChange={e => {
        setFieldValue(correctFieldKey, {
          value: "",
          label: e.target.value,
          type: "Category",
          conditions: field.conditions
        });
        if (changeAdditionalQuestions) changeAdditionalQuestions({
          value: "",
          label: e.target.value
        }, "category", correctFieldKey);
      }}
      onSelect={value => {
        setFieldValue(correctFieldKey, {
          ...parserForSelect(value),
          type: "Category",
          conditions: field.conditions
        });
        if (changeAdditionalQuestions) changeAdditionalQuestions(parserForSelect(value), "category", correctFieldKey);
      }}
      options={categories}
      placeholder=""
      error={errors?.[correctFieldKey]?.value}
    />
  );
};

export default CategoriesSelect;

CategoriesSelect.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  onlyFirstLevel: PropTypes.bool,
  errors: PropTypes.any,
  changeAdditionalQuestions: PropTypes.func,
};