import React, { useRef } from "react"
import s from "./ColumnWidget.module.scss"

const percents = ["100%", "80%", "60%", "40%", "20%", "0"]
const ColumnWidget = ({ score_types = {} }) => {
  const usersCount = Object.values(score_types).reduce(
    (acc, itm) => (acc += itm),
    0
  )

  const percentsWidgetRef = useRef(null)
  const labels = Object.keys(score_types)
  const getColHeight = (value = 0) => {
    const percent = (value * 100) / usersCount
    const widgetPercentsHeight = percentsWidgetRef.current?.offsetHeight - 17
    const height = (widgetPercentsHeight * percent) / 100 || 0
    return height === Infinity ? 0 : height
  }
  const data = [
    {
      label: String(labels[0]),
      color: "#DE4935",
      height: getColHeight(score_types.detractors)
    },
    {
      label: String(labels[1]),
      color: "#F2CD7B",
      height: getColHeight(score_types.passives)
    },
    {
      label: String(labels[2]),
      color: "#83A755",
      height: getColHeight(score_types.promoters)
    }
  ]
  return (
    <div className={s.columnWidget}>
      <ul className={s.columns}>
        {data.map(({ label, height, color }, index) => (
          <li className={s.column} key={index}>
            <span
              style={{ height: `${height}px`, background: color }}
              className={s.col}
            ></span>
            <span className={s.label}>{label}</span>
          </li>
        ))}
      </ul>

      <div className={s.percents} ref={percentsWidgetRef}>
        {percents.map((percent, index) => (
          <div className={s.percentWrap} key={index}>
            <span className={s.percent}>{percent}</span>
            <span className={s.line}></span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ColumnWidget
