import React from "react";
import styles from "./Savings.module.scss";
import FinancialSavings from "./FinancialSavings/FinancialSavings";
import NonFinancialSavings from "./NonFinancialSavings/NonFinancialSavings";

const SavingsTypes = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <FinancialSavings />
      </div>
      <NonFinancialSavings />
    </div>
  );
};

export default SavingsTypes;
