import axios from "./axios";

export default {
  updateTranslate: async values => {
    try {
      return await axios.put("locales", values);
    } catch (error) {
      throw error;
    }
  }
}