import React, {useRef, useState} from "react";
import MainTable from "components/tables/MainTable/MainTable";
import s from "./SupplierServicesTable.module.scss";
import {ReactComponent as AttachmentIcon} from "../../../assets/icons/attachment.svg";
import classNames from "classnames";
import {getFileIconByType} from "../../../helpers/fileIconsByType";
import {useSelector} from "react-redux";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import useOutsideClicker from "../../../hooks/useOutsideClicker";
import EmptyBlock from "../../elements/EmptyBlock/EmptyBlock";

const SupplierServicesTable = (
  {
    data,
    className,
    supplier,
    setShowNewService,
    onSort,
    editService,
    setShowEditService,
    setShowDel,
    setItemDelete
  }
) => {
  const [visibleAttachmentsPopupId, setVisibleAttachmentsPopupId] = useState(null);
  const attachmentsRef = useRef(null);
  useOutsideClicker(attachmentsRef, () => setVisibleAttachmentsPopupId(null));
  const user = useSelector(state => state.user);
  const ownerId = supplier?.owner && supplier.owner.id;
  const {roles, id} = useSelector(state => state.user);
  const {admin, cpo, procurement_admin} = userRoles;


  const accessServices =
    user.roles.includes(userRoles.cpo) ||
    user.roles.includes(userRoles.admin) || ownerId === user.id ||
    user.roles.includes(userRoles.procurement_admin);
  return (
    <>
      {!data.length
        ? (roles === admin || roles === cpo || roles === procurement_admin || id === ownerId)
          ? <EmptyBlock
            subtitle={"The supplier doesn’t have any services."}
            buttonTitle="New service"
            onClick={() => setShowNewService(true)}
          />
          : <EmptyBlock
            subtitle={"The supplier doesn’t have any services."}
            withBtn={false}
          />
        :
        <MainTable
          data={data}
          className={className}
          dataTypes={[
            {
              title: "Service",
              value: ({name}) => name,
              sortable: true,
              handleSort: ({direction}) => {
                onSort({sort_by: "name", direction});
              }
            },
            {
              title: "Description",
              value: ({description}) => `${description}` || "-",
              sortable: true,
              handleSort: ({direction}) => {
                onSort({sort_by: "description", direction});
              }
            },
            {
              title: "",
              position: "end",
              value: ({attachments = [], id}) => (
                <div className={s.attachmentsWrap}>
                  <AttachmentIcon
                    className={classNames(s.icon, {[s.disabled]: !attachments?.length})}
                    onClick={() => attachments.length > 0 && setVisibleAttachmentsPopupId(id)}
                  />
                  {visibleAttachmentsPopupId === id && attachments.length >= 0 && (
                    <ul className={s.attachments} ref={attachmentsRef}>
                      {attachments.map((attachment, index) => (
                        <li className={s.attachment} key={index}>
                          {getFileIconByType(
                            attachment.type || attachment.file_type
                          )}
                          <a
                            className={s.link}
                            href={attachment.path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {attachment.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )
            }
          ]}
          ariaLabel="services details"
          options={item => {
            return [accessServices && {
              title: "Edit",
              onClick: () => {
                editService(item)
                setShowEditService(true);
              }
            },
              accessServices && {
                title: "Delete",
                onClick: () => {
                  setItemDelete(item)
                  setShowDel(true);
                }
              }
            ];
          }}
          button={accessServices && {
            name: "New service",
            onClick: () => {

              setShowNewService(true);
            }
          }}
        />}
    </>
  );
};
export default SupplierServicesTable;