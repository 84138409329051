import React, { useState, useEffect } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import classNames from "classnames";
import s from "../../NotificationsTable.module.scss";
import Checkbox from "components/elements/Checkbox/Checkbox";
import renderNotificationItem from "helpers/renderNotificationItem";

const CheckboxRow = ({ data, onSelect, selectAll, clear }) => {
  const [selected, setSelected] = useState(false);
  const { message, time_from_creation, is_readed } = data;

  useEffect(() => {
    selectAll && setSelected(true);
  }, [selectAll]);
  useEffect(() => {
    clear && setSelected(false);
  }, [clear]);

  return (
    <TableRow
      className={classNames(s.tableRow, {
        [s.selected]: selected,
        [s.readed]: !is_readed,
      })}
    >
      <TableCell className={s.tableCell}>
        <div className={s.checkboxWrap}>
          <Checkbox
            className={s.checkbox}
            onChange={() => {
              onSelect(data, !selected);
              setSelected((state) => !state);
            }}
            checked={selected}
          />
          {renderNotificationItem({
            className: s.message,
            message,
            type: data.subject_type,
            projectId: data.project_id,
            milestoneId: data.milestone_id,
            requestId: data.request_id,
            approvalId: data.approval_id,
          })}
        </div>
      </TableCell>
      <TableCell className={s.tableCell}>{time_from_creation}</TableCell>
    </TableRow>
  );
};

export default CheckboxRow;
