export const trimCol = (data) => {
  let validRowsIdx = [];
  data.map(({ status, rowIdx }) => {
    const isValidRow =
      status !== "Not required" &&
      status !== "Pending" &&
      status !== "Rejected";
    if (isValidRow) {
      validRowsIdx.push(rowIdx);
    }
  });

  const validSideRowsIdx =
    validRowsIdx.length > 2
      ? [
          [...validRowsIdx].sort()?.[0],
          [...validRowsIdx].sort((a, b) => b - a)?.[0],
        ]
      : validRowsIdx;

  if (validSideRowsIdx.length === 2) {
    return data.slice(
      validSideRowsIdx?.[0],
      validSideRowsIdx?.[1] ? validSideRowsIdx?.[1] + 1 : validSideRowsIdx?.[1]
    );
  } else if (validSideRowsIdx.length === 1) {
    return [data[validSideRowsIdx[0]]];
  } else return [];
};
