import React from "react";
import styles from "./SystemReportsContainer.module.scss";
import SystemReportsTabs from "./components/SystemReportsTabs/SystemReportsTabs";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const SystemReportsContainer = () => {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      <h3 className={styles.title} data-translate-key={"system-reports"}> {t(getTranslateKey("system-reports"))}</h3>
      <SystemReportsTabs />
    </div>
  );
};

export default SystemReportsContainer;
