import React from "react";
import classNames from "classnames";
import s from "./PieChartWidget.module.scss";
import PieChart from "./PieChart/PieChart";
import Skeleton from "@material-ui/lab/Skeleton";

const PieChartWidget = ({ className, width }) => {
  return (
    <div className={classNames("skeletLoaderWrap", s.wrap, className)}>
      <Skeleton
        variant="text"
        className={s.title}
        animation="wave"
        width={200}
      />
      <div className={s.inline}>
        <PieChart width={width} />
        <PieChart width={width} />
        <PieChart width={width} />
        <PieChart width={width} />
        <PieChart width={width} />
      </div>
    </div>
  );
};

export default PieChartWidget;
