import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import { paths } from "routes/paths";
import ProjectTypeDetailsContainer from "components/containers/ProjectTypeDetailsContainer/ProjectTypeDetailsContainer";

const ProjectTypeDetailsPage = () => {
	return (
		<DefaultLayout
			breadcrumbs={[
				{
					name: "Admin Settings",
					disabled: true,
				},
				{
					name: "Projects",
					path: paths.adminSettingsProjects
				},
				{
					name: "Project type details"
				}
			]}
		>
			<ProjectTypeDetailsContainer />
		</DefaultLayout>
	);
};

export default ProjectTypeDetailsPage;
