import axios from "./axios";

export default {
  getFilter: async type => {
    try {
      return await axios.get(`/custom_filters?filter_types=${type}`);
    } catch (error) {
      throw error;
    }
  },

  addFilter: async (type, values) => {
    try {
      return await axios.post(`/custom_filters?filter_type=${type}`, values);
    } catch (error) {
      throw error;
    }
  },

  deleteFilter: async id => {
    try {
      return await axios.delete(`/custom_filters/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getFilterTasks: async () => {
    try {
      return await axios.get(`/tasks_filters`);
    } catch (error) {
      throw error;
    }
  }
};