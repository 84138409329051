import React from "react"
import styles from "./NPSScoreWidget.module.scss"
import NPSScoreBarChart from "components/elements/NPSScoreBarChart/NPSScoreBarChart"
import GaugeChartWidget from "components/elements/GaugeChartWidget/GaugeChartWidget"

const NPSScoreWidget = ({ data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <GaugeChartWidget nps_score={data.nps_score} />
      </div>
      <span className={styles.divider}></span>
      <div className={styles.column}>
        <NPSScoreBarChart data={data.score_types} />
      </div>
    </div>
  )
}

export default NPSScoreWidget
