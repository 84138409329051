import React, {useState} from "react";
import MainTable from "components/tables/MainTable/MainTable";
import Popup from "components/popups/Popup/Popup";
import {formatCurrency} from "helpers/currencyFormat";
import {currencyData} from "helpers/helpData";
import EditPopup from "components/containers/ProjectContainer/components/Suppliers/Popups/EditPopup/EditPopup";
import LogoSuppliers from "../../elements/LogoSuppliers/LogoSuppliers";
import s from "../SuppliersTable/SuppliersTable.module.scss";
import Tags from "../../elements/Tags/Tags";
import {userRoles, userTypes} from "../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import sort from "helpers/sortHelper"

const ProjectSuppliersTable = (
  {
    currentSuppliers,
    removeHandler,
    history,
    openPopup,
    havePermissions,
    handleEdit,
    onDeleteTag,
    onAssignTag
  }
) => {
  const [data, setData] = useState(currentSuppliers);
  const [show, setShow] = useState(null);
  const [supplier, setSupplier] = useState({});
  const {user_type} = useSelector(state => state.user);
  const {business} = userTypes;
  const user = useSelector(state => state.user);

  const onSort = ({sort_by, direction}) => {
    if (sort_by === "name")
      setData(sort.sortString(data, {field: sort_by, direction}))
  };

  const deleteTagAction = (id, tag, item) => {
    if (tag.type === "flag") {
      if (tag.owner.id === user.id || user.roles.includes(userRoles.admin)) {
        return onDeleteTag(id, item.id);
      }
    } else return onDeleteTag(id, item.id);
  };

  return (
    <>
      <MainTable
        data={data}
        dataTypes={[
          {
            title: "Supplier",
            value: ({name, id, logo}) => (
              <div className={s.logoSubSupplier}>
                <LogoSuppliers name={name} logo={logo}/>
                <a className={s.link} href={`/suppliers/${id}`}>{name}</a>
              </div>),
            sortable: true,
            onClick: id => history.push(`/suppliers/${id}`),
            handleSort: ({direction}) => {
              onSort({sort_by: "name", direction});
            },
            sticky: true
          },
          {
            title: "Spend",
            value: ({spend, spend_currency}) =>
              spend
                ? formatCurrency(
                  currencyData.filter(e => e.label === spend_currency)[0]
                    ?.value,
                  spend
                )
                : "-",
            alignment: "right"
          },
          {
            title: "Payments",
            value: ({payments_count}) => payments_count || "-"
          },
          {
            title: "Contracts",
            value: ({contracts_count}) => contracts_count || "-"
          },
          {
            title: "Status",
            value: ({project_supplier_status}) =>
              project_supplier_status === "selected" ? "Selected" : "Unselected"
          },
          {
            title: "Tags",
            value: (item, allData) =>
              item?.tags ? (
                <Tags
                  allData={allData}
                  width={260}
                  item={item}
                  onDelete={user_type !== business && ((id, tag) => deleteTagAction(id, tag, item))}
                  onAssign={user_type !== business && (id => onAssignTag(id, item.id))}
                />
              ) : (""),
            type: "tags"
          }
        ]}
        options={item => {
          return [
            havePermissions.update && {
              title: "Edit",
              onClick: () => {
                setSupplier(item);
                setShow("edit");
              }
            },
            havePermissions.destroy && {
              title: "Remove",
              onClick: () => {
                setSupplier(item);
                setShow("delete");
              }
            }
          ];
        }}
        button={
          havePermissions.update && {
            name: "Add supplier",
            onClick: () => openPopup()
          }
        }
      />

      {show === "delete" && (
        <Popup
          onClose={() => setShow(null)}
          title="Remove supplier"
          successBtnTitle="Remove"
          text={`Are you sure you want to remove the "${supplier.name}" supplier?`}
          onClick={() => {
            removeHandler(supplier.id);
            setShow(null);
          }}
          dialog
          isDelete
        />
      )}
      {show === "edit" && (
        <EditPopup
          onClose={() => setShow(null)}
          onSubmit={({project_supplier_status}) =>
            handleEdit({
              project_supplier_status,
              supplier_id: supplier?.id
            }).then(() => setShow(null))
          }
          status={supplier?.project_supplier_status}
        />
      )}
    </>
  );
};

export default ProjectSuppliersTable;
