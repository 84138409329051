import React, {useState, useRef, useEffect} from "react"
import s from "./Tags.module.scss"
import Tag from "./components/Tag/Tag"
import classNames from "classnames"
import useOutsideClicker from "hooks/useOutsideClicker"
import Input from "react-input-autosize"
import {useFormik} from "formik"
import {string, object} from "yup"
import uniqid from "uniqid"
import {colors} from "./helpers/colors"
import {ReactComponent as Check} from "assets/icons/black_check.svg"
import NotificationBox from "components/elements/NotificationBox/NotificationBox"
import services from "services"
import {useHistory, useLocation} from "react-router-dom"
import querystring from "query-string"
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const Tags = ({allData, item, onAssign, onDelete, onSort, width}) => {
  const [opened, setOpened] = useState(false)
  const [itemIdx, setItemIdx] = useState(0)
  const [tags, setTags] = useState([])
  const [searchedTags, setSearchedTags] = useState([])
  const [selectedColor, setSelectedColor] = useState("gray")
  const [alert, setAlert] = useState({message: "", type: ""})
  const history = useHistory()
  const location = useLocation()
  const query = querystring.parse(location.search)
  const tagsWrapRef = useRef(null)
  const tagsRef = useRef(null)
  const fieldRef = useRef(null)
  const haveUncoloredTag = Boolean(
    tags.filter(tag => Boolean(!tag.color)).length
  )
  const {t} = useTranslation();

  useOutsideClicker(tagsWrapRef, () => {
    if (!haveUncoloredTag) {
      setOpened(false)
      setFieldValue("value", "")
    }
  })

  useEffect(() => {
    allData && allData.map((e, idx) => e.id === item.id && setItemIdx(idx))
  }, [])

  useEffect(() => {
    setTags(item?.tags || []);
  }, [item.tags])

  const handleSearchTag = val => {
    services.tagsServices
      .searchTag({query: val})
      .then(res => setSearchedTags(res.data.slice(0, 5)))
      .catch(err => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      })
  }

  const handleAssignTag = tag => {
    if (!tags.filter(e => e.id === tag.id).length) {
      onAssign &&
      onAssign(tag.id)
        .then(() => {
          setTags([...tags, tag])
          setFieldValue("value", "")
          setAlert({
            message: "Tag successfully assigned.",
            type: "success"
          })
          fieldRef.current.focus()
        })
        .catch(() =>
          setAlert({
            message: "Something went wrong. Please, try again.",
            type: "error"
          })
        )
    } else {
      setAlert({
        message: "This tag already added.",
        type: "error"
      })
    }
  }
  const handleDeleteTag = id => {
    const successDeleteTag = () => {
      if (tags?.length === 1 && query?.tag?.length > 0) {
        setOpened(false)
        if (allData.length === 1)
          history.push({
            search: querystring.stringify({...query, tag: ""})
          })
      }
      setTags(tags.filter(e => e.id !== id))
      setAlert({
        message: "Tag successfully deleted.",
        type: "success"
      })
    }
    if (tags.filter(tag => tag.id === id)?.[0]?.color) {
      const callback = onDelete(id, tags.find(tag => tag.id === id));

      if (callback) {
        callback.then(successDeleteTag)
          .catch(err => {
            setAlert({
              message: "Something went wrong. Please, try again.",
              type: "error"
            })
          })
      }
    } else {
      successDeleteTag()
    }
  }
  const handleAddColor = async color => {
    if (color !== selectedColor) {
      const addedColor = tags.filter(e => !e.color)?.[0]
      setSelectedColor("gray")
      await services.tagsServices
        .addTag({name: addedColor.name, color})
        .then(res => {
          const tag = res.data
          setAlert({
            message: "Successfully changed color for tag.",
            type: "success"
          })
          onAssign &&
          onAssign(res.data.id)
            .then(() => {
              setTags(tags.map(e => (e.id === addedColor.id ? tag : e)))
              setAlert({
                message: "Successfully assign tag.",
                type: "success"
              })
            })
            .catch(err =>
              setAlert({
                message: "Something went wrong. Please, try again.",
                type: "error"
              })
            )
        })
        .catch(err =>
          setAlert({
            message: "Something went wrong. Please, try again.",
            type: "error"
          })
        )
    }
  }
  const handleCreateTag = value => {
    const existSearchTags = searchedTags.filter(e => e.name === value).length
    const existAddedTags = tags.filter(tag => tag.name === value).length
    if (
      !existAddedTags &&
      !existSearchTags &&
      !haveUncoloredTag &&
      values.value.length > 0 &&
      values.value.length <= 32
    ) {
      setFieldValue("value", "")
      setAlert({
        message: "Tag successfully created.",
        type: "success"
      })
      return setTags([
        ...tags,
        {
          id: uniqid(),
          name: value,
          color: null
        }
      ])
    } else if (!values.value.length) {
      setAlert({
        message: "The tag name is required",
        type: "error"
      })
    } else if (values.value.length > 32) {
      setAlert({
        message:
          "The Tag name is too long. It should be 32 characters or less.",
        type: "error"
      })
    } else {
      setAlert({
        message: "Sorry, tag already exist.",
        type: "error"
      })
    }
  }
  const handleDeleteKeyPress = e => {
    if (e.key === "Backspace" && !values.value.length && tags.length > 0) {
      const tag = tags[tags.length - 1]
      onDelete &&
      onDelete(tag.id)
        .then(() => {
          setFieldValue("value", `${tag?.name} `)
          setTags(tags.slice(0, tags.length - 1))
          setAlert({
            message: "Tag successfully deleted.",
            type: "success"
          })
        })
        .catch(err => {
          setAlert({
            message: "Something went wrong. Please, try again.",
            type: "error"
          })
        })
    }
  }
  const formik = useFormik({
    initialValues: {
      value: ""
    },
    validationSchema: object({
      value: string()
        .required("")
        .trim()
    }),
    validate: () => {
      let errors = {}
      if (haveUncoloredTag) {
        errors.value = "Please, choose a color for previous tag."
      }
      return errors
    },
    onSubmit: ({value}) => handleCreateTag(value)
  })
  const {handleSubmit, values, setFieldValue, errors} = formik
  return (
    <div
      className={classNames(s.tagsWrap, {[s.detailState]: !Boolean(allData)})}
      onClick={() => !opened && setOpened(true)}
      style={{position: !opened && "relative"}}
      ref={tagsWrapRef}
    >
      <div
        ref={tagsRef}
        className={classNames(s.tagsInlineWrap, {[s.opened]: opened})}
        style={Object.assign({top: 0}, width ? {width: `${width}px`} : {})}
        onClick={() => fieldRef.current?.focus()}
      >
        <div className={s.tags}>
          {tags.sort((a, b) => a.type?.localeCompare(b.type)).map(tag => (
            <Tag
              className={s.tag}
              tag={tag}
              withDelete={onDelete && opened}
              onClick={e => {
                e.stopPropagation()
                onSort && onSort(tag)
              }}
              onDelete={handleDeleteTag}
            />
          ))}
          {opened && onAssign && (
            <form
              className={s.addForm}
              onSubmit={handleSubmit}
              noValidate
              autoComplete='off'
            >
              <Input
                name='tag'
                placeholder={t(getTranslateKey('Add a tag'))}
                className={s.addField}
                value={values.value}
                onChange={e => {
                  setFieldValue("value", e.target.value)
                  handleSearchTag(e.target.value)
                }}
                onKeyDown={handleDeleteKeyPress}
                autoFocus={true}
                ref={fieldRef}
              />
            </form>
          )}
        </div>
      </div>
      {opened && values.value.length > 0 && !haveUncoloredTag && (
        <div
          className={s.searchedTagsWrap}
          style={{top: 60}}
        >
          {searchedTags.length > 0 && (
            <ul>
              {searchedTags.slice(0, 5).map(tag => (
                <li>
                  <Tag
                    tag={tag}
                    className={s.searchTag}
                    withDelete={false}
                    onClick={e => {
                      e.stopPropagation()
                      handleAssignTag(tag)
                    }}
                  />
                </li>
              ))}
            </ul>
          )}
          {!searchedTags.length && (
            <div className={s.emptySearch}>
              <span data-translate-key={getTranslateKey("No tags found. You can")}>
                {t(getTranslateKey("No tags found. You can"))}
              </span>
              &nbsp;
              <span className={s.createTag} onClick={() => handleCreateTag(values.value)}>
                <span data-translate-key={getTranslateKey("create tag for")}>
                 {t(getTranslateKey("create tag for"))}
                </span>
                {`"${values.value}"`}
              </span>
            </div>
          )}
        </div>
      )}
      {opened && haveUncoloredTag && (
        <div
          className={s.colorPicker}
          style={{top: 60}}
        >
          {Object.keys(colors).map(colorName => (
            <span
              style={{background: colors[colorName][0]}}
              onClick={e => {
                e.stopPropagation()
                setSelectedColor(colorName)
                handleAddColor(colorName)
                fieldRef.current.focus()
              }}
            >
              {selectedColor === colorName && <Check/>}
            </span>
          ))}
        </div>
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </div>
  )
}
export default Tags
