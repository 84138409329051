import React, {useState, useEffect} from "react"
import classNames from "classnames"
import {
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TableHead
} from "@material-ui/core"
import s from "./NotificationsTable.module.scss"
import Button from "components/elements/Button/Button"
import Checkbox from "components/elements/Checkbox/Checkbox"
import CheckboxRow from "./components/CheckboxRow/CheckboxRow"
import TablePagination from "components/elements/TablePagination/TablePagination"
import DeleteNotificationPopup from "components/popups/DeleteNotificationPopup/DeleteNotificationPopup"
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const NotificationsTable = (
  {
    data,
    onDelete,
    onMarkRead,
    totalCount,
    perPage,
    page,
    onPageChange
  }
) => {
  const [selectAll, setSelectAll] = useState(false)
  const [clear, setClear] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const {t} = useTranslation();

  useEffect(() => {
    selectAll && setSelectedItems(data)
  }, [selectAll])

  const onSelect = (item, checked) => {
    checked
      ? setSelectedItems([...selectedItems, item])
      : setSelectedItems(selectedItems.filter(itm => itm.id !== item.id))

    selectAll && !checked && setSelectAll(false)
    !selectAll &&
    checked &&
    selectedItems.length + 1 === data.length &&
    setSelectAll(true)
  }

  return (
    <>
      <div className={classNames(s.wrap, {[s.scroll]: data.length >= 10})}>
        <TableContainer className={s.tableContainer}>
          <Table>
            <TableHead className={s.mainHead}>
              <TableRow>
                <TableCell className={s.totalCounter}>
                  <span data-translate-key={getTranslateKey("Total")}>
                    {t(getTranslateKey("Total"))}
                  </span> <span>{data.length}</span>
                </TableCell>

                <TableCell style={{borderBottom: "none"}}>
                  <div
                    className={classNames(s.buttonWrap, {
                      [s.show]: Boolean(selectedItems.length)
                    })}
                  >
                    <Button
                      styled='secondary'
                      className={s.button}
                      onClick={() => setShowPopup(true)}
                    >
                      Delete
                    </Button>
                    <Button
                      styled='primary'
                      className={s.button}
                      onClick={() =>
                        onMarkRead(selectedItems).then(() => {
                          setClear(true)
                          setSelectAll(false)
                          setSelectedItems([])
                        })
                      }
                    >
                      Mark as read
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead className={s.secondHead}>
              <TableRow>
                <TableCell className={classNames(s.title, s.col1)}>
                  <div className={s.checkboxWrap}>
                    <Checkbox
                      className={s.checkbox}
                      onChange={() => {
                        if (selectAll) {
                          setSelectedItems([])
                          setClear(true)
                        } else {
                          setClear(false)
                        }
                        setSelectAll(state => !state)
                      }}
                      checked={selectAll}
                    />
                    <span data-translate-key={getTranslateKey("Notification")}>
                    {t(getTranslateKey("Notification"))}
                    </span>
                  </div>
                </TableCell>
                <TableCell className={classNames(s.title, s.col2)}>
                  <span data-translate-key={getTranslateKey("Time")}>
                    {t(getTranslateKey("Time"))}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={s.tableBody}>
              {data.map((item, index) => (
                <CheckboxRow
                  key={index}
                  data={item}
                  onSelect={onSelect}
                  selectAll={selectAll}
                  clear={clear}
                />
              ))}
            </TableBody>
          </Table>
          {totalCount > perPage && (
            <div className={s.pagination}>
              <TablePagination
                perPage={perPage}
                count={totalCount}
                onPageChange={onPageChange}
                defaultPage={page || 1}
              />
            </div>
          )}
        </TableContainer>
      </div>
      {showPopup && (
        <DeleteNotificationPopup
          isDeleteAll={selectAll}
          isDeleteSeveral={Boolean(!selectAll && selectedItems.length > 1)}
          onClose={() => setShowPopup(false)}
          onClick={() =>
            onDelete(selectedItems).then(() => {
              setShowPopup(false)
              setClear(true)
              setSelectAll(false)
              setSelectedItems([])
            })
          }
        />
      )}
    </>
  )
}

export default NotificationsTable
