import React from "react";
import s from "./AdditionalQuestionsLoading.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";

const AdditionalQuestionsLoading = () => {
  return (
    <div className={s.tablesContainer}>
      <Skeleton
        variant="text"
        className={s.title}
        animation="wave"
        width={200}
      />
      <div className={s.content}>
        <div>
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={160}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={140}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={130}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={110}
          />
        </div>
        <div>
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={160}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={140}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={130}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={110}
          />
        </div>
        <div>
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={160}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={140}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={130}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={110}
          />
        </div>
        <div>
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={160}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={140}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={130}
          />
          <Skeleton
            variant="text"
            className={s.item}
            animation="wave"
            width={110}
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalQuestionsLoading;


