import React from "react";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as Play } from "../../landing/assets/icons/play.svg"
import styles from "./VideoPlayer.module.scss";

const PlayBtn = () => (
  <button className={styles.playButton}>
    <Play />
  </button>
);

const VideoPlayer = ({ classes, link, playIcon }) => {
  return (
    <div className={classNames(styles.player, classes)}>
      <ReactPlayer
        className={styles.reactPlayer}
        url={link}
        width="100%"
        height="100%"
        playing
        controls
        light
        playIcon={<PlayBtn />}
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  classes: PropTypes.any,
  link: PropTypes.string,
  playIcon: PropTypes.node,
};

VideoPlayer.defaultProps = {
  classes: "",
  link:
    "https://video.wixstatic.com/video/eb30b9_d6adce7505f440c8be32248fb3eb1db1/1080p/mp4/file.mp4",
  playIcon: <button>Play</button>,
};

export default VideoPlayer;
