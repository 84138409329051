import React from "react";
import s from "./Footer.module.scss";
import CustomLink from "components/elements/CustomLink/CustomLink";
import {ReactComponent as Logo} from "../../assets/icons/LogoWhite.svg";
import {ReactComponent as Linkedin} from "../../assets/icons/Linkedin.svg";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";

const Footer = () => {
  const {t} = useTranslation();
  return (
    <footer className={s.footer}>
      <div className={s.landingContainer}>
        <div className={s.topBlock}>
          <Logo/>
          <div className={s.linkIn}>
            <p className={s.linkText} data-translate-key={"get-in-touch"}>
              {t(getTranslateKey("get-in-touch"))}
            </p>
            <CustomLink type="link" path="https://www.linkedin.com/company/focal-point-procurement">
              <Linkedin/>
            </CustomLink>
          </div>
        </div>
        <div className={s.bottomBlock}>
          <div className={s.policies}>
            <CustomLink
              type="nav-link"
              path="/cookie-policy"
              className={s.policyLink}
            >
              Cookie Policy
            </CustomLink>

            <CustomLink
              type="nav-link"
              path="/privacy-policy"
              className={s.policyLink}
            >
              Privacy Policy
            </CustomLink>
          </div>

          <p className={s.copyright} data-translate-key={"copyright-©2020.-focalpoint-procurement-management,-all-rights-reserved"}>
            {t(getTranslateKey("copyright-©2020.-focalpoint-procurement-management,-all-rights-reserved"))}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
