export const popupTypes = {
  add: "ADD",
  delete: "DELETE",
  change_status: "CHANGE_STATUS",
};

export const SET_SUPPLIERS = "SET_SUPPLIERS";
export const SET_LOADING = "SET_LOADING";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const CHANGE_STATUS_SUPPLIER = "CHANGE_STATUS_SUPPLIER";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const SET_POPUP_NAME = "SET_POPUP_NAME";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_SELECTED_SUPPLIER = "SET_SELECTED_SUPPLIER";
export const SET_ALERT = "SET_ALERT";
