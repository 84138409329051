import React, {useEffect, useState} from "react";
import s from "./ProjectFinancialDetailsForm.module.scss";
import Button from "components/elements/Button/Button";
import CurrencySelectionField from "components/elements/CurrencySelectionField/CurrencySelectionField";
import {currencyData} from "helpers/helpData";
import {useFormik} from "formik";
import {object, string} from "yup";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import {localization} from "helpers/localizationForCompanies";
import DatePicker from "components/elements/DatePicker/DatePicker";
import {formatDate} from "helpers/dateFormat";
import Attachments from "components/forms/AddMilestoneTaskForm/components/Attachments/Attachments";
import classNames from "classnames";
import services from "services";
import Textarea from "components/elements/Textarea/Textarea";
import PropTypes from "prop-types";
import {entitiesFormatter} from "../../../helpers/entitiesFormatter";

const ProjectFinancialDetailsForm = (
  {
    onSubmit,
    onClose,
    defaultData,
    defaultCurrency,
    projectTargetSpend,
    projectTargetSpendCurrency,
    status,
    actualEndDate,
    targetEndDate,
    error,
    endDate,
  }
) => {

  const [savingsTypesList, setSavingsTypesList] = useState([]);
  const END_DATE = new Date(endDate);
  const NOTE_MAX_LENGTH = 100;

  useEffect(() => {
    services.savingsTypeServices.getSavingsTypeList().then(res => {
      if (res.data) {
        setSavingsTypesList(res.data.map(type => entitiesFormatter(type)));
      }
    });
  }, []);

  const [helpDataVals, setHelpDataVals] = useState(
    defaultData
      ? currencyData.find(
        e => e.label === defaultData.anticipated_savings_currency
      )
      : defaultCurrency
        ? currencyData.find(e => e.label === defaultCurrency)
        : currencyData[15]
  );

  let data = {
    start_date: defaultData?.start_date
      ? new Date(defaultData?.start_date)
      : END_DATE.setDate(END_DATE.getDate() + 1),
    end_date: defaultData?.end_date
      ? new Date(defaultData?.end_date)
      : END_DATE,
    anticipated_savings: defaultData?.anticipated_savings || "",
    budgeted_spend: projectTargetSpend || defaultData?.budgeted_spend || "",
    base_line_spend: defaultData?.base_line_spend || "",
    financial_note: defaultData?.financial_note || "",
    savings_type: defaultData?.savings_type ? {
      value: defaultData?.savings_type.id,
      label: defaultData?.savings_type.name
    } : {
      value: "",
      label: ""
    },
    note: defaultData?.note || "",
    attachments: defaultData?.attachments || []
  };

  if (!defaultData) {
    if (status === "completed") {
      if (actualEndDate) {
        data = {...data, start_date: new Date(actualEndDate), end_date: new Date(actualEndDate)};
      } else if (targetEndDate) {
        data = {...data, start_date: new Date(targetEndDate), end_date: new Date(targetEndDate)};
      }
    }
    if ((status === "active" || status === "planned") && targetEndDate) {
      data = {...data, start_date: new Date(targetEndDate), end_date: new Date(targetEndDate)};
    }
  }

  const formik = useFormik({
    initialValues: data,
    validationSchema: object({
      budgeted_spend: string()
        .required(`Please enter ${localization.targetSpend}`)
        .max(12, "Max digits quantity is 12"),
      base_line_spend: string().max(12, "Max digits quantity is 12"),
      savings_type: object({value: string().required(`Please choose ${localization.savingstype}`)}),
      start_date: string()
        .required("Savings start date is required")
        .nullable(),
      end_date: string()
        .required("Savings end date is required")
        .nullable(),
      note: string().max(
        NOTE_MAX_LENGTH,
        `The Note is too long. It should be ${NOTE_MAX_LENGTH} characters or less.`
      )
    }),
    validate: values => {
      const errors = {};
      if (values.budgeted_spend < 0)
        errors.budgeted_spend = "Value should be equal to or greater than 0";
      return errors;
    },
    onSubmit: values => {
      onSubmit({
        ...values,
        anticipated_savings_currency:
          helpDataVals?.label || defaultData.anticipated_savings_currency,
        budgeted_spend_currency:
          helpDataVals?.label || defaultData.budgeted_spend_currency,
        base_line_spend_currency:
          helpDataVals?.label || defaultData.baseline_spend_currency,
        start_date: formatDate(values.start_date),
        end_date: formatDate(values.end_date),
        note: values.note,
        attachments: values.attachments.filter(file => file.lastModifiedDate),
        savings_type: values.savings_type.value,
        savings_type_id: values.savings_type.value,
      });
    }
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleChange
  } = formik;
  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };
  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      );
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        );
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="dateBlock">
        <DatePicker
          label="Savings start date"
          selected={values.start_date}
          onChange={date => {
            setFieldValue("start_date", date);
            date > values.end_date && setFieldValue("end_date", date);
          }}
          selectsStart
          startDate={values.start_date}
          endDate={values.end_date}
          placeholderText="Choose Savings start date"
          wrapperClassName="wrapperdatePicker"
          className="customInput"
          todayButton="Go to today"
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.start_date && errors.start_date}
        />

        <DatePicker
          label="Savings end date"
          selected={values.end_date}
          onChange={date => setFieldValue("end_date", date)}
          selectsEnd
          startDate={values.start_date}
          endDate={values.end_date}
          minDate={values.start_date}
          placeholderText="Choose Savings end date"
          wrapperClassName="wrapperdatePicker"
          className="customInput"
          todayButton="Go to today"
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.end_date && errors.end_date}
        />
      </div>
      {error && <span className={s.error}>{error}</span>}
      <CurrencySelectionField
        className={s.selectionField}
        options={currencyData}
        defaultValue={helpDataVals}
        value={values.base_line_spend}
        onChange={({fieldVal, ...spread}) => {
          setHelpDataVals({...spread});
          setFieldValue("base_line_spend", fieldVal);
          values.budgeted_spend &&
          setFieldValue(
            "anticipated_savings",
            fieldVal - values.budgeted_spend > 0 ? fieldVal - values.budgeted_spend : 0
          );
        }}
        error={
          errors.base_line_spend &&
          touched.base_line_spend &&
          errors.base_line_spend
        }
        name="base_line_spend"
        label="Baseline Spend"
        fieldPlaceholder="000,000"
      />
      <CurrencySelectionField
        className={s.selectionField}
        options={currencyData}
        defaultValue={
          currencyData.find(e => e.label === projectTargetSpendCurrency) ||
          helpDataVals
        }
        value={values.budgeted_spend}
        onChange={({fieldVal, ...spread}) => {
          setHelpDataVals({...spread});
          setFieldValue("budgeted_spend", fieldVal);
          values.base_line_spend &&
          setFieldValue(
            "anticipated_savings",
            values.base_line_spend - fieldVal > 0 ? values.base_line_spend - fieldVal : 0
          );
        }}

        error={
          errors.budgeted_spend &&
          touched.budgeted_spend &&
          errors.budgeted_spend
        }
        name="budgeted_spend"
        label={`${localization.targetSpend}`}
        fieldPlaceholder="000,000"
      />
      <CurrencySelectionField
        className={s.selectionField}
        options={currencyData}
        defaultValue={helpDataVals}
        value={values.anticipated_savings}
        onChange={({fieldVal, ...spread}) => {
          setHelpDataVals({...spread});
          setFieldValue("anticipated_savings", fieldVal);
        }}
        disabled={true}
        error={
          errors.anticipated_savings &&
          touched.anticipated_savings &&
          errors.anticipated_savings
        }
        name="anticipated_savings"
        label={`${localization.targetSavings}`}
        labelGrey={`${localization.targetSavingsExtra}`}
        fieldPlaceholder="000,000"
      />
      <CustomDropdown
        options={savingsTypesList}
        value={values.savings_type}
        onChange={option => setFieldValue("savings_type", option)}
        label={`${localization.savingstype}`}
        placeholder="Choose type"
        error={
          errors.savings_type && touched.savings_type && errors.savings_type?.value
        }
      />
      <div className={s.inputWrap}>
        <Attachments
          onChange={handleChangeAttachment}
          onDelete={handleDeleteAttachment}
          attachments={values.attachments}
          // filesMaxLength='infinity'
        />
      </div>
      <div
        className={classNames(s.countField, {
          [s.countWrap]: errors.note && touched.note
        })}
      >
        <Textarea
          label="Note"
          value={values.note}
          error={errors.note && touched.note && errors.note}
          onChange={handleChange}
          name="note"
          placeholder="Enter a note"
          count={{
            current: values.note.length,
            max: NOTE_MAX_LENGTH
          }}
        />
      </div>
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {defaultData ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default ProjectFinancialDetailsForm;

ProjectFinancialDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  defaultData: PropTypes.string,
  defaultCurrency: PropTypes.any,
  projectTargetSpend: PropTypes.any,
  projectTargetSpendCurrency: PropTypes.any,
  isCreate: PropTypes.bool,
  status: PropTypes.string,
  actualEndDate: PropTypes.string,
  targetEndDate: PropTypes.string,
  error: PropTypes.string,
  endDate: PropTypes.string,
};