import React from "react";
import {CircularProgress} from "@material-ui/core";
import {Chart} from "react-google-charts";
import styles from "./SpendByMonth.module.scss";
import {formatData, generateColors} from "../../helpers";

const COLORS = generateColors(50);
const SpendByMonth = ({data: defaultData, currency}) => {
  const data = formatData(defaultData);
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Supplier Spend by month and category</h4>
      <div className={styles.chartWrapper}>
        <Chart
          height="280px"
          chartType="ColumnChart"
          loader={
            <div className="loaderWrap justifyCenter">
              <CircularProgress/>
            </div>
          }
          data={data}
          formatters={new Array(data[0].length - 1).fill(0).map((_, index) => ({
            type: "NumberFormat",
            column: index + 1,
            options: {
              prefix: `${currency}`,
              fractionDigits: 0,
            },
          }))}
          options={{
            colors: COLORS,
            allowHtml: true,
            showRowNumber: true,
            legend: "none",
            chartArea: {
              height: "100%",
              width: "100%",
              top: 20,
              left: 46,
              right: 10,
              bottom: 20,
            },
            isStacked: true,
            vAxis: {
              format: "short",
              minValue: 1,
              textStyle: {
                color: "rgba(0, 0, 0, 0.55)",
                fontSize: 12,
                fontName: "Roboto",
              },
              baselineColor: "none",
              minorGridlines: {count: 0},
              gridlines: {
                color: "#ecf0f4",
              },
            },
            hAxis: {
              format: "MMM",
              textStyle: {
                color: "#C0C7CE",
                fontSize: 11,
                fontName: "Nunito Sans",
              },
              gridlines: {
                color: "#fff",
              },
            },
          }}
        />
        <div className={styles.legendWrapper}>
          {data[0].map((legend, index) => {
            if (index) {
              return (
                <span className={styles.legend} key={legend}>
                  <span style={{backgroundColor: COLORS[index - 1]}}></span>
                  <p>{legend}</p>
                </span>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default SpendByMonth;
