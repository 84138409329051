import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from "react-dom";
import classNames from "classnames";
import styles from "./CustomStatusTabs.module.scss";
import {ReactComponent as Arrow} from "../../../../../../../assets/icons/arrowDown.svg";

const SingleTab = (
  {
    setActiveTab,
    child,
    isActive,
    setOpenTab,
    isOpen,
    changeSelector,
    deleteSelector,
    portalContainer
  }
) => {
  const [isHover, setHover] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [selectorName, setSelectorName] = useState(child.props.label);
  const tabRef = useRef(null);
  const tX = tabRef.current?.getBoundingClientRect().x + tabRef.current?.getBoundingClientRect().width - 50;
  const tY = tabRef.current?.getBoundingClientRect().y + tabRef.current?.getBoundingClientRect().height;
  const [cords, setCords] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    setCords({
      x: tX,
      y: tY,
    });
  }, [tX, tY]);

  const changeSelectorAction = () => {
    if (Boolean(selectorName)) {
      changeSelector(selectorName);
    }
    setEditMode(false);
  }

  if (isEditMode) return (
    <input
      autoFocus
      type="text"
      value={selectorName}
      onChange={e => {
        if (e.target.value.length < 50) {
          setSelectorName(e.target.value);
        }
      }}
      onBlur={changeSelectorAction}
      className={styles.input}
    />
  );

  const menu = (
    <ul className={styles.menu} style={{
      left: cords.x,
      top: cords.y
    }}>
      <li onClick={e => {
        e.stopPropagation();
        setEditMode(true);
      }}>
        Rename
      </li>
      <li onClick={e => {
        e.stopPropagation();
        deleteSelector(child.props.id);
        setOpenTab("");
      }}>
        Delete
      </li>
    </ul>
  );

  return (
    <div
      ref={tabRef}
      className={classNames(styles.tab, {[styles.active]: isActive, [styles.opened]: isOpen})}
      onClick={() => setActiveTab(child.props.id)}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
    >
      {child.props.label}
      {
        (isHover || isOpen) && (
          <Arrow onClick={e => {
            e.stopPropagation();
            setOpenTab(child.props.id)
          }}/>
        )
      }
      {(isOpen && portalContainer.current) && ReactDOM.createPortal(menu, portalContainer.current)}
    </div>
  );
};

export default SingleTab;