import React from "react";
import s from "./CalendarActualSavings.module.scss";
import {CalendarContainer} from "react-datepicker";
import DatePicker from "../../../../elements/DatePicker/DatePicker";

const CalendarActualSavings = ({startDate, onDateSelect}) => {

  const calendarContainerStyles = ({className, children}) => {
    return (
      <div className={s.calendarStyleBlock}>
        <CalendarContainer className={className}>
          <div className={s.calendarStyleChildren}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };
  return (
    <div className={s.calendarWrap}>
      <div className={s.calendarWrapPosition}>
        <DatePicker
          calendarIcon={false}
          selected={startDate}
          onChange={(date) => {
            onDateSelect(date[0]);
          }}
          dateFormat={"MMMM"}
          todayButton="This month"
          showMonthYearPicker
          calendarContainer={calendarContainerStyles}
          calendarClassName={s.calendarClassNameStyle}
          selectsRange
          selectsDisabledDaysInRange
          inline
          renderCustomHeader={({
                                 date,
                                 decreaseYear,
                                 increaseYear,
                               }) => (

            <div className={s.wrapHeaderCalendar}>
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                style={{position: "static", margin: 15}}
                onClick={decreaseYear}
              >
            <span
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
              }
            >
              {"<"}
            </span>
              </button>
              <span style={{fontSize: 18, color: "white", fontWeight: "normal"}}
                    className="react-datepicker__current-month">
            {date && date.toLocaleString("en-US", {
              year: "numeric",
            })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                style={{position: "static", margin: 15}}
                onClick={increaseYear}
              >
            <span
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
            >
              {">"}
            </span>
              </button>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default CalendarActualSavings;