import React, {useEffect, useState} from "react";
import styles from "./SelectedTaskSidebar.module.scss";
import classNames from "classnames";
import {ReactComponent as ArrowLeft} from "../../../assets/icons/arrow-left.svg";
import SelectedTaskDetails from "./components/SelectedTaskDetails/SelectedTaskDetails";
import Popup from "../../popups/Popup/Popup";
import SubtaskForm from "../../forms/SubtaskForm/SubtaskForm";
import PropTypes from "prop-types";

const SelectedTaskSidebar = (
  {
    selectedSubtask,
    onClose,
    onEdit,
    useForProject = false,
    preparedResponsibleUsers,
    addNote,
    editNote,
    deleteNote,
    isHighRule,
    changeStatus,
    isResponsibleUser
  }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditPopUp, setIsOpenEditPopUp] = useState(false);

  useEffect(() => {
    if (selectedSubtask?.id) setIsOpen(true);
  }, [selectedSubtask?.id]);

  const close = () => {
    setIsOpen(false);
    onClose();
  };

  const subtaskParams = {
    ...selectedSubtask,
    responsible_user: selectedSubtask?.responsible_user ? {
      value: selectedSubtask.responsible_user.id,
      label: selectedSubtask.responsible_user.name
    } : null
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.selectedTaskSidebar, {[styles.opened]: isOpen})}>
        <button
          className={styles.closeBtn}
          onClick={close}
        >
          <ArrowLeft/>
        </button>
        {selectedSubtask && (
          <SelectedTaskDetails
            data={selectedSubtask}
            openEditModal={() => setIsOpenEditPopUp(true)}
            useForProject={useForProject}
            addNote={addNote}
            editNote={(noteId, values) => editNote(selectedSubtask.id, noteId, values)}
            deleteNote={noteId => deleteNote(selectedSubtask.id, noteId)}
            isHighRule={isHighRule}
            changeStatus={changeStatus}
            isResponsibleUser={isResponsibleUser}
          />
        )}
      </div>
      {
        isOpenEditPopUp && (
          <Popup title="Edit subtask" onClose={() => setIsOpenEditPopUp(false)}>
            <SubtaskForm
              onClose={() => setIsOpenEditPopUp(false)}
              defaultValues={subtaskParams}
              responsibleUsers={preparedResponsibleUsers}
              onSubmit={values => {
                onEdit(values, selectedSubtask?.id);
                setIsOpenEditPopUp(false);
              }}
            />
          </Popup>
        )
      }
    </React.Fragment>
  );
};

export default SelectedTaskSidebar;

SelectedTaskSidebar.propTypes = {
  selectedSubtask: PropTypes.any,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  useForProject: PropTypes.bool,
  preparedResponsibleUsers: PropTypes.array,
  addNote: PropTypes.func,
  editNote: PropTypes.func,
  deleteNote: PropTypes.func,
  isHighRule: PropTypes.bool,
  changeStatus: PropTypes.func,
  isResponsibleUser: PropTypes.bool
};