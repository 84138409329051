import React, {useState, useEffect, useRef} from "react"
import styles from "./ResourceUtilizationReportContainer.module.scss"
import ResourceUtilizationReportFilter
  from "components/filters/ResourceUtilizationReportFilter/ResourceUtilizationReportFilter"
import ResourceUtilizationReportTabs from "./components/ResourceUtilizationReportTabs/ResourceUtilizationReportTabs"
import services from "services"
import {formatFilters} from "components/elements/FilterBar/helpers/helpers"
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar"
import {formatDepartmentsFilter} from "components/filters/helpers/formatDepartmentsFilter"
import {useTranslation} from "react-i18next";
import {userTypes} from "../../../helpers/userPermissions/userRoles";
import {userRoles} from "../../../helpers/usersData";

const ResourceUtilizationReportContainer = () => {
  const [filters, setFilters] = useState({})
  const containerRef = useRef();
  const {t} = useTranslation();
  const {procurement} = userTypes;

  const procurementUserRoles = userRoles
    .filter(role => (role.userType.value === procurement))
    .map(role => role.value)
    .join(",");

  useEffect(() => {
    services.userServices.getUsersFilters().then(res => {
      const tempFilters = res.data
      delete tempFilters.user_types
      const formattedResult = {
        ...tempFilters,
        departments: formatDepartmentsFilter(tempFilters.departments)
      }
      setFilters(filters => ({...filters, ...formatFilters(formattedResult)}))
    });
    services.userServices
      .stakeholdersAutocomplete({roles: procurementUserRoles})
      .then(res => {
        if (res.data) {
          setFilters(filters => ({
            ...filters,
            ...formatFilters({users: res.data})
          }))
        }
      });
  }, [])

  return (
    <section className={styles.container} ref={containerRef}>
      <h1 className={styles.title}
          data-translate-key="resource-utilization-report">{t("resource-utilization-report")}</h1>
      {!Object.keys(filters).length ? (
        <FilterBarLoading length={3} className={styles.filter}/>
      ) : (
        <ResourceUtilizationReportFilter
          className={styles.filter}
          filters={filters}
          afterFilterChangeAction={() => {
            const currentScrollPosition = containerRef.current.scrollTop;
            containerRef.current.scrollTo(0, currentScrollPosition);
          }}
        />
      )}

      <ResourceUtilizationReportTabs/>
    </section>
  )
}

export default ResourceUtilizationReportContainer
