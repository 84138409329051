import React from "react";
import classNames from "classnames";
import styles from "./Arrow.module.scss";
import { ReactComponent as ArrowSvg } from "assets/icons/arrowDown.svg";

export const Arrow = ({ arrowArr, setArrowArr, quest }) => {
  const chnageArrow = () => {
    setArrowArr((prevArr) =>
      prevArr.find((a) => a.pos === quest.pos)
        ? prevArr.map((a) => (quest.pos === a.pos ? { ...a, arrowChecked: !a.arrowChecked } : a))
        : [...prevArr, { pos: quest.pos, arrowChecked: true }],
    );
  };
  return (
    <ArrowSvg
      className={classNames(styles.arrow, {
        [styles.opened]: !!arrowArr.find((p) => p.pos === quest.pos)?.arrowChecked,
      })}
      onClick={chnageArrow}
    />
  );
};
