import React, { useEffect } from "react";
import { Alert } from "@material-ui/lab";
import classNames from "classnames";
import s from "./NotificationBox.module.scss";
import {useTranslate} from "../../../hooks/useTranslate";

const alertTypes = ["error", "warning", "info", "success"];

const NotificationBox = ({
  className,
  type,
  message,
  onClose = () => {},
  disappearTime = 4,
  onCancel,
}) => {
  const alertType = alertTypes.filter((e) => e === type)[0] || "success";
  const [translateKey, translateValue] = useTranslate(message);
  useEffect(() => {
    setTimeout(() => onClose(), disappearTime * 1000);
  }, [disappearTime, onClose]);

  return (
    <div className={s.wrapper}>
      <Alert
        severity={alertType}
        className={classNames(s.alert, {
          [className]: className,
          [s.success]: alertType === "success",
        })}
        onClose={onClose}
      >
        <div className={s.wrap}>
          <p data-translate-key={translateKey}>{translateValue}</p>
          {onCancel && (
            <button onClick={onCancel} className={s.btn}>
              Undo
            </button>
          )}
        </div>
      </Alert>
    </div>
  );
};

export default NotificationBox;
