export const popupTypes = {
  add: "ADD",
  delete: "DELETE",
};

export const SET_CONTRACTS = "SET_CONTRACTS";
export const SET_LOADING = "SET_LOADING";
export const ADD_NEW_CONTRACT = "ADD_NEW_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const SET_POPUP_NAME = "SET_POPUP_NAME";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_SELECTED_CONTRACT = "SET_SELECTED_CONTRACT";
export const SET_ALERT = "SET_ALERT";
