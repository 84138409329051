import React, {useState} from "react"
import {useHistory} from "react-router-dom"
import MainTable from "components/tables/MainTable/MainTable"
import {localization} from "helpers/localizationForCompanies"
import {currencyData} from "helpers/helpData"
import {formatCurrency} from "helpers/currencyFormat"
import {fromDate} from "helpers/dateFormat"
import sort from "helpers/sortHelper"

const TeamReportProjectsActiveProjectsTable = ({data: initialData}) => {
  const history = useHistory();

  const [data, setData] = useState(initialData?.map(itm => ({...itm, category: itm.category.name})) ?? []);

  const onSort = ({sort_by, direction}) => {
    if (
      sort_by === "project_type" ||
      sort_by === "name" ||
      sort_by === "category"
    ) {
      setData(sort.sortString(data, {field: sort_by, direction}))
    } else if (sort_by === "start_date" || sort_by === "end_date") {
      setData(sort.sortDate(data, {field: sort_by, direction}))
    } else {
      setData(sort.sortNumber(data, {field: sort_by, direction}))
    }
  }

  return (
    <>
      <MainTable
        data={data}
        dataTypes={[
          {
            title: `${localization.title}`,
            value: ({name}) => name,
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "name", direction})
            },
            onClick: id => history.push(`/projects/${id}`),
            sticky: true
          },
          {
            title: "Project type",
            value: ({project_type}) => project_type?.name || '-',
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "project_type", direction})
            }
          },
          {
            title: `${localization.category}`,
            value: ({category}) => category,
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "category", direction})
            }
          },
          {
            title: "Start date",
            value: ({start_date}) => fromDate(start_date),
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "start_date", direction})
            }
          },
          {
            title: "End date",
            value: ({end_date}) => fromDate(end_date),
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "end_date", direction})
            }
          },
          {
            title: "Baseline Spend (USD)",
            value: ({baseline_spend}) =>
              baseline_spend
                ? formatCurrency(
                  currencyData.filter(e => e.label === "USD")[0]?.value,
                  baseline_spend
                )
                : "-",
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "baseline_spend", direction})
            }
          },
          {
            title: "Savings (USD)",
            value: ({savings}) =>
              savings
                ? formatCurrency(
                  currencyData.filter(e => e.label === "USD")[0]?.value,
                  savings
                )
                : "-",
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "savings", direction})
            }
          }
        ]}
      />
    </>
  )
}
export default TeamReportProjectsActiveProjectsTable
