import React, { useEffect } from "react";
import ChartLoadingState from "components/loadingStates/PieChartWidget/PieChart/PieChart";

const ChartLoading = ({ isLoaded = () => {} }) => {
  useEffect(() => {
    return isLoaded;
  }, []);
  return <ChartLoadingState />;
};

export default ChartLoading;
