import { drawBeforeXLines } from "./beforeXLines";
import { drawAfterXLines } from "./afterXLines";
import { drawBeforeYLines } from "./beforeYLines";
import { drawAfterYLines } from "./afterYLines";
import { getColsPos } from "../helpers/getColsPos";

export const drawAllLines = (data, dataPos) => {
  return []
    .concat(
      drawBeforeXLines(data),
      drawAfterXLines(data),
      drawBeforeYLines(getColsPos(dataPos)),
      drawAfterYLines(getColsPos(dataPos))
    )
    .map(({ x1, x2, y1, y2 }) => ({
      x1,
      x2,
      y1,
      y2,
    }));
};
