import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import FilterBar from "../../elements/FilterBar/FilterBar";
import s from "./CustomDropdownsFilter.module.scss";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {debounce} from "lodash";
import services from "../../../services";

const CustomDropdownsFilter = ({showDefaultFilter, handleClearAll,handleChangeFilter}) => {
  const history = useHistory();
  const query = qs.parse(history.location.search);
  const [savedFilter, setSavedFilter] = useState(null);

  const statuses = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "deleted",
      name: "Deleted",
    },
  ];

  const saveDefaultFilter = () => {
    services.filterService.addFilter("admin_settings_custom_dropdowns", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  };

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  };

  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  return (
    <FilterBar
      filterName="Filter dropdowns"
      handleClearAll={handleClearAll}
      className={s.filter}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      withSearch
    >
      <FilterBarDropdown
        name="Status"
        options={statuses}
        handleChange={values => handleChangeFilter("statuses", values)}
        defaultValues={query?.statuses}
        useAlwaysDefault
        showDefaultFilter={showDefaultFilter}
      />
    </FilterBar>
  );
};

export default CustomDropdownsFilter;