import React from "react";
import s from "./ContractCreateContainer.module.scss";
import services from "../../../services";
import {formatDate} from "../../../helpers/dateFormat";
import ContractForm from "../../forms/ContractForm/ContractForm";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const ContractCreateContainer = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const serializeData = values => {
    const {
      supplier_id,
      name,
      parent_contract_id,
      category_id,
      start_date,
      end_date,
      business_owner_id,
      procurement_owner_id,
      total_value,
      total_value_currency,
    } = values;
    return {
      params: {
        supplier_id: supplier_id.id,
        name,
        parent_id: parent_contract_id.value === "none" ? "" : parent_contract_id.value,
        category_ids: category_id.id,
        start_date: formatDate(start_date),
        end_date: formatDate(end_date),
        business_owner_id: business_owner_id.id,
        procurement_owner_id: procurement_owner_id.id,
        total_value: total_value,
        total_value_currency: total_value_currency.label,
      },
    }
  }

  const createAction = values => {
    const {params} = serializeData(values);
    services.contractsServices.createContract(params).then(res => {
      history.push('/contracts');
    });
  }

  return (
    <section className={s.container}>
      <h3 className={s.title} data-translate-key={"contracts"}> {t(getTranslateKey("contracts"))}</h3>
      <ContractForm onCreate={values => createAction(values)}/>
    </section>
  );
};

export default ContractCreateContainer;
