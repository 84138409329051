import {
  SET_DETAILS,
  SET_DETAILS_BY_YEAR,
  ADD_DETAIL,
  DELETE_DETAIL,
  EDIT_DETAIL,
  SET_POPUP,
  RESET_ALERT,
  CURRENT_DETAIL,
  SET_DEFAULT_CURRENCY,
  SET_PERMISSIONS,
  RESET_ERROR,
  SET_DETAIL_ATTACHMENT
} from "./types"
import services from "services"

const getAlert = message => ({
  type: "success",
  message
})

const errorAlert = {
  type: "error",
  message: "Something went wrong. Please, try again."
}

export const setDetailsByYear = (dispatch, projectId, data) => {
  !data
    ? services.projectFinancialDetailsServices
        .getFinancialDetails(projectId)
        .then(res => {
          dispatch({
            type: SET_DETAILS_BY_YEAR,
            payload: res.data.financial_details_by_year
          })
        })
    : dispatch({
        type: SET_DETAILS_BY_YEAR,
        payload: data
      })
}

export const setDetails = (dispatch, projectId) => {
  services.projectFinancialDetailsServices
    .getFinancialDetails(projectId)
    .then(res => {
      dispatch({
        type: SET_DETAILS,
        payload: res.data.financial_details
      })
      dispatch({
        type: SET_DEFAULT_CURRENCY,
        payload: res.data.default_currency
      })
      if (res.data.permissions) {
        dispatch({
          type: SET_PERMISSIONS,
          payload: { permissions: res.data.permissions }
        })
      }
      setDetailsByYear(dispatch, projectId, res.data.financial_details_by_year)
    })
}
const appendFile = file => {
  const formData = new FormData()
  formData.append("file", file)
  return formData
}
export const addDetail = async (
  dispatch,
  projectId,
  { attachments, ...spread }
) => {
  await services.projectFinancialDetailsServices
    .addFinancialDetail(projectId, { ...spread })
    .then(res => {
      const detail = res?.data
      dispatch({
        type: ADD_DETAIL,
        payload: {
          detail,
          alert: getAlert("Financial details successfully added.")
        }
      })
      setDetailsByYear(dispatch, projectId)
      for (let i = 0; i < attachments.length; i++) {
        const file = appendFile(attachments[i])
        detail.id &&
          services.taskServices.addAttachment(file, detail.id).then(res => {
            const attachment = res?.data
            attachment &&
              dispatch({
                type: SET_DETAIL_ATTACHMENT,
                payload: {
                  attachment,
                  detailID: detail.id
                }
              })
          })
        detail.id &&
          detail.financial_benefit_id &&
          services.taskServices.addAttachment(file, detail.financial_benefit_id)
      }
    })
    .catch(res => {
      const error = res.response.data.error
      dispatch({
        type: ADD_DETAIL,
        payload: {
          alert: errorAlert,
          error:
            error === "Start Date cannot be greater then project End Date" ||
            error === "Start Date cannot be lower then project End Date"
              ? "Savings Start Date cannot be before the project End Date"
              : null
        }
      })
    })
}

export const editDetail = (
  dispatch,
  projectId,
  detailId,
  { attachments, ...spread }
) => {
  services.projectFinancialDetailsServices
    .editFinancialDetail(projectId, detailId, { ...spread })
    .then(res => {
      const detail = res?.data
      dispatch({
        type: EDIT_DETAIL,
        payload: {
          detail,
          alert: getAlert("Financial details successfully edited.")
        }
      })
      setDetailsByYear(dispatch, projectId)
      for (let i = 0; i < attachments.length; i++) {
        const file = appendFile(attachments[i])
        detail.id &&
          services.taskServices.addAttachment(file, detail.id).then(res => {
            const attachment = res?.data
            attachment &&
              dispatch({
                type: SET_DETAIL_ATTACHMENT,
                payload: {
                  attachment,
                  detailID: detail.id
                }
              })
          })
      }
    })
    .catch(res => {
      const error = res.response.data.error
      dispatch({
        type: EDIT_DETAIL,
        payload: {
          alert: errorAlert,
          error:
            error === "Start Date cannot be greater then project End Date" ||
            error === "Start Date cannot be lower then project End Date"
              ? "Savings Start Date cannot be before the project End Date"
              : null
        }
      })
    })
}

export const deleteDetail = (dispatch, projectId, detailId) => {
  services.projectFinancialDetailsServices
    .deleteFinancialDetail(projectId, detailId)
    .then(() => {
      dispatch({
        type: DELETE_DETAIL,
        payload: {
          detail_id: detailId,
          alert: getAlert("Financial detail successfully deleted.")
        }
      })
      setDetailsByYear(dispatch, projectId)
    })
    .catch(() => {
      dispatch({
        type: DELETE_DETAIL,
        payload: {
          alert: errorAlert
        }
      })
    })
}

export const changeComment = async (projectId, { results_comment }) => {
  await services.projectServices.editProject(projectId, {
    financial_note: results_comment
  })
}

export const setCurrentDetail = id => ({
  type: CURRENT_DETAIL,
  payload: id
})

export const setPopup = type => ({
  type: SET_POPUP,
  payload: type
})

export const resetAlert = () => ({
  type: RESET_ALERT
})
export const resetError = () => ({
  type: RESET_ERROR
})
