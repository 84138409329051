import React, {useEffect, useState} from "react";
import s from "./RecentRequest.module.scss";
import sBase from "../MainDashboard.module.scss";
import classNames from "classnames";
import services from "../../../../../../services";
import {ReactComponent as RedirectIcon} from "../../../../../../assets/icons/redirect-icon.svg";
import Button from "../../../../../elements/Button/Button";
import {useHistory} from "react-router-dom";
import {paths} from "../../../../../../routes/paths";
import DefaultLoading from "../../../../../loadingStates/Dashboard/DefaultLoading/DefaultLoading";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import SingleRequest from "./SingleRequest";
import {useTranslation} from "react-i18next";

const RecentRequest = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    services.dashboardServices.getRecentRequests().then((res) => {
      setRequests(res.data);
      setLoading(false);
    });
  }, []);

  const renderContent = () => {
    if (loading) return (
      <section className={s.loadingWrap}>
        <DefaultLoading/>
      </section>
    );

    if (!loading && !requests.length) return (
      <EmptyContent
        className={s.emptyBlock}
        text="You don't have any requests yet."
      />
    );

    return (
      <TableContainer>
        <Table stickyHeader className={sBase.table}>
          <TableHead className={sBase.tableHead} data-attr="table-head">
            <TableRow>
              <TableCell className={sBase.tableCell} data-translate-key={"request"}>{t("request")}</TableCell>
              <TableCell className={sBase.tableCell}
                         data-translate-key={"responsible-procurement"}>{t("responsible-procurement")}</TableCell>
              <TableCell className={sBase.tableCell} data-translate-key={"status"}>{t("status")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={sBase.tableBody}>
            {requests.map(request => <SingleRequest request={request} key={request.id}/>)}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <section className={classNames(sBase.wrapper, s.recentRequestWrap)}>
      <header>
        <h4 className={classNames(sBase.title, s.title)} data-translate-key={"recent-requests"}>
          {t("recent-requests")}
        </h4>
        <Button
          styled="secondary"
          className={s.redirectBtn}
          onClick={() => history.push(paths.requests)}
          data-translate-key={"show-all"}
        >
          <RedirectIcon/> {t("show-all")}
        </Button>
      </header>
      {renderContent()}
    </section>
  );
};

export default RecentRequest;