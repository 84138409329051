import React, {useState, useEffect} from "react";
import classNames from "classnames";
import s from "./ProjectReportContainer.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import {useParams} from "react-router-dom";
import services from "services";
import Details from "components/containers/ProjectContainer/components/ProjectInfo/components/Details/Details";
import DetailLoading from "components/loadingStates/Detail/Detail";
import General from "./components/General/General";
import Suppliers from "./components/Suppliers/Suppliers";
import ReportTimeSpendWidget from "../../elements/ReportTimeSpendWidget/ReportTimeSpendWidget";

const ProjectReportContainer = () => {
  const params = useParams();
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    services.projectServices.getProject(params.id).then((res) => {
      setProject(res?.data);
      setLoading(false);
    });
  }, []);
  return (
    <div className={s.wrap}>
      {loading ? (
        <Skeleton
          variant="text"
          className={classNames("title", s.title)}
          animation="wave"
          width={200}
        />
      ) : (
        <h3 className={s.title}>{project.name}</h3>
      )}
      {loading ? (
        <DetailLoading length={6} className={s.details}/>
      ) : (
        <Details data={project} className={s.details}/>
      )}
      <General className={s.general}/>
      <div className={s.row}>
        <h3 className={s.name}>Time spent</h3>
        <ReportTimeSpendWidget type="project"/>
      </div>
      <Suppliers className={s.suppliers}/>
    </div>
  );
};

export default ProjectReportContainer;
