import React from "react";
import PropTypes from "prop-types";
import CustomLink from "components/elements/CustomLink/CustomLink";
import Divider from "../../../components/Divider/Divider";
import Social from "../../../components/Social/Social";
import classNames from "classnames";
import s from "./ArticleCard.module.scss";

const ArticleCard = ({ img, title, path, className }) => {
  return (
    <CustomLink path={path} className={classNames(s.articleCard, className)}>
      <img src={img} alt="/" className={s.cardImg} />
      <div className={s.content}>
        <h2 className={s.title}>{title}</h2>

        <Divider />

        <Social />
      </div>
    </CustomLink>
  );
};

ArticleCard.propTypes = {
  img: PropTypes.any,
  title: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.any
}

ArticleCard.defaultProps = {
  img: "",
  title: "",
  path: "",
  className: ""
}

export default ArticleCard;
