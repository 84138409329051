import {LOGIN, LOGOUT, SET_LOGO_COMPANY, SET_LANGUAGE} from "helpers/constants";
import { load } from "helpers/localStorage";
import services from "../services";

const savedUser = load("currentUser");

let initialState = {
  id: "",
  name: "",
  email: "",
  title: "",
  roles: "",
  language: "en",
  organization: {
    name: "",
  },
  division: {},
  department: {},
};

if (savedUser) {
  const initialKeys = Object.keys(initialState);
  const valid = initialKeys.every((key) => savedUser.hasOwnProperty(key));
  if (!valid) {
    services.userServices.logout().then(() => {
      initialState = {
        id: "",
        name: "",
        email: "",
        title: "",
        roles: "",
        language: "",
        organization: {
          name: "",
        },
        division: {},
        department: {},
      };
    });
  } else {
    initialState = savedUser;
  }
}

const user = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN: {
      return payload;
    }
    case SET_LOGO_COMPANY: {
      return {
        ...state,
        organization: {
         ...state.organization,
          logo_company: action.payload
        },
      }
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        language: action.payload
      }
    }
    case LOGOUT: {
      return {
        id: "",
        name: "",
        email: "",
        title: "",
        organization: {
          name: "",
        },
        division: {},
        department: {},
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
