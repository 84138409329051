import React from "react";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import MainTable from "../MainTable/MainTable";
import {fromDate} from "helpers/dateFormat";
import PropTypes from "prop-types";
import Status from "../../elements/Status/Status";
import s from "../../containers/AdminSettingsContainer/AdminSettingsContainer.module.scss";

const AdminSettingsFinancialSavingsGroupTable = (
  {
    data,
    onAdd,
    onDelete,
    onEdit,
    onRestore,
  }
) => {
  const history = useHistory();
  const query = qs.parse(history.location.search);

  const onSort = ({sort_by, direction}) => {
    history.push({
      search: qs.stringify({...query, sort_by, direction, page: 1}),
    });
  };

  const onPageChange = (page) => {
    history.push({search: qs.stringify({...query, page})});
  };

  return (
    <MainTable
      data={data}
      inlineWrapClassName={s.inlineWrap}
      dataTypes={[
        {
          title: "Savings group",
          value: ({name}) => name,
        },
        {
          title: "Status",
          // eslint-disable-next-line react/prop-types,react/display-name
          value: ({status}) => <Status status={status == "deleted" ? "archived" : status}/>,
        },
        {
          title: "Updated",
          value: ({updated_at}) =>
            updated_at ? fromDate(updated_at) : "-",
        },
      ]}
      options={item => {
        if (item.status !== "deleted") return [
          onEdit && {
            title: "Edit",
            onClick: () => {
              onEdit(item);
            },
          },
          onDelete && {
            title: "Delete",
            onClick: () => {
              onDelete(item);
            },
          },
        ];
        return [
          onRestore && {
            title: "Restore",
            onClick: () => {
              onRestore(item);
            },
          },
        ];
      }}
      button={{
        name: "New type",
        onClick: () => onAdd(),
      }}
      perPage={30}
      totalCount={data.savings_types_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={query.page || 1}
    />
  );
};

export default AdminSettingsFinancialSavingsGroupTable;

AdminSettingsFinancialSavingsGroupTable.propTypes = {
  data: PropTypes.any,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onRestore: PropTypes.func,
};
