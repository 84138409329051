import React from "react";
import sBase from '../Dashboard.module.scss';
import s from "./Tasks.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";

const TasksLoading = () => {
  return (
    <div className={classNames(sBase.container, s.notificationsContainer)}>
      <Skeleton
        variant="text"
        className={s.title}
        animation="wave"
        width={200}
      />
      <Skeleton
        variant="text"
        className={s.item}
        animation="wave"
        width={160}
      />
      <Skeleton
        variant="text"
        className={s.item}
        animation="wave"
        width={140}
      />
      <Skeleton
        variant="text"
        className={s.item}
        animation="wave"
        width={130}
      />
      <Skeleton
        variant="text"
        className={s.item}
        animation="wave"
        width={110}
      />
    </div>
  );
};

export default TasksLoading;


