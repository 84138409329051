import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import Button from "../../../../elements/Button/Button";
import CustomDropdown from "../../../../elements/CustomDropdown/CustomDropdown";
import {object, string} from "yup";

const SupplierStatusForm = ({onSubmit, onClose, suppliersList}) => {

  const [supplierStatuses, setSupplierStatuses] = useState([
    {
      value: 'preferred',
      label: 'Preferred'
    },
    {
      value: 'approved',
      label: 'Approved'
    },
    {
      id: 3,
      value: 'deleted',
      label: 'Deleted'
    },
    {
      value: 'non_approved',
      label: 'No Approved'
    }
  ]);

  useEffect(() => {
    setSupplierStatuses(supplierStatuses.filter(s => suppliersList.map(ss => ss.supplier_type).indexOf(s.value) === -1));
  }, [suppliersList]);

  const formik = useFormik({
    initialValues: {
      supplier: '',
    },
    validationSchema: object({
      supplier: string().required("Supplier Status is required"),
    }),
    onSubmit: (values) => {
      onSubmit({
        supplier: values.supplier,
      });
      onClose();
    },
  });

  const {handleSubmit, values, setFieldValue, errors, touched} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <CustomDropdown
        options={supplierStatuses}
        value={values.supplier}
        onChange={(value) => setFieldValue("supplier", value)}
        label="Supplier status"
        placeholder="Choose status"
        error={errors.supplier && touched.supplier && errors.supplier}
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">Create</Button>
      </div>
    </form>
  );
}
export default SupplierStatusForm;
