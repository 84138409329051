import React from "react";
import AdminSettingsTriageContainer from "../../containers/AdminSettingsTriageContainer/AdminSettingsTriageContainer";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import {useLocation} from "react-router-dom";
import querystring from "query-string";

const AdminSettingsTriagePage = () => {
  const location = useLocation();
  const {name} = querystring.parse(location.search);

  return (
    <DefaultLayout breadcrumbs={[
      {
        name: "Admin Settings",
        path: "",
        disabled: true
      },
      {
        name: "Requests",
        path: paths.adminSettingsRequest + "?tab=Triage",
      },
      {
        name,
        withoutTranslate: true,
      }
    ]}>
      <AdminSettingsTriageContainer/>
    </DefaultLayout>
  );
};
export default AdminSettingsTriagePage;
