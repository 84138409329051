import React from "react";
import s from "./Users.module.scss";
import Users from "./Users/Users";
import PropTypes from "prop-types";
import {ReactComponent as NotificationIcon} from "../../../../../../assets/icons/notification.svg";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const CategoryTab = ({id}) => {
  const {t} = useTranslation();
  return (
    <div className={s.usersTabWrapper}>
      <div className={s.notification}>
        <NotificationIcon/>
        <span
          data-translate-key={getTranslateKey("You can select a stakeholder from this table by clicking on checkbox.")}>
          {t(getTranslateKey("You can select a stakeholder from this table by clicking on checkbox."))}
        </span>
      </div>
      <Users id={id}/>
    </div>
  );
};

export default CategoryTab;

CategoryTab.propTypes = {
  id: PropTypes.string
};