import React from "react";
import AdditionalHeader from "../AdditionalHeader/AdditionalHeader";
import PropTypes from "prop-types";
import {FormControlLabel, Radio} from "@material-ui/core";
import styles from "./AdditionalRadio.module.scss";

const AdditionalRadio = ({values, setFieldValue, field, errors}) => {
  const {question, key} = field;
  return (
    <>
      <AdditionalHeader questionName={question.question_name} isRequired={question.is_required}/>
      <div className={styles.answersList}>
        {
          question.answers.map(qr =>
            <FormControlLabel
              checked={values[key]?.value === qr.answer_id}
              value={qr.answer_name}
              key={qr.answer_id}
              control={<Radio color="primary" onClick={() => setFieldValue(field.key, {
                value: qr.answer_id,
                question_id: question.question_id
              })}/>}
              label={qr.answer_name || "Question text"}
            />
          )
        }
      </div>
      {errors[field.key] && <span className={styles.errorMessage}>{errors[field.key].value}</span>}
    </>
  );
};

export default AdditionalRadio;

AdditionalRadio.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any,
};