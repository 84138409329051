import React from "react";
import PropTypes from "prop-types"
import classNames from 'classnames'
import s from "./ModulesCard.module.scss";
import {useTranslate} from "../../../../../../../hooks/useTranslate";

const ModulesCard = ({ img, title, text, titleClass }) => {
  const [translateLabelKey, translateLabelValue] = useTranslate(title);
  const [translateLabelKeyText, translateLabelValueText] = useTranslate(text);
  return (
    <div className={s.card}>
      <img src={img} alt="card-icon" className={s.img} />
      <h3 className={classNames(s.title, titleClass)}  data-translate-key={translateLabelKey}>{translateLabelValue}</h3>
      <p className={s.text} data-translate-key={translateLabelKeyText}>{translateLabelValueText}</p>
    </div>
  );
};

ModulesCard.propTypes = {
  img: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  titleClass: PropTypes.any
}

ModulesCard.defaultProps = {
  img: "",
  title: "",
  text: "",
  titleClass: ""
}

export default ModulesCard;
