import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import styles from "./SurveyTemplateTable.module.scss";
import { SurveyTemplateContainer } from "../SurveyTemplateContainer/SurveyTemplateContainer";
import { EditScalePage } from "../EditScalePage/EditScalePage";
import { NewStepPopup } from "../NewStepPopup/NewStepPopup";
import { SurveyStepsTable } from "../SurveyStepsTable/SurveyStepsTable";
import { Buttons } from "../Buttons/Buttons";
import { EditQuestionsPage } from "../EditQuestionsPage/EditQuestionsPage";
import { PreviewPage } from "../PreviewPage/PreviewPage";
import { useLocation } from "react-router-dom";
import { statuses as statusesDefault } from "components/containers/SurveysContainer/components/Templates/Templates.jsx";
import TablesLoading from "components/loadingStates/Dashboard/Tables/TablesLoading";
import Skeleton from "@material-ui/lab/Skeleton";
import services from "services";

const ColorBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 70px;
  height: 27px;
  padding: 8px 16px !important;
  margin-left: 10px;
  margin-top: 3px;
  border-radius: 6px;
  background-color: ${({ color }) => color ?? ""};
  color: white;
`;

export const initStatuses = [
  { status: "Low", color: "#DE4935", mincore: 0, core: 25 },
  { status: "Medium", color: "#F29239", mincore: 26, core: 50 },
  { status: "High", color: "#F2CD7B", mincore: 51, core: 75 },
  { status: "Extreme", color: "#83A755", mincore: 76, core: 100 },
];

export const colors = [
  "#DE4935",
  "#F29239",
  "#F2CD7B",
  "#83A755",
  "#00D4C8",
  "#54C0E2",
  "#5B79B4",
  "#655DEF",
  "#A04DDB",
  "#FF4DA2",
  "#DF8A9F",
];

export const SurveyTemplateTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editScalePopUp, setEditScalePopUp] = useState(false);
  const [editQuestions, setEditQuestions] = useState(false);
  const [newPopUp, setNewPopUp] = useState({ checked: false, action: "", item: {} });
  const [steps, setSteps] = useState([]);
  const [statuses, setStatuses] = useState(initStatuses);
  const [saveSteps, setSaveSteps] = useState(false);
  const [preview, setPreview] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const { pathname } = useLocation();
  const currentId = pathname.split("/")[3];

  useEffect(() => {
    setIsLoading(true);
    services.templatesServices.getTemplates().then((res) => {
      setCurrentTemplate(res.data.find((el) => el.id === currentId) || {});
    });
    services.templatesServices.getSteps(currentId).then((res) => {
      setSteps(res.data.filter((st) => st.status !== "inactive"));
      setSteps((p) =>
        p.map((st) => ({
          ...st,
          questions: st.questions.map((q) => ({
            ...q,
            answers: q.answers.filter((a) => a.status !== "inactive"),
          })),
        })),
      );
      setSteps((p) =>
        p.map((st) => ({
          ...st,
          questions: st.questions.filter((q) => q.status !== "inactive"),
        })),
      );
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (currentTemplate && !saveSteps) {
      services.templatesServices.createStep(currentId, steps).then((res) => {
        setSteps(res.data.filter((st) => st.status !== "inactive"));
        setSteps((p) =>
          p.map((st) => ({
            ...st,
            questions: st.questions.map((q) => ({
              ...q,
              answers: q.answers.filter((a) => a.status !== "inactive"),
            })),
          })),
        );
        setSteps((p) =>
          p.map((st) => ({
            ...st,
            questions: st.questions.filter((q) => q.status !== "inactive"),
          })),
        );
      });
    }
  }, [saveSteps]);

  return (
    <div className={styles.mainContainer}>
      <section className={styles.container}>
        {editQuestions && (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <h3 className={styles.title}>Edit questions</h3>
            <Buttons
              name="Standart"
              setEditQuestions={setEditQuestions}
              setSaveSteps={setSaveSteps}
            />
          </div>
        )}

        {(!editQuestions || !preview) && (
          <div className={styles.wrapper}>
            <div>
              {!editQuestions && !isLoading && (
                <h3 className={styles.title}>{currentTemplate?.name}</h3>
              )}
              {isLoading && <Skeleton variant="text" animation="wave" width={250} />}
              {!editScalePopUp && !preview && !editQuestions && (
                <ColorBtn
                  color={
                    currentTemplate
                      ? statusesDefault.find((el) => el.value === currentTemplate.status)?.color
                      : ""
                  }>
                  {currentTemplate
                    ? statusesDefault.find((el) => el.value === currentTemplate.status)?.label
                    : ""}
                </ColorBtn>
              )}
            </div>
            {!editQuestions && (
              <Buttons
                name="Preview"
                editScalePopUp={editScalePopUp}
                setEditScalePopUp={setEditScalePopUp}
                preview={preview}
                setPreview={setPreview}
              />
            )}
          </div>
        )}
      </section>
      {!editScalePopUp ? (
        !editQuestions &&
        !preview && (
          <SurveyTemplateContainer
            statuses={statuses}
            setEditScalePopUp={setEditScalePopUp}
            currentTemplate={currentTemplate}
            setCurrentTemplate={setCurrentTemplate}
            isLoading={isLoading}
          />
        )
      ) : (
        <EditScalePage statuses={statuses} setStatuses={setStatuses} />
      )}
      {editQuestions && (
        <EditQuestionsPage
          steps={steps}
          setSteps={setSteps}
          saveSteps={saveSteps}
          setSaveSteps={setSaveSteps}
        />
      )}
      {preview && <PreviewPage steps={steps} />}
      {newPopUp.checked && (
        <NewStepPopup
          setNewPopUp={setNewPopUp}
          setSteps={setSteps}
          currentId={currentId}
          newPopUp={newPopUp}
          steps={steps}
        />
      )}
      {steps.length > 0 && !editScalePopUp && !editQuestions && !preview && (
        <SurveyStepsTable
          steps={steps}
          setEditQuestions={setEditQuestions}
          currentId={currentId}
          setSteps={setSteps}
          setNewPopUp={setNewPopUp}
          newPopUp={newPopUp}
        />
      )}
      {!steps.length && !editScalePopUp && !editQuestions && !preview && !isLoading && (
        <EmptyBlock
          className={styles.emptyMargins}
          subtitle="You don’t have survey steps yet. You can create a new one"
          buttonTitle="Edit"
          onClick={() => setNewPopUp((p) => ({ checked: !p.checked, action: "Create" }))}
        />
      )}
      {!steps.length && !editScalePopUp && !editQuestions && !preview && isLoading && (
        <div style={{ height: "300px", marginTop: "30px" }}>
          <TablesLoading length={6} />
        </div>
      )}
    </div>
  );
};
