import userServices from "./userServices";
import profileServices from "./profileServices";
import projectServices from "./projectServices";
import categoriesServices from "./categoriesServices";
import supplierServices from "./supplierServices";
import stakeholdersServices from "./stakeholdersServices";
import milestonesServices from "./milestonesServices";
import projectAttachmentsServices from "./projectAttachmentsServices";
import linkedProjectsServices from "./linkedProjectsServices";
import financialBenefitsServises from "./financialBenefitsServises";
import currenciesServices from "./currenciesServices";
import nonFinancialBenefitsServices from "./nonFinancialBenefitsServices";
import taskServices from "./taskServices";
import contractsServices from "./contractsServices";
import contactUsServices from "./contactUsServices";
import notesServices from "./notesServices";
import projectFinancialDetailsServices from "./projectFinancialDetailsServices";
import dashboardServices from "./dashboardServices";
import surveyServices from "./surveyServices";
import notificationsServices from "./notificationsServices";
import adminServices from "./adminServices";
import resourceUtilizationServices from "./resourceUtilizationServices";
import projectRequestsServices from "./projectRequestsServices";
import tagsServices from "./tagsServices";
import reportsServices from "./reportsServices";
import reportServices from "./reportServices";
import adminProjectsServices from "./adminProjectsServices";
import companyGoalsServices from "./companyGoalsServices";
import additionalQuestions from "./additionalQuestionsServices";
import flagServices from "./flagServices";
import savingsTypeServices from "./savingsTypeServices";
import requestTypeServices from "./requestTypeServices";
import taskStatusServices from "./taskStatusServices";
import savingsGroupServices from "./savingsGroupServices";
import customDropdownsServices from "./customDropdownsServices";
import approvalServices from "./approvalServices";
import noteServices from "./noteServices";
import filterService from "./filterService";
import translateService from "./translateService";
import triageServices from "./triageServices";
import customStatusesServices from "./customStausesServices";
import emailTemplatesServices from "./emailTemplatesService";
import templatesServices from "./templatesRequestsService";
import savingsGeneralServices from "./savingsGeneralServices";
import divisionServices from "./divisionServices";

export default {
  userServices,
  profileServices,
  projectServices,
  categoriesServices,
  supplierServices,
  stakeholdersServices,
  milestonesServices,
  projectAttachmentsServices,
  linkedProjectsServices,
  financialBenefitsServises,
  currenciesServices,
  nonFinancialBenefitsServices,
  taskServices,
  contractsServices,
  contactUsServices,
  notesServices,
  projectFinancialDetailsServices,
  dashboardServices,
  surveyServices,
  notificationsServices,
  adminServices,
  resourceUtilizationServices,
  projectRequestsServices,
  tagsServices,
  reportsServices,
  reportServices,
  adminProjectsServices,
  companyGoalsServices,
  additionalQuestions,
  flagServices,
  savingsTypeServices,
  savingsGroupServices,
  taskStatusServices,
  requestTypeServices,
  customDropdownsServices,
  approvalServices,
  noteServices,
  filterService,
  translateService,
  triageServices,
  customStatusesServices,
  emailTemplatesServices,
  templatesServices,
  savingsGeneralServices,
  divisionServices
};
