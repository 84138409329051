import React, {useEffect, useState} from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import qs from "query-string";
import {debounce} from "lodash";
import {useHistory} from "react-router-dom";
import {getAllIds} from "components/elements/FilterBar/helpers/helpers";
import services from "../../../services";

const SystemReportsSuppliersFilter = ({filters, className}) => {
  const history = useHistory();
  const [savedFilter, setSavedFilter] = useState(null);

  useEffect(() => {
    services.filterService.getFilter("system_report_suppliers").then(res => {
      if (res.data && res.data[0]) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      }
    });
  }, []);

  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(
      values[values.length - 1]?.id === "none"
        ? [values[values.length - 1]]
        : values.filter(e => e.id !== "none")
    );

    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleClearAll = () => {
    const searchQuery = {page, tab, sort_by, direction};
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const saveDefaultFilter = () => {
    services.filterService.addFilter("system_report_suppliers", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  }

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  }

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams ?? savedFilter.filter_params),
      page: 1,
      tab,
      sort_by,
      direction,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  }

  const handleSearch = debounce(term => {
    history.push({
      search: qs.stringify(
        {...query, query: term},
        {
          arrayFormat: "comma"
        }
      )
    });
  }, 300);

  return (
    <FilterBar
      filterName='Filter suppliers'
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      withSearch
    >
      <FilterBarDropdown
        name='Status'
        options={filters.status}
        defaultValues={query?.statuses?.split(",")}
        handleChange={values => {
          handleChangeFilter("statuses", values);
        }}
      />
      <FilterBarDropdown
        name='Owner'
        searchPlaceholder='Search owner'
        defaultValues={query?.owner_ids?.split(",")}
        options={filters.owners}
        handleChange={values => {
          handleChangeFilter("owner_ids", values);
        }}
      />
      <FilterBarDropdown
        name='Categories'
        searchPlaceholder='Search category'
        defaultValues={query?.category_ids?.split(",")}
        options={filters.categories}
        handleChange={values => {
          handleChangeFilter("category_ids", values);
        }}
      />
    </FilterBar>
  );
};

export default SystemReportsSuppliersFilter;
