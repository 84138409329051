import React from "react";
import styles from "./TaskDetails.module.scss";
import InfoBlock from "../../../../elements/InfoBlock/InfoBlock";
import CustomLink from "../../../../elements/CustomLink/CustomLink";
import {trimRest} from "../../../../../helpers/stringHelper";
import {ReactComponent as Link} from "../../../../../assets/icons/link.svg";
import PropTypes from "prop-types";

const Hyperlinks = ({hyperlinks}) => {
  return (
    <div className={styles.hyperlinks}>
      <InfoBlock title="Hyperlinks:">
        <div className={styles.hyperlinksWrap}>
          {hyperlinks?.map((hyperlink, index) => (
            <CustomLink key={index} path={hyperlink.url} type="link">
              {trimRest(17, hyperlink.name)} <Link/>
            </CustomLink>
          ))}
        </div>
      </InfoBlock>
    </div>
  );
};

export default Hyperlinks;

Hyperlinks.propTypes = {
  hyperlinks: PropTypes.array,
};