export const formatCheckedClients = (clients, options) => {
  let checkedClients = []
  const clientNames = Object.keys(clients)
  options.map(option => {
    if (clientNames.includes(option.label)) {
      checkedClients.push(option)
    } else if (option.subItems) {
      const fItem = formatCheckedClients(clients, option.subItems)
      if (fItem) {
        checkedClients.push(fItem)
      }
    }
  })
  return checkedClients
}
