import React from "react";
import PropTypes from 'prop-types'
import UserFormWrapper from "components/wrappers/UserFormWrapper/UserFormWrapper";
import s from "./EmailSent.module.scss";

const EmailSent = ({ email }) => {
  return (
    <UserFormWrapper className={s.emailSentWrapper}>
      <p className={s.emailSent}>
        We just sent you a reset password link to the <span>{email}</span>.
      </p>
      <p className={s.followSent}>Please, follow instructions from the email</p>
    </UserFormWrapper>
  );
};

EmailSent.propTypes = {
  email: PropTypes.string
}

EmailSent.defaultProps = {
  email: "andrewtabit@gmail.com"
}

export default EmailSent;
