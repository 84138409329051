import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Checkbox from "components/elements/Checkbox/Checkbox";
import s from "./FilterBarDropdownItem.module.scss";
import {ReactComponent as Arrow} from "assets/icons/arrow-down.svg";
import classNames from "classnames";

const FilterBarDropdownItem = (
  {
    item,
    checked,
    onCheck,
    isChecked,
    depthLevel = 0,
    withOptionTranslate
  }
) => {
  const handleCheck = () => {
    onCheck(item);
  };
  const [isOpen, setIsOpen] = useState(false);
  const isSubItems = item.subItems?.length > 0;

  useEffect(() => {
    setIsOpen(item.opened || false);
  }, [item.opened]);

  return (
    <div>
      <div
        className={s.item}
        style={{
          paddingLeft:
            isSubItems || depthLevel > 0 ? `${depthLevel * 14}px` : 0,
        }}
      >
        {(isSubItems || depthLevel > 0) && (
          <span
            className={classNames(s.arrowButton, {
              [s.isHiddenArrow]: depthLevel > 0 && !isSubItems,
              [s.isClosed]: !isOpen,
            })}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Arrow/>
          </span>
        )}
        <Checkbox
          withTranslate={withOptionTranslate}
          checked={checked && checked !== "minus"}
          onChange={handleCheck}
          label={item.name}
          type={checked}
        />
      </div>
      {isSubItems &&
        isOpen &&
        item.subItems.map((subItem, index) => (
          <FilterBarDropdownItem
            key={index}
            item={subItem}
            onCheck={onCheck}
            checked={isChecked(subItem)}
            isChecked={isChecked}
            depthLevel={depthLevel + 1}
            withOptionTranslate={withOptionTranslate}
          />
        ))}
    </div>
  );
};

FilterBarDropdownItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    subItems: [],
  }),
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
};

FilterBarDropdownItem.defaultProps = {
  checked: false,
};

export default FilterBarDropdownItem;
