import React, {useState, useEffect, Fragment, useContext} from "react";
import {useParams} from "react-router-dom";
import services from "services/index";
import AlertContext from "contexts/AlertContext/AlertContext";
import {formatDate} from "helpers/dateFormat";
import DateChangerWidget from "./components/DateChangerWidget/DateChangerWidget";
import * as propTypes from "prop-types";
import {delay} from "../../../../../helpers/delay";

const MilestonesContainer = ({project}) => {
  const params = useParams();
  const [milestones, setMilestones] = useState([]);
  const {setAlert} = useContext(AlertContext);

  const [sorted, setSorted] = useState({
    sort_by: "start_date",
    direction: "asc"
  });

  const loadMilestones = () => {
    services.milestonesServices.getMilestones(params.id, sorted).then(res => {
      setMilestones(res.data.milestones);
    });
  }

  useEffect(() => {
    loadMilestones();
  }, [sorted, params.id]);

  const appendFile = file => {
    const formData = new FormData()
    formData.append("file", file)
    return formData
  }

  const addHandler = (milestoneId, {attachments, ...spread}) => {
    services.taskServices
      .addTask(milestoneId, {...spread})
      .then(res => {
        const task = res?.data
        setAlert({
          message: `"${task.name}" task successfully added.`
        })
        if (task?.id) {
          setMilestones(prevMilestones => {
            return prevMilestones.map(milestone => {
              if (milestone.id === milestoneId) {
                return {
                  ...milestone,
                  tasks: {
                    ...milestone.tasks, additional_tasks: [...milestone.tasks.additional_tasks, task]
                  }
                }
              }
              return milestone;
            })
          });
          for (let i = 0; i < attachments.length; i++) {
            const file = appendFile(attachments[i])
            services.taskServices.addAttachment(file, task.id).then(res => {
              const attachment = res?.data;
              attachment &&
              setMilestones(prevMilestones => {
                return prevMilestones.map(milestone => {
                  if (milestone.id === milestoneId) {
                    return {
                      ...milestone,
                      tasks: {
                        ...milestone.tasks, additional_tasks: milestone.tasks.additional_tasks.map(t => {
                          if (task.id === t.id) {
                            return {
                              ...t,
                              attachments: [...t.attachments, attachment],
                            }
                          }
                          return t;
                        })
                      }
                    }
                  }
                  return milestone;
                })
              })
            });
          }
        }
      })
      .catch(err => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      })
  }

  const editHandler = (milestoneId, taskId, {attachments, ...spread}) => {
    services.taskServices
      .editTask(milestoneId, taskId, {...spread})
      .then(res => {
        const task = res?.data
        setAlert({
          message: `"${task.name}" task successfully changed.`
        });
        if (task?.id && !{...spread}?.stage_id) {
          for (let i = 0; i < attachments.length; i++) {
            const file = appendFile(attachments[i])
            services.taskServices.addAttachment(file, task.id).then(() => {
            });
          }
        }
        loadMilestones();
      })
      .catch(err => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      })
  }

  const deleteHandler = (milestoneId, taskId) => {
    services.taskServices
      .deleteTask(milestoneId, taskId)
      .then(() => {
        loadMilestones();
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Something went wrong. Please, try again."
        })
      })
  }

  const handleDatesChange = data => {
    const result = {};
    Object.keys(data).map(e => {
      result[e] = {
        start_date: formatDate(new Date(data[e].start_date)),
        end_date: formatDate(new Date(data[e].end_date)),
      };
    });
    services.milestonesServices
      .changeMilestonesDuration(params.id, {milestones: result})
      .then(res => {
        setMilestones(
          res?.data?.milestones
        );
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Something went wrong. Please, try again."
        });
      });
  };

  const deleteApprovalStep = (milestoneId, id) => {
    services.approvalServices.deleteApproval(milestoneId, id).then(res => {
      if (res.status < 300) loadMilestones();
    }).catch((err) => {
      setAlert({
        message: err.response?.data.error ||
          "Something went wrong. Please, try again.",
        type: "error"
      });
    });
  }

  const sendAnswer = (approvalId, approverId, {status, note, attachments}) => {
    services.approvalServices.sendAnswer(approvalId, approverId, {status}).then(res => {
      if (res.data) {
        services.noteServices.createNote(res.data.id, {
          description: note,
        }).then(res => {
          Promise.all([...attachments.map(attachment => {
            const file = appendFile(attachment);
            services.taskServices.addAttachment(file, res.data.id).then(res => res.data);
          })]).finally(() => delay(1000).then(() => loadMilestones()));
        }).catch((err) => {
          setAlert({
            message: err.response?.data.error ||
              "Something went wrong. Please, try again.",
            type: "error"
          });
        });
      }
    }).catch((err) => {
      setAlert({
        message: err.response?.data.error ||
          "Something went wrong. Please, try again.",
        type: "error"
      });
    });
  }

  const sendRequest = (milestoneId, approvalId) => {
    services.approvalServices.sendRequest(milestoneId, approvalId).then(res => {
      if (res.data) loadMilestones();
    }).catch((err) => {
      setAlert({
        message: err.response?.data.error ||
          "Something went wrong. Please, try again.",
        type: "error"
      });
    });
  };

  return (
    <Fragment>
      <DateChangerWidget
        milestones={milestones}
        onSubmit={handleDatesChange}
        owner={project.owner?.id}
        projectStartDate={project?.start_date}
        projectEndDate={project?.end_date}
        projectName={project?.name}
        projectTypeId={project?.project_type?.id}
        onCreateTask={(milestoneId, values) => addHandler(milestoneId, values)}
        onEditTask={(milestoneId, taskId, values) => editHandler(milestoneId, taskId, values)}
        onDeleteTask={(milestoneId, taskId) => deleteHandler(milestoneId, taskId)}
        deleteApprovalStep={(milestoneId, id) => deleteApprovalStep(milestoneId, id)}
        sendAnswer={(approvalId, approverId, values) => sendAnswer(approvalId, approverId, values)}
        sendRequest={(milestoneId, approvalId) => sendRequest(milestoneId, approvalId)}
      />
    </Fragment>
  );
};

export default MilestonesContainer;

MilestonesContainer.propTypes = {
  project: propTypes.any,
};
