import React, {Fragment, useEffect, useReducer} from "react";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import Popup from "components/popups/Popup/Popup";
import ProjectFinancialDetailsForm from "components/forms/ProjectFinancialDetailsForm/ProjectFinancialDetailsForm";
import {useHistory, useParams} from "react-router-dom";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import ProjectFinancialDetailsTable from "components/tables/ProjectFinancialDetailsTable/ProjectFinancialDetailsTable";
import CommentContainer from "components/elements/Comment/CommentContainer";
import {initialState, reducer} from "./helpers/reducer";
import {
  setDetails,
  addDetail,
  editDetail,
  deleteDetail,
  changeComment,
  setCurrentDetail,
  setPopup,
  resetAlert,
  resetError,
} from "./helpers/actions";
import {popupTypes} from "./helpers/types";
import FinancialByYear from "../FinancialByYear/FinancialByYear";
import TableLoading from "components/loadingStates/Table/Table";
import PropTypes from "prop-types";

const FinancialDetails = ({ownerId, comment, endDate, project}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    setDetails(dispatch, params.id);
  }, [params.id]);
  return (
    <Fragment>
      {state.loading && <TableLoading length={6}/>}
      {!state.loading && state.details.length > 0 && (
        <ProjectFinancialDetailsTable
          data={state.details}
          onAdd={() => {
            dispatch(resetError());
            dispatch(setPopup(popupTypes.add));
          }}
          onEdit={({id}) => {
            dispatch(resetError());
            dispatch(setCurrentDetail(id));
            dispatch(setPopup(popupTypes.edit));
          }}
          onDelete={(id) => {
            dispatch(setCurrentDetail(id));
            dispatch(setPopup(popupTypes.delete));
          }}
          havePermissions={state.permissions}
        />
      )}

      {!state.loading && !state.details.length && (
        <EmptyBlock
          subtitle={
            state.permissions.update
              ? "You don’t have financial details yet. You can add a new data"
              : "You don’t have financial details yet"
          }
          buttonTitle="New detail"
          onClick={() => dispatch(setPopup(popupTypes.add))}
          withBtn={state.permissions.update}
        />
      )}

      {!state.loading && state.details_by_year && (
        <FinancialByYear
          data={state.details_by_year}
          title="Financial details by year"
          defaultCurrency={state.default_currency}
        />
      )}

      <CommentContainer
        owner={ownerId}
        comment={comment}
        onAddComment={(value) => changeComment(params.id, value)}
      />

      {state.popup === popupTypes.add && (
        <Popup
          title="New financial detail"
          text="Please, complete the following fields to create new financial details"
          onClose={() => dispatch(setPopup(null))}
        >
          <ProjectFinancialDetailsForm
            status={project.stage.status}
            actualEndDate={project.actual_end_date}
            targetEndDate={project.end_date}
            projectTargetSpend={project.target_spend}
            projectTargetSpendCurrency={project.target_spend_currency}
            defaultCurrency={state.default_currency}
            onClose={() => dispatch(setPopup(null))}
            onSubmit={(values) => addDetail(dispatch, params.id, values)}
            error={state.error}
            endDate={endDate}
          />
        </Popup>
      )}

      {state.popup === popupTypes.edit && (
        <Popup
          title="Edit financial details"
          onClose={() => dispatch(setPopup(null))}
        >
          <ProjectFinancialDetailsForm
            onClose={() => dispatch(setPopup(null))}
            onSubmit={(values) =>
              editDetail(dispatch, params.id, state.current_detail, values)
            }
            defaultData={
              state.details.filter((e) => e.id === state.current_detail)[0]
            }
            error={state.error}
            endDate={endDate}
          />
        </Popup>
      )}

      {state.popup === popupTypes.delete && (
        <Popup
          title="Delete financial detail"
          text="Are you sure you want to delete this financial detail?"
          onClose={() => dispatch(setPopup(null))}
          onClick={() =>
            deleteDetail(dispatch, params.id, state.current_detail)
          }
          isDelete
          dialog
        />
      )}

      {Boolean(state.alert.message) && (
        <NotificationBox
          message={state.alert.message}
          type={state.alert.type}
          onClose={() => dispatch(resetAlert())}
        />
      )}
    </Fragment>
  );
};
export default FinancialDetails;

FinancialDetails.propTypes = {
  ownerId: PropTypes.string,
  comment: PropTypes.string,
  endDate: PropTypes.string,
  project: PropTypes.any,
};