import React, {useState} from 'react';
import s from "./DetailPanel.module.scss";
import CustomNotification from "../../../../elements/QuestionNotification/CustomNotification";
import {sentences} from "../helpers/sentences";
import {ReactComponent as ArrowLeft} from "assets/icons/arrow-left.svg";
import Popup from "../../../../popups/Popup/Popup";
import EditTriageDetailsForm from "../../../../forms/EditTriageDetailsForm/EditTriageDetailsForm";
import SimpleDetails from "../../../../elements/SimpleDetails/SimpleDetails";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {userRoles} from "../../../../../helpers/userPermissions/userRoles";

const DetailsPanel = ({onEdit, data}) => {
  const [isOpenEditPopUp, setOpenEditPopUp] = useState(false);
  const prepareHierarchies = data.triage_hierarchies;
  const {t} = useTranslation();
  const user = useSelector(state => state.user);

  const initialValues = {
    triage_admin: data.triage_admin ? {
      label: data.triage_admin.name,
      value: data.triage_admin.id
    } : null,
    activeHierarchyList: prepareHierarchies.active,
    ignoreHierarchyList: prepareHierarchies.inactive,
  }

  return (
    <>
      <SimpleDetails
        onBtnClick={
          user.roles.split(",").includes(userRoles.admin) ?
            () => setOpenEditPopUp(true) : null
        }
      >
        <div className={s.columns}>
          <div className={s.col}>
            <div className={s.title}>
              <span data-translate-key={getTranslateKey("Triage admin")}>
                {t(getTranslateKey("Triage admin"))}
              </span>
              <CustomNotification
                withTranslate
                value={sentences.notifications.triageAdmin}
              />
            </div>
            <p className={s.value}>
              {data.triage_admin?.name ?? "-"}
            </p>
          </div>
          <div className={s.col}>
            <div className={s.title}>
              <span data-translate-key={getTranslateKey("Hierarchy")}>
                {t(getTranslateKey("Hierarchy"))}
              </span>
              <CustomNotification
                withTranslate
                value={sentences.notifications.hierarchy}
              />
            </div>
            <p className={s.value}>
              {prepareHierarchies.active.length ? prepareHierarchies.active.map((item, index) => {
                const itemNme = (
                  <span data-translate-key={getTranslateKey(item.name)}>
                      {t(getTranslateKey(item.name))}
                    </span>
                )
                return index === prepareHierarchies.active.length - 1 ?
                  <>{itemNme}</> :
                  <>
                    {itemNme}
                    <ArrowLeft/>
                  </>
              }) : "-"}
            </p>
          </div>
        </div>
      </SimpleDetails>
      {isOpenEditPopUp && (
        <Popup
          title="Edit triage details"
          onClose={() => setOpenEditPopUp(false)}
        >
          <EditTriageDetailsForm
            onClose={() => setOpenEditPopUp(false)}
            initialValues={initialValues}
            submit={values => {
              onEdit(values);
              setOpenEditPopUp(false);
            }}
          />
        </Popup>
      )}
    </>
  );
};

export default DetailsPanel;