import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { object, string } from "yup";

import s from "./CommentForm.module.scss";
import Textarea from "components/elements/Textarea/Textarea";
import Button from "components/elements/Button/Button";
import useOutsideClicker from "hooks/useOutsideClicker";

const COMMENT_LENGTH = 300;

const CommentForm = ({
  comment,
  addHandler,
  onClose,
  autoFocus,
  submitOnBlur,
  onErrors,
}) => {
  const [show, setShow] = useState(autoFocus);

  const formRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      comment,
    },
    validationSchema: object({
      comment: string().max(
        COMMENT_LENGTH,
        `Max length is ${COMMENT_LENGTH} characters`
      ),
    }),
    onSubmit: (values) => {
      addHandler({ results_comment: values.comment }).then(() => {
        setShow(false);
      });
    },
  });

  const {
    handleSubmit,
    touched,
    values,
    errors,
    setFieldValue,
    setTouched,
    ...rest
  } = formik;

  useEffect(() => {
    onErrors && onErrors(!!Object.keys(errors).length);
  }, [errors]);

  useOutsideClicker(formRef, () => {
    if (submitOnBlur) {
      show && handleSubmit();
    } else {
      setShow(false);
      onClose();
    }
  });

  return (
    <form onSubmit={handleSubmit} autoComplete="off" ref={formRef}>
      <div onClick={() => setShow(true)}>
        <Textarea
          className={s.textarea}
          placeholder="Write a comment"
          value={values.comment}
          onChange={(e) => {
            setTouched({ comment: true });
            setFieldValue("comment", e.target.value);
          }}
          label=""
          error={errors.comment && touched.comment && errors.comment}
          extraProps={{ autoFocus: show }}
          count={
            show
              ? { current: values.comment.length, max: COMMENT_LENGTH }
              : null
          }
          {...rest}
        />
      </div>
      {!submitOnBlur && show && (
        <>
          <div className={s.container}>
            <Button
              styled="secondary"
              type="reset"
              className={s.btn}
              onClick={() => {
                onClose();
                setShow(false);
                setFieldValue("comment", comment);
              }}
            >
              Cancel
            </Button>
            <Button styled="primary" type="submit" className={s.btn}>
              Save
            </Button>
          </div>
        </>
      )}
    </form>
  );
};
CommentForm.propTypes = {
  autoFocus: PropTypes.bool,
  submitOnBlur: PropTypes.bool,
};
CommentForm.defaultProps = {
  autoFocus: false,
  submitOnBlur: false,
};
export default CommentForm;
