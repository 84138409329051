import React, {Fragment} from "react";
import s from "./Milestone.module.scss";
import classNames from "classnames";
import {formatDate, fromDate} from "helpers/dateFormat";
import PropTypes from "prop-types";
import {getTranslateKey} from "../../../../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Milestone = (
  {
    width,
    windowWidth,
    data,
    windowData,
    onMouseDown,
    className,
    mode,
    name,
    index,
    projectDays,
    afterLastWindowWidth,
    afterLastWindowData,
    milestonesLength,
    backgroundColor,
  }
) => {
  const isSmallEmptyBlock = windowWidth <= 48;
  const {t} = useTranslation();
  const getTimeSpend = spend => {
    let result = {
      value: spend,
      format: "days"
    };
    if (spend >= 7 && projectDays >= 1000) {
      result = {
        value: Math.floor(spend / 7),
        format: "weeks"
      };
    }
    return result;
  };

  const windowSpend = getTimeSpend(windowData.anticipated_time_spend);

  const afterLastWindowSpend = getTimeSpend(
    afterLastWindowData.anticipated_time_spend
  );

  const milestoneSpend = getTimeSpend(data.anticipated_time_spend);

  return (
    <Fragment>
      {windowWidth > 0 && (
        <div
          className={classNames(s.emptyBlock, {[s.small]: isSmallEmptyBlock})}
          style={{width: `${windowWidth}px`, maxWidth: `${windowWidth}px`}}
        >
          <div className={s.content}>
            <span className={s.label} data-translate-key={getTranslateKey(`Free ${windowSpend.format}`)}>
              {t(getTranslateKey(`Free ${windowSpend.format}`))}
            </span>
            <span className={s.daysCount}>
							{windowData.anticipated_time_spend ? windowSpend.value : 0}
						</span>
          </div>
          {isSmallEmptyBlock && <span className={s.threeDots}>...</span>}
        </div>
      )}
      <div
        className={classNames(
          s.milestone,
          {
            [s.small]: width <= 60,
            [s.view]: mode === "view",
            [s.first]: !index,
            [s.last]: index === milestonesLength - 1,
            [s.withEmpty]: windowWidth
          },
          className
        )}
        style={{
          width: `${width}px`,
          background: backgroundColor,
        }}
      >
        {mode === "edit" && (
          <>
            <div
              className={classNames(s.divider, s.left)}
              onMouseDown={e => onMouseDown(e, "left")}
            >
							<span className={s.tooltip}>
								{fromDate(formatDate(new Date(data?.start_date)))}
							</span>
            </div>
            <div
              className={classNames(s.divider, s.right)}
              onMouseDown={e => onMouseDown(e, "right")}
            >
							<span className={s.tooltip}>
								{fromDate(formatDate(new Date(data?.end_date)))}
							</span>
            </div>
          </>
        )}
        <div className={s.content}>
          <span className={s.name}>{name}</span>
          <div className={s.timeSpend}>
            <span className={s.count}>{milestoneSpend.value}</span>
            <span data-traanslate-key={getTranslateKey(milestoneSpend.format)}>
              {t(getTranslateKey(milestoneSpend.format))}
            </span>
          </div>
        </div>
        {width <= 60 && <span className={s.threeDots}>...</span>}
      </div>
      {afterLastWindowWidth > 0 && (
        <div
          className={classNames(s.emptyBlock, {
            [s.small]: afterLastWindowWidth <= 48
          })}
          style={{
            width: `${afterLastWindowWidth}px`,
            maxWidth: `${afterLastWindowWidth}px`
          }}
        >
          <div className={s.content}>
						<span className={s.label} data-translate-key={getTranslateKey(`Free ${afterLastWindowSpend.format}`)}>
              {t(getTranslateKey(`Free ${afterLastWindowSpend.format}`))}
            </span>
            <span className={s.daysCount}>
							{afterLastWindowData.anticipated_time_spend
                ? afterLastWindowSpend.value
                : 0}
						</span>
          </div>
          {afterLastWindowWidth <= 48 && (
            <span className={s.threeDots}>...</span>
          )}
        </div>
      )}
    </Fragment>
  );
};
export default Milestone;

Milestone.propTypes = {
  width: PropTypes.number,
  windowWidth: PropTypes.number,
  data: PropTypes.any,
  windowData: PropTypes.any,
  onMouseDown: PropTypes.func,
  className: PropTypes.string,
  mode: PropTypes.string,
  name: PropTypes.string,
  index: PropTypes.any,
  projectDays: PropTypes.any,
  afterLastWindowWidth: PropTypes.any,
  afterLastWindowData: PropTypes.any,
  milestonesLength: PropTypes.number,
  backgroundColor: PropTypes.string,
};