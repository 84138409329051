export const SET_ALERT = "SET_ALERT";
export const SET_FILTERS = "SET_FILTERS";
export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_REQUEST_DATA = "SET_REQUEST_DATA";
export const DELETE_CONTRACT = "DELETE_CONTRACT";

export const initialState = {
  contracts: [],
  totalContractsCount: 0,
  filters: [],
  activeFilters: {suppliers: [], expires_in: []},
  alert: {message: "", type: ""},
  currentPage: 1,
  loading: true,
};

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_REQUEST_DATA: {
      return {
        ...state,
        contracts: payload.contracts,
        loading: payload.loading,
        totalContractsCount: payload.contractsTotalCount,
      };
    }

    case SET_ALERT: {
      return {
        ...state,
        alert: {
          message: payload.message,
          type: payload.type,
        },
      };
    }

    case SET_FILTERS: {
      return {
        ...state,
        filters: payload.filters,
      };
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload.page || 1,
      };
    }

    case SET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: payload.activeFilters,
      };
    }
    case DELETE_CONTRACT: {
      return {
        ...state,
        contracts: state.contracts.filter(c => c.id !== payload),
      };
    }
    default:
      throw Error("Unknown action type");
  }
};
