import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import DashboardContainer from "components/containers/DashboardContainer/DashboardContainer";

const DashboardPage = () => {
  return (
    <DefaultLayout>
      <DashboardContainer/>
    </DefaultLayout>
  );
};

export default DashboardPage;
