import React, {useEffect, useState} from "react";
import MainTable from "../MainTable/MainTable";
import ActualSavingsPopup from "../../popups/ActualSavingsPopup/ActualSavingsPopup";
import {useHistory, useParams} from "react-router-dom";
import s from "./ProjectActualSavingsTable.module.scss";
import {ReactComponent as ActualSavingsPencil} from "../../../assets/icons/pencilChange.svg";
import PropTypes from "prop-types";
import EmptyBlock from "../../elements/EmptyBlock/EmptyBlock";
import {formatCurrency} from "../../../helpers/currencyFormat";
import {currencyData} from "../../../helpers/helpData";
import {numberFormatAbbreviated} from "../../containers/CategoriesContainer/helpers";
import AddNewActualSavingsForm from "../../forms/AddNewActualSavingsForm/AddNewActualSavingsForm";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import services from "../../../services";
import {formatData} from "../../containers/ProjectContainer/components/FinancialByYear/helpers/formatData";

const ProjectActualSavingsTable = (
  {
    onDateClick,
    startDate,
    decreaseMonth,
    increaseMonth,
    actualSavings,
    editActualSavingsGroup,
    newActualSavingsGroup,
    className,
    title,
    rowClassName,
    owner,
    savingsGrBenefits
  }
) => {
  const params = useParams();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showNewActual, setShowNewActual] = useState(false);
  const {roles, id} = useSelector(state => state.user);
  const [savingsGr, setSavingsGroup] = useState([]);
  const formattedData = formatData(savingsGrBenefits).map(e => e.savings_type[0]);

  const isUserGroup = roles.includes(userRoles.procurement_admin) ||
    roles.includes(userRoles.admin) ||
    roles.includes(userRoles.cpo) ||
    id.includes(owner);


  useEffect(() => {
    services.projectServices.getProjectActualSavingsGroup()
      .then(res => {
        if (res.data) {
          setSavingsGroup(res.data.saving_groups);
        }
      });
  }, []);

  return (
    <>
      {actualSavings.length > 0 ?
        <MainTable
          className={className}
          rowClassName={rowClassName}
          data={actualSavings}
          totalName={title}
          startDate={startDate}
          onDateClick={onDateClick}
          calendar
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          calendarIcon={false}
          dataTypes={[
            {
              title: "Savings group",
              value: ({group_name}) => group_name,
            },
            {
              title: "Committed",
              value: ({committed, committed_currency}) =>
                formatCurrency(
                  currencyData.filter(e => e.label === committed_currency)[0]
                    ?.value,
                  numberFormatAbbreviated(committed)
                ),
            },
            {
              title: "Actual",
              value: ({actual, actual_currency}) =>
                formatCurrency(
                  currencyData.filter(e => e.label === actual_currency)[0]
                    ?.value,
                  numberFormatAbbreviated(actual)
                ),
            },
            {
              title: "Forecasted",
              value: ({forecasted, forecasted_currency}) =>
                formatCurrency(
                  currencyData.filter(e => e.label === forecasted_currency)[0]
                    ?.value,
                  numberFormatAbbreviated(forecasted)
                ),
            },
            isUserGroup ? {
              title: "",
              position: "end",
              value: () => (
                <div className={s.ActualSavingsIcons}>
                  <ActualSavingsPencil
                    onClick={() => {
                      setShow(true);
                    }}
                  />
                </div>
              ),
            } : "",
          ]}
          buttons={isUserGroup && [
            {
              styled: "secondary",
              type: "submit",
              name: "View details",
              onClick: () => history.push(`/projects/${params.id}/actual_savings`)
            },
            {
              name: "New savings",
              onClick: () => {
                setShowNewActual(true);
              }
            }
          ]}
        /> :
        <>
          <MainTable
            className={className}
            rowClassName={rowClassName}
            totalName={title}
            startDate={startDate}
            onDateClick={onDateClick}
            calendar
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            calendarIcon={false}
            buttons={isUserGroup && [
              {
                styled: "secondary",
                type: "submit",
                name: "View details",
                onClick: () => history.push(`/projects/${params.id}/actual_savings`)
              },
              {
                name: "New savings",
                onClick: () => {
                  setShowNewActual(true);
                }
              }
            ]}
          />
          <EmptyBlock
            subtitle={"You don't have any data for this month yet."}
            withBtn={false}
          />
        </>
      }
      {show && (
        <ActualSavingsPopup
          title={`Edit expense reduction`}
          data={actualSavings[0]}
          setShow={setShow}
          onSubmitEdit={editActualSavingsGroup}
        />
      )}
      {showNewActual && (
        <AddNewActualSavingsForm
          title="New actual savings"
          text="Please, complete the following fields to create new actual savings"
          setShowNewActual={setShowNewActual}
          onSubmitEdit={newActualSavingsGroup}
          savingsGr={savingsGr.filter(e => formattedData.includes(e.name))}
        />
      )}
    </>

  );
};

export default ProjectActualSavingsTable;

ProjectActualSavingsTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  rowClassName: PropTypes.string,
};