import React from "react";
import s from "./EmptyState.module.scss";
import Button from "components/elements/Button/Button";
import Can from "components/elements/Can/Can";
import {userRoles} from "helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {paths} from "../../../../../../../routes/paths";
import {getTranslateKey} from "../../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const EmptyState = () => {
  const user = useSelector(state => state.user);
  const translateKey = getTranslateKey("You don’t have project here. You can add a new one.");
  const {t} = useTranslation();
  const history = useHistory();

  return (
    <div className={s.emptyState}>
      <span className={s.title} data-translate-key={translateKey}>
        {t(translateKey)}
      </span>
      <Can
        permission={!user.roles.includes(userRoles.business_user)}
        yes={() => (
          <Button className={s.btn} onClick={() => history.push(
            paths.createProject,
            {from: "advanced-created"}
          )} styled='addElement'>
            New Project
          </Button>
        )}
      />
    </div>
  );
};

export default EmptyState;
