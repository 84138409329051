export const userRoles = {
  guest: "guest",
  admin: "admin",
  //procurement
  cpo: "cpo",
  director: "director",
  procurement_manager: "procurement_manager",
  procurement_category_owner: "procurement_category_owner",
  procurement_admin: "procurement_admin",
  //business
  business_user: "business_user",
  category_business_owner: "category_business_owner",
  //support
  finance_user: "finance_user",
  risk_user: "risk_user",
  audit_user: "audit_user",
  triage_user: "triage_user",
  //supplier_admin
  supplier_admin: "supplier_admin",
  supplier_user: "supplier_user"
};

export const userTypes = {
  procurement: "procurement",
  business: "business",
  support: "support",
  adminTypes: "admin"
};
