import React from "react";
import s from "./DetailItem.module.scss";
import classNames from "classnames";
import {useTranslate} from "../../../../../../../hooks/useTranslate";

const DetailItem = ({ label, value, className }) => {
  const [translateKey, translateValue] = useTranslate(label);
  return (
    <li className={classNames(s.detailItem, className)}>
      <span className={s.label} data-translate-key={translateKey}>
        {translateValue}
      </span>
      {value ? (
        <span className={s.value}>{value}</span>
      ) : (
        <span className={s.emptyValue}>-</span>
      )}
    </li>
  );
};

export default DetailItem;
