import axios from "./axios";

export default {

  getRequestTypes: async () => {
    try {
      return await axios.get("/request_types");
    } catch (error) {
      throw error;
    }
  },

  getRequestType: async id => {
    try {
      return await axios.get(`/requests/${id}`);
    } catch (error) {
      throw error;
    }
  },

  getCategoryOverview: async id => {
    try {
      return await axios.get(`/requests/${id}/category_overview`);
    } catch (error) {
      throw error;
    }
  },

  getGanttChart: async id => {
    try {
      return await axios.get(`/requests/${id}/gantt_chart`);
    } catch (error) {
      throw error;
    }
  },

  getPreferredSuppliers: async id => {
    try {
      return await axios.get(`/requests/${id}/preferred_suppliers`);
    } catch (error) {
      throw error;
    }
  },

  getCategoryStakeholders: async id => {
    try {
      return await axios.get(`/requests/${id}/category_stakeholders`);
    } catch (error) {
      throw error;
    }
  },

  getRequestUsers: async (id, params) => {
    try {
      return await axios.get(`/requests/${id}/users`, {params});
    } catch (error) {
      throw error;
    }
  },

  assignRequest: async (id, params) => {
    try {
      return await axios.post(`/requests/${id}/assign`, params);
    } catch (error) {
      throw error;
    }
  },

  rejectRequest: async (id, params) => {
    try {
      return await axios.post(`/requests/${id}/reject`, params);
    } catch (error) {
      throw error;
    }
  },

  completeRequest: async id => {
    try {
      return await axios.post(`/requests/${id}/complete`);
    } catch (error) {
      throw error;
    }
  },

  addRequest: async params => {
    try {
      return await axios.post("/requests", params);
    } catch (error) {
      throw error;
    }
  },
  updateRequest: async (id, params) => {
    try {
      return await axios.put(`/requests/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  getRequestTypesAdmin: async params => {
    try {
      return await axios.get("/admin/request_types", {params});
    } catch (error) {
      throw error;
    }
  },

  reorderFieldsInStep: async (stepId, stepFieldId, ids) => {
    try {
      return await axios.put(`/admin/steps/${stepId}/step_fields/replace`, {
        step_field_ids: ids
      });
    } catch (error) {
      throw error;
    }
  },

  reorderFieldBetweenSteps: async (stepId, stepFieldId, newStepId) => {
    try {
      return await axios.put(`/admin/steps/${stepId}/step_fields/${stepFieldId}/`, {
        replacement_step_id: newStepId
      });
    } catch (error) {
      throw error;
    }
  },

  addRequestTypes: async params => {
    try {
      return await axios.post("/admin/request_types", params);
    } catch (error) {
      throw error;
    }
  },

  editRequestTypes: async (id, params) => {
    try {
      return await axios.put(`/admin/request_types/${id}`, params);
    } catch (error) {
      throw error;
    }
  },

  deleteRequestTypes: async id => {
    try {
      return await axios.delete(`/admin/request_types/${id}`,);
    } catch (error) {
      throw error;
    }
  },

  getRequestTypesDetails: async id => {
    try {
      return await axios.get(`/admin/request_types/${id}`);
    } catch (error) {
      throw error;
    }
  },

  getFieldsByStep: async id => {
    try {
      return await axios.get(`/admin/steps/${id}/step_fields`);
    } catch (error) {
      throw error;
    }
  },

  getRequestTypesSteps: async id => {
    try {
      return await axios.get(`/admin/request_types/${id}/steps`);
    } catch (error) {
      throw error;
    }
  },

  addRequestTypesSteps: async (typeId, params) => {
    try {
      return await axios.post(`/admin/request_types/${typeId}/steps`, params);
    } catch (error) {
      throw error;
    }
  },

  editRequestTypesSteps: async (typeId, stepId, params) => {
    try {
      return await axios.put(`/admin/request_types/${typeId}/steps/${stepId}`, params);
    } catch (error) {
      throw error;
    }
  },

  deleteRequestTypesSteps: async (typeId, id) => {
    try {
      return await axios.delete(`/admin/request_types/${typeId}/steps/${id}`);
    } catch (error) {
      throw error;
    }
  },

  getStepFields: async id => {
    try {
      return await axios.get(`/admin/steps/${id}/step_fields`);
    } catch (error) {
      throw error;
    }
  },

  addStepField: async (stepId, params) => {
    try {
      return await axios.post(`/admin/steps/${stepId}/step_fields`, params);
    } catch (error) {
      throw error;
    }
  },

  editStepField: async (stepId, fieldId, params) => {
    try {
      return await axios.put(`/admin/steps/${stepId}/step_fields/${fieldId}`, params);
    } catch (error) {
      throw error;
    }
  },

  deleteStepField: async (stepId, fieldId) => {
    try {
      return await axios.delete(`/admin/steps/${stepId}/step_fields/${fieldId}`);
    } catch (error) {
      throw error;
    }
  },

  getStepFieldDetails: async (stepId, fieldId) => {
    try {
      return await axios.get(`/admin/steps/${stepId}/step_fields/${fieldId}`);
    } catch (error) {
      throw error;
    }
  },

  editStepFieldDetails: async (stepId, fieldId, params) => {
    try {
      return await axios.put(`/admin/steps/${stepId}/step_fields/${fieldId}`, params);
    } catch (error) {
      throw error;
    }
  },

};
