import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import querystring from "query-string";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import UserProjectsTable from "components/tables/UserProjectsTable/UserProjectsTable";
import services from "services/index";
import TableLoading from "components/loadingStates/Table/Table";
import {useTranslation} from "react-i18next";

const UserProjects = () => {
  const params = useParams();
  const history = useHistory();
  const [projects, setProjects] = useState({
    projects: [],
    projects_count: 0,
  });
  const [loading, setLoading] = useState(true);
  const query = querystring.parse(history.location.search) || {};
  const {t} = useTranslation();

  useEffect(() => {
    services.userServices.getUserProjects(params.id, query).then((res) => {
      setProjects(res.data);
      setLoading(false);
    });
  }, [params.id, history.location]);

  const pageChangeHandler = (page) => {
    history.push({ search: querystring.stringify({ ...query, page }) });
  };

  const sortHandler = (sort) => {
    history.push({
      search: querystring.stringify({ ...query, ...sort }),
    });
  };

  return (
    <>
      {loading ? (
        <TableLoading length={3} />
      ) : (
        <>
          {projects.projects.length ? (
            <UserProjectsTable
              data={projects}
              perPage={20}
              history={history}
              onPageChange={pageChangeHandler}
              onSort={sortHandler}
              currentPage={1}
            />
          ) : (
            <EmptyBlock withBtn={false}>
              <div data-translate-key="this-user-doesn't-have-projects-yet.">{t("this-user-doesn't-have-projects-yet.")}</div>
            </EmptyBlock>
          )}
        </>
      )}
    </>
  );
};

export default UserProjects;
