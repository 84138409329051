import React from "react";
import querystring from "query-string";
import {useHistory} from "react-router-dom";
import MainTable from "components/tables/MainTable/MainTable";
import {fromDate} from "helpers/dateFormat";
import {formatUserRoles} from "helpers/userPermissions/formatUserRoles";
import s from "./UsersTable.module.scss";
import PropTypes from "prop-types";
import UsersPopupMessage from "./UsersPopupMessage";

const UsersTable = (
  {
    state,
    perPage,
    isAdmin = false,
    onEdit,
    onAdd,
    onDelete,
    onResetPass,
    dragIdLabel
  }
) => {
  const history = useHistory();

  const onPageChange = (page) => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };
  const onSort = (type) => {
    const query = querystring.parse(history.location.search);
    history.push({
      search: querystring.stringify({...query, page: 1, ...type}),
    });
  };
  const checkForMonth = item => {
    return item.status === "inactive";
  };

  return (
    <MainTable
      stickyHeader={true}
      className={isAdmin ? s.adminTable : s.table}
      data={state.users}
      rowClassName={s.rowClass}
      dragIdLabel={dragIdLabel}
      ariaLabel="Users"
      perPage={perPage}
      totalCount={state.totalUsersCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={state.currentPage}
      useMaxHeightLarge={true}
      uniqueRowStyle={item => checkForMonth(item) && s.status}
      dataTypes={[
        {
          title: "Users",
          value: (item) =>
            <div className={s.completed}>
              {item && <UsersPopupMessage item={item}/>}
            </div>,
          sortable: true,
          onClick: (id) => history.push(`/users/${id}`),
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          sticky: true

        },
        {
          title: "Email",
          value: ({email}) => email,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "email", direction});
          },
        },
        {
          title: "Division",
          value: ({division}) => division.name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "division", direction});
          },
        },
        {
          title: "Department",
          value: ({department}) => department.name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "department", direction});
          },
        },
        {
          title: "Role",
          value: ({roles}) =>
            roles.length > 0
              ? formatUserRoles(roles)
                .map((e) => e.label)
                .join(", ")
              : "-",
          visible: isAdmin,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "role", direction});
          },
          position: "center"
        },
        {
          title: "Last log in",
          value: ({last_sign_in_at}) =>
            last_sign_in_at ? fromDate(last_sign_in_at) : "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "last_sign_in_at", direction});
          },
        },
      ]}
      options={(item) => {
        return [
          onEdit && {
            title: "Edit",
            onClick: () => onEdit(item),
          },
          onDelete && {
            title: "Delete",
            onClick: () => onDelete(item),
          },
          onResetPass && {
            title: "Reset password",
            onClick: () => onResetPass(item),
          },
        ];
      }}
      button={
        onAdd && {
          name: "New user",
          onClick: () => onAdd(),
        }
      }
    />
  );
};
export default UsersTable;

UsersTable.propTypes = {
  state: PropTypes.any,
  perPage: PropTypes.number,
  isAdmin: PropTypes.bool,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onResetPass: PropTypes.func,
  dragIdLabel: PropTypes.string,
};