import React from 'react';
import style from "./FormHeader.module.scss";
import Button from "../../../../../elements/Button/Button";
import {changeMode} from "../../../helpers/actions";

const FormHeader = ({dispatch}) => {
  return (
    <div className={style.header}>
      <p>Edit Field</p>
      <div className={style.btnGroup}>
        <Button styled='secondary' onClick={() => changeMode(dispatch, false)}>
          Cancel
        </Button>
        <Button styled='primary' type="submit">
          Save
        </Button>
      </div>
    </div>
  );
};

export default FormHeader;