import React from "react";
import MainTable from "../../../../../../tables/MainTable/MainTable";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import s from "./TaskStatusesTable.module.scss";
import {ReactComponent as Reorder} from "../../../../../../../assets/icons/reorder.svg";
import {dateFormatterWithoutTimezone} from "../../../../../../../helpers/dateFormat";
import {backgroundTypes} from "../../../../../../../helpers/helpData";

const TaskStatusesTable = (
  {
    taskStatuses,
    onEdit,
    onDelete,
    onCreate,
  }
) => {

  return (
    <MainTable
      totalName="Task statuses"
      data={taskStatuses.sort((a, b) => a.position - b.position)}
      dragIdLabel="status"
      getDragId={item => item.id}
      dragWithSort={true}
      dataTypes={[
        {
          title: "Status",
          value: ({name}) => (
            <div className={s.name}>
              <Reorder/> <span>{name || "-"}</span>
            </div>
          ),
        },
        {
          title: "Color",
          value: ({color}) => <div className={s.colorStatus} style={{background: backgroundTypes[color]}}/>,
        },
        {
          title: "Updated",
          value: ({updated_at}) => dateFormatterWithoutTimezone(updated_at.replace("Z", "")),
        },
      ]}
      options={item => [
        {
          title: "Edit",
          onClick: () => onEdit(item),
        },
        {
          title: "Delete",
          onClick: () => onDelete(item),
        },
      ]}
      button={{
        name: "New status",
        onClick: () => onCreate()
      }}
    />
  );
};

export default TaskStatusesTable;
