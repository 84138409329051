import React from "react";
import s from "../DatePicker/DatePicker.module.scss";
import {useTranslate} from "../../../hooks/useTranslate";
import PropTypes from "prop-types";

const ErrorMessage = ({error}) => {
  const [translateKeyError, translateValueError] = useTranslate(error);

  return (
    <span
      className={s.errorMessage}
      data-translate-key={translateKeyError}
    >
      {translateValueError}
    </span>
  );
};

export default ErrorMessage;

ErrorMessage.propTypes = {
  error: PropTypes.string
};