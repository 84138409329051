import React from 'react';
import styles from "./UserCard.module.scss";
import {ReactComponent as UnknownUser} from "../../../assets/icons/unknown-user-icon.svg";
import gradient from "random-gradient";
import {trimRest} from "../../../helpers/stringHelper";

const UserCard = ({name, noUserText}) => {
  const nameParts = name && name.split(" ").filter(item => item);

  const getAvatar = () => {
    if (nameParts) return (
      <div
        className={styles.gradient}
        style={{
          background: gradient(name)
        }}
      >
        {nameParts.length > 1 ? nameParts[0][0] + nameParts[1][0] : nameParts[0][0]}
      </div>
    );
    return <UnknownUser/>;
  }

  return (
    <div className={styles.userCard}>
      {getAvatar()}
      <span title={name ?? noUserText}>
        {trimRest(24, name ?? noUserText)}
      </span>
    </div>
  );
};

export default UserCard;