import { linesConfig } from "../helpers/defaultConfig";
import { trimCol } from "../helpers/trimCol";

export const drawBeforeYLines = (dataCols) => {
  let beforeVerticalLines = [];

  dataCols.map((col, index) => {
    const trimPrevColDataLength = dataCols[index - 1]
      ? trimCol(dataCols[index - 1]).length
      : 0;
    const isLongerPrevCol = dataCols[index - 1]
      ? col.length > dataCols[index - 1].length
      : false;
    if (isLongerPrevCol && trimPrevColDataLength) {
      beforeVerticalLines.push({
        x1:
          col[0].left -
          linesConfig.horizontalLineWidth -
          linesConfig.horizontalLineMarginX,
        y1: col[0].top + linesConfig.horizontalLineMarginY,
        x2:
          col[col.length - 1].left -
          linesConfig.horizontalLineWidth -
          linesConfig.horizontalLineMarginX,
        y2: col[col.length - 1].top + linesConfig.horizontalLineMarginY,
      });
    }
  });

  return beforeVerticalLines;
};
