import {
  SET_STAKEHOLDERS,
  SET_LOADING,
  ADD_STAKEHOLDER,
  DELETE_STAKEHOLDER,
  CLOSE_POPUP,
  SET_POPUP_NAME,
  SET_SELECTED_STAKEHOLDER,
  SET_ALERT,
} from "./types";

export const initialState = {
  stakeholders: [],
  stakeholders_count: 0,
  permissions: {},
  loading: true,
  popup: null,
  current_stakeholder: null,
  alert: { type: "", message: "" },
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_STAKEHOLDERS:
      return {
        ...state,
        stakeholders: payload.users,
        stakeholders_count: payload.users_count,
        permissions: payload.permissions,
        loading: false,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ADD_STAKEHOLDER:
      return {
        ...state,
        stakeholders: payload.data
          ? [...state.stakeholders, payload.data]
          : state.stakeholders,
        alert: payload.alert,
        stakeholders_count: payload.data
          ? state.stakeholders_count + 1
          : state.stakeholders_count,
      };
    case DELETE_STAKEHOLDER:
      return {
        ...state,
        stakeholders: payload.stakeholder
          ? state.stakeholders.filter((e) => e.id !== payload.stakeholder)
          : state.stakeholders,
        alert: payload.alert,
        popup: null,
        stakeholders_count: payload.stakeholders
          ? state.stakeholders_count - 1
          : state.stakeholders_count,
      };
    case CLOSE_POPUP:
      return {
        ...state,
        popup: null,
      };
    case SET_POPUP_NAME:
      return {
        ...state,
        popup: payload.name,
      };

    case SET_SELECTED_STAKEHOLDER:
      return {
        ...state,
        current_stakeholder: payload.stakeholder,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: payload,
      };
    default:
      return state;
  }
};
