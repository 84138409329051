export const years = [
  {
    name: "2021",
    id: "2021"
  },
  {
    name: "2022",
    id: "2022"
  },
  {
    name: "2023",
    id: "2023"
  },
  {
    name: "2024",
    id: "2024"
  },
  {
    name: "2025",
    id: "2025"
  },
  {
    name: "2026",
    id: "2026"
  }
]
