import React from "react"
import MainTable from "components/tables/MainTable/MainTable"
import { currencyData } from "helpers/helpData"
import { formatCurrency } from "helpers/currencyFormat"
import { supplierStatuses } from "helpers/helpData"
import s from "../FirstLevelCategoriesTable/FirstLevelCategoriesTable.module.scss";

const CategoriesContractsExpirationsTable = ({ data, currency }) => {
  const checkForMonth = item => Object.prototype.hasOwnProperty.call(item, "total");
  return (
    <MainTable
      data={data}
      withXScroll={true}
      uniqueRowStyle={item => checkForMonth(item) &&  s.totalSum}
      dataTypes={[
        {
          title: "Supplier status",
          value: ({ status }) =>
            supplierStatuses.find(itm => itm.value === status)?.label || "-"
        },
        {
          title: "<90 days",
          position: "center",
          value: ({ days_90 }) =>
            days_90.total_value ? (
              <div>
                {days_90.count} /{" "}
                {formatCurrency(
                  currencyData.filter(e => e.label === currency)[0]?.value,
                  days_90.total_value
                )}
              </div>
            ) : (
              "-"
            )
        },
        {
          title: "<180 days",
          position: "center",
          value: ({ days_180 }) =>
            days_180.total_value ? (
              <div>
                {days_180.count} /{" "}
                {formatCurrency(
                  currencyData.filter(e => e.label === currency)[0]?.value,
                  days_180.total_value
                )}
              </div>
            ) : (
              "-"
            )
        },
        {
          title: ">180 days",
          position: "center",
          value: ({ days_180_plus }) =>
            days_180_plus.total_value ? (
              <div>
                {days_180_plus.count} /{" "}
                {formatCurrency(
                  currencyData.filter(e => e.label === currency)[0]?.value,
                  days_180_plus.total_value
                )}
              </div>
            ) : (
              "-"
            )
        },
        {
          title: "Total",
          position: "end",
          value: ({ days_total }) =>
            days_total.total_value ? (
              <div>
                {days_total.count} /{" "}
                {formatCurrency(
                  currencyData.filter(e => e.label === currency)[0]?.value,
                  days_total.total_value
                )}
              </div>
            ) : (
              "-"
            )
        }
      ]}
    />
  )
}
export default CategoriesContractsExpirationsTable
