import React from "react";
import {object, string} from "yup";
import FormConstructor from "../../elements/FormConstructor/FormConstructor";
import PropTypes from "prop-types";
import services from "../../../services";
import {userRoles} from "../../../helpers/usersData";

const AssignRequestForm = ({onSubmit, onCLose}) => {
  const NOTE_LENGTH = 150;
  const procurementUserRoles = userRoles
    .filter(role => role.userType.value === "procurement")
    .map(role => role.value)
    .join(",");

  const serializer = values => ({
    note: values.note,
    procurement_user_id: values.procurement_user.id,
    attachments: values.attachment,
  });

  return (
    <FormConstructor
      submitBtn={{
        styled: "primary",
        title: "Assign",
      }}
      cancelBtn={{
        styled: "secondary",
        title: "Cancel",
        onClick: () => onCLose()
      }}
      fields={[
        {
          type: "autocomplete",
          title: "Procurement user",
          placeholder: "Start typing user name",
          required: object({
            id: string().required("Procurement user is required")
          }),
          key: "procurement_user",
          value: "",
          load: term => services.userServices
            .stakeholdersAutocomplete({
              query: term,
              roles: procurementUserRoles,
            }),
        },
        {
          type: "textarea",
          title: "Note",
          placeholder: "Write a note",
          required: string()
            .required("Note is required")
            .max(NOTE_LENGTH, `The note is too long. It should be ${NOTE_LENGTH} characters or less.`),
          key: "note",
          value: "",
          max: NOTE_LENGTH,
        },
        {
          type: "attachments",
          key: "attachment",
          value: [],
        }
      ]}
      onSubmit={values => onSubmit(serializer(values))}
    />
  );
};

export default AssignRequestForm;

AssignRequestForm.propTypes = {
  onSubmit: PropTypes.func,
  description: PropTypes.string,
  onCLose: PropTypes.func,
};