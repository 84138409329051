import React, {useState, useEffect} from "react";
import classNames from "classnames";
import {Link, useLocation} from "react-router-dom";
import styles from "./CardInfo.module.scss";
import {ReactComponent as Title} from "assets/icons/usersPage/title.svg";
import {ReactComponent as EmailAdress} from "assets/icons/usersPage/emailAdress.svg";
import {ReactComponent as Division} from "assets/icons/usersPage/division.svg";
import {ReactComponent as Department} from "assets/icons/usersPage/department.svg";
import {useSelector} from "react-redux";
import {userRoles} from "../../../../../../../helpers/userPermissions/userRoles";
import s
  from "../../../../../ProjectContainer/components/Milestones/components/DateChangerWidget/DateChangerWidget.module.scss";
import {ReactComponent as Pencil} from "../../../../../../../assets/icons/edit-pencil.svg";
import {getTranslateKey} from "../../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import querystring from "query-string";
import {checkRoles} from "../../../../../../../helpers/checkRoles";

const CardInfo = (
  {
    id,
    name,
    role,
    title,
    email,
    lvl,
    team_name,
    users_count,
    teams_count,
    division,
    department,
    root,
    withTeamEdit,
    openTeamEditModal,
    isTeam,
    isReport,
    isOpenCard,
    isLast,
    withFilter,
  }
) => {
  const [show, setShow] = useState(lvl === 1);
  const user = useSelector(state => state.user);
  const {cpo, admin, procurement_admin} = userRoles;
  const {t} = useTranslation();
  const canSeeRoles = [cpo, admin, procurement_admin].includes(user.roles);
  const location = useLocation();
  const {department_ids} = querystring.parse(location.search);
  let style = show ? {height: isTeam ? "fit-content" : "207px"} : {};

  useEffect(() => {
    setShow(isOpenCard);
  }, [isOpenCard]);

  const isFiltered = () => {
    if (withFilter && department_ids) return !department_ids.includes(department.id);
    return false;
  };

  const renderExtendedInfo = () => {
    if (isTeam && lvl !== 1) return (
      <div className={styles.wrap}>
        <Department className={styles.icon}/>
        <p>{department?.name}</p>
      </div>
    );
    return (
      <>
        <div className={styles.wrap}>
          <Title className={styles.icon}/>
          <p>{title}</p>
        </div>
        <div className={styles.wrap}>
          <EmailAdress className={styles.icon}/>
          <p>{email}</p>
        </div>
        <div className={styles.wrap}>
          <Division className={styles.icon}/>
          <p>{division?.name}</p>
        </div>
        <div className={styles.wrap}>
          <Department className={styles.icon}/>
          <p>{department?.name}</p>
        </div>
      </>
    );
  };

  const renderEntitiesCount = () => {
    if (teams_count > 0 || users_count > 0) {
      if (teams_count > 0) return (
        <p className={styles.usersCount}>({teams_count} team{teams_count > 1 && "s"})</p>
      );
      return <p className={styles.usersCount}>({users_count} user{users_count > 1 && "s"})</p>;
    }
  };

  const renderEditBtn = () => {
    const btn = (
      <Pencil
        onClick={e => {
          e.stopPropagation();
          openTeamEditModal();
        }}
      />
    );

    if (withTeamEdit && checkRoles(user.roles, [admin, procurement_admin])) return btn;

    return "";
  };

  return (
    <>
      <div
        className={classNames(styles.info, {
          [styles.root]: root,
          [styles.last]: isLast,
          [styles.filtered]: isFiltered()
        })}
        onClick={() => {
          setShow((state) => !state);
        }}
        style={style}
      >
        <div className={styles.headerInfo}>
          <div>
            <Link
              className={styles.name}
              to={isReport ? `/system-reports/teams/${id}?years=2021` : `/users/${id}`}
              onClick={(e) => e.stopPropagation()}
            >
              {team_name ? team_name : name}
            </Link>
            {renderEntitiesCount()}
          </div>
          {renderEditBtn()}
        </div>
        {show && (
          <>
            {team_name && <p className={styles.leaderName}>{name}</p>}
            {canSeeRoles && (
              <p className={styles.role} data-translate-key={getTranslateKey(role)}>
                {t(getTranslateKey(userRoles[role].split("_").join(" ") ?? role))}
              </p>
            )}
            <div className={styles.extendedInfo}>
              {renderExtendedInfo()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CardInfo;
