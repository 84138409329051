import React from "react";
import styles from "./FinancialByYear.module.scss";
import ProjectFinancialByYearTable from "components/tables/ProjectFinancialByYearTable/ProjectFinancialByYearTable";
import { formatData } from "./helpers/formatData";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import MainTable from "../../../../tables/MainTable/MainTable";

const FinancialByYear = ({ data, title, defaultCurrency }) => {
  const formattedData = formatData(data);
  return (
    <div className={styles.wrapper}>
      {formattedData.length > 0 ? <ProjectFinancialByYearTable
        className={styles.table}
        data={formattedData}
        title={title}
        defaultCurrency={defaultCurrency}
      /> :
        <>
          <MainTable
            className={styles.tableContainer}
            totalName={title}
            totalCount=''
          />
          <EmptyBlock
            subtitle={"You don’t have any data yet."}
            withBtn={false}
          />
        </>




      }
    </div>
  );
};

export default FinancialByYear;
