import React from "react";
import s from "../MainDashboard.module.scss";
import Notifications from "../Notifications/Notifications";
import Tasks from "../Tasks/Tasks";
import classNames from "classnames";
import NewRequest from "../NewRequest/NewRequest";
import RecentRequest from "../RecentRequest/RecentRequest";
import GanttChartDashboard from "../GanttChart/GanttChartDashboard";
import Suppliers from "../Suppliers/Suppliers";
import Contracts from "../Contracts/Contracts";

const CategoryOwnerDashboard = () => {
  return (
    <>
      <div className={s.group}>
        <Notifications/>
        <Tasks/>
      </div>
      <div className={classNames(s.group, s.high)}>
        <NewRequest/>
        <RecentRequest/>
      </div>
      <div className={classNames(s.group, s.high)}>
        <Suppliers/>
        <Contracts/>
      </div>
      <div className={s.ganttChart}>
        <GanttChartDashboard/>
      </div>
    </>
  );
};

export default CategoryOwnerDashboard;