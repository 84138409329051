import React, {useEffect, useState} from "react";
import RequestFormStepsHeader from "./components/RequestFormStepsHeader/RequestFormStepsHeader";
import styles from "./RequestForm.module.scss";
import {useFormik} from "formik";
import {object} from "yup";
import SingleStep from "./components/SingleStep/SingleStep";
import {baseSteps} from "./helpers/baseSteps";
import services from "../../../services";
import {setRequestTypes} from "./helpers/setProjectTypes";
import {generateInitialValues} from "./helpers/generateInitialValues";
import TableLoading from "../../loadingStates/Table/Table";
import {generateValidationSchema} from "./helpers/generateValidationSchema";
import {setNewSteps} from "./helpers/setNewSteps";
import {generateValidationErrors} from "./helpers/generateValidationErrors";
import {getValues} from "./helpers/getValues";
import {filterParamsForQuestions} from "./helpers/filterParamsForQuestions";
import {useDidUpdate} from "../../../hooks/useDidUpdate";
import {useHistory} from "react-router-dom";
import {setAdditionalQuestions} from "./helpers/setAdditionalQuestions";
import PropTypes from "prop-types";
import {appendFile} from "../../../helpers/appendFile";
import {delay} from "../../../helpers/delay";
import {updateSteps} from "./helpers/updateSteps";
import NotificationBox from "../../elements/NotificationBox/NotificationBox";

const RequestForm = ({defaultRequest, defaultSteps}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState(defaultSteps || []);
  const [allSteps, setAllSteps] = useState(defaultSteps || []);
  const [loading, setLoading] = useState(true);
  const [paramsForQuestions, setParamsForQuestions] = useState("{}");
  const history = useHistory();
  const request = history.location.state?.request;
  const [alert, setAlert] = useState({message: "", type: ""});
  const entity = history.location.state?.entity;

  useEffect(() => {
    if (!defaultRequest) {
      services.requestTypeServices.getRequestTypes().then(res => {
        if (res.data) {
          setSteps(setRequestTypes(baseSteps, res.data));
          setAllSteps(setRequestTypes(baseSteps, res.data));
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  const formik = useFormik({
    initialValues: generateInitialValues(steps.reduce((acc, value) => acc.concat(value.fields), [])),
    validationSchema: (currentStep === 1 || currentStep === steps.length) &&
      object(generateValidationSchema(steps[currentStep - 1]?.fields)),
    validate: values => generateValidationErrors(steps[currentStep - 1]?.fields, values),
    onSubmit: values => {
      if (currentStep !== steps.length) {
        setCurrentStep(currentStep + 1);
      } else {
        if (defaultRequest) {
          services.requestTypeServices.updateRequest(defaultRequest.id, getValues(values))
            .then(res => {
              if (res.data) send(res.data);
            }).catch(() => {
            setAlert({
              message: "Something went wrong. Please, try again.",
              type: "error"
            });
          });
        } else {
          services.requestTypeServices.addRequest(getValues(values))
            .then(res => {
              if (res.data) send(res.data);
            }).catch(() => {
            setAlert({
              message: "Something went wrong. Please, try again.",
              type: "error"
            });
          });
        }
      }
    },
  });

  const {handleSubmit, values, setFieldValue, errors, touched, resetForm} = formik;

  const send = data => {
    const attachments = values.attachments || [];
    Promise.all([...attachments.map(attachment => {
      const file = appendFile(attachment);
      services.taskServices.addAttachment(file, data.id).then(res => res.data);
    })])
      .finally(() => delay(1000).then(() => history.push(`/requests/${data.id}`)));
  };

  useDidUpdate(() => {
    if (values.request_type_id) services.requestTypeServices.getRequestTypesSteps(values.request_type_id.value)
      .then(res => {
        if (res.data) {
          setSteps(setNewSteps(steps, res.data, defaultRequest, values));
          setAllSteps(setNewSteps(allSteps, res.data, defaultRequest, values, true));
          resetForm({
            values: {
              request_type_id: values.request_type_id,
              name: values.name,
            },
            errors: {
              name: ""
            }
          });
        }
      });
  }, [values.request_type_id?.value]);
  useDidUpdate(() => {
    if (request) {
      setFieldValue("name", {
        conditions: "",
        type: "String",
        value: entity?.name ?? "",
      });
      setFieldValue("request_type_id", {
        type: "radio",
        value: request
      });
    }
  }, [loading]);

  const getParams = () => ({
    values,
    setFieldValue,
    errors,
    touched,
    step: steps[currentStep - 1],
    index: currentStep - 1,
  });

  const changeAdditionalQuestions = (value, type, fieldKey) => {
    const preparedParams = JSON.parse(paramsForQuestions);
    if (!Boolean(value.value)) {
      delete preparedParams[fieldKey];
    } else {
      preparedParams[fieldKey] = {value, type};
    }
    setParamsForQuestions(JSON.stringify(preparedParams));
  };

  useDidUpdate(() => {
    setParamsForQuestions(
      JSON.stringify(
        filterParamsForQuestions(JSON.parse(paramsForQuestions), steps[currentStep - 1].fields, values)
      )
    );
    setSteps(updateSteps(steps, values));
  }, [values]);

  useDidUpdate(() => {
    const preparedParams = JSON.parse(paramsForQuestions);
    Promise.all([
      ...Object.entries(preparedParams).map(([_, data]) => {
        const {type, value: {value}} = data;
        return services.additionalQuestions.getAdditionalQuestions(type, value).then(res => res.data);
      })
    ]).then(result => {
      if (result) setSteps(setAdditionalQuestions(steps, result.flat()));
    });
  }, [paramsForQuestions]);

  if (loading) return <TableLoading length={2}/>;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <RequestFormStepsHeader
        currentStep={currentStep}
        steps={steps}
        prev={value => setCurrentStep(value)}
        isUpdate={Boolean(defaultRequest)}
      />
      <div className={styles.stepsList}>
        <SingleStep {...getParams()} changeAdditionalQuestions={changeAdditionalQuestions}/>
      </div>
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </form>
  );
};

export default RequestForm;

RequestForm.propTypes = {
  defaultRequest: PropTypes.any,
  defaultSteps: PropTypes.array,
};