import React, {useState} from "react";
import styles from "./SingleNote.module.scss";
import Avatar from "../../../../Avatar/Avatar";
import PropTypes from "prop-types";
import Files from "../../../../../forms/AddMilestoneTaskForm/components/Attachments/components/Files/Files";
import DropListTrigger from "../../../../DropListTrigger/DropListTrigger";
import {trimRest} from "../../../../../../helpers/stringHelper";
import {formatDate} from "../../../../../../helpers/dateFormat";

const SingleNote = ({data, onOpenPoUp}) => {
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);

  return (
    <article className={styles.singleNote}>
      <header>
        <div className={styles.dataHeader}>
          <Avatar name={data.user.name} size="medium"/>
          <div>
            <p>{trimRest(12, data.user.name)}</p>
            <p>{formatDate(data.created_at)}</p>
          </div>
        </div>
        <DropListTrigger
          data={[
            {
              title: "Edit",
              onClick: () => onOpenPoUp(data, "edit")
            },
            {
              title: "Delete",
              onClick: () => onOpenPoUp(data, "delete")
            }
          ]}
          visibleMenu={visibleDropListMenu}
          setVisibleMenu={setVisibleDropListMenu}
        />
      </header>
      <p>{data.description}</p>
      <Files files={data.attachments} className={styles.attachments} withLink={true}/>
    </article>
  );
};

export default SingleNote;

SingleNote.propTypes = {
  data: PropTypes.any,
  onOpenPoUp: PropTypes.func
};