import React from "react";
import {Link} from "react-router-dom";
import querystring from "query-string";

export default ({className, message, type, projectId, milestoneId, requestId, approvalId, taskId}) => {
  switch (type) {
    case "SurveyResult":
      return projectId ? (
        <Link
          className={className}
          to={{
            pathname: `/projects/${projectId}`,
            search: querystring.stringify({tab: "Survey"}),
          }}
        >
          {message}
        </Link>
      ) : (
        <span className={className}>{message}</span>
      );

    case "Project":
      return projectId ? (
        <Link
          className={className}
          to={{
            pathname: `/projects/${projectId}`,
          }}
        >
          {message}
        </Link>
      ) : (
        <span className={className}>{message}</span>
      );

    case "Task":
      return projectId && milestoneId && taskId ? (
        <Link
          className={className}
          to={{
            pathname: `/projects/${projectId}/milestones/${milestoneId}/tasks/${taskId}`,
          }}
        >
          {message}
        </Link>
      ) : (
        <span className={className}>{message}</span>
      );

    case "Request":
      return requestId ? (
        <Link
          className={className}
          to={{
            pathname: `/requests/${requestId}`,
          }}
        >
          {message}
        </Link>
      ) : (
        <span className={className}>{message}</span>
      );

    case "Approval":
      return projectId && milestoneId && approvalId ? (
        <Link
          className={className}
          to={{
            pathname: `/projects/${projectId}/milestones/${milestoneId}/approvals/${approvalId}/detail`
          }}
        >
          {message}
        </Link>
      ) : (
        <span className={className}>{message}</span>
      );

    default:
      return <span className={className}>{message}</span>;
  }
};
