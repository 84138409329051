import { config, linesConfig } from "../helpers/defaultConfig";
import { getRowsPos } from "../helpers/getRowsPos";

export const drawAfterXLines = (data) => {
  let afterLines = [];
  getRowsPos(data).map(({ top, left, colIdx, rowIdx, status }) => {
    status !== "Not required" &&
      status !== "Pending" &&
      status !== "Rejected" &&
      Object.keys(data).length !== colIdx + 1 &&
      afterLines.push({
        x1: left + config.colWidth,
        y1: top + linesConfig.horizontalLineMarginY,
        x2: left + config.colWidth + linesConfig.horizontalLineWidth,
        y2: top + linesConfig.horizontalLineMarginY,
      });
  });
  return afterLines;
};
