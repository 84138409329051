import React, {useState, Fragment, useRef, useEffect} from "react";
import {TableCell} from "@material-ui/core";
import s from "./MainTable.module.scss";
import classNames from "classnames";
import {hasOverflowingChildren} from "helpers/overflow";
import {ReactComponent as ArrowDown} from "assets/icons/arrowDown.svg";
import {ReactComponent as ArrowUp} from "assets/icons/arrowUp.svg";
import PropTypes from "prop-types";

const RenderTableCell = (
  {
    dataTypes,
    type,
    data,
    index,
    visible = true,
    allData,
    tdStyle,
    expand = undefined,
    changeExpand,
    level,
    subKey,
  }
) => {

  const [visibleHint, setVisibleHint] = useState(false);
  const [hintPosition, setHintPosition] = useState({x: -999, y: -999});
  const [hintOverflow, setHintOverfow] = useState(false);
  const hintRef = useRef(null);
  const title = type?.value && type.value(data);
  const componentType = type.type;
  const hint = type.hint;
  const titleMaxLength = type.max;
  const formatTitle = () => {
    if (title.length >= titleMaxLength) {
      return `${title.substr(0, titleMaxLength)}...`;
    }
    return title;
  };
  useEffect(() => {
    hint &&
    hintRef.current &&
    setHintOverfow(hasOverflowingChildren(hintRef.current, "x"));
  }, [hintPosition.x]);
  return (
    <Fragment>
      {visible && type && (
        <TableCell
          className={classNames(s.tableCell, {
            [s.link]: type.onClick,
            [s.col1]: dataTypes.length === 3 && index === 0,
            [s.col2]: dataTypes.length === 3 && index === 1,
            [s.tagsComponent]: componentType === "tags"
          })}
          onClick={() => type.onClick && type.onClick(data.id, data)}
          style={tdStyle}
        >
          {hint ? (
            <span
              onMouseMove={e => {
                if (hint && title.length >= titleMaxLength) {
                  setHintPosition({x: e.clientX, y: e.clientY});
                  !visibleHint && setVisibleHint(true);
                }
              }}
              onMouseLeave={() => hint && setVisibleHint(false)}
            >
              {formatTitle()}
            </span>
          ) : (
            <div className={classNames(s.tableCellContent, {[s[type.position]]: type.position})}>
              <div
                style={index === 0 ? {paddingLeft: level * 15} : {}}
                className={classNames({
                  [s.hideContent]: type.hideCondition ? type.hideCondition(data) : false,
                })}
              >
                {type.value(data, allData)}
                {type.subTypes && (<div className={s.filteredSpend}> {type.subTypes.value(data)}</div>)}
              </div>
              {(expand !== undefined && data[subKey]?.length > 0) && (
                <div
                  onClick={e => {
                    e.stopPropagation();
                    changeExpand(state => !state);
                  }}
                >
                  {expand ? <ArrowUp/> : <ArrowDown/>}
                </div>
              )}
            </div>
          )}
          {visibleHint && (
            <span
              style={{
                left: !hintOverflow && `${hintPosition.x + 5}px`,
                right: hintOverflow && "-1px",
                top: `${hintPosition.y + 5}px`
              }}
              className={s.hint}
              ref={hintRef}
            >
              {type.value(data, allData)}
            </span>
          )}
        </TableCell>
      )}
    </Fragment>
  );
};
export default RenderTableCell;

RenderTableCell.propTypes = {
  dataTypes: PropTypes.any,
  type: PropTypes.string,
  data: PropTypes.any,
  index: PropTypes.number,
  visible: PropTypes.bool,
  allData: PropTypes.any,
  tdStyle: PropTypes.any,
  expand: PropTypes.any,
  changeExpand: PropTypes.any,
  level: PropTypes.number,
  subKey: PropTypes.string,
};