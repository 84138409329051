import React, { useEffect, useReducer, Fragment } from "react"
import {
  setTaskRoles,
  addTaskRole,
  changeTaskRole,
  setCurrentTaskRole,
  deleteTaskRole,
  closeAlert,
  setPopup
} from "./helpers/actions"
import { reducer, initialState } from "./helpers/reducer"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import TableLoading from "components/loadingStates/Table/Table"
import AdminTaskRolesTable from "../../../../../tables/AdminTaskRolesTable/AdminTaskRolesTable";
import AddPopup from "./popups/AddPopup/AddPopup"
import EditPopup from "./popups/EditPopup/EditPopup"
import DeletePopup from "./popups/DeletePopup/DeletePopup"
import NotificationBox from "components/elements/NotificationBox/NotificationBox"
const TaskRoles = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    loading,
    tasks_count,
    popup,
    alert,
    current_taskrole
  } = state
  useEffect(() => setTaskRoles(dispatch), [])
  const onSort = search_query => setTaskRoles(dispatch, search_query)
  const onPageChange = page =>
    setTaskRoles(dispatch, state.search_query, page)
  return (
    <Fragment>
      {!loading && tasks_count > 0 && (
        <AdminTaskRolesTable
          state={state}
          onSort={onSort}
          onPageChange={onPageChange}
          onAdd={() => dispatch(setPopup("add"))}
          onEdit={item => {
            dispatch(setPopup("edit"))
            dispatch(setCurrentTaskRole(item))
          }}
          onDelete={id => {
            dispatch(setPopup("delete"))
            dispatch(setCurrentTaskRole(id))
          }}
        />
      )}
      {!loading && !tasks_count && (
        <EmptyBlock
          subtitle='You don’t have any task roles yet.'
          buttonTitle='New role'
          onClick={() => dispatch(setPopup("add"))}
          withBtn={true}
        />
      )}
      {loading && <TableLoading length={2} />}
      {popup === "add" && (
        <AddPopup
          onClose={() => dispatch(setPopup(null))}
          onSubmit={values => addTaskRole(dispatch, { name: values.taskRole })}
        />
      )}
      {popup === "edit" && (
        <EditPopup
          onClose={() => dispatch(setPopup(null))}
          onSubmit={values =>
            changeTaskRole(dispatch, current_taskrole?.id, {
              name: values.taskRole
            })
          }
          defaultValues={{ taskRole: current_taskrole?.name }}
        />

      )}
      {popup === "delete" && (
        <DeletePopup
          onClose={() => dispatch(setPopup(null))}
          onSubmit={() => deleteTaskRole(dispatch, current_taskrole)}
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          onClose={() => dispatch(closeAlert())}
        />
      )}
    </Fragment>
  )
}
export default TaskRoles
