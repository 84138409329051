import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import s from "./ProfileContainer.module.scss";
import Tabs from "components/elements/Tabs/Tabs";
import General from "./components/General/General";
import NotificationSettings from "./components/NotificationSettings/NotificationSettings";
import SystemSettings from "./components/SystemSettings/SystemSettings";
import {useHistory} from "react-router-dom";
import querystring from "query-string";
import services from "services";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import CompanyGoalContainer from "components/containers/CompanyGoalContainer/CompanyGoalContainer";
import {useTranslation} from "react-i18next";
import {load, save} from "../../../helpers/localStorage";
import {changeLanguage} from "../../../actions/user";

const ProfileContainer = () => {
  const emptySettings = {
    email_sent_time: "",
    time_zone: "",
    language: "",
  };
  const {i18n} = useTranslation();
  const [settings, setSettings] = useState(emptySettings);
  const [alert, setAlert] = useState({message: "", type: ""});
  const user = useSelector(store => store.user);
  const {name, title, email, division, department} = user;
  const history = useHistory();
  const dispatch = useDispatch();
  const {tab} = querystring.parse(history.location.search);

  useEffect(() => {
    if (
      tab &&
      tab !== "General" &&
      settings.email_sent_time === emptySettings.email_sent_time &&
      settings.time_zone === emptySettings.time_zone
    ) {
      services.userServices.getSelfSettings().then(res => {
        setSettings({
          email_sent_time:
            res.data?.email_sent_time ?? emptySettings.email_sent_time,
          time_zone: res.data?.time_zone || emptySettings.time_zone,
          language: res.data?.language || emptySettings.language,
        });
      });
    }
  }, [tab]);
  const handleTabChange = tab => {
    history.push({search: querystring.stringify({tab})});
  };
  const handleChangeSettings = value => {
    services.userServices
      .changeSelfSettings({settings: {...settings, ...value}})
      .then(res => {
        setSettings({
          email_sent_time: res.data?.email_sent_time ?? emptySettings.email_sent_time,
          time_zone: res.data?.time_zone || emptySettings.time_zone,
          language: res.data?.language || emptySettings.language,
        });
        setAlert({
          type: "success",
          message: "Settings successfully changed."
        });
        if (value.language) {
          save("currentUser", {...load("currentUser"), language: value.language});
          dispatch(changeLanguage(value.language));
          i18n.changeLanguage(value.language);
        }
      })
      .catch(err =>
        setAlert({
          message:
            err.response?.data.error ||
            "Something went wrong. Please, try again.",
          type: "error"
        })
      );
  };

  return (
    <section className={s.profileContainer}>
      <h1 className={s.fullName}>{name}</h1>
      <Tabs handleClick={handleTabChange} defaultActiveTab={tab}>
        <div label="General">
          <General data={{title, email, division, department}}/>
        </div>
        <div label="Notifications">
          <NotificationSettings
            data={settings.email_sent_time}
            onChange={handleChangeSettings}
          />
        </div>
        <div label="System settings">
          <SystemSettings
            data={settings}
            onChange={handleChangeSettings}
          />
        </div>
        {user.user_type !== "business" && user.user_type !== "support" ?
          <div label="Goals">
            <CompanyGoalContainer source="user" isRequest={true} sourceId={user.id}/>
          </div> : <span/>
        }
      </Tabs>
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </section>
  );
};

export default ProfileContainer;
