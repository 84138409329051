import React from "react";
import Popup from "components/popups/Popup/Popup.jsx";
import ChangeProjectStageForm from 'components/forms/ChangeProjectStageForm/ChangeProjectStageForm'

const AddStagePopup = ({ onClose, onSubmit }) => {
  return (
    <Popup title='New project stage' text=' Please, complete the following fields to create a new project stage' onClose={onClose}>
      <ChangeProjectStageForm onClose={onClose} onSubmit={onSubmit} />
    </Popup>
  );
};

export default AddStagePopup;
