import React from "react";
import ProjectActualSavingsTable from "../../../../../tables/ProjectActualSavingsTable/ProjectActualSavingsTable";
import s from "./ActualSavings.module.scss";

const ActualSavings = (
  {
    onDateClick,
    decreaseMonth,
    increaseMonth,
    startDate,
    actualSavings,
    editActualSavingsGroup,
    newActualSavingsGroup,
    benefits,
    owner,
    savingsGr
  }
) => {
  return (
    <div className={s.wrapper}>
      <ProjectActualSavingsTable
        title={"Actual savings for"}
        className={s.table}
        rowClassName={s.rowClass}
        actualSavings={actualSavings}
        benefits={benefits}
        startDate={startDate}
        decreaseMonth={decreaseMonth}
        increaseMonth={increaseMonth}
        editActualSavingsGroup={editActualSavingsGroup}
        newActualSavingsGroup={newActualSavingsGroup}
        onDateClick={onDateClick}
        owner={owner}
        savingsGrBenefits={savingsGr}
      />
    </div>
  );
};
export default ActualSavings;