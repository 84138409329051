import React from 'react';
import classNames from "classnames";
import {ReactComponent as StepArrow} from "../../../../../assets/icons/step-arrow.svg";
import styles from "./RequestFormStepsHeader.module.scss";
import PropTypes from "prop-types";
import {trimRest} from "../../../../../helpers/stringHelper";
import {useTranslate} from "../../../../../hooks/useTranslate";

const Step = ({step, idx, total, isActive}) => {
  const [translateKey, translateValue] = useTranslate(step.name);
  const isTranslate = ["Start", "Additional information"].includes(step.name);

  return (
    <>
      <div className={classNames(styles.stepWrapper, {[styles.active]: isActive})}>
        <div className={styles.step}>
          <div className={styles.num}>{idx}</div>
          <div className={styles.name} title={step.name} data-translate-key={isTranslate && translateKey}>
            {trimRest(22, isTranslate ? translateValue : step.name)}
          </div>
        </div>
      </div>
      {idx !== total && (
        <div className={styles.arrow}>
          <StepArrow/>
        </div>
      )}
    </>
  );
};

export default Step;

Step.propTypes = {
  step: PropTypes.any,
  idx: PropTypes.number,
  total: PropTypes.number,
  isActive: PropTypes.bool,
};