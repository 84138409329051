import React from "react";
import styles from "./SurveyStepsTable.module.scss";
import MainTable from "components/tables/MainTable/MainTable";
import _ from "lodash";
import services from "services";

export const SurveyStepsTable = ({ steps, setEditQuestions, currentId, setSteps, setNewPopUp }) => {
  const handleEditStep = () => setEditQuestions((prevState) => !prevState);

  const editStep = (item) =>
    setNewPopUp((p) => ({ checked: !p.checked, action: "Edit", item: item }));

  const deleteStep = (item) => {
    const newArr = steps.filter((st) => st.id !== item.id);
    services.templatesServices.createStep(currentId, newArr).then((res) => {
      setSteps(res.data.filter((st) => st.status !== "inactive"));
    });
  };

  const formatStepsArr = (stepsArr) => {
    const newArr = [];
    stepsArr.map((step) =>
      step?.questions?.length
        ? step.questions.map((quest) => {
            !newArr.find((el) => _.isEqual(el, step)) && newArr.push(step);
            newArr.push(quest);
          })
        : newArr.push(step),
    );
    return newArr;
  };

  return (
    <MainTable
      totalName="Questions"
      className={styles.emptyMargins}
      subRowClassName={styles.subRow}
      data={formatStepsArr(steps)}
      withXScroll={true}
      dataTypes={[
        {
          title: "Question",
          value: (item) =>
            !item?.name ? (
              item.question
            ) : (
              <div className={styles.questionName}>
                <label>{`Step ${steps.findIndex((el) => _.isEqual(el, item)) + 1} - ${
                  item.name
                }`}</label>
                <p>{item.description}</p>
              </div>
            ),
        },

        {
          title: "Type",
          position: "center",
          value: (item) =>
            item?.question_type
              ? typeof item?.question_type === "string"
                ? item?.question_type
                : item?.question_type?.label
              : "",
        },
        {
          title: "Weight, %",
          position: "center",
          value: (item) => (item?.weight ? item.weight : ""),
        },
      ]}
      options={(item) =>
        item?.name && [
          {
            title: "Edit",
            onClick: () => editStep(item),
          },
          {
            title: "Delete",
            onClick: () => deleteStep(item),
          },
        ]
      }
      button={{
        name: "Edit",
        styled: "primary",
        onClick: handleEditStep,
      }}
    />
  );
};
