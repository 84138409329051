import React from "react";
import classNames from "classnames";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import s from "./UserFormWrapper.module.scss";
import { Link } from "react-router-dom";

const UserFormWrapper = ({ children, className }) => {
  return (
    <div className={classNames(s.userFormWrapper, className)}>
      <Link to="/">
        <Logo className={s.logo} />
      </Link>
      {children}
    </div>
  );
};

export default UserFormWrapper;
