const entityValidation = (entities, errors, type) => {
  if (entities.some(entity => !entity?.entity)) return {...errors, [type]: `Each ${type.slice(0, -1)} is required`};
  return errors;
};

const valueValidation = (entities, final_savings, errors, type) => {
  const splitSavingByEntity = entities.reduce((accumulator, current) => accumulator + Number(current?.value), 0);
  if (splitSavingByEntity !== Number(final_savings)) return {
    ...errors,
    [type]: `Split savings by ${type} should be equal to final saving`,
  };

  return errors;
};

const eachValueValidation = (entities, errors, type) => {
  if (entities.some(entity => entity?.value <= 0)) return {
    ...errors,
    [type]: `Each split saving by ${type} should be greater then 0`,
  };

  return errors;
};

const eachTypeLengthValidation = (entities, errors, type) => {
  if (!entities.length) return {...errors, [type]: `Add at least 1 ${type.slice(0, -1)}`};
};

export const validation = ({departments, divisions, users, final_savings}) => {
  let errors = {};

  errors = eachValueValidation(departments, errors, "departments");
  errors = eachValueValidation(divisions, errors, "divisions");
  errors = eachValueValidation(users, errors, "users");

  errors = entityValidation(departments, errors, "departments");
  errors = entityValidation(divisions, errors, "divisions");
  errors = entityValidation(users, errors, "users");

  errors = valueValidation(departments, final_savings, errors, "departments");
  errors = valueValidation(divisions, final_savings, errors, "divisions");
  errors = valueValidation(users, final_savings, errors, "users");

  errors = eachTypeLengthValidation(departments, errors, "departments");
  errors = eachTypeLengthValidation(divisions, errors, "divisions");
  errors = eachTypeLengthValidation(users, errors, "users");

  return errors;
};