import React, {Fragment, useEffect, useState, useContext} from "react";
import AdminApprovalsGroupsTable from "components/tables/AdminApprovalsGroupsTable/AdminApprovalsGroupsTable";
import AddGroupPopup from "./Popups/AddGroupPopup/AddGroupPopup";
import EditGroupPopup from "./Popups/EditGroupPopup/EditGroupPopup";
import Popup from "components/popups/Popup/Popup";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import services from "services";
import {useHistory, useLocation} from "react-router-dom";
import AlertContext from "contexts/AlertContext/AlertContext";
import TableLoading from "components/loadingStates/Table/Table";

const Groups = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [approvers, setApprovers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const {setAlert, getCancelId} = useContext(AlertContext);

  useEffect(() => {
    history.push(location.pathname);
    services.adminServices.getApprovalGroups().then((res) => {
      setData(res.data.approval_groups);
      setLoading(false);
    });
    services.adminServices.getApprovers().then((res) => {
      setApprovers(
        res.data.map(({name, id}) => ({label: name, value: name, id}))
      );
    })
    services.adminServices.getDepartments().then((res) => {
      setDepartments(res.data);
    });
  }, []);

  const handleAddGroup = (values) => {
    setPopup(null);
    services.adminServices
      .addApprovalGroup(values)
      .then((res) => {
        if (res.data) {
          setData([...data, res.data]);
          setAlert({
            message: `"${res.data.name}" group successfully added.`,
          });
        }
      })
      .catch(() =>
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error",
        })
      );
  };

  const handleDeleteGroup = () => {
    setPopup(null);
    services.adminServices
      .deleteApprovalGroup(currentGroup.id)
      .then(res => {
        if (res.status < 300) setData(data.filter((e) => e.id !== currentGroup.id))
      }).catch(() =>
      setAlert({
        message: "Something went wrong. Please, try again.",
        type: "error",
      })
    );
  };

  const handleEditGroup = values => {
    setPopup(null);
    services.adminServices
      .changeApprovalGroup(currentGroup.id, values)
      .then(res => {
        if (res.data) {
          setData(data.map((e) => e.id === res.data.id ? res.data : e));
          setAlert({
            message: `"${currentGroup.name}" group successfully changed.`,
          });
        }
      })
      .catch(() => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error",
        });
      });
  };

  return (
    <Fragment>
      {data.length > 0 && !loading && (
        <AdminApprovalsGroupsTable
          data={data}
          onAdd={() => setPopup("add")}
          onEdit={(e) => {
            setCurrentGroup(e);
            setPopup("edit");
          }}
          onDelete={(e) => {
            setCurrentGroup(e);
            setPopup("delete");
          }}
        />
      )}
      {!data.length && !loading && (
        <EmptyBlock
          subtitle="No work item results found"
          buttonTitle="New group"
          onClick={() => setPopup("add")}
        />
      )}
      {loading && <TableLoading length={4}/>}
      {popup === "add" && (
        <AddGroupPopup
          onClose={() => setPopup(null)}
          onSubmit={handleAddGroup}
          approvers={approvers}
          departments={departments}
        />
      )}
      {popup === "edit" && (
        <EditGroupPopup
          defaultValues={currentGroup}
          onClose={() => setPopup(null)}
          onSubmit={handleEditGroup}
          approvers={approvers}
          departments={departments}
        />
      )}
      {popup === "delete" && (
        <Popup
          title="Delete approval group"
          text="Are you sure you want to delete this group?"
          onClose={() => setPopup(null)}
          onClick={handleDeleteGroup}
          isDelete
          dialog
        />
      )}
    </Fragment>
  );
};
export default Groups;
