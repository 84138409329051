import {ADD_ITEM, DELETE_ITEM, LOAD_DATA, UPDATE_ITEM} from "../reducer";
import services from "services";

const getEntityList = type => {
  switch (type) {
    case 'typeOfSpendList':
      return services.additionalQuestions.getTypeOfSpend()
    case 'supplierStatusList':
      return services.additionalQuestions.getSupplierStatuses()
    case 'budgetList':
      return services.additionalQuestions.getBudgets()
    case 'projectTypeList':
      return services.additionalQuestions.getProjectTypes()
    case 'categoryList':
      return services.additionalQuestions.getCategories()
  }
}

export const loadData = (dispatch, type) => {
  getEntityList(type).then(res => dispatch({
    type: LOAD_DATA,
    payload: {
      typeList: type,
      data: res.data,
    },
  }));
};

export const addNewTypeOfSpend = (dispatch, value) => {
  services.additionalQuestions.addTypeOfSpend(value)
    .then(res => dispatch({
      type: ADD_ITEM,
      payload: {
        typeList: 'typeOfSpendList',
        data: res.data,
      },
    }));
}

export const deleteTypeOfSpend = (dispatch, id) => {
  services.additionalQuestions.deleteTypeOfSpend(id)
    .then(() => dispatch({
      type: DELETE_ITEM,
      payload: {
        typeList: 'typeOfSpendList',
        id,
      },
    }));
}

export const addNewSupplierStatus = (dispatch, value) => {
  services.additionalQuestions.addSupplierStatus(value)
    .then(res => dispatch({
      type: ADD_ITEM,
      payload: {
        typeList: 'supplierStatusList',
        data: res.data,
      },
    }));
}

export const deleteSupplierStatus = (dispatch, id) => {
  services.additionalQuestions.deleteSupplierStatus(id)
    .then(() => dispatch({
      type: DELETE_ITEM,
      payload: {
        typeList: 'supplierStatusList',
        id,
      },
    }));
}

export const addNewBudget = (dispatch, value) => {
  services.additionalQuestions.addBudget(value)
    .then(res => dispatch({
      type: ADD_ITEM,
      payload: {
        typeList: 'budgetList',
        data: res.data,
      },
    }));
}

export const updateCurrentBudget = (dispatch, value, id) => {
  services.additionalQuestions.updateBudget(value, id)
    .then(res => dispatch({
      type: UPDATE_ITEM,
      payload: {
        typeList: 'budgetList',
        data: res.data,
      },
    }));
}

export const deleteBudget = (dispatch, id) => {
  services.additionalQuestions.deleteBudget(id)
    .then(() => dispatch({
      type: DELETE_ITEM,
      payload: {
        typeList: 'budgetList',
        id,
      },
    }));
}


export const addNewProjectType = (dispatch, value) => {
  services.additionalQuestions.addProjectType(value)
    .then(res => dispatch({
      type: ADD_ITEM,
      payload: {
        typeList: 'projectTypeList',
        data: res.data,
      },
    }));
}

export const deleteProjectType = (dispatch, id) => {
  services.additionalQuestions.deleteProjectType(id)
    .then(() => dispatch({
      type: DELETE_ITEM,
      payload: {
        typeList: 'projectTypeList',
        id,
      },
    }));
}

export const addNewCategory = (dispatch, value) => {
  services.additionalQuestions.addCategories(value)
    .then(res => dispatch({
      type: ADD_ITEM,
      payload: {
        typeList: 'categoryList',
        data: res.data,
      },
    }));
}

export const deleteCategory = (dispatch, id) => {
  services.additionalQuestions.deleteCategories(id)
    .then(() => dispatch({
      type: DELETE_ITEM,
      payload: {
        typeList: 'categoryList',
        id,
      },
    }));
}