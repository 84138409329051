import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import s from "./HistoryLoading.module.scss";

const HistoryLoading = () => {
  return (
    <div className={s.historyLoadingWrapper}>
      <Skeleton variant="text" animation="wave" width="100%"/>
      <Skeleton variant="text" animation="wave" width="100%"/>
      <Skeleton variant="text" animation="wave" width="100%"/>
    </div>
  );
};

export default HistoryLoading;