import {
  SET_STAKEHOLDERS,
  ADD_STAKEHOLDER,
  CHANGE_STAKEHOLDER,
  DELETE_STAKEHOLDER,
  SET_CURRENT_STAKEHOLDER,
  SET_POPUP,
  CLOSE_ALERT
} from "./reducer"
import services from "services"
export const setStakeholders = (dispatch, search_query, page) => {
  services.adminServices
    .getStakeholderTypes({ ...search_query, page })
    .then(res => {
      dispatch({
        type: SET_STAKEHOLDERS,
        payload: {
          per_page: res.data?.per_page,
          stakeholder_types_count: res.data?.stakeholder_types_count,
          stakeholder_types: res.data?.stakeholder_types,
          search_query: {
            sort_by: search_query?.sort_by || "",
            direction: search_query?.direction || ""
          },
          loading: false
        }
      })
    })
    .catch(err =>
      dispatch({
        type: SET_STAKEHOLDERS,
        payload: {
          alert: {
            type: "error",
            message:
              err.response?.data.error ||
              "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const addStakeholder = (dispatch, values) => {
  services.adminServices
    .addStakeholderType(values)
    .then(res =>
      dispatch({
        type: ADD_STAKEHOLDER,
        payload: {
          stakeholder: res.data || null,
          alert: {
            type: "success",
            message: "Stakeholder type successfully created."
          },
          popup: null
        }
      })
    )
    .catch(err =>
      dispatch({
        type: ADD_STAKEHOLDER,
        payload: {
          alert: {
            type: "error",
            message:
              err.response?.data.error ||
              "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const changeStakeholder = (dispatch, id, values) => {
  services.adminServices
    .changeStakeholderType(id, values)
    .then(res =>
      dispatch({
        type: CHANGE_STAKEHOLDER,
        payload: {
          stakeholder: res.data || null,
          alert: {
            type: "success",
            message: "Stakeholder type successfully changed."
          },
          popup: null
        }
      })
    )
    .catch(err =>
      dispatch({
        type: CHANGE_STAKEHOLDER,
        payload: {
          alert: {
            type: "error",
            message:
              err.response?.data.error ||
              "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const deleteStakeholder = (dispatch, id) => {
  services.adminServices
    .deleteStakeholderType(id)
    .then(res =>
      dispatch({
        type: DELETE_STAKEHOLDER,
        payload: {
          stakeholder_id: id,
          alert: {
            type: "success",
            message: "Stakeholder type successfully deleted."
          },
          popup: null
        }
      })
    )
    .catch(err =>
      dispatch({
        type: DELETE_STAKEHOLDER,
        payload: {
          alert: {
            type: "error",
            message:
              err.response?.data.error ||
              "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const setPopup = popup => ({
  type: SET_POPUP,
  payload: popup
})
export const closeAlert = () => ({
  type: CLOSE_ALERT,
  payload: null
})
export const setCurrentStakeholder = stakeholder => ({
  type: SET_CURRENT_STAKEHOLDER,
  payload: stakeholder
})
