import React from "react";
import Popup from "components/popups/Popup/Popup";

const DeleteUserPopup = ({onSubmit, onClose}) => {
  return (
    <Popup
      title="Delete user"
      text="Are you sure you want to delete this user?"
      onClose={onClose}
      onClick={onSubmit}
      dialog
      isDelete
      subText="You can restore users by editing their types."
    />
  );
};
export default DeleteUserPopup;
