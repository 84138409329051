import React, { useState, Fragment } from "react";
import classNames from "classnames";
import Input from "components/elements/Input/Input";
import Textarea from "components/elements/Textarea/Textarea";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import Thank from "./Thank/Thank";
import options from "./options";
import { useFormik } from "formik";
import { string, object } from "yup";
import s from "./ContactForm.module.scss";
import services from "services";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";

const ContactForm = () => {
  const {t} = useTranslation();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      industry: "",
      company: "",
      role: {},
      message: "",
    },
    validationSchema: object({
      email: string()
        .max(
          64,
          "Please enter a valid email address that consists of no more than 64 characters"
        )
        .email("Email is invalid")
        .required("Email is required"),
      firstName: string().required("First name is required"),
      lastName: string().required("Last name is required"),
      industry: string().required("Insudstry is required"),
      company: string().required("Company name is required"),
      role: object({ value: string().required("Role is required") }),
      message: string().required("Please, enter your message"),
    }),
    onSubmit: (values) => {
      services.contactUsServices.sendContactUsForm(values).then(() => {
        setSubmitted(true);
      });
    },
  });
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    setFieldTouched,
    handleBlur,
    validateForm,
    values,
    touched,
    errors,
  } = formik;

  const [submitted, setSubmitted] = useState(false);

  return (
    <Fragment>
      {!submitted ? (
        <div className={s.contactForm}>
          <h2 className={s.title} data-translate-key={"contact-us"}> {t(getTranslateKey("contact-us"))}</h2>
          <p className={s.text} data-translate-key={"we’re-here-to-help.-tell-us-a-little-bit-about-yourself,-ask-a-question-and-we-will-get-back-to-you-in-less-than-24-hours."}>
            {t(getTranslateKey("we’re-here-to-help.-tell-us-a-little-bit-about-yourself,-ask-a-question-and-we-will-get-back-to-you-in-less-than-24-hours."))}
          </p>

          <form className={s.form} onSubmit={handleSubmit}>
            <div className={s.inputBlock}>
              <div className={s.row}>
                <Input
                  name="firstName"
                  label="First name"
                  value={values.firstName}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!Number(val.slice(-1))) {
                      setFieldValue("firstName", val);
                    }
                  }}
                  labelClass={s.label}
                  inputClass={classNames(s.formItem, s.input)}
                  className={s.widthClass}
                  onBlur={handleBlur}
                  error={
                    errors.firstName && touched.firstName && errors.firstName
                  }
                />
                <Input
                  name="lastName"
                  value={values.lastName}
                  onBlur={handleBlur}
                  error={errors.lastName && touched.lastName && errors.lastName}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!Number(val.slice(-1))) {
                      setFieldValue("lastName", val);
                    }
                  }}
                  label="Last name"
                  labelClass={s.label}
                  inputClass={classNames(s.formItem, s.input)}
                  className={s.widthClass}
                />
              </div>

              <div className={s.row}>
                <Input
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  error={errors.email && touched.email && errors.email}
                  onChange={handleChange}
                  label="Business email"
                  type="email"
                  labelClass={s.label}
                  inputClass={classNames(s.formItem, s.input)}
                  className={s.widthClass}
                />

                <Input
                  name="industry"
                  value={values.industry}
                  onBlur={handleBlur}
                  error={errors.industry && touched.industry && errors.industry}
                  onChange={handleChange}
                  label="Industry"
                  labelClass={s.label}
                  inputClass={classNames(s.formItem, s.input)}
                  className={s.widthClass}
                />
              </div>

              <div className={s.row}>
                <Input
                  name="company"
                  value={values.company}
                  onBlur={handleBlur}
                  error={errors.company && touched.company && errors.company}
                  onChange={handleChange}
                  label="Company"
                  labelClass={s.label}
                  inputClass={classNames(s.formItem, s.input)}
                  className={s.widthClass}
                />

                <CustomDropdown
                  name="role"
                  value={values.role}
                  error={errors.role && touched.role && errors.role?.value}
                  onChange={(value) => {
                    setFieldValue("role", value);
                  }}
                  onFocus={() => {
                    setFieldTouched("role", true, true);
                  }}
                  label="Your role"
                  options={options}
                  labelClass={s.label}
                  className={classNames(s.dropDown)}
                  // controlClasses={classNames(s.formItem, s.controlClasses)}
                  headerClass={s.headerClass}
                  arrowClass={s.arrowClass}
                  menuClasses={s.menuClasses}
                  dropClass={s.dropClass}
                />
              </div>
            </div>
            <div className={s.area}>
              <Textarea
                label="What challenges are you faced with?"
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.message && touched.message && errors.message}
                placeholder=""
                className={classNames(s.formItem)}
                labelClass={s.label}
              />
            </div>
            <button
              type="submit"
              className={s.buttonLink}
              onClick={handleSubmit}
              data-translate-key={"submit"}
            >
              {t(getTranslateKey("submit"))}
            </button>
          </form>
        </div>
      ) : (
        <Thank />
      )}
    </Fragment>
  );
};

export default ContactForm;
