import React, {useState, useEffect} from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import s from "./CommentContainer.module.scss";
import CommentForm from "components/forms/CommentForm/CommentForm";
import Comment from "./components/Comment/Comment";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const CommentContainer = (
  {
    comment,
    owner,
    onAddComment,
    autoFocus,
    allowed = false,
    className,
    submitOnBlur,
    onErrors,
  }
) => {
  const user = useSelector((state) => state.user.id);
  const {t} = useTranslation();
  const [_comment, _setComment] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (comment) _setComment(comment);
  }, [comment]);

  const addHandler = async (data) => {
    await onAddComment(data).then(() => {
      _setComment(data.results_comment);
      setShow(false);
    });
  };

  return (
    <div className={classNames(s.container, className)}>
      <p className={s.title} data-translate-key={getTranslateKey("Comment")}>
        {t(getTranslateKey("Comment"))}
      </p>
      {show && !_comment && (
        <CommentForm
          addHandler={(data) => addHandler(data)}
          comment={_comment}
          onClose={() => setShow(false)}
          autoFocus={autoFocus}
          onErrors={onErrors}
        />
      )}

      {owner === user || allowed ? (
        _comment && !show ? (
          <div onClick={() => setShow(true)}>
            <Comment comment={_comment}/>
          </div>
        ) : (
          <CommentForm
            addHandler={(data) => addHandler(data)}
            comment={_comment}
            onClose={() => setShow(false)}
            autoFocus={autoFocus}
            submitOnBlur={submitOnBlur}
            onErrors={onErrors}
          />
        )
      ) : !_comment ? (
        <Comment comment="No comment yet"/>
      ) : (
        <Comment comment={_comment}/>
      )}
    </div>
  );
};

CommentContainer.propTypes = {
  comment: PropTypes.string,
  owner: PropTypes.string,
  onAddComment: PropTypes.func,
  submitOnBlur: PropTypes.bool,
};

CommentContainer.defaultProps = {
  comment: "",
  onAddComment: () => {
  },
  submitOnBlur: false,
};

export default CommentContainer;
