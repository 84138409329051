import React, { useState, Fragment, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { debounce } from "lodash";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import services from "services";
import s from "./AddContractForm.module.scss";
import { useParams } from "react-router-dom";

const AddContractForm = ({ currentContracts = [], addHandler, onClose }) => {
  const [searchedContracts, setSearchedContracts] = useState([]);
  const params = useParams();
  const filteredContracts = searchedContracts.filter((contract) =>
    Boolean(!currentContracts.filter((item) => item.id === contract.id).length)
  );

  const onSearchContract = useCallback(
    debounce((params) => {
      services.contractsServices
        .getContractsAutocomplete(params)
        .then((res) => {
          setSearchedContracts(res.data);
        });
    }, 300),
    []
  );

  const formik = useFormik({
    initialValues: {
      contract: {
        id: null,
        name: "",
      },
    },
    validate: ({ contract }) => {
      let errors = {};
      if (!Boolean(contract.id)) {
        errors.contract = "Please, choose the contract";
      }
      return errors;
    },
    onSubmit: (values) => {
      addHandler({
        contract_id: values.contract?.id,
      });
      onClose();
    },
  });

  const { handleSubmit, errors, setFieldValue, touched, values } = formik;

  const contractName =
    typeof values.contract === "object"
      ? values.contract.name
      : values.contract;

  useEffect(() => {
    services.contractsServices.getContracts().then((res) => {
      setSearchedContracts(res.data.contracts);
    });
    contractName &&
      onSearchContract({
        query: contractName,
      });
  }, []);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <AutoCompleteInput
        data={filteredContracts}
        value={contractName}
        placeholder="Start typing contract's name"
        onChange={(e) => {
          setFieldValue("contract", e.target.value);
          onSearchContract({
            query: e.target.value,
          });
        }}
        onSelect={(value) => {
          setFieldValue("contract", value);
        }}
        label="Contract"
        name="contract"
        className={s.field}
        emptyText={{
          text: "No work item results found.",
        }}
        error={errors.contract && touched.contract && errors.contract}
        withArrow
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Add
        </Button>
      </div>
    </form>
  );
};

export default AddContractForm;
