import React from "react";
import s from "./CustomDropDownsFormHeader.module.scss";
import Button from "../../../../elements/Button/Button";
import {useHistory} from "react-router-dom";
import {useTranslate} from "../../../../../hooks/useTranslate";

const CustomDropDownsFormHeader = ({isUpdate}) => {
  const history = useHistory();
  const [translateKey, translateValue] = useTranslate(isUpdate ? "Edit dropdown" : "New dropdown");
  return (
    <div className={s.header}>
      <h2 data-translate-key={translateKey}>{translateValue}</h2>
      <div className={s.btnWrap}>
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {isUpdate ? "Save" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default CustomDropDownsFormHeader;