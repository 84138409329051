import moment from "moment";

export const formatDate = (date, useNewFormat = false) => {
  if (useNewFormat) return moment(date).format("MM/DD/YYYY");
  return moment(date).format("DD/MM/YYYY");
};

export const fromHistoryDate = (date) => {
  const _date = new Date(date);
  const time = _date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const mdy = _date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return `${time.toLowerCase()}, ${mdy}`;
};

export const fromHistoryDateTwo = (date) => {
  const _date = new Date(date);
  const time = _date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const mdy = _date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return `${mdy} ${time.toLowerCase()} `;
};

export const fromDate = (date) => {
  if (!date) return "-";
  const dateParts = date.split("/");
  let _date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

  if (String(_date) === "Invalid Date") {
    _date = new Date(date);
  }
  const mdy = _date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return mdy;
};

export const dateFormatterWithoutTimezone = date => {
  return moment(date).utcOffset(0, false).format("MM/DD/YYYY");
};
