import React from "react";
import Input from "../../Input/Input";
import PropTypes from "prop-types";

const FormConstructorInput = ({field, values, setFieldValue, touched, errors}) => {
  return (
    <div>
      <Input
        type={field.type}
        value={values[field.key]}
        name={field.key}
        onChange={e => setFieldValue(field.key, e.target.value)}
        placeholder={field.placeholder}
        label={field.title}
        error={touched[field.key] && errors[field.key]}
        limitValue={field.limitValue}
        withCounter={Boolean(field.limitValue)}
      />
    </div>
  );
};

export default FormConstructorInput;

FormConstructorInput.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  touched: PropTypes.any,
  errors: PropTypes.any
};