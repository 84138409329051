import React, {useEffect, useState} from "react";
import classNames from "classnames";
import services from "services/index";
import s from "./Activities.module.scss";
import sBase from "../SidebarDashboard.module.scss";
import Tabs from "../../../../../elements/Tabs/Tabs";
import querystring from "query-string";
import {useLocation} from "react-router-dom";
import SidebarWidgetsLoading from "../../../../../loadingStates/Dashboard/SidebarWidgets/SidebarWidgetsLoading";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import CurrentDropDown from "./DropDown/CurrentDropDown";
import AssignedDropDown from "./DropDown/AssignedDropDown";
import CompletedDropDown from "./DropDown/CompletedDropDown";
import {useTranslation} from "react-i18next";

const Activities = () => {
  const [data, setData] = useState({
    current: [],
    completed: [],
    assigned_tasks: [],
  });
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const {tab} = querystring.parse(location.search);
  const { t } = useTranslation();

  useEffect(() => {
    services.dashboardServices.getActivities().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const {current, completed, assigned_tasks} = data;

  const getContent = () => {
    if (current.length || completed.length || assigned_tasks.length) {
      return (
        <div className={s.content}>
          <Tabs defaultActiveTab={tab}>
            <div label='Current'>
              {current.map((c, index) => <CurrentDropDown data={c} key={index}/>)}
            </div>
            <div label='Completed'>
              {completed.map((c, index) => <CompletedDropDown data={c} key={index}/>)}
            </div>
            <div label='Assigned tasks'>
              {assigned_tasks.map((a, index) => <AssignedDropDown data={a} key={index}/>)}
            </div>
          </Tabs>
        </div>
      )
    }

    return <EmptyContent padding={88} text='You don’t have activity yet.' withTranslate/>;
  }

  return (
    <section className={classNames(sBase.wrapper)}>
      {
        loading ? <SidebarWidgetsLoading/> :
          <>
            <h4 className={sBase.title} data-translate-key="your-activities">{t("your-activities")}</h4>
            {getContent()}
          </>
      }

    </section>
  );
};

export default Activities;


