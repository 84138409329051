import React, {useEffect, useState} from "react";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import AdminSettingsContracts from "./AdminSettingsContracts/AdminSettingsContracts";
import services from "../../../services";
import {useTranslation} from "react-i18next";


const AdminSettingsContractsContainer = () => {
  const [limitContracts, setLimitContracts] = useState();
  const {t} = useTranslation();

  useEffect(() => {
    services.adminServices.getContractsExpiration().then((res) => {
      setLimitContracts(res.data);
    });
  }, []);


  return (
    <div className={s.wrap}>
      <h2 className={s.title} data-translate-key="contracts">{t("contracts")}</h2>
      {limitContracts && <AdminSettingsContracts limitContracts={limitContracts}/>}
    </div>
  );
};

export default AdminSettingsContractsContainer;