import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import ProjectActualSavingsContainer
  from "../../containers/ProjectActualSavingsContainer/ProjectActualSavingsContainer";
import {useHistory, useParams} from "react-router-dom";
import {paths} from "../../../routes/paths";
import qs from "query-string";
import {useSelector} from "react-redux";
import {userRoles} from "../../../helpers/userPermissions/userRoles";

const ProjectActualSavingsPage = () => {
  const {id} = useParams();
  const history = useHistory();
  const {actualSavings_id} = qs.parse(history.location.search);
  const user = useSelector(state => state?.user);
  const isBusinessUser = user.roles.includes(userRoles.business_user);
  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);

  let breadcrumbs = [
    {
      name: "Projects",
      path: `/projects`,
      disabled: (isBusinessUser || isCategoryOwner),
    },
    {name: "Project details", path: `/projects/${id}`},
    {name: "Actual savings"},
  ];

  if (actualSavings_id) {
    breadcrumbs = [
      {
        path: paths.projects,
        name: "Requests"
      },
      {
        path: `/projects/${actualSavings_id}`,
        name: "Request details"
      },
      {
        name: "New project"
      }
    ];
  }
  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <ProjectActualSavingsContainer/>
    </DefaultLayout>
  );
};

export default ProjectActualSavingsPage;