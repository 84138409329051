export const getSearchedOptions = (
  search,
  options = [],
  searchedOptions = [],
  event = null
) => {
  const searched = searchedOptions
  options.map(e => {
    let parentEvent = event === null ? e : event
    if (e.label?.toLowerCase().includes(search)) {
      searched.push(event || e)
    } else if (e.subItems) {
      getSearchedOptions(search, e.subItems, searched, parentEvent)
    }
  })
  return searched
}
