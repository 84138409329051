import React, {useEffect, useState} from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {debounce} from "lodash";
import PropTypes from "prop-types";
import qs from "query-string";
import {useHistory} from "react-router-dom";
import services from "../../../services";
import {entityFormat} from "../../../helpers/triages/entityFormat";
import querystring from "query-string";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";
import {generateUniqueId} from "../../../helpers/uniqueId";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const AdminSettingsTriageFilter = ({className, currentEntity}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const {hierarchy, entity, prev_entity, name, ...query} = querystring.parse(history.location.search);
  const searchQuery = {entity, hierarchy, prev_entity, name};
  const [triageUsers, setTriageUsers] = useState([]);
  const uniqueKey = generateUniqueId();

  useEffect(() => {
    services.triageServices.getTriageUsers({entity: entityFormat(currentEntity)}).then(res => {
      if (res.data) setTriageUsers([...res.data.map(user => ({
        id: user.id,
        name: user.name,
      })), {
        id: "unassigned",
        name: t(getTranslateKey("Unassigned")),
      }]);
    });
  }, []);

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    history.push({
      search: qs.stringify({
        ...searchQuery,
        [filterName]: valuesIds,
      }, {arrayFormat: "comma"})
    });
  };

  const handleClearAll = () => {
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleSearch = debounce(term => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  return (
    <FilterBar
      filterName={`Filter ${currentEntity}`}
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={""}
      withSearch
      clearAllWithSearch={true}
    >
      <FilterBarDropdown
        key={uniqueKey}
        name='Users'
        options={triageUsers}
        defaultValues={query?.user_ids?.split(",")}
        handleChange={values => {
          handleChangeFilter("user_ids", values);
        }}
        searchPlaceholder='Search user'
      />
    </FilterBar>
  );
};

export default AdminSettingsTriageFilter;

AdminSettingsTriageFilter.propTypes = {
  filters: PropTypes.any,
  className: PropTypes.string,
};
