import React from "react";
import classNames from "classnames";
import CustomLink from "components/elements/CustomLink/CustomLink";
import VideoPlayer from "components/elements/VideoPlayer/VideoPlayer";
import s from "./ProcurementTogether.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const ProcurementTogether = () => {
  const {t} = useTranslation();
  return (
    <section className={s.procurementTogether}>
      <div className={s.landingContainer}>
        <div className={s.wrapper}>
          <div className={s.textBlock}>
            <h1
              className={s.title}
              data-translate-key="let's-rock-procurement-together"
              data-i18n="[html]content.body"
            >
              {t("let's-rock-procurement-together")}
            </h1>
            <h1 className={s.titleMobile}>
              {t("let's-rock-procurement-together")}
            </h1>
            <p className={s.text}
               data-translate-key={"make-category-management-even-smarter-across-your-company"}> {t(getTranslateKey("make-category-management-even-smarter-across-your-company"))}
            </p>
            <CustomLink
              className={classNames(s.buttonLink, s.rockButton)}
              type="nav-link"
              path="/contact-us"
            >
              Get a demo
            </CustomLink>
          </div>
          <div className={s.videoBlock}>
            <VideoPlayer
              link="https://video.wixstatic.com/video/eb30b9_d6adce7505f440c8be32248fb3eb1db1/1080p/mp4/file.mp4"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcurementTogether;
