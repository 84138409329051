import axios from "./axios";

export default {
  getLinkedProjects: async (id) => {
    try {
      const result = await axios.get(`/projects/${id}/linked_projects`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  addLinkedProjects: async (id, params) => {
    try {
      const result = await axios.post(
        `/projects/${id}/linked_projects`,
        params
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
  removeLinkedProject: async (id, projectId, config) => {
    try {
      const result = await axios.delete(
        `/projects/${id}/linked_projects/${projectId}`,
        config
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
};
