import React from "react";
import PropTypes from "prop-types";
import MainTable from "../MainTable/MainTable";
import { fromDate } from "helpers/dateFormat";
import s from "./ProjectAttachmentsTable.module.scss";

const ProjectAttachmentsTable = ({
  data,
  onDelete,
  onClick,
  onDownload,
  onPreview,
  havePermissions,
}) => {
  return (
    <MainTable
      className={s.attachmentsTable}
      dataTypes={[
        {
          title: "Name",
          value: ({ name }) => name,
          sortable: true,
          // onClick: (id, data) => onPreview(data),
        },
        {
          title: "Type",
          value: ({ file_type }) => file_type,
        },
        {
          title: "Date",
          value: ({ created_at }) => fromDate(created_at),
        },
        {
          title: "Owner",
          value: ({ owner }) => owner.name,
        },
      ]}
      data={data}
      options={({ id, path, name }) => {
        const options = [
          { title: "Delete", onClick: () => onDelete(id) },
          { title: "Download", onClick: () => onDownload({ path, name }) },
        ];

        !havePermissions.destroy && delete options[0];
        return options;
      }}
      button={
        havePermissions.update && {
          name: "Add attachment",
          onClick: onClick,
        }
      }
      ariaLabel="attachments"
    />
  );
};
ProjectAttachmentsTable.propTypes = {
  data: PropTypes.array,
  onDelete: PropTypes.func,
};
ProjectAttachmentsTable.defaultProps = {
  data: [],
  onDelete: () => {},
};
export default ProjectAttachmentsTable;
