import axios from "./axios";

export default {
  getSuppliers: async params => {
    try {
      const result = await axios.get("/suppliers", {params});
      return result;
    } catch (error) {
      throw error;
    }
  },
  getSupplier: async (id, params) => {
    try {
      const result = await axios.get(`/suppliers/${id}`, {params});
      return result;
    } catch (error) {
      throw error;
    }
  },
  putSupplier: async (id, logo) => {
    try {
      return await axios.put(`/suppliers/${id}`, {logo});
    } catch (error) {
      throw error;
    }
  },
  getSuppliersFilters: async () => {
    try {
      const result = await axios.get("/supplier_filters");
      return result;
    } catch (error) {
      throw error;
    }
  },
  createSupplier: async name => {
    try {
      const result = await axios.post("/suppliers", {name});
      return result;
    } catch (error) {
      throw error;
    }
  },
  getProjectSuppliers: async (id, params) => {
    try {
      const result = await axios.get(`/projects/${id}/suppliers`, {params});
      return result;
    } catch (error) {
      throw error;
    }
  },
  addProjectSupplier: async (id, params) => {
    try {
      const result = await axios.post(`/projects/${id}/supplier`, params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  editProjectSupplier: async (id, params) => {
    try {
      const result = await axios.put(`/projects/${id}/supplier`, params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  removeProjectSupplier: async (id, params, config) => {
    try {
      const result = await axios.delete(`/projects/${id}/supplier`, {
        data: params,
        ...config
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  suppliersAutocomplete: async params => {
    try {
      const result = await axios.get("/suppliers_autocomplete", {
        params
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  addCategorySupplier: async params => {
    try {
      const result = await axios.post("/category_suppliers", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  editSupplier: async (id, params) => {
    try {
      const result = await axios.put(`/suppliers/${id}`, params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  removeCategorySupplier: async id => {
    try {
      const result = await axios.delete(`category_suppliers/${id}`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  changeStatusCategorySupplier: async (id, params) => {
    try {
      return await axios.put(`category_suppliers/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  removeSupplier: async id => {
    try {
      const result = await axios.delete(`suppliers/${id}`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  supplierReportGeneral: async (id, params) => {
    try {
      return await axios.get(`suppliers/${id}/general`, {params});
    } catch (error) {
      throw error;
    }
  },
  supplierReportContracts: async (id, params) => {
    try {
      return await axios.get(`suppliers/${id}/contracts`, {params});
    } catch (error) {
      throw error;
    }
  },
  supplierReportProjects: async (id, params) => {
    try {
      return await axios.get(`suppliers/${id}/projects`, {params});
    } catch (error) {
      throw error;
    }
  },
  supplierReportStakeholders: async (id, params) => {
    try {
      return await axios.get(`suppliers/${id}/stakeholders`, {params});
    } catch (error) {
      throw error;
    }
  },
  getSuppliersServices: async (id, params) => {
    try {
      return await axios.get(`suppliers/${id}/services`, {params});
    } catch (error) {
      throw error;
    }
  },
  postSuppliersNewServices: async (id, params) => {
    try {
      return await axios.post(`suppliers/${id}/services`, params);
    } catch (error) {
      throw error;
    }
  },
  deleteSuppliersNewServices: async (id, idServ, params) => {
    try {
      return await axios.delete(`/suppliers/${id}/services/${idServ}`, params);
    } catch (error) {
      throw error;
    }
  },
  editSuppliersServices: async (id, params) => {
    try {
      return await axios.put(`/suppliers/${id}/services/${params.serviceId}`, params);
    } catch (error) {
      throw error;
    }
  },
};