import React from "react";
import HeaderNotificationDropListItem from "./HeaderNotificationDropListItem";
import CustomLink from "components/elements/CustomLink/CustomLink";
import s from "./HeaderNotificationDropList.module.scss";

const HeaderNotificationDropList = ({ data, onDelete, onViewport }) => {
  return (
    <ul className={s.dropList}>
      {data &&
        data.map((item) => (
          <HeaderNotificationDropListItem
            key={item.id}
            data={item}
            onDelete={onDelete}
            onEnterViewport={() => !item.is_readed && onViewport(item.id)}
          />
        ))}
      <li className={s.linkItem}>
        <CustomLink type="nav-link" path="/notifications">
          View all notifications
        </CustomLink>
      </li>
    </ul>
  );
};

export default HeaderNotificationDropList;
