import React, {useContext, useEffect, useState} from "react";
import s from "./ApproverForm.module.scss";
import sBase from "../ApprovalForm.module.scss";
import PropTypes from "prop-types";
import CustomDropdown from "../../../elements/CustomDropdown/CustomDropdown";
import {approvalTypes, approvedParams, approverTypes} from "../../../../helpers/approvalsDefaultData";
import services from "../../../../services";
import {ModeContext} from "../../../../contexts/ModeContext/ModeContext";
import {mods} from "../../../../contexts/ModeContext/mods";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const ApproverForm = ({values, setFieldValue, errors, touched}) => {
  const {mode} = useContext(ModeContext);
  const isViewMode = mode === mods.view;
  const [approvalGroups, setApprovalGroups] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    services.approvalServices.getApprovalGroups().then((res) => {
      const preparedApprovalGroups = res?.data || [];
      setApprovalGroups(preparedApprovalGroups.map(group => ({
        value: group.id,
        label: group.name,
      })));
    });
  }, []);

  const whatApprovedField = (
    <CustomDropdown
      options={approvedParams}
      value={values.what_approved}
      onChange={option => !isViewMode && setFieldValue("what_approved", option)}
      placeholder="Choose what should be approved"
      error={touched.what_approved && errors.what_approved && errors.what_approved.value}
      disabled={isViewMode}
    />
  );

  const renderContentByApprover = () => {
    if (values.approver.value === approverTypes[1].value) return (
      <>
        <span data-translate-key={getTranslateKey("for")}>
          {t(getTranslateKey("for"))}
        </span>
        <div className={sBase.input}>
          {whatApprovedField}
        </div>
      </>
    );

    if (values.approver.value === approverTypes[0].value) return (
      <>
        <span data-translate-key={getTranslateKey("is")}>
          {t(getTranslateKey("is"))}
        </span>
        <div className={sBase.input}>
          <CustomDropdown
            options={approvalGroups}
            value={values.approval_group}
            onChange={option => !isViewMode && setFieldValue("approval_group", option)}
            placeholder="Choose approval group"
            error={touched.approval_group && errors.approval_group && errors.approval_group.value}
            disabled={isViewMode}
          />
        </div>
      </>
    );
  };

  const renderApprovalGroupFields = () => {
    if (values.approver.value === approverTypes[0].value) return (
      <div className={sBase.inputGroup}>
        <div className={sBase.input}>
          <CustomDropdown
            options={approvalTypes}
            value={values.approval_type}
            onChange={option => !isViewMode && setFieldValue("approval_type", option)}
            placeholder="Choose approval type"
            error={touched.approval_type && errors.approval_type && errors.approval_type.value}
            disabled={isViewMode}
          />
        </div>
        <span>for</span>
        <div className={sBase.input}>
          {whatApprovedField}
        </div>
      </div>
    );
  };

  return (
    <div className={s.approverForm}>
      <h3 className={sBase.formGroupTitle} data-translate-key={getTranslateKey("Approver")}>
        {t(getTranslateKey("Approver"))}
      </h3>
      <div className={sBase.inputGroup}>
        <div className={sBase.input}>
          <CustomDropdown
            options={approverTypes}
            value={values.approver}
            onChange={option => !isViewMode && setFieldValue("approver", option)}
            placeholder="Choose approver"
            error={touched.approver && errors.approver && errors.approver.value}
            disabled={isViewMode}
          />
        </div>
        {renderContentByApprover()}
      </div>
      {renderApprovalGroupFields()}
    </div>
  );
};

export default ApproverForm;

ApproverForm.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  errors: PropTypes.any,
};