import React from "react";
import styles from "./NotesHistory.module.scss";
import PropTypes from "prop-types";
import {generateUniqueId} from "../../../../helpers/uniqueId";
import moment from "moment";

const NotesHistory = ({history}) => {
  return (
    <section className={styles.notesWrapper}>
      {
        history.map(historyItem => (
          <article key={generateUniqueId()} className={styles.singleHistory}>
            <header>
              <p>{historyItem.user.name}</p>
              <p>
                {moment(historyItem.date).format("MM/DD/YYYY h:mm a")}
              </p>
            </header>
            <main>
              <p>{historyItem.note || "-"}</p>
              {historyItem.attachments?.length
                ? historyItem.attachments.map((attachment, index) => (
                  <a
                    key={index}
                    href={attachment.path}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={styles.attachment}
                  >
                    {attachment.name} {index !== historyItem.attachments.length - 1 ? "," : ""}
                  </a>
                ))
                : "-"}
            </main>
          </article>
        ))
      }
    </section>
  );
};

export default NotesHistory;

NotesHistory.propTypes = {
  history: PropTypes.array
};