export const getSavingsWidgetTitle = (selectedYears, chartData) => {

  const ALLOWANCE_MONTHS_COUNT = chartData?.allowance_months_count || 0,
    ALLOWANCE_RULE = chartData?.allowance_rule || ""

  if (ALLOWANCE_RULE === "full") {
    return `Total department impact of ${selectedYears?.join(", ") ||
    "all"} activities`
  } else if (ALLOWANCE_RULE === "months") {
    return `${ALLOWANCE_MONTHS_COUNT} months department impact of ${selectedYears?.join(
      ", "
    ) || "all"} activities`
  } else {
    return `Total department impact of all activities`
  }
}