import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import s from "./Tabs.module.scss";
import Tab from "./Tab/Tab";
import classNames from "classnames";

const Tabs = (
  {
    children,
    handleClick,
    defaultActiveTab,
    className,
    styled,
    tabClassName,
    additional = null
  }
) => {
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const childrenArrays = React.Children.toArray(children);
    setActiveTab(childrenArrays[0].props.label);
  }, []);

  useEffect(() => {
    if (defaultActiveTab) {
      setActiveTab(defaultActiveTab);
    }
  }, [defaultActiveTab]);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    handleClick && handleClick(tab);
  };

  return (
    <div>
      <div className={s.tabsWrapper}>
        <ul
          className={classNames(
            s.tabs,
            {
              [s.primary]: styled === "primary",
              [s.secondary]: styled === "secondary",
            },
            className
          )}
        >
          {React.Children.map(children, (child) => {
            const {label, disabled} = child.props;
            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
                styled={styled}
                className={classNames(
                  s.tab,
                  {
                    [s.secondary]: styled === "secondary",
                  },
                  tabClassName
                )}
                disabled={disabled}
              />
            );
          })}
        </ul>
        {additional}
      </div>
      <div>
        {React.Children.map(children, (child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};
Tabs.propTypes = {
  styled: PropTypes.oneOf(["primary", "secondary"]),
};
Tabs.defaultProps = {
  styled: "primary",
};

export default Tabs;
