import React from "react";
import Popup from "../Popup/Popup";
import CurrencySelectionField from "../../elements/CurrencySelectionField/CurrencySelectionField";
import s from "./ActualSavingsPopup.module.scss";
import {useFormik} from "formik";
import {object, string} from "yup";
import {currencyData} from "../../../helpers/helpData";
import PropTypes from "prop-types";
import Button from "../../elements/Button/Button";

const ActualSavingsPopup = ({title, text, setShow, data, onSubmitEdit}) => {

  const formik = useFormik({
    initialValues: {
      id: data.group_id,
      type: data.group_name,
      actual: data.actual,
      actual_currency: "USD",
      forecasted: data.forecasted,
      forecasted_currency: "USD",
      month_id: data.id
    },
    validationSchema: object({
      actual: string()
        .required("Please, enter your actual savings")
        .matches(/^(?![$-])/, "Value should be equal to or greater than 0")
        .max(12, "Max digits quantity is 12"),
      forecasted: string()
        .required("Please, enter your forecasted savings")
        .matches(/^(?![$-])/, "Value should be equal to or greater than 0")
        .max(12, "Max digits quantity is 12"),
    }),
    onSubmit: (values) => {
      onSubmitEdit({
        actual: values.actual,
        forecasted: values.forecasted,
        group_id: values.id,
        month_id: data.id
      });
      setShow(false)
    }
  });
  const {
    handleSubmit,
    touched,
    values,
    errors,
    setFieldValue,
  } = formik;

  return (
    <Popup
      className={s.popPupActualSaving}
      title={title}
      onClose={() => setShow(false)}
      text={text}
    >
      <form onSubmit={handleSubmit}>
        <CurrencySelectionField
          className={s.currencyItem}
          options={currencyData}
          value={values.actual}
          defaultValue={values.currency}
          onChange={({fieldVal, ...currency}) => {
            setFieldValue("currency", currency);
            setFieldValue("actual", fieldVal);
          }}
          error={touched.actual && errors.actual}
          label="Actual savings"
          fieldPlaceholder="000,000"
        />
        <CurrencySelectionField
          value={values.forecasted}
          defaultValue={values.currency}
          onChange={({fieldVal, ...currency}) => {
            setFieldValue("currency", currency);
            setFieldValue("forecasted", fieldVal);
          }}
          error={touched.forecasted && errors.forecasted}
          label="Forecasted savings"
          fieldPlaceholder="000,000"
        />
        <div className={s.popupBtnsWrap}>
          <Button
            styled="secondary"
            type="reset"
            className="popupBtn"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button styled="primary" type="submit" className="popupBtn">
            Save
          </Button>
        </div>
      </form>
    </Popup>
  );
};

export default ActualSavingsPopup;

ActualSavingsPopup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  setShow: PropTypes.func,
  data: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};