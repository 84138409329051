import React, {useEffect, useState} from "react";
import StepsWrapper from "./StepsWrapper/StepsWrapper";
import services from "../../../../../services";
import {useParams} from "react-router-dom";
import DetailLoading from "../../../../loadingStates/Detail/Detail";
import SingleStep from "./SingleStep/SingleStep";
import Popup from "../../../../popups/Popup/Popup";
import RequestTypesStepForm from "../../../../forms/RequestTypesStepForm/RequestTypesStepForm";
import {divideSteps} from "../../helpers/divideSteps";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import Information from "../../../../elements/Information/Information";
import s from "./Steps.module.scss";

const Steps = () => {
  let {id} = useParams();
  const [addStepPopUp, setStepPopUp] = useState(false);
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    services.requestTypeServices.getRequestTypesSteps(id).then(res => {
      if (res.data) {
        setSteps(res.data);
        setLoading(false);
      }
    });
  };

  const addStep = values => {
    services.requestTypeServices.addRequestTypesSteps(id, values).then(res => {
      if (res.data) setSteps(steps => [...steps, res.data]);
      setStepPopUp(false);
    });
  };

  const editStep = editedStep =>
    setSteps(steps => steps.map(step => step.id === editedStep.id ? editedStep : step));

  const deleteStep = deletedStepId => setSteps(steps => steps.filter(step => step.id !== deletedStepId));

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = params => {
    const {destination, source, draggableId} = params;
    if (destination?.droppableId === source?.droppableId) {
      const fields = steps.find(step => step.id === destination.droppableId).step_fields;
      const reorderedFieldIds = reorder(fields, source.index, destination.index).map(field => field.id).join(",");
      services.requestTypeServices.reorderFieldsInStep(source?.droppableId, draggableId, reorderedFieldIds).then(res => {
        if (res.data) load();
      });
    } else {
      services.requestTypeServices.reorderFieldBetweenSteps(source?.droppableId, draggableId, destination?.droppableId).then(res => {
        if (res.data) load();
      });
    }
  };

  if (loading && !steps.length) return <DetailLoading length={2}/>;

  return (
    <>
      <Information message="If a field is already used in requests, it can’t be deleted." informationClass={s.informationFirst}/>
      <Information message="A step can only be deleted if it contains no fields." informationClass={s.informationSecond}/>
      <StepsWrapper count={steps.length} addAction={() => setStepPopUp(true)}>
        <SingleStep step={divideSteps(steps).defaultSteps[0]}/>
        <DragDropContext onDragEnd={onDragEnd}>
          {divideSteps(steps).customSteps.map(step => {
            return (
              <Droppable droppableId={step.id} key={step.id}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <SingleStep step={step} editAction={editStep} deleteAction={deleteStep}/>
                  </div>
                )}
              </Droppable>
            );
          })}
        </DragDropContext>
        <SingleStep step={divideSteps(steps).defaultSteps[1]}/>
      </StepsWrapper>
      {
        addStepPopUp && (
          <Popup
            title="New step"
            onClose={() => setStepPopUp(false)}
            text="Please, complete the following fields to add a new step"
          >
            <RequestTypesStepForm
              onSubmit={addStep}
              onCLose={() => setStepPopUp(false)}
            />
          </Popup>
        )
      }
    </>
  );
};

export default Steps;
