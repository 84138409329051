import {getConditionResult} from "./getConditionResult";

export const generateValidationErrors = (fields, values) => {
  let errors = {};
  const preparedFields = fields.filter(field => !field.optional);
  const preparedStringFields = fields.filter(field => field.type === "String" && field.key !== "name");

  preparedFields.forEach(field => {
    if (field.conditions && field.conditions !== "()") {
      if (eval(getConditionResult(field.conditions, values)) ?? true) {
        if (!Boolean(values[field.key]?.value)) errors[field.key] = {
          value: "Field is required",
        };
      }
    } else {
      if (!Boolean(values[field.key]?.value)) errors[field.key] = {
        value: "Field is required",
      };
    }
  });

  preparedStringFields.forEach(field => {
    if (values[field.key]?.value?.length > 1000) errors[field.key] = {
      value: "The filed is too long. It should be 1000 characters or less.",
    };
  });

  if (values?.name?.value?.length > 80) {
    errors.name = {
      value: "Name cannot have length greater than 80.",
    };
  }

  if (values?.description?.value?.length > 180) {
    errors.description = {
      value: "The Description is too long. It should be 180 characters or less.",
    };
  }

  return errors;
};