const formatLabel = (label, savingsTypesData) => {
  return savingsTypesData.filter(type => type.label === label)[0]?.label;
};
export const formatDataToArray = (data, savingsTypesData) => {
  const arr = [];
  Object.keys(data).forEach((label) => {
    if (
      data[label].hasOwnProperty("not_completed") &&
      data[label].hasOwnProperty("completed")
    ) {
      const val1 = data[label].not_completed;
      const val2 = data[label].completed;
      const newItem = [
        [formatLabel(label, savingsTypesData), (val1 + val2).toString()],
        ["Active&Planned", val1],
        ["Completed", val2],
      ];
      arr.push(newItem);
    }
  });

  return arr;
};
