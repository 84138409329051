export const formatMilestonesData = (
	milestones,
	oneDayPercent,
	oneDaySeconds,
	projectStartDate,
	projectEndDate
) => {
	const milestones_widths = {}
	const milestones_data = {}
	const windows_widths = {}
	const windows_data = {}

	milestones.forEach((milestone, index) => {
		const milestoneWidth = milestone.anticipated_time_spend * oneDayPercent
		const prevIndex = index - 1
		milestones_widths[milestone.id] = milestoneWidth
		milestones_data[milestone.id] = {
			start_date: new Date(milestone.start_date).getTime(),
			end_date: new Date(milestone.end_date).getTime(),
			anticipated_time_spend: milestone.anticipated_time_spend
		}
		if (prevIndex < 0) {
			const window_days = Math.floor(
				(new Date(milestone.start_date) - new Date(projectStartDate)) /
					oneDaySeconds
			)
			windows_widths[milestone.id] = window_days * oneDayPercent
			windows_data[milestone.id] = {
				anticipated_time_spend: window_days
			}
			if (index === milestones.length - 1) {
				const window_days = Math.floor(
					(new Date(projectEndDate) - new Date(milestone.end_date)) /
						oneDaySeconds
				)
				windows_widths['after_last'] = window_days * oneDayPercent
				windows_data['after_last'] = {
					anticipated_time_spend: window_days
				}
			}
		} else {
			const window_days =
				Math.floor(
					(new Date(milestone.start_date) -
						new Date(milestones[prevIndex].end_date)) /
						oneDaySeconds
				) - 1
			windows_widths[milestone.id] = window_days * oneDayPercent
			windows_data[milestone.id] = {
				anticipated_time_spend: window_days
			}
			if (index === milestones.length - 1) {
				const window_days = Math.floor(
					(new Date(projectEndDate) - new Date(milestone.end_date)) /
						oneDaySeconds
				)
				windows_widths['after_last'] = window_days * oneDayPercent
				windows_data['after_last'] = {
					anticipated_time_spend: window_days
				}
			}
		}
	})
	return { milestones_widths, milestones_data, windows_widths, windows_data }
}
