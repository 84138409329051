import React from "react";
import Popup from "components/popups/Popup/Popup";
import AdminSettingsFinancialSavingsForm
  from "components/forms/AdminSettingsFinancialSavingsForm/AdminSettingsFinancialSavingsForm";
import PropTypes from "prop-types";

const AddFinancialSavingsTypePopup = ({onClose, onSubmit}) => {
  return (
    <Popup
      onClose={onClose}
      title="New financial savings type"
      text="Please, complete the following fields to create a new savings type"
    >
      <AdminSettingsFinancialSavingsForm
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Popup>
  );
};

export default AddFinancialSavingsTypePopup;

AddFinancialSavingsTypePopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
