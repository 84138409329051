import React, {useState, useEffect} from "react";
import s from "./GoalsWidget.module.scss";
import GoalCard from "./components/GoalCard/GoalCard";
import PropTypes from "prop-types";
import classNames from "classnames";
import EditPopup from "./popups/EditPopup/EditPopup";
import services from "services";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {userRoles} from "helpers/userPermissions/userRoles";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import {updateChangedUsers} from "../../containers/CompanyGoalsContainer/helpers/updateUsers";
import {checkRoles} from "../../../helpers/checkRoles";
import {deepFind} from "../../../helpers/deepFind";

const GoalsWidget = (
  {
    data,
    organization,
    onEdit,
    loadingState,
    onSelectDepartment
  }
) => {
  const history = useHistory();
  const {roles, department, id} = useSelector(state => state.user);
  const [currOrganizationId, setCurrOrganizationId] = useState(organization.id);
  const [currDepartmentId, setCurrDepartmentId] = useState(null);
  const [currUserId, setCurrUserId] = useState(null);
  const [popup, setPopup] = useState("edit");
  const [currentGoal, setCurrentGoal] = useState(null);
  const [currDepartmentIndex, setCurrDepartmentIndex] = useState(0);
  const [users, setUsers] = useState(null);
  const [validationData, setValidationData] = useState({
    parentData: null,
    generalData: null,
  });
  const [alert, setAlert] = useState({message: "", type: ""});
  const {goal: generalGoal, departments} = data;

  useEffect(() => {
    onSelectDepartment(Boolean(currDepartmentId));
  }, [currDepartmentId, onSelectDepartment]);

  const editAccessValidator = (userId, goalType) => {
    if (checkRoles(roles, [userRoles.cpo, userRoles.admin, userRoles.procurement_admin])) return true;

    if (goalType === "department") {
      return false;
    }

    if (goalType === "user") {
      const allUsers = departments?.find((e, index) => !loadingState ? e.id === currDepartmentId : !index)?.users;
      if (allUsers.every(u => u?.id)) {
        const currentUser = deepFind(allUsers, id);
        if (currentUser) {
          return Boolean(deepFind(currentUser.users, userId));
        }
      }
    }
  };

  const visibleDepartments =
    currOrganizationId && departments && departments.length > 0;

  const visibleUsers =
    loadingState ||
    (currOrganizationId &&
      currDepartmentId &&
      departments?.find(({id}) => id === currDepartmentId)?.users?.length > 0);

  const handleEditGoal = values => {
    services.companyGoalsServices
      .changeCompanyGoal({source_id: currentGoal.id, ...values})
      .then(res => {
        const prepareValues = {...currentGoal, ...res?.data};
        onEdit(prepareValues);
        if (prepareValues?.source === "user") {
          setUsers(updateChangedUsers(users, prepareValues));
        }
      })
      .catch(() =>
        setAlert({
          type: "error",
          message: "Something went wrong. Please, try again."
        })
      )
      .finally(() => setPopup(null));
  };

  const getValidationData = (mainGoal, goalsList, childList) => {
    let preparedParentData = null;
    let preparedGeneralData = {
      annual_goal: 0,
      quarter1: 0,
      quarter2: 0,
      quarter3: 0,
      quarter4: 0,
    };
    let preparedChildData = null;

    if (mainGoal) {
      preparedParentData = {
        annual_goal: mainGoal.annual_goal,
        quarter1: mainGoal.quarter1,
        quarter2: mainGoal.quarter2,
        quarter3: mainGoal.quarter3,
        quarter4: mainGoal.quarter4,
      };
    }

    if (goalsList.length) {
      preparedGeneralData = goalsList.reduce((accum, current) => {
        return {
          annual_goal: accum.annual_goal + current.annual_goal,
          quarter1: accum.quarter1 + current.quarter1,
          quarter2: accum.quarter2 + current.quarter2,
          quarter3: accum.quarter3 + current.quarter3,
          quarter4: accum.quarter4 + current.quarter4,
        };
      });
    }

    if (childList.length) {
      preparedChildData = childList.reduce((accum, current) => {
        return {
          annual_goal: accum.annual_goal + current.annual_goal,
          quarter1: accum.quarter1 + current.quarter1,
          quarter2: accum.quarter2 + current.quarter2,
          quarter3: accum.quarter3 + current.quarter3,
          quarter4: accum.quarter4 + current.quarter4,
        };
      });
    }

    return {
      parentData: preparedParentData,
      generalData: preparedGeneralData,
      childData: preparedChildData,
    };
  };

  const getLineHeight = () => {
    const currentUsers = data?.departments[currDepartmentIndex]?.users;

    if (data?.departments.length < currentUsers.length || currDepartmentIndex < currentUsers.length) {
      if (currUserId) return (currentUsers.length - 1) * (24 + 94.5) + 188;
      return (currentUsers.length - 1) * (24 + 98.3);
    }
    return (currDepartmentIndex * (24 + 94.5)) + 188;
  }

  const isHasItem = (item, itemId) => {
    if (item.id === itemId) return true;
    return item.users ? item.users.some(item => isHasItem(item, itemId)) : false;
  }

  const hasOpenedChildren = (item, itemId) => {
    return {
      ...item,
      currentTree: isHasItem(item, itemId),
      users: item.users.map(item => hasOpenedChildren(item, itemId))
    }
  }

  const isOpenTree = (item, itemId) => {
    if (item.id === itemId && item.currentTree) return !item.isOpen;
    return item.users ? item.users.some(item => isHasItem(item, itemId)) : false;
  }

  const onOpenTree = (item, itemId, idx) => {
    return {
      ...item,
      isOpen: isOpenTree(item, itemId),
      position: item.position ? item.position : idx,
      users: item.users.map(item => onOpenTree(item, itemId))
    }
  }

  const updateUsers = (id, idx) => {
    if (!users) {
      const currentDepartment = departments?.find((e, index) => !loadingState ? e.id === currDepartmentId : !index);
      setUsers(currentDepartment.users.map(user => (user.id === id && !user.isOpen) ?
        ({...user, isOpen: true, position: idx}) :
        ({...user, isOpen: false, position: idx})
      ));
    } else {
      let preparedData = users.map(item => hasOpenedChildren(item, id));
      preparedData = preparedData.map(item => onOpenTree(item, id, idx));
      setUsers(preparedData);
    }
  }

  const recursiveUsers = (users, preparedUsers = []) => {
    const prepareUsers = preparedUsers;

    if (users?.length) users.forEach(user => {
      if (user.isOpen) prepareUsers.push(user);
      if (user.users?.length) recursiveUsers(user.users, prepareUsers);
    });

    return prepareUsers;
  }

  const getLineHeightUsers = user => {
    if (user.position < user.users.length) {
      if (user.users.some(u => u.isOpen)) return (user.users.length - 1) * (24 + 94.5) + 188;
      return (user.users.length - 1) * (24 + 99.5);
    }
    return (user.position * (24 + 94.5)) + 188;
  }

  return (
    <div className={s.goalsWidget}>
      {generalGoal && (
        <ul className={s.list}>
          <GoalCard
            className={classNames(s.goalCard, s.first, {
              [s.afterLine]: visibleDepartments
            })}
            quarters={[
              [`${generalGoal.quarter1}`, `0`],
              [`${generalGoal.quarter2}`, `0`],
              [`${generalGoal.quarter3}`, `0`],
              [`${generalGoal.quarter4}`, `0`]
            ]}
            annualGoal={[`${generalGoal.annual_goal}`, `0`]}
            active={currOrganizationId === organization.id}
            onClick={() => {
              currOrganizationId
                ? setCurrOrganizationId(null)
                : setCurrOrganizationId(organization.id);
              setUsers(null);
            }}
            onEdit={
              editAccessValidator(data.id, "company") &&
              (() => {
                setPopup("edit");
                setCurrentGoal({...generalGoal, id: data.id, source: "company"});
                setValidationData(
                  getValidationData(
                    null,
                    [generalGoal],
                    departments.map(d => d.goal),
                  )
                );
              })
            }
            loadingState={loadingState}
            onRedirect={() =>
              history.push(`/company-goals/${data.id}?source=company`)
            }
            type="company"
            subDataType={departments?.length > 0 && 'departments'}
            subDataCount={departments.length}
          />
        </ul>
      )}
      {visibleDepartments && (
        <ul className={s.list}>
          {departments.map(({name, goal, id, users}, index) => (
            <GoalCard
              className={classNames(s.goalCard, s.beforeLine, {
                [s.afterLine]: visibleUsers,
                [s.first]: !index
              })}
              name={name}
              quarters={[
                [`${goal?.quarter1 ?? 0}`, `${goal?.quarter1_remaining ?? 0}`],
                [`${goal?.quarter2 ?? 0}`, `${goal?.quarter2_remaining ?? 0}`],
                [`${goal?.quarter3 ?? 0}`, `${goal?.quarter3_remaining ?? 0}`],
                [`${goal?.quarter4 ?? 0}`, `${goal?.quarter4_remaining ?? 0}`]
              ]}
              annualGoal={[`${goal?.annual_goal ?? 0}`, goal?.anual_goal_remaining ?? 0]}
              key={index}
              active={loadingState ? !Boolean(index) : currDepartmentId === id}
              onClick={() => {
                if (currDepartmentId) {
                  if (currDepartmentId === id) {
                    setCurrDepartmentId(null);
                    setCurrDepartmentIndex(0);
                  } else {
                    setCurrDepartmentId(id);
                    setCurrDepartmentIndex(index);
                  }
                } else {
                  setCurrDepartmentId(id);
                  setCurrDepartmentIndex(index);
                }
                setCurrUserId(null);
                setUsers(null);
              }}
              onEdit={
                editAccessValidator(id, "department") &&
                (() => {
                  setPopup("edit");
                  setCurrentGoal({...goal, id, source: "department", name});
                  const preparedGoal = departments?.find(d => d.id === id).users.map(u => u.goal);
                  setValidationData(
                    getValidationData(
                      generalGoal,
                      departments.filter(d => d.id !== id)?.map(d => d.goal),
                      preparedGoal,
                    )
                  );
                })
              }
              loadingState={loadingState}
              onRedirect={() =>
                history.push(`/company-goals/${id}?source=department`)
              }
              type="department"
              subDataType={users?.length > 0 && 'users'}
              subDataCount={users?.length}
            />
          ))}
          <li className={s.line}/>
        </ul>
      )}
      {visibleUsers && (
        <ul className={s.list}>
          {departments
            ?.find((e, index) =>
              !loadingState ? e.id === currDepartmentId : !index
            )
            ?.users.map((user, index) => {
                const {first_name, last_name, goal, id} = user;
                return (
                  <GoalCard
                    className={classNames(s.goalCard, s.beforeLine, {
                      [s.first]: !index,
                      [s.afterLine]: recursiveUsers(users).find(user => user?.users.length)
                    })}
                    name={`${first_name} ${last_name}`}
                    teamName={user.team_name}
                    quarters={[
                      [`${goal?.quarter1 ?? 0}`, `${goal?.quarter1_remaining ?? 0}`],
                      [`${goal?.quarter2 ?? 0}`, `${goal?.quarter2_remaining ?? 0}`],
                      [`${goal?.quarter3 ?? 0}`, `${goal?.quarter3_remaining ?? 0}`],
                      [`${goal?.quarter4 ?? 0}`, `${goal?.quarter4_remaining ?? 0}`]
                    ]}
                    annualGoal={[`${goal?.annual_goal ?? 0}`, goal?.anual_goal_remaining ?? 0]}
                    key={index}
                    active={currUserId === id}
                    onClick={() => {
                      if (currUserId) {
                        if (currUserId === id) {
                          setCurrUserId(null);
                        } else {
                          setCurrUserId(id);
                        }
                      } else {
                        setCurrUserId(id);
                      }

                      updateUsers(id, index);
                    }}
                    onEdit={
                      editAccessValidator(id, "user") &&
                      (() => {
                        setPopup("edit");
                        setCurrentGoal({
                          ...goal,
                          id,
                          source: "user",
                          department_id: currDepartmentId,
                          name: `${first_name} ${last_name}`
                        });
                        const preparedDepartment = departments?.find((e, index) => !loadingState ? e.id === currDepartmentId : !index);
                        setValidationData(
                          getValidationData(
                            preparedDepartment?.goal,
                            preparedDepartment.users.filter(u => u.id !== id)?.map(u => u.goal),
                            [],
                          )
                        );
                      })
                    }
                    type={user.team_name ? "team" : "user"}
                    subDataType={user?.users?.length > 0 && 'users'}
                    subDataCount={user?.users?.length}
                    loadingState={loadingState}
                    onRedirect={() =>
                      history.push(`/company-goals/${id}?source=user`)
                    }
                  />
                )
              }
            )}
        </ul>
      )}
      {
        users && recursiveUsers(users).map((userData, idx) => {
          return (
            <ul className={s.list} key={idx}>
              {
                userData.users.map((user, index) => {
                  const {first_name, last_name, goal, id, isOpen} = user;
                  return (
                    <GoalCard
                      className={classNames(s.goalCard, s.beforeLine, {
                        [s.first]: !index,
                        [s.afterLine]: recursiveUsers(users).find(user => user.id === id && user?.users.length)
                      })}
                      name={`${first_name} ${last_name}`}
                      teamName={user.team_name}
                      quarters={[
                        [`${goal?.quarter1 ?? 0}`, `${goal?.quarter1_remaining ?? 0}`],
                        [`${goal?.quarter2 ?? 0}`, `${goal?.quarter2_remaining ?? 0}`],
                        [`${goal?.quarter3 ?? 0}`, `${goal?.quarter3_remaining ?? 0}`],
                        [`${goal?.quarter4 ?? 0}`, `${goal?.quarter4_remaining ?? 0}`]
                      ]}
                      annualGoal={[`${goal?.annual_goal ?? 0}`, goal?.anual_goal_remaining ?? 0]}
                      key={index}
                      active={isOpen}
                      onClick={() => updateUsers(id, index)}
                      onEdit={
                        editAccessValidator(id, "user") &&
                        (() => {
                          setPopup("edit");
                          setCurrentGoal({
                            ...goal,
                            id,
                            source: "user",
                            department_id: currDepartmentId,
                            name: `${first_name} ${last_name}`
                          });
                          const preparedUser = users?.find((e, index) => !loadingState ? e.id === currUserId : !index);
                          setValidationData(
                            getValidationData(
                              preparedUser?.goal,
                              preparedUser.users.filter(u => u.id !== id)?.map(u => u.goal),
                              [],
                            )
                          );
                        })
                      }
                      type={user.team_name ? "team" : "user"}
                      subDataType={user?.users?.length > 0 && 'users'}
                      subDataCount={user?.users?.length}
                      loadingState={loadingState}
                      onRedirect={() =>
                        history.push(`/company-goals/${id}?source=user`)
                      }
                    />
                  )
                })
              }
            </ul>
          )
        })
      }
      {visibleUsers && (
        <li
          className={classNames(s.line, s.last)}
          style={{
            height: getLineHeight(),
          }}
        />
      )}
      {
        recursiveUsers(users).map((user, index) => {
          if (user?.users.length) return (
            <li
              className={classNames(s.line, s.last)}
              key={index}
              style={{
                left: `calc( calc( 250px + 38px) * ${3 + index} - 19px)`,
                height: getLineHeightUsers(user),
              }}
            />
          )
        })
      }
      {popup === "edit" && currentGoal && (
        <EditPopup
          onClose={() => setPopup(null)}
          onSubmit={handleEditGoal}
          defaultValues={currentGoal}
          validationData={validationData}
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </div>
  );
};
GoalsWidget.propTypes = {
  data: PropTypes.object,
  organization: PropTypes.object,
  onEdit: PropTypes.func,
  loadingState: PropTypes.bool,
  onSelectDepartment: PropTypes.func
};
GoalsWidget.defaultProps = {
  data: {},
  organization: {},
  onEdit: () => {
  },
  loadingState: false,
  onSelectDepartment: () => {
  }
};

export default GoalsWidget;
