import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import ContractsContainer from "components/containers/ContractsContainer/ContractsContainer";

const ContractsPage = () => {
  return (
    <DefaultLayout withRightSidebar={true}>
      <ContractsContainer />
    </DefaultLayout>
  );
};

export default ContractsPage;
