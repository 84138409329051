const fileTypes = {
  "application/msword": ".doc",
  "application/pdf": ".pdf",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    ".pptx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx"
}
export const validateAttachments = (selectedFiles, files) => {
  return selectedFiles.filter(
    file =>
      !files.filter(
        existFile =>
          file.name === existFile.name &&
          file.size === existFile.size &&
          (file.type === existFile.type ||
            fileTypes[file.type] === existFile.file_type)
      ).length
  )
}
