import React from "react";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import PropTypes from "prop-types";
import AdditionalHeader from "../AdditionalHeader/AdditionalHeader";
import styles from "../RadioGroupField/RadioGroupField.module.scss";

const AdditionalYesNo = ({values, setFieldValue, field, errors}) => {
  const {question, key} = field;

  const radio = <Radio
    color="primary"
    icon={
      <svg width="20" height="20" viewBox="0 0 24 24" fill="#2F78CD" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
      </svg>
    }
    checkedIcon={
      <svg width="20" height="20" viewBox="0 0 24 24" fill="#2F78CD" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
      </svg>
    }
  />;

  return (
    <>
      <AdditionalHeader questionName={question.question_name} isRequired={question.is_required}/>
      <RadioGroup aria-label="radioGroup" name="radioGroup">
        <FormControlLabel
          onClick={() => setFieldValue(field.key, {
            value: "true",
            question_id: question.question_id
          })}
          checked={values[key]?.value === "true"}
          value={"yes"}
          control={radio}
          label={"Yes"}/>
        <FormControlLabel
          onClick={() => setFieldValue(field.key, {
            value: "false",
            question_id: question.question_id
          })}
          checked={values[key]?.value === "false"}
          value={"no"}
          control={radio}
          label={"No"}/>
      </RadioGroup>
      {errors[field.key] && <span className={styles.errorMessage}>{errors[field.key].value}</span>}
    </>
  );
};

export default AdditionalYesNo;

AdditionalYesNo.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any,
};