import React, {useEffect, useState} from "react";
import EmptyBlock from "../../../elements/EmptyBlock/EmptyBlock";
import Popup from "../../../popups/Popup/Popup";
import RequestTypesForm from "../../../forms/RequestTypesForm/RequestTypesForm";
import TableLoading from "../../../loadingStates/Table/Table";
import RequestTypesTable from "../../tables/RequestTypesTable/RequestTypesTable";
import services from "../../../../services";
import {emptyRequestTypes} from "./helpers/emptyData";
import querystring from "query-string";
import {useHistory, useLocation} from "react-router-dom";
import Information from "../../../elements/Information/Information";
import s from "./RequestTypesTab.module.scss";

const RequestTypesTab = () => {
  const history = useHistory();
  const location = useLocation();
  const [popUp, setPopUp] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const [data, setData] = useState(emptyRequestTypes);
  const [loading, setLoading] = useState(true);
  const {per_page, request_types, request_types_count} = data;
  const query = querystring.parse(history.location.search);

  useEffect(() => {
    services.requestTypeServices.getRequestTypesAdmin({
      page: query.page
    }).then(res => {
      setData(res.data);
      setLoading(false);
    });
  }, [location.search]);

  const addHandler = values => {
    services.requestTypeServices.addRequestTypes({
      ...values,
      relation: values.relation.value === "None" ? "" : values.relation.value
    }).then(res => {
      if (res.data) {
        setData({
          ...data,
          request_types: [...data.request_types, res.data],
          request_types_count: request_types_count + 1
        });
        setPopUp(null);
      }
    });
  };

  const editHandler = values => {
    services.requestTypeServices.editRequestTypes(currentType.id, {
      ...values,
      relation: values.relation.value === "None" ? "" : values.relation.value
    }).then(res => {
      if (res.data) {
        setData({
          ...data, request_types: data.request_types.map(requestType => {
            if (requestType.id === res.data.id) return res.data;
            return requestType;
          })
        });
        setPopUp(null);
      }
    });
  };

  const deleteHandler = () => {
    services.requestTypeServices.deleteRequestTypes(currentType.id).then(() => {
      setData({
        ...data, request_types: data.request_types
          .filter(requestType => requestType.id !== currentType.id),
        request_types_count: request_types_count - 1
      });
      setPopUp(null);
    });
  };

  const onPageChange = page => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  const renderContent = () => {
    if (request_types.length) return (
      <>
      <Information message="If a request type has at least 1 request, it can’t be deleted, only archived." informationClass={s.information}/>
      <RequestTypesTable
        data={request_types}
        totalCount={request_types_count}
        perPage={per_page}
        onAdd={() => setPopUp("add")}
        onEdit={item => {
          setCurrentType(item);
          setPopUp("edit");
        }}
        onDelete={item => {
          setCurrentType(item);
          setPopUp("delete");
        }}
        onPageChange={onPageChange}
      />
      </>
    );

    return (
      <EmptyBlock
        subtitle="You don’t have request types yet. You can add a new one."
        buttonTitle="New type"
        onClick={() => setPopUp("add")}
      />
    );
  };

  return (
    <>
      {loading ? <TableLoading length={7}/> : renderContent()}
      {
        popUp === "add" && (
          <Popup
            title="New type"
            onClose={() => setPopUp(null)}
          >
            <RequestTypesForm
              onSubmit={values => addHandler(values)}
              onCLose={() => setPopUp(null)}
            />
          </Popup>
        )
      }
      {
        (popUp === "edit" && currentType) && (
          <Popup
            title="Edit type"
            onClose={() => setPopUp(null)}
          >
            <RequestTypesForm
              onSubmit={values => editHandler(values)}
              defaultValues={currentType}
              onCLose={() => setPopUp(null)}
            />
          </Popup>
        )
      }
      {
        popUp === "delete" && (
          <Popup
            title="Delete request type"
            text="Are you sure you want to delete this request type? It will remain in all previous requests, but not in new ones."
            onClose={() => setPopUp(null)}
            onClick={deleteHandler}
            isDelete
            dialog
          />
        )
      }
    </>
  );
};

RequestTypesTab.propTypes = {};

export default RequestTypesTab;