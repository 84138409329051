import React, {Fragment, useState, useEffect} from "react";
import s from "./Attachments.module.scss";
import {useDropzone} from "react-dropzone";
import classNames from "classnames";
import {ReactComponent as Error} from "assets/icons/error-warning.svg";
import {ReactComponent as Download} from "assets/icons/download-light.svg";
import Files from "./components/Files/Files";
import {validateAttachments} from "./helpers/validateAttachments";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import Label from "../../../../elements/Label/Label";

const Attachments = (
  {
    onChange,
    onDelete,
    attachments,
    filesMaxLength,
    customHeight = "auto",
    disabled = false,
    label = "Attachment",
    className,
    titleDropFilesToAttach = false,
    parentClassName,
    optional = false,
  }
) => {
  const [error, setError] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const {t} = useTranslation();
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragReject,
  } = useDropzone({
    accept:
      "application/msword,.doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation,.pptx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx",
    disabled,
  });
  const validateFilesMaxLength = () => {
    if (filesMaxLength === "infinity") {
      return false;
    } else if (filesMaxLength) {
      return acceptedFiles.length + attachments?.length > filesMaxLength;
    } else {
      return acceptedFiles.length + attachments?.length > 3;
    }
  };

  useEffect(() => {
    setFirstLoad(false);
    if (attachments && validateFilesMaxLength()) {
      setError(
        `There are too many files. There must be ${filesMaxLength ||
        3} or less.`
      );
    } else if (acceptedFiles.length) {
      const filteredFiles = validateAttachments(acceptedFiles, attachments);
      if (!filteredFiles.length) {
        setError("This files already exist.");
      } else {
        onChange(filteredFiles);
        setError(null);
        setIsChanged(true);
      }
    } else {
      if (!firstLoad) {
        setError("File could not be uploaded. Invalid file type.");
      }
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (isDragReject) {
      setError("File could not be uploaded. Invalid file type.");
    }
  }, [isDragReject]);

  return (
    <Fragment>
      {label && <Label label={label} optional={optional}/>}
      <div
        style={{height: customHeight}}
        {...getRootProps()}
        className={classNames(s.dropzone, parentClassName, {[s.error]: error, [s.disabled]: disabled})}
      >
        <input{...getInputProps()} accept=".doc,.docx,.xlsx,.pptx,.pdf"/>
        {!error && !isChanged && (
          <div className={s.dropFied}>
            <Download className={s.icon}/>
            <span className={s.title}>
              {titleDropFilesToAttach
                ? <span data-translate-key={getTranslateKey("Drop files to attach, or ")}>
                {t(getTranslateKey("Drop files to attach, or "))}
              </span>
                : <span data-translate-key={getTranslateKey("Choose a file or drag it")}>
                {t(getTranslateKey("Choose a file or drag it"))}
              </span>}
              {" "}
              {titleDropFilesToAttach
                ? <span className={s.link} data-translate-key={getTranslateKey("browse.")}>
                {t(getTranslateKey("browse."))}
              </span>
                : <span className={s.link} data-translate-key={getTranslateKey("here")}>
                {t(getTranslateKey("here"))}
              </span>}
            </span>
            {titleDropFilesToAttach
              ? ""
              : <span className={s.title}>(docx, doc, xlsx, pptx, pdf)</span>}
          </div>
        )}
        {!error && isChanged && (
          <span className={s.dropzoneTitle}>
            <span data-translate-key={getTranslateKey("Done !")}>
              {t(getTranslateKey("Done !"))}
            </span>
          </span>
        )}
        {error && (
          <Fragment>
            <Error/>
            <span className={s.message}>
              {error}{" "}
              <span>Allowed file types: docx, doc, xlsx, pptx, pdf</span>
            </span>
            <span className={s.link}>Upload another file</span>
          </Fragment>
        )}

      </div>
      {attachments?.length > 0 && (
        <Files files={attachments} onDelete={onDelete} disabled={disabled} className={className} withLink/>
      )}
    </Fragment>
  );
};
export default Attachments;

Attachments.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  attachments: PropTypes.array,
  filesMaxLength: PropTypes.number,
  customHeight: PropTypes.any,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};