import React from "react";
import {object, string} from "yup";
import FormConstructor from "../../elements/FormConstructor/FormConstructor";
import PropTypes from "prop-types";

const RequestTypesForm = ({onSubmit, defaultValues, onCLose}) => {

  const relations = [
    {
      value: "None",
      label: "None"
    },
    {
      value: "supplier",
      label: "Supplier"
    },
    {
      value: "contract",
      label: "Contract"
    },
    {
      value: "project",
      label: "Project"
    },
  ];

  return (
    <FormConstructor
      submitBtn={{
        styled: "primary",
        title: defaultValues ? "Save" : "Create",
      }}
      cancelBtn={{
        styled: "secondary",
        title: "Cancel",
        onClick: () => onCLose()
      }}
      fields={[
        {
          type: "text",
          title: "Type name",
          placeholder: "Enter type name",
          required: string().required("Type name is required"),
          key: "name",
          value: defaultValues?.name || "",
        },
        {
          type: "dropdown",
          title: "Relation",
          placeholder: "Choose relation",
          required: object({
            value: string().required("Relation is required")
          }),
          withTranslate: true,
          key: "relation",
          value: defaultValues ? relations.find(relation => relation.value === defaultValues.relation) ?? relations[0] : relations[0],
          options: relations,
          titleNotification: 'Request type with a relation is quick, no-step request \n' +
            'that you can open from a related table.'
        },
        {
          type: "textarea",
          title: "Description (optional)",
          placeholder: "Enter description",
          required: string()
            .max(180, "The Description is too long. It should be 180 characters or less."),
          key: "description",
          value: defaultValues?.description || "",
          max: 180,
        }
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default RequestTypesForm;

RequestTypesForm.propTypes = {
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.any,
  onCLose: PropTypes.func,
};