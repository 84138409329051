import React from "react";
import PropTypes from "prop-types";
import styles from "./HyperlinksField.module.scss";
import Button from "../Button/Button";
import {ReactComponent as Plus} from "assets/icons/plus-gray.svg";
import {useTranslate} from "../../../hooks/useTranslate";
import {generateUniqueId} from "../../../helpers/uniqueId";
import SingleHyperlink from "./SingleHyperlink";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const HyperlinksField = ({setFieldValue, errors, values}) => {
  const [translateKey, translateValue] = useTranslate("New hyperlink");

  const addHyperlink = () => {
    setFieldValue("hyperlinks", [...values, {id: generateUniqueId(), name: "", url: ""}]);
  };

  const changeHyperlink = (id, type, value) => {
    setFieldValue("hyperlinks", values.map(hyperlink => {
      if (hyperlink.id === id) return {...hyperlink, [type]: value};
      return hyperlink;
    }));
  };

  const deleteHyperlink = id => {
    setFieldValue("hyperlinks", values.filter(hyperlink => hyperlink.id !== id));
  };

  return (
    <div className={styles.hyperlinksWrap}>
      <div className={styles.hyperlinks}>
        {values.map(hyperlink => (
          <SingleHyperlink
            key={hyperlink.id}
            data={hyperlink}
            onDelete={deleteHyperlink}
            onChange={changeHyperlink}
          />
        ))}
      </div>
      {errors.hyperlinks && <ErrorMessage error={errors.hyperlinks}/>}
      <Button
        styled="textButton"
        data-translate-key={translateKey}
        className={styles.addBtn}
        onClick={addHyperlink}
      >
        <Plus/> {translateValue}
      </Button>
    </div>
  );
};

export default HyperlinksField;

HyperlinksField.propTypes = {
  setFieldValue: PropTypes.func,
  errors: PropTypes.any,
  values: PropTypes.array,
};