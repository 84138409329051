import React, { useEffect } from "react";
import LandingLayout from "../../Landinglayout/Landinglayout";
import BlogCard from "./components/BlogCard/BlogCard";
import { blogData } from "./blogData";
import s from "./BlogPage.module.scss";

const BlogPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <LandingLayout>
      <div className={s.blogPage}>
        <div className={s.landingContainer}>
          {blogData.map((card) => (
            <BlogCard key={card.id} img={card.img} article={card.article} path={card.path} id={card.id}  />
          ))}
        </div>
      </div>
    </LandingLayout>
  );
};

export default BlogPage;
