export const filterUniqueEntities = (entitiesList, filterBy) => {
  const preparedEntities = [];

  entitiesList.forEach(entity => {
    const entityParams = preparedEntities.map(e => e[filterBy]);

    if (entity && !entityParams.includes(entity[filterBy])) {
      preparedEntities.push(entity);
    }
  });

  return preparedEntities;
};