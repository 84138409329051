import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import EditAdvancedProjectContainer from "../../containers/EditAdvancedProjectContainer/EditAdvancedProjectContainer";

const EditAdvancedProjectPage = () => {

  let breadcrumbs = [
    {
      path: paths.projects,
      name: "Projects"
    },
    {
      name: "Edit project"
    }
  ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <EditAdvancedProjectContainer/>
    </DefaultLayout>
  );
};

export default EditAdvancedProjectPage;
