import React, {useState, useEffect} from "react";
import qs from "query-string";
import {useHistory} from "react-router-dom";
import styles from "./CategoriesContractsExpirations.module.scss";
import CategoriesContractsExpirationsTable
  from "components/tables/CategoriesContractsExpirationsTable/CategoriesContractsExpirationsTable";
import services from "services";
import {formatContractsExpirationsData} from "../../helpers";
import TableLoading from "components/loadingStates/Table/Table";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const CategoriesContractsExpirations = ({categoryIdsFilter}) => {
  const [data, setData] = useState([]);
  const [currency, setCurrency] = useState("$");
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const history = useHistory();
  const query = qs.parse(history.location.search);

  useEffect(() => {
    services.categoriesServices
      .getCategoriesContractsExpirations({...query, category_ids: categoryIdsFilter})
      .then((res) => {
        setCurrency(res.data.currency);
        setData(formatContractsExpirationsData(res.data?.expirations || []));
        setLoading(false);
      });
  }, [history.location.search, categoryIdsFilter]);

  let newDate = [];
  if (data) {
    newDate = [
      ...data,
      {
        status: "total",
        total: "totalSum",
        days_90: {
          count: data.reduce((accumulator, current) => accumulator + current.days_90.count, 0),
          total_value: data.reduce((accumulator, current) => accumulator + current.days_90.total_value, 0)
        }
        ,
        days_180: {
          count: data.reduce((accumulator, current) => accumulator + current.days_180.count, 0),
          total_value: data.reduce((accumulator, current) => accumulator + current.days_180.total_value, 0)
        },

        days_180_plus: {
          count: data.reduce((accumulator, current) => accumulator + current.days_180_plus.count, 0),
          total_value: data.reduce((accumulator, current) => accumulator + current.days_180_plus.total_value, 0),
        },
        days_total: {
          count: data.reduce((accumulator, current) => accumulator + current.days_total.count, 0),
          total_value: data.reduce((accumulator, current) => accumulator + current.days_total.total_value, 0),
        }
      },
    ];
  }

  return (
    <>
      <h3 className={styles.title} data-translate-key={getTranslateKey("Contract expirations")}>
        {t(getTranslateKey("Contract expirations"))}
      </h3>
      {loading && <TableLoading length={5}/>}
      {!loading && (
        <CategoriesContractsExpirationsTable data={newDate} currency={currency}/>
      )}
    </>
  );
};

export default CategoriesContractsExpirations;
