import React from "react"
import { useFormik } from "formik"
import { object, string } from "yup"
import s from "./EditProjectNoteForm.module.scss"
import Textarea from "components/elements/Textarea/Textarea"
import Button from "components/elements/Button/Button"
import Attachments from "components/forms/AddMilestoneTaskForm/components/Attachments/Attachments"
import services from "services"

const EditProjectNoteForm = ({ note, editHandler, onClose, maxLengthText= 1000 }) => {
  const formik = useFormik({
    initialValues: {
      description: note.description,
      attachments: note?.attachments || []
    },
    validationSchema: object({
      description: string()
        .required("Please, write a note")
        .max(
          maxLengthText,
          `Please enter Note description that consists of no more than ${maxLengthText} characters`
        )
    }),
    onSubmit: values => {
      editHandler(note.id, {
        description: values.description,
        attachments: values.attachments
      })
      onClose()
    }
  })

  const { handleSubmit, touched, values, errors, setFieldValue } = formik
  const descriptionLength = values.description.length
  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files])
  }
  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      )
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        )
      })
    }
  }
  return (
    <form onSubmit={handleSubmit} autoComplete='off' className={s.form}>
      <Textarea
        className={s.textarea}
        placeholder='Write a note'
        value={values.description}
        onChange={e => setFieldValue("description", e.target.value)}
        label='Note description'
        error={touched.description && errors.description}
        count={{ current: descriptionLength, max: maxLengthText }}
      />
      <Attachments
        onChange={handleChangeAttachment}
        onDelete={handleDeleteAttachment}
        attachments={values.attachments}
        // filesMaxLength='infinity'
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          Save
        </Button>
      </div>
    </form>
  )
}

export default EditProjectNoteForm
