import React, {useEffect, useState} from "react";
import s from "./NewRequest.module.scss";
import sBase from "../MainDashboard.module.scss";
import classNames from "classnames";
import Button from "../../../../../elements/Button/Button";
import {ReactComponent as Plus} from "../../../../../../assets/icons/btn-plus.svg";
import services from "../../../../../../services";
import DefaultLoading from "../../../../../loadingStates/Dashboard/DefaultLoading/DefaultLoading";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import SingleRequestType from "./SingleRequestType";
import {ReactComponent as ArrowDown} from "../../../../../../assets/icons/arrowDown.svg";
import {paths} from "../../../../../../routes/paths";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import {useTranslate} from "../../../../../../hooks/useTranslate";

const NewRequest = () => {
  const [loading, setLoading] = useState(true);
  const [isFull, setFull] = useState(false);
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedRequestTypeId, setSelectedRequestTypeId] = useState(null);
  const previewRequestTypes = requestTypes.slice(0, 6);
  const [translateKey, translateValue] = useTranslate(isFull ? "Show less" : "Show more");
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    services.requestTypeServices
      .getRequestTypes()
      .then(res => {
        setRequestTypes(res.data);
        setLoading(false);
      });
  }, []);

  const createRequest = request => history.push(paths.createRequest, {request});

  const renderContent = () => {
    if (loading) return <DefaultLoading/>;

    if (!loading && !requestTypes.length) return (
      <EmptyContent
        className={s.emptyBlock}
        text="There are no request types in your organization yet. Please contact your manager to add them."
      />
    );

    return (
      <>
        <div className={s.description}
             data-translate-key={getTranslateKey("please,-select-what-you-are-looking-for-to-create-a-new-request")}>
          {t(getTranslateKey("please,-select-what-you-are-looking-for-to-create-a-new-request"))}
        </div>
        <div className={s.requestList}>
          {(isFull ? requestTypes : previewRequestTypes).map(requestType => (
            <SingleRequestType
              requestType={requestType}
              selectedRequestTypeId={selectedRequestTypeId}
              onClick={() => setSelectedRequestTypeId(requestType.id)}
              key={requestType.id}
            />
          ))}
        </div>
        <button
          className={classNames(s.changeModeBtn, {[s.active]: isFull})}
          onClick={() => setFull(!isFull)}
          data-translate-key={translateKey}
        >
          {translateValue}
          <ArrowDown/>
        </button>
      </>
    );
  };

  return (
    <section className={classNames(sBase.wrapper, s.newRequestWrap)}>
      <header>
        <h4 className={classNames(sBase.title, s.title)} data-translate-key={"new-request"}>
          {t("new-request")}
        </h4>
        {
          (!loading && !!requestTypes.length) && (
            <Button
              styled="primary"
              className={s.newRequestBtn}
              onClick={() => createRequest(selectedRequestTypeId)}
              data-translate-key={getTranslateKey("new-request")}>
              <Plus/> {t(getTranslateKey("new-request"))}
            </Button>
          )
        }
      </header>
      {renderContent()}
    </section>
  );
};

export default NewRequest;