import React from "react"
import MainTable from "components/tables/MainTable/MainTable"
import { useHistory } from "react-router-dom"
import { formatCurrency } from "helpers/currencyFormat"
import {dateFormatterWithoutTimezone} from "helpers/dateFormat"

const ReportingTagProjectsTable = ({
  data,
  perPage,
  onPageChange,
  currentPage,
  dataCount
}) => {
  const history = useHistory()
  return (
    <MainTable
      data={data}
      dataTypes={[
        {
          title: "Project name",
          value: ({ name }) => name || "-",
          onClick: id => history.push(`/projects/${id}`),
          sticky: true
        },
        {
          title: "Project type",
          value: ({ project_type }) => project_type?.name || "-"
        },
        {
          title: "Category",
          value: ({ category }) => category?.name || "-"
        },
        {
          title: "Start date",
          value: ({ start_date }) => dateFormatterWithoutTimezone(start_date) || "-"
        },
        {
          title: "End date",
          value: ({ end_date }) => dateFormatterWithoutTimezone(end_date) || "-"
        },
        {
          title: "Baseline Spend (USD)",
          value: ({ baseline_spend }) =>
            baseline_spend !== null ? formatCurrency("$", baseline_spend) : "-",
          alignment: "right"
        },
        {
          title: "Savings",
          value: ({ currency, savings }) =>
            savings !== null && currency
              ? formatCurrency(currency, savings)
              : "-",
          alignment: "right"
        }
      ]}
      perPage={perPage}
      totalCount={dataCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  )
}
export default ReportingTagProjectsTable
