import {
  SET_TASKROLES,
  ADD_TASKROLE,
  CHANGE_TASKROLE,
  DELETE_TASKROLE,
  SET_CURRENT_TASKROLE,
  SET_POPUP,
  CLOSE_ALERT
} from "./reducer"
import services from "services"
export const setTaskRoles = (dispatch, search_query, page) => {
  services.adminServices
    .getTaskRoles({ ...search_query, page })
    .then(res => {
      dispatch({
        type: SET_TASKROLES,
        payload: {
          per_page: res.data?.per_page,
          tasks_count: res.data?.tasks_count,
          taskroles: res.data?.task_roles,
          search_query: {
            sort_by: search_query?.sort_by || "",
            direction: search_query?.direction || ""
          },
          loading: false
        }
      })
    })
    .catch(err =>
      dispatch({
        type: SET_TASKROLES,
        payload: {
          alert: {
            type: "error",
            message:
              err.response?.data.error ||
              "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const addTaskRole = (dispatch, values) => {
  services.adminServices
    .addTaskRole(values)
    .then(res =>
      dispatch({
        type: ADD_TASKROLE,
        payload: {
          taskrole: res.data || null,
          alert: {
            type: "success",
            message: "Task role successfully created."
          },
          popup: null
        }
      })
    )
    .catch(err =>
      dispatch({
        type: ADD_TASKROLE,
        payload: {
          alert: {
            type: "error",
            message:
              err.response?.data.error ||
              "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const changeTaskRole = (dispatch, id, values) => {
  services.adminServices
    .changeTaskRole(id, values)
    .then(res =>
      dispatch({
        type: CHANGE_TASKROLE,
        payload: {
          taskrole: res.data || null,
          alert: {
            type: "success",
            message: "Task role successfully changed."
          },
          popup: null
        }
      })
    )
    .catch(err =>
      dispatch({
        type: CHANGE_TASKROLE,
        payload: {
          alert: {
            type: "error",
            message:
              err.response?.data.error ||
              "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const deleteTaskRole = (dispatch, id) => {
  services.adminServices
    .deleteTaskRole(id)
    .then(res =>
      dispatch({
        type: DELETE_TASKROLE,
        payload: {
          taskrole_id: id,
          alert: {
            type: "success",
            message: "Task role successfully deleted."
          },
          popup: null
        }
      })
    )
    .catch(err =>
      dispatch({
        type: DELETE_TASKROLE,
        payload: {
          alert: {
            type: "error",
            message:
              err.response?.data.error ||
              "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const setPopup = popup => ({
  type: SET_POPUP,
  payload: popup
})
export const closeAlert = () => ({
  type: CLOSE_ALERT,
  payload: null
})
export const setCurrentTaskRole = taskrole => ({
  type: SET_CURRENT_TASKROLE,
  payload: taskrole
})
