import React from "react";
import s from "./FilterBar.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";

const FilterBar = ({ length = 0, className }) => {
  const getBars = () => {
    let bars = [];
    for (let i = 0; i < length; i++) {
      bars.push(
        <Skeleton
          variant="text"
          className={s.bar}
          animation="wave"
          width={100}
        />
      );
    }
    return bars;
  };
  return (
    <div className={classNames("skeletLoaderWrap", s.wrap, className)}>
      <Skeleton variant="text" animation="wave" width={200} />
      <div className={s.bars}>{getBars()}</div>
    </div>
  );
};

export default FilterBar;
