import axios from "./axios";

export default {
  getCustomSelectorsAdmin: async () => {
    try {
      return await axios.get("/admin/custom_selectors");
    } catch (error) {
      throw error
    }
  },

  addCustomSelector: async params => {
    try {
      return await axios.post("/admin/custom_selectors", params);
    } catch (error) {
      throw error
    }
  },

  changeCustomSelector: async (id, params) => {
    try {
      return await axios.put(`/admin/custom_selectors/${id}`, params);
    } catch (error) {
      throw error
    }
  },

  deleteCustomSelector: async id => {
    try {
      return await axios.delete(`/admin/custom_selectors/${id}`);
    } catch (error) {
      throw error
    }
  },

  addCustomSelectorOption: async (id, params) => {
    try {
      return await axios.post(`/admin/custom_selectors/${id}/options`, params);
    } catch (error) {
      throw error
    }
  },

  editCustomSelectorOption: async (selectorId, id, params) => {
    try {
      return await axios.put(`/admin/custom_selectors/${selectorId}/options/${id}`, params);
    } catch (error) {
      throw error
    }
  },

  deleteCustomSelectorOption: async (selectorId, id) => {
    try {
      return await axios.delete(`/admin/custom_selectors/${selectorId}/options/${id}`);
    } catch (error) {
      throw error
    }
  },

  getProjectCustomSelectors: async id => {
    try {
      return await axios.get(`/projects/${id}/custom_selectors`);
    } catch (error) {
      throw error
    }
  },

  getOptions: async id => {
    try {
      return await axios.get(`/custom_selectors/${id}`);
    } catch (error) {
      throw error
    }
  },

  changeProjectSelectorOption: async (projectId, selectorId, optionId) => {
    try {
      return await axios.put(`/projects/${projectId}/custom_selectors/${selectorId}/options/${optionId}`);
    } catch (error) {
      throw error
    }
  },

  unassignOption: async (projectId, selectorId) => {
    try {
      return await axios.put(`/projects/${projectId}/custom_selectors/${selectorId}/unassign`);
    } catch (error) {
      throw error
    }
  },

  getCustomSelectors: async () => {
    try {
      return await axios.get('/custom_selectors');
    } catch (error) {
      throw error
    }
  }
}