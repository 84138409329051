import React from "react";
import Popup from "components/popups/Popup/Popup";
import ChangeFlagForm from "components/forms/ChangeFlagForm/ChangeFlagForm"

const AddGroupPopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      onClose={onClose}
      title="New flag"
      text="Please, complete the following fields to create a flag"
    >
      <ChangeFlagForm
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Popup>
  );
};
export default AddGroupPopup;
