import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Popup from "components/popups/Popup/Popup";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import ContractsTable from "components/tables/ContractsTable/ContractsTable";
import AddProjectContractForm from "components/forms/AddProjectContractForm/AddProjectContractForm";
import services from "services/index";
import AlertContext from "contexts/AlertContext/AlertContext";
import TableLoading from "components/loadingStates/Table/Table";

const ContractsContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [show, setShow] = useState(null);
  const [currentContracts, setCurrentContracts] = useState([]);
  const [permissions, setPermissions] = useState({
    update: false,
    destroy: false,
  });
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AlertContext);
  const [currentContract, setCurrentContract] = useState(null);

  useEffect(() => {
    services.contractsServices.getProjectContracts(params.id).then((res) => {
      setCurrentContracts(res.data.contracts);
      setPermissions({
        update: res.data.permissions?.update || false,
        destroy: res.data.permissions?.destroy || false,
      });
      setLoading(false);
    });
  }, [params.id]);
  const addHandler = (values) => {
    services.contractsServices
      .addProjectContract(params.id, values)
      .then((res) => {
        setCurrentContracts([...currentContracts, res.data]);
        setAlert({
          message: "Contract successfully added.",
        });
      })
      .catch(() =>
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error",
        })
      );
  };

  const removeHandler = (id) => {
    services.contractsServices
      .removeProjectContract(params.id, { contract_id: id })
      .then(() => {
        setAlert({
          message: "Contract successfully removed.",
          onCancel: () => {
            services.contractsServices
              .addProjectContract(params.id, { contract_id: id })
              .then((res) => {
                setCurrentContracts((state) => [...state, res.data]);
              });
          },
        });
        setCurrentContracts((state) => state.filter((item) => item.id !== id));
      })
      .catch(() => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error",
        });
      });
  };

  return (
    <>
      {loading && <TableLoading length={7} />}
      {!loading && (
        <>
          {currentContracts.length ? (
            <ContractsTable
              state={{ contracts: currentContracts }}
              onDelete={(item) => {
                setCurrentContract(item);
                setShow("delete");
              }}
              history={history}
              onAdd={() => setShow("add")}
              havePermissions={permissions}
            />
          ) : (
            <EmptyBlock
              subtitle={
                permissions.update
                  ? "You don’t have contracts yet. You can add a new one"
                  : "You don’t have contracts yet."
              }
              buttonTitle="Add contract"
              onClick={() => setShow("add")}
              withBtn={permissions.update}
            />
          )}
          {show === "add" && (
            <Popup
              onClose={() => setShow(null)}
              title="Add contract"
              text="Choose a contract from existing ones"
            >
              <AddProjectContractForm
                currentContracts={currentContracts}
                addHandler={addHandler}
                onClose={() => setShow(null)}
              />
            </Popup>
          )}
          {show === "delete" && (
            <Popup
              onClose={() => {
                setShow(null);
                setCurrentContract(null);
              }}
              successBtnTitle="Remove"
              title="Remove contract"
              text={`Are you sure you want to remove the "${currentContract.name}" contract?`}
              onClick={() => {
                removeHandler(currentContract.id);
                setShow(null);
              }}
              isDelete
              dialog
            />
          )}
        </>
      )}
    </>
  );
};

export default ContractsContainer;
