import React, { useState, useEffect } from "react";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import { TemplatesPopUp } from "../TemplatesPopUp/TemplatesPopUp.jsx";
import { TemplatesTable } from "../TemplatesTable/TemplatesTable.jsx";
import services from "services/index.js";

export const statuses = [
  {
    label: "Active",
    value: "active",
    color: "#83A755",
  },
  {
    label: "Testing",
    value: "testing",
    color: "#F29239",
  },
  {
    label: "Archived",
    value: "archived",
    color: "#DE4935",
  },
  {
    label: "Inactive",
    value: "inactive",
    color: "#DE4935",
  },
];

export const Templates = () => {
  const [currentTemplates, setCurrentTemplates] = useState([]);
  const [openPopup, setOpenPopUp] = useState({ checked: false, action: "" });
  const [editItem, setEditItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    services.templatesServices.getTemplates().then((res) => {
      setCurrentTemplates(res.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {!currentTemplates.length && !isLoading && (
        <EmptyBlock
          subtitle="You don’t have templates yet. You can create a new one."
          buttonTitle="New template"
          onClick={() =>
            setOpenPopUp((prevPopUp) => ({ checked: !prevPopUp.checked, action: "Create" }))
          }
        />
      )}
      {currentTemplates.length > 0 && !isLoading && (
        <TemplatesTable
          currentTemplates={currentTemplates}
          setCurrentTemplates={setCurrentTemplates}
          setOpenPopUp={setOpenPopUp}
          openPopup={openPopup}
          setEditItem={setEditItem}
        />
      )}
      {openPopup.checked && (
        <TemplatesPopUp
          setOpenPopUp={setOpenPopUp}
          openPopup={openPopup}
          setCurrentTemplates={setCurrentTemplates}
          editItem={editItem}
        />
      )}
      {isLoading && <TableLoading length={6} />}
    </>
  );
};
