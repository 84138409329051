import React, {useState, useRef} from "react"
import MainTable from "components/tables/MainTable/MainTable"
import EditProjectFinancialBenefitForm
  from "components/forms/EditProjectFinancialBenefitForm/EditProjectFinancialBenefitForm"
import Popup from "components/popups/Popup/Popup"
import {fromDate} from "helpers/dateFormat"
import {formatCurrency} from "helpers/currencyFormat"
import {currencyData} from "helpers/helpData"
import {localization} from "helpers/localizationForCompanies"
import s from "./ProjectFinancialBenefitsTable.module.scss"
import {ReactComponent as AttachmentIcon} from "assets/icons/attachment.svg"
import classNames from "classnames"
import {getFileIconByType} from "helpers/fileIconsByType"
import useOutsideClicker from "hooks/useOutsideClicker"
import {useHistory, useParams} from "react-router-dom";

const ProjectFinancialBenefitsTable = (
  {
    benefits,
    removeHandler,
    havePermissions
  }
) => {
  const [show, setShow] = useState({delete: false, edit: false})
  const [benefit, setBenefit] = useState({})
  const history = useHistory();
  const params = useParams();
  const [visibleAttachmentsPopupId, setVisibleAttachmentsPopupId] = useState(
    null
  )
  const attachmentsRef = useRef(null)
  useOutsideClicker(attachmentsRef, () => setVisibleAttachmentsPopupId(null))

  return (
    <>
      <MainTable
        data={benefits}
        dataTypes={[
          {
            title: "Savings start date",
            value: ({start_date}) => fromDate(start_date)
          },
          {
            title: "Savings end date",
            value: ({end_date}) => fromDate(end_date)
          },
          {
            title: `${localization.finalCost}`,
            value: ({project_spend, project_spend_currency}) =>
              formatCurrency(
                currencyData.filter(e => e.label === project_spend_currency)[0]
                  ?.value,
                project_spend
              ),
            alignment: "right"
          },
          {
            title: `${localization.finalSavings}`,
            value: ({project_savings, project_savings_currency}) =>
              formatCurrency(
                currencyData.filter(
                  e => e.label === project_savings_currency
                )[0]?.value,
                project_savings
              ),
            alignment: "right"
          },
          {
            title: `${localization.savingstype}`,
            value: ({savings_type}) => savings_type.name
          },
          {
            title: "Note",
            value: ({note}) => note || "-"
          },
          {
            title: "",
            value: ({attachments, id}) => (
              <div className={s.attachmentsWrap}>
                <AttachmentIcon
                  className={classNames(s.icon, {
                    [s.disabled]: !attachments.length
                  })}
                  onClick={() =>
                    attachments.length > 0 && setVisibleAttachmentsPopupId(id)
                  }
                />
                {visibleAttachmentsPopupId === id && attachments.length > 0 && (
                  <ul className={s.attachments} ref={attachmentsRef}>
                    {attachments.map((attachment, index) => (
                      <li className={s.attachment} key={index}>
                        {getFileIconByType(
                          attachment.type || attachment.file_type
                        )}
                        <a
                          className={s.link}
                          href={attachment.path}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {attachment.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )
          }
        ]}
        options={item => {
          const options = [
            {
              title: "View",
              onClick: () => history.push(`/projects/${params.id}/financial-benefits/${item.id}`)
            },
            {
              title: "Delete",
              onClick: () => {
                setBenefit(item)
                setShow({...show, delete: true})
              }
            }
          ]
          !havePermissions.update && delete options[0]
          !havePermissions.destroy && delete options[1]
          return options
        }}
        button={
          havePermissions.update && {
            name: "Add benefit",
            onClick: () => history.push(`/projects/${params.id}/financial-benefits`)
          }
        }
        totalName='Financial benefits'
      />
      {show.delete && (
        <Popup
          onClose={() => setShow({...show, delete: false})}
          title='Delete financial benefit?'
          text='Are you sure you want to delete this benefit?'
          onClick={() => {
            removeHandler(benefit.id)
            setShow({...show, delete: false})
          }}
          dialog
          isDelete
        />
      )}
    </>
  )
}

export default ProjectFinancialBenefitsTable
