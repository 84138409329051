import React, {useEffect, useState} from 'react';
import PieChartWidgetLoading from "../../../../loadingStates/PieChartWidget/PieChartWidget";
import services from "../../../../../services";
import classNames from "classnames";
import PieChartWiget from "../../../../elements/PieChartWidget/PieChartWidget";
import {getPieChartWidgetTitle} from "../../../../../helpers/getPieChartWidgetTitle";
import FilterBarDropdown from "../../../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import styles from "./TeamReportPieChart.module.scss";
import baseStyle from "../../TeamReportContainer.module.scss";
import {getChartValues} from "../../../../../helpers/getChartValues";
import SavingsChart from "../../../../elements/SavingsChart/SavingsChart";
import {getSavingsWidgetTitle} from "../../../../../helpers/getSavingsWidgetTitle";
import {useHistory, useParams} from "react-router-dom";
import {years} from "../../../UserReportContainer/helpers/years";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import {getAllIds} from "../../../../elements/FilterBar/helpers/helpers";
import qs from "query-string";

const TeamReportPieChart = () => {
  const params = useParams();
  const [chartData, setChartData] = useState(null);
  const [selectedYears, setSelectedYears] = useState(["2021"]);
  const {t} = useTranslation();
  const history = useHistory();

  useEffect(() => {
    params.id &&
    services.reportServices
      .getTeamReportActivity(params.id, {
        years: selectedYears?.join(",") || ""
      })
      .then(res => {
        const data = res?.data || {}
        if (res?.data?.period === "quarters") {
          setChartData(res?.data || null)
        } else if (res?.data?.period === "years") {
          setChartData(data);
        } else {
          setChartData(res?.data)
        }
      })
  }, [selectedYears]);
  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );
  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    setSelectedYears(values.map(e => e.name))
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };
  return (
    <React.Fragment>
      <h3
        className={classNames(baseStyle.title, baseStyle.widget)}
        data-translate-key={getTranslateKey("Project information")}
      >
        {t(getTranslateKey("Project information"))}
      </h3>
      {!chartData && (
        <PieChartWidgetLoading
          className={styles.pieChartLoader}
          width={144}
        />
      )}
      {(chartData && chartData.period === "years") && (
        <div className={classNames(styles.savingsChartWrap)}>
          <PieChartWiget
            data={getChartValues(chartData)}
            title={getPieChartWidgetTitle(selectedYears, chartData)}
          />
          <div className={styles.years}>
            <FilterBarDropdown
              name='Years'
              options={years}
              defaultValues={query?.years?.split(",")}
              handleChange={(values) => {
                handleChangeFilter("years", values);
              }}
              setFilterValue={() => {
              }}
            />
          </div>
        </div>
      )}
      {chartData && !chartData.period && (
        <div className={classNames(styles.savingsChartWrap)}>
          <SavingsChart
            title={getSavingsWidgetTitle(selectedYears, chartData)}
            data={chartData}
          />
          <div className={styles.years}>
            <FilterBarDropdown
              name='Years'
              options={years}
              defaultValues={selectedYears}
              handleChange={(values) => {
                handleChangeFilter("years", values);
              }}
              setFilterValue={() => {
              }}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TeamReportPieChart;