import React, { useEffect, useState, useReducer } from "react"
import s from "./Stages.module.scss"
import Header from "../Header/Header"
import AdminSettingsProjectsStagesTable from "components/tables/AdminSettingsProjectsStagesTable/AdminSettingsProjectsStagesTable"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import TableLoading from "components/loadingStates/Table/Table"
import AddStagePopup from "./popups/AddStagePopup/AddStagePopup"
import EditStagePopup from "./popups/EditStagePopup/EditStagePopup"
import DeleteStagePopup from "./popups/DeleteStagePopup/DeleteStagePopup"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { initialState, reducer } from "./helpers/reducer"
import NotificationBox from "components/elements/NotificationBox/NotificationBox"
import {
  getStages,
  changePopup,
  addStage,
  deleteStage,
  editStage,
  closeAlert,
  replaceStages
} from "./helpers/actions"

const Stages = () => {
  const [currentStage, setCurrentStage] = useState(null)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { stages, popup, loading, alert } = state
  useEffect(() => getStages(dispatch), [])
  const handleDragEnd = result => {
    const sourceLvl = result.source.index
    if (result.destination.index > sourceLvl) {
      const lvl = result.destination.index + 1
      const newStages = [
        ...stages.filter((_, index) => index !== sourceLvl).slice(0, lvl),
        stages[sourceLvl],
        ...stages.slice(lvl + 1)
      ]
      replaceStages(dispatch, { stage_ids: newStages.map(e => e.id).join(",") })
    } else if (result.destination.index < sourceLvl) {
      const lvl = result.destination.index
      const newStages = [
        ...stages.slice(0, lvl),
        stages[sourceLvl],
        ...stages.filter((_, index) => index !== sourceLvl).slice(lvl)
      ]
      replaceStages(dispatch, { stage_ids: newStages.map(e => e.id).join(",") })
    }
  }

  return (
    <div className={s.stages}>
      {!loading && stages.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='project_stages' type='PROJECT_STAGES'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={s.tableWrap}
              >
                <AdminSettingsProjectsStagesTable
                  data={stages}
                  onAdd={() => dispatch(changePopup("add"))}
                  onDelete={stage => {
                    dispatch(changePopup("delete"))
                    setCurrentStage(stage)
                  }}
                  onEdit={stage => {
                    dispatch(changePopup("edit"))
                    setCurrentStage(stage)
                  }}
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!loading && !stages.length && (
        <EmptyBlock
          subtitle='No work item results found'
          buttonTitle='New stage'
          onClick={() => dispatch(changePopup("add"))}
        />
      )}
      {loading && <TableLoading length={2} />}
      {popup === "add" && (
        <AddStagePopup
          onClose={() => dispatch(changePopup(null))}
          onSubmit={({ name }) => addStage(dispatch, { name })}
        />
      )}
      {popup === "edit" && (
        <EditStagePopup
          onClose={() => dispatch(changePopup(null))}
          onSubmit={({ name }) =>
            editStage(dispatch, currentStage?.id, { name })
          }
          defaultValues={{ name: currentStage?.name || "" }}
        />
      )}
      {popup === "delete" && (
        <DeleteStagePopup
          onClose={() => dispatch(changePopup(null))}
          onSubmit={() => deleteStage(dispatch, currentStage?.id)}
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => dispatch(closeAlert())}
        />
      )}
    </div>
  )
}
export default Stages
