import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../Checkbox/Checkbox";

const FormConstructorCheckbox = ({field, values, setFieldValue}) => {
  return (
    <div>
      <Checkbox
        value={values[field.key]}
        checked={values[field.key]}
        onChange={e => setFieldValue(field.key, e.target.checked)}
        label={field.title}
      />
    </div>
  );
};

export default FormConstructorCheckbox;

FormConstructorCheckbox.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
};