import React from "react";
import styles from "./SingleStep.module.scss";
import RadioGroupField from "./RadioGroupField/RadioGroupField";
import PropTypes from "prop-types";
import InputField from "./InputField/InputField";
import ContractsSelect from "./ContractsSelect/ContractsSelect";
import NumberField from "./NumberField/NumberField";
import BooleanField from "./BooleanField/BooleanField";
import classNames from "classnames";
import CategoriesSelect from "./CategoriesSelect/CategoriesSelect";
import UsersSelect from "./UsersSelect/UsersSelect";
import SuppliersSelect from "./SuppliersSelect/SuppliersSelect";
import DropDownsSelect from "./DropDownsSelect/DropDownsSelect";
import DateField from "./DateField/DateField";
import {getConditionResult} from "../../helpers/getConditionResult";
import DescriptionField from "./DescriptionField/DescriptionField";
import AttachmentsField from "./AttachmentsField/AttachmentsField";
import AdditionalTextField from "./AdditionalTextField/AdditionalTextField";
import AdditionalYesNo from "./AdditionalYesNo/AdditionalYesNo";
import AdditionalRadio from "./AdditionalRadio/AdditionalRadio";
import {useTranslation} from "react-i18next";
import {useTranslate} from "../../../../../hooks/useTranslate";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import TypeOfSpendSelect from "./TypeOfSpendSelect/TypeOfSpendSelect";

const SingleStep = ({step, index, setFieldValue, errors, values, changeAdditionalQuestions}) => {
  const baseSteps = ["Start", "Additional information"];
  const [translateKey1, translateValue1] = useTranslate(`Step ${index + 1} - What can I help you with?`);
  const {t} = useTranslation();

  const renderTitle = () => {
    if (step.name === baseSteps[0]) return (
      <p className={styles.header} data-translate-key={translateKey1}>{translateValue1}</p>
    );
    if (step.name === baseSteps[1]) return (
      <p className={styles.header}>
        <span data-translate-key={getTranslateKey("Step")}>
          {t(getTranslateKey("Step"))}
        </span>
        {" "}
        {index + 1}
        {" "}
        -
        {" "}
        <span data-translate-key={getTranslateKey("Additional information")}>
          {t("Additional information")}
        </span>
      </p>
    );
    else return (
      <p className={styles.header}>Step {index + 1} - {step.name}</p>
    );
  };

  const getProps = field => ({field, values, setFieldValue, errors});

  const fieldWrapper = fieldComponent => <div className={styles.fieldWrap}>{fieldComponent}</div>;

  const renderAdditionalQuestion = field => {
    if (field.question.question_type === "text_field") {
      return fieldWrapper(<AdditionalTextField {...getProps(field)} />, field.question.question_id);
    }
    if (field.question.question_type === "yes_no") {
      return fieldWrapper(<AdditionalYesNo {...getProps(field)} />, field.question.question_id);
    }
    return fieldWrapper(<AdditionalRadio {...getProps(field)} />, field.question.question_id);
  };

  const renderField = field => {
    switch (field.type) {
      case "radio":
        return fieldWrapper(<RadioGroupField key={field.key} {...getProps(field)} />);
      case "String":
        return fieldWrapper(<InputField key={field.key} {...getProps(field)} />);
      case "Contract":
        return fieldWrapper(<ContractsSelect key={field.key} {...getProps(field)} />);
      case "Number":
        return fieldWrapper(<NumberField key={field.key} {...getProps(field)} />);
      case "User":
        return fieldWrapper(<UsersSelect key={field.key} {...getProps(field)} />);
      case "Supplier":
        return fieldWrapper(<SuppliersSelect key={field.key} {...getProps(field)} />);
      case "Boolean":
        return fieldWrapper(<BooleanField key={field.key} {...getProps(field)} />);
      case "Category":
        return fieldWrapper(<CategoriesSelect
          key={field.key}
          {...getProps(field)}
          changeAdditionalQuestions={changeAdditionalQuestions}
        />);
      case "Description":
        return fieldWrapper(<DescriptionField key={field.key} {...getProps(field)} />);
      case "Attachments":
        return fieldWrapper(<AttachmentsField key={field.key} {...getProps(field)} />);
      case "additional_question":
        return renderAdditionalQuestion(field);
      case "Date":
        return fieldWrapper(<DateField key={field.key} {...getProps(field)} />);
      case "Custom Dropdown":
        return fieldWrapper(<DropDownsSelect key={field.key} {...getProps(field)} />);
      case "Type of Spend":
        return fieldWrapper(<TypeOfSpendSelect
          key={field.key}
          {...getProps(field)}
          changeAdditionalQuestions={changeAdditionalQuestions}
        />);
    }
  };

  return (
    <div className={styles.singleStep}>
      {renderTitle()}
      <div className={classNames(styles.fieldList, {[styles.full]: !baseSteps.includes(step.name)})}>
        {step.fields.map(field => {
          if (Boolean(field.conditions) && field.conditions !== "()") {
            if (eval(getConditionResult(field.conditions, values))) return renderField(field);
          } else {
            return renderField(field);
          }
        })}
      </div>
    </div>
  );
};

export default SingleStep;

SingleStep.propTypes = {
  step: PropTypes.any,
  index: PropTypes.number,
  setFieldValue: PropTypes.func,
  errors: PropTypes.any,
  touched: PropTypes.any,
  values: PropTypes.any,
  changeAdditionalQuestions: PropTypes.any,
};