import React from 'react';
import s from "./StatusItem.module.scss";

const StatusItem = ({item, index, onClick}) => {
  return (
    <div
      key={index}
      onClick={onClick}
      title={item.label}
      className={s.itemWrap}
    >
      <div className={s.status}>
        <span style={{background: item.color}}/>
        {item.label}
      </div>
    </div>
  );
};

export default StatusItem;