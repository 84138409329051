import axios from "./axios";

export default {
	get: async (id) => {
		try {
			const result = await axios.get(`/projects/${id}/non_financial_benefits`);
			return result;
		} catch (error) {
			throw error;
		}
	},
	edit: async (id, params) => {
		try {
			const result = await axios.put(`/projects/${id}/non_financial_benefits`, params);
			return result;
		} catch (error) {
			throw error;
		}
	}
};
