import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { QuestionPage } from "../QuestionPage/QuestionPage";
import { NewStepPopup } from "../NewStepPopup/NewStepPopup";
import { ReactComponent as Error } from "assets/icons/error.svg";
import styles from "./EditQuestionsPage.module.scss";

export const EditQuestionsPage = ({ steps, setSteps, saveSteps, setSaveSteps }) => {
  const [newStep, setNewStep] = useState({ checked: false, action: "", subAction: "" });
  const [tempSteps, setTempSteps] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const { pathname } = useLocation();
  const currentId = pathname.split("/")[3];

  useEffect(() => {
    const isSumArr = tempSteps.map(
      (step) =>
        step.questions.reduce(
          (acc, quest) =>
            acc +
            (typeof quest?.weight === "string" ? +quest?.weight?.replace("%", "") : quest?.weight),
          0,
        ),
    );

    const isWeightCorrect = isSumArr.reduce((acc, sum) => acc + sum, 0) < 101;

    if (isWeightCorrect) {
      setErrorPopUp(false);
      if (isSaved) {
        setSteps(tempSteps);
        setSaveSteps(false);
        setIsSaved(false);
      }
    } else {
      setErrorPopUp(true);
      setSaveSteps(false);
      setIsSaved(false);
    }
  }, [isSaved]);

  useEffect(() => {
    setTempSteps(
      steps.map((st) => ({ ...st, questions: st.questions?.map((q, id) => ({ ...q, pos: id })) })),
    );
  }, [steps, setSteps]);

  return (
    <>
      {errorPopUp && (
        <section className={styles.error}>
          <Error />
          The weight of all questions must be 100%. Please, change the weight.
        </section>
      )}
      {newStep.checked && (
        <NewStepPopup
          setSteps={setTempSteps}
          setNewPopUp={setNewStep}
          newPopUp={newStep}
          currentId={currentId}
        />
      )}
      {tempSteps.length > 0 &&
        tempSteps.map((step, id) => (
          <QuestionPage
            step={step}
            setNewStep={setNewStep}
            id={id}
            saveSteps={saveSteps}
            setTempSteps={setTempSteps}
            setIsSaved={setIsSaved}
          />
        ))}
    </>
  );
};
