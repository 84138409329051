import React from "react";
import Input from "../../../../../elements/Input/Input";
import PropTypes from "prop-types";

const NumberField = ({field, values, setFieldValue, errors}) => {

  return (
    <Input
      type="text"
      isSeparateNumber
      label={field.label}
      value={values[field.key]?.value || ""}
      onChange={e => {
        const val = Number(e);
        if (!isNaN(val)) setFieldValue(field.key, {value: val, type: "Number", conditions: field.conditions});
      }}
      error={errors[field.key] && errors[field.key].value}
    />
  );
};

export default NumberField;

NumberField.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  errors: PropTypes.any,
};