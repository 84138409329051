import React from "react";
import PropTypes from "prop-types";
import styles from "./TaskDetails.module.scss";
import {ReactComponent as Pencil} from "../../../../../../assets/icons/edit-pencil.svg";
import CustomLink from "../../../../CustomLink/CustomLink";
import InfoBlock from "../../../../InfoBlock/InfoBlock";
import Avatar from "../../../../Avatar/Avatar";
import {formatDate} from "../../../../../../helpers/dateFormat";
import ResponsibleUser
  from "../../../../../containers/AdminSettingsTaskDetailsContainer/components/ResponsibleUser/ResponsibleUser";

const TaskDetails = ({data, openEditModal, useForProject, isHighRule}) => {

  const getAllows = () => {
    if (!useForProject) return true;

    return (isHighRule && data.status !== "completed" && data.optional);
  };

  return (
    <section className={styles.taskDetails}>
      <header>
        <p>Details</p>
        {
          (getAllows()) && (
            <Pencil
              onClick={e => {
                e.stopPropagation();
                openEditModal();
              }}
            />
          )
        }
      </header>
      {
        !useForProject && (
          <InfoBlock title="Assignee:" type="flex">
            <ResponsibleUser user={data.responsible_user}/>
          </InfoBlock>
        )
      }
      {
        useForProject && (
          <React.Fragment>
            <InfoBlock title="Responsible user:" type="flex">
              <Avatar name={data.responsible_user.name}/> {data.responsible_user.name}
            </InfoBlock>
            <InfoBlock title="Due date:" type="flex">
              {formatDate(data.due_date, true)}
            </InfoBlock>
          </React.Fragment>
        )
      }
      <InfoBlock title="Attachments:" type="flex">
        <div className={styles.attachments}>
          {data?.attachments?.map((a, index) => (
            <CustomLink key={index} path={a.path || ""}>
              {a.name}
            </CustomLink>
          ))}
          {!data?.attachments?.length && "-"}
        </div>
      </InfoBlock>
      <InfoBlock title="Description:" type="flex">
        {data.description || "-"}
      </InfoBlock>
    </section>
  );
};

export default TaskDetails;

TaskDetails.propTypes = {
  data: PropTypes.any,
  openEditModal: PropTypes.func,
  useForProject: PropTypes.bool,
  isHighRule: PropTypes.bool
};
