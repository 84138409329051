import React from "react";
import { useHistory } from "react-router-dom";
import ChangePasswordForm from "components/forms/ChangePasswordForm/ChangePasswordForm";
import services from "services";
import { remove } from "helpers/localStorage";
import { useDispatch } from "react-redux";
import { logout } from "actions/user";

const PasswordChangeContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const submitHandler = async ({ password, newPassword }) => {
    try {
      await services.userServices
        .changePassword(password, newPassword)
        .then(() => {
          dispatch(logout());
          history.push("/login");
        });
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <ChangePasswordForm
        onSubmit={submitHandler}
        onClose={() => history.goBack()}
      />
    </>
  );
};

export default PasswordChangeContainer;
