import axios from "./axios"

export default {
  tagReports: async params => {
    try {
      return await axios.get("/tag_reports", { params })
    } catch (error) {
      throw error
    }
  },
  tagReport: async id => {
    try {
      return await axios.get(`/tag_reports/${id}`)
    } catch (error) {
      throw error
    }
  },
  flagReport: async id => {
    try {
      return await axios.get(`/flag_reports/${id}`);
    } catch (error) {
      throw error
    }
  },
  tagReportSuppliers: async (id, params) => {
    try {
      return await axios.get(`/tag_reports/${id}/suppliers`, { params })
    } catch (error) {
      throw error
    }
  },
  tagReportContracts: async (id, params) => {
    try {
      return await axios.get(`/tag_reports/${id}/contracts`, { params })
    } catch (error) {
      throw error
    }
  },
  tagReportProjects: async (id, params) => {
    try {
      return await axios.get(`/tag_reports/${id}/projects`, { params })
    } catch (error) {
      throw error
    }
  },
  userReportActivity: async (id, params) => {
    try {
      return await axios.get(`/user_reports/${id}/year_impact_activity`, {
        params
      })
    } catch (error) {
      throw error
    }
  },
  departmentReportActivity: async (id, params) => {
    try {
      return await axios.get(`/department_reports/${id}/year_impact_activity`, {
        params
      })
    } catch (error) {
      throw error
    }
  },
  getYears: async () => {
    try {
      return await axios.get(`/years`)
    } catch (error) {
      throw error
    }
  }
}
