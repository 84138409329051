import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./CurrencySelectionField.module.scss";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import {useTranslate} from "../../../hooks/useTranslate";
import Label from "../Label/Label";

const CurrencySelectionField = (
  {
    label,
    additionalLabel,
    options,
    fieldPlaceholder,
    error,
    value,
    onChange,
    name,
    className,
    defaultValue,
    inputProps,
    disabled,
    disableCurrency = false,
    optional = false,
  }) => {
  const [currency, setCurrency] = useState({});
  const [_value, setValue] = useState("");
  const [translateKey, translateValue] = useTranslate(label);
  const [translateKeyError, translateValueError] = useTranslate(error);
  const [translateAdditionalLabelKey, translateAdditionalLabelValue] = useTranslate(additionalLabel);

  useEffect(() => {
    setCurrency(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    value !== undefined &&
    value !== null &&
    value !== "" &&
    !inputProps.type &&
    setValue(prev => {
      const val = Number(value).toLocaleString("en-US", {
        maximumFractionDigits: 0
      });
      return val === "NaN" ? prev : val;
    })
    ;(value === undefined || value === null || value === "") &&
    !inputProps.type &&
    setValue("");
  }, [value]);

  const renderLabel = () => {
    if (additionalLabel) {
      return (
        <>
          <span className={s.label} data-translate-key={translateKey}>{translateValue}</span>
          <span className={s.labelGrey} data-translate-key={translateAdditionalLabelKey}>
            {translateAdditionalLabelValue}
          </span>
        </>
      );
    }

    return <Label label={label} optional={optional}/>;
  };

  return (
    <div
      className={classNames(
        s.currencySelectionField,
        {[s.disabled]: disabled},
        className
      )}
    >
      {renderLabel()}
      <div className={s.wrap}>
        <div className={s.fieldWrap}>
          <input
            disabled={disabled}
            value={!inputProps.type ? _value : value}
            onChange={e => {
              if (!inputProps.type && isNaN(e.target.value.replace(/,/g, ""))) {
                if (e.target.value === "-") {
                  onChange({
                    ...currency,
                    fieldVal: ""
                  });
                }
                return;
              }
              onChange({
                ...currency,
                fieldVal: e.target.value.replace(/,/g, "")
              });
            }}
            className={s.currencyField}
            type="text"
            placeholder={fieldPlaceholder}
            name={name}
            {...inputProps}
          />
        </div>
        <CustomDropdown
          className={classNames(s.changeCurrency, {[s.disableCurrency]: disableCurrency})}
          value={currency}
          options={options}
          onChange={e => {
            setCurrency(e);
            onChange({...e, fieldVal: value});
          }}
          disabled={disabled || disableCurrency}
        />
      </div>

      {error && <span className={s.errorMessage} data-translate-key={translateKeyError}>{translateValueError}</span>}
    </div>
  );
};

CurrencySelectionField.propTypes = {
  label: PropTypes.string,
  additionalLabel: PropTypes.string,
  options: PropTypes.array,
  fieldPlaceholder: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.any,
  defaultValue: PropTypes.object,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  disableCurrency: PropTypes.bool,
  optional: PropTypes.bool
};

CurrencySelectionField.defaultProps = {
  label: "Budgeted Spend",
  options: [
    {index: 1, label: "AUD", value: "$AUD"},
    {index: 2, label: "CAD", value: "$CAD"},
    {index: 10, label: "MXN", value: "Mex$"},
    {index: 16, label: "USD", value: "$USD"}
  ],
  fieldPlaceholder: "000,000",
  error: "",
  value: 454,
  onChange: () => {
  },
  name: "budgeted_spend",
  className: "",
  defaultValue: {index: 16, label: "USD", value: "$USD"},
  inputProps: {},
  disabled: false
};

export default CurrencySelectionField;
