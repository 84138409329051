import React, {useEffect, useState} from 'react';
import services from "../../../../../services";
import {useParams} from "react-router-dom";
import styles from "../../TeamReportContainer.module.scss";
import TableLoading from "../../../../loadingStates/Table/Table";
import classNames from "classnames";
import TeamReportProjectTypesTable from "../../../../tables/TeamReportProjectTypesTable/TeamReportProjectTypesTable";
import TeamReportProjectsActiveProjectsTable
  from "../../../../tables/TeamReportProjectsActiveProjectsTable/TeamReportProjectsActiveProjectsTable";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const TeamReportProjects = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const {t} = useTranslation();

  useEffect(() => {
    services.reportServices.getTeamReportProjects(params.id).then(res => {
      if (res.data) {
        setData(res.data);
      }
    });
  }, []);

  const convertProjectTypes = () => {
    return data.project_types.map(type => {
      return {
        ...Object.values(type)[0],
        name: Object.keys(type)[0],
      }
    });
  }

  if (!data) return <TableLoading length={5}/>;

  return (
    <>
      <section className={styles.detailBlock}>
        <TeamReportProjectTypesTable data={convertProjectTypes()}/>
      </section>
      <section>
        <h3
          className={classNames(styles.title, styles.widget)}
          data-translate-key={getTranslateKey("Active projects")}
        >
          {t(getTranslateKey("Active projects"))}
        </h3>
        <TeamReportProjectsActiveProjectsTable data={data.active_projects?.active_projects}/>
      </section>
    </>
  );
};

export default TeamReportProjects;