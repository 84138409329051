import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "helpers/currencyFormat";

const ReportingTagSuppliersTable = ({
  data,
  perPage,
  onPageChange,
  currentPage,
  dataCount,
}) => {
  const history = useHistory();
  return (
    <MainTable
      data={data}
      dataTypes={[
        {
          title: "Category",
          value: ({ category }) => category?.name || "-",
        },
        {
          title: "Supplier",
          value: ({ name }) => name || "-",
          onClick: (id) => history.push(`/suppliers/${id}`),
          sticky: true
        },
        {
          title: "% of Category Spend",
          value: ({ projects_count }) => projects_count || "-",
        },
        {
          title: "Spend (USD)",
          value: ({ spend, spend_currency }) =>
            spend && spend_currency
              ? formatCurrency(spend_currency, spend)
              : "-",
          alignment: "right",
        },
        {
          title: "Payments",
          value: ({ payments_count }) => payments_count || "-",
        },
        {
          title: "Contracts",
          value: ({ contracts_count }) => contracts_count || "-",
        },
        {
          title: "Status",
          value: ({ status }) => status || "-",
        },
        {
          title: "Owner",
          value: ({ owner }) => owner?.name || "-",
        },
      ]}
      perPage={perPage}
      totalCount={dataCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};
export default ReportingTagSuppliersTable;
