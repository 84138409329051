export const formatFinancialByYear = (data) => {
  if (data?.financial_benefits_by_year) {
    for (let key in data.financial_benefits_by_year) {
      if (
        !Object.values(data.financial_benefits_by_year[key]).some((itm) => itm)
      ) {
        delete data.financial_benefits_by_year[key];
      }
    }
  }
  return data;
};
