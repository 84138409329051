import React, {useState, useEffect} from "react";
import s from "./FinancialBenefitsAddContainer.module.scss";
import services from "services";
import {useHistory, useParams} from "react-router-dom";
import FinancialBenefitsForm from "../../forms/FinancialBenefitsForm/FinancialBenefitsForm";
import {appendFile} from "../../../helpers/appendFile";
import {delay} from "../../../helpers/delay";
import {CircularProgress} from "@material-ui/core";

const FinancialBenefitsAddContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    services.projectServices.getProject(params.id).then(res => {
      setProject(res.data);
      setLoading(false);
    });
  }, [params.id]);

  const createFinancialBenefitAction = values => {
    services.financialBenefitsServises.createFinancialBenefit(params.id, values.data).then(res => {
      if (res.data) {
        Promise.all([...values.attachments.map(attachment => {
          const file = appendFile(attachment);
          services.taskServices.addAttachment(file, res.data.id).then(res => res.data);
        })])
          .finally(() => delay(500)
            .then(() => history.push(`/projects/${params.id}/financial-benefits/${res.data.id}`)));
      }
    });
  };

  let formComponent = (
    <div className="loaderWrap justifyCenter">
      <CircularProgress/>
    </div>
  );

  if (!loading) formComponent = (
    <FinancialBenefitsForm
      onSubmit={createFinancialBenefitAction}
      defaultValues={{
        start_date: project?.end_date,
        end_date: project?.end_date,
      }}
    />
  );

  return (
    <div className={s.wrap}>
      <h2 className={s.title}>
        {project?.name}
      </h2>
      {formComponent}
    </div>
  );
};
export default FinancialBenefitsAddContainer;
