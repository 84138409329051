import {object, string} from "yup";

export const validationSchema = withProjectState => object({
  name: string().required("Name is required"),
  request_type: object({
    value: string().required("Request type is required"),
  }),
  project_state: withProjectState && object({
    value: string().required("Project state is required"),
  }),
  condition_type: object({
    value: string().required("Condition type is required"),
  }),
  approver: object({
    value: string().required("Approver is required"),
  }),
  what_approved: object({
    value: string().required("Choose what should be approved"),
  }),
  priority: object({
    value: string().required("Priority is required"),
  })
});