import React, {useEffect, useState} from "react";
import AdminSettingsWrapper from "../../elements/AdminSettingsWrapper/AdminSettingsWrapper";
import TaskDetails from "./components/TaskDetails/TaskDetails";
import Subtasks from "./components/Subtasks/Subtasks";
import styles from "./AdminSettingsTaskDetailsContainer.module.scss";
import services from "../../../services";
import {useParams} from "react-router-dom";
import {editTask} from "../../../helpers/tasks/tasksActions";
import {appendFile} from "../../../helpers/appendFile";
import {delay} from "../../../helpers/delay";
import SelectedTaskSidebar from "../../elements/SelectedTaskSidebar/SelectedTaskSidebar";

const AdminSettingsTaskDetailsContainer = () => {
  const {taskId, id} = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedSubtaskId, setSelectedSubtaskId] = useState(null);
  const selectedSubtask = data?.subtasks?.find(subtask => subtask.id === selectedSubtaskId);

  const load = () => {
    services.adminProjectsServices.getConfigureTask(id, taskId).then(res => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    load();
  }, []);

  const onEditAction = data => {
    setData(prevData => ({...prevData, ...data}));
  };

  const onAddSubtaskAction = params => {
    services.adminProjectsServices.addConfigureSubTask(taskId, params.values).then(res => {
      if (res.data) {
        Promise.all([...params.attachments.map(attachment => {
          const file = appendFile(attachment);
          services.taskServices.addAttachment(file, res.data.id).then(res => res.data);
        })]).finally(() => delay(100).then(() => {
          setData(prevData => ({...prevData, subtasks: [...prevData.subtasks, res.data]}));
        }));
      }
    });
  };

  const onEditSubtaskAction = (params, subtaskId) => {
    services.adminProjectsServices.editConfigureSubTask(params.values, taskId, subtaskId).then(res => {
      if (res.data) {
        Promise.all([...params.attachments.map(attachment => {
          const file = appendFile(attachment);
          services.taskServices.addAttachment(file, res.data.id).then(res => res.data);
        })]).finally(() => delay(100).then(() => load()));
      }
    });
  };

  const deleteSubtaskAction = subtaskId => {
    services.adminProjectsServices.deleteConfigureSubTask(taskId, subtaskId).then(() => {
      setData(prevData => ({...prevData, subtasks: prevData.subtasks.filter(s => s.id !== subtaskId)}));
    });
  };

  return (
    <AdminSettingsWrapper loading={loading}>
      <React.Fragment>
        <h2 className={styles.title}>{data?.name}</h2>
        <TaskDetails
          data={data}
          edit={values => editTask(values, taskId, onEditAction, id)}
        />
        <Subtasks
          subtasks={data?.subtasks}
          addSubtask={onAddSubtaskAction}
          deleteSubtask={deleteSubtaskAction}
          editSubtask={onEditSubtaskAction}
          selectTask={subtaskId => setSelectedSubtaskId(subtaskId)}
        />
        <SelectedTaskSidebar
          selectedSubtask={selectedSubtask}
          onClose={() => setSelectedSubtaskId(null)}
          onEdit={onEditSubtaskAction}
        />
      </React.Fragment>
    </AdminSettingsWrapper>
  );
};

export default AdminSettingsTaskDetailsContainer;
