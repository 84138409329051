import React, {useState} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./Detail.module.scss";
import Button from "components/elements/Button/Button";
import {ReactComponent as ArrowDown} from "assets/icons/arrowDown.svg";
import {trimRest} from "../../../helpers/stringHelper";
import {useTranslate} from "../../../hooks/useTranslate";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Detail = (
  {
    data = [],
    className,
    showBtn,
    title,
    buttons,
    useColumns = false,
  }
) => {
  const [opened, setOpened] = useState(false);
  const [translateKeyTitle, translateValueTitle] = useTranslate(title);
  const [translateKey, translateValue] = useTranslate(opened ? "Show less" : "Show more");
  const {t} = useTranslation();
  const divider = fields => {
    return [
      fields.slice(0, Math.ceil(fields.length / 2)),
      fields.slice(Math.ceil(fields.length / 2)),
    ];
  };

  const renderAttachments = attachments => {
    if (!attachments.length) return "-";

    return (
      <div className={s.attachments}>
        {
          attachments.map((a, index) => (
            <div>
              <a
                title={a.name}
                className={s.link}
                href={a.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                {trimRest(20, a.name)}
              </a>
              {index < attachments.length - 1 && ", "}
            </div>
          ))
        }
      </div>
    );
  };

  const renderField = (field, key = "key") => {
    return (
      <div className={classNames(s.row, {[s.paragraph]: field.useParagraph})} key={key}>
        <div className={s.col1}>
          <span data-translate-key={getTranslateKey(field.name)}>
            {t(getTranslateKey(field.name))}
          </span>:
        </div>
        <div className={s.col2} style={{maxWidth: field.maxWidth ?? "auto"}}>
          {field.type !== "attachment" ? field.value : renderAttachments(field.value)}
        </div>
      </div>
    );
  };

  const getDescription = description => {
    if (description) {
      if (showBtn && opened) return description;
      return description;
    }
    return "-";
  };

  const withColumnsRender = () => {
    const description = data.find(i => i.name === "Description" || i.name === "Note");
    data = data.filter(i => i.name !== "Description" && i.name !== "Note");

    const [firstDef, lastDef] = divider(data.slice(0, showBtn));
    const [firstRest, lastRest] = divider(data.slice(showBtn));

    return (
      <div>
        <div className={s.columnsWrapper}>
          <div className={s.column}>
            {firstDef.map((field, index) => renderField(field, index))}
            {opened && firstRest.map((field, index) => renderField(field, index))}
          </div>
          <div className={s.column}>
            {lastDef.map((field, index) => renderField(field, index))}
            {opened && lastRest.map((field, index) => renderField(field, index))}
          </div>
        </div>
        {renderField(getDescription(description))}
      </div>
    );
  };

  const withoutColumnsRender = () => {
    return data.map((item, index) => {
      if (item && showBtn && showBtn > index && !opened) {
        return renderField(item, index);
      }
      if (item && (opened || !showBtn)) {
        return renderField(item, index);
      } else {
        return null;
      }
    });
  };

  return (
    <div className={classNames(s.container, className)}>
      {title && <h4 className={s.title} data-translate-key={translateKeyTitle}>{translateValueTitle}</h4>}
      {useColumns ? withColumnsRender() : withoutColumnsRender()}
      {showBtn && (
        <span
          className={classNames(s.showBtn, {[s.opened]: opened})}
          onClick={() => setOpened(!opened)}
          data-translate-key={translateKey}
        >
          {translateValue}
          <ArrowDown/>
        </span>
      )}
      {buttons.length > 0 && (
        <div className={s.buttons}>
          {buttons.map(
            (btn, index) => {
              if (btn) {
                if (btn.component) return btn.component;
                return (
                  <Button
                    styled={btn.styled || "primary"}
                    className={s.button}
                    onClick={() => btn.onClick()}
                    disabled={btn.disabled}
                    key={index}
                  >
                    {btn.name}
                  </Button>
                );
              }
            }
          )}
        </div>
      )}
    </div>
  );
};

Detail.propTypes = {
  data: PropTypes.array,
  buttons: PropTypes.array,
  className: PropTypes.string,
  showBtn: PropTypes.any,
  title: PropTypes.string,
  useColumns: PropTypes.bool,
};

Detail.defaultProps = {
  data: [
    {name: "Budgeted Spend", value: "$USD 454"},
    {name: "Anticipated Savings", value: "$USD 34"},
    {name: "Savings type", value: "Run Rate (CA)"},
    {name: "Note", value: "gg"},
  ],
  buttons: [],
};

export default Detail;
