import {currencyData} from "../../../../../../helpers/helpData";

export const formatData = (data = []) => {
  const headerArr = [{ type: "date", label: "Month" }];
  const valueArr = [];
  data[0].spend_by_month.forEach((itm, index) => {
    valueArr.push([new Date(`${index + 1}/01/${itm.year}`)]);
  });
  data.forEach((item) => {
    headerArr.push(item.category);
    item.spend_by_month.forEach((itm, index) => {
      valueArr[index].push(itm.spend);
    });
  });
  return [headerArr, ...valueArr];
};

export const generateColors = (size = 10) => {
  const colors = [];
  while (colors.length < size) {
    let color = Math.floor(Math.random() * 1000000 + 1);
    colors.push("#" + ("000000" + color.toString(16)).slice(-6));
  }
  return colors;
};

export const preformatData = (data) => {
  data.spend_by_month = data.spend_by_month.filter((category) =>
    category.spend_by_month.some((month) => month.spend)
  );
  let currency = currencyData.filter((e) => e.label === data.currency)[0]
    ?.value;
  data.currency = currency === "USD $" ? "$" : currency;
  return data;
};
