import React from 'react';
import s from "../MainDashboard.module.scss";
import Notifications from "../Notifications/Notifications";
import Tasks from "../Tasks/Tasks";
import NewRequest from "../NewRequest/NewRequest";
import RecentRequest from "../RecentRequest/RecentRequest";
import classNames from "classnames";
import GanttChartDashboard from "../GanttChart/GanttChartDashboard";

const BusinessUserDashboard = () => {
  return (
    <>
      <div className={s.group}>
        <Notifications/>
        <Tasks/>
      </div>
      <div className={classNames(s.group, s.high)}>
        <NewRequest/>
        <RecentRequest/>
      </div>
      <div className={s.ganttChart}>
        <GanttChartDashboard/>
      </div>
    </>
  );
};

export default BusinessUserDashboard;