import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import DropList from './DropList'
import {ReactComponent as Menu} from 'assets/icons/menu.svg'
import useOutsideClicker from 'hooks/useOutsideClicker'
import classNames from 'classnames'
import s from './DropListTrigger.module.scss'

const DropListTrigger = (
  {
    data,
    className,
    visibleMenu,
    setVisibleMenu,
    ariaLabel,
    withOverflow,
    menuIcon,
    customMenuClass,
    customBtnClass,
    withScroll
  }
) => {
  const [menu, setMenu] = useState(false)
  const menuRef = useRef(null)
  useEffect(() => {
    setMenu(visibleMenu)
  }, [visibleMenu])
  useOutsideClicker(menuRef, () => setMenu(false))

  const handleClick = value => {
    setVisibleMenu && setVisibleMenu(value)
    setMenu(!menu)
  }

  return (
    <div
      className={classNames(s.wrapDropListTrigger, className)}
      aria-label={ariaLabel}
    >
      <button
        className={classNames(s.trigger, s[customBtnClass], {[s.active]: menu})}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          handleClick(true)
        }}
      >
        {
          menuIcon ?
            <span className={classNames(s.menu, s[customMenuClass])}>
            {menuIcon}
            </span> :
            <Menu className={s.menu}/>
        }
      </button>

      {menu && (
        <div ref={menuRef}>
          <DropList
            setVisibleMenu={handleClick}
            data={data}
            withOverflow={withOverflow}
            withScroll={withScroll}
          />
        </div>
      )}
    </div>
  )
}

DropListTrigger.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  visibleDropListMenu: PropTypes.bool,
  withOverflow: PropTypes.bool
}

DropListTrigger.defaultProps = {
  data: [
    {
      title: 'Change status',
      onClick: () => {
      }
    },
    {
      title: 'Delete',
      onClick: () => {
      }
    }
  ],
  className: '',
  visibleDropListMenu: false,
  withOverflow: true
}

export default DropListTrigger
