import React from "react";
import Popup from "components/popups/Popup/Popup";
import PropTypes from "prop-types";
import AdminSettingsFinancialSavingsGroupDeleteForm
  from "../../../../../../forms/AdminSettingsFinancialSavingsGroupDeleteForm/AdminSettingsFinancialSavingsGroupDeleteForm";

const DeleteFinancialSavingsGroupPopup = ({ onClose, onSubmit, groupId }) => {
  return (
    <Popup
      onClose={onClose}
      title="Delete savings group"
      text="Are you sure you want to delete this savings group? Please, choose where do you want to send savings types in this group."
    >
      <AdminSettingsFinancialSavingsGroupDeleteForm
        onClose={onClose}
        onSubmit={onSubmit}
        groupId={groupId}
      />
    </Popup>
  );
};

export default DeleteFinancialSavingsGroupPopup;

DeleteFinancialSavingsGroupPopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
