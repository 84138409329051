import React, { useState, useEffect, Fragment, useContext } from "react"
import { useParams, useHistory } from "react-router-dom"
import { debounce } from "lodash"

import services from "services/index"
import Popup from "components/popups/Popup/Popup"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import AddProjectStakeholdersForm from "components/forms/AddProjectStakeholdersForm/AddProjectStakeholdersForm"
import StakeholdersTable from "components/tables/StakeholdersTable/StakeholdersTable"
import AlertContext from "contexts/AlertContext/AlertContext"
import TableLoading from "components/loadingStates/Table/Table"

const StakeholdersContainer = ({ ownerId, isSurveySent, setServeySent }) => {
  const params = useParams()
  const history = useHistory()
  const [users, setUsers] = useState([])
  const [stakeholderTypes, setStakeholderTypes] = useState([])
  const [stakeholders, setStakeholders] = useState([])
  const [stakeholderForEdit, setStakeholderForEdit] = useState(null)
  const [show, setShow] = useState(false)
  const [surveyPopupVisibility, setSurveyPopupVisibility] = useState(false)
  const [loading, setLoading] = useState(true)
  const { setAlert } = useContext(AlertContext)
  const [permissions, setPermissions] = useState({
    update: false,
    destroy: false
  })

  useEffect(() => {
    services.stakeholdersServices.getStakeholders(params.id).then(res => {
      setStakeholders(res.data.stakeholders)
      setPermissions({
        update: res.data.permissions?.update || false,
        destroy: res.data.permissions?.destroy || false
      })
      setLoading(false)
    })

    services.stakeholdersServices.getStakeholderTypes().then(({ data }) => {
      setStakeholderTypes(data)
    })
  }, [])
  const addHandler = values => {
    services.stakeholdersServices
      .addStakeholder(params.id, values)
      .then(res => {
        setStakeholders([...stakeholders, res.data])
        setAlert({
          message: `"${res.data.user.name ||
            ""}" stakeholder successfully added.`
        })

        isSurveySent && setSurveyPopupVisibility(true)
      })
      .catch(res =>
        setAlert({
          message:
            res.response.data.error ||
            "Something went wrong. Please, try again.",
          type: "error"
        })
      )
  }

  const editHandler = values => {
    services.stakeholdersServices
      .updateStakeholder(params.id, values)
      .then(res => {
        setStakeholders(
          stakeholders.map(item =>
            item.user?.id !== res.data.user.id ? item : res.data
          )
        )
        setAlert({
          message: `"${res.data.user.name ||
            ""}" stakeholder successfully updated.`
        })

        isSurveySent && setSurveyPopupVisibility(true)
      })
      .catch(res =>
        setAlert({
          message:
            res.response.data.error ||
            "Something went wrong. Please, try again.",
          type: "error"
        })
      )
  }

  const removeHandler = stakeholder => {
    services.stakeholdersServices
      .removeStakeholder(params.id, { stakeholder_id: stakeholder.user?.id })
      .then(() => {
        setAlert({
          type: "success",
          message: `"${stakeholder.user?.name}" stakeholder successfully removed.`,
          onCancel: () => {
            services.stakeholdersServices
              .addStakeholder(params.id, {
                stakeholder_id: stakeholder.user?.id
              })
              .then(res => {
                setStakeholders(state => [...state, res.data])
              })
          }
        })

        stakeholders.length < 2 && setServeySent(false)
        setStakeholders(state =>
          state.filter(item => item.user?.id !== stakeholder.user?.id)
        )
      })
      .catch(() => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      })
  }

  const searchHandler = debounce(term => {
    services.userServices
      .stakeholdersAutocomplete({ query: term })
      .then(res => setUsers(res.data.filter(user => user.id !== ownerId)))
  }, 300)

  const sendSurveyHandler = () => {
    services.surveyServices
      .sendSurvey(params.id)
      .then(res => {
        setServeySent(true)
        setAlert({
          message: res.data.message
        })
      })
      .catch(res =>
        setAlert({
          message: res.response.data.error,
          type: "error"
        })
      )
      .finally(() => setSurveyPopupVisibility(false))
  }

  useEffect(() => {
    setShow(stakeholderForEdit ? "edit" : false)
  }, [stakeholderForEdit])

  return (
    <Fragment>
      {loading && <TableLoading length={5} />}
      {!loading && (
        <Fragment>
          {stakeholders.length ? (
            <StakeholdersTable
              stakeholders={stakeholders}
              removeHandler={removeHandler}
              history={history}
              onAddNew={() => setShow("add")}
              onEdit={setStakeholderForEdit}
              havePermissions={permissions}
            />
          ) : (
            <EmptyBlock
              subtitle={
                permissions.update
                  ? "You don’t have stakeholders yet. You can add a new one"
                  : "You don’t have stakeholders yet."
              }
              buttonTitle='Add Stakeholder'
              onClick={() => setShow("add")}
              withBtn={permissions.update}
            />
          )}

          {show && (
            <Popup
              onClose={() => {
                setStakeholderForEdit(null)
                setShow(false)
              }}
              title={`${show === "add" ? "Add" : "Edit"} stakeholder`}
              text={
                show === "add" ? "Choose a stakeholder from the list below" : ""
              }
            >
              <AddProjectStakeholdersForm
                users={users}
                stakeholder={stakeholderForEdit}
                stakeholders={stakeholders}
                stakeholderTypes={stakeholderTypes.map(type => ({
                  value: type.id,
                  label: type.name
                }))}
                addHandler={show === "add" ? addHandler : editHandler}
                searchHandler={searchHandler}
                onClose={() => {
                  setShow(false)
                  setStakeholderForEdit(null)
                }}
              />
            </Popup>
          )}

          {surveyPopupVisibility && (
            <Popup
              onClose={() => setSurveyPopupVisibility(false)}
              onClick={sendSurveyHandler}
              title='Send survey'
              text="You have already sent a survey for the project's stakeholders."
              dialog
              successBtnTitle='Send'
              cancelBtnTitle='Not now'
              subText="If you want to send a survey for this one, click on 'Send' button."
            />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default StakeholdersContainer
