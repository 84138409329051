import React, {useContext} from "react";
import s from "./PriorityForm.module.scss";
import sBase from "../ApprovalForm.module.scss";
import PropTypes from "prop-types";
import UniversalSelect from "../../../elements/UniversalSelect/UniversalSelect";
import {ModeContext} from "../../../../contexts/ModeContext/ModeContext";
import {mods} from "../../../../contexts/ModeContext/mods";
import {priorities} from "../../../../helpers/approvalsDefaultData";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const PriorityForm = ({values, setFieldValue, errors, touched}) => {
  const {mode} = useContext(ModeContext);
  const isViewMode = mode === mods.view;
  const {t} = useTranslation();

  return (
    <div className={s.priorityForm}>
      <h3 className={sBase.formGroupTitle} data-translate-key={getTranslateKey("Priority")}>
        {t(getTranslateKey("Priority"))}
      </h3>
      <div className={sBase.input}>
        <UniversalSelect
          options={priorities}
          value={values.priority}
          onChange={option => !isViewMode && setFieldValue("priority", option)}
          placeholder="Choose priority"
          error={touched.priority && errors.priority && errors.priority.value}
          disabled={isViewMode}
          customItemType="priority"
          withTranslate
        />
      </div>
    </div>
  );
};

export default PriorityForm;

PriorityForm.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  errors: PropTypes.any,
};