import React, { useState } from "react";
import RequestPasswordResetForm from "components/forms/RequestPasswordResetForm/RequestPasswordResetForm";

import EmailSent from "./components/EmailSent/EmailSent";
import services from "services";

const PasswordResetRequestContainer = () => {
  const [email, setEmail] = useState("");

  const resetHandler = async({email}) => {
    try {
      const result = await services.userServices.requestPasswordReset(email);
      setEmail(email);
      return result;
    } catch (e) {
      throw e;
    }
  };

  return (
    <>
      {!email ? (
        <RequestPasswordResetForm onSubmit={resetHandler} />
      ) : (
        <EmailSent email={email} />
      )}
    </>
  );
};

export default PasswordResetRequestContainer;
