import React from "react";
import Popup from "../Popup/Popup";
import AssignToProjectForm from "components/forms/AssignToProjectForm/AssignToProjectForm";

const AssignToProjectPopup = ({ title, onClose, onSubmit }) => {
  return (
    <Popup title={title} onClose={onClose}>
      <AssignToProjectForm onClose={onClose} onSubmit={onSubmit} />
    </Popup>
  );
};

export default AssignToProjectPopup;
