import React, {useEffect, useState} from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {getAllIds} from "components/elements/FilterBar/helpers/helpers";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import {debounce} from "lodash";
import PropTypes from "prop-types";
import services from "../../../services";

const ProjectsFilter = ({filters, className}) => {
  const history = useHistory();
  const [savedFilter, setSavedFilter] = useState(null);

  useEffect(() => {
    services.filterService.getFilter("projects")
      .then(res => {
        if (res.data && res.data[0]) {
          setSavedFilter(res.data[0]);
          showDefaultFilter(res.data[0]?.filter_params || "");
        }
      });
  }, []);

  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleClearAll = () => {
    const searchQuery = {page, tab, sort_by, direction};
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const saveDefaultFilter = () => {
    services.filterService.addFilter("projects", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  }

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  }

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams || savedFilter?.filter_params || "{}"),
      tab,
      sort_by,
      direction,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  }

  const handleSearch = debounce(term => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma"
        }
      )
    });
  }, 300);

  return (
    <FilterBar
      filterName='Filter projects'
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      withSearch
      savedFilter={savedFilter}
      clearAllWithSearch={true}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
    >
      <FilterBarDropdown
        name='Users'
        options={filters.owners}
        defaultValues={query?.owner_ids?.split(",")}
        handleChange={values => {
          handleChangeFilter("owner_ids", values);
        }}
        searchPlaceholder='Search user'
      />
      <FilterBarDropdown
        name='Years'
        defaultValues={query?.years?.split(",")}
        options={filters.years?.map(e => ({
          id: String(e),
          name: String(e)
        }))}
        handleChange={values => {
          handleChangeFilter("years", values);
        }}
      />
      <FilterBarDropdown
        name='Type'
        defaultValues={query?.project_type_ids?.split(",")}
        options={filters.project_types}
        handleChange={values => {
          handleChangeFilter("project_type_ids", values);
        }}
      />
      <FilterBarDropdown
        name='Categories'
        searchPlaceholder='Search category'
        defaultValues={query?.category_ids?.split(",")}
        options={filters.categories}
        handleChange={values => {
          handleChangeFilter("category_ids", values);
        }}
      />
      <FilterBarDropdown
        name='Stakeholders'
        searchPlaceholder='Search stakeholder'
        defaultValues={query?.stakeholder_ids?.split(",")}
        options={filters.stakeholders}
        handleChange={values => {
          handleChangeFilter("stakeholder_ids", values);
        }}
      />
      <FilterBarDropdown
        name='Departments'
        options={filters.departments}
        defaultValues={query?.department_ids?.split(",")}
        handleChange={values => {
          handleChangeFilter("department_ids", values);
        }}
      />
      <FilterBarDropdown
        name='Divisions'
        options={filters.divisions}
        defaultValues={query?.division_ids?.split(",")}
        handleChange={values => {
          handleChangeFilter("division_ids", values);
        }}
      />
    </FilterBar>
  );
};

export default ProjectsFilter;

ProjectsFilter.propTypes = {
  filters: PropTypes.any,
  className: PropTypes.string,
};
