import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import MainTable from "components/tables/MainTable/MainTable";
import {currencyData} from "helpers/helpData";
import {formatCurrency} from "helpers/currencyFormat";
import sort from "helpers/sortHelper";
import s from "./FirstLevelCategoriesTable.module.scss";

const FirstLevelCategoriesTable = (
  {
    data: initialData,
    onNameClick = () => {
    }
  }
) => {
  const organizationName = useSelector(state => state.user.organization.name);
  const [data, setData] = useState(initialData);
  useEffect(() => {
    setData(initialData);
  }, [initialData]);
  const onSort = ({sort_by, direction}) => {
    if (sort_by === "name") {
      setData(sort.sortString(data, {field: sort_by, direction}));
    } else {
      setData(sort.sortNumber(data, {field: sort_by, direction}));
    }
  };
  const checkForMonth = item => Object.prototype.hasOwnProperty.call(item, "total");

  return (
    <MainTable
      data={data}
      withXScroll={true}
      uniqueRowStyle={item => checkForMonth(item) && s.totalSum}
      dataTypes={[
        {
          title: "Category",
          value: ({name}) => name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          onClick: onNameClick
        },
        {
          title: "Spend",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "spend", direction});
          },
          value: ({spend}) => {
            return spend
              ? formatCurrency(
                currencyData.filter(e => e.label === "USD")[0]?.value,
                spend
              )
              : "-";
          }
        },
        {
          title: "Suppliers",
          value: ({suppliers_count}) => suppliers_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "suppliers_count", direction});
          }
        },
        {
          title: "Payments",
          value: ({payments_count}) => payments_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "payments_count", direction});
          }
        },
        {
          title: "Contracts",
          value: ({contracts_count}) => contracts_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "contracts_count", direction});
          },
          visible: organizationName !== "Indeed"
        },
        {
          title: "Projects",
          value: ({projects_count}) => projects_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "projects_count", direction});
          }
        },
        {
          title: "Anticipated Savings",
          sortable: true,
          position: "end",
          handleSort: ({direction}) => {
            onSort({sort_by: "anticipated_savings", direction});
          },
          value: ({anticipated_savings}) => {
            return anticipated_savings
              ? formatCurrency(
                currencyData.filter(e => e.label === "USD")[0]?.value,
                anticipated_savings
              )
              : "-";
          }
        }
      ]}
    />
  );
};
export default FirstLevelCategoriesTable;

