import React, {useEffect, useRef, useState} from 'react';
import styles from "./CustomStatusTabs.module.scss";
import classNames from "classnames";
import SingleTab from "./SingleTab";
import {getTranslateKey} from "../../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const CustomStatusTabs = ({children, addNewSelector, changeSelector, deleteSelector}) => {
  const [activeTab, setActiveTab] = useState("");
  const [newSelectorValue, setNewSelectorValue] = useState("");
  const [isCreateMode, setCreateMode] = useState(false);
  const [currentOpened, setCurrentOpened] = useState("");
  const {t} = useTranslation();
  const tabsRef = useRef();

  const childrenArrays = React.Children.toArray(children);

  useEffect(() => {
    if (childrenArrays.length) {
      setActiveTab(childrenArrays[0].props.id);
    }
  }, []);

  const createSelector = () => {
    setNewSelectorValue("Selector name");
    setCreateMode(true);
  }

  const changeSelectorValue = e => {
    if (e.target.value.length < 50) {
      setNewSelectorValue(e.target.value);
    }
  }

  const addSelector = async () => {
    if (Boolean(newSelectorValue)) {
      const newTabId = await addNewSelector(newSelectorValue);
      if (newTabId) setActiveTab(newTabId);
    }
    setNewSelectorValue("");
    setCreateMode(false);
  }

  const changeSelectorAction = (id, value) => {
    changeSelector(id, value);
    setCurrentOpened("");
  }

  const deleteSelectorAction = async id => {
    const isDeleted = await deleteSelector(id);
    if (isDeleted && childrenArrays.length && id === activeTab) {
      setActiveTab(childrenArrays[0].props.id);
    }
  }

  const onActiveTab = tab => {
    setActiveTab(tab);
  }

  const onOpenTab = value => {
    if (currentOpened === value) setCurrentOpened("");
    else setCurrentOpened(value);
  }

  const renderTabs = () => {
    if (childrenArrays.length) return React.Children.map(children, child => {
      if (child.props.id === activeTab) return child.props.children;
    });

    return (
      <div
        className={styles.emptyContent}
        data-translate-key={getTranslateKey("You don’t have custom selectors yet. You can add a new one.")}
      >
        {t(getTranslateKey("You don’t have custom selectors yet. You can add a new one."))}
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={styles.tabs} ref={tabsRef}>
        <div className={styles.tabsWrapper}>
          {
            React.Children.map(children, child => {
              return (
                <SingleTab
                  child={child}
                  isActive={child.props.id === activeTab}
                  isOpen={child.props.id === currentOpened}
                  setActiveTab={onActiveTab}
                  setOpenTab={onOpenTab}
                  changeSelector={value => changeSelectorAction(child.props.id, value)}
                  deleteSelector={deleteSelectorAction}
                  portalContainer={tabsRef}
                />
              )
            })
          }
          {
            isCreateMode && (
              <input
                type="text"
                autoFocus
                value={newSelectorValue}
                onChange={changeSelectorValue}
                onBlur={addSelector}
                className={styles.input}
              />
            )
          }
        </div>
        <div
          className={classNames(styles.tab, styles.btn)}
          onClick={createSelector}
        >
          +
        </div>
      </div>
      {renderTabs()}
    </React.Fragment>
  );
};

export default CustomStatusTabs;