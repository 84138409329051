import querystring from "query-string"
import {useSelector} from "react-redux"
import {useEffect, useState} from "react";
import services from "../services";

export const useProjectsPath = () => {
  const currentYear = String(new Date().getFullYear())
  const user = useSelector(state => state.user)
  const [preparedFilter, setPreparedFilter] = useState({
    years: currentYear,
    owner_ids: user.id,
  });

  useEffect(() => {
    services.filterService.getFilter("projects")
      .then(res => {
        if (res.data && res.data.length) {
          setPreparedFilter(JSON.parse(res.data[0]?.filter_params))
        }
      });
  }, []);

  return `/projects?${querystring.stringify({
    tab: "Board",
    ...preparedFilter,
  })}`
}
