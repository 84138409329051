import axios from "./axios";

export default {
  getTaskStatuses: async () => {
    try {
      return await axios.get("/task_statuses");
    } catch (error) {
      throw error;
    }
  },

  getTasks: async (params) => {
    try {
      return await axios.get("/self/tasks", {params});
    } catch (error) {
      throw error;
    }
  },

  changeTaskStatus: async (milestoneId, id, params) => {
    try {
      return await axios.put(`/milestones/${milestoneId}/tasks/${id}/change_status`, params);
    } catch (error) {
      throw error;
    }
  },

  addTask: async (id, params) => {
    try {
      const result = await axios.post(`/milestones/${id}/tasks`, params, {});
      return result;
    } catch (error) {
      throw error;
    }
  },

  editTask: async (milestoneId, id, params, config) => {
    try {
      return await axios.put(
        `/milestones/${milestoneId}/tasks/${id}`,
        params,
        config
      );
    } catch (error) {
      throw error;
    }
  },

  deleteTask: async (milestoneId, id, config) => {
    try {
      const result = await axios.delete(
        `/milestones/${milestoneId}/tasks/${id}`,
        config
      );
      return result;
    } catch (error) {
      throw error;
    }
  },

  getTaskNotes: async id => {
    try {
      return await axios.get(`/tasks/${id}/notes`);
    } catch (error) {
      throw error;
    }
  },

  addAttachment: async (params, id) => {
    try {
      return await axios.post(`/custom_attachments?source_id=${id}`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    } catch (error) {
      throw error;
    }
  },

  deleteAttachment: async id => {
    try {
      return await axios.delete(`/custom_attachments/${id}`);
    } catch (error) {
      throw error;
    }
  },

};
