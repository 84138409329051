import React, {useEffect, useState} from "react";
import s from "./ProjectNonFinancialBenefitsForm.module.scss";
import PropTypes from "prop-types";
import Button from "components/elements/Button/Button";
import SelectCheckDropdown from "components/elements/SelectCheckDropdown/SelectCheckDropdown";
import services from "services";
import {getBenefitsByGroup} from "./helpers/getBenefitsByGroup";
import {getMainValues} from "./helpers/getMainValues";
import {getInitialValues} from "./helpers/getInitialValues";

const ProjectNonFinancialBenefitsForm = (
  {
    onClose,
    onSubmit,
    initialData,
  }
) => {

  const [data, setData] = useState([]);
  const [mainValues, setMainValues] = useState({
    risk_migration: getInitialValues(initialData, "Risk migration"),
    process_enhancements: getInitialValues(initialData, "Process Enhancements"),
    relationship_management: getInitialValues(initialData, "Relationship Management"),
    contract_benefits: getInitialValues(initialData, "Contract Benefits"),
  });

  useEffect(() => {
    services.financialBenefitsServises.getNonFinancialBenefitsNoAdmin().then(res => {
      if (res.data) {
        setData(res.data);
      }
    });
  }, []);

  const submit = () => {
    onSubmit({
      non_savings_types_ids: getMainValues(data, mainValues),
    });
  };

  return (
    <form className={s.form} onSubmit={e => {
      e.preventDefault();
      submit();
    }}>
      <SelectCheckDropdown
        label="Risk migration"
        className={s.dropdown}
        options={getBenefitsByGroup("Risk migration", data)}
        value={mainValues.risk_migration}
        onChange={(e) => setMainValues({...mainValues, risk_migration: e})}
        placeholder="Choose benefit"
        valueFormat="first"
      />
      <SelectCheckDropdown
        label="Process Enhancements"
        className={s.dropdown}
        options={getBenefitsByGroup("Process Enhancements", data)}
        value={mainValues.process_enhancements}
        onChange={(e) => setMainValues({...mainValues, process_enhancements: e})}
        placeholder="Choose benefit"
        valueFormat="first"
      />
      <SelectCheckDropdown
        label="Relationship Management"
        className={s.dropdown}
        options={getBenefitsByGroup("Relationship Management", data)}
        value={mainValues.relationship_management}
        onChange={(e) => setMainValues({...mainValues, relationship_management: e})}
        placeholder="Choose benefit"
        valueFormat="first"
      />
      <SelectCheckDropdown
        label="Contract Benefits"
        className={s.dropdown}
        options={getBenefitsByGroup("Contract Benefits", data)}
        value={mainValues.contract_benefits}
        onChange={(e) => setMainValues({...mainValues, contract_benefits: e})}
        placeholder="Choose benefit"
        valueFormat="first"
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {initialData ? "Save" : "Add"}
        </Button>
      </div>
    </form>
  );
};

ProjectNonFinancialBenefitsForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  initialData: PropTypes.object,
};

ProjectNonFinancialBenefitsForm.defaultProps = {
  onClose: () => {
  },
  onSubmit: () => {
  },
};

export default ProjectNonFinancialBenefitsForm;
