import React from "react";
import Slider from "react-slick";
import MOSlide from "./MOSlide/MOSlide";
import ManagementCard from "./ManagementCard/ManagementCard";
import CustomLink from "components/elements/CustomLink/CustomLink";
import {
  managementCardsData,
  managementSliderData,
} from "./managementCardsData";
import s from "./ManagementOverview.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const ManagementOverview = () => {
  const {t} = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className={s.managementOverview}>
      <div className={s.landingContainer}>
        <h2 className={s.title} data-translate-key={"procurement-performance-management-overview"}>
          {t(getTranslateKey("procurement-performance-management-overview"))}
        </h2>

        <div className={s.cardBlock}>
          {managementCardsData.map((card) => (
            <ManagementCard
              key={card.number}
              img={card.img}
              title={card.title}
              number={card.number}
              text={card.text}
            />
          ))}
        </div>

        <div className={s.mobileBlock}>
          <Slider {...settings} className={s.slider}>
            {managementSliderData.map((slide, index) => (
              <MOSlide
                key={index}
                img1={slide.img1}
                number1={slide.number1}
                title1={slide.title1}
                text1={slide.text1}
                img2={slide.img2}
                number2={slide.number2}
                title2={slide.title2}
                text2={slide.text2}
              />
            ))}
          </Slider>
        </div>

        <CustomLink className={s.buttonLink} type="nav-link" path="/product">
          Learn more
        </CustomLink>
      </div>
    </div>
  );
};

export default ManagementOverview;
