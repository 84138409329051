import React from "react";
import AdminSettingsFlagsContainer from "../../containers/AdminSettingsFlagsContainer/AdminSettingsFlagsContainer";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

const AdminSettingsFlagsPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {name: "Admin Settings", disabled: true},
        {name: "Flags"},
      ]}
    >
      <AdminSettingsFlagsContainer/>
    </DefaultLayout>
  );
};

export default AdminSettingsFlagsPage;
