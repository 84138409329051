export const popupTypes = {
  add: "ADD",
  delete: "DELETE",
};

export const SET_PROJECTS = "SET_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const SET_POPUP = "SET_POPUP";
export const RESET_ALERT = "RESET_ALERT";
export const CURRENT_PROJECT = "CURRENT_PROJECT";
export const SET_LOADING = "SET_LOADING";
