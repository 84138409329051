import axios from "./axios";

export default {
  getProjectStages: async () => {
    try {
      return await axios.get("/admin/stages");
    } catch (error) {
      throw error;
    }
  },
  getProjectTypeStages: async id => {
    try {
      return await axios.get(`/admin/project_types/${id}/stages`);
    } catch (error) {
      throw error;
    }
  },
  addConfigureTask: async (projectId, values) => {
    try {
      return await axios.post(`/admin/project_types/${projectId}/configure_tasks`, values);
    } catch (error) {
      throw error;
    }
  },

  getConfigureTask: async (projectTypeId, id) => {
    try {
      return await axios.get(`/admin/project_types/${projectTypeId}/configure_tasks/${id}`);
    } catch (error) {
      throw error;
    }
  },

  editConfigureTask: async (projectId, taskId, values) => {
    try {
      return await axios.put(`/admin/project_types/${projectId}/configure_tasks/${taskId}`, values);
    } catch (error) {
      throw error;
    }
  },
  deleteConfigureTask: async (projectId, taskId) => {
    try {
      return await axios.delete(`/admin/project_types/${projectId}/configure_tasks/${taskId}`);
    } catch (error) {
      throw error;
    }
  },
  reorderConfigureTasks: async (projectTypeId, params) => {
    try {
      return await axios.put(`admin/project_types/${projectTypeId}/configure_tasks/reorder`, params);
    } catch (error) {
      throw error;
    }
  },
  addConfigureSubTask: async (taskId, values) => {
    try {
      return await axios.post(`/admin/configure_tasks/${taskId}/configure_subtasks`, values);
    } catch (error) {
      throw error;
    }
  },
  deleteConfigureSubTask: async (taskId, id) => {
    try {
      return await axios.delete(`/admin/configure_tasks/${taskId}/configure_subtasks/${id}`);
    } catch (error) {
      throw error;
    }
  },
  editConfigureSubTask: async (values, taskId, id) => {
    try {
      return await axios.put(`/admin/configure_tasks/${taskId}/configure_subtasks/${id}`, values);
    } catch (error) {
      throw error;
    }
  },
  addProjectStage: async values => {
    try {
      return await axios.post("/admin/stages", values);
    } catch (error) {
      throw error;
    }
  },
  deleteProjectStage: async id => {
    try {
      return await axios.delete(`/admin/stages/${id}`);
    } catch (error) {
      throw error;
    }
  },
  editProjectStage: async (id, values) => {
    try {
      return await axios.put(`/admin/stages/${id}`, values);
    } catch (error) {
      throw error;
    }
  },
  replaceProjectStages: async values => {
    try {
      return await axios.put("/admin/stages/replace", values);
    } catch (error) {
      throw error;
    }
  },
  getStage: async stageId => {
    try {
      return await axios.get(`/admin/stages/${stageId}`);
    } catch (error) {
      throw error;
    }
  },
  addStageTask: async (stageId, values) => {
    try {
      return await axios.post(`/admin/stages/${stageId}/stage_tasks`, values);
    } catch (error) {
      throw error;
    }
  },
  editStageTask: async (stageId, taskId, values) => {
    try {
      return await axios.put(
        `/admin/stages/${stageId}/stage_tasks/${taskId}`,
        values
      );
    } catch (error) {
      throw error;
    }
  },
  deleteStageTask: async (stageId, taskId) => {
    try {
      return await axios.delete(
        `/admin/stages/${stageId}/stage_tasks/${taskId}`
      );
    } catch (error) {
      throw error;
    }
  },
  getProjectTypes: async () => {
    try {
      return await axios.get("/admin/project_types");
    } catch (error) {
      throw error;
    }
  },
  addProjectType: async values => {
    try {
      return await axios.post("/admin/project_types", values);
    } catch (error) {
      throw error;
    }
  },
  editProjectType: async (typeId, values) => {
    try {
      return await axios.put(`/admin/project_types/${typeId}`, values);
    } catch (error) {
      throw error;
    }
  },
  getProjectType: async typeId => {
    try {
      return await axios.get(`/admin/project_types/${typeId}`);
    } catch (error) {
      throw error;
    }
  },
  deleteProjectType: async id => {
    try {
      return await axios.delete(`/admin/project_types/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getProjectGroups: async () => {
    try {
      return await axios.get("/admin/project_groups");
    } catch (error) {
      throw error;
    }
  },
  addProjectGroup: async values => {
    try {
      return await axios.post("/admin/project_groups", values);
    } catch (error) {
      throw error;
    }
  },
  changeProjectGroup: async (id, values) => {
    try {
      return await axios.put(`/admin/project_groups/${id}`, values);
    } catch (error) {
      throw error;
    }
  },
  deleteProjectGroup: async id => {
    try {
      return await axios.delete(`/admin/project_groups/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getCategoriesLevels: async () => {
    try {
      return await axios.get("admin/projects/settings");
    } catch (error) {
      throw error;
    }
  },
  putCategoriesLevels: async values => {
    try {
      return await axios.put("admin/projects/settings", values);
    } catch (error) {
      throw error;
    }
  },

};
