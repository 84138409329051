import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import querystring from "query-string";
import styles from "./SurveyResultsContainer.module.scss";
import services from "services/index";

import Stakeholders from "./components/Stakeholders/Stakeholders";
import SurveyResultsTable from "components/tables/SurveyResultsTable/SurveyResultsTable";
import { isNull } from "lodash";

const SurveyResultsContainer = ({ setBreadcrumbs }) => {
  const params = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({});
  const [data, setData] = useState({
    survey_results: {},
    survey_results_count: 0,
  });
  const query = querystring.parse(history.location.search) || {};

  useEffect(() => {
    services.surveyServices.getSurveyResults(params.id).then((res) => {
      setProject(res.data.project_summary);
      setData(res.data);
      setLoading(false);
      setBreadcrumbs((state) => [
        ...state,
        {
          name: "Project details",
          path: `/projects/${params.id}`,
        },
        { name: "Survey results" },
      ]);
    });
  }, [params.id]);

  const pageChangeHandler = (page) => {
    history.push({ search: querystring.stringify({ ...query, page }) });
  };

  const sortHandler = (sort) => {
    history.push({
      search: querystring.stringify({ ...query, ...sort }),
    });
  };

  return (
    <div className={styles.container}>
      {loading && (
        <div className="loaderWrap">
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>Survey results for {project.name}</h1>
          </div>
          {data.survey_results.some((item) => !isNull(item.value)) && (
            <div className={styles.wrapper}>
              <Stakeholders data={data} />
            </div>
          )}

          <SurveyResultsTable
            data={data}
            onPageChange={pageChangeHandler}
            currentPage={query.page || 1}
            onSort={sortHandler}
            perPage={20}
          />
        </>
      )}
    </div>
  );
};

export default SurveyResultsContainer;
