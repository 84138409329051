import React, {useContext} from 'react';
import ApprovalForm from "../../../forms/ApprovalForm/ApprovalForm";
import {prepareRequestData} from "../../../../helpers/approvals/prepareRequestData";
import {useParams} from "react-router-dom";
import services from "../../../../services";
import AlertContext from "../../../../contexts/AlertContext/AlertContext";

const EditSubContainer = ({initialValues, updateAction}) => {
  const params = useParams();
  const {setAlert} = useContext(AlertContext);

  const send = values => {
    const prepareValues = {
      ...prepareRequestData(values),
      project_type_id: params.projectTypeId
    };

    services.approvalServices.editApprovalAdmin(initialValues.id, prepareValues).then(res => {
      if (res.data) updateAction(res.data);
    }).catch((err) => {
      setAlert({
        message: err.response?.data.error ||
          "Something went wrong. Please, try again.",
        type: "error"
      });
    });;
  }

  return <ApprovalForm onSubmit={send} initialValues={initialValues}/>;
};

export default EditSubContainer;