import React from 'react'
import s from './Thank.module.scss'

const Thank = () => {
	return (
		<div className={s.thank}>
          <h2 className={s.tnankTitle}>Thank you for contacting us</h2>

          <p className={s.thankText}>
            We appreciate you contacting us. One of our colleagues will get back
            in touch with you soon!
          </p>

          <p className={s.thankText}>Have a great day!</p>
        </div>
	)
}

export default Thank
