export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USERS = "SET_USERS";
export const SET_CURRENT_CATEGORY_ID = "SET_CURRENT_CATEGORY_ID";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT";
export const SET_LAST_LOCATION = "SET_LAST_LOCATION";
export const SET_GLOBAL_PROJECT = "SET_GLOBAL_PROJECT";
export const SET_GLOBAL_PROJECT_MESSAGE = "SET_GLOBAL_PROJECT_MESSAGE";
export const DELETE_GLOBAL_PROJECT = "DELETE_GLOBAL_PROJECT";
export const ADD_GLOBAL_PROJECT_SUPPLIER = "ADD_GLOBAL_PROJECT_SUPPLIER";
export const ADD_GLOBAL_PROJECT_CONTRACT = "ADD_GLOBAL_PROJECT_CONTRACT";
export const ADD_GLOBAL_PROJECT_USER = "ADD_GLOBAL_PROJECT_USER";
export const DELETE_GLOBAL_PROJECT_CONTRACT = "DELETE_GLOBAL_PROJECT_CONTRACT";
export const DELETE_GLOBAL_PROJECT_SUPPLIER = "DELETE_GLOBAL_PROJECT_SUPPLIER";
export const DELETE_GLOBAL_PROJECT_USER = "DELETE_GLOBAL_PROJECT_USER";
export const ADD_GLOBAL_PROJECT = "ADD_GLOBAL_PROJECT";
export const EDIT_GLOBAL_PROJECT = "EDIT_GLOBAL_PROJECT";
export const SET_LOGO_COMPANY = "SET_LOGO_COMPANY";
export const CHANGE_TRANSLATION_MOD = "CHANGE_TRANSLATION_MOD";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_SUBTASK = "SET_SUBTASK";
export const EDIT_SUBTASK = "SET_SUBTASK";
export const DELETE_SUBTASK = "DELETE_SUBTASK";
