import axios from "./axios"

export default {
  getOrganization: async () => {
    try {
      const result = await axios.get("/admin/organization")
      return result
    } catch (error) {
      throw error
    }
  },
  changeOrganization: async params => {
    try {
      const result = await axios.put("/admin/organization", params)
      return result
    } catch (error) {
      throw error
    }
  },
  getDepartments: async () => {
    try {
      return await axios.get("/departments")
    } catch (error) {
      throw error
    }
  },
  getApprovalGroups: async () => {
    try {
      return await axios.get("/admin/approval_groups")
    } catch (error) {
      throw error
    }
  },
  getApprovers: async () => {
    try {
      return await axios.get("/admin/approvers")
    } catch (error) {
      throw error
    }
  },
  deleteApprovalGroup: async (id, config) => {
    try {
      return await axios.delete(`/admin/approval_groups/${id}`, config)
    } catch (error) {
      throw error
    }
  },
  addApprovalGroup: async params => {
    try {
      return await axios.post("/admin/approval_groups", params)
    } catch (error) {
      throw error
    }
  },
  changeApprovalGroup: async (id, params, config) => {
    try {
      return await axios.put(`/admin/approval_groups/${id}`, params, config)
    } catch (err) {
      throw err
    }
  },
  getScenarios: async params => {
    try {
      return await axios.get("/admin/approval_scenarios", {params});
    } catch (error) {
      throw error
    }
  },
  addScenario: async values => {
    try {
      return await axios.post("/admin/approval_scenarios", values)
    } catch (error) {
      throw error
    }
  },
  editScenario: async (id, values) => {
    try {
      return await axios.put(`/admin/approval_scenarios/${id}`, values)
    } catch (error) {
      throw error
    }
  },
  deleteScenario: async id => {
    try {
      return await axios.delete(`/admin/approval_scenarios/${id}`)
    } catch (error) {
      throw error
    }
  },
  getScenario: async id => {
    try {
      return await axios.get(`/admin/approval_scenarios/${id}`)
    } catch (error) {
      throw error
    }
  },
  getScenarioSteps: async (id, params) => {
    try {
      return await axios.get(`/admin/approval_scenarios/${id}/approval_steps`, {
        params
      })
    } catch (error) {
      throw error
    }
  },
  getApprovalGroupsList: async id => {
    try {
      return await axios.get(`/admin/approval_group_list`)
    } catch (error) {
      throw error
    }
  },
  addStep: async (id, values) => {
    try {
      return await axios.post(
        `/admin/approval_scenarios/${id}/approval_steps`,
        values
      )
    } catch (error) {
      throw error
    }
  },
  editStep: async (scenarioId, stepId, values) => {
    try {
      return await axios.put(
        `/admin/approval_scenarios/${scenarioId}/approval_steps/${stepId}`,
        values
      )
    } catch (error) {
      throw error
    }
  },
  deleteStep: async (scenarioId, stepId) => {
    try {
      return await axios.delete(
        `/admin/approval_scenarios/${scenarioId}/approval_steps/${stepId}`
      )
    } catch (error) {
      throw error
    }
  },
  getStakeholderTypes: async params => {
    try {
      return await axios.get(`/admin/stakeholder_types`, { params })
    } catch (error) {
      throw error
    }
  },
  addStakeholderType: async params => {
    try {
      return await axios.post(`/admin/stakeholder_types`, params)
    } catch (error) {
      throw error
    }
  },
  changeStakeholderType: async (id, params) => {
    try {
      return await axios.put(`/admin/stakeholder_types/${id}`, params)
    } catch (error) {
      throw error
    }
  },
  deleteStakeholderType: async id => {
    try {
      return await axios.delete(`/admin/stakeholder_types/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getContractsExpiration: async () => {
    try {
      return await axios.get(`/admin/contracts/expiration`);
    } catch (error) {
      throw error;
    }
  },
  putContractsExpiration: async (params) => {
    try {
      return await axios.put(`/admin/contracts/update_expiration`, params);
    } catch (error) {
      throw error
    }
  },
  getTaskRoles: async params => {
    try {
      return await axios.get(`/admin/task_roles`, { params })
    } catch (error) {
      throw error
    }
  },
  addTaskRole: async params => {
    try {
      return await axios.post(`/admin/task_roles`, params)
    } catch (error) {
      throw error
    }
  },
  changeTaskRole: async (id, params) => {
    try {
      return await axios.put(`/admin/task_roles/${id}`, params)
    } catch (error) {
      throw error
    }
  },
  deleteTaskRole: async id => {
    try {
      return await axios.delete(`/admin/task_roles/${id}`);
    } catch (error) {
      throw error;
    }
  }
}
