import React, {useEffect, useState} from 'react';
import {parserForSelect} from "../../../containers/AdminSettingsStepFieldDetailsContainer/helpers/parserForSelect";
import AutoCompleteInput from "../../AutoCompleteInput/AutoCompleteInput";
import services from "../../../../services";
import Input from "../../Input/Input";
import {emptySelect} from "../../../../helpers/emptyData";

const SuppliersAutocomplete = ({changeSingleCondition, defaultValue, isViewMode}) => {
  const [value, setValue] = useState("");
  const [searchedContracts, setSearchedContracts] = useState([]);

  useEffect(() => {
    if (defaultValue && defaultValue !== " ") {
      services.supplierServices
        .getSupplier(defaultValue)
        .then(res => {
          if (res.data) {
            setValue(res.data.name);
          } else {
            setValue("");
          }
        });
    }
  }, [defaultValue]);

  const searchSuppliers = term => {
    services.supplierServices
      .suppliersAutocomplete({query: term})
      .then(res => setSearchedContracts(res.data));
  };

  if (isViewMode) return <Input value={value} withoutChange/>;

  return (
    <AutoCompleteInput
      label=""
      value={value}
      placeholder=""
      onChange={e => {
        changeSingleCondition(emptySelect);
        setValue(e.target.value);
        searchSuppliers(e.target.value);
      }}
      onSelect={value => {
        changeSingleCondition(parserForSelect(value));
      }}
      data={searchedContracts}
    />
  );
};

export default SuppliersAutocomplete;