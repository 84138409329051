import React from "react";
import querystring from "query-string";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import MainTable from "components/tables/MainTable/MainTable";
import style from "./SystemReportsTagsTable.module.scss";
import classNames from "classnames";
import {backgroundTypes} from "../../../helpers/helpData";

const SystemReportsTagsTable = ({data, perPage = 30}) => {
  const history = useHistory();
  const organizationName = useSelector(state => state.user.organization.name);
  const query = querystring.parse(history.location.search);

  const onPageChange = page => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  const onSort = type => {
    const query = querystring.parse(history.location.search);
    history.push({
      search: querystring.stringify({...query, page: 1, ...type})
    });
  };

  const getStyle = (type, color) => {
    if (type === "tag") {
      return {
        background: backgroundTypes[color],
        color: "#fff",
      };
    }
    return {
      border: `2px solid ${backgroundTypes[color]}`,
      color: backgroundTypes[color],
    };
  };

  return (
    <MainTable
      stickyHeader={true}
      data={data.tags}
      perPage={perPage}
      totalCount={data.tags_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={query.page || 1}
      fullHeight={true}
      dataTypes={[
        {
          title: "Tag",
          value: ({name, type, color}) => <span
            className={classNames(style.badge, style[type])}
            style={getStyle(type, color)}
          >
            {name}
          </span>,
          sortable: true,
          onClick: (id, item) => {
            if (item.type === "tag") {
              history.push(`/system-reports/tags/${id}`);
            } else {
              history.push(`/system-reports/flags/${id}`);
            }
          },
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          sticky: true
        },
        {
          title: "Suppliers",
          value: ({suppliers_count, flag_admins}) => {
            if (flag_admins) return flag_admins.length || "_";
            return suppliers_count || "_";
          },
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "suppliers_count", direction});
          }
        },
        {
          title: "Projects",
          value: ({projects_count}) => projects_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "projects_count", direction});
          }
        },
        {
          title: "Contracts",
          value: ({contracts_count}) => contracts_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "contracts_count", direction});
          },
          visible: organizationName !== "Indeed"
        }
      ]}
    />
  );
};
export default SystemReportsTagsTable;
