import React from "react";
import CheckedLine from "../../../../components/CheckedLine/CheckedLine";
import CustomLink from "components/elements/CustomLink/CustomLink";
import s from "./TopCard.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const data1 = [
  "Facilitate procurement intake process",
  "TriageTab incoming work and assign resources",
  "Manage all procurement activity through pipeline",
  "Visualize procurement spend",
];

const data2 = [
  "Track financial and non-financial benefits",
  "Manage internal resource utilization",
  "Measure client satisfaction (net promoter score)",
  "Collaborate with internal stakeholders",
];

const TopCard = () => {
  const {t} = useTranslation();
  return (
    <div className={s.topCard}>
      <h1 className={s.title} data-translate-key={"the-procurement-value-management-suite-includes:"}>
        {t(getTranslateKey("the-procurement-value-management-suite-includes:"))}
      </h1>

      <div className={s.linesBlock}>
        <div className={s.lines}>
          {data1.map((line, index) => (
            <CheckedLine index={index} item={line}/>
          ))}
        </div>

        <div className={s.lines}>
          {data2.map((line, index) => (
            <CheckedLine index={index} item={line}/>
          ))}
        </div>
      </div>

      <CustomLink className={s.buttonLink} type="nav-link" path="/contact-us" data-translate-key={"talk-to-sales"}>
        {t(getTranslateKey("talk-to-sales"))}
      </CustomLink>
    </div>
  );
};

export default TopCard;
