export const formatData = (data) => {
  const result = [];
  const keys = Object.keys(data);
  const _keys = {};
  keys.forEach((key) => {
    Object.keys(data[key]).forEach((_key) => (_keys[_key] = true));
  });
  keys.forEach((key) => {
    if (Object.values(data[key]).some((el) => el)) {
      const item = {
        savings_type: [key],
        financialByYears: [],
      };
      Object.keys(_keys).forEach((_key) => {
        if (_key !== "total") {
          item.financialByYears.push({
            name: _key,
            value: data[key][_key] || 0,
          });
        } else {
          item.total = data[key][_key] || 0;
        }
      });
      result.push(item);
    }
  });
  return result;
};
