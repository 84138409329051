import {generateUniqueId} from "../../../../helpers/uniqueId";

export const addCondition = conditions => {
  return [...conditions.map((c, index) => {
    if (index === conditions.length - 1) return {...c, condition: "and"};
    return c;
  }), {
    id: generateUniqueId(),
    condition: null,
    condition_group: [
      {
        id: generateUniqueId(),
        condition: null,
        value: " === "
      }
    ]
  }];
};