import React, {useContext, useEffect, useState} from "react";
import styles from "./AdminSettingsApprovalDetailsContainer.module.scss";
import {ModeContext} from "../../../contexts/ModeContext/ModeContext";
import {useHistory, useParams} from "react-router-dom";
import {mods} from "../../../contexts/ModeContext/mods";
import ViewSubContainer from "./SubContainers/ViewSubContainer";
import EditSubContainer from "./SubContainers/EditSubContainer";
import {CircularProgress} from "@material-ui/core";
import {prepareFormData} from "../../../helpers/approvals/prepareFormData";
import services from "services";
import DropListTrigger from "../../elements/DropListTrigger/DropListTrigger";
import Popup from "../../popups/Popup/Popup";

const AdminSettingsApprovalDetailsContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(null);
  const [popUp, setPopUp] = useState(null);
  const {mode, enableViewMode} = useContext(ModeContext);
  let subContainer = null;

  useEffect(() => {
    services.approvalServices.getApprovalAdmin(params.id).then(res => {
      setData(res.data);
    });
  }, []);

  const updateApproval = data => {
    setData(data);
    enableViewMode();
  }

  const deleteApproval = () => {
    services.approvalServices
      .deleteApprovalAdmin(data.id)
      .then(res => {
        if (res.status < 300) history.goBack();
      });
    return true;
  };

  if (data) {
    if (mode === mods.view) subContainer = <ViewSubContainer initialValues={prepareFormData(data)}/>;
    if (mode === mods.edit) subContainer = (
      <EditSubContainer
        initialValues={prepareFormData(data)}
        updateAction={updateApproval}
      />
    );
  }

  if (!data) return (
    <div
      className='loaderWrap justifyCenter'
      style={{marginTop: "24px"}}
    >
      <CircularProgress/>
    </div>
  );

  return (
    <>
      <div className={styles.approvalContainer}>
        <div className={styles.header}>
          <h2 className={styles.title}>{history.location.state?.projectTypeName ?? "Project type"}</h2>
          <DropListTrigger
            data={[
              {
                title: "Delete",
                onClick: () => setPopUp("delete")
              }
            ]}
            visibleMenu={visibleDropListMenu}
            setVisibleMenu={setVisibleDropListMenu}
          />
        </div>
        {subContainer}
      </div>
      {popUp === "delete" && (
        <Popup
          title="Delete approval step"
          text="Are you sure you want to delete this approval step?
It will remain in all current projects, but not in new ones."
          onClose={() => setPopUp(null)}
          successBtnTitle="Delete"
          onClick={() => deleteApproval()}
          isDelete
          dialog
        />
      )}
    </>
  );
};

export default AdminSettingsApprovalDetailsContainer;