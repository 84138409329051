import React from "react";
import s from "./NotesHistoryPopup.module.scss";
import Popup from "components/popups/Popup/Popup";
import Note from "./components/Note/Note";
import PropTypes from "prop-types";

const NotesHistoryPopup = ({ onClose, data }) => {
  return (
    <Popup title='Notes history' onClose={onClose} className={s.popup}>
      <ul className={s.notes}>
        {data?.owner_notes && (
          <Note className={s.note} data={data.owner_notes} />
        )}
        {data?.responsible_user_notes && (
          <Note className={s.note} data={data.responsible_user_notes} />
        )}
      </ul>
    </Popup>
  );
};
export default NotesHistoryPopup;

NotesHistoryPopup.propTypes = {
  onClose: PropTypes.func, 
  data: PropTypes.any,
};