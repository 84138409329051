import React from "react";
import Popup from "components/popups/Popup/Popup";
import PropTypes from "prop-types";

const RestoreFinancialSavingsTypePopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      onClose={onClose}
      onClick={onSubmit}
      title="Restore financial savings type"
      text="Are you sure you want to restore this savings type?"
      successBtnTitle="Restore"
      dialog
    />
  );
};

export default RestoreFinancialSavingsTypePopup;

RestoreFinancialSavingsTypePopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
