import React, {useState, useEffect} from "react";
import s from "./ProjectRequestDetailContainer.module.scss";
import services from "services";
import DetailLoading from "components/loadingStates/Detail/Detail";
import RequestDetail from "./components/RequestDetail/RequestDetail";
import {useParams} from "react-router-dom";
import Popup from "../../popups/Popup/Popup";
import AssignRequestForm from "../../forms/AssignRequestForm/AssignRequestForm";
import RejectRequestForm from "../../forms/RejectRequestForm/RejectRequestForm";
import {RequestTabs} from "./components/RequestTabs/RequestTabs";
import {RequestHeader} from "./components/RequestHeader/RequestHeader";
import {appendFile} from "../../../helpers/appendFile";
import GeneralInformation from "./components/GeneralInformation/GeneralInformation";

const RequestDetailContainer = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popUp, setPopUp] = useState(null);
  const params = useParams();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    services.requestTypeServices.getRequestType(params.id).then(res => {
      setData(res.data);
      setLoading(false);
    });
  };

  const renderDetails = () => {
    if (loading) return <DetailLoading length={8}/>;
    return (
      <div className={s.requestInfoWrap}>
        <RequestDetail
          data={data}
          onAssign={() => setPopUp("assign")}
          onReject={() => setPopUp("reject")}
          onComplete={() => setPopUp("complete")}
        />
        <GeneralInformation data={data}/>
      </div>
    );
  };

  const assign = ({note, procurement_user_id, attachments}) => {
    services.requestTypeServices.assignRequest(params.id, {
      note,
      procurement_user_id
    }).then(res => {
      if (res.data) {
        setPopUp(null);
        addAttachments(attachments, res.data.id);
        load();
      }
    });
  };

  const reject = ({note, attachment}) => {
    services.requestTypeServices.rejectRequest(params.id, {note}).then(res => {
      if (res.data) {
        setPopUp(null);
        addAttachments(attachment, res.data.id);
        load();
      }
    });
  };

  const addAttachments = (attachments, id) => {
    Promise.all([...attachments.map(attachment => {
      const file = appendFile(attachment);
      services.taskServices.addAttachment(file, id).then(res => res.data);
    })]).then(() => {
    });
  };

  const complete = () => {
    services.requestTypeServices.completeRequest(params.id).then(res => {
      if (res.data) {
        setPopUp(null);
        load();
      }
    });
  };

  if (!data) return "";

  return (
    <>
      <div className={s.wrap}>
        <div className={s.titleWrap}>
          <RequestHeader loading={loading} data={data}/>
        </div>
        {renderDetails()}
        <div className={s.wrapTabs}>
          <RequestTabs data={data}/>
        </div>
      </div>
      {
        popUp === "assign" && (
          <Popup
            onClose={() => setPopUp(null)}
            title="Assign request"
          >
            <AssignRequestForm
              onCLose={() => setPopUp(null)}
              onSubmit={values => assign(values)}
            />
          </Popup>
        )
      }
      {
        popUp === "reject" && (
          <Popup
            onClose={() => setPopUp(null)}
            title="Reject request"
          >
            <RejectRequestForm
              onCLose={() => setPopUp(null)}
              onSubmit={values => reject(values)}
            />
          </Popup>
        )
      }
      {
        popUp === "complete" && (
          <Popup
            onClose={() => setPopUp(null)}
            successBtnTitle='Complete'
            title='Complete the request'
            text='Are you sure you already want to complete this request?'
            onClick={() => complete()}
            isDelete
            dialog
          />
        )
      }
    </>
  );
};
export default RequestDetailContainer;
