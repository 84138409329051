export const deleteSingleCondition = (conditions, conditionId, singleConditionId) => {
  return conditions.map(condition => {
    if (condition.id === conditionId) {

      let newConditionGroup = condition.condition_group
        .filter(singleCondition => singleCondition.id !== singleConditionId);

      const lastCondition = newConditionGroup.pop();

      if (lastCondition) {
        return {
          ...condition,
          condition_group: [...newConditionGroup, {...lastCondition, condition: null}],
        };
      }

      return condition;
    }
    return condition;
  });
};