import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import styles from "./DepartmentReportProjects.module.scss"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import TableLoading from "components/loadingStates/Table/Table"
import DepartmentReportProjectsTable from "components/tables/DepartmentReportProjectsTable/DepartmentReportProjectsTable"
import DepartmentReportProjectsActiveProjectsTable from "components/tables/DepartmentReportProjectsActiveProjectsTable/DepartmentReportProjectsActiveProjectsTable"
import services from "services/index"
import { formatProjectTypesData, formatProjectSavingsData } from "../../helpers"

const DepartmentReportProjects = () => {
  const params = useParams()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    params.id &&
      services.reportServices
        .getDepartmentReportProjects(params.id)
        .then(res => {
          if (res.data?.project_types) {
            res.data.project_types = formatProjectTypesData(
              res.data.project_types
            )
            res.data.savings = formatProjectSavingsData(res.data.savings)
          }
          setData(res.data)
          setLoading(false)
        })
  }, [params.id])

  return (
    <>
      {loading ? (
        <TableLoading length={5} />
      ) : (
        <>
          <div className={styles.table}>
            {Boolean(data.project_types?.length) ? (
              <DepartmentReportProjectsTable data={data.project_types} />
            ) : (
              <EmptyBlock
                title="Department doesn't have projects yet."
                withBtn={false}
                message
              />
            )}
          </div>
          <div className={styles.table}>
            <h3 className={styles.title}>Active projects</h3>
            {Boolean(data.active_projects?.active_projects?.length) ? (
              <DepartmentReportProjectsActiveProjectsTable
                data={data.active_projects?.active_projects}
              />
            ) : (
              <EmptyBlock
                title="Department doesn't have active projects yet."
                withBtn={false}
                message
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default DepartmentReportProjects
