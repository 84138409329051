import React, {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom";
import styles from "./FinancialBenefitsEditContainer.module.scss";
import services from "../../../services";
import FinancialBenefitsForm from "../../forms/FinancialBenefitsForm/FinancialBenefitsForm";
import {CircularProgress} from "@material-ui/core";
import {appendFile} from "../../../helpers/appendFile";
import {delay} from "../../../helpers/delay";

const FinancialBenefitsEditContainer = () => {
  const {projectId, financialBenefitId} = useParams();
  const history = useHistory();
  const [project, setProject] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    services.projectServices.getProject(projectId).then(res => {
      setProject(res.data);
    });
    services.financialBenefitsServises.getFinancialBenefit(projectId, financialBenefitId).then(res => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  }, []);

  const editFinancialBenefitAction = values => {
    services.financialBenefitsServises.editFinancialBenefit(projectId, financialBenefitId, values.data).then(res => {
      if (res.data) {
        Promise.all([...values.attachments.map(attachment => {
          const file = appendFile(attachment);
          services.taskServices.addAttachment(file, res.data.id).then(res => res.data);
        })])
          .finally(() => delay(500)
            .then(() => history.goBack()));
      }
    });
  }

  let formComponent = (
    <div className="loaderWrap justifyCenter">
      <CircularProgress/>
    </div>
  );

  if (!loading) formComponent = (
    <FinancialBenefitsForm
      isEdit
      onSubmit={editFinancialBenefitAction}
      defaultValues={data}
    />
  );

  return (
    <div className={styles.wrap}>
      <h2 className={styles.title}>{project?.name}</h2>
      {formComponent}
    </div>
  );

}
export default FinancialBenefitsEditContainer
