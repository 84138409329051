import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import styles from "./FinancialSavings.module.scss";
import {ReactComponent as Info} from "assets/icons/info.svg";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import AdminSettingsFinancialSavingsTable
  from "components/tables/AdminSettingsFinancialSavingsTable/AdminSettingsFinancialSavingsTable";
import AddFinancialSavingsTypePopup from "./Popups/AddFinancialSavingsTypePopup/AddFinancialSavingsTypePopup";
import EditFinancialSavingsTypePopup from "./Popups/EditFinancialSavingsTypePopup/EditFinancialSavingsTypePopup";
import DeleteFinancialSavingsTypePopup from "./Popups/DeleteFinancialSavingsTypePopup/DeleteFinancialSavingsTypePopup";
import testData from "./testData";
import RestoreFinancialSavingsTypePopup
  from "./Popups/RestoreFinancialSavingsTypePopup/RestoreFinancialSavingsTypePopup";
import services from "services";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const FinancialSavings = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const {t} = useTranslation();

  useEffect(() => {
    loadTypes();
  }, [history.location]);

  const loadTypes = () => {
    services.savingsTypeServices.getSavingsType().then(res => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  }

  const addHandler = params => {
    services.savingsTypeServices.createSavingsType(params).then(res => {
      if (res.data) {
        setData([...data, res.data]);
      }
    });
  };

  const editHandler = params => {
    services.savingsTypeServices.editSavingsType(currentType.id, params).then(res => {
      if (res.data) {
        setData(data.map(type => {
          if (type.id === res.data.id) return res.data;
          return type;
        }))
      }
    });
  };

  const deleteHandler = ({savings_type_id}) => {
    services.savingsTypeServices.deleteSavingsType(currentType.id, savings_type_id).then(res => {
      if (res.data) {
        loadTypes();
        setCurrentType(null);
        setPopup(null);
      }
    });
  };

  const restoreHandler = () => {
    services.savingsTypeServices.restoreSavingsType(currentType.id).then(res => {
      if (res.data) {
        loadTypes();
        setCurrentType(null);
        setPopup(null);
      }
    });
  };

  const renderContent = () => {
    if (data.length) return (
      <AdminSettingsFinancialSavingsTable
        data={data}
        onAdd={() => setPopup("add")}
        onEdit={type => {
          setCurrentType(type);
          setPopup("edit");
        }}
        onDelete={type => {
          setCurrentType(type);
          setPopup("delete");
        }}
        onRestore={type => {
          setCurrentType(type);
          setPopup("restore");
        }}
      />
    );
    return (
      <EmptyBlock
        subtitle="You don’t have any savings types yet."
        buttonTitle="New type"
        onClick={() => setPopup("add")}
      />
    )
  }

  return (
    <>
      <div className={styles.title}>
        <h2 data-translate-key={getTranslateKey("financial-benefits")}>{t(getTranslateKey("financial-benefits"))}</h2>
        <Info className={styles.icon}/>
      </div>
      {loading && <TableLoading length={2}/>}
      {!loading && renderContent()}
      {popup === "add" && (
        <AddFinancialSavingsTypePopup
          onClose={() => setPopup(null)}
          onSubmit={addHandler}
        />
      )}
      {popup === "edit" && (
        <EditFinancialSavingsTypePopup
          onClose={() => setPopup(null)}
          onSubmit={editHandler}
          defaultData={currentType}
        />
      )}
      {popup === "delete" && (
        <DeleteFinancialSavingsTypePopup
          onClose={() => setPopup(null)}
          onSubmit={deleteHandler}
          typeId={currentType.id}
        />
      )}
      {popup === "restore" && (
        <RestoreFinancialSavingsTypePopup
          onClose={() => setPopup(null)}
          onSubmit={restoreHandler}
        />
      )}
    </>
  );
};

export default FinancialSavings;
