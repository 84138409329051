import React, { useState, Fragment, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./NavigationItem.module.scss";
import { ReactComponent as SubToggle } from "assets/icons/arrowDown.svg";

const NavigationItem = ({
  item,
  history,
  currentCategoryId,
  level,
  padding,
  handleClick,
}) => {
  const [show, setShow] = useState(false);
  const hasCategories = item.categories && item.categories.length > 0;

  const render = (item) => {
    return (
      <NavigationItem
        key={item.id}
        item={item}
        history={history}
        currentCategoryId={currentCategoryId}
        level={level + 1}
        padding={padding + 24}
        handleClick={handleClick}
      />
    );
  };

  useEffect(() => {
    setShow(item.opened || false);
  }, [item.opened]);

  return (
    <Fragment>
      <div
        className={classNames(s.navItem, {
          [s.active]: currentCategoryId === item.id,
        })}
        style={{
          paddingLeft: hasCategories ? padding - 24 : padding - 6,
        }}
        onClick={() => {
          handleClick(item);
        }}
      >
        {hasCategories ? (
          <SubToggle
            onClick={(e) => {
              e.stopPropagation();
              setShow(!show);
            }}
            className={classNames(s.toggle, {
              [s.toggleRotate]: show,
            })}
          />
        ) : null}
        <span className={classNames(s.label, s[`label${level}`])}>
          {"L" + level}
        </span>
        <span className={s.name}>{item.name}</span>
      </div>
      {hasCategories && show && (
        <Fragment>{item.categories.map((item) => render(item))}</Fragment>
      )}
    </Fragment>
  );
};

NavigationItem.propTypes = {
  item: PropTypes.object,
  currentCategoryId: PropTypes.string,
  level: PropTypes.number,
  padding: PropTypes.number,
};

NavigationItem.defaultProps = {
  currentCategoryId: "1",
  level: 1,
  padding: 44,
};
export default NavigationItem;
