import React from "react"
import { useFormik } from "formik"
import { string, object } from "yup"
import Input from "components/elements/Input/Input"
import Button from "components/elements/Button/Button"
const AdminStakeholderTypesForm = ({ onClose, onSubmit, defaultValues }) => {
  const formik = useFormik({
    initialValues: defaultValues || {
      type: ""
    },
    validationSchema: object({
      type: string()
        .required("Stakeholder type is required")
        .max(
          50,
          "The stakeholder type is too long. It should be 50 characters or less."
        )
    }),
    onSubmit
  })
  const { values, errors, handleSubmit, handleChange, touched } = formik
  return (
    <form onSubmit={handleSubmit} noValidate={true} autoComplete='off'>
      <Input
        value={values.type}
        onChange={handleChange}
        name='type'
        placeholder='Enter type name'
        label='Stakeholder type'
        error={errors.type && touched.type && errors.type}
        inputProps={{ autoFocus: true }}
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          styled='primary'
          type='submit'
          className='popupBtn'
          disabled={defaultValues && defaultValues?.type === values.type}
        >
          {defaultValues ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  )
}
export default AdminStakeholderTypesForm
