import React, {useEffect, useState} from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {useParams} from "react-router-dom";
import TaskDetailsContainer from "../../containers/TaskDetailsContainer/TaskDetailsContainer";
import services from "../../../services";
import {useSelector} from "react-redux";
import {userRoles} from "../../../helpers/userPermissions/userRoles";

const TaskDetailsPage = () => {
  const {projectId, milestoneId, taskId} = useParams();
  const [data, setData] = useState({});
  const user = useSelector(state => state?.user);
  const isBusinessUser = user.roles.includes(userRoles.business_user);
  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  let breadcrumbs = [
    {
      name: "Projects",
      path: "/projects/",
      disabled: (isBusinessUser || isCategoryOwner),
    },
    {
      name: "Project details",
      path: `/projects/${projectId}`
    },
    {
      name: "Milestones",
      path: `/projects/${projectId}?tab=Milestones`
    },
    {
      name: data.name || "Task name",
      withoutTranslate: true
    },
  ];

  useEffect(() => {
    load();
    services.projectServices.getProject(projectId).then(res => {
      if (res.data) {
        setProject(res.data);
      }
    });
  }, []);

  const load = () => {
    services.projectServices.getTaskDetails(milestoneId, taskId).then(res => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  };

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <TaskDetailsContainer
        load={load}
        data={data}
        project={project}
        loading={loading}
      />
    </DefaultLayout>
  );
};

export default TaskDetailsPage;