import {CHANGE_TRANSLATION_MOD} from "../helpers/constants";

export const changeTranslationMod = value => {
  return async dispatch => {
    dispatch({
      type: CHANGE_TRANSLATION_MOD,
      payload: value
    });
  };
};
