import React, { useState, useRef, useEffect, useCallback } from "react";
import classNames from "classnames";
import { debounce } from "lodash";
import s from "./FilterBarDropdownProjects.module.scss";
import { ReactComponent as Arrow } from "assets/icons/arrowDown.svg";
import { ReactComponent as Clear } from "assets/icons/clear.svg";
import styles from "components/elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown.module.scss";
import useOutsideClicker from "hooks/useOutsideClicker";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import Checkbox from "components/elements/Checkbox/Checkbox";
import services from "services";
import {useTranslate} from "../../../../../hooks/useTranslate";
import {useTranslation} from "react-i18next";

const FilterBarDropdownProjects = ({
  name,
  handleChange,
  clearAllFilters,
  setClearAllFilters,
  setFilterValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkedProjects, setCheckedProjects] = useState([]);
  const [projects, setProjects] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [message, setMessage] = useState("");
  const dropdownRef = useRef(null);
  const {t} = useTranslation();
  const [translateMessageKey, translateMessageValue] = useTranslate(message);
  const [translateNameKey, translateNameValue] = useTranslate(name);
  useOutsideClicker(dropdownRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setMessage("Start typing project name to select one");
    if (!isOpen) {
      setSearchVal("");
      if (checkedProjects.length) setProjects(checkedProjects);
      else setProjects([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (clearAllFilters) {
      setCheckedProjects([]);
      setFilterValue(name, false);
      setClearAllFilters(false);
    }
  }, [clearAllFilters]);

  const onClear = () => {
    setCheckedProjects([]);
    setFilterValue(name, false);
    handleChange([]);
  };

  const handleSearch = useCallback(
    debounce((term) => {
      services.projectServices
        .projectsAutocomplete({ query: term })
        .then((res) => {
          setProjects(res.data);
          res.data.length
            ? setMessage("")
            : setMessage("No work item results found");
        });
    }, 300),
    []
  );

  const isCheckedProject = (id) => {
    return Boolean(checkedProjects.filter((e) => e.id === id).length);
  };

  const handleCheckProject = (e, item) => {
    const checked = e.target.checked;
    if (!checked) {
      const newcheckedProjects = checkedProjects.filter(
        (e) => e.id !== item.id
      );
      setCheckedProjects(newcheckedProjects);
      handleChange(newcheckedProjects);
      setFilterValue(name, newcheckedProjects.length > 0);
    } else {
      const newcheckedProjects = [...checkedProjects, item];
      setCheckedProjects(newcheckedProjects);
      handleChange(newcheckedProjects);
      setFilterValue(name, newcheckedProjects.length > 0);
    }
  };

  return (
    <div
      className={classNames(styles.filterDropdownWrapper, {
        [styles.filterListOpened]: isOpen,
      })}
      ref={dropdownRef}
    >
      <div
        className={styles.nameBlock}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {checkedProjects[0] ? (
          <p className={styles.selectedOption}>
            {`${checkedProjects[0].name}`}{" "}
            {checkedProjects.length > 1 && `(+${checkedProjects.length - 1})`}
          </p>
        ) : (
          <p data-translate-key={translateNameKey}>{translateNameValue}</p>
        )}
        <span
          className={classNames(styles.arrowIcon, {
            [styles.listOpen]: isOpen,
          })}
        >
          <Arrow />
        </span>
      </div>
      {isOpen && (
        <div className={styles.openedList}>
          <div className={s.projectsWrap}>
            <Input
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
                handleSearch(e.target.value);
              }}
              placeholder="Search project"
              className={s.searchField}
            />
            {projects.length > 0 && (
              <ul className={s.projects}>
                {projects.map((item, index) => (
                  <li className={s.user} key={index}>
                    <Checkbox
                      value={isCheckedProject(item.id)}
                      checked={isCheckedProject(item.id)}
                      onChange={(e) => handleCheckProject(e, item)}
                      label={item.name}
                    />
                  </li>
                ))}
              </ul>
            )}
            {message.length > 0 && !projects.length && (
              <span className={s.message} data-translate-key={translateMessageKey}>{translateMessageValue}</span>
            )}
          </div>
          <div className={s.clearBlock}>
            <Button
              styled="textButton"
              className={styles.clearBtn}
              onClick={onClear}
            >
              <Clear />
              <span data-translate-key="clear">{t("clear")}</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default FilterBarDropdownProjects;
