export const SET_FILTERS = "SET_FILTERS";
export const SET_LOADING = "SET_LOADING";
export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_REQUEST_DATA = "SET_REQUEST_DATA";
export const SET_POPUP = "SET_POPUP";
export const SET_USER = "SET_USER";
export const RESET_ALERT = "RESET_ALERT";
export const DELETE_USER = "DELETE_USER";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const initialState = {
  users: [],
  totalUsersCount: 0,
  loading: true,
  filters: [],
  activeFilters: { departments: [], divisions: [], user_types: [], statuses: [] },
  currentPage: 1,
  popup: "",
  current_user: null,
  alert: { type: "", message: "" },
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: payload.loading,
      };
    }

    case SET_REQUEST_DATA: {
      return {
        ...state,
        users: payload.users,
        loading: payload.loading,
        totalUsersCount: payload.usersTotalCount,
      };
    }

    case SET_FILTERS: {
      return {
        ...state,
        filters: payload.filters,
      };
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload.page || 1,
      };
    }

    case SET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: payload.activeFilters,
      };
    }
    case SET_POPUP: {
      return {
        ...state,
        popup: payload,
      };
    }
    case SET_USER: {
      return {
        ...state,
        current_user: payload,
      };
    }
    case RESET_ALERT: {
      return {
        ...state,
        alert: { type: "", message: "" },
      };
    }
    case DELETE_USER:
      return {
        ...state,
        users: payload.user_id
          ? state.users.filter((e) => e.id !== payload.user_id)
          : state.users,
        alert: payload.alert,
        popup: null,
      };
    case ADD_USER:
      return {
        ...state,
        users: payload.user ? [payload.user, ...state.users] : state.users,
        alert: payload.alert,
        popup: null,
      };
    case EDIT_USER:
      return {
        ...state,
        users: payload.user
          ? state.users.map((e) =>
              e.id === payload.user.id ? payload.user : e
            )
          : state.users,
        alert: payload.alert,
        popup: null,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        alert: payload.alert,
        popup: null,
      };
    default:
      throw Error("Unknown action type");
  }
};
