const TITLES = {
  rfx: "RFx activities",
  supplier: "Supplier activities",
  custom: "Custom activities",
  contract: "Contract activities",
  total: "Total",
  planned: "Planned activities",
  active: "Active  activities",
  completed: "Completed  activities",
  widgetProjects: "Project Savings",
  actualSavingsChart: "Actual savings by months",
  widgetResourceUtilization: "Resource Utilization (projects)",
  widgetNetPromoterScore: "Net Promoter Score",
  widgetTransactionVolumes: "Transaction volumes",
  categories: "Your categories",
  suppliers: "Your suppliers",
  messages: "Messages",
  planedProjects: "Planned",
  activeProjects: "Active",
  completedProjects: "Completed",
  individualLeaderboard: "Individual Leaderboard",
  teamLeaderboard: "Team Leaderboard",
  announcements: "Announcements",
  recentRequests: "Recent requests",
  ca: "Cost Avoidance",
  er: "Expense Reduction",
  cbe: "CBE",
  pmr: "PMR",
  pte: "PTE",
  ucs: "UCS",
};

export const getTitle = (value) => {
  return TITLES[value];
};

export const formatSavings = (defaultData) => {
  const data = { ...defaultData };
  delete data.default_currency;
  const result = [];
  Object.entries(data).map((item) => {
    const _item = Object.entries(item[1]);
    const data = [
      [getTitle(item[0]), String(item[1].total)],
      [getTitle(_item[0][0]), _item[0][1]],
      [getTitle(_item[1][0]), _item[1][1]],
    ];
    result.push(data);
  });
  return result;
};

export const formatIndeedSavings = (defaultData) => {
  const data = { ...defaultData };
  delete data.default_currency;

  const result = [];
  Object.entries(data).map((item) => {
    const _item = Object.entries(item[1]);
    const data = [
      [getTitle(item[0]), String(item[1].total)],
      [getTitle(_item[0][0]), _item[0][1]],
      [getTitle(_item[1][0]), _item[1][1]],
      [getTitle(_item[2][0]), _item[2][1]],
      [getTitle(_item[3][0]), _item[3][1]],
    ];
    result.push(data);
  });
  return result;
};


