import React, {useEffect, useState} from "react";
import UserDepartmentsTable from "../../../../tables/UserDepartmentsTable/UserDepartmentsTable";
import services from "../../../../../services";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Popup from "../../../../popups/Popup/Popup";
import UserDepartmentForm from "../../../../tables/UserDepartmentsTable/Popup/UserDepartmentForm";
import NotificationBox from "../../../../elements/NotificationBox/NotificationBox";
import querystring from "query-string";

const UserDepartments = () => {
  const params = useParams();
  const [alert, setAlert] = useState({message: "", type: ""});
  const [departments, setDepartments] = useState();
  const [popup, setPopup] = useState(null);
  const [currentDepartments, setCurrentDepartments] = useState({});
  const [parentDepartment, setParentDepartment] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const location = useLocation();

  const errorAlert = {
    type: "error",
    message: "Something went wrong. Please, try again.",
  };

  const getDepartmentsAdmin = () => {
    const {page, direction, tag, ...query} = querystring.parse(
      location.search
    );

    const filters = Object.assign({}, {expires_in: "Any"}, {page, direction, ...query, tag});

    services.userServices
      .getDepartmentsAdmin(filters)
      .then(res =>
        setDepartments(res.data)
      );
  };
  useEffect(() => {
    getDepartmentsAdmin();
  }, [params.id, location.search]);

  const deleteHandler = (data) => {
    services.userServices.deleteDepartmentsAdmin(currentDepartments.id, data).then(() => {
        setDepartments({
          ...departments,
          departments_count: departments.departments_count - 1,
          departments: departments.departments.filter(department => department.id !== currentDepartments.id)
        });
        setPopup(null);
      }
    );

  };
  const editHandler = (data) => {
    services.userServices
      .putDepartmentsAdmin(currentDepartments.id, data)
      .then(res => {
        if (res.data) {
          setDepartments({
            ...departments,
            departments: departments.departments.map(department => department.id === currentDepartments.id ? res.data : department)
          });
        }
        setAlert({
          type: "success",
          message: "Department successfully changed.",
        });
        setPopup(null);
      }).catch(() => setAlert(errorAlert));
  };

  const addHandler = data => {
    services.userServices
      .postDepartmentsAdmin(data)
      .then(res => {
        setDepartments({
          ...departments,
          departments: [...departments.departments, res.data]
        });
        setAlert({
          type: "success",
          message: "Department added successfully.",
        });
        setPopup(null);
      }).catch(() => setAlert(errorAlert));
  };

  const pageChangeHandler = page => {
    const query = querystring.parse(location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  return (
    <>
      {departments &&
        <UserDepartmentsTable
          data={departments.departments}
          onEdit={(department, parentDepartment) => {
            setCurrentDepartments(department);
            setParentDepartment(parentDepartment);
            setPopup("edit");
          }}
          onDelete={item => {
            setCurrentDepartments(item);
            setPopup("delete");
          }}
          onAdd={item => {
            setCurrentDepartments(item);
            setPopup("add");
          }}
          perPage={departments.per_page}
          totalCount={departments.departments_count}
          currentPage={currentPage}
          onPageChange={pageChangeHandler}
        />}
      {popup === "add" && (
        <Popup
          title="New department"
          text="Please, complete the following fields to add a new department"
          onClose={() => setPopup(null)}
        >
          <UserDepartmentForm
            onClose={() => setPopup(null)}
            departments={departments.departments}
            onSubmit={addHandler}
          />
        </Popup>
      )}
      {popup === "delete" && (
        <Popup
          onClose={() => setPopup(null)}
          title="Delete department"
          text="Are you sure you want to delete this department? All users from it will be moved to the “Default” department."
          onClick={e => {
            e.preventDefault();
            deleteHandler();
          }}
          dialog
          isDelete
        />
      )}
      {popup === "edit" && (
        <Popup
          onClose={() => setPopup(null)}
          title="Edit department"
        >
          <UserDepartmentForm
            onClose={() => setPopup(null)}
            departments={departments.departments}
            parentDepartment={parentDepartment}
            onSubmit={editHandler}
            defaultData={currentDepartments}
          />
        </Popup>
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </>
  );
};

export default UserDepartments;