import React from "react";
import PropTypes from "prop-types";
import s from "./MilestoneTask.module.scss";
import {ReactComponent as GreenCheck} from "../../../../../../../../../assets/icons/greenCheck.svg";
import {useHistory, useParams} from "react-router-dom";

const MilestoneTask = ({task, taskColor, milestoneId, statuses}) => {
  const history = useHistory();
  const params = useParams();

  const isCompleted = task.task_status.id === statuses[statuses.length - 1]?.id;

  return (
    <>
      <div
        className={s.taskWrap}
        style={{borderLeft: `4px solid ${taskColor}`}}
        onClick={() => {
          history.push(`/projects/${params.id}/milestones/${milestoneId}/tasks/${task.id}`);
        }}>
        <p>{task.name}</p>
        <div className={s.taskContent}>
          <p>{task.responsible_user.name}</p>
          {
            isCompleted && (
              <div className={s.completed}>
                <GreenCheck/>
              </div>
            )
          }
        </div>
      </div>
    </>
  );

};

export default MilestoneTask;

MilestoneTask.propTypes = {
  task: PropTypes.any,
  taskColor: PropTypes.string,
  editTask: PropTypes.func,
  ownerId: PropTypes.string,
  milestoneId: PropTypes.string,
};