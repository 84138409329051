import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {load} from "./helpers/localStorage";
import {translateUrl} from "./helpers/baseURL";

const user = load("currentUser");

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: user?.language ?? "en",
    fallbackLng: user?.language ?? "en",
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false,
      bindI18n: 'languageChanged editorSaved',
    },
    backend: {
      loadPath: translateUrl,
      allowMultiLoading: false,
      options: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
      }
    }
  });

export default i18n;