import React from "react";
import s from "../QuestionsContent.module.scss";
import Input from "../../../../../elements/Input/Input";
import {FormControlLabel, RadioGroup, Radio} from "@material-ui/core";
import classNames from "classnames";

const SingleQuestionYesOrNo = ({currentQuestion, isEdit, updateQuestion}) => {
  if (isEdit) {
    return (
      <div className={s.questionContent} data-drag-item="drag">
        <p className={s.titleQuestion} data-drag-item="drag">
          {currentQuestion.question_name}
          {currentQuestion.is_required && <sup>*</sup>}
        </p>
        <Input
          value={currentQuestion.question_name}
          onChange={event => updateQuestion({...currentQuestion, question_name: event.target.value})}
          placeholder='Question text'
          className={classNames(s.input, s.padding, {[s.withTitle]: Boolean(currentQuestion.question_name)})}
        />
      </div>
    );
  }

  const radio = <Radio
    className={s.customRadio}
    color="primary"
    icon={
      <svg width="20" height="20" viewBox="0 0 24 24" fill="#2F78CD" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
      </svg>
    }
    checkedIcon={
      <svg width="20" height="20" viewBox="0 0 24 24" fill="#2F78CD" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
      </svg>
    }
  />;

  return (
    <div className={s.questionContent} data-drag-item="drag">
      <p className={s.titleQuestion} data-drag-item="drag">
        {currentQuestion.question_name}
        {currentQuestion.is_required && <sup>*</sup>}
      </p>
      <RadioGroup className={s.radioGroup} aria-label="radioGroup" name="radioGroup" value={''}>
        <FormControlLabel value={'yes'} control={radio} label={'Yes'}/>
        <FormControlLabel value={'no'} control={radio} label={'No'}/>
      </RadioGroup>
    </div>
  )
}

export default SingleQuestionYesOrNo;

