import React, {useEffect, useReducer, useState} from "react";
import s from "./Users.module.scss";
import services from "services";
import querystring from "query-string";
import {useLocation, useHistory} from "react-router-dom";
import AdminSettingsUsersFilter from "components/filters/AdminSettingsUsersFilter/AdminSettingsUsersFilter";
import {
  getAllIds,
  formatFilters
} from 'components/elements/FilterBar/helpers/helpers'
import {
  setFilters,
  setCurrentPage,
  setActiveFilters,
  setRequestData,
  setPopup,
  setUser,
  resetAlert
} from './helpers/actions'
import {initialState, reducer} from './helpers/reducer'
import UsersTable from 'components/tables/UsersTable/UsersTable'
import EmptyBlock from 'components/elements/EmptyBlock/EmptyBlock'
import NotificationBox from 'components/elements/NotificationBox/NotificationBox'
import Popups from './Popups/Popups'
import FilterBarLoading from 'components/loadingStates/FilterBar/FilterBar'
import TableLoading from 'components/loadingStates/Table/Table'
import {formatDepartmentsFilter} from 'components/filters/helpers/formatDepartmentsFilter'

const Users = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [divisions, setDivisions] = useState([]);
  const location = useLocation();
  const history = useHistory();

  const getFilters = async () => {
    const result = await services.userServices.getUsersFilters()
    const formattedResult = {
      ...result.data,
      departments: formatDepartmentsFilter(result.data.departments)
    }
    const formattedFilters = formatFilters(formattedResult)
    dispatch(setFilters(formattedFilters))
  }
  const getUsers = query => {
    let filters = {
      ...query
    }
    services.userServices.getAdminUsers(filters).then(res => {
      dispatch(
        setRequestData({
          ...res.data,
          loading: false,
          usersTotalCount: res.data.users_count
        })
      )
    })
  }

  useEffect(() => {
    const {page} = querystring.parse(location.search)
    dispatch(setCurrentPage(Number(page)))
  }, [location])

  useEffect(() => {
    const query = querystring.parse(location.search)
    getUsers(query)
  }, [location.search])

  const onSuccess = () => {
    const query = querystring.parse(location.search);
    getUsers(query);
  };

  useEffect(() => {
    getFilters();
    services.userServices.getDivisions().then(res => {
      if (res.data) {
        setDivisions(res.data);
      }
    });
  }, []);

  const handlePageChange = page => {
    const query = querystring.parse(history.location.search)
    history.push({search: querystring.stringify({...query, page})})
  }

  const handleSort = type => {
    history.push({search: querystring.stringify({page: 1, ...type})})
  }

  return (
    <div className={s.wrap}>
      {!Array.isArray(state.filters) && !Object.keys(state.filters).length ? (
        <FilterBarLoading length={4} className={s.filterBar}/>
      ) : (
        <AdminSettingsUsersFilter
          divisions={divisions}
          filters={state.filters}
          className={s.filterBar}
        />
      )}
      {state.users.length > 0 && !state.loading && (
        <UsersTable
          state={state}
          perPage={30}
          onPageChange={handlePageChange}
          onSort={handleSort}
          isAdmin
          onAdd={() => dispatch(setPopup('add'))}
          onEdit={user => {
            dispatch(setUser(user))
            dispatch(setPopup('edit'))
          }}
          onDelete={user => {
            dispatch(setUser(user))
            dispatch(setPopup('delete'))
          }}
          onResetPass={user => {
            dispatch(setUser(user))
            dispatch(setPopup('reset-pass'))
          }}
        />
      )}
      {state.loading && <TableLoading length={7}/>}
      {!state.users.length && !state.loading && (
        <EmptyBlock
          subtitle="You don’t have any users yet."
          buttonTitle="New user"
          onClick={() => dispatch(setPopup("add"))}
        />
      )}
      {state.alert.message && (
        <NotificationBox
          message={state.alert.message}
          type={state.alert.type}
          onClose={() => dispatch(resetAlert())}
        />
      )}
      <Popups
        state={state}
        divisions={divisions}
        dispatch={dispatch}
        onSuccess={onSuccess}
      />
    </div>
  )
}

export default Users;