import axios from "./axios";

export default {
  getUtilizationIndividual: async (params) => {
    try {
      const result = await axios.get("/utilization/individual", { params });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getUtilizationDepartment: async (params) => {
    try {
      const result = await axios.get("/utilization/departments", { params });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getUtilizationGanttChart: async (params) => {
    try {
      const result = await axios.get("/utilization/gantt_chart", { params });
      return result;
    } catch (error) {
      throw error;
    }
  },
};
