import React from "react";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import PropTypes from "prop-types";
import AdditionalHeader from "../AdditionalHeader/AdditionalHeader";
import styles from "./AdditionalRadioButtonSelector.module.scss";

const AdditionalRadioButtonSelector = ({values, setFieldValue, errors}) => {
  const radio = <Radio
    color="primary"
  />;

  return (
    <div className={styles.radioButtonBlock}>
      <AdditionalHeader questionName={"Type"} className={styles.header}/>
      <RadioGroup aria-label="radioGroup" name="radioGroup">
        <FormControlLabel
          onClick={() => (setFieldValue("typeRadioButton", {singleChoice: true, multipleChoice: false}))}
          checked={values?.typeRadioButton.singleChoice}
          value={"single"}
          control={radio}
          label={"Single choice"}/>
        <FormControlLabel
          onClick={() => (setFieldValue("typeRadioButton", {singleChoice: false, multipleChoice: true}))}
          checked={values?.typeRadioButton.multipleChoice}
          value={"multiple"}
          control={radio}
          label={"Multiple choice"}/>
      </RadioGroup>
      {errors && <span className={styles.errorMessage}>{errors.value}</span>}
    </div>
  );
};

export default AdditionalRadioButtonSelector;

AdditionalRadioButtonSelector.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any,
};