export const popupTypes = {
  add: "ADD",
  delete: "DELETE",
};

export const SET_STAKEHOLDERS = "SET_STAKEHOLDERS";
export const SET_LOADING = "SET_LOADING";
export const ADD_STAKEHOLDER = "ADD_STAKEHOLDER";
export const DELETE_STAKEHOLDER = "DELETE_STAKEHOLDER";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const SET_POPUP_NAME = "SET_POPUP_NAME";
export const SET_SELECTED_STAKEHOLDER = "SET_SELECTED_STAKEHOLDER";
export const SET_ALERT = "SET_ALERT";
