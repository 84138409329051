import {parseToObj} from "../../../../helpers/formulaToObj";
import {getConditionValues} from "../../../../helpers/conditionValues";
import {parseToFormula} from "../../../../helpers/objToFormula";

export const getConditionResult = (conditionValue, values) => {
  const preparedConditions = parseToObj(conditionValue);

  const newCondition = preparedConditions.map(condition => {
    return {
      ...condition, condition_group: condition.condition_group.map(cG => {
        const {fieldValue, generalCondition, generalValue} = getConditionValues(cG.value);
        const resultValue = typeof values[fieldValue] === "object" ? values[fieldValue]?.value : values[fieldValue];
        return {
          ...cG,
          value: `'${resultValue}'${generalCondition}'${generalValue}'`
        };
      })
    };
  });

  return parseToFormula(newCondition);
};