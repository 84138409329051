import React, {useState} from "react";
import classNames from "classnames";
import styles from "./RequestsTable.module.scss";
import PropTypes from "prop-types";
import {useTranslate} from "../../../hooks/useTranslate";

const Status = ({status, stage}) => {
  const [isTooltip, setTooltip] = useState(false);
  const preparedStatus = `${status[0].toUpperCase()}${status.slice(1)}`.replace("_", " ");
  const [translateKey, translateValue] = useTranslate(preparedStatus);

  return (
    <div
      className={classNames(styles.status, styles[status])}
      onMouseEnter={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
    >
      {
        (isTooltip && status === "in_progress") && (
          <div className={styles.tooltip}>
            <p>{preparedStatus}:</p>
            <p> {stage ? stage.name : "-"}</p>
          </div>
        )
      }
      {
        (isTooltip && status === "inactive") && (
          <div className={styles.tooltip}>
            <p> {"This user is inactive"}</p>
          </div>
        )
      }
      {
        (isTooltip && status === "active") && (
          <div className={styles.tooltip}>
            <p> {"Ready for a conversation"}</p>
          </div>
        )
      }
      <span data-translate-key={translateKey}>
        {translateValue}
      </span>
    </div>
  );
};

export default Status;

Status.propTypes = {
  status: PropTypes.string,
  stage: PropTypes.any,
};