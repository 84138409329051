import s from "../../tables/SuppliersTable/SuppliersTable.module.scss";
import React from "react";
import classNames from "classnames";
import {checkRegExp} from "../../../helpers/checkRegExp";

const LogoSuppliers = ({name, logo, classNameLogo}) => {
  const typeParts = name.trim().replace(/\s+/g, " ").split(" ");
  const avatar = typeParts.length > 1 ? typeParts[0][0] + typeParts[1][0] : typeParts[0][0];

  const logoInitials = () => {
    return (
      <div className={classNames(s.logoGrey, classNameLogo)}>
        {avatar}
      </div>
    );
  };
  const logoSuppliers = () => {
      let urlNew;
      if (logo.match(/\.(jpeg|jpg|gif|svg|png)$/) != null) {
        const img = new Image();
        img.src = logo;
        return (
          img.onload ? <img
            className={classNames(s.logo, classNameLogo)}
            src={logo}
            alt="Logo"
          /> : logoInitials()
        );
      }
      if (checkRegExp(logo, "url") != null) {
        if (logo && logo.length > 1 && logo.slice(0, 2) !== "ht") {
          urlNew = new URL("http://" + logo);
        } else {
          urlNew = new URL(logo);
        }
        return (
          <img
            className={classNames(s.logo, classNameLogo)}
            src={`https://www.google.com/s2/favicons?domain=${urlNew.hostname.slice(0, 3) == "www" ? urlNew.hostname.slice(4) : urlNew.hostname}`}
            alt="Logo"
          />
        );
      }
    }
  ;
  return (
    <div>
      {logo ? logoSuppliers()
        : logoInitials()
      }
    </div>
  );
};
export default LogoSuppliers;