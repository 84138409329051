import React from "react";
import { useFormik } from "formik";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input.jsx";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import Checkbox from "../../../../elements/Checkbox/Checkbox";
import styles from "./Details.module.scss";
import { ReactComponent as Deleted } from "assets/icons/deleted.svg";
import { statuses } from "../../../../containers/SurveysContainer/components/Templates/Templates";
import { boolean, object, string } from "yup";
import { useTranslation } from "react-i18next";
import { getTranslateKey } from "helpers/getTranslateKey";

const types = [
  { label: "Contract", value: "contract" },
  { label: "User", value: "user" },
  { label: "Supplier", value: "supplier" },
  { label: "Category", value: "category" },
  { label: "Type of spend", value: "type-of-spend" },
  { label: "Budget of spend", value: "budget-of-spend" },
  { label: "Target spend", value: "target-spend" },
  { label: "Date", value: "date" },
  { label: "Number", value: "number" },
  { label: "String", value: "string" },
  { label: "Yes/No", value: "yesno" },
  { label: "Attachment", value: "attachment" },
  { label: "Custom dropdown", value: "custom-dropdown" },
];

const attachments = [
  { label: "Without attachment", value: "without-attachment" },
  { label: "Add optional", value: "add-optional" },
  { label: "Add required attachment", value: "add-required-attachment" },
];

export const Details = ({ setTempQuestions, quest }) => {
  const { t } = useTranslation();
  const handleDelete = () =>
    setTempQuestions((prevQuests) => prevQuests.filter((prevQuest) => prevQuest.id !== quest.id));

  const formik = useFormik({
    initialValues: {
      question: quest?.question || "",
      question_type: quest?.question_type || "",
      weight: quest?.weight || "100%",
      description: quest?.description || "",
      attachment: quest?.attachment || attachments[0],
      status: quest?.status || statuses[0],
      optional: quest?.optional || false,
    },
    enableReinitialize: true,
    validationSchema: object({
      question: string()
        .required(t(getTranslateKey("Please, question is required")))
        .max(
          50,
          t(getTranslateKey(`The question is too long. It should be 50 characters or less.`)),
        ),
      question_type: string().required(t(getTranslateKey("Please, type is required"))),
      weight: string()
        .required(t(getTranslateKey("Please, weight is required")))
        .max(100, t(getTranslateKey("Please, maximum weight is 100"))),
      status: string().required(t(getTranslateKey("Please, status is required"))),
      attachment: string().required(t(getTranslateKey("Please, attachment is required"))),
      optional: boolean().required(t(getTranslateKey("Please, optional question is required"))),
      description: string().max(
        180,
        t(getTranslateKey(`The Description is too long. It should be 180 characters or less.`)),
      ),
    }),
    onSubmit: (values) => {
      const correctValues = {
        question: values.question,
        question_type:
          typeof values.question_type === "string"
            ? values.question_type
            : values.question_type.label,
        description: values.description,
        status: typeof values.question_type === "string" ? values.status : values.status.value,
        optional: values.optional,
        weight: values.weight,
      };
      setTempQuestions((prevQuests) =>
        prevQuests.map((prevQuest) =>
          quest.pos === prevQuest.pos ? { ...prevQuest, ...correctValues } : prevQuest,
        ),
      );
    },
  });
  const { handleSubmit, values, errors, setFieldValue } = formik;

  const getValueWithPercent = (value) => {
    if (typeof value === "number") {
      return `${value}%`;
    } else {
      const numberArr = value
        .split("")
        .filter((el) => !isNaN(+el))
        .join("");
      return `${numberArr}%`;
    }
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className={styles.questCont}>
        <Input
          key="question"
          className={styles.firstDiv}
          name="question"
          label="Questions text"
          placeholder="Enter your questions text"
          onChange={(e) => setFieldValue("question", e.target.value)}
          value={values.question}
          error={errors.question}
          limitValue={50}
          withCounter
        />
        <CustomDropdown
          className={styles.dropDown}
          options={types}
          value={values.question_type}
          onChange={(option) => setFieldValue("question_type", option)}
          label="Type"
          placeholder="Choose type"
          error={errors.question_type}
        />
        <Input
          name="weight"
          label="Weight"
          placeholder="Enter weight"
          onChange={(e) => setFieldValue("weight", e.target.value)}
          value={getValueWithPercent(values.weight)}
          error={errors.weight}
        />
      </div>
      <div className={styles.questCont}>
        <Input
          className={styles.firstDiv}
          name="description"
          label="Description"
          placeholder="Enter a description"
          onChange={(e) => setFieldValue("description", e.target.value)}
          value={values.description}
          error={errors.description}
          limitValue={180}
          withCounter
        />
        <CustomDropdown
          className={styles.dropDown}
          options={attachments}
          value={values.attachment}
          onChange={(option) => setFieldValue("attachment", option)}
          label="Added attachment"
          placeholder="Choose status"
          error={errors.attachments}
        />
        <CustomDropdown
          className={styles.dropDown}
          options={[statuses[0], statuses.at(-1)]}
          value={
            typeof values.status === "string"
              ? statuses.find((el) => el.value === values.status)
              : values.status
          }
          onChange={(option) => setFieldValue("status", option)}
          label="Status"
          placeholder="Choose Status"
          error={errors.status}
        />
      </div>

      <div className={styles.lineCont} />
      <div className={styles.bottomCont}>
        <div className={styles.checkCont}>
          <Checkbox
            value={values.optional}
            checked={values.checked}
            onChange={(e) => setFieldValue("check", e.target.checked)}
            label="Optional question"
            withTranslate
            error={errors.optional}
          />
          <div className={styles.lineCont2} />
          <Deleted onClick={handleDelete} style={{ cursor: "pointer" }} />
        </div>
        <div>
          <Button styled="secondary" type="reset" className="popupBtn" onClick={() => {}}>
            Cancel
          </Button>
          <Button styled="primary" type="submit" className="popupBtn">
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};
