import React from "react";
import styles from "./UserOrgCard.module.scss";
import CardInfo from "../CardInfo/CardInfo";

const UserOrgCard = ({data, withTeamEdit, openTeamEditModal, isTeam, openCard, isReport, withFilter}) => {
  return <ul>{renderChild(data, withTeamEdit, openTeamEditModal, isTeam, openCard, isReport, withFilter)}</ul>;
};

const Children = ({item, withTeamEdit, openTeamEditModal, isTeam, openCard, isReport, isLast, withFilter}) => {
  return (
    <>
      <li>
        <div className={styles.card}>
          <div onClick={() => openCard(item.id)}>
            <CardInfo
              {...item}
              root={Boolean(item.isOpenCard && item.childrens?.length)}
              withTeamEdit={withTeamEdit}
              isOpenCard={item.isOpenCard}
              isTeam={isTeam}
              isReport={isReport}
              isLast={isLast}
              withFilter={withFilter}
              openTeamEditModal={() => openTeamEditModal(item)}
            />
          </div>
          {item.isOpenCard && item.childrens?.length ? (
            <div className={styles.childrenWrapper}>
              <div className={styles.innerWrapper}>
                <ul>{renderChild(item.childrens, withTeamEdit, openTeamEditModal, isTeam, openCard, isReport, withFilter)}</ul>
              </div>
            </div>
          ) : null}
        </div>
      </li>
    </>
  );
};

const renderChild = (item, withTeamEdit, openTeamEditModal, isTeam, openCard, isReport, withFilter) => {
  return item.map((it, index) => <Children
      item={it}
      key={it.id}
      withTeamEdit={withTeamEdit}
      openTeamEditModal={openTeamEditModal}
      isTeam={isTeam}
      isReport={isReport}
      withFilter={withFilter}
      openCard={openCard}
      isLast={item.length - 1 === index}
    />
  );
}

export default UserOrgCard;
