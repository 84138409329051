import React from "react";
import FilterBar from "../../elements/FilterBar/FilterBar";
import qs from "query-string";
import {debounce} from "lodash";
import {useHistory} from "react-router-dom";

const EmailsFilter = ({className}) => {
  const history = useHistory();

  const {...query} = qs.parse(history.location.search);

  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  const handleClearAll = () => {
    history.push({search: qs.stringify()});
  };

  return (
    <FilterBar
      filterName="Filter emails"
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      withoutDefaultFilter
      withSearch
    >
    </FilterBar>
  );
};

export default EmailsFilter;