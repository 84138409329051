import React, {useState} from "react";
import PropTypes from "prop-types";
import styles from "./RequestsTable.module.scss";

const Progress = ({doneCount, toDoCount}) => {
  const total = doneCount + toDoCount;
  const donePercent = (doneCount * 100 / total).toFixed(2);
  const [isTooltipToDo, setIsTooltipToDo] = useState(false);
  const [isTooltipDone, setIsTooltipDone] = useState(false);

  return (
    <div
      className={styles.progressBar}
      onMouseEnter={() => setIsTooltipToDo(true)}
      onMouseLeave={() => setIsTooltipToDo(false)}
    >
      {
        isTooltipToDo && (
          <div className={styles.tooltip}>
            To do: {toDoCount} stages
          </div>
        )
      }
      <div
        style={{width: `${donePercent}%`}}
        className={styles.done}
        onMouseEnter={() => setIsTooltipDone(true)}
        onMouseLeave={() => setIsTooltipDone(false)}
      >
        {
          isTooltipDone && (
            <div className={styles.tooltip}>
              Done: {doneCount} stages
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Progress;

Progress.propTypes = {
  doneCount: PropTypes.number,
  toDoCount: PropTypes.number,
};