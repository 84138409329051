export const formatData = (value, circle) => {
  let val = value * 10;

  let r = circle.r.baseVal.value;
  let c = Math.PI * (r * 2);

  if (val < 0) {
    val = 0;
  }
  if (val > 100) {
    val = 100;
  }

  let pct = ((100 - val) / 100) * c;
  return pct;
};
