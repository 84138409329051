export const SET_FILTERS = "SET_FILTERS";
export const SET_LOADING = "SET_LOADING";
export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_REQUEST_DATA = "SET_REQUEST_DATA";

export const initialState = {
  users: [],
  totalUsersCount: 0,
  loading: true,
  filters: [],
  activeFilters: { departments: [], divisions: [], user_types: [] },
  currentPage: 1,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: payload.loading,
      };
    }

    case SET_REQUEST_DATA: {
      return {
        ...state,
        users: payload.users,
        loading: payload.loading,
        totalUsersCount: payload.usersTotalCount,
      };
    }

    case SET_FILTERS: {
      return {
        ...state,
        filters: payload.filters,
      };
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload.page || 1,
      };
    }

    case SET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: payload.activeFilters,
      };
    }

    default:
      throw Error("Unknown action type");
  }
};
