import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import {useHistory} from "react-router-dom";
import querystring from "query-string";
import {fromDate} from "helpers/dateFormat";
import {currencyData} from "helpers/helpData";
import {formatCurrency} from "helpers/currencyFormat";
import s from "./CategoriesContractsTable.module.scss";
import Tags from "components/elements/Tags/Tags";
import LogoSuppliers from "../../elements/LogoSuppliers/LogoSuppliers";

const CategoriesContractsTable = (
  {
    data,
    perPage,
    totalCount,
    onAdd,
    onDelete,
    onAssignTag,
    onDeleteTag
  }
) => {
  const history = useHistory();
  const query = querystring.parse(history.location.search);
  const currentPage = query.page || 1;

  const onPageChange = page => {
    const query = querystring.parse(history.location.search);

    history.push({search: querystring.stringify({...query, page})});
  };

  const onSort = ({sort_by, direction, tag}) => {
    const query = querystring.parse(history.location.search);
    history.push({
      search: querystring.stringify({
        ...query,
        sort_by,
        direction,
        page: 1,
        tag
      })
    });
  };
  return (
    <MainTable
      data={data}
      stickyHeader={true}
      className={s.table}
      withXScroll={true}
      dragIdLabel="contract"
      dataTypes={[
        {
          title: "Supplier",
          value: ({supplier}) => (
            <div className={s.logoSubSupplier}>
              <LogoSuppliers name={supplier.name} logo={supplier.logo}/>
              <span>{supplier.name}</span>
            </div>),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "supplier_name", direction});
          },
          hint: true,
          max: 22
        },
        {
          title: "Contract name",
          value: ({name}) => name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          onClick: id => history.push(`/contract/details/${id}`),
          sticky: true
        },
        {
          title: "Total contract value",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "total_value", direction});
          },
          value: ({total_value_currency, total_value}) => {
            return total_value && total_value_currency
              ? formatCurrency(
                currencyData.filter(e => e.label === total_value_currency)[0]
                  ?.value,
                total_value
              )
              : "-";
          }
        },
        {
          title: "Annual contract value",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "annual_value", direction});
          },
          value: ({annual_value_currency, annual_value}) => {
            return annual_value && annual_value_currency
              ? formatCurrency(
                currencyData.filter(e => e.label === annual_value_currency)[0]
                  ?.value,

                annual_value
              )
              : "-";
          }
        },
        {
          title: "Start date",
          value: ({start_date}) =>
            start_date ? fromDate(start_date) : "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "start_date", direction});
          }
        },
        {
          title: "End date",
          value: ({end_date}) => (end_date ? fromDate(end_date) : "-"),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "end_date", direction});
          }
        },
        {
          title: "Tags",
          value: (item, allData) => (
            <Tags
              allData={allData}
              item={item}
              onDelete={id => onDeleteTag(id, item.id)}
              onAssign={id => onAssignTag(id, item.id)}
              onSort={({name}) =>
                query.tag !== name ? onSort({tag: name}) : onSort({tag: ""})
              }
            />
          ),
          type: "tags"
        }
      ]}
      button={{
        name: "Add contract",
        onClick: () => onAdd()
      }}
      options={item => [
        {
          title: "Delete",
          onClick: () => onDelete(item)
        }
      ]}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};
export default CategoriesContractsTable;
