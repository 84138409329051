import React, { useEffect, useState } from 'react'
import EmptyContent from '../EmptyContent/EmptyContent'
import Content from '../Content/Content'
import {
	addNewBudget,
	deleteBudget,
	loadData,
	updateCurrentBudget
} from '../helpers/actions/main'
import Popup from '../../../../popups/Popup/Popup'
import BudgetForm from './BudgetForm'
import AdditionalQuestionsLoading from '../../../../loadingStates/AdditionalQuestionsLoading/AdditionalQuestionsLoading'

const Budget = ({ data, dispatch }) => {
	const [loading, setLoading] = useState(true)
	const [currentBudget, setCurrentBudget] = useState(null)
	const [isOpenPopUp, setOpenPopUp] = useState(false)

	useEffect(() => {
		loadData(dispatch, 'budgetList')
		setLoading(false)
	}, [])

	const addNewCondition = value => addNewBudget(dispatch, { budget: value })
	const updateBudget = value => {
		updateCurrentBudget(dispatch, { budget: value }, currentBudget.id)
		setCurrentBudget(null)
	}
	const deleteCondition = id => deleteBudget(dispatch, id)

	const serializeData = data => {
		return data.map(s => {
			return {
				name: 'Greater than $' + Number(s.budget).toLocaleString('en-US'),
				questions_count: s.additional_questions_count,
				id: s.id
			}
		})
	}

	return (
		<>
			{loading ? (
				<AdditionalQuestionsLoading />
			) : data.length ? (
				<Content
					data={serializeData(data)}
					type='Budget'
					addAction={() => setOpenPopUp(true)}
					editAction={item => {
						setCurrentBudget(data.find(d => d.id === item.id))
						setOpenPopUp(true)
					}}
					deleteAction={id => deleteCondition(id)}
				/>
			) : (
				<EmptyContent addAction={() => setOpenPopUp(true)} />
			)}
			{isOpenPopUp && (
				<Popup
					onClose={() => setOpenPopUp(false)}
					title={currentBudget ? 'Edit condition' : 'New condition'}
				>
					<BudgetForm
						initialValue={
							currentBudget
								? {
										name: String(currentBudget.budget),
										value: String(currentBudget.budget),
										id: currentBudget.id
								  }
								: null
						}
						onSubmit={value => addNewCondition(value.budget.value)}
						onEdit={value => updateBudget(value.budget.value)}
						onClose={() => setOpenPopUp(false)}
						budgetList={serializeData(
							data.filter(b => b.id !== currentBudget?.id)
						)}
					/>
				</Popup>
			)}
		</>
	)
}
export default Budget
