import React from "react";
import PropTypes from "prop-types";
import s from "./MilestoneTasksGroupStage.module.scss";
import MilestoneTask from "../MilestoneTask/MilestoneTask.js";
import Approval from "../Approval/Approval";
import Button from "../../../../../../../../elements/Button/Button";
import {useSelector} from "react-redux";
import {userRoles} from "../../../../../../../../../helpers/userPermissions/userRoles";
import {useHistory, useParams} from "react-router-dom";

const MilestoneTasksGroupStage = (
  {
    tasksColor,
    milestone,
    milestonesLength,
    nameGroup,
    owner,
    projectName,
    projectTypeId,
    deleteApprovalStep,
    sendAnswer,
    sendRequest,
    statuses
  }
) => {
  const history = useHistory();
  const params = useParams();
  const user = useSelector(store => store.user);
  const {admin, cpo, procurement_admin} = userRoles;
  const highestRoles = [admin, cpo, procurement_admin];
  const isCanAdd = user.id === owner || highestRoles.includes(user.roles);

  return (
    <div className={s.stageWrap} style={{
      width: `${100 / milestonesLength}%`
    }}>
      {
        milestone.tasks[nameGroup].map(task => {
          if (nameGroup !== "approvals") return (
            <MilestoneTask
              key={task.id}
              task={task}
              taskColor={tasksColor}
              milestoneId={milestone.id}
              statuses={statuses}
            />
          );
          return (
            <Approval
              key={task.id}
              milestoneId={milestone.id}
              milestoneName={milestone.name}
              projectName={projectName}
              projectTypeId={projectTypeId}
              data={task}
              ownerId={owner}
              deleteApprovalStep={id => deleteApprovalStep(milestone.id, id)}
              sendAnswer={sendAnswer}
              sendRequest={sendRequest}
            />
          );
        })
      }
      {(isCanAdd && nameGroup === "approvals") && (
        <Button
          styled='secondary'
          className={s.addTaskBtn}
          onClick={() => history.push(`/projects/${params.id}/milestones/${milestone.id}/create-approval`, {
            projectName,
            projectTypeId,
            milestoneName: milestone.name,
          })}
        >
          + New approval step
        </Button>
      )}
    </div>
  );

};

export default MilestoneTasksGroupStage;

MilestoneTasksGroupStage.propTypes = {
  tasksColor: PropTypes.string,
  milestone: PropTypes.any,
  milestonesLength: PropTypes.number,
  nameGroup: PropTypes.string,
  editTask: PropTypes.func,
  owner: PropTypes.any,
};