import React, { useEffect, useState, useContext } from "react"
import { useParams } from "react-router-dom"
import { CircularProgress } from "@material-ui/core"
import s from "./MilestoneContainer.module.scss"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import Popup from "components/popups/Popup/Popup"
import AddMilestoneTaskForm from "components/forms/AddMilestoneTaskForm/AddMilestoneTaskForm"
import TasksTable from "components/tables/TasksTable/TasksTable"
import services from "services/index"
import { cancelRequest } from "services/axios"
import AlertContext from "contexts/AlertContext/AlertContext"
import sort from "helpers/sortHelper"

const MilestoneContainer = ({ setBreadcrumbs }) => {
  const params = useParams()
  const [milestone, setMilestone] = useState({})
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const { setAlert, getCancelId } = useContext(AlertContext)
  const [sorted, setSorted] = useState({ field: "", direction: "" })
  const [permissions, setPermissions] = useState({
    update: false,
    destroy: false
  });

  useEffect(() => {
    services.milestonesServices
      .getMilestone(params.projectId, params.id)
      .then(res => {
        setMilestone(res.data)
        setPermissions({
          update: res.data.tasks.permissions?.update || false,
          destroy: res.data.tasks.permissions?.destroy || false
        })
        setBreadcrumbs(state => [
          ...state,
          {
            name: "Project details",
            path: `/projects/${res.data.project.id}`,
          },
          {
            name: "Milestones",
            path: `/projects/${res.data.project.id}?tab=Milestones`
          },
          { name: `${res.data.name}` }
        ])
        setTasks(
          sort.sortDate(res.data.tasks.list, {
            field: "start_date",
            direction: "asc"
          })
        )
        setSorted({ field: "start_date", direction: "asc" })
        setLoading(false)
      })
  }, [params.id])
  const appendFile = file => {
    const formData = new FormData()
    formData.append("file", file)
    return formData
  }
  const addHandler = ({ files, ...spread }) => {
    services.taskServices
      .addTask(params.id, { ...spread })
      .then(res => {
        const task = res?.data
        setAlert({
          message: `"${task.name}" task successfully added.`
        })
        if (sorted.field) setTasks(sort.sortDate([...tasks, task], sorted))
        else setTasks([...tasks, task])
        for (let i = 0; i < files.length; i++) {
          const file = appendFile(files[i])
          task.id &&
            services.taskServices.addAttachment(file, task.id).then(res => {
              const attachment = res?.data
              attachment &&
                setTasks(tasks =>
                  tasks.map(e =>
                    e.id === task.id
                      ? {
                          ...e,
                          attachments: [...e.attachments, attachment],
                          has_note: true
                        }
                      : e
                  )
                )
            })
        }
      })
      .catch(err => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      })
  }

  const editHandler = (id, data) => {
    const { attachments, ...spread } = data
    const cancelId = getCancelId()
    const oldTasks = tasks
    const newTask = { ...tasks.find(task => task.id === id), ...data }
    if (sorted.field)
      setTasks(
        sort.sortDate(
          tasks.map(item => (item.id === id ? newTask : item)),
          sorted
        )
      )
    else setTasks(tasks.map(task => (task.id === id ? newTask : task)))
    setAlert({
      message: `"${newTask.name}" task successfully edited.`,
      onCancel: () => {
        cancelRequest(cancelId)
        setTasks(oldTasks)
      }
    })
    services.taskServices
      .editTask(params.id, id, { ...spread }, { cancelId })
      .then(res => {
        const task = res.data
        if (sorted.field)
          setTasks(
            sort.sortDate(
              tasks.map(item => (item.id === res.data.id ? res.data : item)),
              sorted
            )
          )
        else setTasks(tasks.map(task => (task.id === id ? res.data : task)))
        for (let i = 0; i < attachments.length; i++) {
          const file = appendFile(attachments[i])
          services.taskServices.addAttachment(file, task?.id).then(res => {
            const attachment = res?.data
            attachment &&
              setTasks(tasks =>
                tasks.map(e =>
                  e.id === task?.id
                    ? {
                        ...e,
                        attachments: [...e.attachments, attachment],
                        has_note:
                          [...e.attachments, attachment].length > 0 ||
                          e.description?.length > 0
                      }
                    : e
                )
              )
          })
        }
      })
      .catch(() => {
        setTasks(oldTasks)
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      })
  }

  const removeHandler = id => {
    const cancelId = getCancelId()
    const oldTasks = tasks
    setAlert({
      message: `"${
        tasks.filter(task => task.id === id)[0].name
      }" task successfully deleted.`,
      onCancel: () => {
        cancelRequest(cancelId)
        setTasks(oldTasks)
      }
    })
    setTasks(tasks.filter(task => task.id !== id))
    services.taskServices
      .deleteTask(params.id, id, { cancelId })
      .then()
      .catch(() => {
        setTasks(oldTasks)
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      })
  }

  const sortHandler = type => {
    setSorted(type)
    if (type.field === "start_date") setTasks(sort.sortDate(tasks, type))
    if (type.field === "end_date") setTasks(sort.sortDate(tasks, type))
  }
  const openNotesHandler = async id => {
    return await services.taskServices.getTaskNotes(id)
  }

  return (
    <div className={s.milestoneContainer}>
      {loading && (
        <div className='loaderWrap'>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <>
          <h1 className={s.title}>{milestone.name}</h1>
          {tasks.length ? (
            <TasksTable
              tasks={tasks}
              removeHandler={removeHandler}
              editHandler={editHandler}
              openPopup={() => setShow(true)}
              onSort={sortHandler}
              havePermissions={permissions}
              openNotesHandler={openNotesHandler}
            />
          ) : (
            <EmptyBlock
              subtitle={
                permissions.update
                  ? "You don’t have task yet. You can add a new one"
                  : "You don’t have task yet."
              }
              buttonTitle='Add task'
              onClick={() => setShow(true)}
              withBtn={permissions.update}
            />
          )}
          {show && (
            <Popup
              onClose={() => setShow(false)}
              title='Add task'
              text='Please, complete the following fields to add new task'
            >
              <AddMilestoneTaskForm
                onSubmit={addHandler}
                onClose={() => setShow(false)}
              />
            </Popup>
          )}
        </>
      )}
    </div>
  )
}

export default MilestoneContainer
