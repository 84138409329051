import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./AverageResult.module.scss";

import { formatData } from "./helpers/format";

const AverageResult = ({ value }) => {
  const [percent, setPercent] = useState(0);

  const circle = useRef(null);

  useEffect(() => {
    circle.current && setPercent(formatData(value, circle.current));
  }, [circle]);

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>Average result</h4>

      <div
        className={styles.container}
        data-pct={Number(value)
          .toFixed(1)
          .replace(".", ",")}
      >
        <svg
          className={styles.svg}
          width="200"
          height="200"
          viewport="0 0 100 100"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            r="90"
            cx="100"
            cy="100"
            fill="transparent"
            strokeDasharray="565.48"
            strokeDashoffset="0"
          ></circle>
          <circle
            className={styles.bar}
            ref={circle}
            r="90"
            cx="100"
            cy="100"
            fill="transparent"
            strokeDasharray="565.48"
            strokeDashoffset="0"
            strokeLinecap="round"
            style={{ strokeDashoffset: percent }}
            transform="rotate(-90 100 100)"
          ></circle>
          <circle
            className={styles.textCircle}
            r="30"
            cx="100"
            cy="100"
            fill="#63A5A1"
          ></circle>
        </svg>
      </div>
    </div>
  );
};

AverageResult.propTypes = {
  value: PropTypes.string.isRequired,
};

export default AverageResult;
