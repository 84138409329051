import React, {useEffect, useState} from 'react';
import {parserForSelect} from "../../../containers/AdminSettingsStepFieldDetailsContainer/helpers/parserForSelect";
import AutoCompleteInput from "../../AutoCompleteInput/AutoCompleteInput";
import services from "../../../../services";
import Input from "../../Input/Input";
import {recursiveJoin} from "../../../../helpers/recursiveJoin";

const CustomDropDownSelect = ({changeSingleCondition, defaultValue, options, isViewMode}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (defaultValue && defaultValue !== " ") {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  if (isViewMode) return <Input value={value} withoutChange/>;

  return (
    <AutoCompleteInput
      label=""
      value={value}
      placeholder=""
      onChange={e => {
        setValue(e.target.value);
      }}
      onSelect={value => {
        changeSingleCondition(parserForSelect(value));
      }}
      data={options}
    />
  );
};

export default CustomDropDownSelect;