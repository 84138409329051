import React from "react";
import { useFormik } from "formik";
import { PreviewQuestion } from "../PreviewQuestion/PreviewQuestion";
import styles from "../EditQuestionsPage/EditQuestionsPage.module.scss";

export const StepContainer = ({ step, id }) => {
  const generateInitialState = (step) => {
    const values = {};
    if (step.questions) {
      step.questions.forEach((quest) => {
        values[
          typeof quest?.question_type === "string"
            ? quest?.question_type
            : quest?.question_type?.label
        ] = "";
      });
    }
    return values;
  };

  const formik = useFormik({
    initialValues: generateInitialState(step),
    onSubmit: (values) => {},
  });

  const { handleSubmit, values, setFieldValue, errors, touched } = formik;
  return (
    <>
      <section className={styles.secWrapper}>
        <section>
          <div className={styles.stepName}>{`Step ${id + 1} - ` + step.name}</div>
          <label className={styles.stepDescription}>{step.description}</label>
        </section>
      </section>
      {step.questions.map((quest) => (
        <PreviewQuestion quest={quest} values={values} setFieldValue={setFieldValue} />
      ))}
    </>
  );
};
