export const getMinMaxDates = (years, useWithMonthReorder = false) => {
  const currentMonth = new Date().getUTCMonth();
  const newYearDate = new Date(`${years[years.length - 1] + 1}-01-01`);
  newYearDate.setDate(newYearDate.getDate() - 1);
  let min = new Date(`${years[0]}-01-01`).getTime();
  let max = newYearDate.getTime();

  if (useWithMonthReorder) {
    if (currentMonth === 4) {
      max = new Date(`${new Date().getFullYear() + 1}-01-01`);
      min = new Date(`${new Date().getFullYear()}-01-01`);
    }
    if (currentMonth > 4) {
      min = new Date(`${new Date().getFullYear()}-${currentMonth - 3}-01`);
      max = new Date(`${new Date().getFullYear() + 1}-${currentMonth - 3}-01`);
    }
    if (currentMonth < 4) {
      min = new Date(`${new Date().getFullYear() - 1}-${4 - currentMonth}-01`);
      max = new Date(`${new Date().getFullYear()}-${4 - currentMonth}-01`);
    }
  }
  return [min, max];
};
