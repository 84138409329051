import React, {useState} from "react";
import s from "./SavingsChart.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import ChartQuarter from "./components/ChartQuarter/ChartQuarter";
import {savingsTypesData} from "helpers/helpData";
import {useTranslate} from "../../../hooks/useTranslate";
import {trimRest} from "../../../helpers/stringHelper";
import CustomNotification from "../QuestionNotification/CustomNotification";

const colors = {
  one_time_er: "99, 165, 161",
  one_time_ca: "244, 162, 97",
  run_rate_er: "251, 120, 122",
  run_rate_ca: "91, 121, 180",
};

const SavingsChart = ({className, data, title = ""}) => {
  const [hintPos, setHintPos] = useState(null);
  const [hintData, setHintData] = useState(null);
  const [translateKey, translateValue] = useTranslate(title);
  const getIndicators = () => {
    if (data.length) {
      return Object.values(data[0])[0]
        .map(
          (indicator) =>
            savingsTypesData.filter((e) => e.value === Object.keys(indicator)[0])[0]
        )
        .filter((e) => Boolean(e));
    }
    return [];
  };

  const renderTypeNames = () => {
    if (data.length) {
      let prepareNames = Object.values(data[0])[0]?.map(type => Object.keys(type)[0])?.slice(0, -1);
      return (
        <div className={s.typeNamesWrapper}>
          {prepareNames?.map((name, index) => (
            <CustomNotification
              key={index}
              value={name}
              text={trimRest(12, name)}
              textClassName={s.typeName}
            />
          ))}
        </div>
      );
    }
  };
  return (
    <div className={classNames(s.savingsChart, className)}>
      <div className={s.titleWrap}>
        <h4 className={s.subtitle} data-translate-key={translateKey}>{translateValue}</h4>
      </div>
      <div className={s.inlineWrap}>
        {renderTypeNames()}
        {data.map((quarter, index) => (
          <div className={s.quarter} key={index}>
            <ChartQuarter
              data={quarter}
              onMouseOut={() => setHintPos(null)}
              onMouseMove={(e, data) => {
                setHintData(data);
                setHintPos({
                  x: e.clientX,
                  y: e.clientY,
                });
              }}
            />
          </div>
        ))}
      </div>
      <ul className={s.indicators}>
        {getIndicators().map((indicator, index) => (
          <li className={s.indicator} key={index}>
            <span
              className={s.circle}
              style={{backgroundColor: `rgba(${colors[indicator.value]}, 1)`}}
            />
            <span className={s.title}>{indicator.label}</span>
          </li>
        ))}
      </ul>
      {hintPos && hintData && (
        <div
          className={s.hint}
          style={{left: `${hintPos.x}px`, top: `${hintPos.y}px`}}
          onMouseMove={() => setHintPos(null)}
        >
          <span className={s.title}>{hintData.title}</span>
          <span className={s.value}>{hintData.value}</span>
        </div>
      )}
    </div>
  );
};
SavingsChart.propTypes = {
  onClick: PropTypes.func,
};

export default SavingsChart;
