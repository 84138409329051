import React, {useState, useEffect, useRef} from "react";
import s from "./DateChangerWidget.module.scss";
import {formatMilestonesData} from "./helpers/formatMilestonesData";
import Milestone from "./components/Milestone/Milestone";
import {fromDate, formatDate} from "helpers/dateFormat";
import Button from "components/elements/Button/Button";
import {ReactComponent as Pencil} from "assets/icons/edit-pencil.svg";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {backgroundTypes} from "../../../../../../../helpers/helpData";
import MilestoneTasksGroup from "./components/MilestoneTasksGroup/MilestoneTasksGroup";
import services from "../../../../../../../services";
import {getTranslateKey} from "../../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../../../../../../hooks/useWindowSize";

const DateChangerWidget = (
  {
    milestones,
    onSubmit,
    owner,
    projectStartDate,
    projectEndDate,
    onCreateTask,
    onEditTask,
    onDeleteTask,
    projectName,
    projectTypeId,
    deleteApprovalStep,
    sendAnswer,
    sendRequest
  }
) => {
  const {t} = useTranslation();
  const [milestonesWidths, setMilestonesWidths] = useState({});
  const [windowsWidths, setWindowsWidths] = useState({});
  const [milestonesData, setMilestonesData] = useState({});
  const [windowsData, setWindowsData] = useState({});
  const [mode, setMode] = useState("view");
  const [changed, setChanged] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [widthWindow] = useWindowSize();
  const ownerId = useSelector(state => state.user.id);

  const parentRef = useRef(null);

  const oneDaySeconds = 86400000;

  const allDays = Math.floor(
    (new Date(projectEndDate) - new Date(projectStartDate)) / oneDaySeconds
  );
  const oneDayPercent = 100 / (allDays + 1);

  const formatMilestones = () => {
    const {
      milestones_widths,
      milestones_data,
      windows_widths,
      windows_data
    } = formatMilestonesData(
      milestones,
      oneDayPercent,
      oneDaySeconds,
      projectStartDate,
      projectEndDate
    );
    setMilestonesWidths(milestones_widths);
    setMilestonesData(milestones_data);
    setWindowsWidths(windows_widths);
    setWindowsData(windows_data);
  };

  useEffect(() => {
    services.taskServices.getTaskStatuses().then(res => {
      if (res.data) setStatuses(res.data?.task_statuses || []);
    });
  }, []);

  useEffect(() => {
    formatMilestones();
  }, [milestones, projectStartDate, projectEndDate]);

  const onMouseDown = (e, position, milestoneId, nextMilestoneId) => {
    const divider = e.currentTarget;
    const dividerPos = divider.getBoundingClientRect();
    const onMouseMove = e => {
      const shift = e.screenX - dividerPos.left;
      const percent =
        ((shift < 0 ? shift * -1 : shift) * 100) / parentRef.current.offsetWidth;
      const milestones_widths = {};
      const milestones_data = {};
      const windows_widths = {};
      const windows_data = {};

      if (shift < 0) {
        if (position === "left") {
          const newPercent =
            percent <= windowsWidths[milestoneId]
              ? percent
              : windowsWidths[milestoneId];
          windows_widths[milestoneId] = windowsWidths[milestoneId] - newPercent;
          milestones_widths[milestoneId] =
            milestonesWidths[milestoneId] + newPercent;
          milestones_data[milestoneId] = {
            anticipated_time_spend:
              milestonesData[milestoneId].anticipated_time_spend +
              Math.floor(newPercent / oneDayPercent),
            start_date:
              milestonesData[milestoneId].start_date -
              Math.floor(newPercent / oneDayPercent) * oneDaySeconds,
            end_date: milestonesData[milestoneId].end_date
          };
          windows_data[milestoneId] = {
            anticipated_time_spend:
              windowsData[milestoneId].anticipated_time_spend -
              Math.floor(newPercent / oneDayPercent)
          };
        } else {
          const newPercent =
            percent + oneDayPercent < milestonesWidths[milestoneId]
              ? percent
              : milestonesWidths[milestoneId] - oneDayPercent;
          windows_widths[nextMilestoneId] = nextMilestoneId
            ? windowsWidths[nextMilestoneId] + newPercent
            : 0;

          milestones_widths[milestoneId] =
            milestonesWidths[milestoneId] - newPercent;

          if (!windowsWidths[nextMilestoneId]) {
            windows_widths["after_last"] =
              windowsWidths["after_last"] - (milestones_widths[milestoneId] - milestonesWidths[milestoneId]);

            windows_data["after_last"] = {
              anticipated_time_spend: Math.floor(windows_widths["after_last"] / oneDayPercent),
            };
          }

          milestones_data[milestoneId] = {
            anticipated_time_spend:
              milestonesData[milestoneId].anticipated_time_spend -
              Math.floor(newPercent / oneDayPercent),
            start_date: milestonesData[milestoneId].start_date,
            end_date:
              milestonesData[milestoneId].end_date -
              Math.floor(newPercent / oneDayPercent) * oneDaySeconds
          };

          windows_data[nextMilestoneId] = {
            anticipated_time_spend: nextMilestoneId
              ? windowsData[nextMilestoneId].anticipated_time_spend +
              Math.floor(newPercent / oneDayPercent)
              : 0
          };
        }
      } else {
        if (position === "left") {
          const newPercent =
            percent + oneDayPercent < milestonesWidths[milestoneId]
              ? percent
              : milestonesWidths[milestoneId] - oneDayPercent;

          windows_widths[milestoneId] = windowsWidths[milestoneId] + newPercent;
          milestones_widths[milestoneId] =
            milestonesWidths[milestoneId] - newPercent;
          milestones_data[milestoneId] = {
            anticipated_time_spend:
              milestonesData[milestoneId].anticipated_time_spend -
              Math.floor(newPercent / oneDayPercent),
            start_date:
              milestonesData[milestoneId].start_date +
              Math.floor(newPercent / oneDayPercent) * oneDaySeconds,
            end_date: milestonesData[milestoneId].end_date
          };
          windows_data[milestoneId] = {
            anticipated_time_spend:
              windowsData[milestoneId].anticipated_time_spend +
              Math.floor(newPercent / oneDayPercent)
          };
        } else {
          let newPercent =
            percent <= windowsWidths[nextMilestoneId]
              ? percent
              : windowsWidths[nextMilestoneId] || 0;

          const preparedAfterLastPercent = windowsWidths["after_last"] -
            (milestonesWidths[milestoneId] + percent - milestonesWidths[milestoneId]);

          if (!windowsWidths[nextMilestoneId]) {
            if (preparedAfterLastPercent > 0) newPercent = percent;
            else newPercent = percent + preparedAfterLastPercent;
          }

          windows_widths[nextMilestoneId] = nextMilestoneId
            ? windowsWidths[nextMilestoneId] - newPercent
            : 0;

          milestones_widths[milestoneId] =
            milestonesWidths[milestoneId] + newPercent;

          if (!windowsWidths[nextMilestoneId]) {
            windows_widths["after_last"] =
              windowsWidths["after_last"] - (milestones_widths[milestoneId] - milestonesWidths[milestoneId]);

            windows_data["after_last"] = {
              anticipated_time_spend: Math.ceil(windows_widths["after_last"] / oneDayPercent),
            };
          }

          milestones_data[milestoneId] = {
            anticipated_time_spend:
              milestonesData[milestoneId].anticipated_time_spend +
              Math.floor(newPercent / oneDayPercent),
            start_date: milestonesData[milestoneId].start_date,
            end_date:
              milestonesData[milestoneId].end_date +
              Math.floor(newPercent / oneDayPercent) * oneDaySeconds
          };

          windows_data[nextMilestoneId] = {
            anticipated_time_spend: nextMilestoneId
              ? windowsData[nextMilestoneId].anticipated_time_spend -
              Math.floor(newPercent / oneDayPercent)
              : 0
          };

        }
      }
      setMilestonesWidths(widths => ({...widths, ...milestones_widths}));
      setMilestonesData(data => ({...data, ...milestones_data}));
      setWindowsWidths(widths => ({...widths, ...windows_widths}));
      setWindowsData(data => ({...data, ...windows_data}));
    };
    const onMouseUp = () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      !changed && setChanged(true);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  return (
    <div className={s.wrap} ref={parentRef}>
      <div className={s.header}>
        <h3 className={s.title} data-translate-key={getTranslateKey("Milestone")}>
          {t(getTranslateKey("Milestone"))}
        </h3>
        {mode === "edit" && (
          <div className={s.btns}>
            <Button
              className={s.btn}
              styled="secondary"
              onClick={() => {
                formatMilestones();
                setMode("view");
              }}
            >
              Cancel
            </Button>
            <Button
              className={s.btn}
              styled="primary"
              onClick={() => {
                onSubmit(milestonesData);
                setMode("view");
              }}
              disabled={!changed}
            >
              Save
            </Button>
          </div>
        )}
        {mode === "view" && ownerId === owner && milestones.length > 0 && (
          <Pencil onClick={() => setMode("edit")} className={s.editBtn}/>
        )}
      </div>
      <div className={s.dates}>
				<span className={s.date}>
					{projectStartDate
            ? fromDate(formatDate(new Date(projectStartDate)))
            : "-"}
				</span>
        <span className={s.date}>
					{projectEndDate
            ? fromDate(formatDate(new Date(projectEndDate)))
            : "-"}
				</span>
      </div>
      {Object.keys(windowsData).length > 0 && (
        <div className={s.inlineWrap}>
          {milestones.map((milestone, index) => (
            <Milestone
              backgroundColor={backgroundTypes[milestone.color]}
              width={
                ((widthWindow || 0) *
                  milestonesWidths[milestone.id]) /
                100
              }
              windowWidth={
                ((widthWindow || 0) *
                  windowsWidths[milestone.id]) /
                100
              }
              data={milestonesData[milestone.id] || {}}
              windowData={windowsData[milestone.id] || {}}
              name={milestone.name}
              onMouseDown={(e, position) =>
                onMouseDown(
                  e,
                  position,
                  milestone.id,
                  milestones[index + 1]?.id
                )
              }
              className={s.milestone}
              key={index}
              index={index}
              mode={mode}
              projectStartDate={projectStartDate}
              projectEndDate={projectEndDate}
              projectDays={Math.floor(
                (new Date(projectEndDate) - new Date(projectStartDate)) /
                oneDaySeconds
              )}
              afterLastWindowWidth={
                index === milestones.length - 1
                  ? ((widthWindow || 0) *
                    windowsWidths["after_last"]) /
                  100
                  : 0
              }
              afterLastWindowData={
                index === milestones.length - 1 ? windowsData["after_last"] : {}
              }
              milestonesLength={milestones.length}
            />
          ))}
        </div>
      )}
      <MilestoneTasksGroup
        nameGroup="required_tasks"
        milestones={milestones}
        onEditTask={(milestoneId, taskId, values) => onEditTask(milestoneId, taskId, values)}
        owner={owner}
        statuses={statuses}
      />
      <MilestoneTasksGroup
        nameGroup="recommended_tasks"
        milestones={milestones}
        onEditTask={(milestoneId, taskId, values) => onEditTask(milestoneId, taskId, values)}
        owner={owner}
        statuses={statuses}
      />
      <MilestoneTasksGroup
        nameGroup="Additional tasks"
        milestones={milestones}
        typeGroup="dnd"
        mode={mode}
        onCreateTask={(milestoneId, values) => onCreateTask(milestoneId, values)}
        onEditTask={(milestoneId, taskId, values) => onEditTask(milestoneId, taskId, values)}
        onDeleteTask={(milestoneId, taskId) => onDeleteTask(milestoneId, taskId)}
        owner={owner}
        statuses={statuses}
      />
      <MilestoneTasksGroup
        nameGroup="approvals"
        milestones={milestones.map(m => ({...m, tasks: {...m.tasks, approvals: m.approvals}}))}
        owner={owner}
        projectName={projectName}
        projectTypeId={projectTypeId}
        deleteApprovalStep={deleteApprovalStep}
        sendAnswer={sendAnswer}
        sendRequest={sendRequest}
        statuses={statuses}
      />
    </div>
  );
};

export default DateChangerWidget;

DateChangerWidget.propTypes = {
  milestones: PropTypes.array,
  onSubmit: PropTypes.func,
  owner: PropTypes.any,
  projectStartDate: PropTypes.string,
  projectEndDate: PropTypes.string,
  onCreateTask: PropTypes.func,
  onEditTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
};