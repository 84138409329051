import {
  GET_STAGE,
  SET_STAGE,
  CHANGE_POPUP,
  ADD_TASK,
  CLOSE_ALERT,
  EDIT_TASK,
  DELETE_TASK
} from "./types"
import services from "services"

export const getStage = (dispatch, id) => {
  services.adminProjectsServices.getStage(id).then(res => {
    const stage = res?.data
    dispatch({
      type: GET_STAGE,
      payload: {
        name: stage?.name || "",
        tasks: stage?.stage_tasks || []
      }
    })
  })
}
export const setStage = stage => {
  return {
    type: SET_STAGE,
    payload: {
      name: stage.name,
      tasks: stage.stage_tasks
    }
  }
}
export const changePopup = popup => {
  return {
    type: CHANGE_POPUP,
    payload: popup
  }
}

export const addTask = (dispatch, stageId, values) => {
  services.adminProjectsServices
    .addStageTask(stageId, values)
    .then(res => {
      const task = res?.data
      dispatch({
        type: ADD_TASK,
        payload: {
          task,
          alert: {
            type: "success",
            message: "Task successfully created."
          }
        }
      })
    })
    .catch(() =>
      dispatch({
        type: ADD_TASK,
        payload: {
          task: null,
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const editTask = (dispatch, stageId, taskId, values) => {
  services.adminProjectsServices
    .editStageTask(stageId, taskId, values)
    .then(res => {
      const task = res?.data
      dispatch({
        type: EDIT_TASK,
        payload: {
          task,
          alert: {
            type: "success",
            message: "Task successfully changed."
          }
        }
      })
    })
    .catch(() =>
      dispatch({
        type: ADD_TASK,
        payload: {
          task: null,
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const deleteTask = (dispatch, stageId, taskId) => {
  services.adminProjectsServices
    .deleteStageTask(stageId, taskId)
    .then(() => {
      dispatch({
        type: DELETE_TASK,
        payload: {
          task_id: taskId,
          alert: {
            type: "success",
            message: "Task successfully deleted."
          }
        }
      })
    })
    .catch(() =>
      dispatch({
        type: DELETE_TASK,
        payload: {
          task_id: null,
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}

export const closeAlert = () => {
  return {
    type: CLOSE_ALERT,
    payload: null
  }
}
