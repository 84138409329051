import React, {useEffect, useState} from "react";
import AutoCompleteInput from "../../../../../elements/AutoCompleteInput/AutoCompleteInput";
import {parserForSelect} from "../../../../../containers/AdminSettingsStepFieldDetailsContainer/helpers/parserForSelect";
import PropTypes from "prop-types";

const DropDownsSelect = ({field, values, setFieldValue, errors}) => {
  const correctFieldKey = field.key ? field.key : field.value;

  const [value, setValue] = useState("");

  useEffect(() => {
    if (values[correctFieldKey]) {
      setValue(values[correctFieldKey].label);
    }
  }, [values[correctFieldKey]]);

  return (
    <AutoCompleteInput
      label={field.label}
      value={value || ""}
      withoutTranslate
      placeholder=""
      onChange={e => {
        setValue(e.target.value);
      }}
      onSelect={value => {
        setFieldValue(correctFieldKey, {...parserForSelect(value), type: "Custom Dropdown", conditions: field.conditions});
      }}
      data={field.options}
      error={errors?.[correctFieldKey]?.value}
    />
  );
};

export default DropDownsSelect;

DropDownsSelect.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  onlyFirstLevel: PropTypes.bool,
  errors: PropTypes.any,
};