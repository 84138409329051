import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import AdminSettingsEmailsContainer from "../../containers/AdminSettingsEmailsContainer/AdminSettingsEmailsContainer";

const AdminSettingsEmailsPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {name: "Admin Settings", disabled: true},
        {name: "Emails"},
      ]}
    >
      <AdminSettingsEmailsContainer/>
    </DefaultLayout>
  );
};

export default AdminSettingsEmailsPage;
