import React, {useEffect, useState} from "react";
import services from "services/index";
import sBase from "../MainDashboard.module.scss";
import {getTitle} from "../../helpers/dataFormat";
import s from "./StaticWidgetsForBusinessUser.module.scss";
import WidgetsLoading from "../../../../../loadingStates/Dashboard/Widgets/WidgetsLoading";

const StaticWidgetsForBusinessUser = () => {
  const [staticData, setStaticData] = useState({
    active: {},
    completed: {},
    planned: {},
  });
  const [loading, setLoading] = useState(true);
  const {active, completed, planned} = staticData;

  useEffect(() => {
    services.dashboardServices.getStatisticAboutProjects().then((res) => {
      setStaticData(res.data);
    });
    setLoading(false);
  }, []);


  return (
    <>
      <section className={sBase.wrapper}>
        {
          loading ? <WidgetsLoading/> :
            <>
              <div className={s.header}>
                <h4 className={sBase.title}>{getTitle("planedProjects")}</h4>
                <span className={s.count}>{planned.count} projects</span>
              </div>
              <p className={s.value}>$ {planned.value}</p>
            </>
        }
      </section>
      <section className={sBase.wrapper}>
        {
          loading ? <WidgetsLoading/> :
            <>
              <div className={s.header}>
                <h4 className={sBase.title}>{getTitle("activeProjects")}</h4>
                <span className={s.count}>{active.count} projects</span>
              </div>
              <p className={s.value}>$ {active.value}</p>
            </>
        }
      </section>
      <section className={sBase.wrapper}>
        {
          loading ? <WidgetsLoading/> :
            <>
              <div className={s.header}>
                <h4 className={sBase.title}>{getTitle("completedProjects")}</h4>
                <span className={s.count}>{completed.count} projects</span>
              </div>
              <p className={s.value}>$ {completed.value}</p>
            </>
        }
      </section>
    </>
  );
};

export default StaticWidgetsForBusinessUser;


