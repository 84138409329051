import React, { useState } from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import { debounce } from "lodash";

const initialFiltersValues = {
  suppliers: [],
  expires_in: [],
};

const CategorySuppliersFilter = ({ onFiltersChange, filters, className }) => {
  const [activeFilters, setActiveFilters] = useState(initialFiltersValues);
  const history = useHistory();
  const { page, tab, sort_by, direction, ...query } = qs.parse(
    history.location.search
  );

  const handleChangeFilter = (filterName, values) => {
    const newFiltersValues = { ...activeFilters, [filterName]: values };
    setActiveFilters({ ...newFiltersValues });
    onFiltersChange({ ...newFiltersValues });
  };

  const handleClearAll = () => {
    setActiveFilters(initialFiltersValues);
    onFiltersChange(initialFiltersValues);
  };
  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        { ...searchQuery, query: term },
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);
  return (
    <FilterBar
      filterName="Filter suppliers"
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      withSearch
    />
  );
};

export default CategorySuppliersFilter;
