import React, { Fragment } from "react";
import PropTypes from "prop-types";
import s from "./UserItem.module.scss";

const UserItem = ({ icon: Icon, title }) => {
  return (
    <Fragment>
      {title && Icon && (
        <li className={s.item}>
          <Icon className={s.icon} />
          <p>{title}</p>
        </li>
      )}
    </Fragment>
  );
};

UserItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
};

export default UserItem;
