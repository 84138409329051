import React from "react";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import s from "./Breadcrumbs.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const Breadcrumbs = ({data = [], className}) => {
  const history = useHistory();
  const {t} = useTranslation();
  const length = data.length;

  return (
    <div className={classNames(s.wrapper, className)}>
      {data.map((item, index) => (
        <div key={index} className={s.container}>
          <div
            data-translate-key={!item.withoutTranslate && getTranslateKey(item.name)}
            className={classNames(s.item, {[s.active]: !item.disabled})}
            onClick={() => {
              if (!item.disabled && index !== length - 1) {
                history.push(item.path, item?.state ?? {});
              }
            }}
          >
            {item.withoutTranslate ? item.name : t(getTranslateKey(item.name))}
          </div>
          {index !== length - 1 ? <span>/</span> : null}
        </div>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string
};

Breadcrumbs.defaultProps = {
  data: [],
  className: PropTypes.string
};

export default Breadcrumbs;
