import React, {useEffect, useState} from "react";
import s from "./DashboardTasksContainer.module.scss";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import TasksFilter from "../../filters/TasksFilter/TasksFilter";
import TasksSelfTable from "../../tables/TasksSelfTable/TasksSelfTable";
import services from "../../../services";
import querystring from "query-string";
import {useHistory} from "react-router-dom";


const DashboardTasksContainer = () => {
  const [getFilters, setGetFilters] = useState([]);
  const [tasks, setTasks] = useState();
  const {t} = useTranslation();
  const history = useHistory();

  const {page, query, status_ids, project_name, sort_by, end_date, direction} = querystring.parse(
    history.location.search
  ) || {};

  useEffect(() => {
    services.taskServices
      .getTasks({page, query, sort_by, status_ids, project_name, end_date, direction})
      .then(res => {
        setTasks(res.data);
      });
  }, [history.location]);

  useEffect(() => {
    services.filterService.getFilterTasks().then(res => {
      setGetFilters(res.data);
    });

  }, []);


  return (
    <section className={s.listOfUsersContainer}>
      <div className={s.wrap}>
        <h1 className={s.title}
            data-translate-key={getTranslateKey("Tasks")}>
          {t(getTranslateKey("Tasks"))}
        </h1>
      </div>
      <TasksFilter
        className={s.filterBar}
        filters={getFilters}
      />
      <div className={s.tableBlock}>
        {tasks && <TasksSelfTable tasks={tasks} perPage={tasks.per_page}/>}
      </div>
    </section>

  );
};

export default DashboardTasksContainer;