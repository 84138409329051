import React from "react";
import InfoBlock from "./InfoBlock/InfoBlock";
import ContactForm from "../../forms/ContactForm/ContactForm";
import s from "./ContactUs.module.scss";

const ContactUs = ({ businessEmail }) => {
  return (
    <section id="contactUs" className={s.contactUs}>
      <div className={s.wrapInfoBlock}>
        <InfoBlock />
      </div>
      <div className={s.wrapFormBlock}>
        <div className={s.formBlock}>
          <ContactForm businessEmail={businessEmail} />
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
