import React, {useEffect, useState} from "react";
import s from "./EditRequestContainer.module.scss";
import RequestForm from "../../forms/RequestForm/RequestForm";
import {useParams} from "react-router-dom";
import services from "../../../services";
import {setRequestTypes} from "../../forms/RequestForm/helpers/setProjectTypes";
import {baseSteps} from "../../forms/RequestForm/helpers/baseSteps";
import {setNewSteps} from "../../forms/RequestForm/helpers/setNewSteps";
import {generateUniqueId} from "../../../helpers/uniqueId";
import {object, string} from "yup";

const EditRequestContainer = () => {
  const {id} = useParams();
  const [defaultRequest, setDefaultRequest] = useState(null);
  const [defaultSteps, setDefaultSteps] = useState([]);
  const [initial, setInitial] = useState(true);

  const getFieldType = field => {
    if (field.field_type === "Type of Spend") return "type_of_spend";
    if (field.field_type === "Category") return "category";
  };

  useEffect(() => {
    services.requestTypeServices.getRequestType(id).then(res => {
      setDefaultRequest(res.data);

      services.requestTypeServices.getRequestTypes().then(types => {
        if (types.data) {
          const preparedSteps = setRequestTypes([
            {
              ...baseSteps[0], fields: baseSteps[0].fields.map(field => {
                if (field.key === "name") return {...field, value: {...field.value, value: res.data.name}};
                return field;
              })
            },
            {
              ...baseSteps[1], fields: baseSteps[1].fields.map(field => {
                if (field.key === "description") return {
                  ...field,
                  value: {...field.value, value: res.data.description}
                };
                if (field.key === "attachments") return {
                  ...field,
                  value: res.data.attachments
                };
                return field;
              })
            },
          ], types.data, res.data);

          services.requestTypeServices.getRequestTypesSteps(res.data.request_type)
            .then(step => {
              if (step.data) {
                const preparedFields = res.data.fields.filter(field => {
                  const type = field.step_field.field_type;
                  if (type === "Type of Spend") return true;
                  return type === "Category";
                }).map(field => ({id: field.value.id, type: getFieldType(field.step_field)}));

                Promise.all([...preparedFields
                  .map(f => services.additionalQuestions.getAdditionalQuestions(f.type, f.id)
                    .then(res => res.data))]).then(values => {
                  setDefaultSteps([...setNewSteps(preparedSteps, step.data, res.data), {
                    fields: values.flat(2).map(v => {
                      const answer = res.data.answers
                        .find(a => a.answer.question.question_id === v.question_id)?.answer?.procurement_answer;

                      return {
                        key: v.question_id,
                        type: "additional_question",
                        question: v,
                        value: {
                          question_id: v.question_id,
                          value: answer
                        } || "",
                        required: v.is_required ? object({
                          value: string().required("Field is required")
                        }) : "",
                      };
                    }),
                    name: "Additional questions",
                    id: generateUniqueId(),
                  }]);
                  setInitial(false);
                });
              }
            });
        }
      });
    });
  }, []);

  if (initial) return "";

  return (
    <div className={s.wrap}>
      <h2 className={s.title}>Edit Request</h2>
      <RequestForm
        defaultRequest={defaultRequest}
        defaultSteps={defaultSteps}
      />
    </div>
  );
};
export default EditRequestContainer;


