import React, { useEffect, useRef, useState } from "react"
import styles from "./GanttChart.module.scss"
import moment from "moment"
import { Draggable } from "react-beautiful-dnd"
import GanttChartLIne from "../GanttChartLIne/GanttChartLIne"
import { monthsShort } from "../../helpers/helpers"
import services from "services"

const GanttChart = ({ projects, ownerName, months = [] }) => {
  const wrapperRef = useRef()
  const [dayInPixels, setDayInPixels] = useState(0)
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [stages, setStages] = useState([])

  const startChartDate = moment()
    .startOf("month")
    .toDate()
  const endChartDate = moment(startChartDate)
    .add(months.length - 1, "M")
    .endOf("month")
    .toDate()
  const daysBetweenDates =
    moment(endChartDate).diff(moment(startChartDate), "days") + 1

  useEffect(() => {
    if (!dayInPixels && wrapperRef.current) {
      setDayInPixels(wrapperRef.current.offsetWidth / daysBetweenDates)
      setWrapperWidth(wrapperRef.current.offsetWidth)
    }
  }, [wrapperRef, dayInPixels, projects])

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        setWrapperWidth(wrapperRef.current.offsetWidth)
        setDayInPixels(wrapperRef.current.offsetWidth / daysBetweenDates)
      }
    }

    window.addEventListener("resize", handleResize)

    return _ => {
      window.removeEventListener("resize", handleResize)
    }
  })
  useEffect(() => {
    services.projectServices
      .getStages()
      .then(res => setStages(res?.data?.stages || []))
  }, [])

  return (
    <div className={styles.chartWrapper} ref={wrapperRef}>
      <p className={styles.ownerName}>{ownerName}</p>
      <ul className={styles.chartValues}>
        {months.map(month => (
          <div key={month.month}>
            <li
              style={{
                ["width"]: wrapperWidth
                  ? `${wrapperWidth / months.length}px`
                  : null
              }}
            >
              {`${monthsShort[month.month]} (${month.value}%)`}
            </li>
            <span
              style={{
                ["height"]: wrapperRef.current
                  ? `${wrapperRef.current.offsetHeight - 94}px`
                  : null
              }}
            ></span>
          </div>
        ))}
      </ul>

      <ul className={styles.chartBars}>
        {projects.map((project, index) => (
          <Draggable key={project?.id} draggableId={project?.id} index={index}>
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <GanttChartLIne
                  project={project}
                  dayInPixels={dayInPixels}
                  wrapperWidth={wrapperWidth}
                  startChartDate={startChartDate}
                  stages={stages}
                />
              </div>
            )}
          </Draggable>
        ))}
      </ul>
    </div>
  )
}

export default GanttChart
