import React, {useEffect, useState} from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import FilterBarDropdownYears from "./FilterBarDropdownYears/FilterBarDropdownYears";
import PropTypes from "prop-types";
import services from "../../../services";

const initialFiltersValues = {
  categories: [],
  spend_year: "",
};

const CategoriesFilter = (
  {
    onFiltersChange,
    filters,
    className,
    afterFilterChangeAction,
    categoryIdsFilter
  }
) => {

  const [activeFilters, setActiveFilters] = useState(initialFiltersValues);
  const history = useHistory();
  const [savedFilter, setSavedFilter] = useState(null);

  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  useEffect(() => {
    services.filterService.getFilter("categories").then(res => {
      if (res.data && res.data[0]) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      }
    });
  }, []);

  const previousYear = new Date().getFullYear() - 1;

  const handleChangeFilter = (filterName, values) => {
    const newFiltersValues = {...activeFilters, [filterName]: values};
    setActiveFilters({...newFiltersValues});
    onFiltersChange(filterName, values);
  };

  const handleClearAll = () => {
    const searchQuery = {spend_year: previousYear};
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"}),
    });
  };

  const saveDefaultFilter = () => {
    services.filterService.addFilter("categories", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  }

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  }

  const showDefaultFilter = filterParams => {
    const preparedFilter = savedFilter?.filter_params || "{}";
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams || preparedFilter),
      page: 1,
      tab,
      sort_by,
      direction,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  }

  return (
    <FilterBar
      filterName="Filter categories"
      handleClearAll={handleClearAll}
      className={className}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      savedFilter={savedFilter}
    >
      <FilterBarDropdownYears
        name="Years"
        options={filters.spend_years}
        defaultValues={query?.spend_year?.split(",")}
        handleChange={(values) => {
          handleChangeFilter("spend_year", values);
        }}
      />
      <FilterBarDropdown
        name="Categories"
        searchPlaceholder="Search category"
        defaultValues={categoryIdsFilter?.split(",")}
        options={filters.categories}
        afterClickAction={() => afterFilterChangeAction()}
        customQueryParams={categoryIdsFilter?.split(",")}
        handleChange={(values) => {
          handleChangeFilter("category_ids", values);
        }}
      />
    </FilterBar>
  );
};

export default CategoriesFilter;

CategoriesFilter.propTypes = {
  onFiltersChange: PropTypes.func,
  filters: PropTypes.any,
  className: PropTypes.string,
  afterFilterChangeAction: PropTypes.func
};