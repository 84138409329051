export const questionsType = [
  {
    value: "text_field",
    label: "Open answer",
  },
  {
    value: "radio_buttons",
    label: "Single choice",
  },
  {
    value: "yes_no",
    label: "Yes/No",
  },
]