import React, {useState} from "react";
import s from "./DropDown.module.scss";
import down from "../../../.././../../../assets/icons/arrow-tab-down.svg"
import up from "../../../.././../../../assets/icons/arrow-tab-up.svg"
import classNames from "classnames";
import {trimRest} from "../../../../../../../helpers/stringHelper";
import {getTranslateKey} from "../../../../../../../helpers/getTranslateKey";

const DropDown = ({children, data}) => {
  const [isActive, setActive] = useState(false);

  const getStatus = (status) => {
    if (status && (status === 'new' || status === 'completed')) {
      return (
        <span className={classNames(s.status, s[status])} data-translate-key={getTranslateKey(status)}>
          {status}
        </span>
      )
    }
  }

  const renderContent = () => {
    if (isActive) return (
      <div className={s.content}>
        {children}
      </div>
    )
  }

  return (
    <div className={s.dropDown}>
      <div className={s.header}>
        <div>
          <p className={s.title}>{trimRest(20, data.name)}</p>
          {getStatus(data.status)}
        </div>
        <button onClick={() => setActive(!isActive)}>
          <img src={isActive ? up : down} alt=""/>
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

export default DropDown;


