import React, { useState, useCallback, useEffect } from "react";
import { Question } from "../Question/Question";
import classNames from "classnames";
import styles from "../EditQuestionsPage/EditQuestionsPage.module.scss";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import { QuestionContainer } from "../QuestionContainer";

export const QuestionPage = ({ step, setNewStep, id, saveSteps, setTempSteps, setIsSaved }) => {
  const [tempQuestions, setTempQuestions] = useState([]);
  const [currentQuest, setCurrentQuest] = useState(null);
  const [draggingOver, setDraggingOver] = useState(false);
  const [arrowArr, setArrowArr] = useState([]);

  const addNewStep = () =>
    setNewStep((p) => ({ checked: !p.checked, action: "Create", subAction: "temp" }));

  useEffect(() => {
    setTempQuestions(step?.questions || []);
  }, [step]);

  useEffect(() => {
    if (saveSteps) {
      setTempSteps((prev) =>
        prev.map((s) => (s.id === step.id ? { ...s, questions: tempQuestions } : s)),
      );
      setIsSaved(true);
    }
  }, [saveSteps]);

  const childSetDraggingOver = useCallback(
    (dragging) => {
      if (draggingOver !== dragging) {
        setDraggingOver((p) => !p);
      }
    },
    [draggingOver, setDraggingOver],
  );

  const dragStartHandler = (quest) => {
    setCurrentQuest(quest);
  };

  const addNew = (option) => {
    if (option.label === "Add new step") {
      addNewStep();
    } else {
      setTempQuestions((prev) => [
        ...prev,
        {
          pos: tempQuestions.length + 1,
          answers: [],
        },
      ]);
    }
  };

  const dropHandler = (e, quest) => {
    e.preventDefault();
    setTempQuestions((prevTempQ) =>
      prevTempQ.map((q) => {
        if (q.pos === quest.pos) {
          return { ...currentQuest, pos: quest.pos };
        }
        if (q.pos === currentQuest.pos) {
          return { ...quest, pos: currentQuest.pos };
        }
        return q;
      }),
    );
    setCurrentQuest(null);
  };

  return (
    <>
      <section className={styles.secWrapper}>
        <section>
          <div className={styles.stepName}>{`Step ${id + 1} - ` + step.name}</div>
          <label className={styles.stepDescription}>{step.description}</label>
        </section>
      </section>
      {tempQuestions
        .sort((a, b) => a.pos - b.pos)
        .map((quest) => (
          <section
            style={{ opacity: currentQuest?.pos === quest.pos && "0.4" }}
            draggable={draggingOver}
            onDragStart={() => dragStartHandler(quest)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => dropHandler(e, quest)}
            className={classNames(styles.detailsWrapper, {
              [styles.opened]: !!arrowArr.find((p) => p.pos === quest.pos)?.arrowChecked,
            })}>
            <section style={{ position: "relative" }}>
              {!arrowArr.find((p) => p.pos === quest.pos)?.arrowChecked && (
                <QuestionContainer
                  arrowArr={arrowArr}
                  setArrowArr={setArrowArr}
                  quest={quest}
                  childSetDraggingOver={childSetDraggingOver}
                  setTempQuestions={setTempQuestions}
                  tempQuestions={tempQuestions}
                />
              )}
              {!!arrowArr.find((p) => p.pos === quest.pos)?.arrowChecked && (
                <Question quest={quest} setArrowArr={setArrowArr} arrowArr={arrowArr} />
              )}
            </section>
          </section>
        ))}
      <section className={styles.addNewSec}>
        <CustomDropdown
          className={classNames(styles.addNewSec, { [styles.dropdown]: true })}
          options={[{ label: "Add new step" }, { label: "Add new question" }]}
          onChange={(option) => addNew(option)}
          placeholder="+ Add new"
        />
      </section>
    </>
  );
};
