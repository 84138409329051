import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import { useHistory } from "react-router-dom";
import querystring from "query-string";

const CategoriesStakeholdersTable = ({
  data,
  totalCount,
  perPage,
  onAdd,
  onDelete,
  permissions,
}) => {
  const history = useHistory();
  const currentPage = querystring.parse(history.location.search).page || 1;
  const query = querystring.parse(history.location.search);

  const onPageChange = (page) => {
    history.push({ search: querystring.stringify({ ...query, page }) });
  };

  const onSort = ({ sort_by, direction, tag }) => {
    history.push({
      search: querystring.stringify({
        ...query,
        sort_by,
        direction,
        page: 1,
        tag
      })
    })
  };

  return (
    <MainTable
      data={data}
      dataTypes={[
        {
          title: "Name",
          value: ({ name }) => name,
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "name", direction })
          },
          onClick: (id) => history.push(`/users/${id}`),
          sticky: true
        },
        {
          title: "Title",
          value: ({ title }) => title,
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "title", direction });
          },
        },

        {
          title: "Division",
          value: ({ division }) => division.name,
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "division", direction });
          },
        },
        {
          title: "Department",
          value: ({ department }) => department.name,
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "department", direction });
          },
        },
        {
          title: "Role",
          value: ({ user_type }) =>
            user_type
              ? user_type.charAt(0).toUpperCase() +
              user_type.slice(1).replace("_", " ")
              : "-",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "user_type", direction });
          },
        },
      ]}
      button={
        permissions.update && {
          name: "Add stakeholder",
          onClick: () => onAdd(),
        }
      }
      perPage={perPage}
      options={(item) => [
        permissions.destroy && {
          title: "Delete",
          onClick: () => onDelete(item),
        },
      ]}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};
export default CategoriesStakeholdersTable;
