export const getCategoryPath = (arr, itemId) => {
  const result = [];
  arr.forEach((element) => {
    if (element.id === itemId) {
      result.push(element);
      return result;
    } else {
      if (element.categories) {
        const data = getCategoryPath(element.categories, itemId);
        if (data.length) result.push(element, ...data);
      }
    }
  });
  return result;
};
