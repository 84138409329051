import React, {useState} from "react";
import sBase from "../MainDashboard.module.scss";
import {TableCell, TableRow} from "@material-ui/core";
import Notice from "../Notice/Notice";
import LogoSuppliers from "../../../../../elements/LogoSuppliers/LogoSuppliers";
import s from "./Suppliers.module.scss"

const SingleSupplier = ({data}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <TableRow className={sBase.tableRow} onMouseLeave={() => setOpen(false)}>
      <TableCell className={sBase.tableCell}>
        <div className={s.logoSubSupplier}>
          <LogoSuppliers name={data?.name || "-"} logo={data?.logo || "-"}/>
          <a href={`/suppliers/${data.id}`}>{data.name}</a>
        </div>
      </TableCell>
      <TableCell
        className={sBase.tableCell}>{`$ ${data.spend.toLocaleString("en-US", {maximumFractionDigits: 0})}`}</TableCell>
      <TableCell className={sBase.tableCell}>{data.active_projects}</TableCell>
      <TableCell>
        <Notice
          id={data.id}
          fetchBy={"supplier"}
          isOpen={isOpen}
          onOpen={() => setOpen(!isOpen)}
        />
      </TableCell>
    </TableRow>
  );
};

export default SingleSupplier;


