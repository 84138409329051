import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./StatusChanger.module.scss";
import {ReactComponent as ArrowDown} from "../../../assets/icons/arrowDown.svg";
import services from "../../../services";
import {trimRest} from "../../../helpers/stringHelper";
import {backgroundTypes} from "../../../helpers/helpData";
import useOutsideClicker from "../../../hooks/useOutsideClicker";

const StatusChanger = ({status, onChange, customStatuses, autoIndex = false}) => {
  const [isOpen, setOpen] = useState();
  const [taskStatuses, setTaskStatuses] = useState(customStatuses || []);
  const statusChangerRef = useRef();

  useOutsideClicker(statusChangerRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (!customStatuses) {
      services.taskServices.getTaskStatuses().then(res => {
        if (res.data) {
          setTaskStatuses(res.data.task_statuses.map(status => {
            return {
              id: status.id,
              label: status.name,
              color: status.color,
            };
          }));
        }
      });
    }
  }, []);

  return (
    <div
      ref={statusChangerRef}
      className={classNames(s.status, s[status], {[s.autoIndex]: autoIndex})}
      style={{
        background: backgroundTypes[status.color],
        cursor: onChange ? "pointer" : "unset"
      }}
      onClick={e => {
        e.stopPropagation();
        onChange && setOpen(!isOpen);
      }}
    >
      {status.name.replace("_", " ").replace("-", " ")}
      {
        onChange && (
          <ArrowDown className={classNames(s.arrow, {
            [s.opened]: isOpen
          })}/>
        )
      }
      {
        isOpen && (
          <div className={s.statusList}>
            {taskStatuses.map(status => (
              <div
                key={status.id}
                className={s.statusWrap}
                onClick={() => onChange(status)}>
                <div
                  className={s.status}
                  title={status.label}
                  style={{
                    background: backgroundTypes[status.color],
                  }}
                >
                  {trimRest(12, status.label)}
                </div>
              </div>
            ))}
          </div>
        )
      }
    </div>
  );
};

StatusChanger.propTypes = {
  status: PropTypes.string,
  onChange: PropTypes.func,
  customStatuses: PropTypes.array,
  autoIndex: PropTypes.bool
};

export default StatusChanger;
