import React from "react";
import sBase from "../MainDashboard.module.scss";
import {TableCell, TableRow} from "@material-ui/core";
import s from "./Contracts.module.scss";
import {formatDate} from "../../../../../../helpers/dateFormat";

const SingleContracts = ({contract}) => {
  return (
    <TableRow className={sBase.tableRow}>
      <TableCell className={sBase.tableCell}>
        <div className={s.contractName}>
          <a href={`/contract/details/${contract.id}`}>{contract.name}</a>
        </div>
      </TableCell>
      <TableCell className={sBase.tableCell}>
        {formatDate(contract.end_date)}
      </TableCell>
      <TableCell className={sBase.tableCell}>
        {contract.currency}
      </TableCell>
      <TableCell className={sBase.tableCell}>
        {`$ ${contract.annual_contract_value.toLocaleString("en-US", {maximumFractionDigits: 0})}`}
      </TableCell>
      <TableCell className={sBase.tableCell}>
        {`$ ${contract.total_annual_contract_value.toLocaleString("en-US", {maximumFractionDigits: 0})}`}
      </TableCell>
    </TableRow>
  );
};

export default SingleContracts;


