import React from "react";
import classNames from "classnames";
import s from "./EditCompanyGoalForm.module.scss";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import {useFormik} from "formik";
import {object, string} from "yup";
import PropTypes from "prop-types";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const EditCompanyGoalForm = ({onSubmit, onClose, defaultValues, validationData}) => {
  const parentData = validationData?.parentData;
  const generalData = validationData?.generalData;
  const childData = validationData?.childData;
  const formatNum = num => num.toLocaleString("en-US", {maximumFractionDigits: 0});
  const {t} = useTranslation();

  const defValues = {
    annual_goal: String(defaultValues.annual_goal),
    quarter1: String(defaultValues.quarter1),
    quarter2: String(defaultValues.quarter2),
    quarter3: String(defaultValues.quarter3),
    quarter4: String(defaultValues.quarter4)
  };

  const formik = useFormik({
    initialValues: defaultValues
      ? defValues
      : {
        annual_goal: "",
        quarter1: "",
        quarter2: "",
        quarter3: "",
        quarter4: ""
      },
    validationSchema: object({
      annual_goal: string()
        .required("Annual goal is required")
        .max(11, "Annual goal is too long"),
      quarter1: string()
        .required("Quarter 1 is required")
        .max(11, "Annual goal is too long"),
      quarter2: string()
        .required("Quarter 2 is required")
        .max(11, "Annual goal is too long"),
      quarter3: string()
        .required("Quarter 3 is required")
        .max(11, "Annual goal is too long"),
      quarter4: string()
        .required("Quarter 4 is required")
        .max(11, "Annual goal is too long")
    }),
    validate: values => {
      const errors = {};
      if (parentData) {
        if (generalData.annual_goal + Number(values.annual_goal) > parentData.annual_goal) {
          errors.annual_goal = `Annual goal of all ${defaultValues.source}s must be less than or equal to the ${defaultValues.source === "department" ? "company" : "department"}’s annual goal`;
        }
        if (generalData.quarter1 + Number(values.quarter1) > parentData.quarter1) {
          errors.quarter1 = `Sum of Q1 of all ${defaultValues.source}s must be less than or equal to the ${defaultValues.source === "department" ? "company" : "department"}’s Q1 value`;
        }
        if (generalData.quarter2 + Number(values.quarter2) > parentData.quarter2) {
          errors.quarter2 = `Sum of Q2 of all ${defaultValues.source}s must be less than or equal to the ${defaultValues.source === "department" ? "company" : "department"}’s Q2 value`;
        }
        if (generalData.quarter3 + Number(values.quarter3) > parentData.quarter3) {
          errors.quarter3 = `Sum of Q3 of all ${defaultValues.source}s must be less than or equal to the ${defaultValues.source === "department" ? "company" : "department"}’s Q3 value`;
        }
        if (generalData.quarter4 + Number(values.quarter4) > parentData.quarter4) {
          errors.quarter4 = `Sum of Q4 of all ${defaultValues.source}s must be less than or equal to the ${defaultValues.source === "department" ? "company" : "department"}’s Q4 value`;
        }
      }
      if (childData) {
        if (Number(values.annual_goal) < childData.annual_goal) {
          errors.annual_goal = `Summary goal of ${defaultValues.source === "company" ? "departments" : "users"} must is more than ${defaultValues.source} annual goal`;
        }
        if (Number(values.quarter1) < childData.quarter1) {
          errors.quarter1 = `Summary quarter goal of ${defaultValues.source === "company" ? "departments" : "users"} must is more than ${defaultValues.source} first quarter`;
        }
        if (Number(values.quarter2) < childData.quarter2) {
          errors.quarter2 = `Summary quarter goal of ${defaultValues.source === "company" ? "departments" : "users"} must is more than ${defaultValues.source} second quarter`;
        }
        if (Number(values.quarter3) < childData.quarter3) {
          errors.quarter3 = `Summary quarter goal of ${defaultValues.source === "company" ? "departments" : "users"} must is more than ${defaultValues.source} third quarter`;
        }
        if (Number(values.quarter4) < childData.quarter4) {
          errors.quarter4 = `Summary quarter goal of ${defaultValues.source === "company" ? "departments" : "users"} must is more than ${defaultValues.source} fourth quarter`;
        }
      }
      return errors;
    },
    onSubmit: ({annual_goal, quarter1, quarter2, quarter3, quarter4}) => {
      const haveSomeMinusVals =
        annual_goal < 0 ||
        quarter1 < 0 ||
        quarter2 < 0 ||
        quarter3 < 0 ||
        quarter4 < 0;
      const quartersSum = +quarter1 + +quarter2 + +quarter3 + +quarter4;
      if (+annual_goal < quartersSum) {
        formik.setFieldError(
          "quarter4",
          "Sum of quarters should be less than or equal to Annual goal"
        );
      } else {
        if (haveSomeMinusVals) {
          formik.setFieldError(
            "quarter4",
            "Values should be equal to or greater than 0"
          );
        } else {
          onSubmit({
            annual_goal: +annual_goal,
            quarter1: +quarter1,
            quarter2: +quarter2,
            quarter3: +quarter3,
            quarter4: +quarter4
          });
        }
      }
    }
  });

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,

  } = formik;

  const difference =
    (+values.annual_goal || 0) -
    (+values.quarter1 || 0) -
    (+values.quarter2 || 0) -
    (+values.quarter3 || 0) -
    (+values.quarter4 || 0);

  return (
    <form
      className={s.form}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <Input
        className={classNames(s.field, {[s.withError]: errors.annual_goal})}
        type="text"
        isSeparateNumber
        label="Annual goal"
        name="annual_goal"
        value={values.annual_goal}
        placeholder="000,000"
        onChange={e => {
          const val = Number(e);
          !isNaN(val) && setFieldValue("annual_goal", val < 0 ? val * -1 : val);
        }}
        error={errors.annual_goal}
      />
      <Input
        className={classNames(s.field, {[s.withError]: errors.quarter1})}
        type="text"
        isSeparateNumber
        label="Q1"
        name="quarter1"
        value={values.quarter1}
        placeholder="000,000"
        onChange={e => {
          const val = Number(e);
          !isNaN(val) && setFieldValue("quarter1", val < 0 ? val * -1 : val);
        }}
        error={errors.quarter1}
      />
      <Input
        className={classNames(s.field, {[s.withError]: errors.quarter2})}
        type="text"
        isSeparateNumber
        label="Q2"
        name="quarter2"
        value={values.quarter2}
        placeholder="000,000"
        onChange={e => {
          const val = Number(e);
          !isNaN(val) && setFieldValue("quarter2", val < 0 ? val * -1 : val);
        }}
        error={errors.quarter2}
      />
      <Input
        className={classNames(s.field, {[s.withError]: errors.quarter3})}
        type="text"
        isSeparateNumber
        label="Q3"
        name="quarter3"
        value={values.quarter3}
        placeholder="000,000"
        onChange={e => {
          const val = Number(e);
          !isNaN(val) && setFieldValue("quarter3", val < 0 ? val * -1 : val);
        }}
        error={errors.quarter3}
      />
      <Input
        className={classNames(s.field, s.last, {
          [s.withError]: errors.quarter4
        })}
        type="text"
        isSeparateNumber
        label="Q4"
        name="quarter4"
        value={values.quarter4}
        placeholder="000,000"
        onChange={e => {
          const val = Number(e);
          !isNaN(val) && setFieldValue("quarter4", val < 0 ? val * -1 : val);
        }}
        error={errors.quarter4}
      />
      {difference > 0 && !errors.quarter4 && (
        <span className={s.message}>
          <span data-translate-key={getTranslateKey("You have $")}>
            {t(getTranslateKey("You have"))}
          </span>
          {" $"}
          {formatNum(difference)}
          {" "}
          <span data-translate-key={getTranslateKey("left")}>
            {t(getTranslateKey("left"))}
          </span>
        </span>
      )}

      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Save
        </Button>
      </div>
    </form>
  );
};

export default EditCompanyGoalForm;

EditCompanyGoalForm.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  defaultValues: PropTypes.any,
  validationData: PropTypes.any,
};