import React, {useEffect, useState} from "react";
import s from "./General.module.scss";
import services from "../../../../../services";
import Button from "../../../../elements/Button/Button";
import {Form, Formik} from "formik";
import NotificationBox from "../../../../elements/NotificationBox/NotificationBox";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const General = () => {
  const [categoryLevels, setCategoryLevels] = useState(null);
  const [alert, setAlert] = useState({message: "", type: ""});
  const {t} = useTranslation();

  useEffect(() => {
    services.savingsGeneralServices.getSavingsSettings()
      .then(res => {
        setCategoryLevels(res.data);
      });
  }, []);
  if (!categoryLevels) return "";
  return (
    <div>
      <Formik
        initialValues={{
          ...categoryLevels,
        }}
        onSubmit={(values, { resetForm } ) => {
          services.savingsGeneralServices.putSavingsSettings(values)
            .then(res => {
            setAlert({
              type: "success",
              message: "Changes have been successfully saved."
            });
              resetForm({ values: res.data })
          });
        }}
      >
        {({values, dirty, handleChange}) => (
          <Form>
            <div className={s.categoryLevels}>
              <div className={s.categoryLevelsItem} role="group" aria-labelledby="checkbox-group">
                {
                  Object.entries(values).map(([key, value], index) => {
                    return (
                      <label className={s.categoryLevelsLabel}>
                        <input type="checkbox" name={key} checked={value} onChange={handleChange}/>
                        <span className={s.inputSpan} data-translate-key={getTranslateKey("Show actual savings in projects")}>
                          {t(getTranslateKey("Show actual savings in projects"))}
                        </span>
                      </label>
                    );
                  })
                }
              </div>
            </div>
            <Button
              type="submit"
              styled="primary"
              children="Save"
              disabled={!dirty}
              className="popupBtn"
            />
          </Form>
        )}
      </Formik>
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </div>
  );
};
export default General;