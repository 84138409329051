import React, { useState } from "react";
import { ReactComponent as Drag } from "assets/icons/drag-icon.svg";
import { Details } from "./Details/Details";
import { Answers } from "./Answers/Answers";
import { Arrow } from "./Arrow/Arrow";
import styles from "../components/EditQuestionsPage/EditQuestionsPage.module.scss";
import classNames from "classnames";

export const QuestionContainer = ({
  arrowArr,
  setArrowArr,
  quest,
  childSetDraggingOver,
  setTempQuestions,
  tempQuestions,
}) => {
  const [details, setDetails] = useState("Details");
  return (
    <>
      <div className={styles.tabs}>
        <div
          className={classNames(styles.tab, { [styles.active]: details === "Details" })}
          onClick={() => setDetails("Details")}>
          Details
        </div>
        <div
          className={classNames(styles.tab, { [styles.active]: details === "Answers" })}
          onClick={() => setDetails("Answers")}>
          Answers
        </div>
      </div>
      <Arrow arrowArr={arrowArr} setArrowArr={setArrowArr} quest={quest} />
      <Drag
        onMouseMove={() => childSetDraggingOver(true)}
        onMouseOut={() => childSetDraggingOver(false)}
        height={15}
        width={20}
        style={{
          position: "absolute",
          top: "10px",
          left: "50%",
          cursor: "grab",
        }}
      />
      {details === "Details" && (
        <Details
          quest={quest}
          setTempQuestions={setTempQuestions}
          childSetDraggingOver={childSetDraggingOver}
        />
      )}
      {details === "Answers" && (
        <Answers quest={quest} setTempQuestions={setTempQuestions} tempQuestions={tempQuestions} />
      )}
    </>
  );
};
