export const recursiveJoin = (entities, child) => {
  let preparedArray = [];

  const recursiveWalk = (entities, child) => {
    entities && entities.forEach(e => {
      preparedArray.push(e);
      if (e[child]) {
        recursiveWalk(e[child], child);
      }
    });
  };

  recursiveWalk(entities, child);
  return preparedArray;
};
