import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import querystring from "query-string";
import s from "./SuppliersContainer.module.scss";
import SuppliersTable from "components/tables/SuppliersTable/SuppliersTable";
import services from "services/index";
import {
  formatFilters
} from "components/elements/FilterBar/helpers/helpers";
import SuppliersFilter from "components/filters/SuppliersFilter/SuppliersFilter";
import EditSupplierPopup from "components/popups/EditSupplierPopup/EditSupplierPopup";
import DeleteSupplierPopup from "components/popups/DeleteSupplierPopup/DeleteSupplierPopup";
import TableLoading from "components/loadingStates/Table/Table";
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar";
import AssignToProjectPopup from "components/popups/AssignToProjectPopup/AssignToProjectPopup";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import SuccessPopup from "components/popups/SuccessPopup/SuccessPopup";
import {
  setGlobalEmptyProject,
  setGlobalProjectMessage
} from "actions/globalProject";
import {useDispatch, useSelector} from "react-redux";
import EmptyBlock from "../../elements/EmptyBlock/EmptyBlock";
import Popup from "../../popups/Popup/Popup";
import {
  closePopup
} from "../CategoryDetailContainer/components/CategoryInfo/components/Suppliers/helpers/actions";
import CategorySupplierChangeStatusForm
  from "../../forms/CategorySupplierChangeStatusForm/CategorySupplierChangeStatusForm";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import qs from "query-string";
import {useDidUpdate} from "../../../hooks/useDidUpdate";

const SuppliersContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState([]);
  const [popup, setPopup] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [currentSupplier, setCurrentSupplier] = useState({});
  const [alert, setAlert] = useState({message: "", type: ""});
  const [projectNameToAssign, setProjectNameToAssign] = useState(null);
  const [savedFilter, setSavedFilter] = useState(null);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector(state => state.globalProject.data);
  const {page, direction, sort_by, query, tag, statuses, category_ids} = querystring.parse(
    history.location.search
  ) || {};

  const load = filter => {
    const params = {
      page,
      direction: location.search ? direction : "desc",
      sort_by: location.search ? sort_by : "spend",
      tag,
      statuses,
      category_ids,
      query
    };

    if (filter) {
      Object.assign(params, JSON.parse(filter ?? savedFilter.filter_params));
    }

    services.supplierServices
      .getSuppliers(params)
      .then(res => {
        setSuppliers(res.data.suppliers);
        setTotal(res.data.suppliers_count);
        setPermissions(res.data.permissions);
        setLoading(false);
      });
  };

  useEffect(() => {
    services.filterService.getFilter("suppliers").then(res => {
      load(res.data[0]?.filter_params || "{}");
      if (res.data && res.data[0]) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      }
    });
  }, []);

  useDidUpdate(() => {
    if (!loading) load();
  }, [history.location]);

  useEffect(() => {
    services.supplierServices.getSuppliersFilters().then(res => {
      const formattedStatuses = res.data.statuses.map(status => ({
        id: status,
        name: status.charAt(0).toUpperCase() + status.slice(1).replace("_", " ")
      }));
      setFilters(
        formatFilters({
          categories: res.data.categories,
          status: formattedStatuses
        })
      );
    });
  }, []);

  const pageChangeHandler = page => {
    const query = querystring.parse(location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  const editHandler = data => {
    services.supplierServices
      .editSupplier(currentSupplier.id, data)
      .then(res => {
        setSuppliers(
          suppliers.map(supplier =>
            supplier.id === currentSupplier.id ? res.data : supplier
          )
        );
      });
  };

  const changeStatusHandler = values => {
    services.supplierServices.changeStatusCategorySupplier(currentSupplier.id, {status: values}).then(() => {
      setSuppliers(suppliers.map(s => {
        return {
          ...s,
          category_suppliers: s.category_suppliers.map(cs => {
            if (cs.id === currentSupplier.id) {
              return {
                ...cs,
                status: values
              };
            }
            return cs;
          }),
        };
      }));
    });
  };

  const onDeleteTag = async (tagId, supplierId) => {
    await services.tagsServices
      .deleteSupplierTag(supplierId, tagId)
      .then(() => {
        if (tag && tag.length > 0) {
          setSuppliers(state =>
            state.filter(supplier => supplier.id !== supplierId)
          );
          setTotal(state => state - 1);
        }
      });
  };

  const onAssignTag = async (tagId, supplierId) =>
    await services.tagsServices.assignSupplierTag(supplierId, tagId);

  const deleteHandler = () => {
    setPopup(null);
    let newSuppliers = suppliers.filter(supplier => supplier.id !== currentSupplier.id);
    if (newSuppliers.length === suppliers.length) {
      newSuppliers = suppliers.map(s => {
        return {
          ...s,
          category_suppliers: s.category_suppliers.filter(cs => cs.id !== currentSupplier.id)
        };
      });
      services.supplierServices.removeCategorySupplier(currentSupplier.id).then(() => {
        setSuppliers(newSuppliers);
        setTotal(total => total - 1);
      });
    } else {
      services.supplierServices.removeSupplier(currentSupplier.id).then(() => {
        setSuppliers(newSuppliers);
        setTotal(total => total - 1);
      });
    }
  };

  const handleAssignSupplier = data => {
    if (data.project) {
      setProjectNameToAssign(data.project.name);
      services.supplierServices
        .addProjectSupplier(data.project.id, {
          supplier_id: currentSupplier.id
        })
        .then(() => {
          setPopup("success");
        })
        .catch(err => {
          setPopup(null);
          setAlert({
            message: err.response.data.error,
            type: "error"
          });
        });
    } else if (data.name) {
      if (project === null) {
        dispatch(setGlobalEmptyProject(data.name));
        setPopup(null);
        dispatch(
          setGlobalProjectMessage({
            type: "success",
            text: "Project was created."
          })
        );
      } else {
        setPopup(null);
        dispatch(
          setGlobalProjectMessage({
            type: "error",
            text: "Project has already been created."
          })
        );
      }
    }
  };

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams ?? savedFilter.filter_params),
      page: 1,
      tag,
      sort_by,
      direction,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const getTable = (isLoad, suppliersList) => {
    if (isLoad) return <TableLoading length={7}/>;
    if (suppliersList.length) {
      return (
        <SuppliersTable
          data={suppliers}
          perPage={50}
          totalCount={total}
          permissions={permissions}
          onPageChange={pageChangeHandler}
          currentPage={currentPage}
          onEdit={id => {
            setCurrentSupplier(suppliers.find(supplier => supplier.id === id));
            setPopup("edit");
          }}
          onDelete={item => {
            setCurrentSupplier(item);
            setPopup("delete");
          }}
          onAssign={item => {
            setCurrentSupplier(item);
            setPopup("assign");
          }}
          onChangeStatus={item => {
            setCurrentSupplier(item);
            setPopup("change");
          }}
          onDeleteTag={onDeleteTag}
          onAssignTag={onAssignTag}
        />
      );
    } else {
      return (
        <EmptyBlock withBtn={false}>
          {t(getTranslateKey("there-are-no-suppliers-according-to-your-search."))}
        </EmptyBlock>
      );
    }
  };

  return (
    <section className={s.container}>
      <h3 className={s.title} data-translate-key={getTranslateKey("Suppliers")}>
        {t(getTranslateKey("Suppliers"))}
      </h3>
      {!Array.isArray(filters) && Object.keys(filters).length ? (
        <SuppliersFilter
          filters={filters}
          className={s.filter}
          showDefaultFilter={showDefaultFilter}
          setSavedFilter={setSavedFilter}
          savedFilter={savedFilter}
        />
      ) : (
        <FilterBarLoading className={s.filter}/>
      )}
      {getTable(loading, suppliers)}
      {popup === "delete" && (
        <DeleteSupplierPopup
          onClose={() => setPopup(null)}
          onDelete={deleteHandler}
        />
      )}
      {popup === "edit" && (
        <EditSupplierPopup
          onSubmit={editHandler}
          onClose={() => setPopup(null)}
          data={currentSupplier}
        />
      )}
      {popup === "assign" && (
        <AssignToProjectPopup
          onClose={() => setPopup(null)}
          title="Add supplier to project"
          onSubmit={handleAssignSupplier}
        />
      )}
      {popup === "success" && (
        <SuccessPopup
          onClose={() => setPopup(null)}
          message={`Supplier was added to the project ${projectNameToAssign}`}
        />
      )}
      {popup === "change" && (
        <Popup
          onClose={() => dispatch(closePopup())}
          title="Change status"
          text="Change supplier status"
        >
          <CategorySupplierChangeStatusForm
            status={currentSupplier.status}
            onSubmit={values => changeStatusHandler(values)}
            onClose={() => setPopup(null)}
          />
        </Popup>
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </section>
  );
};
export default SuppliersContainer;
