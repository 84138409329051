import React from "react";
import SidebarNavigationItem from "../SidebarNavigationItem/SidebarNavigationItem";
import s from "./SidebarNavigation.module.scss";
import LogoCompany from "../LogoCompany";
import CurrentVersion from "../../../../../../elements/CurrentVersion/CurrentVersion";
import classNames from "classnames";

const SidebarNavigation = ({sidebarViewData, width, setSize, childSetDragStop}) => {
  return (
    <>
      <LogoCompany width={width}/>
      <ul className={classNames(s.sidebarNavigation, {[s.position]: width > 100})}>
        {sidebarViewData.map(
          (item, index) =>
            item && (
                <SidebarNavigationItem
                setSize={setSize}
                childSetDragStop={childSetDragStop}
                width={width}
                key={index}
                index={index}
                {...item}
              />
            )
        )}
      </ul>
      <CurrentVersion/>
    </>
  );
};

export default SidebarNavigation;
