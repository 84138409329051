import React, {useState} from "react";
import s from "./Approval.module.scss";
import PropTypes from "prop-types";
import {ReactComponent as GreenFlag} from "assets/icons/green-flag.svg";
import {ReactComponent as ClockIcon} from "assets/icons/clock.svg";
import {ReactComponent as GroupIcon} from "assets/icons/group.svg";
import {ReactComponent as CaseIcon} from "assets/icons/case.svg";
import {useHistory, useParams} from "react-router-dom";
import {ReactComponent as RedFlag} from "../../../../../../../../../assets/icons/red-flag.svg";
import {ReactComponent as OrangeFlag} from "../../../../../../../../../assets/icons/orange-flag.svg";
import DropListTrigger from "../../../../../../../../elements/DropListTrigger/DropListTrigger";
import {useSelector} from "react-redux";
import {userRoles} from "../../../../../../../../../helpers/userPermissions/userRoles";
import Status from "../../../../../../../../elements/Status/Status";
import Popup from "../../../../../../../../popups/Popup/Popup";
import ApproverForm from "../../../../../../../../forms/ApproverForm/ApproverForm";
import {capitalizeFirstLetter} from "../../../../../../../../../helpers/stringHelper";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../../../../helpers/getTranslateKey";

const Approval = (
  {
    data,
    milestoneId,
    ownerId,
    deleteApprovalStep,
    milestoneName,
    projectTypeId,
    projectName,
    sendAnswer,
    sendRequest
  }
) => {
  const {t} = useTranslation();
  const [isShowDeletePopUp, setShowDeletePopUp] = useState(false);
  const [isShowRequestApproval, setShowRequestApproval] = useState(false);
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);
  const [currentApprover, setCurrentApprover] = useState(null);
  const params = useParams();
  const history = useHistory();
  const user = useSelector(store => store.user);
  const {admin, cpo, procurement_admin} = userRoles;
  const highestRoles = [admin, cpo, procurement_admin];
  const isCanChange = user.id === ownerId || highestRoles.includes(user.roles);

  const renderPriorityFlag = () => {
    if (data.priority === "high") return <RedFlag/>;
    if (data.priority === "medium") return <OrangeFlag/>;
    return <GreenFlag/>;
  };

  const renderContextMenu = () => {
    let dropListMenu = [];

    if (isCanChange) {
      if (data.status === "not_requested") dropListMenu.push({
        title: "Request approval",
        onClick: () => setShowRequestApproval(true),
      });
      if (data.status === "rejected" || data.status === "requires_clarification") dropListMenu.push({
        title: "Request approval review",
        onClick: () => setShowRequestApproval(true),
      });
      if (data.permissions.update && data.status === "not_requested") dropListMenu.push({
        title: "Edit",
        onClick: () => history.push(`/projects/${params.id}/milestones/${milestoneId}/approvals/${data.id}`, {
          milestoneName,
          projectTypeId,
          projectName,
        }),
      });
      if (data.permissions.destroy) dropListMenu.push({
        title: "Delete",
        onClick: () => setShowDeletePopUp(true),
      });
    }

    if (dropListMenu.length) {
      return (
        <DropListTrigger
          data={dropListMenu}
          visibleMenu={visibleDropListMenu}
          setVisibleMenu={setVisibleDropListMenu}
        />
      );
    }
    return null;
  };

  const renderAnswer = () => {
    const actualApprover = data.atomic_approvers.find(atomicApprover => atomicApprover.approver.id === user.id);

    const answerBtn = (
      <div className={s.answerBtnWrap}>
        <button
          className={s.answerBtn}
          onClick={event => {
            event.stopPropagation();
            setCurrentApprover(actualApprover);
          }}
          data-translate-key={getTranslateKey("Answer")}
        >
          {t(getTranslateKey("Answer"))}
        </button>
      </div>
    );

    if (actualApprover && actualApprover.status === "pending" && data.status !== "not_requested") {

      if (data?.approval_group_type === "sequential") {
        const sortedApprovers = data.atomic_approvers.sort((a, b) => a.level - b.level);

        const position = sortedApprovers.map(atomicApprover => atomicApprover.id).indexOf(actualApprover.id);

        const canMakeAnswer = sortedApprovers
          .slice(0, position)
          .every(atomicApprover => atomicApprover.status === "approved");

        if (canMakeAnswer && !actualApprover.status_changed_at) return answerBtn;
      } else {
        return answerBtn;
      }
    }
  };

  return (
    <>
      <div
        className={s.approval}
        onClick={() => history.push(`/projects/${params.id}/milestones/${milestoneId}/approvals/${data.id}/detail`)}
      >
        <header>
          <Status status={data.status} withTranslate/>
          {renderContextMenu()}
        </header>
        <div className={s.infoBlock}>
          <h3>{capitalizeFirstLetter(data.name)}</h3>
          {renderPriorityFlag()}
        </div>
        <div className={s.infoBlock}>
          <ClockIcon/>
          <p>{capitalizeFirstLetter(data.request_type.replace("_", " "))}</p>
        </div>
        <div className={s.infoBlock}>
          <GroupIcon/>
          <p>{data.approval_group?.name ?? "-"}</p>
        </div>
        <div className={s.infoBlock}>
          <CaseIcon/>
          <p>{capitalizeFirstLetter(data.project_tab.replace("_", " "))}</p>
        </div>
        {renderAnswer()}
      </div>
      {isShowDeletePopUp && (
        <Popup
          title="Delete approval step"
          text="Are you sure you want to delete this approval step?"
          onClose={() => setShowDeletePopUp(false)}
          onClick={() => {
            deleteApprovalStep(data.id);
            setShowDeletePopUp(false);
          }}
          isDelete
          dialog
        />
      )}
      {isShowRequestApproval && (
        <Popup
          title="Request approval"
          text="Are you sure you want to request an approval?"
          onClose={() => setShowRequestApproval(false)}
          onClick={() => {
            sendRequest(milestoneId, data.id);
            setShowRequestApproval(false);
          }}
          successBtnTitle="Request"
          dialog
        />
      )}
      {
        currentApprover && (
          <Popup
            onClose={() => setCurrentApprover(null)}
            title="Approval step"
          >
            <ApproverForm
              onClose={() => setCurrentApprover(null)}
              onSubmit={values => {
                sendAnswer(data.id, currentApprover.id, values);
                setCurrentApprover(null);
              }}
            />
          </Popup>
        )
      }
    </>
  );
};

export default Approval;

Approval.propTypes = {
  data: PropTypes.any,
  milestoneId: PropTypes.string,
  ownerId: PropTypes.string,
  deleteApprovalStep: PropTypes.func,
  milestoneName: PropTypes.string,
  projectTypeId: PropTypes.string,
  projectName: PropTypes.string,
};