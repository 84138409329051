import React, {useState, useRef, useEffect} from 'react'
import classNames from 'classnames'
import s from './DropListTrigger.module.scss'
import {hasOverflowingChildren} from 'helpers/overflow'
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const DropList = ({data, setVisibleMenu, className, withOverflow, withScroll}) => {
  const menuRef = useRef(null)
  const [overflow, setOverflow] = useState(false)
  const {t} = useTranslation();

  useEffect(() => {
    withOverflow && setOverflow(hasOverflowingChildren(menuRef.current, 'y'))
  }, [withOverflow])

  return (
    <ul
      ref={menuRef}
      className={classNames(s.dropList, {[s.overflow]: overflow}, {[s.scroll]: withScroll}, className)}
    >
      {data && data.filter(item => item).map(({title, onClick}, index) => (
        <li
          className={s.dropListItem}
          data-translate-key={typeof title === "string" && getTranslateKey(title)}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            onClick()
            setVisibleMenu(false)
          }}
          key={index}
        >
          {typeof title === "string" ? t(getTranslateKey(title)) : title}
        </li>
      ))}
    </ul>
  )
}

export default DropList
