import React, {useEffect, useState} from "react";
import s from "./Tasks.module.scss";
import sBase from "../MainDashboard.module.scss";
import services from "../../../../../../services";
import moment from "moment";
import classNames from "classnames";
import TasksLoading from "../../../../../loadingStates/Dashboard/Tasks/TasksLoading";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ReactComponent as RedirectIcon} from "../../../../../../assets/icons/redirect-icon.svg";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import Button from "../../../../../elements/Button/Button";

const Tasks = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    services.dashboardServices.getTasks().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const getContent = () => {
    if (data.length) {
      return (
        <div className={s.customTable}>
          <div className={sBase.table}>
            <div className={classNames(sBase.tableHead, s.tableHead)}>
              <div className={sBase.tableCell} data-translate-key="task">{t("task")}</div>
              <div className={sBase.tableCell} data-translate-key="date">{t("date")}</div>
            </div>
            <div className={classNames(sBase.tableBody, s.content)}>
              {
                data.map((t, index) => {
                  return (
                    <div className={classNames(sBase.tableRow, s.tableRow)} key={index}>
                      <div className={sBase.tableCell}>
                        <a className={s.link} onClick={e => {
                          e.stopPropagation();
                          history.push(`/projects/${t.project_id}/milestones/${t.milestone.id}/tasks/${t.id}`);
                        }}>{t.name}</a>
                      </div>
                      <div className={sBase.tableCell}>
                        {t.end_date ? moment(t.end_date).format("MM/DD/YYYY") : "-"}
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      );
    }
    return <EmptyContent padding={88} text="You don’t have tasks yet."/>;
  };

  return (
    <section className={classNames(sBase.wrapper, s.tasksWrapper)}>
      {
        loading ? <TasksLoading/> :
          <>
            <div className={s.tasksHeader}>
              <h4 className={classNames(sBase.title, s.title)} data-translate-key="tasks-needing-attention">
                {t("tasks-needing-attention")}
              </h4>
              <Button
                styled="secondary"
                className={s.redirectBtn}
                onClick={() => {
                  history.push("/self/tasks");
                }}
              >
                <RedirectIcon/>
                <span data-translate-key={getTranslateKey("Show all")}>
              {t(getTranslateKey("Show all"))}
            </span>
              </Button>
            </div>
            {getContent()}
          </>
      }
    </section>
  );
};

export default Tasks;
