import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import s from "./Landinglayout.module.scss";

const Landinglayout = ({ children }) => {
  return (
    <div className={s.landinglayout}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Landinglayout;
