import React, {useState} from "react";
import sBase from "../MainDashboard.module.scss";
import {TableCell, TableRow} from "@material-ui/core";
import Notice from "../Notice/Notice";

const SingleCategory = ({data}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <TableRow className={sBase.tableRow} onMouseLeave={() => setOpen(false)}>
      <TableCell className={sBase.tableCell}><a href={`/categories/${data.id}`}>{data.name}</a></TableCell>
      <TableCell
        className={sBase.tableCell}>{`$ ${data.spend.toLocaleString("en-US", {maximumFractionDigits: 0})}`}</TableCell>
      <TableCell className={sBase.tableCell}>{data.active_projects}</TableCell>
      <TableCell className={sBase.tableCell}>{data.preferred_spend}</TableCell>
      <TableCell className={sBase.tableCell}>{data.spend_top5}</TableCell>
      <TableCell>
        <Notice
          id={data.id}
          fetchBy={'category'}
          sOpen={isOpen}
          onOpen={() => setOpen(!isOpen)}
        />
      </TableCell>
    </TableRow>
  )
};

export default SingleCategory;


