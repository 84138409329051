import React, {useEffect, useState} from "react";
import s from "./ContractEditContainer.module.scss";
import services from "../../../services";
import {formatDate} from "../../../helpers/dateFormat";
import ContractForm from "../../forms/ContractForm/ContractForm";
import {currencyData as currencies} from "../../../helpers/helpData";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const ContractEditContainer = ({id}) => {
  const {t} = useTranslation();
  const [currentContract, setCurrentContract] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      services.contractsServices.getContractDetails(id).then(res => {
        if (res.data) {
          setCurrentContract({
            id: res.data.id,
            category_id: {
              id: res.data.category.id,
              name: res.data.category.name,
            },
            parent_contract_id: {
              value: res.data?.parent ? res.data.parent.id : "none",
              label: res.data?.parent ? res.data.parent.name : "None",
            },
            name: res.data.name,
            supplier_id: {
              id: res.data.supplier.id,
              name: res.data.supplier.name,
            },
            business_owner_id: {
              id: res.data.business_owner.id,
              name: res.data.business_owner.name,
            },
            procurement_owner_id: {
              id: res.data.procurement_owner.id,
              name: res.data.procurement_owner.name,
            },
            total_value: res.data.total_value,
            total_value_currency: currencies.filter(e => e.label === ("USD"))[0],
            note: res.data.comment,
            start_date: new Date(res.data.start_date),
            end_date: new Date(res.data.end_date),
            attachments: res.data?.attachments || [],
          });
        }
      });
    }
  }, []);

  const serializeData = values => {
    const {
      supplier_id,
      name,
      parent_contract_id,
      category_id,
      start_date,
      end_date,
      business_owner_id,
      procurement_owner_id,
      total_value,
      total_value_currency,
    } = values;
    return {
      params: {
        supplier_id: supplier_id.id,
        name,
        parent_id: parent_contract_id.value === "none" ? "" : parent_contract_id.value,
        category_ids: category_id.id,
        start_date: formatDate(start_date),
        end_date: formatDate(end_date),
        business_owner_id: business_owner_id.id,
        procurement_owner_id: procurement_owner_id.id,
        total_value: total_value,
        total_value_currency: total_value_currency.label,
      }
    };
  };

  const updateAction = values => {
    const {params} = serializeData(values);
    services.contractsServices.editContract(id, params).then(res => {
      history.push("/contracts");
    });
  };

  return (
    <section className={s.container}>
      <h3 className={s.title} data-translate-key={"contracts"}>
        {t(getTranslateKey("contracts"))}
      </h3>
      {
        currentContract && <ContractForm defaultValues={currentContract} onUpdate={values => updateAction(values)}/>
      }
    </section>
  );
};

export default ContractEditContainer;
