import React from "react";
import s from '../AdditionalQuestions.module.scss';
import HeaderQuestion from "../HeaderQuestion/HeaderQuestion";

const TextQuestion = ({answer}) => {
  return (
    <div className={s.question}>
      <HeaderQuestion questionName={answer.question.question_name} isRequired={answer.question.is_required}/>
      <p className={s.content}>{answer.procurement_answer}</p>
    </div>
  )
}
export default TextQuestion;
