export const widgetTitlesData = [
	{
		label: "Full Year Run Rate of 2020 Activity",
		value: "full_current_year_impact"
	},
	{
		label: "2020 Impact of 2020 Activity",
		value: "current_year_impact"
	},
	{
		label: "2020 Total Impact of 2020 Activity and previous years",
		value: "current_and_previous_years_impact"
	},
	{
		label: "Carryover from previous years",
		value: "previous_years_impact"
	}
];
