import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./TopScrollbar.module.scss";

const TopScrollbar = ({ className, children }) => {
  const childrenWrapper = useRef(null);
  const outerDiv = useRef(null);
  const [width, setWidth] = useState(null);
  let widthTimeout;

  useEffect(() => {
    clearTimeout(widthTimeout);
    widthTimeout = setTimeout(() => getChildWrapperWidth(), 50);

    window.addEventListener("resize", getChildWrapperWidth);
    return () => {
      window.removeEventListener("resize", getChildWrapperWidth);
    };
  }, [childrenWrapper]);

  const getChildWrapperWidth = () => {
    let width = null;
    if (childrenWrapper.current && childrenWrapper.current.scrollWidth) {
      width = childrenWrapper.current.scrollWidth + "px";
    }
    width && setWidth(width);
  };

  return (
    <div className={classNames({ [className]: className }, styles.mainWrapper)}>
      <div
        ref={outerDiv}
        className={styles.outerDivStyle}
        onScroll={() => {
          childrenWrapper.current.scrollLeft = outerDiv.current.scrollLeft;
        }}
      >
        <div style={{ width }}>&nbsp;</div>
      </div>
      <div
        ref={childrenWrapper}
        className={classNames(styles.noOverflow, styles.scrollbar)}
        onScroll={() => {
          outerDiv.current.scrollLeft = childrenWrapper.current.scrollLeft;
        }}
      >
        <div
          style={{ display: "table" }}
          onScroll={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default TopScrollbar;
