import {
  GET_STAGES,
  CHANGE_POPUP,
  ADD_STAGE,
  DELETE_STAGE,
  EDIT_STAGE,
  CLOSE_ALERT,
  REPLACE_STAGES
} from "./types"

export const initialState = {
  stages: null,
  popup: null,
  loading: true,
  alert: {
    type: "",
    message: ""
  }
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STAGES:
      return {
        ...state,
        stages: payload.stages,
        loading: payload.loading
      }
    case ADD_STAGE:
      return {
        ...state,
        stages: payload.stage ? [...state.stages, payload.stage] : state.stages,
        popup: payload.popup,
        alert: payload.alert
      }
    case DELETE_STAGE:
      return {
        ...state,
        stages: payload.id
          ? state.stages.filter(e => e.id !== payload.id)
          : state.stages,
        popup: payload.id ? null : "delete",
        alert: payload.alert
      }
    case EDIT_STAGE:
      return {
        ...state,
        stages: payload.stage
          ? state.stages.map(e =>
              e.id === payload.stage.id ? payload.stage : e
            )
          : state.stages,
        popup: payload.stage ? null : "edit",
        alert: payload.alert
      }
    case REPLACE_STAGES:
      return {
        ...state,
        stages: payload.stages || state.stages,
        alert: payload.alert
      }
    case CHANGE_POPUP:
      return {
        ...state,
        popup: payload
      }
    case CLOSE_ALERT:
      return {
        ...state,
        alert: {
          type: "",
          message: ""
        }
      }
  }
}
