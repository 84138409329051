import React from "react";
import s from "./MilestonesTasksBoard.module.scss";
import classNames from "classnames";
import Milestones from "./components/Milestones/Milestones";
import Accordeon from "./components/Accordeon/Accordeon";
import TasksList from "./components/TasksList/TasksList";
import PropTypes from "prop-types";

const MilestonesTasksBoard = (
  {
    data,
    className,
    addTask,
    editTask,
    deleteTask,
    deleteApproval,
    projectTypeName,
  }
) => {
  if (!data.length) return "";
  return (
    <div className={classNames(s.boardWrap, className)}>
      <Milestones
        data={data.map(({id, name, color}) => ({
          id,
          name,
          color
        }))}
      />
      <Accordeon title='Required tasks'>
        <TasksList
          preparedData={data}
          tasksType='required_tasks'
          addTask={values => addTask({...values, task_type: "required"})}
          editTask={(values, taskId) => editTask({...values, task_type: "required"}, taskId)}
          deleteTask={id => deleteTask(id)}
        />
      </Accordeon>
      <Accordeon title='Recommended tasks'>
        <TasksList
          preparedData={data}
          tasksType='recommended_tasks'
          addTask={values => addTask({...values, task_type: "recommended"})}
          editTask={(values, taskId) => editTask({...values, task_type: "recommended"}, taskId)}
          deleteTask={id => deleteTask(id)}
        />
      </Accordeon>
      <Accordeon title='Approvals'>
        <TasksList
          preparedData={data}
          tasksType='approvals'
          projectTypeName={projectTypeName}
          deleteTask={id => deleteApproval(id)}
        />
      </Accordeon>
    </div>
  );
};

export default MilestonesTasksBoard;

MilestonesTasksBoard.propTypes = {
  data: PropTypes.any,
  className: PropTypes.string,
  addTask: PropTypes.func,
  editTask: PropTypes.func,
  deleteTask: PropTypes.func,
  deleteApproval: PropTypes.func,
  projectTypeName: PropTypes.string,
};