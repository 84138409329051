export const formatCategoryName = (name) => {
	return name
		.split(" ")
		.map((e, index) => {
			if (e === "IT" || e === "HR") {
				return e;
			} else if (!index && e !== "IT" && e !== "HR") {
				return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase();
			} else {
				return e.toLowerCase();
			}
		})
		.join(" ");
};
