import React from "react"
import MainTable from "components/tables/MainTable/MainTable"
import {formatCurrency} from "helpers/currencyFormat"
import {currencyData} from "helpers/helpData"

const ProjectFinancialByYearTable = (
  {
    data,
    className,
    title,
    defaultCurrency
  }
) => {
  return (
    <MainTable
      className={className}
      data={data}
      totalName={title}
      dataTypes={[
        {
          title: `Savings Groups`,
          value: ({savings_type}) => {
            return savings_type;
          }
        },
        {
          title: "Total Savings",
          value: ({total}) =>
            total
              ? formatCurrency(
                currencyData.filter(
                  e => e.label === (defaultCurrency || "USD")
                )[0]?.value,
                total
              )
              : "-",
          alignment: "left"
        },

        ...data[0].financialByYears.map((item, index) => ({
          title: item.name,
          value: ({financialByYears}) => {
            const {value} = financialByYears[index]
            return value
              ? formatCurrency(
                currencyData.filter(
                  e => e.label === (defaultCurrency || "USD")
                )[0]?.value,
                value
              )
              : "-"
          },
          alignment: "left"
        }))
      ]}
    />
  )
}

export default ProjectFinancialByYearTable
