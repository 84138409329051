import React from "react";
import PropTypes from "prop-types";
import UserFormWrapper from "components/wrappers/UserFormWrapper/UserFormWrapper";
import Input from "components/elements/Input/Input";
import PasswordInput from "components/elements/PasswordInput/PasswordInput";
import Customlink from "components/elements/CustomLink/CustomLink";
import Button from "components/elements/Button/Button";
import s from "./LoginForm.module.scss";
import { useFormik } from "formik";
import { object, string } from "yup";
import { paths } from "routes/paths";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const PasswordForm = ({ email, handleLogin, error }) => {
  const {t} = useTranslation();
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: object({
      password: string().required("Password can’t be blank"),
    }),
    onSubmit: (values) =>
      handleLogin({ email: email, password: values.password }),
  });
  const { handleSubmit, handleChange, values, errors, setFieldValue } = formik;
  return (
    <UserFormWrapper>
      <p className={s.userEmail}>{email}</p>
      <h1 className={s.title} data-translate-key={"enter-password"}> {t(getTranslateKey("enter-password"))}</h1>
      <form className={s.form} onSubmit={handleSubmit}>
        <div className={s.hiddenField}>
          <Input type="email" name="email" value={email} />
        </div>
        <PasswordInput
          className={s.field}
          placeholder="Enter your Password"
          name="password"
          value={values.password}
          error={errors.password || error}
          onChange={(e) => setFieldValue("password", e)}
        />
        <Customlink
          className={s.link}
          type="nav-link"
          path={paths.passwordResetRequest}
        >
          Forgot password?
        </Customlink>
        <Button className={s.btn} styled="primary" type="submit">
          Sign in
        </Button>
      </form>
    </UserFormWrapper>
  );
};

PasswordForm.propTypes = {
  email: PropTypes.string.isRequired,
};

PasswordForm.defaultProps = {
  email: "",
};

export default PasswordForm;
