import { supplierStatuses } from "helpers/helpData.js";

export const formatData = (data = []) => {
  const headerArr = [{ type: "date", label: "Month" }];
  const valueArr = [];
  data[0].by_month.forEach((itm, index) => {
    valueArr.push([new Date(`${index + 1}/01/${itm.year}`)]);
  });
  data.forEach((item) => {
    headerArr.push(item.category);
    item.by_month &&
      item.by_month.forEach((itm, index) => {
        valueArr[index].push(itm.spend);
      });
  });
  return [headerArr, ...valueArr];
};

export const generateColors = (size = 10) => {
  const colors = [];
  while (colors.length < size) {
    let color = Math.floor(Math.random() * 1000000 + 1);
    colors.push("#" + ("000000" + color.toString(16)).slice(-6));
  }
  return colors;
};

export const formatSpendByStatusData = (data) => {
  const statuses = [];
  const pieChartData = [];
  const COLORS = ["#5b79b4", "#6ACFEF", "#F4A261"];
  let total = 1;
  if (data.by_status) {
    total = data.by_status.reduce((acc, itm) => {
      acc += itm.spend;
      return acc;
    }, 0);
  }
  data.by_status &&
    data.by_status.forEach((itm, index) => {
      statuses.push({
        name: supplierStatuses.find((el) => el.value === itm.status)?.label,
        color: COLORS[index],
        value: itm.spend ? Number((itm.spend / total) * 100).toFixed(0) : 0,
      });
      if (pieChartData[0]) {
        pieChartData[0][1] = String(Number(pieChartData[0][1]) + itm.spend);
      } else pieChartData[0] = ["Total", String(itm.spend)];
      pieChartData.push([
        supplierStatuses.find((el) => el.value === itm.status)?.label,
        itm.spend,
      ]);
    });

  return { statuses, pieChartData };
};

export const formatSuppliersRate = (data) => {
  const NAMES = { high: "< 5%", middle: "> 5-25%", low: "> 25%" };
  const result = [];
  for (let key in data) {
    result.push({
      name: NAMES[key],
      spend: data[key]?.spend,
      count: data[key]?.count,
    });
  }
  return result;
};

export const formatContractsExpirationsData = (data) => {
  const result = [];
  data.forEach((itm) => {
    const expirations = {};
    itm.expirations.forEach((el) => {
      for (let key in el)
        expirations[`days_${key.replace("+", "_plus")}`] = el[key];
    });
    result.push({ status: itm.status, ...expirations });
  });
  return result;
};

export const numberFormatAbbreviated = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K";
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "M";
  } else if (num <= 999) {
    return num;
  }
};
