import React from "react";
import styles from "./HyperlinksField.module.scss";
import Input from "../Input/Input";
import PropTypes from "prop-types";
import {ReactComponent as Trash} from "../../../assets/icons/trash-otline.svg";

const SingleHyperlink = ({data, onChange, onDelete}) => {
  return (
    <div className={styles.hyperlink}>
      <Input
        value={data.name}
        onChange={e => onChange(data.id, "name", e.target.value)}
        name='name'
        type='text'
        placeholder='Enter name'
        label='Hyperlink name'
        withCounter
        limitValue={50}
      />
      <Input
        value={data.url}
        onChange={e => onChange(data.id, "url", e.target.value)}
        name='url'
        type='text'
        placeholder='Enter url'
        label='URL'
        withCounter
        limitValue={150}
      />
      <Trash onClick={() => onDelete(data.id)}/>
    </div>
  );
};

export default SingleHyperlink;

SingleHyperlink.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  data: PropTypes.any,
};