import React from "react";
import PropTypes from 'prop-types'
import classNames from "classnames";
import s from "./Divider.module.scss";

const Divider = ({ className }) => {
  return <div className={classNames(s.divider, className)}></div>;
};

Divider.propTypes = {
  className: PropTypes.any
}

Divider.defaultProps = {
  className: ""
}

export default Divider;
