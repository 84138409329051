import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import DropList from "components/elements/DropListTrigger/DropList";
import useOutsideClicker from "hooks/useOutsideClicker";
import s from "./HeaderUserMenu.module.scss";

const HeaderUserMenuTrigger = ({ data, className, initialLetter }) => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const menuRef = useRef(null);
  useOutsideClicker(menuRef, () => setVisibleMenu(false));

  return (
    <div className={s.wrapDropListTrigger}>
      <button className={s.triggerWithLetter} onClick={(e) => {
					e.stopPropagation();
					setVisibleMenu(!visibleMenu);
				}} >
        {initialLetter}
      </button>

      {visibleMenu && (
        <div ref={menuRef}>
          <DropList setVisibleMenu={setVisibleMenu} data={data} className={className} />
        </div>
      )}
    </div>
  );
};

HeaderUserMenuTrigger.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string
}

HeaderUserMenuTrigger.defaultProps = {
  data: [
    {
      title: "My profile",
      onClick: () => {},
    },
    {
      title: "Sign out",
      onClick: () => {},
    },
  ],
  className: ""
}

export default HeaderUserMenuTrigger;