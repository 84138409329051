import React from "react";
import { useFormik } from "formik";
import Button from "components/elements/Button/Button";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";

const CategorySupplierChangeStatusForm = ({ onSubmit, onClose, status }) => {
  const statuses = [
    {
      value: "preferred",
      label: "Preferred",
    },
    {
      value: "approved",
      label: "Approved",
    },
    {
      label: "Non approved",
      value: "non_approved",
    },
  ];
  const formik = useFormik({
    initialValues: {
      status: statuses.filter((e) => e.value === status)[0],
    },
    validate: ({ status }) => {
      const errors = {};
      if (status.value === "") {
        errors.status = "Please, choose Status";
      }
      return errors;
    },
    onSubmit: ({ status }) => {
      onSubmit(status.value);
      onClose();
    },
  });
  const { handleSubmit, errors, setFieldValue, values, touched } = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div>
        <CustomDropdown
          options={statuses}
          value={values.status.value}
          onChange={(option) => setFieldValue("status", option)}
          label="Status"
          placeholder="Choose status"
          error={errors.status}
        />
      </div>
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Save
        </Button>
      </div>
    </form>
  );
};

export default CategorySupplierChangeStatusForm;
