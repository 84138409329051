import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import services from "services/index";
import SurveyStakeholderContainer from "./components/SurveyStakeholderContainer";
import SurveyOwnerContainer from "./components/SurveyOwnerContainer";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import TableLoading from "components/loadingStates/Table/Table";

const SurveyContainer = ({ ownerId, setServeySent }) => {
  const params = useParams();
  const history = useHistory();
  const { id } = useSelector((state) => state.user);
  const isOwner = ownerId === id;
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [loading, setLoading] = useState(true);
  const [isStakeholder, setIsStakeholder] = useState(false);
  const [surveys, setSurveys] = useState({
    survey_results: [],
    survey_results_count: 0,
  });

  useEffect(() => {
    getSurveys();
  }, [params.id, history.location]);

  const getSurveys = (query) => {
    services.surveyServices
      .getSurveys(params.id, query)
      .then((res) => {
        setSurveys(res.data);

        !isOwner && setIsStakeholder(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <TableLoading length={5} />}
      {isOwner && !loading && (
        <SurveyOwnerContainer
          params={params}
          history={history}
          getSurveys={getSurveys}
          setAlert={setAlert}
          surveys={surveys}
          setServeySent={setServeySent}
        />
      )}
      {isStakeholder && !loading && (
        <SurveyStakeholderContainer
          params={params}
          history={history}
          survey={surveys}
        />
      )}

      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert({ message: "", type: "" })}
        />
      )}
    </>
  );
};

export default SurveyContainer;
