import Identify from "../../../../assets/icons/ManOver/Identify.svg";
import Align from "../../../../assets/icons/ManOver/Align.svg";
import Track from "../../../../assets/icons/ManOver/Track.svg";
import Complete from "../../../../assets/icons/ManOver/Complete.svg";
import Measure from "../../../../assets/icons/ManOver/Measure.svg";
import Communicate from "../../../../assets/icons/ManOver/Communicate.svg";

const managementCardsData = [
  {
    img: Identify,
    number: "1",
    title: "Identify",
    text:
      "Develop and cultivate a list of all procurement improvement opportunities in one place",
  },
  {
    img: Align,
    number: "2",
    title: "Align & Assign",
    text:
      "Align which ones are important for you and assign the project responsible person",
  },
  {
    img: Track,
    number: "3",
    title: "Track",
    text: "Once upon the project start, you keep track its implementation",
  },
  {
    img: Complete,
    number: "4",
    title: "Complete",
    text:
      "After completing the project define savings and non-financial benefits its brought you",
  },
  {
    img: Measure,
    number: "5",
    title: "Measure",
    text:
      "Measure stakeholders satisfaction to evaluate how well they enjoyed about the project",
  },
  {
    img: Communicate,
    number: "6",
    title: "Communicate",
    text:
      "Communicate project results and stakeholders satisfaction within the company",
  },
];

const managementSliderData = [
  {
    img1: Identify,
    number1: "1",
    title1: "Identify",
    text1:
      "Develop and cultivate a list of all procurement improvement opportunities in one place",
    img2: Align,
    number2: "2",
    title2: "Align & Assign",
    text2:
      "Align which ones are important for you and assign the project responsible person",
  },
  {
    img1: Track,
    number1: "3",
    title1: "Track",
    text1: "Once upon the project start, you keep track its implementation",
    img2: Complete,
    number2: "4",
    title2: "Complete",
    text2:
      "After completing the project define savings and non-financial benefits its brought you",
  },
  {
    img1: Measure,
    number1: "5",
    title1: "Measure",
    text1:
      "Measure stakeholders satisfaction to evaluate how well they enjoyed about the project",
    img2: Communicate,
    number2: "6",
    title2: "Communicate",
    text2:
      "Communicate project results and stakeholders satisfaction within the company",
  },
];

export { managementCardsData, managementSliderData };
