import React from 'react';
import classnames from 'classnames';
import s from "./Preloader.module.scss";

const Preloader = () => {
	return (
		<div className={s.preload}>
			<div className={s['sk-folding-cube']}>
				<div className={classnames(s['sk-cube1'], s['sk-cube'])}/>
				<div className={classnames(s['sk-cube2'], s['sk-cube'])}/>
				<div className={classnames(s['sk-cube4'], s['sk-cube'])}/>
				<div className={classnames(s['sk-cube3'], s['sk-cube'])}/>
			</div>
			<div className={s.caption}>
				Focal Point <br /> Procurement solutions
			</div>
		</div>
	);
}

export default Preloader;