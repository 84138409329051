import React from "react"
import s from "./ChartQuarter.module.scss"
import classNames from "classnames"
import {formatValue} from "components/elements/PieChart/formatValue"
import {backgroundTypes, savingsTypesData} from "helpers/helpData"
import hexToRgba from "hex-to-rgba";
import {useTranslate} from "../../../../../hooks/useTranslate";

const ChartQuarter = ({className, onMouseMove, onMouseOut, data}) => {
  const quarter = Object.values(data)[0];
  const quarterData = quarter?.slice(0, quarter.length - 1);
  const totalObj = quarter[quarter.length - 1];
  const totalValue = Object.values(totalObj)[0];
  const [translateKey, translateValue] = useTranslate("total");
  const totalDataFormat = totalValue.toLocaleString("en-US", {
    maximumFractionDigits: 0
  });

  const getHintData = (title, value) => {
    return {
      title:
        typeof title === "string"
          ? title
          : savingsTypesData.filter(e => e.value === title)[0]?.label,
      value:
        "$" +
        value.toLocaleString("en-US", {
          maximumFractionDigits: 0
        })
    }
  };

  const isEmpty = !quarterData.some(itm =>
    Object.values(Object.values(itm)[0]).some(e => e)
  );

  const getColor = index => {
    return Object.values(backgroundTypes)[index] ?? backgroundTypes.dark_blue;
  }

  return (
    <div className={classNames(s.chartQuarter, className)}>
      <span className={s.total} data-translate-key={translateKey}>
        {isEmpty ? "-" : `${translateValue} ${totalDataFormat}`}
      </span>
      <div className={s.quarter}>
        {quarterData.map((item, index) => {
          return (
            <div key={index} className={s.itemContainer}>
              <div
                className={s.item}
                style={{
                  backgroundColor: !Object.values(quarterData[index])[0].total
                    ? "rgb(200, 200, 200, 0.5)"
                    : `${hexToRgba(getColor(index), 0.5)}`,
                  borderColor: !Object.values(quarterData[index])[0].total
                    ? "rgb(200, 200, 200, 1)"
                    : `${hexToRgba(getColor(index))}`
                }}
                onMouseMove={e =>
                  Object.values(quarterData[index])[0].total &&
                  onMouseMove(
                    e,
                    getHintData("Active&Planned", Object.values(item)[0].active)
                  )
                }
                onMouseOut={
                  Object.values(quarterData[index])[0].total
                    ? onMouseOut
                    : () => {
                    }
                }
              >
              <span
                className={s.progress}
                style={{
                  width: `${(Object.values(item)[0]?.completed * 100) /
                  Object.values(item)[0]?.total || 0}%`,
                  backgroundColor: `${hexToRgba(getColor(index))}`
                }}
                onMouseMove={e => {
                  e.stopPropagation()
                  onMouseMove(
                    e,
                    getHintData("Completed", Object.values(item)[0].completed)
                  )
                }}
                onMouseOut={onMouseOut}
              />
                {Object.values(item)[0]?.total > 0 && (
                  <span className={s.value}>
                    {formatValue(Object.values(item)[0]?.total)}
                  </span>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <span className={s.title}>{Object.keys(data)[0]}</span>
    </div>
  )
}
export default ChartQuarter
