import Anders from "../../../../assets/img/team/Anders.jpg";
import Edward from "../../../../assets/img/team/Edward.jpg";
import Erik from "../../../../assets/img/team/Erik.jpg";
import Antonio from "../../../../assets/img/team/Antonio.jpg";

const teamData = [
  {
    img: Anders,
    name: "Anders Lillevik",
    office: "Chief Executive Officer",
    text:
      "After being a procurement executive for over 20 years, Anders founded Focal Point to help organizations maximize the value of their procurement spend.",
      linkedin: "/",
      twitter: "/"
  },
  {
    img: Edward,
    name: "Edward Chick",
    office: "Chief Operating Officer",
    text:
      "With significant tenure building and leading sales organizations for leading technology firms, Edward brings over 30 years of selling to within the procurement vertical.",
      linkedin: "/",
      twitter: "/"
  },
  {
    img: Erik,
    name: "Erik Bullen",
    office: "Board of Advisors",
    text:
      "Serial entrepreneur, seven successful startups. Multiple exits, serves advisor to multiple high-growth startups.",
      linkedin: "/",
      twitter: "/"
  },
  {
    img: Antonio,
    name: "Antonio Moreno",
    office: "Board of Advisors",
    text:
      "Serial entrepreneur, eight startups in telecom and technology. Multiple exits, one with a nine-figure exit.",
      linkedin: "/",
      twitter: "/"
  },
];

export default teamData;
