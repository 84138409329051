import React, {useState, useContext, useEffect} from "react";
import AddFlagPopup from "./Popups/AddFlagPopup/AddFlagPopup";
import EditFlagPopup from "./Popups/EditFlagPopup/EditFlagPopup";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import AlertContext from "contexts/AlertContext/AlertContext";
import FlagsTable from "components/tables/FlagsTable/FlagsTable";
import Popup from "components/popups/Popup/Popup";
import services from "../../../../services";
import TableLoading from "../../../loadingStates/Table/Table";

const Flags = () => {
  const [data, setData] = useState([]);
  const [popup, setPopup] = useState(null);
  const [currentFlag, setCurrentFlag] = useState(null);
  const {setAlert} = useContext(AlertContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData([]);
    services.flagServices.getFlags().then(res => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const handleAddGroup = values => {
    services.flagServices.addFlag({
      ...values,
      flag_admin_ids: values.flag_admin_ids.map(a => a.id).join(",")
    }).then(res => {
      setData(prevData => [...prevData, res.data]);
      setAlert({
        message: `"${values.name}" flag successfully added.`,
      });
    });
    setPopup(null);
  };

  const handleDeleteGroup = () => {
    const oldData = data;
    services.flagServices.deleteFlag(currentFlag.id).then(() => {
      setData(data.filter((e) => e.id !== currentFlag.id));
      setAlert({
        message: `"${currentFlag.name}" flag successfully deleted.`,
        onCancel: () => {
          setData(oldData);
        },
      });
    });
    setPopup(null);
  };

  const handleEditGroup = (values) => {
    const oldData = data;
    services.flagServices.editFlag(currentFlag.id, {
      ...values,
      flag_admin_ids: values.flag_admin_ids.map(a => a.id).join(",")
    })
      .then(res => {
        setData(data.map((e) => e.id === currentFlag.id ? {...e, ...res.data,} : e));
        setAlert({
          message: `"${currentFlag.name}" flag successfully changed.`,
          onCancel: () => {
            setData(oldData);
          },
        });
      });
    setPopup(null);
  };

  if (loading) return <TableLoading length={3} />;

  let content = (
    <EmptyBlock
      subtitle="No work item results found"
      buttonTitle="New flag"
      onClick={() => setPopup("add")}
    />
  );

  if (data.length) content = (
    <FlagsTable
      data={data}
      onAdd={() => setPopup("add")}
      onEdit={(e) => {
        setCurrentFlag(e);
        setPopup("edit");
      }}
      onDelete={(e) => {
        setCurrentFlag(e);
        setPopup("delete");
      }}
    />
  );

  return (
    <>
      {content}
      {popup === "add" && (
        <AddFlagPopup
          onClose={() => setPopup(null)}
          onSubmit={handleAddGroup}
        />
      )}
      {popup === "edit" && (
        <EditFlagPopup
          defaultValues={currentFlag}
          onClose={() => setPopup(null)}
          onSubmit={handleEditGroup}
        />
      )}
      {popup === "delete" && (
        <Popup
          title="Delete flag"
          text="Are you sure you want to delete this flag?"
          onClose={() => setPopup(null)}
          onClick={handleDeleteGroup}
          isDelete
          dialog
          subText="It will be removed from all projects with it."
        />
      )}
    </>
  );
};

export default Flags;