export const popUpType = {
  new: 'new',
  edit: 'edit',
  delete: 'delete'
}

export const defaultPopUpState = {
  isOpen: false,
  type: '',
  announcement: null,
}