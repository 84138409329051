import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import RequestDetailContainer from "components/containers/RequestDetailContainer/RequestDetailContainer";

const RequestDetailPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: "/requests",
          name: "Requests",
        },
        {
          name: "Request details",
        },
      ]}
    >
      <RequestDetailContainer />
    </DefaultLayout>
  );
};
export default RequestDetailPage;
