import {
  SET_ALERT,
  SET_FILTERS,
  SET_BOARD_LOADING,
  SET_PIE_LOADING,
  SET_PROJECTS,
  SET_POPUP_NAME,
  SET_PIE_CHART_DATA,
  CLOSE_POPUP,
  SET_CURRENT_PAGE,
  SET_CURRENT_TAB,
  SET_TOTAL_PROJECTS_COUNT,
  ADD_NEW_PROJECT,
  SET_SELECTED_PROJECT_ID,
  SET_REQUEST_DATA,
  SET_STAGES
} from "./reducer"

export const setBoardLoading = loading => ({
  type: SET_BOARD_LOADING,
  payload: loading
})

export const setPieLoading = loading => ({
  type: SET_PIE_LOADING,
  payload: loading
})

export const setRequestData = data => ({
  type: SET_REQUEST_DATA,
  payload: { ...data }
})

export const setProjects = projects => ({
  type: SET_PROJECTS,
  payload: { projects }
})

export const addNewProjects = newProject => ({
  type: ADD_NEW_PROJECT,
  payload: { newProject }
})

export const closePopup = () => ({
  type: CLOSE_POPUP,
  payload: null
})

export const openPopup = name => ({
  type: SET_POPUP_NAME,
  payload: { name }
})

export const setFilters = filters => ({
  type: SET_FILTERS,
  payload: { filters }
})

export const setCurrentTab = tab => ({
  type: SET_CURRENT_TAB,
  payload: { tab }
})

export const setCurrentPage = page => ({
  type: SET_CURRENT_PAGE,
  payload: { page }
})

export const setProjectsTotalCount = projectsCount => ({
  type: SET_TOTAL_PROJECTS_COUNT,
  payload: { projectsCount }
})

export const setSelectedProjectId = projectId => ({
  type: SET_SELECTED_PROJECT_ID,
  payload: { projectId }
})

export const setPieChartData = pieChartData => ({
  type: SET_PIE_CHART_DATA,
  payload: { pieChartData }
})

export const setAlert = alert => ({
  type: SET_ALERT,
  payload: { ...alert }
})

export const setStages = stages => ({
  type: SET_STAGES,
  payload: stages
})
