import React from "react";
import AdminSettingsGeneralContainer
from "../../containers/AdminSettingsGeneralContainer/AdminSettingsGeneralContainer";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

const AdminSettingsGeneralPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {name: "Admin Settings", path: "", disabled: true},
        {name: "General", path: ""},
      ]}
    >
      <AdminSettingsGeneralContainer/>
    </DefaultLayout>
  )
};
export default AdminSettingsGeneralPage;
