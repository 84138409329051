import React, {useState, useEffect} from "react"
import Chart from "react-google-charts"
import {CircularProgress} from "@material-ui/core"
import styles from "./SpendTMT.module.scss"
import {formatData, generateColors} from "../../helpers"
import {currencyData} from "helpers/helpData"
import services from "services"
import qs from "query-string"
import {useHistory} from "react-router-dom"
import WidgetEmptyState from "components/elements/WidgetEmptyState/WidgetEmptyState"
import SpendTMTChartLoading from "components/loadingStates/SpendTMTChart/SpendTMTChart"
import classNames from "classnames"
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const COLORS = generateColors(50)

const SpendTMT = ({categoryIdsFilter}) => {

  const {t} = useTranslation();
  const [data, setData] = useState([])
  const [currency, setCurrency] = useState("$")
  const [loading, setLoading] = useState(true)
  const [ticks, setTicks] = useState([])

  const history = useHistory()
  const query = qs.parse(history.location.search);

  useEffect(() => {
    services.categoriesServices.getCategoriesSpend({...query, category_ids: categoryIdsFilter}).then(res => {
      if (Boolean(Object.keys(res.data).length)) {
        const result = res.data
        let _currency = result.currency
        _currency =
          currencyData.find(itm => itm.value === _currency)?.label || "$"
        setCurrency(_currency === "USD $" ? "$" : _currency)
        setData(result.spends.length ? formatData(result.spends) : [])
        setTicks(
          result.spends[0]?.by_month.map(
            (itm, index) => new Date(`${index + 1}/01/${itm.year}`)
          )
        )
      } else {
        setData([])
      }
      setLoading(false)
    })
  }, [history.location.search, categoryIdsFilter]);

  return (
    <div
      className={classNames(styles.container, {[styles.loading]: loading})}
    >
      {!loading && (
        <h4 className={styles.title}>
          {query.spend_year ? query.spend_year : new Date().getFullYear()}
          {' '}
          <span data-translate-key={getTranslateKey("spend")}>
            {t(getTranslateKey("spend"))}
          </span>
        </h4>
      )}
      {loading && <SpendTMTChartLoading/>}
      {!loading && Boolean(data.length) && (
        <div className={styles.chartWrapper}>
          <Chart
            width={"100%"}
            height={"280px"}
            chartType='ColumnChart'
            loader={<SpendTMTChartLoading onlyColumns={true}/>}
            data={data}
            formatters={data[0]?.map(
              (_, index) =>
                index && {
                  type: "NumberFormat",
                  column: index,
                  options: {
                    prefix: currency,
                    fractionDigits: 0
                  }
                }
            )}
            options={{
              colors: COLORS,
              allowHtml: true,
              showRowNumber: true,
              legend: "none",
              chartArea: {
                height: "100%",
                width: "100%",
                top: 40,
                left: 40,
                right: 10,
                bottom: 20
              },
              isStacked: true,
              vAxis: {
                minValue: 1,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.55)",
                  fontSize: 12,
                  fontName: "Roboto"
                },
                baselineColor: "none",
                minorGridlines: {count: 0},
                gridlines: {
                  color: "#ecf0f4"
                },
                format: "short"
              },
              hAxis: {
                format: "MMM",
                textStyle: {
                  color: "#C0C7CE",
                  fontSize: 11,
                  fontName: "Nunito Sans"
                },
                ticks: ticks,
                gridlines: {
                  color: "#fff",
                  count: 12
                }
              }
            }}
          />
          <div className={styles.legendWrapper}>
            {data[0]?.map((legend, index) => {
              if (index) {
                return (
                  <span className={styles.legend} key={legend}>
                    <span style={{backgroundColor: COLORS[index - 1]}}></span>
                    <p>{legend}</p>
                  </span>
                )
              }
            })}
          </div>
        </div>
      )}
      {!loading && !Boolean(data.length) && (
        <WidgetEmptyState description='There is no data that matches selected filters'/>
      )}
    </div>
  )
}

export default SpendTMT
