import React, {Fragment} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./Textarea.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslate} from "../../../hooks/useTranslate";
import Label from "../Label/Label";

const Textarea = (
  {
    label,
    value,
    error,
    onChange,
    placeholder,
    className,
    labelClass,
    name,
    count,
    extraProps,
    onBlur,
    customHeight,
    disabled,
    optional
  }
) => {
  const {t} = useTranslation();
  const [translateKey, translateValue] = useTranslate(placeholder);

  return (
    <Fragment>
      {label && <Label label={label} labelClass={labelClass} optional={optional}/>}
      <textarea
        disabled={disabled}
        value={value}
        onChange={onChange}
        className={classNames(className, s.description, {[s.disabled]: disabled})}
        data-translate-key={translateKey}
        placeholder={translateValue}
        name={name}
        onBlur={onBlur}
        style={customHeight && {height: customHeight}}
        {...extraProps}
      />
      <div className={s.bottomWrap}>
        <span className={s.errorMessage} data-translate-key={getTranslateKey(error)}>{t(getTranslateKey(error))}</span>
        {count && (
          <span className={s.counterWrap}>
            <span className={classNames(s.count, {[s.error]: error})}>
              {count?.current}
            </span>
            {` / ${count?.max}`}
          </span>
        )}
      </div>
    </Fragment>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.any,
  extraProps: PropTypes.object,
};

Textarea.defaultProps = {
  label: "Description",
  value:
    "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, seda",
  error: "",
  onChange: () => {
  },
  placeholder: "Write a description",
  className: "",
  extraProps: {},
};

export default Textarea;
