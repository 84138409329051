export const formatStepsData = (data) => {
  let steps = {};

  data.map((step) => {
    steps[`step${step.position}`] = step.approval_group.approvers.map(
      (approver) => ({
        assigned_to: approver.name,
        status: null,
        name: step.project_tab.split("_").join(" "),
      })
    );
  });
  return steps;
};
