import React from "react";
import styles from "./SupplierDetail.module.scss";
import Detail from "components/elements/Detail/Detail";
import {formatCurrency} from "helpers/currencyFormat";
import {currencyData} from "helpers/helpData";
import Tags from "../../../../elements/Tags/Tags";

const SupplierDetail = ({data, onDeleteTag, onAssignTag}) => {
  const {status, spend_currency, spend, projects_count, contracts_count, owner, tags} = data;

  return (
    <Detail
      className={styles.details}
      data={[
        {name: "Status", value: status.replace("_", " ")},
        {
          name: "Spend",
          value: spend
            ? formatCurrency(
              currencyData.filter((e) => e.label === spend_currency)[0]
                ?.value,
              spend
            )
            : "-",
        },
        {
          name: "Lifetime projects",
          value: projects_count ? projects_count : "-",
        },
        {
          name: "Lifetime contracts",
          value: contracts_count ? contracts_count : "-",
        },
        {
          name: "Owner",
          value: owner ? owner.name : "-",
        },
        {
          name: "Tags",
          value: (
            <div className="tagsWrap">
                  <Tags
                    allData={[{ tags: tags }]}
                    item={{ tags: tags }}
                    onAssign={onAssignTag}
                    onDelete={onDeleteTag}
                    width={360}
                  />
            </div>
          ),
          type: "tags"
        },
      ]}
    />
  );
};

export default SupplierDetail;
