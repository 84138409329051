import {LOGIN, LOGOUT, SET_LANGUAGE} from "helpers/constants";
import services from "services";
import {deleteGlobalProject} from "./globalProject";

export const login = (user) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN,
      payload: user,
    });
  };
};

export const logout = () => {
  return async (dispatch) => {
    services.userServices
      .logout()
      .then(() => {
        dispatch({
          type: LOGOUT,
        });
      })
      .then(() => {
        dispatch(deleteGlobalProject());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const changeLanguage = lang => {
  return async (dispatch) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: lang,
    });
  };
};