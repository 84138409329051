import {
  SET_FILTERS,
  SET_LOADING,
  SET_ACTIVE_FILTERS,
  SET_CURRENT_PAGE,
  SET_REQUEST_DATA,
  SET_POPUP,
  SET_USER,
  RESET_ALERT,
  DELETE_USER,
  ADD_USER,
  EDIT_USER,
  RESET_PASSWORD,
} from "./reducer";
import services from "services";

const getAlert = (message) => ({
  type: "success",
  message,
});

const errorAlert = {
  type: "error",
  message: "Something went wrong. Please, try again.",
};

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: {loading},
});

export const setRequestData = (data) => ({
  type: SET_REQUEST_DATA,
  payload: {...data},
});

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: {filters},
});

export const setActiveFilters = (activeFilters) => ({
  type: SET_ACTIVE_FILTERS,
  payload: {activeFilters},
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: {page},
});

export const setPopup = (popup) => ({
  type: SET_POPUP,
  payload: popup,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const resetAlert = () => ({
  type: RESET_ALERT,
});

export const deleteUser = (dispatch, userId) => {
  services.userServices
    .adminDeleteUser(userId)
    .then(() => {
      dispatch({
        type: DELETE_USER,
        payload: {
          user_id: userId,
          alert: getAlert("User successfully deleted."),
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DELETE_USER,
        payload: {
          alert: errorAlert,
        },
      });
    });
};

export const addUser = async (dispatch, user) => {
  await services.userServices
    .adminAddUser(user)
    .then((res) => {
      dispatch({
        type: ADD_USER,
        payload: {
          user: res.data,
          alert: getAlert("User successfully added."),
        },
      });
    })
    .catch((error) => {
      throw error;
    });
};

export const editUser = (dispatch, id, values, onSuccess) => {
  services.userServices
    .adminEditUser(id, values)
    .then((res) => {
      dispatch({
        type: EDIT_USER,
        payload: {
          user: res.data,
          alert: getAlert("User successfully edited."),
        },
      });
      onSuccess(() => values);
    })
    .catch(() => {
      dispatch({
        type: EDIT_USER,
        payload: {
          alert: errorAlert,
        },
      });
    });
};

export const resetPassword = (dispatch, id, values) => {
  services.userServices
    .adminEditUser(id, values)
    .then(() => {
      dispatch({
        type: RESET_PASSWORD,
        payload: {
          alert: getAlert("Password successfully edited."),
        },
      });
    })
    .catch(() => {
      dispatch({
        type: RESET_PASSWORD,
        payload: {
          alert: errorAlert,
        },
      });
    });
};
