import React, {useEffect, useState} from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {useParams} from "react-router-dom";
import services from "../../../services";
import {useSelector} from "react-redux";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import TaskStakeholdersContainer from "../../containers/TaskStakeholdersContainer/TaskStakeholdersContainer";

const TaskStakeholdersPage = () => {
  const {projectId, milestoneId, taskId} = useParams();
  const [data, setData] = useState({});
  const user = useSelector(state => state?.user);
  const isBusinessUser = user.roles.includes(userRoles.business_user);
  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);

  let breadcrumbs = [
    {
      name: "Projects",
      path: "/projects/",
      disabled: (isBusinessUser || isCategoryOwner),
    },
    {
      name: "Project details",
      path: `/projects/${projectId}`
    },
    {
      name: "Milestones",
      path: `/projects/${projectId}?tab=Milestones`
    },
    {
      name: data.name || "Task name",
      path: `/projects/${projectId}/milestones/${milestoneId}/tasks/${taskId}`,
      withoutTranslate: true,
    },
    {
      name: "Stakeholders",
    },
  ];

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    services.projectServices.getTaskDetails(milestoneId, taskId).then(res => {
      if (res.data) setData(res.data);
    });
  };

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <TaskStakeholdersContainer/>
    </DefaultLayout>
  );
};

export default TaskStakeholdersPage;