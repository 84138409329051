import {getConditionResult} from "./getConditionResult";

const addValue = (baseFields, values, preparedValues, key) => {
  if (baseFields.includes(key)) preparedValues[key] = values[key].value;
  else preparedValues.fields.push({
    id: key,
    type: values[key].type,
    value: values[key].value,
  });

  return preparedValues;
};

export const getValues = values => {
  let preparedValues = {
    fields: [],
    answers: [],
  };
  const baseFields = ["request_type_id", "name", "description"];
  for (let key in values) {
    if (
      key !== "attachments" &&
      values[key].value &&
      !values[key].question_id
    ) {
      if (Boolean(values[key].conditions) && values[key].conditions !== "()") {
        if (eval(getConditionResult(values[key].conditions, values))) {
          preparedValues = addValue(baseFields, values, preparedValues, key);
        }
      } else {
        preparedValues = addValue(baseFields, values, preparedValues, key);
      }
    }
    if (values[key]?.question_id) {
      preparedValues.answers.push({
        question_id: values[key].question_id,
        answer: values[key].value,
      });
    }
  }
  return preparedValues;
};