import React from "react"
import s from "./SpendTMTChart.module.scss"
import Skeleton from "@material-ui/lab/Skeleton"
import classNames from "classnames"

const SpendTMTChart = ({ onlyColumns = false }) => {
  const columns = [108, 89, 41, 81, 85, 99, 100, 101, 108, 108, 108, 108]
  return (
    <div
      className={classNames(s.spendTMTChart, { [s.onlyColumns]: onlyColumns })}
    >
      {!onlyColumns && (
        <Skeleton
          variant='text'
          className={s.title}
          animation='wave'
          width={200}
        />
      )}

      <div className={s.columns}>
        {columns.map((height, index) => (
          <Skeleton
            variant='rect'
            className={s.column}
            animation='wave'
            width={28}
            height={height}
            key={index}
          />
        ))}
      </div>
      {!onlyColumns && (
        <div className={s.indicators}>
          <Skeleton
            variant='text'
            className={s.indicator}
            animation='wave'
            width={100}
          />
          <Skeleton
            variant='text'
            className={s.indicator}
            animation='wave'
            width={100}
          />
          <Skeleton
            variant='text'
            className={s.indicator}
            animation='wave'
            width={100}
          />
          <Skeleton
            variant='text'
            className={s.indicator}
            animation='wave'
            width={100}
          />
        </div>
      )}
    </div>
  )
}
export default SpendTMTChart
