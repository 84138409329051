import React from "react";
import s from "./Detail.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";

const Detail = ({ length = 0, className }) => {
  const getItems = () => {
    let bars = [];
    for (let i = 0; i < length; i++) {
      bars.push(
        <li>
          <span>
            <Skeleton
              variant="text"
              className={s.text}
              animation="wave"
              width={140 - i * 10}
            />
          </span>
          <span>
            <Skeleton
              variant="text"
              className={s.text}
              animation="wave"
              width={80}
            />
          </span>
        </li>
      );
    }
    return bars;
  };
  return (
    <ul className={classNames("skeletLoaderWrap", s.wrap, className)}>
      {getItems()}
    </ul>
  );
};

export default Detail;
