import React from "react";
import styles from "./AdminSettingsWrapper.module.scss";
import PropTypes from "prop-types";
import {CircularProgress} from "@material-ui/core";

const AdminSettingsWrapper = ({children, loading}) => {

  if (loading) return (
    <div className="loaderWrap justifyCenter">
      <CircularProgress/>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  );
};

export default AdminSettingsWrapper;

AdminSettingsWrapper.propTypes = {
  children: PropTypes.element,
  loading: PropTypes.bool,
};