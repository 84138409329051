import React, { useEffect, useState } from "react";
import s from "./General.module.scss";
import services from "services";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import Score from "./components/Score/Score";
import TotalTime from "./components/TotalTime/TotalTime";
import FinancialByYear from "components/containers/ProjectContainer/components/FinancialByYear/FinancialByYear";
import NonFinancialBenefits from "components/containers/ProjectContainer/components/ProjectResults/NonFinancialBenefits/NonFinancialBenefits";
import { formatFinancialByYear } from "../../helpers";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const General = ({ className }) => {
  const [data, setData] = useState({});
  const {t} = useTranslation();
  const params = useParams();
  useEffect(() => {
    services.reportServices.getProjectReportsGeneral(params.id).then((res) => {
      setData(formatFinancialByYear(res.data));
    });
  }, []);
  return (
    <div className={classNames(s.wrap, className)}>
      <h3 className="subtitle" data-translate-key={getTranslateKey("General")}>
        {t(getTranslateKey("General"))}
      </h3>
      <div className={s.widgets}>
        <Score nps_score={data.nps_score} score_types={data.score_types} />
        <TotalTime total_time={data.total_time} />
      </div>
      {data?.financial_benefits_by_year && (
        <FinancialByYear
          data={data.financial_benefits_by_year}
          defaultCurrency="USD"
          title="Financial Benefits by year"
        />
      )}
      <div className={s.nonFinancialBenefitsWrap}>
        {data?.non_financial_benefit && (
          <NonFinancialBenefits
            noIteractions={true}
            benefits={data.non_financial_benefit}
          />
        )}
      </div>
    </div>
  );
};

export default General;
