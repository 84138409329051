import React from "react";
import s from "./CategoryInformation.module.scss";
import sBase from "../../ContractDetailsContainer.module.scss";
import Wrapper from "../Wrapper/Wrapper";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const CategoryInformation = ({params}) => {
  const {t} = useTranslation();
  const {categories, category_owner, department, division} = params;

  return (
    <Wrapper>
      <div className={s.header}>
        <h4 className={sBase.sectionTitle} data-translate-key={getTranslateKey("Category information")}>
          {t(getTranslateKey("Category information"))}
        </h4>
      </div>
      <div className={s.infoWrap}>
        <div className={s.infoBlock}>
          <p className={s.title} data-translate-key={getTranslateKey("Category")}>
            {t(getTranslateKey("Category"))}:
          </p>
          <p className={s.value}>{categories.map(c => c.name).join(' / ')}</p>
        </div>
        <div className={s.infoBlock}>
          <p className={s.title} data-translate-key={getTranslateKey("Category owner")}>
            {t(getTranslateKey("Category owner"))}:
          </p>
          <p className={s.value}>{category_owner ? `${category_owner.name} - ${category_owner.email}` : '-'}</p>
        </div>
        <div className={s.infoBlockGroup}>
          <div className={s.infoBlock}>
            <p className={s.title} data-translate-key={getTranslateKey("Category department")}>
              {t(getTranslateKey("Category department"))}:
            </p>
            <p className={s.value}>{department ? department.name : '-'}</p>
          </div>
          <div className={s.infoBlock}>
            <p className={s.title} data-translate-key={getTranslateKey("Category division")}>
              {t(getTranslateKey("Category division"))}:
            </p>
            <p className={s.value}>{division ? division.name : '-'}</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CategoryInformation;
