import React, {useState, useEffect, useRef} from "react";
import s from "./DropdownTree.module.scss";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";
import classnames from "classnames";
import TreeItem from "components/elements/TreeItem/TreeItem";
import useOutsideClicker from "hooks/useOutsideClicker";
import Input from "components/elements/Input/Input";
import {searchCategories, openedCategories} from "helpers/categoriesHelper";
import PropTypes from "prop-types";
import SavingsChart from "../SavingsChart/SavingsChart";
import {useTranslate} from "../../../hooks/useTranslate";

const DropdownTree = (
  {
    name,
    label,
    placeholder,
    defaultValue,
    options = [],
    onSelect,
    onBlur,
    className,
    categoryLevels,
    error,
    withSearch = false,
    onChange,
  }
) => {
  const [selectedOption, setSelectedOption] = useState({
    name: "",
    id: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [translateKey, translateValue] = useTranslate(label);
  const [translateKeyError, translateValueError] = useTranslate(error);

  const handleClose = () => {
    setIsOpen(false);
    onBlur && onBlur(name);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length) {
      const result = searchCategories(e.target.value, [...options]);
      setSearchedOptions(result);
    } else {
      setSearchedOptions(options);
    }
    onChange(e);
  };

  const handleSelect = (item) => {
    setSelectedOption({name: item.name, id: item.id});
    withSearch && setSearchValue(item.name);
    onSelect && onSelect({name: item.name, id: item.id});
    handleClose();
  };

  useOutsideClicker(dropdownRef, handleClose);

  useEffect(() => {
    setSelectedOption(defaultValue);
    withSearch && setSearchValue(defaultValue.name);
  }, [defaultValue]);

  useEffect(() => {
    if (options.length && withSearch) {
      setSearchedOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (withSearch && selectedOption.id && searchedOptions) {
      setSearchedOptions(openedCategories(searchedOptions, selectedOption.id));
    }
  }, [selectedOption.id]);

  return (
    <div
      className={classnames(s.dropdownWrapper, className, {
        [s.withError]: Boolean(error),
      })}
      ref={dropdownRef}
    >
      { label && <span className={s.label}  data-translate-key={translateKey}>{translateValue}</span>}
      {!withSearch ? (
        <div
          className={s.dropdown}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {placeholder && !selectedOption.id && (
            <span className={s.placeholder}>{placeholder}</span>
          )}
          <span className={s.selectedOption}>{selectedOption.name}</span>

          <span className={classnames(s.arrow, {[s.open]: isOpen})}>
            <Arrow/>
          </span>
        </div>
      ) : (
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Input
            icon
            value={searchValue}
            placeholder={placeholder}
            onChange={handleSearch}
            className={s.inputSearch}
          />
        </div>
      )}

      {error && <p className={s.errorMessage} data-translate-key={translateKeyError}>{translateValueError}</p>}

      {isOpen && (
        <div className={s.optionsList}>
          {withSearch &&
          searchedOptions.map((option) => (
            <TreeItem
              categoryLevels={categoryLevels}
              key={option.id}
              item={option}
              handleClick={handleSelect}
              currentCategoryId={selectedOption.id}
            />
          ))}
          {!withSearch &&
          options.map((option) => (
            <TreeItem
              categoryLevels={categoryLevels}
              key={option.id}
              item={option}
              handleClick={handleSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
};

DropdownTree.defaultProps = {
  label: "Category",
  placeholder: "Select Category",
  defaultValue: {
    name: "",
    id: "",
  },
};

export default DropdownTree;

SavingsChart.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  categoryLevels: PropTypes.any,
  error: PropTypes.any,
  withSearch: PropTypes.bool,
  onChange: PropTypes.func,
};