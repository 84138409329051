import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import {useHistory} from "react-router-dom";
import Tags from "components/elements/Tags/Tags";
import {fromDate} from "helpers/dateFormat";
import {currencyData} from "helpers/helpData";
import {formatCurrency} from "helpers/currencyFormat";

const SupplierDetailContractsTable = ({
                                        data,
                                        perPage = 30,
                                        changeQuery,
                                        query,
                                        onAssignTag,
                                        onDeleteTag,
                                      }) => {
  const history = useHistory();

  const onPageChange = page => {
    changeQuery(query => ({
      ...query,
      page
    }));
  };

  const onSort = ({sort_by, direction}) => {
    changeQuery(query => ({...query, sort_by, direction, page: 1}));
  };
  return (
    <MainTable
      data={data.contracts}
      dataTypes={[
        {
          title: "Contract name",
          value: ({name}) => name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          onClick: id => history.push(`/contract/details/${id}`)
        },
        {
          title: "Total contract value",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "total_value", direction});
          },
          value: ({total_value_currency, total_value}) => {
            return total_value && total_value_currency
              ? formatCurrency(
                currencyData.filter(e => e.label === total_value_currency)[0]
                  ?.value,
                total_value
              )
              : "-";
          }
        },
        {
          title: "Annual contract value",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "annual_value", direction});
          },
          value: ({annual_value_currency, annual_value}) => {
            return annual_value && annual_value_currency
              ? formatCurrency(
                currencyData.filter(e => e.label === annual_value_currency)[0]
                  ?.value,

                annual_value
              )
              : "-";
          }
        },
        {
          title: "Start date",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "start_date", direction});
          },
          value: ({start_date}) => fromDate(start_date)
        },
        {
          title: "End date",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "end_date", direction});
          },
          value: ({end_date}) => fromDate(end_date)
        },
        {
          title: "Tags",
          type: "tags",
          value: (item, allData) => (
            <Tags
              allData={allData}
              item={item}
              width={208}
              onDelete={id => onDeleteTag(id, item.id)}
              onAssign={id => onAssignTag(id, item.id)}
              onSort={({name}) =>
                query.tag !== name ? onSort({tag: name}) : onSort({tag: ""})
              }
            />
          )
        }
      ]}
      perPage={perPage}
      totalCount={data.contracts_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={query.page || 1}
    />
  );
};
export default SupplierDetailContractsTable;
