import React from "react"
import {useFormik} from "formik"
import {string, object} from "yup"
import Input from "components/elements/Input/Input"
import Button from "components/elements/Button/Button"

const AdminTaskRolesForm = ({onClose, onSubmit, defaultValues}) => {

  const formik = useFormik({
    initialValues: defaultValues || {
      taskRole: ""
    },
    validationSchema: object({
      taskRole: string()
        .required("Task role is required")
        .max(
          50,
          "The task role is too long. It should be 50 characters or less."
        )
    }),
    onSubmit
  })
  const {values, errors, handleSubmit, handleChange, touched} = formik
  return (
    <form onSubmit={handleSubmit} noValidate={true} autoComplete='off'>
      <Input
        value={values.taskRole}
        onChange={handleChange}
        name='taskRole'
        placeholder='Enter role name'
        label='Role'
        error={errors.taskRole && touched.taskRole && errors.taskRole}
        inputProps={{autoFocus: true}}
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          styled='primary'
          type='submit'
          className='popupBtn'
          disabled={defaultValues && defaultValues?.taskRole === values.taskRole}
        >
          {defaultValues ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  )
}
export default AdminTaskRolesForm
