import React, {useEffect, useState} from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {debounce} from "lodash";
import qs from "query-string";
import {useHistory} from "react-router-dom";
import services from "../../../services";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";

const AdminSettingsUsersFilter = ({filters, className}) => {
  const history = useHistory();
  const [divisions, setDivisions] = useState([]);
  const {...query} = qs.parse(
    history.location.search
  );
  const [savedFilter, setSavedFilter] = useState(null);

  useEffect(() => {
    services.filterService.getFilter("admin_settings_users").then(res => {
      if (res.data && res.data[0]) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      }
    });
    services.userServices.getDivisions().then(res => {
      if (res.data) {
        setDivisions(res.data);
      }
    });
  }, []);

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleClearAll = () => {
    history.push({search: qs.stringify()});
  };

  const saveDefaultFilter = () => {
    services.filterService.addFilter("admin_settings_users", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  };

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  };

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams ?? savedFilter.filter_params),
      page: 1,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };


  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  const capitalLetter = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  };
  return (
    <FilterBar
      filterName="Filter users"
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      withSearch
    >
      <FilterBarDropdown
        name="Status"
        options={filters.statuses ? filters.statuses.map(item => ({name: capitalLetter(item), id: item})) : []}
        defaultValues={query?.statuses ? query?.statuses.split(",") : []}
        handleChange={(values) => {
          handleChangeFilter("statuses", values);
        }}
      />
      <FilterBarDropdown
        name="Type"
        searchPlaceholder="Search type"
        options={filters.user_types ? filters.user_types.map(item => ({name: item, id: item})) : []}
        defaultValues={query?.user_types}
        handleChange={(values) => {
          handleChangeFilter("user_types", values);
        }}
      />
      <FilterBarDropdown
        name="Divisions"
        options={divisions}
        defaultValues={query?.divisions}
        handleChange={(values) => {
          handleChangeFilter("divisions", values);
        }}
      />
      <FilterBarDropdown
        name="Departments"
        searchPlaceholder="Search department"
        options={filters.departments}
        defaultValues={query?.department_ids}
        handleChange={(values) => {
          handleChangeFilter("department_ids", values);
        }}
      />
    </FilterBar>
  );
};

export default AdminSettingsUsersFilter;
