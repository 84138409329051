import React, {useEffect, useState} from "react"
import classNames from "classnames"
import {useFormik} from "formik"
import {object, string} from "yup"
import PropTypes from "prop-types"
import DatePicker from "components/elements/DatePicker/DatePicker"
import s from "./EditProjectFinancialBenefitForm.module.scss"
import Button from "components/elements/Button/Button"
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown"
import CurrencySelectionField from "components/elements/CurrencySelectionField/CurrencySelectionField"
import {formatDate} from "helpers/dateFormat"
import {localization} from "helpers/localizationForCompanies"
import Attachments from "components/forms/AddMilestoneTaskForm/components/Attachments/Attachments"
import services from "services"
import Textarea from "components/elements/Textarea/Textarea"
import {entitiesFormatter} from "../../../helpers/entitiesFormatter";

const EditProjectFinancialBenefitForm = (
  {
    benefit,
    currencies,
    onClose,
    onSubmit
  }
) => {
  const [savingsTypesList, setSavingsTypesList] = useState([]);

  useEffect(() => {
    services.savingsTypeServices.getSavingsTypeList().then(res => {
      if (res.data) {
        setSavingsTypesList(res.data.map(type => entitiesFormatter(type)))
      }
    });
  }, []);

  const NOTE_MAX_LENGTH = 100
  const {
    start_date,
    end_date,
    project_spend,
    project_savings,
    project_spend_currency,
    savings_type,
    note,
    attachments
  } = benefit
  const formik = useFormik({
    initialValues: {
      project_spend,
      project_savings,
      currency: currencies.filter(e => e.label === project_spend_currency)[0],
      savings_type: savings_type.id || "",
      start_date: new Date(start_date),
      end_date: new Date(end_date),
      note: note || "",
      attachments: attachments || []
    },
    validationSchema: object({
      project_spend: string()
        .required(`Please enter ${localization.finalCost}`)
        .max(12, "Max digits quantity is 12"),
      project_savings: string()
        .required(`Please enter ${localization.finalSavings}`)
        .max(12, "Max digits quantity is 12"),
      savings_type: string().required(
        `Please choose ${localization.savingstype}`
      ),
      start_date: string()
        .required("Savings start date is required")
        .nullable(),
      end_date: string()
        .required("Savings end date is required")
        .nullable(),
      note: string().max(
        NOTE_MAX_LENGTH,
        `The Note is too long. It should be ${NOTE_MAX_LENGTH} characters or less.`
      )
    }),
    validate: values => {
      const errors = {}
      if (values.project_spend < 0)
        errors.project_spend = "Value should be equal to or greater than 0"
      if (values.project_savings < 0)
        errors.project_savings = "Value should be equal to or greater than 0"
      return errors
    },
    onSubmit: values => {
      onSubmit(benefit.id, {
        project_spend: values.project_spend,
        project_savings: values.project_savings,
        project_spend_currency: values.currency.label,
        project_savings_currency: values.currency.label,
        savings_type_id: values.savings_type,
        start_date: formatDate(values.start_date),
        end_date: formatDate(values.end_date),
        note: values.note,
        attachments: values.attachments.filter(file => file.lastModifiedDate)
      })
      onClose()
    }
  })

  const {
    handleSubmit,
    touched,
    values,
    errors,
    setFieldValue,
    handleChange
  } = formik
  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files])
  }
  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      )
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        )
      })
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className='dateBlock'>
        <DatePicker
          label='Savings start date'
          selected={values.start_date}
          onChange={date => {
            setFieldValue("start_date", date)
            date > values.end_date && setFieldValue("end_date", date)
          }}
          selectsStart
          startDate={values.start_date}
          endDate={values.end_date}
          placeholderText='Choose Savings start date'
          wrapperClassName='wrapperdatePicker'
          className='customInput'
          todayButton='Go to today'
          showYearDropdown
          dateFormatCalendar='MMMM'
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.start_date && errors.start_date}
        />

        <DatePicker
          label='Savings end date'
          selected={values.end_date}
          onChange={date => setFieldValue("end_date", date)}
          selectsEnd
          startDate={values.start_date}
          endDate={values.end_date}
          minDate={values.start_date}
          placeholderText='Choose Savings end date'
          wrapperClassName='wrapperdatePicker'
          className='customInput'
          todayButton='Go to today'
          showYearDropdown
          dateFormatCalendar='MMMM'
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.end_date && errors.end_date}
        />
      </div>
      <CurrencySelectionField
        className={s.selectionField}
        options={currencies}
        value={values.project_spend}
        defaultValue={values.currency}
        onChange={({fieldVal, ...currency}) => {
          setFieldValue("currency", currency)
          setFieldValue("project_spend", fieldVal)
        }}
        error={touched.project_spend && errors.project_spend}
        name='project_spend'
        label={`${localization.finalCost}`}
        fieldPlaceholder='000,000'
      />
      <CurrencySelectionField
        className={s.selectionField}
        options={currencies}
        value={values.project_savings}
        defaultValue={values.currency}
        onChange={({fieldVal, ...currency}) => {
          setFieldValue("currency", currency)
          setFieldValue("project_savings", fieldVal)
        }}
        error={touched.project_savings && errors.project_savings}
        name='project_savings'
        label={`${localization.finalSavings}`}
        fieldPlaceholder='000,000'
      />
      <div className={s.inputWrap}>
        <CustomDropdown
          options={savingsTypesList}
          value={values.savings_type}
          onChange={option => setFieldValue("savings_type", option.value)}
          label={`${localization.savingstype}`}
          placeholder='Choose type'
          controlClasses={classNames({[s.dropdown]: !values.savings_type})}
          error={touched.savings_type && errors.savings_type}
        />
      </div>
      <div className={s.inputWrap}>
        <Attachments
          onChange={handleChangeAttachment}
          onDelete={handleDeleteAttachment}
          attachments={values.attachments}
          // filesMaxLength='infinity'
        />
      </div>
      <div
        className={classNames(s.countField, {
          [s.countWrap]: errors.note && touched.note
        })}
      >
        <Textarea
          label='Note'
          value={values.note}
          error={errors.note && touched.note && errors.note}
          onChange={handleChange}
          name='note'
          placeholder='Enter a note'
          count={{
            current: values.note.length,
            max: NOTE_MAX_LENGTH
          }}
        />
      </div>
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          Save
        </Button>
      </div>
    </form>
  )
}

EditProjectFinancialBenefitForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  currency: PropTypes.array
}
EditProjectFinancialBenefitForm.defaultProps = {
  onClose: () => {
  },
  onSubmit: () => {
  },
  currencies: []
}

export default EditProjectFinancialBenefitForm
