import React from "react";
import PropTypes from "prop-types";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import styles from "../RadioGroupField/RadioGroupField.module.scss";

const BooleanField = ({values, setFieldValue, field, errors}) => {
  return (
    <>
      <p>{field.label}</p>
      <RadioGroup
        value={values[field.key]?.value}
        onChange={e => setFieldValue(field.key, {value: e.target.value, type: "Boolean", conditions: field.conditions})}
      >
        <div style={{display: "flex"}}>
          <FormControlLabel value={"true"} control={<Radio color="primary"/>} label={"Yes"}/>
          <FormControlLabel value={"false"} control={<Radio color="primary"/>} label={"No"}/>
        </div>
      </RadioGroup>
      {errors[field.key] && <span className={styles.errorMessage}>{errors[field.key].value}</span>}
    </>
  );
};

export default BooleanField;

BooleanField.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any
};