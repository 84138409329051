import React, {useState, useEffect} from "react";
import ReportingTagSuppliersTable from "components/tables/ReportingTagSuppliersTable/ReportingTagSuppliersTable";
import services from "services";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import qs from "query-string";
import {useParams, useHistory, useLocation} from "react-router-dom";
import TableLoading from "components/loadingStates/Table/Table";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Suppliers = ({className}) => {
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const query = qs.parse(history.location.search);

  useEffect(() => {
    services.reportsServices
      .tagReportSuppliers(params.id, query)
      .then((res) => {
        setData(res.data.suppliers);
        setDataCount(res.data.suppliers_count);
        setLoading(false);
      });
  }, [location.search]);

  const onPageChange = (page) => {
    const query = qs.parse(history.location.search);
    history.push({search: qs.stringify({...query, page})});
  };

  return (
    <div className={className}>
      <h3
        className="subtitle"
        data-translate-key={getTranslateKey("Suppliers")}
      >
        {t(getTranslateKey("Suppliers"))}
      </h3>
      {!loading && data.length > 0 && (
        <ReportingTagSuppliersTable
          data={data}
          dataCount={dataCount}
          perPage={30}
          currentPage={query?.page || 1}
          onPageChange={onPageChange}
        />
      )}
      {loading && <TableLoading length={8}/>}
      {!loading && !data.length && (
        <EmptyBlock title="You don’t have any suppliers yet." message/>
      )}
    </div>
  );
};

export default Suppliers;
