import React from "react"
import MainTable from "components/tables/MainTable/MainTable"
import { useHistory } from "react-router-dom"
import { formatUserRoles } from "helpers/userPermissions/formatUserRoles"

const SupplierDetailStakeholdersTable = ({
  data,
  perPage = 30,
  changeQuery,
  query
}) => {
  const history = useHistory()

  const onPageChange = page => {
    changeQuery(query => ({
      ...query,
      page
    }))
  }

  const onSort = ({ sort_by, direction }) => {
    changeQuery(query => ({ ...query, sort_by, direction, page: 1 }))
  }
  return (
    <MainTable
      data={data.stakeholders}
      dataTypes={[
        {
          title: "Name",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "name", direction })
          },
          value: ({ name }) => name,
          onClick: id => history.push(`/users/${id}`)
        },
        {
          title: "Title",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "title", direction })
          },
          value: ({ title }) => title || "-"
        },
        {
          title: "Division",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "division", direction })
          },
          value: ({ division }) => division?.name || "-"
        },
        {
          title: "Department",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "department", direction })
          },
          value: ({ department }) => department?.name || "-"
        },
        {
          title: "Role",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "role", direction })
          },
          value: ({ roles }) =>
            roles
              ? formatUserRoles(roles)
                  .map(e => e.label)
                  .join(", ")
              : "-"
        }
      ]}
      perPage={perPage}
      totalCount={data.stakeholders?.length}
      onPageChange={onPageChange}
      isPagination={true}
      page={query.page || 1}
    />
  )
}
export default SupplierDetailStakeholdersTable
