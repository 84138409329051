import React from "react";
import {ReactComponent as Arrow} from "../../../../assets/icons/arrowDown.svg";
import {components} from "react-select";

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Arrow/>
    </components.DropdownIndicator>
  );
};