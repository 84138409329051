import React, {useCallback, useEffect, useState} from "react";
import s from "./ContractForm.module.scss";
import {useFormik} from "formik";
import {object, string} from "yup";
import services from "../../../services";
import {debounce} from "lodash";
import {currencyData as currencies} from "../../../helpers/helpData";
import CurrencySelectionField from "../../elements/CurrencySelectionField/CurrencySelectionField";
import Button from "../../elements/Button/Button";
import {useHistory} from "react-router-dom";
import {recursiveJoin} from "../../../helpers/recursiveJoin";
import AutoCompleteInput from "../../elements/AutoCompleteInput/AutoCompleteInput";
import CustomDropdown from "../../elements/CustomDropdown/CustomDropdown";
import Input from "../../elements/Input/Input";
import Divider from "../../elements/Divider/Divider";
import DatePicker from "../../elements/DatePicker/DatePicker";
import {localization} from "../../../helpers/localizationForCompanies";
import {load} from "../../../helpers/localStorage";
import PropTypes from "prop-types";
import {userRoles as orgRoles} from "helpers/userPermissions/userRoles";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {checkRoles} from "../../../helpers/checkRoles";

const ContractForm = (
  {
    onCreate,
    onUpdate,
    defaultValues,
  }
) => {

  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersProcurement, setUsersProcurement] = useState([]);
  const user = load("currentUser");
  const history = useHistory();
  const {t} = useTranslation();
  const handleSearchSupplier = query => {
    services.supplierServices
      .suppliersAutocomplete({query})
      .then(res => setSuppliers(res.data));
  };
  const preparedOwner = !checkRoles(user.roles, orgRoles.admin) && {name: user?.name, id: user?.id};

  useEffect(() => {
    services.categoriesServices
      .getCategories()
      .then(res => setCategories(recursiveJoin(res.data, "categories")));
  }, []);

  const handleSearchBusinessUsers = useCallback(
    debounce(
      (term, roles) =>
        services.userServices
          .stakeholdersAutocomplete({query: term, roles})
          .then(res => setUsers(res.data)),
      300
    ),
    []
  );
  const handleSearchProcurementUsers = useCallback(
    debounce(
      (term, roles) =>
        services.userServices
          .stakeholdersAutocomplete({query: term, roles})
          .then(res => setUsersProcurement(res.data)),
      300
    ),
    []
  );
  const formik = useFormik({
    initialValues: defaultValues || {
      supplier_id: {name: "", id: ""},
      category_id: {name: "", id: ""},
      parent_contract_id: {value: "", label: ""},
      name: "",
      start_date: new Date(),
      end_date: new Date(),
      business_owner_id: {name: "", id: ""},
      procurement_owner_id: preparedOwner ? preparedOwner : "",
      total_value: "",
      total_value_currency: currencies.filter(e => e.label === ("USD"))[0],
    },
    validationSchema: object({
      supplier_id: object({
        name: string().required("Please, choose supplier"),
        id: string().required("Please, choose supplier")
      }),
      parent_contract_id: object({
        value: string().required("Project type is required"),
      }),
      name: string().required("Name is required")
        .max(32, "Contract name should be 32 characters or less"),
      start_date: string()
        .required("Start date is required")
        .nullable(),
      end_date: string()
        .required("End date is required")
        .nullable(),
      business_owner_id: object({
        name: string().required(
          `Please, enter ${localization.businessOwner}`
        ),
        id: string().required(
          `Please, choose exist ${localization.businessOwner}`
        )
      }),
      procurement_owner_id: object({
        name: string().required(
          `Please, enter ${localization.procurementOwner}`
        ),
        id: string().required(
          `Please, choose exist ${localization.procurementOwner}`
        )
      }),
      total_value: string().required("Total contract value is required"),
      note: string()
        .max(300, "The Note is too long. It should be 300 characters or less."),
    }),
    validate: values => {
      let errors = {category_id: {}};
      if (values?.parent_contract_id?.value === "none" && values?.category_id.name === "") {
        errors.category_id.name = "Please, choose category";
      } else {
        errors = {};
      }
      return errors;
    },
    onSubmit: values => {
      defaultValues?.id ? onUpdate(values) : onCreate(values);
    }
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
  } = formik;

  useEffect(() => {
    services.contractsServices.getContracts({supplier_ids: values.supplier_id.id}).then(res => {
      setContracts(res.data.contracts || []);
    });
  }, [values.supplier_id]);

  useEffect(() => {
    if (values.parent_contract_id.value !== "none" && values.parent_contract_id.value !== "") {
      const preparedContract = contracts.find(contract => contract.id === values.parent_contract_id.value);
      if (preparedContract) {
        setFieldValue("supplier_id", {
          id: preparedContract.supplier.id,
          name: preparedContract.supplier.name,
        });
      }
    }
  }, [values.parent_contract_id]);

  return (
    <form
      className={s.addContractForm}
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div className={s.formContent}>
        <header className={s.header}>
          <h3 data-translate-key={!onCreate ? "edit-contract" : "new-contract"}> {t(getTranslateKey(!onCreate ? "edit-contract" : "new-contract"))}</h3>
          <div>
            <Button
              styled="secondary"
              type="reset"
              className="popupBtn"
              onClick={() => history.push("/contracts")}
            >
              Cancel
            </Button>
            <Button styled="primary" type="submit" className="popupBtn">
              {defaultValues?.id ? "Update" : "Create"}
            </Button>
          </div>
        </header>
        <div className={s.half}>
          <AutoCompleteInput
            value={values.supplier_id.name}
            onChange={e => {
              setFieldValue("supplier_id", {
                id: "",
                name: e.target.value
              });
              handleSearchSupplier(e.target.value);
            }}
            onSelect={({id, name}) => {
              setFieldValue("supplier_id", {
                id,
                name
              });
            }}
            name="supplier"
            data={suppliers}
            placeholder="Choose supplier"
            label="Supplier"
            labelGrey={(values.parent_contract_id.value !== "none" && values.parent_contract_id.value !== "") ? "(Supplier information is taken from the parent contract)" : ""}
            disabled={(values.parent_contract_id.value !== "none" && values.parent_contract_id.value !== "")}
            error={
              errors.supplier_id &&
              touched.supplier_id &&
              (errors.supplier_id.name || errors.supplier_id.id)
            }
          />
          {
            values.parent_contract_id.value === "none" && (
              <AutoCompleteInput
                value={values.category_id.name}
                onChange={e => {
                  setFieldValue("category_id", {
                    id: "",
                    name: e.target.value
                  });
                }}
                onSelect={({id, name}) => {
                  setFieldValue("category_id", {
                    id,
                    name
                  });
                }}
                name="category"
                data={categories}
                placeholder="Choose category"
                label="Category"
                error={
                  errors.category_id &&
                  touched.category_id &&
                  (errors.category_id.name || errors.category_id.id)
                }
              />
            )
          }
        </div>
        <div className={s.half}>
          <CustomDropdown
            options={[{
              value: "none",
              label: "None"
            }, ...recursiveJoin(contracts || [], "contracts").map(c => ({value: c.id, label: c.name}))]}
            value={values.parent_contract_id}
            onChange={option => setFieldValue("parent_contract_id", option)}
            label="Parent contract"
            placeholder="Choose contract"
            error={touched.parent_contract_id && errors.parent_contract_id && errors.parent_contract_id.value}
          />
          <Input
            label="Contract name"
            value={values.name}
            placeholder="Enter contract name"
            onChange={(e) => {
              setFieldValue("name", e.target.value);
            }}
            error={touched.name && errors.name}
          />
        </div>
      </div>
      <Divider background="#C6C6C6" margin={25}/>
      <div className={s.half}>
        <div className="dateBlock">
          <DatePicker
            label="Start date"
            selected={values.start_date}
            onChange={date => {
              setFieldValue("start_date", date);
              date > values.end_date && setFieldValue("end_date", date);
            }}
            selectsStart
            startDate={values.start_date}
            endDate={values.end_date}
            placeholderText="Choose start date"
            wrapperClassName="fullPicker"
            className="customInput fullInput"
            classWrap="fullWrap"
            todayButton="Go to today"
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={5}
            scrollableYearDropdown
            error={touched.start_date && errors.start_date}
          />
        </div>
        <div className="dateBlock">
          <DatePicker
            label="End date"
            selected={values.end_date}
            onChange={date => setFieldValue("end_date", date)}
            selectsEnd
            startDate={values.start_date}
            endDate={values.end_date}
            minDate={values.start_date}
            placeholderText="Choose end date"
            wrapperClassName="fullPicker"
            className="customInput fullInput"
            classWrap="fullWrap"
            todayButton="Go to today"
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={5}
            scrollableYearDropdown
            error={touched.end_date && errors.end_date}
          />
        </div>
      </div>
      <Divider background="#C6C6C6" margin={25}/>
      <div className={s.half}>
        <AutoCompleteInput
          value={values.business_owner_id.name}
          onChange={e => {
            setFieldValue("business_owner_id", {
              id: "",
              name: e.target.value
            });
            handleSearchBusinessUsers(e.target.value, "category_business_owner");
          }}
          onSelect={value => {
            setFieldValue("business_owner_id", {
              id: value.id,
              name: value.name
            });
          }}
          name="owner_id"
          data={users}
          placeholder={`Enter ${localization.businessOwner}`}
          label={`${localization.businessOwner}`}
          error={
            errors.business_owner_id &&
            touched.business_owner_id &&
            (errors.business_owner_id.name || errors.business_owner_id.id)
          }
        />
        <AutoCompleteInput
          value={values.procurement_owner_id?.name || ""}
          onChange={e => {
            setFieldValue("procurement_owner_id", {
              id: "",
              name: e.target.value
            });
            handleSearchProcurementUsers(e.target.value, "procurement_category_owner,cpo,director,procurement_manager,procurement_admin,admin");
          }}
          onSelect={value => {
            setFieldValue("procurement_owner_id", {
              id: value.id,
              name: value.name
            });
          }}
          name="owner_id"
          data={usersProcurement}
          placeholder={`Enter ${localization.procurementOwner}`}
          label={`${localization.procurementOwner}`}
          error={
            errors.procurement_owner_id &&
            touched.procurement_owner_id &&
            (errors.procurement_owner_id.name || errors.procurement_owner_id.id)
          }
        />
      </div>
      <div className={s.half}>
        <CurrencySelectionField
          options={currencies}
          value={values.total_value}
          onChange={({fieldVal, value, label}) => {
            const currency = {
              value,
              label
            };
            currency.value && setFieldValue("total_value_currency", currency);
            setFieldValue("total_value", fieldVal);
          }}
          defaultValue={values.total_value_currency.value}
          error={errors.total_value}
          name="total_value"
          label="Total contract value"
          fieldPlaceholder="000,000"
        />
      </div>
    </form>
  );
};

export default ContractForm;

ContractForm.propTypes = {
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  defaultValues: PropTypes.any,
};
