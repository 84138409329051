import React from "react";
import UserFormWrapper from "components/wrappers/UserFormWrapper/UserFormWrapper";
import Button from "components/elements/Button/Button";
import CustomLink from "components/elements/CustomLink/CustomLink";
import s from "./Success.module.scss";

const Success = () => {
  return (
    <UserFormWrapper className={s.container}>
      <h1 className={s.title}>Your password is changed</h1>
      <p className={s.text}>Summary of what you’ve completed:</p>
      <p className={s.boldText}>Your password was changed</p>
      <div className={s.wrapper}>
        <Button styled="primary">
          <CustomLink type="nav-link" path="/login" className={s.link}>
            Sign in
          </CustomLink>
        </Button>
      </div>
    </UserFormWrapper>
  );
};

export default Success;
