import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { CircularProgress } from "@material-ui/core"
import querystring from "query-string"
import styles from "./SurveyContainer.module.scss"
import SurveyWidget from "./components/SurveyWidget/SurveyWidget"
import Detail from "components/elements/Detail/Detail"
import Comment from "components/elements/Comment/CommentContainer"
import Button from "components/elements/Button/Button"
import Popup from "components/popups/Popup/Popup"
import NotificationBox from "components/elements/NotificationBox/NotificationBox"
import services from "services/index"
import summaryData from "./helpers/summaryData"

const SurveyContainer = ({ setBreadcrumbs }) => {
  const params = useParams()
  const history = useHistory()
  const [project, setProject] = useState(null)
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [isHasErrors, setErrorStatus] = useState(false)
  const [comment, setComment] = useState("")
  const [rating, setRating] = useState(null)
  const [alert, setAlert] = useState({ message: "", type: "" })

  const [isAnswered, setIsAnswered] = useState(false)

  useEffect(() => {
    services.surveyServices.getSurvey(params.id, params.surveyId).then(res => {
      const { value, comment } = res.data.survey_result
      setProject(res.data.project_summary)
      setRating(value)
      setComment(comment || "")
      setIsAnswered(value ? true : false)
      setLoading(false)
      setBreadcrumbs(state => [
        ...state,
        {
          name: "Project details",
          path: `/projects/${params.id}`
        },
        { name: "Survey results" }
      ])
    })
  }, [params.id])

  const commentHandler = async ({ results_comment }) => {
    setComment(results_comment)
  }

  const ratingHandler = rate => {
    setRating(rate)
  }

  const sendHandler = () => {
    if (rating) {
      services.surveyServices
        .updateSurvey(params.id, params.surveyId, { comment, value: rating })
        .then(() => {
          setShow(false)
          history.push(`/projects/${params.id}`)
        })
      history.push({
        pathname: `/projects/${params.id}`,
        search: querystring.stringify({ tab: "Survey" })
      })
    } else {
      setShow(false)
      setAlert({
        message: "Please, choose your answer",
        type: "error"
      })
    }
  }

  return (
    <div className={styles.container}>
      {loading && (
        <div className='loaderWrap'>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <>
          <h2 className={styles.title}>Survey for {project.name}</h2>
          <div className={styles.wrapper}>
            <div className={styles.wrap}>
              <SurveyWidget
                onChange={ratingHandler}
                value={rating}
                allowed={!isAnswered}
              />

              <Comment
                onAddComment={commentHandler}
                comment={comment}
                allowed={!isAnswered}
                className={styles.comment}
                submitOnBlur
                onErrors={setErrorStatus}
              />

              {!isAnswered && (
                <Button
                  styled='primary'
                  onClick={() => setShow(true)}
                  className={styles.btn}
                  disabled={isHasErrors}
                >
                  Send
                </Button>
              )}
            </div>
            <Detail
              data={summaryData(project)}
              title='Summary'
              className={styles.summary}
            />
          </div>
        </>
      )}
      {show && (
        <Popup
          title='Send survey results'
          text='Are you sure you want to send this survey results?
          You can’t change your answers after this action.'
          onClose={() => setShow(false)}
          onClick={sendHandler}
          dialog
          isDelete
          successBtnTitle='Send'
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert({ message: "", type: "" })}
        />
      )}
    </div>
  )
}

export default SurveyContainer
