import React, {useEffect, useState, Fragment} from "react";
import classNames from "classnames";
import s from "./ChangeUserForm.module.scss";
import Button from "components/elements/Button/Button";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import Input from "components/elements/Input/Input";
import PasswordInput from "components/elements/PasswordInput/PasswordInput";
import {useFormik} from "formik";
import services from "services";
import {
  schemaManagement,
  schemaGeneral,
  schemaPassword
} from "./helpers/schemas";
import {userRoles as roles} from "helpers/userPermissions/userRoles";
import AutoCompleteInput from "../../elements/AutoCompleteInput/AutoCompleteInput";
import {userRoles, userTypes} from "../../../helpers/usersData";
import Checkbox from "../../elements/Checkbox/Checkbox";
import {emptyValues} from "./helpers/helpData";
import {useDidUpdate} from "../../../hooks/useDidUpdate";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import RSSelect from "../../elements/ReactSelectCustom/RSSelect";

const ChangeUserForm = ({onSubmit, defaultValues, onClose, divisions}) => {
  const [managers, setManagers] = useState([]);
  const [activeTab, setActiveTab] = useState("general");
  const [departments, setDepartments] = useState([]);
  const [cpoState, setCPOState] = useState([]);
  const {t} = useTranslation();
  const {procurement_admin, director, procurement_manager, procurement_category_owner} = roles;

  useEffect(() => {
    services.userServices
      .getDepartments()
      .then(res =>
        setDepartments(
          res.data.map(({name, id}) => ({name, value: name, id}))
        )
      );
    services.userServices
      .getUserCPO()
      .then(res =>
        setCPOState(res.data.cpo)
      );

  }, []);

  const tabs = ["general", "management"];
  const currentSchema = activeTab === tabs[0] ? schemaGeneral : schemaManagement;
  const formatUser = user => {
    return {
      first_name: user.first_name,
      last_name: user.last_name,
      status: user.status ? "inactive" : "active",
      ready_for_conversation: user.ready_for_conversation,
      email: user.email,
      title: user.title,
      division_id: divisions.filter(e => e.name === user.division.label)?.[0]?.id,
      department_id: user.department?.id,
      password: user.password,
      user_type: user.type.label.toLowerCase(),
      role: user.role.label
        .split(" ")
        .join("_")
        .toLowerCase(),
      team_name: user.team_name,
      parent_id: user.manager?.value
    };
  };

  const divisionsItems = divisions?.filter(item => item.name !== null).map(({name}) => ({
    label: name,
    value: name
  }));

  const getDefaultValues = () => {
    if (defaultValues) {
      return {
        ...defaultValues,
        first_name:
          defaultValues?.first_name || defaultValues.name.split(" ")[0],
        last_name:
          defaultValues?.last_name || defaultValues.name.split(" ")?.[1] || "",
        type: userTypes.find(itm => itm.value === defaultValues.user_type),
        manager: defaultValues.parent && {
          value: defaultValues.parent.id,
          label: defaultValues.parent.name
        },
        status: defaultValues.status === "inactive",
      };
    } else return null;
  };

  const formik = useFormik({
    initialValues: getDefaultValues() || emptyValues,
    validationSchema: defaultValues
      ? currentSchema
      : currentSchema.concat(schemaPassword),
    validate: values => {
      let errors = {};
      if (generalTabHaveErrors && !managementTabHaveErrors)
        setActiveTab("general");
      if (
        (values.role.value === procurement_manager ||
          values.role.value === procurement_category_owner) &&
        activeTab === tabs[1] &&
        values.manager &&
        !values.manager.value.length &&
        managers.length > 0
      ) {
        errors.manager = "Superior manager is required";
      }
      return errors;
    },
    onSubmit: (values, {setSubmitting, setFieldError}) => {
      if (activeTab === tabs[1]) {
        onSubmit(formatUser(values))
          .then(() => {
            setSubmitting(false);
          })
          .catch(e => {
            setFieldError("email", e.response.data.error);
            setActiveTab("general");
            setSubmitting(false);
          });
      } else {
        setActiveTab(tabs[1]);
      }
    }
  });

  const {
    setFieldValue,
    handleSubmit,
    values,
    touched,
    errors,
    setErrors
  } = formik;

  const generalTabHaveErrors =
    (touched.first_name && errors.first_name) ||
    touched.last_name && errors.last_name ||
    touched.title && errors.title ||
    touched.email && errors.email ||
    touched.password && errors.password;
  const managementTabHaveErrors =
    errors.type ||
    errors.division ||
    errors.department ||
    errors.role ||
    errors.manager;

  const isUserGroup = values.role.value.includes(procurement_admin) ||
    values.role.value.includes(director) ||
    values.role.value.includes(procurement_manager) ||
    values.role.value.includes(procurement_category_owner);

  const loadManagers = () => {
    services.userServices
      .managers()
      .then(res => {
        const data = res.data.map(({id, name}) => ({
          value: id,
          label: name
        }));
        setManagers(data);
      });
  };

  useEffect(() => {
    if (isUserGroup && defaultValues) {
      loadManagers();
    }
  }, []);

  useDidUpdate(() => {
    if (isUserGroup) {
      loadManagers();
    } else {
      setFieldValue("manager", {value: "", label: ""});
    }
    if (!managers.length && activeTab === tabs[1]) {
      const {managers, ...spread} = errors;
      setErrors({...spread});
    }
  }, [values.role.value]);

  useDidUpdate(() => {
    setFieldValue("role", {
      value: "",
      name: "",
    });
  }, [values.type]);
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <div className={s.tabs}>
        <span
          className={classNames(s.tab, {[s.active]: activeTab === tabs[0]})}
          onClick={() => setActiveTab(tabs[0])}
          data-translate-key={getTranslateKey("step-1---general-information")}
        >
           {t(getTranslateKey("step-1---general-information"))}
        </span>
        <span
          className={classNames(s.tab, {
            [s.active]: activeTab === tabs[1]
          })}
          onClick={() => setActiveTab(tabs[1])}
          data-translate-key={getTranslateKey("step-2---management")}
        >
          {t(getTranslateKey("step-2---management"))}
        </span>
      </div>
      {activeTab === tabs[0] && (
        <Fragment>
          <Input
            className={classNames(s.field, {
              [s.withError]: errors.first_name && touched.first_name
            })}
            name="first_name"
            label="First name"
            value={values.first_name}
            placeholder="Enter first name"
            onChange={e => {
              setFieldValue("first_name", e.target.value);
            }}
            error={touched.first_name && errors.first_name}
            limitValue={50}
            withCounter
          />
          <Input
            className={classNames(s.field, {
              [s.withError]: errors.last_name && touched.last_name
            })}
            name="last_name"
            label="Last name"
            value={values.last_name}
            placeholder="Enter last name"
            onChange={e => {
              setFieldValue("last_name", e.target.value);
            }}
            error={touched.last_name && errors.last_name}
            limitValue={50}
            withCounter
          />
          <Input
            className={classNames(s.field, {
              [s.withError]: errors.title && touched.title
            })}
            name="title"
            label="Title"
            value={values.title}
            placeholder="Enter user title"
            onChange={e => {
              setFieldValue("title", e.target.value);
            }}
            error={touched.title && errors.title}
            limitValue={32}
            withCounter
          />
          <Input
            className={classNames(s.field, {
              [s.withError]: errors.email && touched.email
            })}
            email="email"
            label="Email"
            value={values.email}
            placeholder="Enter email"
            onChange={e => {
              setFieldValue("email", e.target.value);
            }}
            error={touched.email && errors.email}
            limitValue={32}
            type="email"
            withCounter
          />
          {!defaultValues && (
            <PasswordInput
              className={classNames(s.fieldPas, {
                [s.withError]: errors.password && touched.password
              })}
              label="Password"
              placeholder="Enter password"
              onChange={value => setFieldValue("password", value)}
              value={values.password}
              error={touched.password && errors.password}
              inputProps={{autoComplete: "new-password"}}
            />
          )}
          <CustomDropdown
            value={values.type?.label}
            onChange={e => setFieldValue("type", e)}
            options={userTypes}
            name="type"
            label="Type"
            className={s.dropdown}
            placeholder="Choose type"
            error={errors.type && touched.type && errors.type?.value}
          />
        </Fragment>
      )}
      {tabs[1] === activeTab && (
        <Fragment>
          <CustomDropdown
            value={values.division?.label}
            onChange={e => setFieldValue("division", e)}
            options={divisionsItems}
            name="division"
            label="Division"
            className={s.dropdown}
            placeholder="Choose division"
            error={
              errors.division && touched.division && errors.division?.value
            }
          />
          <AutoCompleteInput
            value={values.department.value}
            onChange={e => {
              setFieldValue("department", {
                id: "",
                name: e.target.value,
                value: e.target.value
              });
            }
            }
            onSelect={({id, name}) => {
              setFieldValue("department", {
                id,
                name,
                value: name
              });
            }}
            name="department"
            className={s.dropdown}
            data={departments || []}
            placeholder="Choose department"
            label="Department"
            error={
              errors.department &&
              touched.department &&
              (errors.department.name || errors.department.id)
            }
          />
          {
            <CustomDropdown
              disabled={!values.type?.value}
              value={values.role?.label}
              onChange={e => {
                setFieldValue("role", e);
              }}
              options={cpoState
                ? userRoles.filter(e => e.label !== "CPO").filter(role => role.userType.value === values.type?.value)
                : userRoles.filter(role => role.userType.value === values.type?.value)}
              name="role"
              label="Role"
              className={s.dropdown}
              placeholder="Choose role"
              error={errors.role && touched.role && errors.role?.value}
            />
          }
          <RSSelect
            className={isUserGroup ? s.dropdown : s.disabled}
            defaultValue={values.manager}
            label="Superior manager"
            onChange={item => setFieldValue("manager", item)}
            options={managers.filter(manager => defaultValues.name !== manager.label)}
            placeholder="Choose Superior Manager"
            error={errors.manager && touched.manager && errors.manager}
          />
          <Checkbox
            label="Inactive user"
            subLabel="This user will not appear in the lists and will not participate in projects."
            onChange={e => setFieldValue("status", e.target.checked)}
            name="statuses"
            className={s.checkbox}
            value={values.status}
            checked={values.status}
            withTranslate
          />
          <Checkbox
            label="Ready for a conversation"
            subLabel="This user will be able to receive all messages and communicate."
            onChange={e => setFieldValue("ready_for_conversation", e.target.checked)}
            name="ready_for_conversation"
            className={s.checkbox}
            value={values.ready_for_conversation}
            checked={values.ready_for_conversation}
            withTranslate
          />
        </Fragment>
      )}

      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        {activeTab === tabs[0] && (
          <Button type="submit" styled="primary" className="popupBtn">
            Next
          </Button>
        )}
        {activeTab === tabs[1] && (
          <Button styled="primary" type="submit" className="popupBtn">
            {defaultValues ? "Save" : "Create"}
          </Button>
        )}
      </div>
    </form>
  );
};
export default ChangeUserForm;
