import axios from "./axios";

export default {
  getEmailTemplates: async params => {
    try {
      return await axios.get('/admin/email_templates', {params});
    } catch (error) {
      throw error;
    }
  },

  changeEmailTemplateStatus: async (id, status) => {
    try {
      return await axios.put(`/admin/email_templates/${id}/change_status`, {status});
    } catch (error) {
      throw error;
    }
  },

  getEmailTemplate: async id => {
    try {
      return await axios.get(`/admin/email_templates/${id}`);
    } catch (error) {
      throw error;
    }
  },

  changeEmailTemplate: async (id, params) => {
    try {
      return await axios.put(`/admin/email_templates/${id}`, params);
    } catch (error) {
      throw error;
    }
  },

  resetEmailTemplate: async id => {
    try {
      return await axios.put(`/admin/email_templates/${id}/reset`);
    } catch (error) {
      throw error;
    }
  },

  getEmailFields: async id => {
    try {
      return await axios.get(`/admin/email_templates/${id}/fields`);
    } catch (error) {
      throw error;
    }
  },
}