import React from "react";
import PreviewProjectContainer from "components/containers/PreviewProjectContainer/PreviewProjectContainer";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";

const PreviewProjectPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: "/procurement-activity",
          name: "Procurement activity",
        },
        {
          name: "Project details",
        },
      ]}
    >
      <PreviewProjectContainer />
    </DefaultLayout>
  );
};
export default PreviewProjectPage;
