import React from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import Textarea from "components/elements/Textarea/Textarea";
import Button from "components/elements/Button/Button";
import Attachments from "components/forms/AddMilestoneTaskForm/components/Attachments/Attachments";
import services from "services";
import PropTypes from "prop-types";

const NoteForm = ({submit, defaultValues, close}) => {
  const formik = useFormik({
    initialValues: defaultValues || {
      description: "",
      attachments: []
    },
    validationSchema: object({
      description: string()
        .required("Please, write a note")
        .max(500, "Description cannot have length greater than 500")
    }),
    onSubmit: ({description, attachments}) => submit({description, attachments})
  });

  const {handleSubmit, touched, values, errors, setFieldValue} = formik;

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };

  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      );
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        );
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Textarea
        placeholder='Enter a note'
        value={values.description}
        onChange={e => setFieldValue("description", e.target.value)}
        count={{
          current: values.description.length,
          max: 500
        }}
        label="Note"
        error={touched.description && errors.description}
      />
      <Attachments
        onChange={handleChangeAttachment}
        onDelete={handleDeleteAttachment}
        attachments={values.attachments}
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={() => close()}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          Create
        </Button>
      </div>
    </form>
  );
};

export default NoteForm;

NoteForm.propTypes = {
  submit: PropTypes.func,
  defaultValues: PropTypes.any,
  close: PropTypes.func
};