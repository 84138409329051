import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import UserReportContainer from "components/containers/UserReportContainer/UserReportContainer";

const UserReportPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        { name: "System reports", path: "/system-reports" },
        { name: "User report", path: "" },
      ]}
    >
      <UserReportContainer />
    </DefaultLayout>
  );
};

export default UserReportPage;
