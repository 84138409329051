import React, {useEffect, useState} from "react";
import style from "./StepList.module.scss";
import {ReactComponent as Plus} from "../../../../../assets/icons/btn-plus.svg";
import ConditionGroup from "../DetailFormEdit/ConditionGroup/ConditionGroup";
import services from "../../../../../services";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";

const StepList = (
  {
    conditions,
    errors,
    addCondition,
    deleteCondition,
    addSingleCondition,
    changeSingleCondition,
    deleteSingleCondition,
    changeCondition,
    isViewMode = false,
  }
) => {
  let {requestId, stepId} = useParams();
  const [fieldsByRequest, setFieldsByRequest] = useState([]);

  useEffect(() => {
    services.requestTypeServices.getRequestTypesSteps(requestId).then(res => {
      const currentStep = res.data.find(step => step.id === stepId);
      setFieldsByRequest(res.data
        .filter(step => step.position <= currentStep.position)
        .reduce((acc, current) => acc.concat(current.step_fields), []));
    });
  }, []);

  let stepList = conditions.map((condition, index) => (
    <ConditionGroup
      key={condition.id}
      condition={condition}
      idx={++index}
      fields={fieldsByRequest}
      deleteCondition={deleteCondition}
      addSingleCondition={addSingleCondition}
      changeSingleCondition={changeSingleCondition}
      deleteSingleCondition={deleteSingleCondition}
      changeCondition={changeCondition}
      isLast={index === conditions.length}
    />
  ));

  if (isViewMode) stepList = conditions.map((condition, index) => (
    <ConditionGroup
      key={condition.id}
      condition={condition}
      idx={++index}
      isLast={index === conditions.length}
      isViewMode={isViewMode}
      fields={fieldsByRequest}
    />
  ));

  return (
    <div className={style.conditions}>
      <div className={style.conditionsHeader}>
        <div className={style.conditionTitle}>Conditions</div>
        {!isViewMode && (
          <button className={style.addConditionBtn} onClick={e => {
            e.preventDefault();
            addCondition();
          }}>
            <Plus/>
          </button>
        )}
      </div>
      <div className={style.conditionsContent}>
        {stepList}
        {errors?.conditions && <p className={style.error}>{errors.conditions}</p>}
      </div>
    </div>
  );
};

export default StepList;

StepList.propTypes = {
  conditions: PropTypes.any,
  errors: PropTypes.any,
  addCondition: PropTypes.func,
  deleteCondition: PropTypes.func,
  addSingleCondition: PropTypes.func,
  changeSingleCondition: PropTypes.func,
  deleteSingleCondition: PropTypes.func,
  changeCondition: PropTypes.func,
  isViewMode: PropTypes.bool,
};