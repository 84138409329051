import React, { useEffect, useState } from "react"
import MainTable from "components/tables/MainTable/MainTable"
import s from "./SurveyTable.module.scss"
const SurveysTable = ({
  history,
  projectId,
  surveys,
  onPageChange,
  currentPage,
  onSort,
  onSendToNewStackholders
}) => {
  const [buttons, setButtons] = useState([
    {
      name: "View results",
      styled: "primary",
      onClick: () => history.push(`/projects/${projectId}/survey-results`)
    }
  ])

  useEffect(() => {
    if (surveys.new_stakeholders) {
      setButtons([
        {
          name: "Send to new stakeholders",
          styled: "secondary",
          onClick: onSendToNewStackholders
        },
        ...buttons
      ])
    }
  }, [])
  const statusesColors = {
    incomplete: "#DE4935",
    done: "#83A755"
  }
  return (
    <MainTable
      data={surveys.survey_results}
      dataTypes={[
        {
          title: "Stakeholder",
          value: ({ stakeholder }) => stakeholder,
          sortable: true,
          handleSort: type =>
            onSort({ sort_by: "name", direction: type.direction })
        },
        {
          title: "Status",
          value: ({ status }) => (
            <div className={s.statusWrap} style={{ backgroundColor: statusesColors[status] }}>
              <span className={s.status}>
                {status[0].toUpperCase() + status.slice(1)}
              </span>
            </div>
          )
        }
      ]}
      perPage={20}
      totalCount={surveys.survey_results_count}
      onPageChange={onPageChange}
      page={currentPage}
      isPagination={true}
      buttons={buttons}
    />
  )
}

export default SurveysTable
