import React from "react"
import Popup from "components/popups/Popup/Popup"
import EditProjectForm from "components/forms/EditProjectForm/EditProjectForm"
import {
  formatEntityToObject,
  formatEntityData
} from "helpers/formatEntityData"

const EditProjectPopup = ({ closeAction, handleEditProject, data }) => {
  const {
    owner,
    start_date,
    end_date,
    description,
    name,
    stage,
    project_type,
    category,
    departments,
    target_spend,
    target_spend_currency
  } = data
  return (
    <Popup onClose={closeAction} title='Edit project information'>
      <EditProjectForm
        onClose={closeAction}
        projectOwner={owner}
        startDateForm={start_date}
        endDateForm={end_date}
        description={description}
        onSubmit={handleEditProject}
        title={name}
        stage={stage}
        project_type={project_type}
        category={category}
        clients={formatEntityToObject(formatEntityData(departments))}
        target_spend={target_spend}
        target_spend_currency={{
          label: target_spend_currency || "",
          value: target_spend_currency || ""
        }}
      />
    </Popup>
  )
}

export default EditProjectPopup
