import React, {useEffect, useState} from "react";
import style from "./DetailFormEdit.module.scss";
import FormHeader from "./FormHeader/FormHeader";
import {useFormik} from "formik";
import {object, string} from "yup";
import {fieldTypes, showFieldTypes} from "../../../../../helpers/helpData";
import Input from "../../../../elements/Input/Input";
import Checkbox from "../../../../elements/Checkbox/Checkbox";
import CustomDropdown from "../../../../elements/CustomDropdown/CustomDropdown";
import {parseToObj} from "../../../../../helpers/formulaToObj";
import {generateUniqueId} from "../../../../../helpers/uniqueId";
import {getConditionValues} from "../../../../../helpers/conditionValues";
import {parseToFormula} from "../../../../../helpers/objToFormula";
import {useParams} from "react-router-dom";
import {editStepField} from "../../helpers/actions";
import StepList from "../StepList/StepList";
import services from "../../../../../services";
import PropTypes from "prop-types";
import {useDidUpdate} from "../../../../../hooks/useDidUpdate";
import Information from "../../../../elements/Information/Information";
import s from "./DetailFormEdit.module.scss";

const DetailFormEdit = ({field, dispatch}) => {
  const defaultFieldType = fieldTypes.find(type => type.value === field.field_type);
  const defaultShowField = field.conditions !== "" ? showFieldTypes[0] : showFieldTypes[1];
  let {stepId, requestId} = useParams();
  const [fieldsByRequest, setFieldsByRequest] = useState([]);
  const alwaysFields = fieldsByRequest.filter(f => !Boolean(f.conditions));

  useEffect(() => {
    services.requestTypeServices.getRequestTypesSteps(requestId).then(res => {
      setFieldsByRequest(res.data.reduce((acc, current) => acc.concat(current.step_fields), []));
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: field.name,
      field_type: defaultFieldType,
      show_field: defaultShowField,
      optional: field.optional,
      conditions: parseToObj(field.conditions),
    },
    validationSchema: object({
      name: string().required("Field name is required"),
      field_type: object({
        value: string().required("Type is required")
      }),
    }),
    validate: ({conditions, show_field}) => {
      let errors = {};

      const singleConditions = conditions.map(condition => condition.condition_group).flat(1);
      const foundedSingleCondition = singleConditions.find(singleCondition => {
        const {generalValue, generalCondition, fieldValue} = getConditionValues(singleCondition.value);
        return (!Boolean(generalValue.trim()) || !Boolean(generalCondition.trim()) || !Boolean(fieldValue.trim()));
      });

      if (foundedSingleCondition) {
        errors.conditions = "Please fill in all fields";
      }

      if (show_field.value === "Under condition" && alwaysFields.length < 2) {
        errors.show_field = {
          value: "Conditions group should contain atleast 1 condition"
        };
      }

      return errors;
    },
    onSubmit: values => {
      let params = {
        ...values,
        field_type: values.field_type.value,
        show_field: values.show_field.value,
        conditions: parseToFormula(values.conditions),
      };
      if (field.custom_dropdown) {
        params = {...params, custom_dropdown_id: field.custom_dropdown.id};
      }
      if (values.show_field.value === "Always") {
        params = {...params, conditions: ""};
      }
      editStepField(dispatch, stepId, field.id, params);
    },
  });

  const {handleSubmit, values, setFieldValue, errors, touched} = formik;

  useDidUpdate(() => {
    if (values.show_field.value === "Always") {
      setFieldValue("conditions", []);
    }
  }, [values.show_field]);

  const addCondition = () => {
    setFieldValue("conditions", [...values.conditions, {
      id: generateUniqueId(),
      condition: null,
      condition_group: [
        {
          id: generateUniqueId(),
          condition: null,
          value: " === "
        }
      ]
    }]);
  };

  const changeCondition = (conditionId, value) => {
    setFieldValue("conditions", values.conditions.map(condition => {
      if (condition.id === conditionId) {
        return {
          ...condition,
          condition: value,
        };
      }
      return condition;
    }));
  };

  const deleteCondition = conditionId => {
    let newConditions = values.conditions.filter(condition => condition.id !== conditionId);
    const lastCondition = newConditions.pop();
    if (lastCondition) {
      setFieldValue("conditions", [...newConditions, {...lastCondition, condition: null}]);
    }
  };

  const addSingleCondition = conditionId => {
    setFieldValue("conditions", values.conditions.map(condition => {
      if (condition.id === conditionId) {
        return {
          ...condition,
          condition_group: [...condition.condition_group, {
            id: generateUniqueId(),
            condition: null,
            value: " === "
          }]
        };
      }
      return condition;
    }));
  };

  const changeSingleCondition = (conditionId, singleConditionId, value, type) => {
    setFieldValue("conditions", values.conditions.map(condition => {
      if (condition.id === conditionId) {
        return {
          ...condition,
          condition_group: condition.condition_group.map(singleCondition => {
            if (singleCondition.id === singleConditionId) {
              const {generalValue, generalCondition, fieldValue} = getConditionValues(singleCondition.value);
              if (type === "type") {
                return {
                  ...singleCondition,
                  value: [`'${value.value}'`, generalCondition, "''"].join("")
                };
              }
              if (type === "value") {
                return {
                  ...singleCondition,
                  value: [`'${fieldValue}'`, generalCondition, `'${value.value}'`].join("")
                };
              }
              if (type === "condition") {
                return {
                  ...singleCondition,
                  value: [`'${fieldValue}'`, value.value, `'${generalValue}'`].join("")
                };
              }
              return {
                ...singleCondition,
                condition: value,
              };
            }
            return singleCondition;
          })
        };
      }
      return condition;
    }));
  };

  const deleteSingleCondition = (conditionId, singleConditionId) => {
    setFieldValue("conditions", values.conditions.map(condition => {
      if (condition.id === conditionId) {

        let newConditionGroup = condition.condition_group
          .filter(singleCondition => singleCondition.id !== singleConditionId);

        const lastCondition = newConditionGroup.pop();

        return {
          ...condition,
          condition_group: [...newConditionGroup, {...lastCondition, condition: null}],
        };
      }
      return condition;
    }));
  };

  return (
    <form className={style.editBlockWrapper} onSubmit={handleSubmit} autoComplete="off">
      <FormHeader dispatch={dispatch}/>
      <div className={style.mainFormFields}>
        <div className={style.fieldGroup}>
          <Input
            label="Name"
            value={values.name}
            placeholder="Enter name"
            onChange={e => setFieldValue("name", e.target.value)}
            error={touched.name && errors.name}
          />
          <Checkbox
            className={style.checkbox}
            value={values.optional}
            checked={values.optional}
            onChange={e => setFieldValue("optional", e.target.checked)}
            label="Optional field"
          />
        </div>
        <div className={style.fieldGroup}>
          <CustomDropdown
            options={fieldTypes}
            value={values.field_type}
            name="field_type"
            onChange={e => setFieldValue("field_type", e)}
            label="Type"
            error={touched.field_type && errors.field_type && errors.field_type.value}
          />
          <CustomDropdown
            options={alwaysFields.length < 2 ? [showFieldTypes[1]] : showFieldTypes}
            value={values.show_field}
            name="field_type"
            onChange={e => setFieldValue("show_field", e)}
            label="Show field"
            error={errors.show_field && errors.show_field.value}
          />
        </div>
      </div>
      {
        (values.show_field.value === "Under condition" && fieldsByRequest?.length) && (
          <StepList
            conditions={values.conditions}
            errors={errors}
            addCondition={addCondition}
            deleteCondition={deleteCondition}
            addSingleCondition={addSingleCondition}
            changeSingleCondition={changeSingleCondition}
            deleteSingleCondition={deleteSingleCondition}
            changeCondition={changeCondition}
          />
        )
      }
      <Information message="This field has already been used, so some information can’t be changed." informationClass={s.information}/>
    </form>
  );
};

export default DetailFormEdit;

DetailFormEdit.propTypes = {
  field: PropTypes.any,
  dispatch: PropTypes.any,
};