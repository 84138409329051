import React from "react";
import MainTable from "../MainTable/MainTable";
import {dateFormatterWithoutTimezone} from "helpers/dateFormat";
import PropTypes from "prop-types";
import Status from "../../elements/Status/Status";

const AdminSettingsNonFinancialSavingsTable = (
  {
    data,
    onAdd,
    onDelete,
    onEdit,
    onRestore,
  }
) => {
  return (
    <MainTable
      data={data}
      dataTypes={[
        {
          title: "Savings type",
          value: ({name}) => name,
        },
        {
          title: "Status",
          value: ({status}) => <Status status={status == "deleted" ? "archived" : status}/>,
        },
        {
          title: "Updated",
          value: ({updated_at}) =>
            updated_at ? dateFormatterWithoutTimezone(updated_at) : "-",
        },
      ]}
      options={item => {
        return [
          (onEdit && item.status === "active") && {
            title: "Edit",
            onClick: () => onEdit(item),
          },
          (onDelete && item.status === "active") && {
            title: "Delete",
            onClick: () => onDelete(item),
          },
          (onRestore && item.status === "deleted") && {
            title: "Restore",
            onClick: () => onRestore(item),
          },
        ];
      }}
      button={{
        name: "New type",
        onClick: () => onAdd(),
      }}
    />
  );
};

export default AdminSettingsNonFinancialSavingsTable;


AdminSettingsNonFinancialSavingsTable.propTypes = {
  data: PropTypes.any,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onRestore: PropTypes.func,
};