import React, {useEffect, useState} from "react";
import services from "../../../../../../services";
import s from "./GanttChartDashboard.module.scss"
import ProjectsGanttChart from "../../../../../elements/ProjectsGanttChart/ProjectsGanttChart";
import GanttChartLoading from "../../../../../loadingStates/Dashboard/GanttChart/GanttChartLoading";
import {months} from "../../../../../elements/ProjectsGanttChart/helpers/helpData";

const GanttChartDashboard = () => {
  const [data, setData] = useState({
    projects: [],
    projects_count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [isFullMode, setFullMode] = useState(false)

  useEffect(() => {
    setLoading(true);
    if (isFullMode) {
      services.dashboardServices.getGanttChart('full').then((res) => {
        setData(res.data);
        setLoading(false);
      });
    } else {
      services.dashboardServices.getGanttChart('view').then((res) => {
        setData(res.data);
        setLoading(false);
      });
    }

  }, [isFullMode]);


  if (loading) return <GanttChartLoading/>;

  if (data.projects.length) return (
    <ProjectsGanttChart
      className={s.chartWrapper}
      data={data.projects}
      monthsList={months}
      isFullMode={isFullMode}
      showFullAction={() => setFullMode(!isFullMode)}
      isUseFull={data.projects_count >= 11}
    />
  );

  return null;
};

export default GanttChartDashboard;


