import React, { useState } from "react";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import uniqid from "uniqid";

const defaultState = {
  className: null,
  type: null,
  message: null,
  disappearTime: 4,
  onCancel: null,
};

const AlertContext = React.createContext(defaultState);

export const AlertContextProvider = ({ children, state }) => {
  const [currentState, setCurrentState] = useState(state || defaultState);

  const closeAlert = () => {
    setCurrentState(defaultState);
  };

  const setAlert = (values = {}) => {
    const extraProps = {};
    if (values.onCancel)
      extraProps.onCancel = () => {
        values.onCancel();
        closeAlert();
      };
    setCurrentState({ ...currentState, ...values, ...extraProps });
  };

  const getCancelId = () => {
    return uniqid();
  };

  return (
    <AlertContext.Provider value={{ setAlert, getCancelId }}>
      {children}
      {currentState.message && (
        <NotificationBox {...currentState} onClose={closeAlert} />
      )}
    </AlertContext.Provider>
  );
};

export default AlertContext;
