import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import ProfileContainer from "components/containers/ProfileContainer/ProfileContainer";

const ProfilePage = () => {
  return (
    <DefaultLayout>
      <ProfileContainer />
    </DefaultLayout>
  );
};

export default ProfilePage;
