import React, { useState } from "react";
import { getTranslateKey } from "../../../../../helpers/getTranslateKey";
import { useTranslation } from "react-i18next";
import { ReactComponent as Completed } from "assets/icons/success-green.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { TemplatesPopUp } from "components/containers/SurveysContainer/components/TemplatesPopUp/TemplatesPopUp";
import styles from "./SurveyTemplateContainer.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";

export const SurveyTemplateContainer = ({
  statuses,
  setEditScalePopUp,
  currentTemplate,
  isLoading,
  setCurrentTemplate,
}) => {
  const [editTemplate, setEditTemplate] = useState({ checked: false, action: "Edit" });

  const { t } = useTranslation();
  const handleEditScale = () => setEditScalePopUp((prevPopUp) => !prevPopUp);
  const handleEditTemplate = () =>
    setEditTemplate((p) => ({ checked: !p.checked, action: "Edit" }));

  return (
    <>
      {editTemplate.checked && (
        <TemplatesPopUp
          setOpenPopUp={setEditTemplate}
          openPopup={editTemplate}
          editItem={currentTemplate}
          setCurrentTemplates={setCurrentTemplate}
          once={true}
        />
      )}
      <div className={styles.secWrapper}>
        <section className={styles.tempInfo}>
          <div className={styles.tempHead}>
            <h3 data-translate-key={getTranslateKey("Template information")}>
              {t(getTranslateKey("Template information"))}
            </h3>
            <Edit onClick={handleEditTemplate} style={{ cursor: "pointer" }} />
          </div>
          <div className={styles.checkLab}>
            <label data-translate-key={getTranslateKey("Public")}>
              {t(getTranslateKey("Public")) + ":"}
            </label>
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width="30%" />
            ) : (
              currentTemplate?.optional && <Completed />
            )}
          </div>
          <div>
            <label
              data-translate-key={getTranslateKey("Description")}
              style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.55)" }}>
              {t(getTranslateKey("Description")) + ":"}
            </label>
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width="80%" />
            ) : (
              <div>{currentTemplate?.description}</div>
            )}
          </div>
        </section>
        <section className={styles.scaleWrapper}>
          <div className={styles.secContainer}>
            <div className={styles.secHead}>
              <h3 data-translate-key={getTranslateKey("Scoring scale")}>
                {t(getTranslateKey("Scoring scale"))}
              </h3>
              <Edit onClick={handleEditScale} style={{ cursor: "pointer" }} />
            </div>
            <label
              data-translate-key={getTranslateKey(
                "This scale shows the survey level after answering all questions.",
              )}>
              {t(
                getTranslateKey("This scale shows the survey level after answering all questions."),
              )}
            </label>
          </div>
          <div>
            <div className={styles.percentContainer}>
              <label>0</label>
              <label>25</label>
              <label>50</label>
              <label>75</label>
              <label>100</label>
            </div>
            <div className={styles.colorContainer}>
              {statuses.map((status) => (
                <div
                  className={styles.colorBlock}
                  style={{
                    backgroundColor: status.color,
                    width: `${status.core - status.mincore}%`,
                  }}
                />
              ))}
            </div>
            <div className={styles.labelContainer1}>
              {statuses.map((status) => (
                <div
                  style={{
                    width: `${status.core - status.mincore}%`,
                  }}>
                  {status.status}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
