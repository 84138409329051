import React, {useEffect, useState} from "react";
import classNames from "classnames";
import services from "services/index";
import s from "./Announcements.module.scss";
import sBase from "../MainDashboard.module.scss";
import {getTitle} from "../../helpers/dataFormat";
import Button from "../../../../../elements/Button/Button";
import Popup from "../../../../../popups/Popup/Popup";
import AnnouncementsForm from "./AnnouncementsForm/AnnouncementsForm";
import {defaultPopUpState, popUpType} from "./AnnouncementsForm/helper";
import Announcement from "./Announcement";
import {userRoles} from "../../../../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Announcements = () => {
  const [data, setData] = useState([]);
  const user = useSelector(state => state.user);
  const {t} = useTranslation();
  const [popUpState, setPopUpState] = useState(defaultPopUpState);

  const isDepartmentAndCEO = user.roles.includes(userRoles.cpo) ||
    user.roles.includes(userRoles.director) ||
    user.roles.includes(userRoles.admin);

  useEffect(() => {
    services.dashboardServices.getAnnouncements().then((res) => {
      setData(res.data);
    });
  }, []);

  const handleCreate = value => {
    services.dashboardServices.addAnnouncements(value.note).then((res) => {
      setData([res.data, ...data]);
    });
  };

  const handleEdit = (values) => {
    const {id, note} = values;
    services.dashboardServices.updateAnnouncements(id, note).then((res) => {
      setData(data.map(a => {
        if (a.id === res.data.id) return res.data;
        return a;
      }));
    });
  };

  const handleDelete = (id) => {
    services.dashboardServices.deleteAnnouncements(id).then(() => {
      setData(data.filter(a => a.id !== id));
      setPopUpState(defaultPopUpState);
    });
  };

  let announcementCreateBtn = null;

  if (isDepartmentAndCEO) {
    announcementCreateBtn = (
      <Button styled="addElement" onClick={() => setPopUpState({isOpen: true, type: "new", announcement: null})}>
        New announcement
      </Button>
    );
  }

  return (
    <>
      <section className={classNames(sBase.wrapper, s.announcementsWrapper)}>
        <div className={s.header}>
          <h4 className={classNames(sBase.title)} data-translate-key={getTranslateKey(getTitle("announcements"))}>
            {t(getTranslateKey(getTitle("announcements")))}
          </h4>
          {announcementCreateBtn}
        </div>
        <div className={s.content}>
          {data.map(a => <Announcement key={a.id} announcement={a} handlePopUp={p => setPopUpState(p)}/>)}
        </div>
      </section>
      {(popUpState.isOpen && (popUpState.type === popUpType.new || popUpState.type === popUpType.edit)) && (
        <Popup
          title={popUpState.type === popUpType.new ? "New announcement" : "Edit announcement"}
          onClose={() => setPopUpState(defaultPopUpState)}
        >
          <AnnouncementsForm
            isEdit={(popUpState.type === popUpType.edit)}
            announcement={popUpState.announcement}
            onClose={() => setPopUpState(defaultPopUpState)}
            onEdit={values => handleEdit(values)}
            onCreate={value => handleCreate(value)}
          />
        </Popup>
      )}
      {(popUpState.isOpen && popUpState.type === popUpType.delete) && (
        <Popup
          title="Delete announcement"
          text="Are you sure you want to delete this announcement?"
          onClose={() => setPopUpState(defaultPopUpState)}
          onClick={() => handleDelete(popUpState.announcement?.id)}
          isDelete
          dialog
        />
      )}
    </>
  );
};

export default Announcements;


