import React, {useState, useEffect} from "react";
import {CircularProgress} from "@material-ui/core";
import {useParams} from "react-router-dom";
import styles from "./SupplierGeneral.module.scss";
import sBase from "../../SupplierDetailsContainer.module.scss";
import SpendByMonth from "./components/SpendByMonth/SpendByMonth";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import SupplierGeneralTable from "components/tables/SupplierGeneralTable/SupplierGeneralTable";
import services from "services";
import {preformatData} from "./helpers";
import BarDropDown from "../../../../elements/BarDropDown/BarDropDown";
import {useTranslation} from "react-i18next";

const SupplierGeneral = () => {
  const params = useParams();
  const {t} = useTranslation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({});

  useEffect(() => {
    services.supplierServices
      .supplierReportGeneral(params.id, {
        ...query,
        spend_year: query?.spend_year?.value ? query.spend_year.value : new Date().getFullYear()
      })
      .then(res => {
        setData(preformatData(res.data));
        setLoading(false);
      });
  }, [params.id, query]);

  const yearsOptionals = Array.from({ length: 5 }, (_, index) => ({name: new Date().getFullYear() - index, value: new Date().getFullYear() - index}));
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <h3 className={sBase.name} data-translate-key="general">{t("general")}</h3>
        <BarDropDown
          name=""
          handleChange={value => {
            setLoading(true);
            setQuery({...query, spend_year: value});
          }}
          options={yearsOptionals}
          defaultValues={query?.spend_year ? query.spend_year : yearsOptionals[0]}
        />
      </div>
      {loading && (
        <div className="loaderWrap justifyCenter">
          <CircularProgress/>
        </div>
      )}

      {!loading && !Boolean(data?.spend_by_month?.length) && (
        <EmptyBlock
          title="No data for displaying."
          withBtn={false}
          message
          className={styles.emptyBlock}
        />
      )}
      {!loading && Boolean(data?.spend_by_month?.length) && (
        <SpendByMonth
          data={data.spend_by_month}
          currency={data.currency}
          query={query}
          changeQuery={value => setQuery({...query, spend_year: value})}
        />
      )}
      {!loading && Boolean(data?.total?.length) && (
        <SupplierGeneralTable
          data={data.total}
          currency={data.currency}
          query={query}
          changeQuery={setQuery}
          className={styles.supplierGeneralTable}
        />
      )}
    </div>
  );
};

export default SupplierGeneral;
