import React from 'react';
import styles from "./TimeSpent.module.scss";
import TimeSpentItem from "./TimeSpentItem";

const TimeSpent = ({data}) => {
  const {milestones, total_project_time} = data;
  const maxHeight = Math.max.apply(null, milestones.map(milestone => milestone.spend));

  return (
    <div className={styles.timeSpentWrap}>
      <h4>Time spent</h4>
      <div className={styles.projects}>
        {milestones.map((milestone, index) => <TimeSpentItem milestone={milestone} maxHeight={maxHeight} key={index}/>)}
      </div>
      <div className={styles.totalTime}>
        Total projects time - {total_project_time} days
      </div>
    </div>
  );
};

export default TimeSpent;