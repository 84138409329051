import React, { useState, useEffect, memo } from "react";
import s from "./AdminScenarioContainer.module.scss";
import AdminApprovalsStepsTable from "components/tables/AdminApprovalsStepsTable/AdminApprovalsStepsTable";
import Popup from "components/popups/Popup/Popup";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import { CircularProgress } from "@material-ui/core";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import querystring from "query-string";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ChangeApprovalStepForm from "components/forms/ChangeApprovalStepForm/ChangeApprovalStepForm";
import ApprovalStepsWidget from "components/elements/ApprovalStepsWidget/ApprovalStepsWidget";
import services from "services";

const AdminScenarioContainer = memo(({ name }) => {
  const [steps, setSteps] = useState([]);
  const [stepsCount, setStepsCount] = useState(0);
  const [currentStep, setCurrentStep] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(null);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { sort_by, direction, page } = querystring.parse(location.search);
  useEffect(() => {
    const query = {
      page,
      sort_by,
      direction,
    };
    services.adminServices.getScenarioSteps(params.id, query).then((res) => {
      setSteps(res.data.approval_steps);
      setStepsCount(res.data.approval_steps_count);
      setLoading(false);
    });
  }, [location.search]);
  const onPageChange = (page) => {
    const query = querystring.parse(history.location.search);
    history.push({ search: querystring.stringify({ ...query, page }) });
  };
  const errorAlert = {
    type: "error",
    message: "Something went wrong. Please, try again.",
  };
  const handleAddStep = (values) => {
    services.adminServices
      .addStep(params.id, values)
      .then((res) => {
        setSteps([...steps, res.data]);
        setStepsCount(stepsCount + 1);
        setAlert({
          type: "success",
          message: "Approval step successfully added.",
        });
        setPopup(null);
      })
      .catch(() => setAlert(errorAlert));
  };
  const handleEditStep = (values) => {
    services.adminServices
      .editStep(params.id, currentStep.id, values)
      .then((res) => {
        setSteps(steps.map((e) => (e.id === res.data.id ? res.data : e)));
        setAlert({
          type: "success",
          message: "Approval step successfully changed.",
        });
        setPopup(null);
      })
      .catch(() => setAlert(errorAlert));
  };
  const handleDeleteStep = () => {
    services.adminServices
      .deleteStep(params.id, currentStep.id)
      .then(() => {
        setSteps(steps.filter((e) => e.id !== currentStep.id));
        setStepsCount(stepsCount - 1);
        setAlert({
          type: "success",
          message: "Approval step successfully deleted.",
        });
        setPopup(null);
      })
      .catch(() => setAlert(errorAlert));
  };
  return (
    <div className={s.wrap} data-attr="flow">
      <h2 className={s.title}>{name}</h2>
      {!loading && (
        <div style={{ width: "100%" }} data-attr="project-approval-wrap">
          <ApprovalStepsWidget steps={steps} />
        </div>
      )}
      <div className={s.tableWrap}>
        {steps.length > 0 && !loading && (
          <AdminApprovalsStepsTable
            data={steps}
            totalCount={stepsCount}
            onAdd={() => setPopup("add")}
            onEdit={(e) => {
              setCurrentStep(e);
              setPopup("edit");
            }}
            onDelete={(e) => {
              setCurrentStep(e);
              setPopup("delete");
            }}
            perPage={30}
            onPageChange={onPageChange}
            currentPage={location.search.page || 1}
          />
        )}
        {!steps.length && !loading && (
          <EmptyBlock
            subtitle="No work item results found"
            buttonTitle="Add step"
            onClick={() => setPopup("add")}
          />
        )}
        {loading && (
          <div className="loaderWrap justifyCenter alignCenter">
            <CircularProgress />
          </div>
        )}
        {popup === "add" && (
          <Popup
            title="Add approval step"
            text="Please, complete the following fields to add a new approval step"
            onClose={() => setPopup(null)}
          >
            <ChangeApprovalStepForm
              onClose={() => setPopup(null)}
              onSubmit={handleAddStep}
            />
          </Popup>
        )}
        {popup === "edit" && (
          <Popup
            title="Edit approval step"
            text="Please, complete the following fields to edit approval step"
            onClose={() => setPopup(null)}
          >
            <ChangeApprovalStepForm
              onClose={() => setPopup(null)}
              onSubmit={handleEditStep}
              initialValues={{
                group: {
                  id: currentStep?.approval_group.id,
                  value: currentStep?.approval_group.name,
                  label: currentStep?.approval_group.name,
                },
                project_tab: {
                  value: currentStep.project_tab,
                  label: currentStep.project_tab,
                },
                approval_type: {
                  value: currentStep.approval_type,
                  label: currentStep.approval_type,
                },
              }}
            />
          </Popup>
        )}
        {popup === "delete" && (
          <Popup
            title="Delete approval step"
            text="Are you sure you want to delete this step?"
            onClose={() => setPopup(null)}
            onClick={handleDeleteStep}
            isDelete
            dialog
          />
        )}
        {alert.message && (
          <NotificationBox
            message={alert.message}
            type={alert.type}
            disappearTime={5}
            onClose={() => setAlert({ message: "", type: "" })}
          />
        )}
      </div>
    </div>
  );
});
export default AdminScenarioContainer;
