import React, {useReducer} from "react"
import s from "./AdditionalQuestions.module.scss";
import Tabs from "../../../elements/Tabs/Tabs";
import TypeOfSpend from "./TypeOfSpend/TypeOfSpend";
import SupplierStatus from "./SupplierStatus/SupplierStatus";
import Budget from "./Budget/Budget";
import ProjectType from "./ProjectType/ProjectType";
import Category from "./Category/Category";
import { initialState, reducer } from "./helpers/reducer";
const AdditionalQuestions = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div className={s.wrap}>
      <Tabs styled="secondary" tabClassName={s.tab}>
        <div label="Type of Spend">
          <TypeOfSpend data={state.typeOfSpendList} dispatch={dispatch}/>
        </div>
        <div label="Supplier status">
          <SupplierStatus data={state.supplierStatusList} dispatch={dispatch}/>
        </div>
        <div label="Budget">
          <Budget data={state.budgetList} dispatch={dispatch}/>
        </div>
        <div label="Project type">
          <ProjectType data={state.projectTypeList} dispatch={dispatch}/>
        </div>
        <div label="Category">
          <Category data={state.categoryList} dispatch={dispatch}/>
        </div>
      </Tabs>
    </div>
  )
}

export default AdditionalQuestions;
