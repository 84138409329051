import React, { useEffect } from "react";
import LandingLayout from "../../Landinglayout/Landinglayout";
import Social from "../../components/Social/Social";
import ArticleCard from "./ArticleCard/ArticleCard";
import Divider from "../../components/Divider/Divider";
import Button from "components/elements/Button/Button";
import s from "./ArticlePage.module.scss";
//For test only:
import { article01, blogData } from "../BlogPage/blogData";

const ArticlePage = () => {
  const { pic, name, date, title, text } = article01.article;
  const { img } = article01;
  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <LandingLayout>
      <div className={s.articlePage}>
        <div className={s.landingContainer}>
          <div className={s.articleContainer}>
            <div className={s.authorBlock}>
              <div className={s.cutaway}>
                <img src={pic} alt="/" className={s.pic} />
                <div className={s.authorDescr}>
                  <h4 className={s.name}>{name}</h4>
                  <p className={s.date}>{date}</p>
                </div>
              </div>

              <Social className={s.topSocialMobile} />
            </div>

            <h1 className={s.title}>{title}</h1>
          </div>
          <div className={s.imgWrap}>
            <img src={img} alt="/" className={s.titleImg} />
          </div>

          <div className={s.articleContainer}>
            <p className={s.text}>{text}</p>

            <Divider />
            <Social className={s.downSocial} />
          </div>

          <h3 className={s.cardsTitle}>Recent posts</h3>

          <div className={s.cardsBlock}>
            <ArticleCard
              path={blogData[1].path}
              img={blogData[1].img}
              title={blogData[1].article.title}
            />
            <ArticleCard
              path={blogData[1].path}
              img={blogData[1].img}
              title={blogData[1].article.title}
              className={s.mobileVew}
            />
            <ArticleCard
              path={blogData[1].path}
              img={blogData[1].img}
              title={blogData[1].article.title}
              className={s.tabletView}
            />
          </div>

          <Button styled="primary" className={s.viewButton}>
            View all
          </Button>
        </div>
      </div>
    </LandingLayout>
  );
};

export default ArticlePage;
