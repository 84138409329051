import React from "react";
import Popup from "components/popups/Popup/Popup";
import AdminSettingsFinancialSavingsForm from "components/forms/AdminSettingsFinancialSavingsForm/AdminSettingsFinancialSavingsForm";

const EditFinancialSavingsTypePopup = ({ onClose, onSubmit, defaultData }) => {
  return (
    <Popup onClose={onClose} title="Edit financial savings type">
      <AdminSettingsFinancialSavingsForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultData={defaultData}
      />
    </Popup>
  );
};

export default EditFinancialSavingsTypePopup;
