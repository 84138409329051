import {getConditionResult} from "./getConditionResult";

export const filterParamsForQuestions = (paramsForQuestions, fields, values) => {
  const newParams = Object.keys(paramsForQuestions).filter(key => {
    const foundedField = fields.find(field => field.key === key);
    if (foundedField && foundedField.conditions && foundedField.conditions !== "()") {
      return !!eval(getConditionResult(foundedField.conditions, values));
    }
    return true;
  });

  for (let key in paramsForQuestions) {
    if (!newParams.includes(key)) delete paramsForQuestions[key];
  }

  return paramsForQuestions;
};