import React, {useEffect, useState} from "react";
import classNames from "classnames";
import s from "./SavingsGoal.module.scss";
import sBase from "components/containers/DashboardContainer/components/MainDashboard/MainDashboard.module.scss";
import {Chart} from "react-google-charts";
import PieChart from "../PieChart/PieChart";
import GoalsLoading from "components/loadingStates/Dashboard/Goals/GoalsLoading";
import {useHistory} from "react-router-dom";
import {paths} from "routes/paths";
import {useTranslation} from "react-i18next";
import {useTranslate} from "../../../hooks/useTranslate";

const SavingsGoal = ({defaultData, className, title, withLink = true}) => {
  const COLORS = ["#63A5A1", "#F4A261", "#5B79B4", "#FB787A"];
  const history = useHistory();
  const [data, setData] = useState(defaultData);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const [translateGoalKey, translateGoalValue] = useTranslate("Savings goal");
  const [translateGoalKeyTitle, translateGoalValueTitle] = useTranslate(title);
  const [translateGoalViewKey, translateGoalViewValue] = useTranslate("(View company goals)");

  const {goal, savings} = data;

  useEffect(() => {
    if (defaultData) {
      setData(defaultData);
      setLoading(false);
    }
  }, [defaultData]);

  const preparedData = (savings || [])
    .filter((_, index) => index !== savings.length - 1)
    .map(s => Object.keys(s).map(key => s[key]))
    .map(s => s[0][s[0].length - 1].total);

  const totalPerformance = savings[4]?.total[savings[4]?.total.length - 1].total;

  const getGoalValue = () => {
    if (!goal) return 0;
    if (goal.annual_goal < 1) return 0;
    if (
      Math.sign(goal.annual_goal - totalPerformance) > 0 ||
      isNaN(Math.sign(goal.annual_goal - totalPerformance))
    ) {
      return goal.annual_goal - totalPerformance;
    }
    return 0;
  };
  return (
    <section className={classNames(sBase.wrapper, s.goalsWrapper, className)}>
      {loading ? (
        <GoalsLoading/>
      ) : (
        <>
          <h4 className={classNames(sBase.title, s.title)} data-translate-key={translateGoalKeyTitle}>
            {translateGoalValueTitle || {translateGoalValue}}
            {withLink && (
              <span onClick={() => history.push(paths.companyGoals)} data-translate-key={translateGoalViewKey}>
                    {translateGoalViewValue}
							</span>
            )}
          </h4>
          <div className={s.cartsWrapper}>
            <div className={s.chart}>
              <Chart
                colors={COLORS}
                width={"99%"}
                height={"100%"}
                chartType="LineChart"
                data={[
                  ["x", t("goals"), t("performance")],
                  ["Q1", goal?.quarter1, preparedData[0]],
                  ["Q2", goal?.quarter2, preparedData[1]],
                  ["Q3", goal?.quarter3, preparedData[2]],
                  ["Q4", goal?.quarter4, preparedData[3]]
                ]}
                options={{
                  series: {
                    0: {color: "#5B79B4"},
                    1: {color: "#F4A261"},
                    2: {color: "#63A5A1"},
                    3: {color: "#FB787A"}
                  },
                  chartArea: {
                    height: "100%",
                    width: "100%",
                    top: 10,
                    left: 100,
                    right: 10,
                    bottom: 40
                  },
                  vAxis: {
                    textStyle: {
                      color: "rgba(0, 0, 0, 0.55)",
                      fontSize: 12,
                      fontName: "Roboto"
                    },
                    format: "$#,###"
                  },
                  lineWidth: 3,
                  pointSize: 7,
                  legend: {position: "bottom", alignment: "end"},
                  tooltip: {isHtml: true}
                }}
                className={s.googleChart}
              />
            </div>
            <div className={s.radialDiagram}>
              <PieChart
                chartWidth={"100%"}
                data={[
                  [t("annual-goal"), String(goal?.annual_goal)],
                  [t("performance"), totalPerformance],
                  [t("goal"), getGoalValue()]
                ]}
                withLegend={false}
                colors={COLORS}
                wrapClassName={s.pieChartWrap}
              />
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default SavingsGoal;
