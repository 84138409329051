import React from 'react'
import s from '../PrivacyPage.module.scss'

const Point07 = () => {
	return (
		<div id="point07" className={s.pointBlock}>
              <h3 className={s.subtitle}>
                7. DO WE COLLECT INFORMATION FROM MINORS?
              </h3>

              <p className={s.text} style={{ fontStyle: "italic" }}>
                <span>In Short: </span>We do not knowingly collect data from or
                market to children under 18 years of age.
              </p>

              <p className={s.text}>
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the Website, you represent that
                you are at least 18 or that you are the parent or guardian of
                such a minor and consent to such minor dependent’s use of the
                Website. If we learn that personal information from users less
                than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such
                data from our records. If you become aware of any data we may
                have collected from children under age 18, please contact us at{" "}
                <a
                  href="mailto:info@focalpointprocurement.com."
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.link}
                >
                  info@focalpointprocurement.com.
                </a>
              </p>
            </div>
	)
}

export default Point07
