import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import querystring from "query-string"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import TableLoading from "components/loadingStates/Table/Table"
import SystemReportsDepartmentsTable from "components/tables/SystemReportsDepartmentsTable/SystemReportsDepartmentsTable"
import services from "services"
import s from "./SystemReportsDepartments.module.scss"
import SystemReportsDepartmentsFilter from "components/filters/SystemReportsDepartmentsFilter/SystemReportsDepartmentsFilter"
const SystemReportsDepartments = () => {
  const history = useHistory()

  const [departments, setDepartments] = useState({
    departments: [],
    departments_count: 0
  })
  const [loading, setLoading] = useState(true)

  const query = querystring.parse(history.location.search)

  useEffect(() => {
    services.reportServices.getSystemReportsDepartments(query).then(res => {
      setDepartments({
        departments: res.data.departments,
        departments_count: res.data.departments_count
      })
      setLoading(false)
    })
  }, [history.location.search])

  return (
    <div>
      <SystemReportsDepartmentsFilter className={s.filter} />
      {loading && <TableLoading length={5} />}
      {!loading && Boolean(departments.departments_count) && (
        <SystemReportsDepartmentsTable data={departments} />
      )}
      {!loading && !Boolean(departments.departments_count) && (
        <EmptyBlock withBtn={false}>
          <div>You don’t have departments.</div>
        </EmptyBlock>
      )}
    </div>
  )
}

export default SystemReportsDepartments
