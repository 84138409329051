import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import CategoriesContainer from "components/containers/CategoriesContainer/CategoriesContainer";

const CategoriesPage = () => {
  return (
    <DefaultLayout withRightSidebar={true}>
      <CategoriesContainer />
    </DefaultLayout>
  );
};

export default CategoriesPage;
