export const projectTypes = [
  {
    value: "rfx",
    label: "RFx"
  },
  {
    value: "supplier_performance_management",
    label: "Supplier Performance Management"
  },
  {
    value: "contract_renewal",
    label: "Contract renewal",
  },
  {
    value: "contract_termination",
    label: "Contract termination"
  },
  {
    value: "contract_renegotiation",
    label: "Contract renegotiation"
  },
  {
    value: "custom",
    label: "Custom project"
  },
  {
    value: "new_contract",
    label: "New contract"
  }
];

export const fieldTypes = [
  {
    value: "Contract",
    label: "Contract"
  },
  {
    value: "User",
    label: "User"
  },
  {
    value: "Supplier",
    label: "Supplier"
  },
  {
    value: "Category",
    label: "Category"
  },
  {
    value: "Type of Spend",
    label: "Type of Spend"
  },
  {
    value: "Date",
    label: "Date"
  },
  {
    value: "Number",
    label: "Number"
  },
  {
    value: "String",
    label: "String"
  },
  {
    value: "Boolean",
    label: "Yes/No"
  },
  {
    value: "Custom Dropdown",
    label: "Custom Dropdown"
  },
];

export const showFieldTypes = [
  {
    value: "Under condition",
    label: "Under condition"
  },
  {
    value: "Always",
    label: "Always"
  }
];

export const projectStatuses = [
  { label: "Planned", value: "planned" },
  { label: "Active: Needs Analysis", value: "stage1" },
  { label: "Active: Strategy Creation", value: "stage2" },
  { label: "Active: Market Activity", value: "stage3" },
  { label: "Active: Award Business", value: "stage4" },
  { label: "Active: Stage 5", value: "stage5" },
  { label: "Completed", value: "completed" }
];

export const riotProjectStatuses = [
  { label: "Planned", value: "planned" },
  { label: "Active: Needs Analysis", value: "stage1" },
  { label: "Active: Strategy Creation", value: "stage2" },
  { label: "Active: Market Activity", value: "stage3" },
  { label: "Active: Award Business", value: "stage4" },
  { label: "Completed", value: "completed" }
];

export const indeedProjectStatuses = [
  { label: "DI1 / Dream", value: "planned" },
  { label: "DI2 / Idea", value: "stage1" },
  { label: "DI3 / Plan", value: "stage2" },
  { label: "DI4 / Action", value: "stage3" },
  { label: "DI5 / Execute", value: "completed" }
];
export const UKGProjectStatuses = [
  { label: "Intake", value: "planned" },
  { label: "Strategy", value: "stage1" },
  { label: "RFP", value: "stage2" },
  { label: "Negotiations", value: "stage3" },
  { label: "Contracting", value: "stage4" },
  { label: "On Hold", value: "stage5" },
  { label: "Completed", value: "completed" }
];

export const wolfeProjectStatuses = [
  { label: "Planning", value: "planned" },
  { label: "RFP Issued", value: "stage1" },
  { label: "Evaluation", value: "stage2" },
  { label: "Negotiations", value: "stage3" },
  { label: "Recommendation", value: "stage4" },
  { label: "Contract Award", value: "stage5" },
  { label: "Implementation", value: "completed" }
];

export const getProjectStatuses = companyName => {
  if (process.env.REACT_APP_MODE === "demo") return indeedProjectStatuses;
  switch (companyName) {
    case "Riot Games":
      return riotProjectStatuses;
    case "Indeed":
      return indeedProjectStatuses;
    case "UKG":
      return UKGProjectStatuses;
    case "Wolfe":
      return wolfeProjectStatuses;
    default:
      return projectStatuses;
  }
};

export const currencyData = [
  {
    label: "AUD",
    value: "AUD $",
    index: 1
  },
  {
    label: "CAD",
    value: "CAD $",
    index: 2
  },
  {
    label: "CNY",
    value: "¥",
    index: 3
  },
  {
    label: "CLP",
    value: "CLP $",
    index: 4
  },
  {
    label: "DKK",
    value: "DKr.",
    index: 5
  },
  {
    label: "EUR",
    value: "‎€",
    index: 6
  },
  {
    label: "GBP",
    value: "£",
    index: 7
  },
  {
    label: "HKD",
    value: "HKD $",
    index: 8
  },
  {
    label: "JPY",
    value: "円",
    index: 9
  },
  {
    label: "MXN",
    value: "Mex$",
    index: 10
  },
  {
    label: "NZD",
    value: "NZD $",
    index: 11
  },
  {
    label: "PHP",
    value: "₱",
    index: 12
  },
  {
    label: "SGD",
    value: "SGD $",
    index: 13
  },
  {
    label: "TWD",
    value: "NT$",
    index: 14
  },
  {
    label: "VND",
    value: "₫",
    index: 15
  },
  {
    label: "USD",
    value: "USD $",
    index: 16
  }
];
export const savingsTypesData = [
  { label: "Run Rate (CA)", value: "run_rate_ca" },
  { label: "Run Rate (ER)", value: "run_rate_er" },
  { label: "One time (CA)", value: "one_time_ca" },
  { label: "One time (ER)", value: "one_time_er" }
];
export const indeedSavingsTypesData = [
  { label: "PMR", value: "run_rate_ca" },
  { label: "CBE", value: "run_rate_er" },
  { label: "UCS", value: "one_time_ca" },
  { label: "PTE", value: "one_time_er" }
];
export const UKGSavingsTypesData = [
  { label: "Other", value: "run_rate_ca" },
  { label: "N/A", value: "run_rate_er" },
  { label: "OpEx", value: "one_time_ca" },
  { label: "CapEx", value: "one_time_er" }
];
export const getSavingsTypesData = companyName => {
  switch (companyName) {
    case "Indeed":
      return indeedSavingsTypesData;
    case "UKG":
      return UKGSavingsTypesData;
    default:
      return savingsTypesData;
  }
};
export const projectLinkedTypes = [
  {
    value: "custom",
    label: "custom"
  },
  {
    value: "before",
    label: "before"
  },
  {
    value: "after",
    label: "after"
  }
];
export const supplierStatuses = [
  { label: "Preferred", value: "preferred" },
  { label: "Non-Approved", value: "non_approved" },
  { label: "Approved", value: "approved" },
  { label: "Deleted", value: "deleted" },
  { label: "Multiple", value: "multiple" },
  { label: "Total:", value: "total" }
];

export const backgroundTypes = {
  gray: "#EAEAEA",
  red: "#EF5759",
  orange: "#F4A261",
  light_green: "#C3E26D",
  dark_green: "#63A5A1",
  green: "#0FC9BE",
  light_blue: "#6ACFEF",
  dark_blue: "#5B79B4",
  blue: "#665FE6",
  purple: "#A862DB",
  pink: "#E24490",
  light_pink: "#EF8EA7",
};