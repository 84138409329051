export const LOAD_REQUEST_DETAIL = "LOAD_REQUEST_DETAIL";
export const LOAD_FIELD_DETAIL = "LOAD_FIELD_DETAIL";
export const CHANGE_MODE = "CHANGE_MODE";
export const EDIT_FIELD = "EDIT_FIELD";

export const initialState = {
  requestDetails: null,
  fieldDetail: null,
  isEditMode: false,
};

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case LOAD_REQUEST_DETAIL: {
      return {
        ...state,
        requestDetails: payload,
      };
    }
    case LOAD_FIELD_DETAIL: {
      return {
        ...state,
        fieldDetail: payload,
      };
    }
    case CHANGE_MODE: {
      return {
        ...state,
        isEditMode: payload,
      };
    }
    case EDIT_FIELD: {
      return {
        ...state,
        fieldDetail: payload,
        isEditMode: false,
      };
    }
    default:
      throw Error("Unknown action type");
  }
}