import React from "react";
import style from "./StepsWrapper.module.scss";
import Button from "../../../../../elements/Button/Button";
import PropTypes from "prop-types";

const StepsWrapper = ({count = 0, addAction, children}) => {
  return (
    <div className={style.stepsWrapper}>
      <div className={style.header}>
        <div className={style.title}>Fields <span className={style.count}>{count}</span></div>
        <Button styled="addElement" onClick={addAction}>
          New step
        </Button>
      </div>
      <div className={style.columns}>
        <div>Field</div>
        <div>Type</div>
        <div>Show field</div>
      </div>
      {children}
    </div>
  );
};

export default StepsWrapper;

StepsWrapper.propTypes = {
  count: PropTypes.number,
  addAction: PropTypes.func,
  children: PropTypes.any,
};