import React from "react";
import {useParams} from "react-router-dom";
import AdminSettingsCustomDropDownsChangeContainer
  from "../../containers/AdminSettingsCustomDropDownsChangeContainer/AdminSettingsCustomDropDownsChangeContainer";

const AdminSettingsCustomDropDownsEditPage = () => {
  const params = useParams();
  return <AdminSettingsCustomDropDownsChangeContainer id={params.id}/>;
};
export default AdminSettingsCustomDropDownsEditPage;
