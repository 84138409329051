import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import { useParams } from "react-router-dom";

const ROLES = [
  {
    label: "Category owner",
    value: "category_owner",
  },
  {
    label: "Stakeholder",
    value: "stakeholder",
  },
];

const CategoryStakeholderForm = ({
  defaultData,
  stakeholders,
  currentStakeholders,
  addHandler,
  onClose,
  onSearch,
}) => {
  const [data, setData] = useState([]);
  const params = useParams();
  const [roles, setRoles] = useState(ROLES);

  useEffect(() => {
    const filteredStakeholders = stakeholders.filter(
      (stakeholder) =>
        !currentStakeholders.find((item) => item.id === stakeholder.id)
    );
    if (
      currentStakeholders.some(
        (stakeholder) => stakeholder.user_type === "category_owner"
      )
    ) {
      setRoles((roles) =>
        roles.filter((role) => role.value !== "category_owner")
      );
    } else {
      setRoles(ROLES);
    }
    setData(filteredStakeholders);
  }, [stakeholders, currentStakeholders]);

  const formik = useFormik({
    initialValues: {
      stakeholder: defaultData
        ? { id: defaultData.id, name: defaultData.name }
        : { id: "", name: "" },
      role: roles.find((item) => item.value === defaultData?.user_type) || {
        value: "",
        label: "",
      },
    },
    validationSchema: object({
      stakeholder: object({
        name: string().required("Please, enter stakeholder name"),
        id: string().required("Please, choose exist stakeholder"),
      }),
      role: object({
        label: string().required("Please, choose the role"),
        value: string().required("Please, choose the role"),
      }),
    }),

    onSubmit: (values) => {
      addHandler({
        category_id: params.id,
        user_id: values.stakeholder.id,
        user_type: values.role.value,
      });
      onClose();
    },
  });
  const { handleSubmit, errors, setFieldValue, values, touched } = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <AutoCompleteInput
        data={data}
        value={values.stakeholder.name}
        placeholder="Start typing stakeholder’s name"
        onChange={(e) => {
          setFieldValue("stakeholder", {
            id: "",
            name: e.target.value,
          });
          onSearch(e.target.value);
        }}
        onSelect={(value) => {
          setFieldValue("stakeholder", {
            id: value.id,
            name: value.name,
          });
        }}
        label="Stakeholder"
        error={
          errors.stakeholder &&
          touched.stakeholder &&
          (errors.stakeholder.name || errors.stakeholder.id)
        }
        emptyText={{
          text: "No work item results found.",
        }}
      />
      <div style={{ marginTop: "16px" }}>
        <CustomDropdown
          options={roles}
          value={values.role.value}
          onChange={(option) => setFieldValue("role", option)}
          label="Role"
          placeholder="Choose role"
          error={
            errors.role &&
            touched.role &&
            (errors.role.label || errors.role.value)
          }
        />
      </div>
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Add
        </Button>
      </div>
    </form>
  );
};

export default CategoryStakeholderForm;
