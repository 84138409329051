import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import UsersContainer from "components/containers/UsersContainer/UsersContainer";

const UsersPage = () => {
  return (
    <DefaultLayout withRightSidebar={true}>
      <UsersContainer/>
    </DefaultLayout>
  );
};

export default UsersPage;