export const parseToFormula = array => {
  return array.reduce((prev, current) => {
    if (!current.value) {
      return prev + `(${parseToFormula(current["condition_group"])})${getCondition(current.condition)}`;
    } else {
      return prev + `(${current.value})${getCondition(current.condition)}`;
    }
  }, "");
};

const getCondition = condition => {
  if (condition) {
    return condition === "or" ? "||" : "&&";
  }
  return "";
};