import {
  GET_STAGES,
  CHANGE_POPUP,
  ADD_STAGE,
  DELETE_STAGE,
  EDIT_STAGE,
  CLOSE_ALERT,
  REPLACE_STAGES
} from "./types"
import services from "services"

export const getStages = dispatch => {
  services.adminProjectsServices
    .getProjectStages()
    .then(res => {
      const stages = res?.data || []
      dispatch({
        type: GET_STAGES,
        payload: {
          stages,
          loading: false
        }
      })
    })
    .catch(() =>
      dispatch({
        type: GET_STAGES,
        payload: {
          stages: null,
          loading: true
        }
      })
    )
}
export const addStage = (dispatch, values) => {
  services.adminProjectsServices
    .addProjectStage(values)
    .then(res => {
      const stage = res?.data
      dispatch({
        type: ADD_STAGE,
        payload: {
          stage,
          popup: null,
          alert: {
            type: "success",
            message: "Project stage successfully added."
          }
        }
      })
    })
    .catch(() =>
      dispatch({
        type: ADD_STAGE,
        payload: {
          stage: null,
          popup: "add",
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const deleteStage = (dispatch, id) => {
  services.adminProjectsServices
    .deleteProjectStage(id)
    .then(() =>
      dispatch({
        type: DELETE_STAGE,
        payload: {
          id,
          alert: {
            type: "success",
            message: "Project stage successfully deleted."
          }
        }
      })
    )
    .catch(() =>
      dispatch({
        type: DELETE_STAGE,
        payload: {
          id: null,
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const editStage = (dispatch, id, values) => {
  services.adminProjectsServices
    .editProjectStage(id, values)
    .then(res => {
      const newStage = res?.data
      dispatch({
        type: EDIT_STAGE,
        payload: {
          stage: newStage,
          alert: {
            type: "success",
            message: "Project stage successfully changed."
          }
        }
      })
    })
    .catch(() =>
      dispatch({
        type: EDIT_STAGE,
        payload: {
          stage: null,
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const replaceStages = (dispatch, values) => {
  services.adminProjectsServices
    .replaceProjectStages(values)
    .then(res => {
      const stages = res?.data
      dispatch({
        type: REPLACE_STAGES,
        payload: {
          stages,
          alert: {
            type: "success",
            message: "Project stage successfully replaced."
          }
        }
      })
    })
    .catch(() =>
      dispatch({
        type: REPLACE_STAGES,
        payload: {
          stages: null,
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const changePopup = popup => {
  return {
    type: CHANGE_POPUP,
    payload: popup
  }
}
export const closeAlert = () => {
  return {
    type: CLOSE_ALERT,
    payload: null
  }
}
