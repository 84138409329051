import React from "react";
import AdminSettingsProjectsContainer
  from "../../containers/AdminSettingsProjectsContainer/AdminSettingsProjectsContainer";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

const AdminSettingsProjectsPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        { name: "Admin Settings", path: "", disabled: true } ,
        { name: "Projects", path: "" },
      ]}
    >
      <AdminSettingsProjectsContainer/>
    </DefaultLayout>
  )
};
export default AdminSettingsProjectsPage;
