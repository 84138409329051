import React from "react";
import MainTable from "../MainTable/MainTable";
import style from "./AdminApprovalsGroupsTable.module.scss";

const AdminApprovalsGroupsTable = (
  {
    data,
    onAdd,
    onEdit,
    onDelete,
    perPage,
    totalCount,
    onPageChange,
    currentPage
  }
) => {
  return (
    <MainTable
      stickyHeader={true}
      inlineWrapClassName={style.inlineWrap}
      totalName="Approval groups"
      data={data}
      dataTypes={[
        {
          title: "Group name",
          value: ({name}) => name
        },
        {
          title: "Approvers",
          value: ({approvers}) => {
            return approvers.length > 0
              ? approvers.map(({name}) => name).join(", ")
              : "-";
          },
          hint: true,
          max: 64
        }
      ]}
      options={item => {
        return [
          onEdit && {
            title: "Edit",
            onClick: () => {
              onEdit(item);
            }
          },
          onDelete && {
            title: "Delete",
            onClick: () => {
              onDelete(item);
            }
          }
        ];
      }}
      button={{
        name: "New group",
        onClick: () => onAdd()
      }}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};
export default AdminApprovalsGroupsTable;
