export const requestTypes = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "upon_request",
    label: "Upon request",
  }
];

export const conditionTypes = [
  {
    value: "under_condition",
    label: "Under condition"
  },
  {
    value: "always",
    label: "Always",
  }
];

export const approverTypes = [
  {
    value: "groups",
    label: "Approval group",
  },
  {
    value: "manager",
    label: "Procurement owner’s manager",
  }
];

export const approvedParams = [
  {
    value: "attachments",
    label: "Attachments",
  },
  {
    value: "contracts",
    label: "Contracts",
  },
  {
    value: "financial_benefits",
    label: "Financial benefits",
  },
  {
    value: "financial_details",
    label: "Financial details",
  },
  {
    value: "linked_projects",
    label: "Linked projects",
  },
  {
    value: "milestones",
    label: "Milestones",
  },
  {
    value: "non_financial_benefits",
    label: "Non financial benefits",
  },
  {
    value: "stakeholders",
    label: "Stakeholders",
  },
  {
    value: "suppliers",
    label: "Suppliers",
  },
  {
    value: "project",
    label: "Project",
  }
];

export const approvalTypes = [
  {
    value: "sequential",
    label: "Sequential",
  },
  {
    value: "by_all",
    label: "Parallel, all needed",
  },
  {
    value: "one_of_the_group",
    label: "Parallel, one needed",
  }
];

export const priorities = [
  {
    value: "low",
    label: "Low",
    color: "#83A755"
  },
  {
    value: "medium",
    label: "Medium",
    color: "#F29239"
  },
  {
    value: "high",
    label: "High",
    color: "#DE4935"
  }
];

export const singleConditionTypes = [
  {
    value: "Category",
    label: "Category",
  },
  {
    value: "Spend",
    label: "Spend",
  },
  {
    value: "Division",
    label: "Division",
  },
  {
    value: "Supplier",
    label: "Supplier",
  },
  {
    value: "Target Savings",
    label: "Target Savings",
  },
  {
    value: "Stakeholder",
    label: "Stakeholder",
  },
];