import React, {useState} from "react";
import baseStyle from "../TasksList.module.scss";
import style from "./Approval.module.scss";
import classNames from "classnames";
import {ReactComponent as OrangeFlag} from "assets/icons/orange-flag.svg";
import {ReactComponent as RedFlag} from "assets/icons/red-flag.svg";
import {ReactComponent as GreenFlag} from "assets/icons/green-flag.svg";
import {ReactComponent as TimeIcon} from "assets/icons/time-icon.svg";
import {ReactComponent as ProjectsIcon} from "assets/icons/projects-icon.svg";
import {ReactComponent as TeamIcon} from "assets/icons/team-icon.svg";
import {capitalizeFirstLetter} from "../../../../../../helpers/stringHelper";
import DropListTrigger from "../../../../DropListTrigger/DropListTrigger";
import Popup from "../../../../../popups/Popup/Popup";
import {useHistory, useParams} from "react-router-dom";

const Approval = ({provided, approval, deleteApproval, projectTypeName}) => {
  const params = useParams();
  const history = useHistory();
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(null);
  const [popUp, setPopUp] = useState(null);

  const renderPriorityFlag = () => {
    if (approval.priority === "high") return <RedFlag/>;
    if (approval.priority === "medium") return <OrangeFlag/>;
    return <GreenFlag/>;
  };

  return (
    <>
      <li
        className={classNames(baseStyle.task, style.approval)}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        onClick={() => history.push(`/admin-settings/project-types/${params.id}/approvals/${approval.id}`, {
          approvalName: approval.name,
          projectTypeName,
        })}
      >
        <header>
          <div className={style.headerContentWrap}>
            <p>{approval.name}</p>
            {renderPriorityFlag()}
          </div>
          <DropListTrigger
            data={[
              {
                title: "Delete",
                onClick: () => setPopUp("delete"),
              }
            ]}
            visibleMenu={visibleDropListMenu}
            setVisibleMenu={() => {
              if (visibleDropListMenu) {
                setVisibleDropListMenu(null);
              } else {
                setVisibleDropListMenu(true);
              }
            }}
          />
        </header>
        <div className={style.infoBlock}>
          <TimeIcon/>
          <p>{capitalizeFirstLetter(approval.request_type.replace("_", " "))}</p>
        </div>
        <div className={style.infoBlock}>
          <TeamIcon/>
          <p>{capitalizeFirstLetter(approval.approver.replace("_", " "))}</p>
        </div>
        <div className={style.infoBlock}>
          <ProjectsIcon/>
          <p>{capitalizeFirstLetter(approval.project_tab.replace("_", " "))}</p>
        </div>
      </li>
      {popUp === "delete" && (
        <Popup
          title="Delete approval step"
          text="Are you sure you want to delete this approval step?
It will remain in all current projects, but not in new ones."
          onClose={() => setPopUp(null)}
          successBtnTitle="Delete"
          onClick={() => deleteApproval()}
          isDelete
          dialog
        />
      )}
    </>
  );
};

export default Approval;