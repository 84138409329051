import React from "react";
import s from "./ApproverForm.module.scss";
import Button from "../../elements/Button/Button";
import {useFormik} from "formik";
import {object, string} from "yup";
import UniversalSelect from "../../elements/UniversalSelect/UniversalSelect";
import {statuses} from "./helpers/statuses";
import Textarea from "../../elements/Textarea/Textarea";
import Attachments from "../AddMilestoneTaskForm/components/Attachments/Attachments";
import services from "../../../services";

const ApproverForm = ({onSubmit, onClose, initialStatus}) => {

  const formik = useFormik({
    initialValues: {
      status: statuses.find(s => s.value === initialStatus) ?? {
        value: "",
        label: "",
        color: "",
      },
      note: "",
      attachments: []
    },
    validationSchema: object({
      status: object({
        value: string().required("Status is required")
      }),
      note: string()
        .required("Note is required")
        .max(150, "Please enter Note description that consists of no more than 150 characters")
    }),
    onSubmit: values => onSubmit({
      ...values,
      status: values.status.value,
    }),
  });

  const {handleSubmit, values, setFieldValue, errors, touched} = formik;

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files])
  }
  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      )
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        )
      })
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off" className={s.formWrapper}>
      <div className={s.fieldWrap}>
        <UniversalSelect
          options={statuses}
          value={values.status}
          label="Status"
          onChange={option => setFieldValue("status", option)}
          placeholder="Choose status"
          error={touched.status && errors.status && errors.status.value}
          customItemType="status"
        />
      </div>
      <div className={s.fieldWrap}>
        <Textarea
          label="Note"
          placeholder="Enter a note"
          customHeight={56}
          value={values.note}
          onChange={(e) => setFieldValue("note", e.target.value)}
          error={touched.note && errors.note}
          name="note"
          count={{
            current: values.note.length,
            max: 150,
          }}
        />
      </div>
      <div className={s.fieldWrap}>
        <Attachments
          onChange={handleChangeAttachment}
          onDelete={handleDeleteAttachment}
          attachments={values.attachments}
        />
      </div>
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className={s.approveBtn}>
          Approve
        </Button>
      </div>
    </form>
  );
};

export default ApproverForm;
