import React from "react";
import MainTable from "../../../tables/MainTable/MainTable";
import s from "./RequestTypesTable.module.scss";
import gradient from "random-gradient";
import PropTypes from "prop-types";
import {useHistory, useLocation} from "react-router-dom";
import {trimRest} from "../../../../helpers/stringHelper";

const RequestTypesTable = ({data, onEdit, onDelete, onAdd, totalCount, perPage, onPageChange}) => {
  const location = useLocation();
  const history = useHistory();

  const renderType = name => {
    const typeParts = name.split(" ");
    const avatar = typeParts.length > 1 ? typeParts[0][0] + typeParts[1][0] : typeParts[0][0];

    return (
      <div className={s.typeName} title={name}>
        <span className={s.icon} style={{background: gradient(name)}}>
          {avatar}
        </span>
        {trimRest(70, name)}
      </div>
    );
  };

  return (
    <MainTable
      inlineWrapClassName={s.inlineWrap}
      data={data}
      stickyHeader={true}
      totalName="Request types"
      dataTypes={[
        {
          title: "Type",
          value: ({name}) => renderType(name),
          onClick: id => history.push(`/admin-settings/request/${id}`),
        },
        {
          title: "Description",
          value: ({description}) => trimRest(70, description),
        }
      ]}
      options={item => [
        {
          title: "Edit",
          onClick: () => onEdit(item),
        },
        {
          title: "Delete",
          onClick: () => onDelete(item),
        }
      ]}
      button={{
        name: "New type",
        onClick: () => onAdd()
      }}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={location.search.page || 1}
    />
  );
};

export default RequestTypesTable;

RequestTypesTable.propTypes = {
  data: PropTypes.any,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  totalCount: PropTypes.number,
  perPage: PropTypes.number,
  onPageChange: PropTypes.func
};