import React from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { object, string, boolean } from "yup";
import { useTranslation } from "react-i18next";
import { getTranslateKey } from "helpers/getTranslateKey";
import _ from "lodash";
import Popup from "components/popups/Popup/Popup.jsx";
import Button from "components/elements/Button/Button";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import Input from "components/elements/Input/Input.jsx";
import Textarea from "components/elements/Textarea/Textarea";
import styles from "./TemplatesPopUp.module.scss";
import { statuses } from "../Templates/Templates";
import Checkbox from "../../../../elements/Checkbox/Checkbox";
import classNames from "classnames";
import services from "services/index.js";

export const TemplatesPopUp = ({
  setOpenPopUp,
  openPopup,
  setCurrentTemplates,
  editItem,
  once,
}) => {
  const MAX_NAME_LENGTH = 50;
  const MAX_DESC_LENGTH = 180;
  const { t } = useTranslation();
  const handleOnCancel = () => {
    setOpenPopUp((prevPopUp) => ({ checked: !prevPopUp.checked, action: "" }));
  };

  const correctStatus = statuses.find((el) => el.value === editItem?.status);

  const formik = useFormik({
    initialValues: {
      name: editItem?.name || "",
      status: correctStatus || statuses[0],
      description: editItem?.description || "",
      optional: editItem?.optional || true,
    },
    validationSchema: object({
      name: string()
        .required(t(getTranslateKey("Please, template name is required")))
        .max(
          MAX_NAME_LENGTH,
          t(
            getTranslateKey(
              `The template name is too long. It should be ${MAX_NAME_LENGTH} characters or less.`,
            ),
          ),
        ),
      status: string().required(t(getTranslateKey("Please, status is required"))),
      optional: boolean().oneOf([true], t(getTranslateKey("Please, status is required"))),
      description: string().max(
        MAX_DESC_LENGTH,
        t(
          getTranslateKey(
            `The Description is too long. It should be ${MAX_DESC_LENGTH} characters or less.`,
          ),
        ),
      ),
    }),
    onSubmit: (values) => {
      if (openPopup.action === "Create") {
        const correctValue = {
          ...values,
          status: values.status.value,
          steps: [],
        };
        services.templatesServices.createTemplate(correctValue).then((res) => {
          setCurrentTemplates((prevTemplates) => [...prevTemplates, res.data]);
          handleOnCancel();
        });
      } else {
        const correctValue = {
          ...values,
          status: values.status.value,
          steps: [],
        };
        services.templatesServices.editTemplate(editItem.id, correctValue).then((res) => {
          once
            ? setCurrentTemplates(res.data)
            : setCurrentTemplates((prevTemps) =>
                prevTemps.map((el) => (el.id === res.data.id ? res.data : el)),
              );
          handleOnCancel();
        });
      }
    },
  });
  const { handleSubmit, values, errors, setFieldValue, handleChange, isSubmitting } = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Popup
        className={styles.wrapper}
        title={`${openPopup.action} template`}
        onClose={handleOnCancel}>
        <>
          <Input
            name="name"
            label="Template name"
            placeholder="Enter template name"
            onChange={handleChange}
            limitValue={MAX_NAME_LENGTH}
            withCounter
            value={values.name}
            error={errors.name}
          />
          <CustomDropdown
            options={statuses.filter((st) => !_.isEqual(st, statuses.at(-1)))}
            value={values.status}
            error={errors.status}
            onChange={(option) => setFieldValue("status", option)}
            label="Status"
            placeholder="Choose status"
          />
          <Textarea
            label="Description (optional)"
            placeholder="Enter description"
            onChange={(e) => setFieldValue("description", e.target.value)}
            value={values.description}
            error={errors.description}
            count={{
              current: values.description.length,
              max: MAX_DESC_LENGTH,
            }}
          />
          <Checkbox
            className={styles.checkboxMargin}
            value={values.optional}
            checked={values.optional}
            error={errors.optional}
            onChange={(e) => setFieldValue("optional", e.target.checked)}
            label="Public template"
            subLabel="All users can use this templates"
            withTranslate
          />
          <div className={classNames("popupBtnsWrap", styles.buttonMargin)}>
            <Button styled="secondary" type="reset" className="popupBtn" onClick={handleOnCancel}>
              Cancel
            </Button>
            <Button styled="primary" type="submit" className="popupBtn">
              {openPopup.action === "Create" ? "Create" : "Edit"}
            </Button>
          </div>
        </>
      </Popup>
    </form>
  );
};

TemplatesPopUp.propTypes = {
  setOpenPopUp: PropTypes.func,
  openPopup: PropTypes.object,
  setCurrentTemplates: PropTypes.func,
  editItem: PropTypes.object,
  setEditItem: PropTypes.func,
};
