import React from "react";
import s from "./Comment.module.scss";
import {useTranslate} from "../../../../../hooks/useTranslate";

const Comment = ({ comment }) => {
  const [translateKey, translateValue] = useTranslate(comment);
  return (
    <div className={s.container}>
      <p className={s.text} data-translate-key={translateKey}>
        {translateValue}
      </p>
    </div>
  );
};

export default Comment;
