import React from "react";
import { Chart } from "react-google-charts";
import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./StakeholderResponsesWidget.module.scss";
import format from "./helpers/formatData";

const COLORS = ["#63A5A1"];

const StakeholderResponsesWidget = ({ data }) => {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Stakeholder responses</h4>
      <Chart
        width={"100%"}
        height={"272px"}
        chartType="Bar"
        loader={
          <div className="loaderWrap">
            <CircularProgress />
          </div>
        }
        data={format(data)}
        options={{
          legend: { position: "none" },
          colors: COLORS,
          vAxis: {
            minValue: 0,
          },
        }}
        className={styles.dashedGridLines}
      />
    </div>
  );
};

StakeholderResponsesWidget.propTypes = {
  data: PropTypes.array,
};

export default StakeholderResponsesWidget;
