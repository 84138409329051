import React from "react"
import MainTable from "components/tables/MainTable/MainTable"
import { useHistory } from "react-router-dom"
import { fromDate } from "helpers/dateFormat"
import { currencyData } from "helpers/helpData"
import { formatCurrency } from "helpers/currencyFormat"

const SupplierDetailProjectsTable = ({
  data,
  perPage = 30,
  changeQuery,
  query
}) => {
  const history = useHistory()

  const onPageChange = page => {
    changeQuery(query => ({
      ...query,
      page
    }))
  }

  const onSort = ({ sort_by, direction }) => {
    changeQuery(query => ({ ...query, sort_by, direction, page: 1 }))
  }
  return (
    <MainTable
      data={data.projects}
      withXScroll={true}
      dataTypes={[
        {
          title: "Project name",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "name", direction })
          },
          value: ({ name }) => name,
          onClick: id => history.push(`/projects/${id}`)
        },
        {
          title: "Type",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "project_type", direction })
          },
          value: ({ project_type }) => project_type?.name || "-"
        },
        {
          title: "Category",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "category", direction })
          },
          value: ({ category }) => category?.name || "-"
        },
        {
          title: "Start date",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "start_date", direction })
          },
          value: ({ start_date }) => (start_date ? fromDate(start_date) : "-")
        },
        {
          title: "End date",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "end_date", direction })
          },
          value: ({ end_date }) => (end_date ? fromDate(end_date) : "-")
        },
        {
          title: "Baseline Spend",
          value: ({ baseline_spend, currency }) =>
            baseline_spend && currency
              ? formatCurrency(
                  currencyData.filter(e => e.label === currency)[0]?.value,
                  baseline_spend
                )
              : "-"
        },
        {
          title: "Savings",
          value: ({ savings, currency }) =>
            savings && currency
              ? formatCurrency(
                  currencyData.filter(e => e.label === currency)[0]?.value,
                  savings
                )
              : "-"
        }
      ]}
      perPage={perPage}
      totalCount={data.projects_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={query.page || 1}
    />
  )
}
export default SupplierDetailProjectsTable
