import React from "react";
import MainTable from "../MainTable/MainTable";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import querystring from "query-string";
import {currencyData} from "helpers/helpData";
import {formatCurrency} from "helpers/currencyFormat";
import {fromDate} from "helpers/dateFormat";
import {userRoles} from "helpers/userPermissions/userRoles";
import Tags from "components/elements/Tags/Tags";

const CategoriesProjectsTable = (
  {
    data,
    onDelete,
    onAdd,
    projectsCount,
    perPage,
    onAssignTag,
    onDeleteTag
  }
) => {
  const history = useHistory();
  const query = querystring.parse(history.location.search);
  const user = useSelector(state => state.user);
  const isProcOwner = user.roles.includes(userRoles.procurement_category_owner);
  const isAdmin = user.roles.includes(userRoles.admin);

  const onPageChange = page => {
    history.push({search: querystring.stringify({...query, page})});
  };

  const onSort = ({sort_by, direction, tag}) => {
    history.push({
      search: querystring.stringify({
        ...query,
        sort_by,
        direction,
        page: 1,
        tag
      })
    });
  };

  return (
    <MainTable
      data={data}
      withXScroll={true}
      stickyHeader={true}
      dataTypes={[
        {
          title: "Project name",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          value: ({name}) => name,
          onClick: id => history.push(`/projects/${id}`),
          sticky: true
        },
        {
          title: "Type",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "project_type", direction});
          },
          value: ({project_type}) => project_type?.name || "-"
        },
        {
          title: "Project Owner",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "owner", direction});
          },
          value: ({owner}) => owner?.name || "-"
        },
        {
          title: "Anticipated Benefits",
          value: ({anticipated_savings, anticipated_savings_currency}) =>
            anticipated_savings && anticipated_savings_currency
              ? formatCurrency(
                currencyData.filter(
                  e => e.label === anticipated_savings_currency
                )[0]?.value,
                anticipated_savings
              )
              : "-",

        },
        {
          title: "Achieved Benefits",
          value: ({project_savings, project_savings_currency}) =>
            project_savings && project_savings_currency
              ? formatCurrency(
                currencyData.filter(
                  e => e.label === project_savings_currency
                )[0]?.value,
                project_savings
              )
              : "-",
        },
        {
          title: "End date",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "end_date", direction});
          },
          value: ({end_date}) => (end_date ? fromDate(end_date) : "-")
        },
        {
          title: "Status",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "status", direction});
          },
          value: ({stage}) => stage?.name || "-"
        },
        {
          title: "Tags",
          // eslint-disable-next-line react/display-name
          value: (item, allData) => (
            <Tags
              allData={allData}
              item={item}
              onDelete={id => onDeleteTag(id, item.id)}
              onAssign={id => onAssignTag(id, item.id)}
              onSort={({name}) =>
                query.tag !== name ? onSort({tag: name}) : onSort({tag: ""})
              }
            />
          ),
          type: "tags"
        }
      ]}
      options={item =>
        ((isProcOwner && user.id === item.owner.id) || isAdmin) && [
          {
            title: "Delete",
            onClick: () => onDelete(item.id)
          }
        ]
      }
      button={
        (isProcOwner || isAdmin) && {
          name: "New project",
          onClick: () => onAdd()
        }
      }
      perPage={perPage}
      totalCount={projectsCount}
      onPageChange={onPageChange}
      page={query.page || 1}
      isPagination={true}
    />
  );
};
export default CategoriesProjectsTable;
