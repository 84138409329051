import React from "react";
import s from "./HistoryItem.module.scss";
import PropTypes from "prop-types";
import moment from "moment";
import Files from "../../../forms/AddMilestoneTaskForm/components/Attachments/components/Files/Files";

const HistoryItem = ({data, disabled = false,}) => {
  const {owner, created_at, description, attachments} = data;
  return (
    <div className={s.historyItem}>
      <header className={s.itemHeader}>
        <p>{owner.name}</p>
        <p>{moment(created_at).format("MM/DD/YYYY hh:mm a")}</p>
      </header>
      <p className={s.note}>{description}</p>
      <Files files={attachments}  disabled={disabled} className={s.attachments} withLink={true}/>
    </div>
  );
};

export default HistoryItem;

HistoryItem.propTypes = {
  data: PropTypes.any,
};