import React from "react"
import querystring from "query-string"
import { useHistory } from "react-router-dom"
import MainTable from "components/tables/MainTable/MainTable"
import { formatCurrency } from "helpers/currencyFormat"
import Tags from "components/elements/Tags/Tags"
import qs from "query-string"

const ProjectReportSuppliersTable = ({
  data,
  dataCount,
  currentPage,
  perPage
}) => {
  const history = useHistory()
  const query = qs.parse(history.location.search)

  const onPageChange = page => {
    const query = querystring.parse(history.location.search)
    history.push({ search: querystring.stringify({ ...query, page }) })
  }
  // const onSort = ({ sort_by, direction, tag }) => {
  //   history.push({
  //     search: qs.stringify({ ...query, sort_by, direction, tag, page: 1 }),
  //   });
  // };
  return (
    <MainTable
      data={data}
      ariaLabel='Suppliers'
      perPage={perPage}
      totalCount={dataCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
      dataTypes={[
        {
          title: "Supplier",
          value: ({ name }) => name,
          onClick: id => history.push(`/suppliers/${id}`),
          sticky: true
        },
        {
          title: "Spend(USD)",
          value: ({ spend, spend_currency }) =>
            spend_currency && spend
              ? formatCurrency(spend_currency, spend)
              : "-"
        },
        {
          title: "Payments",
          value: ({ payments_count }) => payments_count || "-"
        },
        {
          title: "Contracts",
          value: ({ contracts_count }) => contracts_count || "-"
        },
        {
          title: "Status",
          value: ({ status }) =>
            status ? status[0].toUpperCase() + status.slice(1) : "-"
        },
        {
          title: "Owner",
          value: ({ owner }) => owner?.name || "-"
        }
        // {
        //   title: "Tags",
        //   value: (item, allData) => (
        //     <Tags
        //       allData={allData}
        //       item={item}
        //       onSort={({ name }) =>
        //         query.tag !== name ? onSort({ tag: name }) : onSort({ tag: "" })
        //       }
        //     />
        //   ),
        //   type: "tags",
        // },
      ]}
    />
  )
}
export default ProjectReportSuppliersTable
