import React from "react";
import ApprovalForm from "../../forms/ApprovalForm/ApprovalForm";
import styles from "./AdminSettingsAddApprovalContainer.module.scss";
import {prepareRequestData} from "../../../helpers/approvals/prepareRequestData";
import services from "../../../services";
import {useHistory, useParams} from "react-router-dom";

const AdminSettingsAddApprovalContainer = () => {
  const params = useParams();
  const history = useHistory();

  const send = values => {
    const prepareValues = {
      ...prepareRequestData(values),
      project_type_id: params.projectTypeId
    };

    services.approvalServices.createApprovalAdmin(prepareValues).then(res => {
      if (res.data) {
        history.push(`/admin-settings/project-types/${params.projectTypeId}`);
      }
    });
  }

  return (
    <div className={styles.approvalContainer}>
      <h2 className={styles.title}>{history.location.state?.projectTypeName ?? "Project type"}</h2>
      <ApprovalForm onSubmit={send}/>
    </div>
  );
};

export default AdminSettingsAddApprovalContainer;