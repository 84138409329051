import React, {useEffect, useRef, useState} from "react";
import s from "./GeneralInformation.module.scss";
import PropTypes from "prop-types";
import {userTypes} from "../../../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import {ReactComponent as TimeIcon} from "../../../../../assets/icons/time-icon.svg";
import {ReactComponent as UserIcon} from "../../../../../assets/icons/user-icon.svg";
import {ReactComponent as ProjectsIcon} from "../../../../../assets/icons/projects-icon.svg";
import {ReactComponent as AssigneeIcon} from "../../../../../assets/icons/assignee-icon.svg";
import {dateFormatterWithoutTimezone} from "../../../../../helpers/dateFormat";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const GeneralInformation = ({data}) => {
  const wrapper = useRef(null);
  const [originalHeight, setOriginalHeight] = useState(0);
  const {t} = useTranslation();

  const {business} = userTypes;
  const user = useSelector(state => state.user);

  useEffect(() => {
    setOriginalHeight(wrapper.current.clientHeight);
  }, []);

  const renderUserParam = () => {
    if (user.user_type === business) return (
      <div className={s.singleParam}>
        <AssigneeIcon/>
        <div className={s.infoBLock}>
          <p data-translate-key={getTranslateKey("Responsible user")}>
            {t(getTranslateKey("Responsible user"))}:
          </p>
          <p>{data.assignee?.name ?? "-"}</p>
        </div>
      </div>
    );

    return (
      <div className={s.singleParam}>
        <UserIcon/>
        <div className={s.infoBLock}>
          <p data-translate-key={getTranslateKey("Requester")}>
            {t(getTranslateKey("Requester"))}:
          </p>
          <p>{data.requester?.name ?? "-"}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={s.generalWrap} ref={wrapper} style={{
      height: originalHeight > 0 ? originalHeight : "auto"
    }}>
      <h4 data-translate-key={getTranslateKey("General information")}>
        {t(getTranslateKey("General information"))}
      </h4>
      <div className={s.paramsGroup}>
        {renderUserParam()}
        <div className={s.singleParam}>
          <TimeIcon/>
          <div className={s.infoBLock}>
            <p data-translate-key={getTranslateKey("Request created")}>
              {t(getTranslateKey("Request created"))}
            </p>
            <p>{data.created ? dateFormatterWithoutTimezone(data.created) : "-"}</p>
          </div>
        </div>
        {
          data.project?.id && (
            <>
              <div className={s.singleParam}>
                <ProjectsIcon/>
                <div className={s.infoBLock}>
                  <p data-translate-key={getTranslateKey("Project")}>
                    {t(getTranslateKey("Project"))}:
                  </p>
                  <a href={`/projects/${data.project?.id}`}>
                    {data.project?.name}
                  </a>
                </div>
              </div>
              <div className={s.singleParam}>
                <TimeIcon/>
                <div className={s.infoBLock}>
                  <p data-translate-key={getTranslateKey("Project created")}>
                    {t(getTranslateKey("Project created"))}:
                  </p>
                  <p>
                    {data.project?.created ? dateFormatterWithoutTimezone(data.project?.created) : "-"}
                  </p>
                </div>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

GeneralInformation.propTypes = {
  data: PropTypes.object,
  onReject: PropTypes.func,
  onAssign: PropTypes.func,
  onComplete: PropTypes.func,
};

export default GeneralInformation;
