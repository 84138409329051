import React, {useContext, useEffect, useState} from "react";
import s from "./GeneralForm.module.scss";
import sBase from "../ApprovalForm.module.scss";
import Input from "../../../elements/Input/Input";
import PropTypes from "prop-types";
import CustomDropdown from "../../../elements/CustomDropdown/CustomDropdown";
import {conditionTypes, requestTypes} from "../../../../helpers/approvalsDefaultData";
import services from "../../../../services";
import {ModeContext} from "../../../../contexts/ModeContext/ModeContext";
import {mods} from "../../../../contexts/ModeContext/mods";
import {useHistory, useParams} from "react-router-dom";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const GeneralForm = ({values, setFieldValue, errors, touched}) => {
  const {mode} = useContext(ModeContext);
  const isViewMode = mode === mods.view;
  const params = useParams();
  const history = useHistory();
  const {t} = useTranslation();
  const [stages, setStages] = useState([]);

  useEffect(() => {
    services.projectServices
      .getStages()
      .then(res => {
        const preparedStages = res?.data?.stages;
        preparedStages.pop();
        setStages(preparedStages.map(stage => ({
          value: stage.id,
          label: stage.name,
        })));
      });
  }, []);

  const getProjectStateValue = () => {
    if (params.milestoneId) {
      return {
        label: history.location.state.milestoneName,
        value: params.milestoneId,
      }
    }
    return values.project_state;
  }

  return (
    <div className={s.generalForm}>
      <h3 className={sBase.formGroupTitle} data-translate-key={getTranslateKey("General")}>
        {t(getTranslateKey("General"))}
      </h3>
      <div className={sBase.input}>
        <Input
          value={values.name}
          placeholder='Enter step name'
          onChange={e => !isViewMode && setFieldValue("name", e.target.value)}
          error={touched.name && errors.name && errors.name}
          disabled={isViewMode}
        />
      </div>
      <div className={sBase.inputGroup}>
        <div className={sBase.input}>
          <CustomDropdown
            options={requestTypes}
            value={values.request_type}
            onChange={option => !isViewMode && setFieldValue("request_type", option)}
            placeholder="Choose request type"
            error={touched.request_type && errors.request_type && errors.request_type.value}
            disabled={isViewMode}
          />
        </div>
        <span data-translate-key={getTranslateKey("when")}>
          {t(getTranslateKey("when"))}
        </span>
        <div className={sBase.input}>
          <CustomDropdown
            options={stages}
            value={getProjectStateValue()}
            onChange={option => !isViewMode && setFieldValue("project_state", option)}
            placeholder="Choose project state"
            error={touched.project_state && errors.project_state && errors.project_state.value}
            disabled={isViewMode || Boolean(params.milestoneId)}
          />
        </div>
      </div>
      <div className={sBase.input}>
        <CustomDropdown
          options={conditionTypes}
          value={values.condition_type}
          onChange={option => !isViewMode && setFieldValue("condition_type", option)}
          placeholder="Choose condition"
          error={touched.condition_type && errors.condition_type && errors.condition_type.value}
          disabled={isViewMode}
        />
      </div>
    </div>
  );
};

export default GeneralForm;

GeneralForm.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  errors: PropTypes.any,
  touched: PropTypes.any,
};