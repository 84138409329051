import React, {useEffect} from "react";
import LandingLayout from "../../Landinglayout/Landinglayout";
import TopCard from "./components/TopCard/TopCard";
import CustomLink from "components/elements/CustomLink/CustomLink";
import s from "./PricingPage.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";

const PricingPage = () => {
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo({top: 0});
  });
  return (
    <LandingLayout>
      <div className={s.pricingPage}>
        <div className={s.topBlock}>
          <div className={s.landingContainer}>
            <h1 className={s.title} data-translate-key={"pricing"}> {t(getTranslateKey("pricing"))}</h1>
            <p className={s.text}
               data-translate-key={"our-solution-can-be-deployed-for-use-in-a-matter-of-weeks-for-clients-to-quickly-achieve-value-and-return-on-their-investments."}>
              {t(getTranslateKey("our-solution-can-be-deployed-for-use-in-a-matter-of-weeks-for-clients-to-quickly-achieve-value-and-return-on-their-investments."))}
            </p>

            <TopCard/>
          </div>
        </div>

        <div className={s.landingContainer}>
          <div className={s.wrapper}>
            <div className={s.content}>
              <h3 className={s.contentTitle}
                  data-translate-key={"our-pricing-structure-is-simple-and-predictable,-but-most-of-all-provides-excellent-value."}>
                {t(getTranslateKey("our-pricing-structure-is-simple-and-predictable,-but-most-of-all-provides-excellent-value."))}
              </h3>

              <p className={s.contentText}
                 data-translate-key={"controlling-spend-and-maximizing-the-return-on-your-procurement-spend-is-critical-for-organizations-of-all-size-and-complexity-levels.-investing-in-focal-point-will-yield-significant-returns."}>
                {t(getTranslateKey("controlling-spend-and-maximizing-the-return-on-your-procurement-spend-is-critical-for-organizations-of-all-size-and-complexity-levels.-investing-in-focal-point-will-yield-significant-returns."))}
              </p>
            </div>

            <div className={s.imgBlock}/>
          </div>
          <h3 className={s.subTitle} data-translate-key={"have-other-questions?"}>
            {t(getTranslateKey("have-other-questions?"))}
          </h3>

          <div className={s.contactBlock}>
            <p data-translate-key={"contact-us&nbsp;"}>
              {t(getTranslateKey("contact-us&nbsp;"))}
            </p>
            <CustomLink type="nav-link" path="/contact-us" className={s.link} data-translate-key={"here"}>
              {t(getTranslateKey("here"))}

            </CustomLink>
            <p data-translate-key={"so-we-can-help."}>
              {t(getTranslateKey("so-we-can-help."))}
            </p>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default PricingPage;
