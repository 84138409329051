import React from "react";
import {ReactComponent as Illustration} from "../../../assets/icons/Contact/illustration.svg";
import s from "./InfoBlock.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const InfoBlock = () => {
  const {t} = useTranslation();
  return (
    <div className={s.infoBlock}>
      <h3 className={s.infoTitle} data-translate-key={"services-&-technology-inquiries"}>
        {t(getTranslateKey("services-&-technology-inquiries"))}
      </h3>

      <p className={s.infoText}
         data-translate-key={"we're-happy-to-hear-form-you!-for-any-inquiries-or-questions,-please-email-us-or-fill-out-the-following-form-below."}>
        {t(getTranslateKey("we're-happy-to-hear-form-you!-for-any-inquiries-or-questions,-please-email-us-or-fill-out-the-following-form-below."))}
      </p>

      <a
        href="mailto:info@focalpointprocurement.com"
        target="_blank"
        rel="noopener noreferrer"
        className={s.mailLink}
      >
        info@focalpointprocurement.com
      </a>

      <a
        href="tel:+14044106601"
        target="_blank"
        rel="noopener noreferrer"
        className={s.phoneLink}
      >
        +1 (404) 410-6601
      </a>

      <Illustration className={s.illustration}/>
    </div>
  );
};

export default InfoBlock;
