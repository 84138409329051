import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import AutoCompleteInput from "../../AutoCompleteInput/AutoCompleteInput";
import {debounce} from "lodash";
import services from "../../../../services";

const FormConstructorAutocomplete = ({field, values, setFieldValue, touched, errors}) => {
  const [options, setOptions] = useState([]);

  const searchHandler = useCallback(
    debounce(
      (term) => field.load(term)
        .then(res => setOptions(res.data)),
      300
    ),
    []
  );

  return (
    <div>
      <AutoCompleteInput
        data={options}
        placeholder={field.placeholder}
        value={values[field.key]?.name || ""}
        onChange={e => {
          setFieldValue(field.key, {
            id: "",
            name: e.target.value
          });
          searchHandler(e.target.value);
        }}
        onSelect={value => {
          setFieldValue(field.key, {
            id: value.id,
            name: value.name
          });
        }}
        label={field.title}
        error={touched[field.key] && errors[field.key] && errors[field.key].name}
      />
    </div>
  );
};

export default FormConstructorAutocomplete;

FormConstructorAutocomplete.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  touched: PropTypes.any,
  errors: PropTypes.any,
  options: PropTypes.array,
};