import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import styles from "./EditScalePage.module.scss";
import MainTable from "components/tables/MainTable/MainTable";
import { colors } from "../SurveyTemplateTable/SurveyTemplateTable";
import { ReactComponent as Checked } from "assets/icons/check.svg";
import { ReactComponent as Deleted } from "assets/icons/deleted.svg";
import { useSelector } from "react-redux";
import { userRoles } from "helpers/userPermissions/userRoles.js";
import { ReactComponent as Error } from "assets/icons/error.svg";

const ColorBtn = styled.div`
  width: 70px;
  height: 30px;
  border-radius: 7px;
  background-color: ${({ color }) => color ?? ""};
  margin-bottom: 7px;
  margin-left: ${({ isNewColor }) => (isNewColor ? "98px" : "0px")};
  cursor: pointer;
`;

const NewColorBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin: 4px;
  border-radius: 2px;
  cursor: pointer;
`;

export const EditScalePage = ({ statuses, setStatuses }) => {
  const DECREASE = "decrease";
  const INCREASE = "increase";
  const [editScale, setEditScale] = useState(false);
  const [tempStatuses, setTempStatuses] = useState(statuses);
  const [colorPopUp, setColorPopUp] = useState({ checked: false, item: {} });
  const [error, setError] = useState(false);
  const user = useSelector((state) => state?.user);
  const isAdmin = user.roles.includes(userRoles.admin);
  const isProcurmentAdmin = user.roles.includes(userRoles.procurement_admin);
  const isCPO = user.roles.includes(userRoles.cpo);

  const isAvailableSum =
    tempStatuses.reduce((acc, s) => acc + (s.core - s.mincore), 0) + tempStatuses.length - 1;

  const getRandomColor = (prevStats) => {
    const color = colors[Math.floor(Math.random() * colors.length)];
    return [...statuses, ...prevStats].some((el) => el.color === color)
      ? getRandomColor(prevStats)
      : color;
  };

  const changeColor = (item, newColor) => {
    setTempStatuses((prevStats) =>
      prevStats.map((el) => (_.isEqual(el, item) ? { ...el, color: newColor } : el)),
    );
    setColorPopUp((prevPopup) => ({ checked: !prevPopup.checked, item: {} }));
  };

  const editBlur = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      const selection = window.getSelection();
      if (selection) selection.removeAllRanges();
      event.target.blur();
    }
  };

  useEffect(() => {
    setTempStatuses((p) =>
      p.map((ps) => ({
        ...ps,
        status: (
          <label
            className={styles.statusName}
            contentEditable={editScale}
            suppressContentEditableWarning={editScale}
            onKeyDown={editBlur}>
            {ps.status}
          </label>
        ),
      })),
    );
  }, [tempStatuses.length]);

  const newStatus = (prevStats) => ({
    status: `New status ${prevStats.length - 3}`,
    color: `${getRandomColor(prevStats)}`,
    mincore: tempStatuses.at(-1).core + 1,
    core: tempStatuses.at(-1).core + 2,
  });

  const handleAddStatus = () => {
    setError(false);
    tempStatuses.length <= 9 &&
      setTempStatuses((prevStats) => [...prevStats, newStatus(prevStats)]);
  };

  const addNewStatus = {
    status: (
      <label
        style={{ color: "#2F78CD", cursor: "pointer" }}
        onClick={() =>
          isAvailableSum < 99 && tempStatuses.at(-1).core < 99 ? handleAddStatus() : setError(true)
        }>
        + Add new status
      </label>
    ),
  };

  const scaleArr =
    editScale && (isAdmin || isProcurmentAdmin || isCPO)
      ? [...tempStatuses, addNewStatus]
      : tempStatuses;

  const handleDelete = (item) => {
    setTempStatuses((prevStats) => prevStats.filter((el) => !_.isEqual(el, item)));
  };

  const handleOnCancel = () => {
    setTempStatuses(statuses);
    setEditScale(false);
  };

  const handleEdit = () => {
    setEditScale(true);
  };

  const handleSave = () => {
    if (isAvailableSum === 100) {
      setStatuses(
        tempStatuses.map((st) => ({
          ...st,
          status:
            typeof st.status.props.children === "string"
              ? st.status.props.children
              : st.status.props.children.props.children,
        })),
      );
      setEditScale(false);
      setError(false);
    } else {
      setError(true);
    }
  };

  const changeCore = (item, action) => {
    if (editScale) {
      const currentId = tempStatuses.findIndex((el) => el.status === item.status);
      if (action === DECREASE) {
        if (currentId == 0 && item.core > 1) {
          setTempStatuses((prevStatuses) =>
            prevStatuses.map((status) =>
              status.status === item.status ? { ...status, core: item.core - 1 } : status,
            ),
          );
          setTempStatuses((prevStatuses) =>
            prevStatuses.map((status, id) =>
              id === currentId && status.mincore > 0
                ? { ...status, mincore: status.mincore - 1 }
                : status,
            ),
          );
        } else if (item.core > 1 && item.core > tempStatuses?.[currentId - 1]?.core + 2) {
          setTempStatuses((prevStatuses) =>
            prevStatuses.map((status) =>
              status.status === item.status ? { ...status, core: item.core - 1 } : status,
            ),
          );
          setTempStatuses((prevStatuses) =>
            prevStatuses.map((status, id) =>
              id === currentId + 1 ? { ...status, mincore: item.core } : status,
            ),
          );
        }
      } else {
        if (_.isEqual(item, tempStatuses.at(-1)) && item.core < 100) {
          setTempStatuses((prevStatuses) =>
            prevStatuses.map((status) =>
              status.status === item.status ? { ...status, core: item.core + 1 } : status,
            ),
          );
          setTempStatuses((prevStatuses) =>
            prevStatuses.map((status, id) =>
              id === currentId + 1 ? { ...status, mincore: item.core + 2 } : status,
            ),
          );
        } else if (item.core < 100 && item.core < tempStatuses[currentId + 1].core - 2) {
          setTempStatuses((prevStatuses) =>
            prevStatuses.map((status) =>
              status.status === item.status ? { ...status, core: item.core + 1 } : status,
            ),
          );
          setTempStatuses((prevStatuses) =>
            prevStatuses.map((status, id) =>
              id === currentId + 1 ? { ...status, mincore: item.core + 2 } : status,
            ),
          );
        }
      }
    }
  };

  const maxCoreContainer = (item) => (
    <div className={styles.maxCoreContainer}>
      <div
        onClick={() => changeCore(item, DECREASE)}
        style={{ cursor: "pointer", userSelect: "none" }}>
        -
      </div>
      <div>{item.core}</div>
      <div
        onClick={() => changeCore(item, INCREASE)}
        style={{ cursor: "pointer", userSelect: "none" }}>
        +
      </div>
    </div>
  );

  return (
    <>
      {error && (
        <section className={styles.error}>
          <Error />
          The weight of all questions must be 100%. Please, change the weight.
        </section>
      )}
      <MainTable
        className={styles.editScaleWrapper}
        titleName="Edit scale"
        headerType="title"
        data={scaleArr}
        withXScroll={true}
        dataTypes={[
          {
            title: "Status",
            value: ({ status }) => status,
          },

          {
            title: "Color",
            position: "center",
            value: (item) => (
              <>
                <ColorBtn
                  color={item.color}
                  isNewColor={colorPopUp.checked && _.isEqual(item, colorPopUp.item) && editScale}
                  onClick={() =>
                    editScale
                      ? setColorPopUp((prev) => ({ checked: !prev.checked, item: item }))
                      : undefined
                  }
                />
                {colorPopUp.checked && _.isEqual(item, colorPopUp.item) && editScale && (
                  <div className={styles.newColorConrainer}>
                    {colors.map((color) => (
                      <NewColorBtn
                        style={{ backgroundColor: color }}
                        onClick={() => changeColor(item, color)}>
                        {color === colorPopUp.item.color && <Checked height={12} width={12} />}
                      </NewColorBtn>
                    ))}
                  </div>
                )}
              </>
            ),
          },
          {
            title: "Minimum core",
            position: "center",
            value: ({ mincore }) => mincore,
          },
          {
            title: "Maximum core",
            position: "center",
            value: (item) => (item.core ? maxCoreContainer(item) : ""),
          },
          {
            position: "end",
            value: (item) =>
              editScale &&
              !_.isEqual(item, addNewStatus) && (
                <Deleted
                  height={30}
                  widht={40}
                  onClick={() => handleDelete(item)}
                  style={{ cursor: "pointer" }}
                />
              ),
          },
        ]}
        buttons={
          editScale
            ? [
                {
                  name: "Cancel",
                  styled: "secondary",
                  onClick: handleOnCancel,
                },
                {
                  name: "Save",
                  styled: "primary",
                  onClick: handleSave,
                },
              ]
            : [
                {
                  name: "Edit",
                  styled: "primary",
                  onClick: handleEdit,
                },
              ]
        }
      />
    </>
  );
};
