import axios from "./axios";

export default {
  getNotifications: async (params) => {
    try {
      const result = await axios.get("/notifications", { params });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getNotificationsCount: async () => {
    try {
      const result = await axios.get("/notifications/counter");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getNotificationsList: async (params) => {
    try {
      const result = await axios.get("/notifications/list", { params });
      return result;
    } catch (error) {
      throw error;
    }
  },
  updateNotifications: async (params) => {
    try {
      const result = await axios.put("/notifications/update", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  deleteNotifications: async (params) => {
    try {
      const result = await axios.delete("/notifications/destroy", { params });
      return result;
    } catch (error) {
      throw error;
    }
  },
};
