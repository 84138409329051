import React from 'react';
import classNames from "classnames";
import s from "./NewRequest.module.scss";
import {FormControlLabel, Radio} from "@material-ui/core";

const SingleRequestType = ({requestType, selectedRequestTypeId, onClick}) => {

  return (
    <div
      className={classNames(s.singleRequestType, {[s.selected]: selectedRequestTypeId === requestType.id})}
      onClick={onClick}
    >
      <span className={s.name}>{requestType.name}</span>
      <FormControlLabel
        checked={selectedRequestTypeId === requestType.id}
        control={<Radio color="primary"/>}
        label=""
      />
    </div>
  );
};

export default SingleRequestType;