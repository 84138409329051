import React, { useState, useRef, useEffect, memo } from "react";
import s from "./ActivityItem.module.scss";
import classNames from "classnames";
import { ReactComponent as Reject } from "assets/icons/bigReject.svg";
import { ReactComponent as Success } from "assets/icons/bigSuccess.svg";
const ActivityItem = memo(({ data, left, top }) => {
  const { status, name, assigned_to } = data;
  const NAME_MAX_LENGTH = 16;
  const USER_MAX_LENGTH = 7;

  const formatStr = (str, maxLength) => {
    if (str.length > maxLength) {
      return `${str.substr(0, maxLength)}...`;
    }
    return str;
  };

  return (
    <div
      className={s.activityItem}
      style={{ left: `${left}px`, top: `${top}px` }}
    >
      <span
        className={classNames(s.icon, {
          [s.success]:
            status === "Approved" ||
            status === "Not required" ||
            status === null,
          [s.reject]: status === "Rejected",
          [s.pending]: status === "Pending",
        })}
      >
        {status === "Approved" && <Success />}
        {status === "Rejected" && <Reject />}
      </span>
      <span className={s.name}>{formatStr(name, NAME_MAX_LENGTH)}</span>
      <span className={s.assignedTo}>
        {`Assigned to: ${formatStr(assigned_to, USER_MAX_LENGTH)}`}
      </span>
      {status && <span className={s.status}>{`Status: ${status}`}</span>}
    </div>
  );
});
export default ActivityItem;
