import React, {useEffect, useState} from 'react';
import {parserForSelect} from "../../../containers/AdminSettingsStepFieldDetailsContainer/helpers/parserForSelect";
import AutoCompleteInput from "../../AutoCompleteInput/AutoCompleteInput";
import services from "../../../../services";
import Input from "../../Input/Input";
import {recursiveJoin} from "../../../../helpers/recursiveJoin";
import {emptySelect} from "../../../../helpers/emptyData";

const CategoriesSelect = (
  {
    changeSingleCondition,
    defaultValue,
    isViewMode,
    onlyFirstLevel = false,
  }
) => {
  const [value, setValue] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    services.categoriesServices
      .getCategories()
      .then(res => {
        if (onlyFirstLevel) {
          setCategories(res.data);
        } else {
          setCategories(recursiveJoin(res.data, "categories"));
        }
      });
  }, []);

  useEffect(() => {
    if (defaultValue && defaultValue !== " ") {
      services.categoriesServices
        .getCategory(defaultValue)
        .then(res => setValue(res.data.name || ""));
    }
  }, [defaultValue]);

  if (isViewMode) return <Input value={value} withoutChange/>;

  return (
    <AutoCompleteInput
      label=""
      value={value}
      placeholder=""
      onChange={e => {
        changeSingleCondition(emptySelect);
        setValue(e.target.value);
      }}
      onSelect={value => {
        changeSingleCondition(parserForSelect(value));
      }}
      data={categories}
    />
  );
};

export default CategoriesSelect;