import React, {useEffect, useState} from "react";
import classNames from "classnames";
import services from "services/index";
import s from "./Notifications.module.scss";
import sBase from "../MainDashboard.module.scss";
import moment from "moment";
import NotificationsLoading from "../../../../../loadingStates/Dashboard/Notifications/NotificationsLoading";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Notifications = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    services.dashboardServices.getNotifications().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const getUrl = (notification) => {
    if ((notification.subject_type === "Task") && notification.task_id) {
      return [`/projects/${notification.project_id}/milestones/${notification.milestone_id}/tasks/${notification.task_id}`];
    }

    if (
      (notification.subject_type === "Task" || notification.subject_type === "Milestone") &&
      notification.project_id &&
      notification.milestone_id
    ) {
      return [`/projects/${notification.project_id}/milestones/${notification.milestone_id}`];
    }

    if (notification.subject_type === "ProcurementRequest" && notification.procurement_request_id) {
      return [`/requests/${notification.procurement_request_id}`];
    }

    if (notification.subject_type === "Project" && notification.project_id) {
      return [`/projects/${notification.project_id}`];
    }

    if (notification.subject_type === "Approval" && notification.project_id) {
      return [`/projects/${notification.project_id}/milestones/${notification.milestone_id}/approvals/${notification.approval_id}/detail`];
    }

    return [""];
  };

  const getContent = () => {
    if (data.length) {
      return (
        <div className={s.content}>
          {
            data.map((n, index) => {
              return (
                <a
                  key={index}
                  className={s.notification}
                  onClick={(e) => {
                    e.preventDefault();
                    const [url, state = {}] = getUrl(n);
                    if (url !== "") history.push(url, state);
                  }}>
                  <p className={s.message}>{n.message}</p>
                  <span className={s.created}>{moment(n.created_at).format("h:mm:ss a, MM/DD/YYYY")}</span>
                </a>
              );
            })
          }
        </div>
      );
    }
    return <EmptyContent padding={88} text="You don’t have notifications yet."/>;
  };

  return (
    <section className={classNames(sBase.wrapper, s.notificationsWrapper)}>
      {
        loading ? <NotificationsLoading/> :
          <>
            <h4 className={classNames(sBase.title, s.title)}
                data-translate-key="notifications">{t("notifications")}</h4>
            {getContent()}
          </>
      }
    </section>
  );
};

export default Notifications;


