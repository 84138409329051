import React from "react"
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout"
import SupplierDetailsContainer from "components/containers/SupplierDetailsContainer/SupplierDetailsContainer"
import { useSelector } from "react-redux"
const SupplierDetailsPage = () => {
  const lastLocation = useSelector(state => state.lastLocation)
  const categories = useSelector(state => state.categories)

  const renderCrumbs = path => {
    if (path?.includes("/categories")) {
      return [
        {
          name: "Categories",
          path: `/categories/${categories?.currentCategoryID ||
            categories.categories?.[0].id}`
        },
        { name: "Supplier details", path: "" }
      ]
    } else if (path?.includes("/system-reports")) {
      return [
        {
          name: "System reports",
          path: "/system-reports"
        },
        { name: "Supplier reports", path: "" }
      ]
    } else {
      return [
        {
          name: "Suppliers",
          path: "/suppliers"
        },
        { name: "Supplier details", path: "" }
      ]
    }
  }
  return (
    <DefaultLayout
      breadcrumbs={renderCrumbs(
        lastLocation[lastLocation.length - 2]?.pathname
      )}
    >
      <SupplierDetailsContainer />
    </DefaultLayout>
  )
}

export default SupplierDetailsPage
