import React from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import { getTranslateKey } from "../../../../../helpers/getTranslateKey";
import Popup from "components/popups/Popup/Popup.jsx";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input.jsx";
import Textarea from "components/elements/Textarea/Textarea";
import services from "services";

export const NewStepPopup = ({ setNewPopUp, setSteps, currentId, newPopUp, steps }) => {
  const { t } = useTranslation();
  const handleOnCancel = () => setNewPopUp((p) => ({ checked: !p, action: "" }));

  const formik = useFormik({
    initialValues: {
      name: newPopUp?.item?.name || "",
      description: newPopUp?.item?.description || "",
    },
    validationSchema: object({
      name: string()
        .required(t(getTranslateKey("Please, template name is required")))
        .max(
          50,
          t(getTranslateKey(`The template name is too long. It should be 50 characters or less.`)),
        ),
      description: string().max(
        180,
        t(getTranslateKey(`The Description is too long. It should be 180 characters or less.`)),
      ),
    }),
    onSubmit: (values) => {
      if (newPopUp.action === "Create") {
        if (newPopUp?.subAction === "temp") {
          setSteps((prevSteps) => [...prevSteps, { ...values, questions: [] }]);
          handleOnCancel();
        } else {
          services.templatesServices
            .createStep(currentId, [...steps, { ...values, questions: [] }])
            .then((res) => {
              setSteps(res.data);
              handleOnCancel();
            });
        }
      } else {
        services.templatesServices
          .createStep(
            currentId,
            steps.map((el) => (el.id === newPopUp.item.id ? { ...newPopUp.item, ...values } : el)),
          )
          .then((res) => {
            setSteps(
              res.data.map((st) => ({
                ...st,
                questions: st.questions.filter((q) => q.status !== "inactive"),
              })),
            );
            handleOnCancel();
          });
      }
    },
  });
  const { handleSubmit, values, errors, setFieldValue, handleChange } = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Popup
        title={newPopUp?.action === "Create" ? "New step" : "Edit step"}
        onClose={handleOnCancel}>
        <>
          <Input
            name="name"
            label="Step name"
            placeholder="Enter step name"
            onChange={handleChange}
            limitValue={50}
            withCounter
            value={values.name}
            error={errors.name}
          />
          <Textarea
            label="Description (optional)"
            placeholder="Enter description"
            onChange={(e) => setFieldValue("description", e.target.value)}
            value={values.description}
            error={errors.description}
            count={{
              current: values.description.length,
              max: 180,
            }}
          />
          <div className="popupBtnsWrap">
            <Button styled="secondary" type="reset" className="popupBtn" onClick={handleOnCancel}>
              Cancel
            </Button>
            <Button styled="primary" type="submit" className="popupBtn">
              {newPopUp?.action === "Create" ? "Create" : "Edit"}
            </Button>
          </div>
        </>
      </Popup>
    </form>
  );
};
