import React, {useCallback, useState} from "react";
import {useFormik} from "formik";
import Button from "../../../../elements/Button/Button";
import {object, string} from "yup";
import AutoCompleteInput from "../../../../elements/AutoCompleteInput/AutoCompleteInput";
import {debounce} from "lodash";
import services from "../../../../../services";

const TypeOfSpendForm = ({onSubmit, onClose, typeOfSpendList}) => {
  const [searchedTypeOfSpend, setSearchedTypeOfSpend] = useState([]);

  const onSearchTypeOfSpend = useCallback(
    debounce((query) => {
      services.categoriesServices.getCategories({query}).then((res) => {
        setSearchedTypeOfSpend(res.data.filter(t => typeOfSpendList.map(s => s.category.name).indexOf(t.name) === -1));
      });
    }, 300),
    []
  );

  const formik = useFormik({
    initialValues: {
      contract: '',
    },
    validationSchema: object({
      contract: string().required("Type of Spend is required"),
    }),
    onSubmit: (values) => {
      onSubmit({
        contract: values,
      });
      onClose();
    },
    validate: (values) => {
      let errors = {};
      if (!values.contract?.name) {
        errors.contract = "Type of Spend is required"
      }
      return errors
    },
  });

  const {handleSubmit, values, setFieldValue, errors, touched} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <AutoCompleteInput
        data={searchedTypeOfSpend}
        value={values.contract.name ? values.contract.name : values.contract}
        placeholder="Start typing type of spend"
        onChange={(e) => {
          setFieldValue("contract", e.target.value);
          onSearchTypeOfSpend({query: e.target.value,});
        }}
        onSelect={(value) => {
          setFieldValue("contract", value);
        }}
        label="Type of Spend"
        name="type_of_spend"
        emptyText={{
          text: "Type of spend not found",
        }}
        error={errors.contract && touched.contract && errors.contract}
        withArrow
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">Create</Button>
      </div>
    </form>
  );
}
export default TypeOfSpendForm;
