import axios from "./axios";

export default {
  getTemplates: async (params) => {
    try {
      return await axios.get("/admin/survey_templates", {
        params,
      });
    } catch (error) {
      throw error;
    }
  },
  createTemplate: async (params) => {
    try {
      return await axios.post("/admin/survey_templates", params);
    } catch (error) {
      throw error;
    }
  },
  editTemplate: async (id, params) => {
    try {
      return await axios.put(`/admin/survey_templates/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  restoreTemplate: async (id, params) => {
    try {
      return await axios.put(`/admin/survey_templates/${id}/restore`, params);
    } catch (error) {
      throw error;
    }
  },
  deleteTemplate: async (id) => {
    try {
      return await axios.delete(`/admin/survey_templates/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getSteps: async (id) => {
    try {
      return await axios.get(`/admin/survey_templates/${id}/survey_steps`);
    } catch (error) {
      throw error;
    }
  },
  createStep: async (id, params) => {
    try {
      return await axios.post(`/admin/survey_templates/${id}/survey_steps`, params);
    } catch (error) {
      throw error;
    }
  },
  editStep: async (tid, sid, params) => {
    try {
      return await axios.put(`/admin/survey_templates/${tid}/survey_steps/${sid}`, params);
    } catch (error) {
      throw error;
    }
  },
  deleteStep: async (tid, sid) => {
    try {
      return await axios.delete(`/admin/survey_templates/${tid}/survey_steps/${sid}`);
    } catch (error) {
      throw error;
    }
  },
};
