import React, {useState} from "react";
import classNames from "classnames";
import CustomLink from "components/elements/CustomLink/CustomLink";
import {ReactComponent as BackArrow} from "assets/icons/backArrow.svg";
import s from "../SidebarNavigation/SidebarNavigation.module.scss";
import Divider from "../../../../../../elements/Divider/Divider";
import useSessionStorage from "../../../../../../../hooks/useSessionStorage";
import {useTranslate} from "../../../../../../../hooks/useTranslate";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../../helpers/getTranslateKey";

const SidebarNavigationItem = (
  {
    icon,
    name,
    link,
    sub,
    disable,
    exact,
    activeRoutes,
    linkInStart,
    index,
    width,
    setSize,
    childSetDragStop
  }
) => {
  const [currentMenuTab, setCurrentMenuTab, removeCurrentMenuTab] = useSessionStorage("currentMenuTab");
  const [visualSub, setVisualSub] = useState(Number(currentMenuTab) === index);
  const [translateKey, translateValue] = useTranslate(name);
  const {t} = useTranslation();
  const handleToggleSub = () => {
    if (!visualSub) {
      setCurrentMenuTab(index)
    } else {
      removeCurrentMenuTab("currentMenuTab");
    }
    setVisualSub(!visualSub);
    setSize({width: 230});
    childSetDragStop(!visualSub);
  };

  return (
    <div onClickCapture={(e) => disable && e.preventDefault()}>
      <CustomLink
        type="nav-link"
        path={link}
        className={classNames(s.navigationItem, {[s.disable]: disable})}
        activeClassName={s.active}
        onClick={(e) => {
          if (sub && Boolean(sub.length)) {
            e.preventDefault();
            handleToggleSub();
          }
        }}
        exact={exact}
        activeRoutes={activeRoutes}
        subLinks={sub}
        linkInStart={linkInStart}
      >
        <span className={classNames(s.iconWrap, {[s.large]: width < 101})}>{icon}</span>

        {width > 100 && <p data-translate-key={translateKey}>{translateValue}</p>}
        {width < 100 && <span className={s.tooltip} data-translate-key={translateKey}>{translateValue}</span>}
      </CustomLink>
      {sub && (
        <div className={classNames(s.subNavigationWrap, {[s.active]: visualSub})}>
          <div className={s.subNavigationHeader}>
            <button className={s.backBtn} onClick={handleToggleSub} data-translate-key="back-to-menu">
              <BackArrow/>{t("back-to-menu")}
            </button>
            <Divider background="#C8C8C8"/>
            <p className={s.parentItemName} data-translate-key={translateKey}>
              {translateValue}
            </p>
          </div>
          <div>
            {
              sub.map((item, index) => (
                <CustomLink
                  key={index}
                  path={item.link}
                  type="nav-link"
                  className={classNames(s.navigationItem, s.subItem, {
                    [s.disable]: item.disable,
                  })}
                  activeClassName={s.active}
                  onClick={(e) => {
                    if (item.disable) e.preventDefault();
                  }}
                  exact={item.exact}
                  activeRoutes={item.activeRoutes}
                  linkInStart={item.linkInStart}
                >
                  <p data-translate-key={getTranslateKey(item.name)}>
                    {t(getTranslateKey(item.name))}
                  </p>
                </CustomLink>
              ))
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarNavigationItem;
