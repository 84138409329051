import React, {useEffect} from "react";
import s from "../QuestionsContent.module.scss";
import Input from "../../../../../elements/Input/Input";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import classNames from "classnames";
import plus from "../../../../../../assets/icons/grey-plus.svg";
import {generateUniqueId} from "../../../../../../helpers/uniqueId";
import {updateLocalQuestion} from "../../../helpers/actions";
import {ReactComponent as Clear} from "assets/icons/clear.svg";

const SingleQuestionSingleChoice = ({dispatch, currentQuestion, isEdit, updateQuestion}) => {

  useEffect(() => {
    if (!currentQuestion.answers.length) {
      updateLocalQuestion(dispatch, addNewRadioItem());
    }
  }, []);


  const handleChange = (value, id) => {
    return {
      ...currentQuestion,
      answers: currentQuestion.answers.map(qr => {
        if (qr.answer_id === id) {
          return {...qr, answer_name: value};
        }
        return qr;
      })
    };
  };

  const addNewRadioItem = () => {
    if (!currentQuestion.answers) {
      currentQuestion.answers = [];
    }
    return {
      ...currentQuestion,
      answers: [...currentQuestion.answers, {answer_id: `random-${generateUniqueId()}`, answer_name: ""}],
    };
  };

  const deleteRadioItem = id => {
    return {
      ...currentQuestion,
      answers: currentQuestion.answers.filter(a => a.answer_id !== id)
    };
  };
  if (isEdit) {
    return (
      <div className={s.questionContent} data-drag-item="drag">
        <p className={s.titleQuestion} data-drag-item="drag">
          {currentQuestion.question_name}
          {currentQuestion.is_required && <sup>*</sup>}
        </p>
        <Input
          value={currentQuestion.question_name}
          onChange={event => updateQuestion({...currentQuestion, question_name: event.target.value})}
          placeholder='Question text'
          className={classNames(
            s.input,
            s.customMargin,
            s.padding,
            {[s.withTitle]: Boolean(currentQuestion.question_name)}
          )}
        />
        {
          currentQuestion.answers?.length ? currentQuestion.answers
            .map(qr => (
                <div className={s.answerWrapper} key={qr.answer_id} data-drag-item="drag">
                  <Input
                    value={qr.answer_name}
                    onChange={event => updateQuestion(handleChange(event.target.value, qr.answer_id))}
                    placeholder='Question text'
                    className={s.input}
                  />
                  <button
                    className={s.deleteAnswer}
                    disabled={currentQuestion.answers.length <= 1}
                    onClick={() => updateQuestion(deleteRadioItem(qr.answer_id))}>
                    <Clear/>
                  </button>
                </div>
              )
            ) : null
        }
        <button
          className={s.newRadio}
          onClick={() => updateLocalQuestion(dispatch, addNewRadioItem())}
        >
          <img src={plus}/> Add new answer
        </button>
      </div>
    );
  }

  return (
    <div className={s.questionContent} data-drag-item="drag">
      <p className={s.titleQuestion} data-drag-item="drag">
        {currentQuestion.question_name}
        {currentQuestion.is_required && <sup>*</sup>}
      </p>
      <RadioGroup className={s.radioGroup} aria-label="radioGroup" name="radioGroup">
        {
          currentQuestion.answers?.length ? currentQuestion.answers
            .map(qr =>
              <FormControlLabel
                value={qr.answer_name}
                key={qr.answer_id}
                control={<Radio color="primary"/>}
                label={qr.answer_name || "Question text"}
              />
            ) : null
        }
      </RadioGroup>
    </div>
  );
};

export default SingleQuestionSingleChoice;
