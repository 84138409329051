import React from "react";
import s from "./TotalTime.module.scss";
import { Chart } from "react-google-charts";
import { pieChartColors } from "components/elements/PieChart/colors";
import {getTranslateKey} from "../../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const testColors = ["#5B79B4", "#F4A261"];

const TotalTime = ({
  total_time = {
    active: 0,
    planned: 0,
    total: 0,
  },
}) => {
  const {t} = useTranslation();
  const data = [
    ["Total", String(total_time.total)],
    ["Active", total_time.active],
    ["Planned", total_time.planned],
  ];
  const emptyData = [
    ["Total", "0"],
    ["Active", 1],
    ["Planned", 0],
  ];
  const isEmptyData = Boolean(!data.filter((e) => Boolean(+e[1])).length);
  const sum = +data[0][1];
  return (
    <div className={s.totalTimeWrap}>
      <div className={s.itemsStatus}>
        <span className={s.title} data-translate-key={getTranslateKey("Total Time")}>
          {t(getTranslateKey("Total Time"))}
        </span>
        <div className={s.item}>
          <div className={s.header}>
            <span className={s.label} data-translate-key={getTranslateKey("Active")}>
              {t(getTranslateKey("Active"))}
            </span>
            <span className={s.value}>
              {total_time.active}
              {" "}
              <span data-translate-key={getTranslateKey("days")}>
                {t(getTranslateKey("days"))}
              </span>
            </span>
          </div>
          <div className={s.line}>
            <span
              className={s.percent}
              style={{
                background: "#5B79B4",
                width: `${(total_time.active * 100) / total_time.total}%`,
              }}
            />
          </div>
        </div>
        <div className={s.item}>
          <div className={s.header}>
            <span className={s.label} data-translate-key={getTranslateKey("Planned")}>
              {t(getTranslateKey("Planned"))}
            </span>
            <span className={s.value}>
              {total_time.planned}
              {" "}
              <span data-translate-key={getTranslateKey("days")}>
                {t(getTranslateKey("days"))}
              </span>
            </span>
          </div>
          <div className={s.line}>
            <span
              className={s.percent}
              style={{
                background: "#F4A261",
                width: `${(total_time.planned * 100) / total_time.total}%`,
              }}
            />
          </div>
        </div>
      </div>
      <div className={s.totalChartWrap}>
        <Chart
          chartType="PieChart"
          data={isEmptyData ? emptyData : data}
          legend_toggle={false}
          width={"170px"}
          height={"170px"}
          options={{
            chartArea: { width: "85%", height: "85%" },
            backgroundColor: "transparent",
            colors: sum ? testColors : [pieChartColors.gray],
            pieHole: 0.8,
            pieSliceText: "none",
            tooltip: {
              trigger: "none",
            },
            legend: {
              position: "none",
              alignment: "center",
            },
          }}
        />
        <span className={s.totalWidgetLabel}>
          {sum}
          {" "}
          <span data-translate-key={getTranslateKey("days")}>
            {t(getTranslateKey("days"))}
          </span>
        </span>
      </div>
    </div>
  );
};

export default TotalTime;
