import axios from "./axios";
export default {
  searchTag: async (params) => {
    try {
      return await axios.get(`/tags_autocomplete`, {params});
    } catch (error) {
      throw error;
    }
  },
  addTag: async (values) => {
    try {
      return await axios.post(`/tags`, values);
    } catch (error) {
      throw error;
    }
  },
  assignSupplierTag: async (supplierId, tagId) => {
    try {
      return await axios.post(`/suppliers/${supplierId}/tags`, {
        tag_ids: tagId,
      });
    } catch (error) {
      throw error;
    }
  },
  deleteSupplierTag: async (supplierId, tagId) => {
    try {
      return await axios.delete(`/suppliers/${supplierId}/tags`, {
        params: {tag_id: tagId},
      });
    } catch (error) {
      throw error;
    }
  },
  assignContractTag: async (contractId, tagId) => {
    try {
      return await axios.post(`/contracts/${contractId}/tags`, {
        tag_ids: tagId,
      });
    } catch (error) {
      throw error;
    }
  },
  deleteContractTag: async (contractId, tagId) => {
    try {
      return await axios.delete(`/contracts/${contractId}/tags`, {
        params: {tag_id: tagId},
      });
    } catch (error) {
      throw error;
    }
  },
  assignProjectTag: async (projectId, tagId) => {
    try {
      return await axios.post(`/projects/${projectId}/tags`, {
        tag_ids: tagId,
      });
    } catch (error) {
      throw error;
    }
  },
  deleteProjectTag: async (projectId, tagId) => {
    try {
      return await axios.delete(`/projects/${projectId}/tags`, {
        params: {tag_id: tagId},
      });
    } catch (error) {
      throw error;
    }
  },
};
