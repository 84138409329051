import React from "react";
import MainTable from "../MainTable/MainTable";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import {
  projectTabs,
  approvalTypes,
} from "components/forms/ChangeApprovalStepForm/helpers";

const AdminApprovalsStepsTable = ({
  data,
  onAdd,
  onEdit,
  onDelete,
  perPage,
  totalCount,
  onPageChange,
  currentPage,
}) => {
  const history = useHistory();
  const onSort = ({ sort_by, direction }) => {
    const query = qs.parse(history.location.search);
    history.push({
      search: qs.stringify({ ...query, sort_by, direction, page: 1 }),
    });
  };
  return (
    <MainTable
      totalName="Approval steps"
      data={data.map((item, index) => ({ ...item, index }))}
      dataTypes={[
        {
          title: "Step",
          value: ({ index }) => `Step ${index + 1}`,
          onClick: () => { },
          sticky: true
        },
        {
          title: "Approval group",
          value: ({ approval_group }) => approval_group.name,
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "approval_group", direction });
          },
        },
        {
          title: "Project tab",
          value: ({ project_tab }) =>
            projectTabs.filter((e) => e.value === project_tab)?.[0]?.label,
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "project_tab", direction });
          },
        },
        {
          title: "Approval type",
          value: ({ approval_type }) =>
            approvalTypes.filter((e) => e.value === approval_type)?.[0]?.label,
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "approval_type", direction });
          },
        },
      ]}
      options={(item) => {
        return [
          onEdit && {
            title: "Edit",
            onClick: () => {
              onEdit(item);
            },
          },
          onDelete && {
            title: "Delete",
            onClick: () => {
              onDelete(item);
            },
          },
        ];
      }}
      button={{
        name: "Add step",
        onClick: () => onAdd(),
      }}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};
export default AdminApprovalsStepsTable;
