import React from "react";
import PropTypes from "prop-types";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import {sentences} from "../../../containers/AdminSettingsRequestsContainer/TriageTab/helpers/sentences";
import CustomNotification from "../../QuestionNotification/CustomNotification";

const FormConstructorSelect = (
  {
    field,
    values,
    setFieldValue,
    touched,
    errors,
    options,
  }
) => {
  let valueForCondition = null;

  if (field.showCondition) {
    if (typeof values[field.showCondition.keyReference] === "object") {
      valueForCondition = values[field.showCondition.keyReference].value;
    } else {
      valueForCondition = values[field.showCondition.keyReference];
    }
  }

  if (field.showCondition && !field.showCondition.method(valueForCondition)) return "";

  return (
    <div>
      <CustomDropdown
        options={options}
        value={values[field.key]}
        name={field.key}
        onChange={e => setFieldValue(field.key, e)}
        placeholder={field.placeholder}
        label={field.title}
        withTranslate={field.withTranslate}
        error={touched[field.key] && errors[field.key] && errors[field.key].value}
        titleNotification={field.titleNotification}
      />
    </div>
  );
};

export default FormConstructorSelect;

FormConstructorSelect.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  touched: PropTypes.any,
  errors: PropTypes.any,
  options: PropTypes.array,
};