import { SET_CURRENT_CATEGORY_ID, SET_CATEGORIES } from "helpers/constants"
import services from "services"

export const setCurrentCategoryID = id => {
  return async dispatch => {
    dispatch({
      type: SET_CURRENT_CATEGORY_ID,
      payload: id,
    });
  };
};

export const setCategories = () => {
  return async dispatch => {
    services.categoriesServices.getCategories().then(res => {
      dispatch({
        type: SET_CATEGORIES,
        payload: res.data
      })
    })
  }
}
