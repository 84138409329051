import {
  SET_PROJECTS,
  ADD_PROJECT,
  DELETE_PROJECT,
  SET_POPUP,
  RESET_ALERT,
  CURRENT_PROJECT,
  SET_LOADING,
} from "./types";
import services from "services";

const getAlert = (message) => ({
  type: "success",
  message,
});

const errorAlert = {
  type: "error",
  message: "Something went wrong. Please, try again.",
};

export const setProjects = (dispatch, categoryId, query) => {
  services.categoriesServices
    .getCategoryProjects(categoryId, query)
    .then((res) => {
      dispatch({
        type: SET_PROJECTS,
        payload: res.data,
      });
    });
};

export const addProject = (dispatch, project) => {
  services.projectServices
    .createProject(project)
    .then((res) => {
      dispatch({
        type: ADD_PROJECT,
        payload: {
          project: res.data,
          alert: getAlert("Project successfully created."),
        },
      });
    })
    .catch(() => {
      dispatch({
        type: ADD_PROJECT,
        payload: {
          alert: errorAlert,
        },
      });
    });
};

export const deleteProject = (dispatch, projectId) => {
  services.projectServices
    .deleteProject(projectId)
    .then(() => {
      dispatch({
        type: DELETE_PROJECT,
        payload: {
          project_id: projectId,
          alert: getAlert("Project successfully deleted."),
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DELETE_PROJECT,
        payload: {
          alert: errorAlert,
        },
      });
    });
};

export const setCurrentProject = (id) => ({
  type: CURRENT_PROJECT,
  payload: id,
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const setPopup = (type) => ({
  type: SET_POPUP,
  payload: type,
});

export const resetAlert = () => ({
  type: RESET_ALERT,
});
