import React from "react"
import s from "./SpendStatusesLoading.module.scss"
import Skeleton from "@material-ui/lab/Skeleton"

const SpendStatusesLoading = () => {
  return (
    <>
      <Skeleton
        variant='text'
        className={s.title}
        animation='wave'
        width={200}
      />
      <div className={s.inline}>
        <div className={s.col}>
          <Skeleton
            variant='text'
            className={s.text}
            animation='wave'
            width={160}
          />
          <Skeleton
            variant='text'
            className={s.text}
            animation='wave'
            width={140}
          />
          <Skeleton
            variant='text'
            className={s.text}
            animation='wave'
            width={116}
          />
        </div>
        <Skeleton
          variant='circle'
          className={s.circle}
          animation='wave'
          width={150}
          height={150}
        />
      </div>
    </>
  )
}
export default SpendStatusesLoading
