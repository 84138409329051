import React, {useState} from "react";
import s from "./AdminSettingsContracts.module.scss";
import {useFormik} from "formik";
import {object, string} from "yup";
import CustomDropdown from "../../../elements/CustomDropdown/CustomDropdown";
import Button from "../../../elements/Button/Button";
import Input from "../../../elements/Input/Input";
import services from "../../../../services";
import NotificationBox from "../../../elements/NotificationBox/NotificationBox";
import {useTranslation} from "react-i18next";

const AdminSettingsContracts = ({limitContracts}) => {
  const {t} = useTranslation();
  const [graceTime, setGraceTime] = useState([
    {label: "Days", value: "days"},
    {label: "Weeks", value: "weeks"},
    {label: "Months", value: "months"}
  ]);
  const [alert, setAlert] = useState({message: "", type: ""});
  const errorAlert = {
    type: "error",
    message: "Something went wrong. Please, try again.",
  };


  const formik = useFormik({
    initialValues: {
      postponement: limitContracts ? limitContracts.count : "10",
      dayWeekMonth: limitContracts ? limitContracts.time_metric : graceTime[0],
    },
    validationSchema: object({
      postponement: string().required("Field is required")
        .matches(/^(?![$-])/, "Value should be equal to or greater than 0")
        .max(12, "Maximum 12 characters can be entered"),
    }),
    onSubmit: (values, { resetForm } ) => {
      const params =
        {
          "count": values.postponement,
          "time_metric": values.dayWeekMonth.value ? values.dayWeekMonth.value : values.dayWeekMonth
        };
      services.adminServices.putContractsExpiration(params)
        .then(res => {
        setAlert({
          type: "success",
          message: "A new deadline has been written about the expiration of the contract.",
        });
          resetForm({ values: values })
      })
        .catch(() => setAlert(errorAlert));
    }
  });
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    dirty,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className={s.col}>
        <div>
          <span data-translate-key="receive-notification-about-contract-expiration-in">{t("receive-notification-about-contract-expiration-in")}</span>
          <div className={s.inputBlock}>
            <Input
              name="postponement"
              value={values.postponement}
              onBlur={handleBlur}
              error={errors.postponement && touched.postponement && errors.postponement}
              onChange={handleChange}
              type="number"
            />
            <CustomDropdown
              value={values.dayWeekMonth}
              onChange={value => setFieldValue("dayWeekMonth", value)}
              options={graceTime}
              className={s.changeCurrency}
            />
          </div>
        </div>
      </div>
      <div className={s.btns}>
        <Button disabled={!dirty} styled="primary" className={s.save} type="submit">
          Save
        </Button>
      </div>
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </form>
  );
};

export default AdminSettingsContracts;