import React from "react";
import s from "./WidgetEmptyState.module.scss";
import PropTypes from "prop-types";
import SearchIcon from "assets/icons/search-big.png";
import {useTranslate} from "../../../hooks/useTranslate";

const WidgetEmptyState = ({ description, title }) => {
  const [translateTitleKey, translateTitleValue] = useTranslate(title);
  const [translateDescriptionKey, translateDescriptionValue] = useTranslate(description);

  return (
    <div className={s.emptyWidget}>
      <img className={s.icon} src={SearchIcon} alt="no data" />
      <span className={s.title} data-translate-key={translateTitleKey}>
        {translateTitleValue}
      </span>
      <p className={s.description} data-translate-key={translateDescriptionKey}>
        {translateDescriptionValue}
      </p>
    </div>
  );
};
WidgetEmptyState.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
WidgetEmptyState.defaultProps = {
  description: "",
  title: "No data yet",
};
export default WidgetEmptyState;
