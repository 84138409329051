import axios from "./axios";

export default {
  getFinancialBenefits: async (id) => {
    try {
      return await axios.get(`/projects/${id}/financial_benefits`);
    } catch (error) {
      throw error;
    }
  },

  getFinancialBenefit: async (projectId, financialBenefitId) => {
    try {
      return await axios.get(`/projects/${projectId}/financial_benefits/${financialBenefitId}`);
    } catch (error) {
      throw error;
    }
  },

  createFinancialBenefit: async (id, params) => {
    try {
      return await axios.post(`/projects/${id}/financial_benefits`, params);
    } catch (error) {
      throw error;
    }
  },

  editFinancialBenefit: async (projectId, id, params, config) => {
    try {
      return await axios.put(
        `/projects/${projectId}/financial_benefits/${id}`,
        params,
        config
      );
    } catch (error) {
      throw error;
    }
  },

  deleteFinancialBenefit: async (projectId, id, config) => {
    try {
      return await axios.delete(
        `/projects/${projectId}/financial_benefits/${id}`,
        config
      );
    } catch (error) {
      throw error;
    }
  },

  getNonFinancialBenefits: async () => {
    try {
      return await axios.get("/admin/non_financial_benefits");
    } catch (error) {
      throw error;
    }
  },

  getNonFinancialBenefitsNoAdmin: async () => {
    try {
      return await axios.get("/non_financial_benefits");
    } catch (error) {
      throw error;
    }
  },

  createNonFinancialBenefits: async params => {
    try {
      return await axios.post("/admin/non_financial_benefits", params);
    } catch (error) {
      throw error;
    }
  },

  editNonFinancialBenefits: async (id, params) => {
    try {
      return await axios.put(`/admin/non_financial_benefits/${id}`, params);
    } catch (error) {
      throw error;
    }
  },

  deleteNonFinancialBenefits: async id => {
    try {
      return await axios.delete(`/admin/non_financial_benefits/${id}`);
    } catch (error) {
      throw error;
    }
  },

  restoreNonFinancialBenefits: async id => {
    try {
      return await axios.put(`/admin/non_financial_benefits/${id}/restore`);
    } catch (error) {
      throw error;
    }
  },
};
