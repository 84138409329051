import { SET_LAST_LOCATION } from "helpers/constants";

export const setLocation = (location) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LAST_LOCATION,
      payload: location,
    });
  };
};
