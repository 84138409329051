import React from "react";
import PropTypes from 'prop-types'
import s from "./ManagementCard.module.scss";
import {useTranslate} from "../../../../../../../hooks/useTranslate";

const ManagementCard = ({ img, number, title, text }) => {
  const [translateLabelKey, translateLabelValue] = useTranslate(title);
  const [translateLabelKeyT, translateLabelValueT] = useTranslate(title);
  return (
    <div className={s.managementCard}>
      <img src={img} alt="cardImg" className={s.img} />

      <h3 className={s.title} data-translate-key={translateLabelKey}>
        {number}. {translateLabelValue}
      </h3>

      <p className={s.text} data-translate-key={translateLabelKeyT}>{translateLabelValueT}</p>
    </div>
  );
};

ManagementCard.propTypes = {
  img: PropTypes.any,
  number: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
}

ManagementCard.defaultProps = {
  img: "",
  number: "",
  title: "",
  text: ""
}

export default ManagementCard;
