import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import SupplierDetailStakeholdersTable from "components/tables/SupplierDetailStakeholdersTable/SupplierDetailStakeholdersTable";
import services from "services";

const SupplierStakeholders = () => {
  const params = useParams();
  const [stakeholders, setStakeholders] = useState({ stakeholders: [] });
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({});

  useEffect(() => {
    services.supplierServices
      .supplierReportStakeholders(params.id, query)
      .then((res) => {
        setStakeholders(res.data);
        setLoading(false);
      });
  }, [params.id, query]);

  return (
    <>
      {loading && <TableLoading length={5} />}
      {!loading && Boolean(stakeholders.stakeholders?.length) && (
        <SupplierDetailStakeholdersTable
          data={stakeholders}
          query={query}
          changeQuery={setQuery}
        />
      )}
      {!loading && !Boolean(stakeholders.stakeholders?.length) && (
        <EmptyBlock
          title="Supplier doesn't have any stakeholders yet."
          message
        />
      )}
    </>
  );
};

export default SupplierStakeholders;
