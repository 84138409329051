import axios from "./axios";

export default {
  getContracts: async (params) => {
    try {
      return await axios.get("/contracts", {
        params,
      });
    } catch (error) {
      throw error;
    }
  },
  createContract: async params => {
    try {
      return await axios.post("/contracts", params);
    } catch (error) {
      throw error;
    }
  },
  editContract: async (id, params) => {
    try {
      return await axios.put(`/contracts/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  getContractDetails: async id => {
    try {
      return await axios.get(`/contracts/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getGanttGraph: async id => {
    try {
      return await axios.get(`/contracts/${id}/gantt_graph`);
    } catch (error) {
      throw error;
    }
  },
  deleteContract: async id => {
    try {
      return await axios.delete(`/contracts/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getContract: async (id) => {
    try {
      return await axios.get(`/contracts/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getProjectContracts: async (id) => {
    try {
      return await axios.get(`projects/${id}/contracts`);
    } catch (error) {
      throw error;
    }
  },
  addProjectContract: async (id, params) => {
    try {
      return await axios.post(`projects/${id}/contract`, params);
    } catch (error) {
      throw error;
    }
  },
  addCategoryContract: async (params) => {
    try {
      return await axios.post("/category_contracts", params);
    } catch (error) {
      throw error;
    }
  },
  removeProjectContract: async (id, params, config) => {
    try {
      return await axios.delete(`projects/${id}/contract`, {
        data: params,
        ...config,
      });
    } catch (error) {
      throw error;
    }
  },
  removeCategoryContract: async (id, params) => {
    try {
      return await axios.delete(`category_contracts/${id}`, {data: params});
    } catch (error) {
      throw error;
    }
  },
  getContractsFilters: async (params) => {
    try {
      return await axios.get("/contract_filters", {
        params,
      });
    } catch (error) {
      throw error;
    }
  },
  getContractsAutocomplete: async (params) => {
    try {
      return await axios.get("/contracts_autocomplete", {
        params,
      });
    } catch (error) {
      throw error;
    }
  },
};
