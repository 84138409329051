import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getTranslateKey } from "helpers/getTranslateKey";
import styles from "./SurveysContainer.module.scss";
import { SurveysInfo } from "./components/SurveysInfo/SurveysInfo";

export const SurveysContainer = ({ path }) => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <div>
        <h3 className={styles.title} data-translate-key="surveys">
          {t(getTranslateKey(`Surveys for ${path}s`))}
        </h3>
      </div>
      <SurveysInfo path={path} />
    </section>
  );
};

SurveysContainer.propTypes = {
  path: PropTypes.string,
};
