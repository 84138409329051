import React, {useEffect, useReducer, useState} from "react";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import {useParams} from "react-router-dom";
import {trimRest} from "../../../helpers/stringHelper";
import DetailLoading from "../../loadingStates/Detail/Detail";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import style from "./AdminSettingsStepFieldDetailsContainer.module.scss";
import {initialState, reducer} from "./helpers/reducer";
import {loadRequestDetail, loadStepFieldDetail} from "./helpers/actions";
import DetailFormEdit from "./components/DetailFormEdit/DetailFormEdit";
import DetailBlockView from "./components/DetailBlockView/DetailBlockView";

const AdminSettingsStepFieldDetailsContainer = () => {
  let {requestId, stepId, fieldId} = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {fieldDetail, requestDetails, isEditMode} = state;

  useEffect(() => {
    loadRequestDetail(dispatch, requestId);
    loadStepFieldDetail(dispatch, stepId, fieldId);
  }, []);

  const renderDetailContent = () => {
    if (isEditMode) {
      return <DetailFormEdit field={fieldDetail} dispatch={dispatch}/>;
    }
    return <DetailBlockView field={fieldDetail} dispatch={dispatch}/>;
  };

  const renderDetailBlock = () => {
    if (!fieldDetail) return <DetailLoading length={2}/>;
    return (
      <div className={style.fieldContentWrap}>
        {renderDetailContent()}
      </div>
    );
  };

  return (
    <>
      <DefaultLayout breadcrumbs={[
        {name: "Admin Settings", path: "", disabled: true},
        {
          path: paths.adminSettingsRequest,
          name: "Requests",
        },
        {
          path: `/admin-settings/request/${requestDetails?.id}`,
          name: trimRest(50, requestDetails?.name),
        },
        {
          name: trimRest(50, fieldDetail?.name),
        },
      ]}>
        <div className={s.wrap}>
          <div className={s.header}>
            <h2 className={s.title} title={fieldDetail?.name}>
              {trimRest(50, fieldDetail?.name) || <Skeleton variant="text" animation="wave" width={120}/>}
            </h2>
          </div>
          {renderDetailBlock()}
        </div>
      </DefaultLayout>
    </>
  );
};

export default AdminSettingsStepFieldDetailsContainer;
