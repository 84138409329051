import React, {useEffect, useState} from "react";
import s from "./TasksList.module.scss";
import Button from "components/elements/Button/Button";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import TaskPopup from "../../popups/TaskPopup";
import {useHistory, useParams} from "react-router-dom";
import Task from "./Task/Task";
import Approval from "./Approval/Approval";
import PropTypes from "prop-types";
import services from "../../../../../services";

const TasksList = (
  {
    preparedData = [],
    tasksType,
    addTask,
    editTask,
    deleteTask,
    projectTypeName = ""
  }
) => {
  const [preparedStages, setPreparedStages] = useState(preparedData.sort((a, b) => a - b));
  const history = useHistory();
  const params = useParams();
  const [taskPopUp, setTaskPopUp] = useState(null);
  const [stageId, setStageId] = useState("");

  useEffect(() => {
    setPreparedStages(preparedData.sort((a, b) => a - b));
  }, [preparedData]);

  const reorderTasks = (items, stageId) => {
    const ids = items.map(i => i.id).join();

    if (tasksType === "approvals") {
      services.approvalServices.reorderApprovalsAdmin({ids, stage_id: stageId});
    } else {
      services.adminProjectsServices.reorderConfigureTasks(params.id, {ids, stage_id: stageId});
    }
  };

  const reorder = (list, item, endIndex) => {
    const firstListPart = list.slice(0, endIndex);
    const lastListPart = list.slice(endIndex, list.length);

    return [...firstListPart, item, ...lastListPart];
  };

  const onDragEnd = params => {
    const {destination, draggableId, source} = params;

    if (!destination) return;

    let newStages = preparedStages;
    const fromStage = source.droppableId;
    const toStage = destination.droppableId;
    const fromPosition = source.index;
    const toPosition = destination.index;

    if (fromStage === toStage && fromPosition === toPosition) return;

    newStages = newStages.map(stage => {
      if (stage.id === fromStage) {
        return {...stage, [tasksType]: stage[tasksType].filter((_, index) => index !== fromPosition)};
      }
      return stage;
    });

    const targetItems = newStages.find(stage => stage.id === toStage)[tasksType];
    const currentItem = preparedStages.find(stage => stage.id === fromStage)[tasksType].find(i => i.id === draggableId);

    const reorderedItems = reorder(targetItems, currentItem, toPosition);

    reorderTasks(reorderedItems, toStage);

    newStages = newStages.map(stage => stage.id === toStage ? {...stage, [tasksType]: reorderedItems} : stage);

    setPreparedStages(newStages);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <ul className={s.list}>
          {preparedStages.map(milestone => (
            <li
              className={s.col}
              key={milestone.id}
              style={{width: `${100 / preparedStages.length}%`}}
            >
              <Droppable droppableId={milestone.id} type={tasksType}>
                {provided => (
                  <ul
                    className={s.tasks}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {milestone[tasksType].map((task, index) => (
                      <Draggable draggableId={task.id} index={index} key={task.id}>
                        {provided => {
                          if (tasksType === "approvals") return (
                            <Approval
                              provided={provided}
                              approval={task}
                              projectTypeName={projectTypeName}
                              deleteApproval={() => deleteTask(task.id)}
                            />
                          );
                          return (
                            <Task
                              provided={provided}
                              task={task}
                              tasksType={tasksType}
                              milestoneColor={milestone.color}
                              deleteTask={() => deleteTask(task.id)}
                              editTask={values => editTask({...values, stage_id: milestone.id}, task.id)}
                            />
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
              <Button
                styled='secondary'
                className={s.addTaskBtn}
                onClick={() => {
                  if (tasksType === "approvals") {
                    history.push(`/admin-settings/project-types/${params.id}/create-approval`, {
                      projectTypeName
                    });
                  } else {
                    setStageId(milestone.id);
                    setTaskPopUp("new");
                  }
                }}
              >
                {`New ${tasksType === "approvals" ? "step" : "task"}`}
              </Button>
            </li>
          ))}
        </ul>
      </DragDropContext>
      {taskPopUp === "new" && (
        <TaskPopup
          onClose={() => setTaskPopUp(null)}
          onFormSubmit={values => {
            addTask({...values, stage_id: stageId});
            setTaskPopUp(null);
          }}
          taskType={tasksType.replace("_", " ").slice(0, -1)}
        />
      )}
    </>
  );
};

export default TasksList;

TasksList.propTypes = {
  preparedData: PropTypes.array,
  tasksType: PropTypes.string,
  addTask: PropTypes.func,
  editTask: PropTypes.func,
  deleteTask: PropTypes.func,
  projectTypeName: PropTypes.string,
};
