import React, {useState} from 'react';
import {useFormik} from "formik";
import services from "../../../services";
import Button from "../../elements/Button/Button";
import s from "./EditTriageDetails.module.scss";
import HierarchyItem from "./HierarchyItem";
import classNames from "classnames";
import RSAsyncSelect from "../../elements/ReactSelectCustom/RSAsyncSelect";
import {sentences} from "../../containers/AdminSettingsRequestsContainer/TriageTab/helpers/sentences";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const EditTriageDetailsForm = ({submit, onClose, initialValues}) => {
  let dragSrcEl = null;
  const [hierarchyList, setHierarchyList] = useState([...initialValues.activeHierarchyList]);
  const [ignoreList, setIgnoreList] = useState([...initialValues.ignoreHierarchyList]);
  const {t} = useTranslation();

  const handleDragStart = e => {
    e.target.style.background = "#F1F8FF";
    e.target.style.border = "1px dashed #83A0E9";
    dragSrcEl = e.target;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData('text/html', e.target.innerHTML);
  }

  const handleDragOver = e => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.dataTransfer.dropEffect = "move";
    return false;
  }

  const handleDrop = e => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (dragSrcEl !== e.target && e.target.dataset.dndid === "dnd-list") {
      const foundedElement = ignoreList.find((_, idx) => Number(dragSrcEl.dataset.dndid) === idx);
      if (foundedElement) {
        setIgnoreList(ignoreList.filter(item => item.id !== foundedElement.id));
        setHierarchyList([...hierarchyList, foundedElement]);
      }
    } else if (dragSrcEl !== e.target && e.target.dataset.dndid === "ignore-dnd-list") {
      const foundedElement = hierarchyList.find((_, idx) => Number(dragSrcEl.dataset.dndid) === idx);
      setHierarchyList(hierarchyList.filter(item => item.id !== foundedElement.id));
      setIgnoreList([...ignoreList, foundedElement]);
    } else if (dragSrcEl !== e.target && (e.target.dataset.dndid || e.target.parentNode.dataset.dndid)) {
      let prepareList = hierarchyList;
      const item = prepareList.splice(dragSrcEl.dataset.dndid, 1);
      prepareList.splice(e.target.dataset.dndid ?? e.target.parentNode.dataset.dndid, 0, item[0]);
      setHierarchyList([...prepareList]);
    }

    return false;
  }

  const handleDragEnd = e => {
    e.target.style.background = "#FFF";
    e.target.style.border = "1px solid #DADADA";
  }

  const formik = useFormik({
    initialValues: {
      triage_admin: initialValues.triage_admin ?? null,
    },
    onSubmit: ({triage_admin}) => {
      submit({
        triage_admin_id: triage_admin?.value ?? "",
        triage_hierarchy_ids: hierarchyList.map(item => item.id).join(),
        inactivate_triage_hierarchy_ids: ignoreList.map(item => item.id).join()
      })
    },
  });

  const {handleSubmit, values, setFieldValue} = formik;

  const usersAutocomplete = query => services.userServices
    .usersAutocomplete({query})
    .then(res => res.data.map(user => ({
      value: user.id,
      label: user.name,
    })));

  const hideHierarchyItem = item => {
    setHierarchyList(hierarchyList.filter(i => i.id !== item.id));
    setIgnoreList([...ignoreList, item]);
  }

  const showHierarchyItem = item => {
    setIgnoreList(ignoreList.filter(i => i.id !== item.id));
    setHierarchyList([...hierarchyList, item]);
  }

  const renderHistoryList = () => {
    if (!hierarchyList.length) return "-";
    return hierarchyList.map((item, index) => (
      <HierarchyItem
        item={item}
        key={item.id}
        index={index}
        handleDragStart={handleDragStart}
        handleDragOver={handleDragOver}
        handleDrop={handleDrop}
        handleDragEnd={handleDragEnd}
        hide={hideHierarchyItem}
        isLast={hierarchyList.length - 1 === index}
      />
    ))
  }

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <RSAsyncSelect
        onChange={item => setFieldValue("triage_admin", item)}
        load={usersAutocomplete}
        defaultValue={values.triage_admin}
        label="Triage admin"
        placeholder="Start typing user name"
        className={s.field}
      />
      <div className={classNames(s.dndContainer, s.field)}>
        <div className={s.labelOptional}>
          <span data-translate-key={getTranslateKey(sentences.forms.hierarchy)}>
            {t(getTranslateKey(sentences.forms.hierarchy))}
          </span>
          <span className={s.gray} data-translate-key={getTranslateKey(sentences.forms.hierarchyDescription)}>
            {t(getTranslateKey(sentences.forms.hierarchyDescription))}
          </span>
        </div>
        <div
          className={s.dndList}
          data-dndid="dnd-list"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {renderHistoryList()}
        </div>
      </div>
      <div className={s.ignoreContainer}>
        <div className={s.labelOptional}>
          <span data-translate-key={getTranslateKey(sentences.forms.hiddenItems)}>
            {t(getTranslateKey(sentences.forms.hiddenItems))}
          </span>
          <span className={s.gray} data-translate-key={getTranslateKey(sentences.forms.hiddenItemsDescription)}>
            {t(getTranslateKey(sentences.forms.hiddenItemsDescription))}
          </span>
        </div>
        <div
          className={s.ignoreList}
          data-dndid="ignore-dnd-list"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {
            ignoreList.map((item, index) => (
              <HierarchyItem
                item={item}
                key={item.id}
                index={index}
                handleDragStart={handleDragStart}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                handleDragEnd={handleDragEnd}
                show={showHierarchyItem}
                isHide={true}
              />
            ))
          }
        </div>
      </div>
      <div className='popupBtnsWrap'>
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          Save
        </Button>
      </div>
    </form>
  );
};

export default EditTriageDetailsForm;