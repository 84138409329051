import { userRoles } from "./userRoles";

export const formatUserRoles = (roles) => {
  let formattedRoles = [];
  roles.split(",").map((role) => {
    if (Object.values(userRoles).filter((e) => e === role).length) {
      const formattedRole = role
        .split("_")
        .map((part) => part?.[0].toUpperCase() + part.slice(1))
        .join(" ");
      formattedRoles.push({
        value: role,
        label: role === userRoles.cpo ? role.toUpperCase() : formattedRole,
      });
    }
  });
  return formattedRoles;
};
