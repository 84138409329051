import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import LandingLayout from "../../Landinglayout/Landinglayout";
import ProductCard from "./ProductCard/ProductCard";
import Step from "./Step/Step";
import productsCardData from "./productsCardData";
import s from "./ProductPage.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";

const ProductPage = () => {
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo({top: 0});
  });
  return (
    <LandingLayout>
      <div className={s.productPage}>
        <div className={s.landingContainer}>
          <h1 className={s.title} data-translate-key={"how-procurement-performance-management-works"}>
            {t(getTranslateKey("how-procurement-performance-management-works"))}
          </h1>

          <div className={s.content}>
            <div className={s.steps}>
              <Step number="1"/>
              <Step number="2"/>
              <Fade top>
                <Step number="3"/>
                <Step number="4"/>
                <Step number="5"/>
                <Step number="6" classLine={s.noLine}/>
              </Fade>

            </div>

            <div className={s.cardBlock}>
              {productsCardData.map((card, index) => (
                <ProductCard
                  key={index}
                  img={card.img}
                  title={card.title}
                  text={card.text}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default ProductPage;
