import React, {useState} from "react";
import AutoCompleteInput from "../../../../../elements/AutoCompleteInput/AutoCompleteInput";
import {parserForSelect} from "../../../../../containers/AdminSettingsStepFieldDetailsContainer/helpers/parserForSelect";
import PropTypes from "prop-types";
import services from "../../../../../../services";

const ContractsSelect = ({field, values, setFieldValue, errors}) => {
  const correctFieldKey = field.key ? field.key : field.value;

  const [searchedContracts, setSearchedContracts] = useState([]);

  const searchContracts = term => {
    services.contractsServices
      .getContractsAutocomplete({query: term})
      .then((res) => setSearchedContracts(res.data));
  };

  return (
    <AutoCompleteInput
      label={field.label}
      value={values[correctFieldKey]?.label || ""}
      withoutTranslate
      placeholder=""
      onChange={e => {
        setFieldValue(correctFieldKey, {value: "", label: e.target.value, type: "Contract", conditions: field.conditions});
        searchContracts(e.target.value);
      }}
      onSelect={value => {
        setFieldValue(correctFieldKey, {...parserForSelect(value), type: "Contract", conditions: field.conditions});
      }}
      data={searchedContracts}
      error={errors?.[correctFieldKey]?.value}
    />
  );
};

export default ContractsSelect;

ContractsSelect.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  errors: PropTypes.any,
};