import React from 'react';
import SystemReportsTeamsFilter from "../../../../filters/SystemReportsTeamsFilter/SystemReportsTeamsFilter";
import styles from "./SystemReportsTeam.module.scss";
import UserOrgChart from "../../../UserDetailsContainer/components/UserOrgChart/UserOrgChart";

const SystemReportsTeam = () => {
  return (
    <div>
      <SystemReportsTeamsFilter className={styles.filterbar}/>
      <UserOrgChart withFilter withTeamEdit isTeam isReport/>
    </div>
  );
};

export default SystemReportsTeam;