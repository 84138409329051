import axios from './axios'

export default {
  getCompanyGoals: async (id, params) => {
    try {
      return await axios.get(`/organizations/${id}/goals`, {params})
    } catch (error) {
      throw error
    }
  },
  getCompanyGoal: async (id, params) => {
    try {
      return await axios.get(`/organizations/${id}/goal`, {params})
    } catch (error) {
      throw error
    }
  },
  getDepartmentGoal: async (id, params) => {
    try {
      return await axios.get(`/departments/${id}/goal`, {params})
    } catch (error) {
      throw error
    }
  },
  getUserGoal: async (id, params) => {
    try {
      return await axios.get(`/users/${id}/goal`, {params})
    } catch (error) {
      throw error
    }
  },
  changeCompanyGoal: async values => {
    try {
      return await axios.put(`/goals`, values)
    } catch (error) {
      throw error
    }
  },
  getCompanyGoalsFilters: async () => {
    try {
      return await axios.get(`/company_goals_filters`)
    } catch (error) {
      throw error
    }
  },
  getIndividualGoalsFilters: async () => {
    try {
      return await axios.get(`/individual_goals_filters`)
    } catch (error) {
      throw error
    }
  },
}
