import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {debounce} from "lodash";
import qs from "query-string";
import FilterBar from "../../../elements/FilterBar/FilterBar";
import {getAllIds} from "../../../elements/FilterBar/helpers/helpers";
import styles from "./RequestsFilter.module.scss";
import FilterBarDropdown from "../../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {userRoles} from "../../../../helpers/usersData";
import {userRoles as orgUserRoles} from "../../../../helpers/userPermissions/userRoles";
import services from "../../../../services";
import {userTypes} from "../../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import {checkRoles} from "../../../../helpers/checkRoles";

const initialFiltersValues = {
  query: "",
};

const RequestsFilter = (
  {
    savedFilter,
    saveDefaultFilter,
    deleteDefaultFilter,
    showDefaultFilter
  }
) => {

  const history = useHistory();
  const [procurementUsers, setProcurementUser] = useState([]);
  const [businessUsers, setBusinessUsers] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [triageUsers, setTriageUsers] = useState([]);
  const adminUser = orgUserRoles.admin;
  const {procurement, business, adminTypes} = userTypes;
  const user = useSelector(state => state.user);
  const query = qs.parse(history.location.search);

  const procurementUserRoles = userRoles
    .filter(role => (role.userType.value === procurement || role.userType.value === adminTypes))
    .map(role => role.value)
    .join(",");

  const businessUserRoles = userRoles
    .filter(role => role.userType.value === business)
    .map(role => role.value)
    .join(",");

  useEffect(() => {
    services.userServices
      .stakeholdersAutocomplete({
        roles: procurementUserRoles,
      })
      .then(res => setProcurementUser(res.data));

    services.userServices
      .stakeholdersAutocomplete({
        roles: businessUserRoles,
      })
      .then(res => setBusinessUsers(res.data));

    services.requestTypeServices
      .getRequestTypes()
      .then(res => setRequestTypes(res.data));

    services.projectRequestsServices.getRequestFilters().then(res => {
      if (res.data) {
        setTriageUsers(res.data.triage_users);
      }
    });
  }, []);

  const handleSearch = debounce(term => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma"
        }
      )
    });
  }, 300);

  const handleClearAll = () => {
    history.push(initialFiltersValues);
  };

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const getResponsibleUserFilter = () => {
    const responsibleUserFilter = (
      <FilterBarDropdown
        name='Responsible procurement'
        options={procurementUsers}
        handleChange={values => handleChangeFilter("responsible_user_ids", values)}
        searchPlaceholder='Search responsible procurement'
        defaultValues={query?.responsible_user_ids?.split(",")}
      />
    );

    if (user.user_type === business) return responsibleUserFilter;

    if (checkRoles(user.roles, adminUser) || user.triage) return responsibleUserFilter;

    if (user.user_type === procurement && user.triage_manager) return responsibleUserFilter;

    return null;
  };

  return (
    <FilterBar
      className={styles.filterBar}
      filterName='Filter requests'
      handleClearAll={handleClearAll}
      onSearch={handleSearch}
      searchDefaultValue={query?.query || ""}
      withSearch
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      clearAllWithSearch={true}
    >
      {getResponsibleUserFilter()}
      {
        user.user_type !== business && (
          <FilterBarDropdown
            name='Requester'
            options={businessUsers}
            handleChange={values => handleChangeFilter("requester_ids", values)}
            searchPlaceholder='Search requester'
            defaultValues={query?.requester_ids?.split(",")}
          />
        )
      }
      {
        (checkRoles(user.roles, adminUser) || user.triage) && (
          <FilterBarDropdown
            name='Triage person'
            options={triageUsers}
            handleChange={values => handleChangeFilter("triage_user_ids", values)}
            searchPlaceholder='Search triage'
            defaultValues={query?.triage_user_ids?.split(",")}
          />
        )
      }
      <FilterBarDropdown
        name='Status'
        options={[
          {
            id: "in_progress",
            name: "In progress",
          },
          {
            id: "completed",
            name: "Completed",
          },
          {
            id: "pending",
            name: "Pending",
          },
          {
            id: "rejected",
            name: "Rejected",
          }
        ]}
        defaultValues={query?.statuses}
        handleChange={values => handleChangeFilter("statuses", values)}
      />
      <FilterBarDropdown
        name='Type'
        options={requestTypes}
        defaultValues={query?.request_type_ids}
        handleChange={values => handleChangeFilter("request_type_ids", values)}
      />
    </FilterBar>
  );
};

export default RequestsFilter;