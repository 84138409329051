import React from "react";
import classNames from "classnames";
import {TableCell, TableSortLabel} from "@material-ui/core";
import s from "../MainTable.module.scss";
import {useTranslate} from "../../../../hooks/useTranslate";

export const renderHeadCells = (dataTypes, orderBy, setOrderBy) => {
  return dataTypes.map((headCell, index) => {
    const visible = headCell?.visible === undefined ? true : headCell.visible;
    const [translateKey, translateValue] = useTranslate(headCell.title);
    const [translateSubKey, translateSubValue] = useTranslate(headCell.subTypes?.title);
    const getSubTitle = () => {
      if (headCell.subTypes?.title) return (
        <div className={s.subTypes}>
          <div data-translate-key={translateKey}>{translateValue}</div>
          <div className={s.titleFiltered} data-translate-key={translateSubKey}>{translateSubValue}</div>
        </div>
      );
      return <div data-translate-key={translateKey}>{translateValue}</div>;
    };

    if (headCell.sortable && visible) {
      return (
        <TableCell
          className={classNames(s.title, {
            [s.col1]: dataTypes.length === 3 && index === 0,
            [s.col2]: dataTypes.length === 3 && index === 1,
            [s.tagsComponent]: headCell.type === "tags",
            [s[headCell.position]]: headCell.position
          })}
          key={index}
        >
          {headCell?.subTypes?.sortable
            ? <div>
              <div className={s.headCellTitel}>
                <TableSortLabel
                  active={orderBy.title === headCell.title}
                  direction={
                    orderBy.title === headCell.title ? orderBy.direction : "asc"
                  }
                  onClick={() => {
                    const newOrderBy = {
                      title: headCell.title,
                      direction: ""
                    };
                    if (orderBy.direction === "desc") {
                      newOrderBy.direction = "asc";
                    } else {
                      newOrderBy.direction = "desc";
                    }
                    setOrderBy(newOrderBy);
                    headCell.handleSort(newOrderBy);
                  }}
                >
                  <div data-translate-key={translateKey}>{translateValue}</div>
                </TableSortLabel>
              </div>
              <div>
                <TableSortLabel
                  active={orderBy.title === headCell.subTypes.title}
                  direction={
                    orderBy.title === headCell.subTypes.title ? orderBy.direction : "asc"
                  }
                  onClick={() => {
                    const newOrderBy = {
                      title: headCell.subTypes.title,
                      direction: ""
                    };
                    if (orderBy.direction === "desc") {
                      newOrderBy.direction = "asc";
                    } else {
                      newOrderBy.direction = "desc";
                    }
                    setOrderBy(newOrderBy);
                    headCell.subTypes.handleSort(newOrderBy);
                  }}
                >
                  <div className={s.titleFiltered} data-translate-key={translateSubKey}>{translateSubValue}</div>
                </TableSortLabel>
              </div>
            </div>

            : <TableSortLabel
              active={orderBy.title === headCell.title}
              direction={
                orderBy.title === headCell.title ? orderBy.direction : "asc"
              }
              onClick={() => {
                const newOrderBy = {
                  title: headCell.title,
                  direction: ""
                };
                if (orderBy.direction === "desc") {
                  newOrderBy.direction = "asc";
                } else {
                  newOrderBy.direction = "desc";
                }
                setOrderBy(newOrderBy);
                headCell.handleSort(newOrderBy);
              }}
            >
              {getSubTitle()}
            </TableSortLabel>}

        </TableCell>
      );
    } else if (!headCell.sortable && visible) {
      return (
        <TableCell
          className={classNames(s.title, {
            [s.col1]: dataTypes.length === 3 && index === 0,
            [s.col2]: dataTypes.length === 3 && index === 1,
            [s.tagsComponent]: headCell.type === "tags",
            [s[headCell.position]]: headCell.position
          })}
          key={index}
        >
          <span data-translate-key={translateKey}>
            {translateValue}
          </span>
        </TableCell>
      );
    }
  });
};
