import React, {useState, useEffect} from "react";
import querystring from "query-string";
import {useHistory} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import styles from "./NotificationsContainer.module.scss";
import NotificationsTable from "components/tables/NotificationsTable/NotificationsTable";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import services from "services";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const PER_PAGE = 30;

const NotificationsContainer = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const query = querystring.parse(history.location.search) || {};

  useEffect(() => {
    services.notificationsServices.getNotifications(query).then((res) => {
      setData(res.data.notifications);
      setTotal(res.data.notifications_count);
      setLoading(false);
    });
  }, [history.location]);

  const onDelete = async (items) => {
    const notification_ids = items.map((itm) => itm.id).join(",");
    await services.notificationsServices
      .deleteNotifications({notification_ids})
      .then(() => {
        const newNotifications = data.filter(
          (itm) => !notification_ids.includes(itm.id)
        );
        setData(newNotifications);
      });
  };
  const onMarkRead = async (items) => {
    const notification_ids = items.map((itm) => itm.id).join(",");
    await services.notificationsServices
      .updateNotifications({notification_ids})
      .then(() => {
        const newNotifications = data.map((itm) => {
          if (notification_ids.includes(itm.id)) {
            itm.is_readed = true;
          }
          return itm;
        });
        setData(newNotifications);
      });
  };

  const onPageChange = (page) => {
    history.push({search: querystring.stringify({...query, page})});
  };
  return (
    <div className={styles.container}>
      <h3 className={styles.title} data-translate-key={getTranslateKey("Notifications")}>
        {t(getTranslateKey("Notifications"))}
      </h3>
      {loading ? (
        <div className="loaderWrap">
          <CircularProgress/>
        </div>
      ) : (
        <>
          {Boolean(data.length) ? (
            <NotificationsTable
              page={query.page}
              data={data}
              perPage={PER_PAGE}
              totalCount={total}
              onPageChange={onPageChange}
              onDelete={onDelete}
              onMarkRead={onMarkRead}
            />
          ) : (
            <EmptyBlock
              title="You don’t have notifications yet."
              withBtn={false}
              message
            />
          )}
        </>
      )}
    </div>
  );
};

export default NotificationsContainer;
