import React from "react";
import Popup from "components/popups/Popup/Popup";
import EditSupplierForm from "components/forms/EditSupplierForm/EditSupplierForm";

const EditSupplierPopup = ({ onClose, onSubmit, data }) => {
  return (
    <Popup
      onClose={onClose}
      title="Edit supplier"
      text="These fields are optional"
    >
      <EditSupplierForm onClose={onClose} onSubmit={onSubmit} data={data} />
    </Popup>
  );
};

export default EditSupplierPopup;
