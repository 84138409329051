import React from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import PropTypes from "prop-types";

const AdminSettingsNonFinancialSavingsForm = ({
  onClose,
  onSubmit,
  defaultData,
}) => {
  const formik = useFormik({
    initialValues: {
      savings_type: defaultData ? defaultData.name : "",
    },

    validationSchema: object({
      savings_type: string().required("Type is required"),
    }),
    onSubmit: (values) => {
      onSubmit({
        savings_type: values.savings_type,
      });
      onClose();
    },
  });
  const { handleSubmit, errors, setFieldValue, values, touched } = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        label="Savings type"
        value={values.savings_type}
        placeholder="Enter type name"
        onChange={(e) => {
          setFieldValue("savings_type", e.target.value);
        }}
        error={touched.savings_type && errors.savings_type}
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {defaultData ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default AdminSettingsNonFinancialSavingsForm;

AdminSettingsNonFinancialSavingsForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultData: PropTypes.any,
};
