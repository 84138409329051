import React from 'react';
import Textarea from "../../../elements/Textarea/Textarea";
import s from "../FinancialBenefitsForm.module.scss";
import Attachments from "../../AddMilestoneTaskForm/components/Attachments/Attachments";
import classNames from "classnames";
import services from "../../../../services";

const AdditionalForm = ({values, setFieldValue, errors}) => {

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files])
  }
  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue("attachments", values.attachments.filter((_, idx) => idx !== index))
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue("attachments", values.attachments.filter(e => e.id !== file.id))
      })
    }
  }

  return (
    <div className={classNames(s.row, s.additional)}>
      <div>
        <Textarea
          value={values.note}
          label="Note"
          onChange={e => setFieldValue("note", e.target.value)}
          placeholder="Enter note"
          error={errors.note && errors.note}
          count={{current: values.note?.length, max: 100}}
          customHeight={56}
        />
      </div>
      <div>
        <Attachments
          onChange={handleChangeAttachment}
          onDelete={handleDeleteAttachment}
          attachments={values.attachments}
          filesMaxLength={3}
          customHeight={56}
        />
      </div>
    </div>
  );
};

export default AdditionalForm;