import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import CustomLink from "components/elements/CustomLink/CustomLink";
import TopNav from "./TopNav/TopNav";
import s from "./Header.module.scss";
import { useSelector } from "react-redux";

const Header = () => {
  const [menu, setMenu] = useState(false);
  const user = useSelector((state) => state.user);
  const handleMenu = () => {
    setMenu(!menu);
  };

  const closeAction = () => {
    setMenu(false);
  };
  if (menu) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
  return (
    <header className={classNames(s.header)}>
      <div className={s.landingContainer}>
        <Link to="/" onClick={closeAction}>
          <Logo />
        </Link>

        <div className={s.topNavBigView}>
          <TopNav />
        </div>

        <div className={s.smallView}>
          <CustomLink
            path={!user.roles ? "/login" : "/profile"}
            type="nav-link"
            className={classNames(s.topNavLink, s.logInLink)}
          >
            {!user.roles ? "Log in" : "My profile"}
          </CustomLink>

          <CustomLink
            className={s.buttonLink}
            type="nav-link"
            path="/contact-us"
            onClick={closeAction}
          >
            Get a demo
          </CustomLink>
        </div>

        {menu ? (
          <TopNav
            onClick={() => setMenu(false)}
            closeAction={closeAction}
            className={classNames(s.vertical, { [s.opened]: menu })}
          />
        ) : null}

        <button className={s.hamburger} onClick={handleMenu}>
          {menu ? (
            <Close className={s.hamburgerButton} />
          ) : (
            <Menu className={s.hamburgerButton} />
          )}
        </button>
      </div>
    </header>
  );
};

export default Header;
