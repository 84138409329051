import React, { useState, useEffect } from 'react'
import s from './LoginContainer.module.scss'
import EmailForm from 'components/forms/LoginForm/EmailForm'
import PasswordForm from 'components/forms/LoginForm/PasswordForm'
import Preloader from 'components/elements/Preloader/Preloader'
import services from 'services'
import { login } from 'actions/user'
import { useDispatch } from 'react-redux'
import { save } from 'helpers/localStorage'
import { useHistory, useLocation } from 'react-router-dom'

const LoginContainer = () => {
	const [email, setEmail] = useState(null)
	const [error, setError] = useState(null)
	const [preloader, setPreloader] = useState(false)
	const location = useLocation()

	const history = useHistory()
	const dispatch = useDispatch()
	const handleLoginData = res => {
		const { access_token, csrf, refresh_token, roles, ...rest } = res.data.user
		dispatch(
			login({
				roles: roles,
				...rest
			})
		)
		save('currentUser', {
			roles: roles,
			...rest
		})
		history.push('/dashboard')
	}

	const handleLogin = values => {
		services.userServices
			.login(values)
			.then(res => {
				handleLoginData(res)
			})
			.catch(
				error =>
					error.response.status === 401 && setError('Wrong email or password')
			)
	}
	const handleSSO = subdomain => {
		setPreloader(true)
		services.userServices
			.loginSSO(subdomain)
			.then(res => {
				if (res) window.location = res
			})
			.catch(error => {
				setPreloader(false)
				error.response?.status === 401 && setError('No SSO')
			})
	}

	useEffect(() => {
		const subdomain = window.location.hostname.split('.')[0]
		let params = new URLSearchParams(window.location.search)

		if (location.pathname === '/login' && params.get('sso')) {
			setPreloader(true)
			services.userServices
				.credsSSO(params.get('sso'))
				.then(res => {
					handleLoginData(res)
				})
				.catch(error => {
					setPreloader(false)
					error.response.status === 401 && setError('Error logging SSO')
				})
		} else if (subdomain) {
			handleSSO(subdomain)
		}
	}, [])

	if (preloader) {
		return (
			<div>
				<Preloader />
			</div>
		)
	} else
		return (
			<div className={s.loginContainer}>
				{!email ? (
					<EmailForm setEmail={setEmail} />
				) : (
					<PasswordForm
						email={email.toLowerCase()}
						handleLogin={handleLogin}
						error={error}
					/>
				)}
			</div>
		)
}

export default LoginContainer
