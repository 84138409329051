import axios from "./axios"

export default {
  getSystemReportsProjects: async params => {
    try {
      return await axios.get("/project_reports", {
        params
      })
    } catch (error) {
      throw error
    }
  },

  getSystemReportsTags: async params => {
    try {
      return await axios.get("/tag_reports", {
        params
      })
    } catch (error) {
      throw error
    }
  },

  getSystemReportsUsers: async params => {
    try {
      return await axios.get("/user_reports", {
        params
      })
    } catch (error) {
      throw error
    }
  },

  getUserReportGeneral: async id => {
    try {
      return await axios.get(`/user_reports/${id}/general`)
    } catch (error) {
      throw error
    }
  },

  getUserReportProjects: async id => {
    try {
      return await axios.get(`/user_reports/${id}/projects`)
    } catch (error) {
      throw error
    }
  },

  getSystemReportsDepartments: async params => {
    try {
      return await axios.get("/department_reports", {
        params
      })
    } catch (error) {
      throw error
    }
  },

  getDepartmentReport: async id => {
    try {
      return await axios.get(`/department_reports/${id}`)
    } catch (error) {
      throw error
    }
  },

  getDepartmentReportGeneral: async id => {
    try {
      return await axios.get(`/department_reports/${id}/general`)
    } catch (error) {
      throw error
    }
  },

  getDepartmentReportProjects: async id => {
    try {
      return await axios.get(`/department_reports/${id}/projects`)
    } catch (error) {
      throw error
    }
  },

  getProjectReports: async id => {
    try {
      return await axios.get(`/project_reports/${id}`)
    } catch (error) {
      throw error
    }
  },

  getProjectReportsGeneral: async id => {
    try {
      return await axios.get(`/project_reports/${id}/general`)
    } catch (error) {
      throw error
    }
  },

  getProjectReportsSuppliers: async id => {
    try {
      return await axios.get(`/project_reports/${id}/suppliers`)
    } catch (error) {
      throw error
    }
  },

  getSuppliersReportsFilters: async params => {
    try {
      return await axios.get(`/supplier_reports_filters`, {
        params
      })
    } catch (error) {
      throw error
    }
  },

  getProjectsReportsFilters: async params => {
    try {
      return await axios.get(`/project_reports_filters`, {
        params
      })
    } catch (error) {
      throw error
    }
  },

  getUsersReportsFilters: async params => {
    try {
      return await axios.get(`/user_reports_filters`, {
        params
      })
    } catch (error) {
      throw error
    }
  },
  getReportTimeSpent: async (type, id) => {
    try {
      return await axios.get(`/${type}_reports/${id}/time_spent`);
    } catch (error) {
      throw error
    }
  },

  getTeamReport: async id => {
    try {
      return await axios.get(`/team_reports/${id}`)
    } catch (error) {
      throw error
    }
  },

  getTeamReportGeneral: async id => {
    try {
      return await axios.get(`/team_reports/${id}/general`)
    } catch (error) {
      throw error
    }
  },

  getTeamReportGoal: async id => {
    try {
      return await axios.get(`/team_reports/${id}/goal`)
    } catch (error) {
      throw error
    }
  },

  getTeamReportActivity: async (id, params) => {
    try {
      return await axios.get(`/team_reports/${id}/year_impact_activity`, {
        params
      })
    } catch (error) {
      throw error
    }
  },

  getTeamReportProjects: async id => {
    try {
      return await axios.get(`/team_reports/${id}/projects`)
    } catch (error) {
      throw error
    }
  }
}
