import React from "react";
import s from "./EmptyContent.module.scss";
import classNames from "classnames";
import {useTranslate} from "../../../hooks/useTranslate";

const EmptyContent = ({padding = 0, text, className, withTranslate}) => {
  const [translateKey, translateValue] = useTranslate(text);
  return (
    <div className={classNames(s.emptyContentWrapper, className)}>
      <p
        style={{
          paddingTop: padding,
          paddingBottom: padding,
        }}
        data-translate-key={translateKey}
      >
        {translateValue}
      </p>
    </div>
  );
};

export default EmptyContent;


