import React, { useEffect } from "react";
import LandingLayout from "../../Landinglayout/Landinglayout";
import PPMSection from "./components/PPMSection/PPMSection";
import CMSection from "./components/CMSection/CMSection";
import CRMSection from "./components/CRMSection/CRMSection";

const FeaturesPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <LandingLayout>
      <PPMSection />
      <CMSection />
      <CRMSection />
    </LandingLayout>
  );
};

export default FeaturesPage;
