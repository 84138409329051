import {formatDate} from "../../../../helpers/dateFormat";

const formatEntity = (entity, final_saving, type) => ({
  [type]: entity.entity.value,
  split_sum: Number(entity.value),
  split_sum_percent: Number(parseFloat((entity.value * 100 / final_saving) || 0).toFixed(2)),
});

export const requestFormat = data => {
  return {
    data: {
      project_spend: data.final_cost,
      project_savings: data.final_savings,
      project_spend_currency: data.currency.label,
      project_savings_currency: data.currency.label,
      start_date: formatDate(data.start_date),
      end_date: formatDate(data.end_date),
      savings_type_id: data.savings_type.value,
      note: data.note || "",
      savings: {
        departments: data.departments.map(department => formatEntity(department, data.final_savings, "department")),
        divisions: data.divisions.map(division => formatEntity(division, data.final_savings, "division")),
        procurement_users: data.users.map(user => formatEntity(user, data.final_savings, "procurement_user"))
      }
    },
    attachments: data.attachments,
  };
}
