import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import {useProjectsPath} from "../../../hooks/useProjectsPath";
import {useParams} from "react-router-dom";
import FinancialBenefitsDetailsContainer
  from "../../containers/FinancialBenefitsDetailsContainer/FinancialBenefitsDetailsContainer";

const FinancialBenefitsDetailsPage = () => {
  const projectsPath = useProjectsPath();
  const {projectId} = useParams();

  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: projectsPath,
          name: "Projects"
        },
        {
          name: "Project details",
          path: `/projects/${projectId}`
        },
        {
          name: "Project results",
          path: `/projects/${projectId}?tab=Project%20Results`
        },
        {
          name: "Benefit details",
        }
      ]}
    >
      <FinancialBenefitsDetailsContainer/>
    </DefaultLayout>
  );
};

export default FinancialBenefitsDetailsPage;