import React, {useState, useEffect} from "react";
import s from "../ProjectsContainer.module.scss";
import Popup from "components/popups/Popup/Popup";
import Button from "components/elements/Button/Button";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import {getProjectStatuses} from "helpers/helpData";
import {localization} from "helpers/localizationForCompanies";
import {useSelector} from "react-redux";

const ChangeStatusPopup = ({handleClose, handleSave, status}) => {
  const [selectedStatus, setSelectedStatus] = useState({
    value: "",
    label: "",
  });
  const organizationName = useSelector((state) => state.user.organization.name);

  const statuses = getProjectStatuses(organizationName);

  useEffect(() => {
    const currentStatus = statuses.find((s) => s.value === status);
    setSelectedStatus(currentStatus);
  }, [status, statuses]);
  return (
    <Popup
      onClose={handleClose}
      title={`Change ${localization.status}`}
      text={`Choose a ${localization.status} from existing ones`}
    >
      <div className={s.dropdownWrapper}>
        <CustomDropdown
          options={statuses}
          value={selectedStatus?.value}
          onChange={(option) => setSelectedStatus(option)}
          label="Status"
        />
      </div>

      <div className={s.editPopup}>
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          styled="primary"
          className="popupBtn"
          onClick={() => {
            handleSave(selectedStatus.value);
            handleClose();
          }}
        >
          Save
        </Button>
      </div>
    </Popup>
  );
};

export default ChangeStatusPopup;
