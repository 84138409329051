import React, {useState, Fragment} from "react";
import s from "./Details.module.scss";
import {dateFormatterWithoutTimezone, formatDate} from "helpers/dateFormat";
import Detail from "components/elements/Detail/Detail";
import {localization} from "helpers/localizationForCompanies";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import EditProjectPopup from "components/popups/EditProjectPopup/EditProjectPopup";
import {currencyData} from "helpers/helpData";
import {formatCurrency} from "helpers/currencyFormat";
import classNames from "classnames";
import {userRoles} from "../../../../../../../helpers/userPermissions/userRoles";
import PropTypes from "prop-types";
import Tags from "components/elements/Tags/Tags";
import ProjectStatuses from "./ProjectStatuses/ProjectStatuses";

const Details = (
  {
    data,
    onEdit,
    className,
    onDeleteTag,
    onAssignTag
  }
) => {
  const [visualPopup, setVisualPopup] = useState(false);
  const user = useSelector(state => state.user);
  const history = useHistory();

  const {
    stage,
    category_full,
    project_type,
    owner,
    start_date,
    end_date,
    actual_end_date,
    description,
    division,
    tags,
    departments,
    target_spend,
    target_spend_currency,
    budget_spend,
    budget_spend_currency
  } = data;

  const actualEndDate = dateFormatterWithoutTimezone(actual_end_date);

  return (
    <Fragment>
      <div className={s.detailsWrapper}>
        <Detail
          className={classNames(s.details, className)}
          data={[
            {
              name: `${localization.status}`,
              value: stage?.name || "-"
            },
            {name: `${localization.category}`, value: category_full},
            {
              name: "Type",
              value: project_type?.name || "-"
            },
            {
              name: "Budget",
              value: formatCurrency(
                currencyData.filter(e => e.label === budget_spend_currency)[0]
                  ?.value,
                budget_spend,
                true
              )
            },
            {
              name: `${localization.targetSpend}`,
              value: formatCurrency(
                currencyData.filter(e => e.label === target_spend_currency)[0]
                  ?.value,
                target_spend,
                true
              )
            },
            {
              name: `${localization.procurementOwner}`,
              value: owner.name
            },
            {name: "Start date", value: formatDate(start_date, true)},
            {name: `${localization.targetEndDate}`, value: formatDate(end_date, true)},
            {name: "Actual End date", value: actualEndDate === "Invalid date" ? "-" : actualEndDate},
            {
              name: "Client",
              value: departments.map(e => e?.name)?.join(", ") || "-"
            },
            {name: "Division", value: division?.name || "-"},
            {name: "Description", value: description || "No description"},
            {
              name: "Tags",
              value: (
                <div className="tagsWrap">
                  <Tags
                    allData={[{tags: tags}]}
                    item={{tags: tags}}
                    onAssign={onAssignTag}
                    onDelete={onDeleteTag}
                    width={370}
                  />
                </div>
              ),
              type: "tags"
            },
          ].map(i => ({...i, useParagraph: true}))}
          buttons={[
            (onEdit && (user.roles.includes(userRoles.admin) || user.id === owner.id)) && {
              name: "Edit",
              onClick: () => history.push(`/edit/projects/${data.id}`)
            }
          ]}
          showBtn={10}
          useColumns
        />
        <ProjectStatuses status={stage?.name} projectOwnerId={owner.id}/>
      </div>
      {visualPopup && (
        <EditProjectPopup
          closeAction={() => setVisualPopup(false)}
          data={data}
          handleEditProject={onEdit}
        />
      )}
    </Fragment>
  );
};

export default Details;

Details.propTypes = {
  data: PropTypes.any,
  onEdit: PropTypes.func,
  className: PropTypes.string,
};