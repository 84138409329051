import React from "react";
import s from "../PrivacyPage.module.scss";

const Point12 = () => {
  return (
    <div id="point12" className={s.pointBlock}>
      <h3 className={s.subtitle}>
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </h3>

      <p className={s.text}>
        If you have questions or comments about this notice, you may email us at{" "}
        <a
          href="https://focalpointprocurement.com"
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
        >
          https://focalpointprocurement.com
        </a>{" "}
        or by post to:
      </p>

      <p className={s.text}>
        Focal Point Procurement Solutions Inc. <br />
        1162 Fairway Gardens NE <br />
        Atlanta, GA 30319 <br />
        United States
      </p>
    </div>
  );
};

export default Point12;
