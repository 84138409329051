import React, { Fragment, useState, useEffect } from "react"
import s from "./PreviewProjectContainer.module.scss"
import { useParams, useHistory } from "react-router-dom"
import services from "services"
import { CircularProgress } from "@material-ui/core"
import Detail from "components/elements/Detail/Detail"
import { projectTypes, getProjectStatuses } from "helpers/helpData"
import { localization } from "helpers/localizationForCompanies"
import { useSelector } from "react-redux"
import { fromDate } from "helpers/dateFormat"
import { formatCurrency } from "helpers/currencyFormat"
import { userRoles } from "helpers/userPermissions/userRoles"
import AddProjectPopup from "components/containers/ProjectsContainer/Popups/AddProjectPopup"

const PreviewProjectContainer = () => {
  const params = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [project, setProject] = useState({})
  const [viewPopup, setViewPopup] = useState(false)

  useEffect(() => {
    services.projectServices.getProject(params.id).then(res => {
      setProject(res.data)
      setLoading(false)
    })
  }, [params.id])
  const organizationName = useSelector(state => state.user.organization.name)
  const user = useSelector(state => state.user)
  const statuses = getProjectStatuses(organizationName)
  return (
    <div className={s.wrap}>
      {loading && (
        <div className='loaderWrap'>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <Fragment>
          <h2 className={s.title}>{project.name}</h2>
          <Detail
            data={[
              {
                name: "Project name",
                value: project.name
              },
              {
                name: "Type of Spend",
                value: projectTypes.filter(
                  e => e.value === project.project_type
                )[0].label
              },
              {
                name: "Supplier",
                value: project.owner.title
              },
              {
                name: "Budget",
                value:
                  <span style={{ color: "rgba(0, 0, 0, 0.55)" }}>-</span> ||
                  formatCurrency("$USD", 100000)
              },
              { name: "Start date", value: fromDate(project.start_date) },
              {
                name: `${localization.targetEndDate}`,
                value: fromDate(project.end_date)
              },
              {
                name:
                  user.roles.includes(userRoles.business_user) ||
                  user.roles.includes(userRoles.category_business_owner)
                    ? `${localization.procurementOwner}`
                    : "Project owner",
                value: project.owner.name
              },
              user.roles.includes(userRoles.admin) && {
                name: "Requester",
                value: "-"
              },
              {
                name: `${localization.status}`,
                value: statuses.find(s => s.value === project.status).label
              }
            ]}
            buttons={[
              user.roles.includes(userRoles.admin) && {
                name: "Create project",
                onClick: () => setViewPopup(true)
              }
            ]}
          />
        </Fragment>
      )}
      {viewPopup && (
        <AddProjectPopup
          closeAction={() => setViewPopup(false)}
          handleCreateProject={values => {}}
          defaultData={{
            category_id: project.category,
            project_type: project.project_type,
            title: project.name,
            owner_id: {
              name: project.owner.name,
              id: project.owner.id
            },
            start_date: new Date(project.start_date),
            end_date: new Date(project.end_date)
          }}
        />
      )}
    </div>
  )
}
export default PreviewProjectContainer
