import React from "react";
import Popup from "components/popups/Popup/Popup";
import PropTypes from "prop-types";
import AdminSettingsFinancialSavingsGroupForm
  from "../../../../../../forms/AdminSettingsFinancialSavingsGroupForm/AdminSettingsFinancialSavingsGroupForm";

const AddFinancialSavingsGroupPopup = ({onClose, onSubmit}) => {
  return (
    <Popup
      onClose={onClose}
      title="New savings group"
      text="Please, complete the following fields to create a new savings group"
    >
      <AdminSettingsFinancialSavingsGroupForm
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Popup>
  );
};

export default AddFinancialSavingsGroupPopup;

AddFinancialSavingsGroupPopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
