import axios from "axios";
import { save, load, remove } from "../helpers/localStorage";
import {baseURL} from "../helpers/baseURL";

const CancelToken = axios.CancelToken;
export let source = CancelToken.source();

let isRefreshing = false;
let failedQueue = [];
const pendingRequests = new Map();

const accessToken = load("accessToken");

export const addAccessTokenToRequestsHeader = (agent, accessToken) => {
  if (accessToken) {
    agent.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  }
};

const agent = axios.create({
  baseURL
});

if (accessToken) {
  addAccessTokenToRequestsHeader(agent, accessToken);
}

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};
// Logic for cancelable request
agent.interceptors.request.use(config => {
  if (!config.cancelId) return config;
  const source = axios.CancelToken.source();
  const requestId = config.cancelId;
  const cancelToken = source.token;
  addRequest(requestId, source.cancel);
  return new Promise(resolve =>
    setTimeout(() => resolve({ ...config, cancelToken }), 4000)
  );
});

const addRequest = (requestId, cancelFn) => {
  pendingRequests.set(requestId, cancelFn);
};

const removeRequest = requestId => {
  if (pendingRequests.has(requestId)) {
    pendingRequests.delete(requestId);
  }
};
export const cancelRequest = requestId => {
  if (pendingRequests.has(requestId)) {
    const cancelFn = pendingRequests.get(requestId);
    cancelFn(requestId);
    removeRequest(requestId);
  }
};

agent.interceptors.response.use(
  response => {
    const requestId = response.config.cancelId;
    removeRequest(requestId);
    return response;
  },
  error => {
    if (axios.isCancel(error)) {
      removeRequest(error.message);
      return new Promise(() => {});
    }
    const originalRequest = error.config;

    source = CancelToken.source();

    if (
      error.response?.status === 401 &&
      originalRequest.url !== "sessions/refresh" &&
      originalRequest.url !== "sessions"
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        agent
          .post("sessions/refresh")
          .then(res => {
            const accessToken = res.data["access_token"];
            addAccessTokenToRequestsHeader(agent, accessToken);
            save("accessToken", accessToken);
            originalRequest.headers["Authorization"] = "Bearer " + accessToken;
            processQueue(null, accessToken);
            resolve(axios(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    } else if (
      error.response?.status === 401 &&
      originalRequest.url === "sessions/refresh" &&
      !(window.location.pathname === "/login")
    ) {
      remove("currentUser");
      remove("accessToken");
      window.location.replace("/login");
      throw error;
    } else if (
      error.response?.status === 404 &&
      !(window.location.pathname === "/login")
    ) {
      window.location.replace("/not-found");
      return;
    }
    return Promise.reject(error);
  }
);

export default agent;
