import React, { Fragment, useState, useEffect, useContext } from "react"
import services from "services"
import { useParams } from "react-router-dom"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import ProjectAttachmentsTable from "components/tables/ProjectAttachmentsTable/ProjectAttachmentsTable"
import Popup from "components/popups/Popup/Popup"
import AddProjectAttachmentForm from "components/forms/AddProjectAttachmentForm/AddProjectAttachmentForm"
import AlertContext from "contexts/AlertContext/AlertContext"
import TableLoading from "components/loadingStates/Table/Table"

const Attachments = () => {
  const params = useParams()
  const [attachments, setAttachments] = useState([])
  const [addAttachmentPopup, setAddAttachmentPopup] = useState(false)
  const [deleteAttachmentPopup, setDeleteAttachmentPopup] = useState(false)
  const [currentAttachmentId, setCurrentAttachmentId] = useState(null)
  const { setAlert } = useContext(AlertContext)
  const [loading, setLoading] = useState(true)
  const [permissions, setPermissions] = useState({
    update: false,
    destroy: false
  })

  useEffect(() => {
    services.projectAttachmentsServices.get(params.id).then(res => {
      setAttachments(res.data.attachments)
      setPermissions({
        update: res.data.permissions?.update || false,
        destroy: res.data.permissions?.destroy || false
      })
      setLoading(false)
    })
  }, [params.id])

  const handleAddAttachment = values => {
    services.projectAttachmentsServices
      .add(params.id, values)
      .then(res => {
        setAlert({
          message: `"${res.data.name}" attachment successfully added.`
        })
        setAddAttachmentPopup(false)
        setAttachments([...attachments, res.data])
      })
      .catch(() =>
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      )
  }

  const handleDeleteAttachment = () => {
    services.projectAttachmentsServices
      .delete(params.id, currentAttachmentId)
      .then(() => {
        setAlert({
          message: "Attachment successfully deleted.",
          onCancel: () => {
            services.projectAttachmentsServices
              .undo(params.id, currentAttachmentId)
              .then(res => {
                setAttachments(state => [...state, res.data])
              })
          }
        })
        setAttachments(state =>
          state.filter(el => el.id !== currentAttachmentId)
        )
      })
      .catch(() => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      })
  }

  const handleDownloadFile = ({ path, name }) => {
    const link = document.createElement("a")
    link.href = path
    link.setAttribute("download", name)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const handlePreviewFile = ({ path, file_type }) => {}
  return (
    <Fragment>
      {loading && <TableLoading length={4} />}
      {!loading && (
        <Fragment>
          {attachments.length > 0 ? (
            <ProjectAttachmentsTable
              data={attachments}
              onClick={() => setAddAttachmentPopup(true)}
              onDelete={id => {
                setCurrentAttachmentId(id)
                setDeleteAttachmentPopup(true)
              }}
              onPreview={handlePreviewFile}
              onDownload={handleDownloadFile}
              havePermissions={permissions}
            />
          ) : (
            <EmptyBlock
              subtitle={
                permissions.update
                  ? "You don’t have attachments yet. You can add a new one"
                  : "You don’t have attachments yet."
              }
              buttonTitle='Add attachment'
              onClick={() => setAddAttachmentPopup(true)}
              withBtn={permissions.update}
            />
          )}
        </Fragment>
      )}
      {addAttachmentPopup && (
        <Popup
          title='Add attachment'
          onClose={() => setAddAttachmentPopup(false)}
        >
          <AddProjectAttachmentForm
            onClose={() => setAddAttachmentPopup(false)}
            onSubmit={handleAddAttachment}
          />
        </Popup>
      )}
      {deleteAttachmentPopup && (
        <Popup
          title='Delete attachment'
          text='Are you sure you want to delete this attachment?'
          onClose={() => setDeleteAttachmentPopup(false)}
          onClick={() => {
            handleDeleteAttachment()
            setDeleteAttachmentPopup(false)
          }}
          dialog
          isDelete
        />
      )}
    </Fragment>
  )
}
export default Attachments
