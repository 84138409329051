export const LOAD_DATA = "LOAD_DATA";
export const ADD_ITEM = "ADD_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";

export const initialState = {
  typeOfSpendList: [],
  supplierStatusList: [],
  budgetList: [],
  projectTypeList: [],
  categoryList: [],
};

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case LOAD_DATA: {
      return {
        ...state,
        [payload.typeList]: payload.data,
      };
    }
    case ADD_ITEM: {
      return {
        ...state,
        [payload.typeList]: [payload.data, ...state[payload.typeList]],
      };
    }
    case UPDATE_ITEM: {
      return {
        ...state,
        [payload.typeList]: state[payload.typeList].map(i => {
          if (i.id === payload.data.id) return payload.data;
          return i;
        }),
      };
    }
    case DELETE_ITEM: {
      return {
        ...state,
        [payload.typeList]: state[payload.typeList].filter(i => i.id !== payload.id),
      };
    }
    default:
      throw Error("Unknown action type");
  }
}