import React, {useState} from "react";
import styles from "./Notes.module.scss";
import PropTypes from "prop-types";
import {ReactComponent as Plus} from "../../../../../../assets/icons/plus-default.svg";
import Popup from "../../../../../popups/Popup/Popup";
import NoteForm from "../../../../../forms/NoteForm/NoteForm";
import SingleNote from "../SingleNote/SingleNote";

const Notes = ({data, onAdd, onEdit, onDelete}) => {
  const [popUp, setPopUp] = useState(null);
  const [currentNote, setCurrentNote] = useState(null);

  const addNoteAction = values => {
    onAdd(values);
    setPopUp(null);
  };

  const editNoteAction = (values) => {
    onEdit(currentNote.id, values);
    setPopUp(null);
    setCurrentNote(null);
  };

  const deleteNoteAction = () => {
    onDelete(currentNote.id);
    setPopUp(null);
    setCurrentNote(null);
  };

  const onOpenPopUp = (note, type) => {
    setCurrentNote(note);
    setPopUp(type);
  };

  const close = () => {
    setPopUp(null);
    setCurrentNote(null);
  };

  return (
    <div className={styles.notesWrap}>
      <header>
        <p>Notes</p>
        <button onClick={() => setPopUp("new")}>
          <Plus/>
        </button>
      </header>
      <div className={styles.noteListWrap}>
        <div className={styles.listTitle}>
          <p className={styles.title}>Note</p>
        </div>
        {data.length === 0 && <p className={styles.emptyList}>You don’t have notes yet.</p>}
        {
          data.map(note => (
            <SingleNote
              key={note.id}
              data={note}
              onOpenPoUp={(note, type) => onOpenPopUp(note, type)}
            />
          ))
        }
        {
          popUp === "new" && (
            <Popup
              title="New note"
              onClose={() => setPopUp(null)}
            >
              <NoteForm close={() => setPopUp(null)} submit={addNoteAction}/>
            </Popup>
          )
        }
        {
          (popUp === "edit" && currentNote) && (
            <Popup
              title="Edit note"
              onClose={close}
            >
              <NoteForm
                close={close}
                submit={editNoteAction}
                defaultValues={currentNote}
              />
            </Popup>
          )
        }
        {
          (popUp === "delete" && currentNote) && (
            <Popup
              title="Delete a note"
              text="Are you sure you want to delete this note?"
              onClose={close}
              onClick={deleteNoteAction}
              dialog
              isDelete
            />
          )
        }
      </div>
    </div>
  );
};

export default Notes;

Notes.propTypes = {
  data: PropTypes.any,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};