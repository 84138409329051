export default {
  sortDate: (array, type) => {
    return [...array].sort((a, b) => {
      return type.direction === "asc"
        ? new Date(b[type.field]) - new Date(a[type.field])
        : new Date(a[type.field]) - new Date(b[type.field]);
    });
  },
  sortString: (array, type) => {
    return [...array].sort((a, b) => {
      return type.direction === "asc"
        ? a[type.field].toLowerCase() > b[type.field].toLowerCase()
          ? 1
          : -1
        : a[type.field].toLowerCase() < b[type.field].toLowerCase()
        ? 1
        : -1;
    });
  },
  sortNumber: (array, type) => {
    return [...array].sort((a, b) => {
      return type.direction === "asc"
        ? a[type.field] - b[type.field]
        : b[type.field] - a[type.field];
    });
  },
};
