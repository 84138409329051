import React from "react";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import Tabs from "../../elements/Tabs/Tabs";
import RequestTypesTab from "./RequestTypesTab/RequestTypesTab";
import CustomDropdownsTab from "./CustomDropdownsTab/CustomDropdownsTab";
import querystring from "query-string";
import {useHistory} from "react-router-dom";
import RequestFilterBar from "./RequestFilterBar";
import TriageTab from "./TriageTab/TriageTab";
import {useTranslation} from "react-i18next";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";

const AdminSettingsRequestsContainer = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const {tab} = querystring.parse(history.location.search);
  const user = useSelector(state => state.user);
  const {admin} = userRoles;

  const tabChangeHandler = tab => {
    history.push({search: querystring.stringify({tab})});
  };

  const renderTabs = () => {
    if (user.roles.split(",").includes(admin)) {
      return (
        <Tabs defaultActiveTab={tab} handleClick={tabChangeHandler}>
          <div label='Request types'>
            <RequestTypesTab/>
          </div>
          <RequestFilterBar/>
          <div label='Custom dropdowns'>
            <CustomDropdownsTab/>
          </div>
          <div label='Triage'>
            <TriageTab/>
          </div>
        </Tabs>
      )
    }

    return (
      <Tabs defaultActiveTab={tab} handleClick={tabChangeHandler}>
        <div label='Triage'>
          <TriageTab/>
        </div>
      </Tabs>
    )
  }

  return (
    <div className={s.wrap}>
      <h2 className={s.title} style={{marginBottom: 12}} data-translate-key="requests">{t("requests")}</h2>
      {renderTabs()}
    </div>
  );
};
export default AdminSettingsRequestsContainer;
