import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import {useHistory} from "react-router-dom";
import querystring from "query-string";
import {supplierStatuses} from "helpers/helpData";
import {currencyData} from "helpers/helpData";
import {formatCurrency} from "helpers/currencyFormat";
import s from "./CategoriesSuppliersTable.module.scss";
import Tags from "components/elements/Tags/Tags";
import LogoSuppliers from "../../elements/LogoSuppliers/LogoSuppliers";
import {useSelector} from "react-redux";
import {userTypes} from "../../../helpers/userPermissions/userRoles";

const CategoriesSuppliersTable = (
  {
    data,
    totalCount,
    onAdd,
    onDelete,
    perPage,
    onChangeStatus,
    onAssignTag,
    onDeleteTag
  }
) => {
  const history = useHistory();
  const query = querystring.parse(history.location.search);
  const currentPage = query.page || 1;
  const {user_type} = useSelector(state => state.user);
  const {business} = userTypes;

  const onPageChange = page => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  const onSort = type => {
    const search = querystring.parse(history.location.search);
    history.push({
      search: querystring.stringify({...search, page: 1, ...type})
    });
  };
  const getParentSupplierId = subId => {
    let result = null;
    data.map(e => {
      const isHaveSub = Boolean(
        e.category_suppliers.filter(e => e.id === subId).length
      );
      if (isHaveSub) {
        result = e.id;
      }
    });
    return result;
  };
  return (
    <MainTable
      data={data}
      className={s.table}
      stickyHeader={true}
      dragIdLabel="supplier"
      getDragId={item => item.id}
      subKey="category_suppliers"
      withXScroll={true}
      subTypes={[
        {
          title: "Supplier",
          value: ({category}, allData) => (
            <div className={s.subSupplier}>
              <LogoSuppliers name={allData?.name || "-"} logo={allData?.logo || "-"}/>
              <p>{allData?.name || "-"}</p>
              <p
                onClick={e => {
                  e.stopPropagation();
                  history.push(`/categories/${category.id}`);
                }}
              >
                ({category.name})
              </p>
            </div>
          ),
          onClick: id => history.push(`/suppliers/${getParentSupplierId(id)}`),
          sticky: true
        },
        {
          title: "Spend",
          value: ({spend, spend_currency}) =>
            spend && spend_currency
              ? formatCurrency(
                currencyData.filter(e => e.label === spend_currency)[0]
                  ?.value,
                spend
              )
              : "-"
        },
        {
          title: "Payments",
          value: ({payments_count}) => payments_count || "-",
          sortable: true,
          alignment: "left"
        },
        {
          title: "Status",
          value: ({status}) =>
            supplierStatuses.find(itm => itm.value === status)?.label || "-"
        }
      ]}
      dataTypes={[
        {
          title: "Supplier",
          value: ({name, id, logo}) => (
            <div className={s.logoSubSupplier}>
              <LogoSuppliers name={name} logo={logo}/>
              <a className={s.link} href={`/suppliers/${id}`}>{name}</a>
            </div>),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          onClick: id => history.push(`/suppliers/${id}`),
          sticky: true
        },
        {
          title: "Spend",
          value: ({spend, spend_currency}) =>
            spend && spend_currency
              ? formatCurrency(
                currencyData.filter(e => e.label === spend_currency)[0]
                  ?.value,
                spend
              )
              : "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "spend", direction});
          },
          alignment: "left"
        },
        {
          title: "Payments",
          value: ({payments_count}) => payments_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "payments_count", direction});
          },
          alignment: "left"
        },
        {
          title: "Status",
          value: ({status}) =>
            supplierStatuses.find(itm => itm.value === status)?.label ||
            "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "status", direction});
          }
        },
        {
          title: "Tags",
          value: (item, allData) => (
            <Tags
              allData={allData}
              item={item}
              width={265}
              onDelete={user_type !== business && (id => onDeleteTag(id, item.id))}
              onAssign={user_type !== business && (id => onAssignTag(id, item.id))}
              onSort={({name}) =>
                query.tag !== name ? onSort({tag: name}) : onSort({tag: ""})
              }
            />
          ),
          type: "tags"
        }
      ]}
      button={user_type !== business && {
        name: "Add supplier",
        onClick: () => onAdd()
      }}
      perPage={perPage}
      options={item => {
        return (
          !item.category_suppliers && [
            {
              title: "Change status",
              onClick: () => onChangeStatus(item)
            },
            {
              title: "Delete",
              onClick: () => onDelete(item)
            }
          ]
        );
      }}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};
export default CategoriesSuppliersTable;
