export const teamList = [
  {
    id: "a1",
    name: "Team 8",
    teams: [
      {
        id: "a1",
        name: "Team 1",
        teams: [
          {
            id: "a13",
            name: "Team 87",
            teams: []
          },
          {
            id: "a13",
            name: "Team 432",
            teams: []
          },
          {
            id: "a13",
            name: "Team 678",
            teams: []
          },
        ]
      },
      {
        id: "a2",
        name: "Team 122",
        teams: []
      }
    ]
  },
  {
    id: "a2",
    name: "Team 42",
    teams: [
      {
        id: "a1",
        name: "Team 1231",
        teams: [
          {
            id: "a13",
            name: "Team 31",
            teams: []
          },
        ]
      },
      {
        id: "a2",
        name: "Team 23",
        teams: []
      }
    ]
  }
]