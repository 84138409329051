import React, { Fragment } from "react";
import s from "./ProcurementActivityContainer.module.scss";
import BusinessProcurementActivityTable from "components/tables/BusinessProcurementActivityTable/BusinessProcurementActivityTable";
import AdminProcurementActivityTable from "components/tables/AdminProcurementActivityTable/AdminProcurementActivityTable";
import { useSelector } from "react-redux";
import { userRoles } from "helpers/userPermissions/userRoles";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";

const businessRequestedProjects = [
  {
    name: "Job project",
    type_of_spend: "HR",
    budget: 350000,
    budget_currency: "$USD",
    end_date: "2020-12-02T00:00:00.000Z",
    status: "Pending",
    id: "27bd3c0b-8dd4-464d-93a6-7a3ef2351e09",
  },
  {
    name: "Project 70",
    owner: {
      id: 1,
      name: "Harry Picket",
    },
    type_of_spend: "Travel",
    budget: 30000,
    budget_currency: "$USD",
    end_date: "2020-12-02T00:00:00.000Z",
    status: "Created",
    id: "27bd3c0b-8dd4-464d-93a6-7a3ef2351e09",
  },
];

const adminRequestedProjects = [
  {
    name: "Job project",
    requester: {
      id: 1,
      name: "John Smith",
    },
    owner: {
      id: 1,
      name: "Harry Potter",
    },
    type_of_spend: "HR",
    end_date: "2020-12-02T00:00:00.000Z",
    status: "Pending",
    id: "27bd3c0b-8dd4-464d-93a6-7a3ef2351e09",
  },
];

const ProcurementActivityContainer = () => {
  const user = useSelector((state) => state.user);
  return (
    <div className={s.wrap}>
      <h2 className={s.title}>Procurement activity</h2>
      {user.roles.includes(userRoles.admin) ? (
        <Fragment>
          {adminRequestedProjects.length > 0 ? (
            <AdminProcurementActivityTable data={adminRequestedProjects} />
          ) : (
            <EmptyBlock
              title="You don’t have requested projects yet."
              withBtn={false}
              message
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          {businessRequestedProjects.length > 0 ? (
            <BusinessProcurementActivityTable
              data={businessRequestedProjects}
            />
          ) : (
            <EmptyBlock
              subtitle="You don’t have requested projects yet. You can add a new one"
              buttonTitle="Add request"
              onClick={() => {}}
              buttonDisabled={true}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};
export default ProcurementActivityContainer;
