import {
  SET_SUPPLIERS,
  SET_LOADING,
  ADD_SUPPLIER,
  DELETE_SUPPLIER,
  CHANGE_STATUS_SUPPLIER,
  CLOSE_POPUP,
  SET_POPUP_NAME,
  SET_CURRENT_PAGE,
  SET_SELECTED_SUPPLIER,
  SET_ALERT
} from "./types"
import services from "services"

export const setSuppliers = (dispatch, categoryId, query) => {
  services.categoriesServices
    .getCategorySuppliers(categoryId, query)
    .then(res => {
      dispatch({
        type: SET_SUPPLIERS,
        payload: res.data
      })
    })
}

export const setLoading = loading => ({
  type: SET_LOADING,
  payload: loading
})

export const addSupplier = (dispatch, values) => {
  services.supplierServices
    .addCategorySupplier(values)
    .then(res => {
      const { id, name, ...spread } = res.data
      dispatch({
        type: ADD_SUPPLIER,
        payload: {
          //   data: res.data,
          data: { id, name, supplier: { ...spread } },
          alert: {
            type: "success",
            message: "Category supplier successfully added."
          }
        }
      })
    })
    .catch(() => {
      dispatch({
        type: ADD_SUPPLIER,
        payload: {
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    })
}

export const deleteSupplier = (dispatch, supplierId) => {
  services.supplierServices
    .removeCategorySupplier(supplierId)
    .then(() => {
      dispatch({
        type: DELETE_SUPPLIER,
        payload: {
          supplier: supplierId,
          alert: {
            type: "success",
            message: "Category supplier successfully deleted."
          }
        }
      })
    })
    .catch(() => {
      dispatch({
        type: DELETE_SUPPLIER,
        payload: {
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    })
}

export const changeStatusSupplier = (
  dispatch,
  supplierId,
  values,
  newParentSupplier
) => {
  services.supplierServices
    .changeStatusCategorySupplier(supplierId, { status: values })
    .then(res => {
      dispatch({
        type: CHANGE_STATUS_SUPPLIER,
        payload: {
          data: res.data,
          alert: {
            type: "success",
            message: "Category supplier successfully changed."
          }
        }
      })
    })
    .catch(() => {
      dispatch({
        type: CHANGE_STATUS_SUPPLIER,
        payload: {
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    })
}

export const closePopup = () => ({
  type: CLOSE_POPUP,
  payload: null
})

export const openPopup = name => ({
  type: SET_POPUP_NAME,
  payload: { name }
})

export const setCurrentPage = page => ({
  type: SET_CURRENT_PAGE,
  payload: { page }
})

export const setSelectedSupplier = supplier => ({
  type: SET_SELECTED_SUPPLIER,
  payload: { supplier }
})

export const setAlert = alert => ({
  type: SET_ALERT,
  payload: { ...alert }
})
