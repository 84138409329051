import React from "react";
import sBase from '../Dashboard.module.scss';
import s from "./GanttChart.module.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";

const GanttChartLoading = () => {
  const {t} = useTranslation();
  const month = ['January', 'February', 'March', 'April', 'May', 'June'];

  return (
    <div className={classNames(sBase.container, s.ganttChartContainer)}>
      <div className={s.projects}>
        <div className={s.title} data-translate-key={getTranslateKey("Projects")}>
          {t(getTranslateKey("Projects"))}
        </div>
        <div className={s.block}/>
        <div className={s.block}/>
        <div className={s.block}/>
        <div className={s.block}/>
      </div>
      <div className={s.content}>
        {month.map((m, index) => {
          return (
            <div className={s.column} key={index}>
              <div className={s.title} data-translate-key={getTranslateKey(m)}>
                {t(getTranslateKey(m))}
              </div>
              <div className={s.block}/>
              <div className={s.block}/>
              <div className={s.block}/>
              <div className={s.block}/>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default GanttChartLoading;


