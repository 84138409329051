import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import s from "./CategoryInfo.module.scss";
import Tabs from "components/elements/Tabs/Tabs";
import Projects from "./components/Projects/Projects";
import Suppliers from "./components/Suppliers/Suppliers";
import Contracts from "./components/Contracts/Contracts";
import Stakeholders from "./components/Stakeholders/Stakeholders";
import CategoriesBreadCrumbs from "components/elements/CategoriesBreadCrumbs/CategoriesBreadCrumbs";
import Skeleton from "@material-ui/lab/Skeleton";

const CategoryInfo = ({ category, categories }) => {
  const history = useHistory();

  const clearQueryOnTabChange = () => {
    history.push({
      search: "",
    });
  };

  return (
    <div className={s.categoriesInfo}>
      {!category.name ? (
        <Skeleton
          variant="text"
          className={classNames("title", s.title)}
          animation="wave"
          width={200}
        />
      ) : (
        <CategoriesBreadCrumbs
          currentCategoryId={category.id}
          categories={categories}
        />
      )}
      <div className={s.wrapTabs}>
        <Tabs handleClick={clearQueryOnTabChange}>
          <div label="Suppliers">
            <Suppliers />
          </div>
          <div label="Contracts">
            <Contracts />
          </div>
          <div label="Projects">
            <Projects category={category} />
          </div>
          <div label="Stakeholders">
            <Stakeholders category={category} />
          </div>
        </Tabs>
      </div>
    </div>
  );
};

CategoryInfo.propTypes = {
  category: PropTypes.object,
};

CategoryInfo.defaultProps = {
  category: {
    id: null,
    name: "",
  },
};

export default CategoryInfo;
