import React, {useEffect, useState} from "react";
import MainTable from "../../../../../../tables/MainTable/MainTable";
import {formatCurrency} from "../../../../../../../helpers/currencyFormat";
import s from "../Category.module.scss";
import services from "../../../../../../../services";
import TableLoading from "../../../../../../loadingStates/Table/Table";
import EmptyTab from "../EmptyTab/EmptyTab";

const PreferredSuppliers = ({id}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    services.requestTypeServices.getPreferredSuppliers(id).then(res => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (loading) return <TableLoading length={2}/>;

  if (!data.length) return <EmptyTab message="Category doesn't have any Preferred Suppliers yet"/>;

  return (
    <MainTable
      totalName='Preferred suppliers'
      data={data}
      dataTypes={[
        {
          title: "Supplier",
          value: ({name, id}) => <a className={s.link} href={`/suppliers/${id}`}>{name}</a>,
        },
        {
          title: "Spend (USD)",
          value: ({target_spend, target_spend_currency}) => formatCurrency(target_spend_currency, target_spend),
        },
        {
          title: "Payments",
          value: ({payments_count}) => payments_count,
        },
        {
          title: "Status",
          value: ({status}) => (<span className={s.capitalize}>{status.replace("_", " ")}</span>),
        }
      ]}
    />
  );
};
export default PreferredSuppliers;
