import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import ProcurementActivityContainer from "components/containers/ProcurementActivityContainer/ProcurementActivityContainer";

const ProcurementActivityPage = () => {
  return (
    <DefaultLayout>
      <ProcurementActivityContainer />
    </DefaultLayout>
  );
};
export default ProcurementActivityPage;
