import React, {useState} from "react";
import {ReactComponent as NotificationIcon} from "assets/icons/notification-icon.svg";
import style from "./Notification.module.scss";
import PropTypes from "prop-types";

const Notification = ({message}) => {
  const [isShow, setShow] = useState(false);

  return (
    <div className={style.notificationWrap}>
      <div
        className={style.iconWrap}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <NotificationIcon/>
      </div>
      {isShow && <div className={style.message}>{message}</div>}
    </div>
  );
};

export default Notification;

Notification.propTypes = {
  message: PropTypes.string,
};