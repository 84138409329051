import React, {useState, useEffect} from "react"
import {CircularProgress} from "@material-ui/core"
import {useHistory} from "react-router-dom"
import querystring from "query-string"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import SystemReportsTagsTable from "components/tables/SystemReportsTagsTable/SystemReportsTagsTable"
import services from "services"
import TableLoading from "components/loadingStates/Table/Table"
import SystemReportsTagsFilter from "components/filters/SystemReportsTagsFilter/SystemReportsTagsFilter"
import s from "./SystemReportsTags.module.scss"

const SystemReportsTags = () => {
  const history = useHistory();

  const [tags, setTags] = useState({
    tags: [],
    tags_count: 0
  })
  const [loading, setLoading] = useState(true)

  const query = querystring.parse(history.location.search);

  useEffect(() => {
    services.reportServices.getSystemReportsTags(query).then(res => {
      setTags(res.data)
      setLoading(false)
    })
  }, [history.location])

  return (
    <div>
      <SystemReportsTagsFilter className={s.filterbar}/>
      {loading && <TableLoading length={4}/>}
      {!loading && Boolean(tags.tags_count) && (
        <SystemReportsTagsTable data={tags}/>
      )}
      {!loading && !Boolean(tags.tags_count) && (
        <EmptyBlock withBtn={false}>
          <div>You don’t have tags.</div>
        </EmptyBlock>
      )}
    </div>
  );
};

export default SystemReportsTags;
