import React from "react";
import styles from "./Expired.module.scss";
import { ReactComponent as ErrorIcon } from "assets/icons/error-expired.svg";
import LandingLayout from "components/landing/Landinglayout/Landinglayout.jsx";

const Expired = () => {
  return (
    <LandingLayout>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <ErrorIcon className={styles.img} />
          <p className={styles.text}>Something went wrong.</p>
          <p className={styles.text}>
            This link has expired. Please send another request to reset
            password.
          </p>
        </div>
      </div>
    </LandingLayout>
  );
};

export default Expired;
