import React, {useEffect, useState} from "react";
import MainTable from "../../../../../../tables/MainTable/MainTable";
import s from "../Users.module.scss";
import classNames from "classnames";
import services from "../../../../../../../services";
import TableLoading from "../../../../../../loadingStates/Table/Table";
import Popup from "../../../../../../popups/Popup/Popup";
import EditPriorityForm from "../../../../../../forms/EditPriorityForm/EditPriorityForm";
import EmptyBlock from "../../../../../../elements/EmptyBlock/EmptyBlock";

const Users = ({id}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpenPopUp, setOpenPopUp] = useState(false);
  const [ratingList, setRatingList] = useState([]);
  const [settings, setSettings] = useState(["category_experience", "supplier_experience", "client_experience", "availability"]);

  const getPriorityTitle = index => {
    if (index === 0) return "Highest priority";
    if (index === 1) return "High priority";
    if (index === 2) return "Medium priority";
    return "Low priority";
  };

  useEffect(() => {
    setLoading(true);
    services.requestTypeServices.getRequestUsers(id, {settings: settings.join(",")}).then(res => {
      setData(res.data);
      let preparedList = [];
      settings.forEach((setting, index) => {
        if (setting === "category_experience") preparedList.push({
          title: (
            <div className={s.settingWrap}>
              Category Experience
              <span title={getPriorityTitle(index)} className={classNames(s.priority, s[`p-${index}`])}/>
            </div>
          ),
          value: ({category_experience}) => renderExperience(category_experience),
        });

        if (setting === "supplier_experience") preparedList.push({
          title: (
            <div className={s.settingWrap}>
              Supplier Experience
              <span title={getPriorityTitle(index)} className={classNames(s.priority, s[`p-${index}`])}/>
            </div>
          ),
          value: ({supplier_experience}) => renderExperience(supplier_experience),
        });
        if (setting === "client_experience") preparedList.push({
          title: (
            <div className={s.settingWrap}>
              Client Experience
              <span title={getPriorityTitle(index)} className={classNames(s.priority, s[`p-${index}`])}/>
            </div>
          ),
          value: ({client_experience}) => renderExperience(client_experience),
        });
        if (setting === "availability") preparedList.push({
          title: (
            <div className={s.settingWrap}>
              Availability
              <span title={getPriorityTitle(index)} className={classNames(s.priority, s[`p-${index}`])}/>
            </div>
          ),
          value: ({availability}) => renderExperience(availability),
        });
      });
      setRatingList(preparedList);
    });
    setLoading(false);
  }, [settings]);

  const renderUserInfo = (id, name) => {
    return (
      <div className={s.userInfo}>
        <a className={s.link} href={`/users/${id}`}>{name}</a>
      </div>
    );
  };

  const renderExperience = value => {
    const preparedValue = Math.round(value);

    let rating = 0;
    let status = "None";

    if (preparedValue > 0 && preparedValue < 25) {
      rating = 1;
      status = "Low";
    }
    if (preparedValue > 24 && preparedValue < 75) {
      rating = 2;
      status = "Medium";
    }
    if (preparedValue > 74) {
      rating = 3;
      status = "High";
    }

    return (
      <div className={s.rating} title={`${status} (${value}%)`}>
        {[1, 2, 3].map(r => {
          if (r <= rating) {
            return <span className={classNames(s.circle, s.full)}/>;
          }
          return <span className={s.circle}/>;
        })}
      </div>
    );
  };

  const renderFit = value => {
    const preparedValue = Math.round(value * 100);
    let status = "Poor";

    if (preparedValue > 24) status = "Good";
    if (preparedValue > 74) status = "Great";

    return <div className={classNames(s.fit, s[status.toLowerCase()])}>{status}</div>;
  };

  if (loading) return <TableLoading length={2}/>;

  const renderContent = () => {
    if (!loading && !data.length) {
      return (
        <EmptyBlock
          subtitle="No available users, change priorities."
          buttonTitle="Edit priority"
          onClick={() => setOpenPopUp(true)}
        />
      );
    } else {
      return (
        <MainTable
          totalName='Total'
          data={data}
          dataTypes={[
            {
              title: "User",
              value: ({user}) => renderUserInfo(user.id, user.name),
            },
            ...ratingList,
            {
              title: "Overall fit",
              value: ({overall_fit}) => renderFit(overall_fit),
            },
          ]}
          button={{
            name: "Edit priority",
            styled: "secondary",
            onClick: () => setOpenPopUp(true),
          }}
        />
      );
    }
  };

  return (
    <>
      {renderContent()}
      {
        isOpenPopUp && (
          <Popup
            onClose={() => setOpenPopUp(false)}
            title='Edit priority'
            text='Please, drag the following fields to change their priority'
          >
            <EditPriorityForm
              updateSettings={settings => setSettings(settings)}
              preparedList={settings}
              onClose={() => setOpenPopUp(false)}
            />
          </Popup>
        )
      }
    </>
  );
};

export default Users;
