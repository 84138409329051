import React, {useRef, memo, useState, useEffect} from "react"
import s from "./ProjectLine.module.scss"
import classNames from "classnames"
import {getMinMaxDates} from "../../helpers/getMinMaxDates"
import {getProjectPos} from "../../helpers/getProjectPos"
import {fromDate} from "helpers/dateFormat"
import {ReactComponent as DateIcon} from "assets/icons/projects-board/dates.svg"
import useOutsideClicker from "hooks/useOutsideClicker"
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const ProjectLine = memo(
  ({
     project,
     idx,
     index,
     years,
     onClick,
     onCloseTimeLine,
     usersLength,
     projectsUserLength,
     useWithMonthReorder,
   }) => {
    const [lineWidth, setLineWidth] = useState(null)
    const {t} = useTranslation();
    const projectLineRef = useRef(null)
    const projectNameRef = useRef(null)
    const threeDotsRef = useRef(null)
    useEffect(() => {
      if (projectLineRef.current?.offsetWidth) {
        setLineWidth(projectLineRef.current.offsetWidth)
      }
    }, [projectLineRef.current?.offsetWidth])
    useOutsideClicker(projectLineRef, onCloseTimeLine)
    const left = getProjectPos(
      project?.start_date || new Date(),
      project?.end_date || new Date(),
      ...getMinMaxDates(years, useWithMonthReorder),
      useWithMonthReorder
    ).left;
    const width = getProjectPos(
      project?.start_date || new Date(),
      project?.end_date || new Date(),
      ...getMinMaxDates(years, useWithMonthReorder),
    ).width;

    return (
      <div
        className={classNames(s.projectLine, {
          [s.first]: !idx && !index,
          [s.last]: idx === projectsUserLength - 1 && index === usersLength - 1,
          [s.small]:
          projectNameRef.current?.offsetWidth > lineWidth - 16 - 7 - 14,
          [s.empty]: threeDotsRef.current?.offsetWidth > lineWidth - 16
        })}
        style={{
          left: `${left >= 0 ? left : 0}%`,
          width: `${left >= 0 ? width : width + left}%`,
          maxWidth: `${left > 0 ? 100 - left : 100}%`
        }}
        onClick={onClick}
        ref={projectLineRef}
      >
        <span className={s.name} ref={projectNameRef}>
          {project?.name}
        </span>
        <span className={s.threeDots} ref={threeDotsRef}>
          ...
        </span>

        <div className={s.iconWrap}>
          <DateIcon/>
          <div className={s.dates}>
            {project?.start_date && (
              <>
                <span data-translate-key={getTranslateKey("Start Date")}>
                  {t(getTranslateKey("Start Date"))}
                </span>
                <span>{fromDate(project.start_date)}</span>
              </>
            )}
            {project?.end_date && (
              <>
                <span data-translate-key={getTranslateKey("Target End Date")}>
                  {t(getTranslateKey("Target End Date"))}
                </span>
                <span>{fromDate(project.end_date)}</span>
              </>
            )}
          </div>
        </div>
        <span className={s.dates}>
          {`${project?.start_date && fromDate(project.start_date)} -`}{" "}
          {project?.end_date ? fromDate(project.end_date) : ""}
        </span>
      </div>
    )
  }
)

export default ProjectLine
