import React from 'react';
import style from "./DetailBlockView.module.scss";
import Button from "../../../../elements/Button/Button";
import {changeMode} from "../../helpers/actions";
import StepList from "../StepList/StepList";
import {parseToObj} from "../../../../../helpers/formulaToObj";

const DetailBlockView = ({field, dispatch}) => {

  const conditions = parseToObj(field.conditions);

  return (
    <>
      <div className={style.viewBlockWrapper}>
        <div className={style.content}>
          <div className={style.infoGroup}>
            <p>Type:</p>
            <p>{field.field_type}</p>
          </div>
          <div className={style.infoGroup}>
            <p>Optional:</p>
            <p>{field.optional ? "Yes" : "No"}</p>
          </div>
          <div className={style.infoGroup}>
            <p>Show field:</p>
            <p>{field.conditions === "" ? "Always" : "Under Condition"}</p>
          </div>
        </div>
        <Button styled='primary' onClick={() => changeMode(dispatch, true)}>
          Edit
        </Button>
      </div>
      {field.conditions && <StepList conditions={conditions} isViewMode={true}/>}
    </>
  );
};

export default DetailBlockView;