import React, {useState} from "react";
import s from "./AdditionalQuestions.module.scss";
import TextQuestion from "./TextQuestion/TextQuestion";
import RadioQuestion from "./RadioQuestion/RadioQuestion";
import CheckboxQuestion from "./CheckboxQuestion/CheckboxQuestion";
import {ReactComponent as ArrowDown} from "assets/icons/arrowDown.svg";
import classNames from "classnames";
import * as propTypes from "prop-types";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const AdditionalQuestionsTab = ({questions}) => {
  const [isOpen, setOpen] = useState(true);
  const {t} = useTranslation();

  const getQuestion = answer => {
    if (answer.question.question_type === "text_field") return (
      <TextQuestion key={answer.answer_id} answer={answer}/>
    );
    if (answer.question.question_type === "radio_buttons") return (
      <RadioQuestion key={answer.answer_id} answer={answer}/>
    );
    if (answer.question.question_type === "yes_no") return (
      <CheckboxQuestion key={answer.answer_id} answer={answer}/>
    );
  };

  // eslint-disable-next-line react/no-unescaped-entities
  let content = (
    <p
      className={s.emptyBlock}
      data-translate-key={getTranslateKey("The request doesn't have any additional questions.")}
    >
      {t(getTranslateKey("The request doesn't have any additional questions."))}
    </p>
  );

  if (questions.length) {
    content = (
      <>
        <div className={s.header}>
          <p className={s.title}>Additional questions</p>
          <button className={classNames(s.openBtn, {[s.active]: isOpen})} onClick={() => setOpen(!isOpen)}>
            <ArrowDown/>
          </button>
        </div>
        {isOpen && (
          <div className={s.contentWrap}>
            {
              questions.map(q => getQuestion(q.answer))
            }
          </div>
        )}
      </>
    );
  }

  return (
    <div className={s.additionalQuestionsTabWrapper}>
      {content}
    </div>
  );
};
export default AdditionalQuestionsTab;

AdditionalQuestionsTab.propTypes = {
  questions: propTypes.any,
};