export const userTypes = [
  {
    label: "Procurement",
    value: "procurement",
  },
  {
    label: "Business",
    value: "business",
  },
  {
    label: "Support",
    value: "support",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

export const userRoles = [
  {
    label: "Admin",
    value: "admin",
    userType: userTypes[3],
  },
  {
    label: "CPO",
    value: "cpo",
    userType: userTypes[0],
  },
  {
    label: "Director",
    value: "director",
    userType: userTypes[0],
  },
  {
    label: "Procurement Manager",
    value: "procurement_manager",
    userType: userTypes[0],
  },
  {
    label: "Business User",
    value: "business_user",
    userType: userTypes[1],
  },
  {
    label: "Category Business Owner",
    value: "category_business_owner",
    userType: userTypes[1],
  },
  {
    label: "Procurement Category Owner",
    value: "procurement_category_owner",
    userType: userTypes[0],
  },
  {
    label: "Procurement Admin",
    value: "procurement_admin",
    userType: userTypes[0],
  },
  {
    label: "Supplier admin",
    value: "supplier_admin",
    userType: userTypes[0],
  },
  {
    label: "Supplier user",
    value: "supplier_user",
    userType: userTypes[0],
  },
  {
    label: "Finance User",
    value: "finance_user",
    userType: userTypes[2],
  },
  {
    label: "Risk User",
    value: "risk_user",
    userType: userTypes[2],
  },
  {
    label: "Audit User",
    value: "audit_user",
    userType: userTypes[2],
  },
];