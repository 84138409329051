import axios from "./axios";

export default {
  get: async (id) => {
    try {
      const result = await axios.get(`projects/${id}/attachments`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  add: async (id, params) => {
    try {
      const result = await axios.post(`projects/${id}/attachments`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  delete: async (projectId, attachmentId, config) => {
    try {
      const result = await axios.delete(
        `projects/${projectId}/attachments/${attachmentId}`,
        config
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
  undo: async (projectId, attachmentId) => {
    try {
      const result = await axios.put(
        `projects/${projectId}/attachments/${attachmentId}/undo`
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
};
