import React from "react";
import Popup from "components/popups/Popup/Popup";
import AdminSettingsFinancialSavingsGroupForm
  from "../../../../../../forms/AdminSettingsFinancialSavingsGroupForm/AdminSettingsFinancialSavingsGroupForm";

const EditFinancialSavingsGroupPopup = ({ onClose, onSubmit, defaultData }) => {
  return (
    <Popup onClose={onClose} title="Edit savings group">
      <AdminSettingsFinancialSavingsGroupForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultData={defaultData}
      />
    </Popup>
  );
};

export default EditFinancialSavingsGroupPopup;
