export const getColsPos = (dataPos) => {
  let existColsIdx = [];
  let dataCols = [];
  dataPos.map((item) => {
    if (!existColsIdx.filter((e) => e === item.colIdx).length) {
      dataCols.push(dataPos.filter((e) => e.colIdx === item.colIdx));
      existColsIdx.push(item.colIdx);
    }
  });
  return dataCols;
};
