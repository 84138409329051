import { formatUserRoles } from "helpers/userPermissions/formatUserRoles";

export const formatDetailData = (data) => {
  const _data = {
    Title: data.title || "-",
    Type: data.user_type || "-",
    Role: data.roles
      ? formatUserRoles(data.roles)
          .map((e) => e.label)
          .join(", ")
      : "-",
    Email: data.email || "-",
    Division: data.division.name || "-",
    Department: data.department.name || "-",
  };

  return Object.keys(_data).map((key) => ({
    name: key,
    value: _data[key],
  }));
};

export const formatProjectTypesData = (initialData = []) => {
  const data = initialData.reduce((acc, itm) => {
    const obj = Object.values(itm)[0];
    if (Object.values(obj).some((el) => el)) {
      acc.push({
        type: Object.keys(itm)[0],
        ...obj,
      });
    }
    return acc;
  }, []);
  return data;
};

export const formatProjectSavingsData = (initialData = []) => {
  const data = initialData.map((itm) => ({
    type: Object.keys(itm)[0],
    ...Object.values(itm)[0].reduce((acc, el) => ({ ...acc, ...el }), {}),
  }));
  return data.filter((obj) =>
    Object.values(obj).some((el, index) => (index ? el : false))
  );
};
