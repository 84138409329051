import React from "react";
import s from "./TaskDetailsContainer.module.scss";
import PropTypes from "prop-types";
import ClarificationHistory from "../../elements/ClarificationHistory/ClarificationHistory";
import Header from "./components/Header/Header";
import {useTaskDetailsState} from "./hooks/useTaskDetailsState";
import TaskDetails from "./components/TaskDetails/TaskDetails";
import Stakeholders from "./components/Stakeholders/Stakeholders";
import Tabs from "../../elements/Tabs/Tabs";
import Subtasks from "./components/Subtasks/Subtasks";
import SelectedTaskSidebar from "../../elements/SelectedTaskSidebar/SelectedTaskSidebar";

const TaskDetailsContainer = ({data, load, project, loading}) => {
  const {
    editHandler,
    changeStatus,
    createNewSubtask,
    isHighRule,
    isResponsibleUser,
    stakeholders,
    preparedResponsibleUsers,
    stakeholdersLoading,
    selectedSubtask,
    setSelectedSubtaskId,
    editSubtask,
    deleteSubtask,
    addNote,
    editNote,
    deleteNote,
    changeSubtaskStatus
  } = useTaskDetailsState({load, data, project});

  let clarificationHistory = <></>;

  if (isHighRule || isResponsibleUser) {
    clarificationHistory = (
      <div label='History'>
        <ClarificationHistory sourceIdUrlParam="taskId"/>
      </div>
    );
  }

  const getSubtasksCount = () => data.subtasks_count && ({
    ...data.subtasks_count,
    to_do: data.subtasks.length - data.subtasks_count.completed - data.subtasks_count.in_progress,
    total: data.subtasks.length
  });

  return (
    <div className={s.wrap}>
      <Header
        data={data}
        isHighRule={isHighRule}
        changeStatus={changeStatus}
      />
      <div className={s.groupDetails}>
        <TaskDetails
          data={data}
          isHighRule={isHighRule}
          editHandler={editHandler}
        />
        <Stakeholders
          stakeholders={stakeholders}
          loading={stakeholdersLoading}
          isHighRule={isHighRule}
        />
      </div>
      <Tabs>
        <div label='Subtasks'>
          <Subtasks
            useForProject={true}
            subtasks={data.subtasks}
            responsibleUsers={preparedResponsibleUsers}
            setSelectedSubtask={setSelectedSubtaskId}
            changeStatus={changeSubtaskStatus}
            onAdd={createNewSubtask}
            onDelete={deleteSubtask}
            onEdit={editSubtask}
            subtasksCount={getSubtasksCount()}
            isHighRule={isHighRule}
            isResponsibleUser={isResponsibleUser}
            loading={loading}
          />
        </div>
        {clarificationHistory}
      </Tabs>
      <SelectedTaskSidebar
        useForProject={true}
        selectedSubtask={selectedSubtask}
        preparedResponsibleUsers={preparedResponsibleUsers}
        onClose={() => setSelectedSubtaskId(null)}
        onEdit={editSubtask}
        addNote={addNote}
        editNote={editNote}
        deleteNote={deleteNote}
        isHighRule={isHighRule}
        changeStatus={changeSubtaskStatus}
        isResponsibleUser={isResponsibleUser}
      />
    </div>
  );
};

export default TaskDetailsContainer;

TaskDetailsContainer.propTypes = {
  data: PropTypes.any,
  load: PropTypes.func,
  project: PropTypes.any,
  loading: PropTypes.bool
};