import React, {useState} from "react"
import s from "./Tag.module.scss"
import {colors} from "../../helpers/colors"
import classNames from "classnames"

const Tag = ({tag, className, withDelete, onClick, onDelete, style}) => {
  const [hover, setHover] = useState(false)
  const {name, color, id, type} = tag

  const getIconDelete = color => {
    return (
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"
           className={s.deleteIcon}>
        <path
          d="M7.82239 0.183542C7.58576 -0.0530905 7.2035 -0.0530905 6.96687 0.183542L3.99986 3.14448L1.03286 0.177474C0.796222 -0.0591581 0.41397 -0.0591581 0.177337 0.177474C-0.0592955 0.414107 -0.0592955 0.79636 0.177337 1.03299L3.14435 4L0.177337 6.96701C-0.0592955 7.20364 -0.0592955 7.58589 0.177337 7.82253C0.41397 8.05916 0.796222 8.05916 1.03286 7.82253L3.99986 4.85552L6.96687 7.82253C7.2035 8.05916 7.58576 8.05916 7.82239 7.82253C8.05902 7.58589 8.05902 7.20364 7.82239 6.96701L4.85538 4L7.82239 1.03299C8.05295 0.802427 8.05295 0.414107 7.82239 0.183542Z"
          fill={color}/>
      </svg>
    )
  }

  return (
    <div
      className={classNames(s.tagWrap, className, s[type])}
      style={{
        background: color
          ? hover
            ? colors[color][1]
            : colors[color][0]
          : colors.gray[0],
        cursor: onClick && "pointer",
        border: type === "flag" ? `2px solid ${colors[color][0]}` : 'none',
        ...style
      }}
      onMouseOver={() => !hover && onClick && setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <div className={classNames(s.tag, {[s.dark]: !color})}>
        <span className={s.title} style={{
          color: type === "flag" ? colors[color][1] : '#fff',
        }}>{name}</span>
        {withDelete && (
          <span
            className={s.deleteBtn}
            onClick={e => {
              e.stopPropagation()
              onDelete(id)
            }}
          >
            {getIconDelete(type === "flag" ? colors[color][1] : "#fff")}
          </span>
        )}
      </div>
    </div>
  )
}
export default Tag
