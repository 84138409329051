import classNames from "classnames";
import s from "./UsersTable.module.scss";
import styles from "../RequestsTable/RequestsTable.module.scss";
import LogoSuppliers from "../../elements/LogoSuppliers/LogoSuppliers";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const UsersPopupMessage = ({item}) => {
  const [isTooltip, setTooltip] = useState(false);
  const preparedStatus = `${item.status[0].toUpperCase()}${item.status.slice(1)}`.replace("_", " ");
  const {t} = useTranslation();
  return (
    <div
      className={classNames(s.status, styles[item.status])}
      onMouseEnter={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
    >
      {
        (isTooltip && item.status === "inactive") && (
          <div className={s.tooltip}>
            <p data-translate-key={getTranslateKey("This user is inactive")}>
              {t(getTranslateKey("This user is inactive"))}
            </p>
          </div>
        )
      }
      {
        (isTooltip && item.status === "active") && (
          (!item.ready_for_conversation ? "" :
              <div className={s.tooltip}>
                <p data-translate-key={getTranslateKey("Ready for a conversation")}>
                  {t(getTranslateKey("Ready for a conversation"))}
                </p>
              </div>
          ))
      }
      {preparedStatus}

      <div className={s.logoUser}>
        <LogoSuppliers classNameLogo={s.logo} name={item.name}/>
        <div>
          <div className={s.nameBlock}>
            <span className={s.nameStyle}>{item.name}</span>
            <span className={s.conversation}>
                  {item.ready_for_conversation &&
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.99967 2.16659V6.83325H2.44634L2.05301 7.22659L1.66634 7.61325V2.16659H8.99967ZM9.66634 0.833252H0.999674C0.633008 0.833252 0.333008 1.13325 0.333008 1.49992V10.8333L2.99967 8.16659H9.66634C10.033 8.16659 10.333 7.86659 10.333 7.49992V1.49992C10.333 1.13325 10.033 0.833252 9.66634 0.833252ZM12.9997 3.49992H11.6663V9.49992H2.99967V10.8333C2.99967 11.1999 3.29967 11.4999 3.66634 11.4999H10.9997L13.6663 14.1666V4.16659C13.6663 3.79992 13.3663 3.49992 12.9997 3.49992Z"
                        fill="#005A9E"/>
                    </svg>}
                  </span>
          </div>
          <div className={s.logoUser}>
            <p className={s.userTitle}>{item.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersPopupMessage;