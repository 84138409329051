import React, {useEffect, useRef, useState} from 'react';
import s from "./GroupedBySelector.module.scss";
import {ReactComponent as Grouped} from "assets/icons/grouped.svg";
import useOutsideClicker from "../../../../hooks/useOutsideClicker";
import {ReactComponent as ArrowDown} from "assets/icons/arrowDown.svg";
import services from "../../../../services";
import {trimRest} from "../../../../helpers/stringHelper";
import qs from "query-string";
import {useHistory} from "react-router-dom";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const GroupedBySelector = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectors, setSelectors] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const groupedRef = useRef();

  const {grouped_by, ...query} = qs.parse(
    history.location.search
  );

  useEffect(() => {
    services.customStatusesServices.getCustomSelectors().then(res => {
      if (res.data && res.data?.length) {
        setSelectors([{id: "", name: "Stage"}, ...res.data]);
        let prepareValue = res.data.find(item => item.id === grouped_by) ?? {id: "", name: "Stage"};
        setSelectedValue(prepareValue);
      }
    });
  }, []);

  useOutsideClicker(groupedRef, () => {
    setOpen(false);
  });

  const changeGroupedBy = selector => {
    const searchQuery = {
      ...query,
      grouped_by: selector.id,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
    setSelectedValue(selector);
    setOpen(false);
  }

  if (!selectors.length) return null;

  return (
    <div className={s.groupedBySelector} ref={groupedRef}>
      <div className={s.selector} onClick={() => setOpen(!isOpen)}>
        <Grouped/>
        <span data-translate-key={getTranslateKey("Grouped by")}>
          {t(getTranslateKey("Grouped by"))}:
        </span>
        <span>{trimRest(20, selectedValue?.name)}</span>
        <ArrowDown/>
      </div>
      {
        (isOpen && selectors.length > 0) && (
          <div className={s.selectorList}>
            {selectors.map(selector => (
              <div key={selector.id} className={s.item} onClick={() => changeGroupedBy(selector)}>
                {selector.name}
              </div>
            ))}
          </div>
        )
      }
    </div>
  );
};

export default GroupedBySelector;