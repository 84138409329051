import React, {useEffect, useState} from "react"
import EmptyContent from "../EmptyContent/EmptyContent";
import Content from "../Content/Content";
import {addNewCategory, deleteCategory, loadData} from "../helpers/actions/main";
import Popup from "../../../../popups/Popup/Popup";
import CategoryForm from "./CategoryForm";

const Category = ({data, dispatch}) => {
  const [isOpenPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    loadData(dispatch, 'categoryList');
  }, []);

  const serializeData = data => {
    return data.map(t => {
      return {
        name: t.category.name,
        questions_count: t.additional_questions_count,
        id: t.id,
      }
    });
  }

  const addNewCondition = value => addNewCategory(dispatch, {category_id: value});
  const deleteCondition = id => deleteCategory(dispatch, id);

  return (
    <>
      {
        !Boolean(data.length) ?
          <EmptyContent addAction={() => setOpenPopUp(true)}/> :
          <Content
            data={serializeData(data)}
            type='Category'
            addAction={() => setOpenPopUp(true)}
            deleteAction={id => deleteCondition(id)}
          />
      }
      {
        isOpenPopUp &&
        <Popup
          onClose={() => setOpenPopUp(false)}
          title="New condition"
        >
          <CategoryForm
            onSubmit={value => addNewCondition(value.category_id)}
            onClose={() => setOpenPopUp(false)}
            categoryList={data}
          />
        </Popup>
      }
    </>
  );
}
export default Category;
