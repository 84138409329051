import React, { useState, useEffect } from "react"
import { CircularProgress } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import querystring from "query-string"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import SystemReportsProjectsTable from "components/tables/SystemReportsProjectsTable/SystemReportsProjectsTable"
import services from "services"
import TableLoading from "components/loadingStates/Table/Table"
import s from "./SystemReportsProjects.module.scss"
import SystemReportsProjectsFilter from "components/filters/SystemReportsProjectsFilter/SystemReportsProjectsFilter"
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar"
import {
  getAllIds,
  formatFilters
} from "components/elements/FilterBar/helpers/helpers"
const SystemReportsProjects = () => {
  const history = useHistory()

  const [projects, setProjects] = useState({
    projects: [],
    projects_count: 0
  })
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState([])

  const query = querystring.parse(history.location.search)

  useEffect(() => {
    services.reportServices.getSystemReportsProjects(query).then(res => {
      setProjects(res.data)
      setLoading(false)
    })
  }, [history.location])
  useEffect(() => {
    services.reportServices.getProjectsReportsFilters().then(res => {
      const types = res?.data?.project_types
      setFilters(
        formatFilters({
          owners: res?.data?.owners || [],
          project_type_ids: types || []
        })
      )
    })
  }, [])
  return (
    <div>
      {!Array.isArray(filters) && Object.keys(filters).length ? (
        <SystemReportsProjectsFilter
          filters={filters}
          className={s.filterbar}
        />
      ) : (
        <FilterBarLoading className={s.filterbar} />
      )}
      {loading && <TableLoading length={7} />}
      {!loading && Boolean(projects.projects_count) && (
        <SystemReportsProjectsTable
          data={projects.projects}
          projectsCount={projects.projects_count}
        />
      )}
      {!loading && !Boolean(projects.projects_count) && (
        <EmptyBlock withBtn={false}>
          <div>You don’t have projects.</div>
        </EmptyBlock>
      )}
    </div>
  )
}

export default SystemReportsProjects
