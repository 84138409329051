import axios from "./axios";

export default {
  getCustomDropdowns: async params => {
    try {
      return await axios.get("/admin/custom_dropdowns", {params});
    } catch (error) {
      throw error;
    }
  },
  getCustomDropdownsAll: async params => {
    try {
      return await axios.get("/admin/custom_dropdowns_list", {params});
    } catch (error) {
      throw error;
    }
  },
  getCustomDropdownsDetail: async id => {
    try {
      return await axios.get(`/admin/custom_dropdowns/${id}`);
    } catch (error) {
      throw error;
    }
  },
  addCustomDropdowns: async params => {
    try {
      return await axios.post("/admin/custom_dropdowns", params);
    } catch (error) {
      throw error;
    }
  },

  editCustomDropdowns: async (id, params) => {
    try {
      return await axios.put(`/admin/custom_dropdowns/${id}`, params);
    } catch (error) {
      throw error;
    }
  },

  deleteCustomDropdowns: async id => {
    try {
      return await axios.delete(`/admin/custom_dropdowns/${id}`,);
    } catch (error) {
      throw error;
    }
  },

  restoreCustomDropDown: async id => {
    try {
      return await axios.put(`/admin/custom_dropdowns/${id}/restore`,);
    } catch (error) {
      throw error;
    }
  },
};
