import React from "react";
import UserFormWrapper from "components/wrappers/UserFormWrapper/UserFormWrapper";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import s from "./LoginForm.module.scss";
import { useFormik } from "formik";
import { object, string } from "yup";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const EmailForm = ({ setEmail, handle }) => {
  const {t} = useTranslation();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: object({
      email: string()
        .email("Email is invalid")
        .required("Email is invalid")
        .max(32, "Email cannot be greater than 32"),
    }),
    onSubmit: (values) => {
		if (handle) handle(values.email, () => setEmail(values.email));
		else setEmail(values.email);
	},
  });
  const { handleSubmit, handleChange, values, errors } = formik;
  return (
    <UserFormWrapper>
      <h1 className={s.title} data-translate-key={"sign-in"}> {t(getTranslateKey("sign-in"))}</h1>
      <form className={s.form} onSubmit={handleSubmit} noValidate>
        <Input
          className={s.field}
          type="email"
          name="email"
          placeholder="Enter your Email"
          value={values.email}
          error={errors.email}
          onChange={handleChange}
        />
        <div className={s.hiddenField}>
          <Input type="password" name="password" value="" />
        </div>
        <Button className={s.btn} styled="primary" type="submit">
          Next
        </Button>
      </form>
    </UserFormWrapper>
  );
};

export default EmailForm;
