import React, {useState} from 'react';
import styles from "./ProjectTaskBreakdown.module.scss";
import {ReactComponent as Arrow} from "../../../../assets/icons/arrowDown.svg";
import classNames from "classnames";
import {trimRest} from "../../../../helpers/stringHelper";

const DepartmentItem = ({data}) => {
  const [opened, setOpened] = useState(false);
  return (
    <div className={styles.department}>
      <header>
        <div className={classNames(styles.titleWrap, {[styles.opened]: opened})}>
          <p className={styles.title}>
            <span
              title={data.name}>{trimRest(18, data.name)}</span> - {data.tasks_count_by_department} task{data.tasks_count_by_department > 1 && 's'}
          </p>
          <Arrow onClick={() => setOpened(!opened)}/>
        </div>
        <div className={styles.spent}>
          {data.spend_by_department} day{data.spend_by_department > 1 && "s"}
        </div>
      </header>
      {
        opened && data.tasks.map(task => (
          <div className={styles.task}>
            <p>{trimRest(18, task.name)}</p>
            <p>{task.spend} day{task.spend > 1 && "s"}</p>
          </div>
        ))
      }
    </div>
  );
};

export default DepartmentItem;