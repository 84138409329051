import React, {useState} from "react";
import styles from "./TaskDetails.module.scss";
import PropTypes from "prop-types";
import {ReactComponent as Edit} from "../../../../../assets/icons/edit-pencil.svg";
import InfoBlock from "../../../../elements/InfoBlock/InfoBlock";
import {ReactComponent as Link} from "../../../../../assets/icons/link.svg";
import {trimRest} from "../../../../../helpers/stringHelper";
import TaskPopup from "../../../../elements/MilestonesTasksBoard/popups/TaskPopup";
import {generateUniqueId} from "../../../../../helpers/uniqueId";
import Avatar from "../../../../elements/Avatar/Avatar";
import ResponsibleUser from "../ResponsibleUser/ResponsibleUser";

const TaskDetails = ({data, edit}) => {
  const [editPopUp, setEditPopUp] = useState(false);
  const preparedTask = {
    ...data,
    hyperlinks: data?.hyperlinks?.map(h => ({...h, id: generateUniqueId()})) ?? [],
    responsible_user: data?.responsible_user ? {
      value: data.responsible_user.id,
      label: data.responsible_user.name
    } : null
  };

  const onEdit = values => {
    edit({...values, task_type: data.task_type, stage_id: data.stage.id});
    setEditPopUp(false);
  };

  return (
    <div className={styles.details}>
      <div className={styles.main}>
        <div className={styles.infoGroup}>
          <InfoBlock title="Responsible user:">
            <ResponsibleUser user={data.responsible_user}/>
          </InfoBlock>
          <InfoBlock title="Attachments:">
            <div className={styles.attachments}>
              {data.attachments.map((a, index) => (
                <a
                  key={index}
                  className={styles.link}
                  href={a.path}
                  target="_blank" rel="noreferrer"
                >
                  {a.name}
                </a>
              ))}
              {data.attachments.length === 0 && "-"}
            </div>
          </InfoBlock>
        </div>
        <InfoBlock title="Help:">
          {data.help_text || "-"}
        </InfoBlock>
        <InfoBlock title="Description:">
          {data.description || "-"}
        </InfoBlock>
      </div>
      <div className={styles.hyperlinks}>
        <InfoBlock title="Hyperlinks:">
          <div className={styles.hyperlinksWrap}>
            {data.hyperlinks.map((hyperlink, index) => (
              <a
                key={index}
                className={styles.link}
                href={hyperlink.url}
                target="_blank" rel="noreferrer"
              >
                {trimRest(14, hyperlink.name)} <Link/>
              </a>
            ))}
            {data.hyperlinks.length === 0 && "-"}
          </div>
        </InfoBlock>
      </div>
      <div className={styles.edit}>
        <Edit onClick={() => setEditPopUp(true)}/>
      </div>
      {editPopUp && (
        <TaskPopup
          onClose={() => setEditPopUp(false)}
          onFormSubmit={onEdit}
          type={preparedTask}
          taskType={data.task_type + " task"}
        />
      )}
    </div>
  );
};

export default TaskDetails;

TaskDetails.propTypes = {
  data: PropTypes.any,
  edit: PropTypes.func
};