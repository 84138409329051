import {object, string} from "yup";
import {generateUniqueId} from "../../../../helpers/uniqueId";

export const baseSteps = [
  {
    fields: [
      {
        key: "name",
        type: "String",
        value: {
          value: "",
          type: "String",
        },
        required: object({
          value: string().required("Name is required")
        }),
        label: "Enter Request name",
        conditions: "",
        optional: true,
      },
    ],
    name: "Start",
    id: generateUniqueId(),
  },
  {
    fields: [
      {
        key: "description",
        type: "Description",
        value: "",
        required: "",
        label: "Description (optional)",
        conditions: "",
        optional: true,
      },
      {
        key: "attachments",
        type: "Attachments",
        value: [],
        required: "",
        label: "",
        conditions: "",
        optional: true,
      },
    ],
    name: "Additional information",
    id: generateUniqueId(),
  },
];