import React from "react"
import Popup from "components/popups/Popup/Popup"
import AdminTaskRolesForm from "components/forms/AdminTaskRolesForm/AdminTaskRolesForm"
const AddPopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      onClose={onClose}
      title='New role'
      text='Please, complete the following fields to add a new task role'
    >
      <AdminTaskRolesForm onClose={onClose} onSubmit={onSubmit} />
    </Popup>
  )
}
export default AddPopup
