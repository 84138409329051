import React from "react";
import MainTable from "components/tables/MainTable/MainTable";
import {currencyData} from "helpers/helpData";
import {formatCurrency} from "helpers/currencyFormat";
import {fromDate} from "helpers/dateFormat";
import {useParams} from "react-router-dom";

const UserProjectsTable = (
  {
    perPage,
    onPageChange,
    onSort,
    data,
    currentPage,
    history
  }
) => {

  const params = useParams();

  return (
    <>
      <MainTable
        data={data.projects}
        dataTypes={[
          {
            title: "Project name",
            value: ({name}) => name,
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "name", direction});
            },
            onClick: id => history.push(`/projects/${id}`),
            hint: true,
            max: 22,
            sticky: true
          },
          {
            title: "Type",
            value: ({project_type}) => project_type?.name || "",
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "project_type", direction});
            }
          },
          {
            title: "Anticipated Benefits (USD)",
            value: ({anticipated_savings, anticipated_savings_currency}) =>
              anticipated_savings && anticipated_savings_currency
                ? formatCurrency(
                  currencyData.filter(
                    e => e.label === anticipated_savings_currency
                  )[0]?.value,
                  anticipated_savings
                )
                : "-",
            alignment: "right"
          },
          {
            title: "Achieved Benefits (USD)",
            value: ({project_savings, project_savings_currency}) =>
              project_savings && project_savings_currency
                ? formatCurrency(
                  currencyData.filter(
                    e => e.label === project_savings_currency
                  )[0]?.value,
                  project_savings
                )
                : "-",
            alignment: "right"
          },
          {
            title: "End date",
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "end_date", direction});
            },
            value: ({end_date}) => (end_date ? fromDate(end_date) : "-")
          },
          {
            title: "Status",
            value: ({stage}) => stage.name,
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "status", direction});
            }
          },
          {
            title: "Role",
            value: ({owner}) => owner?.id === params.id ? "Owner" : "Stakeholder",
            sortable: true,
            handleSort: ({direction}) => {
               onSort({sort_by: "role", direction});
            }
          }
        ]}
        perPage={perPage}
        totalCount={data.projects_count}
        onPageChange={onPageChange}
        isPagination={true}
        page={currentPage}
      />
    </>
  );
};
export default UserProjectsTable;
