import React from "react";
import Popup from "components/popups/Popup/Popup.jsx";
import TaskForm from "../../../forms/TaskForm/TaskForm";
import PropTypes from "prop-types";

const TaskPopup = ({onClose, onFormSubmit, type, taskType}) => {
  return (
    <Popup
      title={`${type ? "Edit" : "New"} ${taskType}`}
      text={
        type &&
        "Please, complete the following fields to create a new task"
      }
      onClose={onClose}
    >
      <TaskForm
        onClose={onClose}
        onFormSubmit={onFormSubmit}
        defaultValues={type}
      />
    </Popup>
  );
};

export default TaskPopup;

TaskPopup.propTypes = {
  onClose: PropTypes.func,
  onFormSubmit: PropTypes.func,
  type: PropTypes.any,
  taskType: PropTypes.string
};