import React, { useState } from "react"
import MainTable from "components/tables/MainTable/MainTable"
import { projectTypes } from "helpers/helpData"
import sort from "helpers/sortHelper"

const UserReportProjectsTable = ({ data: initialData }) => {
  const [data, setData] = useState(initialData)
  const onSort = ({ sort_by, direction }) => {
    if (sort_by === "type")
      setData(sort.sortString(data, { field: sort_by, direction }))
    else {
      setData(sort.sortNumber(data, { field: sort_by, direction }))
    }
  }
  return (
    <>
      <MainTable
        data={data}
        dataTypes={[
          {
            title: "Project type",
            value: ({ type }) => type || "-",
            sortable: true,
            handleSort: ({ direction }) => {
              onSort({ sort_by: "type", direction })
            }
          },
          {
            title: "Planned",
            value: ({ planned }) => planned || "-",
            sortable: true,
            handleSort: ({ direction }) => {
              onSort({ sort_by: "planned", direction })
            }
          },
          {
            title: "Active",
            value: ({ active }) => active || "-",
            sortable: true,
            handleSort: ({ direction }) => {
              onSort({ sort_by: "active", direction })
            }
          },
          {
            title: "Done",
            value: ({ completed }) => completed || "-",
            sortable: true,
            handleSort: ({ direction }) => {
              onSort({ sort_by: "completed", direction })
            }
          },
          {
            title: "Total",
            value: ({ total }) => total || "-",
            sortable: true,
            handleSort: ({ direction }) => {
              onSort({ sort_by: "total", direction })
            }
          },
          {
            title: "Avg Time (days)",
            value: ({ average_time }) => average_time || "-",
            sortable: true,
            handleSort: ({ direction }) => {
              onSort({ sort_by: "average_time", direction })
            }
          }
        ]}
      />
    </>
  )
}
export default UserReportProjectsTable
