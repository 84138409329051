import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import defaultDate from "../DashboardContainer/components/MainDashboard/SavingsGoal/defaultDate";
import services from "../../../services";
import classNames from "classnames";
import sBase from "../DashboardContainer/components/MainDashboard/MainDashboard.module.scss";
import s from "./ActualSavingsChart.module.scss";
import GoalsLoading from "../../loadingStates/Dashboard/Goals/GoalsLoading";
import {getTitle} from "../DashboardContainer/components/helpers/dataFormat";
import {paths} from "../../../routes/paths";
import {Chart} from "react-google-charts";

const ActualSavingsChart = ({departmentId, type, savingsData}) => {
  const COLORS = ["#63A5A1", "#F4A261", "#5B79B4", "#FB787A"];
  const history = useHistory();
  const [data, setData] = useState(defaultDate);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    services.dashboardServices.getGoalsActualSavings({
      view: type,
      department_id: departmentId
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const result = savingsData.filter(sav => sav.month !== undefined).map(saving => {
    if (saving.month) {
      return [`${saving.month.slice(0, 3)} ${saving.year}`, saving.committed, saving.forecasted, saving.actual];
    }
    return [`${saving.month.slice(0, 3)} ${saving.year}`, 0, 0, 0];
  });

  return (
    <section className={classNames(sBase.wrapper, s.goalsWrapper)}>
      {
        loading ? <GoalsLoading/>
          : (
            <>
              <h4 className={classNames(sBase.title, s.title)}>{getTitle("actualSavingsChart")}
                <span onClick={() => history.push(paths.companyGoals)}/>
              </h4>
              <div className={s.cartsWrapper}>
                <div className={s.chart}>
                  <Chart
                    colors={COLORS}
                    width={"99%"}
                    height={"100%"}
                    chartType="LineChart"
                    data={[["x", "Committed", "Forecasted", "Actual"], ...result]}
                    options={{
                      series: {
                        0: {color: "#63A5A1"},
                        1: {color: "#F4A261"},
                        2: {color: "#5B79B4"},
                        3: {color: "#FB787A"},
                      },
                      chartArea: {
                        height: "100%",
                        width: "100%",
                        top: 10,
                        left: 100,
                        right: 10,
                        bottom: 40,
                      },
                      vAxis: {
                        textStyle: {
                          color: "rgba(0, 0, 0, 0.55)",
                          fontSize: 12,
                          fontName: "Roboto",
                        },
                        format: "$#,###"
                      },
                      lineWidth: 3,
                      pointSize: 7,
                      legend: {position: "bottom", alignment: "end"},
                      tooltip: {isHtml: true},
                    }}
                    className={s.googleChart}
                  />
                </div>
              </div>
            </>
          )
      }
    </section>
  );
};

export default ActualSavingsChart;