import React from "react";
import PasswordChangeContainer from "components/containers/PasswordChangeContainer/PasswordChangeContainer";
import AuthenticationLayout from "components/layouts/AuthenticationLayout/AuthenticationLayout";

const PasswordChangePage = () => {
  return (
    <AuthenticationLayout>
      <PasswordChangeContainer />
    </AuthenticationLayout>
  );
};

export default PasswordChangePage;
