import React from "react";
import {useHistory} from "react-router-dom";
import MainTable from "../MainTable/MainTable";
import styles from "./AdminSettingsProjectsTypesTable.module.scss";

const AdminSettingsProjectsTypesTable = (
  {
    data = [],
    onEdit,
    onAdd,
    onDelete
  }
) => {
  const history = useHistory();

  return (
    <MainTable
      inlineWrapClassName={styles.inlineWrap}
      stickyHeader={true}
      totalName="Project types"
      data={data}
      dataTypes={[
        {
          title: "Type",
          value: ({name}) => name || "-",
          onClick: id => history.push(`/admin-settings/project-types/${id}`)
        },
        {
          title: "Load, %",
          value: ({load}) => load || "-"
        },
        {
          title: "Group of activities",
          value: ({project_group}) => project_group?.name || "-"
        },
        {
          title: "Required tasks",
          value: ({required_tasks_count}) => required_tasks_count
        }
      ]}
      button={{
        name: "New type",
        onClick: onAdd
      }}
      options={item => {
        return [
          onEdit && {
            title: "Edit",
            onClick: () => onEdit(item)
          },
          onDelete && {
            title: "Delete",
            onClick: () => onDelete(item)
          }
        ];
      }}
      totalCount={data.length}
    />
  );
};

export default AdminSettingsProjectsTypesTable;
