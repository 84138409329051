import React, {Fragment, useState} from "react";
import s from "./CreateProjectContainer.module.scss";
import EmptyState from "./components/EmptyState/EmptyState";
import {ReactComponent as EditIcon} from "assets/icons/edit-pencil.svg";
import DetailItem from "./components/DetailItem/DetailItem";
import Button from "components/elements/Button/Button";
import MainTable from "components/tables/MainTable/MainTable";
import {ReactComponent as AddIcon} from "assets/icons/plus-gray.svg";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import Popup from "components/popups/Popup/Popup";
import AddProjectSupplierForm from "components/forms/AddProjectSupplierForm/AddProjectSupplierForm";
import services from "services";
import {debounce} from "lodash";
import AddContractForm from "./forms/AddContractForm/AddContractForm";
import AddUserForm from "./forms/AddUserForm/AddUserForm";

import {
  deleteGlobalProject,
  addGlobalProjectSupplier,
  setGlobalProjectMessage,
  addGlobalProjectContract,
  addGlobalProjectUser,
  deleteGlobalProjectContract,
  deleteGlobalProjectSupplier,
  deleteGlobalProjectUser,
} from "actions/globalProject";
import {formatCurrency} from "helpers/currencyFormat";
import {fromDate} from "helpers/dateFormat";
import PropTypes from "prop-types";
import {paths} from "../../../../../routes/paths";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import {userTypes} from "../../../../../helpers/userPermissions/userRoles";

const CreateProjectContainer = ({onMouseEnter, onMouseLeave}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const project = useSelector(state => state.globalProject.data);
  const {user_type} = useSelector(state => state.user);
  const {support} = userTypes;
  const [popup, setPopup] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const handleDeleteProject = () => {
    dispatch(deleteGlobalProject());
    setPopup(null);
  };

  const handleDeleteContract = id => {
    dispatch(deleteGlobalProjectContract(id));
    dispatch(
      setGlobalProjectMessage({
        type: "success",
        text: "Contract was successfully deleted."
      })
    );
  };

  const handleDeleteSupplier = id => {
    dispatch(deleteGlobalProjectSupplier(id));
    dispatch(
      setGlobalProjectMessage({
        type: "success",
        text: "Supplier was successfully deleted."
      })
    );
  };

  const handleDeleteUser = id => {
    dispatch(deleteGlobalProjectUser(id));
    dispatch(
      setGlobalProjectMessage({
        type: "success",
        text: "User was successfully deleted."
      })
    );
  };

  const handleAddContract = ({contract_id}) => {
    services.contractsServices
      .getContract(contract_id)
      .then(res => {
        dispatch(addGlobalProjectContract(res.data));
        setPopup(null);
        dispatch(
          setGlobalProjectMessage({
            type: "success",
            text: "Contract was added to the project. You can edit it here"
          })
        );
      })
      .catch(err => {
        setPopup(null);
        dispatch(
          setGlobalProjectMessage({
            type: "error",
            text: err.response.data.error
          })
        );
      });
  };

  const handleAddSupplier = id => {
    services.supplierServices
      .getSupplier(id)
      .then(res => {
        dispatch(addGlobalProjectSupplier(res.data));
        setPopup(null);
        dispatch(
          setGlobalProjectMessage({
            type: "success",
            text: "Supplier was added to the project. You can edit it here"
          })
        );
      })
      .catch(err => {
        setPopup(null);
        dispatch(
          setGlobalProjectMessage({
            type: "error",
            text: err.response.data.error
          })
        );
      });
  };

  const handleAddUser = ({user_id}) => {
    services.userServices
      .getUser(user_id)
      .then(res => {
        dispatch(addGlobalProjectUser(res.data));
        setPopup(null);
        dispatch(
          setGlobalProjectMessage({
            type: "success",
            text: "User was added to the project. You can edit it here"
          })
        );
      })
      .catch(err => {
        setPopup(null);
        dispatch(
          setGlobalProjectMessage({
            type: "error",
            text: err.response.data.error
          })
        );
      });
  };

  const handleSaveProject = () => {
    const formatProject = {
      category_id: project?.category?.id,
      owner_id: project?.owner?.id,
      name: project.name,
      project_type_id: project?.project_type?.id,
      budget_spend: project.budget_value || 0,
      budget_spend_currency: project.budget_currency?.label,
      target_spend: project?.target_spend || 0,
      target_spend_currency: project?.target_spend_currency?.label,
      start_date: project?.start_date,
      end_date: project?.end_date,
      supplier_ids: project?.suppliers.map(e => e.id).join(","),
      contract_ids: project?.contracts.map(e => e.id).join(","),
      stakeholder_ids: project?.users.map(e => e.id).join(","),
      department_ids: project?.departments?.map(e => e.id).join(",")
    };
    services.projectServices
      .createAdvancedProject(formatProject)
      .then(res => {
        dispatch(
          setGlobalProjectMessage({
            type: "success",
            text: "Your project successfully created."
          })
        );
        dispatch(deleteGlobalProject());
        setPopup(null);
        history.push(`/projects/${res.data.id}`);
      })
      .catch(err => {
        dispatch(
          setGlobalProjectMessage({
            type: "error",
            text: err.response.data.error
          })
        );
      });
  };

  const searchSuppliersHandler = debounce(term => {
    services.supplierServices
      .suppliersAutocomplete({query: term})
      .then(res => setSuppliers(res.data));
  }, 300);

  return (
    <div
      className={s.createProjectContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={s.wrap}>
        {project ? (
          <Fragment>
            <header className={s.header}>
              <span className={s.name}>{project.name}</span>
              <EditIcon
                className={s.editBtn}
                onClick={() => history.push(paths.advancedProjectEdit)}
              />
            </header>
            <ul className={s.projectDetail}>
              <DetailItem
                className={s.item}
                label="Category"
                value={project?.category?.name ?? "-"}
              />
              <DetailItem
                className={s.item}
                label="Type"
                value={project?.project_type?.label || "-"}
              />
              <DetailItem
                className={s.item}
                label="Budget"
                value={
                  project.budget_value && project.budget_currency?.value
                    ? formatCurrency(
                      project.budget_currency?.value,
                      Number(project.budget_value)
                    )
                    : null
                }
              />
              <DetailItem
                className={s.item}
                label="Target Spend"
                value={
                  project.target_spend && project.target_spend_currency?.value
                    ? formatCurrency(
                      project.target_spend_currency?.value,
                      Number(project.target_spend)
                    )
                    : null
                }
              />
              <DetailItem
                className={s.item}
                label="Start date"
                value={project.start_date ? fromDate(project.start_date) : null}
              />
              <DetailItem
                className={s.item}
                label="End date"
                value={project.end_date ? fromDate(project.end_date) : null}
              />
              <DetailItem
                className={s.item}
                label="Project owner"
                value={project?.owner_id?.name ?? "-"}
              />
            </ul>

            <div className={s.tableWrap}>
              <MainTable
                totalName="Suppliers"
                icon={{
                  icon: <AddIcon/>,
                  onClick: () => setPopup("add_supplier")
                }}
                data={project.suppliers}
                className={s.table}
                dataTypes={[
                  {
                    title: "Supplier",
                    value: ({name}) => name,
                    onClick: id => history.push(`/suppliers/${id}`)
                  },
                  {
                    title: "Spend (USD)",
                    value: ({spend}) =>
                      spend ? formatCurrency("$", Number(spend)) : "-"
                  }
                ]}
                options={item => {
                  return [
                    {
                      title: "Delete",
                      onClick: () => {
                        handleDeleteSupplier(item.id);
                      }
                    }
                  ];
                }}
                totalCount={project.suppliers.length}
              />
              {!project.suppliers.length && (
                <span className={s.noData} data-translate-key={getTranslateKey("You don’t have suppliers yet.")}>
                  {t(getTranslateKey("You don’t have suppliers yet."))}
                </span>
              )}
            </div>

            <div className={s.tableWrap}>
              <MainTable
                totalName="Contracts"
                icon={{
                  icon: <AddIcon/>,
                  onClick: () => setPopup("add_contract")
                }}
                data={project.contracts}
                className={s.table}
                dataTypes={[
                  {
                    title: "Contract name",
                    value: ({name}) => name,
                    onClick: id => history.push(`/contracts/${id}`)
                  },
                  {
                    title: "Annual contract value (USD)",
                    value: ({annual_value}) =>
                      annual_value
                        ? formatCurrency("$", Number(annual_value))
                        : "-"
                  }
                ]}
                options={item => {
                  return [
                    {
                      title: "Delete",
                      onClick: () => {
                        handleDeleteContract(item.id);
                      }
                    }
                  ];
                }}
                totalCount={project.contracts.length}
              />
              {!project.contracts.length && (
                <span className={s.noData} data-translate-key={getTranslateKey("You don’t have contracts yet.")}>
                  {t(getTranslateKey("You don’t have contracts yet."))}
                </span>
              )}
            </div>
            <div className={s.tableWrap}>
              <MainTable
                totalName="Users"
                icon={{
                  icon: <AddIcon/>,
                  onClick: () => setPopup("add_user")
                }}
                data={project.users}
                className={s.table}
                dataTypes={[
                  {
                    title: "User name",
                    value: ({name}) => name,
                    onClick: id => history.push(`/users/${id}`)
                  },
                  {
                    title: "User title",
                    value: ({title}) => title
                  }
                ]}
                options={item => {
                  return [
                    {
                      title: "Delete",
                      onClick: () => {
                        handleDeleteUser(item.id);
                      }
                    }
                  ];
                }}
                totalCount={project.users.length}
              />
              {!project.users.length && (
                <span className={s.noData} data-translate-key={getTranslateKey("You don’t have users yet.")}>
                  {t(getTranslateKey("You don’t have users yet."))}
                </span>
              )}
            </div>
          </Fragment>
        ) : (
          user_type !== support && <EmptyState/>
        )}
      </div>
      {project && (
        <div className={s.btns}>
          <Button
            className={s.btn}
            styled="secondary"
            onClick={() => setPopup("delete_project")}
          >
            Delete
          </Button>
          <Button
            className={s.btn}
            styled="primary"
            onClick={() => handleSaveProject()}
          >
            Create
          </Button>
        </div>
      )}
      {popup === "delete_project" && (
        <Popup
          title="Delete project"
          text="Are you sure you want to delete this project?"
          onClose={() => setPopup(null)}
          onClick={handleDeleteProject}
          isDelete
          dialog
        />
      )}
      {popup === "add_supplier" && (
        <Popup
          onClose={() => setPopup(null)}
          title="Add supplier"
          text="Choose a supplier from existing ones"
        >
          <AddProjectSupplierForm
            suppliers={suppliers}
            currentSuppliers={project.suppliers}
            addHandler={handleAddSupplier}
            onClose={() => setPopup(null)}
            onSearch={searchSuppliersHandler}
          />
        </Popup>
      )}
      {popup === "add_contract" && (
        <Popup
          onClose={() => setPopup(null)}
          title="Add contract"
          text="Choose a contract from existing ones"
        >
          <AddContractForm
            currentContracts={project.contracts}
            addHandler={handleAddContract}
            onClose={() => setPopup(null)}
          />
        </Popup>
      )}
      {popup === "add_user" && (
        <Popup
          onClose={() => setPopup(null)}
          title="Add user"
          text="Choose a user from existing ones"
        >
          <AddUserForm
            currentUsers={project.users}
            addHandler={handleAddUser}
            onClose={() => setPopup(null)}
          />
        </Popup>
      )}
    </div>
  );
};

export default CreateProjectContainer;

CreateProjectContainer.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};