import React, {useState} from "react";
import s from "./Announcements.module.scss";
import moment from "moment";
import DropListTrigger from "../../../../../elements/DropListTrigger/DropListTrigger";
import PropTypes from "prop-types";
import {userRoles} from "../../../../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";

const Announcement = ({announcement, handlePopUp}) => {
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);
  const user = useSelector(state => state.user);

  const isDepartmentAndCEO = user.roles.includes(userRoles.cpo) ||
    user.roles.includes(userRoles.director) ||
    user.roles.includes(userRoles.admin);

  let contextMenu = null;

  if (isDepartmentAndCEO) {
    contextMenu = (
      <DropListTrigger
        data={[
          {
            title: "Edit",
            onClick: () => handlePopUp({isOpen: true, type: "edit", announcement})
          },
          {
            title: "Delete",
            onClick: () => handlePopUp({isOpen: true, type: "delete", announcement})
          }
        ]}
        visibleMenu={visibleDropListMenu}
        setVisibleMenu={setVisibleDropListMenu}
      />
    );
  }

  return (
    <div className={s.announcement}>
      <div>
        <div className={s.message}>{announcement.body}</div>
        <span className={s.date}>{moment(announcement.updated_at).format("h:mm:ss a, MM/DD/YYYY")}</span>
      </div>
      {contextMenu}
    </div>
  );
};

export default Announcement;

Announcement.propTypes = {
  announcement: PropTypes.any,
  handlePopUp: PropTypes.func,
};