export const SET_ALERT = "SET_ALERT"
export const SET_FILTERS = "SET_FILTERS"
export const SET_BOARD_LOADING = "SET_BOARD_LOADING"
export const SET_PIE_LOADING = "SET_PIE_LOADING"
export const SET_PROJECTS = "SET_PROJECTS"
export const SET_POPUP_NAME = "SET_POPUP_NAME"
export const SET_PIE_CHART_DATA = "SET_PIE_CHART_DATA"
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE"
export const SET_CURRENT_TAB = "SET_CURRENT_TAB"
export const CLOSE_POPUP = "CLOSE_POPUP"
export const SET_TOTAL_PROJECTS_COUNT = "SET_TOTAL_PROJECTS_COUNT"
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT"
export const SET_SELECTED_PROJECT_ID = "SET_SELECTED_PROJECT_ID"
export const SET_REQUEST_DATA = "SET_REQUEST_DATA"
export const SET_STAGES = "SET_STAGES"

export const initialState = {
  visiblePopupName: "",
  projects: [],
  totalProjectsCount: 0,
  selectedProjectId: "",
  pieLoading: true,
  boardLoading: true,
  filters: [],
  alert: { message: "", type: "" },
  pieChartWidgetData: {
    one_time_ca: { completed: 0, not_completed: 0 },
    one_time_er: { completed: 0, not_completed: 0 },
    run_rate_ca: { completed: 0, not_completed: 0 },
    run_rate_er: { completed: 0, not_completed: 0 },
    total: { completed: 0, not_completed: 0 }
  },
  currentPage: 1,
  currentTab: "Board",
  projectStages: []
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BOARD_LOADING: {
      return {
        ...state,
        boardLoading: payload
      }
    }
    case SET_PIE_LOADING: {
      return {
        ...state,
        pieLoading: payload
      }
    }

    case SET_REQUEST_DATA: {
      return {
        ...state,
        projects: payload.projects,
        pieChartWidgetData: Array.isArray(payload.year_impact)
          ? initialState.pieChartWidgetData
          : payload.year_impact,
        pieLoading: payload.loading,
        boardLoading: payload.loading,
        totalProjectsCount: payload.projectsTotalCount
      }
    }

    case SET_ALERT: {
      return {
        ...state,
        alert: {
          message: payload.message,
          type: payload.type
        }
      }
    }

    case SET_FILTERS: {
      return {
        ...state,
        filters: payload.filters
      }
    }

    case SET_PROJECTS: {
      return {
        ...state,
        projects: payload.projects
      }
    }

    case ADD_NEW_PROJECT: {
      return {
        ...state,
        projects: [...state.projects, payload.newProject]
      }
    }

    case SET_POPUP_NAME: {
      return {
        ...state,
        visiblePopupName: payload.name
      }
    }

    case CLOSE_POPUP: {
      return {
        ...state,
        visiblePopupName: "",
        selectedProjectId: ""
      }
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload.page || 1
      }
    }

    case SET_CURRENT_TAB: {
      return {
        ...state,
        currentTab: payload.tab || "Board",
        currentPage: 1
      }
    }

    case SET_TOTAL_PROJECTS_COUNT: {
      return {
        ...state,
        totalProjectsCount: payload.projectsCount
      }
    }

    case SET_SELECTED_PROJECT_ID: {
      return {
        ...state,
        selectedProjectId: payload.projectId
      }
    }

    case SET_PIE_CHART_DATA: {
      return {
        ...state,
        pieChartWidgetData: payload.pieChartData
      }
    }

    case SET_STAGES: {
      return {
        ...state,
        stages: payload || []
      }
    }

    default:
      throw Error("Unknown action type")
  }
}
