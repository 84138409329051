export const searchCategories = (value, array) => {
  let result = [];
  array.forEach((item) => {
    let node = { ...item };
    let child;
    if (node.name.toLowerCase().search(value.toLowerCase()) > -1) {
      delete node.opened;
      result.push(node);
    } else {
      if (node.categories) {
        child = searchCategories(value, node.categories);
        if (child.length) {
          node.categories = child;
          node.opened = true;
          result.push(node);
        }
      }
    }
  });
  return result;
};
export const openedCategories = (categories, currentId) => {
  let result = [];
  categories.forEach((item) => {
    let node = { ...item };
    let child;
    if (node.id === currentId) {
      result.push(node);
    } else {
      if (node.categories) {
        child = openedCategories(node.categories, currentId);
        if (child.length) {
          node.categories = child;
          if (
            child.filter((itm) => itm.id === currentId || itm.opened).length
          ) {
            node.opened = true;
          }
        }
      }
      result.push(node);
    }
  });
  return result;
};

export const formatCategories = (categories) => {
  const formattedCategories = [];
  if (Array.isArray(categories)) {
    categories.forEach((category) => {
      formattedCategories.push(category);

      if (category.categories?.length > 0) {
        formattedCategories.push(...formatCategories(category.categories));
      }
    });
  }
  return formattedCategories;
};
