import React from "react";
import styles from "./Stakeholders.module.scss";
import {ReactComponent as RedirectIcon} from "../../../../../assets/icons/redirect-icon.svg";
import PropTypes from "prop-types";
import {useHistory, useParams} from "react-router-dom";
import Avatar from "../../../../elements/Avatar/Avatar";
import DetailLoading from "components/loadingStates/Detail/Detail";
import {trimRest} from "../../../../../helpers/stringHelper";

const Stakeholders = ({stakeholders, loading, isHighRule}) => {
  const history = useHistory();
  const {projectId, milestoneId, taskId} = useParams();

  let stakeholderList = (
    <div className={styles.emptyList}>
      <p>You don’t have stakeholders yet.</p>
    </div>
  );

  if (loading) return (
    <div className={styles.detailLoading}>
      <DetailLoading length={3}/>
    </div>
  );

  if (stakeholders?.length) {
    stakeholderList = (
      <div className={styles.stakeholdersList}>
        {stakeholders.map((stakeholder, index) => (
          <div className={styles.singleStakeholder} key={index}>
            <Avatar name={stakeholder.stakeholder.name} size="large"/>
            <div className={styles.info}>
              <p title={stakeholder.stakeholder.name}>
                {trimRest(20, stakeholder.stakeholder.name)}
              </p>
              <p title={stakeholder.role}>
                {trimRest(20, stakeholder.role)}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.stakeholdersWrap}>
      <header>
        <h3>Stakeholders <span>({stakeholders.length})</span></h3>
        <RedirectIcon
          onClick={() =>
            history.push(
              `/projects/${projectId}/milestones/${milestoneId}/tasks/${taskId}/stakeholders?permissions=${isHighRule}`
            )}
        />
      </header>
      {stakeholderList}
    </div>
  );
};

export default Stakeholders;

Stakeholders.propTypes = {
  stakeholders: PropTypes.any,
  loading: PropTypes.bool,
  isHighRule: PropTypes.bool
};