import React from 'react'
import DefaultLayout from 'components/layouts/DefaultLayout/DefaultLayout'
import CompanyGoalsContainer from 'components/containers/CompanyGoalsContainer/CompanyGoalsContainer'

const CompanyGoalsPage = () => {
    return <DefaultLayout>
        <CompanyGoalsContainer />
    </DefaultLayout>
}

export default CompanyGoalsPage