import React from "react";
import CustomLink from "components/elements/CustomLink/CustomLink";
import s from "./ScheduleADemo.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const ScheduleADemo = () => {
  const {t} = useTranslation();
  return (
    <div className={s.scheduleADemo}>
      <div className={s.landingContainer}>
        <h2 className={s.title} data-translate-key={"schedule-a-demo"}> {t(getTranslateKey("schedule-a-demo"))}</h2>

        <p className={s.text} data-translate-key={"schedule-a-live-demo-with-one-of-our-product-specialists-to-see-how-focal-point-suits-your-needs."}>
          {t(getTranslateKey("schedule-a-live-demo-with-one-of-our-product-specialists-to-see-how-focal-point-suits-your-needs."))}
        </p>

        <CustomLink className={s.buttonLink} type="nav-link" path="/contact-us" data-translate-key={"get-a-demo"}>
          {t(getTranslateKey("get-a-demo"))}
        </CustomLink>
      </div>
    </div>
  );
};

export default ScheduleADemo;
