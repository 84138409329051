import React from "react";
import Popup from "components/popups/Popup/Popup";

const renderText = (project) => {
  switch (typeof project.linked_projects) {
    case "string":
      return `This project is linked to the ${project.linked_projects} project. Are you sure you want to delete it?`;
    case "number":
      return `This project is linked to ${project.linked_projects} other projects. Are you sure you want to delete it?`;
    default:
      return "Are you sure you want to delete this project?"
  }
};

const DeleteProjectPopup = ({project, onClose, onClick, isDisableDeleteBtn}) => {
  const text = renderText(project);

  return (
    <Popup
      title="Delete project"
      text={text}
      onClose={onClose}
      onClick={onClick}
      isDisableSuccess={isDisableDeleteBtn}
      dialog
      isDelete
    />
  );
};

export default DeleteProjectPopup;
