import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import querystring from "query-string"
import SystemReportsSuppliersTable from "components/tables/SystemReportsSuppliersTable/SystemReportsSuppliersTable"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import services from "services"
import TableLoading from "components/loadingStates/Table/Table"
import s from "./SystemReportsSuppliers.module.scss"
import SystemReportsSuppliersFilter from "components/filters/SystemReportsSuppliersFilter/SystemReportsSuppliersFilter"
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar"
import {
  formatFilters
} from "components/elements/FilterBar/helpers/helpers"
const SystemReportsSuppliers = () => {
  const history = useHistory()
  const [suppliers, setSuppliers] = useState({
    suppliers: [],
    suppliers_count: 0,
    per_page: 30
  })
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState([])

  const query = querystring.parse(history.location.search)

  useEffect(() => {
    services.supplierServices.getSuppliers(query).then(res => {
      setSuppliers(res.data)
      setLoading(false)
    })
  }, [history.location])
  useEffect(() => {
    services.reportServices.getSuppliersReportsFilters().then(res => {
      const formattedStatuses = res.data?.statuses?.map((status, index) => ({
        id: status,
        name:
          status.charAt(0).toUpperCase() + status.slice(1).replace("_", " "),
        subItems: null
      }))
      const formattedOwners = res.data?.owners.map(e =>
        e.none
          ? {
              id: e.none?.toLowerCase(),
              name: e.none[0]?.toUpperCase() + e.none?.slice(1)
            }
          : e
      )
      setFilters(
        formatFilters({
          categories: res.data?.categories || [],
          owners: formattedOwners || [],
          status: formattedStatuses || []
        })
      )
    })
  }, [])
  return (
    <div>
      {!Array.isArray(filters) && Object.keys(filters).length ? (
        <SystemReportsSuppliersFilter filters={filters} className={s.filter} />
      ) : (
        <FilterBarLoading className={s.filter} />
      )}
      {loading && <TableLoading length={7} />}
      {!loading && Boolean(suppliers.suppliers_count) && (
        <SystemReportsSuppliersTable data={suppliers} />
      )}
      {!loading && !Boolean(suppliers.suppliers_count) && (
        <EmptyBlock withBtn={false}>
          <div>You don’t have suppliers.</div>
        </EmptyBlock>
      )}
    </div>
  )
}

export default SystemReportsSuppliers
