import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import SuppliersContainer from "components/containers/SuppliersContainer/SuppliersContainer";

const SuppliersPage = () => {
  return (
    <DefaultLayout withRightSidebar={true}>
      <SuppliersContainer />
    </DefaultLayout>
  );
};

export default SuppliersPage;
