import React from "react";
import Popup from "components/popups/Popup/Popup";
import AdminSettingsNonFinancialSavingsForm
  from "components/forms/AdminSettingsNonFinancialSavingsForm/AdminSettingsNonFinancialSavingsForm";
import PropTypes from "prop-types";
import TableWrapper from "../../../../../../../wrappers/TableWrapper/TableWrapper";

const AddNonFinancialSavingsTypePopup = ({onClose, onSubmit}) => {
  return (
    <Popup
      onClose={onClose}
      title="New non-financial savings type"
      text="Please, complete the following fields to create a new savings type"
    >
      <AdminSettingsNonFinancialSavingsForm
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Popup>
  );
};

export default AddNonFinancialSavingsTypePopup;

AddNonFinancialSavingsTypePopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}