import React, {useEffect, useState} from "react";
import s from "./ProjectActualSavingsMonthNotesForm.module.scss";
import Attachments from "../AddMilestoneTaskForm/components/Attachments/Attachments";
import Textarea from "../../elements/Textarea/Textarea";
import {useFormik} from "formik";
import {object, string} from "yup";
import Button from "../../elements/Button/Button";
import {useHistory} from "react-router-dom";
import services from "../../../services";
import NotesHistoryItem from "./NotesHistoryItem";
import NotificationBox from "../../elements/NotificationBox/NotificationBox";

const ProjectActualSavingsMonthNotesForm = ({idProject}) => {
  const history = useHistory();
  const NOTE_MAX_LENGTH = 300;
  const [actualSavings, setActualSavings] = useState([]);
  const [alert, setAlert] = useState({message: "", type: ""});
  const idSavings = history.location.state.from.id;

  const load = () => {
    services.projectServices.getProjectActualSavingsMonthNotes(idSavings)
      .then(res => {
        setActualSavings(res.data.notes);
      });
  };

  useEffect(() => {
    load();
  }, []);

  const errorAlert = {
    type: "error",
    message: "Something went wrong. Please, try again.",
  };

  const formik = useFormik({
    initialValues: {
      description: "",
      attachments: []
    },
    validationSchema: object({
      description: string().max(
        NOTE_MAX_LENGTH,
        `Please enter task note that consists of no more than ${NOTE_MAX_LENGTH} characters`
      ),

    }),
    onSubmit: ({description, attachments}) => {
      let formData = new FormData();
      formData = addAttachmentsToFormData(formData, attachments);
      formData.append("source_id", idSavings);
      formData.append("description", description);
      services.projectServices
        .addNoteToSavings(formData)
        .then(res => {
        if (res.data) load();
        setAlert({
          type: "success",
          message: "Notes history have been successfully recorded."
        });
      })
       .catch(() => setAlert(errorAlert));
      formik.resetForm();
    }
  });
  const {handleSubmit, touched, values, errors, setFieldValue} = formik;

  const addAttachmentsToFormData = (formData, files) => {
    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }
    return formData;
  };

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };

  const handleDeleteAttachment = (_, fileIndex) =>
    setFieldValue(
      "attachments",
      values.attachments.filter((_, idx) => idx !== fileIndex)
    );

  return (
    <form onSubmit={handleSubmit}>
      <div className={s.wrap}>
        <h2 className={s.wrapTitle}>Notes history</h2>

        {actualSavings && actualSavings.map((item) => <NotesHistoryItem item={item} key={item.id}/>)}

        <div className={s.wrapTextAttach}>
          <Textarea
            label=""
            placeholder="Enter a note"
            value={values.description}
            onChange={(e) => setFieldValue("description", e.target.value)}
            error={touched.description && errors.description}
            name="description"
            count={{
              current: values.description.length,
              max: NOTE_MAX_LENGTH
            }}
          />
          <div className={s.inputWrap}>
            <Attachments
              label=""
              onChange={handleChangeAttachment}
              onDelete={handleDeleteAttachment}
              attachments={values.attachments}
              filesMaxLength={3}
            />
          </div>

          <div className={s.popupBtnsWrap}>
            <Button
              styled="secondary"
              className="popupBtn"
              type="reset"
              onClick={() => history.push(`/projects/${idProject}/actual_savings`)}
            >
              Cancel
            </Button>
            <Button styled="primary" type="submit" className="popupBtn">
              Send
            </Button>
          </div>
        </div>
      </div>
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </form>
  );
};

export default ProjectActualSavingsMonthNotesForm;