import React, {useEffect, useState} from "react";
import GanttChartLoading from "../../../../../../loadingStates/Dashboard/GanttChart/GanttChartLoading";
import ProjectsGanttChart from "../../../../../../elements/ProjectsGanttChart/ProjectsGanttChart";
import {months} from "../../../../../../elements/ProjectsGanttChart/helpers/helpData";
import services from "../../../../../../../services";
import PropTypes from "prop-types";

const GanttChart = ({id}) => {
  const [data, setData] = useState({
    projects: [],
    projects_count: 0,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    services.requestTypeServices.getGanttChart(id).then(res => {
      setData(res.data);
      setLoading(false);
    });
  }, []);


  if (loading) return <GanttChartLoading/>;

  if (data.projects.length) return (
    <ProjectsGanttChart
      data={data.projects}
      monthsList={months}
    />
  );

  return null;
};

export default GanttChart;

GanttChart.propTypes = {
  id: PropTypes.string,
};
