import React, {useState, useCallback, useEffect} from "react";
import classNames from "classnames";
import {useFormik} from "formik";
import {object, string} from "yup";
import PropTypes from "prop-types";
import {debounce} from "lodash";
import DatePicker from "components/elements/DatePicker/DatePicker";
import s from "./AddMilestoneTaskFormNew.module.scss";
import Input from "components/elements/Input/Input";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import {formatDate} from "helpers/dateFormat";
import {getStatusLabel} from "helpers/milestonesHelper";
import services from "services";
import Textarea from "components/elements/Textarea/Textarea";
import Divider from "../../elements/Divider/Divider";
import {backgroundTypes} from "../../../helpers/helpData";
import style from "../TaskForm/TaskForm.module.scss";
import Attachments from "../AddMilestoneTaskForm/components/Attachments/Attachments";

const AddMilestoneTaskForm = ({onSubmit, onClose, disabledFields}) => {
  const [users, setUsers] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const DESCRIPTION_MAX_LENGTH = 180;
  const HELP_MAX_LENGTH = 500;

  useEffect(() => {
    services.taskServices.getTaskStatuses().then(res => {
      if (res.data) {
        const statuses = res.data.task_statuses.map(status => {
          return {
            id: status.id,
            label: getStatusLabel(status.name, backgroundTypes[status.color]),
            value: status.id,
          }
        });
        setTaskStatuses(statuses);
        setFieldValue("status", statuses[0]);
      }
    })
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      help_text: "",
      responsible_user_id: {name: "", id: ""},
      status: {},
      start_date: new Date(),
      end_date: new Date(),
      description: "",
      attachments: []
    },
    validationSchema: object({
      name: string()
        .required("Name is required")
        .min(2, "Please enter task name that consists of no less than 2 characters")
        .max(32, "Please enter task name that consists of no more than 32 characters"),
      description: string().max(
        DESCRIPTION_MAX_LENGTH,
        `Please enter task note that consists of no more than ${DESCRIPTION_MAX_LENGTH} characters`
      ).required("Description is required"),
      help_text: string().max(
        HELP_MAX_LENGTH,
        `Please enter help text that consists of no more than ${HELP_MAX_LENGTH} characters`
      ),
      responsible_user_id: object({
        name: string().required("Please, enter user name"),
        id: string().required("Please select user from dropdown list")
      }),
      start_date: string()
        .required("Start date is required")
        .nullable(),
      end_date: string()
        .required("End date is required")
        .nullable(),
      status: object({
        value: string().required("Status is required")
      }),
    }),
    onSubmit: values => {
      onSubmit({
        name: values.name,
        responsible_user_id: values.responsible_user_id.id,
        task_status_id: values.status.value,
        start_date: formatDate(values.start_date),
        end_date: formatDate(values.end_date),
        description: values.description,
        attachments: values.attachments,
        help_text: values.help_text,
      });
    }
  });

  const {
    handleSubmit,
    touched,
    values,
    errors,
    setFieldValue,
    handleChange
  } = formik;

  const searchHandler = useCallback(
    debounce(term => {
      services.userServices
        .stakeholdersAutocomplete({query: term})
        .then(res => setUsers(res.data));
    }, 300),
    []
  );

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };

  const handleDeleteAttachment = (_, fileIndex) =>
    setFieldValue(
      "attachments",
      values.attachments.filter((_, idx) => idx !== fileIndex)
    );

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        disabled={disabledFields?.includes("name")}
        className={classNames(s.countField, {
          [s.countWrap]: errors.name && touched.name
        })}
        label="Task name"
        value={values.name}
        placeholder="Enter task name"
        onChange={e => {
          setFieldValue("name", e.target.value);
        }}
        error={touched.name && errors.name}
        limitValue={32}
        withCounter
      />
      <div
        className={classNames(s.countField, {
          [s.countWrap]: errors.description && touched.description
        })}
      >
        <Textarea
          disabled={disabledFields?.includes("description")}
          label="Description"
          value={values.description}
          error={
            errors.description && touched.description && errors.description
          }
          onChange={handleChange}
          name="description"
          placeholder="Enter description"
          count={{
            current: values.description.length,
            max: DESCRIPTION_MAX_LENGTH
          }}
        />
      </div>
      <div
        className={classNames(s.countField, {
          [s.countWrap]: errors.description && touched.description
        })}
      >
        <Textarea
          className={style.textarea}
          value={values.help_text}
          label="Help"
          onChange={e => setFieldValue("help_text", e.target.value)}
          placeholder="Enter help text"
          error={errors.help_text}
          count={{
            current: values.help_text.length,
            max: HELP_MAX_LENGTH
          }}
        />
      </div>
      <div className={s.inputWrap}>
        <Attachments
          onChange={handleChangeAttachment}
          onDelete={handleDeleteAttachment}
          attachments={values.attachments}
          titleDropFilesToAttach={true}
        />
      </div>
      <Divider margin={20}/>
      <div className={s.inputWrap}>
        <AutoCompleteInput
          data={users}
          placeholder={"Choose user"}
          value={values.responsible_user_id.name}
          onChange={e => {
            setFieldValue("responsible_user_id", {
              id: "",
              name: e.target.value
            });
            searchHandler(e.target.value);
          }}
          onSelect={value => {
            setFieldValue("responsible_user_id", {
              id: value.id,
              name: value.name
            });
          }}
          label="Responsible User"
          error={
            errors.responsible_user_id &&
            touched.responsible_user_id &&
            (errors.responsible_user_id.name || errors.responsible_user_id.id)
          }
        />
      </div>
      <div className={s.inputWrap}>
        <CustomDropdown
          options={taskStatuses}
          value={values.status}
          onChange={option => setFieldValue("status", option)}
          label="Status"
          menuClasses={s.options}
          error={errors.status && touched.status && errors.status.value}
        />
      </div>
      <div className="dateBlock">
        <DatePicker
          label="Start date"
          selected={values.start_date}
          onChange={date => {
            setFieldValue("start_date", date);
            date > values.end_date && setFieldValue("end_date", date);
          }}
          selectsStart
          startDate={values.start_date}
          endDate={values.end_date}
          placeholderText="Choose start date"
          wrapperClassName="wrapperdatePicker"
          className="customInput"
          todayButton="Go to today"
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.start_date && errors.start_date}
        />
        <DatePicker
          label="End date"
          selected={values.end_date}
          onChange={date => setFieldValue("end_date", date)}
          selectsEnd
          startDate={values.start_date}
          endDate={values.end_date}
          minDate={values.start_date}
          placeholderText="Choose end date"
          wrapperClassName="wrapperdatePicker"
          className="customInput"
          todayButton="Go to today"
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={touched.end_date && errors.end_date}
        />
      </div>
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Create
        </Button>
      </div>
    </form>
  );
};

AddMilestoneTaskForm.propTypes = {
  users: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  disabledFields: PropTypes.array,
};

AddMilestoneTaskForm.defaultProps = {
  users: [],
  onClose: () => {
  },
  onSubmit: () => {
  }
};

export default AddMilestoneTaskForm;
