import React from "react";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import AdditionalQuestions from "./AdditionalQuestions/AdditionalQuestions";
import {useTranslation} from "react-i18next";

const AdminSettingsAdditionalQuestionsContainer = () => {
  const {t} = useTranslation();
  return (
    <div className={s.wrap}>
      <h2 className={s.title} data-translate-key="additional-questions">{t("additional-questions")}</h2>
      <AdditionalQuestions/>
    </div>
  );
};
export default AdminSettingsAdditionalQuestionsContainer;
