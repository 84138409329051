import { getRowsPos } from "../helpers/getRowsPos";
import { linesConfig } from "../helpers/defaultConfig";

export const drawBeforeXLines = (data) => {
  let beforeLines = [];
  getRowsPos(data).map(({ top, left, colIdx }) => {
    const Y = top + linesConfig.horizontalLineMarginY;
    const X2 = left - linesConfig.horizontalLineMarginX;
    const prevColName = Object.keys(data)?.[colIdx - 1];
    const prevColProcessRowsLength = data[prevColName]?.filter(
      (e) =>
        e.status !== "Pending" &&
        e.status !== "Rejected" &&
        e.status !== "Not required"
    ).length;
    colIdx &&
      prevColProcessRowsLength &&
      beforeLines.push({
        x1: X2 - linesConfig.horizontalLineWidth,
        y1: Y,
        x2: X2,
        y2: Y,
      });
  });
  return beforeLines;
};
