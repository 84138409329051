import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Checkbox from "../../../../elements/Checkbox/Checkbox";
import Button from "components/elements/Button/Button";
import styles from "../Details/Details.module.scss";
import s from "./Answers.module.scss";
import { ReactComponent as Deleted } from "assets/icons/deleted.svg";
import classNames from "classnames";
import { Answer } from "../Answer/Answer";

export const Answers = ({ quest, setTempQuestions, tempQuestions }) => {
  const [unsavedAnswers, setUnsavedAnswers] = useState([]);

  const formik = useFormik({
    initialValues: {
      sameScore: false,
    },
    onSubmit: (values, actions) => {
      setTimeout(() => {
        actions.setSubmitting(false);
      }, 1000);
    },
  });
  const { handleSubmit, setFieldValue, values, isSubmitting } = formik;

  useEffect(() => {
    setUnsavedAnswers(quest.answers || []);
  }, [quest]);

  useEffect(() => {
    if (values.sameScore && unsavedAnswers.every((answ) => answ?.score)) {
      !unsavedAnswers.every((answ) => answ?.score === unsavedAnswers[0]?.score) &&
        setFieldValue("sameScore", false);
    }
  }, [unsavedAnswers]);

  useEffect(() => {
    if (!isSubmitting && unsavedAnswers.length) {
      setTempQuestions((prevQuests) =>
        prevQuests.map((prevQuest) =>
          prevQuest.pos === quest.pos
            ? {
                ...prevQuest,
                answers: unsavedAnswers,
              }
            : prevQuest,
        ),
      );
    }
  }, [isSubmitting]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit} className={s.formCont}>
      <div className={s.formCont}>
        {unsavedAnswers.length > 0 && (
          <div className={s.answCont}>
            <div className={s.firstDiv}>If answer is</div>
            <div>Then (Optional)</div>
            <div />
            <div>Enter score</div>
          </div>
        )}
        <div
          style={{
            maxHeight: "75px",
            overflowY: unsavedAnswers.length > 2 ? "scroll" : "none",
          }}>
          {unsavedAnswers.map((answ) => (
            <Answer
              answ={answ}
              setUnsavedAnswers={setUnsavedAnswers}
              isSubmitting={isSubmitting}
              sameScore={values.sameScore}
              unsavedAnswers={unsavedAnswers}
              tempQuestions={tempQuestions}
              quest={quest}
              setTempQuestions={setTempQuestions}
            />
          ))}
        </div>
        <label
          className={s.addNew}
          onClick={() =>
            setUnsavedAnswers((prevArr) => [
              ...prevArr,
              { pos: quest?.answers.length + unsavedAnswers.length + 1 },
            ])
          }>
          + Add new answer
        </label>
        <div
          className={classNames(styles.lineCont, {
            [styles.noAnsw]: !unsavedAnswers.length,
          })}
        />
        <div className={styles.bottomCont}>
          <div className={styles.checkCont}>
            <Checkbox label="Optional question" withTranslate />
            <div className={styles.lineCont2} />
            <Checkbox
              value={values.sameScore}
              checked={values.sameScore}
              onChange={(e) =>
                unsavedAnswers.length && setFieldValue("sameScore", e.target.checked)
              }
              label="Select the same score for all answers"
              withTranslate
            />
            <div className={styles.lineCont2} />
            <Deleted />
          </div>
          <div>
            <Button styled="secondary" type="reset" className="popupBtn" onClick={() => {}}>
              Cancel
            </Button>
            <Button styled="primary" type="submit" className="popupBtn">
              Save
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
