import React from "react";
import AdminSettingsUsersContainer from "../../containers/AdminSettingsUsersContainer/AdminSettingsUsersContainer";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

const AdminSettingsUsersPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        { name: "Admin Settings", path: "", disabled: true } ,
        { name: "Users", path: "" },
      ]}
    >
      <AdminSettingsUsersContainer/>
    </DefaultLayout>
  )

};
export default AdminSettingsUsersPage;
