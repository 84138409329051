import React, {useEffect, useState} from "react";
import ProjectsBoard from "components/elements/ProjectsBoard/ProjectsBoard";
import {stagesStyles} from "./boardsData";
import {formatDate} from "helpers/dateFormat";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import services from "../../../../../services";

const BoardTab = (
  {
    projects,
    onDelete,
    onDrop,
    stages,
  }
) => {
  const history = useHistory();
  const [customStages, setCustomStages] = useState(null);

  const {grouped_by} = qs.parse(
    history.location.search
  );

  useEffect(() => {
    if (grouped_by) {
      services.customStatusesServices.getOptions(grouped_by).then(res => {
        if (res.data) {
          setCustomStages(res.data);
        }
      });
    } else {
      setCustomStages(null);
    }
  }, [history.location.search]);

  const getStageStyles = stageId => {
    if (stageId === stages?.[0]?.id) {
      return stagesStyles.planned;
    } else if (stageId === stages?.[stages.length - 1]?.id) {
      return stagesStyles.completed;
    }
    return stagesStyles.active;
  };

  const formatProjects = projectsArray => {
    return projectsArray.map(project => ({
      id: project.id,
      name: project.name,
      subTitle: {
        owner: project.owner.name,
        category: project.category.name
      },
      stage: project.stage,
      selected_option: project.custom_options?.find(selector => selector.custom_selector === grouped_by),
      body: {
        start: formatDate(project.start_date, true),
        end: formatDate(project.end_date, true),
        onDelete: id => {
          onDelete(id);
        },
        onEdit: id => {
          history.push(`/edit/projects/${id}`);
        },
        last_action: project.last_action,
        savings: project.savings || {value: 0, currency: "USD"},
        actual_end_date: project.actual_end_date,
        uid: project.uid,
      },
      status: getStageStyles(project.stage.id).status
    }));
  };

  const formatColumns = projectsArray => {
    const copiedArray = [];
    const projects = formatProjects(projectsArray);

    if (customStages) {
      customStages.forEach(stage => {
        copiedArray.push({
          id: stage.id,
          title: stage.name,
          cards: projects.filter(e => e.selected_option?.custom_selector_option === stage.id),
          cardStyle: {
            border: "1px solid #D6D6D6",
          },
        });
      });
      copiedArray.push({
        id: "unassigned",
        title: "Unassigned",
        cards: projects.filter(e => !e.selected_option?.custom_selector_option),
        cardStyle: {
          border: "1px solid #D6D6D6",
        },
      });
    } else {
      if (stages) {
        stages.forEach(stage => {
          copiedArray.push({
            id: stage.id,
            title: stage.name,
            cards: projects.filter(e => {
              if (grouped_by === "unassigned") return (e.stage.id === stage.id && e.selected_option?.length === 0);
              return e.stage.id === stage.id;
            }),
            cardStyle: getStageStyles(stage.id).cardStyle
          });
        });
      }
    }

    return copiedArray;
  };

  return (
    <div>
      <ProjectsBoard
        onDelete={onDelete}
        data={formatColumns(projects)}
        onDrop={onDrop}
      />
    </div>
  );
};

export default BoardTab;

BoardTab.propTypes = {
  projects: PropTypes.array,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onDrop: PropTypes.func,
  stages: PropTypes.array,
};