import React from "react";
import Popup from "components/popups/Popup/Popup";
import ResetUserPasswordForm from "components/forms/ResetUserPasswordForm/ResetUserPasswordForm";

const ResetPassPopup = ({ onClose, onSubmit }) => {
  return (
    <Popup title="Reset password" text="Change user password" onClose={onClose}>
      <ResetUserPasswordForm onClose={onClose} onSubmit={onSubmit} />
    </Popup>
  );
};

export default ResetPassPopup;
