import React, {useContext} from "react";
import Button from "../../../elements/Button/Button";
import style from "./FormHeader.module.scss";
import {useHistory} from "react-router-dom";
import {ModeContext} from "../../../../contexts/ModeContext/ModeContext";
import {mods} from "../../../../contexts/ModeContext/mods";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const FormHeader = ({isEdit, reset}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const {mode, enableEditMode} = useContext(ModeContext);

  const renderHeaderTitle = () => isEdit ? history.location.state?.approvalName ?? "Edit approval step" : "New approval step";

  const renderButtonsGroup = () => {
    if (isEdit) {
      if (mode === mods.view) return (
        <Button styled='primary' onClick={enableEditMode}>
          Edit
        </Button>
      );
      return (
        <div className={style.btnGroup}>
          <Button styled='secondary' onClick={reset}>
            Cancel
          </Button>
          <Button styled='primary' type="submit">
            Save
          </Button>
        </div>
      )
    }
    return (
      <div className={style.btnGroup}>
        <Button styled='secondary' onClick={() => history.goBack()}>
          Cancel
        </Button>
        <Button styled='primary' type="submit">
          Create
        </Button>
      </div>
    );
  }

  return (
    <div className={style.header}>
      <p data-translate-key={getTranslateKey(renderHeaderTitle())}>
        {t(getTranslateKey(renderHeaderTitle()))}
      </p>
      {renderButtonsGroup()}
    </div>
  );
};

export default FormHeader;