import React from "react"
import styles from "./NPSScoreBarChart.module.scss"

const NPSScoreBarChart = ({ data }) => {
  const total = Object.values(data).reduce((acc, val) => (acc += val), 0)
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.valuesContainer}>
          <span className={styles.value}>
            <p>Detractors</p>
            <span className={styles.lineWrap}>
              <span
                style={{
                  "--color": "#DE4935",
                  "--percent": `${(data.detractors / total) * 100}%`
                }}
              ></span>
            </span>
          </span>
          <span className={styles.value}>
            <p>Passives</p>
            <span className={styles.lineWrap}>
              <span
                style={{
                  "--color": "#F2CD7B",
                  "--percent": `${(data.passives / total) * 100}%`
                }}
              ></span>
            </span>
          </span>
          <span className={styles.value}>
            <p>Promoters</p>
            <span className={styles.lineWrap}>
              <span
                style={{
                  "--color": "#83A755",
                  "--percent": `${(data.promoters / total) * 100}%`
                }}
              ></span>
            </span>
          </span>
        </div>
        <div className={styles.innerContainer}>
          <span className={styles.square} data-pct={"0"}></span>
          <span className={styles.square} data-pct={"20%"}></span>
          <span className={styles.square} data-pct={"40%"}></span>
          <span className={styles.square} data-pct={"60%"}></span>
          <span className={styles.square} data-pct={"80%"}></span>
        </div>
      </div>
    </div>
  )
}

export default NPSScoreBarChart
