import React, { useState, useReducer, useEffect } from "react"
import s from "./AdminSettingsProjectStageContainer.module.scss"
import classNames from "classnames"
import AdminSettingsProjectsStageTasksTable from "components/tables/AdminSettingsProjectsStageTasksTable/AdminSettingsProjectsStageTasksTable"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import TableLoading from "components/loadingStates/Table/Table"
import AddTaskPopup from "./popups/AddTaskPopup/AddTaskPopup"
import EditTaskPopup from "./popups/EditTaskPopup/EditTaskPopup"
import DeleteTaskPopup from "./popups/DeleteTaskPopup/DeleteTaskPopup"
import Skeleton from "@material-ui/lab/Skeleton"
import { initialState, reducer } from "./helpers/reducer"
import {
  getStage,
  changePopup,
  addTask,
  closeAlert,
  editTask,
  deleteTask
} from "./helpers/actions"
import { useParams } from "react-router-dom"
import NotificationBox from "components/elements/NotificationBox/NotificationBox"

const AdminSettingsProjectStageContainer = () => {
  const [currentTask, setCurrentTask] = useState(null)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { tasks, loading, alert, popup, name } = state
  const params = useParams()
  useEffect(() => {
    getStage(dispatch, params.id)
  }, [params.id])
  return (
    <div className={s.stageWrap}>
      {loading ? (
        <Skeleton
          variant='text'
          className={classNames("title", s.title)}
          animation='wave'
          width={200}
        />
      ) : (
        <h2 className={s.title}>{name || "-"}</h2>
      )}

      {!loading && tasks.length > 0 && (
        <AdminSettingsProjectsStageTasksTable
          data={tasks}
          onAdd={() => {
            dispatch(changePopup("add"))
          }}
          onDelete={task => {
            dispatch(changePopup("delete"))
            setCurrentTask(task)
          }}
          onEdit={task => {
            dispatch(changePopup("edit"))
            setCurrentTask(task)
          }}
        />
      )}
      {!loading && !tasks.length && (
        <EmptyBlock
          subtitle='No work item results found'
          buttonTitle='New task'
          onClick={() => dispatch(changePopup("add"))}
        />
      )}
      {loading && <TableLoading length={2} />}
      {popup === "add" && (
        <AddTaskPopup
          onClose={() => dispatch(changePopup(null))}
          onSubmit={({ name }) => addTask(dispatch, params.id, { name })}
        />
      )}
      {popup === "edit" && (
        <EditTaskPopup
          onClose={() => dispatch(changePopup(null))}
          onSubmit={({ name }) =>
            editTask(dispatch, params.id, currentTask?.id, { name })
          }
          defaultValues={{ name: currentTask.name }}
        />
      )}
      {popup === "delete" && (
        <DeleteTaskPopup
          onClose={() => dispatch(changePopup(null))}
          onSubmit={() => deleteTask(dispatch, params.id, currentTask?.id)}
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => dispatch(closeAlert())}
        />
      )}
    </div>
  )
}

export default AdminSettingsProjectStageContainer
