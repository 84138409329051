import axios from "./axios";

export default {
  getActivities: async () => {
    try {
      const result = await axios.get("/dashboard/activities");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getLastActivities: async () => {
    try {
      const result = await axios.get("/dashboard/last_activities");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getNotifications: async () => {
    try {
      const result = await axios.get("/dashboard/notifications");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getGoals: async params => {
    try {
      return await axios.get("/dashboard/goal", {params});
    } catch (error) {
      throw error;
    }
  },
  getGoalsActualSavings: async params => {
    try {
      return await axios.get("/dashboard/goal", {params});
    } catch (error) {
      throw error;
    }
  },
  getAnnouncements: async () => {
    try {
      const result = await axios.get("/dashboard/announcements");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getFilters: async () => {
    try {
      const result = await axios.get("/dashboard_filters");
      return result;
    } catch (error) {
      throw error;
    }
  },
  deleteAnnouncements: async id => {
    try {
      return await axios.delete(`/dashboard/announcements/${id}`);
    } catch (error) {
      throw error;
    }
  },
  addAnnouncements: async value => {
    try {
      return await axios.post("/dashboard/announcements", {body: value});
    } catch (error) {
      throw error;
    }
  },
  updateAnnouncements: async (id, value) => {
    try {
      return await axios.put(`/dashboard/announcements/${id}`, {body: value});
    } catch (error) {
      throw error;
    }
  },
  getTasks: async () => {
    try {
      const result = await axios.get("/dashboard/tasks");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getIndividualLeaderboard: async () => {
    try {
      const result = await axios.get("/dashboard/individual_leader_board");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getTeamLeaderboard: async () => {
    try {
      const result = await axios.get("/dashboard/team_leader_board");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getStatistic: async params => {
    try {
      return await axios.get("/dashboard/statistic", {params});
    } catch (error) {
      throw error;
    }
  },
  getStatisticAboutProjects: async () => {
    try {
      const result = await axios.get("/dashboard/projects_statistic");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getGanttChart: async (mode) => {
    try {
      const result = await axios.get(`/dashboard/gantt_chart?mode=${mode}`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategories: async () => {
    try {
      const result = await axios.get("/dashboard/categories");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getRecentRequests: async () => {
    try {
      const result = await axios.get("/dashboard/recent_requests");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getNotificationByType: async (id, type) => {
    try {
      return await axios.get(`/dashboard/${type}_notifications?${type}_id=${id}`);
    } catch (error) {
      throw error;
    }
  },
  getSuppliers: async () => {
    try {
      const result = await axios.get("/dashboard/suppliers");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getContracts: async () => {
    try {
      const result = await axios.get("/dashboard/contracts");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getMessages: async () => {
    try {
      const result = await axios.get("/dashboard/messages");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getSavings: async () => {
    try {
      const result = await axios.get("/dashboard/savings");
      return result;
    } catch (error) {
      throw error;
    }
  },
  getQuicksightURLs: async () => {
    try {
      const result = await axios.get("/dashboard/quicksight_urls");
      return result;
    } catch (error) {
      throw error;
    }
  },
};
