import { config } from "./defaultConfig";
import { height } from "./getWidgetSize";

export const getRowsPos = (data) => {
  const items = [];
  Object.keys(data).map((colName, colIdx) => {
    data[colName].map((item, rowIdx) => {
      const top = config.colHeight * rowIdx + config.rowMargin * rowIdx;
      const verticalAlignment =
        (height(data) -
          config.innerMarginBottom -
          config.headerHeight -
          (data[colName].length * config.colHeight +
            (data[colName].length - 1) * config.rowMargin)) /
        2;
      items.push({
        ...item,
        index: `${colIdx}${rowIdx}`,
        colIdx,
        rowIdx,
        left:
          config.colWidth * colIdx +
          config.colMargin * colIdx +
          config.innerMarginLeft,
        top: verticalAlignment + top + config.headerHeight,
      });
    });
  });
  return items;
};
