import React from "react";
import CheckedLine from "../../../../components/CheckedLine/CheckedLine";
import s from "./CRMSection.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const CRMSection = () => {
  const {t} = useTranslation();
  return (
    <section className={s.crmSection}>
      <div className={s.landingContainer}>

        <div className={s.wrapper}>

          <div className={s.content}>
            <h2 className={s.subTitle} data-translate-key={"category-risk-management"}>
              {t(getTranslateKey("category-risk-management"))}
            </h2>

            <p className={s.text}
               data-translate-key={"make-certain-that-risk-is-managed-appropriately,-not-simply-with-an-individual-supplier-perspective,-but-ensuring-that-risk-is-managed-across-category-suppliers."}>
              {t(getTranslateKey("make-certain-that-risk-is-managed-appropriately,-not-simply-with-an-individual-supplier-perspective,-but-ensuring-that-risk-is-managed-across-category-suppliers."))}
            </p>

            <CheckedLine item="Identify and eliminate single points of supply chain failure"/>

            <CheckedLine item="Manage individual supplier risks and know where to move volumes in case of failure"/>

            <CheckedLine
              item="Know that you have collected and evaluated all relevant supplier and category artifacts"/>
          </div>

          <div className={s.imgBlock}></div>

        </div>
      </div>
    </section>
  );
};

export default CRMSection;
