import React, { useState, useEffect, Fragment } from "react";
import s from "./History.module.scss";
import classNames from "classnames";
import querystring from "query-string";
import services from "services";
import { useParams, useHistory } from "react-router-dom";
import ProjectHistoryTable from "components/tables/ProjectHistoryTable/ProjectHistoryTable";
import { CircularProgress } from "@material-ui/core";
import TableLoading from "components/loadingStates/Table/Table";

const History = () => {
  const params = useParams();
  const history = useHistory();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const currentPage = querystring.parse(history.location.search).page || 1;
  const query = querystring.parse(history.location.search) || {};

  useEffect(() => {
    services.projectServices
      .getProjectsHistory(params.id, query)
      .then((res) => {
        setData(res.data.project_histories);
        setTotal(res.data.project_histories_count);
        setLoading(false);
      });
  }, [params.id, history.location]);

  const onPageChange = (page) => {
    history.push({ search: querystring.stringify({ ...query, page }) });
  };
  const onSort = (sort) => {
    history.push({
      search: querystring.stringify({ ...query, ...sort }),
    });
  };

  return (
    <Fragment>
      {!loading ? (
        <ProjectHistoryTable
          data={data}
          totalCount={total}
          onPageChange={onPageChange}
          currentPage={currentPage}
          onSort={onSort}
        />
      ) : (
        <TableLoading length={3} />
      )}
    </Fragment>
  );
};
export default History;
