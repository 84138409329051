import React, {useState} from "react";
import MainTable from "../MainTable/MainTable";
import PropTypes from "prop-types";
import {dateFormatterWithoutTimezone} from "../../../helpers/dateFormat";
import {ReactComponent as NoteIcon} from "../../../assets/icons/note.svg";
import {useHistory, useParams} from "react-router-dom";
import Status from "../../elements/Status/Status";
import {useSelector} from "react-redux";
import Popup from "../../popups/Popup/Popup";
import ApproverForm from "../../forms/ApproverForm/ApproverForm";
import s from "./ApproversTable.module.scss";

const ApproversTable = ({data, sendAnswer, approvalGroupType}) => {
  const user = useSelector(store => store.user);
  const [currentApprover, setCurrentApprover] = useState(null);
  const history = useHistory();
  const params = useParams();

  const renderContextMenu = item => {
    const {status, approver} = item;

    const editBtn = [{
      title: "Edit",
      onClick: () => setCurrentApprover(item),
    }];

    const defaultCondition = user.id === approver.id && status !== "not_requested" && status !== "approved";

    const sortedApprovers = data.sort((a, b) => a.level - b.level);

    const position = sortedApprovers.map(approver => approver.approver.id).indexOf(approver.id);

    const canMakeAnswer = sortedApprovers
      .slice(0, position)
      .every(approver => approver.status === "approved");

    if (approvalGroupType === "sequential") {
      if (defaultCondition && canMakeAnswer) return editBtn;
    } else {
      if (defaultCondition) return editBtn;
    }
  }

  return (
    <>
      <MainTable
        data={data}
        dataTypes={[
          {
            title: "Approver",
            value: ({approver}) => approver.name,
          },
          {
            title: "Status",
            value: ({status}) => <Status status={status} withTranslate/>
          },
          {
            title: "Date",
            value: ({status_changed_at}) => status_changed_at ? dateFormatterWithoutTimezone(status_changed_at) : "-"
          },
          {
            title: "",
            position: "end",
            value: ({id, notes}) => {
              if (!notes.length) return <div className={s.note}>-</div>;
              return (
                <div className={s.note}>
                  <NoteIcon onClick={() => history.push(
                    `/projects/${params.projectId}/milestones/${params.milestoneId}/approvals/${params.id}/approver/${id}/history`,
                  )}/>
                </div>
              );
            }
          }
        ]}
        options={item => renderContextMenu(item)}
        useMaxHeight={true}
      />
      {
        currentApprover && (
          <Popup
            onClose={() => setCurrentApprover(null)}
            title="Approval step"
          >
            <ApproverForm
              onClose={() => setCurrentApprover(null)}
              initialStatus={currentApprover.status}
              onSubmit={values => {
                sendAnswer(currentApprover.id, values);
                setCurrentApprover(null);
              }}
            />
          </Popup>
        )
      }
    </>
  );
};

export default ApproversTable;

ApproversTable.propTypes = {
  data: PropTypes.any,
};