import axios from "./axios";

export default {
	getFinancialDetails: async (id) => {
		try {
			const result = await axios.get(`/projects/${id}/financial_details`);
			return result;
		} catch (error) {
			throw error;
		}
	},
	addFinancialDetail: async (id, params) => {
		try {
			const result = await axios.post(`/projects/${id}/financial_details`, params);
			return result;
		} catch (error) {
			throw error;
		}
	},
	editFinancialDetail: async (project_id, id, params) => {
		try {
			const result = await axios.put(`/projects/${project_id}/financial_details/${id}`, params);
			return result;
		} catch (error) {
			throw error;
		}
	},
	deleteFinancialDetail: async (project_id, id) => {
		try {
			const result = await axios.delete(`/projects/${project_id}/financial_details/${id}`);
			return result;
		} catch (error) {
			throw error;
		}
	}
};
