import React from "react";
import PropTypes from "prop-types";
import Social from "../../../../components/Social/Social";
import CustomLink from "components/elements/CustomLink/CustomLink";
import Divider from "../../../../components/Divider/Divider";

import s from "./BlogCard.module.scss";
import {useTranslate} from "../../../../../../hooks/useTranslate";

const BlogCard = ({ id, path, img, article }) => {
  const { pic, name, date, title, descr } = article;
  const [translateLabelKey, translateLabelValue] = useTranslate(title);
  const [translateLabelKeyDescr, translateLabelValueDescr] = useTranslate(descr);
  const [translateLabelKeyDate, translateLabelValueDate] = useTranslate(date);
  return (
    <div className={s.blogCard}>
      <div className={s.imgBlock}>
        <CustomLink path={path}>
          <img src={img} alt="/" />
        </CustomLink>
      </div>

      <div className={s.contentBlock}>
        <div className={s.authorBlock}>
          <img src={pic} alt="/" className={s.pic} />
          <div className={s.authorDescr}>
            <h4 className={s.name}>{name}</h4>
            <p className={s.date} data-translate-key={translateLabelKeyDate}>{translateLabelValueDate}</p>
          </div>
        </div>

        <CustomLink path={path}>
          <h1 className={s.title} data-translate-key={translateLabelKey}>{translateLabelValue}</h1>
        </CustomLink>

        <p className={s.descr} data-translate-key={translateLabelKeyDescr}>{translateLabelValueDescr}</p>

        <Divider />

        <Social />
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  id: PropTypes.string,
  path: PropTypes.string,
  img: PropTypes.any,
  article: PropTypes.object,
};

BlogCard.defaultProps = {
  id: "art01",
  path: "/article/art01",
  img: "",
  article: {},
};

export default BlogCard;
