import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {routes} from "routes";
import {StylesProvider} from "@material-ui/core/styles";
import PrivateRoute from "helpers/PrivateRoute";
import NotFoundPage from "components/pages/NotFoundPage/NotFoundPage";
import TranslatePopUp from "./components/popups/TranslatePopUp/TranslatePopUp";
import GlobalContextTranslateMenu from "./components/elements/GlobalContextMenu/GlobalContextTranslateMenu";
import services from "./services";
import {useSelector} from "react-redux";
import {userRoles} from "./helpers/userPermissions/userRoles";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import Preloader from "./components/elements/Preloader/Preloader";
import {delay} from "./helpers/delay";

function App() {
  const url = window.location.pathname;
  const [translatePopUpValue, setTranslatePopUp] = useState(null);
  const [contextTranslateMenu, setContextTranslateMenu] = useState(null);
  const {roles, language, organisation} = useSelector(state => state.user);
  const [disabledSendTranslationBtn, setDisabledSendTranslationBtn] = useState(false);
  const {ready} = useTranslation();
  const isEnableTranslationMod = useSelector(state => state.globalSettings.isEnableTranslationMod);
  const isProcurementAdmin = roles?.includes(userRoles.procurement_admin);

  let lang = "";
  if (organisation?.language) lang = organisation.language;
  if (language) lang = language;

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang]);

  if (!url.includes("admin-settings") && !url.includes("categories") && !url.includes("reports")) {
    window.sessionStorage.removeItem("currentMenuTab");
  }

  const clickDocument = e => {
    if (Boolean(e.target.dataset?.translateKey)) {
      setContextTranslateMenu(!contextTranslateMenu ? {
        x: e.pageX,
        y: e.pageY,
        key: e.target.dataset?.translateKey,
      } : null)
    } else {
      setContextTranslateMenu(null);
    }
  }

  const reloadTranslate = () => {
    i18n.reloadResources([lang])
      .then(() => {
        delay(50).then(() => {
          setDisabledSendTranslationBtn(false);
          setTranslatePopUp(null);
        })
      })
  };

  const updateTranslate = (key, value) => {
    const prepareData = [{
      key,
      [lang]: value
    }];
    setDisabledSendTranslationBtn(true);
    services.translateService.updateTranslate(prepareData)
      .then(res => {
        if (res.status < 300) delay(50).then(() => reloadTranslate());
      });
  }

  if (!ready) return (
    <div>
      <Preloader/>
    </div>
  );

  return (
    <div
      className='App'
      onContextMenu={e => {
        if (isProcurementAdmin && isEnableTranslationMod && lang !== "en") {
          e.preventDefault();
          clickDocument(e);
        }
      }}
    >
      <StylesProvider>
        <Switch>
          {routes.map(
            ({Component, path, allowed, exact, allowedUserTypes}, i) => (
              <PrivateRoute
                path={path}
                allowed={allowed}
                allowedUserTypes={allowedUserTypes}
                exact={exact}
                Component={Component}
                key={i}
              />
            )
          )}
          <Route path='*' component={NotFoundPage}/>
        </Switch>
      </StylesProvider>
      {contextTranslateMenu && (
        <GlobalContextTranslateMenu
          x={contextTranslateMenu.x}
          y={contextTranslateMenu.y}
          click={() => setTranslatePopUp(contextTranslateMenu.key)}
          close={() => setContextTranslateMenu(null)}
        />
      )}
      {
        translatePopUpValue && (
          <TranslatePopUp
            disabledSendBtn={disabledSendTranslationBtn}
            onClose={() => setTranslatePopUp(false)}
            onSubmit={(key, value) => updateTranslate(key, value)}
            value={translatePopUpValue}
          />
        )
      }
    </div>
  );
}

export default App;
