import React from "react";
import PropTypes from "prop-types";
import styles from "./InfoBlock.module.scss";
import classNames from "classnames";

const InfoBlock = ({title, children, className, type}) => {
  return (
    <div className={classNames(styles.info, className, styles[type])}>
      <p className={styles.title}>
        {title}
      </p>
      {children}
    </div>
  );
};

export default InfoBlock;

InfoBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  type: PropTypes.string
};