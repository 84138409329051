import React from "react";
import {formatUserRoles} from "../../../helpers/userPermissions/formatUserRoles";
import MainTable from "../MainTable/MainTable";
import {fromDate} from "../../../helpers/dateFormat";
import {useHistory} from "react-router-dom";

const SupplierUsersTable = (
  {
    data,
    perPage,
    totalCount,
    onEdit,
    onAdd,
    onDelete,
    onResetPass,
    currentPage
  }
) => {
  const history = useHistory();
  return (
    <MainTable
      data={data}
      ariaLabel="Suppliers users"
      perPage={perPage}
      totalCount={totalCount}
      isPagination={true}
      page={currentPage}
      dataTypes={[
        {
          title: "Users",
          value: ({first_name}) => <a>{first_name}</a>,
          onClick: (id) => history.push(`/users/${id}`),
          sticky: true
        },
        {
          title: "Email",
          value: ({email}) => email,

        },
        {
          title: "Supplier",
          value: ({supplier}) => supplier.name,

        },
        {
          title: "Role",
          value: ({role}) =>
            role?.length > 0
              ? formatUserRoles(role)
                .map((e) => e.label)
                .join(", ")
              : "-",
          position: "center"
        },
        {
          title: "Last log in",
          value: ({last_sign_in_at}) =>
            last_sign_in_at ? fromDate(last_sign_in_at) : "-",

        },
      ]}
      options={item => {
        return [
          {
            title: "Edit",
            onClick: () => onEdit(item),
          },
          {
            title: "Delete",
            onClick: () => {
              onDelete(item.id);
            }
          },
          {
            title: "Reset password",
            onClick: () => {
              onResetPass(item.id);
            }
          },

        ];
      }}
      button={
        onAdd && {
          name: "New user",
          onClick: () => onAdd(),
        }
      }
    />
  );
};

export default SupplierUsersTable;