export const popupTypes = {
  edit: "EDIT",
  add: "ADD",
  delete: "DELETE"
}

export const SET_DETAILS = "SET_DETAILS"
export const SET_DETAILS_BY_YEAR = "SET_DETAILS_BY_YEAR"
export const ADD_DETAIL = "ADD_DETAIL"
export const DELETE_DETAIL = "DELETE_DETAIL"
export const EDIT_DETAIL = "EDIT_DETAIL"
export const SET_POPUP = "SET_POPUP"
export const RESET_ALERT = "RESET_ALERT"
export const CURRENT_DETAIL = "CURRENT_DETAIL"
export const SET_DEFAULT_CURRENCY = "SET_DEFAULT_CURRENCY"
export const SET_PERMISSIONS = "SET_PERMISSIONS"
export const RESET_ERROR = "RESET_ERROR"
export const SET_DETAIL_ATTACHMENT = "SET_DETAIL_ATTACHMENT"
