import React from "react";
import s from "../PrivacyPage.module.scss";

const Point02 = () => {
  return (
    <div id="point02" className={s.pointBlock}>
      <h3 className={s.subtitle}>2. HOW DO WE USE YOUR INFORMATION?</h3>

      <p className={s.text} style={{ fontStyle: "italic" }}>
        <span>In Short: </span>We process your information for purposes based on
        legitimate business interests, the fulfillment of our contract with you,
        compliance with our legal obligations, and/or your consent.
      </p>

      <p className={s.text}>
        We use personal information collected via our Website for a variety of
        business purposes described below. We process your personal information
        for these purposes in reliance on our legitimate business interests, in
        order to enter into or perform a contract with you, with your consent,
        and/or for compliance with our legal obligations. We indicate the
        specific processing grounds we rely on next to each purpose listed
        below.
      </p>

      <p className={s.text}>We use the information we collect or receive:</p>

      <p className={s.text}>
        <span>• To send administrative information to you. </span>We may use
        your personal information to send you product, service and new feature
        information and/or information about changes to our terms, conditions,
        and policies.
      </p>

      <p className={s.text}>
        <span>• To protect our Services. </span>We may use your information as
        part of our efforts to keep our Website safe and secure (for example,
        for fraud monitoring and prevention).
      </p>

      <p className={s.text}>
        <span>
          • To enforce our terms, conditions and policies for business purposes,
          to comply with legal and regulatory requirements or in connection with
          our contract.
        </span>
      </p>

      <p className={s.text}>
        <span>• To respond to legal requests and prevent harm. </span>If we
        receive a subpoena or other legal request, we may need to inspect the
        data we hold to determine how to respond.
      </p>

      <p className={s.text}>
        <span>• To send you marketing and promotional communications. </span>
        We and/or our third-party marketing partners may use the personal
        information you send to us for our marketing purposes, if this is in
        accordance with your marketing preferences. For example, when expressing
        an interest in obtaining information about us or our Website,
        subscribing to marketing or otherwise contacting us, we will collect
        personal information from you. You can opt-out of our marketing emails
        at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
      </p>

      <p className={s.text}>
        <span>• Deliver targeted advertising to you. </span>We may use your
        information to develop and display personalized content and advertising
        (and work with third parties who do so) tailored to your interests
        and/or location and to measure its effectiveness.
      </p>
    </div>
  );
};

export default Point02;
