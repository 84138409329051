import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import {useHistory, useParams} from "react-router-dom";
import ModeContextProvider from "../../../contexts/ModeContext/ModeContext";
import AdminSettingsApprovalDetailsContainer
  from "../../containers/AdminSettingsApprovalDetailsContainer/AdminSettingsApprovalDetailsContainer";
import {mods} from "../../../contexts/ModeContext/mods";

const AdminApprovalDetailsPage = () => {
  const history = useHistory();
  const params = useParams();

  let breadcrumbs = [
    {name: "Admin Settings", path: "", disabled: true},
    {name: "Projects", path: paths.adminSettingsProjects},
    {
      name: history.location.state?.projectTypeName ?? "Project type details",
      path: `/admin-settings/project-types/${params.projectTypeId}`,
    },
    {name: history.location.state?.approvalName, path: "",},
  ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <ModeContextProvider defaultMode={mods.view}>
        <AdminSettingsApprovalDetailsContainer/>
      </ModeContextProvider>
    </DefaultLayout>
  );
};

export default AdminApprovalDetailsPage;
