import React, { useState, useEffect, Fragment } from "react"
import s from "./FinancialBenefitsContainer.module.scss"
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout"
import PieChartWidget from "components/elements/PieChartWidget/PieChartWidget"
import { widgetTitlesData } from "./widgetTitlesData"
import services from "services"
import { useProjectsPath } from "hooks/useProjectsPath"

const FinancialBenefitsContainer = () => {
  const [data, setData] = useState(null)
  const projectsPath = useProjectsPath()
  useEffect(() => {
    services.projectServices.getReports().then(res => setData(res.data))
  }, [])
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: projectsPath,
          name: "Project - Fiscal year 2020"
        },
        {
          path: "/complete-savings",
          name: "Complete savings widget"
        }
      ]}
    >
      <div className={s.wrap}>
        <h2 className={s.title}>Financial benefit widgets</h2>
        {data && (
          <Fragment>
            {widgetTitlesData.map(({ label, value }, index) => (
              <PieChartWidget data={data[value]} title={label} key={index} />
            ))}
          </Fragment>
        )}
      </div>
    </DefaultLayout>
  )
}
export default FinancialBenefitsContainer
