export const config = {
  colWidth: 148,
  colHeight: 105,
  colMargin: 96,
  rowMargin: 16,
  headerHeight: 63,
  innerMarginLeft: 20,
  innerMarginBottom: 24,
};

export const linesConfig = {
  horizontalLineWidth: 40,
  horizontalLineMarginX: 16,
  horizontalLineMarginY: 53,
};
