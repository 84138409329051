import React from "react";
import classNames from "classnames";

export default (data, onSort, styles, history) => {
  const user = data.find(user => user.utilizations.utilization);
  const fields = [
    {
      title: "Users Name",
      value: ({name}) => name,
      onClick: id => history.push(`/users/${id}`),
      sortable: true,
      handleSort: ({direction}) => {
        onSort({sort_by: "name", direction});
      }
    },
    {
      title: "User Projects",
      value: ({utilizations}) => utilizations?.projects_count || "-",
      sortable: true,
      handleSort: ({direction}) => {
        onSort({sort_by: "projects_count", direction});
      }
    }
  ];
  if (user) {
    fields.push(
      ...user.utilizations.utilization.map(item => ({
        title: item.month,
        value: user => {
          const {value} = user.utilizations?.utilization?.find(
            itm => itm.month === item.month
          ) || {value: 0};
          return (
            <span
              className={classNames({
                [styles.normal]: value <= 100 && value >= 85,
                [styles.high]: value > 100
              })}
            >
              {value ? `${value}%` : "-"}
            </span>
          );
        }
      }))
    );
  }
  return fields;
}
