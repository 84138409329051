import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import styles from "./UserReportProjects.module.scss"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import TableLoading from "components/loadingStates/Table/Table"
import UserReportProjectsTable from "components/tables/UserReportProjectsTable/UserReportProjectsTable"
import UserReportProjectsActiveProjectsTable from "components/tables/UserReportProjectsActiveProjectsTable/UserReportProjectsActiveProjectsTable"
import services from "services/index"
import { formatProjectTypesData, formatProjectSavingsData } from "../../helpers"

const UserReportProjects = () => {
  const params = useParams()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    params.id &&
      services.reportServices.getUserReportProjects(params.id).then(res => {
        if (res.data?.project_types) {
          res.data.project_types = formatProjectTypesData(
            res.data.project_types
          )
          res.data.savings = formatProjectSavingsData(res.data.savings)
        }
        setData(res.data)
        setLoading(false)
      })
  }, [params.id])
  return (
    <>
      {loading ? (
        <TableLoading length={5} />
      ) : (
        <>
          <div className={styles.table}>
            {Boolean(data.project_types?.length) ? (
              <UserReportProjectsTable data={data.project_types} />
            ) : (
              <EmptyBlock
                title="User doesn't have projects yet."
                withBtn={false}
                message
              />
            )}
          </div>
          <div className={styles.table}>
            <h3 className={styles.title}>Active projects</h3>
            {Boolean(data.active_projects?.active_projects?.length) ? (
              <UserReportProjectsActiveProjectsTable
                data={data.active_projects?.active_projects}
              />
            ) : (
              <EmptyBlock
                title="User doesn't have active projects yet."
                withBtn={false}
                message
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default UserReportProjects
