import React from "react";
import {useHistory} from "react-router-dom";
import querystring from "query-string";
import Tabs from "components/elements/Tabs/Tabs";
import Tab from "components/elements/Tabs/Tab/Tab";
import SystemReportsSuppliers from "../SystemReportsSuppliers/SystemReportsSuppliers";
import SystemReportsProjects from "../SystemReportsProjects/SystemReportsProjects";
import SystemReportsUsers from "../SystemReportsUsers/SystemReportsUsers";
import SystemReportsDepartments from "../SystemReportsDepartments/SystemReportsDepartments";
import SystemReportsTags from "../SystemReportsTags/SystemReportsTags";
import SystemReportsTeam from "../SystemReportsTeam/SystemReportsTeam";

const SystemReportsTabs = () => {
  const history = useHistory();
  const {tab} = querystring.parse(history.location.search);

  const handleTabChange = (tab) => {
    history.push({search: querystring.stringify({tab})});
  };

  return (
    <Tabs handleClick={handleTabChange} defaultActiveTab={tab}>
      <Tab label="Suppliers">
        <SystemReportsSuppliers/>
      </Tab>
      <Tab label="Projects">
        <SystemReportsProjects/>
      </Tab>
      <Tab label="Users">
        <SystemReportsUsers/>
      </Tab>
      <Tab label="Departments">
        <SystemReportsDepartments/>
      </Tab>
      <Tab label="Tags">
        <SystemReportsTags/>
      </Tab>
      <Tab label="Teams">
        <SystemReportsTeam/>
      </Tab>
    </Tabs>
  );
};

export default SystemReportsTabs;
