import axios from "./axios";

export default {
  getNotes: async (id, page) => {
    try {
      return await axios.get(`/custom_notes?source_id=${id}&page=${page}`);
    } catch (error) {
      throw error;
    }
  },
  createNote: async (id, params) => {
    try {
      return await axios.post(`/custom_notes?source_id=${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  editNotes: async (sourceId, noteId, params, config) => {
    try {
      return await axios.put(`/custom_notes/${noteId}/?source_id=${sourceId}`, params, config);
    } catch (error) {
      throw error;
    }
  },
  deleteNote: async (sourceId, noteId) => {
    try {
      return await axios.delete(`/custom_notes/${noteId}/?source_id=${sourceId}`);
    } catch (error) {
      throw error;
    }
  },
};