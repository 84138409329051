import React, {useState, useEffect} from "react";
import styles from "./CategoriesProjects.module.scss";
import CategoriesProjectsStatusTable
  from "components/tables/CategoriesProjectsStatusTable/CategoriesProjectsStatusTable";
import services from "services";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const CategoriesProjects = ({categoryIdsFilter}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const history = useHistory();
  const query = qs.parse(
    history.location.search
  );

  useEffect(() => {
    services.categoriesServices.getCategoriesMainProjects({...query, category_ids: categoryIdsFilter}).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [history.location.search, categoryIdsFilter]);

  let newDate = [];
  if (data.projects) {
    newDate = [
      ...data.projects,
      {
        status: "Total:",
        total: "totalSum",
        ...data.projects.reduce(
          (accumulator, current) => ({
            ...accumulator,
            ...Object.keys(accumulator).reduce((obj, key) => ({...obj, [key]: accumulator[key] + current[key]}), {}),
          }), {
            baseline_spend: 0,
            benefits: 0,
            count: 0,
          }),
      },
    ];
  }

  return (
    <>
      <h3 className={styles.title} data-translate-key={getTranslateKey("Projects")}>
        {t(getTranslateKey("Projects"))}
      </h3>
      {loading && <TableLoading length={4}/>}
      {!loading && Boolean(data.projects?.length) && (
        <CategoriesProjectsStatusTable data={newDate}/>
      )}
      {!loading && !Boolean(data.projects?.length) && (
        <EmptyBlock title="No projects data for displaying." message/>
      )}
    </>
  );
};

export default CategoriesProjects;
