import React, { useEffect, useState } from "react";
import Input from "components/elements/Input/Input.jsx";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import s from "../Answers/Answers.module.scss";
import { useFormik } from "formik";
import { ReactComponent as Deleted } from "assets/icons/deleted.svg";

const types = [
  { label: "Skip to", value: "skipto" },
  { label: "Select a score level", value: "selectlevel" },
];

export const Answer = ({
  answ,
  setUnsavedAnswers,
  isSubmitting,
  sameScore,
  unsavedAnswers,
  tempQuestions,
  quest,
  setTempQuestions,
}) => {
  const [actionArr, setActionArr] = useState([]);

  const formik = useFormik({
    initialValues: {
      answer: answ?.answer || "",
      answer_type: answ?.answer_type || "",
      action: answ?.action || "",
      score: answ?.score || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isSubmitting) {
        const correctValues = {
          action: typeof values.action === "string" ? values.action : values.action.value,
          answer: values.answer,
          answer_type:
            typeof values.answer_type === "string" ? values.answer_type : values.answer_type.value,
          score: +values.score,
        };
        setUnsavedAnswers((prevAnsws) =>
          prevAnsws.map((pansw) =>
            pansw.pos === answ.pos ? { ...pansw, ...correctValues } : pansw,
          ),
        );
      }
    },
  });
  const { handleSubmit, values, setFieldValue } = formik;

  useEffect(() => {
    if (sameScore) {
      setUnsavedAnswers((prevAnsws) =>
        prevAnsws.map((pansw) =>
          pansw.pos === answ.pos ? { ...pansw, score: unsavedAnswers[0]?.score || "10" } : pansw,
        ),
      );
    }
  }, [sameScore]);

  useEffect(() => {
    if (isSubmitting) {
      handleSubmit();
    }
  }, [isSubmitting]);

  const formatArr = tempQuestions
    .filter((q) => q.pos > quest.pos)
    .map((q) => ({
      label: q.question,
      value: q.question?.toLowerCase().replace(" ", ""),
    }));

  return (
    <form autoComplete="off">
      <div className={s.answCont}>
        <Input
          className={s.firstDiv}
          name="answ"
          placeholder="Enter your answer"
          onChange={(e) => setFieldValue("answer", e.target.value)}
          value={values.answer}
        />
        <CustomDropdown
          className={s.dropDown}
          options={types}
          value={values.action}
          onChange={(option) => {
            setFieldValue("action", option);
            if (option.value === "skipto") {
              setActionArr(formatArr);
            } else {
              setActionArr([
                { label: "Low", value: "low" },
                { label: "Medium", value: "medium" },
                { label: "High", value: "high" },
                { label: "Extreme", value: "extreme" },
              ]);
            }
          }}
          placeholder="Choose type"
        />
        <CustomDropdown
          options={actionArr}
          value={values.answer_type}
          onChange={(option) => setFieldValue("answer_type", option)}
          placeholder="Choose type"
        />
        <Input
          name="score"
          placeholder="Enter your score"
          onChange={(e) => {
            setFieldValue("score", e.target.value);
            setUnsavedAnswers((prevAnsws) =>
              prevAnsws.map((pansw) =>
                pansw.pos === answ.pos ? { ...pansw, score: e.target.value } : pansw,
              ),
            );
          }}
          value={values.score}
        />
        <Deleted
          onClick={() =>
            setTempQuestions((p) =>
              p.map((pq) =>
                pq.pos === quest.pos || pq.id === quest.id
                  ? { ...pq, answers: pq.answers.filter((a) => a.id !== answ.id) }
                  : pq,
              ),
            )
          }
        />
      </div>
    </form>
  );
};
