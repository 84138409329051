import axios from "./axios";

export default {
  reorderTasks: async ids => {
    try {
      return await axios.put("admin/task_statuses/replace", {task_status_ids: ids});
    } catch (error) {
      throw error;
    }
  },

  getAdminTaskStatuses: async () => {
    try {
      return await axios.get("/admin/task_statuses");
    } catch (error) {
      throw error;
    }
  },

  addTaskStatus: async params => {
    try {
      return await axios.post("/admin/task_statuses", params);
    } catch (error) {
      throw error;
    }
  },

  editTaskStatus: async (id, params) => {
    try {
      return await axios.put(`/admin/task_statuses/${id}`, params);
    } catch (error) {
      throw error;
    }
  },

  deleteTaskStatus: async id => {
    try {
      return await axios.delete(`/admin/task_statuses/${id}`);
    } catch (error) {
      throw error;
    }
  },

};
