import React, {useEffect, useState} from 'react';
import {parserForSelect} from "../../../containers/AdminSettingsStepFieldDetailsContainer/helpers/parserForSelect";
import AutoCompleteInput from "../../AutoCompleteInput/AutoCompleteInput";
import services from "../../../../services";
import Input from "../../Input/Input";
import {recursiveJoin} from "../../../../helpers/recursiveJoin";
import {emptySelect} from "../../../../helpers/emptyData";

const DivisionsSelect = ({changeSingleCondition, defaultValue, isViewMode}) => {
  const [value, setValue] = useState("");
  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    services.userServices.getUsersFilters()
      .then(res => {
        setDivisions(res.data?.divisions || []);
      });
  }, []);

  useEffect(() => {
    if (defaultValue && defaultValue !== " ") {
      services.userServices.getUsersFilters()
        .then(res => {
          setValue(res.data?.divisions.find(i => i.id === defaultValue)?.name || "");
        });
    }
  }, [defaultValue]);

  if (isViewMode) return <Input value={value} withoutChange/>;

  return (
    <AutoCompleteInput
      label=""
      value={value}
      placeholder=""
      onChange={e => {
        changeSingleCondition(emptySelect);
        setValue(e.target.value);
      }}
      onSelect={value => {
        changeSingleCondition(parserForSelect(value));
      }}
      data={divisions}
    />
  );
};

export default DivisionsSelect;