import React, { useState } from "react";
import styles from "./BlockChart.module.scss";
import { BlockContainer } from "./BlockContainer";

export const BlockChart = ({ item, quater, index, data, quatersYears }) => {
  const [toolTip, setToolTip] = useState({ show: false, x: 0, y: 0, id: 0, action: "" });

  const currentItem = (id, index) => {
    return quatersYears ? null : Object.values(Object.values(Object.values(data)[id])[0][index])[0];
  };

  const currentYear = (id) => Object.keys(Object.values(data)[id]);

  const indexArr = quater
    ? !quatersYears
      ? [0, 1, 2, 3]
      : new Array(data.length - 1).fill(undefined).map((v, idx) => idx)
    : [];

  const lastIndex =
    quater && !quatersYears ? Object.values(Object.values(data)[0])[0].length - 1 : null;

  const activeSum =
    quater &&
    indexArr.map((el) =>
      Object.values(Object.values(data)[el])[0]
        .map((p) => Object.values(p)[0]?.active)
        .filter((item) => item !== undefined)
        .reduce((acc, el) => acc + el, 0),
    );

  const completedSum =
    quater &&
    indexArr.map((el) => Object.values(Object.values(data)[el])[0].at(-1).total - activeSum[el]);

  const currentCompleted = quater
    ? quater && lastIndex === index
      ? completedSum[toolTip.id]
      : currentItem(toolTip.id, index)?.completed
    : item[2][1];

  const currentActive = quater
    ? quater && lastIndex === index
      ? activeSum[toolTip.id]
      : currentItem(toolTip.id, index)?.active
    : item[1][1];

  const formatPercentYears = () => {
    if (quater && quatersYears) {
      if (toolTip.id === indexArr.length) {
        if (completedSum.reduce((a, e) => a + e) && !activeSum.reduce((a, e) => a + e)) {
          return 100;
        } else {
          return (
            (completedSum.reduce((a, e) => a + e) /
              [...completedSum, ...activeSum].reduce((a, e) => a + e)) *
            100
          ).toFixed(3);
        }
      } else if (completedSum[toolTip.id] && !activeSum[toolTip.id]) {
        return 100;
      } else {
        return (
          (completedSum[toolTip.id] / (completedSum[toolTip.id] + activeSum[toolTip.id])) *
          100
        ).toFixed(3);
      }
    }
  };

  const formatPercent = () => {
    if (quater) {
      if (quatersYears) {
        if (completedSum[toolTip.id] && !activeSum[toolTip.id]) {
          return 100;
        } else {
          return (
            (completedSum[toolTip.id] / (completedSum[toolTip.id] + activeSum[toolTip.id])) *
            100
          ).toFixed(3);
        }
      } else if (lastIndex === index) {
        if (currentCompleted && !activeSum[toolTip.id]) {
          return 100;
        } else {
          return (
            (currentCompleted / (completedSum[toolTip.id] + activeSum[toolTip.id])) *
            100
          ).toFixed(3);
        }
      } else {
        if (currentCompleted && !currentItem(toolTip.id, index)?.active) {
          return 100;
        } else {
          return ((currentCompleted / currentItem(toolTip.id, index)?.total) * 100).toFixed(3);
        }
      }
    } else {
      if (item[2][1] && !item[1][1]) {
        return 100;
      } else {
        return ((item[2][1] / (item[1][1] + item[2][1])) * 100).toFixed(3);
      }
    }
  };

  const percents = () => {
    if (quatersYears) {
      if (toolTip.action === "Completed") {
        return formatPercentYears();
      } else {
        return 100 - +formatPercentYears();
      }
    } else {
      if (toolTip.action === "Completed") {
        return formatPercent();
      } else {
        return 100 - +formatPercent();
      }
    }
  };

  const targetSum = () => {
    if (quatersYears) {
      if (toolTip.id === indexArr.length) {
        if (toolTip.action === "Completed") {
          return completedSum.reduce((a, e) => a + e, 0);
        } else {
          return activeSum.reduce((a, e) => a + e, 0);
        }
      } else {
        if (toolTip.action === "Completed") {
          return completedSum[toolTip.id];
        } else {
          return activeSum[toolTip.id];
        }
      }
    } else {
      if (toolTip.action === "Completed") {
        return currentCompleted;
      } else {
        return currentActive;
      }
    }
  };

  return (
    <>
      {toolTip.show && (
        <div
          className={styles.tooltip}
          style={{
            left: toolTip.x + 278,
            top: toolTip.y + 182,
          }}>
          <div>
            {quater
              ? quater && !quatersYears
                ? `${toolTip.action} for Q${toolTip.id + 1}`
                : toolTip.id === indexArr.length
                ? `${toolTip.action}`
                : `${toolTip.action} for ${currentYear(toolTip.id)}`
              : `${toolTip.action}`}
          </div>
          <div>
            <span>${(targetSum() / 1000000).toFixed(4)}M</span>
            <span> ({percents()}%)</span>
          </div>
        </div>
      )}
      <div style={{ zIndex: 1, paddingTop: "20px" }}>
        {!quater && !quatersYears && (
          <BlockContainer
            completed={item[2][1]}
            active={item[1][1]}
            total={item[2][1] + item[1][1]}
            el={0}
            setToolTip={setToolTip}
          />
        )}
        {quater && !quatersYears && lastIndex !== index && (
          <div className={styles.container}>
            {[0, 1, 2, 3].map((el) => (
              <BlockContainer
                completed={currentItem(el, index).completed}
                active={currentItem(el, index).active}
                total={currentItem(el, index).total}
                el={el}
                setToolTip={setToolTip}
                quater={true}
              />
            ))}
          </div>
        )}
        {quatersYears && (
          <div style={{ justifyContent: "center" }} className={styles.container}>
            {indexArr.map((el) => (
              <BlockContainer
                completed={completedSum[el]}
                active={activeSum[el]}
                total={completedSum[el] + activeSum[el]}
                el={el}
                setToolTip={setToolTip}
                quater={true}
              />
            ))}
            <BlockContainer
              completed={completedSum.reduce((a, e) => a + e)}
              active={activeSum.length}
              total={[...completedSum, ...activeSum].reduce((a, e) => a + e)}
              el={indexArr.length}
              setToolTip={setToolTip}
              quater={true}
            />
          </div>
        )}
        {lastIndex === index && !quatersYears && (
          <div className={styles.container}>
            {[0, 1, 2, 3].map((el) => (
              <BlockContainer
                completed={completedSum[el]}
                active={activeSum[el]}
                total={completedSum[el] + activeSum[el]}
                el={el}
                setToolTip={setToolTip}
                quater={true}
              />
            ))}
          </div>
        )}
        <div
          style={{
            width: !quater ? "40px" : "",
            display: quatersYears ? "flex" : "",
            justifyContent: quatersYears ? "center" : "",
          }}
          className={styles.title}>
          {quatersYears ? "Total" : quater ? Object.keys(item)[0] : item[0][0]}
        </div>
      </div>
    </>
  );
};
