import React from "react";
import s from './EmptyTab.module.scss';

const EmptyTab = ({message}) => {
  return (
    <div className={s.emptyTab}>
      {message}
    </div>
  )
}
export default EmptyTab
