import React from "react";
import MainTable from "../MainTable/MainTable";
import {backgroundTypes} from "../../../helpers/helpData";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import styles from "./FlagsTable.module.scss";

const FlagsTable = (
  {
    data,
    onAdd,
    onEdit,
    onDelete,
    perPage,
    totalCount,
    onPageChange,
    currentPage
  }
) => {

  const history = useHistory();

  return (
    <MainTable
      inlineWrapClassName={styles.inlineWrap}
      totalName="Total"
      totalCount={totalCount}
      data={data}
      dataTypes={[
        {
          title: "Name",
          value: ({name}) => name,
          onClick: id => history.push(`/system-reports/flags/${id}`, {from: "Admin Settings"})
        },
        {
          title: "Color",
          // eslint-disable-next-line react/prop-types,react/display-name
          value: ({color}) => <div
            style={{width: "64px", height: "22px", borderRadius: "6px", backgroundColor: backgroundTypes[color]}}/>
        },
        {
          title: "Flag Admins",
          value: ({flag_admins}) => {
            return flag_admins.length > 0
              ? flag_admins.map(({name}) => name).join(", ")
              : "-";
          },
          hint: true,
          max: 64
        }
      ]}
      options={item => {
        return [
          onEdit && {
            title: "Edit",
            onClick: () => {
              onEdit(item);
            }
          },
          onDelete && {
            title: "Delete",
            onClick: () => {
              onDelete(item);
            }
          }
        ];
      }}
      button={{
        name: "New flag",
        onClick: () => onAdd()
      }}
      perPage={perPage}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};
export default FlagsTable;

FlagsTable.propTypes = {
  data: PropTypes.any,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
};