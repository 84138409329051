import React from "react";
import s from "../AdditionalQuestions.module.scss";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import HeaderQuestion from "../HeaderQuestion/HeaderQuestion";

const RadioQuestion = ({answer}) => {
  return (
    <div className={s.question}>
      <HeaderQuestion questionName={answer.question.question_name} isRequired={answer.question.is_required}/>
      <div className={s.content}>
        <RadioGroup aria-label="radioGroup" name="radioGroup">
          {
            answer.question.answers?.length && answer.question.answers
              .map(qr =>
                <FormControlLabel
                  checked={answer.procurement_answer === qr.answer_id}
                  value={qr.answer_name || "Question text"}
                  key={qr.answer_id}
                  control={<Radio color="primary"/>}
                  label={qr.answer_name || "Question text"}
                />
              )
          }
        </RadioGroup>
      </div>
    </div>
  );
};
export default RadioQuestion;
