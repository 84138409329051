import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import ContractCreateContainer from "../../containers/ContractCreateContainer/ContractCreateContainer";

const ContractNewPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: "/contracts",
          name: "Contracts",
        },
        {
          name: 'New contract',
        },
      ]}
    >
      <ContractCreateContainer/>
    </DefaultLayout>
  );
};

export default ContractNewPage;
