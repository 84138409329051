import React from "react";
import s from "./Table.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";

const Table = ({ length = 0 }) => {
  const getColumns = (index) => {
    let data = [];
    for (let i = 0; i < length; i++) {
      data.push(
        <span
          className={s.inline}
          style={{
            width: length > 6 ? `${100 / length - 3}%` : `${100 / length}%`,
          }}
        >
          <Skeleton
            variant="text"
            className={s.text}
            animation="wave"
          />
        </span>
      );
    }
    return data;
  };
  return (
    <div className="skeletLoaderWrap">
      <Skeleton variant="text" animation="wave" width={200} />
      <ul className={classNames(s.rows, { [s.large]: length > 6 })}>
        <li>{getColumns(0)}</li>
        <li>{getColumns(1)}</li>
        <li>{getColumns(2)}</li>
        <li>{getColumns(3)}</li>
      </ul>
    </div>
  );
};

export default Table;
