import React from "react";
import PropTypes from 'prop-types'
import classNames from 'classnames'
import s from "./Step.module.scss";

const Step = ({ number, classLine }) => {
  return (
    <div className={s.step}>
      <div className={s.numberWrap}>
        <span className={s.number}>{number}</span>
      </div>
	  <span className={classNames(s.line, classLine)} />
    </div>
  );
};

Step.propTypes  = {
  number: PropTypes.string.isRequired,
  classLine: PropTypes.any
}

Step.defaultProps  = {
  number: "1",
  classLine: ""
}

export default Step;
