import React from "react";
import {trimRest} from "../../../helpers/stringHelper";
import MainTable from "../MainTable/MainTable";
import {paths} from "../../../routes/paths";
import {useHistory, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./CustomDropDownTable.module.scss";

const CustomDropDownTable = ({data, deleteDropDown, restoreDropDown, totalCount, perPage, onPageChange}) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <MainTable
      data={data}
      stickyHeader={true}
      totalName="Custom dropdowns"
      dataTypes={[
        {
          title: "Name",
          value: ({name}) => name,
          onClick: id => history.push(`/admin-settings/custom_dropdowns/${id}`),
        },
        {
          title: "Type",
          value: ({type}) => type === "single" ? "Single choice" : "Multiple choice",
        },
        {
          title: "Options",
          value: ({options}) => {
            if (options[0]?.name) {
              options = options.map(e => e.name);
            }
            return (
              <div title={options.join(",")}>{trimRest(32, options.join(","))}</div>
            );
          }
        },
        {
          title: "Status",
          value: ({status}) => <span className={styles.capitalize}>{status == "deleted" ? "archived" : status}</span>,
        }
      ]}
      options={item => [
        {
          title: "Edit",
          onClick: () => history.push(`/admin-settings/custom_dropdowns/${item.id}`),
        },
        item.status === "active" && {
          title: "Delete",
          onClick: () => deleteDropDown(item.id),
        },
        item.status === "deleted" && {
          title: "Restore",
          onClick: () => restoreDropDown(item.id),
        }
      ]}
      button={{
        name: "New Dropdown",
        onClick: () => history.push(paths.adminSettingsCustomDropDownsCreate)
      }}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={location.search.page || 1}
    />
  );
};

export default CustomDropDownTable;

CustomDropDownTable.propTypes = {
  data: PropTypes.any,
  deleteDropDown: PropTypes.func,
};