import axios from "./axios";

export default {
  getApprovalAdmin: async id => {
    try {
      return await axios.get(`/admin/approval_stages/${id}`);
    } catch (error) {
      throw error;
    }
  },

  getApprovalGroups: async () => {
    try {
      return await axios.get("/approval_groups")
    } catch (error) {
      throw error
    }
  },

  createApprovalAdmin: async params => {
    try {
      return await axios.post("/admin/approval_stages", params);
    } catch (error) {
      throw error;
    }
  },

  editApprovalAdmin: async (id, values) => {
    try {
      return await axios.put(`/admin/approval_stages/${id}`, values);
    } catch (error) {
      throw error;
    }
  },

  deleteApprovalAdmin: async id => {
    try {
      return await axios.delete(`/admin/approval_stages/${id}`);
    } catch (error) {
      throw error;
    }
  },

  reorderApprovalsAdmin: async params => {
    try {
      return await axios.put("/admin/approval_stages/reorder", params);
    } catch (error) {
      throw error;
    }
  },

  getApprovalDetails: async (milestoneId, approvalId) => {
    try {
      return await axios.get(`/milestones/${milestoneId}/approvals/${approvalId}`);
    } catch (error) {
      throw error;
    }
  },

  createApproval: async (milestoneId, params) => {
    try {
      return await axios.post(`/milestones/${milestoneId}/approvals`, params);
    } catch (error) {
      throw error;
    }
  },

  editApproval: async (milestoneId, approvalId, values) => {
    try {
      return await axios.put(`/milestones/${milestoneId}/approvals/${approvalId}`, values);
    } catch (error) {
      throw error;
    }
  },

  deleteApproval: async (milestoneId, approvalId) => {
    try {
      return await axios.delete(`/milestones/${milestoneId}/approvals/${approvalId}`);
    } catch (error) {
      throw error;
    }
  },

  sendRequest: async (milestoneId, approvalId) => {
    try {
      return await axios.put(`/milestones/${milestoneId}/approvals/${approvalId}/send_request`);
    } catch (error) {
      throw error;
    }
  },

  sendAnswer: async (approvalId, approverId, values) => {
    try {
      return await axios.put(`/approvals/${approvalId}/atomic_approvers/${approverId}`, values);
    } catch (error) {
      throw error;
    }
  },

  getApproverDetails: async (approvalId, approverId) => {
    try {
      return await axios.get(`/approvals/${approvalId}/atomic_approvers/${approverId}`);
    } catch (error) {
      throw error;
    }
  }
}