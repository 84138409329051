import React, {useEffect, useState} from "react";
import s from "./ClarificationHistoryContainer.module.scss";
import ClarificationHistory from "../../elements/ClarificationHistory/ClarificationHistory";
import {useParams} from "react-router-dom";
import services from "../../../services";

const ClarificationHistoryContainer = () => {
  const {milestoneId, id} = useParams();
  const [approval, setApproval] = useState(null);

  useEffect(() => {
    services.approvalServices.getApprovalDetails(milestoneId, id).then(res => {
      setApproval(res.data);
    });
  }, []);

  return (
    <div className={s.wrap}>
      <h2 className={s.title}>{approval?.name ?? "Approval"}</h2>
      <ClarificationHistory sourceId="approverId"/>
    </div>
  );
};

export default ClarificationHistoryContainer;