import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import TagReportContainer from "components/containers/TagReportContainer/TagReportContainer";

const TagReportPage = () => (
  <DefaultLayout
    breadcrumbs={[
      {
        path: "/system-reports",
        name: "System reports",
      },
      {
        name: "Tag report",
      },
    ]}
  >
    <TagReportContainer />
  </DefaultLayout>
);

export default TagReportPage;
