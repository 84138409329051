import React, {useEffect, useState} from "react";
import s from "./Notice.module.scss";
import {ReactComponent as EmptyNoticeIcon} from "assets/icons/notice/empty-notice.svg";
import {ReactComponent as NoticeIcon} from "assets/icons/notice/notice.svg";
import services from "../../../../../../services";
import DropListTrigger from "../../../../../elements/DropListTrigger/DropListTrigger";
import {ReactComponent as ProjectIcon} from "../../../../../../assets/icons/notice/project.svg";
import {ReactComponent as ContractIcon} from "../../../../../../assets/icons/notice/contract.svg";
import {useHistory} from "react-router-dom";

const Notice = ({id, fetchBy, isOpen, onOpen}) => {
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    services.dashboardServices.getNotificationByType(id, fetchBy).then((res) => {
      setData(res.data.notifications);
    });
  }, [id]);

  const getIcon = notice => {
    const keysList = Object.keys(notice);
    if (keysList.find(k => k === 'project_id')) {
      return <ProjectIcon/>;
    }
    return <ContractIcon/>;
  }

  const getUrl = n => {
    const keys = Object.keys(n);
    if (keys.find(k => k === 'project_id')) {
      return `/projects/${n['project_id']}`;
    } else {
      return `/contracts/${n['contract_id']}`;
    }
  }

  if (data.length) {
    return (
      <>
        <DropListTrigger
          menuIcon={
            <>
              <NoticeIcon/>
              <span>{data.length}</span>
            </>
          }
          customMenuClass="notice"
          customBtnClass="notice"
          data={data.map(n => {
            return {
              title: (
                <div className={s.noticeItem} key={n.id}>
                  <div className={s.iconWrap}>
                    {getIcon(n)}
                  </div>
                  <p>{n.message}</p>
                </div>
              ),
              onClick: () => history.push(getUrl(n)),
            }
          })}
          visibleMenu={isOpen}
          setVisibleMenu={onOpen}
          ariaLabel='drop-list-btn'
          withScroll={true}
        />
      </>
    )
  }

  return (
    <div className={s.emptyNotice}>
      <EmptyNoticeIcon/>
    </div>
  );
};

export default Notice;


