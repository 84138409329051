import React, {useEffect, useState} from "react";
import s from "./CreateProjectContainer.module.scss";
import ProjectForm from "components/forms/ProjectRequestActionsForm/ProjectForm";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import services from "../../../services";

const CreateProjectContainer = ({requestId}) => {
  const [stages, setStages] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    services.projectServices.getStages().then(res => {
      setStages(
        res.data?.stages?.map(({id, name}) => ({
          id,
          label: name,
          value: name
        })) || []
      );
    });
  }, []);

  return (
    <div className={s.wrap}>
      <h2 className={s.title} data-translate-key={getTranslateKey(requestId ? "request-details" : "projects")}>
        {t(getTranslateKey(requestId ? "request-details" : "projects"))}</h2>
      {stages.length > 0 && <ProjectForm stages={stages} requestId={requestId}/>}
    </div>
  );
};

export default CreateProjectContainer;

CreateProjectContainer.propTypes = {
  requestId: PropTypes.string,
};