import React, { useState } from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdownUsers from "./components/FilterBarDropdownUsers/FilterBarDropdownUsers";
import FilterBarDropdownProjects from "./components/FilterBarDropdownProjects/FilterBarDropdownProjects";
import {debounce} from "lodash";
import qs from "query-string";
import {useHistory} from "react-router-dom";

const initialFiltersValues = {
  users: [],
  projects: [],
};

const ActivityFilter = ({ onFiltersChange, className }) => {
  const [activeFilters, setActiveFilters] = useState(initialFiltersValues);
  const history = useHistory();
  const { ...query } = qs.parse(
    history.location.search
  );
  const handleChangeFilter = (filterName, values) => {
    const newFiltersValues = { ...activeFilters, [filterName]: values };
    setActiveFilters({ ...newFiltersValues });
    onFiltersChange({ ...newFiltersValues });
  };

  const handleClearAll = () => {
    setActiveFilters(initialFiltersValues);
    onFiltersChange(initialFiltersValues);
  };

  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        { ...searchQuery, query: term },
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  return (
    <FilterBar
      filterName="Filter activities"
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      withoutDefaultFilter
      withSearch
    >
      <FilterBarDropdownUsers
        name="Users"
        handleChange={(values) => {
          handleChangeFilter("users", values);
        }}
      />
      <FilterBarDropdownProjects
        name="Projects"
        handleChange={(values) => {
          handleChangeFilter("projects", values);
        }}
      />
    </FilterBar>
  );
};

export default ActivityFilter;
