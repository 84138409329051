import React from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import s from "../../elements/DropdownTree/DropdownTree.module.scss";
import Input from "../../elements/Input/Input";


const ChangeTeamNameForm = (
  {
    onSubmit,
    onClose,
    defaultValues
  }
) => {
  const formik = useFormik({
    initialValues: {
      team_name: defaultValues.team_name ?? ""
    },
    onSubmit: ({team_name}) => {
      onSubmit(team_name);
    },
  });

  const {handleSubmit, setFieldValue, values} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        icon
        label="Team name"
        value={values.team_name}
        placeholder="Team name"
        onChange={e => setFieldValue("team_name", e.target.value)}
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Save
        </Button>
      </div>
    </form>
  );
};

export default ChangeTeamNameForm;
