import React from "react";
import {formatDate} from "../../../../../../../helpers/dateFormat";
import sBase from "../Activities.module.scss";
import DropDown from "./DropDown";
import {trimRest} from "../../../../../../../helpers/stringHelper";
import {useTranslation} from "react-i18next";

const AssignedDropDown = ({data}) => {
  const { t } = useTranslation();
  return (
    <DropDown data={{name: data.project.name, status: data.status}}>
      <div className={sBase.activityInfo}>
        <div>
          <span data-translate-key="procurement-owner">{t("procurement-owner")}</span>
          <span>{data.project.owner}</span>
        </div>
        <div>
          <span data-translate-key="task-name">{t("task-name")}</span>
          <span>{trimRest(15, data.name)}</span>
        </div>
        <div>
          <span data-translate-key="expected-date">{t("expected-date")}</span>
          <span>{formatDate(data.expected_date, true)}</span>
        </div>
      </div>
    </DropDown>
  );
};

export default AssignedDropDown;



