import services from "../../../../services";
import {setAlert} from "../../CategoryDetailContainer/components/CategoryInfo/components/Contracts/helpers/actions";
import {appendFile} from "../../../../helpers/appendFile";
import {useParams} from "react-router-dom";
import {userRoles} from "../../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {filterUniqueEntities} from "../../../../helpers/filterUniqueEntities";
import {delay} from "../../../../helpers/delay";
import {checkRoles} from "../../../../helpers/checkRoles";

export const useTaskDetailsState = ({load, data, project}) => {
  const {taskId, milestoneId} = useParams();
  const user = useSelector(store => store.user);
  const [stakeholders, setStakeholders] = useState([]);
  const [stakeholdersLoading, setStakeholdersLoading] = useState(true);
  const [selectedSubtaskId, setSelectedSubtaskId] = useState(null);

  const selectedSubtask = data?.subtasks?.find(s => s.id === selectedSubtaskId);

  const {admin, cpo, procurement_admin} = userRoles;
  const preparedRoles = [admin, cpo, procurement_admin];

  const isHighRule = (user.id === data?.owner?.id || checkRoles(user.roles, preparedRoles));
  const isResponsibleUser = user.id === data?.responsible_user?.id;

  let preparedResponsibleUsers = filterUniqueEntities([project?.owner, data?.responsible_user, ...stakeholders.map(s => ({
    id: s.stakeholder.id,
    name: s.stakeholder.name
  }))], "id").map(s => ({label: s?.name, value: s?.id}));

  useEffect(() => {
    services.projectServices.getTaskStakeholders(taskId).then(res => {
      if (res.data) {
        setStakeholders(res.data);
        setStakeholdersLoading(false);
      }
    });
  }, []);

  const editHandler = ({attachments, ...spread}) => {
    services.taskServices
      .editTask(milestoneId, taskId, {...spread})
      .then(res => {
        const task = res?.data;
        setAlert({
          message: `"${task.name}" task successfully changed.`
        });
        if (task?.id && !{...spread}?.stage_id) {
          for (let i = 0; i < attachments.length; i++) {
            const file = appendFile(attachments[i]);
            services.taskServices.addAttachment(file, task.id).then(() => {
            });
          }
        }
        load();
      })
      .catch(() => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };

  const attachmentsHandler = (attachments, sourceId) => {
    Promise.all([...attachments.map(attachment => {
      const file = appendFile(attachment);
      services.taskServices.addAttachment(file, sourceId).then(res => res.data);
    })]).finally(() => delay(100).then(() => load()));
  };

  const changeStatus = statusId => {
    services.taskServices.changeTaskStatus(milestoneId, taskId, {task_status_id: statusId}).then(res => {
      if (res.data) load();
    });
  };

  const createNewSubtask = params => {
    services.projectServices.createSubtask(taskId, params.values).then(res => {
      if (res.data) attachmentsHandler(params.attachments, res.data.id);
    });
  };

  const editSubtask = (params, subtaskId) => {
    services.projectServices.editSubtask(params.values, taskId, subtaskId).then(res => {
      if (res.data) {
        if (res.data) attachmentsHandler(params.attachments, res.data.id);
      }
    });
  };

  const changeSubtaskStatus = (subtaskId, status) => {
    services.projectServices.editSubtaskStatus(taskId, subtaskId, {status}).then(res => {
      if (res.data) load();
    });
  };

  const deleteSubtask = subtaskId => {
    services.projectServices.deleteSubtask(taskId, subtaskId).then(() => {
      load();
    });
  };

  const addNote = (subtaskId, params) => {
    services.noteServices.createNote(subtaskId, {description: params.description}).then(res => {
      if (res.data) attachmentsHandler(params.attachments, res.data.id);
    });
  };

  const editNote = (subtaskId, noteId, params) => {
    services.noteServices.editNotes(subtaskId, noteId, {description: params.description}).then(res => {
      if (res.data) attachmentsHandler(params.attachments, res.data.id);
    });
  };

  const deleteNote = (subtaskId, noteId) => {
    services.noteServices.deleteNote(subtaskId, noteId).then(() => load());
  };

  return {
    editHandler,
    changeStatus,
    createNewSubtask,
    isHighRule,
    isResponsibleUser,
    stakeholders,
    preparedResponsibleUsers,
    stakeholdersLoading,
    selectedSubtask,
    setSelectedSubtaskId,
    editSubtask,
    deleteSubtask,
    addNote,
    editNote,
    deleteNote,
    changeSubtaskStatus
  };
};