import React from "react";
import s from "../AdminSettingsContainer/AdminSettingsContainer.module.scss";
import Flags from "./Flags/Flags";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const AdminSettingsFlagsContainer = () => {
  const {t} = useTranslation();
  return (
      <div className={s.wrap}>
        <h2 className={s.title} data-translate-key={"flags"}> {t(getTranslateKey("flags"))}</h2>
        <Flags/>
      </div>
  );
};
export default AdminSettingsFlagsContainer;
