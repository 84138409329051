import {deepCopy} from "helpers";

export const insertNewOption = (selectedOptions, newOption) => {
  const copiedSelectedOptions = deepCopy(selectedOptions);
  const {filterName} = newOption;
  return {
    ...copiedSelectedOptions,
    [filterName]: [...copiedSelectedOptions[filterName], newOption],
  };
};
export const removeOption = (selectedOptions, option) => {
  const copiedSelectedOptions = deepCopy(selectedOptions);
  const {filterName} = option;
  return {
    ...copiedSelectedOptions,
    [filterName]: copiedSelectedOptions[filterName].filter(
      (o) => o.id !== option.id
    ),
  };
};
export const isChecked = (checkedOptions, item) => {
  const checked = Boolean(
    checkedOptions.find((option) => option.id === item.id)
  );
  if (checked) {
    return checked;
  } else if (item.subItems) {
    const childItems = item.subItems.map((subItem) =>
      isChecked(checkedOptions, subItem)
    );
    const isCheckedChild = childItems.some((item) => Boolean(item) === true);
    if (isCheckedChild) {
      return "minus";
    } else {
      return false;
    }
  }
  return false;
};
export const removeAllOptions = (selectedOptions, filterName) => {
  const copiedSelectedOptions = deepCopy(selectedOptions);
  return {
    ...copiedSelectedOptions,
    [filterName]: [],
  };
};
export const getAllIds = (selectedOptions) => {
  return selectedOptions?.map((option) => (option.id ? option.id : option));
};
export const formatFilters = (filters) => {
  const filterEntries = Object.entries(filters);
  const newFilters = filterEntries.map((filter) => [
    filter[0],
    typeof filters[filter[0]][0] === "object"
      ? filters[filter[0]].map(formatSubCategories)
      : filters[filter[0]],
  ]);
  return Object.fromEntries(newFilters);
};
const formatSubCategories = (filterValue) => ({
  name: filterValue.name,
  id: filterValue.id,
  subItems: filterValue.categories
    ? filterValue.categories.map(formatSubCategories)
    : null,
});
export const searchCategories = (value, options) => {
  const result = [];
  const searchedValue = value.toLowerCase();
  const copiedOptions = deepCopy(options);
  if (!!!searchedValue.trim()) return copiedOptions;
  copiedOptions.forEach((option) => {
    const optionName = String(option.name).toLowerCase();
    if (optionName.includes(searchedValue)) {
      result.push({...option, opened: true});
    } else if (option.subItems) {
      const subItems = searchCategories(searchedValue, option.subItems);
      if (subItems.length > 0) {
        option.subItems = subItems;
        option.opened = true;
        result.push(option);
      }
    }
  });
  return result;
};
export const getAllSubCategories = (category) => {
  const result = [category];
  if (!category.subItems) {
    return result;
  }
  category.subItems.forEach((subItem) => {
    result.push(subItem);
    if (subItem.subItems) {
      result.push(...getAllSubCategories(subItem));
    }
  });
  return result;
};
export const getAllFirstLvlSubCategories = (category) => {
  const result = [];
  if (!category.subItems) {
    return [category];
  }
  category.subItems.forEach((subItem) => {
    result.push(subItem);
  });
  return result;
};
