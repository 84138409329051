import React from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import services from "../../../services";
import style from "./SubtaskForm.module.scss";
import Textarea from "../../elements/Textarea/Textarea";
import Attachments from "../AddMilestoneTaskForm/components/Attachments/Attachments";
import PropTypes from "prop-types";
import Checkbox from "../../elements/Checkbox/Checkbox";
import RSSelect from "../../elements/ReactSelectCustom/RSSelect";
import DatePicker from "../../elements/DatePicker/DatePicker";
import {formatDate} from "../../../helpers/dateFormat";
import RSAsyncSelect from "../../elements/ReactSelectCustom/RSAsyncSelect";

const MAX_DESCRIPTION_LENGTH = 180;

const SubtaskForm = ({onClose, defaultValues, onSubmit, responsibleUsers}) => {
  const formik = useFormik({
    initialValues: defaultValues || {
      name: "",
      description: "",
      attachments: [],
      optional: false,
      responsible_user: {},
      due_date: new Date(),
    },
    validationSchema: object({
      name: string()
        .required("Name is required")
        .min(2, "Please enter subtask name that consists of no less than 2 characters")
        .max(50, "Please enter subtask name that consists of no more than 50 characters"),
      description: string().max(180, "Please enter description that consists of no more than 180 characters")
    }),
    validate: ({due_date, responsible_user}) => {
      const errors = {};

      if (responsibleUsers) {
        if (!responsible_user.value) {
          errors.responsible_user = "Please, choose responsible user";
        }
        if (!due_date) {
          errors.due_date = "Due date is required";
        }
      }

      return errors;
    },
    onSubmit: ({name, description, attachments, optional, responsible_user, due_date}) => {
      let values = {
        name,
        description,
        optional,
        responsible_user_id: responsible_user?.value || null,
      };

      if (responsibleUsers) {
        values = {...values, due_date: formatDate(due_date)};
      }

      onSubmit({
        values,
        attachments
      });
    }
  });

  const {
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    values,
  } = formik;

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };

  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      );
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        );
      });
    }
  };

  const suppliersAutocomplete = term => services.userServices
    .stakeholdersAutocomplete({query: term})
    .then(res => res.data.map(supplier => ({
      value: supplier.id,
      label: supplier.name,
    })));

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <div className={style.field}>
        <Input
          type='text'
          name='name'
          label='Subtask name'
          placeholder='Enter task name'
          value={values.name}
          error={errors.name}
          onChange={handleChange}
          limitValue={50}
          withCounter
        />
      </div>
      <div className={style.field}>
        <Textarea
          value={values.description}
          label="Description"
          optional
          onChange={e => setFieldValue("description", e.target.value)}
          placeholder="Enter description"
          error={errors.description}
          count={{
            current: values.description.length,
            max: MAX_DESCRIPTION_LENGTH
          }}
        />
      </div>
      {
        !responsibleUsers && (
          <RSAsyncSelect
            load={suppliersAutocomplete}
            optional
            defaultValue={values.responsible_user}
            className={style.field}
            onChange={option => setFieldValue("responsible_user", option)}
            label="Responsible user"
            placeholder="Start typing user name"
          />
        )
      }
      <div className={style.field}>
        <Attachments
          onChange={handleChangeAttachment}
          onDelete={handleDeleteAttachment}
          attachments={values.attachments}
          filesMaxLength={3}
          optional
          withLink={true}
        />
      </div>
      {
        responsibleUsers && (
          <div className={style.groupField}>
            <RSSelect
              options={responsibleUsers}
              defaultValue={values.responsible_user}
              onChange={option => setFieldValue("responsible_user", option)}
              label="Responsible user"
              placeholder="Choose user"
              error={errors.responsible_user}
            />
            <div className='dateBlock'>
              <DatePicker
                label="Due date"
                selected={values.due_date}
                onChange={date => setFieldValue("due_date", date)}
                selectsStart
                placeholderText='Choose due date'
                wrapperClassName='wrapperdatePicker'
                className='customInput'
                todayButton='Go to today'
                showYearDropdown
                dateFormatCalendar='MMMM'
                yearDropdownItemNumber={5}
                scrollableYearDropdown
                error={errors.due_date}
              />
            </div>
          </div>
        )
      }
      <Checkbox
        inputProps={{"aria-label": "primary checkbox"}}
        label="Optional"
        subLabel="Subtask will not be required"
        checked={values.optional}
        onChange={() => setFieldValue("optional", !values.optional)}
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          styled='primary'
          type='submit'
          className='popupBtn'
          onClick={e => e.stopPropagation()}
        >
          {defaultValues ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default SubtaskForm;

SubtaskForm.propTypes = {
  onClose: PropTypes.func,
  defaultValues: PropTypes.any,
  onSubmit: PropTypes.func,
  responsibleUsers: PropTypes.array
};