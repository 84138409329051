import React from "react";
import Popup from "components/popups/Popup/Popup";
import PropTypes from "prop-types";
import AdminSettingsFinancialSavingsGroupDeleteForm
  from "../../../../../forms/AdminSettingsFinancialSavingsGroupDeleteForm/AdminSettingsFinancialSavingsGroupDeleteForm";

const DeleteDivision = ({ onClose, onSubmit }) => {
  return (
    <Popup
        title="Delete division"
        text="Are you sure you want to delete this division?"
        onClose={onClose}
        successBtnTitle="Delete"
        onClick={onSubmit}
        dialog
        isDelete
        dialog
        subText="All users from it will be moved to the “Default” division."
    />

);
};

export default DeleteDivision;

DeleteDivision.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
