import React, {useEffect, useState} from "react"
import FilterBar from "components/elements/FilterBar/FilterBar"
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown"
import qs from "query-string"
import {debounce} from "lodash"
import {useHistory} from "react-router-dom"
import {formatFilters, getAllIds} from "components/elements/FilterBar/helpers/helpers"
import services from "../../../services";
import {formatDepartmentsFilter} from "../helpers/formatDepartmentsFilter";
import FilterBarLoading from "../../loadingStates/FilterBar/FilterBar";

const SystemReportsTeamsFilter = ({className}) => {
  const history = useHistory();
  const [filters, setFilters] = useState(null);
  const [savedFilter, setSavedFilter] = useState(null);

  useEffect(() => {
    services.userServices.getDepartments().then(res => {
      if (res.data) {
        setFilters(
          formatFilters({
            departments: res.data ? formatDepartmentsFilter(res.data) : []
          })
        );
      }
    });
    services.filterService.getFilter("system_report_teams").then(res => {
      if (res.data && res.data[0]) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      }
    });
  }, []);

  const {tab, ...query} = qs.parse(history.location.search);

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values)
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      tab,
    }
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    })
  }

  const handleClearAll = () => {
    const searchQuery = {tab};
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    })
  };

  const handleSearch = debounce(term => {
    history.push({
      search: qs.stringify(
        {...query, tab, query: term},
        {
          arrayFormat: "comma"
        }
      )
    })
  }, 300);

  const saveDefaultFilter = () => {
    services.filterService.addFilter("system_report_teams", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  };

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  };

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams ?? savedFilter.filter_params),
      tab,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  if (!filters) return <FilterBarLoading/>;

  return (
    <FilterBar
      filterName='Filter teams'
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
    >
      <FilterBarDropdown
        name='Departments'
        searchPlaceholder='Search department'
        defaultValues={query?.department_ids?.split(",")}
        options={filters.departments}
        handleChange={values => {
          handleChangeFilter("department_ids", values)
        }}
      />
    </FilterBar>
  )
}

export default SystemReportsTeamsFilter
