import React from "react";
import AuthenticationLayout from "components/layouts/AuthenticationLayout/AuthenticationLayout";
import PasswordResetRequestContainer from "components/containers/PasswordResetRequestContainer/PasswordResetRequestContainer"

const PasswordResetRequestPage = () => {
  return (
    <AuthenticationLayout>
      <PasswordResetRequestContainer/>
    </AuthenticationLayout>
  );
};

export default PasswordResetRequestPage;
