import React, { useEffect, useState, Fragment } from "react";
import services from "services";
import s from "./ReportingContainer.module.scss";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import DatePicker from "components/elements/DatePicker/DatePicker"
import moment from 'moment';



const ReportingContainer = () => {
  const [urls, setUrls] = useState([]);
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState();
  const [period, setPeriod] = useState({ start_date: moment().startOf('quarter').subtract(4, 'Q').toDate(), end_date: moment().endOf('quarter').subtract(1, 'Q').toDate() })

  const fmtMD = (v) => v < 10 ? `0${v}` : v;
  const fmtDate = (d) => `${d.getFullYear()}-${fmtMD((d.getMonth()) + 1)}-${fmtMD(d.getDate())}`;

  useEffect(() => {
    services.dashboardServices.getQuicksightURLs().then((res) => {
      setUrls(res.data.dashboard_urls);
	  const reps = res.data.dashboard_urls.map((x, ind) => ({ label: ind === 0 ? 'Main' : 'Custom ' + ind, value: x }) );
	  setReports(reps);
	  if (reps.length > 0) setReport(reps[0].value);
    });
  }, []);
  return (
    <div className={s.wrap}>
		<div className={s.settings}>
			<CustomDropdown
			options={reports}
			value={report}
			onChange={(option) => { setReport(option.value); }}
			/>
            {(reports.length && report !== reports[0].value) &&
                (<div className={s.periodContainer}>
                    <DatePicker
                        // label='Start date'
                        selected={period.start_date}
                        onChange={date => {
                            setPeriod({ start_date: date, end_date: date > period.end_date ? date : period.end_date })
                        }}
                        selectsStart
                        startDate={period.start_date}
                        endDate={period.end_date}
                        placeholderText='Start date'
                        wrapperClassName='wrapperdatePicker'
                        className='customInput'
                        todayButton='Go to today'
                        showYearDropdown
                        dateFormatCalendar='MMMM'
                        yearDropdownItemNumber={5}
                        scrollableYearDropdown
                        classWrap={s.fit}
                        // error={touched.start_date && errors.start_date}
                    />
                    <DatePicker
                        // label='Start date'
                        selected={period.end_date}
                        onChange={date => {
                            setPeriod({ start_date: date < period.start_date ? date : period.start_date, end_date: date })
                        }}
                        selectsStart
                        startDate={period.end_date}
                        endDate={period.end_date}
                        placeholderText='End date'
                        wrapperClassName='wrapperdatePicker'
                        className='customInput'
                        todayButton='Go to today'
                        showYearDropdown
                        dateFormatCalendar='MMMM'
                        yearDropdownItemNumber={5}
                        scrollableYearDropdown
                        classWrap={s.fit}
                        // error={touched.start_date && errors.start_date}
                    />
                </div>)
            }
		</div>

      {/* {urls.length > 0 && ( */}
      {report && (
        <html>
          <iframe width="100%" height="830px" src={report + `&Start=${fmtDate(period.start_date)}&End=${fmtDate(period.end_date)}`}></iframe>
        </html>
      )}
    </div>
  )
}
export default ReportingContainer
