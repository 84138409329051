import {DELETE_SUBTASK, SET_SUBTASK} from "../helpers/constants";

let initialState = {
  selectedSubtask: null,
};

const subtasks = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_SUBTASK: {
      return {...state, selectedSubtask: payload};
    }
    case DELETE_SUBTASK: {
      return {...state, selectedSubtask: null};
    }
    default: {
      return state;
    }
  }
};

export default subtasks;