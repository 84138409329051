import React from "react";
import Popup from "components/popups/Popup/Popup";
import ChangeUserForm from "components/forms/ChangeUserForm/ChangeUserForm";
import { formatUserRoles } from "helpers/userPermissions/formatUserRoles";

const EditUserPopup = ({onSubmit, onClose, filters, defaultData, divisions}) => {
  const defaultValues = {
    ...defaultData,
    role: formatUserRoles(defaultData.roles).reverse()?.[0] || {
      label: "",
      value: "",
    },
    division: {
      label: defaultData.division.name,
      value: defaultData.division.name,
    },
    department: {
      label: defaultData.department.name || "",
      value: defaultData.department.name || "",
    },
    manager: {
      label: defaultData?.manager?.name || "",
      value: defaultData?.manager?.name || "",
    },
  };
  return (
    <Popup title="Edit user" onClose={onClose}>
      <ChangeUserForm
        onSubmit={onSubmit}
        onClose={onClose}
        filters={filters}
        divisions={divisions}
        defaultValues={defaultValues}
      />
    </Popup>
  );
};
export default EditUserPopup;
