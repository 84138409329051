import React, {useState} from "react";
import classNames from "classnames";
import {TableRow, TableCell} from "@material-ui/core";
import {Draggable} from "react-beautiful-dnd";
import RenderTableCell from "./RenderTableCell";
import DropListTrigger from "components/elements/DropListTrigger/DropListTrigger";
import {useDraggableInPortal} from "hooks/useDraggableInPortal";
import PropTypes from "prop-types";

function getStyle(style, snapshot) {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: "0.001s",
    background: "white"
  };
}

const RenderRow = (
  {
    index,
    dragIdLabel,
    item,
    s,
    dataTypes,
    data,
    tdStyle,
    options,
    subKey,
    subTypes,
    isSubRow = false,
    className,
    level = 0,
    uniqueRowStyle
  }
) => {

  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);
  const [expand, setExpand] = useState(false);
  const renderDraggable = useDraggableInPortal();
  const hasExpand = Boolean(subKey && item[subKey]);
  if (isSubRow) {
    return (
      <TableRow
        className={classNames(s.subTableRow)}
        key={index}
        onMouseEnter={() => setVisibleDropListMenu(false)}
      >
        {dataTypes.map((type, index) => (
          // eslint-disable-next-line react/jsx-key
          <RenderTableCell
            dataTypes={dataTypes}
            type={type}
            data={item}
            index={index}
            visible={type.visible}
            allData={data}
            tdStyle={tdStyle}
            uniqueRowStyle={uniqueRowStyle}
          />
        ))}
        {options &&
          Array.isArray(options(item)) &&
          options(item).filter(e => Boolean(e)).length > 0 && (
            <TableCell className={s.tableCell}>
              <DropListTrigger
                withScroll={true}
                data={options(item).filter(e => Boolean(e))}
                className={classNames(s.dropListTrigger, {
                  [s.overflow]: index === data.length - 1
                })}
                visibleMenu={visibleDropListMenu}
                setVisibleMenu={setVisibleDropListMenu}
                ariaLabel="drop-list-btn"
              />
            </TableCell>
          )}
      </TableRow>
    );
  }

  return (
    <>
      <Draggable
        key={index}
        draggableId={`${dragIdLabel}-${item.id}`}
        index={index}
        isDragDisabled={!dragIdLabel}
      >
        {renderDraggable((provided, snapshot) => (
          <TableRow
            className={classNames(s.tableRow, className, uniqueRowStyle && uniqueRowStyle(item))}
            key={index}
            onMouseEnter={() => setVisibleDropListMenu(false)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(provided.draggableProps.style, snapshot)}
          >
            {dataTypes.map((type, index) => {
              // eslint-disable-next-line react/jsx-key
              return <RenderTableCell
                dataTypes={dataTypes}
                type={type}
                data={item}
                index={index}
                visible={type.visible}
                allData={data}
                tdStyle={tdStyle}
                expand={!index && hasExpand ? expand : undefined}
                changeExpand={setExpand}
                level={level}
                subKey={subKey}
                uniqueRowStyle={uniqueRowStyle}
              />;
            })}
            {options &&
              Array.isArray(options(item)) &&
              options(item).filter(e => Boolean(e)).length > 0 && (
                <TableCell className={s.tableCell}>
                  <DropListTrigger
                    withScroll={true}
                    data={options(item).filter(e => Boolean(e))}
                    className={classNames(s.dropListTrigger, {
                      [s.overflow]: index === data.length - 1
                    })}
                    visibleMenu={visibleDropListMenu}
                    setVisibleMenu={setVisibleDropListMenu}
                    ariaLabel="drop-list-btn"
                  />
                </TableCell>
              )}
          </TableRow>
        ))}
      </Draggable>
      {expand && subKey &&
        item[subKey]?.map((itm, index) => (
          <RenderRow
            key={index}
            index={index}
            item={itm}
            s={s}
            dataTypes={subTypes}
            data={item}
            tdStyle={tdStyle}
            options={options}
            subTypes={subTypes}
            subKey={subKey}
            isSubRow={false}
            dragIdLabel={dragIdLabel}
            level={level + 1}
          />
        ))}
    </>
  );
};

export default RenderRow;

RenderRow.propTypes = {
  index: PropTypes.number,
  dragIdLabel: PropTypes.string,
  item: PropTypes.any,
  s: PropTypes.any,
  dataTypes: PropTypes.any,
  data: PropTypes.any,
  tdStyle: PropTypes.any,
  options: PropTypes.any,
  subKey: PropTypes.string,
  subTypes: PropTypes.any,
  isSubRow: PropTypes.bool,
  className: PropTypes.string,
  level: PropTypes.number,
  uniqueRowStyle: PropTypes.func,
};