import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {getAllIds} from "components/elements/FilterBar/helpers/helpers";
import {debounce} from "lodash";
import services from "../../../services";

const ResourceUtilizationReportFilter = ({filters, className, afterFilterChangeAction}) => {
  const history = useHistory();
  const [savedFilter, setSavedFilter] = useState(null);

  useEffect(() => {
    services.filterService.getFilter("resource_utilization_report")
      .then(res => {
        if (res.data && res.data[0]) {
          setSavedFilter(res.data[0]);
          showDefaultFilter(res.data[0]?.filter_params || "");
        }
      });
  }, []);

  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction,
    };

    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"}),
    });
  };

  const handleClearAll = () => {
    const searchQuery = {page, tab, sort_by, direction};
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"}),
    });
  };

  const saveDefaultFilter = () => {
    services.filterService.addFilter("resource_utilization_report", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  }

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  }

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams ?? savedFilter?.filter_params),
      page: 1,
      tab,
      sort_by,
      direction,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  }

  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);
  return (
    <>
      {tab !== "Department list" ? (
        <FilterBar
          filterName="Filter users"
          handleClearAll={handleClearAll}
          className={className}
          onSearch={handleSearch}
          searchDefaultValue={query.query || ""}
          withSearch={tab === undefined || tab === 'Individual list'}
          savedFilter={savedFilter}
          saveDefaultFilter={saveDefaultFilter}
          resetDefaultFilter={deleteDefaultFilter}
          showDefaultFilter={showDefaultFilter}
        >
          <FilterBarDropdown
            name="Users"
            options={filters.users}
            defaultValues={query?.user_ids?.split(",")}
            afterClickAction={() => afterFilterChangeAction()}
            searchPlaceholder="Search user"
            handleChange={(values) => {
              handleChangeFilter("user_ids", values);
            }}
          />

          {(!tab || tab === "Individual list") && (
            <FilterBarDropdown
              name="Divisions"
              options={filters.divisions}
              defaultValues={query?.division_ids?.split(",")}
              handleChange={(values) => {
                handleChangeFilter("division_ids", values);
              }}
            />
          )}
          {(!tab || tab === "Individual list") && (
            <FilterBarDropdown
              name="Departments"
              options={filters.departments}
              defaultValues={query?.department_ids?.split(",")}
              afterClickAction={() => afterFilterChangeAction()}
              searchPlaceholder="Search department"
              handleChange={(values) => {
                handleChangeFilter("department_ids", values);
              }}
            />
          )}
        </FilterBar>
      ) : null}
    </>
  );
};

export default ResourceUtilizationReportFilter;
