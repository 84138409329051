import React from "react";
import {useFormik} from "formik";
import Button from "components/elements/Button/Button";
import {object} from "yup";
import {initialValuesCreator} from "./helper/intialValuesCreator";
import {validationSchemaCreator} from "./helper/validationSchemaCreator";
import FormConstructorInput from "./fields/FormConstructorInput";
import s from "./FormConstructor.module.scss";
import FormConstructorTextarea from "./fields/FormConstructorTextarea";
import PropTypes from "prop-types";
import FormConstructorSelect from "./fields/FormConstructorSelect";
import FormConstructorCheckbox from "./fields/FormConstructorCheckbox";
import FormConstructorAttachments from "./fields/FormConstructorAttachments";
import FormConstructorAutocomplete from "./fields/FormConstructorAutocomplete";

const FormConstructor = ({submitBtn, cancelBtn, onSubmit, fields}) => {
  const formik = useFormik({
    initialValues: initialValuesCreator(fields),
    validationSchema: object(validationSchemaCreator(fields)),
    validate: values => {
      let errors = {};
      const fieldsWithCondition = fields.filter(field => field.showCondition);

      fieldsWithCondition.forEach(field => {
        let valueForCondition;

        if (typeof values[field.showCondition.keyReference] === "object") {
          valueForCondition = values[field.showCondition.keyReference].value;
        } else {
          valueForCondition = values[field.showCondition.keyReference];
        }

        if (field.showCondition.method(valueForCondition) && field.showCondition.requiredMessage) {
          if (field.type === "dropdown") {
            if (!Boolean(values[field.key].value)) errors = {
              ...errors, [field.key]: {
                value: field.showCondition.requiredMessage,
              }
            };
          }
        }
      });

      return errors;
    },
    onSubmit: values => {
      const preparedValues = {};
      for (let key in values) {
        if (values[key] !== undefined) {
          preparedValues[key] = values[key];
        }
      }
      onSubmit(preparedValues);
    }
  });

  const {handleSubmit, errors, setFieldValue, values, touched} = formik;

  const getParamsField = field => {
    const preparedParams = {field, setFieldValue, values, touched, errors};
    if (field.options) preparedParams.options = field.options;
    return preparedParams;
  };

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <div className={s.formWrapper}>
        {fields.map(field => {
          if (field.type === "text") return <FormConstructorInput key={field.key} {...getParamsField(field)}/>;
          if (field.type === "textarea") return <FormConstructorTextarea key={field.key} {...getParamsField(field)}/>;
          if (field.type === "dropdown") return <FormConstructorSelect key={field.key} {...getParamsField(field)}/>;
          if (field.type === "autocomplete") return <FormConstructorAutocomplete key={field.key} {...getParamsField(field)}/>;
          if (field.type === "checkbox") return <FormConstructorCheckbox key={field.key} {...getParamsField(field)}/>;
          if (field.type === "attachments") return <FormConstructorAttachments key={field.key} {...getParamsField(field)}/>;
        })}
      </div>
      <div className='popupBtnsWrap'>
        <Button styled={cancelBtn.styled} type='reset' className='popupBtn' onClick={cancelBtn.onClick}>
          {cancelBtn.title}
        </Button>
        <Button styled={submitBtn.styled} type='submit' className='popupBtn'>
          {submitBtn.title}
        </Button>
      </div>
    </form>
  );
};
export default FormConstructor;

FormConstructor.propTypes = {
  submitBtn: PropTypes.any,
  cancelBtn: PropTypes.any,
  onSubmit: PropTypes.func,
  fields: PropTypes.array,
};