import React from "react";

import Popup from "components/popups/Popup/Popup.jsx";

const DeleteTypePopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      title="Delete project type"
      text="Are you sure you want to delete this project type?"
      onClose={onClose}
      successBtnTitle="Delete"
      onClick={onSubmit}
      isDelete
      dialog
      subText="All current projects with this type will have the Custom type."
    />
  );
};

export default DeleteTypePopup;
