import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import Button from "components/elements/Button/Button";
import PropTypes from "prop-types";
import s from "./AdminSettingsFinancialSavingsDeleteForm.module.scss";
import CustomDropdown from "../../elements/CustomDropdown/CustomDropdown";
import Checkbox from "../../elements/Checkbox/Checkbox";
import services from "../../../services";

const AdminSettingsFinancialSavingsDeleteForm = (
  {
    onClose,
    onSubmit,
    typeId,
  }
) => {

  const [savingsTypes, setSavingsTypes] = useState([]);

  useEffect(() => {
    services.savingsTypeServices.getSavingsType().then(res => {
      if (res.data) {
        setSavingsTypes(
          res.data
            .filter(type => type.id !== typeId)
            .map(type => ({
              label: type.name,
              value: type.id
            }))
        );
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      savings_type_id: {},
      is_leave: false,
    },

    validate: ({is_leave, savings_type_id}) => {
      const errors = {};
      if (!is_leave && !savings_type_id?.value) {
        errors.savings_type_id = "Savings type is required";
      }
      return errors;
    },

    onSubmit: (values) => {
      onSubmit({
        savings_type_id: values.savings_type_id.value,
      });
      onClose();
    },
  });

  const {handleSubmit, errors, setFieldValue, values, touched} = formik;

  useEffect(() => {
    if (values.is_leave) {
      setFieldValue("savings_type_id", {value: "", label: ""});
    }
  }, [values.is_leave]);


  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className={s.field}>
        <CustomDropdown
          value={values.savings_type_id}
          onChange={e => setFieldValue("savings_type_id", e)}
          options={savingsTypes}
          name="savings"
          label="Savings type"
          placeholder="Choose type"
          disabled={values.is_leave}
          error={errors.savings_type_id && touched.savings_type_id && errors.savings_type_id}
        />
      </div>
      <div className={s.field}>
        <Checkbox
          label="Leave savings with deleted type"
          subLabel="Savings with a deleted type will be displayed only in pre-existing projects."
          checked={values.is_leave}
          onChange={() => setFieldValue("is_leave", !values.is_leave)}
        />
      </div>
      <div className="popupBtnsWrap">
        <Button styled="secondary" className="popupBtn" type="reset" onClick={onClose}>Cancel</Button>
        <Button styled="primary" type="submit" className="popupBtn">Delete</Button>
      </div>
    </form>
  );
};

export default AdminSettingsFinancialSavingsDeleteForm;

AdminSettingsFinancialSavingsDeleteForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};