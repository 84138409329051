import React from "react";
import Textarea from "../../Textarea/Textarea";
import PropTypes from "prop-types";
import FormConstructorInput from "./FormConstructorInput";

const FormConstructorTextarea = ({field, values, setFieldValue, touched, errors}) => {
  return (
    <div>
      <Textarea
        placeholder={field.placeholder}
        value={values[field.key]}
        onChange={e => setFieldValue(field.key, e.target.value)}
        label={field.title}
        count={{current: values[field.key]?.length, max: field.max}}
        error={touched[field.key] && errors[field.key]}
      />
    </div>
  );
};

export default FormConstructorTextarea;

FormConstructorTextarea.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  touched: PropTypes.any,
  errors: PropTypes.any
};