import React from "react";
import CategoryOverview from "./CategoryOverview/CategoryOverview";
import s from "./Category.module.scss";
import PreferredSuppliers from "./PreferredSuppliers/PreferredSuppliers";
import CategoryStakeholders from "./CategoryStakeholders/CategoryStakeholders";
import PropTypes from "prop-types";
import GanttChart from "./GanttChart/GanttChart";

const CategoryTab = ({id}) => {
  return (
    <div className={s.categoryTabWrapper}>
      <CategoryOverview id={id}/>
      <PreferredSuppliers id={id}/>
      <GanttChart id={id}/>
      <CategoryStakeholders id={id}/>
    </div>
  );
};
export default CategoryTab;

CategoryTab.propTypes = {
  id: PropTypes.string,
};