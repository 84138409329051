import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import {useHistory, useParams} from "react-router-dom";
import ProjectActualSavingsMonthNotesContainer
  from "../../containers/ProjectActualSavingsMonthNotesContainer/ProjectActualSavingsMonthNotesContainer";
import {useSelector} from "react-redux";
import {userRoles} from "../../../helpers/userPermissions/userRoles";

const ProjectActualSavingsMonthNotesPage = () => {
  const history = useHistory();
  const {id} = useParams();
  const idProject = history.location.state.from.idProject;
  const user = useSelector(state => state?.user);
  const isBusinessUser = user.roles.includes(userRoles.business_user);
  const isCategoryOwner = user.roles.includes(userRoles.category_business_owner);

  let breadcrumbs = [
    {
      name: "Projects",
      path: "/projects/",
      disabled: (isBusinessUser || isCategoryOwner),
    },
    {name: "Project details", path: `/projects/${idProject}`},
    {name: "Actual savings", path: `/projects/${idProject}/actual_savings`},
    {name: "Month"},
  ];
  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <ProjectActualSavingsMonthNotesContainer idProject={idProject} id={id}/>
    </DefaultLayout>
  );
};

export default ProjectActualSavingsMonthNotesPage;