import {months} from "./helpData"

export const getHeaderLabels = years => {
  const result = []
  if (years.length === 1) {
    result.push(...months)
  } else if (years.length > 1 && years.length < 4) {
    years.map(year => {
      result.push(`Q1 (${year})`, "Q2", "Q3", "Q4")
    })
  } else {
    result.push(...years)
  }
  return result
}

export const getMonthLabels = months => {
  const currentMonth = new Date().getUTCMonth();

  if (currentMonth === 3) return months;

  if (currentMonth < 3) {
    return [
      ...months.slice(12 - (3 - currentMonth), 12),
      ...months.slice(0, currentMonth),
      ...months.slice(currentMonth, 12 - (3 - currentMonth))
    ];
  }

  return [
    ...months.slice(currentMonth - 3, currentMonth),
    ...months.slice(currentMonth, 12),
    ...months.slice(0, currentMonth - 3)
  ];
}