import React from "react";
import sBase from "../Activities.module.scss";
import {formatDate} from "../../../../../../../helpers/dateFormat";
import DropDown from "./DropDown";
import {useTranslation} from "react-i18next";

const CompletedDropDown = ({data}) => {
  const { t } = useTranslation();
  return (
    <DropDown data={{name: data.name, status: null}}>
      <div className={sBase.activityInfo}>
        <div>
          <span data-translate-key="completion-date">{t("completion-date")}</span>
          <span>{formatDate(data.completion_date, true)}</span>
        </div>
        <div>
          <span data-translate-key="financial-benefit">{t("financial-benefit")}</span>
          <span>{`$ ${data.financial_benefit}`}</span>
        </div>
        <div>
          <span data-translate-key="project-days">{t("project-days")}</span>
          <span>{data.project_days}</span>
        </div>
        <div>
          <span data-translate-key="nps-score">{t("nps-score")}</span>
          <span>{`${data.nps_score}%`}</span>
        </div>
      </div>
    </DropDown>
  );
};

export default CompletedDropDown;



