import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../Checkbox/Checkbox";
import Attachments from "../../../forms/AddMilestoneTaskForm/components/Attachments/Attachments";
import services from "../../../../services";

const FormConstructorAttachments = ({field, values, setFieldValue}) => {

  const handleChangeAttachment = files => {
    setFieldValue(field.key, [...(values[field.key] || []), ...files]);
  };

  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(field.key, values[field.key].filter((_, idx) => idx !== index));
    }
  };

  return (
    <div>
      <Attachments
        onChange={handleChangeAttachment}
        onDelete={handleDeleteAttachment}
        attachments={values[field.key]}
      />
    </div>
  );
};

export default FormConstructorAttachments;

FormConstructorAttachments.propTypes = {
  field: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
};