import React, {Fragment, useEffect, useState} from "react"
import s from "./AddProjectAttachmentForm.module.scss"
import {useDropzone} from "react-dropzone"
import classNames from "classnames"
import Button from "components/elements/Button/Button"
import Input from "components/elements/Input/Input"
import {ReactComponent as UploadFile} from "assets/icons/upload-file.svg"
import {ReactComponent as DOCFile} from "assets/icons/doc-file.svg"
import {ReactComponent as DOCXFile} from "assets/icons/docx-file.svg"
import {ReactComponent as PDFFile} from "assets/icons/pdf-file.svg"
import {ReactComponent as PPTXFile} from "assets/icons/pptx-file.svg"
import {ReactComponent as XLSXFile} from "assets/icons/xlsx-file.svg"
import {ReactComponent as Delete} from "assets/icons/clear.svg"
import {ReactComponent as Error} from "assets/icons/error-warning.svg"
import {getFileIconByType} from "helpers/fileIconsByType"
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const AddProjectAttachmentForm = ({onClose, onSubmit}) => {
  const {t} = useTranslation();
  const [file, setFile] = useState(null)
  const [error, setError] = useState(false)
  const [fileName, setFileName] = useState("")
  const [fileNameError, setFileNameError] = useState(null)
  const FILE_NAME_MAX_LENGTH = 100

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragReject
  } = useDropzone({
    accept:
      "application/msword,.doc, application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation,.pptx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  })
  useEffect(() => {
    setFile(acceptedFiles[0])
    if (acceptedFiles.length) {
      setError(false)
    }
  }, [acceptedFiles])
  useEffect(() => {
    if (isDragReject) {
      setError(true)
    }
  }, [isDragReject])

  return (
    <form
      className={s.addAttachmentForm}
      onSubmit={e => {
        e.preventDefault()

        if (file) {
          const formData = new FormData()
          const blob = new Blob([file], {type: file.type})
          blob["fileName"] = fileName
            ? `${fileName}.${file.name.split(".")[1]}`
            : file.name
          blob["lastModifiedDate"] = file.lastModifiedDate

          formData.append("file", blob, blob["fileName"])
          onSubmit(formData)
        }
      }}
    >
      <div
        {...getRootProps()}
        className={classNames(s.dropzone, {[s.error]: error})}
      >
        <input {...getInputProps()} accept='.doc, .docx, .xlsx, .pptx, .pdf'/>
        {!file && !error && (
          <Fragment>
            <UploadFile className={s.icon}/>
            <span className={s.title}>
              <span className={s.bold} data-translate-key={getTranslateKey("Choose a file or drag it here")}>
                {t(getTranslateKey("Choose a file or drag it here"))}
              </span>{" "}
              <span className={s.docTypes}>(docx, doc, xlsx, pptx, pdf)</span>
            </span>
          </Fragment>
        )}
        {file && !error && (
          <span className={s.dropzoneTitle}>
            <span>Done !</span>
          </span>
        )}
        {error && (
          <Fragment>
            <Error/>
            <span className={s.message}>
              File could not be uploaded. Invalid file type.{" "}
              <span>Allowed file types: docx, doc, xlsx, pptx, pdf</span>
            </span>
            <span className={s.link}>Upload another file</span>
          </Fragment>
        )}
      </div>
      <Input
        className={s.fileNameField}
        value={fileName}
        onChange={e => {
          const val = e.target.value
          setFileName(val)
          if (val.length <= FILE_NAME_MAX_LENGTH) {
            setFileNameError(null)
          } else {
            setFileNameError(
              "Please enter Attachment name that consists of no more than 100 characters."
            )
          }
        }}
        label='Attachment name'
        error={fileNameError && fileNameError}
      />
      {file && (
        <div className={s.uploadedFile}>
          {getFileIconByType(file.type)}
          <span className={s.fileName}>
            {file.name
              .split(".")
              .slice(0, -1)
              .join(".")}
          </span>
          <Delete className={s.deleteFile} onClick={() => setFile(null)}/>
        </div>
      )}

      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={!file || Boolean(fileNameError)}
          styled='primary'
          type='submit'
          className='popupBtn'
        >
          Add
        </Button>
      </div>
    </form>
  )
}
export default AddProjectAttachmentForm
