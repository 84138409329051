import React, {useEffect, useRef, useState} from "react";
import s from "./ProjectStatuses.module.scss";
import services from "../../../../../../../../services";
import {backgroundTypes} from "../../../../../../../../helpers/helpData";
import useOutsideClicker from "../../../../../../../../hooks/useOutsideClicker";
import {useSelector} from "react-redux";
import {userRoles} from "../../../../../../../../helpers/userPermissions/userRoles";
import {checkRoles} from "../../../../../../../../helpers/checkRoles";
import classNames from "classnames";

const ProjectStatusesSelector = (
  {
    data,
    isOpen,
    setOpen,
    changeSelectorOption,
    projectOwnerId,
    status
  }
) => {
  const user = useSelector(state => state.user);
  const [options, setOptions] = useState([]);
  const optionListRef = useRef();

  const {admin, cpo, procurement_admin} = userRoles;
  const preparedRoles = [admin, cpo, procurement_admin];

  const isDisabled = !(user.id === projectOwnerId || checkRoles(user.roles, preparedRoles)) || status === "Completed";

  useEffect(() => {
    services.customStatusesServices.getOptions(data.id).then(res => {
      if (res.data) {
        setOptions(res.data);
      }
    });
  }, []);

  useOutsideClicker(optionListRef, e => {
    if (
      typeof e.target?.parentElement.className === "string" &&
      !e.target.parentElement?.className.includes("optionList")
    ) {
      setOpen(null);
    }
  });

  const renderSelectedOption = () => {
    if (!data.selected_option) {
      return "Select Option";
    }

    return (
      <React.Fragment>
        <div className={s.color} style={{background: backgroundTypes[data.selected_option.color]}}/>
        {data.selected_option.name}
      </React.Fragment>
    );
  };

  const filteredOptions = options.filter(option => option.id !== data.selected_option?.id);

  if (!filteredOptions.length) return null;

  return (
    <div className={s.selector}>
      <p className={s.title}>{data.name}:</p>
      <div className={classNames(s.selectedOption, {[s.disabled]: isDisabled})} onClick={() => {
        if (!isDisabled) {
          setOpen(isOpen ? null : data.id);
        }
      }}>
        {renderSelectedOption()}
      </div>
      {(isOpen && filteredOptions.length > 0) && (
        <div className={s.optionList} ref={optionListRef}>
          {filteredOptions.map(option => (
            <div
              key={option.id}
              className={s.option}
              onClick={() => changeSelectorOption(option.id)}
            >
              <div className={s.color} style={{background: backgroundTypes[option.color]}}/>
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectStatusesSelector;