import React from "react";
import Popup from "components/popups/Popup/Popup";
import PropTypes from "prop-types";
import AdminSettingsFinancialSavingsDeleteForm
  from "../../../../../../../forms/AdminSettingsFinancialSavingsDeleteForm/AdminSettingsFinancialSavingsDeleteForm";

const DeleteFinancialSavingsTypePopup = ({ onClose, onSubmit, typeId }) => {
  return (
    <Popup
      onClose={onClose}
      title="Delete financial savings type"
      text="Are you sure you want to delete this savings type? Please, choose where do you want to send savings with this type."
    >
      <AdminSettingsFinancialSavingsDeleteForm
        onClose={onClose}
        onSubmit={onSubmit}
        typeId={typeId}
      />
    </Popup>
  );
};

export default DeleteFinancialSavingsTypePopup;

DeleteFinancialSavingsTypePopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
