import React from "react"
import sBase from "../AdditionalQuestions.module.scss";
import Button from "../../../../elements/Button/Button";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const EmptyContent = ({addAction}) => {
  const {t} = useTranslation();
  return (
    <div className={sBase.emptyContent}>
      <p className={sBase.emptyNotification}data-translate-key={"you-don’t-have-conditions-yet.-you-can-create-a-new-one"}> {t(getTranslateKey("you-don’t-have-conditions-yet.-you-can-create-a-new-one"))}</p>
      <Button styled="addElement" onClick={() => addAction()}>New condition</Button>
    </div>
  )
}
export default EmptyContent;
