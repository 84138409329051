import React, { useEffect } from "react";
import ContactUs from "../../components/ContactUs/ContactUs";
import LandingLayout from "../../Landinglayout/Landinglayout";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <>
      <LandingLayout>
        <div style={{marginTop: "64px"}}>
          <ContactUs />
        </div>
        
      </LandingLayout>
    </>
  );
};

export default ContactUsPage;
