import React from "react"
import MainTable from "components/tables/MainTable/MainTable"
import { useHistory } from "react-router-dom"
import { formatCurrency } from "helpers/currencyFormat"
import {dateFormatterWithoutTimezone} from "helpers/dateFormat"

const ReportingTagContractsTable = ({
  data,
  perPage,
  onPageChange,
  currentPage,
  dataCount
}) => {
  const history = useHistory()
  return (
    <MainTable
      data={data}
      dataTypes={[
        {
          title: "Supplier",
          value: ({ supplier }) => supplier?.name || "-",
          hint: true,
          max: 22
        },
        {
          title: "Contract name",
          value: ({ name }) => name || "-",
          onClick: id => history.push(`/contract/details/${id}`),
          sticky: true
        },
        {
          title: "TCV (USD)",
          value: ({ total_value }) =>
            total_value !== null ? formatCurrency("$", total_value) : "-"
        },
        {
          title: "ACV (USD)",
          value: ({ annual_value }) =>
            annual_value !== null
              ? formatCurrency("$", annual_value)
              : "-",
          alignment: "right"
        },
        {
          title: "Start date",
          value: ({ start_date }) => dateFormatterWithoutTimezone(start_date) || "-"
        },
        {
          title: "End date",
          value: ({ end_date }) => dateFormatterWithoutTimezone(end_date) || "-"
        }
      ]}
      perPage={perPage}
      totalCount={dataCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  )
}
export default ReportingTagContractsTable
