import React from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import PropTypes from "prop-types";

const AdminSettingsFinancialSavingsGroupForm = (
  {
    onClose,
    onSubmit,
    defaultData,
  }
) => {

  const formik = useFormik({
    initialValues: {
      name: defaultData ? defaultData.name : "",
    },

    validationSchema: object({
      name: string().required("Savings group is required")
        .min(2,
          "Please enter savings group that consists of no less than 2 characters"
        )
        .max(50,
          "Please enter savings group that consists of no more than 50 characters"
        ),
    }),

    onSubmit: ({name}) => {
      onSubmit({name});
      onClose();
    },
  });

  const {handleSubmit, errors, setFieldValue, values, touched} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        label="Savings group"
        value={values.name}
        placeholder="Enter group name"
        onChange={(e) => {
          setFieldValue("name", e.target.value);
        }}
        error={touched.name && errors.name}
        limitValue={50}
        withCounter
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {defaultData ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default AdminSettingsFinancialSavingsGroupForm;

AdminSettingsFinancialSavingsGroupForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultData: PropTypes.any,
};