import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import styles from "./UserOrgChart.module.scss";
import UserOrgCard from "./components/UserOrgCard/UserOrgCard";
import services from "services";
import {formatData} from "./helpers/formatData";
import {useSelector} from "react-redux";
import Popup from "../../../../popups/Popup/Popup";
import ChangeTeamNameForm from "../../../../forms/ChangeTeamNameForm/ChangeTeamNameForm";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import querystring from "query-string";

const UserOrgChart = (
  {
    withTeamEdit = false,
    isTeam = false,
    withFilter = false,
    isReport = false
  }
) => {
  const params = useParams();
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector(state => state.user);
  const [currentChangeTeamUser, setCurrentChangeTeamUser] = useState(null);
  const history = useHistory();
  const query = querystring.parse(history.location.search);

  const noData = (
    <div className={styles.emptyOrgChart}>
      <div data-translate-key={getTranslateKey("The organization doesn't have a CPO user.")}>
        {t(getTranslateKey("The organization doesn't have a CPO user."))}
      </div>
      <div data-translate-key={getTranslateKey("The organization chart will be available after adding the CPO user.")}>
        {t(getTranslateKey("The organization chart will be available after adding the CPO user."))}
      </div>
    </div>
  )

  useEffect(() => {
    services.userServices.team_hierarchy(params.id ?? user.id).then((res) => {
      setData([formatData(res.data, 1, user)].map(item => item.lvl === 1 ? {...item, isOpenCard: true} : item));
      setLoading(false);
    });
  }, [params.id]);

  const isHasItem = (item, itemId) => {
    if (item.id === itemId) return true;
    return item.childrens ? item.childrens.some(item => isHasItem(item, itemId)) : false;
  }

  const hasOpenedChildren = (item, itemId) => {
    return {
      ...item,
      currentTree: isHasItem(item, itemId),
      childrens: item.childrens.map(item => hasOpenedChildren(item, itemId))
    }
  }

  const isOpenTree = (item, itemId) => {
    if (item.id === itemId && item.currentTree) return !item.isOpenCard;
    return item.childrens ? item.childrens.some(item => isHasItem(item, itemId)) : false;
  }

  const onOpenTree = (item, itemId) => {
    return {
      ...item,
      isOpenCard: isOpenTree(item, itemId),
      childrens: item.childrens.map(item => onOpenTree(item, itemId))
    }
  }

  const onOpenCardAction = itemId => {
    let preparedData = data.map(item => hasOpenedChildren(item, itemId));
    preparedData = preparedData.map(item => onOpenTree(item, itemId));
    setData(preparedData);
  }

  const findAndUpdateUser = (team_name, data) => {
    return data.map(user => {
      if (user.id === currentChangeTeamUser.id) return {...user, team_name};
      if (user.childrens?.length) return {...user, childrens: findAndUpdateUser(team_name, user.childrens)};
      return user;
    });
  }

  const updateTeamName = team_name => {
    services.userServices.changeTeamName(currentChangeTeamUser.id, {team_name}).then(res => {
      if (res.data) {
        const newUsers = findAndUpdateUser(res.data.team_name, data);
        setData(newUsers);
        setCurrentChangeTeamUser(null);
      }
    });
  }

  const renderOrgChart = () => {
    if (data[0]?.id) return (
      <UserOrgCard
        data={data}
        withTeamEdit={withTeamEdit}
        isTeam={isTeam}
        isReport={isReport}
        withFilter={withFilter}
        openCard={onOpenCardAction}
        openTeamEditModal={user => setCurrentChangeTeamUser(user)}
      />
    );
    return noData;
  }

  return (
    <>
      <div className={styles.wrapper}>
        {loading && (
          <div className="loaderWrap justifyCenter">
            <CircularProgress/>
          </div>
        )}
        {!loading && (
          <div className={styles.tree}>
            {renderOrgChart()}
          </div>
        )}
      </div>
      {
        currentChangeTeamUser && (
          <Popup
            title={isReport ? 'Rename team' : 'Edit team'}
            onClose={() => setCurrentChangeTeamUser(null)}
          >
            <ChangeTeamNameForm
              onSubmit={updateTeamName}
              onClose={() => setCurrentChangeTeamUser(null)}
              defaultValues={{team_name: currentChangeTeamUser.team_name}}
            />
          </Popup>
        )
      }
    </>
  );
};

export default UserOrgChart;
