import React, {useState, useEffect} from "react";
import s from "./TablePagination.module.scss";
import classnames from "classnames";
import {ReactComponent as SingleArrow} from "assets/icons/paginationArrowLeft.svg";
import {ReactComponent as LastPageArrow} from "assets/icons/lastPageArrow.svg";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const TablePagination = ({perPage, count, defaultPage, onPageChange}) => {
  const [page, setPage] = useState(1);
  const [totalePages, setTotalPages] = useState();
  const {t} = useTranslation();

  useEffect(() => {
    const pages = Math.ceil(count / perPage);
    setTotalPages(pages);
  }, [count, perPage]);

  useEffect(() => {
    setPage(Number(defaultPage));
  }, [defaultPage]);

  const handleChangePage = (page) => {
    const pageNumber = Number(page);
    setPage(pageNumber);
    onPageChange(pageNumber);
  };
  const firstNumber = perPage * page - (perPage - 1);
  const lastNumber = perPage * page > count ? count : perPage * page;

  return (
    <div className={s.paginationWrapper}>
      <span
        className={s.pagesInfo}
      >
        {`${firstNumber}-${lastNumber} `}
        <span data-translate-key={getTranslateKey("of")}>
          {t(getTranslateKey("of"))}
        </span>
        {` ${count}`}
      </span>
      <div className={s.arrows}>
        <span
          className={classnames(s.arrowBlock, s.firstPageIcon, {
            [s.disabled]: page * perPage <= perPage,
          })}
          onClick={() => {
            handleChangePage(1);
          }}
        >
          <LastPageArrow/>
        </span>
        <span
          className={classnames(s.arrowBlock, {
            [s.disabled]: page * perPage <= perPage,
          })}
          onClick={() => {
            handleChangePage(page - 1);
          }}
        >
          <SingleArrow/>
        </span>
        <span
          className={classnames(s.arrowBlock, s.nextPage, {
            [s.disabled]: page * perPage >= count,
          })}
          onClick={() => {
            handleChangePage(page + 1);
          }}
        >
          <SingleArrow/>
        </span>
        <span
          className={classnames(s.arrowBlock, {
            [s.disabled]: page * perPage >= count,
          })}
          onClick={() => {
            handleChangePage(totalePages);
          }}
        >
          <LastPageArrow/>
        </span>
      </div>
    </div>
  );
};

TablePagination.defaultProps = {
  perPage: 10,
  count: 100,
  defaultPage: 1,
};

export default TablePagination;
