import React from 'react';
import classNames from "classnames";
import s from "./EditTriageDetails.module.scss";
import {ReactComponent as HiddeIcon} from "../../../assets/icons/hidde.svg";
import {ReactComponent as ShowIcon} from "../../../assets/icons/show.svg";
import {ReactComponent as DNDIcon} from "../../../assets/icons/drag-icon.svg";
import {ReactComponent as ArrowLeft} from "../../../assets/icons/arrow-left.svg";
import CustomNotification from "../../elements/QuestionNotification/CustomNotification";
import {sentences} from "../../containers/AdminSettingsRequestsContainer/TriageTab/helpers/sentences";
import {useTranslate} from "../../../hooks/useTranslate";

const HierarchyItem = (
  {
    item,
    index,
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleDragEnd,
    isLast,
    isHide = false,
    hide,
    show,
  }
) => {
  const [translateKey, translateValue] = useTranslate(item.name);
  const mainItem = (
    <div
      draggable
      className={classNames(s.hierarchyItem, "dndBox", {[s.margin]: isHide})}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnd={handleDragEnd}
      key={index}
      data-dndid={index}
    >
      <span data-translate-key={translateKey}>
        {translateValue}
      </span>
      {isHide ?
        <CustomNotification
          Icon={ShowIcon}
          value={sentences.notifications.showHierarchyItem}
          withTranslate
          onClick={() => show(item)}
        /> :
        <CustomNotification
          Icon={HiddeIcon}
          value={sentences.notifications.hideHierarchyItem}
          onClick={() => hide(item)}
          withTranslate
        />
      }
    </div>
  );

  if (isHide) return mainItem;

  return (
    <>
      <div className={s.hierarchyItemWrap}>
        <DNDIcon/>
        {mainItem}
      </div>
      {!isLast && <ArrowLeft className={s.arrow}/>}
    </>
  );
};

export default HierarchyItem;