import React from 'react'
import s from '../PrivacyPage.module.scss'

const Point03 = () => {
	return (
		<div id="point03" className={s.pointBlock}>
              <h3 className={s.subtitle}>
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </h3>

              <p className={s.text} style={{ fontStyle: "italic" }}>
                <span>In Short: </span>We only share information with your
                consent, to comply with laws, to provide you with services, to
                protect your rights, or to fulfill business obligations.
              </p>

              <p className={s.text}>
                We may process or share your data that we hold based on the
                following legal basis: <br />
                <span>• Consent:</span>We may process your data if you have
                given us specific consent to use your personal information for a
                specific purpose.
              </p>

              <p className={s.text}>
                <span>• Legitimate Interests:</span>We may process your data
                when it is reasonably necessary to achieve our legitimate
                business interests.
              </p>

              <p className={s.text}>
                <span>• Performance of a Contract:</span>Where we have entered
                into a contract with you, we may process your personal
                information to fulfill the terms of our contract.
              </p>

              <p className={s.text}>
                <span>• Legal Obligations:</span>We may disclose your
                information where we are legally required to do so in order to
                comply with applicable law, governmental requests, a judicial
                proceeding, court order, or legal process, such as in response
                to a court order or a subpoena (including in response to public
                authorities to meet national security or law enforcement
                requirements).
              </p>

              <p className={s.text}>
                <span>• Vital Interests:</span>We may disclose your information
                where we believe it is necessary to investigate, prevent, or
                take action regarding potential violations of our policies,
                suspected fraud, situations involving potential threats to the
                safety of any person and illegal activities, or as evidence in
                litigation in which we are involved.
              </p>

              <p className={s.text}>
                More specifically, we may need to process your data or share
                your personal information in the following situations: <br />
                <span>• Business Transfers. </span>We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </p>

              <p className={s.text}>
                <span>• Business Partners. </span>We may share your information
                with our business partners to offer you certain products,
                services or promotions
              </p>
            </div>
	)
}

export default Point03
