import React, {useEffect, useState} from 'react';
import services from "../../../services";
import TableLoading from "../../loadingStates/Table/Table";
import {useParams} from "react-router-dom";
import TimeSpent from "./TimeSpent/TimeSpent";
import ProjectTaskBreakdown from "./ProjectTaskBreakdown/ProjectTaskBreakdown";
import styles from "./ReportTimeSpendWidget.module.scss";

const ReportTimeSpendWidget = ({type}) => {
  const params = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    services.reportServices.getReportTimeSpent(type, params.id).then(res => {
      if (res.data) setData(res.data);
    });
  }, []);

  if (!data) return <TableLoading length={2}/>;

  return (
    <section className={styles.timeSpentWrap}>
      <TimeSpent data={{
        milestones: data.milestones,
        total_project_time: data.total_project_time
      }}/>
      <ProjectTaskBreakdown data={{
        departments: data.departments,
        total_tasks_count: data.total_tasks_count
      }}/>
    </section>
  );
};

export default ReportTimeSpendWidget;