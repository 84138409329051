import axios from "./axios";

export default {
  getSavingsSettings: async () => {
    try {
      return await axios.get("/admin/savings/settings");
    } catch (error) {
      throw error;
    }
  },
  putSavingsSettings: async values => {
    try {
      return await axios.put("/admin/savings/settings", values);
    } catch (error) {
      throw error;
    }
  },

};
