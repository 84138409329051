import React, {useEffect, useState} from "react";
import services from "../../../../services";
import {useHistory} from "react-router-dom";
import SupplierUsersTable from "../../../tables/SupplierUsersTable/SupplierUsersTable";
import Popup from "../../../popups/Popup/Popup";
import SupplierUsersForm from "./Popups/SupplierUsersForm";
import ResetUserPasswordForm from "../../../forms/ResetUserPasswordForm/ResetUserPasswordForm";
import AdminSettingsSupplierUsersFilter
  from "../../../filters/AdminSettingsSupplierUsersFilter/AdminSettingsSupplierUsersFilter";
import qs from "query-string";


const SupplierUsers = () => {
  const [supplierUsers, setSupplierUsers] = useState([]);
  const [perPage, setPerPage] = useState();
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSupplier, setCurrentSupplier] = useState({});
  const [popup, setPopup] = useState(null);
  const history = useHistory();
  const query = qs.parse(history.location.search);

  useEffect(() => {
    services.userServices.getAdminSupplierUsers({...query}).then(res => {
      if (res.data) {
        setSupplierUsers(res.data.supplier_users);
        setPerPage(res.data.per_page);
        setTotalCount(res.data.total_count);
      }
    });
  }, [history.location]);

  const deleteAction = () => {
    services.userServices
      .deleteAdminSupplierUsers(currentSupplier)
      .then(() => {
        setSupplierUsers(supplierUsers.filter(user => user.id !== currentSupplier));
      });
    setPopup(null);
  };

  const addUserSupplier = (values) => {
    services.userServices
      .postAdminSupplierUsers(values)
      .then(res => {
        setSupplierUsers([...supplierUsers, res.data]);
      });
    setPopup(null);
  };

  const editUserSupplier = (values) => {
    services.userServices
      .putAdminSupplierUsers(values, currentSupplier.id)
      .then(res => {
        setSupplierUsers(supplierUsers.map(user => user.id === currentSupplier.id ? res.data : user));
      });
    setPopup(null);
  };

  const resetUserSupplierPassword = (values) => {
    services.userServices
      .putAdminSupplierUsers(values, currentSupplier)
      .then(() => {
      });
    setPopup(null);
  };

  return (
    <section>
      <div>
        <AdminSettingsSupplierUsersFilter/>
        {supplierUsers.length > 0 &&
          <SupplierUsersTable
            data={supplierUsers}
            perPage={perPage}
            totalCount={totalCount}
            currentPage={currentPage}
            onEdit={(item) => {
              setCurrentSupplier(item);
              setPopup("onEdit");
            }}
            onAdd={(item) => {
              setCurrentSupplier(item);
              setPopup("onAdd");
            }}
            onDelete={(item) => {
              setCurrentSupplier(item);
              setPopup("delete");
            }}
            onResetPass={(item) => {
              setCurrentSupplier(item);
              setPopup("reset-pass");
            }}
          />}
      </div>
      {popup === "delete" && (
        <Popup
          onClose={() => setPopup(null)}
          title="Delete user"
          text="Are you sure you want to delete this user?"
          onClick={() => deleteAction()}
          dialog
          isDelete
        />
      )}
      {popup === "onAdd" && (
        <Popup
          onClose={() => setPopup(null)}
          title="New user"
          text="Please, complete the following fields to create a new supplier user"
        >
          <SupplierUsersForm
            onSubmit={(values) => addUserSupplier(values)}
            onClose={() => setPopup(null)}
          />
        </Popup>
      )}
      {popup === "onEdit" && (
        <Popup
          onClose={() => setPopup(null)}
          title="Edit user"
        >
          <SupplierUsersForm
            onSubmit={(values) => editUserSupplier(values)}
            defaultValues={currentSupplier}
            onClose={() => setPopup(null)}
          />
        </Popup>
      )}
      {popup === "reset-pass" && (
        <Popup title="Reset password"
               text="Change user password"
               onClose={() => setPopup(null)}
        >
          <ResetUserPasswordForm
            onClose={() => setPopup(null)}
            onSubmit={(values) => resetUserSupplierPassword(values)}
          />
        </Popup>
      )}
    </section>
  );
};

export default SupplierUsers;