import React from "react";
import PropTypes from "prop-types"
import classNames from "classnames";
import { ReactComponent as Linkedin } from "../../assets/icons/social/Linkedin.svg";
import { ReactComponent as Twitter } from "../../assets/icons/social/Twitter.svg";
import { ReactComponent as Facebook } from "../../assets/icons/social/Facebook.svg";
import { ReactComponent as LinkCopy } from "../../assets/icons/social/LinkCopy.svg";
import s from "./Social.module.scss";

const Social = ({ className }) => {
  return (
    <div className={classNames(s.social, className)}>
      <Linkedin className={s.socialIcon} />
      <Twitter className={s.socialIcon} />
      <Facebook className={s.socialIcon} />
      <LinkCopy className={s.socialIcon} />
    </div>
  );
};

Social.propTypes = {
  className: PropTypes.any
}

Social.defaultProps = {
  className: ""
}

export default Social;
