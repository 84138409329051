import React from "react"
import styles from "./TeamReportContainer.module.scss";
import TeamReportDetail from "./components/TeamReportDetail/TeamReportDetail";
import TeamReportGeneral from "./components/TeamReportGeneral/TeamReportGeneral";
import CompanyGoalContainer from "../CompanyGoalContainer/CompanyGoalContainer";
import TeamReportPieChart from "./components/TeamReportPieChart/TeamReportPieChart";
import TeamReportProjects from "./components/TeamReportProjects/TeamReportProjects";
import ReportTimeSpendWidget from "../../elements/ReportTimeSpendWidget/ReportTimeSpendWidget";
import TeamReportTimeSpent from "./components/TeamReportTimeSpent/TeamReportTimeSpent";

const TeamReportContainer = () => {
  return (
    <main className={styles.container}>
      <TeamReportDetail/>
      <TeamReportGeneral/>
      <CompanyGoalContainer
        className={styles.detailBlock}
        isRequest={true}
        source='team'
        withFilters={false}
        withEdit={false}
      />
      <TeamReportTimeSpent/>
      <TeamReportPieChart/>
      <TeamReportProjects/>
    </main>
  )
}

export default TeamReportContainer;
