import React, {useEffect, useState} from "react";
import {questionType, statuses} from "../../../helpers/types";
import s from "../QuestionsContent.module.scss";
import dragIcon from "../../../../../../assets/../assets/icons/drag-icon.svg";
import trashIcon from "../../../../../../assets/../assets/icons/trash.svg";
import Checkbox from "../../../../../elements/Checkbox/Checkbox";
import CustomDropdown from "../../../../../elements/CustomDropdown/CustomDropdown";
import SingleQuestionOpenAnswer from "./SingleQuestionOpenAnswer";
import SingleQuestionSingleChoice from "./SingleQuestionSingleChoice";
import SingleQuestionYesOrNo from "./SingleQuestioneYesOrNo";
import {deleteQuestion, updateLocalQuestion} from "../../../helpers/actions";
import {questionsType} from "../../../helpers/data";

const SingleQuestion = ({question, dispatch, provided, status, questionsLength}) => {
  const [isEdit, setEdit] = useState(false);
  const [currentType, setCurrentType] = useState(question.question_type);

  useEffect(() => {
    setEdit(false);
    if (status === statuses.static) {
      updateLocalQuestion(dispatch, question);
    }
  }, [status]);

  useEffect(() => {
    updateLocalQuestion(dispatch, {...question, question_type: currentType});
  }, [currentType]);

  const changeStatus = event => {
    if (event.target.dataset.dragItem) {
      status === statuses.edit && setEdit(!isEdit);
    }
  };

  const renderContent = () => {
    if (question.question_type === questionType.text) {
      return <SingleQuestionOpenAnswer
        currentQuestion={question}
        isEdit={isEdit}
        updateQuestion={question => updateLocalQuestion(dispatch, question)}
      />;
    }

    if (question.question_type === questionType.radio) {
      return <SingleQuestionSingleChoice
        currentQuestion={question}
        isEdit={isEdit}
        dispatch={dispatch}
        updateQuestion={question => updateLocalQuestion(dispatch, question)}
      />;
    }

    if (question.question_type === questionType.checkbox) {
      return <SingleQuestionYesOrNo
        currentQuestion={question}
        isEdit={isEdit}
        dispatch={dispatch}
        updateQuestion={question => updateLocalQuestion(dispatch, question)}
      />;
    }
  };

  return (
    <div
      onClick={event => changeStatus(event)}
      className={s.question}
      style={provided.draggableProps.style}
      ref={provided.innerRef}
      data-drag-item="drag"
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {(isEdit && status === statuses.edit) && <img className={s.dragIcon} src={dragIcon}/>}
      <div className={s.contentWrapper} data-drag-item="drag">
        {renderContent()}
        {
          (isEdit && status === statuses.edit) &&
          <div className={s.controlPanel} data-drag-item="drag">
            <CustomDropdown
              options={questionsType}
              value={questionsType.find(q => currentType === q.value)}
              onChange={({value}) => setCurrentType(value)}
            />
          </div>
        }
      </div>
      {
        (isEdit && status === statuses.edit) &&
        <div className={s.controlPanelFooter} data-drag-item="drag">
          <button
            className={s.delete}
            disabled={questionsLength <= 1}
            onClick={() => deleteQuestion(dispatch, question.question_id)}>
            <img src={trashIcon}/>
          </button>
          <div className={s.verticalDivide}/>
          <Checkbox
            inputProps={{"aria-label": "primary checkbox"}}
            label="Required question"
            checked={question.is_required}
            onChange={() => updateLocalQuestion(dispatch, {...question, is_required: !question.is_required})}
          />
        </div>
      }
    </div>
  );

};
export default SingleQuestion;
