import React, {useEffect, useState} from "react"
import EmptyContent from "../EmptyContent/EmptyContent";
import Content from "../Content/Content";
import Popup from "../../../../popups/Popup/Popup";
import {addNewSupplierStatus, deleteSupplierStatus, loadData} from "../helpers/actions/main";
import SupplierStatusForm from "./SupplierStatusForm";
import AdditionalQuestionsLoading
  from "../../../../loadingStates/AdditionalQuestionsLoading/AdditionalQuestionsLoading";

const SupplierStatus = ({data, dispatch}) => {
  const [loading, setLoading] = useState(true);
  const [isOpenPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    loadData(dispatch, 'supplierStatusList');
    setLoading(false);
  }, []);

  const addNewCondition = value => addNewSupplierStatus(dispatch, {supplier_type: value});
  const deleteCondition = id => deleteSupplierStatus(dispatch, id);

  const getUpperValue = value => value.split('_').join(' ').charAt(0).toUpperCase() + value.split('_').join(' ').slice(1)

  const serializeData = data => {
    return data.map(s => {
      return {
        name: getUpperValue(s.supplier_type),
        questions_count: s.additional_questions_count,
        id: s.id,
      }
    });
  }

  return (
    <>
      {
        loading ? <AdditionalQuestionsLoading/> : (
          data.length ?
            <Content
              data={serializeData(data)}
              type='Supplier status'
              addAction={() => setOpenPopUp(true)}
              deleteAction={id => deleteCondition(id)}
            /> : <EmptyContent addAction={() => setOpenPopUp(true)}/>
        )
      }
      {
        isOpenPopUp &&
        <Popup
          onClose={() => setOpenPopUp(false)}
          title="New condition"
        >
          <SupplierStatusForm
            onSubmit={value => addNewCondition(value.supplier.value)}
            onClose={() => setOpenPopUp(false)}
            suppliersList={data}
          />
        </Popup>
      }
    </>
  );
}
export default SupplierStatus;
