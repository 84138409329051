import React from "react";
import PropTypes from 'prop-types'
import classNames from "classnames";
import s from "./CheckedLine.module.scss";
import {useTranslate} from "../../../../hooks/useTranslate";

const CheckedLine = ({ index, item, className }) => {
  const [translateLabelKey, translateLabelValue] = useTranslate(item);
  return (
    <li key={index} className={classNames(s.item, className)}>
      <span className={s.itemText} data-translate-key={translateLabelKey}>{translateLabelValue}</span>
    </li>
  );
};

CheckedLine.propTypes = {
  index: PropTypes.number,
  item: PropTypes.string,
  className: PropTypes.any
}

CheckedLine.defaultProps = {
  index: 1,
  item: "Test item checked line",
  className: PropTypes.any
}

export default CheckedLine;
