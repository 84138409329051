import React from "react";
import classNames from "classnames";
import {NavLink, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import s from "./CustomLink.module.scss";
import {useTranslate} from "../../../hooks/useTranslate";

const CustomLink = (
  {
    path,
    children,
    className,
    type,
    exact,
    activeClassName,
    style,
    onClick,
    subLinks,
    activeRoutes,
    linkInStart
  }
) => {
  const location = useLocation();
  const [translateKey, translateValue] = useTranslate(children);
  const clearPath = path.split('?')[0];
  const isActiveLink = () => {
    if (!subLinks && clearPath === '/') return false;
    else if (linkInStart) {
      return !location.pathname.indexOf(clearPath);
    } else if (subLinks) {
      const activeSubLinks = subLinks
        .filter(link => link.activeRoutes ? link.activeRoutes.filter(e => e?.exact ? location.pathname === e.name : location.pathname.includes(e.name)).length : link?.exact ? link.link === location.pathname : location.pathname.includes(link.link));
      return Boolean(activeSubLinks.length)
    } else if (activeRoutes) {
      return Boolean(activeRoutes.filter(e => e?.exact ? location.pathname === e.name : location.pathname.includes(e.name)).length)
    } else {
      return exact ? location.pathname === clearPath : location.pathname.includes(clearPath);
    }
  };
  const navClasses = classNames(s.link, className, {
    [activeClassName]: isActiveLink(),
  });
  const linkClasses = classNames(s.link, className);
  return (
    <>
      {type === "nav-link" && (
        <NavLink
          to={path}
          className={navClasses}
          exact={exact}
          style={style}
          onClick={onClick}
          data-translate-key={translateKey}
        >
          {translateValue}
        </NavLink>
      )}
      {type === "link" && (
        <a
          href={path}
          className={linkClasses}
          target="_blank"
          rel="noopener noreferrer"
          data-translate-key={translateKey}
        >
          {translateValue}
        </a>
      )}
    </>
  );
};

CustomLink.propTypes = {
  path: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CustomLink.defaultProps = {
  path: "/",
  children: "",
  type: "nav-link",
};

export default CustomLink;
