import React from "react";
import AuthenticationLayout from "components/layouts/AuthenticationLayout/AuthenticationLayout";
import PasswordResetContainer from "components/containers/PasswordResetContainer/PasswordResetContainer";

const PasswordResetPage = () => {
  return (
    <AuthenticationLayout>
      <PasswordResetContainer />
    </AuthenticationLayout>
  );
};

export default PasswordResetPage;
