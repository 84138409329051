import React from 'react';
import styles from "./ProjectTaskBreakdown.module.scss";
import DepartmentItem from "./DepartmentItem";

const ProjectTaskBreakdown = ({data}) => {
  const totalDaysCount = data.departments.reduce((acc, cur) => acc + cur.spend_by_department, 0);
  const totalTasksCount = data.departments.reduce((acc, cur) => acc + cur.tasks_count_by_department, 0);

  return (
    <div className={styles.projectTaskBreakdownWrap}>
      <h4>Project task breakdown</h4>
      <div className={styles.projectTaskBreakdownList}>
        {data.departments.map(department => <DepartmentItem key={department.id} data={department}/>)}
      </div>
      <div className={styles.totalTasks}>
        <p>Total - {totalTasksCount} task{totalTasksCount > 1 && "s"}</p>
        <div className={styles.spent}>{totalDaysCount} day{totalDaysCount > 1 && "s"}</div>
      </div>
    </div>
  );
};

export default ProjectTaskBreakdown;