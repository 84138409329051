import React, {useEffect, useState} from "react"
import FilterBar from "components/elements/FilterBar/FilterBar"
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown"
import qs from "query-string"
import {debounce} from "lodash"
import {useHistory} from "react-router-dom"
import {getAllIds} from "components/elements/FilterBar/helpers/helpers"
import services from "../../../services";

const SystemReportsTagsFilter = ({className}) => {
  const history = useHistory();
  const [savedFilter, setSavedFilter] = useState(null);

  useEffect(() => {
    services.filterService.getFilter("system_report_tags").then(res => {
      if (res.data && res.data[0]) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
      }
    });
  }, []);

  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  )

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values)
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction,
    }
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    })
  }

  const handleClearAll = () => {
    const searchQuery = {page, tab, sort_by, direction}
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    })
  }

  const saveDefaultFilter = () => {
    services.filterService.addFilter("system_report_tags", {
      filter_params: JSON.stringify(query)
    }).then(res => {
      setSavedFilter(res.data);
    });
  }

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  }

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...query,
      ...JSON.parse(filterParams ?? savedFilter.filter_params),
      page: 1,
      tab,
      sort_by,
      direction,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  }


  const handleSearch = debounce(term => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  return (
    <FilterBar
      filterName='Filter tags'
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      withSearch
    >
      <FilterBarDropdown
        name='Type'
        defaultValues={query?.type}
        options={[
          {
            name: "Tag",
            id: "tag",
          },
          {
            name: "Flag",
            id: "flag",
          }
        ]}
        useAutoHeight={true}
        handleChange={values => {
          handleChangeFilter("type", values)
        }}
      />
    </FilterBar>
  )
}

export default SystemReportsTagsFilter
