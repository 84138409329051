import React, {useEffect, useState} from "react";
import {array, object, string} from "yup";
import {useFormik} from "formik";
import CustomDropDownsFormHeader from "./components/CustomDropDownsFormHeader/CustomDropDownsFormHeader";
import s from "./CustomDropDownsForm.module.scss";
import Input from "../../elements/Input/Input";
import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import CustomDropdown from "../../elements/CustomDropdown/CustomDropdown";
import AdditionalRadioButtonSelector
  from "../RequestForm/components/SingleStep/AdditionalRadioButtonSelector/AdditionalRadioButtonSelector";
import services from "../../../services";
import DropdownTree from "../../elements/DropdownTree/DropdownTree";

const CustomDropDownsForm = ({changeDropDown, defaultValues}) => {
  const {t} = useTranslation();
  const source = [
    {
      value: "custom",
      label: "Custom"
    },
    {
      value: "type_of_spend",
      label: "Type of spend"
    },
    {
      value: "category",
      label: "Category"
    },
  ];
  const [categories, setCategories] = useState({
    entityList: [],
    type: defaultValues?.source || source[0].value
  });
  const [type, setType] = useState();

  useEffect(() => {
    services.categoriesServices.getCategories().then(res => {
      setCategories({
          entityList: res.data,
          type: defaultValues?.source || source[0].value
        }
      );
    });
  }, [type]);


  const loadCategory = (type) => {
    return (
      services.categoriesServices.getCategories().then(res => {
        setCategories({
            entityList: res.data,
            type: type
          }
        );
      })
    );
  };


  const formik = useFormik({
    initialValues: {
      typeRadioButton: {
        singleChoice: defaultValues === undefined ? true : defaultValues?.type === "single" ? true : false,
        multipleChoice: defaultValues?.type === "multiple" ? true : false
      },
      name: defaultValues?.name || "",
      source: defaultValues?.source || source[0].value,
      options: typeof defaultValues?.options[0] === "object" ? defaultValues?.options.map(option => option) : defaultValues?.options || [],
    },
    validationSchema: object({
      name: string().required("Name is required"),
      source: string().required("Source is required"),
      options: array().of(string().required("Please, fill the option value")),
    }),
    validate: ({options}) => {
      let errors = {};
      if (!options.length) {
        errors.optionCount = {
          value: "Please add option"
        };
      }
      return errors;
    },
    onSubmit: (values) => {
      return (
        changeDropDown({
          ...values,
          options: values.options[0]?.id
            ? values.options.map(id => {
              if (id.id) {
                return id.id;
              } else {
                return id;
              }
            })
            : values.options,
          option_ids: values.options[0]?.id
            ? values.options.map(id => {
              if (id.id) {
                return id.id;
              } else {
                return id;
              }
            })
            : values.options,
          type: values.typeRadioButton.singleChoice ? "single" : "multiple",
        }));
    },
  });

  const {
    errors,
    handleSubmit,
    setFieldValue,
    touched,
    values,
  } = formik;

  const renderSourceSelect = ({option, index}) => {
    if (categories.type === "custom") {
      return (
        <Input
          className={s.input}
          value={option}
          placeholder="Enter option value"
          onChange={event =>
            setFieldValue("options", values.options.map((option, idx) =>
              idx === index ? event.target.value : option
            ))
          }
          error={errors.options?.length && touched.options && errors.options[index]}
        />
      );
    }
    if (categories.type === "category") {
      return (
        <DropdownTree
          withSearch={true}
          defaultValue={option}
          onSelect={event =>
            setFieldValue("options", values.options.map((option, idx) =>
              idx === index ? event.id : option
            ))
          }
          name="category"
          className={s.fieldCategory}
          options={categories.entityList}
          placeholder="Start typing category name"
          label={false}
          error={errors.options?.length && touched.options && errors.options[index]}
        />
      );
    }
    if (categories.type === "type_of_spend") {
      const nameOptional = {label: option.name, value: option.id};
      return (
        <CustomDropdown
          options={categories.entityList.map(e => ({value: e.id, label: e.name}))}
          onChange={event =>
            setFieldValue("options", values.options.map((option, idx) =>
              idx === index ? event.value : option
            ))
          }
          value={nameOptional.label}
          name="category"
          className={s.fieldCategory}
          withSearch={true}
          placeholder="Category name"
          arrow={false}
          error={errors.options?.length && touched.options && errors.options[index]}
        />
      );
    }
  };

  const handleSelectSource = (e) => {
    if (e.value === "custom") {
      setFieldValue("source", "custom");
      values.options.length = 0;
      setCategories({
        entityList: [],
        type: "custom"
      });
    }

    if (e.value === "type_of_spend") {
      values.options.length = 0;
      setFieldValue("source", "type_of_spend");
      values.source = "";
      loadCategory("type_of_spend");
    }
    if (e.value === "category") {
      values.options.length = 0;
      setFieldValue("source", "category");
      loadCategory("category");
    }
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <h2  className={s.header}  data-translate-key={"requests"}> {t(getTranslateKey("requests"))}</h2>
      <div className={s.formContent}>
        <CustomDropDownsFormHeader isUpdate={Boolean(defaultValues?.id)}/>
        <div className={s.field}>
          <div className={s.fieldInput}>
            <Input
              label="Name"
              className={s.fieldInputName}
              value={values.name}
              placeholder="Enter dropdown name"
              onChange={e => setFieldValue("name", e.target.value)}
              error={touched.name && errors.name}
            />
            <CustomDropdown
              value={values.source}
              onChange={e => handleSelectSource(e)}
              options={source}
              name="Source"
              label="Source"
              className={s.fieldInputSource}
              error={touched.source && errors.source}
            />
          </div>
          <AdditionalRadioButtonSelector values={values} setFieldValue={setFieldValue} errors={errors}/>
        </div>
        <ul className={s.options}>
          {values.options.map((option, index) => {
            return (
              <li key={index} className={s.optionWrap}>
                {renderSourceSelect({option, index})}
                <Close
                  className={s.deleteBtn}
                  onClick={() => {
                    setFieldValue("options", values.options.filter((_, idx) => idx !== index));
                  }}
                />
              </li>
            );
          })}
        </ul>
        <div className={s.addOption}
             onClick={() => setFieldValue("options", [...values.options, ""])}
             data-translate-key={"add-new-option"}>+ {t(getTranslateKey("add-new-option"))}
          {errors.optionCount && <p className={s.error}>{errors.optionCount.value}</p>}
        </div>
      </div>
    </form>
  );
};

export default CustomDropDownsForm;

CustomDropDownsForm.propTypes = {
  changeDropDown: PropTypes.func,
  defaultValues: PropTypes.any,
};