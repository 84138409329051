import React, {useCallback, useState} from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import Button from "components/elements/Button/Button";
import PropTypes from "prop-types";
import Input from "../../elements/Input/Input";
import {debounce} from "lodash";
import services from "../../../services";
import AutoCompleteInput from "../../elements/AutoCompleteInput/AutoCompleteInput";
import styles from "./StakeholderForm.module.scss";

const StakeholderForm = ({close, submit, defaultValues, usedStakeholderIds}) => {
  const [stakeholders, setStakeholders] = useState([]);

  const formik = useFormik({
    initialValues: {
      stakeholder: defaultValues?.stakeholder || {},
      role: defaultValues?.role || ""
    },
    validationSchema: object({
      stakeholder: object({
        id: string().required("Stakeholder is required")
      }),
      role: string()
        .required("Please, write a role")
        .max(50, "Role cannot have length greater than 50")
    }),
    onSubmit: ({stakeholder, role}) => {
      submit({
        stakeholder_id: stakeholder.id,
        role
      });
    }
  });

  const {handleSubmit, touched, values, errors, setFieldValue} = formik;

  const searchStakeholders = useCallback(
    debounce(
      (term) =>
        services.userServices
          .stakeholdersAutocomplete({query: term})
          .then((res) => setStakeholders(res.data.filter(s => !usedStakeholderIds.includes(s.id)))),
      300
    ),
    []
  );

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      {
        !defaultValues && (
          <AutoCompleteInput
            className={styles.field}
            data={stakeholders}
            value={values.stakeholder?.name || ""}
            placeholder="Start typing stakeholder’s name"
            onChange={(e) => {
              setFieldValue("stakeholder", {
                id: "",
                name: e.target.value,
              });
              searchStakeholders(e.target.value);
            }}
            onSelect={(value) => {
              setFieldValue("stakeholder", {
                id: value.id,
                name: value.name,
              });
            }}
            label="Stakeholder"
            emptyText={{
              text: "Stakeholder not found",
            }}
            error={errors.stakeholder && touched.stakeholder && errors.stakeholder?.id}
          />
        )
      }
      <Input
        value={values.role}
        onChange={e => setFieldValue("role", e.target.value)}
        name="role"
        type="text"
        placeholder="Enter stakeholder role"
        label="Role"
        error={errors.role && touched.role && errors.role}
        withCounter
        limitValue={50}
      />
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={close}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          {defaultValues ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default StakeholderForm;

StakeholderForm.propTypes = {
  close: PropTypes.func,
  submit: PropTypes.func,
  defaultValues: PropTypes.any,
  usedStakeholderIds: PropTypes.array
};