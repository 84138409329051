import React, {useState, useRef, useEffect} from "react";
import classNames from "classnames";
import s from "./FilterBarDropdownYears.module.scss";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";
import styles from "components/elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown.module.scss";
import useOutsideClicker from "hooks/useOutsideClicker";
import {CircularProgress} from "@material-ui/core";
import {useTranslate} from "../../../../hooks/useTranslate";

const FilterBarDropdownYears = (
  {
    name,
    handleChange,
    clearAllFilters,
    setClearAllFilters,
    setFilterValue,
    options,
    defaultValues,
    className
  }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(defaultValues);
  const [translateYearKey, translateYearValue] = useTranslate(name);
  const dropdownRef = useRef(null);

  useOutsideClicker(dropdownRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setCurrent(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (defaultValues?.length && options?.length) {
      setFilterValue(name, true);
    }
  }, [options, defaultValues]);

  useEffect(() => {
    if (clearAllFilters) {
      setFilterValue(name, false);
      setClearAllFilters(false);
      setCurrent(new Date().getFullYear() - 1);
    }
  }, [clearAllFilters]);

  const handleSelect = (item) => {
    setCurrent(item);
    setIsOpen(false);
    handleChange(item);
    setFilterValue(name, item);
  };

  return (
    <div
      className={classNames(styles.filterDropdownWrapper, {
        [styles.filterListOpened]: isOpen,
      })}
      ref={dropdownRef}
    >
      <div
        className={styles.nameBlock}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {current ? (
          <p className={styles.selectedOption}>{`${current}`}</p>
        ) : (
          <p data-translate-key={translateYearKey}>{translateYearValue}</p>
        )}
        <span
          className={classNames(styles.arrowIcon, {
            [styles.listOpen]: isOpen,
          })}
        >
          <Arrow/>
        </span>
      </div>
      {isOpen && (
        <div className={classNames(styles.openedList, s.openedList,{
          [className]: className,
        })}>
          <ul className={s.expiresInWrap}>
            {options ? (
              options.map((item, index) => (
                <li
                  className={classNames(s.item, {
                    [s.active]: current === item,
                  })}
                  onClick={() => handleSelect(item)}
                  key={index}
                >
                  {item}
                </li>
              ))
            ) : (
              <div
                className="loaderWrap justifyCenter"
                style={{marginTop: "24px"}}
              >
                <CircularProgress/>
              </div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
export default FilterBarDropdownYears;
