import React from "react";
import Popup from "components/popups/Popup/Popup";

const DeleteNotificationPopup = ({
  isDeleteAll,
  isDeleteSeveral,
  onClose,
  onClick,
}) => {
  const message = () => {
    if (isDeleteAll)
      return "Are you sure you want to delete all sentences?";
    if (isDeleteSeveral)
      return "Are you sure you want to delete these sentences?";
    return "Are you sure you want to delete this notification?";
  };

  return (
    <Popup
      title="Delete notification"
      text={message()}
      onClose={onClose}
      onClick={onClick}
      dialog
      isDelete
    />
  );
};

export default DeleteNotificationPopup;
