import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { mixed, object, string } from "yup"
import styled from "styled-components"

import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput"
import Button from "components/elements/Button/Button"
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown"

const AddProjectStakeholdersForm = ({
  users,
  stakeholders,
  stakeholderTypes,
  addHandler,
  searchHandler,
  stakeholder,
  onClose
}) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const _users = users.filter(
      user => !stakeholders.filter(item => item.id === user.id).length
    )
    setData(_users)
  }, [stakeholders, users])

  const initialValues = {
    stakeholder_id:
      stakeholder?.id === "string"
        ? stakeholder.id
        : {
            name: stakeholder?.user?.name || "",
            id: stakeholder?.user?.id || ""
          },
    type: {
      value: stakeholder?.stakeholder_type?.id || "",
      label: stakeholder?.stakeholder_type?.name || ""
    }
  }

  const validationSchema = object({
    stakeholder_id:
      stakeholder?.id === "string"
        ? string().required()
        : object({
            name: string().required("Please, enter stakeholder name"),
            id: string().required("Please, choose the stakeholder")
          }),
    type: object({
      value: string().required("Stakeholder type is required"),
      label: string().required("Stakeholder type is required")
    }).required()
  })

  const onSubmit = values => {
    addHandler({
      stakeholder_id:
        stakeholder?.id === "string"
          ? stakeholder.id
          : values.stakeholder_id.id,
      stakeholder_type_id: values.type.value
    })
    onClose()
  }

  const { handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      {typeof stakeholder?.id !== "string" && (
        <FieldWrap>
          <AutoCompleteInput
            data={data}
            placeholder="Start typing stakeholder's name"
            value={values.stakeholder_id.name}
            onChange={e => {
              setFieldValue("stakeholder_id", {
                id: "",
                name: e.target.value
              })
              searchHandler(e.target.value)
            }}
            onSelect={value => {
              setFieldValue("stakeholder_id", {
                id: value.id,
                name: value.name
              })
            }}
            label='Stakeholder'
            error={
              errors.stakeholder_id &&
              touched.stakeholder_id &&
              (errors.stakeholder_id.name || errors.stakeholder_id.id)
            }
          />
        </FieldWrap>
      )}

      <FieldWrap>
        <CustomDropdown
          options={stakeholderTypes}
          value={values.type.label}
          onChange={option =>
            setFieldValue("type", {
              value: option.value,
              label: option.label
            })
          }
          label='Type'
          error={
            errors.type &&
            touched.type &&
            (errors.type.value || errors.type.label)
          }
          placeholder='Choose stakeholder type'
        />
      </FieldWrap>

      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          Add
        </Button>
      </div>
    </form>
  )
}

export default AddProjectStakeholdersForm

const FieldWrap = styled.div`
  margin-bottom: 16px;
`
