import React  from "react"
import MainTable from "components/tables/MainTable/MainTable"
import { useHistory } from "react-router-dom"
import { fromDate } from "helpers/dateFormat"
import { formatCurrency } from "helpers/currencyFormat"
import qs from "query-string"


const ContractsTable = ({
  state,
  perPage,
  totalCount,
  onPageChange,
  onAdd,
  currentPage,
  onDelete,
  havePermissions,
}) => {
  const history = useHistory()
  const query = qs.parse(history.location.search)

  const onSort = ({ sort_by, direction, tag }) => {
    history.push({
      search: qs.stringify({ ...query, sort_by, direction, tag, page: 1 })
    })
  }

  return (
    <MainTable
      data={state.contracts}
      withXScroll={true}
      dragIdLabel='contract'
      dataTypes={[
        {
          title: "Supplier",
          value: ({ supplier }) => supplier.name,
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "supplier_name", direction })
          }
        },
        {
          title: "Contract name",
          value: ({ name }) => name,
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "name", direction })
          },
          onClick: id => history.push(`/contract/details/${id}`),
          sticky: true
        },
        {
          title: "Total contract value (USD)",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "total_value", direction })
          },
          value: ({ total_value_currency, total_value }) => {
            return total_value && total_value_currency
              ? formatCurrency("$", total_value)
              : "-"
          }
        },
        {
          title: "Annual contract value (USD)",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "annual_value", direction })
          },
          value: ({ annual_value_currency, annual_value }) => {
            return annual_value && annual_value_currency
              ? formatCurrency("$", annual_value)
              : "-"
          }
        },
        {
          title: "Start date",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "start_date", direction })
          },
          value: ({ start_date }) => (start_date ? fromDate(start_date) : "-")
        },
        {
          title: "End date",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "end_date", direction })
          },
          value: ({ end_date }) => (end_date ? fromDate(end_date) : "-")
        },
      ]}
      options={item => {
        return [
          havePermissions?.destroy &&
            onDelete && {
              title: "Remove",
              onClick: () => {
                onDelete(item)
              }
            },
        ]
      }}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
      button={
        havePermissions?.update &&
        onAdd && {
          name: "Add contract",
          onClick: () => onAdd()
        }
      }
    />
  )
}
export default ContractsTable
