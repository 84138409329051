import React from "react";
import CheckedLine from '../../../../../components/CheckedLine/CheckedLine'
import PropTypes from "prop-types";
import s from "./Slide.module.scss";
import {useTranslate} from "../../../../../../../hooks/useTranslate";

const Slide = ({ img, title, list }) => {
  const [translateLabelKey, translateLabelValue] = useTranslate(title);
  return (
    <div className={s.slide}>
      <div className={s.wrapImg}>
        <img src={img} alt="cardImg" />
      </div>

      <div className={s.cardContent}>
        <h4 className={s.title} data-translate-key={translateLabelKey}>{translateLabelValue}</h4>
        <ul className={s.list}>
          {list &&
            list.map((item, index) => (
              <CheckedLine key={index} item={item} className={s.item}  />
            ))}
        </ul>
      </div>
    </div>
  );
};

Slide.propTypes = {
  img: PropTypes.any,
  titile: PropTypes.string,
  list: PropTypes.array,
};

Slide.defaultProps = {
  img: "",
  title: "",
  list: [],
};

export default Slide;
