import React from "react";
import s from "./RequestStagesTab.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import {ReactComponent as Check} from "../../../../../../assets/icons/gantt-chart/timeline-check.svg";
import {ReactComponent as Rejected} from "assets/icons/rejected.svg";
import {dateFormatterWithoutTimezone, formatDate} from "../../../../../../helpers/dateFormat";

const RequestStagesTab = (
  {
    currentStage,
    stages,
    status,
    stageDates,
    assigned,
    created,
    isCompleted,
  }
) => {

  const isActive = index => {
    if (isCompleted) return true;
    if (!currentStage) return index === 0 && created;
    if (!currentStage) return index === 1 && assigned;
    return index < stages.map(stage => stage.id).indexOf(currentStage.id) + 1;
  };

  const getStageDate = (id, name) => {
    const stage = stageDates.find(stage => stage.stage.id === id);

    if (!stage && name === "Created")
      return created ? dateFormatterWithoutTimezone(created) : "--";

    if (!stage && name === "Assigned to procurement")
      return assigned ? dateFormatterWithoutTimezone(assigned) : "--";

    if (stage) {
      return dateFormatterWithoutTimezone(stage.data);
    }

    return "--";
  };

  return (
    <div className={s.stagesWrapper}>
      <div className={s.timeline}>
        <div className={s.lineWrap}>
          {stages.map(
            ({id, name}, index) => {
              return (
                <div
                  key={id}
                  className={classNames(s.statusBarWrap, {
                    [s.active]: isActive(index),
                    [s.rejected]: status === "rejected" && index === 1,
                  })}
                >
                  <span className={s.statusBar}>
                    {(status === "rejected" && index === 1) ? <Rejected/> : <Check/>}
                    <span className={s.stageName}>{name}</span>
                    <span className={s.stageData}>{getStageDate(id, name)}</span>
                  </span>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestStagesTab;

RequestStagesTab.propTypes = {
  currentStage: PropTypes.any,
  stages: PropTypes.array,
  status: PropTypes.string,
  stageDates: PropTypes.string,
  assigned: PropTypes,
  created: PropTypes.string,
  isCompleted: PropTypes.bool,
};
