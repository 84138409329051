import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import ActivityFilter from "components/filters/ActivityFilter/ActivityFilter";
import ActivityTable from "components/tables/ActivityTable/ActivityTable";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import styles from "./ActivityContainer.module.scss";
import services from "services";
import { getAllIds } from "components/elements/FilterBar/helpers/helpers";
import TableLoading from "components/loadingStates/Table/Table";
import {useTranslation} from "react-i18next";

const ActivityContainer = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const [activity, setActivity] = useState({
    project_activities: [],
    project_activities_count: 0,
  });
  const [filters, setFilters] = useState({ project_ids: "", user_ids: "" });
  const [loading, setLoading] = useState(true);

  const query = qs.parse(history.location.search);

  useEffect(() => {
    services.projectServices
      .projectsActivity({ ...query, ...filters })
      .then((res) => {
        setActivity(res.data);
        setLoading(false);
      });
  }, [history.location]);

  const onFiltersChange = (filters) => {
    let user_ids = getAllIds(filters.users).join(",");
    let project_ids = getAllIds(filters.projects).join(",");

    setFilters({ user_ids, project_ids });
    history.push({ search: qs.stringify({ ...query, page: 1 }) });
  };
  return (
    <div className={styles.container}>
      <h2 className={styles.title} data-translate-key="activity">{t("activity")}</h2>
      <ActivityFilter
        className={styles.filterBar}
        onFiltersChange={onFiltersChange}
      />
      {loading && <TableLoading length={5} />}
      {!loading &&
        (activity.project_activities_count ? (
          <ActivityTable
            data={activity.project_activities}
            inlineWrapClassName={styles.inlineWrap}
            totalCount={activity.project_activities_count}
            currentPage={query?.page || 1}
          />
        ) : (
          <EmptyBlock title="You don’t have any activity yet." message />
        ))}
    </div>
  );
};

export default ActivityContainer;
