import React from "react";
import { useHistory } from "react-router-dom";
import HeaderUserMenuTrigger from "./HeaderUserMenuTrigger";
import s from "./HeaderUserMenu.module.scss";
import { logout } from "actions/user";
import { connect } from "react-redux";

const HeaderUserMenu = ({ userName, logout }) => {
	const initialLetter = userName.split("")[0].toUpperCase();
	const history = useHistory();
	return (
		<HeaderUserMenuTrigger
			data={[
				{
					title: "My profile",
					onClick: () => history.push("/profile")
				},
				{
					title: "Sign out",
					onClick: () => logout().then(() => {
						window.sessionStorage.removeItem("currentMenuTab");
						history.push("/");
					})
				}
			]}
			initialLetter={initialLetter}
			className={s.dropList}
		/>
	);
};

export default connect(null, { logout })(HeaderUserMenu);
