import React from "react";
import CheckedLine from "../../../../components/CheckedLine/CheckedLine";
import s from "./CMSection.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const CMSection = () => {
  const {t} = useTranslation();
  return (
    <section className={s.cmSection}>
      <div className={s.landingContainer}>

        <div className={s.wrapper}>
          <div className={s.imgBlock}></div>

          <div className={s.content}>
            <h2 className={s.subTitle} data-translate-key={"category-management"}>
              {t(getTranslateKey("category-management"))}
            </h2>

            <p className={s.text}
               data-translate-key={"facilitating-the-process-for-procurement-and-users-to-develop-category-strategies,-category-plans-and-developing-category-metrics-and-kpis."}>
              {t(getTranslateKey("facilitating-the-process-for-procurement-and-users-to-develop-category-strategies,-category-plans-and-developing-category-metrics-and-kpis."))}
            </p>

            <CheckedLine item="Know all internal procurement category metrics"/>

            <CheckedLine
              item="Develop category plans taking category risk, dependency, and market factors into consideration"/>

            <CheckedLine item="Collaborate with internal stakeholders to align procurement and corporate goals"/>
          </div>

        </div>
      </div>

    </section>
  );
};

export default CMSection;
