import React, {useContext} from "react";
import s from "./ApprovalDetailsContainer.module.scss";
import PropTypes from "prop-types";
import Detail from "../../elements/Detail/Detail";
import Tabs from "../../elements/Tabs/Tabs";
import ApproversTable from "../../tables/ApproversTable/ApproversTable";
import Conditions from "./Conditions/Conditions";
import {CircularProgress} from "@material-ui/core";
import services from "services";
import Status from "../../elements/Status/Status";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import {delay} from "../../../helpers/delay";
import {appendFile} from "../../../helpers/appendFile";
import AlertContext from "../../../contexts/AlertContext/AlertContext";
import {approvalTypes} from "../../../helpers/approvalsDefaultData";

const ApprovalDetailsContainer = ({data, load}) => {
  const {setAlert} = useContext(AlertContext);

  const sendAnswer = (approverId, {status, note, attachments}) => {
    services.approvalServices.sendAnswer(data.id, approverId, {status}).then(res => {
      if (res.data) {
        services.noteServices.createNote(res.data.id, {
          description: note,
        }).then(res => {
          Promise.all([...attachments.map(attachment => {
            const file = appendFile(attachment);
            services.taskServices.addAttachment(file, res.data.id).then(res => res.data);
          })]).finally(() => delay(500).then(() => load()));
        }).catch((err) => {
          setAlert({
            message: err.response?.data.error ||
              "Something went wrong. Please, try again.",
            type: "error"
          });
        });
      }
    }).catch((err) => {
      setAlert({
        message: err.response?.data.error ||
          "Something went wrong. Please, try again.",
        type: "error"
      });
    });
  }

  if (!data) return (
    <div className="loaderWrap justifyCenter alignCenter">
      <CircularProgress/>
    </div>
  );

  const renderTabs = () => {
    const conditionsTab = (Boolean(data.conditions?.condition_groups?.length)) && (
      <div label="Conditions">
        <Conditions conditions={data.conditions.condition_groups}/>
      </div>
    );
    if (conditionsTab) return (
      <Tabs styled="primary">
        <div label="Approvers">
          <ApproversTable
            data={data.atomic_approvers}
            approvalGroupType={data.approval_group_type}
            sendAnswer={sendAnswer}
          />
        </div>
        {conditionsTab}
      </Tabs>
    );
    return (
      <Tabs styled="primary">
        <div label="Approvers">
          <ApproversTable
            data={data.atomic_approvers}
            approvalGroupType={data.approval_group_type}
            sendAnswer={sendAnswer}
          />
        </div>
      </Tabs>
    );
  }

  const getApprover = () => {
    if (data.approval_group) return data.approval_group.name;
    return data.atomic_approvers[0]?.approver.name;
  }

  return (
    <div className={s.wrap}>
      <header>
        <h2 className={s.title}>{data.name}</h2>
        <Status status={data.status} withTranslate/>
      </header>
      <Detail
        className={s.details}
        data={[
          {
            name: "Request type",
            value: capitalizeFirstLetter(data.request_type.replace("_", " "))
          },
          {
            name: "Approver",
            value: getApprover() ?? "-"
          },
          {
            name: "Approval type",
            value: approvalTypes.find(a => a.value === data.approval_group_type).label
          },
          {
            name: "Project tab",
            value: capitalizeFirstLetter(data.project_tab.replace("_", " ")),
          },
        ]}
      />
      {renderTabs()}
    </div>
  );
};

export default ApprovalDetailsContainer;

ApprovalDetailsContainer.propTypes = {
  approvalId: PropTypes.string,
};