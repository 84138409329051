import {approverTypes} from "../../../../helpers/approvalsDefaultData";
import {getConditionValues} from "../../../../helpers/conditionValues";

export const validator = ({approver, approval_group, approval_type, conditions}) => {
  let errors = {};

  if (approver?.value === approverTypes[0].value) {
    if (!approval_group?.value) {
      errors.approval_group = {
        value: "Approval Group is required",
      };
    }

    if (!approval_type?.value) {
      errors.approval_type = {
        value: "Approval Type is required",
      };
    }
  }

  const singleConditions = conditions.map(cG => cG.condition_group).flat(1);
  const foundedSingleCondition = singleConditions.find(singleCondition => {
    const {generalValue, generalCondition, fieldValue} = getConditionValues(singleCondition.value);
    return (!Boolean(generalValue.trim()) || !Boolean(generalCondition.trim()) || !Boolean(fieldValue.trim()));
  });

  if (foundedSingleCondition) {
    errors.conditions = "Please fill in all fields";
  }

  return errors;
};