import React from "react";
import FilterBar from "components/elements/FilterBar/FilterBar";
import FilterBarDropdown from "../../elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import {debounce} from "lodash";
import {getAllIds} from "../../elements/FilterBar/helpers/helpers";
import services from "../../../services";

const SuppliersFilter = (
  {
    filters,
    className,
    showDefaultFilter,
    setSavedFilter,
    savedFilter
  }
) => {

  const history = useHistory();
  const {page, tab, sort_by, direction, ...query} = qs.parse(
    history.location.search
  );

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values)
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
      page: 1,
      tab,
      sort_by,
      direction,
    }
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    })
  }

  const handleClearAll = () => {
    const searchQuery = {page, tab, sort_by, direction};
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleSearch = debounce((term) => {
    const searchQuery = qs.parse(history.location.search);
    history.push({
      search: qs.stringify(
        {...searchQuery, query: term},
        {
          arrayFormat: "comma",
        }
      ),
    });
  }, 300);

  const saveDefaultFilter = () => {
    services.filterService.addFilter("suppliers", {filter_params: JSON.stringify(query)}).then(res => {
      setSavedFilter(res.data);
    });
  }

  const deleteDefaultFilter = () => {
    services.filterService.deleteFilter(savedFilter.id).then(() => setSavedFilter(null));
  }

  return (
    <FilterBar
      filterName="Filter suppliers"
      handleClearAll={handleClearAll}
      className={className}
      onSearch={handleSearch}
      searchDefaultValue={query.query || ""}
      savedFilter={savedFilter}
      saveDefaultFilter={saveDefaultFilter}
      resetDefaultFilter={deleteDefaultFilter}
      showDefaultFilter={showDefaultFilter}
      withSearch
    >
      <FilterBarDropdown
        name="Status"
        options={filters.status}
        defaultValues={query?.statuses?.split(",")}
        handleChange={(values) => {
          handleChangeFilter("statuses", values);
        }}
      />
      <FilterBarDropdown
        name="Categories"
        searchPlaceholder="Search category"
        defaultValues={query?.category_ids?.split(",")}
        options={filters.categories}
        handleChange={(values) => {
          handleChangeFilter("category_ids", values);
        }}
      />
    </FilterBar>
  );
};

export default SuppliersFilter;
