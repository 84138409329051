import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Button from "components/elements/Button/Button"
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown"
import { getTimeOptions } from "./data"

const NotificationSettings = ({ data, onChange }) => {
  const [emailSentTime, setEmailSentTime] = useState(String(data));
  const isDisabledBtn = String(data) === emailSentTime;
  useEffect(() => {
    emailSentTime !== data && setEmailSentTime(String(data))
  }, [data]);
  return (
    <Wrapper>
      <CustomDropdown
        options={getTimeOptions()}
        value={emailSentTime}
        onChange={({ value }) => setEmailSentTime(value)}
        label='Receive emails at'
        placeholder='Choose a time'
      />
      <ButtonHolder>
        <Button
          disabled={isDisabledBtn}
          styled='primary'
          onClick={() => onChange({ email_sent_time: +emailSentTime })}
        >
          Save
        </Button>
      </ButtonHolder>
    </Wrapper>
  )
}

export default NotificationSettings

const Wrapper = styled.div`
  max-width: 400px;
`

const ButtonHolder = styled.div`
  margin-top: 80px;
`
