import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput";
import Button from "components/elements/Button/Button";
import s from "./AddLinkedProjectsForm.module.scss";
import Textarea from "components/elements/Textarea/Textarea";
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown";
import { projectLinkedTypes } from "helpers/helpData";

const AddLinkedProjectsForm = ({
  projects,
  linkedProjects,
  addHandler,
  onClose,
  searchHandler,
}) => {
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      projects.filter(
        (project) =>
          !linkedProjects.filter((item) => item.id === project.id).length
      )
    );
  }, [linkedProjects, projects]);

  const formik = useFormik({
    initialValues: {
      type: "",
      id: "",
      note: "",
    },
    validationSchema: object({
      type: string().required("Please, choose the linked type"),
      id: string().required("Please, choose the project"),
      note: string()
        .required("Please enter note")
        .max(180, "The Note is too long. It should be 180 characters or less."),
    }),
    onSubmit: (values) => {
      addHandler({
        linked_project_id: values.id,
        note: values.note,
        linked_type: values.type,
      });
      onClose();
    },
  });

  const { handleSubmit, touched, values, errors, setFieldValue } = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className={s.field}>
        <CustomDropdown
          options={projectLinkedTypes}
          value={values.type}
          onChange={({ value }) => setFieldValue("type", value)}
          label="Linked type"
          placeholder="Choose Linked Type"
          error={errors.type && touched.type && errors.type}
        />
      </div>
      <AutoCompleteInput
        data={data}
        value={value}
        placeholder="Start Typing Project's name"
        onChange={(e) => {
          setValue(e.target.value);
          searchHandler(e.target.value);
        }}
        onSelect={(value) => {
          setFieldValue("id", value.id);
          setValue(value.name);
        }}
        label="Project name"
        error={touched.id && errors.id}
      />
      <div className={s.wrap}>
        <Textarea
          label="Note"
          placeholder="Enter..."
          value={values.note}
          onChange={(e) => setFieldValue("note", e.target.value)}
          error={touched.note && errors.note}
          name="note"
          count={{
            current: values.note.length,
            max: 180,
          }}
        />
      </div>
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          Add
        </Button>
      </div>
    </form>
  );
};

export default AddLinkedProjectsForm;
