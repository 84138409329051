import React, { useState, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import s from "./HeaderNotifications.module.scss";
import useOutsideClicker from "hooks/useOutsideClicker";
import HeaderNotificationDropList from "../HeaderNotificationDropList/HeaderNotificationDropList";

const HeaderNotificationsItem = ({
  notifications,
  count,
  onNotificationsLoad,
  onDelete,
  onViewport,
  loading,
  type,
}) => {
  const [visualList, setVisualList] = useState(false);
  const menuRef = useRef(null);

  useOutsideClicker(menuRef, () => setVisualList(false));

  return (
    <div className={s.headerNotifications} ref={menuRef}>
      <div
        className={classNames({
          [s.empty]: !count.all,
          [s.bell]: type === "regular",
          [s.business]: type === "business",
        })}
        onClick={() => {
          setVisualList((visualList) => !visualList);
          !visualList && onNotificationsLoad(type);
        }}
      >
        {count.new > 0 && <div className={s.count}>{count.new}</div>}
      </div>

      {!!count.all && visualList && (
        <div className={s.wrapDropList}>
          {loading ? (
            <div className="loaderWrap">
              <CircularProgress />
            </div>
          ) : (
            <HeaderNotificationDropList
              data={notifications}
              handleCloseList={() => setVisualList(false)}
              onDelete={onDelete}
              onViewport={onViewport}
            />
          )}
        </div>
      )}
    </div>
  );
};

HeaderNotificationsItem.propTypes = {
  notifications: PropTypes.array,
  count: PropTypes.objectOf(PropTypes.number),
};

HeaderNotificationsItem.defaultProps = {
  notifications: [],
  count: {
    all: 0,
    new: 0,
  },
};

export default HeaderNotificationsItem;
