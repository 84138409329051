export const projectTabs = [
  {
    label: "Attachments",
    value: "attachments",
  },
  {
    label: "Contracts",
    value: "contracts",
  },
  {
    label: "Financial benefits",
    value: "financial_benefits",
  },
  {
    label: "Financial details",
    value: "financial_details",
  },
  {
    label: "Linked projects",
    value: "linked_projects",
  },
  {
    label: "Milestones",
    value: "milestones",
  },
  {
    label: "Non financial benefits",
    value: "non_financial_benefits",
  },
  {
    label: "Stakeholders",
    value: "stakeholders",
  },
  {
    label: "Suppliers",
    value: "suppliers",
  },
];

export const approvalTypes = [
  {
    label: "Sequential",
    value: "sequential",
  },
  {
    label: "Parallel one",
    value: "one_of_the_group",
  },
  {
    label: "Parallel all",
    value: "by_all",
  },
];
