import React from "react"
import MainTable from "../MainTable/MainTable"
import { useHistory } from "react-router-dom"
import querystring from "query-string"
import { currencyData } from "helpers/helpData"
import { formatCurrency } from "helpers/currencyFormat"
import { fromDate } from "helpers/dateFormat"

const SystemReportsProjectsTable = ({ data, projectsCount, perPage = 30 }) => {
  const history = useHistory()
  const query = querystring.parse(history.location.search)

  const onPageChange = page => {
    history.push({ search: querystring.stringify({ ...query, page }) })
  }

  const onSort = ({ sort_by, direction }) => {
    history.push({
      search: querystring.stringify({ ...query, sort_by, direction, page: 1 })
    })
  }

  return (
    <MainTable
      stickyHeader={true}
      data={data}
      totalName='Closed projects'
      fullHeight={true}
      dataTypes={[
        {
          title: "Project name",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "name", direction })
          },
          value: ({ name }) => name,
          onClick: id => history.push(`/system-reports/projects/${id}`),
          sticky: true
        },
        {
          title: "Project type",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "project_type", direction })
          },
          value: ({ project_type }) => project_type?.name || "-"
        },
        {
          title: "Owner",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "owner_name", direction })
          },
          value: ({ owner }) => owner?.name || "-"
        },
        {
          title: "Start date",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "start_date", direction })
          },
          value: ({ start_date }) => fromDate(start_date)
        },
        {
          title: "End date",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "end_date", direction })
          },
          value: ({ end_date }) => fromDate(end_date)
        },

        {
          title: "Baseline Spend",
          value: ({ baseline_spend, currency }) =>
            baseline_spend && currency
              ? formatCurrency(
                  currencyData.filter(e => e.label === currency)[0]?.value,
                  baseline_spend
                )
              : "-",
          alignment: "right"
        },
        {
          title: "New Spend",
          value: ({ savings, currency }) =>
            savings && currency
              ? formatCurrency(
                  currencyData.filter(e => e.label === currency)[0]?.value,
                  savings
                )
              : "-",
          alignment: "right"
        }
      ]}
      perPage={perPage}
      totalCount={projectsCount}
      onPageChange={onPageChange}
      page={query.page || 1}
      isPagination={true}
    />
  )
}
export default SystemReportsProjectsTable
