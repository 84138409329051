import React from "react";
import s from "../TasksTable/TasksTable.module.scss";
import {fromDate} from "../../../helpers/dateFormat";
import MainTable from "../MainTable/MainTable";
import querystring from "query-string";
import {useHistory} from "react-router-dom";
import qs from "query-string";
import Status from "../../elements/Status/Status";

const TasksSelfTable = ({tasks, perPage}) => {
  const history = useHistory();
  const query = qs.parse(history.location.search);

  const onSort = ({ sort_by, direction }) => {
    history.push({
      search: querystring.stringify({ ...query, sort_by, direction, page: 1 })
    })
  }

  const onPageChange = (page) => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  return (
    <MainTable
      className={s.nameColumn}
      data={tasks.tasks}
      perPage={perPage}
      totalCount={tasks.tasks_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={Number(query.page)}
      useMaxHeightLarge={true}
      dataTypes={[
        {
          title: "Task",
          value: ({name}) =>  name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          onClick: (id, tasks) => {
            history.push(`/projects/${tasks.projects[0].id}/milestones/${tasks.milestone.id}/tasks/${id}`);
          },
        },
        {
          title: "Project",
          value: ({projects}) => projects[0].name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "project_name", direction});
          },
        },
        {
          title: "Start date",
          value: ({ start_date }) =>
            start_date ? fromDate(start_date) : "-",
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "start_date", direction })
          }
        },
        {
          title: "End date",
          value: ({ end_date }) => (end_date ? fromDate(end_date) : "-"),
          sortable: true,
          handleSort: ({ direction }) => {
            onSort({ sort_by: "end_date", direction })
          }
        },
        {
          title: "Status",
          value: ({task_status}) => <Status status={task_status.name} background={task_status.color}/>,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "status", direction});
          },
        },
      ]}
    />
  );
};

export default TasksSelfTable;