import React from "react";
import s from "./GeneralInformation.module.scss";
import sBase from "../../ContractDetailsContainer.module.scss";
import Wrapper from "../Wrapper/Wrapper";
import {formatDate} from "../../../../../helpers/dateFormat";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

const GeneralInformation = ({params}) => {
  const {t} = useTranslation();
  const {index, business_owner, procurement_owner, start_date, end_date} = params;

  return (
    <Wrapper>
      <div className={s.header}>
        <h4 className={sBase.sectionTitle} data-translate-key="general-information">
          {t("general-information")}
        </h4>
        <span className={s.index}>{index}</span>
      </div>
      <div className={s.infoWrap}>
        <div>
          <div className={s.infoBlock}>
            <p className={s.title} data-translate-key="business-contract-owner">
              {t("business-contract-owner")}:
            </p>
            <p className={s.value}>{business_owner.name}</p>
          </div>
          <div className={s.infoBlock}>
            <p className={s.title} data-translate-key="procurement-contract-owner">
              {t("procurement-contract-owner")}:
            </p>
            <p className={s.value}>{procurement_owner.name}</p>
          </div>
        </div>
        <div>
          <div className={s.infoBlock}>
            <p className={s.title} data-translate-key="start-date">
              {t("start-date")}:
            </p>
            <p className={s.value}>{formatDate(start_date, true)}</p>
          </div>
          <div className={s.infoBlock}>
            <p className={s.title} data-translate-key="end-date">
              {t("end-date")}:
            </p>
            <p className={s.value}>{formatDate(end_date, true)}</p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
    ;
};

export default GeneralInformation;

GeneralInformation.propTypes = {
  params: PropTypes.any,
};
