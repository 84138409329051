import React from "react"
import Popup from "components/popups/Popup/Popup.jsx"
import ChangeProjectStageForm from "components/forms/ChangeProjectStageForm/ChangeProjectStageForm"

const EditStagePopup = ({ onClose, onSubmit, defaultValues }) => {
  return (
    <Popup title='Edit project stage' onClose={onClose}>
      <ChangeProjectStageForm
        defaultValues={defaultValues}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Popup>
  )
}

export default EditStagePopup
