import React from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import UserFormWrapper from "components/wrappers/UserFormWrapper/UserFormWrapper";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import CustomLink from "components/elements/CustomLink/CustomLink";
import s from "./RequestPasswordResetForm.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const RequestPasswordResetForm = ({onSubmit: handleSubmitProp}) => {
  const {t} = useTranslation();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: object({
      email: string()
        .email("Email is invalid")
        .required("Email is invalid")
        .max(
          32,
          "Please enter email address that consists of no more than 32 characters"
        ),
    }),
    onSubmit: ({email}, {setSubmitting, setFieldError}) => {
      handleSubmitProp({email})
        .then(() => {
          setSubmitting(false);
        })
        .catch((e) => {
          setFieldError("email", e.response.data.error);
          setSubmitting(false);
        });
    },
  });

  const {handleSubmit, touched, values, errors, setFieldValue} = formik;

  return (
    <UserFormWrapper>
      <h1 className={s.title} data-translate-key={getTranslateKey("We need to verify your identity")}>
        {t(getTranslateKey("We need to verify your identity"))}
      </h1>

      <p className={s.labelInputEmail} data-translate-key={getTranslateKey("Please enter your email")}>
        {t(getTranslateKey("Please enter your email"))}
      </p>

      <form className={s.form} onSubmit={handleSubmit}>
        <div className={s.inputWrap}>
          <Input
            type="email"
            placeholder="Enter your Email"
            onChange={(e) => setFieldValue("email", e.target.value)}
            value={values.email}
            error={touched.email && errors.email}
          />
        </div>

        <div className={s.buttonWrap}>
          <Button styled="secondary" type="text" className={s.cancelButton}>
            <CustomLink type="nav-link" path="/login" className={s.cancelLink}>
              Cancel
            </CustomLink>
          </Button>

          <Button styled="primary" type="submit">
            Get link
          </Button>
        </div>
      </form>
    </UserFormWrapper>
  );
};

export default RequestPasswordResetForm;
