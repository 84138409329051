import {generateUniqueId} from "../../../../helpers/uniqueId";

export const addSingleCondition = (conditions, conditionId) => {
  return conditions.map(condition => {
    if (condition.id === conditionId) {
      return {
        ...condition,
        condition_group: [...condition.condition_group.map((cG, index) => {
          if (index === condition.condition_group.length - 1) return {...cG, condition: "and"};
          return cG;
        }), {
          id: generateUniqueId(),
          condition: null,
          value: " === "
        }]
      };
    }
    return condition;
  });
};