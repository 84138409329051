import {
  SET_CONTRACTS,
  SET_LOADING,
  ADD_NEW_CONTRACT,
  DELETE_CONTRACT,
  CLOSE_POPUP,
  SET_POPUP_NAME,
  SET_CURRENT_PAGE,
  SET_SELECTED_CONTRACT,
  SET_ALERT,
} from "./types";
import services from "services";

export const setContracts = (dispatch, categoryId, query) => {
  services.categoriesServices
    .getCategoryContracts(categoryId, query)
    .then((res) => {
      dispatch({
        type: SET_CONTRACTS,
        payload: res.data,
      });
    });
};

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const addNewContract = (dispatch, values) => {
  services.contractsServices
    .addCategoryContract(values)
    .then((res) => {
      dispatch({
        type: ADD_NEW_CONTRACT,
        payload: {
          data: res.data,
          alert: {
            type: "success",
            message: "Category contract successfully added.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: ADD_NEW_CONTRACT,
        payload: {
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again.",
          },
        },
      });
    });
};

export const deleteContract = (dispatch, categoryId, contract) => {
  services.contractsServices
    .removeCategoryContract(categoryId, { contract_id: contract.id })
    .then((res) => {
      dispatch({
        type: DELETE_CONTRACT,
        payload: {
          contract: contract.id,
          alert: {
            type: "success",
            message: "Category contract successfully deleted.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DELETE_CONTRACT,
        payload: {
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again.",
          },
        },
      });
    });
};

export const closePopup = () => ({
  type: CLOSE_POPUP,
  payload: null,
});

export const openPopup = (name) => ({
  type: SET_POPUP_NAME,
  payload: { name },
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: { page },
});

export const setSelectedContract = (contract) => ({
  type: SET_SELECTED_CONTRACT,
  payload: { contract },
});

export const setAlert = (alert) => ({
  type: SET_ALERT,
  payload: { ...alert },
});
