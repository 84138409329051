export const findCategoriesByName = (name, categories) => {
  let category = null
  categories.map(e => {
    if (e.name === name) {
      category = e
    } else if (e.subItems && e.subItems.length > 0) {
      const item = findCategoriesByName(name, e.subItems)
      if (item) {
        category = item
      }
    }
    return e
  })
  return category
}
export const getCategoriesIds = (category) => {
  const category_ids = [category.id];

  if (category.subItems && category.subItems.length > 0) {
    category.subItems.map(sub => {
      category_ids.push(sub.id)
      if (getCategoriesIds(sub).length) {
        category_ids.push(...getCategoriesIds(sub))
      }
    })
  }

  return category_ids;
}
