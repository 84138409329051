import React from "react";
import Popup from "components/popups/Popup/Popup.jsx";
import ProjectTypeForm from "components/forms/ProjectTypeForm/ProjectTypeForm";

const AddTypePopup = ({ onClose, onFormSubmit, type }) => {
  return (
    <Popup
      title={`${type ? "Edit" : "New"} project type`}
      text={
        !type &&
        "Please, complete the following fields to create a new project type"
      }
      onClose={onClose}
    >
      <ProjectTypeForm
        onClose={onClose}
        onFormSubmit={onFormSubmit}
        defaultValues={type}
      />
    </Popup>
  );
};

export default AddTypePopup;
