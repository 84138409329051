import React from "react";
import {paths} from "../../../routes/paths";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import DashboardTasksContainer from "../../containers/DashboardTasksContainer/DashboardTasksContainer";

const DashboardTasksPage = () => {
  let breadcrumbs = [
    {name: "Dashboard", path: paths.dashboard},
    {name: "Tasks", disabled: true}
  ];
  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <DashboardTasksContainer/>
    </DefaultLayout>
  );
};

export default DashboardTasksPage;