import React, {useEffect, useState} from "react";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import styles from "./RadioGroupField.module.scss";
import PropTypes from "prop-types";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const RadioGroupField = ({values, setFieldValue, field, errors}) => {
  const [currentValue, setCurrentValue] = useState(null);
  const {t} = useTranslation();

  useEffect(() => {
    setCurrentValue(values[field.key]?.value);
  }, [values[field.key]?.value]);

  return (
    <div className={styles.radioField}>
      <RadioGroup
        value={currentValue}
        onChange={e => setFieldValue(field.key, {value: e.target.value, type: "radio"})}
        className={styles.radioGroupWrapper}
      >
        {
          field.options.map(option => (
            <FormControlLabel key={option.id} value={option.id} control={<Radio color="primary"/>} label={option.name}/>
          ))
        }
      </RadioGroup>
      {errors[field.key] && (
        <span
          className={styles.errorMessage}
          data-translate-key={getTranslateKey(errors[field.key])}
        >
          {t(getTranslateKey(errors[field.key]))}
        </span>
      )}
    </div>
  );
};

export default RadioGroupField;

RadioGroupField.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any
};