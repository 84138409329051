import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import PropTypes from "prop-types";
import CustomDropdown from "../../elements/CustomDropdown/CustomDropdown";
import s from "./AdminSettingsFinancialSavingsForm.module.scss";
import services from "../../../services";

const AdminSettingsFinancialSavingsForm = (
  {
    onClose,
    onSubmit,
    defaultData,
  }
) => {
  const [savingsGroups, setSavingsGroup] = useState([]);

  useEffect(() => {
    services.savingsGroupServices.getSavingsGroupAdmin().then(res => {
      if (res.data) {
        setSavingsGroup(
          res.data
            .filter(group => group.status !== "deleted")
            .map(group => ({
              label: group.name,
              value: group.id
            }))
        );
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: defaultData ? defaultData.name : "",
      savings_group: defaultData ? {
        value: defaultData.saving_group.id,
        label: defaultData.saving_group.name,
      } : {}
    },
    validationSchema: object({
      name: string()
        .required("Savings type is required")
        .min(
          2,
          "Please enter savings type that consists of no less than 2 characters"
        )
        .max(
          50,
          "Please enter savings type that consists of no more than 50 characters"
        ),
      savings_group: object({
        value: string().required("Group is required"),
      }),
    }),

    onSubmit: (values) => {
      onSubmit({
        name: values.name,
        saving_group_id: values.savings_group.value,
      });
      onClose();
    },
  });

  const {handleSubmit, errors, setFieldValue, values, touched} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className={s.field}>
        <Input
          label="Savings type"
          value={values.name}
          placeholder="Enter type name"
          onChange={(e) => {
            setFieldValue("name", e.target.value);
          }}
          error={touched.name && errors.name}
          limitValue={50}
          withCounter
        />
      </div>
      <div className={s.field}>
        <CustomDropdown
          value={values.savings_group}
          onChange={e => setFieldValue("savings_group", e)}
          options={savingsGroups}
          name="savings"
          label="Group"
          placeholder="Choose savings group"
          error={errors.savings_group && touched.savings_group && errors.savings_group.value}
        />
      </div>
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {defaultData ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default AdminSettingsFinancialSavingsForm;

AdminSettingsFinancialSavingsForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultData: PropTypes.any,
};