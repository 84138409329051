import React, {useEffect, useState} from "react";
import classNames from "classnames";
import services from "services/index";
import s from "./SavingsGoal.module.scss";
import sBase from "../MainDashboard.module.scss";
import {Chart} from "react-google-charts";
import PieChart from "../../../../../elements/PieChart/PieChart";
import defaultDate from "./defaultDate";
import GoalsLoading from "../../../../../loadingStates/Dashboard/Goals/GoalsLoading";
import {useHistory} from "react-router-dom";
import {paths} from "routes/paths";
import qs from "query-string";
import {useTranslate} from "../../../../../../hooks/useTranslate";
import {useTranslation} from "react-i18next";

const SavingsGoal = ({type}) => {
  const COLORS = ["#63A5A1", "#F4A261", "#5B79B4", "#FB787A"];
  const history = useHistory();
  const [data, setData] = useState(defaultDate);
  const [loading, setLoading] = useState(true);
  const [translateGoalKey, translateGoalValue] = useTranslate("Savings goal");
  const [translateGoalViewKey, translateGoalViewValue] = useTranslate("(View company goals)");
  const {t} = useTranslation();
  const {team_id} = qs.parse(history.location.search);
  const {goal, savings} = data;

  useEffect(() => {
    setLoading(true);
    services.dashboardServices.getGoals({team_id, view: type})
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  }, [team_id]);

  const preparedData = (savings || [])
    .filter((_, index) => index !== savings.length - 1)
    .map(s => Object.keys(s).map(key => s[key]))
    .map(s => s[0][s[0].length - 1].total);

  let totalPerformance = (savings || [])[savings?.length - 1]?.total;
  if (totalPerformance) {
    totalPerformance = totalPerformance[totalPerformance.length - 1].total
  }

  const getGoalValue = () => {
    if (goal?.annual_goal) {
      if (goal.annual_goal < 1) return 0;
      if (Math.sign(goal.annual_goal - totalPerformance) > 0 || isNaN(Math.sign(goal.annual_goal - totalPerformance))) {
        return goal.annual_goal - totalPerformance;
      }
    }
    return 0;
  };
  return (
    <section className={classNames(sBase.wrapper, s.goalsWrapper)}>
      {
        loading ? <GoalsLoading/> :
          (
            <>
              <h4 className={classNames(sBase.title, s.title)} data-translate-key={translateGoalKey}>
                {translateGoalValue}
                <span onClick={() => history.push(paths.companyGoals)} data-translate-key={translateGoalViewKey}>
                    {translateGoalViewValue}
                  </span>
              </h4>
              <div className={s.cartsWrapper}>
                <div className={s.chart}>
                  <Chart
                    colors={COLORS}
                    width={"99%"}
                    height={"100%"}
                    chartType="LineChart"
                    data={[
                      ["x", t("goals"), t("performance")],
                      ["Q1", goal?.quarter1 ?? 0, preparedData[0]],
                      ["Q2", goal?.quarter2 ?? 0, preparedData[1]],
                      ["Q3", goal?.quarter3 ?? 0, preparedData[2]],
                      ["Q4", goal?.quarter4 ?? 0, preparedData[3]],
                    ]}
                    options={{
                      series: {
                        0: {color: "#5B79B4"},
                        1: {color: "#F4A261"},
                        2: {color: "#63A5A1"},
                        3: {color: "#FB787A"},
                      },
                      chartArea: {
                        height: "100%",
                        width: "100%",
                        top: 10,
                        left: 100,
                        right: 10,
                        bottom: 40,
                      },
                      vAxis: {
                        textStyle: {
                          color: "rgba(0, 0, 0, 0.55)",
                          fontSize: 12,
                          fontName: "Roboto",
                        },
                        format: "$#,###"
                      },
                      lineWidth: 3,
                      pointSize: 7,
                      legend: {position: "bottom", alignment: "end"},
                      tooltip: {isHtml: true},
                    }}
                    className={s.googleChart}
                  />
                </div>
                <div className={s.radialDiagram}>
                  <PieChart
                    chartWidth={"100%"}
                    data={[
                      [t("annual-goal"), String(goal?.annual_goal ?? 0)],
                      [t("performance"), totalPerformance],
                      [t("goal"), getGoalValue()],
                    ]}
                    withLegend={false}
                    colors={COLORS}
                  />
                </div>
              </div>
            </>
          )
      }
    </section>
  );
};

export default SavingsGoal;


