import axios from "./axios";

export default {
  getStakeholders: async (id) => {
    try {
      const result = await axios.get(`/projects/${id}/stakeholders`);
      return result;
    } catch (error) {
      throw error;
    }
  },
  addStakeholder: async (id, params) => {
    try {
      const result = await axios.post(`/projects/${id}/stakeholder`, params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  updateStakeholder: async (id, params) => {
    try {
      const result = await axios.put(`/projects/${id}/stakeholder`, params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  removeStakeholder: async (id, params, config) => {
    try {
      const result = await axios.delete(`/projects/${id}/stakeholder`, {
        data: params,
        ...config,
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  addCategoryStakeholder: async (categoryId, params) => {
    try {
      const result = await axios.post(`/categories/${categoryId}/user`, params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  removeCategoryStakeholder: async (categoryId, id) => {
    try {
      const result = await axios.delete(`/categories/${categoryId}/user`, {
        data: { user_id: id },
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getStakeholderTypes: async (id) => {
    try {
      const result = await axios.get(`/stakeholder_types`);
      return result;
    } catch (error) {
      throw error;
    }
  },
};
