import { SET_NOTIFICATIONS_COUNT } from "helpers/constants";
import services from "services";

export const setNotificationsCount = () => {
  return async (dispatch) => {
    services.notificationsServices.getNotificationsCount().then((res) => {
      dispatch({
        type: SET_NOTIFICATIONS_COUNT,
        payload: res.data,
      });
    });
  };
};
