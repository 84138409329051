import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderNotifications from "../HeaderNotifications/HeaderNotifications";
import { setNotificationsCount } from "actions/notifications";
import services from "services";

const HeaderNotificationsContainer = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const count = useSelector((state) => state.notifications);

  useEffect(() => {
    const getCount = () => {
      dispatch(setNotificationsCount());
    };
    getCount();
    const interval = setInterval(() => getCount(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const onNotificationsLoad = (type) => {
    setLoading(true);
    services.notificationsServices
      .getNotificationsList({ message_type: type })
      .then((res) => {
        setNotifications(res.data);
        setLoading(false);
      });
  };

  const onDelete = (id) => {
    services.notificationsServices
      .deleteNotifications({ notification_ids: id })
      .then(() => {
        setNotifications(notifications.filter((itm) => itm.id !== id));
      });
  };

  const onViewport = (id) => {
    services.notificationsServices
      .updateNotifications({ notification_ids: id })
      .then(() => {
        setNotifications((state) =>
          state.map((itm) => {
            if (itm.id === id) itm.is_readed = true;
            return itm;
          })
        );
      });
  };

  return (
    <HeaderNotifications
      notifications={notifications}
      count={count}
      loading={loading}
      onNotificationsLoad={onNotificationsLoad}
      onDelete={onDelete}
      onViewport={onViewport}
    />
  );
};

export default HeaderNotificationsContainer;
