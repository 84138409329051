import {parseToFormula} from "../objToFormula";

export const prepareRequestData = values => ({
  name: values.name,
  request_type: values.request_type.value,
  stage_id: values.project_state?.value,
  approval_condition_type: values.condition_type.value,
  approver_type: values.approver.value,
  project_tab: values.what_approved.value,
  approval_group_id: values.approval_group?.value ?? null,
  approval_group_type: values.approval_type?.value ?? null,
  priority: values.priority.value,
  conditions: parseToFormula(values.conditions),
})