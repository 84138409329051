import React, { useEffect, useState } from "react"
import moment from "moment"
import { Tooltip } from "@material-ui/core"
import { projectTypes } from "helpers/helpData"
import {
  getProjectStartCoordinates,
  getProjectLength,
  getDateForEarlierProject,
  getProjectLineColors
} from "../../helpers/helpers"

const GanttChartLIne = ({
  project,
  dayInPixels,
  wrapperWidth,
  startChartDate,
  stages
}) => {
  const colors = getProjectLineColors(
    project.stage?.id,
    project.end_date,
    stages
  )
  const [styles, setStyles] = useState({ opacity: 0 })

  useEffect(() => {
    wrapperWidth && dayInPixels && getStyles()
  }, [wrapperWidth, dayInPixels])

  const getStyles = () => {
    let width, left
    if (moment(project.start_date).isBefore(startChartDate)) {
      width =
        getDateForEarlierProject(
          startChartDate,
          project.start_date,
          project.end_date
        ) * dayInPixels

      left = 0
    } else {
      width =
        getProjectLength(project.start_date, project.end_date) * dayInPixels
      left = getProjectStartCoordinates(project.start_date) * dayInPixels
    }
    width = width > wrapperWidth - left ? wrapperWidth - left : width
    left = `${left}px`
    width = `${width}px`
    setStyles({
      left,
      width,
      backgroundColor: colors?.line,
      border: `1px solid ${colors?.lineBorders}`,
      opacity: 1,
      cursor: "pointer"
    })
  }

  return (
    <Tooltip
      title={
        <span style={{ fontSize: "14px" }}>
          {project.name} • {project?.project_type?.name}
        </span>
      }
      arrow
    >
      <li style={styles}>
        <span style={{ color: colors?.text, marginRight: "8px" }}>
          {project.name}
        </span>
        {project?.project_type?.name}
      </li>
    </Tooltip>
  )
}

export default GanttChartLIne
