import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import s from "../FinancialBenefitsForm.module.scss";
import DatePicker from "../../../elements/DatePicker/DatePicker";
import CurrencySelectionField from "../../../elements/CurrencySelectionField/CurrencySelectionField";
import {currencyData} from "../../../../helpers/helpData";
import {entitiesFormatter} from "../../../../helpers/entitiesFormatter";
import services from "../../../../services";
import RSSelect from "../../../elements/ReactSelectCustom/RSSelect";

const MainForm = ({values, setFieldValue, errors}) => {
  const [savingsTypesList, setSavingsTypesList] = useState([]);

  useEffect(() => {
    services.savingsTypeServices.getSavingsTypeList().then(res => {
      if (res.data) {
        setSavingsTypesList(res.data.map(type => entitiesFormatter(type)));
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div className={classNames("dateBlock", s.datesWrap)}>
        <DatePicker
          label="Start date"
          selected={values.start_date}
          onChange={date => {
            setFieldValue("start_date", date);
            date > values.end_date && setFieldValue("end_date", date);
          }}
          selectsStart
          startDate={values.start_date}
          endDate={values.end_date}
          placeholderText="Choose start date"
          wrapperClassName="wrapperdatePicker"
          className="customInput"
          classWrap={s.datePickerWrap}
          todayButton="Go to today"
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={errors.start_date && errors.start_date}
        />
        <DatePicker
          label="Target end date"
          selected={values.end_date}
          onChange={date => setFieldValue("end_date", date)}
          selectsEnd
          startDate={values.start_date}
          endDate={values.end_date}
          minDate={values.start_date}
          placeholderText="Choose end date"
          wrapperClassName="wrapperdatePicker"
          className="customInput"
          classWrap={s.datePickerWrap}
          todayButton="Go to today"
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          error={errors.end_date && errors.end_date}
        />
      </div>
      <div className={s.row}>
        <CurrencySelectionField
          options={currencyData}
          value={values.final_cost}
          onChange={({fieldVal, ...currency}) => {
            setFieldValue("final_cost", fieldVal)
            setFieldValue("currency", currency)
          }}
          defaultValue={values.currency}
          name='final_cost'
          label="Final cost"
          fieldPlaceholder='000,000'
          error={errors.final_cost && errors.final_cost}
        />
        <CurrencySelectionField
          options={currencyData}
          value={values.final_savings}
          onChange={({fieldVal, ...currency}) => {
            setFieldValue("final_savings", fieldVal)
            setFieldValue("currency", currency)
          }}
          defaultValue={values.currency}
          name='final_savings'
          label="Final savings"
          fieldPlaceholder='000,000'
          error={errors.final_savings && errors.final_savings}
        />
      </div>
      <div className={classNames(s.row, s.largeMargin)}>
        <RSSelect
          options={savingsTypesList}
          defaultValue={values.savings_type}
          onChange={option => setFieldValue("savings_type", option)}
          label="Savings type"
          placeholder='Choose type'
          error={errors.savings_type && errors.savings_type}
        />
      </div>
    </React.Fragment>
  );
};

export default MainForm;