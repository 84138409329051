import React, {useState, useRef, useEffect} from "react";
import classNames from "classnames";
import s from "./FilterBarDropdownExpiresIn.module.scss";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";
import {ReactComponent as Clear} from "assets/icons/clear.svg";
import styles from "components/elements/FilterBar/components/FilterBarDropdown/FilterBarDropdown.module.scss";
import useOutsideClicker from "hooks/useOutsideClicker";
import Button from "components/elements/Button/Button";
import {CircularProgress} from "@material-ui/core";
import {useTranslate} from "../../../../../hooks/useTranslate";
import {useTranslation} from "react-i18next";

const FilterBarDropdownExpiresIn = (
  {
    name,
    handleChange,
    clearAllFilters,
    setClearAllFilters,
    setFilterValue,
    defaultValue,
    options
  }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const dropdownRef = useRef(null);
  const {t} = useTranslation();
  const [translateNameKey, translateNameValue] = useTranslate(name);
  const [translateNameKeyD, translateNameValueD] = useTranslate(current);
  useOutsideClicker(dropdownRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (clearAllFilters) {
      setFilterValue(name, false);
      setClearAllFilters(false);
      setCurrent(null);
    }
  }, [clearAllFilters]);

  const onClear = () => {
    setCurrent(null);
    setFilterValue(name, false);
    handleChange([]);
    setIsOpen(false);
  };

  useEffect(() => {
    if (defaultValue) {
      setCurrent(defaultValue);
      setFilterValue(name, defaultValue);
    }
  }, [defaultValue]);

  const handleSelect = item => {
    setCurrent(item);
    handleChange(item);
    setFilterValue(name, item);
    setIsOpen(false);
  };

  return (
    <div
      className={classNames(styles.filterDropdownWrapper, {
        [styles.filterListOpened]: isOpen
      })}
      ref={dropdownRef}
    >
      <div
        className={styles.nameBlock}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {current ? (
          <p className={styles.selectedOption} data-translate-key={translateNameKeyD}>
            {translateNameValueD}
            {current === "Expired" ? "" : (
              <span data-translate-key="days">
                {`${t("days")}`}
              </span>
            )}
          </p>
        ) : (
          <p data-translate-key={translateNameKey}>{translateNameValue}</p>
        )}
        <span
          className={classNames(styles.arrowIcon, {
            [styles.listOpen]: isOpen
          })}
        >
          <Arrow/>
        </span>
      </div>
      {isOpen && (
        <div className={classNames(styles.openedList, s.openedList)}>
          <ul className={s.expiresInWrap}>
            {options ? (
              options.map((item, index) => (
                <li className={classNames(s.item, {[s.active]: current === item})}
                    onClick={() => handleSelect(item)}
                    key={index}>
                  {
                    item !== "Expired" ? (
                      <span data-translate-key="days">
                        {`${item} ${t("days")}`}
                      </span>
                    ) : (
                      <span data-translate-key="expired">
                         {t("expired")}
                      </span>
                    )
                  }
                </li>
              ))
            ) : (
              <div
                className="loaderWrap justifyCenter"
                style={{marginTop: "24px"}}
              >
                <CircularProgress/>
              </div>
            )}
          </ul>
          <div className={s.clearBlock}>
            <Button
              styled="textButton"
              className={classNames(s.clearBtn, {
                [s.disabled]: !current
              })}
              onClick={onClear}
              data-translate-key="clear">
              <Clear/>
              {t("clear")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default FilterBarDropdownExpiresIn;
