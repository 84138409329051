import {
  GET_TYPES,
  CHANGE_POPUP,
  ADD_TYPE,
  EDIT_TYPE,
  DELETE_TYPE,
  CLOSE_ALERT
} from "./types"
import services from "services"

export const getTypes = dispatch => {
  services.adminProjectsServices.getProjectTypes().then(res => {
    const types = res?.data
    dispatch({
      type: GET_TYPES,
      payload: types
    })
  })
}

export const changePopup = popup => {
  return {
    type: CHANGE_POPUP,
    payload: popup
  }
}

export const closeAlert = () => {
  return {
    type: CLOSE_ALERT,
    payload: null
  }
}

export const addType = (dispatch, values) => {
  services.adminProjectsServices
    .addProjectType(values)
    .then(res => {
      const type = res?.data
      dispatch({
        type: ADD_TYPE,
        payload: {
          type,
          alert: {
            type: "success",
            message: "Project type successfully added."
          }
        }
      })
    })
    .catch(() =>
      dispatch({
        type: ADD_TYPE,
        payload: {
          type: null,
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}

export const editType = (dispatch, typeId, values) => {
  services.adminProjectsServices
    .editProjectType(typeId, values)
    .then(res => {
      const type = res?.data
      dispatch({
        type: EDIT_TYPE,
        payload: {
          type,
          alert: {
            type: "success",
            message: "Project type successfully changed."
          }
        }
      })
    })
    .catch(() =>
      dispatch({
        type: EDIT_TYPE,
        payload: {
          type: null,
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}
export const deleteType = (dispatch, id) => {
    services.adminProjectsServices
    .deleteProjectType(id)
    .then(() => {
      dispatch({
        type: DELETE_TYPE,
        payload: {
          id,
          alert: {
            type: "success",
            message: "Project type successfully deleted."
          }
        }
      })
    })
    .catch(() =>
      dispatch({
        type: DELETE_TYPE,
        payload: {
          type: null,
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again."
          }
        }
      })
    )
}
