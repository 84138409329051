import React, { useState } from "react";
import Input from "components/elements/Input/Input";
import classNames from "classnames";
import s from "./PasswordInput.module.scss";
import { ReactComponent as HideIcon } from "assets/icons/eyeHide.svg";
import { ReactComponent as ShowIcon } from "assets/icons/eyeShow.svg";

const PasswordInput = ({
  placeholder,
  onChange,
  value,
  error,
  label,
  inputProps,
  className,
  name,
}) => {
  const [show, setShow] = useState(false);
  const iconStyle = {
    transform:
      label && !error
        ? "translateY(100%)"
        : !label && error
        ? "translateY(0%)"
        : "translateY(50%)",
  };
  return (
    <div className={classNames(s.inputWrap, className)}>
      <div style={{ position: "relative" }}>
        <Input
          type={!show ? "password" : "text"}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          label={label}
          error={error}
          name={name}
          inputProps={inputProps}
        />
        {!show ? (
          <ShowIcon
            onClick={() => setShow(true)}
            className={s.icon}
            style={iconStyle}
          />
        ) : (
          <HideIcon
            onClick={() => setShow(false)}
            className={s.icon}
            style={iconStyle}
          />
        )}
      </div>
    </div>
  );
};

export default PasswordInput;
