import React, {
  useEffect,
  useReducer,
  useMemo,
  useContext,
  useState
} from "react";
import s from "./ProjectsContainer.module.scss";
import querystring from "query-string";
import services from "services";
import Button from "components/elements/Button/Button";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import {CircularProgress} from "@material-ui/core";
import ProjectsFilter from "components/filters/ProjectsFilter/ProjectsFilter";
import {formatFilters} from "components/elements/FilterBar/helpers/helpers";
import {useSelector} from "react-redux";
import {
  setFilters,
  openPopup,
  closePopup,
  setCurrentPage,
  setCurrentTab,
  setProjects,
  setRequestData,
  setPieChartData,
  setStages,
  setBoardLoading,
  setPieLoading,
} from "./helpers/actions";
import ProjectsTabs from "./Tabs/ProjectsTabs/ProjectsTabs";
import {useHistory, useLocation} from "react-router-dom";
import DeleteProjectPopup from "components/popups/DeleteProjectPopup/DeleteProjectPopup";
import ChangeStatusPopup from "./Popups/ChangeStatusPopup";
import PieChartWiget from "components/elements/PieChartWidget/PieChartWidget";
import {initialState, reducer} from "./helpers/reducer";
import Can from "components/elements/Can/Can";
import {userRoles, userTypes} from "helpers/userPermissions/userRoles";
import {cancelRequest} from "services/axios";
import AlertContext from "contexts/AlertContext/AlertContext";
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar";
import PieChartWidgetLoading from "components/loadingStates/PieChartWidget/PieChartWidget";
import {paths} from "routes/paths";
import {formatDepartmentsFilter} from "components/filters/helpers/formatDepartmentsFilter";
import ProjectCompletionPopup from "./Popups/ProjectCompletionPopup/ProjectCompletionPopup";
import {autoFillYears} from "components/elements/ProjectsGanttChart/helpers/autoFillYears";
import {useDidUpdate} from "../../../hooks/useDidUpdate";
import Popup from "../../popups/Popup/Popup";
import {ReactComponent as ErroNotificatioIcon} from "assets/icons/error-notification-icon.svg";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const ProjectsContainer = () => {
  const {user_type} = useSelector(state => state.user);
  const {support} = userTypes;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [currentProject, setCurrentProject] = useState(null);
  const [isInitial, setInitial] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState("");
  const [currentStageId, setCurrentStageId] = useState(null);
  const user = useSelector(state => state.user);
  const history = useHistory();
  const location = useLocation();
  const {t} = useTranslation();
  const {setAlert, getCancelId} = useContext(AlertContext);
  const currentYear = String(new Date().getFullYear());

  const {
    tab,
    page = 1,
    sort_by,
    direction,
    category_ids,
    department_ids,
    division_ids,
    owner_ids,
    stakeholder_ids,
    years,
    project_type_ids,
    query,
    grouped_by,
    tag
  } = querystring.parse(location.search);

  const filters = {
    category_ids,
    department_ids,
    division_ids,
    stakeholder_ids,
    owner_ids,
    years,
    project_type_ids,
    page,
    sort_by,
    direction,
    query,
    tag
  };

  const ALLOWANCE_MONTHS_COUNT =
      state.pieChartWidgetData?.allowance_months_count || 0,
    ALLOWANCE_RULE = state.pieChartWidgetData?.allowance_rule || "";

  const getFullTitleChart = () => {
    return (
      <>
        <span data-translate-key={getTranslateKey("Total impact of")}>
          {t(getTranslateKey("Total impact of"))}
        </span>
        {" "}
        {years?.replace(/,/g, ", ")}
        {" "}
        <span data-translate-key={getTranslateKey("Activity")}>
          {t(getTranslateKey("Activity"))}
        </span>
      </>
    );
  };

  const getMonthTitleChart = () => {
    return (
      <>
        {ALLOWANCE_MONTHS_COUNT}
        {" "}
        <span data-translate-key={getTranslateKey("months impact of")}>
          {t(getTranslateKey("months impact of"))}
        </span>
        {" "}
        {years?.replace(
          /,/g,
          ", "
        )}
        {" "}
        <span data-translate-key={getTranslateKey("Activity")}>
          {t(getTranslateKey("Activity"))}
        </span>
      </>
    );
  };

  const getMonthYearTitleChart = () => {
    return (
      <>
        {ALLOWANCE_MONTHS_COUNT}
        {" "}
        <span data-translate-key={getTranslateKey("months impact of all activities")}>
          {t(getTranslateKey("months impact of all activities"))}
        </span>
      </>
    );
  };

  const getCurrentYearTitleChart = () => {
    return (
      <>
        {currentYear}
        {" "}
        <span data-translate-key={getTranslateKey("Impact of")}>
          {t(getTranslateKey("Impact of"))}
        </span>
        {" "}
        {currentYear}
        {" "}
        <span data-translate-key={getTranslateKey("Activity")}>
          {t(getTranslateKey("Activity"))}
        </span>
      </>
    );
  };

  const getPieChartWidgetTitle = () => {
    if ((ALLOWANCE_RULE === "full" && years) || (!ALLOWANCE_RULE && years)) {
      return getFullTitleChart();
    } else if (ALLOWANCE_RULE === "months" && years) {
      return getMonthTitleChart();
    } else if (ALLOWANCE_RULE === "months" && !years) {
      return getMonthYearTitleChart();
    } else if (ALLOWANCE_RULE === "full" && !years) {
      return (
        <span data-translate-key={getTranslateKey("Total impact of all activities")}>
          {t(getTranslateKey("Total impact of all activities"))}
        </span>
      );
    } else {
      return getCurrentYearTitleChart();
    }
  };

  const prevYears = [
    currentYear,
    currentYear - 1,
    currentYear - 2,
    currentYear - 3
  ];

  const getFullTitleSavings = () => {
    return (
      <>
        <span data-translate-key={getTranslateKey("Total impact of")}>
          {t(getTranslateKey("Total impact of"))}
        </span>
        {" "}
        {filters.years?.replace(",", ", ") || prevYears.join(", ")}
        {" "}
        <span data-translate-key={getTranslateKey("activities")}>
          {t(getTranslateKey("activities"))}
        </span>
      </>
    );
  };

  const getMonthTitleSavings = () => {
    return (
      <>
        {ALLOWANCE_MONTHS_COUNT}
        {" "}
        <span data-translate-key={getTranslateKey("months impact of")}>
          {t(getTranslateKey("months impact of"))}
        </span>
        {" "}
        {filters.years?.replace(",", ", ") || prevYears.join(", ")}
        {" "}
        <span data-translate-key={getTranslateKey("activities")}>
          {t(getTranslateKey("activities"))}
        </span>
      </>
    );
  };

  const getSavingsWidgetTitle = () => {
    if (ALLOWANCE_RULE === "full") {
      return getFullTitleSavings();
    } else if (ALLOWANCE_RULE === "months") {
      return getMonthTitleSavings();
    } else {
      return (
        <span data-translate-key={getTranslateKey("Total impact of all activities")}>
          {t(getTranslateKey("Total impact of all activities"))}
        </span>
      );
    }
  };

  const getFilters = async () => {
    const {data} = await services.projectServices.getProjectsFilters();
    const formattedResult = {
      ...data,
      departments: formatDepartmentsFilter(data.departments)
    };
    const formattedFilters = formatFilters(formattedResult);
    dispatch(setFilters(formattedFilters));
  };

  const getProjects = () => {
    if (tab === "List") {
      services.projectServices
        .getProjects(filters)
        .then(res => {
          dispatch(
            setRequestData({
              ...res?.data,
              loading: false,
              projectsTotalCount: res?.data?.projects_count
            })
          );
          setInitial(true);
        })
        .catch(() => console.error("Sorry, something went wrong"));
    } else if (tab === "Gantt chart") {
      const allYears = autoFillYears(
        years
          ?.split(",")
          .map(e => +e)
          .sort()
      ).join(",");
      services.projectServices
        .getGanttChartProjects({...filters, years: allYears})
        .then(res => {
          dispatch(
            setRequestData({
              ...res?.data,
              loading: false,
              projectsTotalCount: res?.data?.projects?.length
            })
          );
          setInitial(true);
        })
        .catch(() => console.error("Sorry, something went wrong"));
    } else {
      services.projectServices
        .getProjectsBoard(filters)
        .then(res => {
          dispatch(
            setRequestData({
              ...res?.data,
              loading: false,
              projectsTotalCount: res?.data?.projects?.length
            })
          );
          setInitial(true);
        })
        .catch(() => console.error("Sorry, something went wrong"));
    }
  };

  useEffect(() => {
    const {tab, page} = querystring.parse(location.search);
    dispatch(setCurrentTab(tab));
    dispatch(setCurrentPage(Number(page)));
    if (location.state?.success !== undefined) {
      const {success, projectName, cancelId} = location.state;
      if (success)
        setAlert({
          message: `"${projectName}" project successfully deleted.`,
          onCancel: () => cancelRequest(cancelId)
        });
      else
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
    }
  }, [location]);

  useEffect(() => {
    getProjects();
  }, []);

  useDidUpdate(() => {
    isInitial && getProjects();
  }, [location.search]);

  useEffect(() => {
    dispatch(setPieLoading(true))
    getFilters();
  }, [location.search]);

  useEffect(() => {
    services.projectServices
      .getStages()
      .then(res => dispatch(setStages(res?.data?.stages || [])));
  }, []);

  const handleDeleteProject = async () => {
    if (state.selectedProjectId) {
      const cancelId = getCancelId();
      const oldProjects = state.projects;
      const {name} = state.projects.find(
        project => project.id === state.selectedProjectId
      );
      const newProjects = state.projects.filter(
        project => project.id !== state.selectedProjectId
      );
      dispatch(closePopup());
      setAlert({
        message: `"${name}" project successfully deleted.`,
        onCancel: () => {
          cancelRequest(cancelId);
          dispatch(setProjects(oldProjects));
        }
      });
      dispatch(setProjects(newProjects));
      await services.projectServices
        .deleteProject(state.selectedProjectId, {cancelId})
        .then(() => {
        })
        .catch(() => {
          dispatch(setProjects(oldProjects));
          setAlert({
            message: "Something went wrong. Please, try again.",
            type: "error"
          });
        });
    }
  };

  const successChangeStatus = (id, values) => {
    services.projectServices
      .editProject(id, {
        ...values,
        stage_id: currentStageId || values.stage_id,
      })
      .then(res => {
        const newProjects = state.projects.map(project => {
          if (project.id === id) {
            return res.data;
          }
          return project;
        });
        dispatch(closePopup());
        setAlert({
          message: `"${res.data.name}" project successfully changed status.`
        });
        dispatch(setProjects(newProjects));
        setCurrentStageId(null);
        services.projectServices.getProjects(filters).then(res => {
          dispatch(setPieChartData(res.data.year_impact));
        });
      })
      .catch(e => {
        if (e.response.status === 406) {
          setAlert({
            message: e.response.data.error,
            type: "error"
          });
          return;
        }
        if (e.response.status === 422) {
          setErrorPopUp(e.response.data.error);
          return;
        }
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };

  const completedStage = state.stages?.length && state.stages[state.stages.length - 1].name;

  const changeStatus = (id, stageId) => {
    if (grouped_by) {
      if (stageId === "unassigned") {
        services.customStatusesServices.unassignOption(id, grouped_by).then(() => {
        });
      } else {
        services.customStatusesServices.changeProjectSelectorOption(id, grouped_by, stageId).then(() => {
        });
      }
    } else {
      if (state.stages?.find(s => s.id === stageId)?.name === completedStage) {
        dispatch(openPopup("completion"));
        setCurrentProject(state.projects?.find(e => e.id === id) || null);
        setCurrentStageId(stageId);
      } else {
        successChangeStatus(id, {stage_id: stageId});
      }
    }
  };

  const isActiveFilters = useMemo(() => {
    return Object.values({
      category_ids,
      department_ids,
      division_ids,
      owner_ids,
      years,
      project_type_ids
    }).some(filter => filter?.length > 0);
  }, [category_ids, department_ids, division_ids, owner_ids, years, project_type_ids]);
  const onDeleteTag = async (tagId, projectId) =>
    await services.tagsServices.deleteProjectTag(projectId, tagId);
  const onAssignTag = async (tagId, projectId) =>
    await services.tagsServices.assignProjectTag(projectId, tagId);

  let totalSum;
  if (state.pieChartWidgetData?.savings) {
    totalSum = state.pieChartWidgetData.savings.filter(e => e.total)[0].total.slice(-1)[0].total > 0;
  }

  return (
    <section className={s.projectsListContainer}>
      <div className={s.addProjectBlock}>
        <h3 className={s.title} data-translate-key="projects">{t("projects")}</h3>
        {state.projects.length > 0 && (
          <Can
            permission={!user.roles.includes(userRoles.business_user)}
            yes={() => (
              <Button styled="addElement" onClick={() => history.push(paths.createProject)}>
                New Project
              </Button>
            )}
          />
        )}
      </div>
      {state.pieLoading && (
        <PieChartWidgetLoading className={s.pieChartLoader} width={144}/>
      )}
      {!state.pieLoading &&
        state.pieChartWidgetData &&
        state.pieChartWidgetData?.period !== "quarters" && (
          <PieChartWiget
            data={state.pieChartWidgetData}
            className={s.pieChartWidget}
            title={getPieChartWidgetTitle()}
            onClick={() => history.push("/financial-benefits")}
          />
        )}
      {!state.pieLoading &&
        state.pieChartWidgetData &&
        state.pieChartWidgetData.period === "quarters" && (
          totalSum
            ? 
          <PieChartWiget
            data={state.pieChartWidgetData}
            className={s.pieChartWidget}
            title={getSavingsWidgetTitle()}
            onClick={() => history.push(paths.financialBenefits)}
            quater={true}
          />
            : <EmptyBlock
              title={t(getTranslateKey(`Total Impact of `)) + `${years}` + t(getTranslateKey(` Activity`))}
              svgTitle={true}
              subtitle="There is no data that matches selected filters"
              className={s.EmptyChart}
              message={s.message}
              withBtn={false}
            />
        )}
      <div className={s.blockOfViews}>
        {!Array.isArray(state.filters) && Object.keys(state.filters).length
          ? (<ProjectsFilter filters={state.filters} className={s.filterBar}/>)
          : (<FilterBarLoading length={5} className={s.filterBar}/>)}
        {state.projects.length || isActiveFilters
          ? (<ProjectsTabs
            tabsClassName={s.tabs}
            state={state}
            dispatch={dispatch}
            onDrop={changeStatus}
            onDeleteTag={onDeleteTag}
            onAssignTag={onAssignTag}
          />)
          : state.boardLoading
            ? (<div
              className="loaderWrap justifyCenter"
              style={{marginTop: "36px"}}>
              <CircularProgress/>
            </div>)
            : (user_type !== support
              ? <ProjectsTabs
                tabsClassName={s.tabs}
                state={state}
                dispatch={dispatch}
                onDrop={changeStatus}
                onDeleteTag={onDeleteTag}
                onAssignTag={onAssignTag}
              />
              : <EmptyBlock
                subtitle="There is no data that matches selected filters"
                className={s.emptyBlock}
                withBtn={false}
              />)}
      </div>
      {state.visiblePopupName === "completion" && currentProject && (
        <ProjectCompletionPopup
          project={currentProject}
          onClose={() => dispatch(closePopup())}
          onSubmit={successChangeStatus}
          stages={state.stages}
        />
      )}
      {state.visiblePopupName === "deleteProject" && (
        <DeleteProjectPopup
          project={state.projects.find(
            project => project.id === state.selectedProjectId
          )}
          onClose={() => {
            dispatch(closePopup());
          }}
          onClick={handleDeleteProject}
        />
      )}
      {state.visiblePopupName === "changeProjectStatus" && (
        <ChangeStatusPopup
          handleClose={() => {
            dispatch(closePopup());
          }}
          handleSave={stageId => {
            changeStatus(state.selectedProjectId, stageId);
          }}
          status={
            state.projects.find(
              project => project.id === state.selectedProjectId
            ).stage.status
          }
        />
      )}
      {Boolean(errorPopUp?.length) && (
        <Popup
          errorPopUp
          onClose={() => setErrorPopUp("")}
        >
          <div className={s.errorNotification}>
            <ErroNotificatioIcon/>
            <p>{errorPopUp}</p>
          </div>
        </Popup>
      )}
    </section>
  );
};
export default ProjectsContainer;
