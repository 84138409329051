import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import { SurveysContainer } from "../../containers/SurveysContainer/SurveysContainer";

const SurveysPage = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([{ name: "Surveys" }]);
  const { pathname } = useLocation();
  const path = pathname.split("-")[0].replace("/", "");

  useEffect(() => {
    setBreadcrumbs([{ name: `Surveys/for ${path}s` }]);
    return () => {
      setBreadcrumbs([{ name: `Surveys/for ${path}s` }]);
    };
  }, [path]);

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <SurveysContainer path={path} />
    </DefaultLayout>
  );
};

export default SurveysPage;
