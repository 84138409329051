import React from "react"
import Popup from "components/popups/Popup/Popup"
const DeletePopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      onClose={onClose}
      title='Delete stakeholder type'
      text='Are you sure you want to delete this stakeholder type?'
      onClick={onSubmit}
      dialog
      isDelete
      subText="It will remain with all current stakeholders, but not with new ones."
    ></Popup>
  )
}
export default DeletePopup
