import Identify from "../../assets/icons/ProductPage/Identify.svg";
import Align from "../../assets/icons/ProductPage/Align.svg";
import Track from "../../assets/icons/ProductPage/Track.svg";
import Complete from "../../assets/icons/ProductPage/Complete.svg";
import Measure from "../../assets/icons/ProductPage/Measure.svg";
import Communicate from "../../assets/icons/ProductPage/Communicate.svg";

const productsCardData = [
  {
    img: Identify,
    title: "Identify",
    text:
      "Develop and cultivate a list of all procurement improvement opportunities in one place, regardless if you are harvesting savings, reducing risk or increasing improvements, in one place.",
  },
  {
    img: Align,
    title: "Align&Assign",
    text:
      "Align opportunities with internal priorities and decide event sequencing, easily reprioritize projects based on changing needs. Assign procurement, finance, risk, legal and business resources to projects and events to ensure appropriate involvement from relevant stakeholders. Forecast project financial and non-financial goals and agree on priorities.",
  },
  {
    img: Track,
    title: "Track",
    text:
      "Update projects and activities as progress is made. Ensure latest updates are available to management and stakeholders. Focal Point ensures the latest financial and non-financial benefit forecasts are available for publication to interested parties. Collaborate with stakeholder to maintain alignment and priorities.",
  },
  {
    img: Complete,
    title: "Complete",
    text:
      "Upon completion, Focal Point allows users to calculate the financial benefits broken down between Cost Avoidance and Cost Reduction for both one-time and run rate purposes. Check off achieved project goals were achieved as well as accomplished non-financial project benefits.",
  },
  {
    img: Measure,
    title: "Measure",
    text:
      "Focal Point will be able to delineate benefits by department, geography or operating company, depending on how your procurement organization reports their numbers. Distribute project feedback surveys to measure stakeholder satisfaction and feedback/ Have a comprehensive view of ROI from the entire company down to project level.",
  },
  {
    img: Communicate,
    title: "Communicate",
    text:
      "With an aggregated and detailed view of projects, goals, benefits, client satisfaction and time spent on projects Focal Point makes it effortless to create and distribute stakeholder updates based on achieved and in-flight activities as well as forward looking projections for stakeholders, departments and executives.",
  },
];

export default productsCardData;
