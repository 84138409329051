import React, { useState, useEffect } from 'react'
import s from './CompanyGoalsContainer.module.scss'
import GoalsWidget from 'components/elements/GoalsWidget/GoalsWidget'
import services from 'services'
import { useSelector } from 'react-redux'
import CompanyGoalsFilter from 'components/filters/CompanyGoalsFilter/CompanyGoalsFilter'
import { getAllIds } from 'components/elements/FilterBar/helpers/helpers'
import FilterBarLoading from 'components/loadingStates/FilterBar/FilterBar'
import { loadingGoalsData } from './helpers'
import {useTranslation} from "react-i18next";
import {updateChangedUsers} from "./helpers/updateUsers";
import {useHistory} from "react-router-dom";
import qs from "query-string";

const CompanyGoalsContainer = () => {
  const history = useHistory();
  const [allGoals, setAllGoals] = useState({});
  const [filteredGoals, setFilteredGoals] = useState({});
  const [filters, setFilters] = useState(null);
  const [activeFilters, setActiveFilters] = useState({
    years: [],
    departments: [],
    users: []
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpenedDepartment, setIsOpenedDepartment] = useState(false);
  const {t} = useTranslation();
  const organization = useSelector(state => state.user.organization);
  const {...query} = qs.parse(history.location.search);

  const filterData = data =>
    activeFilters
      ? Boolean(Object.keys(data).length) && {
      ...data,
      departments: data.departments
        .filter(
          e =>
            (Boolean(activeFilters.departments.length)
              ? activeFilters.departments.includes(e.id)
              : true) &&
            (Boolean(activeFilters.years.length)
              ? activeFilters.years.includes(String(e.goal?.year))
              : true) &&
            (searchQuery && !isOpenedDepartment
              ? e.name?.toLowerCase().includes(searchQuery.toLowerCase())
              : true)
        )
        .map(e => ({
          ...e,
          users: e.users.filter(
            e =>
              (Boolean(activeFilters.users.length)
                ? activeFilters.users.includes(e.id)
                : true) &&
              (Boolean(activeFilters.years.length)
                ? activeFilters.years.includes(String(e.goal?.year))
                : true) &&
              (searchQuery && isOpenedDepartment
                ? `${e.first_name} ${e.last_name}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
                : true)
          )
        }))
    }
      : data

  useEffect(() => {
    services.companyGoalsServices.getCompanyGoalsFilters().then(res => {
      setFilters(res.data);
    })
  }, []);

  useEffect(() => {
    services.companyGoalsServices
      .getCompanyGoals(organization.id, {year: query?.year})
      .then(res => {
        setAllGoals(res.data)
        setFilteredGoals(res.data)
      })
  }, [history.location.search]);

  useEffect(() => {
    setFilteredGoals(filterData(allGoals))
  }, [activeFilters, searchQuery]);

  const getUpdatedData = values => {
    switch (values.source) {
      case 'company':
        return {...filteredGoals, goal: values}
      case 'department':
        return {
          ...filteredGoals,
          departments: filteredGoals.departments.map(e =>
            e.goal.id === values.id ? {...e, goal: values} : e
          )
        }
      case 'user':
        return {
          ...filteredGoals,
          departments: filteredGoals.departments.map(e =>
            e.id === values.department_id
              ? {
                ...e,
                users: updateChangedUsers(e.users, values)
              }
              : e
          )
        }
      default:
        return filteredGoals
    }
  };

  const handleChangeFilters = selectedFilters => {
    const departmentsIds = getAllIds(selectedFilters.departments)
    const usersIds = getAllIds(selectedFilters.users)

    const filters = {
      years: [selectedFilters.years],
      departments: departmentsIds,
      users: usersIds
    }

    if (
      !filters.years.length &&
      !filters.departments.length &&
      !filters.users.length
    ) {
      setActiveFilters(null)
    } else {
      setActiveFilters(filters)
    }
  };

  return (
    <div className={s.companyGoalsContainer}>
      <h2 className={s.title} data-translate-key="company-goals">{t("company-goals")}</h2>
      {filters ? (
        <CompanyGoalsFilter
          filters={filters}
          onFiltersChange={handleChangeFilters}
          activeFilter={activeFilters}
          onSearch={query => setSearchQuery(query)}
          className={s.filterBar}
        />
      ) : (
        <FilterBarLoading className={s.filterBar} length={3}/>
      )}
      {Boolean(Object.keys(filteredGoals).length) ? (
        <GoalsWidget
          data={filteredGoals}
          organization={organization}
          onEdit={values => setFilteredGoals(getUpdatedData(values))}
          onSelectDepartment={open => setIsOpenedDepartment(open)}
        />
      ) : (
        <GoalsWidget
          data={loadingGoalsData}
          organization={organization}
          loadingState={true}
        />
      )}
    </div>
  )
}

export default CompanyGoalsContainer
