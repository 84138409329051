export const sentences = {
  notifications: {
    triageAdmin: "Triage admin receives all unassigned requests",
    hierarchy: "Order of items in triage process",
    showHierarchyItem: "Add item to triage hierarchy",
    hideHierarchyItem: "Hide item from triage hierarchy",
  },
  forms: {
    hierarchy: "Hierarchy",
    hierarchyDescription: "(Drag and drop items to change their order in the triage process)",
    hiddenItems: "Hidden items",
    hiddenItemsDescription: "(Drag and drop items here if you don't need them in the triage process)"
  },
  popups: {
    unassignUser: "Unassign triage person",
    unassignUserDescription: "Are you sure you want to unassign this triage person? All teams will also be removed from this division. All requests will be assigned to the user higher in the hierarchy."
  }
};