import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import Button from "../../../../elements/Button/Button";
import CustomDropdown from "../../../../elements/CustomDropdown/CustomDropdown";
import services from "../../../../../services";
import {object, string} from "yup";

const ProjectTypeForm = ({onSubmit, onClose, projectTypesList}) => {
  const [projectTypes, setProjectTypes] = useState([]);

  useEffect(() => {
    services.projectServices.getProjectTypes().then(res => {
      setProjectTypes(
        res.data.project_types
          .filter(p => projectTypesList.map(pt => pt.project_type.id).indexOf(p.id) === -1)
          .map(p => {
            return {
              value: p,
              label: p.name
            }
          })
      );
    });
  }, [projectTypesList]);

  const formik = useFormik({
    initialValues: {
      project: '',
    },
    validationSchema: object({
      project: string().required("Project Type is required"),
    }),
    onSubmit: (values) => {
      onSubmit({
        project: values.project,
      });
      onClose();
    },
  });

  const {handleSubmit, values, setFieldValue, errors, touched} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <CustomDropdown
        options={projectTypes}
        value={values.project}
        onChange={(value) => setFieldValue("project", value)}
        label="Project type"
        placeholder="Choose project type"
        error={errors.project && touched.project && errors.project}
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">Create</Button>
      </div>
    </form>
  );
}
export default ProjectTypeForm;
