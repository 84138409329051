import React from "react";
import {useFormik} from "formik";
import Popup from "../Popup/Popup";
import s from "./UploadLogoPopup.module.scss";
import Button from "../../elements/Button/Button";
import PropTypes from "prop-types";
import Input from "../../elements/Input/Input";
import services from "../../../services";
import {useParams} from "react-router-dom";
import classNames from "classnames";

const UploadLogoPopup = ({setShow, title, url, onSubmit}) => {
  const typeParts = title.split(" ");
  const params = useParams();
  const avatar = typeParts.length > 1 ? typeParts[0][0] + typeParts[1][0] : typeParts[0][0];
  let urlNew;
  const logoSuppliers = () => {
    if (url.match(/\.(jpeg|jpg|gif|svg|png)$/) != null) {
      return (
        <img className={s.logo}
             src={`${url}`}
             alt="Logo"/>
      );
    }
    if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) != null) {
      if (url && url.length > 1 && url.slice(0, 2) !== "ht") {
        urlNew = new URL("http://" + url);
      } else {
        urlNew = new URL(url);
      }
      return (
        <img className={s.logo}
             src={`https://www.google.com/s2/favicons?domain=${urlNew.hostname.slice(0, 3) == "www" ? urlNew.hostname.slice(4) : urlNew.hostname}`}
             alt="Logo"/>
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      suppliersLogoUrl: "",
    },
    onSubmit: (values) => {
      services.supplierServices.putSupplier(params.id, values.suppliersLogoUrl.replace(/ /g, ''))
        .then(res => onSubmit(res.data));
      setShow(false);
    }
  });
  const {handleSubmit, setFieldValue, errors, touched} = formik;

  return (
    <Popup
      title={title}
      onClose={() => setShow(false)}
    >
      <form onSubmit={handleSubmit}>
        <Input
          onChange={(value) => setFieldValue("suppliersLogoUrl", value.target.value)}
          value={url}
          placeholder={`Enter supplier URL`}
          label={`URL`}
          error={errors.suppliersLogoUrl && touched.suppliersLogoUrl && errors.suppliersLogoUrl}
        />
        {url ? logoSuppliers()
          : <div className={classNames(s.logoGrey)}>
            {avatar}
          </div>
        }
        <div className={s.popupBtnsWrap}>
          <Button
            styled="secondary"
            type="reset"
            className="popupBtn"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button styled="primary" type="submit" className="popupBtn">
            Upload
          </Button>
        </div>
      </form>
    </Popup>
  );
};
export default UploadLogoPopup;
UploadLogoPopup.propTypes = {
  title: PropTypes.string,
  setShow: PropTypes.func,
  onClose: PropTypes.func,
};