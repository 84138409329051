import React from 'react';
import {useFormik} from "formik";
import {object, string} from "yup";
import s from "./TranslateForm.module.scss";
import Input from "../../elements/Input/Input";
import Button from "../../elements/Button/Button";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import {useTranslation} from "react-i18next";

const TranslateForm = ({onClose, onSubmit, value, disabledSendBtn}) => {
  const {t} = useTranslation();

  const formik = useFormik({
    initialValues: {
      default_text: capitalizeFirstLetter(t(value).split("-").join(" ")),
      translation_text: t(value),
    },
    validationSchema: object({
      translation_text: string().required("Translation is required"),
    }),
    onSubmit: ({translation_text}) => onSubmit(value, translation_text),
  });

  const {handleSubmit, values, setFieldValue, errors, touched} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        label="Default name"
        disabled={true}
        value={values.default_text}
        className={s.field}
        withoutChange
      />
      <Input
        label="Current name"
        value={values.translation_text}
        onChange={e => setFieldValue("translation_text", e.target.value)}
        error={errors.translation_text && touched.translation_text && errors.translation_text}
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={disabledSendBtn}
          styled="primary"
          type="submit"
          className="popupBtn"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default TranslateForm;