import React from "react";
import Popup from "components/popups/Popup/Popup";
import ChangeAdminApprovalGroupForm from "components/forms/ChangeAdminApprovalGroupForm/ChangeAdminApprovalGroupForm";

const AddGroupPopup = ({ onClose, onSubmit, approvers, departments }) => {
  return (
    <Popup
      onClose={onClose}
      title="New approval group"
      text=" Please, complete the following fields to create an approval group"
    >
      <ChangeAdminApprovalGroupForm
        approvers={approvers}
        onClose={onClose}
        onSubmit={onSubmit}
        departments={departments}
      />
    </Popup>
  );
};
export default AddGroupPopup;
