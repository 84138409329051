import React, { useEffect, useReducer, Fragment } from "react"
import {
  setStakeholders,
  addStakeholder,
  changeStakeholder,
  setCurrentStakeholder,
  deleteStakeholder,
  closeAlert,
  setPopup
} from "./helpers/actions"
import { reducer, initialState } from "./helpers/reducer"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import TableLoading from "components/loadingStates/Table/Table"
import AdminStakeholderTypesTable from "components/tables/AdminStakeholderTypesTable/AdminStakeholderTypesTable"
import AddPopup from "./popups/AddPopup/AddPopup"
import EditPopup from "./popups/EditPopup/EditPopup"
import DeletePopup from "./popups/DeletePopup/DeletePopup"
import NotificationBox from "components/elements/NotificationBox/NotificationBox"
const Stakeholders = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    loading,
    stakeholder_types_count,
    popup,
    alert,
    current_stakeholder
  } = state
  useEffect(() => setStakeholders(dispatch), [])
  const onSort = search_query => setStakeholders(dispatch, search_query)
  const onPageChange = page =>
    setStakeholders(dispatch, state.search_query, page)
  return (
    <Fragment>
      {!loading && stakeholder_types_count > 0 && (
        <AdminStakeholderTypesTable
          state={state}
          onSort={onSort}
          onPageChange={onPageChange}
          onAdd={() => dispatch(setPopup("add"))}
          onEdit={item => {
            dispatch(setPopup("edit"))
            dispatch(setCurrentStakeholder(item))
          }}
          onDelete={id => {
            dispatch(setPopup("delete"))
            dispatch(setCurrentStakeholder(id))
          }}
        />
      )}
      {!loading && !stakeholder_types_count && (
        <EmptyBlock
          subtitle='You don’t have any stakeholder types yet.'
          buttonTitle='New type'
          onClick={() => dispatch(setPopup("add"))}
          withBtn={true}
        />
      )}
      {loading && <TableLoading length={2} />}
      {popup === "add" && (
        <AddPopup
          onClose={() => dispatch(setPopup(null))}
          onSubmit={values => addStakeholder(dispatch, { name: values.type })}
        />
      )}
      {popup === "edit" && (
        <EditPopup
          onClose={() => dispatch(setPopup(null))}
          onSubmit={values =>
            changeStakeholder(dispatch, current_stakeholder?.id, {
              name: values.type
            })
          }
          defaultValues={{ type: current_stakeholder?.name }}
        />
      )}
      {popup === "delete" && (
        <DeletePopup
          onClose={() => dispatch(setPopup(null))}
          onSubmit={() => deleteStakeholder(dispatch, current_stakeholder)}
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          onClose={() => dispatch(closeAlert())}
        />
      )}
    </Fragment>
  )
}
export default Stakeholders
