import { formatUserRoles } from "helpers/userPermissions/formatUserRoles";
export default (data) => {
  const _data = {
    Title: data.title || "-",
    Type: data.user_type || "-",
    Role: data.roles
      ? formatUserRoles(data.roles)
          .map((e) => e.label)
          .join(", ")
      : "-",
    Email: data.email || "-",
    Division: data.division.name || "-",
    Department: data.department.name || "-",
  };
  if (_data.Role === "cpo") _data.Role = _data.Role.toUpperCase();
  return Object.keys(_data).map((key) => ({
    name: key,
    value: _data[key],
  }));
};
