export const getAllowanceOptions = () => {
  const months = [...Array(43)].map((_, i) => ({
    value: String(6 + i),
    label: String(6 + i),
  }));
  return [{ value: "full", label: "All" }, ...months];
};

export const getAllowance = (data) => {
  let result;
  if (data.allowance_rule === "full") result = { label: "All", value: "full" };
  else
    result = {
      label: String(data.allowance_months_count),
      value: String(data.allowance_months_count),
    };
  return result;
};
