import React, {useEffect, useState} from "react";
import s from "./EditAdvancedProjectContainer.module.scss";
import ProjectForm from "components/forms/ProjectRequestActionsForm/ProjectForm";
import {CircularProgress} from "@material-ui/core";
import {formatEntityData, formatEntityToObject} from "../../../helpers/formatEntityData";
import {load} from "../../../helpers/localStorage";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import services from "../../../services";

const EditAdvancedProjectContainer = () => {
  const {t} = useTranslation();
  const [project, setProject] = useState();
  const [stages, setStages] = useState([]);

  useEffect(() => {
    setProject(load("globalProject")?.data);

    services.projectServices.getStages().then(res => {
      setStages(
        res.data?.stages?.map(({id, name}) => ({
          id,
          label: name,
          value: name
        })) || []
      );
    });

  }, []);

  const getFormattedDate = data => {
    const d = data.split("/");
    return `${d[1]}/${d[0]}/${d[2]}`;
  };

  const getProjectParams = () => {
    return {
      ...project,
      suppliers: project.suppliers,
      stakeholders: formatEntityToObject(formatEntityData(project.stakeholders)),
      clients: project.clients,
      start_date: new Date(getFormattedDate(project.start_date)),
      end_date: new Date(getFormattedDate(project.end_date)),
      category_id: project.category_id,
      owner_id: project.owner_id,
    };
  };

  if (!project) return (
    <div className={s.loader}>
      <CircularProgress/>
    </div>
  );
  return (
    <div className={s.wrap}>
      <h2 className={s.title} data-translation-key={getTranslateKey("Edit project")}>
        {t(getTranslateKey("Edit project"))}
      </h2>
      {stages.length > 0 && <ProjectForm
        initialProject={getProjectParams()}
        stages={stages}
        preparedClients={project.departments}
        preparedStakeholders={project.suppliers}
        isEditAdvancedProject={true}
      />}
    </div>
  );
};

export default EditAdvancedProjectContainer;