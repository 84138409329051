import axios from "./axios";

export default {
  getCategories: async (params) => {
    try {
      const result = await axios.get("/categories", {params});
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategory: async id => {
    try {
      return await axios.get(`/categories/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getCategoriesFilters: async (params) => {
    try {
      const result = await axios.get("/category_filters", {
        params,
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategoryProjects: async (id, params) => {
    try {
      const result = await axios.get(`/categories/${id}/projects`, {
        params,
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategorySuppliers: async (id, params) => {
    try {
      const result = await axios.get(`/categories/${id}/suppliers`, {
        params,
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategoryContracts: async (id, params) => {
    try {
      const result = await axios.get(`/categories/${id}/contracts`, {
        params,
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategoryStakeholders: async (id, params) => {
    try {
      const result = await axios.get(`/categories/${id}/users`, {
        params,
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategoriesSpend: async params => {
    try {
      const result = await axios.post("/categories_spend", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategoriesList: async params => {
    try {
      const result = await axios.post("/categories_list", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategoriesSpendByStatus: async params => {
    try {
      const result = await axios.post("/spend_by_status", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategoriesMainProjects: async params => {
    try {
      const result = await axios.post("/categories_main_projects", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategoriesSuppliersRate: async params => {
    try {
      const result = await axios.post("/suppliers_rate", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCategoriesContractsExpirations: async params => {
    try {
      const result = await axios.post("/contracts_expirations", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
};
