import React, { useReducer, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import querystring from "query-string";
import { debounce } from "lodash";
import Popup from "components/popups/Popup/Popup";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import CategoriesStakeholdersTable from "components/tables/CategoriesStakeholdersTable/CategoriesStakeholdersTable";
import CategoryStakeholderForm from "components/forms/CategoryStakeholderForm/CategoryStakeholderForm";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import {
  setStakeholders,
  addStakeholder,
  deleteStakeholder,
  closePopup,
  openPopup,
  setSelectedStakeholder,
  setAlert,
} from "./helpers/actions";
import { initialState, reducer } from "./helpers/reducer";
import { popupTypes } from "./helpers/types";
import services from "services";
import TableLoading from "components/loadingStates/Table/Table";

const Stakeholders = () => {
  const params = useParams();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentCategoryID } = useSelector((state) => state.categories);
  const [searchStakeholders, setSearchStakeholders] = useState([]);

  const query = querystring.parse(history.location.search) || {};

  useEffect(() => {
    setStakeholders(dispatch, params.id || currentCategoryID, query);
  }, [history.location, params.id]);

  const searchHandler = debounce((term) => {
    services.userServices
      .stakeholdersAutocomplete({ query: term })
      .then((res) => setSearchStakeholders(res.data));
  }, 300);

  return (
    <>
      {state.loading && <TableLoading length={6} />}
      {state.stakeholders.length > 0 && !state.loading && (
        <CategoriesStakeholdersTable
          data={state.stakeholders}
          permissions={state.permissions}
          totalCount={state.stakeholders_count}
          perPage={20}
          onAdd={() => dispatch(openPopup(popupTypes.add))}
          onDelete={(item) => {
            dispatch(setSelectedStakeholder(item));
            dispatch(openPopup(popupTypes.delete));
          }}
        />
      )}
      {!state.stakeholders.length && !state.loading && (
        <EmptyBlock
          buttonTitle="Add stakeholder"
          onClick={() => dispatch(openPopup(popupTypes.add))}
          subtitle={
            state.permissions.update
              ? "There are no stakeholders in this category yet. You can add a new one"
              : "There are no stakeholders in this category yet."
          }
          withBtn={state.permissions.update}
        />
      )}

      {state.popup === popupTypes.add && (
        <Popup
          onClose={() => dispatch(closePopup())}
          title="Add stakeholder"
          text="Choose a stakeholder from existing ones"
        >
          <CategoryStakeholderForm
            stakeholders={searchStakeholders}
            currentStakeholders={state.stakeholders}
            addHandler={(values) => addStakeholder(dispatch, params.id, values)}
            onClose={() => dispatch(closePopup())}
            onSearch={searchHandler}
          />
        </Popup>
      )}
      {state.popup === popupTypes.delete && (
        <Popup
          onClose={() => dispatch(closePopup())}
          title="Delete stakeholder"
          text="Are you sure you want to delete this stakeholder?"
          onClose={() => dispatch(closePopup())}
          onClick={() =>
            deleteStakeholder(
              dispatch,
              params.id,
              state.current_stakeholder?.id
            )
          }
          dialog
          isDelete
        ></Popup>
      )}
      {state.alert.message && (
        <NotificationBox
          message={state.alert.message}
          type={state.alert.type}
          disappearTime={4}
          onClose={() => dispatch(setAlert({ type: "", message: "" }))}
        />
      )}
    </>
  );
};

export default Stakeholders;
