import React, {useState} from "react";
import styles from "./Subtasks.module.scss";
import Popup from "../../../../popups/Popup/Popup";
import SubtaskForm from "../../../../forms/SubtaskForm/SubtaskForm";
import EmptyBlock from "../../../../elements/EmptyBlock/EmptyBlock";
import PropTypes from "prop-types";
import Button from "../../../../elements/Button/Button";
import Accordeon from "../../../../elements/MilestonesTasksBoard/components/Accordeon/Accordeon";
import SingleSubtask from "../SingleSubtask/SingleSubtask";
import TableLoading from "../../../../loadingStates/Table/Table";

const Subtasks = (
  {
    subtasks = [],
    responsibleUsers = [],
    onAdd,
    setSelectedSubtask,
    onDelete,
    onEdit,
    isHighRule,
    loading,
    changeStatus,
    subtasksCount,
    isResponsibleUser,
    useForProject
  }
) => {
  const [popUp, setPopUp] = useState(null);
  const requiredSubTasks = subtasks.filter(s => !s.optional);
  const optionalSubTasks = subtasks.filter(s => s.optional);
  const [currentSubtask, setCurrentSubtask] = useState(null);

  const newSubtaskPopUp = (
    <Popup
      title="New subtask"
      text="Please, complete the following fields to create a new subtask"
      onClose={() => setPopUp(null)}
    >
      <SubtaskForm
        onClose={() => setPopUp(null)}
        responsibleUsers={responsibleUsers}
        onSubmit={values => {
          onAdd(values);
          setPopUp(null);
        }}
      />
    </Popup>
  );

  const onOpenPopUp = (type, currentSubtask) => {
    setPopUp(type);
    setCurrentSubtask(currentSubtask);
  };

  const deleteHandler = () => {
    onDelete(currentSubtask.id);
    setPopUp(null);
    setCurrentSubtask(null);
  };

  const editHandler = params => {
    onEdit(params, currentSubtask.id);
    setPopUp(null);
    setCurrentSubtask(null);
  };

  if (loading) return <TableLoading length={4}/>;

  const getProgressPercent = type => {
    return `${Math.round((subtasksCount[type] * 100) / subtasksCount.total) || 0}%`;
  };

  if (!subtasks?.length) return (
    <React.Fragment>
      <EmptyBlock
        subtitle={`You don’t have subtasks yet. ${isHighRule && "You can add a new one."}`}
        buttonTitle="New subtask"
        onClick={() => setPopUp("new")}
        withBtn={isHighRule}
      />
      {popUp === "new" && newSubtaskPopUp}
    </React.Fragment>
  );

  return (
    <div className={styles.subtasksWrapper}>
      <header>
        <h3>Subtasks <span>{subtasks.length}</span></h3>
        {
          isHighRule && (
            <Button onClick={() => setPopUp("new")}>
              New subtask
            </Button>
          )
        }
      </header>
      <div className={styles.progressWrap}>
        <div className={styles.progress}>
          <div style={{background: "#83A755", width: getProgressPercent("completed")}}/>
          <div style={{background: "#5B79B4", width: getProgressPercent("in_progress")}}/>
        </div>
        <p>{getProgressPercent("completed")} Completed</p>
      </div>
      <div className={styles.subtasksHeader}>
        <div>Subtask</div>
        <div>Assignee</div>
        <div>Due date</div>
        <div>Status</div>
        <div/>
      </div>
      <div className={styles.accordeonGroup}>
        {requiredSubTasks.length > 0 && (
          <Accordeon title="Required subtasks" positionTitle="left">
            <div className={styles.subtasks}>
              {requiredSubTasks.map(subtask => (
                <SingleSubtask
                  data={subtask}
                  key={subtask.id}
                  setSelectedSubtask={setSelectedSubtask}
                  openPopUp={onOpenPopUp}
                  isHighRule={isHighRule}
                  isResponsibleUser={isResponsibleUser}
                  changeStatus={changeStatus}
                  useForProject={useForProject}
                />
              ))}
            </div>
          </Accordeon>
        )}
        {optionalSubTasks.length > 0 && (
          <Accordeon title="Optional subtasks" positionTitle="left">
            <div className={styles.subtasks}>
              {optionalSubTasks.map(subtask => (
                <SingleSubtask
                  data={subtask}
                  key={subtask.id}
                  setSelectedSubtask={setSelectedSubtask}
                  openPopUp={onOpenPopUp}
                  isHighRule={isHighRule}
                  isResponsibleUser={isResponsibleUser}
                  changeStatus={changeStatus}
                  useForProject={useForProject}
                />
              ))}
            </div>
          </Accordeon>
        )}
      </div>
      {popUp === "new" && newSubtaskPopUp}
      {(popUp === "delete" && currentSubtask) && (
        <Popup
          title="Delete a subtask"
          text="Are you sure you want to delete this subtask?"
          onClose={() => setPopUp(null)}
          onClick={deleteHandler}
          isDelete
          dialog
        />
      )}
      {(popUp === "edit" && currentSubtask) && (
        <Popup title="Edit subtask" onClose={() => setPopUp(null)}>
          <SubtaskForm
            onClose={() => setPopUp(null)}
            defaultValues={{
              ...currentSubtask,
              responsible_user: {
                value: currentSubtask.responsible_user.id,
                label: currentSubtask.responsible_user.name
              },
              due_date: new Date(currentSubtask.due_date)
            }}
            responsibleUsers={responsibleUsers}
            onSubmit={editHandler}
          />
        </Popup>
      )
      }
    </div>
  );
};

export default Subtasks;

Subtasks.propTypes = {
  subtasks: PropTypes.array,
  responsibleUsers: PropTypes.array,
  onAdd: PropTypes.func,
  setSelectedSubtask: PropTypes.func,
  deleteSubtask: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  isHighRule: PropTypes.bool,
  loading: PropTypes.bool,
  changeStatus: PropTypes.func,
  subtasksCount: PropTypes.any,
  isResponsibleUser: PropTypes.bool,
  useForProject: PropTypes.bool
};