import React from "react";
import Button from "../../../../elements/Button/Button";
import s from "./EmptyContent.module.scss";
import {useTranslation} from "react-i18next";

const EmptyContent = ({addNewAction}) => {
  const {t} = useTranslation();
  return (
    <div className={s.emptyContent}>
      <p className={s.emptyNotification}
         data-translate-key="you-don’t-have-additional-questions-yet.-you-can-add-a-new-one">
        {t("you-don’t-have-additional-questions-yet.-you-can-add-a-new-one")}
      </p>
      <Button styled="addElement" onClick={addNewAction}
              data-translate-key="new-question">
        {t("new-question")}
      </Button>
    </div>
  );
};
export default EmptyContent;
