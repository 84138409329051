import React from "react"
import { fromDate } from "helpers/dateFormat"
import { localization } from "helpers/localizationForCompanies"
import qs from "query-string"
import Tags from "components/elements/Tags/Tags"
import { currencyData, projectTypes } from "helpers/helpData"
import { formatCurrency } from "helpers/currencyFormat"

const projectsTableData = ({
  history,
  onSort,
  deleteTagAction,
  onAssignTag
}) => {
  const query = qs.parse(history.location.search)

  return [
    {
      title: "Project",
      value: ({ name }) => name,
      sortable: true,
      handleSort: ({ direction }) => {
        onSort({ sort_by: "name", direction })
      },
      onClick: id =>
        history.push({
          pathname: `/projects/${id}`,
          state: {
            history: history.location.pathname + history.location.search
          }
        }),
      sticky: true
    },
    {
      title: "Type",
      value: ({ project_type }) =>
      project_type?.name || '-',
      sortable: true,
      handleSort: ({ direction }) => {
        onSort({ sort_by: "project_type", direction })
      }
    },
    {
      title: "Project Owner",
      value: ({ owner }) => owner?.name || "-",
      sortable: true,
      handleSort: ({ direction }) => {
        onSort({ sort_by: "owner", direction })
      }
    },
    {
      title: "Savings",
      value: ({ savings }) =>
        formatCurrency(
          currencyData.filter(e => e.label === savings?.currency)[0]?.value,
          savings?.value
        ),
      sortable: true,
      handleSort: ({ direction }) => {
        onSort({ sort_by: "savings", direction })
      }
    },
    {
      title: "Status",
      value: ({ stage }) => stage?.name || '-',
      sortable: true,
      handleSort: ({ direction }) => {
        onSort({ sort_by: "status", direction })
      }
    },
    {
      title: "Start date",
      value: ({ start_date }) => (start_date ? fromDate(start_date) : "-"),
      sortable: true,
      handleSort: ({ direction }) => {
        onSort({ sort_by: "start_date", direction })
      }
    },
    {
      title: `${localization?.targetEndDate}`,
      value: ({ end_date }) => (end_date ? fromDate(end_date) : "-"),
      sortable: true,
      handleSort: ({ direction }) => {
        onSort({ sort_by: "end_date", direction })
      }
    },
    {
      title: "Tags",
      value: (item, allData) =>
        item.tags ? (
          <Tags
            allData={allData}
            width={235}
            item={item}
            onDelete={(id, tag) => deleteTagAction(id, tag, item)}
            onAssign={id => onAssignTag(id, item.id)}
            onSort={({ name }) =>
              query.tag !== name ? onSort({ tag: name }) : onSort({ tag: "" })
            }
          />
        ) : (
          "-"
        ),
      type: "tags"
    }
  ]
}
export default projectsTableData
