import React from 'react';
import s from "./RSAsyncSelect.module.scss";
import {RSCustomStyle} from "./selectStyle";
import {DropdownIndicator} from "./components/DropdownIndicator";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslate} from "../../../hooks/useTranslate";
import Select from 'react-select';

const RSSelect = (
  {
    options,
    className,
    label,
    onChange,
    defaultValues = [],
    defaultValue,
    isMulti = false,
    placeholder = "",
    error,
    customOption,
    isClearable = false
  }
) => {
  const [translateKey, translateValue] = useTranslate(label);
  const [translateErrorKey, translateErrorValue] = useTranslate(error);
  const {t} = useTranslation();

  let components = {DropdownIndicator};

  if (customOption) components = {...components, Option: customOption};

  return (
    <div className={className}>
      {label && <p data-translate-key={translateKey}>{translateValue}</p>}
      <Select
        isMulti={isMulti}
        options={options}
        isClearable={isClearable}
        onChange={item => onChange(item)}
        defaultOptions={defaultValues}
        defaultValue={defaultValue}
        placeholder={t(getTranslateKey(placeholder)) ?? "..."}
        components={components}
        styles={RSCustomStyle}
      />
      {error && (
        <span
          className={s.errorMessage}
          data-translate-key={translateErrorKey}
        >
          {translateErrorValue}
        </span>
      )}
    </div>
  );
};

export default RSSelect;