import React, {useState} from "react";
import styles from "./Subtasks.module.scss";
import Button from "../../../../elements/Button/Button";
import PropTypes from "prop-types";
import Accordeon from "../../../../elements/MilestonesTasksBoard/components/Accordeon/Accordeon";
import Popup from "../../../../popups/Popup/Popup";
import SubtaskForm from "../../../../forms/SubtaskForm/SubtaskForm";
import EmptyBlock from "../../../../elements/EmptyBlock/EmptyBlock";
import SingleSubtask from "./SingleSubtask";

const Subtasks = (
  {
    subtasks,
    addSubtask,
    deleteSubtask,
    editSubtask,
    selectTask
  }
) => {

  const requiredSubTasks = subtasks.filter(s => !s.optional);
  const optionalSubTasks = subtasks.filter(s => s.optional);
  const [popUp, setPopUp] = useState(null);

  const newSubtaskPopUp = (
    <Popup
      title="New subtask"
      text="Please, complete the following fields to create a new subtask"
      onClose={() => setPopUp(null)}
    >
      <SubtaskForm
        onClose={() => setPopUp(null)}
        onSubmit={values => {
          addSubtask(values);
          setPopUp(null);
        }}
      />
    </Popup>
  );


  if (!subtasks.length) return (
    <React.Fragment>
      <EmptyBlock
        subtitle="You don’t have subtasks yet. You can add a new one."
        buttonTitle="New subtask"
        onClick={() => setPopUp("new")}
      />
      {popUp === "new" && newSubtaskPopUp}
    </React.Fragment>
  );

  return (
    <div className={styles.subtasksWrapper}>
      <header>
        <div className={styles.title}>
          <div className={styles.titleWrap}>
            <h3>Subtasks</h3>
            <span>{subtasks.length}</span>
          </div>
          <Button onClick={() => setPopUp("new")}>
            New subtask
          </Button>
        </div>
        <div className={styles.header}>
          <div className={styles.subTitle}>
            Subtask
          </div>
          <div className={styles.subTitle}>
            Assignee
          </div>
        </div>
      </header>
      {requiredSubTasks.length > 0 && (
        <Accordeon title="Required subtasks" positionTitle="left">
          <div className={styles.subtasks}>
            {requiredSubTasks.map(subtask => (
              <SingleSubtask
                data={subtask}
                deleteSubtask={deleteSubtask}
                editSubtask={editSubtask}
                selectTask={selectTask}
                key={subtask.id}
              />
            ))}
          </div>
        </Accordeon>
      )}
      {optionalSubTasks.length > 0 && (
        <Accordeon title="Optional subtasks" positionTitle="left">
          <div className={styles.subtasks}>
            {optionalSubTasks.map(subtask => (
              <SingleSubtask
                data={subtask}
                deleteSubtask={deleteSubtask}
                editSubtask={editSubtask}
                selectTask={selectTask}
                key={subtask.id}
              />
            ))}
          </div>
        </Accordeon>
      )}
      {popUp === "new" && newSubtaskPopUp}
    </div>
  );
};

export default Subtasks;

Subtasks.propTypes = {
  subtasks: PropTypes.array,
  addSubtask: PropTypes.func,
  deleteSubtask: PropTypes.func,
  editSubtask: PropTypes.func,
  selectTask: PropTypes.func
};