import React, { useEffect, useState, Fragment, useContext } from "react";
import { useParams } from "react-router-dom";
import s from "./Notes.module.scss";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import Popup from "components/popups/Popup/Popup";
import AddProjectNoteForm from "components/forms/AddProjectNoteForm/AddProjectNoteForm";
import ProjectNotesTable from "components/tables/ProjectNotesTable/ProjectNotesTable";
import services from "services";
import { cancelRequest } from "services/axios";
import AlertContext from "contexts/AlertContext/AlertContext";
import TableLoading from "components/loadingStates/Table/Table";

const Notes = () => {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [notesPermissionsUpdate, setNotesPermissionsUpdate] = useState(false);
  const { setAlert, getCancelId } = useContext(AlertContext);

  useEffect(() => {
    services.notesServices.getNotes(params.id).then(res => {
      setNotes(res.data.notes);
      setNotesPermissionsUpdate(res.data.permissions.update);
      setLoading(false);
    });
  }, [params.id]);
  const appendFile = file => {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
  };
  const addHandler = ({ attachments, ...spread }) => {
    services.notesServices
      .addNote(params.id, { ...spread })
      .then(res => {
        const note = res?.data;
        setNotes([...notes, note]);
        setAlert({
          message: "Note successfully added."
        });
        for (let i = 0; i < attachments.length; i++) {
          const file = appendFile(attachments[i]);
          note.id &&
            services.taskServices.addAttachment(file, note.id).then(res => {
              const attachment = res?.data;
              attachment &&
                setNotes(notes =>
                  notes.map(e =>
                    e.id === note.id
                      ? {
                          ...e,
                          attachments: [...e.attachments, attachment]
                        }
                      : e
                  )
                );
            });
        }
      })
      .catch(() =>
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      );
  };

  const editHandler = (id, { attachments, ...spread }) => {
    const cancelId = getCancelId();
    const oldNotes = notes;
    const newNote = { ...notes.find(note => note.id === id), ...spread };

    setNotes(notes.map(item => (item.id === id ? newNote : item)));
    setAlert({
      message: "Note successfully edited.",
      onCancel: () => {
        cancelRequest(cancelId);
        setNotes(oldNotes);
      }
    });
    services.notesServices
      .editNote(params.id, id, { ...spread }, { cancelId })
      .then(res => {
        const note = res?.data;
        setNotes(notes.map(item => (item.id === id ? note : item)));
        for (let i = 0; i < attachments?.length; i++) {
          const file = appendFile(attachments?.[i]);
          note.id &&
            services.taskServices.addAttachment(file, note.id).then(res => {
              const attachment = res?.data;
              attachment &&
                setNotes(notes =>
                  notes.map(e =>
                    e.id === note.id
                      ? {
                          ...e,
                          attachments: [...e.attachments, attachment]
                        }
                      : e
                  )
                );
            });
        }
      })
      .catch(() => {
        setNotes(oldNotes);
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };

  const removeHandler = id => {
    const cancelId = getCancelId();
    const oldNotes = notes;

    setNotes(notes.filter(item => item.id !== id));
    setAlert({
      message: "Note successfully deleted.",
      onCancel: () => {
        cancelRequest(cancelId);
        setNotes(oldNotes);
      }
    });
    services.notesServices
      .deleteNote(params.id, id, { cancelId })
      .then()
      .catch(() => {
        setNotes(oldNotes);
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };

  return (
    <section className={s.container}>
      {loading && <TableLoading length={2} />}
      {!loading && (
        <Fragment>
          {notes.length ? (
            <ProjectNotesTable
              notes={notes}
              removeHandler={removeHandler}
              editHandler={editHandler}
              openPopup={() => setShow(true)}
            />
          ) : (
              notesPermissionsUpdate && <EmptyBlock
              subtitle='You don’t have notes yet. You can add a new one'
              buttonTitle='Add note'
              onClick={() => setShow(true)}
              withBtn={true}
            />
          )}
          {show && (
            <Popup onClose={() => setShow(false)} title='Add note'>
              <AddProjectNoteForm
                addHandler={addHandler}
                onClose={() => setShow(false)}
              />
            </Popup>
          )}
        </Fragment>
      )}
    </section>
  );
};

export default Notes;
