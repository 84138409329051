import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./Checkbox.module.scss";
import {useTranslate} from "../../../hooks/useTranslate";

const Checkbox = ({className, value, checked, onChange, label, type, error, subLabel, withTranslate}) => {
  const [translateKey, translateValue] = useTranslate(label);
  const [translateKeySubLabel, translateValueSubLabel] = useTranslate(subLabel);
  const [translateErrorKey, translateErrorValue] = useTranslate(error);

  return (
    <div
      className={classNames(s.checkboxWrap, className, {
        [s.typeMinus]: type === "minus",
      })}
    >
      <label>
        <input
          type="checkbox"
          value={value}
          onChange={onChange}
          checked={checked}
          name={label}
        />
        <span className={s.checkbox}>
          <span className={s.check}/>
        </span>
        <div className={s.labelGroup}>
          {(label && !withTranslate) && <span className={s.label}>{label}</span>}
          {(subLabel && !withTranslate) && <span className={s.subLabel}>{subLabel}</span>}
          {(label && withTranslate) &&
            <span className={s.label} data-translate-key={translateKey}>{translateValue}</span>}
          {(subLabel && withTranslate) &&
            <span className={s.subLabel} data-translate-key={translateKeySubLabel}>{translateValueSubLabel}</span>}
          {error && (
            <span className={s.errorMessage} data-translate-key={translateErrorKey}>
              {" "}
              {translateErrorValue}
            </span>
          )}
        </div>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["minus", "check"]),
  label: PropTypes.string,
  subLabel: PropTypes.string,
};

Checkbox.defaultProps = {
  className: "",
  value: false,
  onChange: () => {
  },
  type: "check",
};

export default Checkbox;
