import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { object, string } from "yup"
import AutoCompleteInput from "components/elements/AutoCompleteInput/AutoCompleteInput"
import Button from "components/elements/Button/Button"
import services from "services"
import { useSelector } from "react-redux"
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown"
import { useParams } from "react-router-dom"
export const statuses = [
  {
    label: "Preferred",
    value: "preferred"
  },
  {
    label: "Approved",
    value: "approved"
  },
  {
    label: "Non approved",
    value: "non_approved"
  }
]
const AddCategorySupplierForm = ({
  suppliers,
  currentSuppliers,
  addHandler,
  onClose,
  onSearch
}) => {
  const [data, setData] = useState([])
  const [value, setValue] = useState("")
  const organizationName = useSelector(state => state.user.organization.name)
  const isIndeedCopmany = organizationName === "Indeed"
  const params = useParams()
  useEffect(() => {
    const filteredSuppliers = suppliers.filter(
      supplier => !currentSuppliers.find(item => item.id === supplier.id)
    )
    setData(filteredSuppliers)
  }, [suppliers, currentSuppliers])
  const formik = useFormik({
    initialValues: {
      id: "",
      status: { value: "", label: "" }
    },
    validationSchema: object({
      id: string()
        .required("Please, choose the supplier")
        .nullable()
    }),
    validate: ({ status }) => {
      const errors = {}
      if (status.value === "") {
        errors.status = "Please, choose the status"
      }
      return errors
    },
    onSubmit: values => {
      addHandler({
        category_id: params.id,
        supplier_id: values.id,
        status: values.status.value
      })
      onClose()
    }
  })
  const { handleSubmit, errors, setFieldValue, values, touched } = formik
  const handleEmptyTextClick = () => {
    services.supplierServices.createSupplier(value).then(res => {
      setData([...data, res.data])
    })
  }
  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <AutoCompleteInput
        data={data}
        value={value}
        placeholder={"Enter supplier’s name"}
        onChange={e => {
          setFieldValue("id", "")
          setValue(e.target.value)
          onSearch(e.target.value)
        }}
        onSelect={value => {
          setFieldValue("id", value.id)
          setValue(value.name)
        }}
        label='Supplier'
        error={
          (!data.length && value.length > 0 ? "No available suppliers" : "") ||
          errors.id
        }
        emptyText={
          isIndeedCopmany && {
            text: "No work item results found. You can ",
            linkText: "add new supplier"
          }
        }
        handleEmptyTextClick={isIndeedCopmany && handleEmptyTextClick}
      />
      <div style={{ marginTop: "16px" }}>
        <CustomDropdown
          options={statuses}
          value={values.status.value}
          onChange={option => setFieldValue("status", option)}
          label='Status'
          placeholder='Choose status'
          error={errors.status}
        />
      </div>
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled='primary' type='submit' className='popupBtn'>
          Add
        </Button>
      </div>
    </form>
  )
}
export default AddCategorySupplierForm
