import React, {useEffect, useState} from "react";
import classNames from "classnames";
import services from "services/index";
import s from "./LastActivities.module.scss";
import sBase from "../SidebarDashboard.module.scss";
import SidebarWidgetsLoading from "../../../../../loadingStates/Dashboard/SidebarWidgets/SidebarWidgetsLoading";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import gradient from "random-gradient";
import moment from "moment";
import {useTranslation} from "react-i18next";

const LastActivities = () => {
  const [data, setData] = useState({
    last: []
  });
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    services.dashboardServices.getLastActivities().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const getDate = (activity) => {
    if (activity.last_updated_at){
      return `Updated ${moment(activity.last_updated_at).format("h:mm:ss a, MM/DD/YYYY")}`;
    }else {
      return `Created ${moment(activity.created_at).format("h:mm:ss a, MM/DD/YYYY")}`;
    }
  }

  const getContent = () => {
    if (data.last.length) {
      return (
        <div className={s.content}>
          {
            data.last.map(a => {
              const avatarGradient = {background: gradient(a.owner.name)}
              return (
                <a href={`/projects/${a.id}`} className={s.activity}>
                  <div className={s.owner}>
                    <div className={s.avatarWrapper} style={avatarGradient}>
                      {a.owner.name.substr(0, 1)}
                    </div>
                    <div className={s.info}>
                      <p className={s.ownerName}>{a.owner.name}</p>
                      <p className={s.reportName}>{a.name}</p>
                      <span className={s.date}>{getDate(a)}</span>
                    </div>
                  </div>
                </a>
              );
            })
          }
        </div>
      );
    }
    return <EmptyContent padding={88} text='You don’t have activity yet.' withTranslate/>;
  }

  return (
    <section className={classNames(sBase.wrapper, s.activityWrapper)}>
      {
        loading ? <SidebarWidgetsLoading/> :
          <>
            <h4 className={classNames(sBase.title, s.title)} data-translate-key="last-activities">
              {t("last-activities")}
            </h4>
            {getContent()}
          </>
      }

    </section>
  );
};

export default LastActivities;


