import React, { useState } from "react"
import s from "./CheckList.module.scss"
import CheckListItem from "../CheckListItem/CheckListItem"
const CheckList = ({ data, emptyMessage, value, onChange, isSearching }) => {
  return (
    <ul className={s.dropdownMenu}>
      {data.length > 0 &&
        data.map((item, index) => (
          <CheckListItem
            item={item}
            value={value}
            onChange={onChange}
            emptyMessage={emptyMessage}
            key={index}
            isSearching={isSearching}
          />
        ))}
      {!data.length && <span>{emptyMessage}</span>}
    </ul>
  )
}
export default CheckList
