import React, {useEffect, useState} from "react";
import s from "./Contracts.module.scss";
import sBase from "../MainDashboard.module.scss";
import {getTitle} from "../../helpers/dataFormat";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import services from "../../../../../../services";
import classNames from "classnames";
import SingleContracts from "./SingleContracts";
import EmptyContent from "../../../../../elements/EmptyContent/EmptyContent";
import DefaultLoading from "../../../../../loadingStates/Dashboard/DefaultLoading/DefaultLoading";
import Button from "../../../../../elements/Button/Button";
import {paths} from "../../../../../../routes/paths";
import {ReactComponent as RedirectIcon} from "../../../../../../assets/icons/redirect-icon.svg";
import {useHistory} from "react-router-dom";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const Contracts = () => {
  const [data, setData] = useState({
    contracts: [],
  });
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const history = useHistory();


  useEffect(() => {
    services.dashboardServices.getContracts().then((res) => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  }, []);


  const renderContent = () => {
    if (loading) return (
      <section className={sBase.wrapper}>
        <DefaultLoading/>
      </section>
    );

    if (!loading && !data.contracts.length) return (
      <section className={classNames(sBase.wrapper, s.contractsWrapper)}>
        <header>
          <h4 className={sBase.title} data-translate-key={getTranslateKey(getTitle("contracts"))}>
            {t(getTranslateKey(getTitle("contracts")))}
          </h4>
        </header>
        <EmptyContent
          className={s.emptyBlock}
          text="You don't have any contracts yet."
        />
      </section>
    );

    return (
      <section className={classNames(sBase.wrapper, s.contractsWrapper)}>
        <header>
          <h4 className={sBase.title} data-translate-key={getTranslateKey("your-contracts")}>
            {t(getTranslateKey("your-contracts"))}
          </h4>
          <Button
            styled="secondary"
            className={s.redirectBtn}
            onClick={() => history.push(paths.contracts)}
          >
            <RedirectIcon/>
            <span data-translate-key={getTranslateKey("Show all")}>
              {t(getTranslateKey("Show all"))}
            </span></Button>
        </header>
        <TableContainer className={s.content}>
          <Table stickyHeader className={sBase.table}>
            <TableHead className={sBase.tableHead} data-attr="table-head">
              <TableRow>
                <TableCell className={sBase.tableCell}>
                  <span data-translate-key={getTranslateKey("Contract")}>
                    {t(getTranslateKey("Contract"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                   <span data-translate-key={getTranslateKey("End date")}>
                    {t(getTranslateKey("Contract"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                   <span data-translate-key={getTranslateKey("Currency")}>
                    {t(getTranslateKey("Currency"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                   <span data-translate-key={getTranslateKey("ACV")}>
                    {t(getTranslateKey("ACV"))}
                  </span>
                </TableCell>
                <TableCell className={sBase.tableCell}>
                   <span data-translate-key={getTranslateKey("TCV")}>
                    {t(getTranslateKey("TCV"))}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={sBase.tableBody}>
              {data.contracts.map(contract => <SingleContracts contract={contract}/>)}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    );
  };

  return renderContent();
};

export default Contracts;


