import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import FormConstructor from "../../elements/FormConstructor/FormConstructor";
import PropTypes from "prop-types";
import { fieldTypes } from "../../../helpers/helpData";
import services from "../../../services";

const RequestTypesStepFieldForm = ({ onSubmit, defaultValues, onCLose }) => {
  const [dropDowns, setDropDowns] = useState([]);

  useEffect(() => {
    services.customDropdownsServices.getCustomDropdownsAll().then(res => {
      if (res.data && res.data.length) {
        setDropDowns(res.data.map(dropDown => {
          return {
            value: dropDown.id,
            label: dropDown.name
          };
        }));
      }
    });
  }, []);

  const serializer = (values) => {
    const preparedParams = {
      ...values,
      field_type: values.field_type.value,
    };

    if (values.custom_dropdown_id) {
      preparedParams.custom_dropdown_id = values.custom_dropdown_id.value;
    }

    return preparedParams;
  };

  return (
    <FormConstructor
      submitBtn={{
        styled: "primary",
        title: defaultValues ? "Save" : "Create",
      }}
      cancelBtn={{
        styled: "secondary",
        title: "Cancel",
        onClick: () => onCLose(),
      }}
      fields={[
        {
          type: "text",
          title: "Field name",
          placeholder: "Enter name",
          limitValue: 50,
          required: string()
            .required("Field name is required")
            .max(50, "Please enter field name that consists of no more than 50 characters"),
          key: "name",
          value: defaultValues?.name || "",
        },
        {
          type: "dropdown",
          title: "Field type",
          placeholder: "Choose type",
          required: object({
            value: string().required("Type is required"),
          }),
          key: "field_type",
          value: defaultValues
            ? fieldTypes.find((type) => type.value === defaultValues.field_type)
            : {
                value: "",
                label: "",
              },
          options: fieldTypes,
        },
        {
          type: "dropdown",
          title: "Dropdown name",
          placeholder: "Choose Dropdown",
          required: object({
            value: string().required("Dropdown is required"),
          }),
          key: "custom_dropdown_id",
          value: defaultValues?.custom_dropdown
            ? {
                value: defaultValues.custom_dropdown.id,
                label: defaultValues.custom_dropdown.name,
              }
            : {
                value: "",
                label: "",
              },
          options: dropDowns,
          showCondition: {
            keyReference: "field_type",
            method: (value) => value === "Custom Dropdown",
            requiredMessage: "Dropdown is required",
          },
        },
        {
          type: "checkbox",
          title: "Optional field",
          key: "optional",
          value: defaultValues?.optional || false,
        },
      ]}
      onSubmit={(values) => onSubmit(serializer(values))}
    />
  );
};

export default RequestTypesStepFieldForm;

RequestTypesStepFieldForm.propTypes = {
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.any,
  onCLose: PropTypes.func,
};
