import React, {useState} from "react";
import styles from "./ConditionGroup.module.scss";
import DropListTrigger from "../../../../../elements/DropListTrigger/DropListTrigger";
import SingleCondition from "../SingleCondition/SingleCondition";
import RadioButtons from "../../../../../elements/Fields/RadioButtons/RadioButtons";
import PropTypes from "prop-types";

const ConditionGroup = (
  {
    condition,
    idx,
    deleteCondition,
    addSingleCondition,
    changeSingleCondition,
    isLast,
    changeCondition,
    deleteSingleCondition,
    isViewMode = false,
    fields
  }
) => {
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);
  return (
    <>
      <div className={styles.conditionGroup}>
        <div className={styles.header}>
          <div className={styles.groupName}>
            Conditions group {idx}
          </div>
          {!isViewMode && (
            <DropListTrigger
              data={[
                {
                  title: "Delete",
                  onClick: () => deleteCondition(condition.id)
                }
              ]}
              visibleMenu={visibleDropListMenu}
              setVisibleMenu={setVisibleDropListMenu}
            />
          )}
        </div>
        <div className={styles.content}>
          {condition.condition_group.map((singleCondition, index) => {
            return (
              <SingleCondition
                key={singleCondition.id}
                singleCondition={singleCondition}
                changeSingleCondition={(singleConditionId, value, type) =>
                  changeSingleCondition(condition.id, singleConditionId, value, type)}
                deleteSingleCondition={singleConditionId => deleteSingleCondition(condition.id, singleConditionId)}
                isLast={index + 1 === condition.condition_group.length}
                isLeftOne={Boolean(condition.condition_group.length === 1)}
                fields={fields}
                isViewMode={isViewMode}
              />
            );
          })}
        </div>
        {!isViewMode && (
          <button className={styles.addSingleCondition} onClick={e => {
            e.preventDefault();
            addSingleCondition(condition.id);
          }}>
            + Add new condition
          </button>
        )}
      </div>
      {!isLast && (
        <RadioButtons
          value={condition.condition}
          change={value => changeCondition(condition.id, value)}
          isViewMode={isViewMode}
        />
      )}
    </>
  );
};

export default ConditionGroup;

ConditionGroup.propTypes = {
  condition: PropTypes.any,
  idx: PropTypes.number,
  deleteCondition: PropTypes.func,
  addSingleCondition: PropTypes.func,
  changeSingleCondition: PropTypes.func,
  isLast: PropTypes.bool,
  changeCondition: PropTypes.func,
  deleteSingleCondition: PropTypes.func,
  isViewMode: PropTypes.bool,
  fields: PropTypes.any,
};