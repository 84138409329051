import React from "react";
import s from "./LeftSidebar.module.scss";
import SidebarNavigation from "./components/SidebarNavigation/SidebarNavigation";
import sidebarViewData from "./components/sidebarViewData";

const LeftSidebar = ({ width, setSize, childSetDragStop }) => {
  return (
    <div className={s.sidebar} style={{width: `${width}px`}}>
      <SidebarNavigation width={width} sidebarViewData={sidebarViewData()} setSize={setSize} childSetDragStop={childSetDragStop}/>
    </div>
  );
};

export default LeftSidebar;
