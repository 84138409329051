import React from "react";
import Popup from "components/popups/Popup/Popup";

const DeleteProjectGroupPopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      onClose={onClose}
      onClick={onSubmit}
      title="Delete project group"
      text="Are you sure you want to delete this project group?"
      isDelete
      dialog
      subText="All project types from it will be moved to the “Default” group."
    />
  );
};

export default DeleteProjectGroupPopup;
