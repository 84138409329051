import React from "react";
import classNames from "classnames";
import s from "./ProjectsTabs.module.scss";
import Tabs from "components/elements/Tabs/Tabs";
import BoardTab from "../BoardTab/BoardTab";
import ProjectsTable from "components/tables/ProjectsTable/ProjectsTable";
import queryString from "query-string";
import {useLocation, useHistory} from "react-router-dom";
import querystring from "query-string";
import {setSelectedProjectId, openPopup} from "../../helpers/actions";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import {userRoles} from "helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import ProjectsBoardLoading from "components/loadingStates/ProjectsBoard/ProjectsBoard";
import TableLoading from "components/loadingStates/Table/Table";
import ProjectsGanttChart from "components/elements/ProjectsGanttChart/ProjectsGanttChart";
import {autoFillYears} from "components/elements/ProjectsGanttChart/helpers/autoFillYears";
import {paths} from "../../../../../routes/paths";
import GroupedBySelector from "../../GroupedBySelector/GroupedBySelector";

const ProjectsTabs = (
  {
    state,
    dispatch,
    onDrop,
    className,
    tabsClassName,
    onDeleteTag,
    onAssignTag
  }
) => {
  const {projects, totalProjectsCount, stages} = state;
  const location = useLocation();
  const history = useHistory();
  const {tab, sort_by, direction, ...query} = queryString.parse(
    location.search
  );
  const user = useSelector(state => state.user);
  const isProjects = projects.length > 0;

  const handleDelete = id => {
    dispatch(setSelectedProjectId(id));
    dispatch(openPopup("deleteProject"));
  };

  const handleChangeStatus = id => {
    dispatch(setSelectedProjectId(id));
    dispatch(openPopup("changeProjectStatus"));
  };

  const handleTabChange = tab => {
    history.push({search: querystring.stringify({...query, tab})});
  };

  const handlePageChange = page => {
    const qs = {...query, tab: state.currentTab, page, sort_by, direction};
    history.push({search: querystring.stringify(qs)});
  };
  const emptyBlock = (
    <EmptyBlock
      subtitle='There are no projects according to your search.'
      buttonTitle='Add project'
      onClick={() => history.push(paths.createProject)}
      className={s.emptyBlock}
      withBtn={!user.roles.includes(userRoles.business_user)}
    />
  );
  return (
    <div className={classNames(s.projectTabs, className)}>
      <Tabs
        handleClick={handleTabChange}
        defaultActiveTab={tab}
        className={tabsClassName}
        additional={tab === "Board" && <GroupedBySelector/>}
      >
        <div label='Board'>
          {state.boardLoading ? (
            <ProjectsBoardLoading/>
          ) : isProjects ? (
            <BoardTab
              onDelete={handleDelete}
              projects={projects}
              onDrop={onDrop}
              stages={stages}
            />
          ) : (
            emptyBlock
          )}
        </div>
        <div label='List'>
          {state.boardLoading ? (
            <TableLoading length={7}/>
          ) : isProjects ? (
            <div style={{paddingLeft: "10px"}}>
              <ProjectsTable
                state={state}
                onDelete={handleDelete}
                onChangeStatus={handleChangeStatus}
                perPage={20}
                totalCount={totalProjectsCount}
                onPageChange={handlePageChange}
                className={s.table}
                dispatch={dispatch}
                onDeleteTag={onDeleteTag}
                onAssignTag={onAssignTag}
              />
            </div>
          ) : (
            emptyBlock
          )}
        </div>
        <div label='Gantt chart'>
          {isProjects ? (
            <ProjectsGanttChart
              className={s.gantChart}
              data={projects}
              selectedYears={autoFillYears(
                query.years
                  ?.split(",")
                  .map(e => +e)
                  .sort()
              )}
            />
          ) : (
            emptyBlock
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default ProjectsTabs;
