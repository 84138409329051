import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import MainTable from "components/tables/MainTable/MainTable";
import { TemplatesDelPopUp } from "../TemplatesDelPopUp/TemplatesDelPopUp.jsx";
import { useHistory } from "react-router-dom";
import { statuses } from "../Templates/Templates.jsx";
import services from "services/index.js";

const ColorBtn = styled.div`
  display: flex;
  align-items: center;
  max-width: 70px;
  height: 30px;
  padding: 8px 8px !important;
  border-radius: 6px;
  background-color: ${({ color }) => color ?? ""};
  &:last-child {
    color: #ffffff;
  }
`;

export const TemplatesTable = ({
  currentTemplates,
  setCurrentTemplates,
  setOpenPopUp,
  setEditItem,
}) => {
  const ARCHIVED = "archived";
  const history = useHistory();
  const [delResPopUp, setDelResPopUp] = useState({ checked: false, action: "", item: {} });

  const correctStatus = (st) => statuses.find((el) => el.value === st.status);

  const addNewTemp = () =>
    setOpenPopUp((prevPopUp) => ({ checked: !prevPopUp.checked, action: "Create" }));

  const isArchived = (item) => item.status === ARCHIVED;

  const handleDeleteRestore = (item) => {
    if (isArchived(item)) {
      setDelResPopUp((prevPopUp) => ({
        checked: !prevPopUp.checked,
        action: "Restore",
        item: item,
      }));
    } else {
      setDelResPopUp((prevPopUp) => ({
        checked: !prevPopUp.checked,
        action: "Delete",
        item: item,
      }));
    }
  };
  const onEdit = (item) => {
    setOpenPopUp((prevPopUp) => ({ checked: !prevPopUp.checked, action: "Edit" }));
    setEditItem(item);
  };

  const onDelete = (item) =>
    services.templatesServices.deleteTemplate(item.id).then((res) => {
      setCurrentTemplates((prevTemplates) => prevTemplates.filter((el) => !_.isEqual(el, item)));
    });

  const onRestore = (item) =>
    services.templatesServices
      .restoreTemplate(item.id, { status: statuses[0].value })
      .then((res) => {
        setCurrentTemplates((prevTemps) =>
          prevTemps.map((temp) => (temp.id === res.data.id ? res.data : temp)),
        );
      });

  return (
    <>
      {delResPopUp.checked && (
        <TemplatesDelPopUp
          setDelResPopUp={setDelResPopUp}
          item={delResPopUp}
          onDeleteRestore={isArchived(delResPopUp.item) ? onRestore : onDelete}
        />
      )}
      <MainTable
        data={currentTemplates}
        withXScroll={true}
        dataTypes={[
          {
            title: "Survey template",
            value: (item) => (
              <div
                onClick={() => history.push(`/project-survey/template/${item.id}`)}
                style={{ cursor: "pointer" }}>
                {item.name}
              </div>
            ),
          },

          {
            title: "Status",
            position: "left",
            value: (item) => (
              <ColorBtn color={correctStatus(item).color}>{correctStatus(item).label}</ColorBtn>
            ),
          },
        ]}
        options={(item) =>
          !isArchived(item)
            ? [
                {
                  title: "Edit",
                  onClick: () => onEdit(item),
                },
                {
                  title: "Delete",
                  onClick: () => handleDeleteRestore(item),
                },
              ]
            : [
                {
                  title: "Restore",
                  onClick: () => handleDeleteRestore(item),
                },
              ]
        }
        button={{
          name: "New template",
          styled: "addElement",
          onClick: addNewTemp,
        }}
      />
    </>
  );
};

TemplatesTable.propTypes = {
  currentTemplates: PropTypes.array,
  setCurrentTemplates: PropTypes.func,
  setOpenPopUp: PropTypes.func,
  setEditItem: PropTypes.func,
};
