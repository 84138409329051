import React from "react";
import s from "./SupplierADT.module.scss";
import sBase from "../../ContractDetailsContainer.module.scss";
import Wrapper from "../Wrapper/Wrapper";
import classNames from "classnames";
import {currencyData} from "../../../../../helpers/helpData";
import PropTypes from "prop-types";
import GeneralInformation from "../GeneralInformation/GeneralInformation";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const SupplierADT = ({params}) => {
  const {t} = useTranslation();
  const {supplier_owner, supplier} = params;
  const supplierStatus = supplier.status && supplier.status[0].toUpperCase() + supplier.status.slice(1);
  return (
    <Wrapper>
      <div className={s.header}>
        <h4 className={sBase.sectionTitle}>{supplier.name}</h4>
      </div>
      <div className={s.infoWrap}>
        <div className={classNames(s.infoBlock, s.single)}>
          <p className={s.title} data-translate-key={getTranslateKey("Supplier owner")}>
            {t(getTranslateKey("Supplier owner"))}:
          </p>
          <p className={s.value}>{supplier_owner ? `${supplier_owner.name} - ${supplier_owner.email}` : "-"}</p>
        </div>
        <div>
          <div className={classNames(s.infoBlock, s.single)}>
            <p className={s.title} data-translate-key={getTranslateKey("Supplier status")}>
              {t(getTranslateKey("Supplier status"))}:
            </p>
            <span className={classNames(s.value, s.status)} data-translate-key={getTranslateKey(supplierStatus)}>
                {t(getTranslateKey(supplierStatus))}
            </span>
          </div>
          <div className={classNames(s.infoBlock, s.single)}>
            <p className={s.title} data-translate-key={getTranslateKey("TMT spend")}>
              {t(getTranslateKey("TMT spend"))}:
            </p>
            <p className={classNames(s.value, s.green)}>
              {`${currencyData.find(c => c.label === supplier.spend_currency)?.value} 
                ${supplier.spend ? supplier.spend.toLocaleString("en-US", {maximumFractionDigits: 0}) : "0"}`}
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SupplierADT;

SupplierADT.propTypes = {
  params: PropTypes.any,
};
