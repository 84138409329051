import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import TableLoading from "components/loadingStates/Table/Table";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import AddFinancialSavingsGroupPopup from "./Popups/AddFinancialSavingsGroupPopup/AddFinancialSavingsGroupPopup";
import EditFinancialSavingsGroupPopup from "./Popups/EditFinancialSavingsGroupPopup/EditFinancialSavingsGroupPopup";
import DeleteFinancialSavingsGroupPopup
  from "./Popups/DeleteFinancialSavingsGroupPopup/DeleteFinancialSavingsGroupPopup";
import RestoreFinancialSavingsGroupPopup
  from "./Popups/RestoreFinancialSavingsGroupPopup/RestoreFinancialSavingsGroupPopup";
import services from "services";
import AdminSettingsFinancialSavingsGroupTable
  from "../../../../tables/AdminSettingsFinancialSavingsGroupTable/AdminSettingsFinancialSavingsGroupTable";

const SavingsGroups = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);

  useEffect(() => {
    loadGroups();
  }, [history.location]);

  const loadGroups = () => {
    services.savingsGroupServices.getSavingsGroupAdmin().then(res => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  }

  const addHandler = params => {
    services.savingsGroupServices.createSavingsGroup(params).then(res => {
      if (res.data) {
        setData([...data, res.data]);
        setCurrentGroup(null);
        setPopup(null);
      }
    });
  };

  const editHandler = params => {
    services.savingsGroupServices.editSavingsGroup(currentGroup.id, params).then(res => {
      if (res.data) {
        setData(data.map(type => {
          if (type.id === res.data.id) return res.data;
          return type;
        }));
        setCurrentGroup(null);
        setPopup(null);
      }
    });
  };

  const deleteHandler = ({savings_group_id}) => {
    services.savingsGroupServices.deleteSavingsGroup(currentGroup.id, savings_group_id).then(res => {
      if (res.data) {
        loadGroups();
        setCurrentGroup(null);
        setPopup(null);
      }
    });
  };

  const restoreHandler = () => {
    services.savingsGroupServices.restoreSavingsGroup(currentGroup.id).then(res => {
      if (res.data) {
        loadGroups();
        setCurrentGroup(null);
        setPopup(null);
      }
    });
  };

  const renderContent = () => {
    if (data.length) return (
      <AdminSettingsFinancialSavingsGroupTable
        data={data}
        onAdd={() => setPopup("add")}
        onEdit={group => {
          setCurrentGroup(group);
          setPopup("edit");
        }}
        onDelete={group => {
          setCurrentGroup(group);
          setPopup("delete");
        }}
        onRestore={group => {
          setCurrentGroup(group);
          setPopup("restore");
        }}
      />
    );
    return (
      <EmptyBlock
        subtitle="You don’t have any savings groups yet."
        buttonTitle="New group"
        onClick={() => setPopup("add")}
      />
    )
  }

  return (
    <>
      {loading && <TableLoading length={2}/>}
      {!loading && renderContent()}
      {popup === "add" && (
        <AddFinancialSavingsGroupPopup
          onClose={() => setPopup(null)}
          onSubmit={addHandler}
        />
      )}
      {popup === "edit" && (
        <EditFinancialSavingsGroupPopup
          onClose={() => setPopup(null)}
          onSubmit={editHandler}
          defaultData={currentGroup}
        />
      )}
      {popup === "delete" && (
        <DeleteFinancialSavingsGroupPopup
          onClose={() => setPopup(null)}
          onSubmit={deleteHandler}
          groupId={currentGroup.id}
        />
      )}
      {popup === "restore" && (
        <RestoreFinancialSavingsGroupPopup
          onClose={() => setPopup(null)}
          onSubmit={() => {
            restoreHandler();
          }}
        />
      )}
    </>
  );
};

export default SavingsGroups;
