import React from "react";
import MainTable from "../MainTable/MainTable";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {userRoles, userTypes} from "helpers/userPermissions/userRoles";
import styles from "./RequestsTable.module.scss";
import classNames from "classnames";
import {ReactComponent as NoteIcon} from "../../../assets/icons/note.svg";
import {paths} from "../../../routes/paths";
import PropTypes from "prop-types";
import Progress from "./Progress";
import Status from "./Status";
import UserCard from "../../elements/UserCard/UserCard";
import CustomNotification from "../../elements/QuestionNotification/CustomNotification";
import {ReactComponent as WarningIcon} from "assets/icons/warning-icon.svg";
import {checkRoles} from "../../../helpers/checkRoles";

const RequestsTable = (
  {
    data,
    perPage,
    totalCount,
    onPageChange,
    currentPage,
    notesHistoryAction,
  }
) => {

  const history = useHistory();
  const {roles, user_type, triage, triage_manager} = useSelector(state => state.user);
  const {admin} = userRoles;
  const {business, procurement} = userTypes;
  let types = [];

  const responsibleUser = {
    title: "Responsible procurement",
    value: ({assignee}) => <UserCard name={assignee?.name} noUserText="Unassigned"/>
  };

  const triagePerson = {
    title: "Triage person",
    value: ({triage_user}) => <UserCard name={triage_user?.name} noUserText="No triage person"/>
  };

  const progress = {
    title: "Progress",
    value: ({done_stages_count, to_do_stages_count}) => (
      <Progress
        doneCount={done_stages_count}
        toDoCount={to_do_stages_count}
      />
    )
  };

  const daysPending = {
    title: "Days pending",
    value: ({days_pending}) => {
      if (days_pending <= 0) return "-";
      return days_pending >= 10 ?
        (
          <div className={styles.daysPending}>
            {days_pending}
            <CustomNotification value="This request is waiting too long" Icon={WarningIcon} withTranslate/>
          </div>
        ) :
        days_pending;
    }
  };

  const requester = {
    title: "Requester",
    value: ({requester}) => <UserCard name={requester?.name} noUserText="No requester"/>
  };

  const request = {
    title: "Request",
    value: ({name, request_type}) => (
      <div className={styles.request}>
        {name}
        <span className={styles.requestType}>{request_type.name}</span>
      </div>
    ),
    onClick: id => history.push(`/requests/${id}`),
  };

  const requestStatus = {
    title: "Status",
    value: ({status, stage}) => <Status status={status} stage={stage}/>
  };

  const note = {
    title: "",
    position: "end",
    value: ({status, notes_history}) => {
      const isDisabled = !notes_history.length || status === "pending";
      return (
        <NoteIcon
          className={classNames(styles.note, {[styles.disabled]: isDisabled})}
          onClick={() => !isDisabled && notesHistoryAction(notes_history)}
        />
      );
    }
  };

  if (user_type === business) {
    types = [request, responsibleUser, requestStatus, progress, daysPending];
  }

  if (user_type === procurement && !checkRoles(roles, admin) && !triage_manager) {
    types = [request, requester, requestStatus, note];
  }

  if (user_type === procurement && triage_manager) {
    types = [request, requester, responsibleUser, requestStatus, daysPending, note];
  }

  if (checkRoles(roles, admin) || triage) {
    types = [request, triagePerson, requester, responsibleUser, requestStatus, daysPending, note];
  }

  return (
    <MainTable
      stickyHeader={true}
      inlineWrapClassName={styles.inlineWrap}
      totalName="Requests"
      data={data}
      dataTypes={types}
      useMaxHeight={true}
      button={
        user_type === business && {
          name: "New request",
          onClick: () => history.push(paths.createRequest)
        }
      }
      options={item => [
        (user_type === business && item.status === "pending") && {
          title: "Edit",
          onClick: () => history.push(`/requests/edit/${item.id}`),
        },
      ]}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={Number(currentPage)}
    />
  );
};

export default RequestsTable;

RequestsTable.propTypes = {
  data: PropTypes.any,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.func,
  notesHistoryAction: PropTypes.func,
};