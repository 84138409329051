import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {object, string} from "yup";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import SelectCheckDropdown from "../../elements/SelectCheckDropdown/SelectCheckDropdown";
import services from "../../../services";
import style from "./TaskForm.module.scss";
import Textarea from "../../elements/Textarea/Textarea";
import Attachments from "../AddMilestoneTaskForm/components/Attachments/Attachments";
import {useParams} from "react-router-dom";
import HyperlinksField from "../../elements/HyperlinksField/HyperlinksField";
import PropTypes from "prop-types";
import {checkRegExp} from "../../../helpers/checkRegExp";
import RSAsyncSelect from "../../elements/ReactSelectCustom/RSAsyncSelect";

const MAX_DESCRIPTION_LENGTH = 180;
const MAX_HELP_LENGTH = 500;

const TaskForm = ({onFormSubmit, onClose, defaultValues}) => {
  const params = useParams();
  const [projectTypes, setProjectTypes] = useState([]);

  useEffect(() => {
    services.projectServices.getProjectTypes().then(res => {
      setProjectTypes(
        res.data.project_types
          .filter(type => type.id !== params.id)
          .map(p => {
            return {
              value: p.name,
              label: p.name,
              id: p.id,
            };
          })
      );
    });
  }, []);

  const handleChangeAttachment = files => {
    setFieldValue("attachments", [...values.attachments, ...files]);
  };

  const handleDeleteAttachment = (file, index) => {
    if (file.lastModifiedDate) {
      setFieldValue(
        "attachments",
        values.attachments.filter((_, idx) => idx !== index)
      );
    } else {
      services.taskServices.deleteAttachment(file.id).then(() => {
        setFieldValue(
          "attachments",
          values.attachments.filter(e => e.id !== file.id)
        );
      });
    }
  };

  const formik = useFormik({
    initialValues: defaultValues || {
      name: "",
      project_types: {},
      responsible_user: null,
      description: "",
      help_text: "",
      attachments: [],
      hyperlinks: [],
    },
    validationSchema: object({
      name: string()
        .required("Name is required")
        .min(2, "Please enter task name that consists of no less than 2 characters")
        .max(32, "Please enter task name that consists of no more than 32 characters"),
      description: string().max(
        MAX_DESCRIPTION_LENGTH,
        `Please enter description that consists of no more than ${MAX_DESCRIPTION_LENGTH} characters`
      )
        .required("Please, enter a description"),
      help_text: string().max(
        MAX_HELP_LENGTH,
        `Please enter help text that consists of no more than ${MAX_HELP_LENGTH} characters`
      )
    }),
    validate: ({hyperlinks}) => {
      const errors = {};

      if (hyperlinks.some(h => !checkRegExp(h.url, "url"))) {
        errors.hyperlinks = "Each hyperlink URl should be valid";
      }

      if (hyperlinks.some(h => !h.url)) {
        errors.hyperlinks = "Each hyperlink URL is required";
      }

      if (hyperlinks.some(h => !h.name)) {
        errors.hyperlinks = "Each hyperlink name is required";
      }

      if (hyperlinks.some(h => h.name?.length > 50)) {
        errors.hyperlinks = "The name of each hyperlink must be no more than 50 characters";
      }

      if (hyperlinks.some(h => h.url?.length > 150)) {
        errors.hyperlinks = "The URL of each hyperlink must be no more than 150 characters";
      }

      return errors;
    },
    onSubmit: ({project_types = {}, name, description, attachments, help_text, hyperlinks, responsible_user}) => {
      let resultProjectTypes = null;
      const preparedHyperlinks = hyperlinks.map(h => ({name: h.name, url: h.url}));

      if (!defaultValues?.id) {
        const preparedTypes = Object.keys(project_types)
          .filter(pt => project_types[pt]);

        resultProjectTypes = projectTypes
          .filter(pt => preparedTypes.indexOf(pt.value) > -1)
          .map(pt => pt.id)
          .join(",");
      }

      let submitValues = {
        stage_id: null,
        name,
        description,
        help_text,
        attachments,
        hyperlinks: preparedHyperlinks,
        responsible_user_id: responsible_user.value
      };

      if (resultProjectTypes) {
        submitValues = {...submitValues, project_type_ids: resultProjectTypes};
      }

      onFormSubmit(submitValues);
    }
  });

  const {
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    touched,
    values,
  } = formik;

  const suppliersAutocomplete = term => services.userServices
    .stakeholdersAutocomplete({query: term})
    .then(res => res.data.map(supplier => ({
      value: supplier.id,
      label: supplier.name,
    })));

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <div className={style.field}>
        <Input
          type='text'
          name='name'
          label='Task name'
          placeholder='Enter task name'
          value={values.name}
          error={touched.name && errors.name && errors.name}
          onChange={handleChange}
          limitValue={32}
          withCounter
        />
      </div>
      {
        !defaultValues?.id && (
          <div className={style.field}>
            <SelectCheckDropdown
              label="Project type"
              options={projectTypes || []}
              value={values.project_types}
              onChange={(e) => setFieldValue("project_types", e)}
              placeholder="Project type"
              valueFormat="first"
              withEndComma={true}
              error={touched.project_types && errors.project_types && errors.project_types.id}
            />
          </div>
        )
      }
      <RSAsyncSelect
        load={suppliersAutocomplete}
        defaultValue={values.responsible_user}
        className={style.field}
        onChange={option => setFieldValue("responsible_user", option)}
        label="Responsible user"
        placeholder="Start typing user name"
      />
      <Textarea
        className={style.textarea}
        value={values.description}
        label="Description"
        onChange={e => setFieldValue("description", e.target.value)}
        placeholder="Enter description"
        error={errors.description}
        count={{
          current: values.description.length,
          max: MAX_DESCRIPTION_LENGTH
        }}
      />
      <Textarea
        className={style.textarea}
        value={values.help_text}
        label="Help"
        onChange={e => setFieldValue("help_text", e.target.value)}
        placeholder="Enter help text"
        error={errors.help_text}
        count={{
          current: values.help_text.length,
          max: MAX_HELP_LENGTH
        }}
      />
      <div className={style.field}>
        <Attachments
          onChange={handleChangeAttachment}
          onDelete={handleDeleteAttachment}
          attachments={values.attachments}
          filesMaxLength={3}
          withLink={true}
        />
      </div>
      <div className={style.field}>
        <HyperlinksField
          setFieldValue={setFieldValue}
          values={values.hyperlinks}
          errors={errors}
        />
      </div>
      <div className='popupBtnsWrap'>
        <Button
          styled='secondary'
          type='reset'
          className='popupBtn'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          styled='primary'
          type='submit'
          className='popupBtn'
        >
          {defaultValues ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default TaskForm;

TaskForm.propTypes = {
  onFormSubmit: PropTypes.func,
  onClose: PropTypes.func,
  defaultValues: PropTypes.any,
};