import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import Input from "components/elements/Input/Input";
import s from "./AutoCompleteInput.module.scss";
import classNames from "classnames";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";

const AutoCompleteInput = (
  {
    data,
    placeholder,
    label,
    className,
    name,
    onChange,
    value,
    error,
    onSelect,
    onBlur,
    onFocus,
    emptyText,
    handleEmptyTextClick,
    withArrow,
    emptyStateDisplay,
    searchByValue,
    withoutTranslate,
    isMulti,
    labelGrey,
    disabled,
    optional,
    ...props
  }
) => {
  const [display, setDisplay] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState({name: "", id: ""});
  const wrapperRef = useRef(null);

  const getMultiSearchValue = data => data.split(",").pop();

  useEffect(() => {
    setOptions(data);
    setSearch({name: isMulti ? getMultiSearchValue(value) : value, id: ""});
    (data.length && value.length > 0 && !firstLoad) ||
    (!data.length && value.length > 0 && emptyText && !firstLoad)
      ? setDisplay(true)
      : setDisplay(false);
  }, [data]);

  useEffect(() => {
    setSearch({name: value, id: ""});
    if (value.length > 0) {
      const foundElements = data.filter(option =>
        searchByValue
          ? option.value?.toLowerCase().includes(value?.toLowerCase())
          : option.name?.toLowerCase().includes(value?.toLowerCase())
      );
      setOptions(foundElements);
    } else {
      setOptions(data);
    }
  }, [value]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    const {current: wrap} = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const updateNameDex = value => {
    setSearch(value);
    onSelect(value);
    setDisplay(false);
    setFirstLoad(true);
  };

  return (
    <div ref={wrapperRef} className={classNames(s.wrapAutoInput, className)}>
      <Input
        disabled={disabled}
        withoutTranslate={withoutTranslate}
        value={value}
        onChange={onChange}
        inputProps={{
          onFocus: () => {
            (options.length || emptyStateDisplay) && setDisplay(true);
            setFirstLoad(false);
            onFocus && onFocus();
          }
        }}
        id='auto'
        onBlur={onBlur}
        placeholder={placeholder}
        label={label}
        className={s.autoInput}
        name={name}
        error={error}
        labelGrey={labelGrey}
        optional={optional}
        {...props}
      />

      {withArrow && (
        <Arrow
          onClick={() =>
            (value.length > 0 || data.length > 0 || emptyStateDisplay) &&
            setDisplay(!display)
          }
          className={classNames(s.arrow, {
            [s.opened]: display,
            [s.noClickable]: !value.length && !data.length && !emptyStateDisplay
          })}
        />
      )}

      {display && (
        <div className={classNames(s.autoContainer, {[s.withError]: error})}>
          {options &&
            options
              .filter(({name, value}) =>
                searchByValue
                  ? value?.toLowerCase().indexOf(search.name?.toLowerCase()) > -1
                  : name?.toLowerCase().indexOf(search.name?.toLowerCase()) > -1
              )
              .map((value, i) => {
                return (
                  <div
                    onClick={() => updateNameDex(value)}
                    className={s.option}
                    key={i}
                    tabIndex='0'
                  >
                    <span>{value.name}</span>
                  </div>
                );
              })}
          {emptyText && !options.length > 0 && (
            <div>
              <p className={s.emptyMessage}>
                {emptyText.text}
                <span onClick={handleEmptyTextClick}>{emptyText.linkText}</span>
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AutoCompleteInput.propTypes = {
  data: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  withArrow: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  emptyText: PropTypes.string,
  handleEmptyTextClick: PropTypes.func,
  emptyStateDisplay: PropTypes.any,
  searchByValue: PropTypes.any,
  labelGrey: PropTypes.string,
  withoutTranslate: PropTypes.bool,
  isMulti: PropTypes.bool,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
};

AutoCompleteInput.defaultProps = {
  data: [
    {name: "Robert Loblaw"},
    {name: "Harry Picket"},
    {name: "Sanjay Gupta"},
    {name: "Jeff Browlee"},
    {name: "Alice Gumoe"}
  ],
  placeholder: "Robert Loblaw",
  label: "Project owner",
  error: "",
  withArrow: false,
  disabled: false,
};

export default AutoCompleteInput;
