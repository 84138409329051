import React, {useEffect, useState} from "react";
import s from "./IndividualLeaderboard.module.scss";
import sBase from "../MainDashboard.module.scss";
import {getTitle} from "../../helpers/dataFormat";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import services from "../../../../../../services";
import classNames from "classnames";
import gradient from "random-gradient";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import {abbreviateNumber} from "../../../../../../helpers/abbreviateNumber";

const IndividualLeaderboard = () => {
  const [data, setData] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    services.dashboardServices.getIndividualLeaderboard().then((res) => {
      setData(res.data || []);
    });
  }, []);

  return (
    <section className={classNames(sBase.wrapper, s.individualLeaderboardWrapper)}>
      <h4
        className={classNames(sBase.title, s.title)}
        data-translate-key={getTranslateKey(getTitle("individualLeaderboard"))}
      >
        {t(getTranslateKey(getTitle("individualLeaderboard")))}
      </h4>
      <TableContainer className={s.content}>
        <Table stickyHeader className={sBase.table}>
          <TableHead className={sBase.tableHead} data-attr='table-head'>
            <TableRow>
              <TableCell className={sBase.tableCell}>
                <span data-translate-key={getTranslateKey("User")}>
                  {t(getTranslateKey("User"))}
                </span>
              </TableCell>
              <TableCell className={sBase.tableCell}>
                <span data-translate-key={getTranslateKey("Savings")}>
                  {t(getTranslateKey("Savings"))}
                </span>
              </TableCell>
              <TableCell className={sBase.tableCell}>
                <span data-translate-key={getTranslateKey("NPS Score, %")}>
                  {t(getTranslateKey("NPS Score, %"))}
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={sBase.tableBody}>
            {
              (data || []).sort((a, b) => b.nps_score - a.nps_score).map(i => {
                const avatarGradient = {background: gradient(i.user.name)}
                return (
                  <TableRow className={sBase.tableRow} key={i.user.id}>
                    <TableCell className={sBase.tableCell}>
                      <a href={`/users/${i.user.id}`} className={s.owner}>
                        <div className={s.avatarWrapper} style={avatarGradient}>
                          {i.user.name.substr(0, 1)}
                        </div>
                        <p>{i.user.name}</p>
                      </a>
                    </TableCell>
                    <TableCell className={sBase.tableCell}>
                      <span className={sBase.currency}>$ </span>
                      {abbreviateNumber(i.savings)}
                    </TableCell>
                    <TableCell className={sBase.tableCell}>
                        <span className={sBase.nps}>
                          {i.nps_score}
                        </span>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

export default IndividualLeaderboard;
