import React, {useEffect, useState} from "react";
import s from "./AdminSettingsTriageContainer.module.scss";
import {useLocation} from "react-router-dom";
import DetailsPanel from "./DetailsPanel/DetailsPanel";
import TriageTable from "../../tables/TriageTable/TriageTable";
import AdminSettingsTriageFilter from "../../filters/AdminSettingsTriageFilter/AdminSettingsTriageFilter";
import querystring from "query-string";
import services from "../../../services";
import {entityFormat} from "../../../helpers/triages/entityFormat";
import DetailLoading from "../../loadingStates/Detail/Detail";

const AdminSettingsTriageContainer = () => {
  const location = useLocation();
  const {hierarchy, entity, prev_entity, name} = querystring.parse(location.search);
  const [triages, setTriages] = useState(null);
  const [currentEntityData, setCurrentEntityData] = useState(null);

  const loadTriages = () => {
    const prepareParams = {};
    prepareParams.entity = entity;
    const prepareHierarchy = hierarchy.split(",");

    prepareHierarchy.forEach(item => {
      const partsOfItem = item.split("_");
      if (partsOfItem[1]) {
        prepareParams[`${entityFormat(partsOfItem[0].toLowerCase())}_id`] = partsOfItem[1]
      }
    });

    services.triageServices.getTriages(prepareParams).then(res => {
      if (res.data) setTriages(res.data);
    });

    const prevEntityParams = prev_entity.split("_");

    const prepareTriageParams = {};
    prepareTriageParams.entity = prevEntityParams[0];

    prepareHierarchy.forEach(item => {
      const partsOfItem = item.split("_");
      if (partsOfItem[1]) {
        if (prevEntityParams[0] === partsOfItem[0]) prepareTriageParams.id = partsOfItem[1];
        else prepareTriageParams[`${entityFormat(partsOfItem[0])}_id`] = partsOfItem[1];
      }
    });

    services.triageServices.getTriages(prepareTriageParams).then(res => {
      if (res.data) setCurrentEntityData(res.data);
    })
  }

  useEffect(() => {
    loadTriages();
  }, [hierarchy]);

  const updateTriage = params => {
    const prepareValues = {
      [`${entityFormat(params.entityName.toLowerCase())}_id`]: params.entityId,
      triage_user_id: params.triageUserId,
    }

    hierarchy.split(",").forEach(item => {
      const partsOfItem = item.split("_");
      if (partsOfItem[1]) {
        prepareValues[`${entityFormat(partsOfItem[0].toLowerCase())}_id`] = partsOfItem[1];
      }
    });

    services.triageServices.updateTriage(prepareValues).then(res => {
      if (res.data) loadTriages();
    });
  }

  const updateCurrentEntity = params => {
    const prepareValues = {
      triage_user_id: params.triageUserId,
    }

    hierarchy.split(",").forEach(item => {
      const partsOfItem = item.split("_");
      if (partsOfItem[1]) {
        prepareValues[`${entityFormat(partsOfItem[0].toLowerCase())}_id`] = partsOfItem[1];
      }
    });

    services.triageServices.updateTriage(prepareValues).then(res => {
      if (res.data) {
        setCurrentEntityData({
          ...currentEntityData,
          triage_user: res.data.triage?.triage_user
        });
      }
    });
  }

  const deleteTriageUser = triageId => {
    services.triageServices.deleteTriage(triageId).then(res => {
      if (res.status === 200) loadTriages()
    });
  }

  const renderTriagesTable = () => {
    if (!triages) return;

    const entityName = Object.keys(triages)[0];

    return (
      <TriageTable
        data={triages[entityName]}
        updateEntityItem={params => updateTriage({...params, entityName})}
        deleteTriageUser={deleteTriageUser}
        entity={entityName}
        hierarchy={hierarchy}
      />
    );
  }

  const renderDetailsPanel = () => {
    const prevEntityParams = prev_entity.split("_");

    if (currentEntityData) return (
      <DetailsPanel
        data={currentEntityData}
        updateEntityItem={updateCurrentEntity}
        entity={prevEntityParams[0]}
      />
    );
    return <DetailLoading className={s.preloader} length={1}/>;
  }

  return (
    <div className={s.wrap}>
      <h2 className={s.title}>{name}</h2>
      {renderDetailsPanel()}
      <AdminSettingsTriageFilter className={s.filter} currentEntity={entity}/>
      {renderTriagesTable()}
    </div>
  );
};

export default AdminSettingsTriageContainer;
