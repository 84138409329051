import React from "react";
import querystring from "query-string";
import { useHistory } from "react-router-dom";
import Tabs from "components/elements/Tabs/Tabs";
import Tab from "components/elements/Tabs/Tab/Tab";
import ResourceUtilizationReportIndividualList from "../ResourceUtilizationReportIndividualList/ResourceUtilizationReportIndividualList";
import ResourceUtilizationReportDepartmentlList from "../ResourceUtilizationReportDepartmentlList/ResourceUtilizationReportDepartmentlList";
import ResourceUtilizationReportGanttChart from "../ResourceUtilizationReportGanttChart/ResourceUtilizationReportGanttChart";

const LABELS = ["Individual list", "Department list", "Gantt chart"];

const ResourceUtilizationReportTabs = () => {
  const history = useHistory();
  const { tab, ...query } = querystring.parse(history.location.search);

  const onTabChange = (tab) => {
    let newQuery = {};
    if (tab === LABELS[0]) newQuery = { tab, ...query };
    if (tab === LABELS[1]) newQuery = { tab };
    if (tab === LABELS[2]) newQuery = { tab, user_ids: query?.user_ids };

    history.push({
      search: querystring.stringify(newQuery),
    });
  };
  return (
    <Tabs defaultActiveTab={tab} handleClick={onTabChange}>
      <Tab label={LABELS[0]}>
        <ResourceUtilizationReportIndividualList />
      </Tab>
      <Tab label={LABELS[1]}>
        <ResourceUtilizationReportDepartmentlList />
      </Tab>
      <Tab label={LABELS[2]}>
        <ResourceUtilizationReportGanttChart />
      </Tab>
    </Tabs>
  );
};

export default ResourceUtilizationReportTabs;
