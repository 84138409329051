import {paths} from "./paths";
import {userRoles, userTypes} from "helpers/userPermissions/userRoles";
import LandingPage from "components/landing/pages/LandingPage/LandingPage";
import ContactUsPage from "components/landing/pages/ContactUsPage/ContactUsPage";
import AboutUsPage from "components/landing/pages/AboutUsPage/AboutUsPage";
import ProductPage from "components/landing/pages/ProductPage/ProductPage";
import FeaturesPage from "components/landing/pages/FeaturesPage/FeaturesPage";
import LoginPage from "components/pages/LoginPage/LoginPage";
import ProfilePage from "components/pages/ProfilePage/ProfilePage";
import CategoriesPage from "components/pages/CategoriesPage/CategoriesPage";
import UsersPage from "components/pages/UsersPage/UsersPage";
import ProjectsPage from "components/pages/ProjectsPage/ProjectsPage";
import ProjectPage from "components/pages/ProjectPage/ProjectPage";
import ContractsPage from "components/pages/ContractsPage/ContractsPage";
import SuppliersPage from "components/pages/SuppliersPage/SuppliersPage";
import SupplierDetailsPage from "components/pages/SupplierDetailsPage/SupplierDetailsPage";
import BlogPage from "components/landing/pages/BlogPage/BlogPage";
import ArticlePage from "components/landing/pages/ArticlePage/ArticlePage";
import CookiePage from "components/landing/pages/CookiePage/CookiePage";
import PrivacyPage from "components/landing/pages/PrivacyPage/PrivacyPage";
import PricingPage from "components/landing/pages/PricingPage/PricingPage";
import MilestonePage from "components/pages/MilestonePage/MilestonePage";
import FinancialBenefitsPage from "components/pages/FinancialBenefitsPage/FinancialBenefitsPage";
import PasswordChangePage from "components/pages/PasswordChangePage/PasswordChangePage";
import PasswordResetRequestPage from "components/pages/PasswordResetRequestPage/PasswordResetRequestPage";
import PasswordResetPage from "components/pages/PasswordResetPage/PasswordResetPage";
import DashboardPage from "components/pages/DashboardPage/DashboardPage";
import SurveyResultsPage from "components/pages/SurveyResultsPage/SurveyResultsPage";
import SurveyPage from "components/pages/SurveyPage/SurveyPage";
import UserDetailsPage from "components/pages/UserDetailsPage/UserDetailsPage";
import ReportingPage from "components/pages/ReportingPage/ReportingPage";
import NotificationsPage from "components/pages/NotificationsPage/NotificationsPage";
import ProcurementActivityPage from "components/pages/ProcurementActivityPage/ProcurementActivityPage";
import PreviewProjectPage from "components/pages/PreviewProjectPage/PreviewProjectPage";
import RequestsPage from "components/pages/BusinessProjectRequestsPage/RequestsPage";
import RequestDetailPage from "components/pages/RequestDetailPage/RequestDetailPage";
import ActivityPage from "components/pages/ActivityPage/ActivityPage";
import AdminScenarioPage from "components/pages/AdminScenarioPage/AdminScenarioPage";
import TagReportPage from "components/pages/TagReportPage/TagReportPage";
import SystemReportsPage from "components/pages/SystemReportsPage/SystemReportsPage";
import ResourceUtilizationReportPage
  from "components/pages/ResourceUtilizationReportPage/ResourceUtilizationReportPage";
import ProjectReportPage from "components/pages/ProjectReportPage/ProjectReportPage";
import UserReportPage from "components/pages/UserReportPage/UserReportPage";
import DepartmentReportPage from "components/pages/DepartmentReportPage/DepartmentReportPage";
import CategoryDetailPage from "components/pages/CategoryDetailPage/CategoryDetailPage";
import AdminSettingsProjectStagePage
  from "components/pages/AdminSettingsProjectStagePage/AdminSettingsProjectStagePage";
import CompanyGoalsPage from "components/pages/CompanyGoalsPage/CompanyGoalsPage";
import CompanyGoalPage from "components/pages/CompanyGoalPage/CompanyGoalPage";
import CreateRequestPage from "components/pages/CreateRequestPage/CreateRequestPage";
import AdminAdditionalQuestionPage from "../components/pages/AdminAdditionalQuestionPage/AdminAdditionalQuestionPage";
import ContractNewPage from "../components/pages/ContractNewPage/ContractNewPage";
import ContractEditPage from "../components/pages/ContractEditPage/ContractEditPage";
import ContractDetailsPage from "../components/pages/ContractDetailsPage/ContractDetailsPage";
import AdminSettingsGeneralPage from "../components/pages/AdminSettingsGeneralPage/AdminSettingsGeneralPage";
import AdminSettingsUsersPage from "../components/pages/AdminSettingsUsersPage/AdminSettingsUsersPage";
import AdminSettingsApprovalsPage from "../components/pages/AdminSettingsApprovalsPage/AdminSettingsApprovalsPage";
import AdminSettingsProjectsPage from "../components/pages/AdminSettingsProjectsPage/AdminSettingsProjectsPage";
import AdminSettingsAdditionalQuestionsPage
  from "../components/pages/AdminSettingsAdditionalQuestionsPage/AdminSettingsAdditionalQuestionsPage";
import AdminSettingsFlagsPage from "../components/pages/AdminSettingsFlagsPage/AdminSettingsFlagsPage";
import FlagReportPage from "../components/pages/FlagReportPage/FlagReportPage";
import ProjectTypeDetailsPage from "../components/pages/ProjectTypeDetailsPage/ProjectTypeDetailsPage";
import AdminSettingsSavingsContainer
  from "../components/containers/AdminSettingsSavingsContainer/AdminSettingsSavingsContainer";
import AdminSettingsRequestsPage from "../components/pages/AdminSettingsRequestsPage/AdminSettingsRequestsPage";
import AdminSettingsCustomDropDownsCreatePage
  from "../components/pages/AdminSettingsCustomDropDownsCreatePage/AdminSettingsCustomDropDownsCreatePage";
import AdminSettingsCustomDropDownsEditPage
  from "../components/pages/AdminSettingsCustomDropDownsEditPage/AdminSettingsCustomDropDownsEditPage";
import AdminSettingsRequestDetailsPage
  from "../components/pages/AdminSettingsRequestDetailsPage/AdminSettingsRequestDetailsPage";
import AdminSettingsStepFieldDetailsPage
  from "../components/pages/AdminSettingsStepFieldDetailsPage/AdminSettingsStepFieldDetailsPage";
import CreateProjectPage from "../components/pages/CreateProjectPage/CreateProjectPage";
import EditRequestPage from "../components/pages/EditRequestPage/EditRequestPage";
import EditProjectPage from "../components/pages/EditProjectPage/EditProjectPage";
import EditAdvancedProjectPage from "../components/pages/EditAdvancedProjectPage/EditAdvancedProjectPage";
import ProjectActualSavingsPage from "../components/pages/ProjectActualSavingsPage/ProjectActualSavingsPage";
import ProjectActualSavingsMonthNotesPage
  from "../components/pages/ProjectActualSavingsMonthNotesPage/ProjectActualSavingsMonthNotesPage";
import AdminSettingsAddApprovalPage
  from "../components/pages/AdminSettingsAddApprovalPage/AdminSettingsAddApprovalPage";
import ApprovalDetailsPage from "../components/pages/ApprovalDetailsPage/ApprovalDetailsPage";
import ApprovalClarificationHistoryPage
  from "../components/pages/ApprovalClarificationHistoryPage/ApprovalClarificationHistoryPage";
import AdminApprovalDetailsPage from "../components/pages/AdminApprovalDetailsPage/AdminApprovalDetailsPage";
import AdminSettingsContractsPage from "../components/pages/AdminSettingsContracts/AdminSettingsContractsPage";
import TaskDetailsPage from "../components/pages/TaskDetailsPage/TaskDetailsPage";
import AddApprovalPage from "../components/pages/AddApprovalPage/AddApprovalPage";
import EditApprovalPage from "../components/pages/EditApprovalPage/EditApprovalPage";
import AdminSettingsTriagePage from "../components/pages/AdminSettingsTriagePage/AdminSettingsTriagePage";
import DashboardTasksPage from "../components/pages/DashboardTasksPage/DashboardTasksPage";
import TeamReportPage from "../components/pages/TeamReportPage/TeamReportPage";
import FinancialBenefitsAddPage
  from "../components/pages/FinancialBenefitsAddPage/FinancialBenefitsAddPage";
import FinancialBenefitsDetailsPage
  from "../components/pages/FinancialBenefitsDetailsPage/FinancialBenefitsDetailsPage";
import FinancialBenefitsEditPage from "../components/pages/FinancialBenefitsEditPage/FinancialBenefitsEditPage";
import AdminSettingsEmailsPage from "../components/pages/AdminSettingsEmailsPage/AdminSettingsEmailsPage";
import AdminSettingsEmailEditorPage
  from "../components/pages/AdminSettingsEmailEditorPage/AdminSettingsEmailEditorPage";
import AdminSettingsEmailPage from "../components/pages/AdminSettingsEmailPage/AdminSettingsEmailPage";
import SurveysPage from "components/pages/SurveysPage/SurveysPage";
import SurveyTemplatePage from "components/pages/SurveyTemplatePage/SurveyTemplatePage.jsx";
import AdminSettingsTaskDetailsPage
  from "../components/pages/AdminSettingsTaskDetailsPage/AdminSettingsTaskDetailsPage";
import TaskStakeholdersPage from "../components/pages/TaskStakeholdersPage/TaskStakeholdersPage";

const {
  guest,
  admin,
  business_user,
  category_business_owner,
  triage_user,
  procurement_admin,
  procurement_category_owner,
  procurement_manager,
  cpo,
  director,
} = userRoles;

const { procurement, adminTypes } = userTypes;
const allTypes = Object.values(userRoles).join(", ");
const authorized = Object.values(userRoles)
  .filter((e) => e !== guest)
  .join(", ");

export const routes = [
  {
    path: paths.home,
    Component: LandingPage,
    allowed: allTypes,
    exact: true,
  },
  {
    path: paths.login,
    Component: LoginPage,
    allowed: guest,
  },
  {
    path: paths.passwordChange,
    Component: PasswordChangePage,
    allowed: authorized,
  },
  {
    path: paths.passwordResetRequest,
    Component: PasswordResetRequestPage,
    allowed: guest,
    exact: true,
  },
  {
    path: paths.passwordReset,
    Component: PasswordResetPage,
    allowed: guest,
  },
  {
    path: paths.profile,
    Component: ProfilePage,
    allowed: authorized,
  },
  {
    path: paths.users,
    Component: UsersPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.projects,
    Component: ProjectsPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.projectDetail,
    Component: ProjectPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.categories,
    Component: CategoriesPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.contracts,
    Component: ContractsPage,
    allowed: authorized,
  },
  {
    path: paths.contractsNew,
    Component: ContractNewPage,
    allowed: authorized,
  },
  {
    path: paths.contractsEdit,
    Component: ContractEditPage,
    allowed: authorized,
  },
  {
    path: paths.contractsDetails,
    Component: ContractDetailsPage,
    allowed: authorized,
  },
  {
    path: paths.suppliers,
    Component: SuppliersPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.supplierDetail,
    Component: SupplierDetailsPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.contact,
    Component: ContactUsPage,
    allowed: allTypes,
  },
  {
    path: paths.about,
    Component: AboutUsPage,
    allowed: allTypes,
  },
  {
    path: paths.product,
    Component: ProductPage,
    allowed: allTypes,
  },
  {
    path: paths.features,
    Component: FeaturesPage,
    allowed: allTypes,
  },
  {
    path: paths.blog,
    Component: BlogPage,
    allowed: allTypes,
  },
  {
    path: paths.article,
    Component: ArticlePage,
    allowed: allTypes,
  },
  {
    path: paths.cookie,
    Component: CookiePage,
    allowed: allTypes,
  },
  {
    path: paths.privacy,
    Component: PrivacyPage,
    allowed: allTypes,
  },
  {
    path: paths.pricing,
    Component: PricingPage,
    allowed: allTypes,
  },
  {
    path: paths.milestoneDetail,
    Component: MilestonePage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.financialBenefits,
    Component: FinancialBenefitsPage,
    allowed: authorized,
  },
  {
    path: paths.dashboard,
    Component: DashboardPage,
    allowed: authorized,
  },
  {
    path: paths.surveyResults,
    Component: SurveyResultsPage,
    allowed: authorized,
  },
  {
    path: paths.survey,
    Component: SurveyPage,
    allowed: authorized,
  },
  {
    path: paths.projectsSurveys,
    Component: SurveysPage,
    allowed: authorized,
  },
  {
    path: paths.suppliersSurveys,
    Component: SurveysPage,
    allowed: authorized,
  },
  {
    path: paths.projectsSurveysTemplate,
    Component: SurveyTemplatePage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.suppliersSurveysTemplate,
    Component: SurveyTemplatePage,
    allowed: authorized,
  },
  {
    path: paths.userDetails,
    Component: UserDetailsPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.financialReports,
    Component: ReportingPage,
    allowed: authorized,
  },
  {
    path: paths.notifications,
    Component: NotificationsPage,
    allowed: authorized,
  },
  {
    path: paths.procurementActivity,
    Component: ProcurementActivityPage,
    allowed: `${business_user}, ${admin}, ${category_business_owner}`,
  },
  {
    path: paths.previewProject,
    Component: PreviewProjectPage,
    allowed: `${business_user}, ${admin}, ${category_business_owner}`,
  },
  {
    path: paths.activity,
    Component: ActivityPage,
    allowed: `${business_user}, ${category_business_owner}, ${admin}, ${triage_user}`,
  },
  {
    path: paths.adminScenario,
    Component: AdminScenarioPage,
    allowed: admin,
  },
  {
    path: paths.systemReports,
    Component: SystemReportsPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.tagSystemReports,
    Component: TagReportPage,
    allowed: authorized,
  },
  {
    path: paths.resourceUtilizationReport,
    Component: ResourceUtilizationReportPage,
    allowed: authorized,
  },
  {
    path: paths.userReport,
    Component: UserReportPage,
    allowed: authorized,
  },
  {
    path: paths.departmentReport,
    Component: DepartmentReportPage,
    allowed: authorized,
  },
  {
    path: paths.teamReport,
    Component: TeamReportPage,
    allowed: authorized,
  },
  {
    path: paths.projectSystemReports,
    Component: ProjectReportPage,
    allowed: authorized,
  },
  {
    path: paths.categoryDetail,
    Component: CategoryDetailPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.adminSettingsProjectStage,
    Component: AdminSettingsProjectStagePage,
    allowed: authorized,
  },
  {
    path: paths.adminAdditionalQuestion,
    Component: AdminAdditionalQuestionPage,
    allowed: authorized,
  },
  {
    path: paths.companyGoals,
    Component: CompanyGoalsPage,
    exact: true,
    allowedUserTypes: `${procurement}, ${adminTypes}`,
  },
  {
    path: paths.companyGoal,
    Component: CompanyGoalPage,
    allowedUserTypes: `${procurement}, ${adminTypes}`,
  },
  {
    path: paths.adminSettingsGeneral,
    Component: AdminSettingsGeneralPage,
    allowed: admin,
  },
  {
    path: paths.adminSettingsUsers,
    Component: AdminSettingsUsersPage,
    allowed: admin,
  },
  {
    path: paths.adminSettingsApprovals,
    Component: AdminSettingsApprovalsPage,
    allowed: admin,
  },
  {
    path: paths.adminSettingsProjects,
    Component: AdminSettingsProjectsPage,
    allowed: admin,
  },
  {
    path: paths.adminSettingsAdditionalQuestions,
    Component: AdminSettingsAdditionalQuestionsPage,
    allowed: admin,
  },
  {
    path: paths.adminSettingsFlags,
    Component: AdminSettingsFlagsPage,
    allowed: admin,
  },
  {
    path: paths.adminSettingsEmails,
    Component: AdminSettingsEmailsPage,
    allowed: admin,
    exact: true,
  },
  {
    path: paths.adminSettingsEmailEditor,
    Component: AdminSettingsEmailEditorPage,
    allowed: admin,
    exact: true,
  },
  {
    path: paths.adminSettingsEmail,
    Component: AdminSettingsEmailPage,
    allowed: admin,
    exact: true,
  },
  {
    path: paths.flagReport,
    Component: FlagReportPage,
    allowed: authorized,
  },
  {
    path: paths.adminSettingsProjectTypeDetails,
    Component: ProjectTypeDetailsPage,
    allowed: admin,
    exact: true,
  },
  {
    path: paths.adminSettingsConfigureTaskDetails,
    Component: AdminSettingsTaskDetailsPage,
    allowed: admin,
    exact: true,
  },
  {
    path: paths.adminSettingsRequest,
    Component: AdminSettingsRequestsPage,
    allowed: `${admin}, ${procurement_admin}, ${procurement_category_owner}, ${procurement_manager}`,
  },
  {
    path: paths.adminSettingsCustomDropDownsCreate,
    Component: AdminSettingsCustomDropDownsCreatePage,
    allowed: admin,
  },
  {
    path: paths.adminSettingsCustomDropDownsEdit,
    Component: AdminSettingsCustomDropDownsEditPage,
    allowed: admin,
  },
  {
    path: paths.adminSettingsSavings,
    Component: AdminSettingsSavingsContainer,
    allowed: admin,
  },
  {
    path: paths.requests,
    Component: RequestsPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.createRequest,
    Component: CreateRequestPage,
    allowed: `${business_user}, 
    ${category_business_owner}, 
    ${admin},
    ${procurement_manager}, 
    ${procurement_category_owner}, 
    ${procurement_admin},
    ${director},
    ${cpo}`,
  },
  {
    path: paths.editRequest,
    Component: EditRequestPage,
    allowed: `${business_user}, ${category_business_owner}`,
  },
  {
    path: paths.requestDetails,
    Component: RequestDetailPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.adminSettingsRequestDetails,
    Component: AdminSettingsRequestDetailsPage,
    allowed: admin,
  },
  {
    path: paths.adminSettingsStepFieldDetail,
    Component: AdminSettingsStepFieldDetailsPage,
    allowed: admin,
  },
  {
    path: paths.createProject,
    Component: CreateProjectPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.editProject,
    Component: EditProjectPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.advancedProjectEdit,
    Component: EditAdvancedProjectPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.projectActualSavingsMonth,
    Component: ProjectActualSavingsPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.projectActualSavingsMonthNotes,
    Component: ProjectActualSavingsMonthNotesPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.adminAddApprovalStep,
    Component: AdminSettingsAddApprovalPage,
    allowed: admin,
    exact: true,
  },
  {
    path: paths.approvalDetails,
    Component: ApprovalDetailsPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.clarificationHistory,
    Component: ApprovalClarificationHistoryPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.adminApprovalDetails,
    Component: AdminApprovalDetailsPage,
    allowed: admin,
    exact: true,
  },
  {
    path: paths.adminSettingsContracts,
    Component: AdminSettingsContractsPage,
    allowed: admin,
    exact: true,
  },
  {
    path: paths.taskDetails,
    Component: TaskDetailsPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.taskStakeholders,
    Component: TaskStakeholdersPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.addApprovalStep,
    Component: AddApprovalPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.addFinancialBenefit,
    Component: FinancialBenefitsAddPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.editFinancialBenefit,
    Component: FinancialBenefitsEditPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.financialBenefitDetails,
    Component: FinancialBenefitsDetailsPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.editApprovalStep,
    Component: EditApprovalPage,
    allowed: authorized,
    exact: true,
  },
  {
    path: paths.adminSettingsTriage,
    Component: AdminSettingsTriagePage,
    allowed: `${admin}, ${procurement_admin}, ${procurement_category_owner}, ${procurement_manager}`,
    exact: true,
  },
  {
    path: paths.dashboardTasks,
    Component: DashboardTasksPage,
    allowed: authorized,
    exact: true,
  },
];
