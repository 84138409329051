import {
  ADD_NEW_QUESTION,
  CHANGE_PAGE_STATUS,
  DELETE_QUESTION,
  LOAD_DATA,
  UPDATE_QUESTION,
  UPDATE_QUESTIONS
} from "./reducer";
import services from "../../../../services";
import {generateUniqueId} from "../../../../helpers/uniqueId";
import {questionType, statuses} from "./types";

export const loadData = (dispatch, id) => {
  services.additionalQuestions.getQuestions(id)
    .then(res => dispatch({
      type: LOAD_DATA,
      payload: res.data.sort((a, b) => a.question_position - b.question_position),
    }));
};

export const addQuestionLocal = (dispatch, position) => {
  dispatch({
    type: ADD_NEW_QUESTION,
    payload: {
      question_id: `random-${generateUniqueId()}`,
      question_name: "Question text",
      is_required: false,
      question_type: "text_field",
      question_position: position,
      answers: [],
    },
  });
};

export const changePageStatus = (dispatch, status) => {
  dispatch({
    type: CHANGE_PAGE_STATUS,
    payload: status,
  });
};

export const deleteQuestion = (dispatch, id) => {
  dispatch({
    type: DELETE_QUESTION,
    payload: id,
  });
};

export const updateLocalQuestion = (dispatch, question) => {
  dispatch({
    type: UPDATE_QUESTION,
    payload: question,
  });
}

export const updateLocalQuestions = (dispatch, questions) => {
  dispatch({
    type: UPDATE_QUESTIONS,
    payload: questions,
  });
}

export const addNewQuestions = (dispatch, id, questionsList, cloneList) => {

  const preparedRequest = {
    delete_question_ids: cloneList
      .filter(cl => !(questionsList.map(q => q.question_id).indexOf(cl.question_id) > -1))
      .map(cl => cl.question_id)
      .join(','),
    questions: [],
  }

  preparedRequest.questions = questionsList.map(question => {
    if (question.question_id.indexOf('random') > -1) {
      return {...question, question_id: null}
    }
    return question;
  });

  preparedRequest.questions = preparedRequest.questions.map(question => {
    let preparedQuestion = question;
    preparedQuestion.delete_answer_ids = '';

    if (question.question_type === questionType.radio) {

      preparedQuestion = {
        ...question,
        answers: question.answers.map(answer => {
          if (answer.answer_id.indexOf('random') > -1) {
            return {...answer, answer_id: null}
          }
          return answer;
        })
      }
      const cloneAnswers = cloneList.find(cl => cl.question_id === preparedQuestion.question_id)?.answers;
      if (cloneAnswers?.length) {
        preparedQuestion = {
          ...preparedQuestion,
          delete_answer_ids: cloneAnswers
            .map(a => a.answer_id)
            .filter(a => !(preparedQuestion.answers.map(qa => qa.answer_id).indexOf(a) > -1))
            .join(','),
        }
      }

    }

    return preparedQuestion;
  });

  services.additionalQuestions.createQuestion(id, preparedRequest).then(() => {
    changePageStatus(dispatch, statuses.static);
    loadData(dispatch, id);
  });
}

