import React, {useState} from "react";
import {userRoles} from "../../../../../helpers/usersData";
import {useFormik} from "formik";
import Input from "../../../../elements/Input/Input";
import classNames from "classnames";
import s from "../../../../forms/ChangeUserForm/ChangeUserForm.module.scss";
import PasswordInput from "../../../../elements/PasswordInput/PasswordInput";
import CustomDropdown from "../../../../elements/CustomDropdown/CustomDropdown";
import Button from "../../../../elements/Button/Button";
import AutoCompleteInput from "../../../../elements/AutoCompleteInput/AutoCompleteInput";
import services from "../../../../../services";
import {object, string} from "yup";
import {formatUserRoles} from "../../../../../helpers/userPermissions/formatUserRoles";

const SupplierUsersForm = ({onSubmit, onClose, defaultValues}) => {
  const [suppliers, setSuppliers] = useState([]);

  const handleSearchSupplier = query => {
    services.supplierServices
      .suppliersAutocomplete({query})
      .then(res => setSuppliers(res.data));
  };

  const formik = useFormik({
    initialValues: {
      first_name: defaultValues?.first_name || "",
      last_name: defaultValues?.last_name || "",
      title: defaultValues?.title || "",
      email: defaultValues?.email || "",
      password: defaultValues?.password || "",
      role: defaultValues?.role || true,
      supplier_id: {name: defaultValues?.supplier.name, id: defaultValues?.supplier.id},
    },
    validationSchema: object({
      first_name: string()
        .required("Name department is required")
        .min(2, "Please enter a first name with at least 2 characters")
        .max(50, "Please enter a first name with up to 50 characters"),
      last_name: string()
        .required("Name department is required")
        .min(2, "Please enter a last name with at least 2 characters")
        .max(50, "Please enter a last name with up to 50 characters"),
      title: string()
        .required("Name title is required")
        .min(2, "Please enter a title name with at least 2 characters")
        .max(32, "Please enter a title name with up to 32 characters"),
      email: string()
        .required("Email is required")
        .min(2, "Please enter a email with at least 2 characters")
        .max(100, "Please enter a email with up to 32 characters"),
      password: string()
        .required("Password is required"),
      role: string()
        .required("Name role is required"),
      supplier_id: string()
        .required("Name Supplier is required")
    }),

    onSubmit: (values) => {
      onSubmit({
        first_name: values.first_name,
        last_name: values.last_name,
        title: values.title,
        email: values.email,
        role: values.role.value,
        password: values.password,
        supplier_id: values.supplier_id.id,
      });
      onClose();
    },
  });

  const {
    setFieldValue,
    handleSubmit,
    values,
    touched,
    errors,
  } = formik;


  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        className={classNames(s.field, {
          [s.withError]: errors.first_name && touched.first_name
        })}
        name="first_name"
        label="First name"
        value={values.first_name}
        placeholder="Enter first name"
        onChange={e => {
          setFieldValue("first_name", e.target.value);
        }}
        error={touched.first_name && errors.first_name}
        limitValue={50}
        withCounter
      />
      <Input
        className={classNames(s.field, {
          [s.withError]: errors.last_name && touched.last_name
        })}
        name="last_name"
        label="Last name"
        value={values.last_name}
        placeholder="Enter last name"
        onChange={e => {
          setFieldValue("last_name", e.target.value);
        }}
        error={touched.last_name && errors.last_name}
        limitValue={50}
        withCounter
      />
      <Input
        className={classNames(s.field, {
          [s.withError]: errors.title && touched.title
        })}
        name="title"
        label="Title"
        value={values.title}
        placeholder="Enter user title"
        onChange={e => {
          setFieldValue("title", e.target.value);
        }}
        error={touched.title && errors.title}
        limitValue={32}
        withCounter
      />
      <Input
        className={classNames(s.field, {
          [s.withError]: errors.email && touched.email
        })}
        email="email"
        label="Email"
        value={values.email}
        placeholder="Enter email"
        onChange={e => {
          setFieldValue("email", e.target.value);
        }}
        error={touched.email && errors.email}
        limitValue={32}
        type="email"
        withCounter
      />
      <PasswordInput
        className={classNames(s.fieldPas, {
          [s.withError]: errors.password && touched.password
        })}
        label="Password"
        placeholder="Enter password"
        onChange={value => setFieldValue("password", value)}
        value={values.password}
        error={touched.password && errors.password}
        inputProps={{autoComplete: "new-password"}}
      />
      <CustomDropdown
        value={values.role.length > 0
          ? formatUserRoles(values.role)
            .map((e) => e.label)
            .join(", ")
          : "-"}
        onChange={e => {
          setFieldValue("role", e);
        }}
        options={userRoles.filter(e => e.value.includes("supplier"))}
        name="role"
        label="Role"
        className={s.dropdown}
        placeholder="Choose role"
        error={errors.role && touched.role && errors.role?.value}
      />
      <AutoCompleteInput
        value={values.supplier_id.name || ""}
        onChange={e => {
          setFieldValue("supplier_id", {
            id: "",
            name: e.target.value
          });
          handleSearchSupplier(e.target.value);
        }}
        onSelect={({id, name}) => {
          setFieldValue("supplier_id", {
            id,
            name
          });
        }}
        name="supplier"
        data={suppliers}
        placeholder="Choose supplier"
        label="Supplier"
        error={
          errors.supplier_id &&
          touched.supplier_id &&
          (errors.supplier_id.name || errors.supplier_id.id)
        }
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {defaultValues ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default SupplierUsersForm;