import {statuses} from './types';

export const LOAD_DATA = "LOAD_DATA";
export const CHANGE_PAGE_STATUS = "CHANGE_PAGE_STATUS";
export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const UPDATE_QUESTIONS = "UPDATE_QUESTIONS";

export const initialState = {
  questionsList: [],
  cloneList: [],
  statusPage: statuses.static,
};

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case LOAD_DATA: {
      return {
        ...state,
        questionsList: payload,
        cloneList: payload,
      };
    }
    case CHANGE_PAGE_STATUS: {
      return {
        ...state,
        statusPage: payload,
      };
    }
    case ADD_NEW_QUESTION: {
      return {
        ...state,
        questionsList: [...state.questionsList, payload],
      };
    }
    case DELETE_QUESTION: {
      return {
        ...state,
        questionsList: state.questionsList.filter(q => q.question_id !== payload),
      };
    }
    case UPDATE_QUESTION: {
      return {
        ...state,
        questionsList: state.questionsList.map(q => {
          if (q.question_id === payload.question_id) return payload;
          return q;
        }),
      };
    }
    case UPDATE_QUESTIONS: {
      return {
        ...state,
        questionsList: payload,
      };
    }
    default:
      throw Error("Unknown action type");
  }
}