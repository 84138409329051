import React from "react";
import classNames from "classnames";
import handleViewport from "react-in-viewport";

import s from "./HeaderNotificationDropList.module.scss";
import {ReactComponent as DeleteItem} from "assets/icons/clear.svg";
import renderNotificationItem from "helpers/renderNotificationItem";

const HeaderNotificationDropListItem = ({data, onDelete, forwardedRef}) => {
  const {message, is_readed, project_id, subject_type, milestone_id, request_id, approval_id, task_id} = data;

  return (
    <li
      className={classNames(s.listItem, {[s.notReaded]: !is_readed})}
      ref={forwardedRef}
    >
      {renderNotificationItem({
        className: s.data,
        message,
        type: subject_type,
        projectId: project_id,
        milestoneId: milestone_id,
        requestId: request_id,
        approvalId: approval_id,
        taskId: task_id
      })}

      <DeleteItem className={s.deleteBtn} onClick={() => onDelete(data.id)}/>
    </li>
  );
};

export default handleViewport(HeaderNotificationDropListItem);
