import React, { useState, useEffect, Fragment, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { debounce } from "lodash";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import Popup from "components/popups/Popup/Popup";
import services from "services/index";
import LinkedProjectsTable from "components/tables/LinkedProjectsTable/LinkedProjectsTable";
import AddLinkedProjectsForm from "components/forms/AddLinkedProjectsForm/AddLinkedProjectsForm";
import AlertContext from "contexts/AlertContext/AlertContext";
import TableLoading from "components/loadingStates/Table/Table";

const LinkedProjectsContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [show, setShow] = useState(false);
  const [linkedProjects, setLinkedProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AlertContext);
  const [permissions, setPermissions] = useState({
    update: false,
    destroy: false,
  });

  const searchHandler = debounce((term) => {
    services.projectServices
      .projectsAutocomplete({ query: term })
      .then((res) => setProjects(res.data));
  }, 300);

  useEffect(() => {
    services.linkedProjectsServices.getLinkedProjects(params.id).then((res) => {
      setLinkedProjects(res.data.linked_projects);
      setPermissions({
        update: res.data.permissions?.update || false,
        destroy: res.data.permissions?.destroy || false,
      });
      setLoading(false);
    });
    searchHandler("");
  }, [params.id]);

  useEffect(() => {
    setFilteredProjects(projects.filter((item) => item.id !== params.id));
  }, [projects]);

  const addHandler = (data) => {
    services.linkedProjectsServices
      .addLinkedProjects(params.id, data)
      .then((res) => {
        setAlert({
          message: `${res.data.name} successfully linked.`,
        });
        setLinkedProjects([...linkedProjects, res.data]);
      })
      .catch((error) => {
        setAlert({
          message:
            error.response.status === 422
              ? error.response.data.error
              : "Something went wrong. Please, try again.",
          type: "error",
        });
      });
  };

  const removeHandler = (id) => {
    const linkedProject = linkedProjects.find((item) => item.id === id);
    linkedProject.linked_project_id = linkedProject.id;
    services.linkedProjectsServices
      .removeLinkedProject(params.id, id)
      .then(() => {
        setAlert({
          message: `"${linkedProject.name}" project successfully unlinked.`,
          onCancel: () => {
            services.linkedProjectsServices
              .addLinkedProjects(params.id, linkedProject)
              .then((res) => {
                setLinkedProjects((state) => [...state, res.data]);
              });
          },
        });
        setLinkedProjects((state) => state.filter((item) => item.id !== id));
      })
      .catch(() => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error",
        });
      });
  };

  return (
    <>
      {loading && <TableLoading length={6} />}
      {!loading && (
        <Fragment>
          {linkedProjects.length ? (
            <LinkedProjectsTable
              linkedProjects={linkedProjects}
              removeHandler={removeHandler}
              history={history}
              openPopup={() => setShow(true)}
              havePermissions={permissions}
            />
          ) : (
            <EmptyBlock
              subtitle={
                permissions.update
                  ? "You don’t have linked projects yet. You can add a new one"
                  : "You don’t have linked projects yet."
              }
              buttonTitle="Link Project"
              onClick={() => setShow(true)}
              withBtn={permissions.update}
            />
          )}
          {show && (
            <Popup
              onClose={() => setShow(false)}
              title="Link a project"
              text="Choose a project from existing ones"
            >
              <AddLinkedProjectsForm
                projects={filteredProjects}
                linkedProjects={linkedProjects}
                addHandler={addHandler}
                searchHandler={searchHandler}
                onClose={() => setShow(false)}
              />
            </Popup>
          )}
        </Fragment>
      )}
    </>
  );
};

export default LinkedProjectsContainer;
