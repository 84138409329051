import React, {useEffect, useState} from "react";
import DetailsPanel from "./DetailsPanel/DetailsPanel";
import TriageTable from "../../../tables/TriageTable/TriageTable";
import services from "../../../../services";
import DetailLoading from "../../../loadingStates/Detail/Detail";
import s from "./TriageTab.module.scss";
import {entityFormat} from "../../../../helpers/triages/entityFormat";

const TriageTab = () => {
  const [data, setData] = useState(null);
  const [triages, setTriages] = useState(null);

  useEffect(() => {
    services.triageServices.getTriageHierarchies().then(res => {
      if (res.data) updateState(res.data);
    });
  }, []);

  const loadTriages = entity => {
    services.triageServices.getTriages({entity: entity.toLowerCase()}).then(res => {
      if (res.data) setTriages(res.data);
    });
  }

  const updateState = data => {
    const prepareData = convertData(data);
    const activeHierarchy = prepareData.triage_hierarchies.active;
    setData(prepareData);
    if (activeHierarchy.length) loadTriages(activeHierarchy[0].name);
    else setTriages(null);
  }

  const convertData = data => {
    return {
      ...data,
      triage_hierarchies: {
        ...data.triage_hierarchies,
        active: data.triage_hierarchies.active
          .sort((a, b) => a.level - b.level)
      }
    }
  }

  const editTriageHierarch = params => {
    services.triageServices.updateTriageHierarchies(params)
      .then(res => {
        if (res.data) updateState(res.data);
      });
  }

  const updateTriage = params => {
    const prepareValues = {
      triage_user_id: params.triageUserId,
      [`${entityFormat(params.entityName.toLowerCase())}_id`]: params.entityId,
    }
    services.triageServices.updateTriage(prepareValues).then(res => {
      if (res.data) loadTriages(params.entityName.toLowerCase());
    });
  }

  const deleteTriageUser = ({triageId, entityName}) => {
    services.triageServices.deleteTriage(triageId).then(res => {
      if (res.status === 200) loadTriages(entityName.toLowerCase())
    });
  }

  const renderDetailsPanel = () => {
    if (data) return <DetailsPanel data={data} onEdit={editTriageHierarch}/>;
    return <DetailLoading className={s.preloader} length={1}/>;
  }

  const renderTriagesTable = () => {
    if (!triages) return;

    const entityName = Object.keys(triages)[0];

    return (
      <TriageTable
        data={triages[entityName]}
        entity={entityName}
        hierarchy={data.triage_hierarchies.active.map(item => item.name).join()}
        updateEntityItem={params => updateTriage({...params, entityName})}
        deleteTriageUser={triageId => deleteTriageUser({triageId, entityName})}
      />
    );
  }

  return (
    <>
      {renderDetailsPanel()}
      {renderTriagesTable()}
    </>
  );
};

export default TriageTab;