import React from "react";
import Popup from "components/popups/Popup/Popup";
import PropTypes from "prop-types";

const RestoreFinancialSavingsGroupPopup = ({ onClose, onSubmit }) => {
  return (
    <Popup
      onClose={onClose}
      onClick={onSubmit}
      title="Restore savings group"
      text="Are you sure you want to restore this savings group?"
      successBtnTitle="Restore"
      dialog
    />
  );
};

export default RestoreFinancialSavingsGroupPopup;

RestoreFinancialSavingsGroupPopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
