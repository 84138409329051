import {getConditionValues} from "../../../../helpers/conditionValues";

export const changeSingleCondition = (conditions, conditionId, singleConditionId, type, value) => {
  return conditions.map(condition => {
    if (condition.id === conditionId) {
      return {
        ...condition,
        condition_group: condition.condition_group.map(singleCondition => {
          if (singleCondition.id === singleConditionId) {
            const {generalCondition, fieldValue, generalValue} = getConditionValues(singleCondition.value);

            if (type === "type") {
              return {
                ...singleCondition,
                value: [`'${value.value}'`, generalCondition, "''"].join("")
              };
            }

            if (type === "value") {
              return {
                ...singleCondition,
                value: [`'${fieldValue}'`, generalCondition, `'${value.value}'`].join("")
              };
            }

            if (type === "condition") {
              return {
                ...singleCondition,
                value: [`'${fieldValue}'`, value.value, `'${generalValue}'`].join("")
              };
            }

            return {
              ...singleCondition,
              condition: value,
            };
          }
          return singleCondition;
        })
      };
    }
    return condition;
  });
}