import {
  GET_STAGE,
  SET_STAGE,
  CHANGE_POPUP,
  ADD_TASK,
  CLOSE_ALERT,
  EDIT_TASK,
  DELETE_TASK
} from "./types"

export const initialState = {
  name: "",
  tasks: [],
  loading: true,
  alert: {
    type: "",
    message: ""
  },
  popup: null
}
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STAGE:
      return {
        ...state,
        name: payload.name,
        tasks: payload.tasks,
        loading: false
      }
    case SET_STAGE:
      return {
        ...state,
        name: payload.name,
        tasks: payload.tasks
      }
    case ADD_TASK:
      return {
        ...state,
        tasks: payload.task ? [...state.tasks, payload.task] : state.tasks,
        popup: payload.task ? null : "add",
        alert: payload.alert
      }
    case EDIT_TASK:
      return {
        ...state,
        tasks: payload.task
          ? state.tasks.map(e => (e.id === payload.task.id ? payload.task : e))
          : state.tasks,
        popup: payload.task ? null : "edit",
        alert: payload.alert
      }
    case DELETE_TASK:
      return {
        ...state,
        tasks: payload.task_id
          ? state.tasks.filter(e => e.id !== payload.task_id)
          : state.tasks,
        popup: payload.task_id ? null : "delete",
        alert: payload.alert
      }
    case CHANGE_POPUP:
      return {
        ...state,
        popup: payload
      }
    case CLOSE_ALERT:
      return {
        ...state,
        alert: {
          type: "",
          message: ""
        }
      }
  }
}
