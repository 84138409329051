import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import CreateProjectContainer
  from "../../containers/CreateProjectContainer/CreateProjectContainer";
import qs from "query-string";
import {paths} from "../../../routes/paths";
import {useHistory, useParams} from "react-router-dom";
import EditProjectContainer from "../../containers/EditProjectContainer/EditProjectContainer";

const EditProjectPage = () => {
  const {id} = useParams();

  let breadcrumbs = [
    {
      path: paths.projects,
      name: "Projects"
    },
    {
      name: "Edit project"
    }
  ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <EditProjectContainer projectId={id}/>
    </DefaultLayout>
  );
};

export default EditProjectPage;
