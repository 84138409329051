import React from "react";
import s from "../QuestionsContent.module.scss";
import Input from "../../../../../elements/Input/Input";
import classNames from "classnames";

const SingleQuestionOpenAnswer = ({currentQuestion, isEdit, updateQuestion}) => {
  if (isEdit) {
    return (
      <div className={s.questionContent} data-drag-item="drag">
        <p className={s.titleQuestion} data-drag-item="drag">
          {currentQuestion.question_name}
          {currentQuestion.is_required && <sup>*</sup>}
        </p>
        <Input
          value={currentQuestion.question_name}
          onChange={event => updateQuestion({...currentQuestion, question_name: event.target.value})}
          placeholder='Enter an answer'
          className={classNames(s.input, s.padding, {[s.withTitle]: Boolean(currentQuestion.question_name)})}
        />
      </div>
    )
  }

  return (
    <div className={s.questionContent} data-drag-item="drag">
      <p className={s.titleQuestion} data-drag-item="drag">
        {currentQuestion.question_name}
        {currentQuestion.is_required && <sup>*</sup>}
      </p>
      <Input value={!isEdit && currentQuestion.question_name} placeholder='Enter an answer' className={s.input}/>
    </div>
  )
}

export default SingleQuestionOpenAnswer;
