import React, {useEffect, useState} from "react"
import EmptyContent from "../EmptyContent/EmptyContent";
import Content from "../Content/Content";
import {addNewProjectType, deleteProjectType, deleteTypeOfSpend, loadData} from "../helpers/actions/main";
import Popup from "../../../../popups/Popup/Popup";
import ProjectTypeForm from "./ProjectTypeForm";

const ProjectType = ({data, dispatch}) => {
  const [isOpenPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    loadData(dispatch, 'projectTypeList');
  }, []);

  const addNewCondition = value => addNewProjectType(dispatch, {project_type_id: value});
  const deleteCondition = id => deleteProjectType(dispatch, id);

  const serializeData = data => {
    return data.map(t => {
      return {
        name: t.project_type.name,
        questions_count: t.additional_questions_count,
        id: t.id,
      }
    });
  }

  return (
    <>
      {
        !Boolean(data.length) ?
          <EmptyContent addAction={() => setOpenPopUp(true)}/> :
          <Content
            data={serializeData(data)}
            type='Project type'
            addAction={() => setOpenPopUp(true)}
            deleteAction={id => deleteCondition(id)}
          />
      }
      {
        isOpenPopUp &&
        <Popup
          onClose={() => setOpenPopUp(false)}
          title="New condition"
        >
          <ProjectTypeForm
            onSubmit={value => addNewCondition(value.project.value.id)}
            onClose={() => setOpenPopUp(false)}
            projectTypesList={data}
          />
        </Popup>
      }
    </>
  );
}
export default ProjectType;
