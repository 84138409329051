import React, {useEffect, useReducer} from "react";
import {useParams} from "react-router-dom";
import s from "./AdminAdditionalQuestionContainer.module.scss";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import EmptyContent from "./components/EmptyContent/EmptyContent";
import {initialState, reducer} from "components/containers/AdminAdditionalQuestionContainer/helpers/reducer";
import {
  addNewQuestions,
  changePageStatus,
  loadData,
} from "components/containers/AdminAdditionalQuestionContainer/helpers/actions";
import {statuses} from "./helpers/types";
import QuestionsContent from "./components/QuestionsContent/QuestionsContent";
import {Button} from "@material-ui/core";
import classNames from "classnames";
import {truncateText} from "../../../helpers/truncateText";
import {paths} from "../../../routes/paths";
import {useTranslation} from "react-i18next";


const AdminAdditionalQuestionContainer = () => {
  let {id, name} = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    loadData(dispatch, id);
  }, []);

  const addQuestion = () => changePageStatus(dispatch, statuses.edit);
  const {t} = useTranslation();
  const renderHeaderBtnGroup = () => {
    if (state.statusPage === statuses.edit) {
      return (
        <div className={s.btnGroup}>
          <Button
            className={classNames(s.defaultBtn, s.cancel)}
            onClick={() => {
              loadData(dispatch, id);
              changePageStatus(dispatch, statuses.static);
            }}
            data-translate-key="cancel">
            {t("cancel")}
          </Button>
          <Button
            className={classNames(s.defaultBtn, s.blue)}
            onClick={() => addNewQuestions(dispatch, id, state.questionsList, state.cloneList)}
            data-translate-key="save">
            {t("save")}
          </Button>
        </div>
      );
    }

    if (state.questionsList.length) {
      return (
        <div className={s.btnGroup}>
          <Button
            className={classNames(s.defaultBtn, s.blue)}
            onClick={() => changePageStatus(dispatch, statuses.edit)}
            data-translate-key="edit">
            {t("edit")}
          </Button>
        </div>
      );
    }
  };
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          name: "Admin Settings",
          disabled: true,
        },
        {
          path: paths.adminSettingsAdditionalQuestions,
          name: "Additional questions",
        },
        {
          name: truncateText(name, 25),
        },
      ]}
    >
      <div className={s.wrap}>
        <div className={s.header}>
          <h2 className={s.title}>{truncateText(name, 25)}</h2>
          {renderHeaderBtnGroup()}
        </div>
        <div className={s.content}>
          {
            state.questionsList.length || state.statusPage === statuses.edit ?
              <QuestionsContent status={state.statusPage} questionsList={state.questionsList} dispatch={dispatch}/> :
              <EmptyContent addNewAction={() => addQuestion()}/>
          }
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AdminAdditionalQuestionContainer;