export const deepFind = (entitiesList, id) => {
  let foundedEntity = undefined;

  foundedEntity = entitiesList.find(entity => entity.id === id);

  if (!foundedEntity) entitiesList.forEach(entity => {
    foundedEntity = deepFind(entity.users, id);
  });

  return foundedEntity;
}