import React, {useState} from "react";
import PropTypes from "prop-types";
import s from "./MilestoneTrelloBoard.module.scss";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Button from "../../../../../../../../elements/Button/Button";
import {backgroundTypes} from "../../../../../../../../../helpers/helpData";
import DropListTrigger from "../../../../../../../../elements/DropListTrigger/DropListTrigger";
import Popup from "../../../../../../../../popups/Popup/Popup";
import AddMilestoneTaskFormNew from "../../../../../../../../forms/AddMilestoneTaskFormNew/AddMilestoneTaskFormNew";
import {ReactComponent as GreenCheck} from "assets/icons/greenCheck.svg";
import {useSelector} from "react-redux";
import {formatDate} from "../../../../../../../../../helpers/dateFormat";
import {trimRest} from "../../../../../../../../../helpers/stringHelper";
import {useHistory, useParams} from "react-router-dom";

const MilestoneDragBoard = ({milestones, addNewTask, editTask, deleteTask, ownerId, statuses}) => {
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(null);
  const [popUp, setPopUp] = useState(null);
  const history = useHistory();
  const params = useParams();
  const [currentMilestone, setCurrentMilestone] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const user = useSelector(store => store.user);

  const isCompleted = id => id === statuses[statuses.length - 1]?.id;

  const onDragEnd = params => {
    const {destination, draggableId, source} = params;

    let preparedTask = milestones
      .find(milestone => milestone.id === source.droppableId)
      .tasks["additional_tasks"]
      .find(task => task.id === draggableId);

    const preparedTaskId = preparedTask.id;

    delete preparedTask.id;

    if (destination?.droppableId) {
      editTask(destination.droppableId, preparedTaskId, {
        ...preparedTask,
        stage_id: destination.droppableId,
        end_date: formatDate(preparedTask.end_date),
        start_date: formatDate(preparedTask.start_date),
      });
    }
  };

  if (!milestones.length) return "";

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <ul className={s.list}>
          {milestones.map(milestone => (
            <li
              className={s.col}
              key={milestone.id}
              style={{width: `${100 / milestones.length}%`}}
            >
              <Droppable droppableId={milestone.id} type={"additional_tasks"} isDropDisabled={user.id !== ownerId}>
                {provided => (
                  <ul
                    className={s.tasks}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {milestone.tasks["additional_tasks"].map((task, index) => (
                      <Draggable
                        draggableId={task.id}
                        index={index}
                        key={task.id}
                        isDropDisabled={user.id !== ownerId}
                      >
                        {provided => (
                          <li
                            onClick={() => {
                              history.push(`/projects/${params.id}/milestones/${milestone.id}/tasks/${task.id}`);
                            }}
                            className={s.task}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
														<span
                              style={{background: backgroundTypes[milestone.color]}}
                              className={s.line}
                            />
                            <div className={s.taskHeadWrapper}>
                              <p>{trimRest(25, task.name)}</p>
                              {
                                (user.id === ownerId || user.id === task.responsible_user.id) && (
                                  <DropListTrigger
                                    data={[
                                      {
                                        title: "Delete",
                                        onClick: () => {
                                          setCurrentMilestone(milestone);
                                          setCurrentTask(task);
                                          setPopUp("delete");
                                        }
                                      }
                                    ]}
                                    visibleMenu={visibleDropListMenu === task.id}
                                    setVisibleMenu={() => setVisibleDropListMenu(task.id)}
                                  />
                                )
                              }
                            </div>
                            <div className={s.taskContent}>
                              <p>{task.responsible_user.name}</p>
                              {
                                isCompleted(task?.task_status?.id) && (
                                  <div className={s.completed}>
                                    <GreenCheck/>
                                  </div>
                                )
                              }
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
              {user.id === ownerId && (
                <Button
                  styled='secondary'
                  className={s.addTaskBtn}
                  onClick={() => {
                    setCurrentMilestone(milestone);
                    setPopUp("new");
                  }}
                >
                  + New task
                </Button>
              )}
            </li>
          ))}
        </ul>
      </DragDropContext>
      {popUp === "new" && (
        <Popup
          onClose={() => setPopUp(null)}
          title="New additional task"
          text="Please, complete the following fields to create a new task"
        >
          <AddMilestoneTaskFormNew
            onSubmit={values => {
              addNewTask(currentMilestone.id, values);
              setPopUp(null);
            }}
            onClose={() => setPopUp(null)}
          />
        </Popup>
      )}
      {popUp === "delete" && (
        <Popup
          title="Delete additional task"
          text="Are you sure you want to delete this task?"
          onClose={() => {
            setCurrentMilestone(null);
            setCurrentTask(null);
            setPopUp(null);
          }}
          onClick={() => {
            deleteTask(currentMilestone.id, currentTask.id);
            setCurrentMilestone(null);
            setCurrentTask(null);
            setPopUp(null);
          }}
          isDelete
          dialog
        />
      )}
    </>

  );
};

export default MilestoneDragBoard;

MilestoneDragBoard.propTypes = {
  milestones: PropTypes.array,
  addNewTask: PropTypes.func,
  editTask: PropTypes.func,
  deleteTask: PropTypes.func,
  ownerId: PropTypes.any,
};