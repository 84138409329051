import React, {useEffect, useState} from 'react';
import services from "../../../../../services";
import {useParams} from "react-router-dom";
import NPSScoreWidget from "../../../DepartmentReportContainer/components/NPSScoreWidget/NPSScoreWidget";
import {CircularProgress} from "@material-ui/core";
import styles from "../../TeamReportContainer.module.scss";

const TeamReportGeneral = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    params.id &&
    services.reportServices
      .getTeamReportGeneral(params.id)
      .then((res) => {
        if (res.data) {
          setData(res.data);
          setLoading(false);
        }
      });
  }, []);

  if (loading) return <CircularProgress className="loader"/>;

  return (
    <div className={styles.detailBlock}>
      <NPSScoreWidget data={data}/>
    </div>
  );
};

export default TeamReportGeneral;