import {
  SET_GLOBAL_PROJECT,
  SET_GLOBAL_PROJECT_MESSAGE,
  DELETE_GLOBAL_PROJECT,
  ADD_GLOBAL_PROJECT_SUPPLIER,
  ADD_GLOBAL_PROJECT_CONTRACT,
  ADD_GLOBAL_PROJECT_USER,
  DELETE_GLOBAL_PROJECT_CONTRACT,
  DELETE_GLOBAL_PROJECT_SUPPLIER,
  DELETE_GLOBAL_PROJECT_USER,
  ADD_GLOBAL_PROJECT,
  EDIT_GLOBAL_PROJECT,
} from "helpers/constants";
import { load } from "helpers/localStorage";

const savedData = load("globalProject");

let initialState = savedData || {
  data: null,
  message: {
    type: "",
    text: "",
  },
};

const globalProject = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GLOBAL_PROJECT: {
      return {
        ...state,
        data: {
          name: payload,
          category: {
            id: null,
            name: null,
          },
          project_type: null,
          target_spend: null,
          target_spend_currency: null,
          start_date: null,
          end_date: null,
          owner: {
            id: null,
            name: null,
          },
          suppliers: [],
          contracts: [],
          users: [],
        },
      };
    }
    case SET_GLOBAL_PROJECT_MESSAGE: {
      return {
        ...state,
        message: payload,
      };
    }
    case DELETE_GLOBAL_PROJECT: {
      return {
        ...state,
        data: null,
      };
    }
    case ADD_GLOBAL_PROJECT_SUPPLIER: {
      return {
        ...state,
        data: {
          ...state.data,
          suppliers: [...state.data.suppliers, payload],
        },
      };
    }
    case ADD_GLOBAL_PROJECT_CONTRACT: {
      return {
        ...state,
        data: {
          ...state.data,
          contracts: [...state.data.contracts, payload],
        },
      };
    }
    case ADD_GLOBAL_PROJECT_USER: {
      return {
        ...state,
        data: {
          ...state.data,
          users: [...state.data.users, payload],
        },
      };
    }
    case DELETE_GLOBAL_PROJECT_CONTRACT: {
      return {
        ...state,
        data: {
          ...state.data,
          contracts: state.data.contracts.filter((e) => e.id !== payload),
        },
      };
    }
    case DELETE_GLOBAL_PROJECT_SUPPLIER: {
      return {
        ...state,
        data: {
          ...state.data,
          suppliers: state.data.suppliers.filter((e) => e.id !== payload),
        },
      };
    }
    case DELETE_GLOBAL_PROJECT_USER: {
      return {
        ...state,
        data: {
          ...state.data,
          users: state.data.users.filter((e) => e.id !== payload),
        },
      };
    }
    case ADD_GLOBAL_PROJECT: {
      return {
        ...state,
        data: {
          target_spend: null,
          target_spend_currency: null,
          suppliers: [],
          contracts: [],
          users: [],
          ...payload,
        },
      };
    }
    case EDIT_GLOBAL_PROJECT: {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default globalProject;
