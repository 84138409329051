import React, { useState, useEffect, useCallback } from "react";

export const useResize = (ref = {}, options, initialValues, limits) => {
  const { step = 1, axis = "both" } = options || {};
  const [coords, setCoords] = useState({ x: undefined, y: undefined });
  const [dims, setDims] = useState({ width: undefined, height: undefined });
  const [size, setSize] = useState({
    width: initialValues?.width,
    height: initialValues?.height,
  });
  const [drag, setDrag] = useState(false);
  const [dragStop, setDragStop] = useState(false);

  const rangeLimitsWidth = (width) => {
    return width > limits.minWidth && width < limits.maxWidth;
  };

  const rangeLimitsHeight = (height) => {
    return height > limits.minHeight && height < limits.maxHeight;
  };

  const initResize = (event) => {
    if (!ref.current) return;
    setCoords({ x: event.clientX, y: event.clientY });
    const { width, height } = window.getComputedStyle(ref.current);
    setDims({ width: parseInt(width, 10), height: parseInt(height, 10) });
  };

  const childSetDragStop = useCallback(
    (value) => {
      if (value !== dragStop) {
        setDragStop(value);
      }
    },
    [setDragStop, dragStop],
  );

  useEffect(() => {
    // Round the size based to `props.step`.
    const getValue = (input) => Math.ceil(input / step) * step;

    const doDrag = (event) => {
      if (!ref.current) return;

      // Calculate the box size.
      const width = getValue(dims.width + event.clientX - coords.x);
      const height = getValue(dims.height + event.clientY - coords.y);

      if (isNaN(width) || isNaN(height)) return;
      setDrag(true);
      // Set the box size.
        if (axis === "horizontal") {
          if (limits?.minWidth && limits?.maxWidth) {
            if (rangeLimitsWidth(width)) ref.current.style.width = width + "px";
          } else ref.current.style.width = width + "px";
          if (limits?.minWidth && limits?.maxWidth) {
            rangeLimitsWidth(width) && setSize({ width });
          } else setSize({ width });
        }

        if (axis === "vertical") {
          if (limits?.minHeight && limits?.maxHeight) {
            rangeLimitsHeight(height) && (ref.current.style.height = height + "px");
          } else ref.current.style.height = height + "px";
          if (limits?.minHeight && limits?.maxHeight) {
            rangeLimitsHeight(height) && setSize({ height });
          } else setSize({ height });
        }
    };

    const stopDrag = () => {
      document.removeEventListener("mousemove", doDrag, false);
      document.removeEventListener("mouseup", stopDrag, false);
      setDrag(false);
    };

    document.addEventListener("mousemove", doDrag, false);
    document.addEventListener("mouseup", stopDrag, false);
  }, [coords, dims, step, ref, axis]);

  return { initResize, size, setSize, drag, childSetDragStop, dragStop };
};
