import React from "react";
import AdminSettingsApprovalsContainer
  from "../../containers/AdminSettingsApprovalsContainer/AdminSettingsApprovalsContainer";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

const AdminSettingsApprovalsPage = () => {
  return  (
    <DefaultLayout
      breadcrumbs={[
        { name: "Admin Settings", path: "", disabled: true } ,
        { name: "Approvals", path: "" },
      ]}
    >
      <AdminSettingsApprovalsContainer/>
    </DefaultLayout>
  );
};
export default AdminSettingsApprovalsPage;
