import React from "react";
import Popup from "components/popups/Popup/Popup";
import PropTypes from "prop-types";
import AdminSettingsUsersDivisionForm
  from "../../../../../forms/AdminSettingsUsersDivisionForm/AdminSettingsUsersDivisionForm";

const AddDivision = ({onClose, onSubmit}) => {
  return (
    <Popup
      onClose={onClose}
      title="New division"
      text="Please, complete the following fields to add a new division"
    >
      <AdminSettingsUsersDivisionForm
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Popup>
  );
};

export default AddDivision;

AddDivision.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
