import React, { useEffect } from 'react'
import LandingLayout from "../../Landinglayout/Landinglayout";
import OurCompany from './components/OurCompany/OurCompany'
import Leadership from './components/Leadership/Leadership'

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <LandingLayout>
      <OurCompany/>
      <Leadership/>
    </LandingLayout>
  )
}

export default AboutUsPage
