import React, {useState, useEffect} from "react";
import s from "./FilterBar.module.scss";
import PropTypes from "prop-types";
import {ReactComponent as Filter} from "assets/icons/filter.svg";
import classNames from "classnames";
import Input from "components/elements/Input/Input";
import BarDropDown from "../BarDropDown/BarDropDown";
import {useTranslate} from "../../../hooks/useTranslate";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const FilterBar = (
  {
    filterName,
    children,
    handleClearAll,
    className,
    withSearch = false,
    onSearch,
    searchDefaultValue,
    clearAllWithSearch = false,
    savedFilter,
    saveDefaultFilter,
    resetDefaultFilter,
    showDefaultFilter,
    withoutDefaultFilter = false,
  }
) => {
  const childrenArray = React.Children.toArray(children);
  const [clearAllFilters, setClearAllFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [searchValue, setSearchValue] = useState(searchDefaultValue || "");
  const [translateNameKey, translateNameValue] = useTranslate(filterName);
  const {t} = useTranslation();

  useEffect(() => {
    setSearchValue(searchDefaultValue);
  }, [searchDefaultValue]);

  useEffect(() => {
    const filtersValue = Object.values(activeFilters);
    const isFilters = filtersValue.some(value => value === true);
    if (!isFilters) {
      setClearAllFilters(false);
    }
  }, [activeFilters]);

  const setFilterValue = (name, value) => {
    setActiveFilters({...activeFilters, [name]: value});
  };

  const setAllFiltersToFalse = () => {
    const array = Object.entries(activeFilters);
    array.forEach(a => (a[1] = false));
    setActiveFilters(Object.fromEntries(array));
    handleClearAll();
    clearAllWithSearch && setSearchValue("");
  };

  const childrenWithClearAllProps = React.Children.map(childrenArray, child =>
    React.cloneElement(child, {
      clearAllFilters,
      setClearAllFilters,
      setFilterValue
    })
  );

  const actionHandler = ({value}) => {
    if (value === "clear_filter") {
      setClearAllFilters(true);
      setAllFiltersToFalse();
    }
    if (value === "save_default") saveDefaultFilter();
    if (value === "reset_default") resetDefaultFilter();
  }

  const renderActionBlock = () => {
    let options = [];
    const isEmptyFilters = Object.values(activeFilters).some(a => a);

    if (!isEmptyFilters && savedFilter) {
      return (
        <div
          className={s.showAllBtn}
          onClick={() => showDefaultFilter()}
          data-translate-key={getTranslateKey("Show default")}
        >
          {t(getTranslateKey("Show default"))}
        </div>
      )
    }

    if (savedFilter) {
      options.push({
        name: "Reset default",
        value: "reset_default",
      });
    }

    if (!savedFilter && isEmptyFilters && !withoutDefaultFilter) {
      options.push({
        name: "Save default",
        value: "save_default",
      });
    }

    if (isEmptyFilters) {
      options.push({
        name: "Clear filters",
        value: "clear_filter",
      });
    }

    if (options.length) {
      return (
        <BarDropDown
          name="Actions"
          className={s.actions}
          typeActions
          handleChange={actionHandler}
          options={options}
        />
      );
    }

    return "";
  }

  return (
    <div className={classNames(s.filterBox, className)}>
      <div className={s.filterName}>
        <Filter/>
        {withSearch ? (
          <Input
            placeholder={filterName}
            onChange={e => {
              if (e.target.value.length > 30) return;
              onSearch(e.target.value);
              setSearchValue(e.target.value);
            }}
            className={s.searchInput}
            value={searchValue}
          />
        ) : (
          <p data-translate-key={translateNameKey}>{translateNameValue}</p>
        )}
      </div>
      <div className={s.filters}>
        {renderActionBlock()}
        {childrenWithClearAllProps}
      </div>
    </div>
  );
};

FilterBar.propTypes = {
  filterName: PropTypes.string.isRequired,
  handleClearAll: PropTypes.func,
  className: PropTypes.string,
  withSearch: PropTypes.bool,
  onSearch: PropTypes.func,
  searchDefaultValue: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  clearAllWithSearch: PropTypes.bool,
  savedFilter: PropTypes.any,
};

export default FilterBar;
