import React, {useState, useEffect} from "react";
import s from "./CompanyGoalContainer.module.scss";
import classNames from "classnames";
import services from "services";
import SavingsGoal from "components/elements/SavingsGoal/SavingsGoal";
import {useParams} from "react-router-dom";
import CompanyGoalFilter from "components/filters/CompanyGoalFilter/CompanyGoalFilter";
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar";
import {getAllIds} from "components/elements/FilterBar/helpers/helpers";
import Table from "./components/Table/Table";
import TableLoading from "components/loadingStates/Table/Table";
import EditPopup from "./popups/EditPopup/EditPopup";
import {ReactComponent as Pencil} from "assets/icons/edit-pencil.svg";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import {userRoles} from "../../../helpers/userPermissions/userRoles";
import {useSelector} from "react-redux";
import {checkRoles} from "../../../helpers/checkRoles";

const statuses = [
  {
    label: "Active & Completed",
    value: "active_and_completed"
  },
  {
    label: "Completed",
    value: "completed"
  }
];

const CompanyGoalContainer = (
  {
    isRequest,
    source,
    className,
    withFilters = true,
    withEdit = true,
    sourceId,
    withLink = true
  }
) => {
  const {id} = useParams();
  const [filters, setFilters] = useState(null);
  const [activeFilters, setActiveFilters] = useState({
    years: ["2021"],
    statuses: ["active_and_completed"],
    query: ""
  });
  const [data, setData] = useState(null);
  const [editPopup, setEditPopup] = useState(null);
  const [alert, setAlert] = useState({message: "", type: ""});
  const {roles} = useSelector(state => state.user);
  const {cpo} = userRoles;

  useEffect(() => {
    withFilters &&
    services.companyGoalsServices.getIndividualGoalsFilters().then(res => {
      const filters = {
        years: res.data.years,
        statuses: res.data.statuses.map((e, index) => ({
          id: statuses[index].value,
          name: statuses[index].label
        }))
      };
      setFilters(filters);
    });
  }, []);

  useEffect(() => {
    const params = {
      year: String(activeFilters.years),
      project_status: activeFilters.statuses.join(","),
      query: activeFilters.query || ""
    };

    const isValidParams =
      params.year?.length > 0 || params.project_status?.length > 0;
    if (source === "company" || !source) {
      services.companyGoalsServices
        .getCompanyGoal(
          id || sourceId,
          isValidParams ? params : {query: params.query}
        )
        .then(res => {
          setData(res.data);
        });
    } else if (source === "department") {
      services.companyGoalsServices
        .getDepartmentGoal(
          id || sourceId,
          isValidParams ? params : {query: params.query}
        )
        .then(res => {
          setData(res.data);
        });
    } else if (source === "user") {
      services.companyGoalsServices
        .getUserGoal(id || sourceId, params)
        .then(res => {
          setData(res.data);
        });
    } else if (source === "team") {
      services.reportServices
        .getTeamReportGoal(id || sourceId)
        .then(res => {
          setData(res.data);
        });
    }
  }, [source, activeFilters]);

  const handleChangeFilters = selectedFilters => {
    const yearsIds = selectedFilters.years;
    const statusesIds = getAllIds(selectedFilters.statuses);
    const filters = {
      years: yearsIds,
      statuses: statusesIds,
      query:
        selectedFilters.query === undefined
          ? activeFilters.query
          : selectedFilters.query
    };
    setActiveFilters(filters);
  };

  const handleEditGoal = values => {
    services.companyGoalsServices
      .changeCompanyGoal({source_id: editPopup.data.id, ...values})
      .then(res => {
        if (editPopup.source === "department") {
          setData({
            ...data,
            departments: data.departments.map(e =>
              e.id === editPopup.data.id ? {...e, goal: res.data} : e
            )
          });
        } else if (editPopup.source === "user") {
          setData({
            ...data,
            users: data.users.map(e =>
              e.id === editPopup.data.id ? {...e, goal: res.data} : e
            )
          });
        } else {
          setData({...data, goal: res.data});
        }
      })
      .catch(() => {
          setAlert({
            type: "error",
            message: "Something went wrong. Please, try again."
          });
        }
      )
      .finally(() => setEditPopup(null));
  };

  return (
    <div className={classNames(s.wrap, {[s.isRequest]: isRequest}, className)}>
      {!isRequest &&
        <h2 className={s.title}>
          {data?.name ? `${data?.name ?? ""}` : `${data?.first_name ?? ""} ${data?.last_name ?? ""}`}
        </h2>}
      {filters && withFilters ? (
        <CompanyGoalFilter
          activeFilters={activeFilters}
          filters={filters}
          onFiltersChange={handleChangeFilters}
          className={s.filterBar}
        />
      ) : (
        withFilters && <FilterBarLoading className={s.filterBar} length={3}/>
      )}
      {data && (
        <div className={s.savingsGoal}>
          <SavingsGoal
            defaultData={data}
            withLink={withLink}
            title="Goal by quarters"
          />
          {withEdit && (
            (checkRoles(roles, cpo) && <Pencil
              className={s.editBtn}
              onClick={() =>
                setEditPopup({source: "company", name: data.name, data})
              }
            />)
          )}
        </div>
      )}
      {data &&
      (data.users?.length > 0 || data.departments?.length > 0) &&
      !isRequest ? (
        <div className={s.table}>
          <Table
            onEdit={item =>
              setEditPopup({
                source: source === "department" ? "user" : "department",
                name: item.name || `${item.first_name}  ${item.last_name}`,
                data: item
              })
            }
            data={data.users || data.departments}
            source={source === "department" ? "users" : "departments"}
          />
        </div>
      ) : (
        !isRequest &&
        data &&
        (data.users?.length > 0 || data.departments?.length > 0) && (
          <div className={s.table}>
            <TableLoading length={5}/>
          </div>
        )
      )}
      {editPopup && withEdit && (
        <EditPopup
          onSubmit={handleEditGoal}
          onClose={() => setEditPopup(null)}
          defaultValues={editPopup.data.goal}
          source={editPopup.source}
          name={editPopup.name}
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({message: "", type: ""})}
        />
      )}
    </div>
  );
};

export default CompanyGoalContainer;
