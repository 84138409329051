import React from "react";
import AdminSettingsRequestsContainer
  from "../../containers/AdminSettingsRequestsContainer/AdminSettingsRequestsContainer";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

const AdminSettingsRequestsPage = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {name: "Admin Settings", path: "", disabled: true},
        {
          name: "Requests",
        },
      ]}
    >
      <AdminSettingsRequestsContainer/>
    </DefaultLayout>
  );
};
export default AdminSettingsRequestsPage;
