import React from "react";
import Tabs from "components/elements/Tabs/Tabs";
import Details from "./components/Details/Details";
import Notes from "./components/Notes/Notes";
import History from "./components/History/History";
import DetailLoading from "components/loadingStates/Detail/Detail";
import {useSelector} from "react-redux";

const ProjectInfo = ({details, onEditDetails, onDeleteTag, onAssignTag}) => {
  const user = useSelector(store => store.user);
  return (
    user.roles === "category_business_owner" || user.roles === "business_user"
      ?
      <Tabs styled="secondary">
        <div label="Details">
          {!Object.keys(details).length ? (
            <DetailLoading length={6}/>
          ) : (
            <Details
              data={details}
              onEdit={onEditDetails}
              onDeleteTag={onDeleteTag}
              onAssignTag={onAssignTag}
            />
          )}
        </div>
        <div label="History">
          <History/>
        </div>
      </Tabs>
      :
      <Tabs styled="secondary">
        <div label="Details">
          {!Object.keys(details).length ? (
            <DetailLoading length={6}/>
          ) : (
            <Details
              data={details}
              onEdit={onEditDetails}
              onDeleteTag={onDeleteTag}
              onAssignTag={onAssignTag}
            />
          )}
        </div>
        <div label="Notes">
          <Notes/>
        </div>
        <div label="History">
          <History/>
        </div>
      </Tabs>
  );
};
export default ProjectInfo;
