import React from 'react';
import Button from "../../../elements/Button/Button";
import s from "../FinancialBenefitsForm.module.scss";
import {useHistory} from "react-router-dom";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const HeaderForm = ({isEdit}) => {
  const history = useHistory();
  const {t} = useTranslation();

  return (
    <div className={s.headerForm}>
      <h2 data-translate-key={getTranslateKey(`${isEdit ? "Edit" : "New"} financial benefit`)}>
        {t(getTranslateKey(`${isEdit ? "Edit" : "New"} financial benefit`))}
      </h2>
      <div>
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button
          styled="primary"
          type="submit"
          className="popupBtn"
        >
          {isEdit ? "Save" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default HeaderForm;