import React from 'react'
import s from '../PrivacyPage.module.scss'

const Point01 = () => {
	return (
		<div id="point01" className={s.pointBlock}>
              <h3 className={s.subtitle}>1. WHAT INFORMATION DO WE COLLECT?</h3>

              <p className={s.text}>
                <span>Personal information you disclose to us</span>
              </p>

              <p className={s.text} style={{ fontStyle: "italic" }}>
                <span>In Short:</span> We collect personal information that you
                provide to us.
              </p>

              <p className={s.text}>
                We collect personal information that you voluntarily provide to
                us when you express an interest in obtaining information about
                us or our products and Services, when you participate in
                activities on the Website or otherwise when you contact us.
              </p>

              <p className={s.text}>
                The personal information that we collect depends on the context
                of your interactions with us and the Website, the choices you
                make and the products and features you use. The personal
                information we collect may include the following:
              </p>

              <p className={s.text}>
                <span>Personal Information Provided by You.</span> We collect
                names; phone numbers; email addresses; job titles; and other
                similar information.
              </p>

              <p className={s.text}>
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
              </p>

              <p className={s.text}>
                <span>Information automatically collected</span>
              </p>

              <p className={s.text}>
                <span>In Short:</span> Some information — such as your Internet
                Protocol (IP) address and/or browser and device characteristics
                — is collected automatically when you visit our Website.
              </p>

              <p className={s.text}>
                We automatically collect certain information when you visit, use
                or navigate the Website. This information does not reveal your
                specific identity (like your name or contact information) but
                may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Website and
                other technical information. This information is primarily
                needed to maintain the security and operation of our Website,
                and for our internal analytics and reporting purposes.
              </p>

              <p className={s.text}>
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </p>

              <p className={s.text}>
                The information we collect includes: <br />• Log and Usage Data.
                Log and usage data is service-related, diagnostic, usage and
                performance information our servers automatically collect when
                you access or use our Website and which we record in log files.
                Depending on how you interact with us, this log data may include
                your IP address, device information, browser type and settings
                and information about your activity in the Website (such as the
                date/time stamps associated with your usage, pages and files
                viewed, searches and other actions you take such as which
                features you use), device event information (such as system
                activity, error reports (sometimes called 'crash dumps') and
                hardware settings).
              </p>

              <p className={s.text}>
                • Device Data. We collect device data such as information about
                your computer, phone, tablet or other device you use to access
                the Website. Depending on the device used, this device data may
                include information such as your IP address (or proxy server),
                device and application identification numbers, location, browser
                type, hardware model Internet service provider and/or mobile
                carrier, operating system and system configuration information.
              </p>

              <p className={s.text}>
                • Location Data. We collect location data such as information
                about your device's location, which can be either precise or
                imprecise. How much information we collect depends on the type
                and settings of the device you use to access the Website. For
                example, we may use GPS and other technologies to collect
                geolocation data that tells us your current location (based on
                your IP address). You can opt out of allowing us to collect this
                information either by refusing access to the information or by
                disabling your Location setting on your device. Note however, if
                you choose to opt out, you may not be able to use certain
                aspects of the Services.
              </p>
            </div>
	)
}

export default Point01
