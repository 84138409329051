import React from "react";
import s from "./ProjectHistoryTable.module.scss";
import MainTable from "../MainTable/MainTable";
import { fromHistoryDate } from "helpers/dateFormat";

const ProjectHistoryTable = ({
  data,
  totalCount,
  onPageChange,
  currentPage,
  onSort,
}) => {
  return (
    <MainTable
      data={data}
      className={s.historyTable}
      dataTypes={[
        {
          title: "Edited",
          value: ({ modification }) => modification,
        },
        {
          title: "Name",
          value: ({ user }) => user.name,
        },
        {
          title: "Time",
          value: ({ created_at }) => fromHistoryDate(created_at),
          sortable: true,
          handleSort: (type) =>
            onSort({ sort_by: "created_at", direction: type.direction }),
        },
      ]}
      perPage={8}
      totalCount={totalCount}
      onPageChange={onPageChange}
      page={currentPage}
      isPagination={true}
    />
  );
};

export default ProjectHistoryTable;
