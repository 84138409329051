import React from "react";
import { useFormik } from "formik";
import { object, string, ref } from "yup";
import UserFormWrapper from "components/wrappers/UserFormWrapper/UserFormWrapper";
import PasswordInput from "components/elements/PasswordInput/PasswordInput";
import Button from "components/elements/Button/Button";
import s from "./ChangePasswordForm.module.scss";
import {useTranslation} from "react-i18next";

const ChangePasswordForm = ({ onSubmit, onClose }) => {
  const {t} = useTranslation();
  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      reenterPassword: "",
    },
    validationSchema: object({
      password: string().required("Old password field can’t be blank"),
      newPassword: string()
        .required("Please enter your New Password")
        .min(8, "New password must have 8 characters minimum")
        .max(32, "Max length is 32 characters")
        .matches(/[a-z]{1}/, "Please use at least 1 lower case character")
        .matches(/[A-Z]{1}/, "Please use at least 1 upper case character")
        .matches(/(?=.*[0-9])/, "Please use at least 1 number")
        .matches(
          /(?=.*[!@#\$%\^&\*])/,
          "Please use at least 1 special character"
        )
        .notOneOf(
          [ref("password"), null],
          "New password can't be the same as your old one"
        ),
      reenterPassword: string()
        .required("Please retype your New Password")
        .oneOf(
          [ref("newPassword"), null],
          "Password verification doesn’t match new password"
        ),
    }),
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      onSubmit(values).catch((error) => {
        if (error.response.data.error === "Wrong old password") {
          setFieldError("password", "Old password is incorrect");
          setSubmitting(false);
        }
      });
    },
  });

  const { handleSubmit, touched, values, errors, setFieldValue } = formik;

  return (
    <>
      <UserFormWrapper>
        <h1 className={s.title} data-translate-key="change-your-password">
          {t("change-your-password")}</h1>

        <p className={s.description} data-translate-key="a-strong-password-helps-prevent-unauthorized-access">
          {t("a-strong-password-helps-prevent-unauthorized-access")}
        </p>

        <form className={s.form} onSubmit={handleSubmit}>
          <div className={s.inputWrap}>
            <PasswordInput
              placeholder="Enter your current password"
              onChange={(value) => setFieldValue("password", value)}
              value={values.password}
              label="Current password"
              error={touched.password && errors.password}
            />
          </div>

          <div className={s.inputWrap}>
            <PasswordInput
              placeholder="Enter your new password"
              onChange={(value) => setFieldValue("newPassword", value)}
              value={values.newPassword}
              label="New password"
              error={touched.newPassword && errors.newPassword}
            />
            <p className={s.passwordState} data-translate-key="8-characters-minimum">
              {t("8-characters-minimum")}</p>
          </div>

          <div className={s.inputWrap}>
            <PasswordInput
              placeholder="Enter your new password"
              onChange={(value) => setFieldValue("reenterPassword", value)}
              value={values.reenterPassword}
              label="Reenter password"
              error={touched.reenterPassword && errors.reenterPassword}
            />
          </div>

          <div className={s.buttonsWrap}>
            <Button styled="secondary" type="reset" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button styled="primary" type="submit" className={s.saveButton}>
              Save
            </Button>
          </div>
        </form>
      </UserFormWrapper>
    </>
  );
};

export default ChangePasswordForm;
