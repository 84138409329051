import axios from "./axios";

export default {
  getSavingsTypeList: async () => {
    try {
      return await axios.get("/savings_types");
    } catch (error) {
      throw error;
    }
  },
  getSavingsType: async () => {
    try {
      return await axios.get("/admin/savings_types");
    } catch (error) {
      throw error;
    }
  },
  createSavingsType: async params => {
    try {
      return await axios.post("/admin/savings_types", params);
    } catch (error) {
      throw error;
    }
  },
  editSavingsType: async (id, params) => {
    try {
      return await axios.put(`/admin/savings_types/${id}`, params);
    } catch (error) {
      throw error;
    }
  },
  deleteSavingsType: async (id, typeId) => {
    try {
      return await axios.delete(`/admin/savings_types/${id}?savings_type_id=${typeId}`);
    } catch (error) {
      throw error;
    }
  },
  restoreSavingsType: async id => {
    try {
      return await axios.put(`/admin/savings_types/${id}/restore`);
    } catch (error) {
      throw error;
    }
  },
};
