import React from "react";
import {string} from "yup";
import FormConstructor from "../../elements/FormConstructor/FormConstructor";
import PropTypes from "prop-types";

const RequestTypesStepForm = ({onSubmit, defaultValues, onCLose}) => {

  return (
    <FormConstructor
      submitBtn={{
        styled: "primary",
        title: defaultValues ? "Save" : "Create",
      }}
      cancelBtn={{
        styled: "secondary",
        title: "Cancel",
        onClick: () => onCLose()
      }}
      fields={[
        {
          type: "text",
          title: "Step name",
          placeholder: "Enter step name",
          limitValue: 50,
          required: string()
            .required("Step name is required")
            .max(50, "Please enter step name that consists of no more than 50 characters"),
          key: "name",
          value: defaultValues?.name || "",
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default RequestTypesStepForm;

RequestTypesStepForm.propTypes = {
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.any,
  onCLose: PropTypes.func,
};