import React, {useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "components/elements/Button/Button";
import {ReactComponent as Close} from "assets/icons/close.svg";
import s from "./Popup.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslate} from "../../../hooks/useTranslate";
import {ReactComponent as WarningIcon} from "../../../assets/icons/warning-icon.svg";

const Popup = (
  {
    title,
    text,
    onClick,
    onClose,
    children,
    dialog,
    successBtnTitle,
    cancelBtnTitle,
    isDelete,
    isLeave,
    className,
    isDisableSuccess = false,
    errorPopUp,
    fullText = false,
    subText,
  }
) => {
  const [big, setBig] = useState(false);
  const [translateKey, translateValue] = useTranslate(text);
  const [translateKeySub, translateValueSub] = useTranslate(subText);
  const popupRef = useRef(null);
  const {t} = useTranslation();
  useEffect(() => {
    popupRef.current.clientHeight > window.innerHeight
      ? setBig(true)
      : setBig(false);
  }, []);

  return (
    <div
      className={s.overlay}
      style={{alignItems: big ? "flex-start" : "center"}}
    >
      <div
        className={classNames(s.popup, {[s.dialogPopup]: dialog, [s.errorPopup]: errorPopUp}, className)}
        ref={popupRef}
      >
        <div className={classNames(s.titleWrap, {[s.dialogTitle]: dialog})}>
          {(dialog && isDelete) && <WarningIcon/>}
          <h1 className={classNames(s.title)} data-translate-key={getTranslateKey(title)}>
            {t(getTranslateKey(title))}
          </h1>
        </div>
        <p
          className={classNames(s.text, {
            [s.dialogText]: dialog,
            [s.fullText]: fullText,
            [s.deletePopup]: isDelete || isLeave
          })}
          data-translate-key={translateKey}
        >
          {translateValue}
        </p>
        {subText &&
        <p
          className={classNames(s.text, {
              [s.dialogText]: dialog,
              [s.fullText]: fullText,
              [s.deletePopup]: isDelete || isLeave
              })}
          data-translate-key={translateKeySub}
        >
          {translateValueSub}
        </p>}

        {children && <div className={s.wrapChildren}>{children}</div>}

        {dialog && (
          <div className={s.buttonsBlock}>
            <Button styled='secondary' className={s.button} onClick={onClose}>
              {cancelBtnTitle}
            </Button>

            <Button styled='primary' className={s.button} onClick={onClick} disabled={isDisableSuccess}
                    data-translate-key={getTranslateKey(successBtnTitle)}>
              {t(getTranslateKey(successBtnTitle))}
            </Button>
          </div>
        )}
        <Close className={s.closeButton} onClick={onClose}/>
      </div>
    </div>
  );
};

Popup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  dialog: PropTypes.bool,
  successBtnTitle: PropTypes.string,
  cancelBtnTitle: PropTypes.string,
  isDelete: PropTypes.bool,
  isLeave: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.element,
  subText: PropTypes.string,
};

Popup.defaultProps = {
  title: "",
  text: "",
  onClick: () => {
  },
  onClose: () => {
  },
  dialog: false,
  successBtnTitle: "Delete",
  cancelBtnTitle: "Cancel",
  subText: "",
};

export default Popup;
