import React, {useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import style from './EditPriorityForm.module.scss';
import {ReactComponent as DragIcon} from '../../../assets/icons/drag-icon.svg';
import Button from "../../elements/Button/Button";

const EditPriorityForm = ({onClose, updateSettings, preparedList}) => {
  const [fields, setFields] = useState(preparedList);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(fields, result.source.index, result.destination.index);
    setFields(items);
  }

  return (
    <div>
      <div className={style.content}>
        <div className={style.labels}>
          <div><span className={style.statusIcon}/>Highest</div>
          <div><span className={style.statusIcon}/>High</div>
          <div><span className={style.statusIcon}/>Medium</div>
          <div><span className={style.statusIcon}/>Low</div>
        </div>
        <DragDropContext
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef}{...provided.droppableProps} className={style.dragEndDropWrap}>
                {fields.map((field, index) => (
                  <Draggable key={field} draggableId={`${field}`} index={index}>
                    {
                      provided =>
                        <div
                          className={style.statusItemWrapper}
                          style={provided.draggableProps.style}
                          ref={provided.innerRef}
                          data-drag-item="drag"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DragIcon/>
                          <div className={style.statusItem}>
                            {field.replace('_', ' ')}
                          </div>
                        </div>
                    }
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={style.buttonsWrap}>
        <Button styled="secondary" type="reset" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button styled="primary" className={style.saveButton} onClick={e=>{
          e.preventDefault();
          updateSettings(fields);
          onClose();
        }}>
          Save
        </Button>
      </div>
    </div>
  )
}


export default EditPriorityForm;
