export const formatData = (data, lvl = 1, currentUser, leader) => {
  const userId = currentUser?.id;
  const isLeader = (userId === data.data?.id || leader) || false;
  return {
    ...data.data,
    lvl,
    isOpenCard: false,
    isLeader,
    childrens: data.users?.map(user => formatData(user, lvl + 1, currentUser, isLeader))
  };
};