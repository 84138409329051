import React from "react";
import PropTypes from "prop-types";
import styles from "./Avatar.module.scss";
import gradient from "random-gradient";
import classNames from "classnames";

const Avatar = ({name, size = "default"}) => {
  const nameParts = name && name.split(" ").filter(item => item);

  if (!nameParts) return "";

  return (
    <div className={classNames(styles.gradient, styles[size])} style={{background: gradient(name)}}>
      {nameParts.length > 1 ? nameParts[0][0] + nameParts[1][0] : nameParts[0][0]}
    </div>
  );
};

export default Avatar;

Avatar.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string
};