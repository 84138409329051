import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navigation from "./components/Navigation/Navigation";
import { setCategories } from "actions/categories";

const NavigationContainer = () => {
  const dispatch = useDispatch();
  const { currentCategoryID, categories } = useSelector(
    (state) => state.categories
  );
  useEffect(() => {
    !categories.length && dispatch(setCategories());
  }, []);
  return (
    <Navigation categories={categories} currentCategoryId={currentCategoryID} />
  );
};

export default NavigationContainer;
