import React from 'react'
import DefaultLayout from 'components/layouts/DefaultLayout/DefaultLayout'
import CompanyGoalContainer from 'components/containers/CompanyGoalContainer/CompanyGoalContainer'
import { paths } from 'routes/paths'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'

const CompanyGoalPage = () => {
	const history = useHistory()
	const query = qs.parse(history.location.search)
	return (
		<DefaultLayout
			breadcrumbs={[
				{
					path: `${paths.companyGoals}?years=2021`,
					name: 'Company goals'
				},
				{
					name: `${query.source?.[0].toUpperCase() + query.source?.slice(1) ||
						'Company'} details`
				}
			]}
		>
			<CompanyGoalContainer source={query.source} withLink={false} />
		</DefaultLayout>
	)
}

export default CompanyGoalPage
