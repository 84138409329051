import { SET_NOTIFICATIONS_COUNT } from "helpers/constants";

let initialState = {
  regular_count: {
    all: 0,
    new: 0,
  },
  business_count: {
    all: 0,
    new: 0,
  },
};

const notifications = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_NOTIFICATIONS_COUNT: {
      return { ...state, ...payload };
    }

    default: {
      return state;
    }
  }
};

export default notifications;
