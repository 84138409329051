import React from "react";
import IncCard from "./IncCard/IncCard";
import s from "./IncreasingProcurement.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const increasesList = [
  "Opportunities (structured process to identify opportunities)",
  "Prioritization (decide which projects to take on and when)",
  "Stakeholder alignment (ensure common goals are agreed upon)",
  "Visibility (project status, resource utilization, client satisfaction)",
];

const decreasesList = [
  "Manual processes (systematized, workflow enabled)",
  "Interfaces (single portal for statuses and updates)",
  "Project uncertainty (know where each project is)",
  "Report timing (one click reporting)",
];

const IncreasingProcurement = () => {
  const {t} = useTranslation();
  return (
    <section className={s.increasingProcurement}>
      <div className={s.landingContainer}>
        <h2 className={s.title}
            data-translate-key={"increasing-procurement-roi"}> {t(getTranslateKey("increasing-procurement-roi"))}</h2>

        <p className={s.text}
           data-translate-key={"focal-point-automates-and-formalizes-procurement-best-practices-in-a-simple-to-use-solution-to-develop-strategies-and-plans-for-maximum-value-creation."}>
          {t(getTranslateKey("focal-point-automates-and-formalizes-procurement-best-practices-in-a-simple-to-use-solution-to-develop-strategies-and-plans-for-maximum-value-creation."))}
        </p>

        <div className={s.cards}>
          <IncCard title="Increases" list={increasesList}/>
          <IncCard title="Decreases" list={decreasesList}/>
        </div>
      </div>
    </section>
  );
};

export default IncreasingProcurement;
