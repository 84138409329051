import React from "react";
import Popup from "components/popups/Popup/Popup";
import ChangeAdminApprovalGroupForm from "components/forms/ChangeAdminApprovalGroupForm/ChangeAdminApprovalGroupForm";

const EditGroupPopup = ({
  onClose,
  onSubmit,
  defaultValues,
  approvers,
  departments,
}) => {
  return (
    <Popup
      onClose={onClose}
      title="Edit approval group"
      text="  Please, complete the following fields to add an approval group"
    >
      <ChangeAdminApprovalGroupForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        departments={departments}
        approvers={approvers}
      />
    </Popup>
  );
};
export default EditGroupPopup;
