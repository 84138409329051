import axios from "./axios";

export default {
  getRequests: async params => {
    try {
      return await axios.get(`/requests`, {params});
    } catch (error) {
      throw error;
    }
  },
  getCategoryOverview: async id => {
    try {
      return await axios.get(`/procurement_requests/${id}/category_overview`);
    } catch (error) {
      throw error;
    }
  },
  getPreferredSuppliers: async id => {
    try {
      return await axios.get(`/procurement_requests/${id}/preferred_suppliers`);
    } catch (error) {
      throw error;
    }
  },
  getCategoryProjects: async id => {
    try {
      return await axios.get(`/procurement_requests/${id}/category_projects`);
    } catch (error) {
      throw error;
    }
  },
  getCategoryStakeholders: async id => {
    try {
      return await axios.get(`/procurement_requests/${id}/category_stakeholders`);
    } catch (error) {
      throw error;
    }
  },
  getRequestUsers: async (id, params) => {
    try {
      return await axios.get(`/procurement_requests/${id}/users`, {params});
    } catch (error) {
      throw error;
    }
  },
  getRequest: async (id) => {
    try {
      return await axios.get(`/procurement_requests/${id}`);
    } catch (error) {
      throw error;
    }
  },
  addRequest: async (values) => {
    try {
      return await axios.post(`/procurement_requests`, values);
    } catch (error) {
      throw error;
    }
  },
  changeRequest: async (values, id, config) => {
    try {
      return await axios.put(`/procurement_requests/${id}`, values, config);
    } catch (error) {
      throw error;
    }
  },
  deleteRequest: async (id, config) => {
    try {
      return await axios.delete(`/procurement_requests/${id}`, config);
    } catch (error) {
      throw error;
    }
  },
  getFilters: async () => {
    try {
      return await axios.get(`/procurement_requests_filters`);
    } catch (error) {
      throw error;
    }
  },
  getRequestFilters: async () => {
    try {
      return await axios.get(`/request_filters`);
    } catch (error) {
      throw error;
    }
  },
  addRequestProject: async (values) => {
    try {
      return await axios.post(`/request_projects`, values);
    } catch (error) {
      throw error;
    }
  },
};
