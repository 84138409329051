import {
  SET_PROJECTS,
  ADD_PROJECT,
  DELETE_PROJECT,
  CURRENT_PROJECT,
  SET_POPUP,
  RESET_ALERT,
  SET_LOADING,
} from "./types";

export const initialState = {
  projects: [],
  projects_count: 0,
  loading: true,
  popup: null,
  current_project: null,
  alert: { type: "", message: "" },
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: payload.projects,
        projects_count: payload.projects_count,
        loading: false,
      };
    case ADD_PROJECT:
      return {
        ...state,
        projects: payload.project
          ? [payload.project, ...state.projects]
          : state.projects,
        projects_count: payload.project
          ? state.projects_count + 1
          : state.projects_count,
        alert: payload.alert,
        popup: null,
      };
    case DELETE_PROJECT:
      return {
        ...state,
        projects: payload.project_id
          ? state.projects.filter((e) => e.id !== state.current_project)
          : state.projects,
        projects_count: payload.project_id
          ? state.projects_count - 1
          : state.projects_count,
        alert: payload.alert,
        popup: null,
      };
    case CURRENT_PROJECT:
      return {
        ...state,
        current_project: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_POPUP:
      return {
        ...state,
        popup: payload,
      };
    case RESET_ALERT:
      return {
        ...state,
        alert: {
          type: "",
          message: "",
        },
      };
    default:
      return state;
  }
};
