import React, { useState, useEffect } from "react"
import TableLoading from "components/loadingStates/Table/Table"
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock"
import AdminSettingsProjectsGroupsTable from "components/tables/AdminSettingsProjectsGroupsTable/AdminSettingsProjectsGroupsTable"
import AddProjectGroupPopup from "./Popups/AddProjectGroupPopup/AddProjectGroupPopup"
import EditProjectGroupPopup from "./Popups/EditProjectGroupPopup/EditProjectGroupPopup"
import DeleteProjectGroupPopup from "./Popups/DeleteProjectGroupPopup/DeleteProjectGroupPopup"
import services from "services"
import NotificationBox from "components/elements/NotificationBox/NotificationBox"

const Groups = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [popup, setPopup] = useState(null)
  const [currentGroup, setCurrentGroup] = useState(null)
  const [alert, setAlert] = useState({ message: "", type: "" })

  useEffect(() => {
    services.adminProjectsServices.getProjectGroups().then(res => {
      setData(res?.data || [])
      setLoading(false)
    })
  }, [])

  const addHandler = values => {
    services.adminProjectsServices
      .addProjectGroup(values)
      .then(res => {
        setData([...data, res.data])
        setPopup(null)
        setAlert({
          type: "success",
          message: "Project group successfully added."
        })
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Something went wrong. Please, try again."
        })
      })
  }
  const editHandler = values => {
    services.adminProjectsServices
      .changeProjectGroup(currentGroup?.id, values)
      .then(res => {
        setData(data.map(e => (e.id === res?.data?.id ? res.data : e)))
        setPopup(null)
        setAlert({
          type: "success",
          message: "Project group successfully changed."
        })
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Something went wrong. Please, try again."
        })
      })
  }
  const deleteHandler = () => {
    services.adminProjectsServices
      .deleteProjectGroup(currentGroup?.id)
      .then(() => {
        setData(data.filter(e => e.id !== currentGroup.id))
        setPopup(null)
        setAlert({
          type: "success",
          message: "Project group successfully deleted."
        })
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Something went wrong. Please, try again."
        })
      })
  }

  return (
    <>
      {loading && <TableLoading length={2} />}
      {!loading && Boolean(data.length) && (
        <AdminSettingsProjectsGroupsTable
          data={data}
          onAdd={() => setPopup("add")}
          onEdit={group => {
            setCurrentGroup(group)
            setPopup("edit")
          }}
          onDelete={group => {
            setCurrentGroup(group)
            setPopup("delete")
          }}
        />
      )}
      {!loading && !Boolean(data.length) && (
        <EmptyBlock
          subtitle='You don’t have any project groups yet.'
          buttonTitle='New group'
          onClick={() => setPopup("add")}
        />
      )}
      {popup === "add" && (
        <AddProjectGroupPopup
          onClose={() => setPopup(null)}
          onSubmit={addHandler}
        />
      )}
      {popup === "edit" && (
        <EditProjectGroupPopup
          onClose={() => setPopup(null)}
          onSubmit={editHandler}
          defaultData={currentGroup}
        />
      )}
      {popup === "delete" && (
        <DeleteProjectGroupPopup
          onClose={() => setPopup(null)}
          onSubmit={() => {
            deleteHandler()
            setPopup(null)
          }}
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => setAlert({ message: "", type: "" })}
        />
      )}
    </>
  )
}

export default Groups
