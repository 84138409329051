import React, {useContext, useState} from "react";
import s from "./ConditionGroup.module.scss";
import DropListTrigger from "../../../../elements/DropListTrigger/DropListTrigger";
import PropTypes from "prop-types";
import RadioButtons from "../../../../elements/Fields/RadioButtons/RadioButtons";
import SingleCondition from "../SingleCondition/SingleCondition";
import {ModeContext} from "../../../../../contexts/ModeContext/ModeContext";
import {mods} from "../../../../../contexts/ModeContext/mods";

const ConditionGroup = (
  {
    idx,
    condition,
    deleteCondition,
    addSingleCondition,
    changeCondition,
    isLast,
    changeSingleCondition,
    deleteSingleCondition,
  }
) => {
  const {mode} = useContext(ModeContext);
  const isViewMode = mode === mods.view;
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);

  return (
    <>
      <div className={s.conditionGroup}>
        <div className={s.header}>
          <div className={s.groupName}>
            Conditions group {idx}
          </div>
          {
            !isViewMode && (
              <DropListTrigger
                data={[
                  {
                    title: "Delete",
                    onClick: () => deleteCondition(condition.id)
                  }
                ]}
                visibleMenu={visibleDropListMenu}
                setVisibleMenu={setVisibleDropListMenu}
              />
            )
          }

        </div>
        <div className={s.singleConditions}>
          {condition.condition_group.map((singleCondition, index) => {
            return (
              <SingleCondition
                key={singleCondition.id}
                singleCondition={singleCondition}
                changeSingleCondition={(singleConditionId, value, type) =>
                  changeSingleCondition(condition.id, singleConditionId, value, type)}
                deleteSingleCondition={singleConditionId => deleteSingleCondition(condition.id, singleConditionId)}
                isLast={index + 1 === condition.condition_group.length}
              />
            );
          })}
        </div>
        {
          !isViewMode && (
            <button className={s.addSingleCondition} onClick={e => {
              e.preventDefault();
              addSingleCondition(condition.id);
            }}>
              + Add new condition
            </button>
          )
        }

      </div>
      {!isLast && (
        <RadioButtons
          value={condition.condition}
          isViewMode={isViewMode}
          change={value => changeCondition(condition.id, value)}
        />
      )}
    </>
  );
};

export default ConditionGroup;

ConditionGroup.propTypes = {
  idx: PropTypes.number,
  condition: PropTypes.any,
  deleteCondition: PropTypes.func,
  addSingleCondition: PropTypes.func,
  isLast: PropTypes.bool,
  changeCondition: PropTypes.func,
  changeSingleCondition: PropTypes.func,
  deleteSingleCondition: PropTypes.func,
};