import React, {useState} from "react";
import querystring from "query-string";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import s from "./ProjectTabs.module.scss";
import FinancialDetails from "../FinancialDetails/FinancialDetails";
import SuppliersContainer from "../Suppliers/SuppliersContainer";
import StakeholdersContainer from "../Stakeholders/StakeholdersContainer";
import Attachments from "../Attachments/Attachments";
import MilestonesContainer from "../Milestones/MilestonesContainer";
import LinkedProjectsContainer from "../LinkedProjects/LinkedProjectsContainer";
import ProjectResults from "../ProjectResults/ProjectResults";
import ContractsContainer from "../Contracts/ContractsContainer";
import SurveyContainer from "../Survey/SurveyContainer";
import Tabs from "components/elements/Tabs/Tabs";

const ProjectTabs = ({data = {}}) => {
  const organizationName = useSelector(state => state.user.organization.name)
  const location = useLocation()
  const {tab} = querystring.parse(location.search)
  const [serveySend, setServeySent] = useState(data.survey_sent || false)

  return (
    <div className={s.projectTabs}>
      <Tabs defaultActiveTab={tab}>
        <div label='Financial details'>
          <FinancialDetails
            ownerId={data.owner?.id}
            comment={data.financial_note}
            endDate={data.end_date}
            project={data}
          />
        </div>
        <div label='Suppliers'>
          <SuppliersContainer/>
        </div>
        <div label='Contracts' disabled={organizationName === "Indeed"}>
          <ContractsContainer/>
        </div>
        <div label='Milestones'>
          <MilestonesContainer project={data}/>
        </div>
        <div label='Linked Projects'>
          <LinkedProjectsContainer/>
        </div>
        <div label='Stakeholders'>
          <StakeholdersContainer
            ownerId={data.owner?.id}
            isSurveySent={data?.survey_sent || serveySend}
            setServeySent={setServeySent}
          />
        </div>
        <div label='Attachments'>
          <Attachments/>
        </div>
        <div label='Project Results'>
          <ProjectResults data={data}/>
        </div>
        <div label='Survey' disabled={!data.survey_result}>
          <SurveyContainer
            ownerId={data.owner?.id}
            setServeySent={setServeySent}
          />
        </div>
      </Tabs>
    </div>
  )
}

export default ProjectTabs
