import React from "react";
import Popup from "components/popups/Popup/Popup.jsx";
import ChangeProjectStageTaskForm from 'components/forms/ChangeProjectStageTaskForm/ChangeProjectStageTaskForm'

const AddTaskPopup = ({ onClose, onSubmit }) => {
  return (
    <Popup title='New task' text='Please, complete the following fields to create a new stage task' onClose={onClose}>
      <ChangeProjectStageTaskForm onClose={onClose} onSubmit={onSubmit} />
    </Popup>
  );
};

export default AddTaskPopup;
