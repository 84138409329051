import React from "react";
import sBase from '../Dashboard.module.scss';
import s from "./Goals.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";

const GoalsLoading = () => {
  return (
    <div className={classNames(sBase.container, s.goalsContainer)}>
      <Skeleton
        variant="text"
        className={s.title}
        animation="wave"
        width={200}
      />
      <div className={s.footer}>
        <Skeleton
          variant="text"
          className={s.item}
          animation="wave"
          width={100}
        />
        <Skeleton
          variant="text"
          className={s.item}
          animation="wave"
          width={100}
        />
      </div>
    </div>
  );
};

export default GoalsLoading;


