import React, {Fragment, useReducer, useEffect, useState} from "react";
import {useParams, useHistory, useLocation} from "react-router-dom";
import CategoriesSuppliersTable from "components/tables/CategoriesSuppliersTable/CategoriesSuppliersTable";
import {CircularProgress} from "@material-ui/core";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import NotificationBox from "components/elements/NotificationBox/NotificationBox";
import Popup from "components/popups/Popup/Popup";
import CategorySupplierChangeStatusForm
  from "components/forms/CategorySupplierChangeStatusForm/CategorySupplierChangeStatusForm";
import AddCategorySupplierForm from "components/forms/AddCategorySupplierForm/AddCategorySupplierForm";
import querystring from "query-string";
import {useSelector} from "react-redux";
import {
  setSuppliers,
  setLoading,
  addSupplier,
  deleteSupplier,
  changeStatusSupplier,
  closePopup,
  openPopup,
  setSelectedSupplier,
  setAlert
} from "./helpers/actions";
import {initialState, reducer} from "./helpers/reducer";
import {popupTypes} from "./helpers/types";
import services from "services";
import TableLoading from "components/loadingStates/Table/Table";
import CategorySuppliersFilter from "components/filters/CategorySuppliersFilter/CategorySuppliersFilter";
import FilterBarLoading from "components/loadingStates/FilterBar/FilterBar";
import styles from "./Suppliers.module.scss";
import {userRoles} from "../../../../../../../helpers/userPermissions/userRoles";

const Suppliers = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchSuppliers, setSearchSuppliers] = useState([]);
  const {
    suppliers,
    suppliers_count,
    loading,
    popup,
    current_supplier,
    alert
  } = state;
  const {currentCategoryID} = useSelector(state => state.categories);

  const {roles} = useSelector(state => state.user);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const query = querystring.parse(history.location.search);

  useEffect(() => {
    if (!location.search) {
      query.sort_by = "spend";
      query.direction = "desc";
    }
    dispatch(setLoading(true));
  }, [params.id]);

  useEffect(() => {
    setSuppliers(dispatch, params.id || currentCategoryID, query);
  }, [location.search, params.id]);

  const searchHandler = term => {
    services.supplierServices
      .suppliersAutocomplete({query: term})
      .then(res => setSearchSuppliers(res.data));
  };

  const onDeleteTag = async (tagId, supplierId) =>
    await services.tagsServices.deleteSupplierTag(supplierId, tagId);

  const onAssignTag = async (tagId, supplierId) =>
    await services.tagsServices.assignSupplierTag(supplierId, tagId);

  return (
    <Fragment>
      {loading ? (
        <FilterBarLoading className={styles.filter}/>
      ) : (
        <CategorySuppliersFilter className={styles.filter}/>
      )}
      {loading && <TableLoading length={4}/>}
      {suppliers.length > 0 && !loading && (
        <CategoriesSuppliersTable
          data={suppliers}
          totalCount={suppliers_count}
          perPage={20}
          onAdd={() => dispatch(openPopup(popupTypes.add))}
          onDelete={item => {
            dispatch(setSelectedSupplier(item));
            dispatch(openPopup(popupTypes.delete));
          }}
          onChangeStatus={item => {
            dispatch(setSelectedSupplier(item));
            dispatch(openPopup(popupTypes.change_status));
          }}
          onDeleteTag={onDeleteTag}
          onAssignTag={onAssignTag}
        />
      )}
      {!state.suppliers.length && !loading && (
        <EmptyBlock
          subtitle="There are no suppliers in this category yet. You can add a new one"
          buttonTitle="Add supplier"
          withBtn={roles === userRoles.admin ? true : false}
          onClick={() => dispatch(openPopup(popupTypes.add))}
        />
      )}
      {loading && (
        <div className="loaderWrap justifyCenter alignCenter">
          <CircularProgress/>
        </div>
      )}
      {popup === popupTypes.add && (
        <Popup
          onClose={() => dispatch(closePopup())}
          title="Add supplier"
          text="Choose a supplier from existing ones"
        >
          <AddCategorySupplierForm
            suppliers={searchSuppliers}
            currentSuppliers={suppliers}
            addHandler={values => addSupplier(dispatch, values)}
            onClose={() => dispatch(closePopup())}
            onSearch={searchHandler}
          />
        </Popup>
      )}
      {popup === popupTypes.change_status && (
        <Popup
          onClose={() => dispatch(closePopup())}
          title="Change status"
          text="Change supplier status"
        >
          <CategorySupplierChangeStatusForm
            status={current_supplier.status}
            onSubmit={values =>
              changeStatusSupplier(dispatch, current_supplier.id, values)
            }
            onClose={() => dispatch(closePopup())}
          />
        </Popup>
      )}
      {popup === popupTypes.delete && (
        <Popup
          title="Delete supplier"
          text={`Are you sure you want to delete the "${current_supplier?.supplier?.name}" supplier?`}
          onClose={() => dispatch(closePopup())}
          onClick={() =>
            deleteSupplier(dispatch, current_supplier?.supplier?.id)
          }
          dialog
          isDelete
        />
      )}
      {alert.message && (
        <NotificationBox
          message={alert.message}
          type={alert.type}
          disappearTime={5}
          onClose={() => dispatch(setAlert({type: "", message: ""}))}
        />
      )}
    </Fragment>
  );
};
export default Suppliers;
