export const savingsData = [
  {
    label: "Years",
    value: "years",
  },
  {
    label: "Quarters",
    value: "quarters",
  },
];

export const whenRuleData = [
  {
    label: "Over allowance time",
    value: "over_allowance_time",
  },
  {
    label: "Upfront",
    value: "upfront",
  },
];