import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";
import s from "../../ProjectRequestDetailContainer.module.scss";
import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";

export const RequestHeader = ({loading, data}) => {
  const {t} = useTranslation();
  const status = `${data.status[0].toUpperCase()}${data.status.slice(1)}`.replace("_", " ");

  if (loading) return (
    <Skeleton
      variant='text'
      className={classNames("title", s.title)}
      animation='wave'
      width={200}
    />
  );

  return (
    <h2 className={s.title}>
      {data && data.name}
      <span className={classNames(s.status, s[data.status])} data-translate-key={getTranslateKey(status)}>
          {t(getTranslateKey(status))}
        </span>
    </h2>
  );
};


RequestHeader.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.any
};