import React, {useState, useEffect, useContext} from "react";
import {useParams, useHistory} from "react-router-dom";
import classNames from "classnames";
import DropListTrigger from "components/elements/DropListTrigger/DropListTrigger";
import ProjectInfo from "./components/ProjectInfo/ProjectInfo";
import s from "./ProjectContainer.module.scss";
import services from "services";
import qs from "query-string";
import ProjectTabs from "./components/ProjectTabs/ProjectTabs";
import DeleteProjectPopup from "components/popups/DeleteProjectPopup/DeleteProjectPopup";
import Can from "components/elements/Can/Can";
import AlertContext from "contexts/AlertContext/AlertContext";
import {formatDate} from "helpers/dateFormat";
import Skeleton from "@material-ui/lab/Skeleton";
import {useSelector} from "react-redux";

const ProjectContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [visibleDeletePopup, setVisibleDeletePopup] = useState(false);
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const {setAlert, getCancelId} = useContext(AlertContext);
  const [project, setProject] = useState({});
  const currentYear = String(new Date().getFullYear());
  const user = useSelector(state => state.user);
  useEffect(() => {
    services.projectServices.getProject(params.id).then(res => {
      setProject(res.data);
      setLoading(false);
    });
  }, [params.id]);

  const handleEdit = data => {
    const oldData = {
      category_id: project.category.id,
      owner_id: project.owner.id,
      description: project.description,
      end_date: formatDate(project.end_date),
      name: project.name,
      project_type: project.project_type,
      start_date: formatDate(project.start_date),
      status: project.status
    };
    services.projectServices
      .editProject(params.id, data)
      .then(res => {
        setProject(res.data);
        setAlert({
          message: "Project details successfully edited",
          onCancel: () => {
            services.projectServices
              .editProject(params.id, oldData)
              .then(res => setProject(res.data));
          }
        });
      })
      .catch(() => {
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };

  const handleDelete = () => {
    const cancelId = getCancelId();
    setDisableDeleteBtn(true);
    services.projectServices
      .deleteProject(params.id, {cancelId})
      .then(() => {
        history.push({
          pathname: "/projects",
          state: {
            success: true,
            projectName: project.name,
            cancelId
          },
          search: qs.stringify({
            years: currentYear,
            owner_ids: user.id,
            tab: "Board"
          })
        });
      })
      .catch(() => {
        history.push({
          pathname: "/projects",
          state: {success: false},
          search: qs.stringify({
            years: currentYear,
            owner_ids: user.id,
            tab: "Board"
          })
        });
      });
  };
  const handleDeleteTag = async tagId =>
    await services.tagsServices.deleteProjectTag(params.id, tagId).then(() => {
      setProject({
        ...project,
        tags: project.tags.filter(e => e.id !== tagId)
      });
      setAlert({
        type: "success",
        message: "Tag successfully deleted"
      });
    });
  const handleAssignTag = async (tagId, projectId) =>
    await services.tagsServices.assignProjectTag(projectId, tagId).then(res => {
      setProject({
        ...project,
        tags: res.data.tags
      });
      setAlert({
        type: "success",
        message: "Tag successfully assigned."
      });
    });

  return (
    <section className={s.projectContainer}>
      <div
        className={s.projectControl}
        onMouseLeave={() => setVisibleDropListMenu(false)}
      >
        {!project?.name ? (
          <Skeleton
            variant="text"
            className={classNames("title", s.title)}
            animation="wave"
            width={200}
          />
        ) : (
          <h2 className={s.title}>{project.name} <span className={s.titleID}>(ID{project.uid})</span></h2>
        )}

        <Can
          permission={project.permissions?.destroy || false}
          yes={() => (
            <DropListTrigger
              data={[
                {
                  title: "Delete",
                  onClick: () => setVisibleDeletePopup(true)
                }
              ]}
              visibleMenu={visibleDropListMenu}
              setVisibleMenu={setVisibleDropListMenu}
            />
          )}
        />
      </div>

      <ProjectInfo
        details={project}
        onEditDetails={handleEdit}
        onDeleteTag={handleDeleteTag}
        onAssignTag={(tagId) => handleAssignTag(tagId, project.id)}
      />
      <ProjectTabs data={project} onEdit={handleEdit}/>

      {visibleDeletePopup && (
        <DeleteProjectPopup
          project={project}
          isDisableDeleteBtn={disableDeleteBtn}
          onClose={() => setVisibleDeletePopup(false)}
          onClick={handleDelete}
        />
      )}
    </section>
  );
};

export default ProjectContainer;
