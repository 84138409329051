import {
  SET_GLOBAL_PROJECT,
  SET_GLOBAL_PROJECT_MESSAGE,
  DELETE_GLOBAL_PROJECT,
  ADD_GLOBAL_PROJECT_SUPPLIER,
  ADD_GLOBAL_PROJECT_CONTRACT,
  ADD_GLOBAL_PROJECT_USER,
  DELETE_GLOBAL_PROJECT_CONTRACT,
  DELETE_GLOBAL_PROJECT_SUPPLIER,
  DELETE_GLOBAL_PROJECT_USER,
  ADD_GLOBAL_PROJECT,
  EDIT_GLOBAL_PROJECT
} from "helpers/constants";

export const setGlobalEmptyProject = name => {
  return async dispatch => {
    dispatch({
      type: SET_GLOBAL_PROJECT,
      payload: name
    });
  };
};

export const setGlobalProjectMessage = message => {
  return async dispatch => {
    dispatch({
      type: SET_GLOBAL_PROJECT_MESSAGE,
      payload: message
    });
  };
};

export const deleteGlobalProject = () => {
  return async dispatch => {
    dispatch({
      type: DELETE_GLOBAL_PROJECT
    });
  };
};

export const addGlobalProjectSupplier = supplier => {
  return async dispatch => {
    dispatch({
      type: ADD_GLOBAL_PROJECT_SUPPLIER,
      payload: supplier
    });
  };
};

export const addGlobalProjectContract = contract => {
  return async dispatch => {
    dispatch({
      type: ADD_GLOBAL_PROJECT_CONTRACT,
      payload: contract
    });
  };
};

export const addGlobalProjectUser = user => {
  return async dispatch => {
    dispatch({
      type: ADD_GLOBAL_PROJECT_USER,
      payload: user
    });
  };
};

export const deleteGlobalProjectContract = id => {
  return async dispatch => {
    dispatch({
      type: DELETE_GLOBAL_PROJECT_CONTRACT,
      payload: id
    });
  };
};

export const deleteGlobalProjectSupplier = id => {
  return async dispatch => {
    dispatch({
      type: DELETE_GLOBAL_PROJECT_SUPPLIER,
      payload: id
    });
  };
};

export const deleteGlobalProjectUser = id => {
  return async dispatch => {
    dispatch({
      type: DELETE_GLOBAL_PROJECT_USER,
      payload: id
    });
  };
};

export const addGlobalProject = values => {
  return async dispatch => {
    dispatch({
      type: ADD_GLOBAL_PROJECT,
      payload: values
    });
  };
};

export const editGlobalProject = values => {
  return async dispatch => {
    dispatch({
      type: EDIT_GLOBAL_PROJECT,
      payload: values
    });
  };
};
