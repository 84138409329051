import React from "react";
import AsyncSelect from "react-select/async";
import s from "./RSAsyncSelect.module.scss";
import {RSCustomStyle} from "./selectStyle";
import {DropdownIndicator} from "./components/DropdownIndicator";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import Label from "../Label/Label";

const RSAsyncSelect = (
  {
    load,
    className,
    label,
    onChange,
    defaultValue,
    isMulti = false,
    placeholder = "",
    error,
    optional = false,
  }
) => {
  const {t} = useTranslation();

  return (
    <div className={className}>
      {label && <Label label={label} optional={optional}/>}
      <AsyncSelect
        isMulti={isMulti}
        loadOptions={load}
        onChange={item => onChange(item)}
        isClearable
        defaultOptions
        defaultValue={defaultValue}
        placeholder={t(getTranslateKey(placeholder)) ?? "..."}
        components={{DropdownIndicator}}
        styles={RSCustomStyle}
      />
      {error && <span className={s.errorMessage}>{error}</span>}
    </div>
  );
};

export default RSAsyncSelect;