import React, {useEffect, useState, Fragment, useContext, useRef} from "react";
import s from "./NotesTable.module.scss";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import Popup from "components/popups/Popup/Popup";
import AddProjectNoteForm from "components/forms/AddProjectNoteForm/AddProjectNoteForm";
import services from "services";
import {cancelRequest} from "services/axios";
import AlertContext from "contexts/AlertContext/AlertContext";
import TableLoading from "components/loadingStates/Table/Table";
import MainTable from "../../../../tables/MainTable/MainTable";
import classNames from "classnames";
import {fromDate} from "../../../../../helpers/dateFormat";
import {ReactComponent as AttachmentIcon} from "../../../../../assets/icons/attachment.svg";
import {getFileIconByType} from "../../../../../helpers/fileIconsByType";
import EditProjectNoteForm from "../../../../forms/EditProjectNoteForm/EditProjectNoteForm";
import {useHistory, useLocation} from "react-router-dom";
import querystring from "query-string";

const NotesTable = ({id}) => {
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [notesCount, setNotesCount] = useState([]);
  const {setAlert, getCancelId} = useContext(AlertContext);
  const [show, setShow] = useState({delete: false, edit: false, add: false});
  const [note, setNote] = useState({});
  const history = useHistory();
  const location = useLocation();
  const [visibleAttachmentsPopupId, setVisibleAttachmentsPopupId] = useState(
    null
  );
  const {page} = querystring.parse(location.search);
  const attachmentsRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    loadNotes();
  }, [location.search]);

  const loadNotes = () => {
    services.noteServices.getNotes(id, page || 1).then(res => {
      setNotes(res.data.notes || []);
      setNotesCount(res.data.notes_count);
      setLoading(false);
    });
  };

  const onPageChange = page => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  const appendFile = file => {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
  };

  const addFileToContractNote = (attachments, note) => {
    for (let i = 0; i < attachments?.length; i++) {
      const file = appendFile(attachments?.[i]);
      note.id &&
      services.taskServices.addAttachment(file, note.id).then(res => {
        const attachment = res?.data;
        attachment && setNotes(notes =>
          notes.map(e =>
            e.id === note.id
              ? {
                ...e,
                attachments: [...e.attachments, attachment]
              }
              : e
          )
        );
      });
    }
  };

  const addHandler = ({attachments, ...spread}) => {
    services.noteServices
      .createNote(id, {...spread})
      .then(res => {
        const note = res?.data;
        setNotes([...notes, note]);
        addFileToContractNote(attachments, note);
        setAlert({
          message: "Note successfully added."
        });
        loadNotes();
      })
      .catch(() =>
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        })
      );
  };

  const editHandler = (noteId, {attachments, ...spread}) => {
    const cancelId = getCancelId();
    const oldNotes = notes;
    services.noteServices
      .editNotes(id, noteId, {...spread}, {cancelId})
      .then(res => {
        const note = res?.data;
        setNotes(notes.map(item => (item.id === noteId ? note : item)));
        if (note) {
          addFileToContractNote(attachments, note);
        }
        setAlert({
          message: "Note successfully edited.",
        });
        loadNotes();
      })
      .catch(() => {
        setNotes(oldNotes);
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };

  const removeHandler = noteId => {
    const cancelId = getCancelId();
    const oldNotes = notes;

    setNotes(notes.filter(item => item.id !== noteId));
    setAlert({
      message: "Note successfully deleted.",
      onCancel: () => {
        cancelRequest(cancelId);
        setNotes(oldNotes);
      }
    });
    services.noteServices
      .deleteNote(id, noteId, {cancelId})
      .then()
      .catch(() => {
        setNotes(oldNotes);
        setAlert({
          message: "Something went wrong. Please, try again.",
          type: "error"
        });
      });
  };

  return (
    <section className={s.container}>
      {loading && <TableLoading length={2}/>}
      {!loading && (
        <Fragment>
          {notes.length ? (
            <div ref={menuRef}>
              <MainTable
                className={classNames(s.table, {[s.overflow]: notes.length > 3})}
                rowClassName={s.tableRow}
                data={notes}
                dataTypes={[
                  {
                    title: "User",
                    value: ({owner}) => owner.name,
                    onClick: (_, {owner}) => history.push(`/users/${owner.id}`)
                  },
                  {
                    title: "Date",
                    value: ({created_at}) => fromDate(created_at)
                  },
                  {
                    title: "Note",
                    value: ({description}) => description
                  },
                  {
                    title: "",
                    // eslint-disable-next-line react/prop-types,react/display-name
                    value: ({attachments = [], id}) => (
                      <div className={s.attachmentsWrap}>
                        <AttachmentIcon
                          className={classNames(s.icon, {
                            [s.disabled]: !attachments?.length
                          })}
                          onClick={() =>
                            attachments.length > 0 && setVisibleAttachmentsPopupId(visibleAttachmentsPopupId ? null : id)
                          }
                        />
                        {visibleAttachmentsPopupId === id && attachments.length > 0 && (
                          <ul className={s.attachments} ref={attachmentsRef}>
                            {attachments.map((attachment, index) => (
                              <li className={s.attachment} key={index}>
                                {getFileIconByType(
                                  attachment.type || attachment.file_type
                                )}
                                <a
                                  className={s.link}
                                  href={attachment.path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {attachment.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )
                  }
                ]}
                options={item => {
                  return [
                    {
                      title: "Edit",
                      onClick: () => {
                        setNote(item);
                        setShow({...show, edit: true});
                      }
                    },
                    {
                      title: "Delete",
                      onClick: () => {
                        setNote(item);
                        setShow({...show, delete: true});
                      }
                    }
                  ];
                }}
                button={{
                  name: "Add note",
                  onClick: () => setShow({...show, add: true})
                }}
                perPage={10}
                totalCount={notesCount}
                onPageChange={onPageChange}
                isPagination={true}
                page={location.search.page || 1}
              />
              {show.delete && (
                <Popup
                  onClose={() => setShow({...show, delete: false})}
                  successBtnTitle="Delete"
                  title="Delete note"
                  text={"Are you sure you want to delete this note?"}
                  onClick={() => {
                    removeHandler(note.id);
                    setShow({...show, delete: false});
                  }}
                  isDelete
                  dialog
                />
              )}
              {show.edit && (
                <Popup
                  onClose={() => setShow({...show, edit: false})}
                  title="Edit note"
                >
                  <EditProjectNoteForm
                    editHandler={editHandler}
                    onClose={() => setShow({...show, edit: false})}
                    note={note}
                    maxLengthText={100}
                  />
                </Popup>
              )}
            </div>
          ) : (
            <EmptyBlock
              subtitle="You don’t have notes yet. You can add a new one"
              buttonTitle="Add note"
              onClick={() => setShow({...show, add: true})}
              withBtn={true}
            />
          )}
          {show.add && (
            <Popup onClose={() => setShow({...show, add: false})} title="Add note">
              <AddProjectNoteForm
                addHandler={addHandler}
                onClose={() => setShow({...show, add: false})}
                maxLengthText={100}
              />
            </Popup>
          )}
        </Fragment>
      )}
    </section>
  );
};

export default NotesTable;
