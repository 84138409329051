import React from "react";
import ReportingContainer from "components/containers/ReportingContainer/ReportingContainer";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";

const ReportingPage = () => {
  return (
    <DefaultLayout>
      <ReportingContainer />
    </DefaultLayout>
  );
};
export default ReportingPage;
