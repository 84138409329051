import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import Input from "components/elements/Input/Input.jsx";
import ContractSelect from "components/forms/RequestForm/components/SingleStep/ContractsSelect/ContractsSelect.jsx";
import UsersSelect from "components/forms/RequestForm/components/SingleStep/UsersSelect/UsersSelect.jsx";
import SuppliersSelect from "components/forms/RequestForm/components/SingleStep/SuppliersSelect/SuppliersSelect.jsx";
import DataField from "components/forms/RequestForm/components/SingleStep/DateField/DateField.jsx";
import AttachmentsField from "components/forms/RequestForm/components/SingleStep/AttachmentsField/AttachmentsField.jsx";
import DropDownsSelect from "components/forms/RequestForm/components/SingleStep/DropDownsSelect/DropDownsSelect.jsx";
import CategoriesSelect from "components/forms/RequestForm/components/SingleStep/CategoriesSelect/CategoriesSelect.jsx";
import TypeOfSpendSelect from "components/forms/RequestForm/components/SingleStep/TypeOfSpendSelect/TypeOfSpendSelect.jsx";

export const PreviewQuestion = ({ quest, setFieldValue, values }) => {
  const types = [
    { label: "Contract", value: "contract" },
    { label: "User", value: "user" },
    { label: "Supplier", value: "supplier" },
    { label: "Category", value: "category" },
    { label: "Type of spend", value: "type-of-spend" },
    { label: "Budget of spend", value: "budget-of-spend" },
    { label: "Target spend", value: "target-spend" },
    { label: "Date", value: "date" },
    { label: "Number", value: "number" },
    { label: "String", value: "string" },
    { label: "Yes/No", value: "yesno" },
    { label: "Attachment", value: "attachment" },
    { label: "Custom dropdown", value: "custom-dropdown" },
  ];

  const getTypeAnswer = (type) => {
    switch (type.value) {
      case "yesno":
        return (
          <RadioGroup
            onChange={(e) => setFieldValue(type.value, { value: e.target.value, type: "radio" })}
            name="radio-buttons-group">
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        );
      case "target-spend":
      case "budget-of-spend":
      case "number":
      case "string":
        return (
          <Input
            name={type.value}
            placeholder={`Enter your ${type.value}`}
            onChange={(e) => setFieldValue(type.value, e.target.value)}
            value={values.string}
          />
        );
      case "contract":
        return <ContractSelect field={type} setFieldValue={setFieldValue} values={values} />;
      case "user":
        return <UsersSelect field={type} setFieldValue={setFieldValue} values={values} />;
      case "supplier":
        return <SuppliersSelect field={type} setFieldValue={setFieldValue} values={values} />;
      case "date":
        return <DataField field={type} setFieldValue={setFieldValue} values={values} />;
      case "attachment":
        return <AttachmentsField field={type} setFieldValue={setFieldValue} values={values} />;
      case "custom-dropdown":
        return <DropDownsSelect field={type} setFieldValue={setFieldValue} values={values} />;
      case "category":
        return <CategoriesSelect field={type} setFieldValue={setFieldValue} values={values} />;
      case "type-of-spend":
        return <TypeOfSpendSelect field={type} setFieldValue={setFieldValue} values={values} />;
      default:
        break;
    }
  };

  return (
    <section
      style={{
        width: "100%",
        padding: "20px",
        backgroundColor: "#ffffff",
        borderRadius: "7px",
        marginTop: "10px",
        marginBottom: "10px",
      }}>
      <div style={{ fontSize: "15px", lineHeight: "22.5px", fontWeight: "500" }}>
        {quest.question}
      </div>
      <div>
        {getTypeAnswer(
          typeof quest.question_type === "string"
            ? types.find((el) => el.label === quest.question_type)
            : quest.question_type,
        )}
      </div>
    </section>
  );
};
