import React, { useEffect, useState } from "react";
import querystring from "query-string";
import { useHistory } from "react-router-dom";
import EmptyBlock from "components/elements/EmptyBlock/EmptyBlock";
import ResourceUtilizationReportTable from "components/tables/ResourceUtilizationReportTable/ResourceUtilizationReportTable";
import services from "services";
import dataTypes from "./dataTypes";
import TableLoading from "components/loadingStates/Table/Table";

const ResourceUtilizationReportIndividualList = () => {
  const history = useHistory();
  const [data, setData] = useState({ users: [], users_count: 0 });
  const [loading, setLoading] = useState(true);

  const query = querystring.parse(history.location.search);

  useEffect(() => {
    services.resourceUtilizationServices
      .getUtilizationIndividual(query)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  }, [history.location]);

  return (
    <div>
      {loading && <TableLoading length={9} />}
      {!loading &&
        (Boolean(data.users.length) ? (
          <ResourceUtilizationReportTable
            data={data}
            dataTypes={dataTypes}
            currentPage={query.page || 1}
          />
        ) : (
          <EmptyBlock title="No work item results found" message/>
        ))}
    </div>
  );
};

export default ResourceUtilizationReportIndividualList;
