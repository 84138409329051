export const testData = {
  id: "1",
  name: "test",
  owner: {
    id: "1",
    name: "test owner"
  },
  created_at: new Date(),
  color: "blue",
  awarded_suppliers: [
    {
      id: "1",
      category: {
        id: "1",
        name: "test category"
      },
      target_spend_value: 99,
      target_spend_currency: "USD",
      diverse_spend_percent: 99
    }
  ],
  awarder_suppliers_statistic: {
    total: {
      projects_count: 43,
      suppliers_count: 75,
      target_spend_count: 2,
      target_spend_currency: "$"
    },
    diverse: {
      projects_count: 8,
      suppliers_count: 6,
      suppliers_percent: 70,
      target_spend_count: 45,
      target_spend_percent: 99,
      target_spend_currency: "$"
    },
    awarded_to: {
      projects_count: 1,
      suppliers_count: 3,
      suppliers_percent: 12,
      target_spend_count: 47,
      target_spend_percent: 61,
      target_spend_currency: "$"
    }
  },
  suppliers: [
    {
      id: "1",
      name: "test supplier",
      category: {
        id: "1",
        name: "test category"
      },
      projects_count: 1,
      spend: 111,
      spend_currency: "USD",
      payments_count: 999,
      contracts_count: 999,
      status: "test status",
      owner: {
        id: "1",
        name: "test owner"
      }
    }
  ],
  projects: [
    {
      id: "1",
      name: "test project",
      project_type: "test type",
      category: {
        id: "1",
        name: "test category"
      },
      start_date: new Date(),
      end_date: new Date(),
      baseline_spend: 999999,
      savings: 3843,
      currency: "USD"
    }
  ],
  contracts: [
    {
      id: "1",
      name: "test contract",
      supplier: {
        id: "1",
        name: "test supplier"
      },
      total_value: 3243,
      annual_value: 143,
      start_date: new Date(),
      end_date: new Date()
    }
  ]
};
