import React from "react";
import Button from "components/elements/Button/Button";
import { ReactComponent as Preview } from "assets/icons/preview.svg";
import { getTranslateKey } from "../../../../../helpers/getTranslateKey";
import { useTranslation } from "react-i18next";
import styles from "./Buttons.module.scss";

export const Buttons = ({
  name,
  editScalePopUp,
  setEditScalePopUp,
  setEditQuestions,
  setSaveSteps,
  setPreview,
}) => {
  const { t } = useTranslation();
  const handleOnCancel = () =>
    !editScalePopUp ? setPreview((p) => !p) : setEditScalePopUp((prevPopUp) => !prevPopUp);
  const onCancel = () => setEditQuestions((prevPopUp) => !prevPopUp);
  const onSave = () => setSaveSteps((p) => !p);

  return (
    <>
      {name === "Standart" && (
        <div>
          <Button styled="secondary" type="reset" className="popupBtn" onClick={onCancel}>
            Cancel
          </Button>
          <Button styled="primary" className="popupBtn" onClick={onSave}>
            Save
          </Button>
        </div>
      )}
      {name === "Preview" && (
        <Button
          className={!editScalePopUp ? styles.preview : ""}
          styled="secondary"
          onClick={editScalePopUp ? handleOnCancel : handleOnCancel}>
          {!editScalePopUp && <Preview />}
          <span data-translate-key={getTranslateKey(editScalePopUp ? "Cancel" : "Preview")}>
            {t(getTranslateKey(editScalePopUp ? "Cancel" : "Preview"))}
          </span>
        </Button>
      )}
    </>
  );
};
