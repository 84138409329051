import React, {useEffect, useState} from "react";
import MainTable from "components/tables/MainTable/MainTable";
import {useHistory} from "react-router-dom";
import {formatCurrency} from "helpers/currencyFormat";
import {currencyData} from "helpers/helpData";
import {useSelector} from "react-redux";
import qs from "query-string";
import {supplierStatuses} from "helpers/helpData";
import Tags from "components/elements/Tags/Tags";
import s from "./SuppliersTable.module.scss";
import PropTypes from "prop-types";
import {userRoles, userTypes} from "../../../helpers/userPermissions/userRoles";
import LogoSuppliers from "../../elements/LogoSuppliers/LogoSuppliers";
import services from "../../../services";
import {paths} from "../../../routes/paths";

const SuppliersTable = (
  {
    data,
    perPage,
    totalCount,
    permissions,
    onPageChange,
    currentPage,
    onEdit,
    onAssignTag,
    onDeleteTag,
    onDelete,
    onAssign,
    onChangeStatus
  }
) => {
  const history = useHistory();
  const organizationName = useSelector(state => state.user.organization.name);
  const query = qs.parse(history.location.search);
  const {user_type} = useSelector(state => state.user);
  const {business, procurement, adminTypes} = userTypes;
  const user = useSelector(state => state.user);
  const [supplierRequest, setSupplierRequest] = useState([]);

  useEffect(() => {
    services.requestTypeServices.getRequestTypes().then(res => {
      if (res.data) setSupplierRequest(res.data.filter(r => r.relation === "supplier"));
    });
  }, []);

  const onSort = ({sort_by, direction, tag}) => {
    history.push({
      search: qs.stringify({
        ...query,
        sort_by,
        direction,
        page: 1,
        tag
      })
    });
  };

  const getParentSupplierId = subId => {
    let result = null;
    data.map(e => {
      const isHaveSub = Boolean(
        e.category_suppliers.filter(e => e.id === subId).length
      );
      if (isHaveSub) {
        result = e.id;
      }
    });
    return result;
  };

  const deleteTagAction = (id, tag, item) => {
    if (tag.type === "flag") {
      if (tag.owner.id === user.id || user.roles.includes(userRoles.admin)) {
        return onDeleteTag(id, item.id);
      }
    } else return onDeleteTag(id, item.id);
  };

  const createRequest = (request, supplier) => {
    history.push(paths.createRequest, {request, entity: supplier});
  };

  return (
    <MainTable
      stickyHeader={true}
      inlineWrapClassName={s.inlineWrap}
      withXScroll={true}
      data={data.map(sup => {
        return {...sup, category_suppliers: sup.category_suppliers.filter(s => s.spend > 0)};
      })}
      dragIdLabel="supplier"
      subKey="category_suppliers"
      subTypes={[
        {
          title: "Supplier",
          // eslint-disable-next-line react/prop-types,react/display-name
          value: ({category, id, logo}, allData) => (
            < div className={s.logoSubSupplier}>
              <LogoSuppliers name={allData?.name || "-"}
                             logo={allData?.logo || "-"}/>
              <div className={s.subSupplier}>
                <a href={`/suppliers/${getParentSupplierId(id)}`}>{allData?.name || "-"}</a>
                <a href={`/categories/${category.id}`}>({category.name})</a>
              </div>
            </div>
          ),
          sticky: true
        },
        {
          title: "Spend",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "spend", direction});
          },
          value: ({spend, spend_currency}) =>
            spend || spend === 0
              ? formatCurrency(currencyData.filter(e => e.label === spend_currency)[0]?.value, spend)
              : "-",
          alignment: "right"
        },
        {
          title: "Payments",
          value: ({payments_count}) => payments_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "payments_count", direction});
          }
        },
        {
          title: "Contracts",
          value: ({contracts_count}) => contracts_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "contracts_count", direction});
          },
          visible: organizationName !== "Indeed"
        },
        {
          title: "Status",
          value: ({status}) =>
            supplierStatuses.find(itm => itm.value === status)?.label || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "status", direction});
          }
        },
        {
          title: "Owner",
          value: ({owner}) => owner?.name || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "owner", direction});
          }
        },
        {
          title: "Tags",
          value: (item, allData) =>
            item?.tags ? (
              <Tags
                allData={allData}
                width={260}
                item={item}
                onDelete={user_type !== business && ((id, tag) => deleteTagAction(id, tag, item))}
                onAssign={user_type !== business && (id => onAssignTag(id, item.id))}
                onSort={({name}) =>
                  query.tag !== name
                    ? onSort({tag: name})
                    : onSort({tag: ""})
                }
              />
            ) : (
              ""
            ),
          type: "tags"
        }
      ]}
      dataTypes={[
        {
          title: "Supplier",
          // eslint-disable-next-line react/display-name,react/prop-types
          value: ({name, id, logo}) => (
            <div className={s.logoSubSupplier}>
              <LogoSuppliers name={name} logo={logo}/>
              <a className={s.link} href={`/suppliers/${id}`}>{name}</a>
            </div>),
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          sticky: true
        },
        {
          title: "Total Spend",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "spend", direction});
          },
          value: ({spend, spend_currency}) =>
            spend || spend === 0
              ? formatCurrency(
                currencyData.filter(e => e.label === spend_currency)[0]
                  ?.value,
                spend
              )
              : "-",
          alignment: "right",
          subTypes: {
            title: "Filtered Spend",
            sortable: true,
            handleSort: ({direction}) => {
              onSort({sort_by: "filtered_spend", direction});
            },
            value: ({filtered_spend, spend_currency}) =>
              filtered_spend || filtered_spend === 0
                ? formatCurrency(
                  currencyData.filter(e => e.label === spend_currency)[0]
                    ?.value,
                  filtered_spend)
                : "-",
          },
        },
        {
          title: "Payments",
          value: ({payments_count}) => payments_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "payments_count", direction});
          }
        },
        {
          title: "Contracts",
          value: ({contracts_count}) => contracts_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "contracts_count", direction});
          },
          visible: organizationName !== "Indeed"
        },
        {
          title: "Status",
          value: ({status}) => supplierStatuses.find(itm => itm.value === status)?.label || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "status", direction});
          }
        },
        {
          title: "Owner",
          value: ({owner}) => owner?.name || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "owner", direction});
          }
        },
        {
          title: "Tags",
          value: (item, allData) =>
            item?.tags ? (
              <Tags
                allData={allData}
                width={260}
                item={item}
                onDelete={user_type !== business && ((id, tag) => deleteTagAction(id, tag, item))}
                onAssign={user_type !== business && (id => onAssignTag(id, item.id))}
                onSort={({name}) =>
                  query.tag !== name
                    ? onSort({tag: name})
                    : onSort({tag: ""})
                }
              />
            ) : (""),
          type: "tags"
        }
      ]}
      options={item => {
        return [
          permissions.update &&
          item?.category_suppliers && {
            title: "Edit",
            onClick: () => {
              onEdit(item.id);
            }
          },
          permissions.destroy &&
          item?.category_suppliers && {
            title: "Delete",
            onClick: () => {
              onDelete(item);
            }
          },
          user_type !== business && item?.category_suppliers && {
            title: "Add to project",
            onClick: () => onAssign(item)
          },
          !item?.category_suppliers && {
            title: "Change status",
            onClick: () => onChangeStatus(item)
          },
          !item?.category_suppliers && {
            title: "Delete",
            onClick: () => onDelete(item)
          },
          ...((
            user.user_type === procurement ||
            user.user_type === business ||
            user.user_type === adminTypes
          ) ? supplierRequest : []).map(request => ({
            title: request.name,
            onClick: () => createRequest(request.id, item)
          }))
        ];
      }}
      perPage={perPage}
      totalCount={totalCount}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};
export default SuppliersTable;

SuppliersTable.propTypes = {
  data: PropTypes.any,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  permissions: PropTypes.any,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  onEdit: PropTypes.func,
  onAssignTag: PropTypes.func,
  onDeleteTag: PropTypes.func,
  onDelete: PropTypes.func,
  onAssign: PropTypes.func,
  onChangeStatus: PropTypes.func,
};