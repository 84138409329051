export const getAllYears = sideYears => {
  const allYears = [sideYears[0]]
  const difference = sideYears[1] - sideYears[0]
  if (difference) {
    for (let i = 1; i <= difference; i++) {
      allYears.push(sideYears[0] + i)
    }
  }
  return allYears
}
