import React, {useEffect, useState} from "react"
import EmptyContent from "../EmptyContent/EmptyContent";
import Content from "../Content/Content";
import {addNewTypeOfSpend, deleteTypeOfSpend, loadData} from "../helpers/actions/main";
import Popup from "../../../../popups/Popup/Popup";
import TypeOfSpendForm from "./TypeOfSpendForm";
import AdditionalQuestionsLoading
  from "../../../../loadingStates/AdditionalQuestionsLoading/AdditionalQuestionsLoading";

const TypeOfSpend = ({data, dispatch}) => {
  const [loading, setLoading] = useState(true);
  const [isOpenPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    loadData(dispatch, 'typeOfSpendList');
    setLoading(false);
  }, []);

  const addNewCondition = value => addNewTypeOfSpend(dispatch, {type_of_spend_id: value});
  const deleteCondition = id => deleteTypeOfSpend(dispatch, id);

  const serializeData = data => {
    return data.map(t => {
      return {
        name: t.category.name,
        questions_count: t.additional_questions_count,
        id: t.id,
      }
    });
  }

  return (
    <>
      {
        loading ? <AdditionalQuestionsLoading/> : (
          data.length ?
            <Content
              data={serializeData(data)}
              type='Type of Spend'
              addAction={() => setOpenPopUp(true)}
              deleteAction={id => deleteCondition(id)}
            /> : <EmptyContent addAction={() => setOpenPopUp(true)}/>
        )
      }
      {
        isOpenPopUp &&
        <Popup
          onClose={() => setOpenPopUp(false)}
          title="New condition"
        >
          <TypeOfSpendForm
            onSubmit={value => addNewCondition(value.contract.contract.id)}
            onClose={() => setOpenPopUp(false)}
            typeOfSpendList={data}
          />
        </Popup>
      }
    </>
  );
}
export default TypeOfSpend;
