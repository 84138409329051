import React, {useEffect, useState} from 'react';
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import CustomDropDownsForm from "../../forms/CustomDropDownsForm/CustomDropDownsForm";
import s from "./AdminSettingsCustomDropDownsChangeContainer.module.scss";
import {paths} from "../../../routes/paths";
import services from "../../../services";
import {useHistory} from "react-router-dom";

const AdminSettingsCustomDropDownsChangeContainer = ({id}) => {
  const history = useHistory();
  const [dropDownDetail, setDropDown] = useState(null);

  useEffect(() => {
    services.customDropdownsServices.getCustomDropdownsDetail(id).then(res => {
      if (res.data) setDropDown(res.data);
    });
  }, []);

  const editDropDown = values => {
    services.customDropdownsServices.editCustomDropdowns(id, values).then(res => {
      if (res.data) {
        history.push(paths.adminSettingsRequest + "?tab=Custom%20dropdowns")
      }
    })
  }

  return (
    <DefaultLayout
      breadcrumbs={[
        {name: "Admin Settings", path: "", disabled: true},
        {
          name: "Requests",
          path: paths.adminSettingsRequest,
        },
        {
          name: "Edit dropdown",
        },
      ]}
    >
      <div className={s.wrapper}>
        {dropDownDetail && <CustomDropDownsForm changeDropDown={editDropDown} defaultValues={dropDownDetail}/>}
      </div>
    </DefaultLayout>
  );
};

export default AdminSettingsCustomDropDownsChangeContainer;