import React, {useEffect, useState} from "react";
import s from "./ChangeFlagForm.module.scss";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import SelectCheckDropdown from "components/elements/SelectCheckDropdown/SelectCheckDropdown";
import {useFormik} from "formik";
import {object, string} from "yup";
import CustomColorPicker from "../../elements/CustomColorPicker/CustomColorPicker";
import PropTypes from "prop-types";
import {backgroundTypes} from "../../../helpers/helpData";
import {formatEntityToObject} from "../../../helpers/formatEntityData";
import services from "../../../services";
import {userRoles} from "../../../helpers/usersData";

const formatDefaultApprovers = (data = []) => {
  const obj = {};
  data.map((e) => (obj[e.name] = true));
  return obj;
};

const ChangeAdminApprovalGroupForm = (
  {
    onClose,
    onSubmit,
    defaultValues,
  }
) => {

  const [preparedAdmins, setPreparedAdmins] = useState([]);

  useEffect(() => {
    services.userServices
      .stakeholdersAutocomplete({roles: userRoles.map(role => role.value).join(",")})
      .then(res => {
        res.data && setPreparedAdmins(
          res.data.map(a => ({
            id: a.id,
            subItems: null,
            label: a.name,
            value: a.name
          })).sort((a, b) => a.label.localeCompare(b.label))
        );
      });
  }, []);

  const [checkedAdmins, setCheckedAdmins] = useState(
    defaultValues?.flag_admins || []
  );
  const MAX_NAME_LENGTH = 50;
  const emptyValues = {
    name: "",
    color: "",
    admins: {}
  };

  const defaultVals = {
    ...defaultValues,
    admins: formatDefaultApprovers(defaultValues?.flag_admins),
  };

  const formik = useFormik({
    initialValues: defaultValues ? defaultVals : emptyValues,
    validationSchema: object({
      name: string()
        .required("Flag name is required")
        .trim()
        .max(
          MAX_NAME_LENGTH,
          `The flag name is too long. It should be ${MAX_NAME_LENGTH} characters or less.`
        ),
      color: string().required("Color is required")
    }),
    onSubmit: ({name, color}) => {
      onSubmit({
        name,
        flag_admin_ids: checkedAdmins.map(({id, value, name}) => ({id, name: value || name})),
        color,
      });
    },
  });

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
  } = formik;

  return (
    <form onSubmit={handleSubmit} className={s.form} autoComplete="off">
      <div className={s.field}>
        <Input
          value={values.name}
          onChange={handleChange}
          name="name"
          type="text"
          placeholder="Enter flag name"
          label="Flag name"
          onBlur={handleBlur}
          error={errors.name && touched.name && errors.name}
        />
      </div>
      <div className={s.field}>
        <CustomColorPicker
          options={backgroundTypes}
          value={values.color}
          onChange={value => setFieldValue("color", value)}
          label="Color"
          placeholder="Choose status color"
          error={errors.color && touched.color && errors.color}
        />
      </div>
      <SelectCheckDropdown
        label="Flag admins"
        options={preparedAdmins}
        value={values.admins}
        onChange={(e, item) => {
          setFieldValue("admins", e);
          item.checked
            ? setCheckedAdmins([item, ...checkedAdmins])
            : setCheckedAdmins(
              checkedAdmins.filter((e) => e.id !== item.id)
            );
        }}
        placeholder="Start typing admin name"
        onSearch={data => {
          setFieldValue("admins", formatEntityToObject(data));
          setCheckedAdmins(data);
        }}
        emptyMessage="Admins not found"
        error={errors.admins && touched.admins && errors.admins}
        withEndComma={true}
      />
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          type="reset"
          className="popupBtn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {defaultValues?.id ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};
export default ChangeAdminApprovalGroupForm;

ChangeAdminApprovalGroupForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.any,
  admins: PropTypes.any,
};