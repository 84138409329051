import React from "react";
import PropTypes from 'prop-types'
import s from "./ProductCard.module.scss";
import {useTranslate} from "../../../../../hooks/useTranslate";

const ProductCard = ({ img, title, text }) => {
  const [translateLabelKey, translateLabelValue] = useTranslate(title);
  const [translateLabelKeyText, translateLabelValueText] = useTranslate(text);
  return (
    <div className={s.card}>
      <img src={img} alt="cardIcon" className={s.img} />
      <div className={s.content}>
        <h4 className={s.title} data-translate-key={translateLabelKey}>{translateLabelValue}</h4>
        <p className={s.text} data-translate-key={translateLabelKeyText}>{translateLabelValueText}</p>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  img: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string
}

ProductCard.defaultProps = {
  img: "",
  title: "",
  text: ""
}

export default ProductCard;
