export const formatValue = (value, defaultCurrency = "$") => {
  const currency = defaultCurrency !== "USD $" ? defaultCurrency : "$";

  return Math.abs(Number(value)) >= 1.0e9
    ? currency +
        (Math.abs(Number(value)) / 1.0e9)
          .toFixed(2)
          .toString()
          .replace(".00", "") +
        "B"
    : Math.abs(Number(value)) >= 1.0e6
    ? currency +
      (Math.abs(Number(value)) / 1.0e6)
        .toFixed(2)
        .toString()
        .replace(".00", "") +
      "M"
    : Math.abs(Number(value)) >= 1.0e3
    ? currency +
      (Math.abs(Number(value)) / 1.0e3)
        .toFixed(2)
        .toString()
        .replace(".00", "") +
      "K"
    : value >= 0
    ? currency + Math.abs(Number(value)).toFixed(0)
    : `- ${currency}` + Math.abs(Number(value)).toFixed(0);
};
export const formatFullValue = (value, defaultCurrency = "$") => {
  const currency = defaultCurrency !== "USD $" ? defaultCurrency : "$";
  return `${currency}${Number(value).toLocaleString("en-US", {
    maximumFractionDigits: 0,
  })}`;
};
