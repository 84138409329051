import React from "react";
import {useSelector} from "react-redux";
import querystring from "query-string";
import {useHistory} from "react-router-dom";
import MainTable from "components/tables/MainTable/MainTable";
import {fromDate} from "helpers/dateFormat";

const SystemReportsUsersTable = ({data, perPage = 30}) => {
  const history = useHistory();
  const organizationName = useSelector(state => state.user.organization.name);
  const query = querystring.parse(history.location.search);

  const onPageChange = page => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  const onSort = type => {
    const query = querystring.parse(history.location.search);
    history.push({
      search: querystring.stringify({...query, page: 1, ...type})
    });
  };

  return (
    <MainTable
      stickyHeader={true}
      data={data.users}
      perPage={perPage}
      totalCount={data.users_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={query.page || 1}
      fullHeight={true}
      dataTypes={[
        {
          title: "Name",
          value: ({name}) => name,
          sortable: true,
          onClick: id => history.push(`/system-reports/users/${id}`),
          handleSort: ({direction}) => {
            onSort({sort_by: "name", direction});
          },
          sticky: true
        },
        {
          title: "Title",
          value: ({title}) => title,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "title", direction});
          }
        },
        {
          title: "Email",
          value: ({email}) => email,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "email", direction});
          },
          visible: organizationName !== "Riot Games"
        },
        {
          title: "Projects",
          value: ({projects_count}) => projects_count || "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "projects_count", direction});
          }
        },
        {
          title: "Division",
          value: ({division}) => division.name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "division", direction});
          }
        },
        {
          title: "Department",
          value: ({department}) => department.name,
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "department", direction});
          }
        },
        {
          title: "Last log in",
          value: ({last_sign_in_at}) =>
            last_sign_in_at ? fromDate(last_sign_in_at) : "-",
          sortable: true,
          handleSort: ({direction}) => {
            onSort({sort_by: "last_sign_in_at", direction});
          }
        }
      ]}
    />
  );
};
export default SystemReportsUsersTable;
