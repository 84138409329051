import React from "react";
import {useFormik} from "formik";
import {validationSchema} from "./helpers/validationSchema";
import s from "./FinancialBenefitsForm.module.scss";
import HeaderForm from "./components/HeaderForm";
import MainForm from "./components/MainForm";
import {getInitialValues} from "./helpers/getInitialValues";
import Divider from "../../elements/Divider/Divider";
import AdditionalForm from "./components/AdditionalForm";
import SplitSavingsForm from "./components/SplitSavingsForm";
import {validation} from "./helpers/validation";
import {requestFormat} from "./helpers/requestFormat";

const FinancialBenefitsForm = ({onSubmit, defaultValues, isEdit = false}) => {

  const formik = useFormik({
    initialValues: getInitialValues(defaultValues),
    validationSchema,
    validate: values => validation(values),
    onSubmit: values => onSubmit(requestFormat(values)),
  });

  const {values, errors, setFieldValue, handleSubmit} = formik;
  const formProps = {values, errors, setFieldValue};

  return (
    <form className={s.financialBenefitsForm} onSubmit={handleSubmit}>
      <HeaderForm isEdit={isEdit}/>
      <MainForm {...formProps} />
      <Divider/>
      <AdditionalForm {...formProps}/>
      <Divider/>
      <SplitSavingsForm {...formProps}/>
    </form>
  )
}

export default FinancialBenefitsForm;