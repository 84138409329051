import React from "react";
import styles from "./RadioButtons.module.scss";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import PropTypes from "prop-types";

const RadioButtons = (
  {
    value,
    change,
    isViewMode = false
  }
) => {

  if (isViewMode) return (
    <RadioGroup aria-label="radioGroup" name="radioGroup" className={styles.radioGroupWrap}>
      <FormControlLabel
        checked={true}
        value={value}
        control={<Radio color="primary"/>}
        label={value}
      />
    </RadioGroup>
  );

  return (
    <RadioGroup aria-label="radioGroup" name="radioGroup" className={styles.radioGroupWrap}>
      <FormControlLabel
        onClick={() => change("and")}
        checked={value === "and"}
        value="and"
        control={<Radio color="primary"/>}
        label="and"
      />
      <FormControlLabel
        onClick={() => change("or")}
        checked={value === "or"}
        value="or"
        control={<Radio color="primary"/>}
        label="or"
      />
    </RadioGroup>
  );
};

export default RadioButtons;

RadioButtons.propTypes = {
  value: PropTypes.any,
  change: PropTypes.func,
  isViewMode: PropTypes.bool,
};