import React from "react";
import s from "./NotFound.module.scss";
import { ReactComponent as NotFoundImg } from "assets/icons/not-found.svg";

const NotFound = ({ history }) => {
  return (
    <div className={s.wrapper}>
      <h1 className={s.header}>That page doesn't exist!</h1>
      <NotFoundImg className={s.img} />
      <p className={s.text}>
        Sorry, the page you were looking for could not be found.
      </p>
      <p className={s.text}>
        You can return to the
        <span className={s.link} onClick={() => history.goBack()}>
          {" "}
          previous page{" "}
        </span>
        or
        <span className={s.link} onClick={() => history.push("/contact-us")}>
          {" "}
          contact us{" "}
        </span>
        if you can’t find what you are looking for.
      </p>
    </div>
  );
};

export default NotFound;
