import React from "react";
import FinancialBenefitsAddContainer
  from "components/containers/FinancialBenefitsAddContainer/FinancialBenefitsAddContainer";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import {useProjectsPath} from "../../../hooks/useProjectsPath";

const FinancialBenefitsAddPage = () => {
  const projectsPath = useProjectsPath();

  return (
    <DefaultLayout
      breadcrumbs={[
        {
          path: projectsPath,
          name: "Projects"
        },
        {
          name: "Project details"
        }
      ]}
    >
      <FinancialBenefitsAddContainer/>
    </DefaultLayout>
  );
};

export default FinancialBenefitsAddPage;