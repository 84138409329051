import React, {useContext, useState} from "react";
import ApprovalForm from "../../forms/ApprovalForm/ApprovalForm";
import styles from "./AddApprovalContainer.module.scss";
import {prepareRequestData} from "../../../helpers/approvals/prepareRequestData";
import services from "../../../services";
import {useHistory, useParams} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import AlertContext from "../../../contexts/AlertContext/AlertContext";

const AddApprovalContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [isSend, setSend] = useState(false);
  const {setAlert} = useContext(AlertContext);

  const send = values => {
    const prepareValues = {
      ...prepareRequestData(values),
    };

    services.approvalServices.createApproval(params.milestoneId, prepareValues).then(res => {
      if (res.data) {
        setSend(true);
        history.push(`/projects/${params.projectId}/milestones/${params.milestoneId}/approvals/${res.data.id}/detail`, {
          milestoneId: params.milestoneId,
        });
      }
    }).catch((err) => {
      setAlert({
        message: err.response?.data.error ||
          "Something went wrong. Please, try again.",
        type: "error"
      });
    });
  }

  const progressLoader = (
    <div className="loaderWrap justifyCenter alignCenter">
      <CircularProgress/>
    </div>
  );

  return (
    <div className={styles.approvalContainer}>
      <h2 className={styles.title}>{history.location.state?.projectName ?? "Project"}</h2>
      {isSend ? progressLoader : <ApprovalForm onSubmit={send}/>}
    </div>
  );
};

export default AddApprovalContainer;