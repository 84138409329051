import React, {createContext, useState} from "react";
import {mods} from "./mods";

export const ModeContext = createContext();

const ModeContextProvider = ({defaultMode, children}) => {
  const [mode, setMode] = useState(defaultMode);

  const enableEditMode = () => setMode(mods.edit);
  const enableViewMode = () => setMode(mods.view);

  return (
    <ModeContext.Provider value={{
      mode,
      enableEditMode,
      enableViewMode,
    }}>
      {children}
    </ModeContext.Provider>
  );
};

export default ModeContextProvider;