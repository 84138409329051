import {
  SET_CONTRACTS,
  SET_LOADING,
  ADD_NEW_CONTRACT,
  DELETE_CONTRACT,
  CLOSE_POPUP,
  SET_POPUP_NAME,
  SET_CURRENT_PAGE,
  SET_SELECTED_CONTRACT,
  SET_ALERT,
} from "./types";

export const initialState = {
  contracts: [],
  contracts_count: 0,
  loading: true,
  popup: null,
  current_contract: null,
  alert: { type: "", message: "" },
  current_page: 1,
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_CONTRACTS:
      return {
        ...state,
        contracts: payload.contracts,
        contracts_count: payload.contracts_count,
        loading: false,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ADD_NEW_CONTRACT:
      return {
        ...state,
        contracts: payload.data
          ? [...state.contracts, payload.data]
          : state.contracts,
        alert: payload.alert,
        contracts_count: payload.data
          ? state.contracts_count + 1
          : state.contracts_count,
        popup: null,
      };
    case DELETE_CONTRACT:
      return {
        ...state,
        contracts: payload.contract
          ? state.contracts.filter((e) => e.id !== payload.contract)
          : state.contracts,
        alert: payload.alert,
        contracts_count: payload.contract
          ? state.contracts_count - 1
          : state.contracts_count,
        popup: null,
      };
    case CLOSE_POPUP:
      return {
        ...state,
        popup: null,
      };
    case SET_POPUP_NAME:
      return {
        ...state,
        popup: payload.name,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        current_page: payload.page,
      };
    case SET_SELECTED_CONTRACT:
      return {
        ...state,
        current_contract: payload.contract,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: payload,
      };
    default:
      return state;
  }
};
