import React from "react";
import querystring from "query-string";
import {useHistory} from "react-router-dom";
import MainTable from "components/tables/MainTable/MainTable";
import styles from "./ResourceUtilizationReportTable.module.scss";

const ResourceUtilizationReportTable = ({data, currentPage, dataTypes}) => {
  const history = useHistory();

  const onPageChange = (page) => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };
  const onSort = (type) => {
    const query = querystring.parse(history.location.search);
    history.push({
      search: querystring.stringify({...query, page: 1, ...type}),
    });
  };
  const getWrapWidth = () => {
    const widthX2 = (window.innerWidth - 270) * 2;
    return widthX2 - 340;
  };
  return (
    <MainTable
      data={data.users || data.departments}
      stickyHeader={true}
      dataTypes={dataTypes(
        data.users || data.departments,
        onSort,
        styles,
        history
      )}
      perPage={30}
      totalCount={data.users_count || data.departments_count}
      onPageChange={onPageChange}
      isPagination={true}
      page={currentPage}
    />
  );
};

export default ResourceUtilizationReportTable;
