import React from "react";
import {useHistory, useParams} from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import {paths} from "../../../routes/paths";
import AdminSettingsContractsContainer
  from "../../containers/AdminSettingsContractsContainer/AdminSettingsContractsContainer";

const AdminSettingsContractsPage = () => {
  const history = useHistory();
  const {id} = useParams();
  let breadcrumbs = [
    {
      name: "Admin Settings",
      disabled: true
    },
    {
      path: paths.adminSettingsContracts,
      name: "Contracts",
    },
  ];
  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <AdminSettingsContractsContainer/>
    </DefaultLayout>
  );

}

export default AdminSettingsContractsPage;