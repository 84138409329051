import React, {useState, useRef, useEffect} from "react";
import s from "./FilterBarDropdown.module.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";
import FilterBarDropdownItem from "../FilterBarDropdownItem/FilterBarDropdownItem";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import useOutsideClicker from "hooks/useOutsideClicker";
import {
  isChecked as isCheckedDefault,
  searchCategories,
  getAllSubCategories,
  getAllFirstLvlSubCategories
} from "../../helpers/helpers";
import _ from "lodash";
import {useTranslate} from "../../../../../hooks/useTranslate";
import {getTranslateKey} from "../../../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";
import qs from "query-string";
import {useHistory} from "react-router-dom";
import classNames from "classnames";

const FilterBarDropdown = (
  {
    name,
    options,
    defaultValues,
    handleChange,
    searchPlaceholder,
    clearAllFilters,
    setClearAllFilters,
    setFilterValue,
    onlyFirstLvlFormat = false,
    useAutoHeight = false,
    useZIndex = false,
    afterClickAction,
    withOptionTranslate = false,
    customQueryParams = null,
    isSingleFilter = false,
    autocomplete,
  }
) => {
  const history = useHistory();
  const {...query} = qs.parse(history.location.search);
  const [isOpen, setIsOpen] = useState(false);
  const [localOptions, setLocationOptions] = useState([]);
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [translateNameKey, translateNameValue] = useTranslate(name);
  const {t} = useTranslation();
  const dropdownRef = useRef(null);

  useOutsideClicker(dropdownRef, () => {
    setIsOpen(false);
    const queryParams = (customQueryParams ?? Object.values(query)).map(item => item?.split(",")).flat();
    const prepareOptions = checkedOptions.filter(item => queryParams?.includes(item.id));
    setCheckedOptions(prepareOptions);
  });

  useEffect(() => {
    if (clearAllFilters) {
      setCheckedOptions([]);
      setFilterValue(name, false);
      setClearAllFilters(false);
      setSearchValue("");
    }
  }, [clearAllFilters]);

  useEffect(() => {
    if (!!defaultValues?.length && !!options?.length) {
      const optionsWithSubOptions = options
        .map(option =>
          onlyFirstLvlFormat
            ? getAllFirstLvlSubCategories(option)
            : getAllSubCategories(option)
        )
        .flat();
      const defaultChecked = optionsWithSubOptions.filter(option =>
        defaultValues.includes(String(option.id))
      );
      setFilterValue(name, true);

      setCheckedOptions(_.uniqWith(defaultChecked, _.isEqual));
    }
    setLocationOptions(options);
  }, [options, defaultValues]);

  const handleCheck = item => {
    let newCheckedOptions;
    if (isSingleFilter) {
      newCheckedOptions = [item];
      handleChange([item]);
      setIsOpen(false);
    } else {
      const isItemChecked = isChecked(item);
      const selectedItemWithSubCategories = onlyFirstLvlFormat
        ? getAllFirstLvlSubCategories(item)
        : getAllSubCategories(item);
      if (
        Boolean(isItemChecked) &&
        (onlyFirstLvlFormat || isItemChecked !== "minus")
      ) {
        newCheckedOptions = checkedOptions.filter(
          option =>
            !selectedItemWithSubCategories.find(
              clickedOption => clickedOption.id === option.id
            )
        );
      } else {
        newCheckedOptions = [...checkedOptions, ...selectedItemWithSubCategories];
      }
      newCheckedOptions = _.uniqWith(newCheckedOptions, _.isEqual);
    }
    setCheckedOptions(newCheckedOptions);
    setFilterValue(name, newCheckedOptions.length > 0);
    afterClickAction && afterClickAction();
  };

  const isChecked = item => isCheckedDefault(checkedOptions, item);

  const handleSearch = e => {
    const {
      target: {value}
    } = e;
    setSearchValue(value);


    if (autocomplete) {
      autocomplete(e, checkedOptions);
    } else {
      const newOptions = searchCategories(value, options);
      setLocationOptions(newOptions);
    }
  };

  const onClear = () => {
    setCheckedOptions([]);
    setFilterValue(name, false);
    handleChange([]);
    setSearchValue("");
    setIsOpen(false);
  };

  return (
    <div
      style={useZIndex ? {zIndex: 999} : {}}
      className={classnames(s.filterDropdownWrapper, {
        [s.filterListOpened]: isOpen
      })}
      ref={dropdownRef}
    >
      <div
        className={s.nameBlock}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {checkedOptions[0] ? (
          <p className={s.selectedOption}>
            <span data-translate-key={withOptionTranslate && getTranslateKey(checkedOptions[0].name)}>
            {withOptionTranslate ? t(getTranslateKey(checkedOptions[0].name)) : checkedOptions[0].name}
            </span>
            {" "}
            {checkedOptions.length > 1 && `(+${checkedOptions.length - 1})`}
          </p>
        ) : (
          <p data-translate-key={translateNameKey}>{translateNameValue}</p>
        )}
        <span className={classnames(s.arrowIcon, {[s.listOpen]: isOpen})}>
					<Arrow/>
				</span>
      </div>

      {isOpen && (
        <div
          className={s.openedList}
          style={useAutoHeight ? {height: "auto"} : {}}
        >
          <div className={classNames(s.optionsList, {[s.singleFilter]: isSingleFilter})}>
            {Boolean(searchPlaceholder) && (
              <Input
                icon
                value={searchValue}
                placeholder={searchPlaceholder}
                onChange={handleSearch}
                className={s.inputSearch}
              />
            )}
            {localOptions &&
              localOptions.map((option, index) => {
                if (isSingleFilter) {
                  return (
                    <li
                      onClick={() => handleCheck(option)}
                      className={classNames(s.item)}
                      key={index}
                    >
                      {option.name}
                    </li>
                  );
                }
                return (
                  <FilterBarDropdownItem
                    withOptionTranslate={withOptionTranslate}
                    item={option}
                    checked={isChecked(option)}
                    key={index}
                    onCheck={handleCheck}
                    isChecked={isChecked}
                  />
                );
              })}
          </div>
          {!isSingleFilter && (
            <div className={s.btnsWrapper}>
              <Button
                styled="secondary"
                className={s.clear}
                onClick={onClear}
              >
                Clear
              </Button>
              <Button
                styled="primary"
                className={s.apply}
                onClick={() => {
                  handleChange(checkedOptions);
                  setIsOpen(false);
                }}
              >
                Apply filter
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

FilterBarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ),
  handleClearAll: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  defaultValues: PropTypes.any,
  clearAllFilters: PropTypes.func,
  setClearAllFilters: PropTypes.func,
  setFilterValue: PropTypes.func,
  onlyFirstLvlFormat: PropTypes.bool,
  useAutoHeight: PropTypes.bool,
  useZIndex: PropTypes.bool,
  afterClickAction: PropTypes.func,
  withOptionTranslate: PropTypes.bool,
  customQueryParams: PropTypes.any,
  isSingleFilter: PropTypes.bool,
  autocomplete: PropTypes.func,
};

FilterBarDropdown.defaultProps = {
  searchPlaceholder: ""
};

export default FilterBarDropdown;
