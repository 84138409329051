import React from "react";
import TeamCard from "./TeamCard/TeamCard";
import teamData from "./teamData";
import s from "./Leadership.module.scss";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../../../helpers/getTranslateKey";

const Leadership = () => {
  const {t} = useTranslation();
  return (
    <section className={s.leadership}>
      <div className={s.landingContainer}>
        <h2 className={s.title} data-translate-key={"leadership-team"}>
          {t(getTranslateKey("leadership-team"))}
        </h2>

        <p className={s.text}
           data-translate-key={"we're-smart,-we're-hard-working,-we're-easy-to-talk-to,-and-we-love-a-challenge-period"}>
          {t(getTranslateKey("we're-smart,-we're-hard-working,-we're-easy-to-talk-to,-and-we-love-a-challenge-period"))}
        </p>

        <div className={s.cards}>
          {teamData.map((card, index) => (
            <TeamCard
              key={index}
              img={card.img}
              name={card.name}
              office={card.office}
              text={card.text}
              paths={card.paths}
              twitter={card.twitter}
              linkedin={card.linkedin}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Leadership;
