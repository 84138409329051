import React, {useState} from "react";
import s from "./CustomCard.module.scss";
import classNames from "classnames";
import {MovableCardWrapper} from "react-trello/dist/styles/Base";
import Tag from "react-trello/dist/components/Card/Tag";
import DropListTrigger from "components/elements/DropListTrigger/DropListTrigger";
import {dateFormatterWithoutTimezone, fromDate} from "helpers/dateFormat";
import {ReactComponent as Owner} from "assets/icons/projects-board/owner.svg";
import {ReactComponent as Category} from "assets/icons/projects-board/category.svg";
import {ReactComponent as Dates} from "assets/icons/projects-board/dates.svg";
import {ReactComponent as Currency} from "assets/icons/projects-board/currency.svg";
import {formatCurrency} from "helpers/currencyFormat";
import {currencyData} from "helpers/helpData";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../../helpers/getTranslateKey";

const CustomCard = (
  {
    onClick,
    id,
    className,
    name,
    body,
    cardColor,
    subTitle,
    tagStyle,
    escalationText,
    tags,
    style,
    status,
    cardDraggable,
  }
) => {
  const [hintPos, setHintPos] = useState({x: 0, y: 0});
  const [hintLabel, setHintLabel] = useState("");
  const {t} = useTranslation();
  const classes = classNames(
    s.customCard,
    {
      [s.white]: status === "planned",
      [s.pink]: status === "active",
      [s.pink]: status === "Completed",
    },
    className
  );
  const HintIcon = ({icon, label}) => (
    <div
      className={s.icon}
      onMouseLeave={() => setHintPos(null)}
      onMouseEnter={e => {
        setHintLabel(label);
        setHintPos({
          x: e.clientX,
          y: e.clientY
        });
      }}
    >
      {icon}
    </div>
  );
  return (
    <>
      <MovableCardWrapper
        onClick={onClick}
        style={{borderRadius: 0, ...style}}
        className={classes}
        onMouseLeave={() => setHintPos(null)}
      >
        <header
          style={{
            color: cardColor
          }}
          draggable={cardDraggable}
          onDragStart={e => e.preventDefault()}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <p className={s.projectName}>{name}</p>
            <DropListTrigger
              data={[
                {
                  title: "Edit",
                  onClick: () => {
                    body.onEdit(id);
                  }
                },
                {
                  title: "Delete",
                  onClick: () => {
                    body.onDelete(id);
                  }
                }
              ]}
              withOverflow={false}
            />
          </div>
          <div className={s.subTitleContainer}>
            <p>ID:{body.uid}</p>
            <p>
              <HintIcon icon={<Owner/>} label="Owner"/>
              <span>{subTitle.owner}</span>
            </p>
            <p>
              <HintIcon icon={<Category/>} label="Category"/>
              <span>{subTitle.category}</span>
            </p>
            <p>
              <HintIcon icon={<Dates/>} label="Start date - End date"/>
              <span>
                {body.start} - {body.end}
              </span>
            </p>
            {body.savings && (
              <p>
                <HintIcon icon={<Currency/>} label="Savings"/>
                <span className={s.currencyVal}>
                  {formatCurrency(
                    currencyData.find(
                      item => item.label === body.savings.currency
                    )?.value || body.savings?.currency,
                    body.savings.value || 0,
                    false
                  )}
                </span>
              </p>
            )}
          </div>
        </header>
        <div className={s.footer}>
          <div className={s.item}>
            <p data-translate-key={"last-action"}> {t(getTranslateKey("last-action"))}</p>{" "}
            <p>{body.last_action ? fromDate(body.last_action) : "-"}</p>
          </div>
          {status === "completed" && (
            <div className={s.item}>
              <p data-translate-key={"actual-end-date"}> {t(getTranslateKey("actual-end-date"))}</p>{" "}
              <p>
                {body.actual_end_date ? fromDate(body.actual_end_date) : "-"}
              </p>
            </div>
          )}
          {escalationText && (
            <div
              style={{
                color: cardColor
              }}
              className={s.text}
            >
              {escalationText}
            </div>
          )}
          {tags && (
            <div className={s.tags}>
              {tags.map(tag => (
                <Tag key={tag.title} {...tag} tagStyle={tagStyle}/>
              ))}
            </div>
          )}
        </div>
      </MovableCardWrapper>
      {hintLabel && hintPos && (
        <div
          style={{left: hintPos.x + 5, top: hintPos.y - 35}}
          className={s.hint}
        >
          {hintLabel}
        </div>
      )}
    </>
  );
};

export default CustomCard;
