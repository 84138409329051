import React, {useEffect, useState} from 'react';
import MainTable from "../MainTable/MainTable";
import {useHistory} from "react-router-dom";
import s from "./TriageTable.module.scss";
import gradient from "random-gradient";
import Popup from "../../popups/Popup/Popup";
import EditTriageEntityForm from "../../forms/EditTriageEntityForm/EditTriageEntityForm";
import qs from "query-string";
import {entityFormat} from "../../../helpers/triages/entityFormat";
import {sentences} from "../../containers/AdminSettingsRequestsContainer/TriageTab/helpers/sentences";
import {ReactComponent as UnknownUserIcon} from "../../../assets/icons/unknown-user-icon.svg";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const TriageTable = ({data, entity, updateEntityItem, hierarchy, deleteTriageUser}) => {
  const [triages, setTriages] = useState(data);
  const {t} = useTranslation();
  const [currentTriage, setCurrentTriage] = useState(null);
  const [currentUnassignTriage, setCurrentUnassignTriage] = useState(null);
  const history = useHistory();
  const searchQuery = qs.parse(history.location.search);
  const {sort_by, direction, user_ids, ...query} = searchQuery;

  const filterChildrenByName = data => {
    if (data.name.toUpperCase().includes(query.query.toUpperCase())) return true;
    if (data[entity]?.length) return data[entity].some(item => filterChildrenByName(item));
  }

  const filterChildrenByUser = (data, userIds) => {
    if (userIds.includes(data.triage_user?.id) || (!data.triage_user?.id && userIds.includes("unassigned"))) return true;
    if (data[entity]?.length) return data[entity].some(item => filterChildrenByUser(item, userIds));
  }

  const getPreparedTriages = data => {
    let prepareData = data;

    if (query.query) prepareData = prepareData.filter(triage => filterChildrenByName(triage));

    if (Boolean(sort_by && direction)) {
      prepareData = prepareData.sort((a, b) =>
        direction === "asc" ?
          a[sort_by].localeCompare(b[sort_by]) :
          b[sort_by].localeCompare(a[sort_by])
      )
    }

    if (Boolean(user_ids)) {
      const userIds = user_ids.split(",");
      prepareData = prepareData.filter(triage => filterChildrenByUser(triage, userIds));
    }

    return [
      ...prepareData
        .map(triage => triage[entity]?.length ?
          {...triage, [entity]: getPreparedTriages(triage[entity])} :
          triage)
    ];
  }

  useEffect(() => {
    setTriages([...getPreparedTriages(data)]);
  }, [query.query, sort_by, direction, user_ids, data]);

  const onSort = ({sort_by, direction}) => {
    history.push({
      search: qs.stringify({
        ...query,
        sort_by,
        direction,
      })
    });
  };

  const renderTriagePersonName = name => {
    if (!name) return (
      <div className={s.nameWrap} title={name}>
        <span className={s.icon}>
          <UnknownUserIcon/>
        </span>
        <span data-translate-key={getTranslateKey("Unassigned")}>
          {t(getTranslateKey("Unassigned"))}
        </span>
      </div>
    );

    const nameParts = name.split(" ");
    const avatar = nameParts.length > 1 ? nameParts[0][0] + nameParts[1][0] : nameParts[0][0];

    return (
      <div className={s.nameWrap} title={name}>
        <span className={s.icon} style={{background: gradient(name)}}>
          {avatar}
        </span>
        {name}
      </div>
    );
  };

  const tableTypes = [
    {
      title: entityFormat(entity),
      value: ({name}) => name,
      onClick: (id, {name}) => {
        const hierarchyArray = hierarchy.split(",").map(item => item.toLowerCase());

        const prepareHierarchy = hierarchyArray.map(item => {
          if (item === entity) return `${item}_${id}`;
          return item;
        }).join();

        const entityPosition = hierarchyArray.indexOf(entity);

        if (entityPosition + 1 < hierarchyArray.length) {
          history.push(
            `/admin-settings/triage?entity=${hierarchyArray[entityPosition + 1]}&hierarchy=${prepareHierarchy}&name=${name}&prev_entity=${entity}_${id}`
          );
        } else return false;
      },
      handleSort: ({direction}) => onSort({sort_by: "name", direction}),
      sortable: true,
    },
    {
      title: "Triage person",
      value: ({triage_user}) => renderTriagePersonName(triage_user?.name),
    },
    {
      title: "Teams",
      value: ({}) => "-",
    },
  ];

  return (
    <>
      <MainTable
        data={triages}
        subKey={entity}
        subTypes={tableTypes}
        dataTypes={tableTypes}
        options={item => [
          {
            title: "Edit",
            onClick: () => setCurrentTriage(item)
          },
          item.triage_user && {
            title: "Unassign triage person",
            onClick: () => setCurrentUnassignTriage(item)
          }
        ]}
        totalCount={triages.length}
      />
      {currentTriage && (
        <Popup
          title={`Edit ${entityFormat(entity)}`}
          onClose={() => setCurrentTriage(null)}
        >
          <EditTriageEntityForm
            onClose={() => setCurrentTriage(null)}
            defaultValues={{
              triage_user: currentTriage.triage_user && {
                value: currentTriage.triage_user.id,
                label: currentTriage.triage_user.name,
              }
            }}
            entity={entity.toLowerCase()}
            submit={triageUserId => {
              updateEntityItem({triageUserId, entityId: currentTriage.id});
              setCurrentTriage(false);
            }}
          />
        </Popup>
      )}
      {
        currentUnassignTriage && (
          <Popup
            title={sentences.popups.unassignUser}
            text={sentences.popups.unassignUserDescription}
            onClose={() => setCurrentUnassignTriage(null)}
            onClick={() => {
              deleteTriageUser(currentUnassignTriage?.triage_user?.triage_id);
              setCurrentUnassignTriage(null);
            }}
            successBtnTitle="Unassign"
            fullText
            dialog
          />
        )
      }
    </>
  );
};

export default TriageTable;