import React from 'react';
import s from "./SimpleDetails.module.scss";
import {ReactComponent as Pencil} from "../../../assets/icons/edit-pencil.svg";
import classNames from "classnames";

const SimpleDetails = ({onBtnClick, className, children}) => {
  return (
    <div className={classNames(s.detailPanel, className)}>
      {children}
      {onBtnClick && (
        <Pencil
          className={s.editBtn}
          onClick={onBtnClick}
        />
      )}
    </div>
  );
};

export default SimpleDetails;