import React from "react";
import s from "./TaskStatusForm.module.scss";
import Button from "../../elements/Button/Button";
import CustomColorPicker from "../../elements/CustomColorPicker/CustomColorPicker";
import {backgroundTypes} from "../../../helpers/helpData";
import {useFormik} from "formik";
import {object, string} from "yup";
import Input from "../../elements/Input/Input";
import {getTranslateKey} from "../../../helpers/getTranslateKey";
import {useTranslation} from "react-i18next";

const TaskStatusForm = ({onSubmit, onClose, initialValues = null}) => {
  const {t} = useTranslation();
  const formik = useFormik({
    initialValues: initialValues || {
      color: "",
      name: "",
    },
    validationSchema: object({
      color: string().required("Color Status is required"),
      name: string().required("Name is required")
        .max(50, "Please enter custom status name that consists of no more than 50 characters"),
    }),
    onSubmit: (values) => {
      onSubmit({
        color: values.color,
        name: values.name,
      });
      onClose();
    },
  });

  const {handleSubmit, values, setFieldValue, errors, touched} = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off" className={s.formWrapper}>
      <div className={s.fieldWrap}>
        <Input
          label="Custom status"
          value={values.name}
          withCounter
          limitValue={50}
          placeholder="Enter custom status name"
          onChange={(e) => {
            setFieldValue("name", e.target.value);
          }}
          error={touched.name && errors.name}
        />
      </div>
      <div className={s.fieldWrap}>
        <CustomColorPicker
          options={backgroundTypes}
          value={values.color}
          onChange={value => setFieldValue("color", value)}
          label="Color"
          placeholder="Choose status color"
          error={errors.color && touched.color && errors.color}
        />
      </div>
      <div className="popupBtnsWrap">
        <Button
          styled="secondary"
          className="popupBtn"
          type="reset"
          onClick={onClose}
          data-translate-key={getTranslateKey("cancel")}
        >
          {t(getTranslateKey("cancel"))}
        </Button>
        <Button styled="primary" type="submit" className="popupBtn">
          {initialValues ? "Save" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default TaskStatusForm;
