import React from "react";
import PropTypes from "prop-types";
import Input from "../../../../../elements/Input/Input";

const InputField = ({values, setFieldValue, field, errors}) => {
  return (
    <Input
      value={values[field.key]?.value || ""}
      onChange={e => setFieldValue(field.key, {
        value: e.target.value,
        type: "String",
        conditions: field.conditions
      })}
      name={field.key}
      type="text"
      label={field.label}
      withoutTranslate={field.key !== "name"}
      withCounter
      limitValue={80}
      error={errors[field.key] && errors[field.key].value}
    />
  );
};

export default InputField;

InputField.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  field: PropTypes.any,
  errors: PropTypes.any,
};