import React, {useEffect, useState} from "react";
import EmptyBlock from "../../../elements/EmptyBlock/EmptyBlock";
import {useHistory, useLocation} from "react-router-dom";
import {paths} from "../../../../routes/paths";
import services from "../../../../services";
import TableLoading from "../../../loadingStates/Table/Table";
import CustomDropDownTable from "../../../tables/CustomDropDownTable/CustomDropDownTable";
import Popup from "../../../popups/Popup/Popup";
import CustomDropdownsFilter from "../../../filters/CustomDropdownsFilter/CustomDropdownsFilter";
import qs from "query-string";
import querystring from "query-string";
import {getAllIds} from "../../../elements/FilterBar/helpers/helpers";

const CustomDropdownsTab = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savedFilter, setSavedFilter] = useState(null);
  const [savedSettingFilter, setSavedSettingFilter] = useState(null);
  const [deleteCustomDropDownId, setDeleteCustomDropDownId] = useState(null);
  const [restoreCustomDropDownId, setRestoreCustomDropDownId] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const {statuses, ...searchParams} = qs.parse(history.location.search);
  const query = qs.parse(history.location.search);
  const {per_page, custom_dropdowns, dropdowns_count} = data;

  useEffect(() => {
    services.filterService.getFilter("admin_settings_custom_dropdowns").then(res => {
      if (res.data.length > 0) {
        setSavedFilter(res.data[0]);
        showDefaultFilter(res.data[0]?.filter_params || "");
        setSavedSettingFilter(res.data[0]?.filter_params);
      } else {
        history.push({
          search: querystring.stringify({
            ...searchParams,
            statuses: "active"
          })
        });
      }
    });
  }, []);

  const getCustomDropdowns = () => {
    const query = querystring.parse(location.search);
    services.customDropdownsServices.getCustomDropdowns(query).then(res => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getCustomDropdowns();
  }, [location.search]);


  if (clearFilter) {
    getCustomDropdowns();
    setClearFilter(false);
  }

  const onPageChange = page => {
    const query = querystring.parse(history.location.search);
    history.push({search: querystring.stringify({...query, page})});
  };

  const handleClearAll = () => {
    setClearFilter(true);
    history.push({search: qs.stringify({...searchParams})});
  };

  const showDefaultFilter = filterParams => {
    const searchQuery = {
      ...statuses,
      ...JSON.parse(filterParams ?? savedFilter.filter_params),
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
  };

  const handleChangeFilter = (filterName, values) => {
    const valuesIds = getAllIds(values);
    const searchQuery = {
      ...query,
      [filterName]: valuesIds,
    };
    history.push({
      search: qs.stringify(searchQuery, {arrayFormat: "comma"})
    });
    if (valuesIds.length === 0) setClearFilter(true);
  };

  const deleteDropDown = () => {
    services.customDropdownsServices.deleteCustomDropdowns(deleteCustomDropDownId).then(() => {
      setData(customDropDowns => customDropDowns.map(dropDown => {
        if (dropDown.id === deleteCustomDropDownId) return {...dropDown, status: "deleted"};
        return dropDown;
      }));
      setDeleteCustomDropDownId(null);
    });
  };

  const restoreDropDown = () => {
    services.customDropdownsServices.restoreCustomDropDown(restoreCustomDropDownId).then(() => {
      setData(customDropDowns => customDropDowns.map(dropDown => {
        if (dropDown.id === restoreCustomDropDownId) return {...dropDown, status: "active"};
        return dropDown;
      }));
      setRestoreCustomDropDownId(null);
    });
  };

  const renderContent = () => {
    if (custom_dropdowns.length) return (
      <>
        <CustomDropDownTable
          data={custom_dropdowns}
          totalCount={dropdowns_count}
          perPage={per_page}
          deleteDropDown={id => setDeleteCustomDropDownId(id)}
          restoreDropDown={id => setRestoreCustomDropDownId(id)}
          onPageChange={onPageChange}
        />
        {
          deleteCustomDropDownId && (
            <Popup
              onClose={() => setDeleteCustomDropDownId(null)}
              title="Delete dropdown"
              text="Are you sure you want to delete this dropdown?"
              onClick={deleteDropDown}
              dialog
              isDelete
              subText="It will remain in all previous requests, but not in new ones. You can restore it later."
            />
          )
        }
        {
          restoreCustomDropDownId && (
            <Popup
              onClose={() => setRestoreCustomDropDownId(null)}
              title="Restore dropdown"
              text="Are you sure you want to restore this dropdown?"
              successBtnTitle="Restore"
              onClick={restoreDropDown}
              dialog
              isDelete
            />
          )
        }
      </>
    );

    return (
      <EmptyBlock
        subtitle="You don’t have custom dropdowns yet. You can add a new one."
        buttonTitle="New Dropdown"
        onClick={() => history.push(paths.adminSettingsCustomDropDownsCreate)}
      />
    );
  };

  return loading ? <TableLoading length={7}/> : (
    <>
      <CustomDropdownsFilter
        handleChangeFilter={handleChangeFilter}
        handleClearAll={handleClearAll}
        showDefaultFilter={showDefaultFilter}
      />
      {renderContent()}
    </>
  );
};

CustomDropdownsTab.propTypes = {};

export default CustomDropdownsTab;