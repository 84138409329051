import React, {useState, useEffect, useRef} from "react";
import classNames from "classnames";
import "react-dropdown/style.css";
import s from "./CustomColorPicker.module.scss";
import {ReactComponent as Arrow} from "assets/icons/arrowDown.svg";
import {ReactComponent as CheckedIcon} from "assets/icons/checked.svg";
import useOutsideClicker from "hooks/useOutsideClicker";
import {useTranslation} from "react-i18next";
import {getTranslateKey} from "../../../helpers/getTranslateKey";

const CustomColorPicker = (
  {
    options,
    name,
    value,
    onChange,
    label,
    placeholder,
    error,
    disabled,
  }
) => {
  const [opened, setOpened] = useState(false);
  const [focused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const dropRef = useRef(null);
  const {t} = useTranslation();

  useEffect(() => setCurrentValue(value), [value]);

  const handleChange = (item, index) => {
    if (!disabled) {
      setOpened(false);
      setCurrentValue(item);
      onChange(item, index, options);
      setFocused(false);
    }
  };

  useOutsideClicker(dropRef, () => {
    setOpened(false);
    setFocused(false);
  });

  let actualValue = <span className={s.placeholder} data-translate-key={getTranslateKey(placeholder)}>{t(getTranslateKey(placeholder))}</span>;

  if (currentValue) actualValue = <div style={{background: options[currentValue]}} className={s.colorItem}/>;

  return (
    <div className={s.wrap} ref={dropRef}>
      {label && <p className={s.label} data-translate-key={getTranslateKey(label)}>{t(getTranslateKey(label))}</p>}
      <div
        className={classNames(s.dropdown, {
          [s.opened]: opened,
          [s.disabled]: disabled,
          [s.focused]: focused,
        })}
        name={name}
      >
        <header
          className={s.header}
          onClick={() => {
            if (!disabled) {
              setFocused(!focused);
              setOpened(!opened);
            }
          }}
        >
          {actualValue}
          <Arrow className={s.arrow}/>
        </header>

        {opened && (
          <div className={s.dropWrap}>
            {
              Object.keys(options).map((o, index) => (
                <div
                  style={{background: options[o]}}
                  className={s.colorItem}
                  onClick={() => handleChange(o, index)}
                >
                  {o === currentValue && <CheckedIcon/>}
                </div>
              ))
            }
          </div>
        )}
      </div>
      {error && <span className={s.errorMessage} data-translate-key={error}>{t(getTranslateKey(error))}</span>}
    </div>
  );
};

export default CustomColorPicker;
