import {parseToObj} from "../../../../helpers/formulaToObj";

export const getInitialValues = values => {
  return values || {
    name: "",
    request_type: {},
    project_state: {},
    condition_type: {},
    conditions: parseToObj(""),
    approver: {},
    what_approved: {},
    approval_group: {},
    approval_type: {},
    priority: {},
  }
};