import React, {useState, useEffect, Fragment} from "react";
import s from "./FlagReportContainer.module.scss";
import classNames from "classnames";
import Detail from "components/elements/Detail/Detail";
import Tag from "components/elements/Tags/components/Tag/Tag";
import {colors} from "components/elements/Tags/helpers/colors";
import services from "services";
import {useParams} from "react-router-dom";
import {formatDate} from "helpers/dateFormat";
import Skeleton from "@material-ui/lab/Skeleton";
import DetailLoading from "components/loadingStates/Detail/Detail";
import {testData} from "./helpers/testData";
import Suppliers from "../../elements/TagReposrtsTables/Suppliers/Suppliers";
import Projects from "../../elements/TagReposrtsTables/Projects/Projects";
import Contracts from "../../elements/TagReposrtsTables/Contracts/Contracts";

const FlagReportContainer = () => {
  const [data, setData] = useState(testData);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    services.reportsServices.flagReport(params.id).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [params.id]);

  return (
    <div className={s.wrap}>
      {loading ? (
        <Fragment>
          <Skeleton
            variant="text"
            className={classNames("title", s.title)}
            animation="wave"
            width={200}
          />
          <DetailLoading length={3} className={s.details}/>
        </Fragment>
      ) : (
        <Fragment>
          <h2 className={s.title}>{data.name}</h2>
          <Detail
            className={s.details}
            data={[
              {
                name: "User",
                value: data.user?.name
              },
              {
                name: "Creation date",
                value: formatDate(data.created_at, true)
              },
              {
                name: "Color",
                value: (
                  <Tag
                    tag={{color: data.color, id: 1, name: ""}}
                    withDelete={false}
                    style={{
                      width: "64px",
                      background: "transparent",
                      border: `2px solid ${colors[data.color][0]}`
                    }}
                  />
                )
              }
            ]}
          />
        </Fragment>
      )}
      <Suppliers className={s.suppliers}/>
      <Projects className={s.projects}/>
      <Contracts className={s.contracts}/>
    </div>
  );
};

export default FlagReportContainer;
