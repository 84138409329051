import axios from "./axios";

export default {
  getNotes: async (id) => {
    try {
      const result = await axios.get(`/projects/${id}/notes`);
      return result;
    } catch (error) {
      throw error;
    }
  },

  addNote: async (id, params) => {
    try {
      const result = await axios.post(`/projects/${id}/notes`, params);
      return result;
    } catch (error) {
      throw error;
    }
  },

  editNote: async (projectId, id, params, config) => {
    try {
      const result = await axios.put(
        `/projects/${projectId}/notes/${id}`,
        params,
        config
      );
      return result;
    } catch (error) {
      throw error;
    }
  },

  deleteNote: async (projectId, id, config) => {
    try {
      const result = await axios.delete(
        `/projects/${projectId}/notes/${id}`,
        config
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
};
