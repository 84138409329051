import React from "react";
import Button from "components/elements/Button/Button";
import Checkbox from "components/elements/Checkbox/Checkbox";
import s from "./NonFinancialBenefits.module.scss";
import {useTranslation} from "react-i18next";


const RenderBenefits = (
  {
    data,
    onEdit,
    onDelete,
    havePermissions,
    noIteractions,
  }
) => {
  const {t} = useTranslation();
  return (
    <div className={s.nonFinancialBenefits}>
      <div className={s.nonFinancialBenefitsContainer}>
        <span className={s.title} data-translate-key="non-financial-benefits">{t("non-financial-benefits")}</span>
        {havePermissions.update && !noIteractions && (
          <Button onClick={onEdit} styled="primary" data-translate-key="edit">{t("edit")}</Button>
        )}
      </div>
      <div className={s.inlineWrap}>
        {
          data.map(group => (
            <div key={group.id} className={s.col}>
              <h4>{group.name}</h4>
              <div className={s.typeList}>
                {
                  group.non_savings_types.map(type => (
                    <Checkbox
                      className={s.checkbox}
                      checked={true}
                      onChange={() => onDelete(type.id)}
                      label={type.name}
                      key={type.id}
                    />
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};
export default RenderBenefits;
