import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import styles from "./UserDetailsContainer.module.scss";
import UserTabs from "./components/UserTabs/UserTabs";
import Detail from "components/elements/Detail/Detail";
import services from "services/index";
import formatData from "./helpers/formatData";
import DetailLoading from "components/loadingStates/Detail/Detail";
import Skeleton from "@material-ui/lab/Skeleton";

const UserDetailsContainer = () => {
  const params = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    params.id &&
      services.userServices.getUser(params.id).then((res) => {
        setUser(res.data);
        setLoading(false);
      });
  }, [params.id]);

  return (
    <section className={styles.container}>
      {loading ? (
        <Skeleton
          variant="text"
          className={classNames("title", styles.title)}
          animation="wave"
          width={200}
        />
      ) : (
        <h3 className={styles.title}>{user.name}</h3>
      )}

      {!user?.id ? (
        <DetailLoading length={6} />
      ) : (
        <Detail
          data={formatData(user)}
          // buttons={[
          //   {
          //     name: "",
          //   },
          // ]}
          className={styles.detail}
        />
      )}
      <UserTabs />
    </section>
  );
};

export default UserDetailsContainer;
