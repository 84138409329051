import React, {useState} from "react";
import styles from "./Surveys.module.scss";
import DropListTrigger from "../../../../DropListTrigger/DropListTrigger";
import {ReactComponent as Plus} from "../../../../../../assets/icons/plus-default.svg";
import PropTypes from "prop-types";

const Surveys = ({data, useForProject}) => {
  const [visibleDropListMenu, setVisibleDropListMenu] = useState(false);

  return (
    <div className={styles.surveysWrap}>
      <header>
        <p>Survey</p>
        <DropListTrigger
          data={[
            {
              title: "Import template",
              onClick: () => {
              }
            },
            {
              title: "Create template",
              onClick: () => {
              }
            }
          ]}
          menuIcon={<Plus/>}
          visibleMenu={visibleDropListMenu}
          setVisibleMenu={setVisibleDropListMenu}
        />
      </header>
      <div className={styles.surveysListWrap}>
        <div className={styles.listTitle}>
          <p className={styles.title}>Survey name</p>
          {useForProject && (
            <React.Fragment>
              <p className={styles.title}>Respondent</p>
              <p className={styles.title}>Score</p>
            </React.Fragment>
          )}
        </div>
        <p className={styles.emptyList}>You don’t have survey yet.</p>
      </div>
    </div>
  );
};

export default Surveys;

Surveys.propTypes = {
  data: PropTypes.any,
  useForProject: PropTypes.bool
};