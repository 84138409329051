import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import ProjectsContainer from "components/containers/ProjectsContainer/ProjectsContainer";

const ProjectsPage = () => {
	return (
		<DefaultLayout>
			<ProjectsContainer />
		</DefaultLayout>
	);
};

export default ProjectsPage;
