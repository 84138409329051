import React from 'react'
import CheckedLine from '../../../../../components/CheckedLine/CheckedLine'
import PropTypes from 'prop-types'
import s from './IncCard.module.scss'
import {useTranslation} from "react-i18next";
import {useTranslate} from "../../../../../../../hooks/useTranslate";

const IncCard = ({title, list}) => {
  const [translateLabelKey, translateLabelValue] = useTranslate(title);
  return (
    <div className={s.incCard} >
      <h3 className={s.title} data-translate-key={translateLabelKey}>{translateLabelValue}</h3>
      <ul className={s.list} >
        {list.map((item, index)=>(
          <CheckedLine key={index} item={item} />
        ))}
      </ul>
    </div>
  )
}

IncCard.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array
}

IncCard.defaultProps = {
  title: "",
  list: []
}

export default IncCard
