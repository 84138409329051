import React from "react";
import s from "./ProjectsBoard.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";

const ProjectsBoard = ({ className }) => {
  const getTasks = () => {
    let data = [];
    for (let i = 0; i < 8; i++) {
      data.push(
        <div className={classNames("skeletLoaderWrap", s.task, className)}>
          <Skeleton variant="text" animation="wave" width={200} />
          <div className={s.bottom}>
            <Skeleton
              variant="text"
              className={s.text}
              animation="wave"
              width={160}
            />
            <Skeleton
              variant="text"
              className={s.text}
              animation="wave"
              width={140}
            />
          </div>
        </div>
      );
    }
    return data;
  };
  return <div className={s.wrap}>{getTasks()}</div>;
};

export default ProjectsBoard;
