import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout/DefaultLayout";
import ActivityContainer from "components/containers/ActivityContainer/ActivityContainer";

const ActivityPage = () => {
  return (
    <DefaultLayout>
      <ActivityContainer />
    </DefaultLayout>
  );
};

export default ActivityPage;
