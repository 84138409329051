import {
  SET_STAKEHOLDERS,
  SET_LOADING,
  ADD_STAKEHOLDER,
  DELETE_STAKEHOLDER,
  CLOSE_POPUP,
  SET_POPUP_NAME,
  SET_SELECTED_STAKEHOLDER,
  SET_ALERT,
} from "./types";
import services from "services";

export const setStakeholders = (dispatch, categoryId, query) => {
  services.categoriesServices
    .getCategoryStakeholders(categoryId, query)
    .then((res) => {
      dispatch({
        type: SET_STAKEHOLDERS,
        payload: res.data,
      });
    });
};

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const addStakeholder = (dispatch, categoryId, values) => {
  services.stakeholdersServices
    .addCategoryStakeholder(categoryId, values)
    .then((res) => {
      dispatch({
        type: ADD_STAKEHOLDER,
        payload: {
          data: res.data,
          alert: {
            type: "success",
            message: "Category stakeholder successfully added.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: ADD_STAKEHOLDER,
        payload: {
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again.",
          },
        },
      });
    });
};

export const deleteStakeholder = (dispatch, categoryId, stakeholderId) => {
  services.stakeholdersServices
    .removeCategoryStakeholder(categoryId, stakeholderId)
    .then(() => {
      dispatch({
        type: DELETE_STAKEHOLDER,
        payload: {
          stakeholder: stakeholderId,
          alert: {
            type: "success",
            message: "Category stakeholder successfully deleted.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DELETE_STAKEHOLDER,
        payload: {
          alert: {
            type: "error",
            message: "Something went wrong. Please, try again.",
          },
        },
      });
    });
};

export const closePopup = () => ({
  type: CLOSE_POPUP,
  payload: null,
});

export const openPopup = (name) => ({
  type: SET_POPUP_NAME,
  payload: { name },
});

export const setSelectedStakeholder = (stakeholder) => ({
  type: SET_SELECTED_STAKEHOLDER,
  payload: { stakeholder },
});

export const setAlert = (alert) => ({
  type: SET_ALERT,
  payload: { ...alert },
});
