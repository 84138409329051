import moment from "moment"

export const move = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source.projects)
  const destClone = Array.from(destination.projects)
  const [removed] = sourceClone.splice(droppableSource.index, 1)
  destClone.splice(droppableDestination.index, 0, removed)
  const result = {}
  result[droppableSource.droppableId] = { ...source, projects: sourceClone }
  result[droppableDestination.droppableId] = {
    ...destination,
    projects: destClone
  }
  return result
}

export const getProjectLineColors = (stageId, endDate, stages) => {
  const currentDate = moment()
  const projectEndDate = moment(endDate)

  if (
    currentDate.isAfter(projectEndDate) &&
    stageId !== stages[stages.length - 1]?.id
  ) {
    return {
      line: "#FFF3EF",
      lineBorders: "#FFD6D6",
      text: "#B21818"
    }
  } else {
    if (stageId === stages[0]?.id) {
      return {
        line: "#FFFFFF",
        lineBorders: "#D6D6D6",
        text: "rgba(0, 0, 0, 0.9)"
      }
    } else if (stageId === stages[stages.length - 1]?.id) {
      return {
        line: "#F1FFEF",
        lineBorders: "#D3EDCA",
        text: "#5F961B"
      }
    } else {
      return {
        line: "#FFFAEF",
        lineBorders: "#FAEACC",
        text: "#AA7000"
      }
    }
  }
}

export const getDateForEarlierProject = (
  startChartDate,
  startDate,
  endDate
) => {
  const chartDate = moment(startChartDate)
  const projectStartDate = moment(startDate)
  const projectEndDate = moment(endDate)
  const projectLength = getProjectLength(projectStartDate, projectEndDate)

  if (
    projectStartDate.isBefore(chartDate) &&
    projectEndDate.isAfter(chartDate)
  ) {
    const daysBetweenDates = chartDate.diff(moment(projectStartDate), "days")
    return projectLength - daysBetweenDates
  }
}

export const getProjectStartCoordinates = startDate => {
  const currentMonth = moment()
    .startOf("month")
    .toDate()
  const endDate = moment(startDate)
    .add(11, "M")
    .endOf("month")
    .toDate()

  if (moment(startDate).isBetween(moment(currentMonth), moment(endDate))) {
    const daysBetweenDates = moment(startDate).diff(
      moment(currentMonth),
      "days"
    )
    return daysBetweenDates
  } else {
    return false
  }
}

export const getProjectLength = (startDate, endDate) => {
  const daysBetweenDates = moment(endDate).diff(moment(startDate), "days")
  return Math.abs(daysBetweenDates)
}

export const monthsShort = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec"
}
