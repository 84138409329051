import React, { useState } from "react"
import s from "./EditPopup.module.scss"
import Popup from "components/popups/Popup/Popup"
import CustomDropdown from "components/elements/CustomDropdown/CustomDropdown"
import Button from "components/elements/Button/Button"

const EditPopup = ({ onClose, onSubmit, status }) => {
  const [statusVal, setStatusVal] = useState(
    status?.[0]?.toUpperCase() + status?.slice(1) || "Unselected"
  )
  return (
    <Popup onClose={onClose} title='Edit supplier'>
      <form
        onSubmit={e => {
          e.preventDefault()
          onSubmit({
            project_supplier_status: statusVal?.toLowerCase()
          })
        }}
      >
        <CustomDropdown
          options={[
            {
              label: "Selected",
              value: "Selected"
            },
            {
              label: "Unselected",
              value: "Unselected"
            }
          ]}
          value={statusVal}
          onChange={({ label }) => setStatusVal(label)}
          label='Status'
        />
        <div className='popupBtnsWrap'>
          <Button
            styled='secondary'
            type='reset'
            className='popupBtn'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={status === statusVal.toLowerCase()}
            styled='primary'
            type='submit'
            className='popupBtn'
          >
            Save
          </Button>
        </div>
      </form>
    </Popup>
  )
}

export default EditPopup
